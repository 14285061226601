import {
    CHECKBOX_LABEL_BVM_CONNECTION,
    CHECKBOX_LABEL_NEW_OVAL_PLATE,
    CHECKBOX_LABEL_OLD_OVAL_PLATE,
    CODED_PARTS_KEYS_FORM,
    DOCUMENTATION_TICKET_STATUS_CLOSED,
    DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_DEALER,
    DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    DOCUMENTATION_TICKET_STATUS_NEW,
    DOCUMENTATION_TICKET_STATUS_READ_BY_SUBSIDIARY,
    DOCUMENTATION_TICKET_STATUS_REOPENED,
    FORM_CHECKBOX_BVM_CONNECTION,
    FORM_CHECKBOX_CLAIM_RELATED,
    FORM_CHECKBOX_NEW_OVAL_PLATE,
    FORM_CHECKBOX_OLD_OVAL_PLATE,
    FORM_CHECKBOX_PVI_RELATED,
    FORM_CLEAR_ATTACHMENTS,
    FORM_FIELD_ABS,
    FORM_FIELD_AIR_CONDITIONING,
    FORM_FIELD_ATTACHMENTS,
    FORM_FIELD_CAR_MODEL,
    FORM_FIELD_CLAIM_RELATED,
    FORM_FIELD_CLIENT_ID,
    FORM_FIELD_CODE,
    FORM_FIELD_CREATION_DATE,
    FORM_FIELD_DEALER_NAME,
    FORM_FIELD_DEALER_NO,
    FORM_FIELD_DESCRIPTION,
    FORM_FIELD_DIALOGYS_VERSION,
    FORM_FIELD_EDITOR_CODE_1,
    FORM_FIELD_EDITOR_CODE_2,
    FORM_FIELD_EDITOR_DESCRIPTION,
    FORM_FIELD_EDITOR_LINE,
    FORM_FIELD_EDITOR_ORDER,
    FORM_FIELD_EDITOR_PART_NO,
    FORM_FIELD_EDITOR_QUANTITY,
    FORM_FIELD_EMAIL,
    FORM_FIELD_ENGINE_SERIAL_NUMBER,
    FORM_FIELD_ENGINE_TYPE,
    FORM_FIELD_FROM,
    FORM_FIELD_GAR_1,
    FORM_FIELD_GAR_2,
    FORM_FIELD_GAR_3,
    FORM_FIELD_GEARBOX_SERIAL_NUMBER,
    FORM_FIELD_GEARBOX_TYPE,
    FORM_FIELD_INTERNAL_STATUS,
    FORM_FIELD_ITEM_NUMBER,
    FORM_FIELD_LINE, FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_MANUFACTURE_DATE,
    FORM_FIELD_MESSAGE,
    FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
    FORM_FIELD_NAME,
    FORM_FIELD_NAME_TD_SEARCH,
    FORM_FIELD_NEW_ATTACHMENTS,
    FORM_FIELD_NEW_PLATE1,
    FORM_FIELD_NEW_PLATE10,
    FORM_FIELD_NEW_PLATE11,
    FORM_FIELD_NEW_PLATE12,
    FORM_FIELD_NEW_PLATE13,
    FORM_FIELD_NEW_PLATE14,
    FORM_FIELD_NEW_PLATE15,
    FORM_FIELD_NEW_PLATE16,
    FORM_FIELD_NEW_PLATE17,
    FORM_FIELD_NEW_PLATE2,
    FORM_FIELD_NEW_PLATE3,
    FORM_FIELD_NEW_PLATE4,
    FORM_FIELD_NEW_PLATE5,
    FORM_FIELD_NEW_PLATE6,
    FORM_FIELD_NEW_PLATE7,
    FORM_FIELD_NEW_PLATE8,
    FORM_FIELD_NEW_PLATE9,
    FORM_FIELD_OLD_PLATE1,
    FORM_FIELD_OLD_PLATE2,
    FORM_FIELD_OLD_PLATE3,
    FORM_FIELD_OLD_PLATE4,
    FORM_FIELD_OLD_PLATE5,
    FORM_FIELD_OLD_PLATE6,
    FORM_FIELD_OLD_PLATE7,
    FORM_FIELD_OLD_PLATE8,
    FORM_FIELD_ORDER,
    FORM_FIELD_PAGE_POST,
    FORM_FIELD_PAGE_PREF,
    FORM_FIELD_PART_DESCRIPTION,
    FORM_FIELD_PART_MARKING,
    FORM_FIELD_PART_NO,
    FORM_FIELD_PART_NUMBER, FORM_FIELD_PARTNER_NAME,
    FORM_FIELD_PFX,
    FORM_FIELD_POWER_STEERING,
    FORM_FIELD_PR,
    FORM_FIELD_PRIORITY_TREATMENT,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_PROBLEM_TYPE,
    FORM_FIELD_PROD,
    FORM_FIELD_PVI_RELATED,
    FORM_FIELD_QUANTITY,
    FORM_FIELD_REGION,
    FORM_FIELD_REGISTRATION_NO,
    FORM_FIELD_REQUEST_FOR_PARTNER,
    FORM_FIELD_SFX,
    FORM_FIELD_SOLUTION_GROUP,
    FORM_FIELD_STEERING_WHEEL,
    FORM_FIELD_SUPPLEMENTARY_INFORMATION,
    FORM_FIELD_SUPPLIER,
    FORM_FIELD_TELEPHONE_NO,
    FORM_FIELD_TICKET_NUMBER,
    FORM_FIELD_TYPE_OF_REQUEST,
    FORM_FIELD_VIN,
    FORM_FIELDS_ORDER_ITEMS,
    FORM_LABEL_ABS,
    FORM_LABEL_AIR_CONDITIONING,
    FORM_LABEL_ATTACHMENTS,
    FORM_LABEL_CLAIM_RELATED,
    FORM_LABEL_CLIENT_ID,
    FORM_LABEL_CODE,
    FORM_LABEL_CREATION_DATE,
    FORM_LABEL_DEALER_NAME,
    FORM_LABEL_DEALER_NO,
    FORM_LABEL_DESCRIPTION, FORM_LABEL_DIALOGYS_VERSION,
    FORM_LABEL_EDITOR_CODE_1,
    FORM_LABEL_EDITOR_CODE_2,
    FORM_LABEL_EDITOR_DESCRIPTION,
    FORM_LABEL_EDITOR_LINE,
    FORM_LABEL_EDITOR_ORDER,
    FORM_LABEL_EDITOR_PART_NO,
    FORM_LABEL_EDITOR_QUANTITY,
    FORM_LABEL_EMAIL,
    FORM_LABEL_ENGINE_SERIAL_NUMBER,
    FORM_LABEL_ENGINE_TYPE,
    FORM_LABEL_FROM,
    FORM_LABEL_GAR_1,
    FORM_LABEL_GAR_2,
    FORM_LABEL_GAR_3,
    FORM_LABEL_GEARBOX_SERIAL_NUMBER,
    FORM_LABEL_GEARBOX_TYPE,
    FORM_LABEL_INTERNAL_STATUS,
    FORM_LABEL_ITEM_NUMBER,
    FORM_LABEL_LINE, FORM_LABEL_MANAGEMENT_GROUP,
    FORM_LABEL_MANUFACTURE_DATE,
    FORM_LABEL_MESSAGE,
    FORM_LABEL_N_REFERENCE_CAUSING_PROBLEM,
    FORM_LABEL_NAME,
    FORM_LABEL_NEW_ATTACHMENTS,
    FORM_LABEL_NEW_PLATE1,
    FORM_LABEL_NEW_PLATE10,
    FORM_LABEL_NEW_PLATE11,
    FORM_LABEL_NEW_PLATE12,
    FORM_LABEL_NEW_PLATE13,
    FORM_LABEL_NEW_PLATE14,
    FORM_LABEL_NEW_PLATE15,
    FORM_LABEL_NEW_PLATE16,
    FORM_LABEL_NEW_PLATE17,
    FORM_LABEL_NEW_PLATE2,
    FORM_LABEL_NEW_PLATE3,
    FORM_LABEL_NEW_PLATE4,
    FORM_LABEL_NEW_PLATE5,
    FORM_LABEL_NEW_PLATE6,
    FORM_LABEL_NEW_PLATE7,
    FORM_LABEL_NEW_PLATE8,
    FORM_LABEL_NEW_PLATE9,
    FORM_LABEL_OLD_PLATE1,
    FORM_LABEL_OLD_PLATE2,
    FORM_LABEL_OLD_PLATE3,
    FORM_LABEL_OLD_PLATE4,
    FORM_LABEL_OLD_PLATE5,
    FORM_LABEL_OLD_PLATE6,
    FORM_LABEL_OLD_PLATE7,
    FORM_LABEL_OLD_PLATE8,
    FORM_LABEL_ORDER,
    FORM_LABEL_ORDER_ITEMS,
    FORM_LABEL_PAGE_POST,
    FORM_LABEL_PAGE_PREF,
    FORM_LABEL_PART_DESCRIPTION,
    FORM_LABEL_PART_MARKING,
    FORM_LABEL_PART_NO,
    FORM_LABEL_PART_NUMBER, FORM_LABEL_PARTNER_NAME,
    FORM_LABEL_POWER_STEERING,
    FORM_LABEL_PR,
    FORM_LABEL_PRIORITY_TREATMENT,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_PROBLEM_TYPE,
    FORM_LABEL_PROD,
    FORM_LABEL_PVI_RELATED,
    FORM_LABEL_QUANTITY,
    FORM_LABEL_REGION,
    FORM_LABEL_REGISTRATION_NO,
    FORM_LABEL_REQUEST_FOR_PARTNER,
    FORM_LABEL_SOLUTION_GROUP,
    FORM_LABEL_STEERING_WHEEL,
    FORM_LABEL_SUPPLEMENTARY_INFORMATION,
    FORM_LABEL_SUPPLIER,
    FORM_LABEL_TELEPHONE_NO,
    FORM_LABEL_TICKET_NUMBER, FORM_LABEL_TYPE_OF_REQUEST,
    FORM_LABEL_VEHICLE_MODEL,
    FORM_LABEL_VIN,
    OVAL_PLATE_ORDER_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM,
    UNKNOWN_PART_OPENING_REQUEST_FORM
} from './formConstants';
import {ALL_COUNTRIES_VALUE} from './Utils';

export const techDocFieldLabelMapping = {
    [FORM_FIELDS_ORDER_ITEMS]: FORM_LABEL_ORDER_ITEMS,
    [FORM_FIELD_CLIENT_ID]: FORM_LABEL_CLIENT_ID,
    [FORM_FIELD_DEALER_NAME]: FORM_LABEL_DEALER_NAME,
    [FORM_FIELD_DEALER_NO]: FORM_LABEL_DEALER_NO,
    [FORM_FIELD_REGION]: FORM_LABEL_REGION,
    [FORM_FIELD_NAME]: FORM_LABEL_NAME,
    [FORM_FIELD_NAME_TD_SEARCH]: FORM_LABEL_NAME,
    [FORM_FIELD_EMAIL]: FORM_LABEL_EMAIL,
    [FORM_FIELD_TELEPHONE_NO]: FORM_LABEL_TELEPHONE_NO,
    [FORM_FIELD_CREATION_DATE]: FORM_LABEL_CREATION_DATE,
    [FORM_FIELD_TICKET_NUMBER]: FORM_LABEL_TICKET_NUMBER,
    [FORM_FIELD_MESSAGE]: FORM_LABEL_MESSAGE,
    [FORM_FIELD_PROD]: FORM_LABEL_PROD,
    [FORM_FIELD_ORDER]: FORM_LABEL_ORDER,
    [FORM_FIELD_LINE]: FORM_LABEL_LINE,
    [FORM_FIELD_EDITOR_LINE]: FORM_LABEL_EDITOR_LINE,
    [FORM_FIELD_PART_NO]: FORM_LABEL_PART_NO,
    [FORM_FIELD_EDITOR_PART_NO]: FORM_LABEL_EDITOR_PART_NO,
    [FORM_FIELD_DESCRIPTION]: FORM_LABEL_DESCRIPTION,
    [FORM_FIELD_EDITOR_DESCRIPTION]: FORM_LABEL_EDITOR_DESCRIPTION,
    [FORM_FIELD_QUANTITY]: FORM_LABEL_QUANTITY,
    [FORM_FIELD_EDITOR_QUANTITY]: FORM_LABEL_EDITOR_QUANTITY,
    [FORM_FIELD_CODE]: FORM_LABEL_CODE,
    [FORM_FIELD_EDITOR_CODE_1]: FORM_LABEL_EDITOR_CODE_1,
    [FORM_FIELD_EDITOR_CODE_2]: FORM_LABEL_EDITOR_CODE_2,
    [FORM_FIELD_PART_MARKING]: FORM_LABEL_PART_MARKING,
    [FORM_FIELD_ENGINE_TYPE]: FORM_LABEL_ENGINE_TYPE,
    [FORM_FIELD_ENGINE_SERIAL_NUMBER]: FORM_LABEL_ENGINE_SERIAL_NUMBER,
    [FORM_FIELD_GEARBOX_TYPE]: FORM_LABEL_GEARBOX_TYPE,
    [FORM_FIELD_GEARBOX_SERIAL_NUMBER]: FORM_LABEL_GEARBOX_SERIAL_NUMBER,
    [FORM_FIELD_ABS]: FORM_LABEL_ABS,
    [FORM_FIELD_POWER_STEERING]: FORM_LABEL_POWER_STEERING,
    [FORM_FIELD_AIR_CONDITIONING]: FORM_LABEL_AIR_CONDITIONING,
    [FORM_FIELD_STEERING_WHEEL]: FORM_LABEL_STEERING_WHEEL,
    [FORM_FIELD_DIALOGYS_VERSION]: FORM_LABEL_DIALOGYS_VERSION,
    [FORM_FIELD_GAR_1]: FORM_LABEL_GAR_1,
    [FORM_FIELD_GAR_2]: FORM_LABEL_GAR_2,
    [FORM_FIELD_GAR_3]: FORM_LABEL_GAR_3,
    [FORM_FIELD_PR]: FORM_LABEL_PR,
    [FORM_FIELD_PAGE_PREF]: FORM_LABEL_PAGE_PREF,
    [FORM_FIELD_PAGE_POST]: FORM_LABEL_PAGE_POST,
    [FORM_FIELD_ITEM_NUMBER]: FORM_LABEL_ITEM_NUMBER,
    [FORM_FIELD_PART_NUMBER]: FORM_LABEL_PART_NUMBER,
    [FORM_FIELD_EDITOR_ORDER]: FORM_LABEL_EDITOR_ORDER,
    [FORM_FIELD_PROBLEM_TYPE]: FORM_LABEL_PROBLEM_TYPE,
    [FORM_FIELD_SUPPLIER]: FORM_LABEL_SUPPLIER,
    [FORM_FIELD_PART_DESCRIPTION]: FORM_LABEL_PART_DESCRIPTION,
    [FORM_FIELD_PROBLEM_DESCRIPTION]: FORM_LABEL_PROBLEM_DESCRIPTION,
    [FORM_FIELD_PVI_RELATED]: FORM_LABEL_PVI_RELATED,
    [FORM_CHECKBOX_PVI_RELATED]: FORM_LABEL_PVI_RELATED,
    [FORM_FIELD_CLAIM_RELATED]: FORM_LABEL_CLAIM_RELATED,
    [FORM_CHECKBOX_CLAIM_RELATED]: FORM_LABEL_CLAIM_RELATED,
    [FORM_FIELD_ATTACHMENTS]: FORM_LABEL_ATTACHMENTS,
    [FORM_FIELD_NEW_ATTACHMENTS]: FORM_LABEL_NEW_ATTACHMENTS,
    [FORM_CLEAR_ATTACHMENTS]: FORM_CLEAR_ATTACHMENTS,
    [FORM_FIELD_VIN]: FORM_LABEL_VIN,
    [FORM_FIELD_MANUFACTURE_DATE]: FORM_LABEL_MANUFACTURE_DATE,
    [FORM_FIELD_REGISTRATION_NO]: FORM_LABEL_REGISTRATION_NO,
    [FORM_FIELD_CAR_MODEL]: FORM_LABEL_VEHICLE_MODEL,
    [FORM_FIELD_INTERNAL_STATUS]: FORM_LABEL_INTERNAL_STATUS,
    [FORM_FIELD_SUPPLEMENTARY_INFORMATION]: FORM_LABEL_SUPPLEMENTARY_INFORMATION,
    [FORM_FIELD_FROM]: FORM_LABEL_FROM,
    [FORM_CHECKBOX_BVM_CONNECTION]: CHECKBOX_LABEL_BVM_CONNECTION,
    [FORM_CHECKBOX_NEW_OVAL_PLATE]: CHECKBOX_LABEL_NEW_OVAL_PLATE,
    [FORM_CHECKBOX_OLD_OVAL_PLATE]: CHECKBOX_LABEL_OLD_OVAL_PLATE,
    [CODED_PARTS_KEYS_FORM]: CODED_PARTS_KEYS_FORM,
    [OVAL_PLATE_ORDER_FORM]: OVAL_PLATE_ORDER_FORM,
    [SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM]: SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    [SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM]: SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
    [UNKNOWN_PART_OPENING_REQUEST_FORM]: UNKNOWN_PART_OPENING_REQUEST_FORM,
    [SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM]: SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM,
    [DOCUMENTATION_TICKET_STATUS_NEW]: DOCUMENTATION_TICKET_STATUS_NEW,
    [DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_DEALER]: DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_DEALER,
    [DOCUMENTATION_TICKET_STATUS_READ_BY_SUBSIDIARY]: DOCUMENTATION_TICKET_STATUS_READ_BY_SUBSIDIARY,
    [DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY]: DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    [DOCUMENTATION_TICKET_STATUS_REOPENED]: DOCUMENTATION_TICKET_STATUS_REOPENED,
    [DOCUMENTATION_TICKET_STATUS_CLOSED]: DOCUMENTATION_TICKET_STATUS_CLOSED,
    [ALL_COUNTRIES_VALUE]: ALL_COUNTRIES_VALUE,
    [FORM_FIELD_NEW_PLATE1]: FORM_LABEL_NEW_PLATE1,
    [FORM_FIELD_NEW_PLATE2]: FORM_LABEL_NEW_PLATE2,
    [FORM_FIELD_NEW_PLATE3]: FORM_LABEL_NEW_PLATE3,
    [FORM_FIELD_NEW_PLATE4]: FORM_LABEL_NEW_PLATE4,
    [FORM_FIELD_NEW_PLATE5]: FORM_LABEL_NEW_PLATE5,
    [FORM_FIELD_NEW_PLATE6]: FORM_LABEL_NEW_PLATE6,
    [FORM_FIELD_NEW_PLATE7]: FORM_LABEL_NEW_PLATE7,
    [FORM_FIELD_NEW_PLATE8]: FORM_LABEL_NEW_PLATE8,
    [FORM_FIELD_NEW_PLATE9]: FORM_LABEL_NEW_PLATE9,
    [FORM_FIELD_NEW_PLATE10]: FORM_LABEL_NEW_PLATE10,
    [FORM_FIELD_NEW_PLATE11]: FORM_LABEL_NEW_PLATE11,
    [FORM_FIELD_NEW_PLATE12]: FORM_LABEL_NEW_PLATE12,
    [FORM_FIELD_NEW_PLATE13]: FORM_LABEL_NEW_PLATE13,
    [FORM_FIELD_NEW_PLATE14]: FORM_LABEL_NEW_PLATE14,
    [FORM_FIELD_NEW_PLATE15]: FORM_LABEL_NEW_PLATE15,
    [FORM_FIELD_NEW_PLATE16]: FORM_LABEL_NEW_PLATE16,
    [FORM_FIELD_NEW_PLATE17]: FORM_LABEL_NEW_PLATE17,
    [FORM_FIELD_OLD_PLATE1]: FORM_LABEL_OLD_PLATE1,
    [FORM_FIELD_OLD_PLATE2]: FORM_LABEL_OLD_PLATE2,
    [FORM_FIELD_OLD_PLATE3]: FORM_LABEL_OLD_PLATE3,
    [FORM_FIELD_OLD_PLATE4]: FORM_LABEL_OLD_PLATE4,
    [FORM_FIELD_OLD_PLATE5]: FORM_LABEL_OLD_PLATE5,
    [FORM_FIELD_OLD_PLATE6]: FORM_LABEL_OLD_PLATE6,
    [FORM_FIELD_OLD_PLATE7]: FORM_LABEL_OLD_PLATE7,
    [FORM_FIELD_OLD_PLATE8]: FORM_LABEL_OLD_PLATE8,
    [FORM_FIELD_SOLUTION_GROUP]: FORM_LABEL_SOLUTION_GROUP,
    [FORM_FIELD_REQUEST_FOR_PARTNER]: FORM_LABEL_REQUEST_FOR_PARTNER,
    [FORM_FIELD_TYPE_OF_REQUEST]: FORM_LABEL_TYPE_OF_REQUEST,
    [FORM_FIELD_PRIORITY_TREATMENT]: FORM_LABEL_PRIORITY_TREATMENT,
    [FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM]: FORM_LABEL_N_REFERENCE_CAUSING_PROBLEM,
    [FORM_FIELD_PARTNER_NAME]: FORM_LABEL_PARTNER_NAME,
    [FORM_FIELD_MANAGEMENT_GROUP]: FORM_LABEL_MANAGEMENT_GROUP,

    //search engine
    [FORM_FIELD_PFX]: FORM_LABEL_TICKET_NUMBER,
    [FORM_FIELD_SFX]: FORM_LABEL_TICKET_NUMBER,
};
