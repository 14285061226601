import {
    SPARE_PARTS_FETCH_PREFERENCES_PENDING,
    SPARE_PARTS_UPDATE_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE,
    SPARE_PARTS_UPDATE_USER_HISTORY_PREFERENCE,
    SPARE_PARTS_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE,
    SPARE_PARTS_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE,
    SPARE_PARTS_UPDATE_USER_LIST_PREFERENCE,
    SPARE_PARTS_UPDATE_USER_SEARCH_ENGINE_PREFERENCE,
    SPARE_PARTS_UPDATE_USER_STATISTICS_PREFERENCE,
    SPARE_PARTS_UPDATE_USER_STATISTICS_SG_PREFERENCE
} from '../../actions/orders/actionSparePartsPreferences';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import get from 'get-value';

export const sparePartsPreferences = (state = {
    isLoading: true,
    menuList: [],
    menuHistory: [],
    menuSearchEngine: [],
    menuStatistics: [],
    menuStatisticsSG: [],
    menuFullTextSearchInternal: [],
    menuInternalList: [],
    menuInternalHistory: [],
}, action) => {
    switch (action.type) {
        case SPARE_PARTS_UPDATE_USER_LIST_PREFERENCE: {
            return Object.assign({}, state, {menuList: action.payload.preferences.hiddenColumns});
        }
        case SPARE_PARTS_UPDATE_USER_HISTORY_PREFERENCE: {
            return Object.assign({}, state, {menuHistory: action.payload.preferences.hiddenColumns});
        }
        case SPARE_PARTS_UPDATE_USER_SEARCH_ENGINE_PREFERENCE: {
            return Object.assign({}, state, {menuSearchEngine: action.payload.preferences.hiddenColumns});
        }
        case SPARE_PARTS_UPDATE_USER_STATISTICS_PREFERENCE: {
            return Object.assign({}, state, {menuStatistics: action.payload.preferences.countries});
        }
        case SPARE_PARTS_UPDATE_USER_STATISTICS_SG_PREFERENCE: {
            return Object.assign({}, state, {menuStatisticsSG: action.payload.preferences.solutionGroups});
        }
        case SPARE_PARTS_UPDATE_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE: {
            return Object.assign({}, state, {menuFullTextSearchInternal: action.payload.preferences.hiddenColumns});
        }
        case SPARE_PARTS_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE: {
            return Object.assign({}, state, {menuInternalList: action.payload.preferences.hiddenColumns});
        }
        case SPARE_PARTS_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE: {
            return Object.assign({}, state, {menuInternalHistory: action.payload.preferences.hiddenColumns});
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_PREFERENCES_SEND: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                menuList: get(action,'payload.preferences.menuList', []),
                menuHistory: get(action,'payload.preferences.menuHistory', []),
                menuSearchEngine: get(action,'payload.preferences.menuSearchEngine', []),
                menuStatistics: get(action,'payload.preferences.menuStatistics', []),
                menuStatisticsSG: get(action,'payload.preferences.menuStatisticsSG', []),
                menuFullTextSearchInternal: get(action,'payload.preferences.menuFullTextSearchInternal', []),
                menuInternalList: get(action,'payload.preferences.menuInternalList', []),
                menuInternalHistory: get(action,'payload.preferences.menuInternalHistory', []),
                isLoading: false,
                correlationId: action.payload.correlationId
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_PREFERENCES_SEND_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId: action.payload.correlationId});
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_PREFERENCES_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId: action.payload.correlationId});
        }
        case SPARE_PARTS_FETCH_PREFERENCES_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        default:
            return state;
    }
};
