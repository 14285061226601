import {cloneDeep} from 'lodash';
import {
    FLOW_ADD_SAVED_TO_LIST,
    FLOW_DELETE_ERROR,
    FLOW_DELETE_PENDING,
    FLOW_DELETE_SUCCESS,
    FLOW_DETAIL_FETCH_ERROR,
    FLOW_DETAIL_FETCH_PENDING,
    FLOW_DETAIL_FETCH_SUCCESS,
    FLOW_LIST_FETCH_ERROR,
    FLOW_LIST_FETCH_PENDING,
    FLOW_LIST_FETCH_SUCCESS,
    FLOW_SAVE_EXISTING_ERROR,
    FLOW_SAVE_EXISTING_PENDING,
    FLOW_SAVE_EXISTING_SUCCESS,
    FLOW_SAVE_NEW_ERROR,
    FLOW_SAVE_NEW_PENDING,
    FLOW_SAVE_NEW_SUCCESS,
    FLOW_STORE_SAVED
} from '../actions/actionFlow';

export const flowReducer = (state = {
    isLoading: false,
    listData: [],
    detailData: {},
    savedFlow: {}
}, action) => {
    switch (action.type) {
        case FLOW_LIST_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case FLOW_LIST_FETCH_SUCCESS: {
            return Object.assign({}, state, {isLoading: false, listData: action.payload});
        }
        case FLOW_LIST_FETCH_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case FLOW_DELETE_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case FLOW_DELETE_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                listData: state.listData.filter(flow => flow.id !== action.payload)
            });
        }
        case FLOW_DELETE_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case FLOW_DETAIL_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case FLOW_DETAIL_FETCH_SUCCESS: {
            return Object.assign({}, state, {isLoading: false, detailData: action.payload});
        }
        case FLOW_DETAIL_FETCH_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case FLOW_SAVE_NEW_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case FLOW_SAVE_NEW_SUCCESS: {
            return Object.assign({}, state, {isLoading: false});
        }
        case FLOW_SAVE_NEW_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case FLOW_SAVE_EXISTING_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case FLOW_SAVE_EXISTING_SUCCESS: {
            return Object.assign({}, state, {isLoading: false});
        }
        case FLOW_SAVE_EXISTING_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case FLOW_STORE_SAVED: {
            return Object.assign({}, state, {savedFlow: action.payload});
        }
        case FLOW_ADD_SAVED_TO_LIST: {
            const savedFlow = cloneDeep(state.savedFlow);
            let flowFound = false;
            const listData = state.listData.map(flow => {
                if (flow.id === savedFlow.id) {
                    flowFound = true;
                    return cloneDeep(savedFlow);
                } else {
                    return cloneDeep(flow);
                }
            });
            if (!flowFound) {
                listData.push(savedFlow);
            }
            return Object.assign({}, state, {listData});
        }
        default: {
            return state;
        }
    }
};
