import React from 'react';
import { FORM_FIELD_CLAIM_TYPE, FORM_FIELD_CLIENT_SOLUTION_PILOT, FORM_FIELD_TRANSPORT_NUMBER, FORM_FIELD_TRANSPORT_PROVIDER, FORM_FIELD_USER_STATUS, FORM_LABEL_CLAIM_TYPE, FORM_LABEL_CLIENT_SOLUTION_PILOT,
    FORM_LABEL_TRANSPORT_NUMBER, FORM_LABEL_TRANSPORT_PROVIDER, FORM_LABEL_USER_STATUS, FORM_ORDERS_SECTION } from "../../../../../../constants/formConstants";
import {
    StyledSpan,
    StyledTh,
    StyledThead,
} from "../../../../../common/StyledComponents";
import ValidFormattedMessage from "../../../../../common/ValidFormattedMessage";
import formMessages from "../../../../../../intl/claims/formMessages";
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { PROVIDER } from '../../../../../../constants/Utils';

const OrdersTroubleshootUpperTableHeader = (props) => {
    const { readOnly, reqFields, userStatus, userRights } = props;
    return (
        <StyledThead>
            <tr>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_USER_STATUS} intlMessages={formMessages}/>
                    {userRights.canEditOrder && userRights.canEditUserStatus && reqFields.includes(FORM_FIELD_USER_STATUS) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_CLIENT_SOLUTION_PILOT} intlMessages={formMessages}/>
                    {userRights.canEditOrder && reqFields.includes(FORM_FIELD_CLIENT_SOLUTION_PILOT) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_TRANSPORT_PROVIDER} intlMessages={formMessages}/>
                    {userRights.canEditOrder && reqFields.includes(FORM_FIELD_TRANSPORT_PROVIDER) && !readOnly && userStatus === PROVIDER && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_TRANSPORT_NUMBER} intlMessages={formMessages}/>
                    {userRights.canEditOrder && reqFields.includes(FORM_FIELD_TRANSPORT_NUMBER) && !readOnly && userStatus === PROVIDER &&  <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_CLAIM_TYPE} intlMessages={formMessages}/>
                    {userRights.canEditOrder && userRights.canEditClaimType && reqFields.includes(FORM_FIELD_CLAIM_TYPE) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
            </tr>
        </StyledThead>
    )
}

OrdersTroubleshootUpperTableHeader.propTypes = {
    readOnly: PropTypes.bool,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
};

const mapStateToProps = (state, props) => ({
    userStatus: formValueSelector(props.form)(state, `${FORM_ORDERS_SECTION}.${FORM_FIELD_USER_STATUS}`)
})

export default connect(mapStateToProps, () => {})(OrdersTroubleshootUpperTableHeader);
