import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'techDoc.managementGroup.title',
        defaultMessage: 'Management Group'
    },
    FORM_TITLE: {
        id: 'techDoc.managementGroup.form.subject',
        defaultMessage: 'Input file for Management Group change'
    },
    ATTACHMENT_FIELD: {
        id: 'techDoc.managementGroup.label.attachment',
        defaultMessage: 'Attachment'
    },
    TOTAL_PARTS_LABEL: {
        id: 'techDoc.managementGroup.label.totalParts',
        defaultMessage: 'Total parts to update'
    },
    LAST_BATCH_LABEL: {
        id: 'techDoc.managementGroup.label.lastBatch',
        defaultMessage: 'Last batch'
    },
    STATUS_LABEL: {
        id: 'techDoc.managementGroup.label.status',
        defaultMessage: 'Batch status'
    },
    UPDATED_TICKETS_LABEL: {
        id: 'techDoc.managementGroup.label.updatedTickets',
        defaultMessage: 'Updated tickets'
    },
    LOADED_PARTS_LABEL: {
        id: 'techDoc.managementGroup.label.loadedParts',
        defaultMessage: 'Loaded parts'
    },
});

export default messages;
