import React from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import PropTypes from 'prop-types';


const StyledTextArea = styled(TextareaAutosize)`
	overflow: hidden;
	resize: none;
	&:focus {
    outline: none;
	  border:1px solid #ffcc33;
	  -webkit-box-shadow: none;
    box-shadow: none;
	}
	margin-bottom: 10px;
`;

export const TextArea = ({input, type}) => (
    <div className="">
        <StyledTextArea className="form-control"
                        {...input}
                        type={type}
                        minRows={5}
                        maxRows={10}
        />
    </div>
);

TextArea.propTypes = {
    input: PropTypes.object,
    type: PropTypes.string,
};
