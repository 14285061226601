import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import get from 'get-value';
import {moduleRoles} from '../../../utils/roles';
import modelAnswersMessages from '../../../intl/admin/modelAnswersMessages';
import PageHeader from '../../common/PageHeader';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import {
    fetchModelAnswers,
    setCountryGroups,
    updateModelAnswer,
    createModelAnswer,
    deleteModelAnswer,
    resetModelAnswers,
    fetchFullSolutionGroupInfo
} from '../../../actions/admin/actionAdminModelAnswers';
import Select from 'react-select';
import authManagementMessages from '../../../intl/admin/authManagementMessages';
import buttonMessages from "../../../intl/common/buttonMessages";
import {PrimaryButton} from "../../common/Button";
import ModelAnswerDialog from "./ModelAnswerDialog";
import ConfirmationDialog from "../../common/modal/ConfirmationDialog";
import ModelAnswersTable from "./ModelAnswersTable";
import v4 from 'uuid/v4';

const Label = styled.label`
    font-weight: bold;
`;

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

const Button = styled(PrimaryButton)`
    margin-right: 5px;
`;

const ModelAnswersPage = props => {
    const {
        roles,
        domain,
        countryGroups,
        intl: {formatMessage},
        isLoading,
        isLoadingSolutionGroup,
        solutionGroup
    } = props;
    const [modelAnswerDialog, setModelAnswerDialog] = useState({isOpen: false});
    const [confirmationDialog, setConfirmationDialog] = useState({isOpen: false});
    const [defaultTableSettings, setTableSettings] = useState({
        pageSize: 10,
        sorted: [{id: 'uuid'}],
        filtered: []
    });
    const solutionGroups = moduleRoles.getSGAdminGroups(domain, roles);
    const optionsSGs = (solutionGroups || []).map(sg => ({value: sg.id, label: sg.name}));

    useEffect(() => {
        return () => {
            props.resetModelAnswers();
        }
    }, []);

    const handleCountryGroupSelection = groups => {
        props.setCountryGroups(groups.map(group => group.value));
        props.fetchModelAnswers(domain, solutionGroup, groups.map(group => group.value), defaultTableSettings.pageSize,
            defaultTableSettings.sorted, undefined, undefined, defaultTableSettings.filtered);
    };

    const handleSolutionGroupSelection = sg => {
        props.fetchFullSolutionGroupInfo(domain, get(sg, 'value'));
    };

    const saveModelAnswerDialog = (formData) => {
        const sgId = get(solutionGroup, 'id');
        const displayArea = get(formData, 'displayArea', {default: 'INTERNAL_NOTES'});
        if (!get(formData, 'uuid')) {
            props.createModelAnswer(domain, {
                id: sgId,
                modelAnswer: {sgId, uuid: v4(), ...formData, displayArea}
            });
        } else {
            props.updateModelAnswer(domain, {
                id: sgId,
                modelAnswer: {sgId, ...formData, displayArea}
            });
        }
        setModelAnswerDialog({isOpen: false});
    };

    const saveConfirmationDialog = (data) => {
        props.deleteModelAnswer(domain, {
            id: get(solutionGroup, 'id'),
            modelAnswer: {sgId: get(solutionGroup, 'id'), uuid: confirmationDialog.modelAnswerToDelete}
        });
        setConfirmationDialog({isOpen: false});
    };

    return (
        <div className="pb-5">
            <PageHeader title={<FormattedMessage {...modelAnswersMessages.TITLE}/>}/>
            <StyledForm className="form-group">
                <div className="form-row mb-sm-3">
                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
                        <Label>
                            <FormattedMessage {...modelAnswersMessages.SELECT_SOLUTION_GROUP}/>
                        </Label>
                        <Select isDisabled={isLoading || isLoadingSolutionGroup}
                                options={optionsSGs}
                                value={optionsSGs.find(sg => sg.value === get(solutionGroup, 'id'))}
                                placeholder={formatMessage(modelAnswersMessages.SELECT_SOLUTION_GROUP)}
                                onChange={handleSolutionGroupSelection}/>
                    </div>
                </div>
                {get(solutionGroup, 'isDefault') && <div className="form-row mb-sm-3">
                    <div className="col">
                        <Label>
                            <FormattedMessage {...authManagementMessages.EDITOR_SELECT_COUNTRIES}/>
                        </Label>
                        <Select isMulti
                                isDisabled={isLoading || isLoadingSolutionGroup}
                                value={countryGroups.map(countryIso => ({
                                    value: countryIso,
                                    label: countryGroupMessages[countryIso] ? formatMessage(countryGroupMessages[countryIso]) : countryIso
                                }))}
                                options={get(solutionGroup, 'groups', {default: []}).map(countryIso => ({
                                    value: countryIso,
                                    label: countryGroupMessages[countryIso] ? formatMessage(countryGroupMessages[countryIso]) : countryIso
                                }))}
                                placeholder={formatMessage(authManagementMessages.EDITOR_PLACEHOLDER_SELECT_COUNTRIES)}
                                onChange={handleCountryGroupSelection}/>
                    </div>
                </div>}
                {!!get(solutionGroup, 'id') && <div className="form-row mb-sm-2">
                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
                        <Label>
                            <FormattedMessage {...modelAnswersMessages.MODEL_ANSWERS}/>
                        </Label>
                        <Button type="button" className="btn btn-sm ml-2 mb-sm-1"
                                disabled={isLoading || isLoadingSolutionGroup}
                                onClick={() => setModelAnswerDialog({
                                    isOpen: true,
                                    initValues: {groups: get(solutionGroup, 'groups', {default: []})}
                                })}>
                            <FormattedMessage {...buttonMessages.ADD}/>
                        </Button>
                    </div>
                </div>}
            </StyledForm>
            {!!get(solutionGroup, 'id') && <>
                <ModelAnswersTable domain={domain}
                                   handleEditButton={setModelAnswerDialog}
                                   handleDeleteButton={setConfirmationDialog}
                                   handleTableSettings={setTableSettings}
                                   defaultSettings={defaultTableSettings}
                />
                <ModelAnswerDialog domain={domain}
                                   show={modelAnswerDialog.isOpen}
                                   closeModal={() => setModelAnswerDialog({isOpen: false})}
                                   saveModal={saveModelAnswerDialog}
                                   initialValues={{
                                       ...modelAnswerDialog.initValues
                                   }}
                />
                <ConfirmationDialog show={confirmationDialog.isOpen}
                                    closeModal={() => setConfirmationDialog({isOpen: false})}
                                    saveModal={saveConfirmationDialog}
                                    actionIdentifier={modelAnswersMessages.TABLE_MODAL_REMOVE}/>
            </>
            }
        </div>
    );
}

ModelAnswersPage.propTypes = {
    roles: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    solutionGroup: PropTypes.string.isRequired,
    countryGroups: PropTypes.array.isRequired,
    setCountryGroups: PropTypes.func.isRequired,
    fetchModelAnswers: PropTypes.func.isRequired,
    fetchFullSolutionGroupInfo: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
    solutionGroup: state.adminModelAnswers.fullSolutionGroupInfo,
    countryGroups: state.adminModelAnswers.countryGroups,
    isLoading: state.adminModelAnswers.isLoading,
    isLoadingSolutionGroup: state.adminModelAnswers.isLoadingSolutionGroup,
});

export default connect(mapStateToProps, {
    setCountryGroups,
    fetchModelAnswers,
    createModelAnswer,
    updateModelAnswer,
    deleteModelAnswer,
    resetModelAnswers,
    fetchFullSolutionGroupInfo,
})(injectIntl(ModelAnswersPage));
