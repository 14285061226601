import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {FieldArray, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {
    downloadInternalTicketAttachment,
} from '../../../../../../actions/orders/actionSparePartsInternalTicketAttachments';
import {
    FORM_ATTACHMENTS_SECTION,
    FORM_FIELD_ATTACHMENTS,
    FORM_FIELD_NEW_ATTACHMENTS,
    FORM_TICKET_SECTION
} from '../../../../../../constants/formConstants';
import {validTicketFiles} from '../../../../../common/validationCommons';
import RenderDropZone from '../../../../../common/RenderDropZone';
import UploadedAttachments from './UploadedAttachments';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const AttachmentSection = ({attachments = [], userRights, readOnly, form}) => {
    return (
        <>
            {((userRights.canAddAttach && !readOnly) || (attachments && attachments.length > 0)) && <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.ATTACHMENTS_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {(attachments && attachments.length > 0) &&
                        <UploadedAttachments attachments={attachments} label={formMessages.EDITOR_ATTACHMENTS} form={form} userRights={userRights}/>
                    }
                    {userRights.canAddAttach && !readOnly && <FieldArray
                        name={FORM_FIELD_NEW_ATTACHMENTS}
                        component={RenderDropZone}
                        validate={validTicketFiles}/>
                    }
                </StyledDiv>
            </>
            }
        </>
    )
}

const mapStateToProps = (state, initialProps) => ({
    attachments: formValueSelector(initialProps.form)(state, `${FORM_ATTACHMENTS_SECTION}.${FORM_FIELD_ATTACHMENTS}`),
    ticketId: formValueSelector(initialProps.form)(state, `${FORM_TICKET_SECTION}.uuid`),
    roles: state.profile.userDetail.roles,
});

export default connect(mapStateToProps, {
    downloadInternalTicketAttachment,
})(AttachmentSection);
