import {put, takeEvery, select, delay} from 'redux-saga/effects';
import {loadFile, uploadRecurrentProblemFile} from '../api/restFile';
import {errorAlert} from '../actions/alertsActions';
import {extractErrorText} from '../actions/actionUtils';
import {sparePartsWebsocketCommands} from '../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../constants/claimsWebsocketCommands';
import FileSaver from 'file-saver';
import alertMessages from '../intl/common/alertMessages';
import {FILE_UPLOAD_STATUS} from '../actions/actionFile';

function* handleUploadRecurrentProblemFile(action) {
    try {
        const fileToUpload = yield select(state => state.mainFiles.attachmentsToBeUploaded.get(action.payload.attachment.attachmentId));
        yield uploadRecurrentProblemFile(action.payload.attachment.url, fileToUpload.localFileUrl, fileToUpload.contentType);
        window.URL.revokeObjectURL(fileToUpload.localFileUrl);
        yield put({
            type: FILE_UPLOAD_STATUS,
            payload: {uuid: action.payload.attachment.attachmentId, status: 'DONE'}
        });
    } catch (e) {
        yield put(errorAlert(...extractErrorText(e, alertMessages.FILE_UPLOAD_ERROR)));
    }
}

function* handleDownloadRecurrentProblemFile(action) {
    try {
        const file = yield loadFile(action.payload.attachment.url);
        const fileToDownload = yield select(state => state.mainFiles.attachmentsToBeDownloaded.get(action.payload.attachment.attachmentId));
        FileSaver.saveAs(file.data, fileToDownload.name);
    } catch (e) {
        yield put(errorAlert(...extractErrorText(e, alertMessages.FILE_DOWNLOAD_ERROR)));
    }
}

function* recurrentProblemFileSaga() {
    yield takeEvery(techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD, handleUploadRecurrentProblemFile);
    yield takeEvery(techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD, handleDownloadRecurrentProblemFile);
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD, handleUploadRecurrentProblemFile);
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD, handleDownloadRecurrentProblemFile);
    yield takeEvery(claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD, handleUploadRecurrentProblemFile);
    yield takeEvery(claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD, handleDownloadRecurrentProblemFile);
}

export default recurrentProblemFileSaga;
