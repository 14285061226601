import React from 'react';
import {reduxForm, FormSection} from 'redux-form';
import SubmitButtons from './sections/SubmitButtons';
import {
    FORM_ATTACHMENTS_SECTION,
    FORM_TICKET_SECTION,
    FORM_ORDERS_SECTION,
    FORM_MESSAGES_SECTION,
} from '../../../../constants/formConstants';
import TicketStatusHistory from '../../../common/TicketStatusHistory';
import TicketSection from './sections/ticket/TicketSection';
import {scrollContentWrapperToTop} from '../../../../utils/utils';
import OrderItemsTroubleshootSection from './sections/orders/OrderItemsTroubleshootSection';
import AttachmentSection from './sections/attachments/AttachmentSection';
import PropTypes from 'prop-types';
import BorderRadius from '../../../common/styled/BorderRadius';
import MessagesSection from './sections/messages/MessagesSection';
import { claimsFieldLabelMapping } from '../../../../constants/claimsFieldLabelMapping';
import formMessages from '../../../../intl/claims/formMessages';
import RenderSyncErrors from '../../../common/RenderSyncErrors';


const TroubleshootMasterForm = (props) => {
    const {form, userRights, reqFields, readOnly, handleSubmit, editMode} = props;

    return (
        <BorderRadius size={10} className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form} intlMessages={formMessages} mapping={claimsFieldLabelMapping}/>
            <FormSection name={FORM_TICKET_SECTION}>
                <TicketSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_ORDERS_SECTION}>
                <OrderItemsTroubleshootSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_MESSAGES_SECTION}>
                <MessagesSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_ATTACHMENTS_SECTION}>
                <AttachmentSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <TicketStatusHistory form={form}/>
            <SubmitButtons form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}
                           onSubmit={handleSubmit} onSubmitFail={scrollContentWrapperToTop} editMode={editMode}/>
        </BorderRadius>
    )
};

TroubleshootMasterForm.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    editMode: PropTypes.any,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
    dicInfo: PropTypes.object,
};

export default reduxForm({})(TroubleshootMasterForm);
