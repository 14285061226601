import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const TECH_DOC_INTERNAL_TICKET_LIST_FETCH_PENDING = 'TECH_DOC_INTERNAL_TICKET_LIST_FETCH_PENDING';

export const fetchTechDocInternalTicketListPageData = (pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    dispatch({
        type: TECH_DOC_INTERNAL_TICKET_LIST_FETCH_PENDING,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_INTERNAL_TICKET_LIST_GET,
        payload: {pageSize: pageSize, sorted: sorted, cursor, isNextFromPivot, filtered: filtered}
    });
};
