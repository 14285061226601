import get from 'get-value';
import {
    MAIN_MESSAGE_FETCH_PENDING,
    MAIN_MESSAGE_SAVE_PENDING,
    MAIN_MESSAGES_SELECT_GROUP,
    SPARE_PARTS_MAIN_MESSAGES_FETCH_PENDING,
    TECH_DOC_MAIN_MESSAGES_FETCH_PENDING,
    CLAIMS_MAIN_MESSAGES_FETCH_PENDING,
} from '../../actions/admin/actionAdminMainMessage';
import {domains} from '../../constants/Utils';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const adminMainMessage = (state = {
    isLoading: false,
    isLoadingSpareParts: false,
    isLoadingTechDoc: false,
    isLoadingClaims: false,
    mainMessage: {},
    mainMessages: {},
    selectedCountryGroup: ''
}, action) => {
    switch (action.type) {
        case MAIN_MESSAGES_SELECT_GROUP: {
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        }
        case MAIN_MESSAGE_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true, mainMessage: {}});
        }
        case MAIN_MESSAGE_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true, mainMessage: {}});
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MAIN_MESSAGES_SEND:
        case techDocWebsocketCommands.TECH_DOC_MAIN_MESSAGES_SEND:
        case claimsWebsocketCommands.CLAIMS_MAIN_MESSAGES_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                mainMessage: get(action, 'payload.mainMessage', {default: {}}),
                correlationId
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MAIN_MESSAGES_ERROR:
        case techDocWebsocketCommands.TECH_DOC_MAIN_MESSAGES_ERROR:
        case claimsWebsocketCommands.CLAIMS_MAIN_MESSAGES_ERROR:
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MAIN_MESSAGES_SEND_ERROR:
        case techDocWebsocketCommands.TECH_DOC_MAIN_MESSAGES_SEND_ERROR:
        case claimsWebsocketCommands.CLAIMS_MAIN_MESSAGES_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, mainMessage: {}, correlationId});
        }

        case SPARE_PARTS_MAIN_MESSAGES_FETCH_PENDING: {
            return Object.assign({}, state, {isLoadingSpareParts: true});
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_GROUPS_MAIN_MESSAGES_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.spCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoadingSpareParts: false,
                mainMessages: {
                    ...state.mainMessages,
                    [domains.SPARE_PARTS]: get(action, 'payload.mainMessages', {default: []})
                },
                spCorrelationId: correlationId
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_GROUPS_MAIN_MESSAGES_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.spCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoadingSpareParts: false,
                mainMessages: {
                    ...state.mainMessages,
                    [domains.SPARE_PARTS]: []
                },
                spCorrelationId: correlationId
            });
        }
        case TECH_DOC_MAIN_MESSAGES_FETCH_PENDING: {
            return Object.assign({}, state, {isLoadingTechDoc: true});
        }
        case techDocWebsocketCommands.TECH_DOC_GROUPS_MAIN_MESSAGES_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.tdCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoadingTechDoc: false,
                mainMessages: {
                    ...state.mainMessages,
                    [domains.TECHNICAL_DOCUMENTATION]: get(action, 'payload.mainMessages', {default: []})
                },
                tdCorrelationId: correlationId
            });
        }
        case techDocWebsocketCommands.TECH_DOC_GROUPS_MAIN_MESSAGES_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.tdCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoadingTechDoc: false,
                mainMessages: {
                    ...state.mainMessages,
                    [domains.TECHNICAL_DOCUMENTATION]: []
                },
                tdCorrelationId: correlationId
            });
        }
        case CLAIMS_MAIN_MESSAGES_FETCH_PENDING: {
            return Object.assign({}, state, {isLoadingClaims: true});
        }
        case claimsWebsocketCommands.CLAIMS_GROUPS_MAIN_MESSAGES_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.clCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoadingClaims: false,
                mainMessages: {
                    ...state.mainMessages,
                    [domains.CLAIMS]: get(action, 'payload.mainMessages', {default: []})
                },
                clCorrelationId: correlationId
            });
        }
        case claimsWebsocketCommands.CLAIMS_GROUPS_MAIN_MESSAGES_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.clCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoadingClaims: false,
                mainMessages: {
                    ...state.mainMessages,
                    [domains.CLAIMS]: []
                },
                clCorrelationId: correlationId
            });
        }
        default: {
            return state;
        }
    }
};
