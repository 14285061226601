import {defineMessages} from 'react-intl';

const escalationLevelMessages = defineMessages({
    TITLE: {
        id: 'admin.escalationLevel.title',
        defaultMessage: 'Escalation level'
    },
    ESCALATION_LEVEL: {
        id: 'admin.escalationLevel.escalationLevel',
        defaultMessage: 'Escalation level'
    },
    MODAL_TITLE: {
        id: 'admin.escalationLevel.modal.title',
        defaultMessage: 'Add New Escalation Level'
    },
   ESCALATION_LEVEL_NAME: {
        id: 'admin.escalationLevel.escalationLevelName',
        defaultMessage: 'Name'
    },
    EMPTY: {
        id: 'admin.escalationLevel.empty',
        defaultMessage: 'No Escalation Levels'
    },

});

export default escalationLevelMessages;
