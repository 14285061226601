import {domains} from '../../constants/Utils';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const ADMIN_SECOND_HAND_BATTERY_TYPE_SELECT_GROUP = 'ADMIN_SECOND_HAND_BATTERY_TYPE_SELECT_GROUP';
export const ADMIN_SECOND_HAND_BATTERY_TYPE_FETCH = 'ADMIN_SECOND_HAND_BATTERY_TYPE_FETCH';
export const ADMIN_SECOND_HAND_BATTERY_TYPE_ADD = 'ADMIN_SECOND_HAND_BATTERY_TYPE_ADD';
export const ADMIN_SECOND_HAND_BATTERY_TYPE_EDIT = 'ADMIN_SECOND_HAND_BATTERY_TYPE_EDIT';
export const ADMIN_SECOND_HAND_BATTERY_TYPE_UPDATE = 'ADMIN_SECOND_HAND_BATTERY_TYPE_UPDATE';
export const ADMIN_SECOND_HAND_BATTERY_TYPE_MOVE = 'ADMIN_SECOND_HAND_BATTERY_TYPE_MOVE';
export const ADMIN_SECOND_HAND_BATTERY_TYPE_SWITCH = 'ADMIN_SECOND_HAND_BATTERY_TYPE_SWITCH';
export const ADMIN_SECOND_HAND_BATTERY_TYPE_REMOVE = 'ADMIN_SECOND_HAND_BATTERY_TYPE_REMOVE';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_SECOND_HAND_BATTERY_TYPE_SELECT_GROUP,
        payload: countryGroup
    });

export const fetchSecondHandBatteryTypes = (domain, group) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_SECOND_HAND_BATTERY_TYPE_FETCH,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPE_GET,
                payload: {group}
            });
        }
    }
};

export const addSecondHandBatteryType = (domain, secondHandBatteryType) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_SECOND_HAND_BATTERY_TYPE_ADD,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPE_CREATE,
                payload: {secondHandBatteryType}
            });
        }
    }
};

export const editSecondHandBatteryType = index => dispatch => {
    dispatch({
        type: ADMIN_SECOND_HAND_BATTERY_TYPE_EDIT,
        payload: {index}
    });
};

export const updateSecondHandBatteryType = (domain, secondHandBatteryType) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_SECOND_HAND_BATTERY_TYPE_UPDATE,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPE_UPDATE,
                payload: {secondHandBatteryType}
            });
        }
    }
};

export const moveSecondHandBatteryType = (domain, secondHandBatteryType, oldIndex) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            dispatch({
                type: ADMIN_SECOND_HAND_BATTERY_TYPE_SWITCH,
                payload: {oldIndex, newIndex: secondHandBatteryType.position, secondHandBatteryType}
            });
            return dispatch({
                type: ADMIN_SECOND_HAND_BATTERY_TYPE_MOVE,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPE_MOVE,
                payload: {secondHandBatteryType}
            });
        }
    }
};

export const removeSecondHandBatteryType = (domain, secondHandBatteryType) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_SECOND_HAND_BATTERY_TYPE_REMOVE,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPE_DELETE,
                payload: {secondHandBatteryType}
            });
        }

    }
};


