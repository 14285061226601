import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import {required, validInput} from './validationCommons';
import {RequiredSpan} from './StyledComponents';

const RadioFields = (props) => {
    const {
        label, field, style, intlMessages, options, isRequired, isDisabled, handleChange, disabledStyle
    } = props;

    let newValidations = [];

    if (isRequired) {
        newValidations.push(required);
    }

    return (
        <div>
            {!!label &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label} />
                {isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
            </label>}
            {options.map((item, index) => (
                <div key={index}>
                    <Field
                        name={field}
                        component="input"
                        type="radio"
                        value={item}
                        style={style}
                        disabled={isDisabled}
                        validate={newValidations}
                        onChange={handleChange ? handleChange : undefined}
                    />
                    {isDisabled ?
                    <span style={disabledStyle}>
                        <FormattedMessage {...intlMessages[item]}/>
                    </span>
                        :
                    <FormattedMessage {...intlMessages[item]}/>
                    }
                </div>
            ))}
        </div>
    )
};
export default RadioFields;

// style={{color:'#DCDCDC'}}

