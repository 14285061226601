import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import {Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    fetchSolutionGroup, redirectReset,
    resetSolutionGroup,
    updateSolutionGroup
} from '../../../../actions/admin/actionSolutionGroup';
import Loader from '../../../Loader';
import { SOLUTION_GROUP_FORM, } from '../../../../constants/formConstants';
import SolutionGroupForm from './forms/SolutionGroupForm';
import {
    initLoadedSolutionGroup,
    transformUpdateSolutionGroupForSave
} from '../../../../utils/solutionGroupUtils';
import { editReqFields } from '../../../../constants/solutionGroupRequiredFields';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {FormattedMessage, injectIntl} from 'react-intl';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import {getAdminSGPath} from '../../../../routes/pathResolver';


class EditSolutionGroupPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetSolutionGroup(this.props.domain);
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchSolutionGroup(this.props.domain, this.props.match.params.solutionGroupUuid, this.props.loggedUser.ipn);
        }
    }

    handleSubmit = (values) => {
        const dataForSend = transformUpdateSolutionGroupForSave(values, this.props.domain);
        this.props.updateSolutionGroup(this.props.domain, dataForSend);
    };

    componentWillUnmount() {
        this.props.redirectReset();
    }

    render() {
        const {
            solutionGroupData,
            domain,
            intl: {formatMessage},
        } = this.props;

        let formType = false;
        if (get(solutionGroupData, 'solutionGroup.name', { default: false })) {
            formType = SOLUTION_GROUP_FORM;
        }

        if (solutionGroupData.solutionGroupSubmittedAndShouldRedirect && !solutionGroupData.isSolutionGroupCreating && !solutionGroupData.isSolutionGroupLoading && get(solutionGroupData, 'solutionGroup.id', { default: false })) {
            return <Redirect to={{
                pathname: `${getAdminSGPath(this.props.domain)}/view/${solutionGroupData.solutionGroup.id}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (
            solutionGroupData.isSolutionGroupLoading
            || solutionGroupData.isSolutionGroupCreating
            || !formType
        ) {
            return <Loader/>;
        }

        let initializationValue
        if(!solutionGroupData.isSolutionGroupCreating && !solutionGroupData.isSolutionGroupLoading && !solutionGroupData.solutionGroupSubmittedAndShouldRedirect && solutionGroupData.initWithOldData){
            initializationValue = initLoadedSolutionGroup(solutionGroupData, formatMessage);
        } else {
            initializationValue = initLoadedSolutionGroup(solutionGroupData, formatMessage);
        }

        const requiredFields = editReqFields['DEFAULT']['EDITOR'][formType];
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.solutionGroup.title"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - Solution Group"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <div className="container-fluid">
                    <div className="col-12">
                        <SolutionGroupForm
                            form={formType}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            domain={domain}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

EditSolutionGroupPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    resetSolutionGroup: PropTypes.func.isRequired,
    fetchSolutionGroup: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateSolutionGroup: PropTypes.func.isRequired,
    intl: PropTypes.object,
    solutionGroupData: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    location: PropTypes.object,
};


const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    solutionGroupData: state.adminSolutionGroup,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchSolutionGroup,
    updateSolutionGroup,
    resetSolutionGroup,
    redirectReset,
})(injectIntl(EditSolutionGroupPage))), ['TD_POWER_USER', 'SP_POWER_USER', 'TD_SG_ADMIN', 'SP_SG_ADMIN']);
