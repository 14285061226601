import React from 'react';
import {
    FORM_FIELD_PROD, FORM_FIELD_SUBJECT_TO_VOR,
    FORM_LABEL_DESCRIPTION,
    FORM_LABEL_LINE,
    FORM_LABEL_ORDER,
    FORM_LABEL_PART_NO,
    FORM_LABEL_PROD,
    FORM_LABEL_QUANTITY,
    FORM_LABEL_SUBJECT_TO_VOR,
} from "../../../../../../constants/formConstants";
import {
    StyledSpan,
    StyledTh,
    StyledThead,
} from "../../../../../common/StyledComponents";
import ValidFormattedMessage from "../../../../../common/ValidFormattedMessage";
import formMessages from "../../../../../../intl/claims/formMessages";
import PropTypes from 'prop-types';
import { change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { PROVIDER, RECEIVER } from '../../../../../../constants/Utils';

const OrdersTroubleshootLowerTableHeader = (props) => {
    const { userRights, readOnly, reqFields, formValues } = props;
    const userStatus = formValues.ordersSection.userStatus

    return (
        <StyledThead>
            <tr>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_PROD} intlMessages={formMessages}/>
                    {userRights.canEditOrder && !readOnly && userStatus === RECEIVER && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_ORDER} intlMessages={formMessages}/>
                    {!readOnly && userRights.canEditOrder && userStatus === RECEIVER && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_LINE} intlMessages={formMessages}/>
                    {!readOnly && userRights.canEditOrder && userStatus === RECEIVER && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_PART_NO} intlMessages={formMessages}/>
                    {!readOnly && userRights.canEditOrder && (userStatus === RECEIVER || userStatus === PROVIDER) && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_SUBJECT_TO_VOR} intlMessages={formMessages}/>
                    {userRights.canEditOrder && reqFields.includes(FORM_FIELD_SUBJECT_TO_VOR) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_DESCRIPTION} intlMessages={formMessages}/>
                    {!readOnly && userRights.canEditOrder && (userStatus === RECEIVER || userStatus === PROVIDER) && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_QUANTITY} intlMessages={formMessages}/>
                    {!readOnly && userRights.canEditOrder && (userStatus === RECEIVER || userStatus === PROVIDER) && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
            </tr>
        </StyledThead>
    )
}


OrdersTroubleshootLowerTableHeader.propTypes = {
    readOnly: PropTypes.bool,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
};

const mapStateToProps = (state, props) => ({
    formValues: getFormValues(props.form)(state)
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTroubleshootLowerTableHeader);
