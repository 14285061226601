import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {injectIntl} from 'react-intl';

const InputWithFullWidth = styled.input`
    width: 100%;
`;

class DatePickerInput extends Component {
    render() {
        const {onClick, value = '', filter = {}, intl: {formatDate}, className, disabled} = this.props;
        return <InputWithFullWidth type="text" onKeyPress={e => e.preventDefault()}
                                   value={value.length > 0 && filter.value ? formatDate(new Date(filter.value)) : value}
                                   className={className}
                                   disabled={disabled}
                                   onClick={onClick}/>;
    }
}

DatePickerInput.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
    filter: PropTypes.object,
    formatDate: PropTypes.func,
    className: PropTypes.any,
    disabled: PropTypes.any
};

export default injectIntl(DatePickerInput);
