import {countryGroups, domains, getFormTypes, getCurrentFormTypes} from '../../constants/Utils';
import Joi from 'joi';
import {SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM} from '../../constants/formConstants';
import get from 'get-value';

const validateCountry = roles => {
    let newRoles = roles;
    if (roles) {
        if (roles.SPARE_PARTS) {
            if (roles.SPARE_PARTS.admin) {
                if (roles.SPARE_PARTS.admin.groupPermission) {
                    Object.keys(countryGroups).map(e => Object.keys(roles.SPARE_PARTS.admin.groupPermission)
                        .filter(i => roles.SPARE_PARTS.admin.groupPermission[i] === true).includes(e) ?
                        newRoles.SPARE_PARTS.admin.groupPermission[countryGroups[e]] = true :
                        newRoles.SPARE_PARTS.admin.groupPermission[countryGroups[e]] = false)
                }
            }
            if (roles.SPARE_PARTS.editor) {
                if (roles.SPARE_PARTS.editor.groupPermission) {
                    Object.keys(countryGroups).map(e => Object.keys(roles.SPARE_PARTS.editor.groupPermission)
                        .filter(i => roles.SPARE_PARTS.editor.groupPermission[i] === true).includes(e) ?
                        newRoles.SPARE_PARTS.editor.groupPermission[countryGroups[e]] = true :
                        newRoles.SPARE_PARTS.editor.groupPermission[countryGroups[e]] = false)
                }
            }
        }
        if (roles.TECHNICAL_DOCUMENTATION) {
            if (roles.TECHNICAL_DOCUMENTATION.admin) {
                if (roles.TECHNICAL_DOCUMENTATION.admin.groupPermission) {
                    Object.keys(countryGroups).map(e => Object.keys(roles.TECHNICAL_DOCUMENTATION.admin.groupPermission)
                        .filter(i => roles.TECHNICAL_DOCUMENTATION.admin.groupPermission[i] === true).includes(e) ?
                        newRoles.TECHNICAL_DOCUMENTATION.admin.groupPermission[countryGroups[e]] = true :
                        newRoles.TECHNICAL_DOCUMENTATION.admin.groupPermission[countryGroups[e]] = false)
                }
            }
            if (roles.TECHNICAL_DOCUMENTATION.editor) {
                if (roles.TECHNICAL_DOCUMENTATION.editor.groupPermission) {
                    Object.keys(countryGroups).map(e => Object.keys(roles.TECHNICAL_DOCUMENTATION.editor.groupPermission)
                        .filter(i => roles.TECHNICAL_DOCUMENTATION.editor.groupPermission[i] === true).includes(e) ?
                        newRoles.TECHNICAL_DOCUMENTATION.editor.groupPermission[countryGroups[e]] = true :
                        newRoles.TECHNICAL_DOCUMENTATION.editor.groupPermission[countryGroups[e]] = false)
                }
            }
        }
        if (roles.CLAIMS) {
            if (roles.CLAIMS.admin) {
                if (roles.CLAIMS.admin.groupPermission) {
                    Object.keys(countryGroups).map(e => Object.keys(roles.CLAIMS.admin.groupPermission)
                        .filter(i => roles.CLAIMS.admin.groupPermission[i] === true).includes(e) ?
                        newRoles.CLAIMS.admin.groupPermission[countryGroups[e]] = true :
                        newRoles.CLAIMS.admin.groupPermission[countryGroups[e]] = false)
                }
            }
            if (roles.CLAIMS.editor) {
                if (roles.CLAIMS.editor.groupPermission) {
                    Object.keys(countryGroups).map(e => Object.keys(roles.CLAIMS.editor.groupPermission)
                        .filter(i => roles.CLAIMS.editor.groupPermission[i] === true).includes(e) ?
                        newRoles.CLAIMS.editor.groupPermission[countryGroups[e]] = true :
                        newRoles.CLAIMS.editor.groupPermission[countryGroups[e]] = false)
                }
            }
        }
    }
    return newRoles;
}

const validateCountryRights = data => {
    let newData = data;
    Object.keys(countryGroups).map(e => Object.keys(data).filter(i => data[i] === true).includes(e) ?
        newData[countryGroups[e]] = true :
        newData[countryGroups[e]] = false)
    return newData;
}

export function validateUserDetail(userDetail) {
    if (userDetail) validateCountry(get(userDetail, 'roles', {}))
    return Joi.validate(
        userDetail,
        Joi.object().required().keys({
            ipn: Joi.string().required(),
            name: Joi.string().required(),
            roles: Joi.object().required().keys(getRolesSchema()),
            settings: Joi.object().keys(getSettingsSchema()),
            dealerName: Joi.any(),
            dealerNumber: Joi.any(),
            telNo: Joi.any(),
            group: Joi.any(),
            mail: Joi.string().required(),
            charSets: Joi.array().required()
        })
    )
}

export function validateAdminRoles(adminRoles) {
    if (adminRoles) validateCountryRights(adminRoles)
    return Joi.validate(
        adminRoles,
        Joi.object().required().keys(getCountryGroupsSchema())
    );
}

export function validateEditorData(editorRoles) {
    if (editorRoles) validateCountryRights(editorRoles)
    return Joi.validate(
        editorRoles,
        Joi.object().required().keys(getCountryGroupsSchema())
    );
}

export function validateSolutionGroupEditorData(solutionGroupEditorRoles) {
    return Joi.validate(
        solutionGroupEditorRoles,
        Joi.array()
    );
}

export function validateDealerRolesForSpareParts(dealerRoles) {
    return Joi.validate(
        dealerRoles,
        Joi.object().required().keys(getSparePartsFormsSchema())
    );
}

export function validateDealerRolesForTechnicalDocumentation(dealerRoles) {
    return Joi.validate(
        dealerRoles,
        Joi.object().required().keys(getTechnicalDocumentationFormsSchema())
    );
}

export function validateDealerRolesForClaims(dealerRoles) {
    return Joi.validate(
        dealerRoles,
        Joi.object().required().keys(getClaimsFormsSchema())
    );
}

export const validateDealerRoles = (domain, dealerData) => {
    if (domain === domains.SPARE_PARTS) {
        return validateDealerRolesForSpareParts(dealerData);
    }
    if (domain === domains.TECHNICAL_DOCUMENTATION) {
        return validateDealerRolesForTechnicalDocumentation(dealerData);
    }
    if (domain === domains.CLAIMS) {
        return validateDealerRolesForClaims(dealerData);
    }
    return {error: 'unknown domain'};
}

function getRolesSchema() {
    const result = {};
    result[domains.GENERIC_TICKET] = Joi.object().keys({
        admin: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        dealer: Joi.object(),
        editor: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        powerUser: Joi.object().max(0)
    });
    result[domains.SPARE_PARTS] = Joi.object().keys({
        admin: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        dealer: Joi.object().keys({
            formsPermission: Joi.object().keys(getSparePartsFormsSchema())
        }),
        editor: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        sgAdmin: Joi.object().keys({
            sgPermission: Joi.array(),
        }),
        sgEditor: Joi.object().keys({
            sgPermission: Joi.array(),
        }),
        powerUser: Joi.object().max(0)
    });
    // ASE MODULE NO LONGER IN USE, LEFTOVER ROLE
    result[domains.ASE] = Joi.object().unknown(true);

    result[domains.TECHNICAL_DOCUMENTATION] = Joi.object().keys({
        admin: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        dealer: Joi.object().keys({
            formsPermission: Joi.object().keys(getTechnicalDocumentationFormsSchema())
        }),
        editor: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        sgAdmin: Joi.object().keys({
            sgPermission: Joi.array(),
        }),
        sgEditor: Joi.object().keys({
            sgPermission: Joi.array(),
        }),
        powerUser: Joi.object().max(0)
    });
    result[domains.CLAIMS] = Joi.object().keys({
        admin: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        dealer: Joi.object().keys({
            formsPermission: Joi.object().keys(getClaimsFormsSchema())
        }),
        editor: Joi.object().keys({
            groupPermission: Joi.object().keys(getCountryGroupsSchema())
        }),
        powerUser: Joi.object().max(0)
    });
    return result;
}

function getSparePartsFormsSchema() {
    const result = {};
    const formTypes = getFormTypes(domains.SPARE_PARTS);
    formTypes.forEach(formType => result[formType] = Joi.boolean());
    return result;
}

function getTechnicalDocumentationFormsSchema() {
    const result = {};
    const formTypes = getCurrentFormTypes(domains.TECHNICAL_DOCUMENTATION);
    formTypes.forEach(formType => result[formType] = Joi.boolean().required());
    return result;
}

function getClaimsFormsSchema() {
    const result = {};
    const formTypes = getFormTypes(domains.CLAIMS);
    formTypes.forEach(formType => result[formType] = Joi.boolean());
    return result;
}

function getCountryGroupsSchema() {
    const result = {};
    Object.keys(countryGroups).forEach(countryGroup => result[countryGroups[countryGroup]] = Joi.boolean().required());
    return result;
}

export function getSettingsSchema() {
    return {
        language: Joi.string(),
        locale: Joi.string(),
    };
}
