import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import get from 'get-value';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const adminEscalationSchemaList = (state = {
    correlationId: null,
    isLoading: false,
    isTableLoading: false,
    isEscalationSchemaListLoading: false,
    listData: [],
    shortListData: [],
    pages: 0,
}, action) => {
    switch (action.type) {
        case techDocWebsocketCommands.TECH_DOC_ESCALATION_SCHEMA_LIST_FETCH_PENDING:
        case sparePartsWebsocketCommands.SPARE_PARTS_ESCALATION_SCHEMA_LIST_FETCH_PENDING: {
            return Object.assign({}, state, { isTableLoading: true });
        }
        case techDocWebsocketCommands.TECH_DOC_ESCALATION_SCHEMA_LIST_SEND:
        case sparePartsWebsocketCommands.SPARE_PARTS_ESCALATION_SCHEMA_LIST_SEND: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isTableLoading: false,
                    listData: get(action, 'payload.result.data', { default: [] }),
                    pages: get(action, 'payload.result.pages', { default: 0 }),
                    correlationId
                });
            }
        }
        case techDocWebsocketCommands.TECH_DOC_ESCALATION_SCHEMA_LIST_SEND_ERROR:
        case sparePartsWebsocketCommands.SPARE_PARTS_ESCALATION_SCHEMA_LIST_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, { isTableLoading: false, correlationId });
            }
        }
        default:
            return state;
    }
};
