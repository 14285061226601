import React from 'react';
import {FormattedMessage} from "react-intl";
import {Field, Fields} from "redux-form";
import {FieldTicketNumber} from './FieldTicketNumber';
import {minLength, validInput} from './validationCommons';
import styled from 'styled-components';

const minLength2 = minLength(2);
const minLength6 = minLength(6);

const InvisibleField = styled(Field)`
  display: none;
`;

const InputFieldTicketNumber = (props) => {
    const {
        label, fields, validation
    } = props;

    return (
        <div>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label}/>
            </label>}
            <InvisibleField name={fields[0]} component="input" type="text" validate={validation ? [validInput, minLength2] : []}/>
            <InvisibleField name={fields[1]} component="input" type="text" validate={validation ? [validInput, minLength6] : []}/>
            <Fields
                names={fields}
                component={FieldTicketNumber}
                isSmall
            />
        </div>
    );
};
export default InputFieldTicketNumber;
