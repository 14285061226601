import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {
    fetchSettingClientIdData, setSorting, setTextSearch,
    setLinesPerPage, deleteClientIdRecord, createClientIdRecord
} from '../../../actions/actionClientId';
import Pagination from '../../common/Pagination';
import ClientIdRecord from './ClientIdRecord';
import Sorter from '../../common/Sorter';
import Loader from '../../Loader';
import InputPerPageForm from './forms/InputPerPageForm';
import InputFullTextForm from './forms/InputFullTextForm';
import {FormattedMessage} from 'react-intl';
import ConfirmationDialog from '../../common/modal/ConfirmationDialog';
import AddClientIdModal from './AddClientIdModal';
import clientIdMessages from '../../../intl/admin/clientIdMessages';
import {CLIENT_ID_FIELD_MAX_LENGTH} from '../../../constants/validationConstants';

const Table = styled.table`
  width: 100%;
  margin: 0 auto;
  table-layout: auto;
  border-bottom: solid 1px #eee;
  padding: 0 10px;
`;

const TBody = styled.tbody`
  border: 0;
`;

const THead = styled.thead`
  border: 0;
  border-bottom: none;
`;

class ClientIdContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddModalOpen: false,
            isDeleteModalOpen: false,
            toBeDuplicatedClientIdData: {},
            toBeDeletedClientIdData: {},
            toBeDeletedRowIndex: -1
        };
    }

    componentDidMount() {
        const {linesPerPage, selectedGroup} = this.props;
        this.props.fetchSettingClientIdData(selectedGroup, 1, linesPerPage);
    }

    loadPage = (page) => {
        const {linesPerPage, selectedGroup} = this.props;
        this.props.fetchSettingClientIdData(selectedGroup, page, linesPerPage);
    }

    handleOrderingSubmit = (column) => {
        const {currentPage, linesPerPage, selectedGroup} = this.props;
        this.props.setSorting(column);
        this.props.fetchSettingClientIdData(selectedGroup, currentPage, linesPerPage);
    }

    handleLinesPerPageSubmit = (values) => {
        const {selectedGroup} = this.props;
        this.props.setLinesPerPage(values.linesPerPage);
        this.props.fetchSettingClientIdData(selectedGroup, 1, values.linesPerPage);
    }

    handleFulltextSubmit = (values) => {
        const {linesPerPage, selectedGroup} = this.props;
        this.props.setTextSearch(values.fulltext);
        this.props.fetchSettingClientIdData(selectedGroup, 1, linesPerPage);
    }

    closeDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: false
        });
    }

    saveDeleteModal = () => {
        const {toBeDeletedClientIdData: {bir8, uuid}} = this.state;
        const {selectedGroup} = this.props;
        this.setState({
            isDeleteModalOpen: false
        });
        this.props.deleteClientIdRecord(selectedGroup, bir8, uuid, this.state.toBeDeletedRowIndex);
    };

    handleDeleteClick = (clientId, index) => {
        this.setState({isDeleteModalOpen: true, toBeDeletedClientIdData: clientId, toBeDeletedRowIndex: index});
    };


    handleAddClick = (clientId) => {
        this.setState({isAddModalOpen: true, toBeDuplicatedClientIdData: clientId});
    };

    closeAddModal = () => {
        this.setState({
            isAddModalOpen: false
        });
    };

    saveAddModal = (values) => {
        const {toBeDuplicatedClientIdData: {bir8, dealerName, region}} = this.state;
        const {selectedGroup} = this.props;
        this.setState({
            isAddModalOpen: false
        });
        this.props.createClientIdRecord({
            selectedGroup,
            bir8,
            clientId: values.clientId.padStart(CLIENT_ID_FIELD_MAX_LENGTH, '0'),
            destinationCode: values.destinationCode.padStart(CLIENT_ID_FIELD_MAX_LENGTH, '0'),
            region,
            dealerName,
        });
    };


    render() {
        const {totalCount, currentPage, tableData, sorting, linesPerPage, fulltext, isLoading, selectedGroup} = this.props;
        const totalPages = totalCount > 0 ? Math.ceil(totalCount / linesPerPage) : 1;

        if (isLoading) {
            return <Loader/>;
        }
        return (
            <div>
                <div className="d-flex flex-row pt-4 align-items-end justify-content-between">
                    <InputPerPageForm initialValues={{linesPerPage: linesPerPage}}
                                      enableReinitialize={true}
                                      onSubmit={this.handleLinesPerPageSubmit}
                                      form="InputPerPageForm"/>
                    <div className="d-flex flex-column align-items-center pb-3">
                        <span className="h-100 text-secondary">
                            <FormattedMessage {...clientIdMessages.PAGE}/>
                            {currentPage &&
                            <span className="font-weight-bold">{' ' + currentPage + '/' + totalPages}</span>}
                        </span>
                        <div className="h-100 text-secondary">
                            <FormattedMessage {...clientIdMessages.TOTAL_RESULTS}/>
                            <span className="font-weight-bold">
                                {': ' + totalCount}
                            </span>
                        </div>
                    </div>
                    <InputFullTextForm initialValues={{fulltext: fulltext,}}
                                       enableReinitialize={true}
                                       onSubmit={this.handleFulltextSubmit}
                                       form="InputFullTextForm"/>
                </div>
                <div className="card rounded">
                    <div className="table-responsive">
                        <Table className="table table-hover">
                            <THead className="thead-light">
                                <tr>
                                    <th>
                                        <FormattedMessage {...clientIdMessages.TABLE_DEALER_NUMBER}/>
                                        <Sorter column="bir8" sort={this.handleOrderingSubmit} sorting={sorting}/>
                                    </th>
                                    <th>
                                        <FormattedMessage {...clientIdMessages.TABLE_DEALER_NAME}/>
                                        <Sorter column="dealerName" sort={this.handleOrderingSubmit} sorting={sorting}/>
                                    </th>
                                    <th>
                                        <FormattedMessage {...clientIdMessages.TABLE_CLIENT_ID}/>
                                        <Sorter column="clientId" sort={this.handleOrderingSubmit} sorting={sorting}/>
                                    </th>
                                    <th>
                                        <FormattedMessage {...clientIdMessages.TABLE_DESTINATION_CODE}/>
                                        <Sorter column="destinationCode" sort={this.handleOrderingSubmit}
                                                sorting={sorting}/>
                                    </th>
                                    <th>
                                        <FormattedMessage {...clientIdMessages.TABLE_DEALER_REGION}/>
                                        <Sorter column="region" sort={this.handleOrderingSubmit} sorting={sorting}/>
                                    </th>
                                    <th/>
                                </tr>
                            </THead>
                            <TBody>
                                {tableData && tableData.length > 0 ?
                                    tableData.map((clientId, index) =>
                                        <ClientIdRecord
                                            key={index}
                                            index={index}
                                            data={clientId}
                                            selectedGroup={selectedGroup}
                                            handleDeleteClick={(clientId, index) => this.handleDeleteClick(clientId, index)}
                                            handleAddClick={(clientId) => this.handleAddClick(clientId)}
                                        />)
                                    :
                                    <tr>
                                        <td className="text-center text-secondary" colSpan="7">
                                            <FormattedMessage {...clientIdMessages.TABLE_NO_RESULTS}/>
                                        </td>
                                    </tr>
                                }
                            </TBody>
                        </Table>
                    </div>
                </div>
                <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                    <Pagination
                        onSetPage={this.loadPage}
                        totalCount={totalCount}
                        page={currentPage}
                        linesPerPage={linesPerPage}/>
                </div>
                <ConfirmationDialog show={this.state.isDeleteModalOpen}
                                    closeModal={this.closeDeleteModal}
                                    saveModal={this.saveDeleteModal}
                                    actionIdentifier="delete this"/>
                <AddClientIdModal
                    show={this.state.isAddModalOpen}
                    clientIdData={this.state.toBeDuplicatedClientIdData}
                    closeModal={this.closeAddModal}
                    saveModal={this.saveAddModal}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isLoading: state.adminClientId.isLoading,
    totalCount: state.adminClientId.totalCount,
    linesPerPage: state.adminClientId.linesPerPage,
    currentPage: state.adminClientId.page,
    tableData: state.adminClientId.list,
    sorting: state.adminClientId.sorting,
    fulltext: state.adminClientId.fulltext,
});

export default connect(mapStateToProps, {
    fetchSettingClientIdData,
    setSorting,
    setTextSearch,
    setLinesPerPage,
    deleteClientIdRecord,
    createClientIdRecord,
})(ClientIdContainer);
