import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    NO_ABS: {
        id: 'techDoc.abs.option.noAbs',
        defaultMessage: 'No ABS'
    },
    ABS: {
        id: 'techDoc.abs.option.abs',
        defaultMessage: 'ABS'
    },
    ABS_WITH_ESP_ESC: {
        id: 'techDoc.abs.option.absWithEspEsc',
        defaultMessage: 'ABS with ESP/ESC'
    },
});

export default messages;
