import React from 'react';
import styled from 'styled-components';
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {
    FORM_FIELD_EDITORS_NOTES_TD,
    FORM_FIELD_MESSAGE, FORM_LABEL_EDITORS_NOTES_HISTORY_SP, FORM_LABEL_EDITORS_NOTES_SP,
    FORM_LABEL_MESSAGES_HISTORY,
    FORM_MESSAGES_SECTION,
} from '../../../../../../constants/formConstants';
import {change, getFormInitialValues} from 'redux-form';
import {connect} from 'react-redux';
import get from 'get-value';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import {injectIntl} from 'react-intl';
import commonMessages from '../../../../../../intl/common/commonMessages';
import {parseTextToJSX} from '../../../../../../utils/utils';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const MessagesSectionInternal = props => {
    const {readOnly, initialValues, change, reqFields, intl} = props;

    const getCreatorData = (message) => {
        const {creatorName, solutionGroup, creatorIpn} = message;
        const solutionGroupName = solutionGroup ? ' ' + solutionGroup.name + ' ' : ' ';
        return `${creatorName}${solutionGroupName}(${creatorIpn})`;
    }

    const messages = get(initialValues, 'messages') || [];
    return (
        <>
            {(!readOnly || messages.length > 0) &&
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.EDITORS_NOTES_SECTION_SP}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {messages.length > 0 &&
                        <div>
                            <div className="border-bottom border-bottom-secondary"
                                 style={{backgroundColor: '#e9ecef', borderRadius: '5px 5px 0 0'}}>
                                <label className="col-form-label pl-0 font-weight-bold ml-3">
                                    <ValidFormattedMessage message={FORM_LABEL_EDITORS_NOTES_HISTORY_SP}
                                                           intlMessages={formMessages}/>
                                </label>
                            </div>
                            {messages.map((message, index) =>
                                <div key={index} className="row m-0 align-items-stretch">
                                    <div style={{backgroundColor: '#f8f9fa'}}
                                         className="col-sm-12 col-lg-4 flex-column border-bottom">
                                        <div>
                                            <FormattedDate value={new Date(message.created)}/> <FormattedTime
                                            value={new Date(message.created)}/>
                                            <span className="font-weight-bold">{' '}
                                                <ValidFormattedMessage message={message.isDealer ? 'DEALER' : 'EDITOR'}
                                                                       intlMessages={commonMessages}/>
                                            </span>
                                        </div>
                                        <div>
                                            {getCreatorData(message)}
                                        </div>
                                    </div>
                                    <div className="col border-bottom" style={{whiteSpace: 'pre-wrap'}}>
                                        {parseTextToJSX(message.text)}
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    {!readOnly &&
                        <div className="col-sm-12 px-4 pb-3 pt-1">
                            <div className="col-sm-12 px-0">
                                <TextareaFieldWithValidation label={formMessages[FORM_LABEL_EDITORS_NOTES_SP]}
                                                             field={FORM_FIELD_MESSAGE}
                                                             boldTooltip
                                                             rows={3}/>
                            </div>
                        </div>
                    }
                </StyledDiv>
            </>}
        </>
    );
};

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
    roles: state.profile.userDetail.roles,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, FORM_MESSAGES_SECTION + '.' + name, value)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MessagesSectionInternal));
