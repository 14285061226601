import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    1: {
        id: 'common.day.mon',
        defaultMessage: 'Monday'
    },
    2: {
        id: 'common.day.tue',
        defaultMessage: 'Tuesday'
    },
    3: {
        id: 'common.day.wed',
        defaultMessage: 'Wednesday'
    },
    4: {
        id: 'common.day.thu',
        defaultMessage: 'Thursday'
    },
    5: {
        id: 'common.day.fri',
        defaultMessage: 'Friday'
    },
    6: {
        id: 'common.day.sat',
        defaultMessage: 'Saturday'
    },
    7: {
        id: 'common.day.sun',
        defaultMessage: 'Sunday'
    },
});

export default messages;
