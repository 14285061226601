import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import 'react-table/react-table.css';
import Table from '../../common/table/TicketTable';
import {IconEdit, PrimaryIcon} from '../../common/Button';
import {Link} from 'react-router-dom';
import {moduleRoles} from '../../../utils/roles';
import get from 'get-value';
import {domains} from '../../../constants/Utils';
import {fetchInternalTechDocSearch} from '../../../actions/techDoc/actionTechDocInternalTicketSearch';
import internalFulltextSearchMessages from '../../../intl/technicalDocumentations/internalFulltextSearchMessages';
import {editTechDocInternalTicketListButtonVisibility} from '../../../constants/techDocInternalTicketRights';


class InternalTicketSearchTechDocTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listDefaultPageSize: 10,
            listDefaultSort: [{id: 'ticketNumber', desc: true}],
        };
        this.handleFetchData = this.handleFetchData.bind(this);
    }

    handleFetchData(tableData) {
        this.props.fetchInternalTechDocSearch(
            tableData.pageSize,
            tableData.sorted,
            tableData.cursor,
            tableData.isNextFromPivot,
            get(this.props, 'listDefaultFilters.1', {default: {}}),
            get(this.props, 'listDefaultFilters.0', {default: []})
        );
        this.setState({
            listDefaultPageSize: tableData.pageSize,
            listDefaultSort: tableData.sorted,
        });
    }

    render() {
        const {columns = [], handleEditClick, listDefaultFilters, roles, searchData} = this.props;
        const {fullTextData, isLoading, isNextPage, isNextFromPivot, pages} = searchData;

        return (
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'ticketNumber',
                                    accessor: 'ticketNumber',
                                    filterable: false,
                                    message: internalFulltextSearchMessages.TABLE_TICKET_NUMBER,
                                    Cell: (row) => <Link to={`view/${row.original.id}`}>{row.value}</Link>
                                },
                                ...columns]}
                            toolButtons={[
                                {
                                    handleClick: handleEditClick,
                                    component: <PrimaryIcon className="btn btn-sm"
                                                            title="Edit"
                                                            type="button"
                                                            key="editButton">
                                        <IconEdit/>
                                    </PrimaryIcon>
                                }
                            ]}
                            isLoading={isLoading}
                            data={fullTextData}
                            pages={pages}
                            handleFetchData={this.handleFetchData}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={listDefaultFilters}
                            defaultSorting={this.state.listDefaultSort}
                            defaultPageSize={this.state.listDefaultPageSize}
                            buttonVisibility={editTechDocInternalTicketListButtonVisibility['TD_SG_EDITOR']}
                            dealerNumber={moduleRoles.isDealerDocumentation(this.props.userRoles) ? this.props.dealerNumber : 'EDITOR'}
                            editorGroups={moduleRoles.isEditorDocumentation(this.props.userRoles) ? moduleRoles.getEditorGroups(domains.TECHNICAL_DOCUMENTATION, this.props.userRoles).map(e => e.group) : false}
                            sgPermissions = {get(roles, `${domains.TECHNICAL_DOCUMENTATION}.sgEditor.sgPermission`)}
                            toolSortable={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

InternalTicketSearchTechDocTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    pages: PropTypes.number.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    fetchTechDocFullTextSearchPageData: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    listDefaultFilters: PropTypes.array.isRequired,
    userRoles: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    searchData: state.techDocInternalTicketSearch,
    userRoles: state.profile.userDetail.roles,
    dealerNumber: state.profile.userDetail.dealerNumber,
    roles: state.profile.userDetail.roles,
});

export default connect(mapStateToProps, {fetchInternalTechDocSearch})(InternalTicketSearchTechDocTable);
