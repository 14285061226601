import {domains} from '../../constants/Utils';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const ADMIN_POPUP_SELECT_GROUP = 'ADMIN_POPUP_SELECT_GROUP';
export const ADMIN_POPUP_FETCH_PENDING = 'ADMIN_POPUP_FETCH_PENDING';
export const ADMIN_POPUP_SAVE_PENDING = 'ADMIN_POPUP_SAVE_PENDING';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({type: ADMIN_POPUP_SELECT_GROUP, payload: countryGroup});

export const fetchPopUp = (domain, group) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_POPUP_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_POPUP_GET,
                payload: {group}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_POPUP_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_POPUP_GET,
                payload: {group}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_POPUP_FETCH_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_POPUP_GET,
                payload: {group}
            });
        }
    }
};

export const savePopUp = (domain, popup) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_POPUP_SAVE_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_POPUP_SET,
                payload: {popup}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_POPUP_SAVE_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_POPUP_SET,
                payload: {popup}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_POPUP_SAVE_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_POPUP_SET,
                payload: {popup}
            });
        }
    }
}
