import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    OWN_WORKSHOP: {
        id: 'spareParts.repairedCarLocation.option.ownWorkshop',
        defaultMessage: 'Own workshop'
    },
    AGENT: {
        id: 'spareParts.repairedCarLocation.option.agent',
        defaultMessage: 'Agent'
    },
    MRA: {
        id: 'spareParts.repairedCarLocation.option.mra',
        defaultMessage: 'Independent workshop (MRA)'
    },
    COUNTER: {
        id: 'spareParts.repairedCarLocation.option.counter',
        defaultMessage: 'Counter'
    },
});

export default messages;
