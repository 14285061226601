import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {addLocaleData} from 'react-intl';
import {routerMiddleware} from 'react-router-redux';
import metisMenuReducer from 'react-metismenu/lib/reducers';
import csLocaleData from 'react-intl/locale-data/cs';
import skLocaleData from 'react-intl/locale-data/sk';
import enLocaleData from 'react-intl/locale-data/en';
import deLocaleData from 'react-intl/locale-data/de';
import frLocaleData from 'react-intl/locale-data/fr';
import daLocaleData from 'react-intl/locale-data/da';
import esLocaleData from 'react-intl/locale-data/es';
import hrLocaleData from 'react-intl/locale-data/hr';
import huLocaleData from 'react-intl/locale-data/hu';
import itLocaleData from 'react-intl/locale-data/it';
import nlLocaleData from 'react-intl/locale-data/nl';
import plLocaleData from 'react-intl/locale-data/pl';
import ptLocaleData from 'react-intl/locale-data/pt';
import roLocaleData from 'react-intl/locale-data/ro';
import slLocaleData from 'react-intl/locale-data/sl';
import srLocaleData from 'react-intl/locale-data/sr';
import svLocaleData from 'react-intl/locale-data/sv';
import thunk from 'redux-thunk';
import {reducer as form} from 'redux-form';
import createSagaMiddleware from 'redux-saga'
import {
    createAdminSocketMiddleware,
    createBvmSocketMiddleware,
    createSparePartsSocketMiddleware,
    createTechDocSocketMiddleware,
    createClaimsSocketMiddleware,
} from './socketMiddleware';
import {alerts} from '../reducers/alertReducer';
import {bvmData} from '../reducers/bvmReducer';
import {flowReducer} from '../reducers/flowReducer'
import {intl} from '../reducers/intlReducer';
import {mainFiles} from '../reducers/fileReducer';
import {menu} from '../reducers/menuReducer';
import {profile} from '../reducers/roleReducer';
import {solutionGroupReducer} from '../reducers/solutionGroupReducer';
import {supportModal} from '../reducers/supportReducer';
import {websocket} from '../reducers/websocketReducer';
import {adminMainMessage} from '../reducers/admin/adminMainMessageReducer';
import {adminNotification} from '../reducers/admin/adminNotificationReducer';
import {adminPopUp} from '../reducers/admin/adminPopUpReducer';
import {adminRecurrentProblem} from '../reducers/admin/adminRecurrentProblemReducer';
import {adminRightsManagement} from '../reducers/admin/adminRightsManagementReducer';
import {adminTicketSettings} from '../reducers/admin/adminTicketSettingsReducer';
import {adminWorkingHours} from '../reducers/admin/adminWorkingHoursReducer';
import {adminClientId} from '../reducers/admin/adminClientIdReducer';
import {adminClientIds} from '../reducers/admin/adminClientIdsReducer';
import {adminPresetResponse} from '../reducers/admin/adminPresetResponseReducer';
import {adminSecondHandBatteryType} from '../reducers/admin/adminSecondHandBatteryReducer';
import {sparePartsOrderMultiSourcingGroups} from '../reducers/orders/multiSoursingGroupsReducer';
import {claimsDic} from '../reducers/claims/claimsDicReducer';
import {sparePartsDic} from '../reducers/orders/sparePartsDicReducer';
import {sparePartsSearchEnginePage} from '../reducers/orders/sparePartsSearchEngineReducer';
import {docFullTextSearchPage} from '../reducers/techDoc/docFullTextSearchReducer';
import {claimsFullTextSearchPage} from '../reducers/claims/claimsFullTextSearchReducer';
import {orderPopUpModal} from '../reducers/orders/orderPopUpModalReducer';
import {docPopUpModal} from '../reducers/techDoc/docPopUpModalReducer';
import {claimsPopUpModal} from '../reducers/claims/claimsPopUpModalReducer';
import {sparePartsOrderAttachment} from '../reducers/orders/sparePartsAttachmentReducer';
import {techDocAttachment} from '../reducers/techDoc/techDocAttachmentReducer';
import {claimsAttachment} from '../reducers/claims/claimsAttachmentReducer';
import {sparePartDealer} from '../reducers/orders/sparePartDealersReducer';
import {docExportDealers} from '../reducers/techDoc/techDocDealersReducer';
import {claimsDealers} from '../reducers/claims/claimsDealersReducer';
import {orderExportTickets} from '../reducers/orders/orderExportReducer';
import {docExportTickets} from '../reducers/techDoc/techDocExportReducer';
import {claimsExportTickets} from '../reducers/claims/claimsExportReducer';
import {sparePartsHistoryPage} from '../reducers/orders/sparePartsHistoryReducer';
import {techDocHistoryPage} from '../reducers/techDoc/techDocHistoryReducer';
import {claimsHistoryPage} from '../reducers/claims/claimsHistoryReducer';
import {sparePartsListLockTicketCheck, sparePartsListPage} from '../reducers/orders/sparePartsListReducer';
import {techDocListOrderLockTicketCheck, techDocListPage} from '../reducers/techDoc/techDocListReducer';
import {claimsListOrderLockTicketCheck, claimsListPage} from '../reducers/claims/claimsListReducer';
import {sparePartsPreferences} from '../reducers/orders/sparePartsPreferencesReducer';
import {techDocPreferences} from '../reducers/techDoc/techDocPreferencesReducer';
import {claimsPreferences} from '../reducers/claims/claimsPreferencesReducer';
import {sparePartsRecurrentProblem} from '../reducers/orders/sparePartsRecurrentProblemReducer';
import {techDocRecurrentProblem} from '../reducers/techDoc/techDocRecurrentProblemReducer';
import {claimsRecurrentProblem} from '../reducers/claims/claimsRecurentProblemReducer';
import {sparePartsStatistics} from '../reducers/orders/sparePartsStatisticsReducer';
import {techDocStatistics} from '../reducers/techDoc/techDocStatisticsReducer';
import {claimsStatistics} from '../reducers/claims/claimsStatisticsReducer';
import {sparePartsOrderLockTicketCheck, sparePartsOrderTicket} from '../reducers/orders/sparePartsTicketReducer';
import {techDocLockTicketCheck, techDocTicket} from '../reducers/techDoc/techDocTicketReducer';
import {techDocLockInternalTicketCheck, techDocInternalTicket} from '../reducers/techDoc/techDocInternalTicketReducer';
import {claimsLockTicketCheck, claimsTicket} from '../reducers/claims/claimsTicketReducer';
import adminClientIdSaga from '../sagas/adminClientIdSaga';
import recurrentProblemFileSaga from '../sagas/recurrentProblemFileSaga';
import rightsManagementSaga from '../sagas/rightsManagementSaga';
import sparePartsDicSaga from '../sagas/spareParts/sparePartsDicSaga';
import supportModalSaga from '../sagas/supportModalSaga';
import dealerPopUpSaga from '../sagas/dealerPopUpSaga';
import sparePartsTicketSaga from '../sagas/spareParts/sparePartsTicketSaga';
import techDocTicketSaga from '../sagas/techDoc/techDocTicketSaga';
import techDocInternalTicketSaga from '../sagas/techDoc/techDocInternalTicketSaga';
import claimsTicketSaga from '../sagas/claimsTicketSaga';
import initialState from './initialState';
import history from '../history.js';
import {adminDefaultDealerRole} from '../reducers/admin/adminDefaultDealerRoleReducer';
import {adminEscalationLevel} from '../reducers/admin/adminEscalationLevelReducer';
import {adminSolutionGroupList} from '../reducers/admin/adminSolutionGroupListReducer';
import {adminSolutionGroup} from '../reducers/admin/adminSolutionGroupReducer';
import {adminEscalationSchema} from '../reducers/admin/adminEscalationSchemaReducer';
import {adminEscalationSchemaList} from '../reducers/admin/adminEscalationSchemaListReducer';
import {adminAnsweringSchemaList} from '../reducers/admin/adminAnsweringSchemaListReducer';
import {adminAnsweringSchema} from '../reducers/admin/adminAnsweringSchemaReducer';
import {adminSolutionGroupOptions} from '../reducers/admin/adminSolutionGroupOptionsReducer';
import {adminModelAnswers} from '../reducers/admin/adminModelAnswersReducer';
import {
    techDocInternalTicketListOrderLockTicketCheck,
    techDocInternalTicketListPage
} from '../reducers/techDoc/techDocInternalTicketListReducer';
import {techDocInternalTicketHistoryPage} from '../reducers/techDoc/techDocInternalTicketHistoryReducer';
import {techDocInternalTicketAttachment} from '../reducers/techDoc/techDocInternalTicketAttachmentReducer';
import {techDocInternalTicketSearch} from '../reducers/techDoc/techDocInternalTicketSearchReducer';
import sparePartsInternalTicketSaga from '../sagas/spareParts/sparePartsInternalTicketSaga';
import {sparePartsInternalTicketSearch} from '../reducers/orders/sparePartsInternalTicketSearchReducer';
import {sparePartsInternalTicketAttachment} from '../reducers/orders/sparePartsInternalTicketAttachmentReducer';
import {sparePartsInternalTicketHistoryPage} from '../reducers/orders/sparePartsInternalTicketHistoryReducer';
import {
    sparePartsInternalTicketListOrderLockTicketCheck,
    sparePartsInternalTicketListPage
} from '../reducers/orders/sparePartsInternalTicketListReducer';
import {
    sparePartsInternalTicket,
    sparePartsLockInternalTicketCheck
} from '../reducers/orders/sparePartsInternalTicketReducer';
import {techDocDic} from '../reducers/techDoc/techDocDicReducer';
import {docExportInternalTickets} from '../reducers/techDoc/techDocInternalTicketExportReducer';
import adminFileSaga from '../sagas/adminFileSaga';
import {
    sparePartsOrderTypeFilter
} from '../reducers/orders/sparePartsOrderTypeFilterReducer';
import {sparePartsVinDealerCheck} from '../reducers/orders/sparePartsTicketVinDealerCheckReducer';
import {sparePartsManagementGroupBatch} from '../reducers/orders/sparePartsManagementGroupReducer';
import {techDocManagementGroupBatch} from '../reducers/techDoc/techDocManagementGroupReducer';

/**
 * Configuration of store, reducers, middlewares, enhancers management.
 */
const configureStore = (initialStoreContent = {}) => {
    const persistedState = Object.assign(initialState, initialStoreContent);
    const middlewares = process.env.NODE_ENV !== 'production' ?
        [require('redux-immutable-state-invariant').default(), thunk] :
        [thunk];
    const enhancers = [];
    const sagaMiddleware = createSagaMiddleware();

    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f);

    const appReducers = combineReducers({
        alerts,
        bvmData,
        flowReducer,
        intl,
        mainFiles,
        menu,
        profile,
        solutionGroupReducer,
        supportModal,
        websocket,
        adminMainMessage,
        adminNotification,
        adminPopUp,
        adminRecurrentProblem,
        adminRightsManagement,
        adminTicketSettings,
        adminDefaultDealerRole,
        adminWorkingHours,
        adminClientId,
        adminClientIds,
        adminPresetResponse,
        adminSecondHandBatteryType,
        adminEscalationLevel,
        adminSolutionGroup,
        adminSolutionGroupList,
        adminEscalationSchema,
        adminEscalationSchemaList,
        adminAnsweringSchema,
        adminAnsweringSchemaList,
        adminSolutionGroupOptions,
        adminModelAnswers,
        sparePartsOrderMultiSourcingGroups,
        sparePartsDic,
        techDocDic,
        claimsDic,
        sparePartsSearchEnginePage,
        docFullTextSearchPage,
        techDocInternalTicketSearch,
        claimsFullTextSearchPage,
        orderPopUpModal,
        docPopUpModal,
        claimsPopUpModal,
        sparePartsOrderAttachment,
        techDocAttachment,
        techDocInternalTicketAttachment,
        claimsAttachment,
        sparePartDealer,
        docExportDealers,
        claimsDealers,
        orderExportTickets,
        docExportTickets,
        docExportInternalTickets,
        claimsExportTickets,
        sparePartsHistoryPage,
        techDocHistoryPage,
        techDocInternalTicketHistoryPage,
        claimsHistoryPage,
        sparePartsListLockTicketCheck,
        techDocListOrderLockTicketCheck,
        techDocInternalTicketListOrderLockTicketCheck,
        claimsListOrderLockTicketCheck,
        sparePartsListPage,
        techDocListPage,
        techDocInternalTicketListPage,
        claimsListPage,
        sparePartsPreferences,
        techDocPreferences,
        claimsPreferences,
        sparePartsRecurrentProblem,
        techDocRecurrentProblem,
        claimsRecurrentProblem,
        sparePartsStatistics,
        techDocStatistics,
        claimsStatistics,
        sparePartsOrderLockTicketCheck,
        techDocLockTicketCheck,
        techDocLockInternalTicketCheck,
        claimsLockTicketCheck,
        sparePartsOrderTicket,
        techDocTicket,
        techDocInternalTicket,
        claimsTicket,
        sparePartsInternalTicketSearch,
        sparePartsInternalTicketAttachment,
        sparePartsInternalTicketHistoryPage,
        sparePartsInternalTicketListOrderLockTicketCheck,
        sparePartsInternalTicketListPage,
        sparePartsLockInternalTicketCheck,
        sparePartsInternalTicket,
        sparePartsOrderTypeFilter,
        sparePartsVinDealerCheck,
        sparePartsManagementGroupBatch,
        techDocManagementGroupBatch,

        metisMenuStore: metisMenuReducer,
        form,
    });

    const store = createStore(
        appReducers,
        persistedState,
        compose(
            applyMiddleware(
                ...middlewares,
                createSparePartsSocketMiddleware(),
                createTechDocSocketMiddleware(),
                createClaimsSocketMiddleware(),
                createAdminSocketMiddleware(),
                createBvmSocketMiddleware(),
                routerMiddleware(history),
                sagaMiddleware
            ),
            ...enhancers
        )
    );

    addLocaleData(enLocaleData);
    addLocaleData(csLocaleData);
    addLocaleData(daLocaleData);
    addLocaleData(deLocaleData);
    addLocaleData(esLocaleData);
    addLocaleData(frLocaleData);
    addLocaleData(hrLocaleData);
    addLocaleData(huLocaleData);
    addLocaleData(itLocaleData);
    addLocaleData(nlLocaleData);
    addLocaleData(plLocaleData);
    addLocaleData(ptLocaleData);
    addLocaleData(roLocaleData);
    addLocaleData(skLocaleData);
    addLocaleData(slLocaleData);
    addLocaleData(srLocaleData);
    addLocaleData(svLocaleData);

    sagaMiddleware.run(adminFileSaga);
    sagaMiddleware.run(recurrentProblemFileSaga);
    sagaMiddleware.run(rightsManagementSaga);
    sagaMiddleware.run(sparePartsDicSaga);
    sagaMiddleware.run(supportModalSaga);
    sagaMiddleware.run(dealerPopUpSaga);
    sagaMiddleware.run(adminClientIdSaga);
    sagaMiddleware.run(sparePartsTicketSaga);
    sagaMiddleware.run(techDocTicketSaga);
    sagaMiddleware.run(techDocInternalTicketSaga);
    sagaMiddleware.run(claimsTicketSaga);
    sagaMiddleware.run(sparePartsInternalTicketSaga);

    return store;
};

export default configureStore;
