import {
    CLAIMS_ATTACHMENTS_SAVED_ERROR,
    CLAIMS_GET_TICKET,
    CLAIMS_TICKET_ATTACHMENTS_SAVED,
    CLAIMS_TICKET_CREATED,
    CLAIMS_TICKET_LOCKED,
    CLAIMS_TICKET_LOCKED_ERROR,
    CLAIMS_TICKET_LOCKED_FATAL,
    CLAIMS_TICKET_SEND,
    CLAIMS_TICKET_UPDATED,
} from '../../constants/websocketCommands';
import {
    CLAIMS_TICKET_CREATE,
    CLAIMS_TICKET_RESET,
    CLAIMS_TICKET_UPDATE
} from '../../actions/claims/actionClaimsTicket';
import {CLAIMS_ATTACHMENTS_UPLOADING_ERROR} from '../../actions/claims/actionClaimsAttachments';
import {CLAIMS_RESET_REDIRECT, CLAIMS_SET_LOCK_TICKET} from '../../actions/claims/actionClaimsLockTicket';

export const claimsTicket = (state = {
    correlationId: null,
    isTicketLoading: false,
    isTicketCreating: false,
    created: false,
    ticket: {},
    problemDescription: {},
    logs: [],
    messages: [],
    editorsNotes: [],
    editorsNoteAttachments: [],
    newEditorsNoteAttachments: [],
    attachments: [],
    newAttachments: [],
}, action) => {
    switch (action.type) {
        // load data
        case CLAIMS_TICKET_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLoading: false,
                isTicketCreating: false,
                created: false,
                ticket: {},
                problemDescription: {},
                logs: [],
                editorsNoteAttachments: [],
                newEditorsNoteAttachments: [],
                attachments: [],
                newAttachments: [],
            });
        }
        case CLAIMS_TICKET_UPDATE:
        case CLAIMS_TICKET_CREATE: {
            const {newAttachments, newEditorsNoteAttachments} = action.payload;
            return Object.assign({}, state, {
                newAttachments,
                newEditorsNoteAttachments,
                isTicketCreating: true,
                created: false,
                isTicketLoading: true,
                problemDescription: {},
                messages: [],
                editorsNotes: [],
                editorsNoteAttachments: [],
                logs: [],
                attachments: [],
            });
        }

        // command accepted
        case CLAIMS_TICKET_CREATED:
        case CLAIMS_TICKET_UPDATED:
        case CLAIMS_GET_TICKET: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketCreating: false,
                created: true,
                isTicketLoading: true,
                correlationId
            });
        }
        case CLAIMS_TICKET_SEND: {
            const {
                ticket, correlationId, problemDescription, logs, editorsNoteAttachments,
                attachments, error, messages, editorsNotes, orderItem
            } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, {isTicketLoading: false, correlationId});
            }
            return Object.assign({}, state, {
                isTicketLoading: false,
                correlationId,
                ticket,
                orderItem,
                problemDescription,
                logs,
                messages,
                editorsNotes,
                editorsNoteAttachments,
                attachments,
            });
        }
        case CLAIMS_TICKET_ATTACHMENTS_SAVED: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketLoading: true,
                correlationId,
                newAttachments: [],
                newEditorsNoteAttachments: [],
            });
        }

        // error
        case CLAIMS_ATTACHMENTS_UPLOADING_ERROR:
        case CLAIMS_ATTACHMENTS_SAVED_ERROR: {
            return Object.assign({}, state, {
                newAttachments: [],
                newEditorsNoteAttachments: [],
            });
        }

        default:
            return state;
    }
};

export const claimsLockTicketCheck = (state = {
    correlationId: null,
    isTicketLocking: false,
    redirect: false,
}, action) => {
    switch (action.type) {
        // load data
        case CLAIMS_RESET_REDIRECT: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case CLAIMS_SET_LOCK_TICKET: {
            const {uuid} = action.payload;
            return Object.assign({}, state, {
                isTicketLocking: true,
                redirect: false,
                uuid,
            });
        }

        // command accepted
        case CLAIMS_TICKET_LOCKED: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }

        // error
        case CLAIMS_TICKET_LOCKED_ERROR:
        case CLAIMS_TICKET_LOCKED_FATAL: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isTicketLocking: false,
                redirect: true,
            });
        }

        default:
            return state;
    }
};
