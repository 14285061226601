import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const fetchInternalSparePartsSearch = (pageSize, sorted, cursor, isNextFromPivot, searchOption, filtered) => (dispatch) => {
    dispatch({
        type: sparePartsWebsocketCommands.SPARE_PARTS_INTERNAL_TICKET_SEARCH_GET,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_INTERNAL_TICKET_SEARCH_GET,
        payload: {
            lastCommand: Date.now(),
            pageSize,
            sorted,
            cursor,
            isNextFromPivot,
            searchOption,
            filtered
        },
    });
};

