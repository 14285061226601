import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import styled from 'styled-components';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import v4 from 'uuid/v4';
import {BoldPrimaryButton, SecondaryButton} from '../../common/Button';
import {moduleRoles} from '../../../utils/roles';
import {ModalBody, ModalFooter, ModalHeader, ModalWindow} from '../../common/modal/Modal';
import SelectGroupsField from '../common/SelectGroupsField';
import {FieldWithValidation} from '../../common/FormField';
import {required} from '../../common/validationCommons';
import recurrentProblemMessages from '../../../intl/admin/recurrentProblemMessages';
import {uploadFile} from '../../../actions/actionFile';
import FileSelectionField from '../common/FileSelectionField';
import buttonMessages from "../../../intl/common/buttonMessages";

const Button = styled(SecondaryButton)`
  margin-right: 10px;
`;

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

class AddRecurrentProblemDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {attachment: null};
    }

    save = data => {
        const attachmentId = v4();
        const problemId = v4();
        this.props.saveModal(data, problemId, attachmentId, this.state.attachment);
        if (this.state.attachment) {
            this.props.uploadFile(this.props.domain, attachmentId, problemId, this.state.attachment.type, window.URL.createObjectURL(this.state.attachment));
            this.setState({attachment: null});
        }
    };

    close = () => {
        this.props.reset();
        this.props.closeModal();
        this.setState({data: null, attachmentId: null, attachment: null});
    };

    handleAttachmentSelection = attachment => {
        this.setState({attachment});
    };

    render() {
        const {show, handleSubmit, userDetail: {roles}, domain, selectedCountryGroup, dirty, invalid, intl: {formatMessage}} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);

        return (
            <ModalWindow isOpen={show} onRequestClose={this.close} shouldCloseOnOverlayClick={false}>
                <StyledForm onSubmit={handleSubmit(this.save)}>
                    <ModalHeader>
                        <FormattedMessage {...recurrentProblemMessages.MODAL_TITLE}/>
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <Field groups={groups}
                                   name="groups"
                                   component={SelectGroupsField}
                                   selectedCountryGroup={selectedCountryGroup}
                                   label={<FormattedMessage {...recurrentProblemMessages.MODAL_SELECT_GROUPS}/>}/>
                            <div className="row">
                                <div className="col-2">
                                    <label>
                                        <FormattedMessage {...recurrentProblemMessages.MODAL_TEXT}/>
                                    </label>
                                </div>
                                <div className="col-10">
                                    <Field
                                        name="message"
                                        component={FieldWithValidation}
                                        type="text"
                                        placeholder={formatMessage(recurrentProblemMessages.MODAL_PLACEHOLDER_TEXT)}
                                        value="text"
                                        className="form-control"
                                        validate={[required]}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <label>
                                        <FormattedMessage {...recurrentProblemMessages.MODAL_ATTACHMENT}/>
                                    </label>
                                </div>
                                <div className="col-10">
                                    <Field name="attachment" component={FileSelectionField}
                                           handleAttachmentSelection={this.handleAttachmentSelection} showIcon
                                           showName needsFixedHeight/>
                                </div>
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className="row">
                            <div className="col">
                                <Button type="button" className="btn"
                                        onClick={this.close}>
                                    <FormattedMessage {...buttonMessages.CANCEL}/>
                                </Button>
                                <BoldPrimaryButton type="submit" className="btn"
                                                   disabled={!dirty || invalid}>
                                    <FormattedMessage {...buttonMessages.SAVE}/>
                                </BoldPrimaryButton>
                            </div>
                        </div>
                    </ModalFooter>
                </StyledForm>
            </ModalWindow>
        )
    }
}

AddRecurrentProblemDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    intl: PropTypes.any.isRequired,
    userDetail: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveModal: PropTypes.func.isRequired,
    group: PropTypes.string.isRequired,
    selectedCountryGroup: PropTypes.string.isRequired,
    dirty: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    userDetail: state.profile.userDetail,
    selectedCountryGroup: state.adminRecurrentProblem.selectedCountryGroup,
    fileUploadUrl: state.mainFiles.fileUploadUrl,
});

export default reduxForm({
    form: 'addRecurrentProblemModalForm',
    enableReinitialize: true,
})(injectIntl(connect(mapStateToProps, {
    uploadFile
})(AddRecurrentProblemDialog)));
