import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const DIC_SPARE_PART_INFO_SETTED = 'DIC_SPARE_PART_INFO_SETTED';
export const DIC_SPARE_PART_RESET = 'DIC_SPARE_PART_RESET';
export const DIC_SPARE_PART_CLOSE_MODAL = 'DIC_SPARE_PART_CLOSE_MODAL';
export const ADD_SPARE_PARTS_ORDERS_DIC_VALIDATIONS = 'ADD_SPARE_PARTS_ORDERS_DIC_VALIDATIONS';

export const resetSparePartDic = () => dispatch => {
    return dispatch({
        type: DIC_SPARE_PART_RESET,
    });
};

export const closeSparePartDicModal = () => dispatch => {
    return dispatch({
        type: DIC_SPARE_PART_CLOSE_MODAL,
    });
};

export const setDicSparePartInfoActionCreator = () => {
    return {
        type: DIC_SPARE_PART_INFO_SETTED,
    }
};

export const fetchDicSparePartInfoActionCreator = (partNo) => {
    return {
        type: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_PART_INFO_GET,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_PART_INFO_GET,
        payload: {
            partNo
        },
    }
};

export const fetchVinDicStatusActionCreator = (vin, ticketNumber) => {
    return {
        type: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_VIN_STATUS_GET,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_VIN_STATUS_GET,
        payload: {
            vin,
            ticketNumber
        },
    }
};

export const addDicValidationMessages = (dicValidationMessages) => dispatch => {
    return dispatch({
        type: ADD_SPARE_PARTS_ORDERS_DIC_VALIDATIONS,
        payload: {dicValidationMessages}
    });
};

export const createDic = (params) => dispatch => {
    return dispatch({
        type: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_CREATE,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_CREATE,
        payload: {...params}
    });
};

export const updateDic = (params) => dispatch => {
    return dispatch({
        type: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_UPDATE,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_UPDATE,
        payload: {...params}
    });
};


