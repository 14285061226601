import 'react-picky/dist/picky.css';
import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import PropTypes from 'prop-types';
import get from 'get-value';
import {clearAlerts} from '../../../actions/alertsActions';
import {lockClaimsTicket, resetRedirectAfterClaimsLockTicket} from '../../../actions/claims/actionClaimsLockTicket';
import {updateClaimsUserListPreference} from '../../../actions/claims/actionClaimsPreferences';
import {fillNumberFiveZero} from '../../../utils/utils';
import PageHeader from '../../common/PageHeader';
import {domains, getDealerFormTypes, getEditorFormTypes, getFormStates, } from '../../../constants/Utils';
import {StyledPicky} from '../../common/StyledComponents';
import ListClaimsTable from './ListClaimsTable';
import checkRoles from '../../common/roleChecker/RoleChecker';
import DatePickerTableComponent from '../../common/datePicker/DatePickerTableComponent';
import Loader from '../../Loader';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import commonMessages from '../../../intl/common/commonMessages';
import listMessages from '../../../intl/claims/listMessages';
import {moduleRoles} from '../../../utils/roles';


const domain = domains.CLAIMS;

class ListClaimsPage extends Component {
    constructor(props) {
        super(props);
        const {intl, loggedUser, loggedUser: {roles}} = props;

        const isEditor = moduleRoles.isEditorClaims(roles);
        const isDealer = !isEditor && moduleRoles.isDealerClaims(roles);

        this.state = {
            ratchet: false,
            columnLibrary: [
                {
                    id: 'CREATED_BY',
                    accessor: 'creatorIpn',
                    message: listMessages.TABLE_CREATED_BY,
                },
                {
                    id: 'ORDER_CREATION_TIME',
                    accessor: 'created',
                    message: listMessages.TABLE_ORDER_CREATION_TIME,
                    Filter: ({filter, onChange}) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'CLIENT_ID',
                    accessor: 'clientId',
                    message: listMessages.TABLE_CLIENT_ID,
                    Cell: e => e.value && String(e.value).padStart(6, "0")
                },
                {
                    id: 'REG_DIR',
                    accessor: 'region',
                    message: listMessages.TABLE_REG_DIR,
                },
                {
                    id: 'DEALER_NUMBER',
                    accessor: 'dealerNumber',
                    message: listMessages.TABLE_DEALER_NUMBER,
                    Cell: e => e.value && String(e.value).padStart(8, "0")
                },
                {
                    id: 'FORM_TYPE',
                    accessor: 'type',
                    message: listMessages.TABLE_FORM_TYPE,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {isDealer && getDealerFormTypes(get(loggedUser, 'roles.CLAIMS.dealer.formsPermission')).map((key, index) =>
                                <option key={index} value={key}>
                                    {formTypeMessages[key] ? intl.formatMessage(formTypeMessages[key]) : key}
                                </option>)
                            }
                            {isEditor && getEditorFormTypes(domain, get(loggedUser, 'roles.CLAIMS.editor.groupPermission')).map((key, index) =>
                                <option key={index} value={key}>
                                    {formTypeMessages[key] ? intl.formatMessage(formTypeMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{formTypeMessages[e.value] ? intl.formatMessage(formTypeMessages[e.value]) : e.value}</div>
                },
                {
                    id: 'STATUS',
                    accessor: 'status',
                    message: listMessages.TABLE_STATUS,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">All</option>
                            {getFormStates(domain).map((key, index) =>
                                <option key={index} value={key}>
                                    {formStatesMessages[key] ? intl.formatMessage(formStatesMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'LAST_UPDATE',
                    accessor: 'lastUpdate',
                    message: listMessages.TABLE_LAST_STATUS_DATE,
                    Filter: ({filter, onChange}) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'LAST_EDITOR',
                    accessor: 'lastEditor',
                    message: listMessages.TABLE_LAST_EDITOR,
                },
                {
                    id: 'CLAIM_RELATED',
                    accessor: 'claimRelatedNo',
                    message: listMessages.TABLE_CLAIM_RELATED
                },
                {
                    id: 'INVOICE',
                    accessor: 'invoiceNo',
                    message: listMessages.TABLE_INVOICE
                },
                {
                    id: 'MISSING_PACKAGE',
                    accessor: 'missingPackageNo',
                    message: listMessages.TABLE_MISSING_PACKAGE
                },
                {
                    id: 'WRONG_DESTINATION',
                    accessor: 'wrongDestinationNo',
                    message: listMessages.TABLE_WRONG_DESTINATION
                },
                {
                    id: 'PART_NO',
                    accessor: 'partNo',
                    message: listMessages.TABLE_PART_NO
                },
                {
                    id: 'SUPPLIER',
                    accessor: 'supplier',
                    message: listMessages.TABLE_SUPPLIER
                },
                {
                    id: 'PACKAGE_NO',
                    accessor: 'packageNo',
                    message: listMessages.TABLE_PACKAGE_NO
                },
                {
                    id: 'TAKING_BACK',
                    accessor: 'takingBackNo',
                    message: listMessages.TABLE_TAKING_BACK
                },
                {
                    id: 'CONTAINER',
                    accessor: 'containerNo',
                    message: listMessages.TABLE_CONTAINER
                },
                {
                    id: 'CLIENT_SOLUTION_PILOT',
                    accessor: 'clientSolutionPilot',
                    message: listMessages.TABLE_CLIENT_SOLUTION_PILOT
                },
            ]
        };
    }

    componentWillUnmount() {
        this.props.resetRedirectAfterClaimsLockTicket();
    }

    handleEditClick = ({id}) => {
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockClaimsTicket(id);
            this.setState({ratchet: true});
        }
    };

    selectMultipleOption = (value) => {
        this.props.updateClaimsUserListPreference(this.props.loggedUser.ipn, this.state.columnLibrary
            .filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    render() {
        const {intl, claimsPreferences, lockTicket, location} = this.props;
        const {columnLibrary} = this.state;
        if (lockTicket.redirect && this.state.ratchet) {
            this.props.history.push(`/claims/edit/${lockTicket.uuid}`);
        }
        if (claimsPreferences.isLoading) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...listMessages.TITLE}/>}/>
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span
                            className="font-weight-bold"><FormattedMessage {...listMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={columnLibrary.filter(item => !get(claimsPreferences, 'menuList', {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            selectAllText={<FormattedMessage {...commonMessages.SELECT_ALL}/>}
                            manySelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            allSelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <ListClaimsTable
                    columns={columnLibrary.filter(item => !get(claimsPreferences, 'menuList', {default: []}).includes(item.id))}
                    handleEditClick={this.handleEditClick}
                    location={location}
                />
            </div>
        );
    }
}


ListClaimsPage.propTypes = {
    intl: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    claimsPreferences: state.claimsPreferences,
    lockTicket: state.claimsListOrderLockTicketCheck
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateClaimsUserListPreference,
    lockClaimsTicket,
    resetRedirectAfterClaimsLockTicket,
})(injectIntl(ListClaimsPage))), ['CL_DEALER', 'CL_EDITOR']);
