import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {
    DIC_TECH_DOC_CLOSE_MODAL,
    DIC_TECH_DOC_INFO_SETTED,
    DIC_TECH_DOC_RESET
} from '../../actions/techDoc/actionTechDocDicData';

export const techDocDic = (state = {
    isModalActive: false,
    partNo: {},
    partDescription: {},
    vorStatus: {},
    dicPartInfoLoaded: false,
    vinNumber: {},
    dicVinStatusLoaded: false,
    canCreateDic: false,
    dicValidationMessages: [],
    isCreateOrUpdateDicLoading: false
}, action) => {
    switch (action.type) {
        case DIC_TECH_DOC_RESET: {
            return Object.assign({}, state, {
                isModalActive: false,
                partNo: {},
                partDescription: {},
                vorStatus: {},
                dicPartInfoLoaded: false,
                vinNumber: {},
                dicVinStatusLoaded: false,
                canCreateDic: false,
                dicValidationMessages: [],
                isCreateOrUpdateDicLoading: false,
            });
        }
        case techDocWebsocketCommands.TECH_DOC_DIC_PART_INFO_GET: {
            return Object.assign({}, state, {dicPartInfoLoaded: false, isModalActive: true});
        }
        case techDocWebsocketCommands.TECH_DOC_DIC_PART_INFO_SEND: {
            const {partNo, partDescription, vorStatus, correlationId, managementGroup} = action.payload;
            if (correlationId < state.partInfoCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                partInfoCorrelationId: correlationId,
                partNo,
                partDescription,
                vorStatus,
                managementGroup,
                dicPartInfoLoaded: true,
                isModalActive: false
            });
        }
        case DIC_TECH_DOC_INFO_SETTED: {
            return Object.assign({}, state, {
                partNo: {},
                partDescription: {},
                vorStatus: {},
                isModalActive: false,
                dicPartInfoLoaded: false
            });
        }
        case techDocWebsocketCommands.TECH_DOC_DIC_PART_INFO_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.partInfoCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                partNo: {},
                partDescription: {},
                vorStatus: {},
                isModalActive: false,
                dicPartInfoLoaded: false,
                partInfoCorrelationId: correlationId,
            });
        }
        case DIC_TECH_DOC_CLOSE_MODAL: {
            return Object.assign({}, state, {
                isModalActive: false,
            });
        }
        default:
            return state;
    }
};
