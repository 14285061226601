import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    VISUAL_STORE_CHECK: {
        id: 'spareParts.internalTicketRequestType.visualStoreCheck',
        defaultMessage: 'Visual Store Check'
    },
    REQUEST_TO_OPEN_FOR_SALE: {
        id: 'spareParts.internalTicketRequestType.requestToOpenForSale',
        defaultMessage: 'Request To Open For Sale'
    },
    PART_DOC_REQUEST: {
        id: 'spareParts.internalTicketRequestType.partDocRequest',
        defaultMessage: 'Part Doc Request'
    },
    REF_REPLACEMENT_REQUEST: {
        id: 'spareParts.internalTicketRequestType.refReplacementRequest',
        defaultMessage: 'Ref Replacement Request'
    },
    PRICE_REQUEST: {
        id: 'spareParts.internalTicketRequestType.priceRequest',
        defaultMessage: 'Price Request'
    },
});

export default messages;
