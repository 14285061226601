import React from 'react';
import {FormattedMessage} from 'react-intl';
import {
    CLAIMS_EXPORT_PATH,
    CLAIMS_HISTORY_PATH,
    CLAIMS_LIST_PATH,
    CLAIMS_RECURRENT_PROBLEM_PATH,
    CLAIMS_FULL_TEXT_SEARCH_PATH,
    CLAIMS_NEW_CONTEST_CLAIM_STATUS,
    CLAIMS_NEW_PROBLEM_FOR_PLACING_CLAIMS,
    CLAIMS_NEW_RETURN_CLAIM,
    CLAIMS_NEW_STOCK_DEAD_CLAIM,
    CLAIMS_NEW_OLD_MATERIAL_CLAIM,
    CLAIMS_NEW_DIRECT_FLOW_CLAIM,
    CLAIMS_NEW_TROUBLESHOOT_CLAIM,
} from '../../../../routes/paths';
import {moduleRoles} from '../../../../utils/roles';
import menuMessages from '../../../../intl/layout/menuMessages';
import {domains} from '../../../../constants/Utils';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import {
    CONTEST_CLAIM_STATUS_FORM,
    DIRECT_FLOW_CLAIM_FORM,
    OLD_MATERIAL_CLAIM_FORM,
    PROBLEM_FOR_PLACING_CLAIMS_FORM,
    RETURN_CLAIM_FORM,
    STOCK_DEAD_CLAIM_FORM,
    TROUBLESHOOT_CLAIM_FORM
} from '../../../../constants/formConstants';

export const ClaimsMenuEditor = [
    {
        label: <FormattedMessage {...menuMessages.CLAIMS_LIST}/>,
        to: CLAIMS_LIST_PATH,
        id: CLAIMS_LIST_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.CLAIMS_HISTORY}/>,
        to: CLAIMS_HISTORY_PATH,
        id: CLAIMS_HISTORY_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.CLAIMS_FULLTEXT_SEARCH}/>,
        to: CLAIMS_FULL_TEXT_SEARCH_PATH,
        id: CLAIMS_FULL_TEXT_SEARCH_PATH
    },
    {
        label: <FormattedMessage {...menuMessages.CLAIMS_RECURRENT_PROBLEM}/>,
        to: CLAIMS_RECURRENT_PROBLEM_PATH,
        id: CLAIMS_RECURRENT_PROBLEM_PATH
    },
];

export const ClaimsMenuAdmin = [
    {
        label: <FormattedMessage {...menuMessages.CLAIMS_EXPORT}/>,
        to: CLAIMS_EXPORT_PATH,
        id: CLAIMS_EXPORT_PATH
    }
];

export const getClaimsDealerMenu = roles => {
    const claimsNewOrderSubmenu = buildClaimsNewOrderSubmenu(roles);

    const result = [
        {
            label: <FormattedMessage {...menuMessages.CLAIMS_LIST}/>,
            to: CLAIMS_LIST_PATH,
            id: CLAIMS_LIST_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.CLAIMS_HISTORY}/>,
            to: CLAIMS_HISTORY_PATH,
            id: CLAIMS_HISTORY_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.CLAIMS_FULLTEXT_SEARCH}/>,
            to: CLAIMS_FULL_TEXT_SEARCH_PATH,
            id: CLAIMS_FULL_TEXT_SEARCH_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.CLAIMS_RECURRENT_PROBLEM}/>,
            to: CLAIMS_RECURRENT_PROBLEM_PATH,
            id: CLAIMS_RECURRENT_PROBLEM_PATH
        }
    ];


    if (buildClaimsNewOrderSubmenu.length > 0) {
        result.unshift(
            {
                label: <FormattedMessage {...menuMessages.CLAIMS_NEW}/>,
                content: claimsNewOrderSubmenu
            }
        );
    }

    return result;
};


const buildClaimsNewOrderSubmenu = roles => {
    const result = [];
    const formTypes = moduleRoles.getDealerForms(domains.CLAIMS, roles).map(formType => formType.group);

    if (formTypes.includes(CONTEST_CLAIM_STATUS_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[CONTEST_CLAIM_STATUS_FORM]}/>,
                to: CLAIMS_NEW_CONTEST_CLAIM_STATUS,
                id: CLAIMS_NEW_CONTEST_CLAIM_STATUS
            }
        );
    }
    if (formTypes.includes(PROBLEM_FOR_PLACING_CLAIMS_FORM)) {
        result.push({
                label: <FormattedMessage {...formTypeMessages[PROBLEM_FOR_PLACING_CLAIMS_FORM]}/>,
                to: CLAIMS_NEW_PROBLEM_FOR_PLACING_CLAIMS,
                id: CLAIMS_NEW_PROBLEM_FOR_PLACING_CLAIMS
            }
        );
    }
    if (formTypes.includes(RETURN_CLAIM_FORM)) {
        result.push({
                label: <FormattedMessage {...formTypeMessages[RETURN_CLAIM_FORM]}/>,
                to: CLAIMS_NEW_RETURN_CLAIM,
                id: CLAIMS_NEW_RETURN_CLAIM
            }
        );
    }
    if (formTypes.includes(STOCK_DEAD_CLAIM_FORM)) {
        result.push({
                label: <FormattedMessage {...formTypeMessages[STOCK_DEAD_CLAIM_FORM]}/>,
                to: CLAIMS_NEW_STOCK_DEAD_CLAIM,
                id: CLAIMS_NEW_STOCK_DEAD_CLAIM
            }
        );
    }
    if (formTypes.includes(OLD_MATERIAL_CLAIM_FORM)) {
        result.push({
                label: <FormattedMessage {...formTypeMessages[OLD_MATERIAL_CLAIM_FORM]}/>,
                to: CLAIMS_NEW_OLD_MATERIAL_CLAIM,
                id: CLAIMS_NEW_OLD_MATERIAL_CLAIM
            }
        );
    }
    if (formTypes.includes(DIRECT_FLOW_CLAIM_FORM)) {
        result.push({
                label: <FormattedMessage {...formTypeMessages[DIRECT_FLOW_CLAIM_FORM]}/>,
                to: CLAIMS_NEW_DIRECT_FLOW_CLAIM,
                id: CLAIMS_NEW_DIRECT_FLOW_CLAIM,
            }
        );
    }
    if (formTypes.includes(TROUBLESHOOT_CLAIM_FORM)) {
        result.push({
                label: <FormattedMessage {...formTypeMessages[TROUBLESHOOT_CLAIM_FORM]}/>,
                to: CLAIMS_NEW_TROUBLESHOOT_CLAIM,
                id: CLAIMS_NEW_TROUBLESHOOT_CLAIM,
            }
        );
    }
    return result;
};
