import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const CLAIMS_POPUP_FETCH_COMMAND = 'CLAIMS_POPUP_FETCH_COMMAND';
export const CLAIMS_POPUP_MODAL_OPEN = 'CLAIMS_POPUP_MODAL_OPEN';
export const CLAIMS_POPUP_MODAL_CONFIRM = 'CLAIMS_POPUP_MODAL_CONFIRM';

export const openClaimsPopUpModal = () => (dispatch) => {
    dispatch({
        type: CLAIMS_POPUP_MODAL_OPEN
    });
};

export const confirmClaimsPopUpModal = () => (dispatch) => {
    dispatch({
        type: CLAIMS_POPUP_MODAL_CONFIRM
    });
};

export const fetchClaimsPopUp = (group) => dispatch => {
    return dispatch({
        type: CLAIMS_POPUP_FETCH_COMMAND,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_POPUP_GET,
        payload: {group}
    });
};
