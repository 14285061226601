import React, {Component} from 'react';
import {connect} from 'react-redux';
import get from 'get-value';
import Loader from '../../Loader';
import {editRights} from '../../../constants/techDocTicketRights';
import {
    DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    DOCUMENTATION_TICKET_STATUS_NEW,
    DOCUMENTATION_TICKET_STATUS_READ_BY_SUBSIDIARY,
    FORM_BUTTON_ANSWER,
    FORM_BUTTON_CREATE_INTERNAL_TICKET,
    FORM_BUTTON_ESCALATE, FORM_BUTTON_FORWARD_TO_DEALER,
    FORM_BUTTON_MARK_AS_READ,
    FORM_BUTTON_TAKE_BACK, OVAL_PLATE_ORDER_FORM,
} from '../../../constants/formConstants';
import {Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    fetchTechDocTicket,
    resetTechDocTicket,
    updateTechDocTicket
} from '../../../actions/techDoc/actionTechDocTicket';
import {createInternalTicketTechDocInitData, transformUpdateTechDocTicketForSave} from '../Utils';
import {
    lockTechDocTicket,
    resetRedirectAfterTechDocLockTicket,
    unlockTechDocTicket
} from '../../../actions/techDoc/actionTechDocLockTicket';
import UploadingTechDocAttachments from './UploadingTechDocAttachments';
import checkRoles from '../../common/roleChecker/RoleChecker';
import EditLoadTicketDataPage from './EditLoadTicketDataPage';
import {DOCUMENTATIONS_NEW_INTERNAL_TICKET} from '../../../routes/paths';
import {moduleRoles} from '../../../utils/roles';
import alertMessages from '../../../intl/common/alertMessages';
import {injectIntl} from 'react-intl';
import {resetTechDocDic} from '../../../actions/techDoc/actionTechDocDicData';


class EditTechDocTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false, createInternalTicket: false, skippedReadBySubsidiaryStatus: false, fetchedNewTicketData: false};
    }

    componentWillUnmount() {
        const {uuid, redirect} = this.props.lockTicket;
        if (!redirect) {
            this.props.unlockTechDocTicket(uuid);
        }
        this.props.resetRedirectAfterTechDocLockTicket();
        this.props.resetTechDocDic();
    }

    componentDidMount() {
        this.props.resetTechDocTicket();
        this.props.resetTechDocDic();
        this.props.lockTechDocTicket(this.props.match.params.ticketUuid);

        if (!this.props.location.redirectedAfterUpdate) {
            this.setState({...this.state, fetchedNewTicketData: true});
            this.props.fetchTechDocTicket(this.props.match.params.ticketUuid);
        }
    }

    answeredToSuspiciousGroup = (submitBy, dataForSend) => {
        if (submitBy === FORM_BUTTON_ANSWER) {
            const assignedSolutionGroup = get(dataForSend, 'ticket.solutionGroup.id');
            return !this.props.previousSolutionGroups.includes(assignedSolutionGroup)
        }
    }

    missingEditorsNotes = (submitBy, dataForSend) => {
        if (submitBy === FORM_BUTTON_ANSWER || submitBy === FORM_BUTTON_ESCALATE) {

            const {loggedUser, ticketData} = this.props;

            const isEditor = moduleRoles.isEditorDocumentation(loggedUser) || moduleRoles.isSGEditorDocumentation(loggedUser);
            const rights = editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status];
            const isOval = get(ticketData, 'ticket.docType') === OVAL_PLATE_ORDER_FORM;

            return rights.canAddEditorsNotes && !isOval && !dataForSend.editorsNote;
        }
    }

    missingMessageToDealer = (submitBy, dataForSend) => {
        return submitBy === FORM_BUTTON_FORWARD_TO_DEALER && !dataForSend.message;
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const dataForSend = transformUpdateTechDocTicketForSave(values);

        if (this.answeredToSuspiciousGroup(submitBy, dataForSend)) {
            const groupName = get(dataForSend, 'ticket.solutionGroup.name');
            if (!window.confirm(this.props.intl.formatMessage(alertMessages.ESCALATION_SUSPICIOUS_GROUP_WARN, {groupName}))) {
                return;
            }
        }
        if (this.missingEditorsNotes(submitBy, dataForSend)) {
            if (!window.confirm(this.props.intl.formatMessage(alertMessages.ESCALATION_MISSING_EDITORS_NOTES_GROUP_WARN))) {
                return;
            }
        }
        if (this.missingMessageToDealer(submitBy, dataForSend)) {
            if (!window.confirm(this.props.intl.formatMessage(alertMessages.ANSWER_TO_DEALER_MISSING_MESSAGE_WARN))) {
                return;
            }
        }
        if (submitBy === FORM_BUTTON_CREATE_INTERNAL_TICKET) {
            const initData = createInternalTicketTechDocInitData(values.newValues);
            this.setState({
                ...this.state,
                ticketSubmittedAndShouldRedirect: true,
                createInternalTicket: true,
                createInternalTicketData: initData
            })
        } else {
            this.props.updateTechDocTicket(dataForSend);
            if (!([FORM_BUTTON_MARK_AS_READ, FORM_BUTTON_TAKE_BACK].includes(submitBy))) {
                this.setState({...this.state, ticketSubmittedAndShouldRedirect: true});
            }
        }
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.ticketSubmittedAndShouldRedirect && !this.state.ticketSubmittedAndShouldRedirect
            && prevProps.ticketData.isTicketCreating && !this.props.ticketData.isTicketCreating
            && this.props.ticketData.isTicketLoading) {
            this.props.lockTechDocTicket(this.props.match.params.ticketUuid);
        }
    }

    render() {
        const {
            ticketData,
            attachmentsData,
            lockTicket,
            loggedUser
        } = this.props;

        if (lockTicket.redirect) {
            return <Redirect to={`/documentation/view/${lockTicket.uuid}`}/>;
        }
        if (this.state.createInternalTicket) {
            return <Redirect to={{
                pathname: DOCUMENTATIONS_NEW_INTERNAL_TICKET,
                initData: this.state.createInternalTicketData,
            }}/>
        }

        let formType = false;
        if (get(ticketData, 'ticket.docType', {default: false})) {
            formType = ticketData.ticket.docType;
        }

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingTechDocAttachments attachments={attachmentsData.attachmentsForUpload}
                                                attachmentsUrl={ticketData.newAttachments}
                                                correlationId={ticketData.correlationId}
                                                isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                                formType={formType}/>
        }

        if (ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || lockTicket.isTicketLocking
            || !formType
        ) {
            return <Loader/>;
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/documentation/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (get(ticketData, 'ticket.uuid', {default: false})
            && [DOCUMENTATION_TICKET_STATUS_NEW, DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY].includes(get(ticketData, 'ticket.status', {default: false}))
            && (moduleRoles.isEditorDocumentation(loggedUser) || moduleRoles.isSGEditorDocumentation(loggedUser))
            && !this.state.skippedReadBySubsidiaryStatus
            && this.state.fetchedNewTicketData
        ) {
            this.setState({...this.state, skippedReadBySubsidiaryStatus: true});
            this.props.updateTechDocTicket({
                ticket: {
                    pfxNumber: ticketData.ticket.pfxNumber,
                    sfxNumber: ticketData.ticket.sfxNumber,
                    status: DOCUMENTATION_TICKET_STATUS_READ_BY_SUBSIDIARY,
                    uuid: ticketData.ticket.uuid,
                }
            });
            return <Loader/>;
        }

        return (
            <EditLoadTicketDataPage formType={formType} handleSubmit={this.handleSubmit}/>
        );
    }
}

const getPreviousSolutionGroups = (state) => {
    const logs = get(state, 'techDocTicket.logs') || [];

    const fromIds = logs.map(item => item.solutionGroup && item.solutionGroup.id);
    const toIds = logs.map(item => item.toSolutionGroup && item.toSolutionGroup.id);

    return [...new Set([...fromIds, ...toIds])];
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.techDocTicket,
    lockTicket: state.techDocLockTicketCheck,
    attachmentsData: state.techDocAttachment,
    previousSolutionGroups: getPreviousSolutionGroups(state)
});

EditTechDocTicketPage.propTypes = {
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
    fetchTechDocTicket: PropTypes.func.isRequired,
    resetTechDocTicket: PropTypes.func.isRequired,
    lockTechDocTicket: PropTypes.func.isRequired,
    unlockTechDocTicket: PropTypes.func.isRequired,
    resetRedirectAfterTechDocLockTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateTechDocTicket: PropTypes.func.isRequired,
    resetTechDocDic: PropTypes.func.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchTechDocTicket,
    resetTechDocTicket,
    lockTechDocTicket,
    unlockTechDocTicket,
    resetRedirectAfterTechDocLockTicket,
    updateTechDocTicket,
    resetTechDocDic,
})(injectIntl(EditTechDocTicketPage))), ['TD_DEALER', 'TD_EDITOR', 'TD_SG_EDITOR']);

