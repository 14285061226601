import React, {Component} from 'react';
import {connect} from 'react-redux';
import Loader from '../../Loader';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {fetchTicketSettingsData, updateTicketSettingsData} from "../../../actions/admin/actionAdminTicketSettings";
import TicketSettingsForm from "./TicketSettingsForm";

const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

class TicketSettingsContainer extends Component {
    componentDidMount() {
        this.props.fetchTicketSettingsData(this.props.domain, this.props.group);
    }

    handleSubmit = (values) => {
        const {domain, group} = this.props;
        this.props.updateTicketSettingsData(domain, {
            group,
            daysToHistory: parseInt(values.daysToHistory),
            daysToClose: parseInt(values.daysToClose),
            blockingTime: parseInt(values.blockingTime)
        });
    };

    render() {
        const {isLoading, group, data, domain} = this.props;
        if (isLoading) {
            return <Loader/>;
        }
        return (
            <Wrapper classname="container-fluid">
                <TicketSettingsForm onSubmit={this.handleSubmit}
                                    initialValues={data}
                                    enableReinitialize={true}
                                    form={`ticketSettingsForm${domain}_${group}`}/>
            </Wrapper>
        );
    }
}

TicketSettingsContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired,
    updateTicketSettingsData: PropTypes.func.isRequired,
    fetchTicketSettingsData: PropTypes.func.isRequired,
    group: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminTicketSettings.isLoading,
    data: state.adminTicketSettings.ticketSettings,
});

export default connect(mapStateToProps, {
        fetchTicketSettingsData,
        updateTicketSettingsData,
    }
)(TicketSettingsContainer);
