import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {FieldArray, formValueSelector} from 'redux-form';
import styled from 'styled-components';
import moment from 'moment';
import {validMandatoryTicketFiles, validTicketFiles} from '../../../../../common/validationCommons';
import {RequiredSpan} from '../../../../../common/StyledComponents';
import RenderDropZone from '../../../../../common/RenderDropZone';
import UploadedAttachments from './UploadedAttachments';
import formMessages from '../../../../../../intl/claims/formMessages';
import {
    FORM_ATTACHMENTS_SECTION,
    FORM_FIELD_ATTACHMENTS,
    FORM_FIELD_NEW_ATTACHMENTS,
    FORM_TICKET_SECTION
} from '../../../../../../constants/formConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const compare = (a, b) => {
    return moment(a.created).isAfter(b.created) ? 1 : -1;
};

const AttachmentSection = (props) => {
    const {attachments = {}, userRights, readOnly, form} = props;
    const dealerAttachments = Object.keys(attachments).map(item => attachments[item]).filter(item => item.isDealer)
        .filter(item => item.section === 'attachmentsSection').sort(compare);
    const editorAttachments = Object.keys(attachments).map(item => attachments[item]).filter(item => item.isEditor)
        .filter(item => item.section === 'attachmentsSection').sort(compare);

    return (
        <>
            {((userRights.canAddAttach && !readOnly) || (dealerAttachments && Object.keys(dealerAttachments).length > 0) ||
                (editorAttachments && Object.keys(editorAttachments).length > 0)) &&
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.ATTACHMENTS_SECTION}/>
                    {((form === 'DIRECT_FLOW_CLAIM' || form === 'OLD_MATERIAL_CLAIM') && userRights.canAddMandatoryAttach) &&
                    <RequiredSpan>{' *\u00A0'}</RequiredSpan>
                    }
                </h3>

                {((form === 'DIRECT_FLOW_CLAIM' || form === 'OLD_MATERIAL_CLAIM') && userRights.canAddMandatoryAttach) &&
                <div className="text-left ml-4 mb-2 mt-0">
                    {(form === 'OLD_MATERIAL_CLAIM') &&
                    <><FormattedMessage {...formMessages.MANDATORY_ATTACHMENT_MSG1}/></>
                    }
                    {(form === 'DIRECT_FLOW_CLAIM') &&
                    <><FormattedMessage {...formMessages.MANDATORY_ATTACHMENT_MSG2}/></>
                    }
                </div>
                }

                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {(dealerAttachments && dealerAttachments.length > 0) &&
                    <UploadedAttachments
                        attachments={dealerAttachments}
                        label={formMessages.DEALER_ATTACHMENTS}
                        form={form}
                        section={'AttachmentSection'}/>
                    }
                    {(editorAttachments && editorAttachments.length > 0) &&
                    <UploadedAttachments
                        attachments={editorAttachments}
                        label={formMessages.EDITOR_ATTACHMENTS}
                        form={form}
                        section={'AttachmentSection'}/>
                    }
                    {userRights.canAddAttach && !readOnly &&
                    <FieldArray
                        name={FORM_FIELD_NEW_ATTACHMENTS}
                        component={RenderDropZone}
                        validate={((form === 'DIRECT_FLOW_CLAIM' || form === 'OLD_MATERIAL_CLAIM') &&
                            userRights.canAddMandatoryAttach) ? validMandatoryTicketFiles : validTicketFiles}/>
                    }
                </StyledDiv>
            </>
            }
        </>
    )
}

const mapStateToProps = (state, initialProps) => ({
    attachments: formValueSelector(initialProps.form)(state, `${FORM_ATTACHMENTS_SECTION}.${FORM_FIELD_ATTACHMENTS}`),
    ticketId: formValueSelector(initialProps.form)(state, `${FORM_TICKET_SECTION}.uuid`),
});

export default connect(mapStateToProps, null)(AttachmentSection);
