import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    addPresetResponse, editPresetResponse, removePresetResponse, updatePresetResponse
} from '../../../actions/admin/actionAdminPresetResponse';
import {IconCancel, IconDelete, IconEdit, IconSave, PrimaryIcon} from '../../common/Button';
import {MessageCell, StyledSmall, StyledTextArea} from '../../common/StyledComponents';
import ConfirmationDialog from '../../common/modal/ConfirmationDialog';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class PresetResponseRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueName: this.props.data.presetName,
            valueMessage: this.props.data.presetMessage,
            isOpenRemoveDialog: false
        };
    }

    onClickPencil = e => {
        const {adminPresetResponse, index, data, domain, updatePresetResponse, editPresetResponse} = this.props;
        const {valueName, valueMessage} = this.state;
        e.preventDefault();
        if ((adminPresetResponse.edit === index) && ((valueName !== data.presetName) || (valueMessage !== data.presetMessage))) {
            const item = {
                group: data.group,
                uuid: data.uuid,
                presetName: valueName,
                presetMessage: valueMessage,
            };
            updatePresetResponse(domain, item);
        } else if (adminPresetResponse.edit === index) {
            editPresetResponse(-1);
        } else {
            this.setState({valueName: data.presetName, valueMessage: data.presetMessage});
            editPresetResponse(index);
        }
    };

    onCancel = e => {
        e.preventDefault();
        this.setState({valueName: this.props.data.presetName, valueMessage: this.props.data.presetMessage});
        this.props.editPresetResponse(-1);
    };

    onClickDelete = () => {
        const item = {
            group: this.props.data.group,
            uuid: this.props.data.uuid,
        };
        this.props.removePresetResponse(this.props.domain, item);
    };

    onChangeHandlerName = e => {
        e.preventDefault();
        this.setState({valueName: e.target.value});
    };

    onChangeHandlerMessage = e => {
        e.preventDefault();
        this.setState({valueMessage: e.target.value});
    };

    showRemoveModal = () => {
        this.setState({
            isOpenRemoveDialog: true
        });
    };

    closeModal = e => {
        e.preventDefault();
        this.setState({
            isOpenRemoveDialog: false
        });
    };

    saveModal = e => {
        e.preventDefault();
        this.setState({
            isOpenRemoveDialog: false
        });
        this.onClickDelete();
    };

    renderHandler(provided) {
        return (
            <td className="col-1"
                {...provided.dragHandleProps}
            >
                {
                    (this.props.adminPresetResponse.edit === -1) &&
                    <FontAwesomeIcon icon="arrows-alt-v"/>
                }
            </td>
        );
    }

    renderName(index) {
        if (this.props.adminPresetResponse.edit === index) {
            return (
                <StyledSmall
                    className="form-control"
                    value={this.state.valueName}
                    placeholder="Enter your text here..."
                    onChange={this.onChangeHandlerName}/>
            );
        } else {
            return this.props.data.presetName;
        }
    }

    renderMessage(index) {
        if (this.props.adminPresetResponse.edit === index) {
            return (
                <StyledTextArea
                    className="form-control"
                    value={this.state.valueMessage}
                    placeholder="Enter your text here..."
                    onChange={this.onChangeHandlerMessage}/>
            );
        } else {
            return this.props.data.presetMessage;
        }
    }

    renderTools(index) {
        let buttons;

        if (this.props.adminPresetResponse.edit === index) {
            buttons =
                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group " role="group" aria-label="First group">
                        <PrimaryIcon className="btn btn-sm m-sm-1" onClick={this.onClickPencil}
                                     title="Save" type="button">
                            <IconSave/>
                        </PrimaryIcon>
                    </div>
                    <div className="btn-group " role="group" aria-label="Second group">
                        <PrimaryIcon className="btn btn-sm  m-sm-1" onClick={this.onCancel}
                                     title="Cancel" type="button">
                            <IconCancel/>
                        </PrimaryIcon>
                    </div>
                </div>
        } else {
            buttons =
                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group " role="group" aria-label="First group">
                        <PrimaryIcon className="btn btn-sm m-sm-1" onClick={this.onClickPencil}
                                     title="Edit" type="button">
                            <IconEdit/>
                        </PrimaryIcon>
                    </div>
                    <div className="btn-group " role="group" aria-label="Second group">
                        <PrimaryIcon className="btn btn-sm m-sm-1" title="Delete" type="button"
                                     onClick={this.showRemoveModal}>
                            <IconDelete/>
                        </PrimaryIcon>
                    </div>
                </div>
        }

        return buttons;
    }

    render() {
        const {provided, index} = this.props;
        return (
            <tr className="d-flex"
                ref={provided.innerRef}
                {...provided.draggableProps}>
                {this.renderHandler(provided)}
                <MessageCell className="col-3">{this.renderName(index)}</MessageCell>
                <MessageCell className="col-6">{this.renderMessage(index)}</MessageCell>
                <td className="col-2">{this.renderTools(index)}</td>
                <ConfirmationDialog show={this.state.isOpenRemoveDialog} closeModal={this.closeModal}
                                    saveModal={this.saveModal} actionIdentifier="remove the automatic response"/>
            </tr>
        );
    }
}

PresetResponseRow.propTypes = {
    data: PropTypes.object.isRequired,
    provided: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    adminPresetResponse: state.adminPresetResponse,
});

export default connect(mapStateToProps, {
    removePresetResponse,
    addPresetResponse,
    editPresetResponse,
    updatePresetResponse,
})(PresetResponseRow);
