import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import {PrimaryButton} from '../../common/Button';
import AddRecurrentProblemDialog from './AddRecurrentProblemDialog';
import {addItem, changePosition, fetchRecurrentProblems} from '../../../actions/admin/actionAdminRecurrentProbem';
import RecurrentProblemsRow from './RecurrentProblemsRow';
import Loader from '../../Loader';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import {moduleRoles} from '../../../utils/roles';
import recurrentProblemMessages from '../../../intl/admin/recurrentProblemMessages';
import buttonMessages from '../../../intl/common/buttonMessages';

const Table = styled.table`
  width: 100%;
`;

const Wrapper = styled.div`
  padding-bottom: 30px;
`;

const Button = styled(PrimaryButton)`
  margin-right: 5px;
`;

class RecurrentProblemsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {isOpenAddRecurrentProblemDialog: false};
    }

    componentDidMount() {
        this.props.fetchRecurrentProblems(this.props.domain, this.props.selectedGroup);
    }

    showAddModal = () =>
        this.setState({
            isOpenAddRecurrentProblemDialog: true,
        });

    closeModal = () =>
        this.setState({
            isOpenAddRecurrentProblemDialog: false
        });

    saveModal = ({groups = [], message = ''}, problemId, attachmentId, attachment) => {
        this.setState({
            isOpenAddRecurrentProblemDialog: false
        });
        const item = {
            message: message,
            uuid: problemId,
            groups: groups.map(group => group.value),
            userName: this.props.profile.name,
            updated: new Date().getTime()
        };
        if (attachment) {
            item.attachment = {
                uuid: attachmentId,
                name: attachment.name,
                type: attachment.type
            }
        }
        this.props.addItem(this.props.domain, item);
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }

        // no movement
        if (result.destination.index === result.source.index) {
            return;
        }

        //swap
        const item = {
            group: this.props.selectedGroup,
            position: result.destination.index,
            uuid: this.props.recurrentProblems[result.source.index].uuid,
        };
        this.props.changePosition(this.props.domain, item, result.source.index);
    };

    render() {
        const {isLoading, recurrentProblems, domain, intl: {formatMessage}, selectedGroup, roles} = this.props;
        return (
            <Wrapper>
                <h5><FormattedMessage {...recurrentProblemMessages.HEADER}/></h5>
                {isLoading && <Loader/>}
                {!isLoading && <>
                    <Button disabled={recurrentProblems.length > 19} className="btn btn-sm mb-sm-3" onClick={this.showAddModal}>
                        <FormattedMessage {...buttonMessages.ADD}/>
                    </Button>
                    {recurrentProblems.length === 0 &&
                    <div>
                        <FormattedMessage {...recurrentProblemMessages.EMPTY}/>
                    </div>
                    }
                    {recurrentProblems.length !== 0 &&
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className="card rounded">
                            <Table className="table table-hover">
                                <thead className="thead-light">
                                <tr className="d-flex">
                                    <th className="col-1"/>
                                    <th className="col-3">
                                        <FormattedMessage {...recurrentProblemMessages.TABLE_GROUP}/>
                                    </th>
                                    <th className="col-5">
                                        <FormattedMessage {...recurrentProblemMessages.TABLE_RECURRENT_PROBLEM_TEXT}/>
                                    </th>
                                    <th className="col-1">
                                        <FormattedMessage {...recurrentProblemMessages.TABLE_ATTACHMENT}/>
                                    </th>
                                    <th className="col-1">
                                        <FormattedMessage {...recurrentProblemMessages.TABLE_LAST_UPDATE}/>
                                    </th>
                                    <th className="col-1">
                                        <FormattedMessage {...recurrentProblemMessages.TABLE_ACTION}/>
                                    </th>
                                </tr>
                                </thead>
                                <Droppable droppableId="table">
                                    {provided => {
                                        return (
                                            <tbody
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                            {recurrentProblems.map((recurrentProblems, index) =>
                                                <Draggable key={index} draggableId={`id-${index}`} index={index}>
                                                    {provided => {
                                                        return (
                                                            <RecurrentProblemsRow
                                                                provided={provided}
                                                                index={index}
                                                                data={recurrentProblems}
                                                                domain={this.props.domain}
                                                            />
                                                        )
                                                    }}
                                                </Draggable>
                                            )}
                                            </tbody>
                                        )
                                    }}
                                </Droppable>
                            </Table>
                        </div>
                    </DragDropContext>
                    }
                    <AddRecurrentProblemDialog domain={domain}
                                               show={this.state.isOpenAddRecurrentProblemDialog}
                                               closeModal={this.closeModal}
                                               saveModal={this.saveModal}
                                               group={formatMessage(countryGroupMessages[selectedGroup])}
                                               initialValues={{
                                                   groups: [moduleRoles.getAdminGroups(domain, roles)
                                                       .map(group => ({
                                                           value: group.group,
                                                           label: formatMessage(countryGroupMessages[group.group]),
                                                           isFixed: true
                                                       })).find(group => group.value === selectedGroup)]
                                               }}/>
                </>}
            </Wrapper>
        );
    }
}

RecurrentProblemsContainer.propTypes = {
    roles: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    recurrentProblems: PropTypes.array.isRequired,
    changePosition: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    fetchRecurrentProblems: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    selectedGroup: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminRecurrentProblem.isLoading,
    recurrentProblems: state.adminRecurrentProblem.recurrentProblems,
    changePosition: state.changePosition,
    addItem: state.addItem,
    fetchRecurrentProblems: state.fetchRecurrentProblems,
    roles: state.profile.userDetail.roles,
    profile: state.profile.userDetail,
    intl: state.intl
});
export default connect(mapStateToProps, {
    changePosition,
    addItem,
    fetchRecurrentProblems
})(injectIntl(RecurrentProblemsContainer));
