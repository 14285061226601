import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    ERR101: {
        id: 'error.dic.partInfo.moreThanOnePartNumber',
        defaultMessage: 'More than one part number received'
    },
    ERR102: {
        id: 'error.dic.partInfo.invalidPartNumber',
        defaultMessage: 'Invalid Part number'
    },
    ERR103: {
        id: 'error.dic.partInfo.descriptionNotAvailable',
        defaultMessage: 'Part Description not available'
    },
    ERR104: {
        id: 'error.dic.partInfo.vorNotFound',
        defaultMessage: 'VOR status not available'
    },
    ERR105: {
        id: 'error.dic.partInfo.partNotFound',
        defaultMessage: 'Part number not found'
    },
    ERR201: {
        id: 'error.dic.vinStatus.errorinVinNumber',
        defaultMessage: 'VIN not valid'
    },
    ERR202: {
        id: 'error.dic.vinStatus.vinNotFound',
        defaultMessage: 'VIN not found'
    },
    ERR301: {
        id: 'error.dic.createDic.moreThan10Parts',
        defaultMessage: 'More than 10 part numbers'
    },
    ERR302: {
        id: 'error.dic.createDic.invalidCustomerNumber',
        defaultMessage: 'Invalid customer number'
    },
    ERR303: {
        id: 'error.dic.createDic.invalidPartNumber',
        defaultMessage: 'Invalid Part number'
    },
    ERR304: {
        id: 'error.dic.createDic.invalidVinNumber',
        defaultMessage: 'Invalid VIN number'
    },
    ERR305: {
        id: 'error.dic.createDic.vinNotFound',
        defaultMessage: 'VIN not found'
    },
    ERR306: {
        id: 'error.dic.createDic.unknownParts',
        defaultMessage: 'Unknown parts in DIC'
    },
    ERR307: {
        id: 'error.dic.createDic.emptyRegistrationNumber',
        defaultMessage: 'Empty Registration number'
    },
    ERR308: {
        id: 'error.dic.createDic.emptyJobNumber',
        defaultMessage: 'Empty Job number'
    },
    ERR309: {
        id: 'error.dic.createDic.emptyImmobilizationLocationCode',
        defaultMessage: 'Empty Immobilization location code'
    },
    ERR310: {
        id: 'error.dic.createDic.dealerNumberNotFound',
        defaultMessage: 'Dealer number not found'
    },
    ERR311: {
        id: 'error.dic.createDic.dicAlreadyExists',
        defaultMessage: 'DIC already exists'
    },
    ERR312: {
        id: 'error.dic.createDic.emptyMprLine',
        defaultMessage: 'Empty MPR order line number'
    },
    ERR313: {
        id: 'error.dic.createDic.invalidMprLine',
        defaultMessage: 'Invalid MPR order line number'
    },
    ERR314: {
        id: 'error.dic.createDic.quantityNotReceived',
        defaultMessage: 'Quantity not received'
    },
    ERR315: {
        id: 'error.dic.createDic.invalidQuantity',
        defaultMessage: 'Invalid quantity'
    },
    ERR401: {
        id: 'error.dic.updateDic.moreThan10Parts',
        defaultMessage: 'More than TEN part numbers'
    },
    ERR402: {
        id: 'error.dic.updateDic.invalidPartNumber',
        defaultMessage: 'Invalid Part number'
    },
    ERR403: {
        id: 'error.dic.updateDic.partNumberAlreadyExists',
        defaultMessage: 'Part Number {partNo} already exists for the DIC'
    },
    ERR404: {
        id: 'error.dic.updateDic.unknownParts',
        defaultMessage: 'Unknown parts in DIC'
    },
    ERR405: {
        id: 'error.dic.updateDic.emptyMprLine',
        defaultMessage: 'Empty MPR order line number'
    },
    ERR406: {
        id: 'error.dic.updateDic.invalidMprLine',
        defaultMessage: 'Invalid MPR order line number'
    },
    ERR407: {
        id: 'error.dic.updateDic.dicCannotBeUpdated',
        defaultMessage: 'DIC in Status 4 or above cannot be updated'
    },
    ERR408: {
        id: 'error.dic.updateDic.dicClosed',
        defaultMessage: 'DIC {dicNumber} is closed by Customer Service. Please modify it manually or create a new DIC in DIC Tool'
    },
    CLIENT_IDS_FETCH_ERROR: {
        id: 'error.clientIds.fetch',
        defaultMessage: 'Cannot fetch Client IDs'
    },
    NO_DEALER_CODE: {
        id: 'error.noDealerCode',
        defaultMessage: 'You don\'t have a dealer code!'
    },
    DEALER_ROLE_SET: {
        id: 'error.dealerRoleSet',
        defaultMessage: 'Cannot grant editor access for this user! Reason: User has already dealer profile'
    },
    EDITOR_ROLE_SET: {
        id: 'error.editorRoleSet',
        defaultMessage: 'Cannot grant dealer access for this user! Reason: User has already editor profile'
    },
    DEALER_ROLE_NO_BIR: {
        id: 'error.dealerRoleNoBir',
        defaultMessage: 'Cannot grant dealer access for this user! Reason: User has no BIR number'
    },
    SP_TICKET_ERR001: {
        id: 'error.spareParts.ticket.ticketLocked',
        defaultMessage: 'The ticket is locked for you for {lockMinutes} minutes and will be released at {lockTime}'
    },
    TD_TICKET_ERR001: {
        id: 'error.techDoc.ticket.ticketLocked',
        defaultMessage: 'The ticket is locked for you for {lockMinutes} minutes and will be released at {lockTime}'
    },
    CL_TICKET_ERR001: {
        id: 'error.claims.ticket.ticketLocked',
        defaultMessage: 'The ticket is locked for you for {lockMinutes} minutes and will be released at {lockTime}'
    },
    SP_TICKET_ERR002: {
        id: 'error.spareParts.ticket.updateFailed',
        defaultMessage: 'Ticket update failed. Ticket was not locked or someone else locked it during the time'
    },
    TD_TICKET_ERR002: {
        id: 'error.techDoc.ticket.updateFailed',
        defaultMessage: 'Ticket update failed. Ticket was not locked or someone else locked it during the time'
    },
    CL_TICKET_ERR002: {
        id: 'error.claims.ticket.updateFailed',
        defaultMessage: 'Ticket update failed. Ticket was not locked or someone else locked it during the time'
    },
    SP_TICKET_ERR003: {
        id: 'error.spareParts.ticket.ticketNotFound',
        defaultMessage: 'Ticket not found'
    },
    TD_TICKET_ERR003: {
        id: 'error.techDoc.ticket.ticketNotFound',
        defaultMessage: 'Ticket not found'
    },
    CL_TICKET_ERR003: {
        id: 'error.claims.ticket.ticketNotFound',
        defaultMessage: 'Ticket not found'
    },
    SP_DIC_ERR001: {
        id: 'error.spareParts.dic.connectionTimedOut',
        defaultMessage: 'Connection to DIC timed out'
    },
    CLAIMS_DIC_ERR001: {
        id: 'error.claims.dic.connectionTimedOut',
        defaultMessage: 'Connection to DIC timed out'
    },
    SP_DIC_ERR002: {
        id: 'error.spareParts.dic.invalidFormat',
        defaultMessage: 'Received DIC message has an invalid format'
    },
    CLAIMS_DIC_ERR002: {
        id: 'error.claims.dic.invalidFormat',
        defaultMessage: 'Received DIC message has an invalid format'
    },
    CLAIMS_SEND_DIC_MESSAGE_ERROR: {
        id: 'error.claims.dic.unexpected',
        defaultMessage: 'Unexpected error'
    },
    SP_DIC_ERR003: {
        id: 'error.spareParts.dic.failedToProcess',
        defaultMessage: 'Failed to process received DIC message'
    },
    BVM_ERR001: {
        id: 'error.bvm.cannotLoadData',
        defaultMessage: 'Cannot load data from BVM'
    },
    SP_LIST_ERR001: {
        id: 'error.spareParts.list.loadListFailed',
        defaultMessage: 'Failed to load a list of Spare Part Orders tickets'
    },
    SP_MODEL_ANSWERS_LIST_ERR001: {
        id: 'error.spareParts.modelAnswers.list.loadListFailed',
        defaultMessage: 'Failed to load a list of Solution group Model Answers'
    },
    TD_LIST_ERR001: {
        id: 'error.techDoc.list.loadListFailed',
        defaultMessage: 'Failed to load a list of Technical Documentation tickets'
    },
    TD_MODEL_ANSWERS_LIST_ERR001: {
        id: 'error.techDoc.modelAnswers.list.loadListFailed',
        defaultMessage: 'Failed to load a list of Solution group Model Answers'
    },
    CL_LIST_ERR001: {
        id: 'error.claims.list.loadListFailed',
        defaultMessage: 'Failed to load a list of Claims tickets'
    },
    SP_HISTORY_ERR001: {
        id: 'error.spareParts.history.loadHistoryFailed',
        defaultMessage: 'Failed to load a history of Spare Part Orders tickets'
    },
    TD_HISTORY_ERR001: {
        id: 'error.techDoc.history.loadHistoryFailed',
        defaultMessage: 'Failed to load a history of Technical Documentation tickets'
    },
    CL_HISTORY_ERR001: {
        id: 'error.claims.history.loadHistoryFailed',
        defaultMessage: 'Failed to load a history of Claims tickets'
    },
    SP_REC_PROB_ERR001: {
        id: 'error.spareParts.recurrentProblems.limit10Reached',
        defaultMessage: 'Limit of 20 recurrent problems for country {country} has been reached'
    },
    TD_REC_PROB_ERR001: {
        id: 'error.techDoc.recurrentProblems.limit10Reached',
        defaultMessage: 'Limit of 20 recurrent problems for country {country} has been reached'
    },
    CL_REC_PROB_ERR001: {
        id: 'error.claims.recurrentProblems.limit10Reached',
        defaultMessage: 'Limit of 20 recurrent problems for country {country} has been reached'
    },
    SP_VIN_DEALER_CHECK_ERR001: {
        id: 'error.spareParts.ticket.vorPviVinDealerCheck',
        defaultMessage: 'A HTL ticket is already being processed for the same VIN. In order to optimize the processing time of your demand, please use the HTL ticket still in progress n° {ticketNumber} and update it.',
    },
});

export default messages;
