import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'claims.fulltextSearch.title',
        defaultMessage: 'Fulltext Search'
    },
    VISIBLE_COLUMNS: {
        id: 'claims.fulltextSearch.label.visibleColumns',
        defaultMessage: 'Visible columns'
    },
    // Ticket section
    TICKET_SECTION: {
        id: 'claims.fulltextSearch.header.dealerInformation',
        defaultMessage: 'Dealer information'
    },
    DEALER_NAME: {
        id: 'claims.fulltextSearch.label.dealerName',
        defaultMessage: 'Dealer Name'
    },
    DEALER_NUMBER: {
        id: 'claims.fulltextSearch.label.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    NAME: {
        id: 'claims.fulltextSearch.label.name',
        defaultMessage: 'Creator Name'
    },
    CLIENT_ID: {
        id: 'claims.fulltextSearch.label.clientId',
        defaultMessage: 'Client ID'
    },
    REGION: {
        id: 'claims.fulltextSearch.label.region',
        defaultMessage: 'Region'
    },
    FROM: {
        id: 'claims.fulltextSearch.label.from',
        defaultMessage: 'Date From'
    },
    TO: {
        id: 'claims.fulltextSearch.label.to',
        defaultMessage: 'Date To'
    },
    LAST_EDITOR: {
        id: 'claims.fulltextSearch.label.lastEditor',
        defaultMessage: 'Last Editor'
    },
    FORM_TYPE: {
        id: 'claims.fulltextSearch.label.formType',
        defaultMessage: 'Type of Form'
    },
    STATUS: {
        id: 'claims.fulltextSearch.label.status',
        defaultMessage: 'Status'
    },
    TICKET_NUMBER: {
        id: 'claims.fulltextSearch.label.ticketNumber',
        defaultMessage: 'Ticket Number'
    },
    // Problem description section
    PROBLEM_DESCRIPTION_SECTION: {
        id: 'claims.fulltextSearch.header.problemDescriptionSection',
        defaultMessage: 'Problem Description'
    },
    CLAIM_RELATED: {
        id: 'claims.fulltextSearch.label.claimRelated',
        defaultMessage: 'Claim Related'
    },
    INVOICE: {
        id: 'claims.fulltextSearch.label.invoice',
        defaultMessage: 'Invoice No.'
    },
    MISSING_PACKAGE: {
        id: 'claims.fulltextSearch.label.missingPackage',
        defaultMessage: 'Missing Package No.'
    },
    WRONG_DESTINATION: {
        id: 'claims.fulltextSearch.label.wrongDestination',
        defaultMessage: 'Package Destination Error No.'
    },
    PART_NUMBER: {
        id: 'claims.fulltextSearch.label.partNo',
        defaultMessage: 'Part No.'
    },
    PACKAGE_NO: {
        id: 'claims.fulltextSearch.label.packageNo',
        defaultMessage: 'Package No.'
    },
    TAKING_BACK: {
        id: 'claims.fulltextSearch.label.takingBack',
        defaultMessage: 'Taking Back No.'
    },
    CONTAINER: {
        id: 'claims.fulltextSearch.label.container',
        defaultMessage: 'Container No.'
    },
    CLIENT_SOLUTION_PILOT: {
        id: 'claims.fulltextSearch.label.clientSolutionPilot',
        defaultMessage: 'Client Solution Pilot'
    },
    // Search option section
    SEARCH_OPTION_SECTION: {
        id: 'claims.fulltextSearch.header.searchOption',
        defaultMessage: 'Searching option'
    },
    FULLTEXT_SEARCH: {
        id: 'claims.fulltextSearch.label.fullText',
        defaultMessage: 'Fulltext'
    },
    FULL_DATABASE: {
        id: 'claims.fulltextSearch.label.fullDatabase',
        defaultMessage: 'Full database'
    },
    INTERNATIONAL: {
        id: 'claims.fulltextSearch.label.international',
        defaultMessage: 'International Search'
    },
    // Table
    TABLE_CREATED_BY: {
        id: 'claims.fulltextSearch.table.createdBy',
        defaultMessage: 'Created by (IPN)'
    },
    TABLE_ORDER_CREATION_TIME: {
        id: 'claims.fulltextSearch.table.creationDate',
        defaultMessage: 'Creation Date'
    },
    TABLE_CLIENT_ID: {
        id: 'claims.fulltextSearch.table.clientId',
        defaultMessage: 'Client ID'
    },
    TABLE_REG_DIR: {
        id: 'claims.fulltextSearch.table.regDir',
        defaultMessage: 'Region'
    },
    TABLE_DEALER_NUMBER: {
        id: 'claims.fulltextSearch.table.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    TABLE_DEALER_NAME: {
        id: 'claims.fulltextSearch.table.dealerName',
        defaultMessage: 'Dealer Name'
    },
    TABLE_FORM_TYPE: {
        id: 'claims.fulltextSearch.table.formType',
        defaultMessage: 'Type of Form'
    },
    TABLE_STATUS: {
        id: 'claims.fulltextSearch.table.status',
        defaultMessage: 'Status'
    },
    TABLE_LAST_STATUS_DATE: {
        id: 'claims.fulltextSearch.table.lastStatusDate',
        defaultMessage: 'Last Status Date'
    },
    TABLE_LAST_EDITOR: {
        id: 'claims.fulltextSearch.table.lastEditor',
        defaultMessage: 'Last Editor'
    },
    TABLE_CLAIM_RELATED: {
        id: 'claims.fulltextSearch.table.claimRelatedNo',
        defaultMessage: 'Claim Related No.'
    },
    TABLE_INVOICE: {
        id: 'claims.fulltextSearch.table.invoiceNo',
        defaultMessage: 'Invoice No.'
    },
    TABLE_MISSING_PACKAGE: {
        id: 'claims.fulltextSearch.table.missingPackageNo',
        defaultMessage: 'Missing Package No.'
    },
    TABLE_WRONG_DESTINATION: {
        id: 'claims.fulltextSearch.table.wrongDestinationNo',
        defaultMessage: 'Package Destination Error No.'
    },
    TABLE_PART_NO: {
        id: 'claims.fulltextSearch.table.partNo',
        defaultMessage: 'Part No.'
    },
    TABLE_PACKAGE_NO: {
        id: 'claims.fulltextSearch.table.packageNo',
        defaultMessage: 'Package No.'
    },
    TABLE_TAKING_BACK: {
        id: 'claims.fulltextSearch.table.takingBackNo',
        defaultMessage: 'Taking Back No.'
    },
    TABLE_CONTAINER: {
        id: 'claims.fulltextSearch.table.containerNo',
        defaultMessage: 'Container No.'
    },
    TABLE_TICKET_NUMBER: {
        id: 'claims.fulltextSearch.table.ticketNo',
        defaultMessage: 'Ticket No.'
    },
    TABLE_CLIENT_SOLUTION_PILOT: {
        id: 'claims.fulltextSearch.table.clientSolutionPilot',
        defaultMessage: 'Client Solution Pilot'
    },
    SUPPLIER: {
        id: 'claims.ticket.table.supplier',
        defaultMessage: 'Supplier'
    },
});

export default messages;
