import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    clearSGEditorRightsManagementPageData,
    fetchSGEditorRightsManagementPageData,
    saveSGEditorRightsManagementPageData,
    setSGEditorOptionsChanged,
    setInitialSGEditorOptions,
    setManagedUser,
    setSelectedSGEditorOptions,
} from '../../../actions/admin/actionSolutionGroupUserRightsManagement';
import SelectUser from './SelectUser';
import {clearAlerts} from '../../../actions/alertsActions';
import SelectUserSolutionGroups from './SelectUserSolutionGroups';
import Loader from '../../Loader';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import {handleUserSearch} from '../../../utils/userUtils';
import PageHeader from '../../common/PageHeader';
import {
    clearDealerRightsManagementPageData,
    clearEditorRightsManagementPageData, fetchDealerRightsManagementPageData,
} from '../../../actions/admin/actionAdminRightsManagement';
import errorMessages from '../../../intl/common/errorMessages';
import {fetchEscalationSchemaSGOptions} from '../../../actions/admin/actionEscalationSchemaOptions';

const Panel = styled.h1`
  font-size: 1.5em;
  text-align: center;
`;

class SolutionGroupUserRightsManagementPage extends Component {
    componentDidMount() {
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setSGEditorOptionsChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleSolutionGroupSelectionChange = (newOptions, { action, removedValue }) => {
        this.props.setSGEditorOptionsChanged(true);
        this.props.setSelectedSGEditorOptions(newOptions.filter(o => o.isFixed !== true));

    };

    handleAllSolutionGroupsClick = () => {
        this.props.setSGEditorOptionsChanged(true)
        this.props.setSelectedSGEditorOptions(this.props.solutionGroupEditorData);
    };

    handleSaveClick = () => {
        const user = this.props.managedUser;
        const domain = this.props.domain;
        const options = this.props.selectedSolutionGroupEditorOptions.concat(this.props.fixedSolutionGroupEditorOptions);
        this.props.saveSGEditorRightsManagementPageData(user, domain, options);
    };

    handleCancelClick = () => {
        this.props.setSelectedSGEditorOptions(this.props.initialSolutionGroupUserOptions);
        this.props.setSGEditorOptionsChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearDealerRightsManagementPageData();
        this.props.fetchDealerRightsManagementPageData(user, this.props.domain);
        this.props.fetchEscalationSchemaSGOptions(this.props.domain);
        this.props.clearEditorRightsManagementPageData();
        this.props.clearSGEditorRightsManagementPageData();
        this.props.fetchSGEditorRightsManagementPageData(user, this.props.domain);
    };

    render() {
        const {isLoading, managedUser, solutionGroupEditorData, domain, isDealer } = this.props;
        if (isLoading) {
            return <Loader/>;
        }

        console.log('this.props');
        console.log(this.props);

        return (
            <div>
                <PageHeader title={
                    <FormattedMessage id="admin.title.auth.mng.solutionGroup.user"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - User Management"
                                      values={{domain: <FormattedMessage {...formTypeMessages[domain]}/>}}/>
                }/>
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch}/>
                </div>
                {isDealer &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.DEALER_ROLE_SET}/>
                </Panel>
                }
                {Object.keys(solutionGroupEditorData).length > 0 && managedUser && managedUser.ipn && !isDealer &&
                <SelectUserSolutionGroups handleSolutionGroupSelectionChange={this.handleSolutionGroupSelectionChange}
                                          handleAllSolutionGroupsClick={this.handleAllSolutionGroupsClick}
                                          handleSaveClick={this.handleSaveClick}
                                          handleCancelClick={this.handleCancelClick}/>
                }
            </div>
        );
    }
}

SolutionGroupUserRightsManagementPage.propTypes = {
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    setSelectedSGEditorOptions: PropTypes.func.isRequired,
    solutionGroupEditorData: PropTypes.object.isRequired,
    selectedSolutionGroupEditorOptions: PropTypes.array,
    fixedSolutionGroupEditorOptions: PropTypes.array,
    saveSGEditorRightsManagementPageData: PropTypes.func.isRequired,
    initialSolutionGroupUserOptions: PropTypes.array.isRequired,
    setSGEditorOptionsChanged: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    clearSGEditorRightsManagementPageData: PropTypes.func.isRequired,
    fetchSGEditorRightsManagementPageData: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    setInitialSGEditorOptions: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isDealer: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => ({
    isLoading: state.adminRightsManagement.isLoading,
    managedUser: state.adminRightsManagement.managedUser,
    solutionGroupEditorData: state.adminRightsManagement.solutionGroupEditorData,
    isDealer: Object.values(state.adminRightsManagement.dealerData || {}).some(value => value),
    loggedUser: state.profile.userDetail,
    initialSolutionGroupUserOptions: state.adminRightsManagement.initialSolutionGroupUserOptions,
    selectedSolutionGroupEditorOptions: state.adminRightsManagement.selectedSolutionGroupEditorOptions,
    fixedSolutionGroupEditorOptions: state.adminRightsManagement.fixedSolutionGroupEditorOptions,
});

export default connect(mapStateToProps, {
    setManagedUser,
    clearAlerts,
    clearSGEditorRightsManagementPageData,
    clearEditorRightsManagementPageData,
    fetchSGEditorRightsManagementPageData,
    clearDealerRightsManagementPageData,
    fetchDealerRightsManagementPageData,
    setInitialSGEditorOptions,
    setSelectedSGEditorOptions,
    setSGEditorOptionsChanged,
    saveSGEditorRightsManagementPageData,
    fetchEscalationSchemaSGOptions,
})(injectIntl(SolutionGroupUserRightsManagementPage));
