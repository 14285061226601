import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    SP_DIC_WARN001: {
        id: 'warnings.dic.alreadyExists',
        defaultMessage: 'DIC already exists for the VIN {vinNumber}'
    },
    SP_DIC_WARN002: {
        id: 'warnings.dic.descriptionAlreadyExists',
        defaultMessage: 'Spare Part Order no. {partNumber} description already exists. To fill the description from the DIC please delete the existing one'
    },
    SP_DIC_WARN003: {
        id: 'warnings.dic.sparePartOrderNoLongerExists',
        defaultMessage: 'Spare Part Order no. {partNumber} no longer exists in this ticket'
    },
    SP_DIC_WARN004: {
        id: 'warnings.dic.managementGroupAlreadyExists',
        defaultMessage: 'Spare Part Order no. {partNumber} management group already exists. To fill the management group from the DIC please delete the existing one'
    },
    SP_DIC_WARN005: {
        id: 'warnings.dic.nonVorPart',
        defaultMessage: 'Impossible to create DIC for non VOR Part no.'
    },
    BVM_VIN_WARN001: {
        id: 'warnings.bvm.dataNotFound',
        defaultMessage: 'No data found in BVM for the VIN {vinNumber}'
    },
    SP_ORDERS_WARN001: {
        id: 'warnings.orders.mpr88ManuallyEdited',
        defaultMessage: 'Manually edited'
    },
    SP_ORDERS_WARN002: {
        id: 'warnings.orders.vorPviVinDealerCheck',
        defaultMessage: 'A HTL ticket is already being processed for the same VIN. In order to optimize the processing time of your demand, please use the HTL ticket still in progress n° {ticketNumber} and update it.'
    },
    TD_DIC_WARN001: {
        id: 'warnings.dic.techDocAlreadyExists',
        defaultMessage: 'DIC already exists for the VIN {vinNumber}'
    },
    TD_DIC_WARN002: {
        id: 'warnings.dic.techDocDescriptionAlreadyExists',
        defaultMessage: 'Part no. {partNumber} description already exists. To fill the description from the DIC please delete the existing one'
    },
    TD_DIC_WARN003: {
        id: 'warnings.dic.techDocPartOrderNoLongerExists',
        defaultMessage: 'Part no. {partNumber} no longer exists in this ticket'
    },
    TD_DIC_WARN004: {
        id: 'warnings.dic.techDocManagementGroupAlreadyExists',
        defaultMessage: 'Part no. {partNumber} management group already exists. To fill the management group from the DIC please delete the existing one'
    },
    TD_DIC_WARN005: {
        id: 'warnings.dic.techDocPartOrderNoMustBeFilled',
        defaultMessage: 'Part no. must be filled to fetch Management Group'
    },
    BVM_VIN_WARN002: {
        id: 'warnings.bvm.deliveryDateOlderThan',
        defaultMessage: 'Dear user, it is not possible to create VOR ticket for vehicle older than {years} years'
    },
});

export default messages;
