import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {domains} from '../../constants/Utils';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const fetchSolutionGroupListPageData = (domain, pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            dispatch({
                type: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUPS_LIST_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUPS_LIST,
                payload: {pageSize: pageSize, sorted: sorted, cursor: cursor, isNextFromPivot: isNextFromPivot, filtered: filtered}
            });
            break;
        }
        case domains.SPARE_PARTS: {
            dispatch({
                type: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUPS_LIST_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUPS_LIST,
                payload: {pageSize: pageSize, sorted: sorted, cursor: cursor, isNextFromPivot: isNextFromPivot, filtered: filtered}
            });
            break;
        }
    }
};
