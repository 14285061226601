export const claimsWebsocketCommands = {
    // route (commands)
    CLAIMS_HISTORY_GET: 'getHistory',
    CLAIMS_LIST_GET: 'getList',
    CLAIMS_POPUP_GET: 'getPopUp',
    CLAIMS_POPUP_SET: 'setPopUp',
    CLAIMS_RECURRENT_PROBLEMS_GET: 'getRecurrentProblem',
    CLAIMS_RECURRENT_PROBLEMS_CREATE: 'createRecurrentProblem',
    CLAIMS_RECURRENT_PROBLEMS_MOVE: 'moveRecurrentProblem',
    CLAIMS_RECURRENT_PROBLEMS_DELETE: 'deleteRecurrentProblem',
    CLAIMS_RECURRENT_PROBLEMS_UPDATE: 'updateRecurrentProblem',
    CLAIMS_RECURRENT_PROBLEMS_FILE_DOWNLOAD: 'recurrentProblemAttachmentDownload',
    CLAIMS_RECURRENT_PROBLEMS_FILE_UPLOAD: 'recurrentProblemAttachmentUpload',
    CLAIMS_USER_LIST_PREFERENCE_SET: 'setUserListPreference',
    CLAIMS_USER_HISTORY_PREFERENCE_SET: 'setUserHistoryPreference',
    CLAIMS_USER_FULL_TEXT_SEARCH_PREFERENCE_SET: 'setUserFullTextSearchPreference',
    CLAIMS_USER_STATISTICS_PREFERENCE_SET: 'setUserStatisticsPreference',
    CLAIMS_PREFERENCE_GET: 'getPreferences',
    CLAIMS_DEALERS_GET: 'getDealers',
    CLAIMS_EXPORT_GET: 'getExport',
    CLAIMS_EXPORT_CREATE: 'createTicketExport',
    CLAIMS_EXPORT_DOWNLOAD_URL_GET: 'exportDownloadPreSignedUrl',
    CLAIMS_FULL_TEXT_SEARCH_GET: 'getFullTextSearch',
    CLAIMS_FULL_TEXT_SEARCH_LAST_EDITORS_GET: 'getLastEditors',

    CLAIMS_ADMIN_ROLE_GET: 'getAdminRole',
    CLAIMS_ADMIN_ROLE_SET: 'setAdminRole',
    CLAIMS_DEALER_ROLE_GET: 'getDealerRole',
    CLAIMS_DEALER_ROLE_SET: 'setDealerRole',
    CLAIMS_EDITOR_ROLE_GET: 'getEditorRole',
    CLAIMS_EDITOR_ROLE_SET: 'setEditorRole',
    CLAIMS_NOTIFICATIONS_GET: 'getEmailNotification',
    CLAIMS_NOTIFICATIONS_SET: 'setEmailNotification',
    CLAIMS_MAIN_MESSAGES_GET: 'getGroupsMainMessages',
    CLAIMS_MAIN_MESSAGE_GET: 'getMainMessage',
    CLAIMS_MAIN_MESSAGE_SET: 'setMainMessage',
    CLAIMS_TICKET_SETTINGS_GET: 'getTicketSettings',
    CLAIMS_TICKET_SETTINGS_SET: 'setTicketSettings',
    CLAIMS_DEFAULT_DEALER_ROLE_GET: 'getDefaultDealerRole',
    CLAIMS_DEFAULT_DEALER_ROLE_SET: 'setDefaultDealerRole',
    CLAIMS_WORKING_HOURS_GET: 'getWorkingHours',
    CLAIMS_WORKING_HOURS_SET: 'setWorkingHours',

    // receive
    CLAIMS_TICKET_SEND: 'CLAIMS_TICKET_SEND',
    CLAIMS_TICKET_SEND_ERROR: 'TCLAIMSTICKET_SEND_ERROR',
    CLAIMS_TICKET_LIST_SEND: 'CLAIMS_TICKET_LIST_SEND',
    CLAIMS_TICKET_LIST_SEND_ERROR: 'CLAIMS_TICKET_LIST_SEND_ERROR',
    CLAIMS_TICKET_HISTORY_SEND: 'CLAIMS_TICKET_HISTORY_SEND',
    CLAIMS_TICKET_HISTORY_SEND_ERROR: 'CLAIMS_TICKET_HISTORY_SEND_ERROR',
    CLAIMS_GENERATED_PRESIGNED_URLS: 'CLAIMS_GENERATED_PRESIGNED_URLS',
    CLAIMS_TICKET_CREATED: 'CLAIMS_TICKET_CREATED',
    CLAIMS_TICKET_CREATE_ERROR: 'CLAIMS_TICKET_CREATE_ERROR',
    CLAIMS_TICKET_LOCKED: 'CLAIMS_TICKET_LOCKED',
    CLAIMS_TICKET_LOCKED_ERROR: 'CLAIMS_TICKET_LOCKED_ERROR',
    CLAIMS_TICKET_UNLOCKED: 'CLAIMS_TICKET_UNLOCKED',
    CLAIMS_TICKET_UNLOCKED_ERROR: 'CLAIMS_TICKET_UNLOCKED_ERROR',
    CLAIMS_TICKET_ATTACHMENTS_SAVED: 'CLAIMS_TICKET_ATTACHMENTS_SAVED',
    CLAIMS_TICKET_ATTACHMENTS_SAVED_ERROR: 'CLAIMS_TICKET_ATTACHMENTS_SAVED_ERROR',
    CLAIMS_TICKET_ATTACHMENTS_DOWNLOAD: 'CLAIMS_TICKET_ATTACHMENTS_DOWNLOAD',
    CLAIMS_TICKET_ATTACHMENTS_DOWNLOAD_ERROR: 'CLAIMS_TICKET_ATTACHMENTS_DOWNLOAD_ERROR',
    CLAIMS_TICKET_UPDATED: 'CLAIMS_TICKET_UPDATED',
    CLAIMS_TICKET_UPDATE_ERROR: 'CLAIMS_TICKET_UPDATE_ERROR',
    CLAIMS_ROLES_ACCEPTED: 'CLAIMS_ROLES_ACCEPTED',
    CLAIMS_ROLES_ERROR: 'CLAIMS_ROLES_ERROR',
    CLAIMS_ADMIN_ROLE_SEND: 'CLAIMS_ADMIN_ROLE_SEND',
    CLAIMS_ADMIN_ROLE_SEND_ERROR: 'CLAIMS_ADMIN_ROLE_SEND_ERROR',
    CLAIMS_DEALER_ROLE_SEND: 'CLAIMS_DEALER_ROLE_SEND',
    CLAIMS_DEALER_ROLE_SEND_ERROR: 'CLAIMS_DEALER_ROLE_SEND_ERROR',
    CLAIMS_EDITOR_ROLE_SEND: 'CLAIMS_EDITOR_ROLE_SEND',
    CLAIMS_EDITOR_ROLE_SEND_ERROR: 'CLAIMS_EDITOR_ROLE_SEND_ERROR',
    CLAIMS_NOTIFICATIONS_ACCEPTED: 'CLAIMS_NOTIFICATIONS_ACCEPTED',
    CLAIMS_NOTIFICATIONS_ERROR: 'CLAIMS_NOTIFICATIONS_ERROR',
    CLAIMS_NOTIFICATIONS_SEND: 'CLAIMS_NOTIFICATIONS_SEND',
    CLAIMS_NOTIFICATIONS_SEND_ERROR: 'CLAIMS_NOTIFICATIONS_SEND_ERROR',
    CLAIMS_WORKING_HOURS_ACCEPTED: 'CLAIMS_WORKING_HOURS_ACCEPTED',
    CLAIMS_WORKING_HOURS_ERROR: 'CLAIMS_WORKING_HOURS_ERROR',
    CLAIMS_WORKING_HOURS_SEND: 'CLAIMS_WORKING_HOURS_SEND',
    CLAIMS_WORKING_HOURS_SEND_ERROR: 'CLAIMS_WORKING_HOURS_SEND_ERROR',
    CLAIMS_RECURRENT_PROBLEMS_ACCEPTED: 'CLAIMS_RECURRENT_PROBLEMS_ACCEPTED',
    CLAIMS_RECURRENT_PROBLEMS_ERROR: 'CLAIMS_RECURRENT_PROBLEMS_ERROR',
    CLAIMS_RECURRENT_PROBLEMS_SEND: 'CLAIMS_RECURRENT_PROBLEMS_SEND',
    CLAIMS_RECURRENT_PROBLEMS_SEND_ERROR: 'CLAIMS_RECURRENT_PROBLEMS_SEND_ERROR',
    CLAIMS_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD: 'CLAIMS_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD',
    CLAIMS_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD_ERROR: 'CLAIMS_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD_ERROR',
    CLAIMS_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD: 'CLAIMS_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD',
    CLAIMS_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD_ERROR: 'CLAIMS_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD_ERROR',
    CLAIMS_MAIN_MESSAGES_ACCEPTED: 'CLAIMS_MAIN_MESSAGES_ACCEPTED',
    CLAIMS_MAIN_MESSAGES_ERROR: 'CLAIMS_MAIN_MESSAGES_ERROR',
    CLAIMS_MAIN_MESSAGES_SEND: 'CLAIMS_MAIN_MESSAGES_SEND',
    CLAIMS_MAIN_MESSAGES_SEND_ERROR: 'CLAIMS_MAIN_MESSAGES_SEND_ERROR',
    CLAIMS_GROUPS_MAIN_MESSAGES_SEND: 'CLAIMS_GROUPS_MAIN_MESSAGES_SEND',
    CLAIMS_GROUPS_MAIN_MESSAGES_SEND_ERROR: 'CLAIMS_GROUPS_MAIN_MESSAGES_SEND_ERROR',
    CLAIMS_POPUP_ACCEPTED: 'CLAIMS_POPUP_ACCEPTED',
    CLAIMS_POPUP_ERROR: 'CLAIMS_POPUP_ERROR',
    CLAIMS_POPUP_SEND: 'CLAIMS_POPUP_SEND',
    CLAIMS_POPUP_SEND_ERROR: 'CLAIMS_POPUP_SEND_ERROR',
    CLAIMS_TICKET_SETTINGS_ACCEPTED: 'CLAIMS_TICKET_SETTINGS_ACCEPTED',
    CLAIMS_TICKET_SETTINGS_ERROR: 'CLAIMS_TICKET_SETTINGS_ERROR',
    CLAIMS_TICKET_SETTINGS_SEND: 'CLAIMS_TICKET_SETTINGS_SEND',
    CLAIMS_TICKET_SETTINGS_SEND_ERROR: 'CLAIMS_TICKET_SETTINGS_SEND_ERROR',
    CLAIMS_DEFAULT_DEALER_ROLES_SEND: 'CLAIMS_DEFAULT_DEALER_ROLES_SEND',
    CLAIMS_DEFAULT_DEALER_ROLES_SEND_ERROR: 'CLAIMS_DEFAULT_DEALER_ROLES_SEND_ERROR',
    CLAIMS_PREFERENCES_ACCEPTED: 'CLAIMS_PREFERENCES_ACCEPTED',
    CLAIMS_PREFERENCES_ERROR: 'CLAIMS_PREFERENCES_ERROR',
    CLAIMS_PREFERENCES_SEND: 'CLAIMS_PREFERENCES_SEND',
    CLAIMS_PREFERENCES_SEND_ERROR: 'CLAIMS_PREFERENCES_SEND_ERROR',
    CLAIMS_DEALERS_SEND: 'CLAIMS_DEALERS_FETCH_SUCCESS',
    CLAIMS_DEALERS_SEND_ERROR: 'CLAIMS_DEALERS_FETCH_ERROR',
    CLAIMS_EXPORT_SEND: 'CLAIMS_EXPORT_FETCH_SUCCESS',
    CLAIMS_EXPORT_SEND_ERROR: 'CLAIMS_EXPORT_FETCH_ERROR',
    CLAIMS_EXPORT_STATUS: 'CLAIMS_EXPORT_STATUS',
    CLAIMS_EXPORT_ERROR: 'CLAIMS_EXPORT_ERROR',
    CLAIMS_EXPORT_DOWNLOAD_URL_SEND: 'CLAIMS_EXPORT_DOWNLOAD',
    CLAIMS_EXPORT_DOWNLOAD_URL_SEND_ERROR: 'CLAIMS_EXPORT_DOWNLOAD_ERROR',
    CLAIMS_FULL_TEXT_SEARCH_SEND: 'CLAIMS_FULL_TEXT_SEARCH_FETCH_SUCCESS',
    CLAIMS_FULL_TEXT_SEARCH_SEND_ERROR: 'CLAIMS_FULL_TEXT_SEARCH_FETCH_ERROR',
    CLAIMS_FULL_TEXT_SEARCH_LAST_EDITORS_SEND: 'CLAIMS_FULL_TEXT_SEARCH_LAST_EDITORS_FETCH_SUCCESS',
    CLAIMS_FULL_TEXT_SEARCH_LAST_EDITORS_SEND_ERROR: 'CLAIMS_FULL_TEXT_SEARCH_LAST_EDITORS_FETCH_ERROR',
    CLAIMS_DIC_VIN_STATUS_GET: 'getVinDicStatus',
    CLAIMS_DIC_PART_INFO_GET: 'getPartInfo',
    CLAIMS_DIC_PART_INFO_SEND: 'CLAIMS_DIC_PART_INFO_SEND',
    CLAIMS_DIC_PART_INFO_ERROR: 'CLAIMS_DIC_PART_INFO_ERROR',
    // PRESET RESPONSE
    CLAIMS_PRESET_RESPONSE_GET: 'getPresetResponses',
    CLAIMS_PRESET_RESPONSE_CREATE: 'createPresetResponse',
    CLAIMS_PRESET_RESPONSE_MOVE: 'movePresetResponse',
    CLAIMS_PRESET_RESPONSE_DELETE: 'deletePresetResponse',
    CLAIMS_PRESET_RESPONSE_UPDATE: 'updatePresetResponse',
    // PRESET RESPONSE
    CLAIMS_PRESET_RESPONSES_ERROR: 'CLAIMS_PRESET_RESPONSES_ERROR',
    CLAIMS_PRESET_RESPONSES_SEND: 'CLAIMS_PRESET_RESPONSES_SEND',
    CLAIMS_PRESET_RESPONSES_SEND_ERROR: 'CLAIMS_PRESET_RESPONSES_SEND_ERROR',

}
