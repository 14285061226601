import React from 'react';
import styled from 'styled-components';
import {FormSection, formValueSelector, reduxForm} from 'redux-form'
import SubmitButtons from './sections/SubmitButtons';
import { scrollContentWrapperToTop } from '../../../../../utils/utils';
import formMessages from '../../../../../intl/admin/escalationSchemaMessages';
import {
    ANSWERING_SCHEMA_FORM,
    FORM_ANSWERING_SCHEMA_ANSWERING_SECTION,
    FORM_ANSWERING_SCHEMA_INFO_SECTION,
    FORM_FIELD_AS_SG_NAME,
} from '../../../../../constants/formConstants';
import RenderSyncErrors from '../../../../common/RenderSyncErrors'
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import AnsweringInfoSection from './sections/info/AnsweringInfoSection';
import {intlAnsweringSchemaFieldMapping} from '../../../../../constants/answeringSchemaFieldLabelMapping';
import AnsweringSection from './sections/answering/AnsweringSection';


const StyledDiv = styled.div`
  border-radius: 10px;
`;

const AnsweringSchemaForm = (props) => {
    const { form, reqFields, readOnly, edit, handleSubmit, domain, selectedSG } = props;

    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form}
                              intlMessages={formMessages}
                              mapping={intlAnsweringSchemaFieldMapping}/>
            <FormSection name={FORM_ANSWERING_SCHEMA_INFO_SECTION}>
                <AnsweringInfoSection form={form}
                             domain={domain}
                             reqFields={reqFields}
                             readOnly={readOnly || edit}/>
            </FormSection>
            { selectedSG &&
                <FormSection name={FORM_ANSWERING_SCHEMA_ANSWERING_SECTION}>
                    <AnsweringSection form={form}
                                 domain={domain}
                                 reqFields={reqFields}
                                 readOnly={readOnly}/>
                </FormSection>
            }
            <SubmitButtons form={form}
                           reqFields={reqFields}
                           readOnly={readOnly}
                           onSubmit={handleSubmit}
                           onSubmitFail={scrollContentWrapperToTop}/>
        </StyledDiv>
    )
};

const mapStateToProps = (state, props) => ({
    selectedSG: formValueSelector(props.form)(state, `${FORM_ANSWERING_SCHEMA_INFO_SECTION}.${FORM_FIELD_AS_SG_NAME}`),
});

export default reduxForm({
    form: ANSWERING_SCHEMA_FORM,
})(injectIntl(connect(mapStateToProps)(AnsweringSchemaForm)));

