import React from 'react';
import get from 'get-value';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {
    FORM_FIELD_EDITORS_NOTES_TD, FORM_LABEL_EDITORS_NOTES_HISTORY_TD,
    FORM_FIELD_MESSAGE_OPTION,
    FORM_EDITORS_NOTES_SECTION_TD,
    FORM_LABEL_EDITORS_NOTES_TD,
    FORM_LABEL_MESSAGE
} from '../../../../../../constants/formConstants';
import {FormattedDate, FormattedMessage, FormattedTime, injectIntl} from 'react-intl';
import formMessages from '../../../../../../intl/technicalDocumentations/formMessages';
import {connect} from 'react-redux';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import styled from 'styled-components';
import {getFormInitialValues, change} from 'redux-form';
import commonMessages from '../../../../../../intl/common/commonMessages';
import {parseTextToJSX} from '../../../../../../utils/utils';
import InputSelectFieldWithValidation from "../../../../../common/InputSelectFieldWithValidation";

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const EditorsNotesSectionTD = props => {

    const {userRights, readOnly, initialValues, modelAnswers, change} = props;

    const editorsNotes = get(initialValues, 'editorsNotes', []);
    const hasNotes = editorsNotes && editorsNotes.length > 0;
    const canAddNotes = !readOnly && userRights.canAddEditorsNotes;
    const isDialogBox = !!modelAnswers && modelAnswers.length > 0;

    const handleChange = (e) => {
        if (e.target.value !== '' && e.target.value !== undefined && e.target.value !== null && modelAnswers) {
            const message = modelAnswers.find( answer => answer.uuid === e.target.value );
            change(FORM_FIELD_EDITORS_NOTES_TD, get(message, 'text'));
        }
    };

    const getCreatorData = (message) => {
        const {creatorName, solutionGroup, creatorIpn} = message;
        const solutionGroupName = solutionGroup ? ' ' + solutionGroup.name + ' ' : ' ';
        return `${creatorName}${solutionGroupName}(${creatorIpn})`;
    }

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.EDITORS_NOTES_SECTION_TD}/>
            </h3>
            {(hasNotes || canAddNotes) && <StyledDiv className="border border-secondary bg-white overflow-hidden">
                {hasNotes && <div>
                    <div className="border-bottom border-bottom-secondary"
                         style={{backgroundColor: '#e9ecef', borderRadius: '5px 5px 0 0'}}>
                        <label className="col-form-label pl-0 font-weight-bold ml-3">
                            <ValidFormattedMessage message={FORM_LABEL_EDITORS_NOTES_HISTORY_TD}
                                                   intlMessages={formMessages}/>
                        </label>
                    </div>
                    {editorsNotes.map((editorsNote, index) =>
                        <div key={index} className="row m-0 align-items-stretch">
                            <div style={{backgroundColor: '#f8f9fa'}}
                                 className="col-sm-12 col-lg-4 flex-column border-bottom">
                                <div>
                                    <FormattedDate value={new Date(editorsNote.created)}/> <FormattedTime
                                    value={new Date(editorsNote.created)}/>
                                    <span className="font-weight-bold">{' '}
                                        <ValidFormattedMessage message={editorsNote.isDealer ? 'DEALER' : 'EDITOR'}
                                                               intlMessages={commonMessages}/>
                                        </span>
                                </div>
                                <div>
                                    {getCreatorData(editorsNote)}
                                </div>
                            </div>
                            <div className="col border-bottom" style={{whiteSpace: 'pre-wrap'}}>
                                {parseTextToJSX(editorsNote.text)}
                            </div>
                        </div>
                    )}
                </div>}
                {canAddNotes && <div className="col-sm-12 px-4 pb-3 pt-1">
                    {isDialogBox &&
                        <div className="col-xl-4 col-lg-6 col-md-12 px-0 pb-2">
                            <InputSelectFieldWithValidation
                                label={formMessages[FORM_LABEL_MESSAGE]}
                                field={FORM_FIELD_MESSAGE_OPTION}
                                customOptionsMsg={modelAnswers.map(e => ({
                                    uuid: e.uuid,
                                    presetName: e.subject
                                }))}
                                handleChange={handleChange}
                                boldTooltip
                            />
                        </div>
                    }
                    <div className="col-sm-12 px-0">
                        <TextareaFieldWithValidation label={!isDialogBox ? formMessages[FORM_LABEL_EDITORS_NOTES_TD] : undefined}
                                                     field={FORM_FIELD_EDITORS_NOTES_TD}
                                                     boldTooltip
                                                     rows={3}/>
                    </div>
                </div>}
            </StyledDiv>}
        </>
    );
};

const getModelAnswers = (state, schemaType) => {
    if (!state.solutionGroupInfo || !state.solutionGroupInfo[schemaType]) {
        return undefined;
    }
    return get(state, `solutionGroupInfo.${schemaType}`, {default: []}).filter(answer => answer.displayArea === 'INTERNAL_NOTES');
}

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
    modelAnswers: getModelAnswers(state.techDocTicket, 'modelAnswers'),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, FORM_EDITORS_NOTES_SECTION_TD + '.' + name, value)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditorsNotesSectionTD));
