//SP Ticket statuses
export const ORDER_TICKET_STATUS_NEW = 'NEW';
export const ORDER_TICKET_STATUS_IN_PROGRESS_DEALER = 'WAIT_DEALER';
export const ORDER_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY = 'WAIT_SUBSIDIARY';
export const ORDER_TICKET_STATUS_READ_BY_DEALER = 'READ_BY_DEALER';
export const ORDER_TICKET_STATUS_READ_BY_SUBSIDIARY = 'READ_BY_SUBSIDIARY';
export const ORDER_TICKET_STATUS_REOPENED = 'REOPEN';
export const ORDER_TICKET_STATUS_CLOSED = 'CLOSED';
export const ORDER_TICKET_STATUS_TRANSFERRED = 'TRANSFERRED';
// only for SP internal ticket
export const ORDER_INTERNAL_TICKET_STATUS_NEW = 'NEW';
export const ORDER_INTERNAL_TICKET_STATUS_CLOSED = 'CLOSED';
export const ORDER_INTERNAL_TICKET_STATUS_TRANSFERRED = 'TRANSFERRED';
export const ORDER_INTERNAL_TICKET_STATUS_ANSWERED = 'ANSWERED';
export const ORDER_INTERNAL_TICKET_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const ORDER_INTERNAL_TICKET_STATUS_REOPENED = 'REOPENED';

//TD Ticket statuses
export const DOCUMENTATION_TICKET_STATUS_NEW = 'NEW';
export const DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_DEALER = 'WAIT_DEALER';
export const DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY = 'WAIT_SUBSIDIARY';
export const DOCUMENTATION_TICKET_STATUS_READ_BY_DEALER = 'READ_BY_DEALER';
export const DOCUMENTATION_TICKET_STATUS_READ_BY_SUBSIDIARY = 'READ_BY_SUBSIDIARY';
export const DOCUMENTATION_TICKET_STATUS_REOPENED = 'REOPEN';
export const DOCUMENTATION_TICKET_STATUS_CLOSED = 'CLOSED';
export const DOCUMENTATION_TICKET_STATUS_TRANSFERRED = 'TRANSFERRED';
// only for TD internal ticket
export const DOCUMENTATION_INTERNAL_TICKET_STATUS_NEW = 'NEW';
export const DOCUMENTATION_INTERNAL_TICKET_STATUS_CLOSED = 'CLOSED';
export const DOCUMENTATION_INTERNAL_TICKET_STATUS_TRANSFERRED = 'TRANSFERRED';
export const DOCUMENTATION_INTERNAL_TICKET_STATUS_ANSWERED = 'ANSWERED';
export const DOCUMENTATION_INTERNAL_TICKET_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const DOCUMENTATION_INTERNAL_TICKET_STATUS_REOPENED = 'REOPENED';

//CL Ticket statuses
export const CLAIMS_TICKET_STATUS_NEW = 'NEW';
export const CLAIMS_TICKET_STATUS_IN_PROGRESS_DEALER = 'WAIT_DEALER';
export const CLAIMS_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY = 'WAIT_SUBSIDIARY';
export const CLAIMS_TICKET_STATUS_READ_BY_DEALER = 'READ_BY_DEALER';
export const CLAIMS_TICKET_STATUS_READ_BY_SUBSIDIARY = 'READ_BY_SUBSIDIARY';
export const CLAIMS_TICKET_STATUS_REOPENED = 'REOPEN';
export const CLAIMS_TICKET_STATUS_CLOSED = 'CLOSED';

//SP form types
export const ALLOCATION_REQUEST_FORM = 'ALLOCATION_REQUEST';
export const ORDER_CANCELLATION_FORM = 'ORDER_CANCELLATION';
export const VOR_PVI_ORDER_FORM = 'VOR_PVI_ORDER';
export const ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM = 'ORDER_FOR_PARTS_NO_LONGER_AVAILABLE';
export const DELIVERY_DATE_REMINDER_FORM = 'DELIVERY_DATE_REMINDER';
export const EV_BATTERY_FORM = 'EV_BATTERY';
export const SPARE_PARTS_INTERNAL_TICKET_FORM = 'INTERNAL_TICKET';

//TD form types
export const CODED_PARTS_KEYS_FORM = 'CODED_PARTS_KEYS';
export const OVAL_PLATE_ORDER_FORM = 'OVAL_PLATE_ORDER';
export const SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM = 'SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE';
export const SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM = 'SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE';
export const UNKNOWN_PART_OPENING_REQUEST_FORM = 'UNKNOWN_PART_OPENING_REQUEST';
export const SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM = 'SPARE_PARTS_REQUEST_FOR_SUPPORT';
export const INTERNAL_TICKET_FORM = 'INTERNAL_TICKET';
// TD GB specific
export const CODED_PARTS_KEYS_FORM_GBIE = 'CODED_PARTS_KEYS_GBIE';


//CL form types
export const CONTEST_CLAIM_STATUS_FORM = 'CONTEST_CLAIM_STATUS';
export const PROBLEM_FOR_PLACING_CLAIMS_FORM = 'PROBLEM_FOR_PLACING_CLAIMS';
export const RETURN_CLAIM_FORM = 'RETURN_CLAIM';
export const STOCK_DEAD_CLAIM_FORM = 'STOCK_DEAD_CLAIM';
export const OLD_MATERIAL_CLAIM_FORM = 'OLD_MATERIAL_CLAIM';
export const DIRECT_FLOW_CLAIM_FORM = 'DIRECT_FLOW_CLAIM';
export const TROUBLESHOOT_CLAIM_FORM = 'TROUBLESHOOT_CLAIM';

//SP form fields names mappings
//Dealer information section
export const FORM_TICKET_SECTION = 'ticketSection';
export const FORM_FIELD_CLIENT_ID = 'clientId';
export const FORM_FIELD_DEALER_NAME = 'dealerName';
export const FORM_FIELD_DEALER_NO = 'dealerNumber';
export const FORM_FIELD_DESTINATION_CODE = 'destinationCode';
export const FORM_FIELD_REGION = 'region';
export const FORM_FIELD_NAME = 'userName';
export const FORM_FIELD_NAME_TD_SEARCH = 'creatorName';
export const FORM_FIELD_EMAIL = 'email';
export const FORM_FIELD_TELEPHONE_NO = 'telNo';
export const FORM_FIELD_CREATION_DATE = 'created';
export const FORM_FIELD_TICKET_NUMBER = 'ticketNumber';
export const FORM_FIELD_SFX = 'sfxNumber';
export const FORM_FIELD_PFX = 'pfxNumber';
export const FORM_FIELD_COUNTRY_CODE = 'countryCode3';

//Dealer information section
export const FORM_DEALER_SECTION = 'dealerSection';
export const FORM_FIELD_DEALER_ADDRESS = 'dealerAddress';
export const FORM_FIELD_COUNTRY = 'group';
export const FORM_FIELD_DELIVERY_CONTACT_NAME = 'deliveryContactName';
export const FORM_FIELD_EORI_NO = 'eoriNo';

//Battery detail section
export const FORM_BATTERY_SECTION = 'batteryDetail';
export const FORM_FIELD_FIC_CUR_NUMBER = 'ficCurNumber';
export const FORM_FIELD_BATTERY_REF_NUMBER = 'batteryRefNumber';
export const FORM_FIELD_BIN = 'bin';
export const FORM_FIELD_BATTERY_TYPE = 'batteryType';
export const FORM_FIELD_SEND_HAND_BATTERY_TYPE = 'secondHandBatteryType';

//Problem description section
export const FORM_BATTERY_PROBLEM_DESCRIPTION_SECTION = 'batteryProblemDescription';
export const FORM_FIELD_INVOICE_NUMBER = 'invoiceNumber';
export const FORM_FIELD_BATTERY_PROBLEM_TYPE = 'batteryProblemType';
export const FORM_FIELD_AVAILABILITY_SECOND_HAND_STOCK = 'availabilitySecondHandStock';
export const FORM_FIELD_MANAGEMENT_GROUP = 'managementGroup';


//Notes section
export const FORM_NOTES_SECTION = 'notesSection';
export const FORM_MESSAGES_SECTION = 'messagesSection';
export const FORM_FIELD_NOTE = 'note';
export const FORM_FIELD_MESSAGE = 'newMessage';
export const FORM_FIELD_MESSAGE_OPTION = 'messageOption';    //chybí v map

//Orders section
export const FORM_FIELDS_ORDER_ITEMS = 'orderItems';
export const FORM_FIELDS_ORDER_ITEM = 'orderItem';
export const FORM_ORDERS_SECTION = 'ordersSection';
export const FORM_FIELD_PROD = 'prod';
export const FORM_FIELD_ORDER = 'order';
export const FORM_FIELD_EDITOR_ORDER = 'editorOrder';
export const FORM_FIELD_LINE = 'line';
export const FORM_FIELD_EDITOR_LINE = 'editorLine';
export const FORM_FIELD_PART_NO = 'partNo';
export const FORM_FIELD_EDITOR_PART_NO = 'editorPartNo';
export const FORM_FIELD_DESCRIPTION = 'description';
export const FORM_FIELD_EDITOR_DESCRIPTION = 'editorDescription';
export const FORM_FIELD_QUANTITY = 'quantity';
export const FORM_FIELD_EDITOR_QUANTITY = 'editorQuantity';
export const FORM_FIELD_REMARKS = 'remarks';
export const FORM_FIELD_PART_MARKING = 'partMarking';
export const FORM_FIELD_REPLY = 'orderReply';
export const FORM_FIELD_SUBJECT_TO_VOR = 'subjectToPvi';
export const FORM_FIELD_LIC_PROD = 'licProd';
export const FORM_FIELD_LIC_ORD = 'licOrd';
export const FORM_FIELD_LIC_OAN = 'licOAn';
export const FORM_FIELD_LIC_LIG = 'licLig';
export const FORM_FIELD_LIC_IAN = 'licIAn';
export const FORM_FIELD_DIC_NUMBER = 'dicNumber';
export const FORM_FIELD_CODE = 'code';
export const FORM_FIELD_EDITOR_CODE_1 = 'editorCode1';
export const FORM_FIELD_EDITOR_CODE_2 = 'editorCode2';
export const FORM_FIELD_SP_TICKET_NUMBER_REFERENCE = 'spTicketNumberReference';
export const FORM_FIELD_USER_STATUS = 'userStatus';
export const FORM_FIELD_CLIENT_SOLUTION_PILOT = 'clientSolutionPilot';
export const FORM_FIELD_TRANSPORT_PROVIDER = 'transportProvider';
export const FORM_FIELD_TRANSPORT_NUMBER = 'transportNumber';
export const FORM_FIELD_CLAIM_TYPE = 'claimType';
export const FORM_FIELD_MPR88 = 'mpr88';

//Supplementary information section
export const FORM_SUPPLEMENTARY_INFORMATION_SECTION = 'suppInfoSection';
export const FORM_FIELD_ENGINE_TYPE = 'engineType';
export const FORM_FIELD_ENGINE_SERIAL_NUMBER = 'engineNumber';
export const FORM_FIELD_GEARBOX_TYPE = 'gearboxType';
export const FORM_FIELD_GEARBOX_SERIAL_NUMBER = 'gearboxNumber';
export const FORM_FIELD_ABS = 'abs';
export const FORM_FIELD_POWER_STEERING = 'powerSteering';
export const FORM_FIELD_AIR_CONDITIONING = 'airConditioning';
export const FORM_FIELD_STEERING_WHEEL = 'steeringWheel';
export const FORM_FIELD_VIP = 'vip';
export const FORM_FIELD_CLIENT_TYPE = 'clientType';
export const FORM_FIELD_SRC_REQUEST = 'srcRequest';
export const FORM_FIELD_SRC_CLAIM_N = 'srcClaimN';
export const FORM_FIELD_MORE_DETAILS = 'moreDetails';
export const FORM_FIELD_AVAILABILITY_DATE = 'availabilityDate';

//Problem description section
export const FORM_PROBLEM_DESCRIPTION_SECTION = 'problemDescription';
export const FORM_FIELD_DIALOGYS_VERSION = 'dialogysVersion';
export const FORM_FIELD_GAR_1 = 'garRelated1';
export const FORM_FIELD_GAR_2 = 'garRelated2';
export const FORM_FIELD_GAR_3 = 'garRelated3';
export const FORM_FIELD_PR = 'pr';
export const FORM_FIELD_PAGE_PREF = 'pagePref';
export const FORM_FIELD_PAGE_POST = 'pagePost';
export const FORM_FIELD_ITEM_NUMBER = 'itemNo';
export const FORM_FIELD_PART_NUMBER = 'partNo';
export const FORM_FIELD_SUPPLIER = 'supplier';
export const FORM_FIELD_PROBLEM_TYPE = 'problemType';
export const FORM_FIELD_PART_DESCRIPTION = 'partDesc';
export const FORM_FIELD_NOD_ID = 'nodId';
export const FORM_FIELD_PART_REFERENCE_ASSOCIATED = 'partAssociatedNumber';
export const FORM_FIELD_FIC_CIR = 'ficCirAssociated';
export const FORM_FIELD_ISSUE_ORIGIN = 'issueOrigin';
export const FORM_FIELD_CUSTOMER_EFFECT = 'customerEffect';
export const FORM_FIELD_DOCUMENT_ID = 'documentId';
export const FORM_FIELD_VEHICLE_COVERED_BY_WARRANTY = 'vehicleCoveredByWarranty';
export const FORM_FIELD_PROBLEM_DESCRIPTION = 'problemDesc';
export const FORM_FIELD_TEMPORARY_SOLUTION = 'temporarySolution';
export const FORM_FIELD_TEMP_SOLUTION = 'temporarySolution';
export const FORM_FIELD_PVI_RELATED = 'pviRelatedNo';
export const FORM_FIELD_CLAIM_RELATED = 'claimRelatedNo';
export const FORM_FIELD_RELATED_DEALERS_TICKETS = 'relatedDealersTicketsNo';
export const FORM_CHECKBOX_PVI_RELATED = 'pviRelated';
export const FORM_CHECKBOX_CLAIM_RELATED = 'claimRelated';
export const FORM_CHECKBOX_RELATED_DEALERS_TICKETS = 'relatedDealersTickets';
export const FORM_FIELD_IMMOBILIZED_VEHICLE = 'immobilizedVehicle';

//Documentation update section
export const DOCUMENTATION_UPDATE_SECTION = 'documentationUpdate';
export const FORM_FIELD_DOC_UPDATE_NEEDED = 'docUpdateNeeded';
export const FORM_FIELD_UPDATE_FILE_NUMBER = 'updateFileNumber';
export const FORM_FIELD_LUP_QS_ASSOCIATED = 'lupQsAssociated';
export const FORM_FIELD_CUSTOMER_RISK = 'customerRisk';
export const FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION = 'estimatedDateOfModification';
export const FORM_FIELD_ROOT_CAUSE = 'rootCause';

//Store check results section
export const DOCUMENTATION_STORE_CHECK_RESULTS_SECTION = 'storeCheckResults';

//Attachments section
export const FORM_FIELD_NEW_ATTACHMENTS = 'newAttachments';
export const FORM_FIELD_ATTACHMENTS = 'attachments';
export const FORM_FIELD_ATTACHMENT_INPUT = 'attachmentInput';
export const FORM_ATTACHMENTS_SECTION = 'attachmentsSection';

//Vehicle Detail section
export const FORM_VEHICLE_DETAIL_SECTION = 'vehicleDetail';
export const FORM_FIELD_VIN = 'vin';
export const FORM_FIELD_DELIVERY_DATE = 'deliveryDate';
export const FORM_FIELD_MANUFACTURE_DATE = 'manufactureDate';
export const FORM_FIELD_ENGINE_TYPE_SUFFIX = 'engineTypeSuffix';
export const FORM_FIELD_ENGINE_SERIAL_NUMBER_VDS = 'engineSerialNumberVds';
export const FORM_FIELD_GEARBOX_TYPE_SUFFIX = 'gearboxTypeSuffix';
export const FORM_FIELD_GEARBOX_SERIAL_NUMBER_VDS = 'gearboxSerialNumberVds';
export const FORM_FIELD_STEERING_WHEEL_ON = 'steeringWheelOn';
export const FORM_FIELD_REGISTRATION_NO = 'registrationNumber';
export const FORM_FIELD_VEHICLE_TYPE = 'vehicleType';
export const FORM_FIELD_VEHICLE_MODEL = 'model';
export const FORM_FIELD_CAR_MODEL = 'carModel';
export const FORM_FIELD_CUSTOMER_NAME = 'customerName';
export const FORM_FIELD_VEHICLE_DETAIL_TEL_NO = 'telephoneNumber';
export const FORM_FIELD_REPAIR_ORDER_NO = 'repairOrderNo';
export const FORM_FIELD_CAR_LOCATION = 'repairedCarLocation';
export const FORM_FIELD_INTERNAL_STATUS = 'internalStatus';
export const FORM_FIELD_NEW_PLATE1 = 'newVehicleType';
export const FORM_FIELD_NEW_PLATE2 = 'newFabricationNumber';
export const FORM_FIELD_NEW_PLATE3 = 'newEquipmentLevel';
export const FORM_FIELD_NEW_PLATE4 = 'newAdditionalOptionalEquipment1';
export const FORM_FIELD_NEW_PLATE5 = 'newAdditionalOptionalEquipment2';
export const FORM_FIELD_NEW_PLATE6 = 'newPaintCode';
export const FORM_FIELD_NEW_PLATE7 = 'newSeatTrimCode';
export const FORM_FIELD_NEW_PLATE8 = 'newInteriorTrimCode';
export const FORM_FIELD_NEW_PLATE9 = 'newTechnicalCountryCode';
export const FORM_FIELD_NEW_PLATE10 = 'newSuspensionType';
export const FORM_FIELD_NEW_PLATE11 = 'newCountryClimateType';
export const FORM_FIELD_NEW_PLATE12 = 'newAirConditioningType';
export const FORM_FIELD_NEW_PLATE13 = 'newEngineFiltrationType';
export const FORM_FIELD_NEW_PLATE14 = 'newSteeringType';
export const FORM_FIELD_NEW_PLATE15 = 'newDampersType';
export const FORM_FIELD_NEW_PLATE16 = 'newTrimSystem';
export const FORM_FIELD_NEW_PLATE17 = 'newBraking';
export const FORM_FIELD_OLD_PLATE1 = 'oldVehicleType';
export const FORM_FIELD_OLD_PLATE2 = 'oldFactoryOptions';
export const FORM_FIELD_OLD_PLATE3 = 'oldTechnicalEquipmentCode';
export const FORM_FIELD_OLD_PLATE4 = 'oldAdditionalOptionalEquipment';
export const FORM_FIELD_OLD_PLATE5 = 'oldSeatTrimCode';
export const FORM_FIELD_OLD_PLATE6 = 'oldPaintCode';
export const FORM_FIELD_OLD_PLATE7 = 'oldEquipmentLevel';
export const FORM_FIELD_OLD_PLATE8 = 'oldFabricationNumber';
export const FORM_FIELD_VEHICLE_FAMILY = 'vehicleFamily';
export const FORM_FIELD_VEHICLE_BRAND = 'vehicleBrand';
export const FORM_FIELD_TAPV = 'tapv';

//Car courtesy reply
export const FORM_CAR_REQUEST_SECTION = 'carRequestsSection';
export const FORM_CAR_REQUEST = 'carRequests';
export const FORM_FIELD_REIMBURSEMENT = 'reimbursement';
export const FORM_FIELD_COURTESY_CAR_TYPE = 'carType';
export const FORM_FIELD_SUPPLEMENTARY_INFORMATION = 'supplementaryInformation';

export const FORM_EDITORS_NOTES_SECTION_TD = 'formEditorsNotesSectionTd';
export const FORM_LABEL_EDITORS_NOTES_TD = 'EDITORS_NOTES_TD';
export const FORM_FIELD_EDITORS_NOTES_TD = 'editorsNotesTD';
export const FORM_LABEL_EDITORS_NOTES_HISTORY_TD = 'EDITORS_NOTES_HISTORY_TD';

export const FORM_EDITORS_NOTES_SECTION_AS = 'formEditorsNotesSectionAs';
export const FORM_LABEL_EDITORS_NOTES_AS = 'EDITORS_NOTES_AS';
export const FORM_FIELD_EDITORS_NOTES_AS = 'editorsNotesAS';
export const FORM_LABEL_EDITORS_NOTES_HISTORY_AS = 'EDITORS_NOTES_HISTORY_AS';

export const FORM_EDITORS_NOTES_SECTION_SP = 'formEditorsNotesSectionSp';
export const FORM_LABEL_EDITORS_NOTES_SP = 'EDITORS_NOTES_SP';
export const FORM_FIELD_EDITORS_NOTES_SP = 'editorsNotesSP';
export const FORM_LABEL_EDITORS_NOTES_HISTORY_SP = 'EDITORS_NOTES_HISTORY_SP';


//Car warehouse
export const FORM_WAREHOUSE_SECTION = 'warehouse';
export const FORM_FIELD_AUTHORITY_GIVEN = 'authorityGiven';
export const FORM_FIELD_AUTHORITY_NUMBER = 'authorityNumber';
export const FORM_FIELD_AGREEMENT = 'agreement';
export const FORM_FIELD_FROM = 'from';
export const FORM_FIELD_UNTIL = 'until';
export const FORM_FIELD_COURTESY_REPLY_REMARKS = 'remarks';
export const FORM_FIELD_DAILY_RATE = 'dailyRate';

//Part Detail section
export const FORM_PART_DETAIL_SECTION = 'partDetail';
export const FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM = 'nReferenceCausingProblem';
export const FORM_FIELD_N_REFERENCE_FOR_REPLACEMENT = 'nReferenceForReplacement';
export const FORM_FIELD_CATALOG_NUMBER = 'catalogNumber';
export const FORM_FIELD_PLATE_NUMBER = 'plateNumber';

//Request Detail section
export const FORM_REQUEST_DETAIL_SECTION = 'requestDetail';
export const FORM_FIELD_REQUESTOR_NAME = 'requestorName';
export const FORM_FIELD_REQUESTOR_IPN = 'requestorIpn';
export const FORM_FIELD_CREATOR_IPN = 'creatorIpn';
export const FORM_FIELD_SOLUTION_GROUP = 'solutionGroup';
export const FORM_FIELD_PARTNER_NAME = 'partnerName';
export const FORM_FIELD_TYPE_OF_REQUEST = 'typeOfRequest';
export const FORM_FIELD_PRIORITY_TREATMENT = 'priorityTreatment';
export const FORM_FIELD_PRIORITY_CASES = 'priorityCases';
export const FORM_FIELD_REQUEST_FOR_PARTNER = 'requestForPartner';
export const FORM_FIELD_NETWORK_TICKET_NUMBER = 'networkTicketNumber';
export const FORM_FIELD_PRIORITY_TYPE = 'priorityType';
export const FORM_FIELD_SRC_NUMBER = 'srcNumber';
export const FORM_FIELD_INTERNAL_TICKET_NUMBER = 'ticketNumber';
export const FORM_FIELD_DEALER_TICKET_NUMBER = 'dealerTicketNumber';
export const FORM_FIELD_DEALER_FORM_TYPE = 'dealerFormType';
export const FORM_FIELD_REQUEST_STATUS = 'status';
export const FORM_FIELD_SG_REQUESTER = 'sgRequester';
export const FORM_FIELD_CREATED_AT_INTERNAL_TICKET = 'created';
export const FORM_FIELD_CREATED_AT_INTERNAL_TICKET_FROM = 'from';
export const FORM_FIELD_CREATED_AT_INTERNAL_TICKET_TO = 'to';
export const FORM_FIELD_REQUEST_ROLE = 'requestorRole';
export const FORM_FIELD_DEALER_COUNTRY_CODE = 'countryCode3';

//Status history
export const FORM_STATUS_HISTORY_SECTION = 'statusHistory';

//SP form label names mappings
//Dealer information section
export const FORM_LABEL_TICKET_SECTION = 'TICKET_SECTION';
export const FORM_LABEL_CLIENT_ID = 'CLIENT_ID';
export const FORM_LABEL_DEALER_NAME = 'DEALER_NAME';
export const FORM_LABEL_DEALER_NO = 'DEALER_NUMBER';
export const FORM_LABEL_DESTINATION_CODE = 'DESTINATION_CODE';
export const FORM_LABEL_REGION = 'REGION';
export const FORM_LABEL_NAME = 'NAME';
export const FORM_LABEL_EMAIL = 'EMAIL';
export const FORM_LABEL_TELEPHONE_NO = 'TELEPHONE_NUMBER';
export const FORM_LABEL_CREATION_DATE = 'CREATION_DATE';
export const FORM_LABEL_TICKET_NUMBER = 'TICKET_NUMBER';

//Dealer information section
export const FORM_LABEL_DEALER_SECTION = 'DEALER_SECTION';
export const FORM_LABEL_DEALER_ADDRESS = 'DEALER_ADDRESS';
export const FORM_LABEL_COUNTRY = 'COUNTRY';
export const FORM_LABEL_DELIVERY_CONTACT_NAME = 'DELIVERY_CONTACT_NAME';
export const FORM_LABEL_EORI_NO = 'EORI_NUMBER';

//Battery detail section
export const FORM_LABEL_FIC_CUR_NUMBER = 'FIC_CUR_NUMBER';
export const FORM_LABEL_BATTERY_REF_NUMBER = 'BATTERY_REF_NUMBER';
export const FORM_LABEL_BIN = 'BIN';
export const FORM_LABEL_BATTERY_TYPE = 'BATTERY_TYPE';

//Problem description section
export const FORM_LABEL_INVOICE_NUMBER = 'INVOICE_NUMBER';
export const FORM_LABEL_BATTERY_PROBLEM_TYPE = 'BATTERY_PROBLEM_TYPE';
export const FORM_LABEL_PRODUCT_CODE = 'PRODUCT_CODE';
export const FORM_LABEL_CLAIM_PROBLEM_TYPE = 'CLAIM_PROBLEM_TYPE';
export const FORM_LABEL_AVAILABILITY_SECOND_HAND_STOCK = 'AVAILABILITY_SECOND_HAND_STOCK';
export const FORM_LABEL_MANAGEMENT_GROUP = 'MANAGEMENT_GROUP';

//Notes section
export const FORM_LABEL_NOTE_SECTION = 'NOTE_SECTION';
export const FORM_LABEL_NOTES_HISTORY = 'NOTES_HISTORY';
export const FORM_LABEL_COURTESY_NOTES_HISTORY = 'COURTESY_NOTES_HISTORY';
export const FORM_LABEL_MESSAGES_HISTORY = 'MESSAGES_HISTORY';
export const FORM_LABEL_NOTE = 'NOTE';
export const FORM_LABEL_MESSAGE = 'MESSAGE';
export const FORM_LABEL_MESSAGES_SECTION = 'MESSAGES_SECTION';

//Orders section
export const FORM_LABEL_ORDER_ITEMS = 'FORM_LABEL_ORDER_ITEMS';
export const FORM_LABEL_ORDERS_SECTION = 'ORDERS_SECTION';
export const FORM_LABEL_DIC_NUMBER = 'DIC_NUMBER';
export const FORM_LABEL_SP_TICKET_NUMBER_REFERENCE = 'SP_TICKET_NUMBER_REFERENCE';
export const FORM_LABEL_DIC_TRANSFER = 'DIC_TRANSFER';
export const FORM_LABEL_VOR_TRANSFER = 'VOR_TRANSFER';
export const FORM_LABEL_MPR88 = 'MPR88';
export const FORM_LABEL_PROD = 'PROD';
export const FORM_LABEL_ORDER = 'ORDER';
export const FORM_LABEL_EDITOR_ORDER = 'EDITOR_ORDER';
export const FORM_LABEL_LINE = 'LINE';
export const FORM_LABEL_EDITOR_LINE = 'EDITOR_LINE';
export const FORM_LABEL_PART_NO = 'PART_NUMBER';
export const FORM_LABEL_EDITOR_PART_NO = 'EDITOR_PART_NUMBER';
export const FORM_LABEL_DESCRIPTION = 'DESCRIPTION';
export const FORM_LABEL_EDITOR_DESCRIPTION = 'EDITOR_DESCRIPTION';
export const FORM_LABEL_QUANTITY = 'QUANTITY';
export const FORM_LABEL_EDITOR_QUANTITY = 'EDITOR_QUANTITY';
export const FORM_LABEL_REMARKS = 'REMARKS';
export const FORM_LABEL_CODE = 'CODE';
export const FORM_LABEL_EDITOR_CODE_1 = 'EDITOR_CODE_1';
export const FORM_LABEL_EDITOR_CODE_2 = 'EDITOR_CODE_2';
export const FORM_LABEL_PART_MARKING = 'PART_MARKING';
export const FORM_LABEL_REPLY = 'ORDER_REPLY';
export const FORM_LABEL_REPLY_HISTORY = 'ORDER_REPLY_HISTORY';
export const FORM_LABEL_SUBJECT_TO_VOR = 'SUBJECT_TO_VOR';
export const FORM_LABEL_LIC_PROD = 'LIC_PROD';
export const FORM_LABEL_LIC_ORD = 'LIC_ORD';
export const FORM_LABEL_LIC_OAN = 'LIC_OAN';
export const FORM_LABEL_LIC_LIG = 'LIC_LIG';
export const FORM_LABEL_LIC_IAN = 'LIC_IAN';
export const FORM_LABEL_USER_STATUS = 'USER_STATUS';
export const FORM_LABEL_CLIENT_SOLUTION_PILOT = 'CLIENT_SOLUTION_PILOT';
export const FORM_LABEL_TRANSPORT_PROVIDER = 'TRANSPORT_PROVIDER';
export const FORM_LABEL_TRANSPORT_NUMBER = 'TRANSPORT_NUMBER';
export const FORM_LABEL_CLAIM_TYPE = 'CLAIM_TYPE';
export const FORM_LABEL_COUNTRY_CODE = 'COUNTRY_CODE_3';

//Supplementary information section
export const FORM_LABEL_SUPPLEMENTARY_INFORMATION_SECTION = 'SUPPLEMENTARY_INFORMATION_SECTION';
export const FORM_LABEL_ENGINE_TYPE = 'ENGINE_TYPE';
export const FORM_LABEL_ENGINE_SERIAL_NUMBER = 'ENGINE_SERIAL_NUMBER';
export const FORM_LABEL_GEARBOX_TYPE = 'GEARBOX_TYPE';
export const FORM_LABEL_GEARBOX_SERIAL_NUMBER = 'GEARBOX_SERIAL_NUMBER';
export const FORM_LABEL_ABS = 'ABS';
export const FORM_LABEL_POWER_STEERING = 'POWER_STEERING';
export const FORM_LABEL_AIR_CONDITIONING = 'AIR_CONDITIONING';
export const FORM_LABEL_STEERING_WHEEL = 'STEERING_WHEEL';
export const FORM_LABEL_VIP = 'VIP';
export const FORM_LABEL_CLIENT_TYPE = 'CLIENT_TYPE';
export const FORM_LABEL_SRC_REQUEST = 'SRC_REQUEST';
export const FORM_LABEL_SRC_CLAIM_N = 'SRC_CLAIM_N';
export const FORM_LABEL_MORE_DETAILS = 'MORE_DETAILS';
export const FORM_LABEL_AVAILABILITY_DATE = 'AVAILABILITY_DATE';

// doc update section
export const FORM_LABEL_DOC_UPDATE_NEEDED = 'DOC_UPDATE_NEEDED';
export const FORM_LABEL_UPDATE_FILE_NUMBER = 'UPDATE_FILE_NUMBER';
export const FORM_LABEL_LUP_QS_ASSOCIATED = 'LUP_QS_ASSOCIATED';
export const FORM_LABEL_CUSTOMER_RISK = 'CUSTOMER_RISK';
export const FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION = 'ESTIMATED_DATE_OF_MODIFICATION';
export const FORM_LABEL_ROOT_CAUSE = 'ROOT_CAUSE';

//Problem description section
export const FORM_LABEL_PROBLEM_DESCRIPTION_SECTION = 'PROBLEM_DESCRIPTION_SECTION';
export const FORM_LABEL_DIALOGYS_VERSION = 'DIALOGYS_VERSION';
export const FORM_LABEL_GAR_1 = 'GAR_1';
export const FORM_LABEL_GAR_2 = 'GAR_2';
export const FORM_LABEL_GAR_3 = 'GAR_3';
export const FORM_LABEL_PR = 'PR';
export const FORM_LABEL_PAGE = 'PAGE';
export const FORM_LABEL_PAGE_PREF = 'PAGE_PREF';
export const FORM_LABEL_PAGE_POST = 'PAGE_POST';
export const FORM_LABEL_ITEM_NUMBER = 'ITEM_NUMBER';
export const FORM_LABEL_PART_NUMBER = 'PART_NUMBER';
export const FORM_LABEL_DEALER_ORDER = 'DEALER_ORDER';
export const FORM_LABEL_EDITOR_ORDER_REPLY = 'EDITOR_ORDER_REPLY';
export const FORM_LABEL_PROBLEM_TYPE = 'PROBLEM_TYPE';
export const FORM_LABEL_MMOBILIZED_VEHICLE = 'IMMOBILIZED_VEHICLE';

// export const FORM_LABEL_QUANTITY = 'QUANTITY';
export const FORM_LABEL_SUPPLIER = 'SUPPLIER';
export const FORM_LABEL_PART_DESCRIPTION = 'PART_DESCRIPTION';
export const FORM_LABEL_NOD_ID = 'NOD_ID';
export const FORM_LABEL_DOCUMENT_ID = 'DOCUMENT_ID';
export const FORM_LABEL_VEHICLE_COVERED_BY_WARRANTY = 'VEHICLE_COVERED_BY_WARRANTY';
export const FORM_LABEL_PART_REFERENCE_ASSOCIATED = 'PART_REFERENCE_ASSOCIATED';
export const FORM_LABEL_FIC_CIR = 'FIC_CIR_ASSOCIATED';
export const FORM_LABEL_ISSUE_ORIGIN = 'ISSUE_ORIGIN';
export const FORM_LABEL_CUSTOMER_EFFECT = 'CUSTOMER_EFFECT';
export const FORM_LABEL_PROBLEM_DESCRIPTION = 'PROBLEM_DESCRIPTION';
export const FORM_LABEL_TEMPORARY_SOLUTION = 'TEMPORARY_SOLUTION';
export const FORM_LABEL_TEMP_SOLUTION = 'TEMPORARY_SOLUTION';
export const FORM_LABEL_PVI_RELATED = 'PVI_RELATED';
export const FORM_LABEL_CLAIM_RELATED = 'CLAIM_RELATED';
export const FORM_LABEL_RELATED_DEALERS_TICKETS = 'RELATED_DEALERS_TICKETS';

//Attachments section
export const FORM_LABEL_ATTACHMENTS_SECTION = 'ATTACHMENTS_SECTION';
export const FORM_LABEL_ATTACHMENTS = 'ATTACHMENTS';
export const FORM_LABEL_DEALER_ATTACHMENTS = 'DEALER_ATTACHMENTS';
export const FORM_LABEL_EDITOR_ATTACHMENTS = 'EDITOR_ATTACHMENTS';
export const FORM_LABEL_NEW_ATTACHMENTS = 'NEW_ATTACHMENTS';
export const FORM_CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENTS';

//Vehicle detail section
export const FORM_LABEL_VEHICLE_DETAIL_VOR_PVI_SECTION = 'VEHICLE_DETAIL_VOR_PVI_SECTION';
export const FORM_LABEL_VEHICLE_DETAIL_SECTION = 'VEHICLE_DETAIL_SECTION';
export const FORM_LABEL_VIN = 'VIN';
export const FORM_LABEL_DELIVERY_DATE = 'DELIVERY_DATE';
export const FORM_LABEL_MANUFACTURE_DATE = 'MANUFACTURE_DATE';
export const FORM_LABEL_REGISTRATION_NO = 'REGISTRATION_NUMBER';
export const FORM_LABEL_ENGINE_TYPE_SUFFIX = 'ENGINE_TYPE_SUFFIX';
export const FORM_LABEL_ENGINE_SERIAL_NUMBER_VDS = 'ENGINE_SERIAL_NUMBER_VDS';
export const FORM_LABEL_GEARBOX_TYPE_SUFFIX = 'GEARBOX_TYPE_SUFFIX';
export const FORM_LABEL_GEARBOX_SERIAL_NUMBER_VDS = 'GEARBOX_SERIAL_NUMBER_VDS';
export const FORM_LABEL_STEERING_WHEEL_ON = 'STEERING_WHEEL_ON';
export const FORM_LABEL_VEHICLE_TYPE = 'VEHICLE_TYPE';
export const FORM_LABEL_VEHICLE_MODEL = 'VEHICLE_MODEL';
export const FORM_LABEL_CUSTOMER_NAME = 'CUSTOMER_NAME';
export const FORM_LABEL_VEHICLE_DETAIL_TEL_NO = 'VEHICLE_DETAIL_TELEPHONE_NUMBER';
export const FORM_LABEL_REPAIR_ORDER_NO = 'REPAIR_ORDER_NUMBER';
export const FORM_LABEL_CAR_LOCATION = 'CAR_LOCATION';
export const FORM_LABEL_CAR_MODEL = 'CAR_MODEL';
export const FORM_LABEL_INTERNAL_STATUS = 'INTERNAL_STATUS';
export const FORM_LABEL_NEW_PLATE1 = 'NEW_PLATE1';
export const FORM_LABEL_NEW_PLATE2 = 'NEW_PLATE2';
export const FORM_LABEL_NEW_PLATE3 = 'NEW_PLATE3';
export const FORM_LABEL_NEW_PLATE4 = 'NEW_PLATE4';
export const FORM_LABEL_NEW_PLATE5 = 'NEW_PLATE5';
export const FORM_LABEL_NEW_PLATE6 = 'NEW_PLATE6';
export const FORM_LABEL_NEW_PLATE7 = 'NEW_PLATE7';
export const FORM_LABEL_NEW_PLATE8 = 'NEW_PLATE8';
export const FORM_LABEL_NEW_PLATE9 = 'NEW_PLATE9';
export const FORM_LABEL_NEW_PLATE10 = 'NEW_PLATE10';
export const FORM_LABEL_NEW_PLATE11 = 'NEW_PLATE11';
export const FORM_LABEL_NEW_PLATE12 = 'NEW_PLATE12';
export const FORM_LABEL_NEW_PLATE13 = 'NEW_PLATE13';
export const FORM_LABEL_NEW_PLATE14 = 'NEW_PLATE14';
export const FORM_LABEL_NEW_PLATE15 = 'NEW_PLATE15';
export const FORM_LABEL_NEW_PLATE16 = 'NEW_PLATE16';
export const FORM_LABEL_NEW_PLATE17 = 'NEW_PLATE17';
export const FORM_LABEL_OLD_PLATE1 = 'OLD_PLATE1';
export const FORM_LABEL_OLD_PLATE2 = 'OLD_PLATE2';
export const FORM_LABEL_OLD_PLATE3 = 'OLD_PLATE3';
export const FORM_LABEL_OLD_PLATE4 = 'OLD_PLATE4';
export const FORM_LABEL_OLD_PLATE5 = 'OLD_PLATE5';
export const FORM_LABEL_OLD_PLATE6 = 'OLD_PLATE6';
export const FORM_LABEL_OLD_PLATE7 = 'OLD_PLATE7';
export const FORM_LABEL_OLD_PLATE8 = 'OLD_PLATE8';
export const FORM_LABEL_VEHICLE_FAMILY = 'VEHICLE_FAMILY';
export const FORM_LABEL_VEHICLE_BRAND = 'VEHICLE_BRAND';
export const FORM_LABEL_TAPV = 'TAPV';

//Car courtesy
export const FORM_LABEL_WAREHOUSE_SECTION = 'WAREHOUSE_SECTION';
export const FORM_LABEL_COURTESY_CAR_TYPE = 'COURTESY_CAR_TYPE';
export const FORM_LABEL_SUPPLEMENTARY_INFORMATION = 'SUPPLEMENTARY_INFORMATION';
export const FORM_LABEL_REIMBURSEMENT = 'REIMBURSEMENT';

//Car courtesy reply
export const FORM_LABEL_CAR_COURTESY_REPLY_HEADER = 'COURTESY_REPLY_HEADER';
export const FORM_LABEL_AUTHORITY_GIVEN = 'AUTHORITY_GIVEN';
export const FORM_LABEL_AUTHORITY_NUMBER = 'AUTHORITY_NUMBER';
export const FORM_LABEL_AGREEMENT = 'AGREEMENT';
export const FORM_LABEL_DAILY_RATE = 'DAILY_RATE';
export const FORM_LABEL_FROM = 'FROM';
export const FORM_LABEL_UNTIL = 'UNTIL';
export const FORM_LABEL_COURTESY_REPLY_REMARKS = 'COURTESY_REPLY_REMARKS';
export const FORM_LABEL_TOTAL_AMOUNT = 'TOTAL_AMOUNT';
export const FORM_LABEL_TOTAL_DAYS = 'TOTAL_DAYS';

//Part Detail labels
export const FORM_LABEL_N_REFERENCE_CAUSING_PROBLEM = 'N_REFERENCE_CAUSING_PROBLEM';
export const FORM_LABEL_N_REFERENCE_FOR_REPLACEMENT = 'N_REFERENCE_FOR_REPLACEMENT';
export const FORM_LABEL_CATALOG_NUMBER = 'CATALOG_NUMBER';
export const FORM_LABEL_PLATE_NUMBER = 'PLATE_NUMBER';

//Request Detail labels
export const FORM_LABEL_REQUESTOR_NAME = 'REQUESTOR_NAME';
export const FORM_LABEL_REQUESTOR_IPN = 'REQUESTOR_IPN';
export const FORM_LABEL_PARTNER_NAME = 'PARTNER_NAME';
export const FORM_LABEL_TYPE_OF_REQUEST = 'TYPE_OF_REQUEST';
export const FORM_LABEL_PRIORITY_TREATMENT = 'PRIORITY_TREATMENT';
export const FORM_LABEL_PRIORITY_CASES = 'PRIORITY_CASES';
export const FORM_LABEL_REQUEST_FOR_PARTNER = 'REQUEST_FOR_PARTNER';
export const FORM_LABEL_NETWORK_TICKET_NUMBER = 'NETWORK_TICKET_NUMBER';
export const FORM_LABEL_PRIORITY_TYPE = 'PRIORITY_TYPE';
export const FORM_LABEL_SRC_NUMBER = 'SRC_NUMBER';
export const FORM_LABEL_INTERNAL_TICKET_NUMBER = 'INTERNAL_TICKET_NUMBER';
export const FORM_LABEL_REQUEST_STATUS = 'REQUEST_STATUS';
export const FORM_LABEL_SG_REQUESTER = 'SG_REQUESTER';
export const FORM_LABEL_CREATED_AT_INTERNAL_TICKET_FROM = 'CREATED_AT_INTERNAL_TICKET_FROM';
export const FORM_LABEL_CREATED_AT_INTERNAL_TICKET_TO = 'CREATED_AT_INTERNAL_TICKET_TO';
export const FORM_LABEL_DEALER_TICKET_NUMBER = 'DEALER_TICKET_NUMBER';
export const FORM_LABEL_DEALER_FORM_TYPE = 'DEALER_FORM_TYPE';
export const FORM_LABEL_REQUESTOR_ROLE = 'REQUESTOR_ROLE';
export const FORM_LABEL_DEALER_COUNTRY_CODE = 'DEALER_COUNTRY_CODE_3';



//Ticket history
export const FORM_LABEL_TICKET_HISTORY = 'TICKET_HISTORY';

//Button mappings
export const FORM_BUTTON_ADD_ORDER = 'addOrderButton';
export const FORM_BUTTON_CLOSE = 'closeButton';
export const FORM_BUTTON_LOAD = 'loadButton';
export const FORM_BUTTON_SAVE = 'saveButton';
export const FORM_BUTTON_EDIT = 'editButton';
export const FORM_BUTTON_REOPEN_TO_DEALER = 'reopenToDealerButton';
export const FORM_BUTTON_SWITCH_TO_PVI = 'switchToPviButton';
export const FORM_BUTTON_REOPEN = 'reopenButton';
export const FORM_BUTTON_CREATE_DIC = 'createDicButton';
export const FORM_BUTTON_UPDATE_DIC = 'updateDicButton';
export const FORM_BUTTON_BACK = 'backButton';
export const FORM_BUTTON_FORWARD_TO_NPDC = 'forwardToNPDCButton';
export const FORM_BUTTON_FORWARD_TO_DEALER = 'forwardToDealerButton';
export const FORM_BUTTON_MARK_AS_READ = 'markAsReadButton';
export const FORM_BUTTON_CREATE_INTERNAL_TICKET = 'createInternalTicketButton';
export const FORM_BUTTON_ESCALATE = 'escalateButton';
export const FORM_BUTTON_ANSWER = 'answerButton';
export const FORM_BUTTON_TAKE_BACK = 'takeBackButton';
export const FORM_BUTTON_SG_TAKE_BACK = 'solutionGroupTakeBackButton';
export const FORM_BUTTON_BVM_LOAD = 'bvmLoadButton';
export const FORM_CHECKBOX_BVM_CONNECTION = 'bvmLoaded';
export const FORM_CHECKBOX_NEW_OVAL_PLATE = 'newOvalPlate';
export const FORM_CHECKBOX_OLD_OVAL_PLATE = 'oldOvalPlate';
export const FORM_CHECKBOX_DIC = 'transferToDic';
export const FORM_CHECKBOX_TO_VOR = 'transferToVor';
export const FORM_BUTTON_TRANSFER_TO = 'transferToButton';
export const FORM_BUTTON_TRANSFER_TO_REQUESTOR = 'transferToRequestorButton';

//Button label messages mapping
export const BUTTON_LABEL_SAVE = 'SAVE';
export const BUTTON_LABEL_SAVE_AND_SEND = 'SAVE_AND_SEND';
export const BUTTON_LABEL_REOPEN = 'REOPEN';
export const BUTTON_LABEL_CREATE_DIC = 'CREATE_DIC';
export const BUTTON_LABEL_UPDATE_DIC = 'UPDATE_DIC';
export const BUTTON_LABEL_REOPEN_TO_DEALER = 'REOPEN_TO_DEALER';
export const BUTTON_LABEL_BACK = 'BACK';
export const BUTTON_LABEL_SEARCH = 'SEARCH';
export const BUTTON_LABEL_LOAD = 'LOAD';
export const BUTTON_LABEL_CLOSE = 'CLOSE';
export const BUTTON_LABEL_CANCEL_REQUEST = 'CANCEL_REQUEST';
export const BUTTON_LABEL_SWITCH_TO_PVI = 'SWITCH_TO_PVI';
export const BUTTON_LABEL_TAKE_BACK = 'TAKE_BACK';
export const BUTTON_LABEL_MARK_AS_READ = 'MARK_AS_READ';
export const BUTTON_LABEL_ADD_REPLY_COMMENTS = 'ADD_REPLY_COMMENTS';
export const BUTTON_LABEL_CREATE_INTERNAL_TICKET = 'CREATE_INTERNAL_TICKET';
export const BUTTON_LABEL_ESCALATE = 'ESCALATE';
export const BUTTON_LABEL_ANSWER = 'ANSWER';
export const BUTTON_LABEL_FORWARD_TO_NPDC = 'FORWARD_TO_NPDC';
export const BUTTON_LABEL_FORWARD_TO_DEALER = 'FORWARD_TO_DEALER';
export const BUTTON_LABEL_ADD = 'ADD';
export const BUTTON_LABEL_BVM_LOAD = 'BVM_LOAD';
export const BUTTON_LABEL_EXPORT = 'EXPORT';
export const BUTTON_LABEL_DOWNLOAD = 'DOWNLOAD';
export const CHECKBOX_LABEL_BVM_CONNECTION = 'BVM_CONNECTION';
export const CHECKBOX_LABEL_NEW_OVAL_PLATE = 'NEW_OVAL_PLATE';
export const CHECKBOX_LABEL_OLD_OVAL_PLATE = 'OLD_OVAL_PLATE';
export const BUTTON_LABEL_TRANSFER_TO = 'TRANSFER_TO';
export const BUTTON_LABEL_TRANSFER_TO_REQUESTOR = 'TRANSFER_TO_REQUESTOR';

//TD Full Text Search
export const FORM_SEARCH_OPTION_SECTION = 'searchOption';
export const FORM_LABEL_SEARCH_OPTION_SECTION = 'SEARCH_OPTION_SECTION';
export const FORM_FIELD_FULL_DATABASE = 'fullDatabase';
export const FORM_FIELD_FULLTEXT_SEARCH = 'fullTextSearch';
export const FORM_FIELD_ATTACHMENTS_ONLY = 'attachmentsOnly';
export const FORM_FIELD_GAR = 'garRelated';
export const FORM_FIELD_INTERNATIONAL = 'international';
export const FORM_LABEL_FULL_DATABASE = 'FULL_DATABASE';
export const FORM_LABEL_FULLTEXT_SEARCH = 'FULLTEXT_SEARCH';
export const FORM_LABEL_ATTACHMENTS_ONLY = 'ATTACHMENTS_ONLY';
export const FORM_LABEL_GAR = 'GAR';
export const FORM_LABEL_INTERNATIONAL = 'INTERNATIONAL';
export const FORM_LABEL_DOC_TYPE = 'DOC_TYPE';
export const FORM_FIELD_DOC_TYPE = 'docType';
export const FORM_LABEL_SOLUTION_GROUP = 'SOLUTION_GROUP';
export const FORM_FIELD_DOC_SOLUTION_GROUP = 'solutionGroup';
export const FORM_LABEL_EDITOR_IPN = 'EDITOR_IPN';
export const FORM_FIELD_EDITOR_IPN = 'editorIpn';
export const FORM_FIELD_TO = 'to';
export const FORM_LABEL_TO = 'TO';
export const FORM_LABEL_IMMOBILIZED_VEHICLE = 'IMMOBILIZED_VEHICLE';

// CL FULL TEXT SEARCH
// fields
export const FORM_FIELD_FORM_TYPE = 'type';
export const FORM_FIELD_STATUS = 'status';
export const FORM_FIELD_LAST_EDITOR = 'lastEditor';
// labels
export const FORM_LABEL_FORM_TYPE = 'FORM_TYPE';
export const FORM_LABEL_STATUS = 'STATUS';
export const FORM_LABEL_LAST_EDITOR = 'LAST_EDITOR';

// CL PROBLEM DESCRIPTION SECTION
// fields
export const FORM_FIELD_INVOICE = 'invoiceNo';
export const FORM_FIELD_MISSING_PACKAGE = 'missingPackageNo';
export const FORM_FIELD_WRONG_DESTINATION = 'wrongDestinationNo';
export const FORM_FIELD_INVOICED_ORDER1 = 'invoicedOrder1';
export const FORM_FIELD_INVOICED_ORDER2 = 'invoicedOrder2';
export const FORM_FIELD_INVOICED_LINE1 = 'invoicedLine1';
export const FORM_FIELD_INVOICED_LINE2 = 'invoicedLine2';
export const FORM_FIELD_CODE_PROD = 'codeProd';
export const FORM_FIELD_DATE = 'date';
export const FORM_FIELD_PACKAGE_NO = 'packageNo';
export const FORM_FIELD_RETURN_PART_NO = 'returnPartNo';
export const FORM_FIELD_QUANTITY_TOTAL = 'quantityTotal';
export const FORM_FIELD_DEFECTIVE = 'defective';
export const FORM_FIELD_DAMAGED = 'damaged';
export const FORM_FIELD_RETURN = 'return';
export const FORM_FIELD_OBSERVATION = 'observation';
export const FORM_FIELD_TAKING_BACK = 'takingBackNo';
export const FORM_FIELD_CONTAINER = 'containerNo';
export const FORM_FIELD_DEALER_REMARKS = 'dealerRemarks';
export const FORM_FIELD_CREDIT_NOTE = 'creditNoteNo';
export const FORM_FIELD_CREDIT_NOTE_DATE = 'creditNoteDate';
export const FORM_FIELD_SUPPLIER_PART_NO = 'supplierPartNo';
export const FORM_FIELD_BILL_OF_DELIVERY = 'billOfDeliveryNo';
export const FORM_FIELD_ACCOUNTING_CREDIT_NOTE = 'accountingCreditNoteNo';
export const FORM_FIELD_ACCOUNTING_CREDIT_NOTE_DATE = 'accountingCreditNoteDate';
// labels
export const FORM_LABEL_INVOICE = 'INVOICE';
export const FORM_LABEL_MISSING_PACKAGE = 'MISSING_PACKAGE';
export const FORM_LABEL_WRONG_DESTINATION = 'WRONG_DESTINATION';
export const FORM_LABEL_ORDER_INVOICED = 'ORDER_INVOICED';
export const FORM_LABEL_CODE_PROD = 'CODE_PROD';
export const FORM_LABEL_DATE = 'DATE';
export const FORM_LABEL_PACKAGE_NO = 'PACKAGE_NO';
export const FORM_LABEL_ORDER_RECEIVED = 'ORDER_RECEIVED';
export const FORM_LABEL_QUANTITY_TOTAL = 'QUANTITY_TOTAL';
export const FORM_LABEL_DEFECTIVE = 'DEFECTIVE';
export const FORM_LABEL_DAMAGED = 'DAMAGED';
export const FORM_LABEL_RETURN = 'RETURN';
export const FORM_LABEL_OBSERVATION = 'OBSERVATION';
export const FORM_LABEL_TAKING_BACK = 'TAKING_BACK';
export const FORM_LABEL_CONTAINER = 'CONTAINER';
export const FORM_LABEL_DEALER_REMARKS = 'DEALER_REMARKS';
export const FORM_LABEL_CREDIT_NOTE = 'CREDIT_NOTE';
export const FORM_LABEL_CREDIT_NOTE_DATE = 'CREDIT_NOTE_DATE';
export const FORM_LABEL_SUPPLIER_PART_NO = 'SUPPLIER_PART_NO';
export const FORM_LABEL_BILL_OF_DELIVERY = 'BILL_OF_DELIVERY';
export const FORM_LABEL_ACCOUNTING_CREDIT_NOTE = 'ACCOUNTING_CREDIT_NOTE';
export const FORM_LABEL_ACCOUNTING_CREDIT_NOTE_DATE = 'ACCOUNTING_CREDIT_NOTE_DATE';

// Editor's Notes
export const FORM_EDITORS_NOTES_SECTION = 'editorsNotesSection';
export const FORM_LABEL_EDITORS_NOTES_HISTORY = 'EDITORS_NOTES_HISTORY';
export const FORM_LABEL_EDITORS_NOTES = 'EDITORS_NOTES';
export const FORM_FIELD_EDITORS_NOTES = 'newEditorsNote';
export const FORM_FIELD_NEW_EDITORS_NOTE_ATTACHMENTS = 'newEditorsNoteAttachments';
export const FORM_FIELD_EDITORS_NOTE_ATTACHMENTS = 'editorsNoteAttachments';
export const FORM_LABEL_EDITORS_NOTE_ATTACHMENTS = 'EDITORS_NOTE_ATTACHMENTS';

//ASE Full Text Search
export const FORM_FIELD_FORMTYPE = 'formType';

// Solution groups
export const SOLUTION_GROUP_FORM = 'SOLUTION_GROUP_FORM';
//Sections
export const FORM_SOLUTION_GROUP_INFO_SECTION = 'solutionGroupSection';
export const FORM_SOLUTION_GROUP_COUNTRY_SECTION = 'countrySection';
export const FORM_SOLUTION_GROUP_ADMIN_SECTION = 'adminSection';
export const FORM_SOLUTION_GROUP_USER_SECTION = 'userSection';
//Fields
export const FORM_FIELD_SG_NAME = 'name'
export const FORM_FIELD_SG_ACTIVE = 'isActive'
export const FORM_FIELD_SG_DEFAULT = 'isDefault'
export const FORM_FIELD_SG_ANSWER_TO_DEALER = 'canForwardToDealer'
export const FORM_FIELD_SG_NOTIFICATIONS = 'notifications'
export const FORM_FIELD_SG_TECH_DOC_INTERNAL_TICKET_NOTIFICATION = 'internalNotifications'
export const FORM_FIELD_SG_COUNTRIES = 'countries'
export const FORM_FIELD_SG_ADMINS = 'admins'
export const FORM_FIELD_SG_MANUAL_ADMIN_LIST = 'adminList'
export const FORM_FIELD_SG_USERS = 'users'
export const FORM_FIELD_SG_MANUAL_USERS_LIST = 'userList'
//Labels
export const FORM_LABEL_SG_NAME = 'SG_NAME'
export const FORM_LABEL_SG_ACTIVE = 'SG_ACTIVE'
export const FORM_LABEL_SG_DEFAULT = 'SG_DEFAULT'
export const FORM_LABEL_SG_ANSWER_TO_DEALER = 'SG_ANSWER_TO_DEALER'
export const FORM_LABEL_SG_NOTIFICATIONS = 'SG_NOTIFICATIONS'
export const FORM_LABEL_SG_TECH_DOC_INTERNAL_NOTIFICATIONS = 'SG_INTERNAL_NOTIFICATIONS'
export const FORM_LABEL_SG_COUNTRIES = 'SG_COUNTRIES'
export const FORM_LABEL_SG_ADMINS = 'SG_ADMINS'
export const FORM_LABEL_SG_USERS = 'SG_USERS'


// Escalation schema
export const ESCALATION_SCHEMA_FORM = 'ESCALATION_SCHEMA_FORM';
//Sections
export const FORM_ESCALATION_SCHEMA_INFO_SECTION = 'escalationSchemaInfoSection';
export const FORM_ESCALATION_SCHEMA_ESCALATION_SECTION = 'escalationSchemaEscalationSection';
//Fields
export const FORM_FIELD_ES_SG_NAME = 'name';
export const FORM_FIELD_ES_FORMTYPE = 'formType';
export const FORM_FIELD_ES_ESCALATION_GROUPS = 'escalationGroups';
//Labels
export const FORM_LABEL_ES_SG_NAME = 'ES_SG_NAME';
export const FORM_LABEL_ES_FORMTYPE = 'ES_FORMTYPE';
export const FORM_LABEL_ES_ESCALATION_GROUPS = 'ES_ESCALATION_GROUPS';

// Answering schema
export const ANSWERING_SCHEMA_FORM = 'ANSWERING_SCHEMA_FORM';
//Sections
export const FORM_ANSWERING_SCHEMA_INFO_SECTION = 'answeringSchemaInfoSection';
export const FORM_ANSWERING_SCHEMA_ANSWERING_SECTION = 'answeringSchemaAnsweringSection';
//Fields
export const FORM_FIELD_AS_SG_NAME = 'name';
export const FORM_FIELD_AS_FORMTYPE = 'formType';
export const FORM_FIELD_AS_ANSWERING_GROUPS = 'answeringGroups';
//Labels
export const FORM_LABEL_AS_SG_NAME = 'AS_SG_NAME';
export const FORM_LABEL_AS_FORMTYPE = 'AS_FORMTYPE';
export const FORM_LABEL_AS_ANSWERING_GROUPS = 'AS_ANSWERING_GROUPS';

export const FORM_REQUEST_DETAILS_SECTION = 'requestDetailsSection';

export class FORM_LABEL_CREATED_AT_INTERNAL_TICKET {
}
