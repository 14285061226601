import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { change, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { BoldPrimaryButton, SecondaryButton } from '../../common/Button';
import {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalWindow
} from '../../common/modal/Modal';
import buttonMessages from "../../../intl/common/buttonMessages";
import modelAnswersMessages from "../../../intl/admin/modelAnswersMessages";
import RadioFields from "../../common/RadioFields";
import get from 'get-value';
import {
    getFormTypes,
    modelAnswersDisplayAreaOptions
} from "../../../constants/Utils";
import {
    TextareaFieldWithValidation
} from "../../common/TextareaFieldWithValidation";
import InputMultipleSelectField from "../../common/InputMultipleSelectField";
import countryGroupMessages from "../../../intl/common/countryGroupMessages";
import formTypeMessages from "../../../intl/common/formTypeMessages";
import internalTicketRequestTypeMessages
    from "../../../intl/technicalDocumentations/internalTicketRequestTypeMessages";
import { InputFieldWithValidation } from "../../common/InputFieldWithValidation";
import ImgTooltip from "../../common/ImgTooltip";
import { isEmpty, isEqual, uniqWith } from "lodash";

const Button = styled(SecondaryButton)`
    margin-right: 10px;
`;

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        minWidth: '600px',
        maxHeight: '80%',
        overflowY: 'auto',
    },
    overlay: {
        zIndex: 1000
    }
};

const ModelAnswerDialog = (props) => {
    const {
        show,
        handleSubmit,
        invalid,
        dirty,
        intl: { formatMessage },
        solutionGroup,
        initialValues,
        displayArea,
        domain,
        change,
        groups,
        formTypes,
    } = props;

    const save = data => {
        props.saveModal(data);
    };

    const close = () => {
        props.reset();
        props.closeModal();
    };

    const isDefaultSG = get(solutionGroup, 'isDefault', false);
    const title = get(initialValues, 'uuid') ? modelAnswersMessages.MODAL_TITLE_UPDATE : modelAnswersMessages.MODAL_TITLE_CREATE;
    const defaultCountryRoles = get(solutionGroup, 'defaultCountryRoles', { default: [] });
    const dealerOptions = useMemo(() => {
        if (!isEmpty(groups)) {
            const dealerFormOptionsByESandAS = [...new Set([...Object.keys(get(solutionGroup, 'escalationSchemas', {})), ...Object.keys(get(solutionGroup, 'answeringSchemas', {}))])].filter(fType => getFormTypes(domain).includes(fType));
            return uniqWith(
                [...defaultCountryRoles
                .filter(role => groups.includes(role.group))
                .flatMap(role => Object.entries(
                    get(role, 'dealer.formsPermission', { default: {} }))
                .map(([formName, value]) => value ? formName : undefined)
                .filter(formType => formType)),
                    ...dealerFormOptionsByESandAS,
                ],
                isEqual);
        }
        return getFormTypes(domain);
    }, [groups, defaultCountryRoles, domain, solutionGroup]);

    const formTypesOptions = displayArea === 'DEALER_NOTES' ? dealerOptions : [...new Set([...Object.keys(get(solutionGroup, 'escalationSchemas', {})), ...Object.keys(get(solutionGroup, 'answeringSchemas', {}))])];

    const handleDisplayAreaChange = (value) => {
        change('formTypes', null);

        if (isDefaultSG && (get(value, 'currentTarget.value', '') === 'INTERNAL_NOTES')) {
            change('groups', get(solutionGroup, 'groups', { default: [] }));
        }
    };

    useEffect(() => {
        if (displayArea === 'DEALER_NOTES' && !isEmpty(formTypes)) {
            change('formTypes',
                formTypes.filter(fType => dealerOptions.includes(fType)));
        }
    }, [groups]);

    return (
        <ModalWindow isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false} customStyles={customStyles}>
            <StyledForm onSubmit={handleSubmit(save)}>
                <ModalHeader>
                    <FormattedMessage {...title} />
                </ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        {isDefaultSG && <>
                            <div className="row pb-2">
                                <div className="col-5">
                                    <label>
                                        <FormattedMessage {...modelAnswersMessages.MODAL_SELECT_GROUPS} />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <InputMultipleSelectField field="groups"
                                                              options={get(solutionGroup, 'groups', { default: [] })}
                                                              intlMessages={countryGroupMessages}
                                                              isDisabled={displayArea === 'INTERNAL_NOTES'}
                                                              isRequired
                                    />
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col-5">
                                    <label>
                                        <FormattedMessage {...modelAnswersMessages.MODAL_DISPLAY_AREA} />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <RadioFields field="displayArea"
                                                 options={modelAnswersDisplayAreaOptions}
                                                 isRequired
                                                 intlMessages={modelAnswersMessages}
                                                 handleChange={handleDisplayAreaChange}
                                    />
                                </div>
                            </div>
                        </>}
                        <div className="row pb-2">
                            <div className="col-5">
                                <label>
                                    <FormattedMessage {...modelAnswersMessages.MODAL_FORM_TYPE} />
                                </label>
                            </div>
                            <div className="col-7">
                                <InputMultipleSelectField field="formTypes"
                                                          options={formTypesOptions}
                                                          intlMessages={{ ...formTypeMessages, ...internalTicketRequestTypeMessages }}
                                                          isRequired
                                />
                            </div>
                        </div>
                        <div className="row pb-2">
                            <div className="col-5">
                                <label>
                                    <FormattedMessage {...modelAnswersMessages.MODAL_SUBJECT} />
                                </label>
                            </div>
                            <div className="col-7">
                                <InputFieldWithValidation field="subject"
                                                          isRequired />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <div className="row m-0">
                                    <label>
                                        <FormattedMessage {...modelAnswersMessages.MODAL_TEXT} />
                                    </label>
                                    <div className="pl-2">
                                        <ImgTooltip />
                                    </div>
                                </div>
                            </div>
                            <div className="col-7">
                                <TextareaFieldWithValidation field="text"
                                                             placeholder={formatMessage(modelAnswersMessages.MODAL_PLACEHOLDER_TEXT)}
                                                             rows={5}
                                                             isRequired />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="row">
                        <div className="col">
                            <Button type="button" className="btn"
                                    onClick={close}>
                                <FormattedMessage {...buttonMessages.CANCEL} />
                            </Button>
                            <BoldPrimaryButton type="submit" className="btn"
                                               disabled={invalid || !dirty}>
                                <FormattedMessage {...buttonMessages.SAVE} />
                            </BoldPrimaryButton>
                        </div>
                    </div>
                </ModalFooter>
            </StyledForm>
        </ModalWindow>
    )
}

ModelAnswerDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    intl: PropTypes.any.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveModal: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    solutionGroup: PropTypes.any.isRequired,
    initialValues: PropTypes.any.isRequired,
    displayArea: PropTypes.any.isRequired,
    domain: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    groups: PropTypes.array, // Add the PropType for groups
    formTypes: PropTypes.array, // Add the PropType for formTypes
};

const selector = formValueSelector('modelAnswerForm');

const mapStateToProps = state => ({
    solutionGroup: state.adminModelAnswers.fullSolutionGroupInfo,
    displayArea: selector(state, 'displayArea'),
    groups: selector(state, 'groups'),
    formTypes: selector(state, 'formTypes'),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change('modelAnswerForm', name, value)),
});

export default reduxForm({
    form: 'modelAnswerForm',
    enableReinitialize: true,
})(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ModelAnswerDialog)));
