import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import get from 'get-value';
import {
    SPARE_PARTS_RECURRENT_PROBLEM_PENDING,
    SPARE_PARTS_RECURRENT_PROBLEM_SELECT_GROUP
} from '../../actions/orders/actionSparePartsRecurrentProblem';

export const sparePartsRecurrentProblem = (state = {
    isLoading: false,
    recurrentProblems: [],
    selectedCountryGroup: '',
}, action) => {
    switch (action.type) {
        case SPARE_PARTS_RECURRENT_PROBLEM_PENDING: {
            return Object.assign({}, state, { isLoading: true, recurrentProblems: [] });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_SEND: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                recurrentProblems: get(action, 'payload.recurrentProblems', { default: [] }),
                correlationId
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_SEND_ERROR: {
            const { correlationId } = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoading: false, recurrentProblems: [], correlationId });
        }
        case SPARE_PARTS_RECURRENT_PROBLEM_SELECT_GROUP: {
            return Object.assign({}, state, { selectedCountryGroup: action.payload });
        }
        default:
            return state;
    }
};
