import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {startUploadInternalTicketAttachments, uploadInternalTicketAttachments} from '../../../actions/orders/actionSparePartsInternalTicketAttachments';
import Loader from '../../Loader';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formMessages from '../../../intl/sparePartsOrders/formMessages';
import PageHeader from '../../common/PageHeader';
import get from 'get-value';

const UploadingSparePartsInternalTicketAttachments = (props) => {
    const {formType, isAttachmentUploading, correlationId, attachmentsUrl, attachments, sparePartsInternalTicket} = props;

    const assignedSolutionGroup = get(sparePartsInternalTicket, 'ticket.assignedSolutionGroup.id') || get(sparePartsInternalTicket, 'solutionGroupInfo.SK');
    const uploadAttachments = () => {
        const merge = [];
        attachmentsUrl.forEach(attachment => {
            const data = attachments.find(att => att.uuid === attachment.uuid);
            if (data) {
                merge.push({
                    ...attachment,
                    ...data,
                });
            }
        });
        props.uploadInternalTicketAttachments(merge, correlationId, assignedSolutionGroup);
    };

    if (!isAttachmentUploading) {
        props.startUploadInternalTicketAttachments();
        uploadAttachments();
    }

    return (
        <div className="container-fluid">
            <PageHeader title={<ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>}/>
            <div className="container-fluid">
                <div className="col-12">
                    <FormattedMessage {...formMessages.UPLOADING_ATTACHMENTS}/>
                </div>
                <Loader/>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, {
    startUploadInternalTicketAttachments,
    uploadInternalTicketAttachments,
})(UploadingSparePartsInternalTicketAttachments);

