import v4 from 'uuid/v4';
import get from 'get-value';
import {diff} from 'deep-object-diff';
import {FORM_FIELD_DIC_NUMBER, FORM_FIELD_MPR88, ORDER_TICKET_STATUS_NEW} from '../../constants/formConstants';
import { getDicNumber } from '../../api/claims/restClaimsDicNumber';

export const initLoadedClaimsTicket = (ticketData) => {
    const {lockIpn, lockTime, ...ticketSection} = get(ticketData, 'ticket', {default: {}});
    const orderItem = get(ticketData, 'orderItem', {default: {}});

    let mpr88
    if(orderItem) {
        mpr88 = orderItem.mpr88 || String((orderItem.prod || "") + (orderItem.order || "") + (orderItem.line || ""))
    }

    return {
        ticketSection: {...ticketSection},
        ordersSection: { ...orderItem, [FORM_FIELD_MPR88]: mpr88 },
        problemDescription: ticketData.problemDescription,
        messages: ticketData.messages,
        editorsNotesSection: {
            editorsNotes: ticketData.editorsNotes,
            editorsNoteAttachments: ticketData.editorsNoteAttachments
        },
        statusHistory: ticketData.logs,
        attachmentsSection: {attachments: ticketData.attachments},
    };
};

export const initNewClaimsTicket = (userDetail) => {
    if (!userDetail.dealerNumber || userDetail === '') {
        return undefined;
    }

    return {
        ticketSection: {
            dealerNumber: userDetail.dealerNumber,
            dealerName: userDetail.dealerName,
            email: userDetail.mail,
            group: userDetail.group,
            status: ORDER_TICKET_STATUS_NEW,
        },
        ordersSection: {},
    };
};

export const transformNewTicketForSave = async (values) => {
    const data = {};
    data.ticket = {
        ...values.ticketSection,
        uuid: v4(),
    };

    if(values.ordersSection) {
        let dicNumber
        try {
            const { data: resultData} = await getDicNumber(values.ordersSection.spTicketNumberReference)
            dicNumber = resultData.dicNumber
        } catch (error) {
            console.log(error)
            dicNumber = ""
        }

        data.orderItem = { ...values.ordersSection, [FORM_FIELD_DIC_NUMBER]: dicNumber }
    }

    if (values.messagesSection) {
        data.message = {
            uuid: v4(),
            newMessage: values.messagesSection.newMessage,
        };
    }

    if (values.problemDescription) {
        data.problemDescription = values.problemDescription;
    }

    if (get(values, 'attachmentsSection.newAttachments', {default: false})
        && values.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        values.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                ...attachment,
                uuid: v4(),
                section: 'attachmentsSection'
            })
        })
    }
    return data;
};

export const transformUpdateClaimsTicketForSave = (values) => {
    const {oldValues, newValues} = values;
    const difference = diff(oldValues, newValues);
    const data = {};

    data.ticket = {
        ...get(difference, 'ticketSection'),
        uuid: oldValues.ticketSection.uuid,
        pfxNumber: oldValues.ticketSection.pfxNumber,
        sfxNumber: oldValues.ticketSection.sfxNumber,
        status: newValues.ticketSection.status,
    };

    const newOrderItem = get(difference, 'ordersSection')
    if(newOrderItem) {
        data.orderItem = newOrderItem
    }

    const newProblemDescription = get(difference, 'problemDescription');
    if (newProblemDescription) {
        data.problemDescription = newProblemDescription;
    }

    const newMessage = get(difference, 'messagesSection.newMessage');
    if (newMessage) {
        data.message = {
            uuid: v4(),
            newMessage,
        };
    }
    const newEditorsNote = get(difference, 'editorsNotesSection.newEditorsNote');
    if (newEditorsNote) {
        data.editorsNote = {
            uuid: v4(),
            newEditorsNote,
        };
    }

    if ((get(difference, 'attachmentsSection.newAttachments', {default: false}) &&
        difference.attachmentsSection.newAttachments.length > 0) ||
        (get(difference, 'editorsNotesSection.newEditorsNoteAttachments', {default: false}) &&
            difference.editorsNotesSection.newEditorsNoteAttachments.length > 0)) {

        data.newAttachments = [];

        if ((get(difference, 'attachmentsSection.newAttachments', {default: false}) &&
            difference.attachmentsSection.newAttachments.length > 0)) {
            difference.attachmentsSection.newAttachments.forEach(attachment => {
                data.newAttachments.push({
                    uuid: v4(),
                    ...attachment,
                    section: 'attachmentsSection'
                })
            })
        }

        if (get(difference, 'editorsNotesSection.newEditorsNoteAttachments', {default: false}) &&
            difference.editorsNotesSection.newEditorsNoteAttachments.length > 0) {
            difference.editorsNotesSection.newEditorsNoteAttachments.forEach(attachment => {
                data.newAttachments.push({
                    uuid: v4(),
                    ...attachment,
                    section: 'editorsNotesSection'
                })
            })
        }
    }

    return data;
}
