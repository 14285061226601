import get from 'get-value';
import {domains, getCurrentFormTypes} from '../constants/Utils.js';
import {countryGroups, getFormTypes} from '../constants/Utils';
import {SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM} from '../constants/formConstants';

function hasAtLeastOneRoleSet(roles, allowedValues) {
    return Object.keys(roles)
        .filter(role => roles[role] === true && allowedValues.includes(role))
        .length > 0;
}

function hasAtLeastOneSolutionGroupSet(roles) {
    return Object.keys(roles).length > 0;
}

function getRoles(roles) {
    return Object.keys(roles)
        .map(role => ({group: role, value: roles[role]}))
        .filter(item => item.value === true)
        .sort((option1, option2) => option1.group.localeCompare(option2.group));
}

export const moduleRoles = {
    isPowerUser(roles, domain) {
        if (domain) {
            return get(roles, `${domain}.powerUser`, {default: false});
        } else {
            return Object.keys(domains)
                .map(domainKey => get(roles, `${domains[domainKey]}.powerUser`, {default: false}))
                .find(isPowerUser => isPowerUser);
        }
    },
    isPowerUserOrder(roles) {
        return this.isPowerUser(roles, domains.SPARE_PARTS);
    },
    isPowerUserDocumentation(roles) {
        return this.isPowerUser(roles, domains.TECHNICAL_DOCUMENTATION);
    },
    isPowerUserClaims(roles) {
        return this.isPowerUser(roles, domains.CLAIMS);
    },

    isAdmin(roles, domain) {
        if (domain) {
            return hasAtLeastOneRoleSet(
                get(roles, `${domain}.admin.groupPermission`, {default: {}}),
                Object.values(countryGroups)
            );
        } else {
            return Object.keys(domains)
                .map(domainKey => get(roles, `${domains[domainKey]}.admin.groupPermission`, {default: {}}))
                .find(roles => hasAtLeastOneRoleSet(roles, Object.values(countryGroups)));
        }
    },
    isSGAdmin(roles, domain) {
        if (domain) {
            return get(roles, `${domain}.sgAdmin.sgPermission`, {default: false});
        } else {
            return Object.keys(domains)
                .map(domainKey => get(roles, `${domains[domainKey]}.sgAdmin.sgPermission`, {default: {}}))
                .find(roles => hasAtLeastOneSolutionGroupSet(roles));
        }
    },
    isSGAdminOrderWithPrefix(roles, prefix) {
        return get(roles, `${domains.SPARE_PARTS}.sgAdmin.sgPermission`, {default: []}).find(sg => get( sg, "name", "").startsWith(prefix));
    },
    isSGAdminDocumentationWithPrefix(roles, prefix) {
        return get(roles, `${domains.TECHNICAL_DOCUMENTATION}.sgAdmin.sgPermission`, {default: []}).find(sg => get( sg, "name", "").startsWith(prefix));
    },
    isSGEditor(roles, domain) {
        if (domain) {
            return get(roles, `${domain}.sgEditor.sgPermission`, {default: false});
        }
    },
    isSGEditorDocumentation(roles) {
        return this.isSGEditor(roles, domains.TECHNICAL_DOCUMENTATION);
    },
    isSGEditorOrder(roles) {
        return this.isSGEditor(roles, domains.SPARE_PARTS);
    },
    isSGEditorLVL1onlyOrder(roles) {
        return this.isSGEditorLVL1only(roles, domains.SPARE_PARTS);
    },
    isSGEditorLVL23Order(roles) {
        return this.isSGEditorLVL23(roles, domains.SPARE_PARTS);
    },
    isSGEditorClaims(roles) {
        return this.isSGEditor(roles, domains.CLAIMS);
    },
    isSGEditorOrderInSG(roles, sgId) {
        return (get(roles, `${domains.SPARE_PARTS}.sgEditor.sgPermission`, {default: []}) || []).some(sg => sg.id === sgId);
    },
    isSGEditorDocumentationInSG(roles, sgId) {
        return (get(roles, `${domains.TECHNICAL_DOCUMENTATION}.sgEditor.sgPermission`, {default: []}) || []).some(sg => sg.id === sgId);
    },
    isSGEditorLVL23(roles, domain) {
        if (domain) {
            const solutionGroups = get(roles, `${domain}.sgEditor.sgPermission`, {default: []});
            return solutionGroups.some(sg => sg.groups.length === 0);
        }
    },
    isSGEditorLVL1only(roles, domain) {
        if (domain) {
            const solutionGroups = get(roles, `${domain}.sgEditor.sgPermission`);
            if (solutionGroups) {
                return solutionGroups.every(sg => sg.groups.length !== 0);
            }
        }
        return false;
    },
    isSGEditorLVL1onlyDocumentation(roles) {
        return this.isSGEditorLVL1only(roles, domains.TECHNICAL_DOCUMENTATION);
    },
    isSGEditorLVL1onlyForGroupsDocumentation(roles, groups) {
        return this.isSGDefaultEditorOnlyForGroups(roles, domains.TECHNICAL_DOCUMENTATION, groups);
    },
    isSGEditorLVL23Documentation(roles) {
        return this.isSGEditorLVL23(roles, domains.TECHNICAL_DOCUMENTATION);
    },
    isAdminOrder(roles) {
        return this.isAdmin(roles, domains.SPARE_PARTS);
    },
    isAdminDocumentation(roles) {
        return this.isAdmin(roles, domains.TECHNICAL_DOCUMENTATION);
    },
    isSGAdminDocumentation(roles, prefix) {
        if (prefix) {
            return this.isSGAdminDocumentationWithPrefix(roles, prefix);
        } else {
            return this.isSGAdmin(roles, domains.TECHNICAL_DOCUMENTATION);
        }
    },
    isSGAdminOrder(roles, prefix) {
        if (prefix) {
            return this.isSGAdminOrderWithPrefix(roles, prefix);
        } else {
            return this.isSGAdmin(roles, domains.SPARE_PARTS);
        }
    },
    isAdminClaims(roles) {
        return this.isAdmin(roles, domains.CLAIMS);
    },
    isEditor(roles, domain) {
        if (domain) {
            return hasAtLeastOneRoleSet(
                get(roles, `${domain}.editor.groupPermission`, {default: {}}),
                Object.values(countryGroups)
            );
        } else {
            return Object.keys(domains)
                .map(domainKey => get(roles, `${domains[domainKey]}.editor.groupPermission`, {default: {}}))
                .find(roles => hasAtLeastOneRoleSet(roles, Object.values(countryGroups)));
        }
    },
    isSGDefaultEditorForGroup(roles, domain, group) {
        if (domain) {
            const solutionGroups = get(roles, `${domain}.sgEditor.sgPermission`, {default: []});
            return solutionGroups.find(sg => (get(sg, 'groups', {default: []}) || []).includes(group));
        }
    },
    isSGDefaultEditorOnlyForGroups(roles, domain, groups) {
        if (domain) {
            const solutionGroups = get(get(roles, `${domain}.sgEditor.sgPermission`));
            if (!solutionGroups) {
                return false;
            }
            const countrySolutionGroupsUnion = [...new Set(solutionGroups.reduce(
                (result, sg) => {
                    get(sg, 'groups', {default: []}).forEach(group => result.push(group));
                    return result;
                },
                []
            ))];
            return solutionGroups.every(sg => sg.groups.length !== 0) && countrySolutionGroupsUnion.every(sg => groups.includes(sg));
        }
    },
    isEditorOrder(roles) {
        return this.isEditor(roles, domains.SPARE_PARTS);
    },
    isEditorDocumentation(roles) {
        return this.isEditor(roles, domains.TECHNICAL_DOCUMENTATION);
    },
    isEditorClaims(roles) {
        return this.isEditor(roles, domains.CLAIMS);
    },
    isDealer(roles, domain) {
        return hasAtLeastOneRoleSet(
                get(roles, `${domain}.dealer.formsPermission`, {default: {}}),
                getCurrentFormTypes(domain)
        );
    },
    isDealerOrder(roles) {
        return this.isDealer(roles, domains.SPARE_PARTS);
    },
    isDealerDocumentation(roles) {
        return this.isDealer(roles, domains.TECHNICAL_DOCUMENTATION);
    },
    isDealerClaims(roles) {
        return this.isDealer(roles, domains.CLAIMS);
    },
    getAdminGroups(domain, roles) {
        return getRoles(get(roles, `${domain}.admin.groupPermission`, {default: {}}));
    },
    getDealerForms(domain, roles) {
        return getRoles(get(roles, `${domain}.dealer.formsPermission`, {default: {}}));
    },
    getEditorGroups(domain, roles) {
        return getRoles(get(roles, `${domain}.editor.groupPermission`, {default: {}}));
    },
    getSGEditorGroups(domain, roles) {
        return get(roles, `${domain}.sgEditor.sgPermission`, {default: {}});
    },
    getSGAdminGroups(domain, roles) {
        return get(roles, `${domain}.sgAdmin.sgPermission`, {default: []});
    },
};
