import {
    DOCUMENTATION_INTERNAL_TICKET_STATUS_ANSWERED,
    DOCUMENTATION_INTERNAL_TICKET_STATUS_CLOSED,
    DOCUMENTATION_INTERNAL_TICKET_STATUS_IN_PROGRESS,
    DOCUMENTATION_INTERNAL_TICKET_STATUS_NEW,
    DOCUMENTATION_INTERNAL_TICKET_STATUS_REOPENED,
    DOCUMENTATION_INTERNAL_TICKET_STATUS_TRANSFERRED,
} from './formConstants';

export const editRights = {
    TD_SG_EDITOR: {
        [DOCUMENTATION_INTERNAL_TICKET_STATUS_NEW]: {
            canEditInternalTicketRequestDetail: false,
            canEditInternalTicketVehDetails: false,
            canEditVehDetails: false,
            canEditBvm: false,
            canEditInternalTicketPartDetail: false,
            canEditInternalTicketManagementGroup: true,
            canEditInternalTicketProblemDescText: false,
            canShowDocumentationUpdate: true,
            canEditDocumentationUpdate: true,
            canEditPartDocDocumentationUpdate: true,
            canAddNotes: true,
            canShowAutomaticResponse: true,
            canAddAttach: true,
            canSave: true,
            canAnswer: false,
            canClose: false,
            canReopen: false,
            canCancel: false,
            canAnswerToRequestor: false,
            canEscalate: false,
            canShowStoreCheckResult: true,
            canDeleteAttachments: false,
        },
        [DOCUMENTATION_INTERNAL_TICKET_STATUS_TRANSFERRED]: {
            canEditInternalTicketRequestDetail: false,
            canEditInternalTicketVehDetails: false,
            canEditVehDetails: false,
            canEditBvm: false,
            canEditInternalTicketPartDetail: false,
            canEditInternalTicketManagementGroup: false,
            canEditInternalTicketProblemDescText: false,
            canShowDocumentationUpdate: true,
            canEditDocumentationUpdate: false,
            canEditPartDocDocumentationUpdate: false,
            canAddNotes: false,
            canShowAutomaticResponse: false,
            canAddAttach: false,
            canSave: false,
            canAnswer: false,
            canClose: false,
            canReopen: false,
            canCancel: false,
            canAnswerToRequestor: false,
            canEscalate: false,
            canShowStoreCheckResult: false,
            canDeleteAttachments: false,
        },
        [DOCUMENTATION_INTERNAL_TICKET_STATUS_ANSWERED]: {
            canEditInternalTicketRequestDetail: false,
            canEditInternalTicketVehDetails: false,
            canEditVehDetails: false,
            canEditBvm: false,
            canEditInternalTicketPartDetail: false,
            canEditInternalTicketManagementGroup: false,
            canEditInternalTicketProblemDescText: false,
            canShowDocumentationUpdate: true,
            canEditDocumentationUpdate: false,
            canEditPartDocDocumentationUpdate: false,
            canAddNotes: false,
            canShowAutomaticResponse: false,
            canAddAttach: false,
            canSave: false,
            canAnswer: false,
            canClose: false,
            canReopen: false,
            canCancel: false,
            canAnswerToRequestor: false,
            canEscalate: false,
            canShowStoreCheckResult: false,
            canDeleteAttachments: false,
        },
        [DOCUMENTATION_INTERNAL_TICKET_STATUS_IN_PROGRESS]: {
            canEditInternalTicketRequestDetail: false,
            canEditInternalTicketVehDetails: false,
            canEditVehDetails: false,
            canEditBvm: false,
            canEditInternalTicketPartDetail: false,
            canEditInternalTicketManagementGroup: true,
            canEditInternalTicketProblemDescText: false,
            canShowDocumentationUpdate: true,
            canEditDocumentationUpdate: true,
            canEditPartDocDocumentationUpdate: true,
            canAddNotes: true,
            canShowAutomaticResponse: true,
            canAddAttach: true,
            canSave: true,
            canAnswer: true,
            canClose: true,
            canReopen: false,
            canCancel: false,
            canAnswerToRequestor: true,
            canEscalate: true,
            canShowStoreCheckResult: true,
            canDeleteAttachments: false,
        },
        [DOCUMENTATION_INTERNAL_TICKET_STATUS_REOPENED]: {
            canEditInternalTicketRequestDetail: false,
            canEditInternalTicketVehDetails: false,
            canEditVehDetails: false,
            canEditBvm: false,
            canEditInternalTicketPartDetail: false,
            canEditInternalTicketManagementGroup: true,
            canEditInternalTicketProblemDescText: false,
            canShowDocumentationUpdate: true,
            canEditDocumentationUpdate: true,
            canEditPartDocDocumentationUpdate: true,
            canAddNotes: true,
            canShowAutomaticResponse: true,
            canAddAttach: true,
            canSave: false,
            canAnswer: false,
            canClose: false,
            canReopen: false,
            canCancel: false,
            canAnswerToRequestor: false,
            canEscalate: true,
            canShowStoreCheckResult: true,
            canDeleteAttachments: false,
        },
        [DOCUMENTATION_INTERNAL_TICKET_STATUS_CLOSED]: {
            canEditInternalTicketRequestDetail: false,
            canEditInternalTicketVehDetails: false,
            canEditVehDetails: false,
            canEditBvm: false,
            canEditInternalTicketPartDetail: false,
            canEditInternalTicketManagementGroup: false,
            canEditInternalTicketProblemDescText: false,
            canShowDocumentationUpdate: true,
            canEditDocumentationUpdate: false,
            canEditPartDocDocumentationUpdate: false,
            canAddNotes: false,
            canShowAutomaticResponse: false,
            canAddAttach: false,
            canSave: false,
            canAnswer: false,
            canClose: false,
            canReopen: true,
            canCancel: false,
            canAnswerToRequestor: false,
            canEscalate: false,
            canShowStoreCheckResult: true,
            canDeleteAttachments: false,
        }
    }
};

export const createRights = {
    canEditInternalTicketRequestDetail: true,
    canEditInternalTicketVehDetails: true,
    canEditVehDetails: true,
    canEditBvm: true,
    canEditInternalTicketPartDetail: true,
    canEditInternalTicketManagementGroup: true,
    canEditInternalTicketProblemDescText: true,
    canShowDocumentationUpdate: true,
    canEditDocumentationUpdate: true,
    canEditPartDocDocumentationUpdate: true,
    canAddNotes: false,
    canShowAutomaticResponse: false,
    canAddAttach: true,
    canSave: true,
    canClose: false,
    canReopen: false,
    canCancel: true,
    canAnswerToRequestor: false,
    canShowStoreCheckResult: false,
    canAnswer: false,
    canEscalate: true,
    canDeleteAttachments: true,
};

export const editTechDocInternalTicketListButtonVisibility = {
    TD_SG_EDITOR: [
        DOCUMENTATION_INTERNAL_TICKET_STATUS_NEW,
        DOCUMENTATION_INTERNAL_TICKET_STATUS_TRANSFERRED,
        DOCUMENTATION_INTERNAL_TICKET_STATUS_ANSWERED,
        DOCUMENTATION_INTERNAL_TICKET_STATUS_IN_PROGRESS,
        DOCUMENTATION_INTERNAL_TICKET_STATUS_REOPENED,
    ]
};
