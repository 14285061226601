import {SPARE_PARTS_ORDERS_SET_LOCK, SPARE_PARTS_ORDERS_SET_UNLOCK} from '../../constants/websocketCommands';

export const SPARE_PARTS_ORDERS_RESET_REDIRECT = 'SPARE_PARTS_ORDERS_RESET_REDIRECT';
export const SPARE_PARTS_ORDERS_SET_LOCK_TICKET = 'SPARE_PARTS_ORDERS_SET_LOCK_TICKET';

export const lockSparePartsOrdersTicket = uuid => dispatch =>
    dispatch({
        type: SPARE_PARTS_ORDERS_SET_LOCK_TICKET,
        sparePartsWebsocket: true,
        command: SPARE_PARTS_ORDERS_SET_LOCK,
        payload: {uuid}
    });

export const unlockSparePartsOrdersTicket = uuid => dispatch =>
    dispatch({
        type: SPARE_PARTS_ORDERS_SET_UNLOCK,
        sparePartsWebsocket: true,
        command: SPARE_PARTS_ORDERS_SET_UNLOCK,
        payload: {uuid}
    });

export const resetRedirectAfterLockTicket = () => dispatch => dispatch({type: SPARE_PARTS_ORDERS_RESET_REDIRECT});
