import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from "react-intl";
import {
    FORM_FIELD_FULL_DATABASE,
    FORM_FIELD_INTERNATIONAL,
    FORM_LABEL_FULL_DATABASE,
    FORM_LABEL_INTERNATIONAL,
} from '../../../../constants/formConstants';
import {InputCheckBox} from '../../../common/InputCheckbox';
import {moduleRoles} from '../../../../utils/roles';
import {connect} from 'react-redux';
import searchEngineMessages from "../../../../intl/sparePartsOrders/searchEngineMessages";

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const SearchOption = (props) => {
    const { roles } = props;
    const isEditor = moduleRoles.isEditorOrder(roles);
    const isLvl1SgEditorOnly = moduleRoles.isSGEditorLVL1onlyOrder(roles);

    return (
        <>
            <h3 className="text-left ml-4">
                <FormattedMessage {...searchEngineMessages.SEARCH_OPTION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary p-4 bg-white">
                {moduleRoles.isDealerOrder(roles) &&
                <div className="form-row">
                    <div className="col-xl-4 col-lg-5 col-md-7 pb-2">
                        <InputCheckBox label={searchEngineMessages[FORM_LABEL_FULL_DATABASE]}
                                       field={FORM_FIELD_FULL_DATABASE}/>
                    </div>
                </div>
                }

                {(isEditor || isLvl1SgEditorOnly) &&
                <div className="form-row">
                    <InputCheckBox label={searchEngineMessages[FORM_LABEL_INTERNATIONAL]}
                                   field={FORM_FIELD_INTERNATIONAL}/>
                </div>
                }
            </StyledDiv>
        </>
    )
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
});

export default connect(mapStateToProps, {})(SearchOption);
