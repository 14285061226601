import React, {Component} from 'react';
import {cloneDeep} from 'lodash';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {clearAlerts} from '../../../actions/alertsActions';
import {Label} from '../../common/StyledComponents';
import SelectCountryGroup from '../../common/country/SelectCountryGroup';
import {
    fetchDefaultDealerRoleData, saveDefaultDealerRoleData, setDefaultDealerRolesChanged, setInitialDefaultDealerRoles,
    setSelectedDefaultDealerRoles,
    storeSelectedCountryGroup
} from '../../../actions/admin/actionAdminDefaultCountryRole';
import defaultCountryRoleMessages from '../../../intl/admin/defaultCountryRoleMessages';
import {moduleRoles} from '../../../utils/roles';
import get from 'get-value';
import SelectDefaultDealerRole from './SelectDefaultDealerRole';

class DefaultCountryRolePage extends Component {
    componentDidMount() {
        const {domain, roles, selectedCountryGroup} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);
        if (selectedCountryGroup === '' || !groups.map(group => group.group).includes(selectedCountryGroup)) {
            this.props.storeSelectedCountryGroup(get(groups, '0.group', ''));
            this.loadData(get(groups, '0.group', ''));
        } else {
            this.loadData(selectedCountryGroup);
        }
    }

    handleCountryGroupSelection = countryGroup => {
        this.props.storeSelectedCountryGroup(countryGroup.value);
        this.props.fetchDefaultDealerRoleData(this.props.domain, countryGroup.value);
    }

    loadData = countryGroup => {
        this.props.clearAlerts();
        this.props.storeSelectedCountryGroup(countryGroup);
        this.props.fetchDefaultDealerRoleData(this.props.domain, countryGroup);
    };

    handleFormTypeSelectionChange = newOptions => {
        this.setSelectedOptionsChanged(newOptions);
        this.props.setSelectedDefaultDealerRoles(newOptions);
    };

    handleAllFormTypesClick = () => {
        const selectedFormTypes = {};

        Object.keys(this.props.defaultDealerData || {}).forEach(
            formType => selectedFormTypes[formType] = true
        );

        const selectedOptions = this.setDefaultDealerRoleAsRoleOptions(selectedFormTypes);
        this.setSelectedOptionsChanged(selectedOptions);
    };


    setDefaultDealerRoleAsRoleOptions = (dealerData) => {
        const result = Object.keys(dealerData || {}).map(countryIso => ({value: countryIso}));
        this.props.setSelectedDefaultDealerRoles(cloneDeep(result));

        return result;
    };

    setSelectedOptionsChanged = newOptions => this.props.setDefaultDealerRolesChanged(
        JSON.stringify(
            cloneDeep(this.props.initialOptions)
                .sort((option1, option2) => option1.value.localeCompare(option2.value))
        ) !== JSON.stringify(
        newOptions
            .sort((option1, option2) => option1.value.localeCompare(option2.value))
        )
    );


    handleCancelClick = () => {
        this.props.setSelectedDefaultDealerRoles(cloneDeep(this.props.initialOptions));
        this.props.setDefaultDealerRolesChanged(false);
    };

    handleSaveClick = () => {
        const selectedFormTypes = {};

        Object.keys(this.props.defaultDealerData || {}).forEach(
            formType => selectedFormTypes[formType] = false
        );
        if (this.props.selectedOptions) {
            this.props.selectedOptions.forEach(
                ({value}) => {
                    selectedFormTypes[value] = true;
                }
            )
        }

        this.props.saveDefaultDealerRoleData(this.props.selectedCountryGroup, this.props.domain, selectedFormTypes);
        // this.loadData(this.props.selectedCountryGroup);
    };

    render() {
        const {isLoading, domain, selectedCountryGroup, roles} = this.props;

        const groups = moduleRoles.getAdminGroups(domain, roles);

        if (isLoading) {
            return <Loader/>;
        }
        return (
            <div>
                <PageHeader title={<FormattedMessage {...defaultCountryRoleMessages.TITLE}/>}/>
                <div className="form-row mb-sm-3">
                    <div className="col-xl-2 col-lg-4 col-md-5 col-sm-6">
                        <Label>
                            <FormattedMessage {...defaultCountryRoleMessages.SELECT_COUNTRY}/>
                        </Label>
                        <SelectCountryGroup groups={groups}
                                            handleCountryGroupSelection={this.handleCountryGroupSelection}
                                            selectedCountryGroup={selectedCountryGroup}/>


                    </div>
                </div>
                    <SelectDefaultDealerRole handleFormTypeSelectionChange={this.handleFormTypeSelectionChange}
                                              handleAllFormTypesClick={this.handleAllFormTypesClick}
                                              handleSaveClick={this.handleSaveClick}
                                              handleCancelClick={this.handleCancelClick}/>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    isLoading: state.adminDefaultDealerRole.isLoading,
    roles: state.profile.userDetail.roles,
    selectedCountryGroup: state.adminDefaultDealerRole.selectedCountryGroup,
    defaultDealerData: state.adminDefaultDealerRole.defaultDealerRole,
    initialOptions: state.adminDefaultDealerRole.initialDefaultDealerRoles,
    selectedOptions: state.adminDefaultDealerRole.selectedDefaultDealerRoles,
});

export default connect(mapStateToProps, {
    clearAlerts,
    storeSelectedCountryGroup,
    fetchDefaultDealerRoleData,
    setSelectedDefaultDealerRoles,
    setDefaultDealerRolesChanged,
    setInitialDefaultDealerRoles,
    saveDefaultDealerRoleData,
})(DefaultCountryRolePage);
