import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {PrimaryButton} from '../common/Button';
import {ModalBody, ModalFooter, ModalHeader, ModalWindow} from '../common/modal/Modal';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Loader from '../Loader';
import buttonMessages from '../../intl/common/buttonMessages';
import popUpMessages from '../../intl/sparePartsOrders/popUpMessages';

class OrderPopUpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
        }
    }

    handleChange = (e) => {
        this.setState({checked: e.target.checked});
    };

    render() {
        const {show, handleClose, isLoading, popUp} = this.props;
        return (
            <ModalWindow isOpen={show}>
                <ModalHeader>
                    <FormattedMessage {...popUpMessages.TITLE}/>
                </ModalHeader>
                <div>
                    <ModalBody>
                        {isLoading && <Loader/>}
                        {!isLoading &&
                        <p style={{whiteSpace: "pre-wrap"}}>
                            {popUp.text && popUp.text}
                            {!popUp.text && <FormattedMessage {...popUpMessages.NO_MESSAGE}/>}
                        </p>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <div className="row">
                            <label className="col">
                                <input type="checkbox" onChange={this.handleChange} checked={this.state.checked}
                                       disabled={isLoading}
                                       className="mr-sm-1"/>
                                <FormattedMessage  {...popUpMessages.CONFIRMATION_MESSAGE}/>
                            </label>
                        </div>
                        <div className="row">
                            <div className="col">
                                <PrimaryButton className="btn" onClick={handleClose} disabled={!this.state.checked}>
                                    <FormattedMessage {...buttonMessages.ACCEPT}/>
                                </PrimaryButton>
                            </div>
                        </div>
                    </ModalFooter>
                </div>
            </ModalWindow>
        );
    }
}

OrderPopUpModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    popUp: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    popUp: state.orderPopUpModal.popUp,
    isLoading: state.orderPopUpModal.isLoading,
});

export default withRouter(connect(mapStateToProps, {})(OrderPopUpModal));
