import React from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import {connect} from 'react-redux';
import {PrimaryButton, SecondaryButton} from '../../common/Button';
import {InputFieldWithValidation} from '../../common/InputFieldWithValidation';
import {
    FORM_FIELD_BATTERY_PROBLEM_TYPE,
    FORM_FIELD_BIN,
    FORM_FIELD_CLIENT_ID, FORM_FIELD_COUNTRY_CODE,
    FORM_FIELD_DEALER_NO,
    FORM_FIELD_DIC_NUMBER, FORM_FIELD_DOC_SOLUTION_GROUP,
    FORM_FIELD_INVOICE, FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_PART_NO,
    FORM_FIELD_PFX,
    FORM_FIELD_REGION,
    FORM_FIELD_REGISTRATION_NO,
    FORM_FIELD_SFX, FORM_FIELD_SOLUTION_GROUP,
    FORM_FIELD_VIN,
    FORM_LABEL_BATTERY_PROBLEM_TYPE,
    FORM_LABEL_BIN,
    FORM_LABEL_CLAIM_PROBLEM_TYPE,
    FORM_LABEL_CLIENT_ID, FORM_LABEL_COUNTRY_CODE,
    FORM_LABEL_DEALER_NO,
    FORM_LABEL_DIC_NUMBER,
    FORM_LABEL_INVOICE_NUMBER, FORM_LABEL_MANAGEMENT_GROUP,
    FORM_LABEL_PART_NO,
    FORM_LABEL_REGION,
    FORM_LABEL_REGISTRATION_NO,
    FORM_LABEL_SOLUTION_GROUP,
    FORM_LABEL_TICKET_NUMBER,
    FORM_LABEL_VIN,
    FORM_SEARCH_OPTION_SECTION,
} from '../../../constants/formConstants';
import styled from 'styled-components';
import {FormSection, reduxForm} from 'redux-form';
import {FormattedMessage} from 'react-intl';
import buttonMessages from '../../../intl/common/buttonMessages';
import InputFieldTicketNumber from '../../common/InputFieldTicketNumber';
import RenderSyncErrors from '../../common/RenderSyncErrors';
import searchEngineMessages from "../../../intl/sparePartsOrders/searchEngineMessages";
import {sparePartsIntlFieldMapping} from "../../../constants/sparePartsFieldLabelMapping";
import SearchOption from '../../sparePartsOrders/searchEngine/section/SearchOption';
import {Field} from "redux-form";
import {handleClientIdSearch} from '../../../utils/utils';
import {handleFetchSearchClientIdsError} from '../../../actions/admin/actionAdminClientIds';
import {AsyncSelectField} from '../../common/FormField';
import commonMessages from '../../../intl/common/commonMessages';
import {injectIntl} from 'react-intl';
import {DIC_FIELD_MAX_LENGTH} from '../../../constants/validationConstants';
import InputSelectFieldWithValidation from '../../common/InputSelectFieldWithValidation';
import formMessages from '../../../intl/sparePartsOrders/formMessages';
import get from 'get-value';
import {batteryProblemTypes, domains} from '../../../constants/Utils';
import batteryProblemTypeMessages from '../../../intl/sparePartsOrders/batteryProblemTypeMessages';
import {moduleRoles} from '../../../utils/roles';
import fulltextSearchMessages from '../../../intl/technicalDocumentations/fulltextSearchMessages';
import {uniqBy} from 'lodash';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const domain = domains.SPARE_PARTS;

class SearchEngineOrderForm extends React.Component {
    reset =  () => {
        this.props.resetForm();
        this.props.reset();
    }

    render() {
        const { handleSubmit, form, handleFetchSearchClientIdsError, intl: {formatMessage}, user } = this.props;
        const roles = get(user, "roles", {})
        const managedGroups = get(this.props, `user.roles.${domain}.dealer`) ? [get(this.props, 'user.group', 'XX')] : moduleRoles.getEditorGroups(domain, get(this.props, 'user.roles', {})).map(({group}) => group);

        const isSgEditor = moduleRoles.isSGEditorOrder(roles);
        const isEditor = !isSgEditor && moduleRoles.isEditorOrder(roles);
        const isDealer = !isSgEditor && !isEditor && moduleRoles.isDealerOrder(roles);

        const getSolutionGroups = () => {
            let sgOptions = [];

            if (moduleRoles.isSGEditor(roles, domains.SPARE_PARTS) && (get(roles, 'SPARE_PARTS.sgEditor.sgPermission.length') > 0)){
                const sgEditorOptions = (get(roles, 'SPARE_PARTS.sgEditor.sgPermission') || []).map(sg => ({
                    uuid: sg.id,
                    presetName: sg.name
                }))
                sgOptions = sgOptions.concat(sgEditorOptions);
            }
            if (moduleRoles.isSGAdmin(roles, domains.SPARE_PARTS) && (get(roles, 'SPARE_PARTS.sgAdmin.sgPermission.length') > 0)){
                const sgAdminOptions = (get(roles, 'SPARE_PARTS.sgAdmin.sgPermission') || []).map(sg => ({
                    uuid: sg.id,
                    presetName: sg.name
                }))
                sgOptions = sgOptions.concat(sgAdminOptions);
            }
            return uniqBy(sgOptions, (e => (e.uuid)));
        }

        return (
            <StyledDiv className="border border-secondary p-4 bg-white">
                <RenderSyncErrors form={form} intlMessages={searchEngineMessages} mapping={sparePartsIntlFieldMapping}/>
                <div className="row justify-content-center">
                    <div className="col-xl-8">
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldWithValidation label={searchEngineMessages[FORM_LABEL_DEALER_NO]}
                                                          field={FORM_FIELD_DEALER_NO}
                                                          maxLength={8}
                                                          isSmall/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <label className="col-form-label pl-0 font-weight-bold">
                                    <FormattedMessage {...searchEngineMessages[FORM_LABEL_CLIENT_ID]}/>
                                </label>
                                <Field component={AsyncSelectField}
                                       name={FORM_FIELD_CLIENT_ID}
                                       isClearable
                                       useCustomStyles
                                       noOptionsMessage={() => formatMessage(commonMessages.NONE)}
                                       loadingMessage={() => formatMessage(commonMessages.LOADING)}
                                       loadOptions={(inputValue, callback) => {handleClientIdSearch(inputValue, callback, handleFetchSearchClientIdsError)}}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldWithValidation label={searchEngineMessages[FORM_LABEL_REGISTRATION_NO]}
                                                          field={FORM_FIELD_REGISTRATION_NO}
                                                          maxLength={10}
                                                          isSmall/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldWithValidation label={searchEngineMessages[FORM_LABEL_PART_NO]}
                                                          field={FORM_FIELD_PART_NO}
                                                          maxLength={10}
                                                          isSmall/>
                            </div>
                        </div>
                        {!isDealer &&
                            <div className="form-row">
                                <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                    <InputFieldWithValidation label={searchEngineMessages[FORM_LABEL_MANAGEMENT_GROUP]}
                                                              field={FORM_FIELD_MANAGEMENT_GROUP}
                                                              maxLength={2}
                                                              isSmall/>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                    <InputFieldWithValidation label={searchEngineMessages[FORM_LABEL_COUNTRY_CODE]}
                                                              field={FORM_FIELD_COUNTRY_CODE}
                                                              maxLength={3}
                                                              isSmall/>
                                </div>
                            </div>
                        }
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldWithValidation label={searchEngineMessages[FORM_LABEL_REGION]}
                                                          field={FORM_FIELD_REGION}
                                                          maxLength={6}
                                                          isSmall/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldWithValidation label={searchEngineMessages[FORM_LABEL_DIC_NUMBER]}
                                                          field={FORM_FIELD_DIC_NUMBER}
                                                          maxLength={DIC_FIELD_MAX_LENGTH}
                                                          isSmall/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldWithValidation label={searchEngineMessages[FORM_LABEL_BIN]}
                                                          field={FORM_FIELD_BIN}
                                                          maxLength={17}
                                                          isSmall/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldWithValidation label={searchEngineMessages[FORM_LABEL_INVOICE_NUMBER]}
                                                          field={FORM_FIELD_INVOICE}
                                                          isSmall/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldWithValidation label={searchEngineMessages[FORM_LABEL_VIN]}
                                                          field={FORM_FIELD_VIN}
                                                          maxLength={17}
                                                          isSmall/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldTicketNumber label={searchEngineMessages[FORM_LABEL_TICKET_NUMBER]}
                                                        fields={[FORM_FIELD_PFX, FORM_FIELD_SFX]}
                                                        validation/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_CLAIM_PROBLEM_TYPE]}
                                                                field={FORM_FIELD_BATTERY_PROBLEM_TYPE}
                                                                options={[...new Set(managedGroups.reduce((acc, cur) => batteryProblemTypes[cur] ? acc.concat(batteryProblemTypes[cur]) : acc, []))]}
                                                                intlMessages={batteryProblemTypeMessages}
                                />
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_SOLUTION_GROUP]}
                                                                field={FORM_FIELD_SOLUTION_GROUP}
                                                                customOptionsMsg={getSolutionGroups()}/>
                            </div>
                        </div>
                        {(user.group === "AT" || user.group === "HU") ? '' :
                            <div className="form-row">
                                <div className="col-xl-12 col-lg-12 col-md-12 pb-2">
                                    <FormSection name={FORM_SEARCH_OPTION_SECTION}>
                                        <SearchOption form={form}/>
                                    </FormSection>
                                </div>
                            </div>
                        }
                        <form className="form-group">
                            <div className="col-12 pb-2">
                                <PrimaryButton type="button"
                                               className="btn mr-2 float-right"
                                               onClick={handleSubmit}>
                                    <FormattedMessage {...buttonMessages.SEARCH}/>
                                </PrimaryButton>
                                <SecondaryButton type="button"
                                                 className="btn float-right mr-2 bg-white"
                                                 onClick={this.reset}>
                                    <FormattedMessage {...buttonMessages.CANCEL}/>
                                </SecondaryButton>
                            </div>
                        </form>
                    </div>
                </div>
            </StyledDiv>
        )
    }
}

SearchEngineOrderForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    handleFetchSearchClientIdsError: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
};


export default reduxForm({})(connect(null, {handleFetchSearchClientIdsError})(injectIntl(SearchEngineOrderForm)));
