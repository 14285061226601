import {
    USER_ROLE_FETCH_ERROR,
    USER_ROLE_FETCH_PENDING,
    USER_ROLE_FETCH_SUCCESS,
    USER_ROLE_SAVE_ERROR,
    USER_ROLE_SAVE_PENDING,
    USER_ROLE_SAVE_SUCCESS,
    USER_TO_BE_CREATED
} from '../actions/actionRole';
import {moduleRoles} from '../utils/roles';

export const profile = (state = {
    isLoading: true,
    userDetail: {},
    userRoles: [],
    userToBeCreated: false
}, action) => {
    switch (action.type) {
        case USER_ROLE_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case USER_ROLE_FETCH_SUCCESS: {
            const {roles} = action.payload;
            const userRoles = [];
            if (moduleRoles.isEditorOrder(roles)) {
                userRoles.push('SP_EDITOR');
            }
            if (moduleRoles.isDealerOrder(roles)) {
                userRoles.push('SP_DEALER');
            }
            if (moduleRoles.isAdminOrder(roles)) {
                userRoles.push('SP_ADMIN');
            }
            if (moduleRoles.isSGAdminOrder(roles)) {
                userRoles.push('SP_SG_ADMIN');
            }
            if (moduleRoles.isSGEditorOrder(roles)) {
                userRoles.push('SP_SG_EDITOR');
            }
            if (moduleRoles.isPowerUserOrder(roles)) {
                userRoles.push('SP_POWER_USER');
            }
            if (moduleRoles.isEditorDocumentation(roles)) {
                userRoles.push('TD_EDITOR');
            }
            if (moduleRoles.isDealerDocumentation(roles)) {
                userRoles.push('TD_DEALER');
            }
            if (moduleRoles.isAdminDocumentation(roles)) {
                userRoles.push('TD_ADMIN');
            }
            if (moduleRoles.isSGAdminDocumentation(roles)) {
                userRoles.push('TD_SG_ADMIN');
            }
            if (moduleRoles.isSGEditorDocumentation(roles)) {
                userRoles.push('TD_SG_EDITOR');
            }
            if (moduleRoles.isPowerUserDocumentation(roles)) {
                userRoles.push('TD_POWER_USER');
            }
            if (moduleRoles.isEditorClaims(roles)) {
                userRoles.push('CL_EDITOR');
            }
            if (moduleRoles.isDealerClaims(roles)) {
                userRoles.push('CL_DEALER');
            }
            if (moduleRoles.isAdminClaims(roles)) {
                userRoles.push('CL_ADMIN');
            }
            if (moduleRoles.isPowerUserClaims(roles)) {
                userRoles.push('CL_POWER_USER');
            }

            return Object.assign({}, state, {isLoading: false, userDetail: action.payload, userRoles});
        }
        case USER_ROLE_FETCH_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case USER_ROLE_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true, userToBeCreated: false});
        }
        case USER_ROLE_SAVE_SUCCESS: {
            return Object.assign({}, state, {isLoading: false});
        }
        case USER_ROLE_SAVE_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case USER_TO_BE_CREATED: {
            return Object.assign({}, state, {userToBeCreated: true});
        }
        default:
            return state;
    }
};
