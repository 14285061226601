import Joi from 'joi';

export function validateSolutionGroups(solutionGroups) {
    return Joi.validate(
        solutionGroups,
        Joi.array().required().items(
            Joi.object().keys({
                id: Joi.string().required().guid({version: ['uuidv4']}),
                name: Joi.string().required(),
                users: Joi.array().required().items(
                    Joi.object().keys(getUserSchema())
                )
            })
        )
    );
}

export function validateSolutionGroupCreateEvent(solutionGroupCreateEvent) {
    return Joi.validate(
        solutionGroupCreateEvent,
        Joi.object().required().keys({
            id: Joi.string().required().guid({version: ['uuidv4']}),
            name: Joi.string().required(),
            isActive: Joi.boolean(),
            isDefault: Joi.boolean(),
            canForwardToDealer: Joi.boolean(),
            notifications: Joi.boolean(),
            groups: Joi.array(),
            admins: Joi.array(),
            editors: Joi.array(),
            internalNotifications: Joi.boolean(),
        })
    );
}

export function validateSolutionGroupUpdateEvent(solutionGroupUpdateEvent) {
    return Joi.validate(
        solutionGroupUpdateEvent,
        Joi.object().required().keys({
            id: Joi.string().required().guid({version: ['uuidv4']}),
            name: Joi.string(),
            isActive: Joi.boolean(),
            isDefault: Joi.boolean(),
            groups: Joi.array(),
            admins: Joi.array(),
            editors: Joi.array(),
            canForwardToDealer: Joi.boolean(),
            notifications: Joi.boolean(),
            internalNotifications: Joi.boolean(),
        })
    );
}

function getUserSchema() {
    return {
        ipn: Joi.string().required(),
        name: Joi.string().required()
    };
}
