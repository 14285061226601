import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';
import fulltextSearchMessages from '../../../../intl/claims/fulltextSearchMessages';
import {
    FORM_FIELD_CLAIM_RELATED,
    FORM_FIELD_INVOICE,
    FORM_FIELD_MISSING_PACKAGE,
    FORM_FIELD_PACKAGE_NO,
    FORM_FIELD_CONTAINER,
    FORM_FIELD_PART_NO,
    FORM_FIELD_TAKING_BACK,
    FORM_FIELD_WRONG_DESTINATION,
    FORM_LABEL_CLAIM_RELATED,
    FORM_LABEL_INVOICE,
    FORM_LABEL_MISSING_PACKAGE,
    FORM_LABEL_PACKAGE_NO,
    FORM_LABEL_CONTAINER,
    FORM_LABEL_PART_NO,
    FORM_LABEL_TAKING_BACK,
    FORM_LABEL_WRONG_DESTINATION,
    FORM_LABEL_CLIENT_SOLUTION_PILOT,
    FORM_FIELD_CLIENT_SOLUTION_PILOT, FORM_FIELD_SUPPLIER, FORM_LABEL_SUPPLIER,
} from '../../../../constants/formConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const ProblemDescriptionSectionClaimsSearch = () => {
    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...fulltextSearchMessages.PROBLEM_DESCRIPTION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-md-6 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_CLAIM_RELATED]}
                                                  field={FORM_FIELD_CLAIM_RELATED}
                                                  maxLength={5}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-md-6 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_INVOICE]}
                                                  field={FORM_FIELD_INVOICE}
                                                  maxLength={6}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-md-8 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_PART_NO]}
                                                  field={FORM_FIELD_PART_NO}
                                                  maxLength={10}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-3 col-md-8 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_PACKAGE_NO]}
                                                  field={FORM_FIELD_PACKAGE_NO}
                                                  maxLength={10}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-md-8 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_MISSING_PACKAGE]}
                                                  field={FORM_FIELD_MISSING_PACKAGE}
                                                  maxLength={10}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-md-8 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_SUPPLIER]}
                                                  field={FORM_FIELD_SUPPLIER}
                                                  maxLength={20}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-4 col-md-10 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_TAKING_BACK]}
                                                  field={FORM_FIELD_TAKING_BACK}
                                                  maxLength={15}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-md-8 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_CONTAINER]}
                                                  field={FORM_FIELD_CONTAINER}
                                                  maxLength={7}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-6 col-md-6 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_WRONG_DESTINATION]}
                                                  field={FORM_FIELD_WRONG_DESTINATION}
                                                  maxLength={10}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-6 col-md-6 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_CLIENT_SOLUTION_PILOT]}
                                                  field={FORM_FIELD_CLIENT_SOLUTION_PILOT}
                                                  maxLength={7}
                                                  isSmall/>
                    </div>
                </div>
            </StyledDiv>
        </>
    )
};

export default (ProblemDescriptionSectionClaimsSearch);
