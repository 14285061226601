import {
    TECH_DOC_ATTACHMENTS_SAVED_ERROR,
    TECH_DOC_GET_TICKET,
    TECH_DOC_TICKET_ATTACHMENTS_SAVED,
    TECH_DOC_TICKET_CREATED,
    TECH_DOC_TICKET_LOCKED,
    TECH_DOC_TICKET_LOCKED_ERROR,
    TECH_DOC_TICKET_LOCKED_FATAL,
    TECH_DOC_TICKET_SEND,
    TECH_DOC_TICKET_UPDATED,
} from '../../constants/websocketCommands';
import {
    TECH_DOC_TICKET_CREATE,
    TECH_DOC_TICKET_RESET, TECH_DOC_TICKET_SUBMIT,
    TECH_DOC_TICKET_UPDATE
} from '../../actions/techDoc/actionTechDocTicket';
import {TECH_DOC_ATTACHMENTS_UPLOADING_ERROR} from '../../actions/techDoc/actionTechDocAttachments';
import {TECH_DOC_RESET_REDIRECT, TECH_DOC_SET_LOCK_TICKET} from '../../actions/techDoc/actionTechDocLockTicket';
import {SPARE_PARTS_ORDERS_TICKET_SUBMIT} from '../../actions/orders/actionTicket';

export const techDocTicket = (state = {
    correlationId: null,
    isTicketLoading: false,
    isTicketCreating: false,
    created: false,
    ticket: {},
    vehicleDetail: {},
    supplementaryInformation: {},
    problemDescription: {},
    logs: [],
    messages: [],
    attachments: [],
    newAttachments: [],
    submittedBy: null,
}, action) => {
    switch (action.type) {
        case TECH_DOC_TICKET_SUBMIT: {
            const { submittedBy } = action.payload;
            return Object.assign({}, state, {
                submittedBy
            });
        }
        case TECH_DOC_TICKET_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLoading: false,
                isTicketCreating: false,
                created: false,
                ticket: {},
                vehicleDetail: {},
                supplementaryInformation: {},
                problemDescription: {},
                logs: [],
                attachments: [],
                newAttachments: [],
                submittedBy: null,
            });
        }
        case TECH_DOC_TICKET_UPDATE:
        case TECH_DOC_TICKET_CREATE: {
            const {newAttachments} = action.payload;
            return Object.assign({}, state, {
                newAttachments,
                isTicketCreating: true,
                created: false,
                isTicketLoading: true,
                vehicleDetail: {},
                supplementaryInformation: {},
                problemDescription: {},
                messages: [],
                logs: [],
                attachments: [],
            });
        }
        //command accepted
        case TECH_DOC_TICKET_CREATED:
        case TECH_DOC_TICKET_UPDATED:
        case TECH_DOC_GET_TICKET: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketCreating: false,
                created: true,
                isTicketLoading: true,
                correlationId
            });
        }
        case TECH_DOC_TICKET_SEND: {
            const {ticket, correlationId, vehicleDetail, supplementaryInformation, problemDescription, logs, attachments, error, messages, solutionGroupInfo, documentationUpdate, editorsNotes, internalTicketReferences} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, {isTicketLoading: false, correlationId});
            }
            return Object.assign({}, state, {
                isTicketLoading: false,
                correlationId,
                ticket,
                vehicleDetail,
                supplementaryInformation,
                problemDescription,
                logs,
                attachments,
                messages,
                solutionGroupInfo,
                documentationUpdate,
                editorsNotes,
                internalTicketReferences,
            });
        }
        //att done + error
        case TECH_DOC_ATTACHMENTS_UPLOADING_ERROR:
        case TECH_DOC_ATTACHMENTS_SAVED_ERROR: {
            return Object.assign({}, state, {
                newAttachments: [],
            });
        }
        case TECH_DOC_TICKET_ATTACHMENTS_SAVED: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketLoading: true,
                correlationId,
                newAttachments: [],
            });
        }
        default:
            return state;
    }
};

export const techDocLockTicketCheck = (state = {
    correlationId: null,
    isTicketLocking: false,
    redirect: false,
}, action) => {
    switch (action.type) {
        case TECH_DOC_TICKET_LOCKED_ERROR:
        case TECH_DOC_TICKET_LOCKED_FATAL: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isTicketLocking: false,
                redirect: true,
            });
        }
        case TECH_DOC_RESET_REDIRECT: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case TECH_DOC_TICKET_LOCKED: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case TECH_DOC_SET_LOCK_TICKET: {
            const {uuid} = action.payload;
            return Object.assign({}, state, {
                isTicketLocking: true,
                redirect: false,
                uuid,
            });
        }
        default:
            return state;
    }
};
