import React, {Component} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        minWidth: '600px',
        maxHeight: '70%',
        overflowY: 'auto',
    },
    overlay: {
        zIndex: 1000
    }
};

const HeaderStyle = styled.div`
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 700
`;

const BodyStyle = styled.div`
    position: relative;
    padding: 20px;
`;

const FooterStyle = styled.div`
    margin-top: 15px;
    padding: 19px 20px 20px;
    text-align: center;
    border-top: 1px solid #e5e5e5;
`;

export const ModalHeader = props =>
    <HeaderStyle>
        {props.children}
    </HeaderStyle>
;

export const ModalBody = props =>
    <BodyStyle>
        {props.children}
    </BodyStyle>
;

export const ModalFooter = props =>
    <FooterStyle>
        {props.children}
    </FooterStyle>
;

export class ModalWindow extends Component {
    componentDidMount() {
        Modal.setAppElement('body');
    }

    render() {
        return (
            <Modal style={this.props.customStyles ? this.props.customStyles : customStyles} {...this.props}>
                {this.props.children}
            </Modal>
        )
    }
}

ModalWindow.propTypes = {
    children: PropTypes.any.isRequired
};
ModalHeader.propTypes = {
    children: PropTypes.any.isRequired
};
ModalBody.propTypes = {
    children: PropTypes.any.isRequired
};
ModalFooter.propTypes = {
    children: PropTypes.any.isRequired
};
