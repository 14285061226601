import {defineMessages} from 'react-intl';

const messages = defineMessages({
    AR: {
        id: 'common.country.ar',
        defaultMessage: 'Argentina'
    },
    AT: {
        id: 'common.country.at',
        defaultMessage: 'Austria'
    },
    BA: {
        id: 'common.country.ba',
        defaultMessage: 'Bosnia and Herzegovina'
    },
    BE: {
        id: 'common.country.be',
        defaultMessage: 'Belgium'
    },
    CH: {
        id: 'common.country.ch',
        defaultMessage: 'Switzerland'
    },
    CZ: {
        id: 'common.country.cz',
        defaultMessage: 'Czech Republic'
    },
    DE: {
        id: 'common.country.de',
        defaultMessage: 'Germany'
    },
    DK: {
        id: 'common.country.dk',
        defaultMessage: 'Denmark'
    },
    DZ: {
        id: 'common.country.dz',
        defaultMessage: 'Algeria'
    },
    EE: {
        id: 'common.country.ee',
        defaultMessage: 'Estonia'
    },
    ES: {
        id: 'common.country.es',
        defaultMessage: 'Spain'
    },
    FI: {
        id: 'common.country.fi',
        defaultMessage: 'Finland'
    },
    FR: {
        id: 'common.country.fr',
        defaultMessage: 'France'
    },
    HR: {
        id: 'common.country.hr',
        defaultMessage: 'Croatia'
    },
    HU: {
        id: 'common.country.hu',
        defaultMessage: 'Hungary'
    },
    IE: {
        id: 'common.country.ie',
        defaultMessage: 'Ireland'
    },
    IT: {
        id: 'common.country.it',
        defaultMessage: 'Italy'
    },
    LT: {
        id: 'common.country.lt',
        defaultMessage: 'Lithuania'
    },
    LU: {
        id: 'common.country.lu',
        defaultMessage: 'Luxembourg'
    },
    LV: {
        id: 'common.country.lv',
        defaultMessage: 'Latvia'
    },
    MA: {
        id: 'common.country.ma',
        defaultMessage: 'Morocco'
    },
    ME: {
        id: 'common.country.me',
        defaultMessage: 'Montenegro'
    },
    MK: {
        id: 'common.country.mk',
        defaultMessage: 'North Macedonia'
    },
    MX: {
        id: 'common.country.mx',
        defaultMessage: 'Mexico'
    },
    NL: {
        id: 'common.country.nl',
        defaultMessage: 'Netherlands'
    },
    NO: {
        id: 'common.country.no',
        defaultMessage: 'Norway'
    },
    PL: {
        id: 'common.country.pl',
        defaultMessage: 'Poland'
    },
    PT: {
        id: 'common.country.pt',
        defaultMessage: 'Portugal'
    },
    RO: {
        id: 'common.country.ro',
        defaultMessage: 'Romania'
    },
    RS: {
        id: 'common.country.rs',
        defaultMessage: 'Serbia'
    },
    SE: {
        id: 'common.country.se',
        defaultMessage: 'Sweden'
    },
    SI: {
        id: 'common.country.si',
        defaultMessage: 'Slovenia'
    },
    AL: {
        id: 'common.country.al',
        defaultMessage: 'Albania'
    },
    SK: {
        id: 'common.country.sk',
        defaultMessage: 'Slovakia'
    },
    GB: {
        id: 'common.country.gb',
        defaultMessage: 'United Kingdom'
    },
    XK: {
        id: 'common.country.xk',
        defaultMessage: 'Kosovo'
    },
    IMP: {
        id: 'common.country.imp',
        defaultMessage: 'Importers'
    },
    CM: {
        id: 'common.country.cm',
        defaultMessage: 'Cameroon'
    },
    CV: {
        id: 'common.country.cv',
        defaultMessage: 'Cabo Verde'
    },
    LK: {
        id: 'common.country.lk',
        defaultMessage: 'Sri Lanka'
    },
    TD: {
        id: 'common.country.td',
        defaultMessage: 'Chad'
    },
    TW: {
        id: 'common.country.tw',
        defaultMessage: 'Taiwan'
    },
    YT: {
        id: 'common.country.yt',
        defaultMessage: 'Mayotte'
    },
    CG: {
        id: 'common.country.cg',
        defaultMessage: 'Congo'
    },
    CY: {
        id: 'common.country.cy',
        defaultMessage: 'Cyprus'
    },
    BJ: {
        id: 'common.country.bj',
        defaultMessage: 'Benin'
    },
    GF: {
        id: 'common.country.gf',
        defaultMessage: 'French Guiana'
    },
    DJ: {
        id: 'common.country.dj',
        defaultMessage: 'Djibouti'
    },
    GA: {
        id: 'common.country.ga',
        defaultMessage: 'Gabon'
    },
    GE: {
        id: 'common.country.ge',
        defaultMessage: 'Georgia'
    },
    PS: {
        id: 'common.country.ps',
        defaultMessage: 'Palestine'
    },
    GH: {
        id: 'common.country.gh',
        defaultMessage: 'Ghana'
    },
    GP: {
        id: 'common.country.gp',
        defaultMessage: 'Guadeloupe'
    },
    HK: {
        id: 'common.country.hk',
        defaultMessage: 'Hong Kong'
    },
    ID: {
        id: 'common.country.id',
        defaultMessage: 'Indonesia'
    },
    IR: {
        id: 'common.country.ir',
        defaultMessage: 'Iran'
    },
    IQ: {
        id: 'common.country.iq',
        defaultMessage: 'Iraq'
    },
    IL: {
        id: 'common.country.il',
        defaultMessage: 'Israel'
    },
    CI: {
        id: 'common.country.ci',
        defaultMessage: 'Ivory Coast'
    },
    JO: {
        id: 'common.country.jo',
        defaultMessage: 'Jordan'
    },
    KE: {
        id: 'common.country.ke',
        defaultMessage: 'Kenya'
    },
    KW: {
        id: 'common.country.kw',
        defaultMessage: 'Kuwait'
    },
    LB: {
        id: 'common.country.lb',
        defaultMessage: 'Lebanon'
    },
    LR: {
        id: 'common.country.lr',
        defaultMessage: 'Liberia'
    },
    LY: {
        id: 'common.country.ly',
        defaultMessage: 'Libya'
    },
    MG: {
        id: 'common.country.mg',
        defaultMessage: 'Madagascar'
    },
    MW: {
        id: 'common.country.mw',
        defaultMessage: 'Malawi'
    },
    MY: {
        id: 'common.country.my',
        defaultMessage: 'Malaysia'
    },
    ML: {
        id: 'common.country.ml',
        defaultMessage: 'Mali'
    },
    MT: {
        id: 'common.country.mt',
        defaultMessage: 'Malta'
    },
    MQ: {
        id: 'common.country.mq',
        defaultMessage: 'Martinique'
    },
    MU: {
        id: 'common.country.mu',
        defaultMessage: 'Mauritius'
    },
    MZ: {
        id: 'common.country.mz',
        defaultMessage: 'Mozambique'
    },
    OM: {
        id: 'common.country.om',
        defaultMessage: 'Oman'
    },
    NC: {
        id: 'common.country.nc',
        defaultMessage: 'New Caledonia'
    },
    NZ: {
        id: 'common.country.nz',
        defaultMessage: 'New Zealand'
    },
    NE: {
        id: 'common.country.ne',
        defaultMessage: 'Niger'
    },
    NG: {
        id: 'common.country.ng',
        defaultMessage: 'Nigeria'
    },
    QA: {
        id: 'common.country.qa',
        defaultMessage: 'Qatar'
    },
    RE: {
        id: 'common.country.re',
        defaultMessage: 'Réunion'
    },
    PM: {
        id: 'common.country.pm',
        defaultMessage: 'Saint Pierre and Miquelon'
    },
    SA: {
        id: 'common.country.sa',
        defaultMessage: 'Saudi Arabia'
    },
    SN: {
        id: 'common.country.sn',
        defaultMessage: 'Senegal'
    },
    SC: {
        id: 'common.country.sc',
        defaultMessage: 'Seychelles'
    },
    SG: {
        id: 'common.country.sg',
        defaultMessage: 'Singapore'
    },
    VN: {
        id: 'common.country.vn',
        defaultMessage: 'Vietnam'
    },
    ZW: {
        id: 'common.country.zw',
        defaultMessage: 'Zimbabwe'
    },
    SD: {
        id: 'common.country.sd',
        defaultMessage: 'Sudan'
    },
    TH: {
        id: 'common.country.th',
        defaultMessage: 'Thailand'
    },
    TG: {
        id: 'common.country.tg',
        defaultMessage: 'Togo'
    },
    AE: {
        id: 'common.country.ae',
        defaultMessage: 'United Arab Emirates'
    },
    UG: {
        id: 'common.country.ug',
        defaultMessage: 'Uganda'
    },
    EG: {
        id: 'common.country.eg',
        defaultMessage: 'Egypt'
    },
    TZ: {
        id: 'common.country.tz',
        defaultMessage: 'Tanzania'
    },
    BF: {
        id: 'common.country.bf',
        defaultMessage: 'Burkina Faso'
    },
    ZM: {
        id: 'common.country.zm',
        defaultMessage: 'Zambia'
    },
    PF: {
        id: 'common.country.pf',
        defaultMessage: 'French Polynesia'
    },
    AO: {
        id: 'common.country.ao',
        defaultMessage: 'Angola'
    },
    AU: {
        id: 'common.country.au',
        defaultMessage: 'Australia'
    },
    BH: {
        id: 'common.country.bh',
        defaultMessage: 'Bahrain'
    },
    BN: {
        id: 'common.country.bn',
        defaultMessage: 'Brunei'
    },
    ZA: {
        id: 'common.country.za',
        defaultMessage: 'South Africa'
    },
    PH: {
        id: 'common.country.ph',
        defaultMessage: 'Philippines'
    },
    MR: {
        id: 'common.country.mr',
        defaultMessage: 'Mauritania'
    },
    SY: {
        id: 'common.country.sy',
        defaultMessage: 'Syria'
    },
    TN: {
        id: 'common.country.tn',
        defaultMessage: 'Tunisia'
    },
    GR: {
        id: 'common.country.gr',
        defaultMessage: 'Greece'
    },
    IS: {
        id: 'common.country.is',
        defaultMessage: 'Iceland'
    },
    ET: {
        id: 'common.country.et',
        defaultMessage: 'Ethiopia'
    },
    RU: {
        id: 'common.country.ru',
        defaultMessage: 'Russia'
    },
    BY: {
        id: 'common.country.by',
        defaultMessage: 'Belarus'
    },
    KZ: {
        id: 'common.country.kz',
        defaultMessage: 'Kazakhstan'
    },
    UY: {
        id: 'common.country.uy',
        defaultMessage: 'Uruguay'
    },
    BM: {
        id: 'common.country.bm',
        defaultMessage: 'Bermuda'
    },
    GT: {
        id: 'common.country.gt',
        defaultMessage: 'Guatemala'
    },
    BO: {
        id: 'common.country.bo',
        defaultMessage: 'Bolivia'
    },
    CL: {
        id: 'common.country.cl',
        defaultMessage: 'Chile'
    },
    PE: {
        id: 'common.country.pe',
        defaultMessage: 'Peru'
    },
    UA: {
        id: 'common.country.ua',
        defaultMessage: 'Ukraine'
    },
    JP: {
        id: 'common.country.jp',
        defaultMessage: 'Japan'
    },
    IN: {
        id: 'common.country.in',
        defaultMessage: 'India'
    },
    KR: {
        id: 'common.country.kr',
        defaultMessage: 'Korea'
    },
    CO: {
        id: 'common.country.co',
        defaultMessage: 'Colombia'
    },
    BR: {
        id: 'common.country.br',
        defaultMessage: 'Brazil'
    },
    TR: {
        id: 'common.country.tr',
        defaultMessage: 'Turkey',
    },
    MD: {
        id: 'common.country.md',
        defaultMessage: 'Moldova'
    },
    BG: {
        id: 'common.country.bg',
        defaultMessage: 'Bulgaria'
    },
    UZ: {
        id: 'common.country.uz',
        defaultMessage: 'Uzbekistan'
    },
    AM: {
        id: 'common.country.am',
        defaultMessage: 'Armenia'
    },
    AZ: {
        id: 'common.country.az',
        defaultMessage: 'Azerbaijan'
    },
    MN: {
        id: 'common.country.mn',
        defaultMessage: 'Mongolia'
    },
    EC: {
        id: 'common.country.ec',
        defaultMessage: 'Ecuador'
    },

    ALL: {
        id: 'common.country.all',
        defaultMessage: 'All Countries'
    },
    SELECT_COUNTRIES: {
        id: 'common.country.select.groups',
        defaultMessage: 'Select countries'
    },
    SELECT_COUNTRY: {
        id: 'common.country.select.group',
        defaultMessage: 'Select a country'
    }
});

export default messages;
