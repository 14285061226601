import {domains} from '../../constants/Utils';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const MAIN_MESSAGES_SELECT_GROUP = 'MAIN_MESSAGES_SELECT_GROUP';
export const MAIN_MESSAGES_FETCH_PENDING = 'MAIN_MESSAGES_FETCH_PENDING';
export const SPARE_PARTS_MAIN_MESSAGES_FETCH_PENDING = 'SPARE_PARTS_MAIN_MESSAGES_FETCH_PENDING';
export const TECH_DOC_MAIN_MESSAGES_FETCH_PENDING = 'TECH_DOC_MAIN_MESSAGES_FETCH_PENDING';
export const CLAIMS_MAIN_MESSAGES_FETCH_PENDING = 'CLAIMS_MAIN_MESSAGES_FETCH_PENDING';
export const MAIN_MESSAGES_FETCH_SUCCESS = 'MAIN_MESSAGES_FETCH_SUCCESS';
export const MAIN_MESSAGES_FETCH_ERROR = 'MAIN_MESSAGES_FETCH_ERROR';
export const MAIN_MESSAGE_FETCH_PENDING = 'MAIN_MESSAGE_FETCH_PENDING';
export const MAIN_MESSAGES_SAVE_FETCH_SUCCESS = 'MAIN_MESSAGES_SAVE_FETCH_SUCCESS';
export const MAIN_MESSAGE_SAVE_PENDING = 'MAIN_MESSAGE_SAVE_PENDING';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({type: MAIN_MESSAGES_SELECT_GROUP, payload: countryGroup});

export const fetchMainMessages = (domain, groups) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: SPARE_PARTS_MAIN_MESSAGES_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MAIN_MESSAGES_GET,
                payload: {groups}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: TECH_DOC_MAIN_MESSAGES_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_MAIN_MESSAGES_GET,
                payload: {groups}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: CLAIMS_MAIN_MESSAGES_FETCH_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_MAIN_MESSAGES_GET,
                payload: {groups}
            });
        }
    }
};

export const fetchMainMessage = (domain, group) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: MAIN_MESSAGE_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MAIN_MESSAGE_GET,
                payload: {group}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: MAIN_MESSAGE_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_MAIN_MESSAGE_GET,
                payload: {group}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: MAIN_MESSAGE_FETCH_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_MAIN_MESSAGE_GET,
                payload: {group}
            });
        }
    }
};

export const saveMainMessage = (domain, mainMessage) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: MAIN_MESSAGE_SAVE_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MAIN_MESSAGE_SET,
                payload: {mainMessage}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: MAIN_MESSAGE_SAVE_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_MAIN_MESSAGE_SET,
                payload: {mainMessage}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: MAIN_MESSAGE_SAVE_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_MAIN_MESSAGE_SET,
                payload: {mainMessage}
            });
        }
    }
}
