import React from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {handleFetchSearchClientIdsError} from '../../../../actions/admin/actionAdminClientIds';
import {injectIntl} from 'react-intl';
import internalFulltextSearchMessages from '../../../../intl/technicalDocumentations/internalFulltextSearchMessages';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';

import BorderRadius from '../../../common/styled/BorderRadius';
import {handleFetchUsersError} from '../../../../actions/actionRole';
import {
    FORM_FIELD_CATALOG_NUMBER, FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
    FORM_FIELD_N_REFERENCE_FOR_REPLACEMENT,
    FORM_FIELD_PART_MARKING,
    FORM_FIELD_PLATE_NUMBER,
    FORM_FIELD_VIN,
    FORM_LABEL_CATALOG_NUMBER, FORM_LABEL_MANAGEMENT_GROUP,
    FORM_LABEL_N_REFERENCE_CAUSING_PROBLEM,
    FORM_LABEL_N_REFERENCE_FOR_REPLACEMENT,
    FORM_LABEL_PART_MARKING,
    FORM_LABEL_PLATE_NUMBER
} from '../../../../constants/formConstants';

const VehicleDetailInternalSearchSection = () => {

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...internalFulltextSearchMessages.PART_DETAIL_SECTION}/>
            </h3>
            <BorderRadius size={5} className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_N_REFERENCE_CAUSING_PROBLEM]}
                                                  field={FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_MANAGEMENT_GROUP]}
                                                  field={FORM_FIELD_MANAGEMENT_GROUP}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_N_REFERENCE_FOR_REPLACEMENT]}
                                                  field={FORM_FIELD_N_REFERENCE_FOR_REPLACEMENT}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_CATALOG_NUMBER]}
                                                  field={FORM_FIELD_CATALOG_NUMBER}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_PLATE_NUMBER]}
                                                  field={FORM_FIELD_PLATE_NUMBER}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_PART_MARKING]}
                                                  field={FORM_FIELD_PART_MARKING}
                                                  isSmall
                        />
                    </div>
                </div>
            </BorderRadius>
        </>
    )
};

const mapStateToProps = (state) => ({
    roles: state.profile.userDetail.roles,
    lastEditors: state.techDocInternalTicketSearch.lastEditors,
});

const mapDispatchToProps = (dispatch, props) => ({
    handleFetchUsersError,
    handleFetchSearchClientIdsError

});


export default  connect(mapStateToProps, mapDispatchToProps)(injectIntl(VehicleDetailInternalSearchSection));
