import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Select from 'react-select';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'get-value';
import {cloneDeep, isEqual} from 'lodash';
import {domains} from '../../constants/Utils';
import {moduleRoles} from '../../utils/roles';
import {PrimaryButton} from '../common/Button';
import {fetchMainMessages} from '../../actions/admin/actionAdminMainMessage';
import {fetchClaimsStatistics} from '../../actions/claims/actionClaimsStatistics';
import {updateClaimsUserStatisticsPreference} from '../../actions/claims/actionClaimsPreferences';
import Loader from '../Loader';
import MainMessage from './MainMessage';
import StatisticsTable from './StatisticsTable';
import formTypeMessages from '../../intl/common/formTypeMessages';
import mainPageMessages from '../../intl/home/mainPageMessages';
import authManagementMessages from '../../intl/admin/authManagementMessages';
import countryGroupMessages from '../../intl/common/countryGroupMessages';
import buttonMessages from '../../intl/common/buttonMessages';

const Wrapper = styled.div`
  background-color: #f9f9f9;
  padding: 10px;
  margin: 10px;
`;

const DOMAIN = domains.CLAIMS;

class ClaimsDomainContent extends Component {
    constructor(props) {
        super(props);
        const {userDetail: {roles}} = props;

        let isEditor = false;
        let isDealer = false;

        if (moduleRoles.isEditorClaims(roles)) isEditor = true;
        if (!isEditor && moduleRoles.isDealerClaims(roles)) isDealer = true;

        this.state = {
            isEditor, isDealer,
            managedGroups: isEditor ? moduleRoles.getEditorGroups(DOMAIN, get(this.props.userDetail, `roles`, {})).map(({group}) => group) : []
        };
    }

    loadData() {
        const {fetchMainMessages, fetchClaimsStatistics, userDetail: {dealerNumber, group}, statisticPreferenceCountries = []} = this.props;
        const {managedGroups} = this.state;
        if (this.state.isEditor) {
            const filteredCountries = statisticPreferenceCountries.filter(country => managedGroups.includes(country));
            fetchMainMessages(DOMAIN, filteredCountries);
            fetchClaimsStatistics({
                countryGroups: filteredCountries,
            });
        } else if (this.state.isDealer && group && dealerNumber) {
            fetchMainMessages(DOMAIN, [group]);
            fetchClaimsStatistics({dealerNumber});
        }
    }

    componentDidUpdate(prevProps) {
        const {statisticPreferenceCountries = []} = this.props;
        const {statisticPreferenceCountries: prevStatisticPreferenceCountries = []} = prevProps;
        const {managedGroups = []} = this.state;

        const selectedOptions = statisticPreferenceCountries.filter(country => managedGroups.includes(country));
        const prevSelectedOptions = prevStatisticPreferenceCountries.filter(country => managedGroups.includes(country));
        if (!isEqual(selectedOptions, prevSelectedOptions)) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    handleCountrySelectionChange = newOptions => {
        this.props.updateClaimsUserStatisticsPreference(cloneDeep(newOptions.map(e => e.value) || []));
    };

    handleAllCountriesClick = () => {
        this.props.updateClaimsUserStatisticsPreference(cloneDeep(this.state.managedGroups || []));
    };

    render() {
        const {isLoadingMessages, isLoadingStatistics, statisticData, intl: {formatMessage}, statisticPreferenceCountries = []} = this.props;
        const {isEditor, isDealer, managedGroups} = this.state;

        const selectedOptions = statisticPreferenceCountries.filter(country => managedGroups.includes(country));
        const options = (managedGroups || {}).map(e => ({
            value: e,
            label: countryGroupMessages[e] ? formatMessage(countryGroupMessages[e]) : e
        }));
        const formattedSelectedOptions = selectedOptions.map(e => ({
            value: e,
            label: countryGroupMessages[e] ? formatMessage(countryGroupMessages[e]) : e
        })).sort((option1, option2) => option1.value.localeCompare(option2.value));

        return <>
            <Wrapper>
                <div className="row mb-sm-3">
                    {(isDealer || isEditor) &&
                    <div className="col-sm-6">
                        <h4><FormattedMessage {...formTypeMessages[DOMAIN]} /></h4>
                    </div>
                    }
                </div>
                {isEditor &&
                <>
                    <div className="row">
                        <div className="col">
                            <h5>
                                <FormattedMessage {...mainPageMessages.SELECT_COUNTRIES} />
                            </h5>
                        </div>
                    </div>
                    <div className="row mb-sm-3">
                        <div className="col-10 pr-0">
                            <Select isMulti isSearchable
                                    value={formattedSelectedOptions}
                                    options={options}
                                    placeholder={formatMessage(authManagementMessages.ADMIN_PLACEHOLDER_SELECT_COUNTRIES)}
                                    onChange={this.handleCountrySelectionChange}/>
                        </div>
                        <div className="col-2 d-flex align-items-center">
                            <PrimaryButton type="button" className="btn btn-sm"
                                           onClick={this.handleAllCountriesClick}>
                                <FormattedMessage {...buttonMessages.ALL}/>
                            </PrimaryButton>
                        </div>
                    </div>
                </>
                }
                {selectedOptions &&
                <>
                    <div className="row mb-sm-3">
                        {(isDealer || isEditor) &&
                        <div className="col-sm-12">
                            <h5>
                                <FormattedMessage {...mainPageMessages.MAIN_MESSAGES} />
                            </h5>
                            {isLoadingMessages && <Loader/>}
                            {!isLoadingMessages &&
                            <MainMessage domain={DOMAIN}/>}
                        </div>
                        }
                    </div>
                    <div className="row mb-sm-5">
                        {(isDealer || isEditor) &&
                        <div className="col-sm-12">
                            <h5>
                                <FormattedMessage {...mainPageMessages.STATISTICS} />
                            </h5>
                            {isLoadingStatistics && <Loader/>}
                            {!isLoadingStatistics &&
                            <StatisticsTable domain={DOMAIN} data={statisticData} group={selectedOptions}/>}
                        </div>
                        }
                    </div>
                </>
                }
            </Wrapper>
        </>;
    }
}

ClaimsDomainContent.propTypes = {
    intl: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    userDetail: state.profile.userDetail,
    isLoadingMessages: get(state, 'adminMainMessage.isLoadingClaims'),
    isLoadingStatistics: get(state, `claimsStatistics.isLoading`),
    statisticData: get(state, `claimsStatistics.data`),
    statisticPreferenceCountries: get(state, 'claimsPreferences.menuStatistics'),
});

export default connect(mapStateToProps, {
    fetchMainMessages,
    fetchClaimsStatistics,
    updateClaimsUserStatisticsPreference,
})(injectIntl(ClaimsDomainContent));
