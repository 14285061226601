import v4 from 'uuid/v4';
import {CLAIMS_GET_STATISTICS} from '../../constants/websocketCommands';

export const CLAIMS_STATISTICS_PENDING = 'CLAIMS_STATISTICS_PENDING';

export const fetchClaimsStatistics = ({dealerNumber, countryGroups}) => dispatch => {
    return dispatch({
        type: CLAIMS_STATISTICS_PENDING,
        claimsWebsocket: true,
        command: CLAIMS_GET_STATISTICS,
        payload: {
            dealerNumber,
            countryGroups,
            correlationId: v4()
        }
    });
};
