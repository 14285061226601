import React, {Component} from 'react';
import {FieldArray} from 'redux-form';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import {
    FORM_FIELD_DIC_NUMBER, FORM_FIELD_PART_NO,
    FORM_FIELDS_ORDER_ITEMS,
    FORM_LABEL_DIC_NUMBER,
    FORM_ORDERS_SECTION, FORM_LABEL_SP_TICKET_NUMBER_REFERENCE, FORM_FIELD_SP_TICKET_NUMBER_REFERENCE, FORM_BUTTON_LOAD, FORM_LABEL_MPR88, FORM_FIELD_MPR88, FORM_FIELD_DESCRIPTION, FORM_FIELD_SUBJECT_TO_VOR, FORM_FIELD_USER_STATUS
} from '../../../../../../constants/formConstants';
import {
    SP_TICKET_NUMBER_REFERENCE_MAX_LENGTH,
} from '../../../../../../constants/validationConstants';
import {
    RequiredSpan,
    StyledDiv
} from '../../../../../common/StyledComponents';
import styled from 'styled-components';
import OrdersTroubleshootLowerTableHeader from './OrdersTroubleshootLowerTableHeader';
import OrdersTroubleshootLowerTableBody from './OrdersTroubleshootLowerTableBody';
import {
    fetchDicClaimsInfoActionCreator,
    setDicClaimsInfoActionCreator
} from '../../../../../../actions/claims/actionClaimsDicData';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {change, formValueSelector} from 'redux-form';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../intl/claims/formMessages';
import {FormattedMessage} from 'react-intl';
import {warnAlert} from '../../../../../../actions/alertsActions';
import OrdersTroubleshootUpperTableBody from './OrdersTroubleshootUpperTableBody';
import OrdersTroubleshootUpperTableHeader from './OrdersTroubleshootUpperTableHeader';
import InputFieldGroupWithValidation from '../../../../../common/InputFieldGroupWithValidation';
import { getDicNumber } from '../../../../../../api/claims/restClaimsDicNumber';
import Loader from '../../../../../Loader';
import warnMessages from '../../../../../../intl/common/warnMessages';
import { RECEIVER } from '../../../../../../constants/Utils';
import {normalizeTicketNumber} from '../../../../../common/normalizationCommons';
import {validPartNoLength, validTicketNumber} from '../../../../../common/validationCommons';

export const Div = styled.div`
    &&{background-color: #e9ecef;};
`;

class OrderItemsTroubleshootSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDicLoading: false
        }
        this.customRefs = {
            [FORM_FIELD_DIC_NUMBER]: React.createRef(),
            [FORM_BUTTON_LOAD]: React.createRef()
        };
    }

    handleOrderPartLoad = () => {
        const {order, fetchDicClaimsInfoActionCreator} = this.props;

        fetchDicClaimsInfoActionCreator(order[FORM_FIELD_PART_NO])
    };

    fillDicClaimsInfo = () => {
        const {dicInfo, order, change, setDicClaimsInfoActionCreator, warnAlert, userRights} = this.props;
        setDicClaimsInfoActionCreator();
        if (order) {
            if(userRights.canEditOrder) {
                if (!order[FORM_FIELD_DESCRIPTION]) {
                    change(`${FORM_ORDERS_SECTION}.${FORM_FIELD_DESCRIPTION}`, dicInfo.partDescription.trim());
                    change(`${FORM_ORDERS_SECTION}.${FORM_FIELD_SUBJECT_TO_VOR}`, dicInfo.vorStatus ? 'YES' : 'NO');
                } else {
                    warnAlert(warnMessages.SP_DIC_WARN002, null, {partNumber: dicInfo.partNo})
                }
            }
        } else {
            warnAlert(warnMessages.SP_DIC_WARN003, null, {partNumber: dicInfo.partNo});
        }
    };

    handleLoadDicNumber = async () => {
        const { order, change } = this.props

        let dicNumber
        try {
            this.setState({ isDicLoading: true })
            const { data } = await getDicNumber(order.spTicketNumberReference)
            dicNumber = data.dicNumber
        } catch (error) {
            dicNumber = ""
        }
        this.setState({ isDicLoading: false })

        change(`${FORM_ORDERS_SECTION}.${FORM_FIELD_DIC_NUMBER}`, dicNumber);
    }

    renderOrders = ({fields}) => {
        const {userRights, readOnly, form, reqFields} = this.props;
        return (
            <>
                <div className="table-responsive">
                    <table className="table table-hover mb-0">
                        <OrdersTroubleshootUpperTableHeader userRights={userRights} readOnly={readOnly}
                                                 reqFields={reqFields} form={form} />
                        <tbody>
                            <OrdersTroubleshootUpperTableBody form={form}
                                                            order={fields}
                                                            userRights={userRights} readOnly={readOnly}
                                                            reqFields={reqFields}
                                                            handleDicTransfer={() => this.handleDicTransfer()} />
                        </tbody>
                    </table>
                    <table className="table table-hover mb-0">
                        <OrdersTroubleshootLowerTableHeader userRights={userRights}
                                                            readOnly={readOnly}
                                                            reqFields={reqFields}
                                                            form={form} />
                        <tbody>
                            <OrdersTroubleshootLowerTableBody form={form}
                                                              order={fields}
                                                              userRights={userRights} readOnly={readOnly}
                                                              reqFields={reqFields}
                                                              handleDicTransfer={() => this.handleDicTransfer()}
                                                              handleOrderPartLoad={this.handleOrderPartLoad} />
                        </tbody>
                    </table>
                </div>
            </>
        )
    };

    render() {
        const {userRights, readOnly, reqFields, dicInfo, userStatus} = this.props;

        if (dicInfo.dicPartInfoLoaded) {
            this.fillDicClaimsInfo();
        }

        return (
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.ORDERS_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    <div className="d-flex p-1 border border-bottom-secondary pl-2">
                        <label className="col-form-label-sm pl-0 font-weight-bold pr-2 mb-0">
                            <ValidFormattedMessage message={FORM_LABEL_SP_TICKET_NUMBER_REFERENCE} intlMessages={formMessages}/>
                            {!(!userRights.canEditSPReferenceNumber || readOnly) && userStatus === RECEIVER && <RequiredSpan>{'\u00A0*'}</RequiredSpan>}
                        </label>
                        <InputFieldWithValidation
                            field={FORM_FIELD_SP_TICKET_NUMBER_REFERENCE}
                            maxLength={SP_TICKET_NUMBER_REFERENCE_MAX_LENGTH}
                            isDisabled={!userRights.canEditSPReferenceNumber || readOnly}
                            normalize={normalizeTicketNumber}
                            validations={[validTicketNumber]}
                            placeholder="00-000000"
                            isSmall
                            isRequired={userRights.canEditSPReferenceNumber && reqFields.includes(FORM_FIELD_SP_TICKET_NUMBER_REFERENCE) && userStatus === RECEIVER}
                            parse={(e) => e || ''}
                        />
                        {userRights.canViewMpr &&
                            <>
                                <div className="pl-2 d-flex">
                                    <label className="col-form-label-sm pl-0 font-weight-bold pr-2 mb-0">
                                        <ValidFormattedMessage message={FORM_LABEL_DIC_NUMBER} intlMessages={formMessages}/>
                                    </label>
                                    <InputFieldGroupWithValidation field={FORM_FIELD_DIC_NUMBER}
                                                            loadOrderData={this.handleLoadDicNumber}
                                                            isDisabled={!userRights.canEditSPReferenceNumber || readOnly}
                                                            setRef={this.customRefs[FORM_LABEL_DIC_NUMBER]}
                                                            setRefButton={this.customRefs[FORM_BUTTON_LOAD]}
                                                            reverse
                                                            isUpperCase
                                    />
                                </div>
                                <div className="pl-2 py-auto">
                                    {this.state.isDicLoading &&
                                        <Loader isSmall/>
                                    }
                                </div>
                            </>
                        }
                    </div>

                    <div className="d-flex justify-content-md-center">
                        <FieldArray name={FORM_FIELDS_ORDER_ITEMS} component={this.renderOrders}/>
                    </div>

                    {userRights.canViewMpr &&
                        <div className='d-flex p-2'>
                            <label className="col-form-label-sm pl-0 font-weight-bold pr-2 mb-0">
                                <ValidFormattedMessage message={FORM_LABEL_MPR88} intlMessages={formMessages}/>
                            </label>
                            <InputFieldWithValidation isDisabled={!userRights.canEditOrder || readOnly} isSmall field={FORM_FIELD_MPR88} />
                        </div>
                    }
                </StyledDiv>
            </>
        )
    }
}

const mapStateToProps = (state, props) => ({
    order: formValueSelector(props.form)(state, FORM_ORDERS_SECTION),
    userStatus: formValueSelector(props.form)(state, `${FORM_ORDERS_SECTION}.${FORM_FIELD_USER_STATUS}`),
    dicInfo: state.claimsDic,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            setDicClaimsInfoActionCreator,
            fetchDicClaimsInfoActionCreator,
            warnAlert
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderItemsTroubleshootSection);
