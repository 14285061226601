import {put, takeEvery} from 'redux-saga/effects';
import {loadFile} from '../api/restFile';
import {errorAlert} from '../actions/alertsActions';
import {extractErrorText} from '../actions/actionUtils';
import FileSaver from 'file-saver';
import alertMessages from '../intl/common/alertMessages';
import { adminWebsocketCommands } from '../constants/adminWebsocketCommands';

function* handleDownloadFile(action) {
    try {
        const attachment = action.payload.attachment
        const file = yield loadFile(attachment.url);
        FileSaver.saveAs(file.data, attachment.name);
    } catch (e) {
        yield put(errorAlert(...extractErrorText(e, alertMessages.FILE_DOWNLOAD_ERROR)));
    }
}

function* adminFileSaga() {
    yield takeEvery(adminWebsocketCommands.ADMIN_ATTACHMENT_DOWNLOAD, handleDownloadFile);
}

export default adminFileSaga;
