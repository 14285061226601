import {adminWebsocketCommands} from '../../constants/adminWebsocketCommands';

export const adminClientIds = (state = {
    clientIds: [],
    correlationId: null,
    isLoading: false,
}, action) => {
    switch (action.type) {
        case adminWebsocketCommands.ADMIN_CLIENT_ID_FETCH_COMMAND: {
            return Object.assign({}, state, {isLoading: true, clientIds: []});
        }
        case adminWebsocketCommands.ADMIN_CLIENT_ID_FETCH_SUCCESS: {
            const {clientIds, correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, clientIds: clientIds ? clientIds : [], correlationId});
        }
        case adminWebsocketCommands.ADMIN_CLIENT_ID_FETCH_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, clientIds: [], correlationId});
        }
        default:
            return state;
    }
};
