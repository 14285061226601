import {CLAIMS_SET_LOCK, CLAIMS_SET_UNLOCK,} from '../../constants/websocketCommands';

export const CLAIMS_RESET_REDIRECT = 'CLAIMS_RESET_REDIRECT';
export const CLAIMS_SET_LOCK_TICKET = 'CLAIMS_SET_LOCK_TICKET';

export const lockClaimsTicket = uuid => dispatch =>
    dispatch({
        type: CLAIMS_SET_LOCK_TICKET,
        claimsWebsocket: true,
        command: CLAIMS_SET_LOCK,
        payload: {uuid}
    });

export const unlockClaimsTicket = uuid => dispatch =>
    dispatch({
        type: CLAIMS_SET_UNLOCK,
        claimsWebsocket: true,
        command: CLAIMS_SET_UNLOCK,
        payload: {uuid}
    });

export const resetRedirectAfterClaimsLockTicket = () => dispatch => dispatch({type: CLAIMS_RESET_REDIRECT});
