import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TABLE_HEADER_STATUS: {
        id: 'mainPage.statistics.table.header.status',
        defaultMessage: 'Status'
    },
    TABLE_HEADER_TOTAL: {
        id: 'mainPage.statistics.table.header.total',
        defaultMessage: 'Sum'
    },
    TABLE_ROW_SPARE_PARTS_TRANSFERRED: {
        id: 'mainPage.statistics.table.row.spareParts.transferred',
        defaultMessage: 'Transferred Spare Part'
    },
    TABLE_ROW_SPARE_PARTS_NEW: {
        id: 'mainPage.statistics.table.row.spareParts.new',
        defaultMessage: 'Order (new)'
    },
    TABLE_ROW_SPARE_PARTS_WAIT_DEALER: {
        id: 'mainPage.statistics.table.row.spareParts.waitDealer',
        defaultMessage: 'Waiting Order (pending dealer)'
    },
    TABLE_ROW_SPARE_PARTS_READ_BY_DEALER: {
        id: 'mainPage.statistics.table.row.spareParts.readDealer',
        defaultMessage: 'In progress Order (read by dealer)'
    },
    TABLE_ROW_SPARE_PARTS_READ_BY_SUBSIDIARY: {
        id: 'mainPage.statistics.table.row.spareParts.readSubsidiary',
        defaultMessage: 'In progress Order (read by subsidiary)'
    },
    TABLE_ROW_SPARE_PARTS_WAIT_SUBSIDIARY: {
        id: 'mainPage.statistics.table.row.spareParts.waitSubsidiary',
        defaultMessage: 'Waiting Order (pending subsidiary)'
    },
    TABLE_ROW_SPARE_PARTS_REOPEN: {
        id: 'mainPage.statistics.table.row.spareParts.reopened',
        defaultMessage: 'Reopened Order'
    },
    TABLE_ROW_SPARE_PARTS_CLOSED: {
        id: 'mainPage.statistics.table.row.spareParts.closed',
        defaultMessage: 'Closed Order'
    },
    TABLE_ROW_TECHNICAL_DOCUMENTATION_NEW: {
        id: 'mainPage.statistics.table.row.technicalDocumentation.new',
        defaultMessage: 'Technical Document (Created)'
    },
    TABLE_ROW_TECHNICAL_DOCUMENTATION_WAIT_DEALER: {
        id: 'mainPage.statistics.table.row.technicalDocumentation.waitDealer',
        defaultMessage: 'Waiting Technical Document (pending dealer)'
    },
    TABLE_ROW_TECHNICAL_DOCUMENTATION_READ_BY_DEALER: {
        id: 'mainPage.statistics.table.row.technicalDocumentation.readDealer',
        defaultMessage: 'In progress Technical Document (read by dealer)'
    },
    TABLE_ROW_TECHNICAL_DOCUMENTATION_READ_BY_SUBSIDIARY: {
        id: 'mainPage.statistics.table.row.technicalDocumentation.readSubsidiary',
        defaultMessage: 'In progress Technical Document (read by subsidiary)'
    },
    TABLE_ROW_TECHNICAL_DOCUMENTATION_WAIT_SUBSIDIARY: {
        id: 'mainPage.statistics.table.row.technicalDocumentation.waitSubsidiary',
        defaultMessage: 'Waiting Technical Document (pending subsidiary)'
    },
    TABLE_ROW_TECHNICAL_DOCUMENTATION_REOPEN: {
        id: 'mainPage.statistics.table.row.technicalDocumentation.reopened',
        defaultMessage: 'Reopened Technical Document'
    },
    TABLE_ROW_TECHNICAL_DOCUMENTATION_CLOSED: {
        id: 'mainPage.statistics.table.row.technicalDocumentation.closed',
        defaultMessage: 'Closed Technical Document'
    },
    TABLE_ROW_TECHNICAL_DOCUMENTATION_TRANSFERRED: {
        id: 'mainPage.statistics.table.row.technicalDocumentation.transferred',
        defaultMessage: 'Transferred Technical Document'
    },
    TABLE_ROW_CLAIMS_NEW: {
        id: 'mainPage.statistics.table.row.claims.new',
        defaultMessage: 'Claims (new)'
    },
    TABLE_ROW_CLAIMS_WAIT_DEALER: {
        id: 'mainPage.statistics.table.row.claims.waitDealer',
        defaultMessage: 'Waiting Claims (pending dealer)'
    },
    TABLE_ROW_CLAIMS_READ_BY_DEALER: {
        id: 'mainPage.statistics.table.row.claims.readDealer',
        defaultMessage: 'In progress Claims (read by dealer)'
    },
    TABLE_ROW_CLAIMS_READ_BY_SUBSIDIARY: {
        id: 'mainPage.statistics.table.row.claims.readSubsidiary',
        defaultMessage: 'In progress Claims (read by subsidiary)'
    },
    TABLE_ROW_CLAIMS_WAIT_SUBSIDIARY: {
        id: 'mainPage.statistics.table.row.claims.waitSubsidiary',
        defaultMessage: 'Waiting Claims (pending subsidiary)'
    },
    TABLE_ROW_CLAIMS_REOPEN: {
        id: 'mainPage.statistics.table.row.claims.reopened',
        defaultMessage: 'Reopened Claims'
    },
    TABLE_ROW_CLAIMS_CLOSED: {
        id: 'mainPage.statistics.table.row.claims.closed',
        defaultMessage: 'Closed Claims'
    },
    TABLE_FOOTER_TOTAL: {
        id: 'mainPage.statistics.table.footer.total',
        defaultMessage: 'Total'
    },
});

export default messages;
