import {
    DOCUMENTATION_TICKET_STATUS_NEW,
    DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_DEALER,
    DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    DOCUMENTATION_TICKET_STATUS_READ_BY_SUBSIDIARY,
    DOCUMENTATION_TICKET_STATUS_REOPENED, DOCUMENTATION_TICKET_STATUS_TRANSFERRED
} from './formConstants';

export const editRights = {
    DEALER: {
        NEW: {
            canAddNotes: false,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditAuthReply: false,
            canEditVehDetails: true,
            canViewAuthReply: false,
            canAddAttach: true,
            canEditProblemDesc: true,
            canEditProblemDescText: true,
            canEditManagementGroup: false,
            canEditImmobilizedVehicle: true,
            canEditSuppInformations: true,
            canEditSuppInformationsText: false,
            canEditHeader: true,
            canChangeToReadBySubs: false,
            canClose: true,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canEditGarFields: false,
            canViewGarFields: false,
            canEditClientId: true,
            canEditInternalStatus: false,
            canForwardBackToSubs: false,
            canCreateNew: true,
            canEditPartMarking: true,
            canShowAutomaticResponse: false,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
            canViewRelatedDealersTickets: false,
        },
        CLOSED: {
            canAddNotes: false,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditAuthReply: false,
            canEditVehDetails: false,
            canViewAuthReply: true,
            canAddAttach: false,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditManagementGroup: false,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: true,
            canSave: false,
            canEditGarFields: false,
            canViewGarFields: true,
            canEditClientId: false,
            canEditInternalStatus: false,
            canForwardBackToSubs: false,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: false,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
            canViewRelatedDealersTickets: false,
        },
        READ_BY_SUBSIDIARY: {
            canAddNotes: false,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditAuthReply: false,
            canEditVehDetails: false,
            canViewAuthReply: false,
            canAddAttach: false,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditManagementGroup: false,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canEditGarFields: false,
            canViewGarFields: true,
            canEditClientId: false,
            canEditInternalStatus: false,
            canForwardBackToSubs: false,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: false,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
            canViewRelatedDealersTickets: false,
        },
        WAIT_SUBSIDIARY: {
            canAddNotes: false,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditAuthReply: false,
            canEditVehDetails: false,
            canViewAuthReply: true,
            canAddAttach: true,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditManagementGroup: false,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: true,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canEditGarFields: false,
            canViewGarFields: true,
            canEditClientId: false,
            canEditInternalStatus: false,
            canForwardBackToSubs: false,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: false,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
            canViewRelatedDealersTickets: false,
        },
        WAIT_DEALER: {
            canAddNotes: true,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditAuthReply: false,
            canEditVehDetails: false,
            canViewAuthReply: true,
            canAddAttach: true,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditManagementGroup: false,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: true,
            canForwardToDealer: false,
            canForwardToSubs: true,
            canReopen: false,
            canSave: true,
            canEditGarFields: false,
            canViewGarFields: true,
            canEditClientId: false,
            canEditInternalStatus: false,
            canForwardBackToSubs: false,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: false,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
            canViewRelatedDealersTickets: false,
        },
        REOPEN: {
            canAddNotes: true,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditAuthReply: false,
            canEditVehDetails: false,
            canViewAuthReply: true,
            canAddAttach: true,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditManagementGroup: false,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: true,
            canForwardToDealer: false,
            canForwardToSubs: true,
            canReopen: false,
            canSave: true,
            canEditGarFields: false,
            canViewGarFields: true,
            canEditClientId: false,
            canEditInternalStatus: false,
            canForwardBackToSubs: false,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: false,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
            canViewRelatedDealersTickets: false,
        },
        TRANSFERRED: {
            canAddNotes: false,
            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canEditAuthReply: false,
            canEditVehDetails: false,
            canViewAuthReply: false,
            canAddAttach: false,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditManagementGroup: false,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canEditGarFields: false,
            canViewGarFields: true,
            canEditClientId: false,
            canEditInternalStatus: false,
            canForwardBackToSubs: false,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: false,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: false,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: false,
            canViewRelatedDealersTickets: false,
        }
    },
    EDITOR: {
        NEW: {
            canAddNotes: false,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditAuthReply: true,
            canEditVehDetails: false,
            canViewAuthReply: true,
            canAddAttach: false,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditManagementGroup: true,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: true,
            canClose: false,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canEditGarFields: false,
            canViewGarFields: false,
            canEditClientId: true,
            canEditInternalStatus: false,
            canForwardBackToSubs: false,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: true,
            canViewInternalTicketReferences: true,
            canViewRelatedDealersTickets: true,
        },
        CLOSED: {
            canAddNotes: false,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditAuthReply: false,
            canEditVehDetails: false,
            canViewAuthReply: true,
            canAddAttach: false,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditManagementGroup: false,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: true,
            canSave: false,
            canEditGarFields: false,
            canViewGarFields: true,
            canEditClientId: false,
            canEditInternalStatus: false,
            canForwardBackToSubs: false,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: false,
            canViewInternalTicketReferences: true,
            canViewRelatedDealersTickets: true,
        },
        READ_BY_SUBSIDIARY: {
            canAddNotes: true,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditAuthReply: true,
            canEditVehDetails: true,
            canViewAuthReply: true,
            canAddAttach: true,
            canEditProblemDesc: true,
            canEditProblemDescText: false,
            canEditManagementGroup: true,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: true,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: true,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canEditGarFields: true,
            canViewGarFields: true,
            canEditClientId: true,
            canEditInternalStatus: true,
            canForwardBackToSubs: false,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: true,
            canEscalate: true,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: true,
            canViewInternalTicketReferences: true,
            canViewRelatedDealersTickets: true,
        },
        WAIT_SUBSIDIARY: {
            canAddNotes: false,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditAuthReply: true,
            canEditVehDetails: false,
            canViewAuthReply: true,
            canAddAttach: true,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditManagementGroup: false,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: true,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canEditGarFields: true,
            canViewGarFields: true,
            canEditClientId: false,
            canEditInternalStatus: true,
            canForwardBackToSubs: false,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: true,
            canViewInternalTicketReferences: true,
            canViewRelatedDealersTickets: true,
        },
        WAIT_DEALER: {
            canAddNotes: false,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditAuthReply: true,
            canEditVehDetails: false,
            canViewAuthReply: true,
            canAddAttach: true,
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            canEditManagementGroup: false,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canEditGarFields: true,
            canViewGarFields: true,
            canEditClientId: false,
            canEditInternalStatus: true,
            canForwardBackToSubs: true,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: false,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: true,
            canViewInternalTicketReferences: true,
            canViewRelatedDealersTickets: true,
        },
        REOPEN: {
            canAddNotes: true,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditAuthReply: true,
            canEditVehDetails: true,
            canViewAuthReply: true,
            canAddAttach: true,
            canEditProblemDesc: true,
            canEditProblemDescText: false,
            canEditManagementGroup: true,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: true,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canEditGarFields: true,
            canViewGarFields: true,
            canEditClientId: false,
            canEditInternalStatus: true,
            canForwardBackToSubs: false,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: false,
            canEscalate: true,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: true,
            canViewInternalTicketReferences: true,
            canViewRelatedDealersTickets: true,
        },
        TRANSFERRED: {
            canAddNotes: true,
            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canEditAuthReply: true,
            canEditVehDetails: true,
            canViewAuthReply: true,
            canAddAttach: true,
            canEditProblemDesc: true,
            canEditProblemDescText: false,
            canEditManagementGroup: true,
            canEditImmobilizedVehicle: false,
            canEditSuppInformations: true,
            canEditSuppInformationsText: false,
            canEditHeader: false,
            canChangeToReadBySubs: false,
            canClose: true,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canEditGarFields: true,
            canViewGarFields: true,
            canEditClientId: false,
            canEditInternalStatus: true,
            canForwardBackToSubs: false,
            canCreateNew: false,
            canEditPartMarking: true,
            canShowAutomaticResponse: true,
            canEditBvm: false,
            canViewEditorReply: true,
            canAnswer: true,
            canEscalate: true,
            canViewDocumentationUpdateSection: true,
            canEditDocumentationUpdate: true,
            canViewInternalTicketReferences: true,
            canViewRelatedDealersTickets: true,
        }
    }
};

export const createRights = {
    canAddNotes: false,
    canAddEditorsNotes: false,
    canReadEditorsNotes: false,
    canEditAuthReply: false,
    canEditVehDetails: true,
    canViewAuthReply: false,
    canAddAttach: true,
    canEditProblemDesc: true,
    canEditProblemDescText: true,
    canEditManagementGroup: false,
    canEditImmobilizedVehicle: true,
    canEditSuppInformations: true,
    canEditSuppInformationsText: true,
    canEditHeader: true,
    canChangeToReadBySubs: false,
    canClose: false,
    canForwardToDealer: false,
    canForwardToSubs: false,
    canReopen: false,
    canSave: true,                           //changed
    canEditGarFields: false,
    canViewGarFields: false,
    canEditClientId: true,
    canEditInternalStatus: false,
    canForwardBackToSubs: false,
    canCreateNew: false,
    canEditPartMarking: false,
    canShowAutomaticResponse: false,
    canEditBvm: true,
    canViewEditorReply: false,
    canViewRelatedDealersTickets: false,
};


export const editTechDocListButtonVisibility = {
    DEALER: [
        DOCUMENTATION_TICKET_STATUS_NEW,
        DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_DEALER,
        DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
        DOCUMENTATION_TICKET_STATUS_REOPENED
    ],
    EDITOR: [
        DOCUMENTATION_TICKET_STATUS_NEW,
        DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_DEALER,
        DOCUMENTATION_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
        DOCUMENTATION_TICKET_STATUS_READ_BY_SUBSIDIARY,
        DOCUMENTATION_TICKET_STATUS_TRANSFERRED,
        DOCUMENTATION_TICKET_STATUS_REOPENED
    ]
};
