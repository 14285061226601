import {
    ANSWERING_SCHEMA_FORM,
    FORM_FIELD_AS_SG_NAME,
    FORM_FIELD_AS_FORMTYPE,
    FORM_FIELD_AS_ANSWERING_GROUPS,
} from './formConstants';

export const editReqFields = {
    'DEFAULT': {
        'EDITOR': {
            [ANSWERING_SCHEMA_FORM]: [
                FORM_FIELD_AS_SG_NAME,
                FORM_FIELD_AS_FORMTYPE,
                FORM_FIELD_AS_ANSWERING_GROUPS,
            ],
        }
    },
};

export const createReqFields = {
    'DEFAULT': {
        [ANSWERING_SCHEMA_FORM]: [
            FORM_FIELD_AS_SG_NAME,
            FORM_FIELD_AS_FORMTYPE,
            FORM_FIELD_AS_ANSWERING_GROUPS,
        ],
    }
};

