import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import Loader from '../../../Loader';
import {ESCALATION_SCHEMA_FORM, FORM_BUTTON_EDIT, } from '../../../../constants/formConstants';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {
    fetchEscalationSchema,
    resetEscalationSchema,
    updateEscalationSchema
} from '../../../../actions/admin/actionEscalationSchema';
import {initLoadedEscalationSchema} from '../../../../utils/escalationSchemaUtils';
import EscalationSchemaForm from './forms/EscalationSchemaForm';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import {getAdminEscalationSchemaPath} from '../../../../routes/pathResolver';

class ViewEscalationSchemaPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToNew: false,
            redirectToEdit: false,
            escalationSchemaSubmitted: false,
            hasToBeUpdated: false,
            storedEscalationSchemaData: {},
            newInitData: {},
        };
    }

    componentDidMount() {
        this.props.clearAlerts();
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchEscalationSchema(this.props.domain, this.props.match.params.escalationSchemaUuid, this.props.userDetail.ipn);
        }
    }

    componentWillUnmount() {
        this.props.resetEscalationSchema(this.props.domain);
    }

    handleSubmit = (values) => {
        const { submitBy } = values;

        switch (submitBy) {
            case FORM_BUTTON_EDIT: {
                this.setState({
                    escalationSchemaSubmitted: true,
                    redirectToEdit: true,
                });
                break;
            }
            default: {
            }
        }
    };

    render() {
        const {
            escalationSchemaData,
            domain,
            intl: {formatMessage},
        } = this.props;

        let formType = false;

        if (get(escalationSchemaData, 'escalationSchema.name', { default: false })) {
            formType = ESCALATION_SCHEMA_FORM;
        }

        if (this.state.escalationSchemaSubmitted && this.state.redirectToEdit) {
            return <Redirect to={{
                pathname: `${getAdminEscalationSchemaPath(domain)}/edit/${escalationSchemaData.escalationSchema.formType + "-" + escalationSchemaData.escalationSchema.solutionGroupId}`,
            }}/>;
        } else if (this.state.escalationSchemaSubmitted) {
            this.props.updateEscalationSchema(domain, this.state.storedEscalationSchemaData);
            this.setState({ escalationSchemaSubmitted: false, storedEscalationSchemaData: {}, hasToBeUpdated: false });
        }

        const initializationValue = initLoadedEscalationSchema(domain, escalationSchemaData, formatMessage);

        if (
            escalationSchemaData.isEscalationSchemaLoading
            || escalationSchemaData.isEscalationSchemaCreating
            || !formType
        ) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.escalationSchema.title"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - Escalation schema"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <div className="container-fluid">
                    <div className="col-12">
                        <EscalationSchemaForm
                            form={formType}
                            initialValues={initializationValue}
                            enableReinitialize={true}
                            reqFields={[]}
                            domain={domain}
                            readOnly
                            handleSubmit={this.handleSubmit}
                            type={'view'}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    escalationSchemaData: state.adminEscalationSchema,
    userDetail: state.profile.userDetail,
});

ViewEscalationSchemaPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    escalationSchemaData: PropTypes.object.isRequired,
    fetchEscalationSchema: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    updateEscalationSchema: PropTypes.func.isRequired,
    resetEscalationSchema: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    userDetail: PropTypes.object.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchEscalationSchema,
    clearAlerts,
    resetEscalationSchema,
    updateEscalationSchema,
})(injectIntl(ViewEscalationSchemaPage))), ['TD_POWER_USER','SP_POWER_USER']);

