import React, {Component} from 'react';
import {
    BUTTON_LABEL_LOAD, FORM_BUTTON_ESCALATE,
    FORM_BUTTON_LOAD,
    FORM_CHECKBOX_DIC,
    FORM_FIELD_DESCRIPTION, FORM_FIELD_DIC_NUMBER,
    FORM_FIELD_LIC_IAN,
    FORM_FIELD_LIC_LIG,
    FORM_FIELD_LIC_OAN,
    FORM_FIELD_LIC_ORD,
    FORM_FIELD_LIC_PROD,
    FORM_FIELD_LINE, FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_ORDER,
    FORM_FIELD_PART_NO,
    FORM_FIELD_PROD,
    FORM_FIELD_QUANTITY,
    FORM_FIELD_REMARKS,
    FORM_FIELD_REPLY,
    FORM_FIELD_SUBJECT_TO_VOR,
    FORM_FIELDS_ORDER_ITEMS, FORM_LABEL_MANAGEMENT_GROUP, FORM_LABEL_MPR88,
    FORM_LABEL_REPLY,
    FORM_LABEL_REPLY_HISTORY,
    FORM_ORDERS_SECTION, FORM_TICKET_SECTION,
} from '../../../../../../constants/formConstants';
import {
    DESC_FIELD_MAX_LENGTH,
    LINE_FIELD_MAX_LENGTH,
    ORDER_FIELD_MAX_LENGTH, ORDER_FIELD_MAX_LENGTH_AR, ORDER_FIELD_MAX_LENGTH_RU,
    PART_NO_FIELD_MAX_LENGTH,
    QUANTITY_SHORT_FIELD_MAX_LENGTH,
    REMARKS_FIELD_MAX_LENGTH,
} from '../../../../../../constants/validationConstants';
import {RedButton, ReplyTd, RequiredSpan, StyledSpan, StyledTr,} from '../../../../../common/StyledComponents';
import {change, Field, formValueSelector, getFormInitialValues} from 'redux-form';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import {
    positiveNumber,
    positiveNumberWithZero,
    validAlphanumericInput,
    validPartNoLength
} from '../../../../../common/validationCommons';
import get from 'get-value';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {connect} from 'react-redux';
import InputFieldGroupWithValidation from '../../../../../common/InputFieldGroupWithValidation';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import OrdersMprRow from './OrdersMprRow';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import commonMessages from '../../../../../../intl/common/commonMessages';
import {prodValues} from '../../../../../../constants/Utils';
import {moduleRoles} from '../../../../../../utils/roles';
import {isEmpty} from 'lodash';
import {PrimaryButton} from '../../../../../common/Button';
import buttonMessages from '../../../../../../intl/common/buttonMessages';

class OrdersVorPviTableBody extends Component {
    constructor(props) {
        super(props)
        this.customRefs = {
            [FORM_FIELD_ORDER]: React.createRef(),
            [FORM_FIELD_LINE]: React.createRef(),
            [FORM_FIELD_PART_NO]: React.createRef(),
            [FORM_FIELD_QUANTITY]: React.createRef(),
            [FORM_BUTTON_LOAD]: React.createRef(),
            [FORM_FIELD_REMARKS]: React.createRef(),
            [FORM_FIELD_LIC_PROD]: React.createRef(),
            [FORM_FIELD_LIC_ORD]: React.createRef(),
            [FORM_FIELD_LIC_OAN]: React.createRef(),
            [FORM_FIELD_LIC_LIG]: React.createRef(),
            [FORM_FIELD_LIC_IAN]: React.createRef(),
        };
    }

    changeFocus = (value, maxLength, fieldName) => {
        if (get(this, `customRefs.${fieldName}.current`) && maxLength && value.length === maxLength) {
            this.customRefs[fieldName].current.focus();
        }
    };

    render() {
        const {userRights, readOnly, order, index, initialValues, reqFields, orders, handleRemoveOrder, handleOrderPartLoad, handleDicTransfer, form, processingDic, dicNumber, createdInDic, user, user: {roles}, solutionGroupInfo = {}, submittedBy} = this.props;
        const orderHistoryReplies = get((initialValues), `${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}.${index}.replies`, {default: []});
        const transferredToDic = get(initialValues, `${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}.${index}.${FORM_CHECKBOX_DIC}`, false);
        return (
            <>
                <tr className="border-bottom">
                    {userRights.canViewMpr &&
                    <td className="text-center" style={{maxWidth: '100px', minWidth: '50px'}}>
                        <Field
                            name={`${order}.${FORM_CHECKBOX_DIC}`}
                            component="input"
                            type="checkbox"
                            onClick={handleDicTransfer}
                            disabled={readOnly || !userRights.canEditMpr || orders[index].licEnded || processingDic || transferredToDic || (!!dicNumber && !createdInDic)}
                        />
                    </td>
                    }
                    <td id="order1Print" style={{maxWidth: '120px', minWidth: '90px'}}>
                        <InputSelectFieldWithValidation field={`${order}.${FORM_FIELD_PROD}`}
                                                        options={get(prodValues, `${get(initialValues, 'ticketSection.group', {default: 'X'})}`, {default: []})}
                                                        isDisabled={!userRights.canEditItems || readOnly}
                                                        isRequired={reqFields.includes(FORM_FIELD_PROD) && !readOnly}
                                                        handleChange={() => this.customRefs[FORM_FIELD_ORDER].current.focus()}
                        />
                    </td>
                    <td id="order2Print" style={{maxWidth: '130px', minWidth: '110px'}}>
                        <InputFieldWithValidation field={`${order}.${FORM_FIELD_ORDER}`}
                                                  maxLength={user.group === "AR" ? ORDER_FIELD_MAX_LENGTH_AR : (user.group === "RU" ? ORDER_FIELD_MAX_LENGTH_RU : ORDER_FIELD_MAX_LENGTH)}
                                                  validations={[validAlphanumericInput]}
                                                  isDisabled={!userRights.canEditItems || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_ORDER) && !readOnly}
                                                  isSmall
                                                  setRef={this.customRefs[FORM_FIELD_ORDER]}
                                                  handleKeyUp={(value) => this.changeFocus(value, (user.group === "AR" ? ORDER_FIELD_MAX_LENGTH_AR : (user.group === "RU" ? ORDER_FIELD_MAX_LENGTH_RU : ORDER_FIELD_MAX_LENGTH)), FORM_FIELD_LINE)}
                        />
                    </td>
                    <td id="order2Print" style={{maxWidth: '130px', minWidth: '100px'}}>
                        <InputFieldWithValidation field={`${order}.${FORM_FIELD_LINE}`}
                                                  maxLength={LINE_FIELD_MAX_LENGTH}
                                                  validations={[positiveNumberWithZero]}
                                                  isDisabled={!userRights.canEditItems || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_LINE) && !readOnly}
                                                  isSmall
                                                  setRef={this.customRefs[FORM_FIELD_LINE]}
                                                  handleKeyUp={(value) => this.changeFocus(value, LINE_FIELD_MAX_LENGTH, FORM_FIELD_PART_NO)}
                        />
                    </td>
                    <td id="order2Print" style={{maxWidth: '200px', minWidth: '175px'}}>
                        <InputFieldGroupWithValidation field={`${order}.${FORM_FIELD_PART_NO}`}
                                                       isRequired={reqFields.includes(FORM_FIELD_PART_NO) && !readOnly}
                                                       isButtonDisabled={!orders[index][FORM_FIELD_PART_NO] || orders[index][FORM_FIELD_PART_NO].length < 10 || orders[index][FORM_FIELD_DESCRIPTION] || orders[index][FORM_FIELD_SUBJECT_TO_VOR]}
                                                       isDisabled={!userRights.canEditItems || readOnly || transferredToDic}
                                                       loadOrderData={() => {
                                                           handleOrderPartLoad(index);
                                                           this.customRefs[FORM_FIELD_QUANTITY].current.focus()
                                                       }}
                                                       maxLength={PART_NO_FIELD_MAX_LENGTH}
                                                       validations={[validPartNoLength]}
                                                       setRef={this.customRefs[FORM_FIELD_PART_NO]}
                                                       setRefButton={this.customRefs[FORM_BUTTON_LOAD]}
                                                       handleKeyUp={(value) => this.changeFocus(value, PART_NO_FIELD_MAX_LENGTH, FORM_BUTTON_LOAD)}
                                                       isUpperCase
                        />
                    </td>
                    <td id="order1Print" style={{maxWidth: '80px', minWidth: '50px'}}>
                        <InputSelectFieldWithValidation
                            field={`${order}.${FORM_FIELD_SUBJECT_TO_VOR}`}
                            options={['YES', 'NO']}
                            intlMessages={user.settings.language === "de" ? formMessages : commonMessages}
                            isDisabled={!userRights.canEditMpr || readOnly || orders[index].licEnded}
                            isRequired={reqFields.includes(FORM_FIELD_SUBJECT_TO_VOR) && !readOnly}
                            handleChange={() => this.customRefs[FORM_FIELD_REMARKS].current.focus()}
                        />
                    </td>
                    <td id="order3Print" style={{minWidth: '100px'}}>
                        <InputFieldWithValidation field={`${order}.${FORM_FIELD_DESCRIPTION}`}
                                                  maxLength={DESC_FIELD_MAX_LENGTH}
                                                  isDisabled={!userRights.canEditItems || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_DESCRIPTION) && !readOnly}
                                                  isSmall
                                                  handleKeyUp={(value) => this.changeFocus(value, DESC_FIELD_MAX_LENGTH, FORM_FIELD_QUANTITY)}/>
                    </td>
                    <td id="order1Print" style={{maxWidth: '100px', minWidth: '50px'}}>
                        <InputFieldWithValidation field={`${order}.${FORM_FIELD_QUANTITY}`}
                                                  maxLength={QUANTITY_SHORT_FIELD_MAX_LENGTH}
                                                  validations={[positiveNumber]}
                                                  isDisabled={!userRights.canEditItemsQuantity || readOnly || transferredToDic}
                                                  isRequired={reqFields.includes(FORM_FIELD_QUANTITY) && !readOnly}
                                                  isSmall
                                                  setRef={this.customRefs[FORM_FIELD_QUANTITY]}
                                                  handleKeyUp={(value) => this.changeFocus(value, QUANTITY_SHORT_FIELD_MAX_LENGTH, FORM_FIELD_REMARKS)}/>
                    </td>
                    <td id="order2Print" style={{maxWidth: '140px', minWidth: '80px'}}>
                        <InputFieldWithValidation field={`${order}.${FORM_FIELD_REMARKS}`}
                                                  maxLength={REMARKS_FIELD_MAX_LENGTH}
                                                  isDisabled={(!userRights.canEditItems && !userRights.canEditRemarks) || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_REMARKS) && !readOnly}
                                                  isSmall
                                                  setRef={this.customRefs[FORM_FIELD_REMARKS]}
                                                  handleKeyUp={(value) => this.changeFocus(value, REMARKS_FIELD_MAX_LENGTH, FORM_FIELD_LIC_PROD)}/>
                    </td>
                    {userRights.canEditItems && !readOnly ?
                        <td>
                            {index > get(initialValues, `${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}`, {default: [{}]}).length - 1 ?
                                <RedButton type="button"
                                           title="Remove Member"
                                           onClick={handleRemoveOrder}
                                           className="form-control form-control-sm">
                                    <FontAwesomeIcon icon="trash-alt"/>
                                </RedButton>
                                : null
                            }
                        </td> : null
                    }
                </tr>
                { moduleRoles.isSGEditorOrder(roles) && <StyledTr>
                    <td className="font-weight-bold text-right vertical border-0">
                        <ValidFormattedMessage message={FORM_LABEL_MANAGEMENT_GROUP} intlMessages={formMessages}/>
                        {(isEmpty(solutionGroupInfo) ? (reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP)) : (reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP) && !get(solutionGroupInfo, "groups", []).length == 0)) && !readOnly &&
                            <RequiredSpan>{'\u00A0*'}</RequiredSpan>}
                    </td>
                    <td className="border-0" colSpan="6">
                        <div className="form-row align-items-end">
                            <div className="col-md-2 col-lg-2 col-xl-2 p-0">
                                <InputFieldWithValidation field={`${order}.${FORM_FIELD_MANAGEMENT_GROUP}`}
                                                          maxLength={2}
                                                          isDisabled={!(userRights.canEditManagementGroup) || readOnly}
                                                          isRequired={(isEmpty(solutionGroupInfo) ? (reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP)) : (reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP) && !get(solutionGroupInfo, "groups", []).length == 0)) && submittedBy === FORM_BUTTON_ESCALATE && !readOnly}
                                                          validations={[validAlphanumericInput]}
                                                          isSmall/>
                            </div>
                            {userRights.canEditManagementGroup && !readOnly &&
                                <>
                                    <div className="pl-2 pt-1">
                                        <PrimaryButton type="button" className="btn"
                                                       onClick={() => handleOrderPartLoad(index)}
                                        >
                                            <ValidFormattedMessage message={BUTTON_LABEL_LOAD}
                                                                   intlMessages={buttonMessages}/>
                                        </PrimaryButton>
                                    </div>
                                </>
                            }
                        </div>
                    </td>
                </StyledTr>
                }
                {userRights.canViewMpr &&
                <OrdersMprRow order={order} index={index} form={form} userRights={userRights} readOnly={readOnly}
                              reqFields={reqFields}
                              ref={this.customRefs}
                              changeFocus={this.changeFocus}
                />}

                {userRights.canViewAuthReply && orderHistoryReplies.map((reply, index) =>
                    <StyledTr key={index}>
                        <ReplyTd className="font-weight-bold text-right vertical border-0">{index === 0 ?
                            <ValidFormattedMessage message={FORM_LABEL_REPLY_HISTORY}
                                                   intlMessages={formMessages}/> : null}</ReplyTd>
                        <ReplyTd className="border-0" colSpan="7">
                            {reply.reply}
                        </ReplyTd>
                    </StyledTr>)
                }

                {userRights.canEditAuthReply && !readOnly && <StyledTr>
                    <td className="font-weight-bold text-right vertical border-0 ">
                        <ValidFormattedMessage message={FORM_LABEL_REPLY} intlMessages={formMessages}/>
                        {(reqFields.includes(FORM_FIELD_REPLY) && orderHistoryReplies.length === 0) ?
                            <StyledSpan>{'\u00A0*'}</StyledSpan> : null}
                    </td>
                    <td className="border-0" colSpan="7">
                        <TextareaFieldWithValidation field={`${order}.${FORM_FIELD_REPLY}`} rows={2}
                                                     isRequired={reqFields.includes(FORM_FIELD_REPLY) && orderHistoryReplies.length === 0}/>
                    </td>
                </StyledTr>
                }
            </>
        )
    };
};

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
    orders: formValueSelector(props.form)(state, `${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}`),
    dicNumber: formValueSelector(props.form)(state, `${FORM_ORDERS_SECTION}.${FORM_FIELD_DIC_NUMBER}`),
    processingDic: state.sparePartsDic.isCreateOrUpdateDicLoading,
    createdInDic: state.sparePartsOrderTicket.ticket.createdInDic,
    user: state.profile.userDetail,
    solutionGroupInfo: state.sparePartsOrderTicket.solutionGroupInfo,
    submittedBy: state.sparePartsOrderTicket.submittedBy,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersVorPviTableBody);
