import {axiosInstance} from './rest';
import {domains} from '../constants/Utils';

export async function loadSolutionGroups() {
    return axiosInstance.get(`administration/GENERIC_TICKET/solutionGroups`);
}

export async function createSolutionGroup(solutionGroupCreateEvent) {
    return axiosInstance.post(`administration/GENERIC_TICKET/solutionGroups`, solutionGroupCreateEvent);
}

export async function deleteSolutionGroup(solutionGroupId) {
    return axiosInstance.delete(`administration/GENERIC_TICKET/solutionGroups/${solutionGroupId}`);
}

export async function saveSolutionGroup(solutionGroupId, solutionGroupUpdateEvent) {
    return axiosInstance.put(`administration/GENERIC_TICKET/solutionGroups/${solutionGroupId}`, solutionGroupUpdateEvent);
}
