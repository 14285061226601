import React from 'react';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    FORM_CHECKBOX_CLAIM_RELATED,
    FORM_CHECKBOX_PVI_RELATED,
    FORM_FIELD_CLAIM_RELATED, FORM_FIELD_EDITOR_IPN,
    FORM_FIELD_GAR, FORM_FIELD_IMMOBILIZED_VEHICLE, FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_PAGE_POST,
    FORM_FIELD_PAGE_PREF,
    FORM_FIELD_PART_DESCRIPTION,
    FORM_FIELD_PART_MARKING,
    FORM_FIELD_PART_NUMBER,
    FORM_FIELD_PR,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_PVI_RELATED,
    FORM_LABEL_CLAIM_RELATED,
    FORM_LABEL_GAR,
    FORM_LABEL_IMMOBILIZED_VEHICLE, FORM_LABEL_MANAGEMENT_GROUP,
    FORM_LABEL_PAGE,
    FORM_LABEL_PART_DESCRIPTION,
    FORM_LABEL_PART_MARKING,
    FORM_LABEL_PART_NUMBER,
    FORM_LABEL_PR,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_PVI_RELATED,
} from '../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';
import {TextareaFieldWithValidation} from '../../../common/TextareaFieldWithValidation';
import {InputFieldPage} from '../../../common/InputFieldPage';
import {InputGroupCheckbox} from '../../../common/InputGroupCheckbox';
import fulltextSearchMessages from '../../../../intl/technicalDocumentations/fulltextSearchMessages';
import InputSelectFieldWithValidation from '../../../common/InputSelectFieldWithValidation';
import immobilizedVehicleMessages from '../../../../intl/technicalDocumentations/immobilizedVehicleMessages';
import {immobilizedVehicle} from '../../../../constants/Utils';
import searchEngineMessages from '../../../../intl/sparePartsOrders/searchEngineMessages';
import {connect} from 'react-redux';
import {handleFetchSearchClientIdsError} from '../../../../actions/admin/actionAdminClientIds';
import {moduleRoles} from '../../../../utils/roles';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const ProblemDescriptionSectionTechDocSearch = ({roles}) => {

    const isSgEditor = moduleRoles.isSGEditorDocumentation(roles);
    const isEditor = !isSgEditor && moduleRoles.isEditorDocumentation(roles);
    const isDealer = !isSgEditor && !isEditor && moduleRoles.isDealerDocumentation(roles);

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...fulltextSearchMessages.PROBLEM_DESCRIPTION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-2 col-lg-3 col-md-4 pb-1">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_PR]}
                                                  field={FORM_FIELD_PR}
                                                  maxLength={5}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-5 pb-1">
                        <InputFieldPage label={fulltextSearchMessages[FORM_LABEL_PAGE]}
                                        fields={[FORM_FIELD_PAGE_PREF, FORM_FIELD_PAGE_POST]}
                        />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-5 pb-1">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_PART_NUMBER]}
                                                  field={FORM_FIELD_PART_NUMBER}
                                                  maxLength={10}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-4 col-lg-4 col-md-4 pb-1">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_PART_DESCRIPTION]}
                                                  field={FORM_FIELD_PART_DESCRIPTION}
                                                  maxLength={30}
                                                  isSmall/>
                    </div>
                    {!isDealer &&
                        <div className="col-xl-4 col-lg-4 col-md-4 pb-1">
                            <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_MANAGEMENT_GROUP]}
                                                      field={FORM_FIELD_MANAGEMENT_GROUP}
                                                      maxLength={2}
                                                      isSmall/>
                        </div>
                    }
                    <div className="col-xl-4 col-lg-4 col-md-4 pb-1">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_PART_MARKING]}
                                                  field={FORM_FIELD_PART_MARKING}
                                                  maxLength={20}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-3 col-lg-6">
                        <InputGroupCheckbox label={fulltextSearchMessages[FORM_LABEL_CLAIM_RELATED]}
                                            fields={[FORM_CHECKBOX_CLAIM_RELATED, FORM_FIELD_CLAIM_RELATED]}
                                            maxLength={8}
                                            placeholder="No."/>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <InputGroupCheckbox label={fulltextSearchMessages[FORM_LABEL_PVI_RELATED]}
                                            fields={[FORM_CHECKBOX_PVI_RELATED, FORM_FIELD_PVI_RELATED]}
                                            maxLength={8}
                                            placeholder="No."/>
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6 pb-1">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_GAR]}
                                                  field={FORM_FIELD_GAR}
                                                  maxLength={20}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputSelectFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_IMMOBILIZED_VEHICLE]}
                                                        field={FORM_FIELD_IMMOBILIZED_VEHICLE}
                                                        options={immobilizedVehicle}
                                                        intlMessages={immobilizedVehicleMessages}/>
                    </div>
                    <div className="col-12 pr-0 pb-1">
                        <TextareaFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_PROBLEM_DESCRIPTION]}
                                                     field={FORM_FIELD_PROBLEM_DESCRIPTION}
                                                     maxLength={3000}
                                                     rows={6}/>
                    </div>
                </div>
            </StyledDiv>
        </>
    )
};

const mapStateToProps = (state) => ({
    roles: state.profile.userDetail.roles,
});

export default connect(mapStateToProps, null)(injectIntl(ProblemDescriptionSectionTechDocSearch));
