import React from 'react';
import {Route} from 'react-router-dom'
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {PrimaryButton} from "../../common/Button";
import buttonMessages from "../../../intl/common/buttonMessages";

export const PrimaryButtonRoute = ({path}) => (
    <Route render={({history, history: {location: {pathname}}}) => (
        <PrimaryButton type="button" className="btn" onClick={() => history.push(`${pathname}/${path}`)}>
            <FormattedMessage {...buttonMessages.ADD}/>
        </PrimaryButton>
    )}/>
);

PrimaryButtonRoute.propTypes = {
    path: PropTypes.string.isRequired
};
