import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import Loader from '../../../Loader';
import {ANSWERING_SCHEMA_FORM, FORM_BUTTON_EDIT,} from '../../../../constants/formConstants';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {
    fetchAnsweringSchema,
    resetAnsweringSchema,
    updateAnsweringSchema
} from '../../../../actions/admin/actionAnsweringSchema';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import AnsweringSchemaForm from './forms/AnsweringSchemaForm';
import {initLoadedAnsweringSchema} from '../../../../utils/answeringSchemaUtils';
import {getAdminAnsweringSchemaPath} from '../../../../routes/pathResolver';

class ViewAnsweringSchemaPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToNew: false,
            redirectToEdit: false,
            answeringSchemaSubmitted: false,
            hasToBeUpdated: false,
            storedAnsweringSchemaData: {},
            newInitData: {},
        };
    }

    componentDidMount() {
        this.props.clearAlerts();
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchAnsweringSchema(this.props.domain, this.props.match.params.answeringSchemaUuid, this.props.userDetail.ipn);
        }
    }

    componentWillUnmount() {
        this.props.resetAnsweringSchema(this.props.domain);
    }

    handleSubmit = (values) => {
        const { submitBy } = values;

        switch (submitBy) {
            case FORM_BUTTON_EDIT: {
                this.setState({
                    answeringSchemaSubmitted: true,
                    redirectToEdit: true,
                });
                break;
            }
            default: {
            }
        }
    };

    render() {
        const {
            answeringSchemaData,
            domain,
            intl: {formatMessage},
        } = this.props;

        let formType = false;
        if (get(answeringSchemaData, 'answeringSchema.name', { default: false })) {
            formType = ANSWERING_SCHEMA_FORM;
        }


        if (this.state.answeringSchemaSubmitted && this.state.redirectToEdit) {
            return <Redirect to={{
                pathname: `${getAdminAnsweringSchemaPath(domain)}/edit/${answeringSchemaData.answeringSchema.formType + "-" + answeringSchemaData.answeringSchema.solutionGroupId}`,
            }}/>;
        } else if (this.state.answeringSchemaSubmitted) {
            this.props.updateAnsweringSchema(domain, this.state.storedAnsweringSchemaData);
            this.setState({ answeringSchemaSubmitted: false, storedAnsweringSchemaData: {}, hasToBeUpdated: false });
        }

        const initializationValue = initLoadedAnsweringSchema(domain, answeringSchemaData, formatMessage);
        if (
            answeringSchemaData.isAnsweringSchemaLoading
            || answeringSchemaData.isAnsweringSchemaCreating
            || !formType
        ) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.answeringLevel.title"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - Answering schema"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <div className="container-fluid">
                    <div className="col-12">
                        <AnsweringSchemaForm
                            form={formType}
                            initialValues={initializationValue}
                            enableReinitialize={true}
                            reqFields={[]}
                            domain={domain}
                            readOnly
                            handleSubmit={this.handleSubmit}
                            type={'view'}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    answeringSchemaData: state.adminAnsweringSchema,
    userDetail: state.profile.userDetail,
});

ViewAnsweringSchemaPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    answeringSchemaData: PropTypes.object.isRequired,
    fetchAnsweringSchema: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    updateAnsweringSchema: PropTypes.func.isRequired,
    resetAnsweringSchema: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    userDetail: PropTypes.object.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchAnsweringSchema,
    clearAlerts,
    resetAnsweringSchema,
    updateAnsweringSchema,
})(injectIntl(ViewAnsweringSchemaPage))), ['TD_POWER_USER','SP_POWER_USER']);

