import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import {formatTicketNumber} from '../../../utils/utils';
import {initLoadedClaimsTicket} from '../Utils';
import {fetchAdminClientIds} from '../../../actions/admin/actionAdminClientIds';
import {moduleRoles} from '../../../utils/roles';
import {editRights} from '../../../constants/claimsTicketRights';
import {editReqFields} from '../../../constants/claimsRequiredFields';
import checkRoles from '../../common/roleChecker/RoleChecker';
import ContestClaimStatusMasterForm from './forms/ContestClaimStatusMasterForm';
import ProblemForPlacingClaimsMasterForm from './forms/ProblemForPlacingClaimsMasterForm';
import ReturnClaimMasterForm from './forms/ReturnClaimMasterForm';
import StockDeadClaimMasterForm from './forms/StockDeadClaimMasterForm';
import OldMaterialClaimMasterForm from './forms/OldMaterialClaimMasterForm';
import DirectFlowClaimMasterForm from './forms/DirectFlowClaimMasterForm';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import Loader from '../../Loader';
import formMessages from '../../../intl/claims/formMessages';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import {
    CONTEST_CLAIM_STATUS_FORM,
    PROBLEM_FOR_PLACING_CLAIMS_FORM,
    RETURN_CLAIM_FORM,
    STOCK_DEAD_CLAIM_FORM,
    OLD_MATERIAL_CLAIM_FORM,
    DIRECT_FLOW_CLAIM_FORM,
    TROUBLESHOOT_CLAIM_FORM
} from '../../../constants/formConstants';
import TroubleshootMasterForm from './forms/TroubleshootMasterForm';

class EditLoadTicketDataPage extends Component {
    componentDidMount() {
        const {ticketData} = this.props;
        this.props.fetchAdminClientIds(get(ticketData, 'ticket.group'), get(ticketData, 'ticket.dealerNumber'));
    }

    render() {
        const {ticketData, loggedUser, clientIds, handleSubmit, formType} = this.props;

        if (clientIds.isLoading) {
            return <Loader/>;
        }

        const initializationValue = initLoadedClaimsTicket(ticketData);
        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {formType === CONTEST_CLAIM_STATUS_FORM &&
                    <ContestClaimStatusMasterForm form={formType}
                                                  initialValues={initializationValue}
                                                  userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                  enableReinitialize={true}
                                                  handleSubmit={handleSubmit}
                                                  reqFields={editReqFields[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][formType]}
                                                  editMode
                    />
                    }
                    {formType === PROBLEM_FOR_PLACING_CLAIMS_FORM &&
                    <ProblemForPlacingClaimsMasterForm form={formType}
                                                       initialValues={initializationValue}
                                                       userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                       enableReinitialize={true}
                                                       handleSubmit={handleSubmit}
                                                       reqFields={editReqFields[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][formType]}
                                                       editMode
                    />
                    }
                    {formType === RETURN_CLAIM_FORM &&
                    <ReturnClaimMasterForm form={formType}
                                           initialValues={initializationValue}
                                           userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                           enableReinitialize={true}
                                           handleSubmit={handleSubmit}
                                           reqFields={editReqFields[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][formType]}
                                           editMode
                    />
                    }
                    {formType === STOCK_DEAD_CLAIM_FORM &&
                    <StockDeadClaimMasterForm form={formType}
                                              initialValues={initializationValue}
                                              userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                              enableReinitialize={true}
                                              handleSubmit={handleSubmit}
                                              reqFields={editReqFields[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][formType]}
                                              editMode
                    />
                    }
                    {formType === OLD_MATERIAL_CLAIM_FORM &&
                    <OldMaterialClaimMasterForm form={formType}
                                                initialValues={initializationValue}
                                                userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                enableReinitialize={true}
                                                handleSubmit={handleSubmit}
                                                reqFields={editReqFields[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][formType]}
                                                editMode
                    />
                    }
                    {formType === DIRECT_FLOW_CLAIM_FORM &&
                    <DirectFlowClaimMasterForm form={formType}
                                               initialValues={initializationValue}
                                               userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                               enableReinitialize={true}
                                               handleSubmit={handleSubmit}
                                               reqFields={editReqFields[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][formType]}
                                               editMode
                    />
                    }
                    {formType === TROUBLESHOOT_CLAIM_FORM &&
                    <TroubleshootMasterForm form={formType}
                                            initialValues={initializationValue}
                                            userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                            enableReinitialize={true}
                                            handleSubmit={handleSubmit}
                                            reqFields={editReqFields[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][formType]}
                                            editMode
                    />
                    }
                </div>
            </div>
        );
    }
}

EditLoadTicketDataPage.propTypes = {
    location: PropTypes.object,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.claimsTicket,
    clientIds: state.adminClientIds,
});

export default checkRoles(connect(mapStateToProps, {
    fetchAdminClientIds,
})(EditLoadTicketDataPage), ['CL_DEALER', 'CL_EDITOR']);

