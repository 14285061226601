import React from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import PageHeader from '../common/PageHeader';

class GenericMainPage extends React.Component {
    render() {
        return (
            <div>
                <PageHeader title={<FormattedMessage id="admin.page.header.generic" defaultMessage="Generic Ticket"/>}/>
            </div>
        );
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps, {})(GenericMainPage);
