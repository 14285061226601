import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {Field, reduxForm} from 'redux-form';
import {PrimaryButton, SecondaryButton} from '../../common/Button';
import {connect} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader, ModalWindow} from '../../common/modal/Modal';
import {FieldWithValidation} from '../../common/FormField';
import {required, validDigitString} from '../../common/validationCommons';
import {CLIENT_ID_FIELD_MAX_LENGTH} from '../../../constants/validationConstants';
import clientIdMessages from '../../../intl/admin/clientIdMessages';
import buttonMessages from '../../../intl/common/buttonMessages';

const Button = styled(SecondaryButton)`
  margin-right: 10px;
`;

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

class AddClientIdModal extends React.Component {
    save = (data) => {
        this.props.reset();
        this.props.saveModal(data);
    };

    close = () => {
        this.props.reset();
        this.props.closeModal();
    };

    render() {
        const {show, handleSubmit, clientIdData, pristine, submitting} = this.props;
        return (
            <ModalWindow
                isOpen={show}
                onRequestClose={this.close}
                shouldCloseOnOverlayClick={false}>
                <StyledForm onSubmit={handleSubmit(this.save)}>
                    <ModalHeader>
                        <FormattedMessage {...clientIdMessages.MODAL_TITLE} />
                    </ModalHeader>
                    <ModalBody>
                        <div className="d-flex justify-content-center">
                            <div className="col-8 ">
                                <div className="form-row align-items-center p-1">
                                    <label className="col-4 pl-0 font-weight-bold ">
                                        <FormattedMessage {...clientIdMessages.MODAL_DEALER_NUMBER} />:
                                    </label>
                                    <span className="col-7">{clientIdData.bir8}</span>
                                </div>
                                <div className="form-row align-items-center p-1">
                                    <label className="col-4 pl-0 font-weight-bold ">
                                        <FormattedMessage {...clientIdMessages.MODAL_NAME} />:
                                    </label>
                                    <span className="col-7">{clientIdData.dealerName}</span>
                                </div>
                                <div className="form-row align-items-center p-1">
                                    <label className="col-4 pl-0 font-weight-bold ">
                                        <FormattedMessage {...clientIdMessages.MODAL_CLIENT_ID} />:
                                    </label>
                                    <Field
                                        name="clientId"
                                        component={FieldWithValidation}
                                        type="text"
                                        validate={[required, validDigitString]}
                                        maxLength={CLIENT_ID_FIELD_MAX_LENGTH}
                                        isSmall={true}
                                        className="col-7 form-control form-control-sm"
                                    />
                                </div>
                                <div className="form-row align-items-center p-1">
                                    <label className="col-4 pl-0 font-weight-bold ">
                                        <FormattedMessage {...clientIdMessages.MODAL_DESTINATION_CODE} />:
                                    </label>
                                    <Field
                                        name="destinationCode"
                                        component={FieldWithValidation}
                                        type="text"
                                        validate={[required, validDigitString]}
                                        maxLength={CLIENT_ID_FIELD_MAX_LENGTH}
                                        isSmall={true}
                                        className="col-7 form-control form-control-sm"
                                    />
                                </div>
                                <div className="form-row align-items-center p-1">
                                    <label className="col-4 pl-0 font-weight-bold ">
                                        <FormattedMessage {...clientIdMessages.MODAL_REGION} />:
                                    </label>
                                    <span className="col-7">{clientIdData.region}</span>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row">
                            <div className="col">
                                <Button type="button" className="btn" onClick={this.close}>
                                    <FormattedMessage {...buttonMessages.CANCEL} />
                                </Button>
                                <PrimaryButton type="submit" className="btn" disabled={pristine || submitting}>
                                    <FormattedMessage {...buttonMessages.SAVE} />
                                </PrimaryButton>
                            </div>
                        </div>
                    </ModalFooter>
                </StyledForm>
            </ModalWindow>
        )
    }
}

AddClientIdModal.propTypes = {
    show: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveModal: PropTypes.func.isRequired,
    clientIdData: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
});

export default reduxForm({
    form: 'addClientIdModalForm',
    enableReinitialize: true,
})(connect(mapStateToProps, {})(AddClientIdModal));
