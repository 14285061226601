import {FILE, YOUTUBE_VIDEO} from '../../HelpConst';

const values = [
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - How to use the List Menu ?',
        href: 'https://youtu.be/2ibt3nrIxEA'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - How to use the History Menu ?',
        href: 'https://youtu.be/YMfii405nMo'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - How to find a specific ticket with the search engine ?',
        href: 'https://youtu.be/FMe-ie3RrLw'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - How to export tickets in excel file available only for administrators?',
        href: 'https://youtu.be/hr76oiwJ3To'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - How to process a dealer ticket - Local Expertise ?',
        href: 'https://youtu.be/EsiGfuTTnbc'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - How to process a dealer ticket - Central RCL level 2',
        href: 'https://youtu.be/Iz5NcL_NQmg'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - How to process a dealer ticket when you are "Business Corporate level 3" ?',
        href: 'https://youtu.be/hFQoIe4DfVI'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'How to add and delete a User',
        href: 'https://youtu.be/r9XOfkGjGyw'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'How to understand the status of a ticket',
        href: 'https://youtu.be/wLEPta29rH8'
    },
];

export default values;
