import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {cloneDeep} from 'lodash';
import {
    clearEditorRightsManagementPageData,
    fetchEditorRightsManagementPageData,
    clearDealerRightsManagementPageData,
    fetchDealerRightsManagementPageData,
    saveEditorRightsManagementPageData,
    setEditorCountriesChanged,
    setInitialEditorCountryOptions,
    setManagedUser,
    setSelectedEditorCountryOptions,
} from '../../../actions/admin/actionAdminRightsManagement';
import SelectUser from './SelectUser';
import {clearAlerts} from '../../../actions/alertsActions';
import {countryGroups} from '../../../constants/Utils';
import SelectEditorCountries from './SelectEditorCountries';
import Loader from '../../Loader';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import errorMessages from '../../../intl/common/errorMessages';
import {handleUserSearch} from '../../../utils/userUtils';
import PageHeader from '../../common/PageHeader';
import ImportersTable from '../../common/ImportersTable';
import {handleFetchUsersError} from '../../../actions/actionRole';

const Panel = styled.h1`
  font-size: 1.5em;
  text-align: center;
`;

class EditorRightsManagementPage extends Component {
    componentDidMount() {
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setEditorCountriesChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleCountrySelectionChange = newOptions => {
        this.setSelectedOptionsChanged(newOptions);
        this.props.setSelectedEditorCountryOptions(newOptions);
    };

    handleAllCountriesClick = () => {
        const selectedCountries = {};

        Object.keys(this.props.editorData || {}).forEach(
            countryIso => selectedCountries[countryIso] = true
        );

        const selectedOptions = this.setEditorDataAsCountryOptions(selectedCountries);
        this.setSelectedOptionsChanged(selectedOptions);
    };

    handleSaveClick = () => {
        const selectedCountries = {};
        Object.keys(countryGroups).forEach(
            groupKey => selectedCountries[countryGroups[groupKey]] = false
        );

        if (this.props.selectedOptions) {
            this.props.selectedOptions.forEach(
                ({value}) => {
                    selectedCountries[value] = true;
                }
            )
        }

        this.props.saveEditorRightsManagementPageData(this.props.managedUser, this.props.domain, selectedCountries);
    };

    handleCancelClick = () => {
        this.props.setSelectedEditorCountryOptions(cloneDeep(this.props.initialOptions));
        this.props.setEditorCountriesChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearEditorRightsManagementPageData();
        this.props.clearDealerRightsManagementPageData();
        this.props.fetchDealerRightsManagementPageData(user, this.props.domain);
        this.props.fetchEditorRightsManagementPageData(user, this.props.domain);
    };

    setEditorDataAsCountryOptions = editorData => {
        const result = Object.keys(editorData || {}).map(countryIso => ({value: countryIso}));
        this.props.setSelectedEditorCountryOptions(cloneDeep(result));

        return result;
    };

    setSelectedOptionsChanged = newOptions => this.props.setEditorCountriesChanged(
        JSON.stringify(
            cloneDeep(this.props.initialOptions)
                .sort((option1, option2) => option1.value.localeCompare(option2.value))
        ) !== JSON.stringify(
        newOptions
            .sort((option1, option2) => option1.value.localeCompare(option2.value))
        )
    );

    render() {
        const {isLoading, managedUser, editorData, isDealer, domain, selectedOptions} = this.props;
        if (isLoading) {
            return <Loader/>;
        }
        return (
            <div>
                <PageHeader title={
                    <FormattedMessage id="admin.title.auth.mng.editor"
                                      defaultMessage="Authorization Management - {domain} - Editor"
                                      values={{domain: <FormattedMessage {...formTypeMessages[domain]}/>}}/>
                }/>
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch}/>
                </div>
                {isDealer &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.DEALER_ROLE_SET}/>
                </Panel>
                }
                {Object.keys(editorData).length > 0 && managedUser && managedUser.ipn && !isDealer &&
                <SelectEditorCountries handleCountrySelectionChange={this.handleCountrySelectionChange}
                                       handleAllCountriesClick={this.handleAllCountriesClick}
                                       handleSaveClick={this.handleSaveClick}
                                       handleCancelClick={this.handleCancelClick}/>
                }
                {selectedOptions && selectedOptions.find(country => country.value === 'IMP') &&
                <div className="container-fluid"><ImportersTable/></div>}
            </div>
        );
    }
}

EditorRightsManagementPage.propTypes = {
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    setSelectedEditorCountryOptions: PropTypes.func.isRequired,
    editorData: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    saveEditorRightsManagementPageData: PropTypes.func.isRequired,
    initialOptions: PropTypes.array.isRequired,
    setEditorCountriesChanged: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    clearEditorRightsManagementPageData: PropTypes.func.isRequired,
    fetchEditorRightsManagementPageData: PropTypes.func.isRequired,
    clearDealerRightsManagementPageData: PropTypes.func.isRequired,
    fetchDealerRightsManagementPageData: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    setInitialEditorCountryOptions: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isDealer: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminRightsManagement.isLoading,
    managedUser: state.adminRightsManagement.managedUser,
    editorData: state.adminRightsManagement.editorData,
    isDealer: Object.values(state.adminRightsManagement.dealerData || {}).some(value => value),
    loggedUser: state.profile.userDetail,
    initialOptions: state.adminRightsManagement.initialEditorOptions,
    selectedOptions: state.adminRightsManagement.selectedEditorOptions,
});

export default connect(mapStateToProps, {
    setManagedUser,
    clearAlerts,
    clearEditorRightsManagementPageData,
    fetchEditorRightsManagementPageData,
    clearDealerRightsManagementPageData,
    fetchDealerRightsManagementPageData,
    setInitialEditorCountryOptions,
    setSelectedEditorCountryOptions,
    setEditorCountriesChanged,
    saveEditorRightsManagementPageData,
    handleFetchUsersError,
})(injectIntl(EditorRightsManagementPage));
