import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.presetResponse.title',
        defaultMessage: 'Automatic Response Setting'
    },
    PRESET_RESPONSE: {
        id: 'admin.presetResponse',
        defaultMessage: 'Automatic Response'
    },
    EMPTY: {
        id: 'admin.presetResponse.empty',
        defaultMessage: 'No Automatic Responses'
    },
    MODAL_TITLE: {
        id: 'admin.presetResponse.modal.title',
        defaultMessage: 'Add New Automatic Response'
    },
    PRESET_RESPONSE_NAME: {
        id: 'admin.presetResponse.presetResponseName',
        defaultMessage: 'Name'
    },
    PRESET_RESPONSE_MESSAGE: {
        id: 'admin.presetResponse.presetResponseMessage',
        defaultMessage: 'Message'
    },
});

export default messages;
