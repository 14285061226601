import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {fetchUserRole, saveUserRole} from '../actions/actionRole';
import Loader from './Loader';
import GlobalStyle from '../theme/globalStyle';
import {axiosFileProgressInstance, initAxios} from '../api/rest';
import {
    authenticateAndRedirect,
    getAuthenticatedUser,
    processAuthenticationRedirect,
    treatEmptyIdpToken
} from '../utils/auth';
import {connectWebsockets} from '../actions/actionWebsocket';
import WebsocketHeartbeat from './WebsocketHeartbeat';
import HotSwappingIntlProvider from '../intl/ConnectedIntlProvider';
import Alert from './common/alert/Alert';
import {Provider as AlertProvider} from 'react-alert';
import AppContent from './AppContent';
import {loadProgressBar} from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import get from 'get-value';
import {logger} from '../utils/winstonLogger';
import {LOGIN_PATH, LOGOUT_PATH} from "../routes/paths";

class App extends Component {
    componentDidMount() {
        logger.info("LOG001");
        if(process.env.REACT_APP_PROVIDER_SWITCH && (localStorage.getItem("provider") === null || localStorage.getItem("providerExpireAt") < new Date().valueOf())) {
            this.props.history.push(LOGIN_PATH)
        } else {
            if (App.redirectedFromIdp(this.props.history)) {
                this.processAuthenticationRedirectResult();
            } else {
                getAuthenticatedUser().then(authenticatedUser => {
                    if (!authenticatedUser || Object.keys(authenticatedUser).length === 0 || authenticatedUser.expired) {
                        authenticateAndRedirect();
                    } else if (!this.props.userDetail || Object.keys(this.props.userDetail).length === 0) {
                        this.processAuthenticatedUser(authenticatedUser);
                    }
                }).catch( err => logger.error("LOG011",  err));
            }
        }
    }

    async componentDidUpdate() {
        if (this.props.userToBeCreated) {
            this.props.saveUserRole();
        }
    }

    static redirectedFromIdp({location}) {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code') || (urlParams.get('state') && urlParams.get('error'));

        logger.info("LOG002",  code);
        return (code && code !== null);
    }

    processAuthenticationRedirectResult = () => {
        logger.info("LOG003");
        processAuthenticationRedirect().then(
            ({user, lastVisitedPath}) => {
                const uid = get(user, 'profile.login') || get(user, 'profile.uid');
                logger.info("LOG005", {uid});
                if (!uid) {
                    treatEmptyIdpToken();
                } else {
                    this.processAuthenticatedUser(user);
                    this.props.history.push(lastVisitedPath);
                }
            },
            (err) => this.props.history.push(LOGOUT_PATH, {err})
        );
    };

    parseUserContext = (principalIpn, userContext) => {
        const userContextProfiles = userContext.map(str => {
            const parts = str.split(';');
            const user = parts[0].split(':');
            const dealership = parts[2].split('|');

            return {
                ipn: user[0] || '',
                brand: user[1] || '',
                code: parts[1] || '',
                dealerNumber: dealership[0] || '',
                registry: dealership[1] || '',
                dealerName: dealership[2] || '',
                dealerLocation: parts[3] || ''
            };
        });

        const uniqueIpnProfiles = [...new Set(userContextProfiles.map(profile => profile.ipn))].filter(e => e !== principalIpn);
        const profiles = uniqueIpnProfiles.map(e => {
            const profile = userContextProfiles.find(profile => profile.ipn === e && profile.dealerNumber && profile.dealerName);
            return profile || {ipn: e};
        });

        return [...profiles];
    };

    extractDealerNumber = (user) => {
        let dealerNumber = get(user, 'siteSourceId');
        if(dealerNumber){
            dealerNumber = dealerNumber.trim().padStart(8, '0');
        } else {
            for (let twinUser of get(user, 'userContext', {default: []})) {
                const twinUserParams = twinUser.split(';');
                if (twinUserParams.length > 2 && twinUserParams[0].toLowerCase().startsWith(get(user, 'login', '').toLowerCase())) {
                    const twinUserDealerParams = twinUserParams[2].split('|');
                    dealerNumber = twinUserDealerParams[0] ? twinUserDealerParams[0].trim().padStart(8, '0') : '';
                }
            }
        }

        return dealerNumber;
    };

    processAuthenticatedUser = user => {
        logger.info("LOG006", {user});
        initAxios(user);
        this.props.connectWebsockets(user.access_token);
        this.props.fetchUserRole(
            user.profile.login || user.profile.uid,
            `${user.profile.firstName || user.profile.firstname} ${user.profile.lastName || user.profile.lastname}`,
            this.extractDealerNumber(user.profile),
            this.parseUserContext(user.profile.login || user.profile.uid, get(user, 'profile.userContext', {default: []}))
        );
    };

    render() {
        const {isLoading} = this.props;
        if (isLoading) {
            return <Loader/>;
        }
        const {store} = this.props;
        return (
            <>
                <GlobalStyle/>
                {loadProgressBar({}, axiosFileProgressInstance)}
                <WebsocketHeartbeat/>
                <HotSwappingIntlProvider {...store}>
                    <AlertProvider template={Alert} position="top right">
                        <AppContent store={store}/>
                    </AlertProvider>
                </HotSwappingIntlProvider>
            </>
        );
    }
}

App.propTypes = {
    connectWebsockets: PropTypes.func.isRequired,
    fetchUserRole: PropTypes.func.isRequired,
    saveUserRole: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    userToBeCreated: PropTypes.bool,
    store: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    userDetail: PropTypes.object
};

const mapStateToProps = state => ({
    isLoading: state.profile.isLoading,
    userToBeCreated: state.profile.userToBeCreated,
    userDetail: state.profile.userDetail,
});

export default withRouter(connect(mapStateToProps, {
    fetchUserRole,
    connectWebsockets,
    saveUserRole
})(App));
