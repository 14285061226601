import styled from 'styled-components';
import ReactTable from 'react-table';
import {PRIMARY_GREY, PRIMARY_LIGHT_GREY, PRIMARY_YELLOW} from './colors';

export const StyledReactTable = styled(ReactTable)`
    border-radius: .25em;
    border: 1px solid ${PRIMARY_GREY} !important;

    div.rt-thead {
        div.rt-th {
            border-right: 1px solid ${PRIMARY_GREY};
        }
        div.rt-th:last-child {
            border-right: 1px solid ${PRIMARY_GREY};
        }
        div.rt-tr {
            background-color: ${PRIMARY_LIGHT_GREY};
        }
    }

    div.rt-thead.-header {
        div.rt-tr {
            border-radius: .25em .25em 0 0;
            font-weight: bold;

            div.rt-th.-sort-asc {
                box-shadow: none;
            }

            div.rt-th.-sort-desc {
                box-shadow: none;
            }
        }
    }

    div.rt-thead.-filters {
        border-bottom: 1px solid ${PRIMARY_GREY};

        div.rt-th {
            border-right: 1px solid ${PRIMARY_GREY};
        }

        input {
            border: 1px solid ${PRIMARY_GREY};
        }
    }

    div.rt-tbody {
        div.rt-tr-group {
            border-bottom: 1px solid ${PRIMARY_GREY};

            div.rt-td {
                line-height: 1.6;
            }
        }
    }

    div.pagination-bottom {
        div.-pagination {
            box-shadow: none;
            border-top: 1px solid ${PRIMARY_GREY};
        }
    }

    button.-btn {
        border-radius: 0.3em !important;
        background-color: ${PRIMARY_YELLOW} !important;
    }
`;


export const StyledReactTableWithoutId = styled(ReactTable)`
    border-radius: .25em;
    border: 1px solid ${PRIMARY_GREY} !important;

    div.rt-thead {
        div.rt-th {
            border-right: 1px solid ${PRIMARY_GREY};
        }
        div.rt-th:last-child {
            border-right: 1px solid ${PRIMARY_GREY};
        }
        div.rt-tr {
            background-color: ${PRIMARY_LIGHT_GREY};
        }
    }

    div.rt-thead.-header {
        div.rt-tr {
            border-radius: .25em .25em 0 0;
            font-weight: bold;

            div.rt-th.-sort-asc {
                box-shadow: none;
            }

            div.rt-th.-sort-desc {
                box-shadow: none;
            }

            div.rt-th:first-child {
                span {
                    display: none;
                }
            }
        }
    }

    div.rt-thead.-filters {
        border-bottom: 1px solid ${PRIMARY_GREY};

        div.rt-th {
            border-right: 1px solid ${PRIMARY_GREY};
        }

        div.rt-th:first-child {
            input {
                display: none;
            }
        }

        input {
            border: 1px solid ${PRIMARY_GREY};
        }
    }

    div.rt-tbody {
        div.rt-tr-group {
            border-bottom: 1px solid ${PRIMARY_GREY};

            div.rt-td {
                line-height: 1.6;
            }
        }
    }

    div.pagination-bottom {
        div.-pagination {
            box-shadow: none;
            border-top: 1px solid ${PRIMARY_GREY};
        }
    }

    button.-btn {
        border-radius: 0.3em !important;
        background-color: ${PRIMARY_YELLOW} !important;
    }
`;
