import {domains} from '../constants/Utils';
import {
    ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_PATH,
    ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_PATH,
    ADMINISTRATION_ORDER_ANSWERING_SCHEMA_PATH,
    ADMINISTRATION_ORDER_ESCALATION_SCHEMA_PATH,
    ADMINISTRATION_ORDER_SOLUTION_GROUP_PATH
} from './paths';

export const getAdminSGPath = (domain) => {
    if(!domain) {
        throw new Error('No domain');
    }
    if (domain === domains.TECHNICAL_DOCUMENTATION) {
        return ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_PATH;
    } else if (domain === domains.SPARE_PARTS) {
        return ADMINISTRATION_ORDER_SOLUTION_GROUP_PATH;
    } else {
        throw new Error('Invalid domain: ' + domain);
    }
}

export const getAdminEscalationSchemaPath = (domain) => {
    if(!domain) {
        throw new Error('No domain');
    }
    if (domain === domains.TECHNICAL_DOCUMENTATION) {
        return ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_PATH;
    } else if (domain === domains.SPARE_PARTS) {
        return ADMINISTRATION_ORDER_ESCALATION_SCHEMA_PATH;
    } else {
        throw new Error('Invalid domain: ' + domain);
    }
}

export const getAdminAnsweringSchemaPath = (domain) => {
    if(!domain) {
        throw new Error('No domain');
    }
    if (domain === domains.TECHNICAL_DOCUMENTATION) {
        return ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_PATH;
    } else if (domain === domains.SPARE_PARTS) {
        return ADMINISTRATION_ORDER_ANSWERING_SCHEMA_PATH;
    } else {
        throw new Error('Invalid domain: ' + domain);
    }
}
