import {put, takeEvery, delay} from 'redux-saga/effects';
import {
    ADMIN_USER_PROFILE_SEND,
    ADMIN_USER_SETTINGS_SEND,
    closeSupportModal,
    initiateCountdown
} from '../actions/actionSupport';

export function* handleSettingsUpdated() {
    yield put(closeSupportModal());
}

export function* handleProfileUpdated() {
    const count = 10;
    yield put(initiateCountdown(count));
    yield delay(count * 1000);
    yield put(closeSupportModal());
    window.location.href = window.location.origin;
}

function* supportModalSaga() {
    yield takeEvery(ADMIN_USER_SETTINGS_SEND, handleSettingsUpdated);
    yield takeEvery(ADMIN_USER_PROFILE_SEND, handleProfileUpdated);
}

export default supportModalSaga;
