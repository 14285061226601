//SP export
export const ORDER_EXPORT_FORM_FIELD_PFX_NUMBER = 'pfxNumber';
export const ORDER_EXPORT_FORM_FIELD_SFX_NUMBER = 'sfxNumber';
export const ORDER_EXPORT_FORM_FIELD_FROM = 'from';
export const ORDER_EXPORT_FORM_FIELD_TO = 'to';
export const ORDER_EXPORT_FORM_FIELD_ASSIGNED = 'assigned';
export const ORDER_EXPORT_FORM_FIELD_STATUS = 'status';
export const ORDER_EXPORT_FORM_FIELD_DEALER_NUMBER = 'dealerNumber';
export const ORDER_EXPORT_FORM_FIELD_DEALER_NAME = 'dealerName';
export const ORDER_EXPORT_FORM_FIELD_TYPE = 'type';
export const ORDER_EXPORT_FORM_FIELD_CHARSET = 'charset';
export const ORDER_EXPORT_FORM_FIELD_SOLUTION_GROUP = 'solutionGroup';

export const ORDER_EXPORT_FORM_LABEL_TICKET_NUMBER = 'TICKET_NUMBER';
export const ORDER_EXPORT_FORM_LABEL_FROM = 'DATE_FROM';
export const ORDER_EXPORT_FORM_LABEL_TO = 'DATE_TO';
export const ORDER_EXPORT_FORM_LABEL_ASSIGNED = 'ASSIGNED';
export const ORDER_EXPORT_FORM_LABEL_STATUS = 'STATUS';
export const ORDER_EXPORT_FORM_LABEL_DEALER_NUMBER = 'DEALER_NUMBER';
export const ORDER_EXPORT_FORM_LABEL_DEALER_NAME = 'DEALER_NAME';
export const ORDER_EXPORT_FORM_LABEL_TYPE = 'TYPE';
export const ORDER_EXPORT_FORM_LABEL_CHARSET = 'CHARSET';
export const ORDER_EXPORT_FORM_LABEL_SOLUTION_GROUP = 'SOLUTION_GROUP';

//DOC export
export const DOC_EXPORT_FORM_FIELD_PFX_NUMBER = 'pfxNumber';
export const DOC_EXPORT_FORM_FIELD_SFX_NUMBER = 'sfxNumber';
export const DOC_EXPORT_FORM_FIELD_FROM = 'from';
export const DOC_EXPORT_FORM_FIELD_TO = 'to';
export const DOC_EXPORT_FORM_FIELD_ASSIGNED = 'assigned';
export const DOC_EXPORT_FORM_FIELD_STATUS = 'status';
export const DOC_EXPORT_FORM_FIELD_DEALER_NUMBER = 'dealerNumber';
export const DOC_EXPORT_FORM_FIELD_DEALER_NAME = 'dealerName';
export const DOC_EXPORT_FORM_FIELD_TYPE = 'type';
export const DOC_EXPORT_FORM_FIELD_CHARSET = 'charset';
export const DOC_EXPORT_FORM_FIELD_SOLUTION_GROUP = 'solutionGroup';

export const DOC_EXPORT_FORM_LABEL_TICKET_NUMBER = 'TICKET_NUMBER';
export const DOC_EXPORT_FORM_LABEL_FROM = 'DATE_FROM';
export const DOC_EXPORT_FORM_LABEL_TO = 'DATE_TO';
export const DOC_EXPORT_FORM_LABEL_ASSIGNED = 'ASSIGNED';
export const DOC_EXPORT_FORM_LABEL_STATUS = 'STATUS';
export const DOC_EXPORT_FORM_LABEL_DEALER_NUMBER = 'DEALER_NUMBER';
export const DOC_EXPORT_FORM_LABEL_DEALER_NAME = 'DEALER_NAME';
export const DOC_EXPORT_FORM_LABEL_TYPE = 'TYPE';
export const DOC_EXPORT_FORM_LABEL_CHARSET = 'CHARSET';
export const DOC_EXPORT_FORM_LABEL_SOLUTION_GROUP = 'SOLUTION_GROUP';

//DOC internal export
export const DOC_EXPORT_INTERNAL_FORM_FIELD_FROM = 'from';
export const DOC_EXPORT_INTERNAL_FORM_FIELD_TO = 'to';
export const DOC_EXPORT_INTERNAL_FORM_FIELD_STATUS = 'status';
export const DOC_EXPORT_INTERNAL_FORM_FIELD_IS_ASSIGNED_TO = 'isAssignedTo';
export const DOC_EXPORT_INTERNAL_FORM_FIELD_HAS_BEEN_ASSIGNED_TO = 'hasBeenAssignedTo';
export const DOC_EXPORT_INTERNAL_FORM_FIELD_REQUEST_TYPE = 'requestType';
export const DOC_EXPORT_INTERNAL_FORM_FIELD_PARTNER = 'partner';
export const DOC_EXPORT_INTERNAL_FORM_FIELD_VEHICLE_FAMILY = 'vehicleFamily';
export const DOC_EXPORT_INTERNAL_FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM = 'nReferenceCausingProblem';
export const DOC_EXPORT_INTERNAL_FORM_FIELD_TYPE = 'type';
export const DOC_EXPORT_INTERNAL_FORM_FIELD_CHARSET = 'charset';

export const DOC_EXPORT_INTERNAL_FORM_LABEL_FROM = 'DATE_FROM';
export const DOC_EXPORT_INTERNAL_FORM_LABEL_TO = 'DATE_TO';
export const DOC_EXPORT_INTERNAL_FORM_LABEL_STATUS = 'STATUS';
export const DOC_EXPORT_INTERNAL_FORM_LABEL_IS_ASSIGNED_TO = 'IS_ASSIGNED_TO';
export const DOC_EXPORT_INTERNAL_FORM_LABEL_HAS_BEEN_ASSIGNED_TO = 'HAS_BEEN_ASSIGNED_TO';
export const DOC_EXPORT_INTERNAL_FORM_LABEL_REQUEST_TYPE = 'REQUEST_TYPE';
export const DOC_EXPORT_INTERNAL_FORM_LABEL_PARTNER = 'PARTNER';
export const DOC_EXPORT_INTERNAL_FORM_LABEL_VEHICLE_FAMILY = 'VEHICLE_FAMILY';
export const DOC_EXPORT_INTERNAL_FORM_LABEL_N_REFERENCE_CAUSING_PROBLEM = 'N_REFERENCE_CAUSING_PROBLEM';
export const DOC_EXPORT_INTERNAL_FORM_LABEL_TYPE = 'TYPE';
export const DOC_EXPORT_INTERNAL_FORM_LABEL_CHARSET = 'CHARSET';

//CL export
export const CLAIMS_EXPORT_FORM_FIELD_PFX_NUMBER = 'pfxNumber';
export const CLAIMS_EXPORT_FORM_FIELD_SFX_NUMBER = 'sfxNumber';
export const CLAIMS_EXPORT_FORM_FIELD_FROM = 'from';
export const CLAIMS_EXPORT_FORM_FIELD_TO = 'to';
export const CLAIMS_EXPORT_FORM_FIELD_ASSIGNED = 'assigned';
export const CLAIMS_EXPORT_FORM_FIELD_STATUS = 'status';
export const CLAIMS_EXPORT_FORM_FIELD_DEALER_NUMBER = 'dealerNumber';
export const CLAIMS_EXPORT_FORM_FIELD_DEALER_NAME = 'dealerName';
export const CLAIMS_EXPORT_FORM_FIELD_TYPE = 'type';
export const CLAIMS_EXPORT_FORM_FIELD_CHARSET = 'charset';

export const CLAIMS_EXPORT_FORM_LABEL_TICKET_NUMBER = 'TICKET_NUMBER';
export const CLAIMS_EXPORT_FORM_LABEL_FROM = 'DATE_FROM';
export const CLAIMS_EXPORT_FORM_LABEL_TO = 'DATE_TO';
export const CLAIMS_EXPORT_FORM_LABEL_ASSIGNED = 'ASSIGNED';
export const CLAIMS_EXPORT_FORM_LABEL_STATUS = 'STATUS';
export const CLAIMS_EXPORT_FORM_LABEL_DEALER_NUMBER = 'DEALER_NUMBER';
export const CLAIMS_EXPORT_FORM_LABEL_DEALER_NAME = 'DEALER_NAME';
export const CLAIMS_EXPORT_FORM_LABEL_TYPE = 'TYPE';
export const CLAIMS_EXPORT_FORM_LABEL_CHARSET = 'CHARSET';


//export
export const XLS = 'XLS';
export const CSV = 'CSV';

//charset
export const WINDOWS_1252 = 'WINDOWS_1252';
export const ISO8859_2 = 'ISO8859_2';
export const UTF_8_BOM = 'UTF_8_BOM';
export const UTF_8 = 'UTF_8';

export const ticketExportTypes = [
    XLS,
    CSV,
];

export const ticketCharsetTypes = [
    WINDOWS_1252,
    ISO8859_2,
    UTF_8,
    UTF_8_BOM,
];
