import React from 'react';
import {FormattedMessage, FormattedDate, FormattedTime} from "react-intl";
import styled from "styled-components";
import {
    FORM_STATUS_HISTORY_SECTION
} from "../../constants/formConstants";
import formStatesMessages from "../../intl/common/formStatesMessages";
import formStatusHistoryMessages from "../../intl/common/formStatusHistoryMessages";
import {formValueSelector} from "redux-form";
import {connect} from 'react-redux';
import ValidFormattedMessage from "./ValidFormattedMessage";
import commonMessages from "../../intl/common/commonMessages";
import {moduleRoles} from '../../utils/roles';

const StyledTd = styled.td`
    &&{
      padding: 0.25rem;
      vertical-align: middle;
    };
`;

const TicketStatusHistory = ({history, roles, isInternal}) => {

    const canReadHistoryRecord = (item) => {
        if (moduleRoles.isEditorDocumentation(roles) || moduleRoles.isSGEditorDocumentation(roles) ||
            moduleRoles.isEditorOrder(roles) || moduleRoles.isSGEditorOrder(roles) || !item.solutionGroup) {
            return true;
        }
        return item.solutionGroup.canForwardToDealer;
    }

    const readableHistory = history ? history.filter(item => canReadHistoryRecord(item)) : null;
    const isInSolutionGroupFlow = (readableHistory || []).some(item => !!item.toSolutionGroup);

    return (
        <>
            {(readableHistory && readableHistory.length > 0) &&
            <div className="pt-5 d-flex justify-content-center">
                <div className="col-lg-12 col-xl-8">
                    <div className="card">
                        <h5 className="card-header text-center">
                            <FormattedMessage {...formStatesMessages.STATUS_HISTORY}/>
                        </h5>
                        <div className="table-responsive">
                            <table className="table mb-0">
                                <tbody>
                                    <tr className="card-header">
                                        <StyledTd><FormattedMessage {...formStatusHistoryMessages.STATUS}/></StyledTd>
                                        {!isInternal && <StyledTd><FormattedMessage {...formStatusHistoryMessages.BY}/></StyledTd>}
                                        {isInSolutionGroupFlow && <StyledTd><FormattedMessage {...formStatusHistoryMessages.FROM}/></StyledTd>}
                                        <StyledTd><FormattedMessage {...formStatusHistoryMessages.IPN}/></StyledTd>
                                        <StyledTd><FormattedMessage {...formStatusHistoryMessages.NAME}/></StyledTd>
                                        {isInSolutionGroupFlow && <StyledTd><FormattedMessage {...formStatusHistoryMessages.TO}/></StyledTd>}
                                        <StyledTd><FormattedMessage {...formStatusHistoryMessages.DATE_TIME}/></StyledTd>
                                    </tr>
                                {readableHistory && readableHistory.map((row, index) =>
                                    <tr key={index}>
                                        <StyledTd><ValidFormattedMessage message={row.status}
                                                                         intlMessages={formStatesMessages}/></StyledTd>
                                        {!isInternal && <StyledTd><ValidFormattedMessage message={row.isDealer ? 'DEALER' : 'EDITOR'}
                                                                         intlMessages={commonMessages}/></StyledTd>}
                                        {isInSolutionGroupFlow && <StyledTd>{row.solutionGroup ? row.solutionGroup.name : 'Dealer'}</StyledTd>}
                                        <StyledTd>{row.creatorIpn}</StyledTd>
                                        <StyledTd>{row.creatorName}</StyledTd>
                                        {isInSolutionGroupFlow && <StyledTd>{row.toSolutionGroup ? row.toSolutionGroup.name : 'Dealer'}</StyledTd>}
                                        <StyledTd><FormattedDate value={new Date(row.created)}/> <FormattedTime
                                            value={new Date(row.created)}/></StyledTd>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

const mapStateToProps = (state, props) => ({
    history: formValueSelector(props.form)(state, `${FORM_STATUS_HISTORY_SECTION}`),
    roles: state.profile.userDetail.roles,
});

export default connect(mapStateToProps, null)(TicketStatusHistory);
