import get from 'get-value';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const docExportDealers = (state = {
    isLoadingDealer: false,
    dealers: [],
    correlationId: null,
}, action) => {
    switch (action.type) {
        case techDocWebsocketCommands.TECH_DOC_DEALERS_GET: {
            return Object.assign({}, state, {isLoadingDealer: true});
        }
        case techDocWebsocketCommands.TECH_DOC_DEALERS_SEND: {
            const dealers = get(action, 'payload.dealers', {default: []});
            return Object.assign({}, state, {
                isLoadingDealer: false,
                dealers,
            });
        }
        case techDocWebsocketCommands.TECH_DOC_DEALERS_SEND_ERROR: {
            return Object.assign({}, state, {isLoadingDealer: false});
        }
        default:
            return state;
    }
};
