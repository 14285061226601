import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TABLE_NO_DATA: {
        id: 'common.table.no.data',
        defaultMessage: 'No data found'
    },
    TABLE_PAGE: {
        id: 'common.table.page',
        defaultMessage: 'Page'
    },
    TABLE_OF: {
        id: 'common.table.of',
        defaultMessage: 'of'
    },
    TABLE_ROWS: {
        id: 'common.table.rows',
        defaultMessage: 'rows'
    },
    TABLE_TOOLS: {
        id: 'common.table.tools',
        defaultMessage: 'tools'
    }
});

export default messages;
