import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { removeAlert} from '../../../actions/alertsActions';
import AlertItem from './AlertItem';

function Alert(props) {
    const { alerts: { list }, style, removeAlert } = props;
    if (list) {
        return (
            <>
                <div style={style}>
                    {
                        list.map(({ text, type, id, details, values }) =>
                            <AlertItem text={text} type={type} id={id} key={id} remove={removeAlert}
                                       details={details}
                                       values={values}/>)
                    }
                </div>
            </>
        );
    }
}

Alert.propTypes = {
    alerts: PropTypes.object.isRequired,
    style: PropTypes.object,
    removeAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    alerts: state.alerts
});

export default connect(mapStateToProps, {
    removeAlert
})(Alert);
