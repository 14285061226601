import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const fetchInternalTechDocSearch = (pageSize, sorted, cursor, isNextFromPivot, searchOption, filtered) => (dispatch) => {
    console.log('filtered');
    console.log(filtered);
    dispatch({
        type: techDocWebsocketCommands.TECH_DOC_INTERNAL_TICKET_SEARCH_GET,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_INTERNAL_TICKET_SEARCH_GET,
        payload: {
            lastCommand: Date.now(),
            pageSize,
            sorted,
            cursor,
            isNextFromPivot,
            searchOption,
            filtered
        },
    });
};

// export const fetchTechDocLastEditor = () => (dispatch) => {
//     dispatch({
//         type: techDocWebsocketCommands.TECH_DOC_FULL_TEXT_SEARCH_LAST_EDITORS_GET,
//         techDocWebsocket: true,
//         command: techDocWebsocketCommands.TECH_DOC_FULL_TEXT_SEARCH_LAST_EDITORS_GET,
//         payload: {lastCommand: Date.now()},
//     });
// };
