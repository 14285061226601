import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {validFiveCharLength, validSevenCharLength, validSixCharLength} from '../../../../../common/validationCommons';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import formMessages from '../../../../../../intl/claims/formMessages';
import {
    FORM_FIELD_CLAIM_RELATED,
    FORM_FIELD_INVOICE,
    FORM_FIELD_CONTAINER,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_TAKING_BACK,
    FORM_LABEL_CLAIM_RELATED,
    FORM_LABEL_INVOICE,
    FORM_LABEL_CONTAINER,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_TAKING_BACK,
} from '../../../../../../constants/formConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const ReturnClaimSection = (props) => {
    const {readOnly, userRights, reqFields} = props;

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.PROBLEM_DESCRIPTION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row pb-2">
                    <div className="col-xl-3 col-lg-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_CLAIM_RELATED]}
                                                  field={FORM_FIELD_CLAIM_RELATED}
                                                  maxLength={5}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_CLAIM_RELATED)}
                                                  validations={[validFiveCharLength]}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_TAKING_BACK]}
                                                  field={FORM_FIELD_TAKING_BACK}
                                                  maxLength={15}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_TAKING_BACK)}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row pb-2">
                    <div className="col-xl-3 col-lg-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_INVOICE]}
                                                  field={FORM_FIELD_INVOICE}
                                                  maxLength={6}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_INVOICE)}
                                                  validations={[validSixCharLength]}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_CONTAINER]}
                                                  field={FORM_FIELD_CONTAINER}
                                                  maxLength={7}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_CONTAINER)}
                                                  validations={[validSevenCharLength]}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row pb-2">
                    <div className="col-12">
                        <TextareaFieldWithValidation label={formMessages[FORM_LABEL_PROBLEM_DESCRIPTION]}
                                                     field={FORM_FIELD_PROBLEM_DESCRIPTION}
                                                     isDisabled={!userRights.canEditProblemDescText || readOnly}
                                                     rows={6}
                                                     isRequired={reqFields.includes(FORM_FIELD_PROBLEM_DESCRIPTION)}/>
                    </div>
                </div>
            </StyledDiv>
        </>
    )
}

export default (ReturnClaimSection);
