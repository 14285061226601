import React, {Component} from 'react';
import {
    change,
    clearFields,
    Field,
    FormSection,
    formValueSelector,
    getFormInitialValues,
} from 'redux-form'
import {FormattedMessage} from "react-intl";
import {connect} from 'react-redux';
import {
    CHECKBOX_LABEL_BVM_CONNECTION,
    FORM_CHECKBOX_BVM_CONNECTION,
    FORM_CHECKBOX_NEW_OVAL_PLATE,
    FORM_CHECKBOX_OLD_OVAL_PLATE,
    FORM_FIELD_ENGINE_SERIAL_NUMBER,
    FORM_FIELD_ENGINE_TYPE,
    FORM_FIELD_GEARBOX_SERIAL_NUMBER,
    FORM_FIELD_GEARBOX_TYPE,
    FORM_FIELD_MANUFACTURE_DATE,
    FORM_FIELD_NEW_PLATE1,
    FORM_FIELD_NEW_PLATE10,
    FORM_FIELD_NEW_PLATE11,
    FORM_FIELD_NEW_PLATE12,
    FORM_FIELD_NEW_PLATE13,
    FORM_FIELD_NEW_PLATE14,
    FORM_FIELD_NEW_PLATE15,
    FORM_FIELD_NEW_PLATE16,
    FORM_FIELD_NEW_PLATE17,
    FORM_FIELD_NEW_PLATE2,
    FORM_FIELD_NEW_PLATE3,
    FORM_FIELD_NEW_PLATE4,
    FORM_FIELD_NEW_PLATE5,
    FORM_FIELD_NEW_PLATE6,
    FORM_FIELD_NEW_PLATE7,
    FORM_FIELD_NEW_PLATE8,
    FORM_FIELD_NEW_PLATE9,
    FORM_FIELD_OLD_PLATE1,
    FORM_FIELD_OLD_PLATE2,
    FORM_FIELD_OLD_PLATE3,
    FORM_FIELD_OLD_PLATE4,
    FORM_FIELD_OLD_PLATE5,
    FORM_FIELD_OLD_PLATE6,
    FORM_FIELD_OLD_PLATE7,
    FORM_FIELD_OLD_PLATE8,
    FORM_FIELD_REGISTRATION_NO,
    FORM_FIELD_STEERING_WHEEL,
    FORM_FIELD_CAR_MODEL,
    FORM_FIELD_VIN,
    FORM_LABEL_MANUFACTURE_DATE,
    FORM_LABEL_REGISTRATION_NO,
    FORM_LABEL_VEHICLE_MODEL,
    FORM_LABEL_VIN,
    FORM_SUPPLEMENTARY_INFORMATION_SECTION,
    FORM_VEHICLE_DETAIL_SECTION,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
    FORM_LABEL_ENGINE_TYPE_SUFFIX,
    FORM_LABEL_ENGINE_SERIAL_NUMBER_VDS,
    FORM_LABEL_GEARBOX_TYPE_SUFFIX,
    FORM_LABEL_GEARBOX_SERIAL_NUMBER_VDS,
    FORM_LABEL_STEERING_WHEEL_ON,
    FORM_FIELD_ENGINE_TYPE_SUFFIX,
    FORM_FIELD_ENGINE_SERIAL_NUMBER_VDS,
    FORM_FIELD_GEARBOX_TYPE_SUFFIX,
    FORM_FIELD_GEARBOX_SERIAL_NUMBER_VDS,
    FORM_FIELD_STEERING_WHEEL_ON,
    FORM_LABEL_INTERNAL_STATUS, FORM_FIELD_INTERNAL_STATUS,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from "../../../../../common/InputFieldWithValidation";
import buttonMessages from "../../../../../../intl/common/buttonMessages";
import {PrimaryButton} from "../../../../../common/Button";
import DatePickerComponent from "../../../../../common/datePicker/DatePickerComponent";
import Loader from "../../../../../Loader";
import NewOvalPlate from "./NewOvalPlate";
import OldOvalPlate from "./OldOvalPlate";
import get from 'get-value';
import {moduleRoles} from "../../../../../../utils/roles";
import InputSelectFieldWithValidation from "../../../../../common/InputSelectFieldWithValidation";
import {required, validVinLength} from "../../../../../common/validationCommons";
import formMessages from "../../../../../../intl/technicalDocumentations/formMessages";
import ValidFormattedMessage from "../../../../../common/ValidFormattedMessage";
import {internalStatuses} from "../../../../../../constants/Utils";
import internalStatusMessages from "../../../../../../intl/technicalDocumentations/internalStatusMessages";
import {bindActionCreators} from "redux";
import {
    bvmDataResetActionCreator,
    bvmDataSetActionCreator,
    fetchBvmInformationActionCreator
} from "../../../../../../actions/orders/bvmData";
import * as moment from "moment";
import ConfirmationDialog from "../../../../../common/modal/ConfirmationDialog";
import BorderRadius from '../../../../../common/styled/BorderRadius';
import displayField from '../../../../../../constants/displayField';
import Div from '../../../../../common/styled/HideAbleDiv';

class VehicleDetailSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenManualRewriteDialog: false,
            isOpenBvmRewriteDialog: false
        }
    }

    componentWillUnmount() {
        this.props.bvmDataResetActionCreator();
    }

    handleBvmLoad = (e) => {
        const {vin} = this.props;
        e.preventDefault();
        this.props.fetchBvmInformationActionCreator(vin);
    };

    closeManualRewriteModal = () => {
        this.setState({isOpenManualRewriteDialog: false,});
        this.props.change(`${FORM_VEHICLE_DETAIL_SECTION}.${FORM_CHECKBOX_BVM_CONNECTION}`, false);
    };

    closeBvmRewriteModal = () => {
        this.setState({isOpenBvmRewriteDialog: false,});
        this.props.change(`${FORM_VEHICLE_DETAIL_SECTION}.${FORM_CHECKBOX_BVM_CONNECTION}`, true);
    };

    saveManualRewriteModal = () => {
        this.setState({isOpenManualRewriteDialog: false,});
        this.props.changeVehDetail(`ovalPlate.${FORM_CHECKBOX_NEW_OVAL_PLATE}`, true);
        this.props.changeVehDetail(`ovalPlate.${FORM_CHECKBOX_OLD_OVAL_PLATE}`, false);
        this.props.clearFields(FORM_FIELD_MANUFACTURE_DATE,
            FORM_FIELD_CAR_MODEL,
            FORM_CHECKBOX_OLD_OVAL_PLATE,
            `ovalPlate.${FORM_FIELD_NEW_PLATE1}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE2}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE3}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE4}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE5}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE6}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE7}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE8}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE9}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE10}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE11}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE12}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE13}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE14}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE15}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE16}`,
            `ovalPlate.${FORM_FIELD_NEW_PLATE17}`,
            `ovalPlate.${FORM_FIELD_OLD_PLATE1}`,
            `ovalPlate.${FORM_FIELD_OLD_PLATE2}`,
            `ovalPlate.${FORM_FIELD_OLD_PLATE3}`,
            `ovalPlate.${FORM_FIELD_OLD_PLATE4}`,
            `ovalPlate.${FORM_FIELD_OLD_PLATE5}`,
            `ovalPlate.${FORM_FIELD_OLD_PLATE6}`,
            `ovalPlate.${FORM_FIELD_OLD_PLATE7}`,
            `ovalPlate.${FORM_FIELD_OLD_PLATE8}`
        );
        this.props.bvmDataResetActionCreator();
    };

    saveBvmRewriteModal = () => {
        this.setState({isOpenBvmRewriteDialog: false,});
        this.props.bvmDataResetActionCreator();
    };

    clearBvmFields = (e) => {
        const {bvmLoaded} = this.props;
        if (e.target.checked) {
            this.setState({isOpenManualRewriteDialog: true});
        } else if (!e.target.checked && bvmLoaded) {
            this.setState({isOpenBvmRewriteDialog: true});
        }
    };

    fillVehicleFromBvm = () => {
        const {bvm, form} = this.props;
        this.props.changeVehDetail(FORM_FIELD_MANUFACTURE_DATE, get(bvm, 'bvmData.manufacturingDate', '') ? moment(bvm.bvmData.manufacturingDate).format("YYYY-MM-DD") : '');
        this.props.changeVehDetail(FORM_FIELD_CAR_MODEL, get(bvm, 'bvmData.model', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE1}`, get(bvm, 'bvmData.vehicleType', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE2}`, get(bvm, 'bvmData.manufacturingNumber', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE3}`, get(bvm, 'bvmData.equipmentLevel', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE4}`, get(bvm, 'bvmData.additionalOptionalEquipment1', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE5}`, get(bvm, 'bvmData.additionalOptionalEquipment2', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE6}`, get(bvm, 'bvmData.paintCode', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE7}`, get(bvm, 'bvmData.seatTrimCode', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE8}`, get(bvm, 'bvmData.interiorTrimCode', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE9}`, get(bvm, 'bvmData.technicalCountryCode', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE10}`, get(bvm, 'bvmData.suspensionType', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE11}`, get(bvm, 'bvmData.countryClimateType', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE12}`, get(bvm, 'bvmData.airConditioningType', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE13}`, get(bvm, 'bvmData.engineFiltrationType', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE14}`, get(bvm, 'bvmData.steeringType', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE15}`, get(bvm, 'bvmData.dampersType', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE16}`, get(bvm, 'bvmData.trimSystem', ''));
        this.props.changeVehDetail(`ovalPlate.${FORM_FIELD_NEW_PLATE17}`, get(bvm, 'bvmData.braking', ''));

        if (form === SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM || form === SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM) {
            this.props.changeSuppInfo(FORM_FIELD_ENGINE_TYPE, get(bvm, 'bvmData.engineType', ''));
            this.props.changeSuppInfo(FORM_FIELD_ENGINE_SERIAL_NUMBER, get(bvm, 'bvmData.engineNumber', ''));
            this.props.changeSuppInfo(FORM_FIELD_GEARBOX_TYPE, get(bvm, 'bvmData.gearboxType', ''));
            this.props.changeSuppInfo(FORM_FIELD_GEARBOX_SERIAL_NUMBER, get(bvm, 'bvmData.gearboxNumber', ''));
            this.props.changeSuppInfo(FORM_FIELD_STEERING_WHEEL, get(bvm, 'bvmData.steeringWheel', ''));
        }
        this.props.bvmDataSetActionCreator();
    };

    renderOvalPlates = () => {
        const {readOnly, userRights, form, vehicleDetailsRawData} = this.props;
        if (get(vehicleDetailsRawData, 'ovalPlate.newOvalPlate')) {
            return (
                <FormSection name="ovalPlate" className="form-row">
                    <NewOvalPlate form={form} userRights={userRights} readOnly={readOnly} checkboxDisabled/>
                </FormSection>
            )
        } else if (get(vehicleDetailsRawData, 'ovalPlate.oldOvalPlate')) {
            return (
                <FormSection name="ovalPlate" className="form-row">
                    <OldOvalPlate form={form} userRights={userRights} readOnly={readOnly} checkboxDisabled/>
                </FormSection>
            )
        } else {
            return (
                <FormSection name="ovalPlate" className="form-row">
                    <NewOvalPlate form={form} userRights={userRights} readOnly={readOnly}/>
                    <OldOvalPlate form={form} userRights={userRights} readOnly={readOnly}/>
                </FormSection>
            )
        }
    }

    render() {
        const {bvmConnection, readOnly, bvm, userRights, vehicleDetailsRawData, roles, initialValues, reqFields, bvmLoaded, form} = this.props;
        if (bvm.bvmSet) {
            this.fillVehicleFromBvm();
        }

        const display = (field) => displayField(field, FORM_VEHICLE_DETAIL_SECTION, form);

        return (
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.VEHICLE_DETAIL_SECTION}/>
                </h3>
                <BorderRadius size={5} className="border border-secondary px-4 py-2 bg-white">
                    <div className="form-row pb-2">
                        <div className="col-12 pb-2">
                            <div className="form-row align-items-end">
                                <div className="col-md-12 col-lg-8 col-xl-4">
                                    <InputFieldWithValidation label={formMessages[FORM_LABEL_VIN]}
                                                              field={FORM_FIELD_VIN}
                                                              maxLength={17}
                                                              isDisabled={ !userRights.canEditBvm || !userRights.canEditVehDetails || readOnly || bvmLoaded || get(vehicleDetailsRawData, 'bvmLoaded', {default: false})}
                                                              isRequired={reqFields.includes(FORM_FIELD_VIN)}
                                                              validations={[validVinLength]}
                                                              isSmall/>
                                </div>
                                <label className="pl-2 pt-1">
                                    <Field
                                        name={FORM_CHECKBOX_BVM_CONNECTION}
                                        component="input"
                                        type="checkbox"
                                        disabled={!userRights.canEditBvm || !userRights.canEditVehDetails || readOnly}
                                        onChange={this.clearBvmFields}
                                    />{' '}
                                    <ValidFormattedMessage message={CHECKBOX_LABEL_BVM_CONNECTION}
                                                           intlMessages={formMessages}/>
                                </label>
                                {userRights.canEditBvm && !readOnly && userRights.canEditVehDetails &&
                                <>
                                    <div className="pl-2 pt-1">
                                        <PrimaryButton type="button" className="btn"
                                                       onClick={this.handleBvmLoad}
                                                       disabled={!bvmConnection || bvmLoaded}>
                                            <FormattedMessage {...buttonMessages.BVM_LOAD}/>
                                        </PrimaryButton>
                                    </div>
                                    <div className="pl-2 py-auto">
                                        {bvm.isBvmLoading &&
                                        <Loader isSmall/>
                                        }
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <Field name={FORM_FIELD_MANUFACTURE_DATE} component={DatePickerComponent}
                                   label={formMessages[FORM_LABEL_MANUFACTURE_DATE]}
                                   validate={reqFields.includes(FORM_FIELD_MANUFACTURE_DATE) ? [required] : undefined}
                                   isRequired={reqFields.includes(FORM_FIELD_MANUFACTURE_DATE)}
                                   isDisabled={!!bvmConnection || !userRights.canEditBvm || !userRights.canEditVehDetails || readOnly}
                                   parse={(e)=> e ? moment(e).format("YYYY-MM-DD") : null}
                            />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_VEHICLE_MODEL]}
                                                      field={FORM_FIELD_CAR_MODEL}
                                                      maxLength={30}
                                                      isRequired={reqFields.includes(FORM_FIELD_CAR_MODEL)}
                                                      isDisabled={!!bvmConnection || !userRights.canEditBvm || !userRights.canEditVehDetails || readOnly}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_REGISTRATION_NO]}
                                                      field={FORM_FIELD_REGISTRATION_NO}
                                                      maxLength={10}
                                                      isDisabled={!moduleRoles.isDealerDocumentation(roles) || !userRights.canEditVehDetails || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_REGISTRATION_NO)}
                                                      isSmall/>
                        </div>
                    </div>
                    <div className="form-row mt-4">
                        {this.renderOvalPlates()}
                    </div>
                    <div className="form-row">
                        {userRights.canViewEditorReply && <div className="col-xl-4 col-lg-7 col-md-12 pb-1">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_INTERNAL_STATUS]}
                                                            field={FORM_FIELD_INTERNAL_STATUS}
                                                            options={get(internalStatuses, `${get(initialValues, 'ticketSection.group', {default: 'X'})}`, {default: []})}
                                                            intlMessages={internalStatusMessages}
                                                            isDisabled={!userRights.canEditInternalStatus || readOnly}
                                                            isRequired={reqFields.includes(FORM_FIELD_INTERNAL_STATUS)}
                            />
                        </div>}
                    </div>
                    <div className="form-row">
                        <Div display={display(FORM_FIELD_ENGINE_TYPE_SUFFIX)} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_ENGINE_TYPE_SUFFIX]}
                                                      field={FORM_FIELD_ENGINE_TYPE_SUFFIX}
                                                      isDisabled={readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_ENGINE_TYPE_SUFFIX)}
                                                      isSmall

                            />
                        </Div>
                        <Div display={display(FORM_FIELD_ENGINE_SERIAL_NUMBER_VDS)} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_ENGINE_SERIAL_NUMBER_VDS]}
                                                      field={FORM_FIELD_ENGINE_SERIAL_NUMBER_VDS}
                                                      isDisabled={readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_ENGINE_SERIAL_NUMBER_VDS)}
                                                      isSmall/>
                        </Div>
                        <Div display={display(FORM_FIELD_GEARBOX_TYPE_SUFFIX)} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_GEARBOX_TYPE_SUFFIX]}
                                                      field={FORM_FIELD_GEARBOX_TYPE_SUFFIX}
                                                      isDisabled={readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_GEARBOX_TYPE_SUFFIX)}
                                                      isSmall
                            />
                        </Div>
                        <Div display={display(FORM_FIELD_GEARBOX_SERIAL_NUMBER_VDS)} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_GEARBOX_SERIAL_NUMBER_VDS]}
                                                      field={FORM_FIELD_GEARBOX_SERIAL_NUMBER_VDS}
                                                      isDisabled={readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_GEARBOX_SERIAL_NUMBER_VDS)}
                                                      isSmall
                            />
                        </Div>
                        <Div display={display(FORM_FIELD_STEERING_WHEEL_ON)} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_STEERING_WHEEL_ON]}
                                                      field={FORM_FIELD_STEERING_WHEEL_ON}
                                                      isDisabled={readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_STEERING_WHEEL_ON)}
                                                      isSmall/>
                        </Div>
                    </div>
                </BorderRadius>
                <ConfirmationDialog show={this.state.isOpenManualRewriteDialog}
                                    closeModal={this.closeManualRewriteModal}
                                    saveModal={this.saveManualRewriteModal}
                                    actionIdentifier="rewrite the form by the data from BVM"/>
                <ConfirmationDialog show={this.state.isOpenBvmRewriteDialog}
                                    closeModal={this.closeBvmRewriteModal}
                                    saveModal={this.saveBvmRewriteModal}
                                    actionIdentifier="manually rewrite data loaded from BVM"/>
            </>
        )
    }
}

const mapStateToProps = (state, props) => ({
    roles: state.profile.userDetail.roles,
    initialValues: getFormInitialValues(props.form)(state),
    vin: formValueSelector(props.form)(state, `${FORM_VEHICLE_DETAIL_SECTION}.${FORM_FIELD_VIN}`),
    bvmConnection: formValueSelector(props.form)(state, `${FORM_VEHICLE_DETAIL_SECTION}.${FORM_CHECKBOX_BVM_CONNECTION}`),
    vehicleDetailsRawData: state.techDocTicket.vehicleDetail,
    bvmLoaded: state.bvmData.bvmLoaded,
    bvm: state.bvmData,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            bvmDataSetActionCreator,
            fetchBvmInformationActionCreator,
            bvmDataResetActionCreator
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
    changeVehDetail: (name, value) => dispatch(change(props.form, FORM_VEHICLE_DETAIL_SECTION + "." + name, value)),
    changeSuppInfo: (name, value) => dispatch(change(props.form, FORM_SUPPLEMENTARY_INFORMATION_SECTION + "." + name, value)),
    clearFields: (...fields) => dispatch(clearFields(props.form, false, false, ...fields.map(e => FORM_VEHICLE_DETAIL_SECTION + "." + e))),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetailSection);
