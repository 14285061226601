import React from 'react';
import {Flex} from '../../common/StyledComponents';
import styled from 'styled-components';
import {PrimaryButton} from '../../common/Button';

const Button = styled.button`
  background: yellow;
  border-color: yellow;
`;

const HelpLang = ({changeLanguage, lang}) => {
    const langLabel = lang === 'fr' ? 'Français' : 'English';

    return <Flex justifyContent={'flex-end'}>
        <div className="dropdown">
            <PrimaryButton className="btn dropdown-toggle" type="button" id="dropdownMenu2"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {langLabel}
            </PrimaryButton>
            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                <button onClick={() => changeLanguage('en')} className="dropdown-item" type="button">English</button>
                <button onClick={() => changeLanguage('fr')} className="dropdown-item" type="button">Français</button>
            </div>
        </div>
    </Flex>
}

export default HelpLang;
