import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {confirmDocPopUpModal, fetchDocPopUp, openDocPopUpModal} from '../../actions/techDoc/actionDocPopUpModal';
import PropTypes from 'prop-types';
import RecurrentProblemDocPage from './recurrentProblem/RecurrentProblemTechDocPage';
import {
    DOCUMENTATIONS_EXPORT_PATH,
    DOCUMENTATIONS_FULL_TEXT_SEARCH_PATH,
    DOCUMENTATIONS_HISTORY_PATH,
    DOCUMENTATIONS_INTERNAL_TICKET,
    DOCUMENTATIONS_INTERNAL_TICKET_EXPORT_PATH,
    DOCUMENTATIONS_INTERNAL_TICKET_HISTORY_PATH,
    DOCUMENTATIONS_INTERNAL_TICKET_LIST_PATH,
    DOCUMENTATIONS_INTERNAL_TICKET_SEARCH_PATH,
    DOCUMENTATIONS_LIST_PATH,
    DOCUMENTATIONS_MANAGEMENT_GROUP_PATH,
    DOCUMENTATIONS_NEW_CODED_PARTS_KEYS,
    DOCUMENTATIONS_NEW_INTERNAL_TICKET,
    DOCUMENTATIONS_NEW_OVAL_PLATE_ORDER,
    DOCUMENTATIONS_NEW_REQUEST_FOR_NOT_CONFORM_REFERENCE,
    DOCUMENTATIONS_NEW_REQUEST_FOR_NOT_FOUND_REFERENCE,
    DOCUMENTATIONS_NEW_UNKNOWN_PART_OPENING_REQUEST,
    DOCUMENTATIONS_PATH,
    DOCUMENTATIONS_RECURRENT_PROBLEM_PATH,
} from '../../routes/paths';
import ListDocPage from './list/ListTechDocPage';
import HistoryDocPage from './history/HistoryTechDocPage';
import NewTechDocTicketPage from './ticket/NewTechDocTicketPage';
import SearchEngineDocPage from './fullTextSearch/FullTextSearchTechDocPage';
import ExportDocPage from './export/ExportTechDocPage';
import {
    CODED_PARTS_KEYS_FORM, INTERNAL_TICKET_FORM,
    OVAL_PLATE_ORDER_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
    UNKNOWN_PART_OPENING_REQUEST_FORM,
} from '../../constants/formConstants';
import get from 'get-value';
import ViewTechDocTicketPage from './ticket/ViewTechDocTicketPage';
import DocPopUpModal from './DocPopUpModal';
import EditTechDocTicketPage from './ticket/EditTechDocTicketPage';
import Loader from '../Loader';
import {domains} from '../../constants/Utils';
import NotFoundPage from '../NotFoundPage';
import NewTechDocInternalTicketPage from './internalTicket/NewTechDocInternalTicketPage';
import ListTechDocInternalTicketPage from './internalTicketList/ListTechDocInternalTicketPage';
import HistoryTechDocInternalTicketPage from './internalTicketHistory/HistoryTechDocInternalTicketPage';
import ViewTechDocInternalTicketPage from './internalTicket/ViewTechDocInternalTicketPage';
import EditTechDocInternalTicketPage from './internalTicket/EditTechDocInternalTicketPage';
import InternalTicketSearchTechDocPage from './internalTicketSearch/InternalTicketSearchTechDocPage';
import InternalTicketExportDocPage from './internalTicketExport/InternalTicketExportTechDocPage';
import ManagementGroupTechDocPage from "./managementGroup/ManagementGroupTechDocPage";

const domain = domains.TECHNICAL_DOCUMENTATION;

class TechnicalDocumentation extends Component {
    componentDidMount() {
        if (!this.props.isConfirm && this.props.userDetail.dealerNumber && this.props.userDetail.dealerNumber !== '' &&  get(this.props.userDetail, `roles.${domain}.dealer`)) {
            this.props.fetchDocPopUp(this.props.group);
            this.props.openDocPopUpModal();
        }
    }

    render() {
        const {userDetail, isOpen, confirmDocPopUpModal, isConfirm, isLoadingPopUp} = this.props;
        const dealer = get(userDetail, `roles.${domain}.dealer`);
        if (dealer && !isConfirm) {
            return isLoadingPopUp ? <Loader/> : <DocPopUpModal show={isOpen}
                                                               handleClose={confirmDocPopUpModal}/>;
        }
        return (
            <Switch>
                <Route exact path={DOCUMENTATIONS_PATH}
                       render={() => dealer ? <Redirect to={DOCUMENTATIONS_RECURRENT_PROBLEM_PATH}/> :
                           <Redirect to={DOCUMENTATIONS_LIST_PATH}/>}/>
                <Route exact path={DOCUMENTATIONS_LIST_PATH}
                       component={ListDocPage}/>
                <Route exact path={DOCUMENTATIONS_PATH + '/:view(view)/:ticketUuid(.*)'}
                       component={ViewTechDocTicketPage}/>
                <Route exact path={DOCUMENTATIONS_PATH + '/:edit(edit)/:ticketUuid(.*)'}
                       component={EditTechDocTicketPage}/>
                <Route exact path={DOCUMENTATIONS_HISTORY_PATH}
                       component={HistoryDocPage}/>
                <Route exact path={DOCUMENTATIONS_FULL_TEXT_SEARCH_PATH}
                       component={SearchEngineDocPage}/>
                <Route exact path={DOCUMENTATIONS_EXPORT_PATH}
                       component={ExportDocPage}/>
                <Route exact path={DOCUMENTATIONS_NEW_REQUEST_FOR_NOT_FOUND_REFERENCE}
                       render={() => <NewTechDocTicketPage
                           formType={SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM}/>}/>
                <Route exact path={DOCUMENTATIONS_NEW_CODED_PARTS_KEYS}
                       render={() => <NewTechDocTicketPage formType={CODED_PARTS_KEYS_FORM}/>}/>
                <Route exact path={DOCUMENTATIONS_NEW_OVAL_PLATE_ORDER}
                       render={() => <NewTechDocTicketPage formType={OVAL_PLATE_ORDER_FORM}/>}/>
                <Route exact path={DOCUMENTATIONS_NEW_REQUEST_FOR_NOT_CONFORM_REFERENCE}
                       render={() => <NewTechDocTicketPage
                           formType={SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM}/>}/>
                <Route exact path={DOCUMENTATIONS_NEW_UNKNOWN_PART_OPENING_REQUEST}
                       render={() => <NewTechDocTicketPage formType={UNKNOWN_PART_OPENING_REQUEST_FORM}/>}/>
                <Route exact path={DOCUMENTATIONS_NEW_INTERNAL_TICKET}
                       render={() => <NewTechDocInternalTicketPage formType={INTERNAL_TICKET_FORM}/>}/>
                <Route exact path={DOCUMENTATIONS_INTERNAL_TICKET + '/:view(view)/:ticketUuid(.*)'}
                       component={ViewTechDocInternalTicketPage}/>
                <Route exact path={DOCUMENTATIONS_INTERNAL_TICKET + '/:edit(edit)/:ticketUuid(.*)'}
                       component={EditTechDocInternalTicketPage}/>
                <Route exact path={DOCUMENTATIONS_INTERNAL_TICKET_LIST_PATH}
                       component={ListTechDocInternalTicketPage}/>
                <Route exact path={DOCUMENTATIONS_INTERNAL_TICKET_HISTORY_PATH}
                       component={HistoryTechDocInternalTicketPage}/>
                <Route exact path={DOCUMENTATIONS_INTERNAL_TICKET_SEARCH_PATH}
                       component={InternalTicketSearchTechDocPage}/>
                <Route exact path={DOCUMENTATIONS_INTERNAL_TICKET_EXPORT_PATH}
                       component={InternalTicketExportDocPage}/>
                <Route exact path={DOCUMENTATIONS_RECURRENT_PROBLEM_PATH}
                       component={RecurrentProblemDocPage}/>
                <Route exact path={DOCUMENTATIONS_MANAGEMENT_GROUP_PATH}
                       component={ManagementGroupTechDocPage}/>
                <Route component={NotFoundPage}/>
            </Switch>
        );
    }
}

TechnicalDocumentation.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isConfirm: PropTypes.bool.isRequired,
    openDocPopUpModal: PropTypes.func.isRequired,
    confirmDocPopUpModal: PropTypes.func.isRequired,
    userDetail: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    isOpen: state.docPopUpModal.isOpen,
    isConfirm: state.docPopUpModal.isConfirm,
    userDetail: state.profile.userDetail,
    isLoadingPopUp: state.docPopUpModal.isLoading,
    group: state.profile.userDetail.group,
});

export default withRouter(connect(mapStateToProps, {
    openDocPopUpModal,
    confirmDocPopUpModal,
    fetchDocPopUp
})(TechnicalDocumentation));
