import {defineMessages} from 'react-intl';

const messages = defineMessages({
    UPLOADING_ATTACHMENTS: {
        id: 'techDoc.ticket.attachments.uploadingAttachments',
        defaultMessage: 'Ticket was saved, uploading attachments...'
    },
    TITLE: {
        id: 'techDoc.ticket.title',
        defaultMessage: 'Fulltext Search'
    },
    TICKET: {
        id: 'techDoc.ticket.ticket',
        defaultMessage: 'Ticket'
    },
    WAIT_DIC: {
        id: 'techDoc.ticket.dic.loadingMessage',
        defaultMessage: 'Resolving DIC response, please wait...'
    },
    TICKET_SECTION: {
        id: 'techDoc.ticket.header.dealerInformation',
        defaultMessage: 'Dealer information'
    },
    DEALER_NAME: {
        id: 'techDoc.ticket.label.dealerName',
        defaultMessage: 'Dealer Name'
    },
    DEALER_NUMBER: {
        id: 'techDoc.ticket.label.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    CLIENT_ID: {
        id: 'techDoc.ticket.label.clientId',
        defaultMessage: 'Client ID'
    },
    REGION: {
        id: 'techDoc.ticket.label.region',
        defaultMessage: 'Region'
    },
    EMAIL: {
        id: 'techDoc.ticket.label.email',
        defaultMessage: 'E-mail'
    },
    EDITOR_IPN: {
        id: 'techDoc.ticket.label.lastEditor',
        defaultMessage: 'Last Editor'
    },
    TELEPHONE_NUMBER: {
        id: 'techDoc.ticket.label.telephoneNumber',
        defaultMessage: 'Telephone number'
    },
    CREATION_DATE: {
        id: 'techDoc.ticket.label.creationDate',
        defaultMessage: 'Creation Date'
    },
    SUPPLEMENTARY_INFORMATION_SECTION: {
        id: 'techDoc.ticket.header.supplementaryInformationSection',
        defaultMessage: 'Supplementary Information'
    },
    SUPPLEMENTARY_INFORMATION: {
        id: 'techDoc.ticket.label.supplementaryInformation',
        defaultMessage: 'Supplementary Information'
    },
    ENGINE_TYPE: {
        id: 'techDoc.ticket.label.engineTypeAndSuffix',
        defaultMessage: 'Engine Type & Suffix'
    },
    ENGINE_SERIAL_NUMBER: {
        id: 'techDoc.ticket.label.engineSerialNumber',
        defaultMessage: 'Engine serial number'
    },
    GEARBOX_TYPE: {
        id: 'techDoc.ticket.label.gearboxType',
        defaultMessage: 'Gearbox Type & Suffix'
    },
    GEARBOX_SERIAL_NUMBER: {
        id: 'techDoc.ticket.label.gearboxSerialNumber',
        defaultMessage: 'Gearbox serial number'
    },
    ABS: {
        id: 'techDoc.ticket.label.abs',
        defaultMessage: 'ABS'
    },
    POWER_STEERING: {
        id: 'techDoc.ticket.label.powerSteering',
        defaultMessage: 'Power Steering'
    },
    AIR_CONDITIONING: {
        id: 'techDoc.ticket.label.airConditioning',
        defaultMessage: 'Air conditioning'
    },
    STEERING_WHEEL: {
        id: 'techDoc.ticket.label.steeringWheel',
        defaultMessage: 'Steering Wheel on'
    },
    PART_DETAIL_SECTION: {
        id: 'techDoc.ticket.header.partDetailSection',
        defaultMessage: 'Part Detail'
    },
    N_REFERENCE_CAUSING_PROBLEM: {
        id: 'techDoc.ticket.label.nReferenceCausingProblem',
        defaultMessage: 'N° Reference causing problem'
    },
    N_REFERENCE_FOR_REPLACEMENT: {
        id: 'techDoc.ticket.label.nReferenceForReplacement',
        defaultMessage: 'N° Reference for Replacement'
    },
    CATALOG_NUMBER: {
        id: 'techDoc.ticket.label.catalogNumber',
        defaultMessage: 'Catalog Number'
    },
    PLATE_NUMBER: {
        id: 'techDoc.ticket.label.plateNumber',
        defaultMessage: 'Plate Number'
    },
    REQUESTOR_NAME: {
        id: 'techDoc.ticket.label.requestorName',
        defaultMessage: 'Requestor Name'
    },
    REQUESTOR_IPN: {
        id: 'techDoc.ticket.label.requestorIpn',
        defaultMessage: 'Requestor Ipn'
    },
    SOLUTION_GROUP: {
        id: 'techDoc.ticket.label.solutionGroup',
        defaultMessage: 'Solution Group'
    },
    PARTNER_NAME: {
        id: 'techDoc.ticket.label.partnerName',
        defaultMessage: 'Partner Name'
    },
    TYPE_OF_REQUEST: {
        id: 'techDoc.ticket.label.typeOfRequest',
        defaultMessage: 'Type Of Request'
    },
    PRIORITY_TREATMENT: {
        id: 'techDoc.ticket.label.priorityTreatment',
        defaultMessage: 'Priority Treatment Needed?'
    },
    PRIORITY_TYPE: {
        id: 'techDoc.ticket.label.priorityType',
        defaultMessage: 'Priority Type'
    },
    SRC_NUMBER: {
        id: 'techDoc.ticket.label.srcNumber',
        defaultMessage: 'SRC Number'
    },
    NETWORK_TICKET_NUMBER: {
        id: 'techDoc.ticket.label.networkTicketNumber',
        defaultMessage: 'Original Associated Network Ticket Number'
    },
    ROOT_CAUSE: {
        id: 'techDoc.ticket.label.rootCause',
        defaultMessage: 'Root Cause'
    },
    REQUEST_FOR_PARTNER: {
        id: 'techDoc.ticket.label.requestForPartner',
        defaultMessage: 'Request For Partner'
    },
    REQUEST_DETAIL_SECTION: {
        id: 'techDoc.ticket.header.requestDetailSection',
        defaultMessage: 'Request Detail'
    },
    VIP: {
        id: 'techDoc.ticket.label.vip',
        defaultMessage: 'VIP'
    },
    CLIENT_TYPE: {
        id: 'techDoc.ticket.label.clientType',
        defaultMessage: 'Type of Client'
    },
    SRC_REQUEST: {
        id: 'techDoc.ticket.label.srcRequest',
        defaultMessage: 'SRC Request°'
    },
    SRC_CLAIM_N: {
        id: 'techDoc.ticket.label.srcClaimN',
        defaultMessage: 'SRC Claim N°'
    },
    MORE_DETAILS: {
        id: 'techDoc.ticket.label.moreDetails',
        defaultMessage: 'More details'
    },
    PROBLEM_DESCRIPTION_SECTION: {
        id: 'techDoc.ticket.header.problemDescriptionSection',
        defaultMessage: 'Problem Description'
    },
    GAR_1: {
        id: 'techDoc.ticket.label.garRelated1',
        defaultMessage: 'GAR related (1)'
    },
    GAR_2: {
        id: 'techDoc.ticket.label.garRelated2',
        defaultMessage: 'GAR related (2)'
    },
    GAR_3: {
        id: 'techDoc.ticket.label.garRelated3',
        defaultMessage: 'GAR related (3)'
    },
    INTERNAL_TICKET_REFERENCES: {
        id: 'techDoc.ticket.header.internalTicketReferences',
        defaultMessage: 'Internal Ticket References'
    },
    PROBLEM_TYPE: {
        id: 'techDoc.ticket.label.problemType',
        defaultMessage: 'Type of Problem'
    },
    DEALER_ORDER: {
        id: 'techDoc.ticket.label.dealerOrder',
        defaultMessage: 'Dealer Order'
    },
    ORDER: {
        id: 'techDoc.ticket.dealerOrder.table.order',
        defaultMessage: 'Order'
    },
    LINE: {
        id: 'techDoc.ticket.dealerOrder.table.line',
        defaultMessage: 'Line'
    },
    DESCRIPTION: {
        id: 'techDoc.ticket.dealerOrder.table.description',
        defaultMessage: 'Description'
    },
    QUANTITY: {
        id: 'techDoc.ticket.dealerOrder.table.quantity',
        defaultMessage: 'QTY'
    },
    CODE: {
        id: 'techDoc.ticket.dealerOrder.table.code',
        defaultMessage: 'Code'
    },
    EDITOR_ORDER_REPLY: {
        id: 'techDoc.ticket.label.editorOrderReply',
        defaultMessage: 'NPDC answer (Order)'
    },
    EDITOR_ORDER: {
        id: 'techDoc.ticket.editorOrderReply.table.order',
        defaultMessage: 'Order'
    },
    EDITOR_LINE: {
        id: 'techDoc.ticket.editorOrderReply.table.line',
        defaultMessage: 'Line'
    },
    EDITOR_PART_NUMBER: {
        id: 'techDoc.ticket.editorOrderReply.table.partNumber',
        defaultMessage: 'Part No'
    },
    EDITOR_DESCRIPTION: {
        id: 'techDoc.ticket.editorOrderReply.table.description',
        defaultMessage: 'Description'
    },
    EDITOR_QUANTITY: {
        id: 'techDoc.ticket.editorOrderReply.table.quantity',
        defaultMessage: 'QTY'
    },
    EDITOR_CODE_1: {
        id: 'techDoc.ticket.editorOrderReply.table.editorCode1',
        defaultMessage: 'Code I'
    },
    EDITOR_CODE_2: {
        id: 'techDoc.ticket.editorOrderReply.table.editorCode2',
        defaultMessage: 'Code II'
    },
    DIALOGYS_VERSION: {
        id: 'techDoc.ticket.label.dialogysVersion',
        defaultMessage: 'DIALOGYS version'
    },
    PR: {
        id: 'techDoc.ticket.label.pr',
        defaultMessage: 'PR'
    },
    PAGE: {
        id: 'techDoc.ticket.label.page',
        defaultMessage: 'Page'
    },
    PAGE_PREF: {
        id: 'techDoc.ticket.label.pagePrefix',
        defaultMessage: 'Page prefix'
    },
    PAGE_POST: {
        id: 'techDoc.ticket.label.pagePostfix',
        defaultMessage: 'Page postfix'
    },
    ITEM_NUMBER: {
        id: 'techDoc.ticket.label.itemNumber',
        defaultMessage: 'Item number'
    },
    PROBLEM_DESCRIPTION: {
        id: 'techDoc.ticket.label.problemDescription',
        defaultMessage: 'Problem Description'
    },
    CLAIM_RELATED: {
        id: 'techDoc.ticket.label.claimRelated',
        defaultMessage: 'Claim related'
    },
    PVI_RELATED: {
        id: 'techDoc.ticket.label.pviRelated',
        defaultMessage: 'VOR/PVI Order related'
    },
    RELATED_DEALERS_TICKETS: {
        id: 'techDoc.ticket.label.relatedDealersTickets',
        defaultMessage: ' Related dealer tickets'
    },
    IMMOBILIZED_VEHICLE: {
        id: 'techDoc.ticket.label.immobilizedVehicle',
        defaultMessage: 'Immobilized Vehicle'
    },
    PART_NUMBER: {
        id: 'techDoc.ticket.label.partNumber',
        defaultMessage: 'Part No'
    },
    PART_MARKING: {
        id: 'techDoc.ticket.label.partMarking',
        defaultMessage: 'Part marking'
    },
    PART_DESCRIPTION: {
        id: 'techDoc.ticket.label.partDescription',
        defaultMessage: 'Part Description'
    },
    SUPPLIER: {
        id: 'techDoc.ticket.label.supplier',
        defaultMessage: 'Supplier'
    },
    VEHICLE_DETAIL_SECTION: {
        id: 'techDoc.ticket.header.vehicleDetail',
        defaultMessage: 'Vehicle Details'
    },
    BVM_CONNECTION: {
        id: 'techDoc.ticket.label.bvmConnection',
        defaultMessage: 'BVM connection'
    },
    VIN: {
        id: 'techDoc.ticket.label.vinNumber',
        defaultMessage: 'VIN'
    },
    MANUFACTURE_DATE: {
        id: 'techDoc.ticket.label.manufactureDate',
        defaultMessage: 'Manufacturing Date'
    },
    TAPV: {
        id: 'techDoc.ticket.label.tapv',
        defaultMessage: 'TAPV'
    },
    VEHICLE_FAMILY: {
        id: 'techDoc.ticket.label.vehicleFamily',
        defaultMessage: 'Vehicle Family'
    },
    VEHICLE_BRAND: {
        id: 'techDoc.ticket.label.vehicleBrand',
        defaultMessage: 'Vehicle Brand'
    },
    VEHICLE_MODEL: {
        id: 'techDoc.ticket.label.vehicleModel',
        defaultMessage: 'Car Model'
    },
    REGISTRATION_NUMBER: {
        id: 'techDoc.ticket.label.registrationNumber',
        defaultMessage: 'Registration number'
    }, ENGINE_TYPE_SUFFIX: {
        id: 'techDoc.ticket.label.engineTypeSuffix',
        defaultMessage: 'Engine Type & Suffix'
    }, ENGINE_SERIAL_NUMBER_VDS: {
        id: 'techDoc.ticket.label.engineSerialNumberVds',
        defaultMessage: 'Engine Serial number'
    }, GEARBOX_TYPE_SUFFIX: {
        id: 'techDoc.ticket.label.gearboxTypeSuffix',
        defaultMessage: 'Gearbox Type & Suffix'
    }, GEARBOX_SERIAL_NUMBER_VDS: {
        id: 'techDoc.ticket.label.gearboxSerialNumberVds',
        defaultMessage: 'Gearbox Serial number'
    }, STEERING_WHEEL_ON: {
        id: 'techDoc.ticket.label.steeringWheelOn',
        defaultMessage: 'Steering Wheel on'
    },
    INTERNAL_STATUS: {
        id: 'techDoc.ticket.label.internalStatus',
        defaultMessage: 'Internal status'
    },
    OLD_OVAL_PLATE: {
        id: 'techDoc.ticket.label.oldOvalPlate',
        defaultMessage: 'Old Oval Plate'
    },
    OLD_PLATE1: {
        id: 'techDoc.ticket.oldOvalPlate.vehicleType',
        defaultMessage: 'Old plate 1'
    },
    OLD_PLATE2: {
        id: 'techDoc.ticket.oldOvalPlate.factoryOptions',
        defaultMessage: 'Old plate 2'
    },
    OLD_PLATE3: {
        id: 'techDoc.ticket.oldOvalPlate.technicalEquipmentCode',
        defaultMessage: 'Old plate 3'
    },
    OLD_PLATE4: {
        id: 'techDoc.ticket.oldOvalPlate.additionalOptionalEquipment',
        defaultMessage: 'Old plate 4'
    },
    OLD_PLATE5: {
        id: 'techDoc.ticket.oldOvalPlate.seatTrimCode',
        defaultMessage: 'Old plate 5'
    },
    OLD_PLATE6: {
        id: 'techDoc.ticket.oldOvalPlate.paintCode',
        defaultMessage: 'Old plate 6'
    },
    OLD_PLATE7: {
        id: 'techDoc.ticket.oldOvalPlate.equipmentLevel',
        defaultMessage: 'Old plate 7'
    },
    OLD_PLATE8: {
        id: 'techDoc.ticket.oldOvalPlate.fabricationNumber',
        defaultMessage: 'Old plate 8'
    },
    NEW_OVAL_PLATE: {
        id: 'techDoc.ticket.label.newOvalPlate',
        defaultMessage: 'New Oval Plate'
    },
    NEW_PLATE1: {
        id: 'techDoc.ticket.newOvalPlate.vehicleType',
        defaultMessage: 'New plate 1'
    },
    NEW_PLATE2: {
        id: 'techDoc.ticket.newOvalPlate.fabricationNumber',
        defaultMessage: 'New plate 2'
    },
    NEW_PLATE3: {
        id: 'techDoc.ticket.newOvalPlate.equipmentLevel',
        defaultMessage: 'New plate 3'
    },
    NEW_PLATE4: {
        id: 'techDoc.ticket.newOvalPlate.additionalOptionalEquipment1',
        defaultMessage: 'New plate 4'
    },
    NEW_PLATE5: {
        id: 'techDoc.ticket.newOvalPlate.additionalOptionalEquipment2',
        defaultMessage: 'New plate 5'
    },
    NEW_PLATE6: {
        id: 'techDoc.ticket.newOvalPlate.paintCode',
        defaultMessage: 'New plate 6'
    },
    NEW_PLATE7: {
        id: 'techDoc.ticket.newOvalPlate.seatTrimCode',
        defaultMessage: 'New plate 7'
    },
    NEW_PLATE8: {
        id: 'techDoc.ticket.newOvalPlate.interiorTrimCode',
        defaultMessage: 'New plate 8'
    },
    NEW_PLATE9: {
        id: 'techDoc.ticket.newOvalPlate.technicalCountryCode',
        defaultMessage: 'New plate 9'
    },
    NEW_PLATE10: {
        id: 'techDoc.ticket.newOvalPlate.suspensionType',
        defaultMessage: 'New plate 10'
    },
    NEW_PLATE11: {
        id: 'techDoc.ticket.newOvalPlate.countryClimateType',
        defaultMessage: 'New plate 11'
    },
    NEW_PLATE12: {
        id: 'techDoc.ticket.newOvalPlate.airConditioningType',
        defaultMessage: 'New plate 12'
    },
    NEW_PLATE13: {
        id: 'techDoc.ticket.newOvalPlate.engineFiltrationType',
        defaultMessage: 'New plate 13'
    },
    NEW_PLATE14: {
        id: 'techDoc.ticket.newOvalPlate.steeringType',
        defaultMessage: 'New plate 14'
    },
    NEW_PLATE15: {
        id: 'techDoc.ticket.newOvalPlate.dampersType',
        defaultMessage: 'New plate 15'
    },
    NEW_PLATE16: {
        id: 'techDoc.ticket.newOvalPlate.trimSystem',
        defaultMessage: 'New plate 16'
    },
    NEW_PLATE17: {
        id: 'techDoc.ticket.newOvalPlate.braking',
        defaultMessage: 'New plate 17'
    },
    MESSAGES_SECTION: {
        id: 'techDoc.ticket.header.messages',
        defaultMessage: 'Messages (Answers to dealer)'
    },
    MESSAGES_HISTORY: {
        id: 'techDoc.ticket.label.messagesHistory',
        defaultMessage: 'History of Messages'
    },
    MESSAGE: {
        id: 'techDoc.ticket.label.dialogBox',
        defaultMessage: 'DialogBox'
    },
    ATTACHMENTS_SECTION: {
        id: 'techDoc.ticket.header.attachments',
        defaultMessage: 'Attachments'
    },
    DEALER_ATTACHMENTS: {
        id: 'techDoc.ticket.label.dealerAttachments',
        defaultMessage: 'Dealer Attachments'
    },
    EDITOR_ATTACHMENTS: {
        id: 'techDoc.ticket.label.editorAttachments',
        defaultMessage: 'Editor Attachments'
    },
    CLAIM_RELATED_NO: {
        id: 'techDoc.ticket.label.claimRelatedNo',
        defaultMessage: 'No.'
    },
    PVI_RELATED_NO: {
        id: 'techDoc.ticket.label.pviRelatedNo',
        defaultMessage: 'No.'
    },
    RELATED_TICKETS_NO: {
        id: 'techDoc.ticket.label.relatedTicketsNumber',
        defaultMessage: 'No.'
    },
    DOCUMENTATION_UPDATE_SECTION: {
        id: 'techDoc.ticket.header.documentationUpdate',
        defaultMessage: 'Documentation Update'
    },
    STORE_CHECK_RESULTS_SECTION: {
        id: 'techDoc.ticket.header.storeCheckResults',
        defaultMessage: 'Store Check Results'
    },
    DOC_UPDATE_NEEDED: {
        id: 'techDoc.ticket.label.docUpdateNeeded',
        defaultMessage: 'Documentation update needed?'
    },
    UPDATE_FILE_NUMBER: {
        id: 'techDoc.ticket.label.updateFileNumber',
        defaultMessage: 'Update File Number'
    },
    ESTIMATED_DATE_OF_MODIFICATION: {
        id: 'techDoc.ticket.label.estimatedDateOfModification',
        defaultMessage: 'Estimated Date Of Modification'
    },
    // editor's motes section
    EDITORS_NOTES_SECTION_TD: {
        id: 'techDoc.ticket.header.editorsNotes',
        defaultMessage: 'Internal Notes'
    },
    EDITORS_NOTES_HISTORY_TD: {
        id: 'techDoc.ticket.header.editorsNotesHistory',
        defaultMessage: 'History of Internal Notes'
    },
    EDITORS_NOTES_TD: {
        id: 'techDoc.ticket.label.editorsNotes',
        defaultMessage: 'Note'
    },
    DELETE_ATTACHMENT: {
        id: 'techDoc.internalTicket.label.deleteAttachments',
        defaultMessage: 'Delete'
    },
    MANAGEMENT_GROUP: {
        id: 'techDoc.ticket.header.managementGroup',
        defaultMessage: 'Management Group'
    },
});

export default messages;
