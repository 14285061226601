import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import {injectIntl} from 'react-intl';
import '../../../css/react-datepicker.css';
import DatePickerInput from './DatePickerInput';

export const StyledDatePicker = styled(DatePicker)`
    color: black;
`;

const DatePickerCommon = props => {
    return (
        <StyledDatePicker customInput={<DatePickerInput/>}
                          onKeyDown={e => e.preventDefault()}
                          isClearable
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                          popperModifiers={{
                              flip: {
                                  behavior: ['bottom'] // don't allow it to flip to be above
                              },
                              hide: {
                                  enabled: false // turn off since needs preventOverflow to be enabled
                              },
                              preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                                  boundariesElement: 'viewport'
                              }
                          }}
                          {...props}
        />
    );
};

export default injectIntl(DatePickerCommon);
