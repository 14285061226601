import {
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_VIN,
    SPARE_PARTS_INTERNAL_TICKET_FORM,
    FORM_FIELD_REQUESTOR_NAME,
    FORM_FIELD_REQUESTOR_IPN,
    FORM_FIELD_PRIORITY_TREATMENT,
    FORM_FIELD_REQUEST_FOR_PARTNER,
    FORM_FIELD_TYPE_OF_REQUEST,
    FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
    FORM_FIELD_SOLUTION_GROUP,
    FORM_FIELD_PARTNER_NAME, FORM_FIELD_MANAGEMENT_GROUP
} from './formConstants';
import {
    PART_DOC_REQUEST,
    PRICE_REQUEST,
    REF_REPLACEMENT_REQUEST,
    REQUEST_TO_OPEN_FOR_SALE,
    VISUAL_STORE_CHECK
} from './Utils';

export const editReqFields = {
    'DEFAULT': {
        'SP_SG_EDITOR': {
            [SPARE_PARTS_INTERNAL_TICKET_FORM]: [
                FORM_FIELD_SOLUTION_GROUP,
                FORM_FIELD_REQUESTOR_NAME,
                FORM_FIELD_REQUESTOR_IPN,
                FORM_FIELD_PRIORITY_TREATMENT,
                FORM_FIELD_REQUEST_FOR_PARTNER,
                FORM_FIELD_TYPE_OF_REQUEST,
            ],
            [VISUAL_STORE_CHECK]: [
                FORM_FIELD_SOLUTION_GROUP,
                FORM_FIELD_REQUESTOR_NAME,
                FORM_FIELD_REQUESTOR_IPN,
                FORM_FIELD_PRIORITY_TREATMENT,
                FORM_FIELD_REQUEST_FOR_PARTNER,
                FORM_FIELD_TYPE_OF_REQUEST,
                FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_PARTNER_NAME,
            ],
            [REQUEST_TO_OPEN_FOR_SALE]: [
                FORM_FIELD_SOLUTION_GROUP,
                FORM_FIELD_REQUESTOR_NAME,
                FORM_FIELD_REQUESTOR_IPN,
                FORM_FIELD_PRIORITY_TREATMENT,
                FORM_FIELD_REQUEST_FOR_PARTNER,
                FORM_FIELD_TYPE_OF_REQUEST,
                FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_PARTNER_NAME,
            ],
            [PART_DOC_REQUEST]: [
                FORM_FIELD_SOLUTION_GROUP,
                FORM_FIELD_REQUESTOR_NAME,
                FORM_FIELD_REQUESTOR_IPN,
                FORM_FIELD_VIN,
                FORM_FIELD_PRIORITY_TREATMENT,
                FORM_FIELD_REQUEST_FOR_PARTNER,
                FORM_FIELD_TYPE_OF_REQUEST,
                FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_PARTNER_NAME,
            ],
            [REF_REPLACEMENT_REQUEST]: [
                FORM_FIELD_SOLUTION_GROUP,
                FORM_FIELD_REQUESTOR_NAME,
                FORM_FIELD_REQUESTOR_IPN,
                FORM_FIELD_PRIORITY_TREATMENT,
                FORM_FIELD_REQUEST_FOR_PARTNER,
                FORM_FIELD_TYPE_OF_REQUEST,
                FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_PARTNER_NAME,
            ],
            [PRICE_REQUEST]: [
                FORM_FIELD_SOLUTION_GROUP,
                FORM_FIELD_REQUESTOR_NAME,
                FORM_FIELD_REQUESTOR_IPN,
                FORM_FIELD_PRIORITY_TREATMENT,
                FORM_FIELD_REQUEST_FOR_PARTNER,
                FORM_FIELD_TYPE_OF_REQUEST,
                FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_PARTNER_NAME,
            ]
        },
    }
};

export const createReqFields = {
    'DEFAULT': {
        [SPARE_PARTS_INTERNAL_TICKET_FORM]: [
            FORM_FIELD_SOLUTION_GROUP,
            FORM_FIELD_REQUESTOR_NAME,
            FORM_FIELD_REQUESTOR_IPN,
            FORM_FIELD_PRIORITY_TREATMENT,
            FORM_FIELD_REQUEST_FOR_PARTNER,
            FORM_FIELD_TYPE_OF_REQUEST,
            FORM_FIELD_MANAGEMENT_GROUP,
        ],
        [VISUAL_STORE_CHECK]: [
            FORM_FIELD_SOLUTION_GROUP,
            FORM_FIELD_REQUESTOR_NAME,
            FORM_FIELD_REQUESTOR_IPN,
            FORM_FIELD_PRIORITY_TREATMENT,
            FORM_FIELD_REQUEST_FOR_PARTNER,
            FORM_FIELD_TYPE_OF_REQUEST,
            FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
            FORM_FIELD_PROBLEM_DESCRIPTION,
            FORM_FIELD_PARTNER_NAME,
            FORM_FIELD_MANAGEMENT_GROUP,
        ],
        [REQUEST_TO_OPEN_FOR_SALE]: [
            FORM_FIELD_SOLUTION_GROUP,
            FORM_FIELD_REQUESTOR_NAME,
            FORM_FIELD_REQUESTOR_IPN,
            FORM_FIELD_PRIORITY_TREATMENT,
            FORM_FIELD_REQUEST_FOR_PARTNER,
            FORM_FIELD_TYPE_OF_REQUEST,
            FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
            FORM_FIELD_PROBLEM_DESCRIPTION,
            FORM_FIELD_PARTNER_NAME,
            FORM_FIELD_MANAGEMENT_GROUP,
        ],
        [PART_DOC_REQUEST]: [
            FORM_FIELD_SOLUTION_GROUP,
            FORM_FIELD_REQUESTOR_NAME,
            FORM_FIELD_REQUESTOR_IPN,
            FORM_FIELD_VIN,
            FORM_FIELD_PRIORITY_TREATMENT,
            FORM_FIELD_REQUEST_FOR_PARTNER,
            FORM_FIELD_TYPE_OF_REQUEST,
            FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
            FORM_FIELD_PROBLEM_DESCRIPTION,
            FORM_FIELD_PARTNER_NAME,
            FORM_FIELD_MANAGEMENT_GROUP,
        ],
        [REF_REPLACEMENT_REQUEST]: [
            FORM_FIELD_SOLUTION_GROUP,
            FORM_FIELD_REQUESTOR_NAME,
            FORM_FIELD_REQUESTOR_IPN,
            FORM_FIELD_PRIORITY_TREATMENT,
            FORM_FIELD_REQUEST_FOR_PARTNER,
            FORM_FIELD_TYPE_OF_REQUEST,
            FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
            FORM_FIELD_PROBLEM_DESCRIPTION,
            FORM_FIELD_PARTNER_NAME,
            FORM_FIELD_MANAGEMENT_GROUP,
        ],
        [PRICE_REQUEST]: [
            FORM_FIELD_SOLUTION_GROUP,
            FORM_FIELD_REQUESTOR_NAME,
            FORM_FIELD_REQUESTOR_IPN,
            FORM_FIELD_PRIORITY_TREATMENT,
            FORM_FIELD_REQUEST_FOR_PARTNER,
            FORM_FIELD_TYPE_OF_REQUEST,
            FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
            FORM_FIELD_PROBLEM_DESCRIPTION,
            FORM_FIELD_PARTNER_NAME,
            FORM_FIELD_MANAGEMENT_GROUP,
        ]
    }
};

