import React, {Component} from 'react';
import {FormattedMessage, injectIntl, FormattedDate, FormattedTime} from 'react-intl';
import {connect} from 'react-redux';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import styled from 'styled-components';
import moment from 'moment';
import {InputFieldWithValidation} from '../../common/InputFieldWithValidation';
import {domains, getFormStates,} from '../../../constants/Utils';
import {PrimaryButton} from '../../common/Button';
import RadioFields from '../../common/RadioFields';
import InputFieldTicketNumber from '../../common/InputFieldTicketNumber';
import InputSelectFieldWithValidation from '../../common/InputSelectFieldWithValidation';
import DatePickerComponent from '../../common/datePicker/DatePickerComponent';
import PropTypes from 'prop-types';
import buttonMessages from '../../../intl/common/buttonMessages';
import statusMessages from '../../../intl/common/formStatesMessages';
import exportConstantMessages from '../../../intl/common/exportMessages';
import exportMessages from '../../../intl/claims/exportMessages';
import charsetMessages from '../../../intl/charsetMessages';
import {BUTTON_LABEL_DOWNLOAD, BUTTON_LABEL_EXPORT} from '../../../constants/formConstants';
import {
    CSV,
    CLAIMS_EXPORT_FORM_FIELD_ASSIGNED,
    CLAIMS_EXPORT_FORM_FIELD_CHARSET,
    CLAIMS_EXPORT_FORM_FIELD_DEALER_NAME,
    CLAIMS_EXPORT_FORM_FIELD_DEALER_NUMBER,
    CLAIMS_EXPORT_FORM_FIELD_FROM,
    CLAIMS_EXPORT_FORM_FIELD_PFX_NUMBER,
    CLAIMS_EXPORT_FORM_FIELD_SFX_NUMBER,
    CLAIMS_EXPORT_FORM_FIELD_STATUS,
    CLAIMS_EXPORT_FORM_FIELD_TO,
    CLAIMS_EXPORT_FORM_FIELD_TYPE,
    CLAIMS_EXPORT_FORM_LABEL_ASSIGNED,
    CLAIMS_EXPORT_FORM_LABEL_CHARSET,
    CLAIMS_EXPORT_FORM_LABEL_DEALER_NAME,
    CLAIMS_EXPORT_FORM_LABEL_DEALER_NUMBER,
    CLAIMS_EXPORT_FORM_LABEL_FROM,
    CLAIMS_EXPORT_FORM_LABEL_STATUS,
    CLAIMS_EXPORT_FORM_LABEL_TICKET_NUMBER,
    CLAIMS_EXPORT_FORM_LABEL_TO,
    CLAIMS_EXPORT_FORM_LABEL_TYPE,
    ticketExportTypes
} from '../../../constants/exportConstant';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const status = (() => {
    const arr = getFormStates(domains.CLAIMS);
    arr.splice(2, 1);
    return arr;
})();

class ExportClaimsForm extends Component {
    onChange = (event, newValue) => {
        event.preventDefault();
        const {change, dealers} = this.props;
        if (event.target.name === `dealer.${CLAIMS_EXPORT_FORM_FIELD_DEALER_NAME}`) {
            const find = dealers.filter(dealer => dealer.dealerName === newValue)[0];
            change('dealer', find ? find : {dealerNumber: '', dealerName: ''});
        }
        if (event.target.name === `dealer.${CLAIMS_EXPORT_FORM_FIELD_DEALER_NUMBER}`) {
            const find = dealers.filter(dealer => dealer.dealerNumber === newValue)[0];
            change('dealer', find ? find : {dealerNumber: '', dealerName: ''});
        }
    };

    render() {
        const {handleSubmit, dealers, exportType, exportRecord, downloadExport, startDate, endDate, charSets} = this.props;
        return (
            <StyledDiv className="border border-secondary p-4 bg-white">
                <div className="row justify-content-center">
                    <div className="col-xl-8">
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldTicketNumber label={exportMessages[CLAIMS_EXPORT_FORM_LABEL_TICKET_NUMBER]}
                                                        fields={[CLAIMS_EXPORT_FORM_FIELD_PFX_NUMBER, CLAIMS_EXPORT_FORM_FIELD_SFX_NUMBER]}
                                                        isSmall/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldWithValidation label={exportMessages[CLAIMS_EXPORT_FORM_LABEL_ASSIGNED]}
                                                          field={CLAIMS_EXPORT_FORM_FIELD_ASSIGNED}
                                                          isSmall/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <Field component={DatePickerComponent}
                                       selectsStart
                                       label={exportMessages[CLAIMS_EXPORT_FORM_LABEL_FROM]}
                                       name={CLAIMS_EXPORT_FORM_FIELD_FROM}
                                       startDate={startDate}
                                       endDate={endDate}
                                       parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <Field component={DatePickerComponent}
                                       selectsEnd
                                       label={exportMessages[CLAIMS_EXPORT_FORM_LABEL_TO]}
                                       name={CLAIMS_EXPORT_FORM_FIELD_TO}
                                       startDate={startDate}
                                       endDate={endDate}
                                       parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation
                                    label={exportMessages[CLAIMS_EXPORT_FORM_LABEL_DEALER_NAME]}
                                    field={`dealer.${CLAIMS_EXPORT_FORM_FIELD_DEALER_NAME}`}
                                    options={dealers.map(dealer => dealer.dealerName)}
                                    handleChange={this.onChange}
                                    isSmall/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation
                                    label={exportMessages[CLAIMS_EXPORT_FORM_LABEL_DEALER_NUMBER]}
                                    field={`dealer.${CLAIMS_EXPORT_FORM_FIELD_DEALER_NUMBER}`}
                                    options={dealers.map(dealer => dealer.dealerNumber)}
                                    handleChange={this.onChange}
                                    isSmall/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={exportMessages[CLAIMS_EXPORT_FORM_LABEL_STATUS]}
                                                                field={CLAIMS_EXPORT_FORM_FIELD_STATUS}
                                                                options={status}
                                                                intlMessages={statusMessages}
                                                                isSmall/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <RadioFields
                                    label={exportMessages[CLAIMS_EXPORT_FORM_LABEL_TYPE]}
                                    field={CLAIMS_EXPORT_FORM_FIELD_TYPE}
                                    options={ticketExportTypes}
                                    intlMessages={exportMessages}/>
                                <InputSelectFieldWithValidation label={exportMessages[CLAIMS_EXPORT_FORM_LABEL_CHARSET]}
                                                                field={CLAIMS_EXPORT_FORM_FIELD_CHARSET}
                                                                options={charSets}
                                                                intlMessages={charsetMessages}
                                                                isDisabled={!exportType}
                                                                noEmpty
                                                                isSmall/>
                            </div>
                        </div>
                        <form className="form-group">
                            {(Object.keys(exportRecord).length > 0) &&
                            <div className="">
                                <div>
                                    <span className="font-weight-bold">
                                        <FormattedMessage {...exportMessages.LAST_EXPORT}/>{' '}
                                    </span>
                                    <FormattedDate value={new Date(exportRecord.created)}/> <FormattedTime
                                    value={new Date(exportRecord.created)}/>
                                </div>
                                <div>
                                    <span className="font-weight-bold">
                                        <FormattedMessage {...exportMessages.EXPORT_STATUS}/>{' '}
                                    </span>
                                    <FormattedMessage {...exportConstantMessages[exportRecord.status]}/>
                                </div>
                                {exportRecord.status === 'FINISH' &&
                                <PrimaryButton type="button"
                                               className="btn mr-2 float-left"
                                               onClick={downloadExport}>
                                    <FormattedMessage {...buttonMessages[BUTTON_LABEL_DOWNLOAD]}/>
                                </PrimaryButton>
                                }
                            </div>
                            }
                            <PrimaryButton type="button"
                                           className="btn mr-2 float-right"
                                           onClick={handleSubmit}>
                                <FormattedMessage {...buttonMessages[BUTTON_LABEL_EXPORT]}/>
                            </PrimaryButton>
                        </form>
                    </div>
                </div>
            </StyledDiv>
        );
    }
}

ExportClaimsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

const selector = (form, ...other) => (formValueSelector(form))(...other);

const mapStateToProps = (state, initialProps) => ({
    startDate: selector(initialProps.form, state, CLAIMS_EXPORT_FORM_FIELD_FROM),
    endDate: selector(initialProps.form, state, CLAIMS_EXPORT_FORM_FIELD_TO),
    exportType: (selector(initialProps.form, state, CLAIMS_EXPORT_FORM_FIELD_TYPE) === CSV),
    dealers: state.claimsDealers.dealers,
    exportRecord: state.claimsExportTickets.exportRecord,
    charSets: state.profile.userDetail.charSets,
});

export default reduxForm({
    enableReinitialize: true,
})(injectIntl(connect(mapStateToProps, {})(ExportClaimsForm)));
