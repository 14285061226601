import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import Get from 'get-value';
import PropTypes from 'prop-types';
import {storeSelectedCountryGroup} from '../../../actions/actionClientId'
import {moduleRoles} from '../../../utils/roles';
import ClientIdContainer from './ClientIdContainer';
import SelectCountryGroup from '../../common/country/SelectCountryGroup';
import clientIdMessages from '../../../intl/admin/clientIdMessages';
import PageHeader from '../../common/PageHeader';
import ImportersTable from '../../common/ImportersTable';
import {domains} from '../../../constants/Utils';

const Label = styled.label`
    font-weight: bold;
`;

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

class ClientIdPage extends Component {
    componentDidMount() {
        if (this.props.selectedCountryGroup === '') {
            this.props.storeSelectedCountryGroup(
                Get(this.getSPAndTDAdminGroups(), '0.group') || ''
            );
        }
    }

    handleCountryGroupSelection = countryGroup => this.props.storeSelectedCountryGroup(countryGroup.value);

    getSPAndTDAdminGroups = () => {
        const {roles} = this.props;
        return moduleRoles.getAdminGroups(domains.TECHNICAL_DOCUMENTATION, roles).map(group => ({
            ...group,
            domain: domains.TECHNICAL_DOCUMENTATION
        }))
            .concat(moduleRoles.getAdminGroups(domains.SPARE_PARTS, roles).map(group => ({
                ...group,
                domain: domains.SPARE_PARTS
            })))
            .concat(moduleRoles.getAdminGroups(domains.CLAIMS, roles).map(group => ({
                ...group,
                domain: domains.CLAIMS
            })))
            .reduce((uniqueGroups, group) => {
                return uniqueGroups.some(item => item.group === group.group) ? uniqueGroups : [...uniqueGroups, group]
            }, [])
            .sort((groupA, groupB) => groupA.group >= groupB.group ? 1 : -1);
    }

    render() {
        const {selectedCountryGroup} = this.props;
        const groups = this.getSPAndTDAdminGroups();
        return (
            <div>
                <PageHeader title={<FormattedMessage {...clientIdMessages.TITLE}/>}/>
                <StyledForm className="form-group">
                    <div className="form-row mb-sm-3">
                        <div className="col-xl-2 col-lg-4 col-md-5 col-sm-6">
                            <Label>
                                <FormattedMessage {...clientIdMessages.SELECT_COUNTRY}/>
                            </Label>
                            <SelectCountryGroup groups={groups}
                                                handleCountryGroupSelection={this.handleCountryGroupSelection}
                                                selectedCountryGroup={selectedCountryGroup}/>
                        </div>
                    </div>
                </StyledForm>
                {groups.map((group, index) =>
                    (selectedCountryGroup === group.group &&
                        <div key={index}>
                            <ClientIdContainer selectedGroup={group.group}/>
                        </div>
                    )
                )}
                {selectedCountryGroup && selectedCountryGroup === 'IMP' && <ImportersTable/>}
            </div>
        );
    }
}

ClientIdPage.propTypes = {
    roles: PropTypes.object.isRequired,
    storeSelectedCountryGroup: PropTypes.func.isRequired,
    selectedCountryGroup: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    linesPerPage: state.adminClientId.linesPerPage,
    roles: state.profile.userDetail.roles,
    selectedCountryGroup: state.adminClientId.selectedCountryGroup
});

export default connect(mapStateToProps, {
    storeSelectedCountryGroup
})(ClientIdPage);
