import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    CONFIRMATION_DIALOG_BODY: {
        id: 'confirmation.dialog.body',
        defaultMessage: 'Are you sure you want to {action}?'
    },
    CONFIRMATION_DIALOG_ACTION_MANUALLY_REWRITE_BVM: {
        id: 'confirmation.dialog.action.manuallyRewriteBvm',
        defaultMessage: 'manually rewrite data loaded from BVM'
    },
    CONFIRMATION_DIALOG_ACTION_BVM_REWRITE: {
        id: 'confirmation.dialog.action.bvmRewrite',
        defaultMessage: 'rewrite the form by the data from BVM'
    },
    CONFIRMATION_DIALOG_TITLE: {
        id: 'confirmation.dialog.title',
        defaultMessage: 'Confirmation'
    }
});

export default messages;
