import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.title',
        defaultMessage: 'Administration module'
    },
    SELECT_COUNTRY: {
        id: 'admin.selectCountry',
        defaultMessage: 'Select a country'
    },
    ACTION: {
        id: 'admin.action',
        defaultMessage: 'Action'
    },
});

export default messages;
