import v4 from 'uuid/v4';
import {TECH_DOC_GET_STATISTICS} from '../../constants/websocketCommands';

export const TECH_DOC_STATISTICS_PENDING = 'TECH_DOC_STATISTICS_PENDING';

export const fetchStatistics = ({dealerNumber, countryGroups, solutionGroups}) => dispatch => {
    return dispatch({
        type: TECH_DOC_STATISTICS_PENDING,
        techDocWebsocket: true,
        command: TECH_DOC_GET_STATISTICS,
        payload: {dealerNumber, countryGroups, solutionGroups, correlationId: v4()}
    });
};
