import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import AdministrationMainPage from './AdministrationMainPage';
import NotFoundPage from '../NotFoundPage';
import MainPageAlertPage from './mainPageAlert/MainPageAlertPage';
import PopUpPage from './recurrentProblem/RecurrentProblemsPage';
import WorkingHoursPage from './workingHours/WorkingHoursPage';
import AdminRightsManagementPage from './right/AdminRightsManagementPage';
import EditorRightsManagementPage from './right/EditorRightsManagementPage';
import DealerRightsManagementPage from './right/DealerRightsManagementPage';
import ClientIdPage from './clientId/ClientIdPage';
import {
    ADMINISTRATION_PATH,
    ADMINISTRATION_CLIENT_ID_PATH,
    ADMINISTRATION_ORDER_DEALER_NOTIFICATION_PATH,
    ADMINISTRATION_ORDER_TICKET_SETTING_PATH,
    ADMINISTRATION_ORDER_RECURRENT_PROBLEM_PATH,
    ADMINISTRATION_ORDER_MAIN_PAGE_PATH,
    ADMINISTRATION_ORDER_WORKING_HOUR_PATH,
    ADMINISTRATION_ORDER_ADMIN_PATH,
    ADMINISTRATION_ORDER_DEALER_PATH,
    ADMINISTRATION_ORDER_EDITOR_PATH,
    ADMINISTRATION_DOCUMENTATIONS_DEALER_NOTIFICATION_PATH,
    ADMINISTRATION_DOCUMENTATIONS_TICKET_SETTING_PATH,
    ADMINISTRATION_DOCUMENTATIONS_RECURRENT_PROBLEM_PATH,
    ADMINISTRATION_DOCUMENTATIONS_MAIN_PAGE_PATH,
    ADMINISTRATION_DOCUMENTATIONS_WORKING_HOUR_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ADMIN_PATH,
    ADMINISTRATION_DOCUMENTATIONS_DEALER_PATH,
    ADMINISTRATION_DOCUMENTATIONS_EDITOR_PATH,
    ADMINISTRATION_CLAIMS_DEALER_NOTIFICATION_PATH,
    ADMINISTRATION_CLAIMS_TICKET_SETTING_PATH,
    ADMINISTRATION_CLAIMS_RECURRENT_PROBLEM_PATH,
    ADMINISTRATION_CLAIMS_MAIN_PAGE_PATH,
    ADMINISTRATION_CLAIMS_WORKING_HOUR_PATH,
    ADMINISTRATION_CLAIMS_ADMIN_PATH,
    ADMINISTRATION_CLAIMS_DEALER_PATH,
    ADMINISTRATION_CLAIMS_EDITOR_PATH,
    ADMINISTRATION_GENERIC_FLOW_DETAIL_PATH,
    ADMINISTRATION_GENERIC_FLOW_PATH,
    ADMINISTRATION_GENERIC_SOLUTION_GROUP_PATH,
    ADMINISTRATION_DOCUMENTATIONS_DEFAULT_COUNTRY_ROLE_PATH,
    ADMINISTRATION_ORDER_DEFAULT_COUNTRY_ROLE_PATH,
    ADMINISTRATION_CLAIMS_PRESET_RESPONSE_PATH,
    ADMINISTRATION_CLAIMS_DEFAULT_COUNTRY_ROLE_PATH,
    ADMINISTRATION_ORDER_SECOND_HAND_BATTERY_TYPE_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ESCALATION_LEVELS_PATH,
    ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_NEW_PATH,
    ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_LIST_PATH,
    ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_PATH,
    ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_USER_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_NEW_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_LIST_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_LIST_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_NEW_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_PATH,
    ADMINISTRATION_ORDER_SOLUTION_GROUP_NEW_PATH,
    ADMINISTRATION_ORDER_SOLUTION_GROUP_LIST_PATH,
    ADMINISTRATION_ORDER_SOLUTION_GROUP_PATH,
    ADMINISTRATION_ORDER_SOLUTION_GROUP_USER_PATH,
    ADMINISTRATION_ORDER_ESCALATION_SCHEMA_NEW_PATH,
    ADMINISTRATION_ORDER_ESCALATION_SCHEMA_PATH,
    ADMINISTRATION_ORDER_ESCALATION_SCHEMA_LIST_PATH,
    ADMINISTRATION_ORDER_ANSWERING_SCHEMA_NEW_PATH,
    ADMINISTRATION_ORDER_ANSWERING_SCHEMA_PATH,
    ADMINISTRATION_ORDER_ANSWERING_SCHEMA_LIST_PATH,
    ADMINISTRATION_ORDER_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS_PATH,
    ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS_PATH,
} from '../../routes/paths';
import {Domain} from '../common/Domain';
import SolutionGroupListPage from './ticket/SolutionGroupListPage';
import FlowListPage from './ticket/FlowListPage';
import FlowDetailPage from './ticket/FlowDetailPage';
import NotificationPage from './notifications/NotificationPage';
import TicketSettingsPage from './ticketSettings/TicketSettingsPage';
import DefaultCountryRolePage from './defaultCountryRole/DefaultCountryRolePage';
import checkRoles from '../common/roleChecker/RoleChecker';
import PresetResponsePage from './presetResponse/PresetResponsePage';
import SecondHandBatteryPage from './secondHandBattery/SecondHandBatteryPage';
import EscalationLevelPage from './escalationLevel/EscalationLevelPage';
import ListSolutionGroupPage from './solutionGroup/list/ListSolutionGroupPage';
import NewSolutionGroupPage from './solutionGroup/detail/NewSolutionGroupPage';
import ViewSolutionGroupPage from './solutionGroup/detail/ViewSolutionGroupPage';
import EditSolutionGroupPage from './solutionGroup/detail/EditSolutionGroupPage';
import SolutionGroupUserRightsManagementPage from './right/SolutionGroupUserRightsManagementPage';
import NewEscalationSchemaPage from './escalationSchema/detail/NewEscalationSchemaPage';
import ListEscalationSchemaPage from './escalationSchema/list/ListEscalationSchemaPage';
import ListAnsweringSchemaPage from './answeringSchema/list/ListAnsweringSchemaPage';
import NewAnsweringSchemaPage from './answeringSchema/detail/NewAnsweringSchemaPage';
import EditEscalationSchemaPage from './escalationSchema/detail/EditEscalationSchemaPage';
import ViewEscalationSchemaPage from './escalationSchema/detail/ViewEscalationSchemaPage';
import EditAnsweringSchemaPage from './answeringSchema/detail/EditAnsweringSchemaPage';
import ViewAnsweringSchemaPage from './answeringSchema/detail/ViewAnsweringSchemaPage';
import ModelAnswersPage from "./modelAnswers/ModelAnswersPage";

class Administration extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={ADMINISTRATION_ORDER_RECURRENT_PROBLEM_PATH}
                       render={() => <Domain component={checkRoles(PopUpPage, ['SP_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_MAIN_PAGE_PATH}
                       render={() => <Domain component={checkRoles(MainPageAlertPage, ['SP_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_WORKING_HOUR_PATH}
                       render={() => <Domain component={checkRoles(WorkingHoursPage, ['SP_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_DEALER_NOTIFICATION_PATH}
                       render={() => <Domain component={checkRoles(NotificationPage, ['SP_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_TICKET_SETTING_PATH}
                       render={() => <Domain component={checkRoles(TicketSettingsPage, ['SP_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_DEFAULT_COUNTRY_ROLE_PATH}
                       render={() => <Domain component={checkRoles(DefaultCountryRolePage, ['SP_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_SECOND_HAND_BATTERY_TYPE_PATH}
                       render={() => <Domain component={checkRoles(SecondHandBatteryPage, ['SP_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_ADMIN_PATH}
                       render={() => <Domain component={checkRoles(AdminRightsManagementPage, ['SP_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_DEALER_PATH}
                       render={() => <Domain component={checkRoles(DealerRightsManagementPage, ['SP_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_EDITOR_PATH}
                       render={() => <Domain component={checkRoles(EditorRightsManagementPage, ['SP_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_SOLUTION_GROUP_NEW_PATH}
                       render={() => <Domain component={checkRoles(NewSolutionGroupPage, ['SP_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_SOLUTION_GROUP_PATH + '/:edit(edit)/:solutionGroupUuid(.*)'}
                       render={() => <Domain component={checkRoles(EditSolutionGroupPage, ['SP_POWER_USER', 'SP_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_SOLUTION_GROUP_PATH + '/:view(view)/:solutionGroupUuid(.*)'}
                       render={() => <Domain component={checkRoles(ViewSolutionGroupPage, ['SP_POWER_USER', 'SP_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_SOLUTION_GROUP_LIST_PATH}
                       render={() => <Domain component={checkRoles(ListSolutionGroupPage, ['SP_POWER_USER', 'SP_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_SOLUTION_GROUP_USER_PATH}
                       render={() => <Domain component={checkRoles(SolutionGroupUserRightsManagementPage, ['SP_POWER_USER', 'SP_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS_PATH}
                       render={() => <Domain component={checkRoles(ModelAnswersPage, ['SP_POWER_USER', 'SP_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_ESCALATION_SCHEMA_NEW_PATH}
                       render={() => <Domain component={checkRoles(NewEscalationSchemaPage, ['SP_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_ESCALATION_SCHEMA_PATH + '/:edit(edit)/:escalationSchemaUuid(.*)'}
                       render={() => <Domain component={checkRoles(EditEscalationSchemaPage, ['SP_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_ESCALATION_SCHEMA_PATH + '/:view(view)/:escalationSchemaUuid(.*)'}
                       render={() => <Domain component={checkRoles(ViewEscalationSchemaPage, ['SP_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_ESCALATION_SCHEMA_LIST_PATH}
                       render={() => <Domain component={checkRoles(ListEscalationSchemaPage, ['SP_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_ANSWERING_SCHEMA_NEW_PATH}
                       render={() => <Domain component={checkRoles(NewAnsweringSchemaPage, ['SP_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_ANSWERING_SCHEMA_PATH + '/:edit(edit)/:answeringSchemaUuid(.*)'}
                       render={() => <Domain component={checkRoles(EditAnsweringSchemaPage, ['SP_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_ANSWERING_SCHEMA_PATH + '/:view(view)/:answeringSchemaUuid(.*)'}
                       render={() => <Domain component={checkRoles(ViewAnsweringSchemaPage, ['SP_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_ORDER_ANSWERING_SCHEMA_LIST_PATH}
                       render={() => <Domain component={checkRoles(ListAnsweringSchemaPage, ['SP_POWER_USER'])}/>}/>

                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_RECURRENT_PROBLEM_PATH}
                       render={() => <Domain component={checkRoles(PopUpPage, ['TD_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_MAIN_PAGE_PATH}
                       render={() => <Domain component={checkRoles(MainPageAlertPage, ['TD_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_WORKING_HOUR_PATH}
                       render={() => <Domain component={checkRoles(WorkingHoursPage, ['TD_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_DEALER_NOTIFICATION_PATH}
                       render={() => <Domain component={checkRoles(NotificationPage, ['TD_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_TICKET_SETTING_PATH}
                       render={() => <Domain component={checkRoles(TicketSettingsPage, ['TD_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_DEFAULT_COUNTRY_ROLE_PATH}
                       render={() => <Domain component={checkRoles(DefaultCountryRolePage, ['TD_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_ADMIN_PATH}
                       render={() => <Domain component={checkRoles(AdminRightsManagementPage, ['TD_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_DEALER_PATH}
                       render={() => <Domain component={checkRoles(DealerRightsManagementPage, ['TD_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_EDITOR_PATH}
                       render={() => <Domain component={checkRoles(EditorRightsManagementPage, ['TD_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_ESCALATION_LEVELS_PATH}
                       render={() => <Domain component={checkRoles(EscalationLevelPage, ['TD_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_NEW_PATH}
                       render={() => <Domain component={checkRoles(NewSolutionGroupPage, ['TD_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_PATH + '/:edit(edit)/:solutionGroupUuid(.*)'}
                       render={() => <Domain component={checkRoles(EditSolutionGroupPage, ['TD_POWER_USER', 'TD_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_PATH + '/:view(view)/:solutionGroupUuid(.*)'}
                       render={() => <Domain component={checkRoles(ViewSolutionGroupPage, ['TD_POWER_USER', 'TD_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_LIST_PATH}
                       render={() => <Domain component={checkRoles(ListSolutionGroupPage, ['TD_POWER_USER', 'TD_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_USER_PATH}
                       render={() => <Domain component={checkRoles(SolutionGroupUserRightsManagementPage, ['TD_POWER_USER', 'TD_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS_PATH}
                       render={() => <Domain component={checkRoles(ModelAnswersPage, ['TD_POWER_USER', 'TD_SG_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_NEW_PATH}
                       render={() => <Domain component={checkRoles(NewEscalationSchemaPage, ['TD_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_PATH + '/:edit(edit)/:escalationSchemaUuid(.*)'}
                       render={() => <Domain component={checkRoles(EditEscalationSchemaPage, ['TD_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_PATH + '/:view(view)/:escalationSchemaUuid(.*)'}
                       render={() => <Domain component={checkRoles(ViewEscalationSchemaPage, ['TD_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_LIST_PATH}
                       render={() => <Domain component={checkRoles(ListEscalationSchemaPage, ['TD_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_NEW_PATH}
                       render={() => <Domain component={checkRoles(NewAnsweringSchemaPage, ['TD_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_PATH + '/:edit(edit)/:answeringSchemaUuid(.*)'}
                       render={() => <Domain component={checkRoles(EditAnsweringSchemaPage, ['TD_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_PATH + '/:view(view)/:answeringSchemaUuid(.*)'}
                       render={() => <Domain component={checkRoles(ViewAnsweringSchemaPage, ['TD_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_LIST_PATH}
                       render={() => <Domain component={checkRoles(ListAnsweringSchemaPage, ['TD_POWER_USER'])}/>}/>


                <Route exact path={ADMINISTRATION_CLAIMS_RECURRENT_PROBLEM_PATH}
                       render={() => <Domain component={checkRoles(PopUpPage, ['CL_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_CLAIMS_MAIN_PAGE_PATH}
                       render={() => <Domain component={checkRoles(MainPageAlertPage, ['CL_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_CLAIMS_WORKING_HOUR_PATH}
                       render={() => <Domain component={checkRoles(WorkingHoursPage, ['CL_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_CLAIMS_DEALER_NOTIFICATION_PATH}
                       render={() => <Domain component={checkRoles(NotificationPage, ['CL_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_CLAIMS_TICKET_SETTING_PATH}
                       render={() => <Domain component={checkRoles(TicketSettingsPage, ['CL_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_CLAIMS_PRESET_RESPONSE_PATH}
                       render={() => <Domain component={checkRoles(PresetResponsePage, ['CL_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_CLAIMS_DEFAULT_COUNTRY_ROLE_PATH}
                       render={() => <Domain component={checkRoles(DefaultCountryRolePage, ['CL_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_CLAIMS_ADMIN_PATH}
                       render={() => <Domain component={checkRoles(AdminRightsManagementPage, ['CL_POWER_USER'])}/>}/>
                <Route exact path={ADMINISTRATION_CLAIMS_DEALER_PATH}
                       render={() => <Domain component={checkRoles(DealerRightsManagementPage, ['CL_ADMIN'])}/>}/>
                <Route exact path={ADMINISTRATION_CLAIMS_EDITOR_PATH}
                       render={() => <Domain component={checkRoles(EditorRightsManagementPage, ['CL_ADMIN'])}/>}/>

                <Route exact path={ADMINISTRATION_GENERIC_SOLUTION_GROUP_PATH}
                       component={SolutionGroupListPage}/>
                <Route exact path={ADMINISTRATION_GENERIC_FLOW_PATH}
                       component={FlowListPage}/>
                <Route exact path={ADMINISTRATION_GENERIC_FLOW_DETAIL_PATH}
                       render={(props) => <FlowDetailPage {...props}/>}/>

                <Route exact path={ADMINISTRATION_CLIENT_ID_PATH}
                       component={checkRoles(ClientIdPage, ['SP_ADMIN', 'TD_ADMIN', 'CL_ADMIN'])}/>
                <Route exact path={ADMINISTRATION_PATH}
                       component={checkRoles(AdministrationMainPage, ['SP_ADMIN', 'TD_ADMIN', 'CL_ADMIN', 'TD_POWER_USER', 'SP_POWER_USER', 'CL_POWER_USER', 'TD_SG_ADMIN', 'SP_SG_ADMIN'])}/>

                <Route component={NotFoundPage}/>
            </Switch>
        );
    }
}

Administration.propTypes = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(Administration);
