import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    INFORMATION: {
        id: 'common.support.header.information',
        defaultMessage: 'Information'
    },
    USER_CUSTOMIZATION: {
        id: 'common.support.header.userCustomization',
        defaultMessage: 'User customization'
    },
    MULTI_SOURCING_COUNTRIES: {
        id: 'common.support.header.multiSourcingCountries',
        defaultMessage: 'Multisourcing countries'
    },
    VERSION: {
        id: 'common.support.version',
        defaultMessage: 'Version'
    },
    CALENDAR_VERSION: {
        id: 'common.support.calendarVersion',
        defaultMessage: 'Calendar version'
    },
    RELEASE_DATE: {
        id: 'common.support.releaseDate',
        defaultMessage: 'Release date'
    },
    RESP_HELP_DESK: {
        id: 'common.support.responsibleHelpDesk',
        defaultMessage: 'Responsible HELP-DESK'
    },
    PHONE: {
        id: 'common.support.phone',
        defaultMessage: 'Phone'
    },
    EMAIL: {
        id: 'common.support.email',
        defaultMessage: 'E-mail'
    },
    LANGUAGE: {
        id: 'common.support.language',
        defaultMessage: 'Language'
    },
    PROFILE: {
        id: 'common.support.profile',
        defaultMessage: 'Profile'
    },
    SWITCH_PROFILE: {
        id: 'common.support.button.switchProfile',
        defaultMessage: 'Switch Profile'
    },
    PROFILE_CHANGE_INITIATED: {
        id: 'common.support.profileChangeInitiated',
        defaultMessage: 'Profile change initiated! Page will be reloaded.'
    },
    LOCATION: {
        id: 'common.support.location',
        defaultMessage: 'Location'
    },
    TIMEZONE: {
        id: 'common.support.timezone',
        defaultMessage: 'Time zone'
    },
});

export default messages;
