import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedDate, FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import countryGroupMessages from '../../intl/common/countryGroupMessages';
import mainPageMessages from '../../intl/home/mainPageMessages';

const MessageWrapper = styled.div`
  white-space: pre-wrap;
`;

const Footer = styled.div`
  font-style: italic;
`;

function MainMessage({domain, mainDomainMessages}) {
    const mainMessages = mainDomainMessages[domain];
    if (!mainMessages || mainMessages.length === 0) {
        return (
            <div className="pb-5">
                <FormattedMessage {...mainPageMessages.MESSAGES_EMPTY} />
            </div>
        );
    }
    return (
        <>
            {mainMessages.map((mainMessage, index) =>
                <div key={`mainMessage${index}`} className="card mb-1">
                    <div className="card-header">
                        <FormattedMessage {...(countryGroupMessages[mainMessage.countryGroup])} />
                    </div>
                    <div className="card-body">
                        <MessageWrapper>
                            {mainMessage.text}
                        </MessageWrapper>
                        <Footer>
                                <span className="pr-2">
                                    <FormattedMessage {...mainPageMessages.LAST_UPDATED} />{':'}
                                </span>
                            {mainMessage.userName} - <FormattedDate value={new Date(mainMessage.updated)}/>
                        </Footer>
                    </div>
                </div>,
            )}
        </>
    );
}

MainMessage.propTypes = {
    domain: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    mainDomainMessages: state.adminMainMessage.mainMessages,
});

export default connect(mapStateToProps, {})(MainMessage);
