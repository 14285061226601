import labels from '../labels';
import {ADMIN_USER_SETTINGS_SEND} from '../actions/actionSupport';
import {USER_ROLE_FETCH_SUCCESS} from '../actions/actionRole';
import {languages, locations} from '../constants/Utils';

export const intl = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_USER_SETTINGS_SEND:
        case USER_ROLE_FETCH_SUCCESS: {
            const {settings = {}} = action.payload;
            const {language = languages.includes(state.language) ? state.language : 'en', locale = locations.includes(state.locale) ? state.locale : 'en-GB'} = settings;
            return Object.assign({}, state, {
                language,
                locale,
                messages: labels[language],
            });
        }
        default:
            return state;
    }
};

