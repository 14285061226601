import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const SPARE_PARTS_INTERNAL_TICKET_HISTORY_FETCH_PENDING = 'SPARE_PARTS_INTERNAL_TICKET_HISTORY_FETCH_PENDING';

export const fetchSparePartsInternalTicketHistoryPageData = (pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    dispatch({
        type: SPARE_PARTS_INTERNAL_TICKET_HISTORY_FETCH_PENDING,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_INTERNAL_TICKET_HISTORY_GET,
        payload: {pageSize: pageSize, sorted: sorted, cursor, isNextFromPivot, filtered: filtered}
    });
};
