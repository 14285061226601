import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import flowMessages from '../../../intl/admin/flowMessages';
import {addSavedFlowToFlowList, deleteFlow, fetchFlowListPageData} from '../../../actions/actionFlow'
import Loader from '../../Loader';
import FlowTable from './FlowTable';
import ConfirmationDialog from '../../common/modal/ConfirmationDialog';
import {DETAIL_PATH} from '../../../routes/paths';
import {PrimaryButtonRoute} from './ButtonRoute';
import PageHeader from '../../common/PageHeader';
import {flowReducer} from '../../../reducers/flowReducer';

class FlowListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenDeleteDialog: false,
            flowIdToBeDeleted: null
        }
    }

    componentDidMount() {
        this.props.fetchFlowListPageData();
    }

    componentDidUpdate() {
        const {isLoading, savedFlow, addSavedFlowToFlowList} = this.props;
        if (!isLoading && Object.keys(savedFlow).length > 0) {
            addSavedFlowToFlowList();
        }
    }

    handleDeleteClick = flow => {
        this.setState({
            isOpenDeleteDialog: true,
            flowIdToBeDeleted: flow.id
        });
    };

    closeDeleteModal = e => {
        e.preventDefault();
        this.setState({
            isOpenDeleteDialog: false,
            flowIdToBeDeleted: null
        });
    };

    saveDeleteModal = e => {
        e.preventDefault();
        this.props.deleteFlow(this.state.flowIdToBeDeleted);
        this.setState({
            isOpenDeleteDialog: false,
            flowIdToBeDeleted: null
        });
    };

    render() {
        const {isLoading} = this.props;
        if (isLoading) {
            return <Loader/>;
        }

        return (
            <div>
                <PageHeader title={<FormattedMessage {...flowMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="row mb-3">
                        <div className="col">
                            <PrimaryButtonRoute path={DETAIL_PATH}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <FlowTable handleDeleteClick={this.handleDeleteClick}/>
                        </div>
                    </div>
                </div>
                <ConfirmationDialog show={this.state.isOpenDeleteDialog} closeModal={this.closeDeleteModal}
                                    saveModal={this.saveDeleteModal} actionIdentifier="delete the Flow"/>
            </div>
        );
    }
}

FlowListPage.propTypes = {
    fetchFlowListPageData: PropTypes.func.isRequired,
    deleteFlow: PropTypes.func.isRequired,
    addSavedFlowToFlowList: PropTypes.func.isRequired,
    savedFlow: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.flowReducer.isLoading,
    savedFlow: state.flowReducer.savedFlow
});

export default connect(mapStateToProps, {
    fetchFlowListPageData,
    deleteFlow,
    addSavedFlowToFlowList
})(FlowListPage);
