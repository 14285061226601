import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    NORMAL_HEATING: {
        id: 'techDoc.airConditioning.normalHeating',
        defaultMessage: 'Normal heating'
    },
    NORMAL_AIRCON: {
        id: 'techDoc.airConditioning.normalAircon',
        defaultMessage: 'Normal aircon'
    }
});

export default messages;
