import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styled from "styled-components";
import 'react-picky/dist/picky.css';
import {withRouter} from 'react-router-dom';
import PageHeader from '../../common/PageHeader';
import checkRoles from '../../common/roleChecker/RoleChecker';
import managementGroupMessages from "../../../intl/sparePartsOrders/managementGroupMessages";
import {useEffect} from 'react';
import ManagementGroupOrderForm from "./ManagementGroupOrderForm";
import Loader from "../../Loader";
import {
    checkOrderManagementGroupBatch,
    initiateOrderManagementGroupBatch
} from "../../../actions/orders/actionSparePartsManagementGroup";

const Wrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
`;

const ManagementGroupOrderPage = (props) => {
    useEffect(() => {
        props.checkOrderManagementGroupBatch();
    }, []);

    const handleSubmit = (values) => {
        props.initiateOrderManagementGroupBatch(values.spareParts);
    };

    if (props.isLoadingBatchInfo) {
        return <Loader/>;
    }

    return (
        <div>
            <PageHeader title={<FormattedMessage {...managementGroupMessages.TITLE}/>}/>
            <Wrapper classname="container-fluid">
                <ManagementGroupOrderForm
                    onSubmit={handleSubmit}
                    enableReinitialize={false}
                    form='sparePartsManagementGroupForm'/>
            </Wrapper>
        </div>
    )
};

ManagementGroupOrderPage.propTypes = {
    intl: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    isLoadingBatchInfo: state.sparePartsManagementGroupBatch.isLoadingBatchInfo,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    initiateOrderManagementGroupBatch,
    checkOrderManagementGroupBatch,
})(injectIntl(ManagementGroupOrderPage))), ['SP_SG_ADMIN']);

