import v4 from 'uuid/v4';
import {SPARE_PARTS_ORDERS_GET_STATISTICS} from '../../constants/websocketCommands';

export const SPARE_PARTS_ORDERS_STATISTICS_PENDING = 'SPARE_PARTS_ORDERS_STATISTICS_PENDING';

export const fetchStatistics = ({ dealerNumber, countryGroups, solutionGroups }) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_ORDERS_STATISTICS_PENDING,
        sparePartsWebsocket: true,
        command: SPARE_PARTS_ORDERS_GET_STATISTICS,
        payload: { dealerNumber, countryGroups, solutionGroups, correlationId: v4() }
    });
};
