import React, {Component, useEffect} from 'react';
import styled from 'styled-components';
import {change, formValueSelector, getFormInitialValues} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {
    BUTTON_LABEL_LOAD,
    FORM_BATTERY_PROBLEM_DESCRIPTION_SECTION,
    FORM_BUTTON_ESCALATE,
    FORM_FIELD_AVAILABILITY_SECOND_HAND_STOCK,
    FORM_FIELD_BATTERY_AGE,
    FORM_FIELD_BATTERY_PROBLEM_TYPE,
    FORM_FIELD_CODE,
    FORM_FIELD_DESCRIPTION,
    FORM_FIELD_INVOICE_NUMBER,
    FORM_FIELD_LINE,
    FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_ORDER,
    FORM_FIELD_PART_NO,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_QUANTITY,
    FORM_FIELD_VEHICLE_MODEL,
    FORM_FIELD_VIN,
    FORM_LABEL_AVAILABILITY_SECOND_HAND_STOCK,
    FORM_LABEL_BATTERY_PROBLEM_TYPE,
    FORM_LABEL_DEALER_ORDER,
    FORM_LABEL_DESCRIPTION,
    FORM_LABEL_INVOICE_NUMBER,
    FORM_LABEL_LINE,
    FORM_LABEL_MANAGEMENT_GROUP,
    FORM_LABEL_ORDER,
    FORM_LABEL_PART_NO,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_PRODUCT_CODE,
    FORM_LABEL_QUANTITY,
    FORM_LABEL_VEHICLE_MODEL,
    FORM_LABEL_VIN,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import {
    positiveNumber,
    positiveNumberWithZero, validAlphanumericInput, validVinLength
} from '../../../../../common/validationCommons';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import get from 'get-value';
import {batteryProblemTypes, batteryTypes, secondHandStock} from '../../../../../../constants/Utils';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {StyledProblemDescTh, StyledSpan} from '../../../../../common/StyledComponents';
import {
    StyledColumnHeaderThead,
    StyledHeaderThead
} from '../../../../../technicalDocumentations/ticket/forms/sections/problemDescription/ProblemDescriptionCodedPartsSection';
import batteryProblemTypeMessages from '../../../../../../intl/sparePartsOrders/batteryProblemTypeMessages';
import {
    ORDER_FIELD_MAX_LENGTH,
    ORDER_FIELD_MAX_LENGTH_AR,
    ORDER_FIELD_MAX_LENGTH_RU
} from '../../../../../../constants/validationConstants';
import InputFieldGroupWithValidation from '../../../../../common/InputFieldGroupWithValidation';
import {
    fetchDicSparePartInfoActionCreator,
    setDicSparePartInfoActionCreator
} from '../../../../../../actions/orders/actionSparePartsDicData';
import warnMessages from '../../../../../../intl/common/warnMessages';
import {warnAlert} from '../../../../../../actions/alertsActions';
import RadioFields from '../../../../../common/RadioFields';
import commonMessages from '../../../../../../intl/common/commonMessages';
import {PrimaryButton} from '../../../../../common/Button';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import Loader from '../../../../../Loader';
import {moduleRoles} from '../../../../../../utils/roles';
import {isEmpty} from 'lodash';

const StyledDiv = styled.div`
  border-radius: 5px;
`;
const StyledBorderDiv = styled.div`
  border-color: #ced4da;
`;


class BatteryProblemDescriptionSection extends Component {

    handleOrderPartLoad = () => {
        const {fetchDicSparePartInfoActionCreator, orders} = this.props;
        fetchDicSparePartInfoActionCreator(orders);
    };

    fillDicSparePartInfo = () => {
        const {dicInfo, change, setDicSparePartInfoActionCreator, warnAlert, userRights} = this.props;
        const order = dicInfo.partNo;
        setDicSparePartInfoActionCreator();
        if (order) {
            if(userRights.canEditBatteryProblemDesc) {
                if (!order[FORM_FIELD_DESCRIPTION]) {
                    change(`${FORM_BATTERY_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_DESCRIPTION}`, dicInfo.partDescription.trim());
                } else {
                    warnAlert(warnMessages.SP_DIC_WARN002, null, {partNumber: dicInfo.partNo})
                }
            }
            if (!order[FORM_FIELD_MANAGEMENT_GROUP]) {
                change(`${FORM_BATTERY_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_MANAGEMENT_GROUP}`, dicInfo.managementGroup);
            } else {
                warnAlert(warnMessages.SP_DIC_WARN004, null, {partNumber: dicInfo.partNo})
            }
        } else {
            warnAlert(warnMessages.SP_DIC_WARN003, null, {partNumber: dicInfo.partNo});
        }
    };

    render() {
        const { userRights, reqFields, readOnly, initialValues, user, user: {roles}, dicInfo, solutionGroupInfo = {}, submittedBy } = this.props;

        if (dicInfo.dicPartInfoLoaded) {
            this.fillDicSparePartInfo();
        }

        return (
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.BATTERY_PROBLEM_DESCRIPTION_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                    <div className="form-row">
                        <div className="col">
                            <StyledBorderDiv className="table-responsive border rounded-top">
                                <table className="table table-hover mb-0">
                                    <StyledHeaderThead>
                                        <tr>
                                            <StyledProblemDescTh colSpan="6" className="font-weight-bold py-0">
                                                <ValidFormattedMessage message={FORM_LABEL_DEALER_ORDER}
                                                                       intlMessages={formMessages}/>
                                            </StyledProblemDescTh>
                                        </tr>
                                    </StyledHeaderThead>
                                    <StyledColumnHeaderThead>
                                        <tr>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_PRODUCT_CODE}
                                                                       intlMessages={formMessages}/>
                                                {reqFields.includes(FORM_FIELD_CODE) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                                            </StyledProblemDescTh>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_ORDER}
                                                                       intlMessages={formMessages}/>
                                                {reqFields.includes(FORM_FIELD_ORDER) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                                            </StyledProblemDescTh>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_LINE}
                                                                       intlMessages={formMessages}/>
                                                {reqFields.includes(FORM_FIELD_LINE) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                                            </StyledProblemDescTh>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_PART_NO}
                                                                       intlMessages={formMessages}/>
                                                {reqFields.includes(FORM_FIELD_PART_NO) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                                            </StyledProblemDescTh>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_DESCRIPTION}
                                                                       intlMessages={formMessages}/>
                                                {reqFields.includes(FORM_FIELD_DESCRIPTION) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                                            </StyledProblemDescTh>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_QUANTITY}
                                                                       intlMessages={formMessages}/>
                                                {reqFields.includes(FORM_FIELD_QUANTITY) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                                            </StyledProblemDescTh>
                                        </tr>
                                    </StyledColumnHeaderThead>
                                    <tbody>
                                    <tr>
                                        <td style={{maxWidth: '120px', minWidth: '150px'}}>
                                            <InputFieldWithValidation field={FORM_FIELD_CODE}
                                                                      maxLength={3}
                                                                      isDisabled={!userRights.canEditBatteryProblemDesc || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_CODE)}
                                                                      isSmall/>
                                        </td>
                                        <td style={{maxWidth: '130px', minWidth: '100px'}}>
                                            <InputFieldWithValidation field={FORM_FIELD_ORDER}
                                                                      maxLength={user.group === "AR" ? ORDER_FIELD_MAX_LENGTH_AR : (user.group === "RU" ? ORDER_FIELD_MAX_LENGTH_RU : ORDER_FIELD_MAX_LENGTH)}
                                                                      validations={[positiveNumber]}
                                                                      isDisabled={!userRights.canEditBatteryProblemDesc || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_ORDER)}
                                                                      isSmall/>
                                        </td>
                                        <td style={{maxWidth: '130px', minWidth: '100px'}}>
                                            <InputFieldWithValidation field={FORM_FIELD_LINE}
                                                                      maxLength={6}
                                                                      validations={[positiveNumberWithZero]}
                                                                      isDisabled={!userRights.canEditBatteryProblemDesc || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_LINE)}
                                                                      isSmall/>
                                        </td>
                                        <td style={{maxWidth: '200px', minWidth: '130px'}}>
                                            <InputFieldGroupWithValidation field={FORM_FIELD_PART_NO}
                                                                      maxLength={10}
                                                                      isDisabled={!userRights.canEditBatteryProblemDesc || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_PART_NO)}
                                                                      loadOrderData={() => {
                                                                          this.handleOrderPartLoad();
                                                                      }}
                                                                      isSmall/>
                                        </td>
                                        <td style={{minWidth: '130px'}}>
                                            <InputFieldWithValidation field={FORM_FIELD_DESCRIPTION}
                                                                      maxLength={20}
                                                                      isDisabled={!userRights.canEditBatteryProblemDesc || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_DESCRIPTION)}
                                                                      isSmall/>
                                        </td>
                                        <td style={{maxWidth: '100px', minWidth: '100px'}}>
                                            <InputFieldWithValidation field={FORM_FIELD_QUANTITY}
                                                                      maxLength={1}
                                                                      isDisabled={!userRights.canEditBatteryProblemDesc || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_QUANTITY)}
                                                                      validations={[positiveNumber]}
                                                                      isSmall/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </StyledBorderDiv>
                        </div>
                    </div>
                    { moduleRoles.isSGEditorOrder(roles) &&
                        <div className="form-row">
                            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 pb-2">
                                <div className="form-row align-items-end">
                                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                                        <InputFieldWithValidation label={formMessages[FORM_LABEL_MANAGEMENT_GROUP]}
                                                                  field={FORM_FIELD_MANAGEMENT_GROUP}
                                                                  maxLength={2}
                                                                  isDisabled={!(userRights.canEditManagementGroup) || readOnly}
                                                                  isRequired={(isEmpty(solutionGroupInfo) ? (reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP)) : (reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP) && !get(solutionGroupInfo, "groups", []).length == 0)) && submittedBy === FORM_BUTTON_ESCALATE && !readOnly}
                                                                  validations={[validAlphanumericInput]}
                                                                  isSmall/>
                                    </div>
                                    {userRights.canEditManagementGroup && !readOnly &&
                                        <>
                                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 pt-1">
                                                <PrimaryButton type="button" className="btn"
                                                               onClick={this.handleOrderPartLoad}
                                                    >
                                                    <ValidFormattedMessage message={BUTTON_LABEL_LOAD}
                                                                           intlMessages={buttonMessages}/>
                                                </PrimaryButton>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <div className="form-row">
                        <div className="col-xl-4 col-lg-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_INVOICE_NUMBER]}
                                                      field={FORM_FIELD_INVOICE_NUMBER}
                                                      validations={[positiveNumber]}
                                                      isDisabled={!userRights.canEditBatteryInvoiceNumber || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_INVOICE_NUMBER) && !readOnly}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-sm-12 pb-2">
                            <RadioFields
                                label={formMessages[FORM_LABEL_AVAILABILITY_SECOND_HAND_STOCK]}
                                field={FORM_FIELD_AVAILABILITY_SECOND_HAND_STOCK}
                                options={secondHandStock}
                                isDisabled={!userRights.canEditBatteryInvoiceNumber || readOnly}
                                // isRequired={reqFields.includes(FORM_FIELD_AVAILABILITY_SECOND_HAND_STOCK) && !readOnly}
                                intlMessages={commonMessages}
                                disabledStyle={{color:'#DCDCDC'}}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-xl-4 col-lg-6 col-sm-12 pb-2">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_BATTERY_PROBLEM_TYPE]}
                                                            field={FORM_FIELD_BATTERY_PROBLEM_TYPE}
                                                            options={get(batteryProblemTypes, `${get(initialValues, 'ticketSection.group', {default: 'X'})}`, {default: []})}
                                                            intlMessages={batteryProblemTypeMessages}
                                                            isDisabled={!userRights.canEditBatteryProblemDesc || readOnly}
                                                            isRequired={reqFields.includes(FORM_FIELD_BATTERY_PROBLEM_TYPE)}
                                                            sort={false}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-12 pr-0 pb-1">
                            <TextareaFieldWithValidation label={formMessages[FORM_LABEL_PROBLEM_DESCRIPTION]}
                                                         field={FORM_FIELD_PROBLEM_DESCRIPTION}
                                                         isDisabled={!userRights.canEditBatteryProblemDesc || readOnly}
                                                         rows={6}
                                                         isRequired={reqFields.includes(FORM_FIELD_PROBLEM_DESCRIPTION)}/>
                        </div>
                    </div>
                </StyledDiv>
            </>
        )
    }
}

const mapStateToProps = (state, initialProps) => ({
    createdInDic: state.sparePartsOrderTicket.ticket.createdInDic,
    processingDic: state.sparePartsDic.isCreateOrUpdateDicLoading,
    initialValues: getFormInitialValues(initialProps.form)(state),
    user: state.profile.userDetail,
    orders: formValueSelector(initialProps.form)(state, `${FORM_BATTERY_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_PART_NO}`),
    dicInfo: state.sparePartsDic,
    solutionGroupInfo: state.sparePartsOrderTicket.solutionGroupInfo,
    submittedBy: state.sparePartsOrderTicket.submittedBy,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            setDicSparePartInfoActionCreator,
            fetchDicSparePartInfoActionCreator,
            warnAlert,
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatteryProblemDescriptionSection);
