import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.defaultCountryRole.title',
        defaultMessage: 'Default country role'
    },
    SELECT_COUNTRY: {
        id: 'admin.defaultCountryRole.selectCountry',
        defaultMessage: 'Select a country'
    },
});

export default messages;
