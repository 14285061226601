import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {importerCountries} from '../../constants/Utils';
import Select from 'react-select';
import styled from 'styled-components';
import countryGroupMessages from '../../intl/common/countryGroupMessages';

const StyledDiv = styled.div`
    padding: 10px;
    border: 1px;
    border-style: solid;
    border-color: #e9e9e9;
    border-radius: 0px 4px 4px 4px;
`;

const StyledUl = styled.ul`
    border-bottom: none;
`;

const styles = {
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? {...base, display: 'none'} : base;
    },
};
const ImportersTable = props => {
    const options = importerCountries.map(country => Object.assign({}, {
        value: country,
        label: countryGroupMessages[country] ? props.intl.formatMessage(countryGroupMessages[country]) : country,
        isFixed: true
    })).sort((countryA, countryB) => countryA.label >= countryB.label ? 1 : -1);
    return <div className="mt-3 mb-5">
        <StyledUl className="nav nav-tabs">
            <li key={'0'}>
                <div className={`nav-link active`}>
                    <FormattedMessage {...countryGroupMessages.IMP} />
                </div>
            </li>
        </StyledUl>
        <StyledDiv>
            <Select isMulti
                    styles={styles}
                    isDisabled={true}
                    isLoading={false}
                    isClearable={false}
                    value={options}/>
        </StyledDiv>
    </div>
};

export default injectIntl(ImportersTable);
