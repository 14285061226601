import { techDocWebsocketCommands } from '../../constants/techDocWebsocketCommands';
import {domains} from '../../constants/Utils';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';



export const fetchEscalationSchemaSGOptions = (domain) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: techDocWebsocketCommands.TECH_DOC_ADMIN_SOLUTION_GROUPS_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_ADMIN_SOLUTION_GROUPS_OPTIONS,
                payload: {}
            });
        }
        case domains.SPARE_PARTS: {
            return dispatch({
                type: sparePartsWebsocketCommands.SPARE_PARTS_ADMIN_SOLUTION_GROUPS_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ADMIN_SOLUTION_GROUPS_OPTIONS,
                payload: {}
            });
        }
    }
};
