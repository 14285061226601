import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import moment from 'moment';
import Loader from '../../Loader';
import {DOC_EXPORT_FORM_FIELD_CHARSET, DOC_EXPORT_FORM_FIELD_TYPE, XLS} from '../../../constants/exportConstant';
import {FORM_FIELD_PFX, FORM_FIELD_SFX} from '../../../constants/formConstants';
import PageHeader from '../../common/PageHeader';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {moduleRoles} from '../../../utils/roles';
import InternalTicketExportTechDocForm from './InternalTicketExportTechDocForm';
import {
    createTechDocExportInternal, downloadExportInternal,
    fetchTechDocExportInternal
} from '../../../actions/techDoc/actionDocInternalTicketExport';

class InternalTicketExportTechDocPage extends Component {
    componentDidMount() {
        this.props.fetchTechDocExportInternal();
    }

    handleSubmit = (values) => {
        const temp = JSON.parse(JSON.stringify((({isAssignedTo, hasBeenAssignedTo, requestType, partner, vehicleFamily, nReferenceCausingProblem,  status}) => {
            return ({
                isAssignedTo,
                hasBeenAssignedTo,
                requestType,
                partner,
                vehicleFamily,
                nReferenceCausingProblem,
                status,
            });
        })(values)));

        const exportFilter = {};
        exportFilter.filter = [];
        for (let key in temp) {
            if (temp.hasOwnProperty(key)) {
                if (key === 'lastEditor') {
                    temp[key].split(' ').forEach(element => exportFilter.filter.push({id: key, value: element}));

                } else {
                    exportFilter.filter.push({id: key, value: temp[key]})
                }
            }
        }

        if (values.from) {
            exportFilter.from = moment(values.from).add(12, 'hours').toISOString().substr(0, 10);
        }
        if (values.to) {
            exportFilter.to = moment(values.to).add(12, 'hours').toISOString().substr(0, 10);
        }
        exportFilter.type = values.type;
        if (!exportFilter.type) {
            exportFilter.type = XLS;
        }
        exportFilter.charset = values.charset;
        this.props.createTechDocExportInternal(exportFilter);
    };

    downloadExport = () => {
        this.props.downloadExportInternal(this.props.exportRecord);
    };

    render() {
        const {isLoadingExport, charSets} = this.props;

        if ( isLoadingExport) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage id="techDoc.internal.export.header" defaultMessage="Export"/>}/>
                <InternalTicketExportTechDocForm onSubmit={this.handleSubmit}
                               form={'internalTicketDocExportForm'}
                               downloadExport={this.downloadExport}
                               initialValues={{
                                   [DOC_EXPORT_FORM_FIELD_TYPE]: XLS,
                                   [DOC_EXPORT_FORM_FIELD_CHARSET]: charSets[0]
                               }}
                />
            </div>
        );
    }
}

InternalTicketExportTechDocPage.propTypes = {
    isLoadingExport: PropTypes.bool.isRequired,
    exportRecord: PropTypes.object.isRequired,
    fetchTechDocExportInternal: PropTypes.func.isRequired,
    createTechDocExportInternal: PropTypes.func.isRequired,
    downloadExportInternal: PropTypes.func.isRequired,
    charSets: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    isLoadingExport: state.docExportInternalTickets.isLoadingExport,
    exportRecord: state.docExportInternalTickets.exportRecord,
    charSets: state.profile.userDetail.charSets,
    roles: state.profile.userDetail.roles,
});

export default checkRoles(connect(mapStateToProps, {
    fetchTechDocExportInternal,
    createTechDocExportInternal,
    downloadExportInternal,
})(injectIntl(InternalTicketExportTechDocPage)), ['TD_SG_EDITOR']);
