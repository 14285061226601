import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const fetchClaimsDealers = () => (dispatch) => {
    dispatch({
        type: claimsWebsocketCommands.CLAIMS_DEALERS_GET,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_DEALERS_GET,
        payload: {}
    });
};
