import {FILE, YOUTUBE_VIDEO} from '../../HelpConst';

const values = [
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - Comment utiliser le menu Liste ?',
        href: 'https://youtu.be/17wEhh6uq0A'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - Comment utiliser l\'historique ?',
        href: 'https://youtu.be/9kyq2XB29VE'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - Comment trouver un ticket spécifique à partir du moteur de recherche  ?',
        href: 'https://youtu.be/T9HSANB8hpE'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order module - Comment exporter des tickets en fichier excel disponible seulement pour les Administrateurs?',
        href: 'https://youtu.be/DqNQCCsd3gs'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - Comment traiter un ticket - Expertise Local ?',
        href: 'https://youtu.be/81eIDwf7TSQ'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - Comment traiter un ticket -  RCL centrale niveau 2',
        href: 'https://youtu.be/wnycMp55DnU'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL Order Module - Comment traiter un ticket  si vous êtes "Business Corporate level 3" ?',
        href: 'https://youtu.be/ZhM732EFpZE'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'Comment ajouter ou supprimer un utilisateur',
        href: 'https://youtu.be/PAfPG_k7LPk'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'Comment savoir où en est le traitement de mon ticket ?',
        href: 'https://youtu.be/PSa2KCF9jBA'
    },
];

export default values;
