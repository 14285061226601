import React from 'react';
import styled from 'styled-components';
import renaultLogo from '../images/RENAULT_LOGO_english.png';
import help from '../images/question.png';
import {Flex, Image, Margin, Padding} from './common/StyledComponents';
import {NavLink} from 'react-router-dom';
import {HELP_PATH} from '../routes/paths';
import {FormattedMessage} from 'react-intl';
import commonMessages from '../intl/common/commonMessages';
import {connect} from 'react-redux';
import {moduleRoles} from '../utils/roles';
import {domains} from '../constants/Utils';

const Title = styled.h1`
    margin: 0;
    margin-top: 5px;
`;
Title.displayName = 'Title';

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: white;
    padding: 8px;

    @media (max-height: 450px) {
        display: none;
    };
`;

const isNotOnlyDealer = (roles) => {
    const techDoc = domains.TECHNICAL_DOCUMENTATION;
    const claims = domains.CLAIMS;
    const spareParts = domains.SPARE_PARTS;
    return moduleRoles.isAdmin(roles, techDoc)
        || moduleRoles.isAdmin(roles,  claims)
        || moduleRoles.isAdmin(roles, spareParts)
        || moduleRoles.isPowerUser(roles, techDoc)
        || moduleRoles.isPowerUser(roles,  claims)
        || moduleRoles.isPowerUser(roles, spareParts)
        || moduleRoles.isEditor(roles, techDoc)
        || moduleRoles.isEditor(roles,  claims)
        || moduleRoles.isEditor(roles, spareParts)
        || moduleRoles.isSGEditor(roles, techDoc)
        || moduleRoles.isSGEditor(roles,  claims)
        || moduleRoles.isSGEditor(roles, spareParts)
        || moduleRoles.isSGAdmin(roles, techDoc)
        || moduleRoles.isSGAdmin(roles,  claims)
        || moduleRoles.isSGAdmin(roles, spareParts)
}

const Header = ({roles}) => (
    <HeaderWrapper className="">
        <Flex>
            <Margin left={15}>
                <Image height={60} src={renaultLogo}/>
            </Margin>
            <Margin left={15}>
                <Title>Dialog: New Hotline</Title>
            </Margin>
        </Flex>
        {isNotOnlyDealer(roles) && <Help/>}
    </HeaderWrapper>
);

const Help = () => {
    return <Margin top={13} right={20}>
        <Flex>
            <NavLink to={HELP_PATH}>
                <Image width={25} height={25} src={help}/>
            </NavLink>
            <Margin left={5}><FormattedMessage {...commonMessages.HELP}/></Margin>
        </Flex>
    </Margin>
}

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
});

export default connect(mapStateToProps)(Header);
