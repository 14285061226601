import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const createTechDocExport = (exportFilter) => (dispatch) => {
    dispatch({
        type: techDocWebsocketCommands.TECH_DOC_EXPORT_CREATE,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_EXPORT_CREATE,
        payload: {
            exportFilter
        },
    });
};

export const fetchTechDocExport = () => (dispatch) => {
    dispatch({
        type: techDocWebsocketCommands.TECH_DOC_EXPORT_GET,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_EXPORT_GET,
        payload: {},
    });
};

export const downloadExport = (exportRecord) => (dispatch) => {
    dispatch({
        type: techDocWebsocketCommands.TECH_DOC_EXPORT_DOWNLOAD_URL_GET,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_EXPORT_DOWNLOAD_URL_GET,
        payload: {
            exportRecord
        },
    });
};
