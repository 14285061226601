import FileSaver from 'file-saver';
import {errorAlert, infoAlert, warnAlert} from '../actions/alertsActions';
import {
    CONNECT_ADMIN_WEBSOCKET_PENDING,
    CONNECT_ADMIN_WEBSOCKET_SUCCESS,
    CONNECT_BVM_WEBSOCKET_PENDING,
    CONNECT_BVM_WEBSOCKET_SUCCESS,
    CONNECT_SPARE_PARTS_WEBSOCKET_PENDING,
    CONNECT_SPARE_PARTS_WEBSOCKET_SUCCESS,
    CONNECT_TECH_DOC_WEBSOCKET_PENDING,
    CONNECT_TECH_DOC_WEBSOCKET_SUCCESS,
    CONNECT_CLAIMS_WEBSOCKET_PENDING,
    CONNECT_CLAIMS_WEBSOCKET_SUCCESS,
} from '../actions/actionWebsocket';
import {
    SPARE_PARTS_ORDERS_ATTACHMENTS_DOWNLOAD,
    TECH_DOC_ATTACHMENTS_DOWNLOAD,
    CLAIMS_ATTACHMENTS_DOWNLOAD,
    TECH_DOC_INTERNAL_TICKET_ATTACHMENTS_DOWNLOAD,
    SPARE_PARTS_INTERNAL_TICKET_ATTACHMENTS_DOWNLOAD,
} from '../constants/websocketCommands';
import errorMessages from '../intl/common/errorMessages';
import warnMessages from '../intl/common/warnMessages';
import alertMessages from '../intl/common/alertMessages';
import {authenticateAndRedirect, getAuthenticatedUser} from '../utils/auth';
import buttonMessages from '../intl/common/buttonMessages';
import {modules} from '../constants/Utils';
import get from 'get-value';
import {sparePartsWebsocketCommands} from '../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../constants/claimsWebsocketCommands';
import {axiosFileProgressInstance} from '../api/rest';

const infoAlertUniqueId = '3466b8ae-0855-43a1-bd7c-257d2a79a3f8';
const webSockets = {};

export const createSparePartsSocketMiddleware = () => {
    return ({dispatch, getState}) => next => async action => {
        if (action.type === CONNECT_SPARE_PARTS_WEBSOCKET_PENDING) {
            webSockets[modules.SPARE_PARTS] = new WebSocket(`${process.env.REACT_APP_BE_SPARE_PARTS_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.SPARE_PARTS].onopen = () => dispatch({type: CONNECT_SPARE_PARTS_WEBSOCKET_SUCCESS});
            webSockets[modules.SPARE_PARTS].onmessage = async message => {
                let messageObj = JSON.parse(message.data);
                if (!messageObj.command) {
                    dispatch(errorAlert(alertMessages.SP_BE_UNREACHABLE, messageObj));
                } else {
                    if (messageObj.error) {
                        dispatch(errorAlert(errorMessages[get(messageObj, 'error.errCode', undefined)] ? errorMessages[messageObj.error.errCode] : messageObj.error,
                            messageObj, messageObj.error));
                    }
                    if (messageObj.warning) {
                        const {...messageWarnObj} = messageObj;
                        dispatch(warnAlert(warnMessages[get(messageObj, 'warning.warnCode', undefined)] ? warnMessages[messageObj.warning.warnCode] : messageObj.warning, messageWarnObj, messageObj.warning));
                    }
                    if (messageObj.command === SPARE_PARTS_ORDERS_ATTACHMENTS_DOWNLOAD) {
                        const {url, name} = messageObj.attachment;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, name),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    if (messageObj.command === sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_EXPORT_DOWNLOAD_URL_SEND) {
                        const {url, type} = messageObj.exportRecord;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, `export.${type}`),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    if (messageObj.command === SPARE_PARTS_INTERNAL_TICKET_ATTACHMENTS_DOWNLOAD) {
                        const {url, name} = messageObj.attachment;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, name),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    dispatch({
                        type: messageObj.command,
                        payload: {...messageObj}
                    });
                }
            };
            webSockets[modules.SPARE_PARTS].onclose = async () =>
                webSockets[modules.SPARE_PARTS] = await reinitializeClosedSocket(webSockets[modules.SPARE_PARTS], process.env.REACT_APP_BE_SPARE_PARTS_WS_URL, action, dispatch);
        }

        if (action.sparePartsWebsocket) {
            attemptWsCall(dispatch, getState, action, modules.SPARE_PARTS, alertMessages.SP_BE_UNREACHABLE);
        }

        return next(action);
    }
};

export const createTechDocSocketMiddleware = () => {
    return ({dispatch, getState}) => next => async action => {
        if (action.type === CONNECT_TECH_DOC_WEBSOCKET_PENDING) {
            webSockets[modules.TECHNICAL_DOCUMENTATION] = new WebSocket(`${process.env.REACT_APP_BE_TECH_DOC_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.TECHNICAL_DOCUMENTATION].onopen = () => dispatch({type: CONNECT_TECH_DOC_WEBSOCKET_SUCCESS});
            webSockets[modules.TECHNICAL_DOCUMENTATION].onmessage = async message => {
                let messageObj = JSON.parse(message.data);
                if (!messageObj.command) {
                    dispatch(errorAlert(alertMessages.TD_BE_UNREACHABLE, messageObj));
                } else {
                    if (messageObj.error) {
                        dispatch(errorAlert(errorMessages[get(messageObj, 'error.errCode', undefined)]
                            ? errorMessages[messageObj.error.errCode]
                            : messageObj.error, messageObj, messageObj.error));
                    }
                    if (messageObj.warning) {
                        const {...messageWarnObj} = messageObj;
                        dispatch(warnAlert(warnMessages[get(messageObj, 'warning.warnCode', undefined)]
                            ? warnMessages[messageObj.warning.warnCode]
                            : messageObj.warning, messageWarnObj, messageObj.warning));
                    }
                    if (messageObj.command === TECH_DOC_ATTACHMENTS_DOWNLOAD) {
                        const {url, name} = messageObj.attachment;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, name),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    if (messageObj.command === techDocWebsocketCommands.TECH_DOC_EXPORT_DOWNLOAD_URL_SEND
                        || messageObj.command === techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_DOWNLOAD_URL_SEND) {
                        const {url, type} = messageObj.exportRecord;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, `export.${type}`),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    if (messageObj.command === TECH_DOC_INTERNAL_TICKET_ATTACHMENTS_DOWNLOAD) {
                        const {url, name} = messageObj.attachment;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, name),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    dispatch({
                        type: messageObj.command,
                        payload: {...messageObj}
                    });
                }
            };
            webSockets[modules.TECHNICAL_DOCUMENTATION].onclose = async () =>
                webSockets[modules.TECHNICAL_DOCUMENTATION] = await reinitializeClosedSocket(webSockets[modules.TECHNICAL_DOCUMENTATION], process.env.REACT_APP_BE_TECH_DOC_WS_URL, action, dispatch);
        }
        if (action.techDocWebsocket) {
            attemptWsCall(dispatch, getState, action, modules.TECHNICAL_DOCUMENTATION, alertMessages.TD_BE_UNREACHABLE);
        }
        return next(action);
    }
};

export const createClaimsSocketMiddleware = () => {
    return ({dispatch, getState}) => next => async action => {
        if (action.type === CONNECT_CLAIMS_WEBSOCKET_PENDING) {
            webSockets[modules.CLAIMS] = new WebSocket(`${process.env.REACT_APP_BE_CLAIMS_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.CLAIMS].onopen = () => dispatch({type: CONNECT_CLAIMS_WEBSOCKET_SUCCESS});
            webSockets[modules.CLAIMS].onmessage = async message => {
                let messageObj = JSON.parse(message.data);
                if (!messageObj.command) {
                    dispatch(errorAlert(alertMessages.CL_BE_UNREACHABLE, messageObj))
                } else {
                    if (messageObj.error) {
                        dispatch(errorAlert(errorMessages[get(messageObj, 'error.errCode', undefined)]
                            ? errorMessages[messageObj.error.errCode]
                            : messageObj.error, messageObj, messageObj.error));
                    }
                    if (messageObj.warning) {
                        const {...messageWarnObj} = messageObj;
                        dispatch(warnAlert(warnMessages[get(messageObj, 'warning.warnCode', undefined)]
                            ? warnMessages[messageObj.warning.warnCode]
                            : messageObj.warning, messageWarnObj, messageObj.warning));
                    }
                    if (messageObj.command === CLAIMS_ATTACHMENTS_DOWNLOAD) {
                        const {url, name} = messageObj.attachment;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, name),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    if (messageObj.command === claimsWebsocketCommands.CLAIMS_EXPORT_DOWNLOAD_URL_SEND) {
                        const {url, type} = messageObj.exportRecord;
                        await axiosFileProgressInstance.get(url).then(
                            result => FileSaver.saveAs(result.data, `export.${type}`),
                            error => dispatch(errorAlert(alertMessages.FILE_DOWNLOAD_ERROR, error))
                        );
                    }
                    dispatch({
                        type: messageObj.command,
                        payload: {...messageObj}
                    });
                }
            };
            webSockets[modules.CLAIMS].onclose = async () =>
                webSockets[modules.CLAIMS] = await reinitializeClosedSocket(webSockets[modules.CLAIMS], process.env.REACT_APP_BE_CLAIMS_WS_URL, action, dispatch);
        }
        if (action.claimsWebsocket) {
            attemptWsCall(dispatch, getState, action, modules.CLAIMS, alertMessages.CL_BE_UNREACHABLE);
        }
        return next(action);
    }
};

export const createAdminSocketMiddleware = () => {
    return ({dispatch, getState}) => next => async action => {
        if (action.type === CONNECT_ADMIN_WEBSOCKET_PENDING) {
            webSockets[modules.ADMINISTRATION] = new WebSocket(`${process.env.REACT_APP_BE_ADMIN_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.ADMINISTRATION].onopen = () => dispatch({type: CONNECT_ADMIN_WEBSOCKET_SUCCESS});
            webSockets[modules.ADMINISTRATION].onmessage = async message => {
                let messageObj = JSON.parse(message.data);
                if (!messageObj.command) {
                    dispatch(errorAlert(alertMessages.ADM_BE_UNREACHABLE, messageObj));
                } else {
                    if (messageObj.error) {
                        dispatch(errorAlert(errorMessages[get(messageObj, 'error.errCode', undefined)] ? errorMessages[messageObj.error.errCode] : alertMessages.ADM_WS_STRUCT_ERROR,
                            messageObj, messageObj.error));
                    }
                    if (messageObj.warning) {
                        const {...messageWarnObj} = messageObj;
                        dispatch(warnAlert(warnMessages[get(messageObj, 'warning.warnCode', undefined)] ? warnMessages[messageObj.warning.warnCode] : messageObj.warning, messageWarnObj, messageObj.warning));
                    }
                    dispatch({
                        type: messageObj.command,
                        payload: {...messageObj}
                    });
                }
            };
            webSockets[modules.ADMINISTRATION].onclose = async () =>
                webSockets[modules.ADMINISTRATION] = await reinitializeClosedSocket(webSockets[modules.ADMINISTRATION], process.env.REACT_APP_BE_ADMIN_WS_URL, action, dispatch);
        }
        if (action.adminWebsocket) {
            attemptWsCall(dispatch, getState, action, modules.ADMINISTRATION, alertMessages.ADM_BE_UNREACHABLE);
        }
        return next(action);
    }
};

export const createBvmSocketMiddleware = () => {
    return ({dispatch, getState}) => next => async action => {
        if (action.type === CONNECT_BVM_WEBSOCKET_PENDING) {
            webSockets[modules.BVM] = new WebSocket(`${process.env.REACT_APP_BE_BVM_WS_URL}?Auth=${action.payload.token}`);
            webSockets[modules.BVM].onopen = () => dispatch({type: CONNECT_BVM_WEBSOCKET_SUCCESS});
            webSockets[modules.BVM].onmessage = async message => {
                let messageObj = JSON.parse(message.data);
                if (!messageObj.command) {
                    dispatch(errorAlert(alertMessages.BVM_BE_UNREACHABLE, messageObj));
                } else {
                    if (messageObj.error) {
                        dispatch(errorAlert(errorMessages[get(messageObj, 'error.errCode', undefined)] ? errorMessages[messageObj.error.errCode] : alertMessages.BVM_WS_STRUCT_ERROR,
                            messageObj, messageObj.error));
                    }
                    if (messageObj.warning) {
                        const {...messageWarnObj} = messageObj;
                        dispatch(warnAlert(warnMessages[get(messageObj, 'warning.warnCode', undefined)] ? warnMessages[messageObj.warning.warnCode] : messageObj.warning, messageWarnObj, messageObj.warning));
                    }
                    dispatch({
                        type: messageObj.command,
                        payload: {...messageObj}
                    });
                }
            };
            webSockets[modules.BVM].onclose = async () =>
                webSockets[modules.BVM] = await reinitializeClosedSocket(webSockets[modules.BVM], process.env.REACT_APP_BE_BVM_WS_URL, action, dispatch);
        }
        if (action.bvmWebsocket) {
            attemptWsCall(dispatch, getState, action, modules.BVM, alertMessages.BVM_BE_UNREACHABLE);
        }
        return next(action);
    }
};

const reinitializeClosedSocket = async (socket, socketUrl, action, dispatch) => {
    let result = socket;

    const authenticatedUser = await getAuthenticatedUser();
    if (authenticatedUser && authenticatedUser.expired) {
        dispatch(infoAlert(
            alertMessages.TOKEN_EXPIRED,
            {
                buttonLabel: buttonMessages.YES,
                handleClick: async function () {
                    if(process.env.REACT_APP_PROVIDER_SWITCH) {
                        localStorage.removeItem("provider")
                        localStorage.removeItem("providerExpireAt")
                        window.location.reload()
                    } else {
                        await authenticateAndRedirect();
                    }
                }
            },
            {},
            infoAlertUniqueId
        ));
    } else {
        const {onopen, onmessage, onerror, onclose} = result;
        result = new WebSocket(`${socketUrl}?Auth=${authenticatedUser.access_token}`);
        Object.assign(result, {onopen, onmessage, onerror, onclose});
    }

    return result;
};

/**
 * The purpose of this function is to keep checking whether the WebSocket is properly connected. It checks only for
 * a certain amount of attempts (REACT_APP_WS_CONN_ATTEMPTS) and waits for certain amount of milliseconds before each
 * attempt (REACT_APP_WS_CONN_INTERVAL). If the connection is established in time, the request is sent to the WebSocket.
 * If not, error alert is dispatched.
 *
 * Why do we do this? Because on some environments it takes some time for each WebSocket to be connected (cca. 2 seconds)
 * and if we don't wait for it somehow, the request is just lost (and frontend keeps spinning the wheel forever).
 * But we cannot handle this only during initialization of the application because user can also access a particular
 * URL address (like for example ticket detail). So we need some general solution on one place for all WebSocket
 * requests.
 *
 * If you have a more elegant solution to this problem, feel free to share your idea.
 *
 * @param dispatch
 * @param getState
 * @param action
 * @param module
 * @param alertMessage
 * @param counter
 * @returns {Promise<void>}
 */
const attemptWsCall = async (dispatch, getState, action, module, alertMessage, counter = 0) => {
    const isConnected = get(getState(), `websocket.isConnected.${module}`, false);

    if (webSockets[module] && isConnected) {
        webSockets[module].send(JSON.stringify({
            command: action.command,
            message: {correlationId: new Date(), ...action.payload}
        }));
    } else if (counter < process.env.REACT_APP_WS_CONN_ATTEMPTS) {
        await (interval => new Promise(resolve => setTimeout(resolve, interval)))(process.env.REACT_APP_WS_CONN_INTERVAL);
        await attemptWsCall(dispatch, getState, action, module, alertMessage, counter + 1);
    } else {
        dispatch(errorAlert(alertMessage));
    }
};
