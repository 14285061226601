import {
    CLIENT_IDS_DELETE_RECORD,
    CLIENT_IDS_EDIT_RECORD,
    ADMIN_CLIENT_IDS_ERROR,
    CLIENT_IDS_FETCH_PENDING,
    CLIENT_IDS_FETCH_SUCCESS,
    CLIENT_IDS_SELECT_GROUP,
    CLIENT_IDS_SET_LINES_PER_PAGE,
    CLIENT_IDS_SET_SORTING,
    CLIENT_IDS_SET_TEXT_SEARCH,
    CLIENT_IDS_UPDATE_RECORD,
    CLIENT_IDS_UPDATE_PENDING,
    CLIENT_IDS_DELETE_PENDING,
    CLIENT_IDS_CREATE_PENDING,
} from '../../actions/actionClientId';
import {computeSorting} from '../../utils/utils.js';

export const adminClientId = (state = {
                                  page: '',
                                  totalCount: '',
                                  linesPerPage: 10,
                                  list: [],
                                  isLoading: true,
                                  sorting: {},
                                  fulltext: '',
                                  edit: -1,
                                  selectedCountryGroup: '',
                              },
                              action) => {
        switch (action.type) {
            case CLIENT_IDS_FETCH_PENDING:
                return Object.assign({}, state, {
                    page: action.payload.page,
                    list: [],
                    isLoading: true,
                    edit: -1,
                });
            case CLIENT_IDS_FETCH_SUCCESS: {
                return Object.assign({}, state, {
                    totalCount: action.payload.result.totalCount,
                    list: action.payload.result.items,
                    isLoading: false,
                    edit: -1,
                });
            }
            case ADMIN_CLIENT_IDS_ERROR: {
                return Object.assign({}, state, {
                    isLoading: false,
                });
            }
            case CLIENT_IDS_SET_TEXT_SEARCH: {
                return Object.assign({}, state, {fulltext: action.payload});
            }
            case CLIENT_IDS_SET_SORTING: {
                const sorting = computeSorting(action.payload, state.sorting);
                return Object.assign({}, state, {sorting});
            }
            case CLIENT_IDS_SET_LINES_PER_PAGE: {
                return Object.assign({}, state, {linesPerPage: action.payload});
            }
            case CLIENT_IDS_EDIT_RECORD: {
                return Object.assign({}, state, {
                    edit: action.payload.index,
                });
            }
            case CLIENT_IDS_CREATE_PENDING:
                return Object.assign({}, state, {
                    isLoading: true,
                });
            case CLIENT_IDS_UPDATE_PENDING:
                return Object.assign({}, state, {
                    isLoading: true,
                    index: action.payload.index,
                });
            case CLIENT_IDS_UPDATE_RECORD: {
                const {bir8, region, index, clientId, destinationCode} = action.payload;
                let newArray = state.list.map(e => e.bir8 === bir8 ? {
                    ...e,
                    region,
                } : e);
                newArray[index].clientId = clientId;
                newArray[index].destinationCode = destinationCode;
                return Object.assign({}, state, {
                    isLoading: false,
                    edit: -1,
                    list: newArray,
                });
            }
            case CLIENT_IDS_DELETE_PENDING:
                return Object.assign({}, state, {
                    isLoading: true,
                    index: action.payload.index,
                });
            case CLIENT_IDS_DELETE_RECORD: {
                let newArray = state.list.slice();
                newArray.splice(action.payload.index, 1);
                return Object.assign({}, state, {
                    isLoading: false,
                    list: newArray,
                });
            }
            case CLIENT_IDS_SELECT_GROUP: {
                return Object.assign({}, state, {selectedCountryGroup: action.payload});
            }
            default:
                return state;
        }
    }
;

