import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    TOKEN_EXPIRED: {
        id: 'alert.token.expired',
        defaultMessage: 'Authorization token expired. Refresh?'
    },
    SP_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.sp',
        defaultMessage: 'Cannot reach Spare Parts backend'
    },
    TD_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.td',
        defaultMessage: 'Cannot reach Technical Documentation backend'
    },
    AS_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.as',
        defaultMessage: 'Cannot reach After Sales Engineering backend'
    },
    CL_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.cl',
        defaultMessage: 'Cannot reach Claims backend'
    },
    STAT_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.stat',
        defaultMessage: 'Cannot reach Statistics backend'
    },
    ADM_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.adm',
        defaultMessage: 'Cannot reach Administration backend'
    },
    BVM_BE_UNREACHABLE: {
        id: 'alert.backend.unreachable.bvm',
        defaultMessage: 'Cannot reach BVM backend'
    },
    SP_WS_ERROR: {
        id: 'alert.error.ws.sp',
        defaultMessage: 'Unexpected Spare Parts WebSocket error'
    },
    TD_WS_ERROR: {
        id: 'alert.error.ws.td',
        defaultMessage: 'Unexpected Technical Documentation WebSocket error'
    },
    CL_WS_ERROR: {
        id: 'alert.error.ws.cl',
        defaultMessage: 'Unexpected Claims WebSocket error'
    },
    STAT_WS_ERROR: {
        id: 'alert.error.ws.stat',
        defaultMessage: 'Unexpected Statistics WebSocket error'
    },
    BVM_WS_ERROR: {
        id: 'alert.error.ws.bvm',
        defaultMessage: 'Unexpected BVM WebSocket error'
    },
    ADM_WS_ERROR: {
        id: 'alert.error.ws.adm',
        defaultMessage: 'Unexpected Administration WebSocket error'
    },
    TD_WS_STRUCT_ERROR: {
        id: 'alert.error.ws.structure.td',
        defaultMessage: 'Command structure error for Technical Documentation'
    },
    CL_WS_STRUCT_ERROR: {
        id: 'alert.error.ws.structure.cl',
        defaultMessage: 'Command structure error for Claims'
    },
    STAT_WS_STRUCT_ERROR: {
        id: 'alert.error.ws.structure.stat',
        defaultMessage: 'Command structure error for Statistics'
    },
    ADM_WS_STRUCT_ERROR: {
        id: 'alert.error.ws.structure.adm',
        defaultMessage: 'Command structure error for Administration'
    },
    BVM_WS_STRUCT_ERROR: {
        id: 'alert.error.ws.structure.bvm',
        defaultMessage: 'Command structure error for BVM'
    },
    FILE_DOWNLOAD_ERROR: {
        id: 'alert.error.file.download',
        defaultMessage: 'Cannot download file'
    },
    FILE_UPLOAD_ERROR: {
        id: 'alert.error.file.upload',
        defaultMessage: 'Cannot upload file'
    },
    CLIENT_ID_CREATE_ERROR: {
        id: 'alert.error.client.id.create',
        defaultMessage: 'Failed to create Client ID'
    },
    CLIENT_ID_DELETE_ERROR: {
        id: 'alert.error.client.id.delete',
        defaultMessage: 'Failed to delete Client ID'
    },
    CLIENT_ID_FETCH_ERROR: {
        id: 'alert.error.client.id.fetch',
        defaultMessage: 'Cannot fetch Setting Client ID data'
    },
    CLIENT_ID_SAVE_ERROR: {
        id: 'alert.error.client.id.save',
        defaultMessage: 'Failed to save Setting Client ID data'
    },
    FLOW_DELETE_ERROR: {
        id: 'alert.error.flow.delete',
        defaultMessage: 'Cannot delete the Flow'
    },
    FLOW_FETCH_ERROR: {
        id: 'alert.error.flow.fetch',
        defaultMessage: 'Cannot fetch Flow data'
    },
    FLOW_LIST_FETCH_ERROR: {
        id: 'alert.error.flow.list.fetch',
        defaultMessage: 'Cannot fetch Flow list data'
    },
    FLOW_RETRIEVED_ERROR: {
        id: 'alert.error.flow.retrieved',
        defaultMessage: 'Retrieved invalid Flow from backend'
    },
    FLOW_LIST_RETRIEVED_ERROR: {
        id: 'alert.error.flow.list.retrieved',
        defaultMessage: 'Retrieved invalid Flow list from backend'
    },
    FLOW_SAVE_ERROR: {
        id: 'alert.error.flow.save',
        defaultMessage: 'Cannot save existing Flow'
    },
    FLOW_NEW_SAVE_ERROR: {
        id: 'alert.error.flow.new.save',
        defaultMessage: 'Cannot save new Flow'
    },
    FLOW_SEND_ERROR: {
        id: 'alert.error.flow.send',
        defaultMessage: 'Cannot send invalid existing Flow to backend'
    },
    FLOW_NEW_SEND_ERROR: {
        id: 'alert.error.flow.new.send',
        defaultMessage: 'Cannot send invalid new Flow to backend'
    },
    RIGHTS_ADMIN_FETCH_ERROR: {
        id: 'alert.error.rights.admin.fetch',
        defaultMessage: 'Cannot fetch Rights Management admin data'
    },
    RIGHTS_ADMIN_RETRIEVED_ERROR: {
        id: 'alert.error.rights.admin.retrieved',
        defaultMessage: 'Retrieved invalid admin rights from backend'
    },
    RIGHTS_ADMIN_SEND_ERROR: {
        id: 'alert.error.rights.admin.send',
        defaultMessage: 'Cannot send invalid admin rights to backend'
    },
    RIGHTS_DEALER_FETCH_ERROR: {
        id: 'alert.error.rights.dealer.fetch',
        defaultMessage: 'Cannot fetch Rights Management dealer data'
    },
    RIGHTS_DEALER_RETRIEVED_ERROR: {
        id: 'alert.error.rights.dealer.retrieved',
        defaultMessage: 'Retrieved invalid dealer rights from backend'
    },
    RIGHTS_DEALER_SEND_ERROR: {
        id: 'alert.error.rights.dealer.send',
        defaultMessage: 'Cannot send invalid dealer rights to backend'
    },
    RIGHTS_EDITOR_FETCH_ERROR: {
        id: 'alert.error.rights.editor.fetch',
        defaultMessage: 'Cannot fetch Rights Management editor data'
    },
    RIGHTS_EDITOR_RETRIEVED_ERROR: {
        id: 'alert.error.rights.editor.retrieved',
        defaultMessage: 'Retrieved invalid editor rights from backend'
    },
    RIGHTS_EDITOR_SEND_ERROR: {
        id: 'alert.error.rights.editor.send',
        defaultMessage: 'Cannot send invalid editor rights to backend'
    },
    RIGHTS_SG_EDITOR_FETCH_ERROR: {
        id: 'alert.error.rights.SGeditor.fetch',
        defaultMessage: 'Cannot fetch Rights Management sg editor data'
    },
    RIGHTS_SG_EDITOR_RETRIEVED_ERROR: {
        id: 'alert.error.rights.SGeditor.retrieved',
        defaultMessage: 'Retrieved invalid sg editor rights from backend'
    },
    RIGHTS_SG_EDITOR_SEND_ERROR: {
        id: 'alert.error.rights.SGeditor.send',
        defaultMessage: 'Cannot send invalid sg editor rights to backend'
    },
    SOLUTION_GROUPS_DELETE_ERROR: {
        id: 'alert.error.solutions.groups.delete',
        defaultMessage: 'Cannot delete the Solution Group'
    },
    SOLUTION_GROUPS_FETCH_ERROR: {
        id: 'alert.error.solution.groups.fetch',
        defaultMessage: 'Cannot fetch all available Solution Groups'
    },
    SOLUTION_GROUPS_LIST_FETCH_ERROR: {
        id: 'alert.error.solution.groups.list.fetch',
        defaultMessage: 'Cannot fetch Solution Group list data'
    },
    SOLUTION_GROUPS_NEW_SAVE_ERROR: {
        id: 'alert.error.solution.groups.new.save',
        defaultMessage: 'Cannot save new Solution Group'
    },
    SOLUTION_GROUPS_NEW_SEND_ERROR: {
        id: 'alert.error.solution.groups.new.send',
        defaultMessage: 'Cannot send invalid new Solution Group to backend'
    },
    SOLUTION_GROUPS_RETRIEVED_ERROR: {
        id: 'alert.error.solution.groups.retrieved',
        defaultMessage: 'Retrieved invalid Solution Groups from backend'
    },
    SOLUTION_GROUPS_LIST_RETRIEVED_ERROR: {
        id: 'alert.error.solution.groups.list.retrieved',
        defaultMessage: 'Retrieved invalid Solution Groups list from backend'
    },
    SOLUTION_GROUPS_SAVE_ERROR: {
        id: 'alert.error.solution.groups.save',
        defaultMessage: 'Cannot save existing Solution Group'
    },
    SOLUTION_GROUPS_SEND_ERROR: {
        id: 'alert.error.solution.groups.send',
        defaultMessage: 'Cannot send invalid existing Solution Group to backend'
    },
    USERS_DETAILS_FETCH_ERROR: {
        id: 'alert.error.users.details.fetch',
        defaultMessage: 'Cannot fetch user\'s detail'
    },
    USERS_FETCH_ERROR: {
        id: 'alert.error.users.fetch',
        defaultMessage: 'Cannot fetch users'
    },
    USERS_RETRIEVED_ERROR: {
        id: 'alert.error.users.retrieved',
        defaultMessage: 'Retrieved invalid user from backend'
    },
    USERS_SAVE_ERROR: {
        id: 'alert.error.users.save',
        defaultMessage: 'Cannot save a user'
    },
    DETAILS_FOR_SUPPORT: {
        id: 'alert.link.detailsForSupport',
        defaultMessage: 'Details for Support'
    },
    DEFAULT_DEALER_ROLES_RETRIEVED_ERROR: {
        id: 'alert.error.defaultDealer.roles.retrieved',
        defaultMessage: 'Retrieved invalid default dealer roles from backend'
    },
    DEFAULT_DEALER_ROLES_FETCH_ERROR: {
        id: 'alert.error.defaultDealer.roles.fetch',
        defaultMessage: 'Cannot fetch default dealer roles data'
    },
    DEFAULT_DEALER_ROLES_SEND_ERROR: {
        id: 'alert.error.defaultDealer.roles.send',
        defaultMessage: 'Cannot send invalid default dealer roles to backend'
    },
    SOLUTION_GROUP_NEW_SEND_ERROR: {
        id: 'alert.error.solutionGroup.new.send',
        defaultMessage: 'Cannot send invalid new solution group to backend'
    },
    SOLUTION_GROUP_UPDATE_SEND_ERROR: {
        id: 'alert.error.solutionGroup.update.send',
        defaultMessage: 'Cannot send invalid updated solution group to backend'
    },
    SOLUTION_GROUP_FETCH_SEND_ERROR: {
        id: 'alert.error.solutionGroup.fetch.send',
        defaultMessage: 'Retrieved invalid solution group from backend'
    },
    ESCALATION_SCHEMA_NEW_SEND_ERROR: {
        id: 'alert.error.escalationSchema.new.send',
        defaultMessage: 'Cannot send invalid new escalation schema to backend'
    },
    ESCALATION_SCHEMA_UPDATE_SEND_ERROR: {
        id: 'alert.error.escalationSchema.update.send',
        defaultMessage: 'Cannot send invalid updated escalation schema to backend'
    },
    ESCALATION_SCHEMA_FETCH_SEND_ERROR: {
        id: 'alert.error.escalationSchema.fetch.send',
        defaultMessage: 'Retrieved invalid escalation schema from backend'
    },
    ANSWERING_SCHEMA_NEW_SEND_ERROR: {
        id: 'alert.error.answeringSchema.new.send',
        defaultMessage: 'Cannot send invalid new answering schema to backend'
    },
    ESCALATION_SUSPICIOUS_GROUP_WARN: {
        id: 'alert.warn.escalation.suspicious.group',
        defaultMessage: 'Selected Solution Group {groupName} doesn\'t appear in ticket history. Are you sure to transfer ticket to SG {groupName}?'
    },
    ESCALATION_MISSING_EDITORS_NOTES_GROUP_WARN: {
        id: 'alert.warn.escalation.missingEditorsNotes',
        defaultMessage: 'Are you sure you want to continue and proceed without any comments in the Internal Notes zones ?'
    },
    ANSWER_TO_DEALER_MISSING_MESSAGE_WARN: {
        id: 'alert.warn.answerToDealer.missingMessage',
        defaultMessage: 'Are you sure you want to continue and proceed without any comments in the Messages zones ?'
    },
    ANSWERING_SCHEMA_UPDATE_SEND_ERROR: {
        id: 'alert.error.answeringSchema.update.send',
        defaultMessage: 'Cannot send invalid updated answering schema to backend'
    },
    ANSWERING_SCHEMA_FETCH_SEND_ERROR: {
        id: 'alert.error.answeringSchema.fetch.send',
        defaultMessage: 'Retrieved invalid answering schema from backend'
    },
});

export default messages;
