import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const SPARE_PARTS_SEARCH_ENGINE_FETCH_PENDING = 'SPARE_PARTS_SEARCH_ENGINE_FETCH_PENDING';

export const fetchSparePartsSearchEnginePageData = (pageSize, sorted, cursor, isNextFromPivot, searchOption, filtered) => dispatch => {
    dispatch({
        type: SPARE_PARTS_SEARCH_ENGINE_FETCH_PENDING,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_SEARCH_ENGINE_GET,
        payload: { lastCommand: Date.now(), pageSize: pageSize, sorted: sorted, cursor, isNextFromPivot, searchOption, filtered:filtered },
    });
};
