import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_LABEL_PROBLEM_DESCRIPTION,
} from '../../../../../../constants/formConstants';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import formMessages from '../../../../../../intl/technicalDocumentations/formMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const ProblemDescriptionSectionInternal = (props) => {
    const {readOnly, userRights, form, reqFields, intl: {formatMessage}} = props;

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.PROBLEM_DESCRIPTION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-12 pr-0 pb-1">
                        <TextareaFieldWithValidation label={formMessages[FORM_LABEL_PROBLEM_DESCRIPTION]}
                                                     field={FORM_FIELD_PROBLEM_DESCRIPTION}
                                                     isDisabled={!userRights.canEditInternalTicketProblemDescText || readOnly}
                                                     rows={6}
                                                     isRequired={reqFields.includes(FORM_FIELD_PROBLEM_DESCRIPTION)}
                                                     />
                    </div>
                </div>
            </StyledDiv>
        </>
    )
}

export default connect(null, null)(injectIntl(ProblemDescriptionSectionInternal));
