import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const TECH_DOC_RECURRENT_PROBLEM_PENDING = 'TECH_DOC_RECURRENT_PROBLEM_PENDING';
export const TECH_DOC_RECURRENT_PROBLEM_SELECT_GROUP = 'TECH_DOC_RECURRENT_PROBLEM_SELECT_GROUP';

export const fetchTechDocRecurrentProblems = (domain, group) => dispatch => {
    return dispatch({
        type: TECH_DOC_RECURRENT_PROBLEM_PENDING,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_GET,
        payload: {group}
    });
};

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: TECH_DOC_RECURRENT_PROBLEM_SELECT_GROUP,
        payload: countryGroup
    });
