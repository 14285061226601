import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'claims.history.title',
        defaultMessage: 'History claims'
    },
    VISIBLE_COLUMNS: {
        id: 'claims.history.label.visibleColumns',
        defaultMessage: 'Visible columns'
    },
    TABLE_TICKET_NUMBER: {
        id: 'claims.history.table.ticketNo',
        defaultMessage: 'Ticket No.'
    },
    TABLE_ORDER_CREATION_TIME: {
        id: 'claims.history.table.creationDate',
        defaultMessage: 'Creation Date'
    },
    TABLE_CLIENT_ID: {
        id: 'claims.history.table.clientId',
        defaultMessage: 'Client ID'
    },
    TABLE_DEALER_NUMBER: {
        id: 'claims.history.table.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    TABLE_DEALER_NAME: {
        id: 'claims.history.table.dealerName',
        defaultMessage: 'Dealer Name'
    },
    TABLE_FORM_TYPE: {
        id: 'claims.history.table.formType',
        defaultMessage: 'Type of Form'
    },
    TABLE_STATUS: {
        id: 'claims.history.table.status',
        defaultMessage: 'Status'
    },
    TABLE_LAST_EDITOR: {
        id: 'claims.history.table.lastEditor',
        defaultMessage: 'Last Editor'
    },
    TABLE_CREATED_BY: {
        id: 'claims.history.table.createdBy',
        defaultMessage: 'Created by (IPN)'
    },
    TABLE_REG_DIR: {
        id: 'claims.history.table.regDir',
        defaultMessage: 'Region'
    },
    TABLE_CLAIM_RELATED: {
        id: 'claims.history.table.claimRelatedNo',
        defaultMessage: 'Claim Related No.'
    },
    TABLE_INVOICE: {
        id: 'claims.history.table.invoiceNo',
        defaultMessage: 'Invoice No.'
    },
    TABLE_MISSING_PACKAGE: {
        id: 'claims.history.table.missingPackageNo',
        defaultMessage: 'Missing Package No.'
    },
    TABLE_WRONG_DESTINATION: {
        id: 'claims.history.table.wrongDestinationNo',
        defaultMessage: 'Package Destination Error No.'
    },
    TABLE_PART_NO: {
        id: 'claims.history.table.partNo',
        defaultMessage: 'Part No.'
    },
    TABLE_PACKAGE_NO: {
        id: 'claims.history.table.packageNo',
        defaultMessage: 'Package No.'
    },
    TABLE_TAKING_BACK: {
        id: 'claims.history.table.takingBackNo',
        defaultMessage: 'Taking Back No.'
    },
    TABLE_CONTAINER: {
        id: 'claims.history.table.containerNo',
        defaultMessage: 'Container No.'
    },
    TABLE_CLIENT_SOLUTION_PILOT: {
        id: 'claims.history.table.clientSolutionPilot',
        defaultMessage: 'Client Solution Pilot'
    },
    TABLE_SUPPLIER: {
        id: 'claims.ticket.table.supplier',
        defaultMessage: 'Supplier'
    },
});

export default messages;
