import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {FormattedDate, injectIntl} from 'react-intl';
import v4 from 'uuid/v4';
import {addItem, editItem, removeItem, updateItem} from '../../../actions/admin/actionAdminRecurrentProbem';
import ConfirmationDialog from '../../common/modal/ConfirmationDialog';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import {IconCancel, IconDelete, IconEdit, IconSave, PrimaryIcon} from '../../common/Button';
import FileIconWithName from '../common/FileIconWithName';
import {downloadFile, uploadFile} from '../../../actions/actionFile';
import FileSelectionField from '../common/FileSelectionField';
import Loader from "../../Loader";

const MessageCell = styled.td`
    white-space: pre-wrap;
`;

const FileCell = styled.td`
    text-align: center;
    cursor: ${props => props.cursorPointer ? 'pointer' : 'default'};
`;

const StyledInput = styled.input`
    width: 100%;
    &:focus {
    outline: none;
      border:1px solid #ffcc33;
      -webkit-box-shadow: none;
    box-shadow: none;
    }
`;

class RecurrentProblemsRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.data.message,
            attachment: this.props.data.attachment,
            isOpenRemoveDialog: false
        };
    }

    onClickPencil = e => {
        const {adminRecurrentProblem, index, data, domain, profile, uploadFile, updateItem, editItem} = this.props;
        const {value, attachment} = this.state;
        e.preventDefault();
        if ((adminRecurrentProblem.edit === index) && ((value !== data.message) || (attachment !== data.attachment))) {
            const date = new Date();
            const item = {
                groups: data.groups,
                uuid: data.uuid,
                message: value,
                updated: date.getTime(),
                userName: profile.name
            };
            if (attachment) {
                const newAttachmentId = v4();
                item.attachment = {
                    uuid: attachment.uuid ? attachment.uuid : newAttachmentId,
                    name: attachment.name,
                    type: attachment.type
                };
                if (!attachment.uuid) {
                    uploadFile(domain, newAttachmentId, data.uuid, attachment.type, window.URL.createObjectURL(this.state.attachment));
                }
            }
            updateItem(domain, item, data, index);
        } else if (adminRecurrentProblem.edit === index) {
            editItem(-1);
        } else {
            this.setState({value: data.message, attachment: data.attachment});
            editItem(index);
        }
    };

    onCancel = e => {
        e.preventDefault();
        this.setState({value: this.props.data.message, attachment: this.props.data.attachment});
        this.props.editItem(-1);
    };

    onClickDelete = () => {
        const item = {
            ...this.props.data
        };
        this.props.removeItem(this.props.domain, item, this.props.index);
    };

    onChangeHandler = e => {
        e.preventDefault();
        this.setState({value: e.target.value});
    };

    showRemoveModal = () => {
        this.setState({
            isOpenRemoveDialog: true
        });
    };

    closeModal = e => {
        e.preventDefault();
        this.setState({
            isOpenRemoveDialog: false
        });
    };

    saveModal = e => {
        e.preventDefault();
        this.setState({
            isOpenRemoveDialog: false
        });
        this.onClickDelete();
    };

    handleDownload = e => {
        e.preventDefault();
        this.props.downloadFile(this.props.domain, this.props.data.attachment, this.props.data.uuid);
    };

    handleAttachmentSelection = attachment => {
        this.setState({attachment});
    };

    renderHandler(provided) {
        return (
            <td className="col-1"
                {...provided.dragHandleProps}
            >
                {
                    (this.props.adminRecurrentProblem.edit === -1) &&
                    <FontAwesomeIcon icon="arrows-alt-v"/>
                }
            </td>
        );
    }

    renderMessage(index) {
        if (this.props.adminRecurrentProblem.edit === index) {
            return (
                <StyledInput
                    className="form-control"
                    value={this.state.value}
                    placeholder="Enter your text here..."
                    onChange={this.onChangeHandler}
                />
            );
        } else {
            return this.props.data.message;
        }
    }

    renderAttachment = index => {
        const {adminRecurrentProblem, progressStatus, data} = this.props;
        const {attachment} = this.state;
        if (adminRecurrentProblem.edit === index) {
            return <FileSelectionField input={{value: attachment}}
                                       handleAttachmentSelection={this.handleAttachmentSelection} showIcon/>
        } else if (data.attachment && progressStatus.get(data.attachment.uuid)) {
            return <Loader isSmall/>;
        } else if (data.attachment) {
            return <FileIconWithName file={data.attachment} showIcon onClick={this.handleDownload}/>;
        }
    };

    renderTools(index) {
        let buttons;

        if (this.props.adminRecurrentProblem.edit === index) {
            buttons =
                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group " role="group" aria-label="First group">
                        <PrimaryIcon className="btn btn-sm" onClick={this.onClickPencil}
                                     title="Save" type="button">
                            <IconSave/>
                        </PrimaryIcon>
                    </div>
                    <div className="btn-group " role="group" aria-label="Second group">
                        <PrimaryIcon className="btn btn-sm  ml-sm-1" onClick={this.onCancel}
                                     title="Cancel" type="button">
                            <IconCancel/>
                        </PrimaryIcon>
                    </div>
                </div>
        } else {
            buttons =
                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group " role="group" aria-label="First group">
                        <PrimaryIcon className="btn btn-sm" onClick={this.onClickPencil}
                                     title="Edit" type="button">
                            <IconEdit/>
                        </PrimaryIcon>
                    </div>
                    <div className="btn-group " role="group" aria-label="Second group">
                        <PrimaryIcon className="btn btn-sm ml-sm-1" title="Delete" type="button"
                                     onClick={this.showRemoveModal}>
                            <IconDelete/>
                        </PrimaryIcon>
                    </div>
                </div>
        }

        return buttons;
    }

    render() {
        const {data, provided, index, intl: {formatMessage}} = this.props;
        return (
            <tr className="d-flex"
                ref={provided.innerRef}
                {...provided.draggableProps}>
                {this.renderHandler(provided)}
                <td className="col-3">
                    {data.groups.map(element => formatMessage(countryGroupMessages[element])).join(', ')}
                </td>
                <MessageCell className="col-5">{this.renderMessage(index)}</MessageCell>
                <FileCell cursorPointer={data.attachment} className="col-1">{this.renderAttachment(index)}</FileCell>
                <td className="col-1"><FormattedDate value={new Date(data.updated)}/></td>
                <td className="col-1">{this.renderTools(index)}</td>
                <ConfirmationDialog show={this.state.isOpenRemoveDialog} closeModal={this.closeModal}
                                    saveModal={this.saveModal} actionIdentifier="remove the recurrent problem"/>
            </tr>
        );
    }
}

RecurrentProblemsRow.propTypes = {
    data: PropTypes.object.isRequired,
    provided: PropTypes.object.isRequired,
    removeItem: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    editItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    adminRecurrentProblem: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    progressStatus: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    adminRecurrentProblem: state.adminRecurrentProblem,
    profile: state.profile.userDetail,
    progressStatus: state.mainFiles.uploading
});

export default connect(mapStateToProps, {
    removeItem,
    addItem,
    editItem,
    updateItem,
    downloadFile,
    uploadFile
})(injectIntl(RecurrentProblemsRow));
