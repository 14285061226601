import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    NEW: {
        id: 'common.form.status.new',
        defaultMessage: 'Created'
    },
    WAIT_DEALER: {
        id: 'common.form.status.inProgressDealer',
        defaultMessage: 'Answered to Dealer'
    },
    WAIT_SUBSIDIARY: {
        id: 'common.form.status.inProgressSubsidiary',
        defaultMessage: 'In Progress - NPDC'
    },
    READ_BY_SUBSIDIARY: {
        id: 'common.form.status.readBySubsidiary',
        defaultMessage: 'Read By NPDC'
    },
    READ_BY_DEALER: {
        id: 'common.form.status.readByDealer',
        defaultMessage: 'Read By Dealer'
    },
    REOPEN: {
        id: 'common.form.status.reopened',
        defaultMessage: 'Reopened'
    },
    CLOSED: {
        id: 'common.form.status.closed',
        defaultMessage: 'Closed'
    },
    OPENED: {
        id: 'common.form.status.opened',
        defaultMessage: 'Opened'
    },
    TRANSFERRED: {
        id: 'common.form.status.transferred',
        defaultMessage: 'Transferred'
    },
    STATUS_HISTORY: {
        id: 'common.form.header.statusHistory',
        defaultMessage: 'History of Changes'
    },
    ANSWERED: {
        id: 'common.form.status.answered',
        defaultMessage: 'Answered'
    },
    REOPENED: {
        id: 'common.form.status.reopened',
        defaultMessage: 'Reopened'
    },
    IN_PROGRESS: {
        id: 'common.form.status.inProgress',
        defaultMessage: 'In Progress'
    }
});

export default messages;
