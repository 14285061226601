import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {
    FORM_FIELD_ENGINE_SERIAL_NUMBER,
    FORM_FIELD_ENGINE_TYPE,
    FORM_FIELD_GEARBOX_SERIAL_NUMBER,
    FORM_FIELD_GEARBOX_TYPE,
    FORM_LABEL_ENGINE_SERIAL_NUMBER,
    FORM_LABEL_ENGINE_TYPE,
    FORM_LABEL_GEARBOX_SERIAL_NUMBER,
    FORM_LABEL_GEARBOX_TYPE,
} from '../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';
import fulltextSearchMessages from "../../../../intl/technicalDocumentations/fulltextSearchMessages";

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const SupplementaryInformationSectionSearch = () => {
    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...fulltextSearchMessages.SUPPLEMENTARY_INFORMATION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-md-6 pb-1">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_ENGINE_TYPE]}
                                                  field={FORM_FIELD_ENGINE_TYPE}
                                                  maxLength={6}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-md-6 pb-1">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_ENGINE_SERIAL_NUMBER]}
                                                  field={FORM_FIELD_ENGINE_SERIAL_NUMBER}
                                                  maxLength={7}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-md-6 pb-1">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_GEARBOX_TYPE]}
                                                  field={FORM_FIELD_GEARBOX_TYPE}
                                                  maxLength={6}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-md-6 pb-1">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_GEARBOX_SERIAL_NUMBER]}
                                                  field={FORM_FIELD_GEARBOX_SERIAL_NUMBER}
                                                  maxLength={7}
                                                  isSmall/>
                    </div>
                </div>
            </StyledDiv>
        </>
    )

};

export default (SupplementaryInformationSectionSearch);
