import React from 'react';
import {clearFields, Field, formValueSelector} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {
    FORM_CHECKBOX_NEW_OVAL_PLATE,
    FORM_CHECKBOX_OLD_OVAL_PLATE,
    FORM_FIELD_NEW_PLATE1,
    FORM_FIELD_NEW_PLATE10,
    FORM_FIELD_NEW_PLATE11,
    FORM_FIELD_NEW_PLATE12,
    FORM_FIELD_NEW_PLATE13,
    FORM_FIELD_NEW_PLATE14,
    FORM_FIELD_NEW_PLATE15,
    FORM_FIELD_NEW_PLATE16,
    FORM_FIELD_NEW_PLATE17,
    FORM_FIELD_NEW_PLATE2,
    FORM_FIELD_NEW_PLATE3,
    FORM_FIELD_NEW_PLATE4,
    FORM_FIELD_NEW_PLATE5,
    FORM_FIELD_NEW_PLATE6,
    FORM_FIELD_NEW_PLATE7,
    FORM_FIELD_NEW_PLATE8,
    FORM_FIELD_NEW_PLATE9,
    FORM_FIELD_OLD_PLATE1,
    FORM_FIELD_OLD_PLATE2,
    FORM_FIELD_OLD_PLATE3,
    FORM_FIELD_OLD_PLATE4,
    FORM_FIELD_OLD_PLATE5,
    FORM_FIELD_OLD_PLATE6,
    FORM_FIELD_OLD_PLATE7,
    FORM_FIELD_OLD_PLATE8,
    FORM_VEHICLE_DETAIL_SECTION
} from '../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';
import fulltextSearchMessages from "../../../../intl/technicalDocumentations/fulltextSearchMessages";


const OldOvalPlate = (props) => {
    const { oldOvalSelected, clearFields } = props;

    const clearPlateFields = (e) => {
        if (e.target.value === 'true') {
            clearFields(
                FORM_CHECKBOX_OLD_OVAL_PLATE,
                FORM_FIELD_OLD_PLATE1,
                FORM_FIELD_OLD_PLATE2,
                FORM_FIELD_OLD_PLATE3,
                FORM_FIELD_OLD_PLATE4,
                FORM_FIELD_OLD_PLATE5,
                FORM_FIELD_OLD_PLATE6,
                FORM_FIELD_OLD_PLATE7,
                FORM_FIELD_OLD_PLATE8,
            )
        }
        clearFields(FORM_CHECKBOX_NEW_OVAL_PLATE, FORM_FIELD_NEW_PLATE1, FORM_FIELD_NEW_PLATE2, FORM_FIELD_NEW_PLATE3, FORM_FIELD_NEW_PLATE4, FORM_FIELD_NEW_PLATE5,
            FORM_FIELD_NEW_PLATE6, FORM_FIELD_NEW_PLATE7, FORM_FIELD_NEW_PLATE8, FORM_FIELD_NEW_PLATE9, FORM_FIELD_NEW_PLATE10, FORM_FIELD_NEW_PLATE11,
            FORM_FIELD_NEW_PLATE12, FORM_FIELD_NEW_PLATE13, FORM_FIELD_NEW_PLATE14, FORM_FIELD_NEW_PLATE15, FORM_FIELD_NEW_PLATE16, FORM_FIELD_NEW_PLATE17);
    };

    return (
        <>
            <div className="col-xl-6 col-lg-6 pb-2">
                <div className="form-row justify-content-center">
                    <label className="pl-2 pt-1">
                        <Field
                            name={FORM_CHECKBOX_OLD_OVAL_PLATE}
                            component="input"
                            type="checkbox"
                            onChange={(e) => clearPlateFields(e)}
                        />{' '}
                        <FormattedMessage {...fulltextSearchMessages.OLD_OVAL_PLATE}/>
                    </label>
                </div>
                <div className="form-row">
                    <div className="offset-3 col-6 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE1}
                                                  maxLength={4}
                                                  isSmall
                                                  isDisabled={!oldOvalSelected}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="offset-1 col-3 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE2}
                                                  maxLength={2}
                                                  isSmall
                                                  isDisabled={!oldOvalSelected}/>
                    </div>
                    <div className="col-4 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE3}
                                                  maxLength={8}
                                                  isSmall
                                                  isDisabled={!oldOvalSelected}/>
                    </div>
                    <div className="col-3 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE4}
                                                  maxLength={8}
                                                  isSmall
                                                  isDisabled={!oldOvalSelected}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="offset-1 col-3 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE5}
                                                  maxLength={8}
                                                  isSmall
                                                  isDisabled={!oldOvalSelected}/>
                    </div>
                    <div className="col-4 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE6}
                                                  maxLength={8}
                                                  isSmall
                                                  isDisabled={!oldOvalSelected}/>
                    </div>
                    <div className="col-3 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE7}
                                                  maxLength={8}
                                                  isSmall
                                                  isDisabled={!oldOvalSelected}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="offset-3 col-6 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE8}
                                                  maxLength={7}
                                                  isSmall
                                                  isDisabled={!oldOvalSelected}/>
                    </div>
                </div>
            </div>
        </>
    )
};

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, initialProps) => ({
    oldOvalSelected: selector(initialProps.form, state, `${FORM_VEHICLE_DETAIL_SECTION}.ovalPlate.${FORM_CHECKBOX_OLD_OVAL_PLATE}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    clearFields: (...fields) => dispatch(clearFields(props.form, false, false, ...fields.map(e => FORM_VEHICLE_DETAIL_SECTION + ".ovalPlate." + e))),
});

export default connect(mapStateToProps, mapDispatchToProps)(OldOvalPlate);
