import {
    TECH_DOC_CREATE_TICKET,
    TECH_DOC_GET_TICKET,
    TECH_DOC_UPDATE_TICKET
} from '../../constants/websocketCommands';
import {SPARE_PARTS_ORDERS_TICKET_SUBMIT} from '../orders/actionTicket';

export const TECH_DOC_TICKET_RESET = 'TECH_DOC_TICKET_RESET';
export const TECH_DOC_TICKET_CREATE = 'TECH_DOC_TICKET_CREATE';
export const TECH_DOC_TICKET_UPDATE = 'TECH_DOC_TICKET_UPDATE';
// export const SPARE_PARTS_ORDERS_FETCH_TICKET = 'getTicket';
export const TECH_DOC_TICKET_SUBMIT = 'TECH_DOC_TICKET_SUBMIT';

export const updateTechDocTicket = (data) => dispatch => {
    return dispatch({
        type: TECH_DOC_TICKET_UPDATE,
        techDocWebsocket: true,
        command: TECH_DOC_UPDATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const saveTechDocTicket = (data) => dispatch => {
    return dispatch({
        type: TECH_DOC_TICKET_CREATE,
        techDocWebsocket: true,
        command: TECH_DOC_CREATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const fetchTechDocTicket = (uuid) => dispatch => {
    return dispatch({
        type: TECH_DOC_GET_TICKET,
        techDocWebsocket: true,
        command: TECH_DOC_GET_TICKET,
        payload: {
            ticket: {
                uuid: uuid,
            }
        }
    });
};

export const resetTechDocTicket = () => dispatch => {
    return dispatch({
        type: TECH_DOC_TICKET_RESET,
    });
};

export const submitBy = (data) => dispatch => {
    return dispatch({
        type: TECH_DOC_TICKET_SUBMIT,
        payload: {
            ...data
        }
    })
}
