import {adminWebsocketCommands} from '../../constants/adminWebsocketCommands';
import {getSearchClientIds as restGetSearchClientIds} from '../../api/restClientId';
import {errorAlert} from '../alertsActions';
import {extractErrorText} from '../actionUtils';
import errorMessages from '../../intl/common/errorMessages';

export const fetchAdminClientIds = (group, dealerNumber) => dispatch => {
    return dispatch({
        type: adminWebsocketCommands.ADMIN_CLIENT_ID_FETCH_COMMAND,
        adminWebsocket: true,
        command: adminWebsocketCommands.ADMIN_CLIENT_ID_FETCH_COMMAND,
        payload: {
            group,
            dealerNumber
        }
    });
};

export const fetchAdminFlatClientIds = () => dispatch => {
    return dispatch({
        type: adminWebsocketCommands.ADMIN_CLIENT_ID_FETCH_COMMAND,
        adminWebsocket: true,
        command: adminWebsocketCommands.ADMIN_CLIENT_ID_FETCH_COMMAND,
        payload: {
            flat: true
        }
    });
};

export function fetchSearchClientIds(textSearch) {
    return restGetSearchClientIds(textSearch);
}

export function handleFetchSearchClientIdsError(error) {
    return dispatch => {
        dispatch(errorAlert(...extractErrorText(error, errorMessages.CLIENT_IDS_FETCH_ERROR)));
    };
}
