import React from 'react';
import styled from 'styled-components';
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import {
    FORM_FIELD_MESSAGE_OPTION,
    FORM_NOTES_SECTION,
    FORM_FIELD_NOTE,
    FORM_LABEL_NOTE,
    FORM_LABEL_NOTES_HISTORY,
    FORM_LABEL_MESSAGE
} from '../../../../../../constants/formConstants';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {change, getFormInitialValues} from 'redux-form';
import {connect} from 'react-redux';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import commonMessages from '../../../../../../intl/common/commonMessages';
import {moduleRoles} from '../../../../../../utils/roles';
import get from 'get-value';
import {parseTextToJSX} from '../../../../../../utils/utils';
import InputSelectFieldWithValidation from "../../../../../common/InputSelectFieldWithValidation";

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const NotesSection = props => {
    const {userRights, readOnly, initialValues, roles, modelAnswers, change} = props;

    const canReadMessage = (note) => {
        if (moduleRoles.isEditorOrder(roles) || moduleRoles.isSGEditorOrder(roles) || !note.solutionGroup) {
            return true;
        }
        return note.solutionGroup.canForwardToDealer;
    };

    const handleChange = (e) => {
        if (e.target.value !== '' && e.target.value !== undefined && e.target.value !== null && modelAnswers) {
            const message = modelAnswers.find( answer => answer.uuid === e.target.value );
            change(FORM_FIELD_NOTE, get(message, 'text'));
        }
    };

    const getCreatorData = (note) => {
        const {userName, solutionGroup, creatorIpn} = note;
        const solutionGroupName = solutionGroup ? ' ' + solutionGroup.name + ' ' : ' ';
        return `${userName}${solutionGroupName}(${creatorIpn})`;
    };

    const notes = (get(initialValues, 'notes') || []).filter(item => canReadMessage(item));
    const canAnswerToDealer = get(initialValues, 'ticketSection.solutionGroup.canForwardToDealer', {default: true});
    const isDialogBox = !!modelAnswers && modelAnswers.length > 0;

    return (
        <>
            {((userRights.canAddNotes && !readOnly) || (notes && (notes.length > 0))) &&
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.NOTE_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {(notes && (notes.length > 0)) &&
                    <div>
                        <div className="border-bottom border-bottom-secondary"
                             style={{backgroundColor: '#e9ecef', borderRadius: '5px 5px 0 0'}}>
                            <label className="col-form-label pl-0 font-weight-bold ml-3">
                                <ValidFormattedMessage message={FORM_LABEL_NOTES_HISTORY} intlMessages={formMessages}/>
                            </label>
                        </div>
                        {notes.map((note, index) =>
                            <div key={index} className="row m-0 align-items-stretch">
                                <div style={{backgroundColor: '#f8f9fa'}}
                                     className="col-sm-12 col-lg-4 flex-column border-bottom">
                                    <div>
                                        <FormattedDate value={new Date(note.created)}/> <FormattedTime
                                        value={new Date(note.created)}/>
                                        <span className="font-weight-bold">{' '}
                                            <ValidFormattedMessage message={note.isDealer ? 'DEALER' : 'EDITOR'}
                                                                   intlMessages={commonMessages}/>
                                        </span>
                                    </div>
                                    <div>
                                        {getCreatorData(note)}
                                    </div>
                                </div>
                                <div className="col border-bottom" style={{whiteSpace: "pre-wrap"}}>
                                    {parseTextToJSX(note.note)}
                                </div>
                            </div>
                        )}
                    </div>
                    }
                    {userRights.canAddNotes && !readOnly &&
                        <div className="col-sm-12 px-4 pb-3 pt-2">
                            {isDialogBox &&
                                <div className="col-xl-4 col-lg-6 col-md-12 px-0 pb-2">
                                    <InputSelectFieldWithValidation
                                        label={formMessages[FORM_LABEL_MESSAGE]}
                                        field={FORM_FIELD_MESSAGE_OPTION}
                                        customOptionsMsg={modelAnswers.map(e => ({
                                            uuid: e.uuid,
                                            presetName: e.subject
                                        }))}
                                        handleChange={handleChange}
                                        isDisabled={!canAnswerToDealer}
                                        boldTooltip
                                    />
                                </div>
                            }
                            <div className="col-sm-12 px-0">
                                <TextareaFieldWithValidation label={!isDialogBox ? formMessages[FORM_LABEL_NOTE] : undefined}
                                                             field={FORM_FIELD_NOTE}
                                                             isDisabled={!canAnswerToDealer}
                                                             boldTooltip
                                                             rows={6}/>
                            </div>
                        </div>
                    }
                </StyledDiv>
            </>}
        </>
    )
};

const getModelAnswers = (state, schemaType) => {
    if (!state.solutionGroupInfo || !state.solutionGroupInfo[schemaType]) {
        return undefined;
    }
    return get(state, `solutionGroupInfo.${schemaType}`, {default: []}).filter(answer => answer.displayArea === 'DEALER_NOTES');
}

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
    roles: state.profile.userDetail.roles,
    modelAnswers: getModelAnswers(state.sparePartsOrderTicket, 'modelAnswers'),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, FORM_NOTES_SECTION + '.' + name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesSection);
