import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import styled from 'styled-components';
import get from 'get-value';
import {storeClaimsSelectedCountryGroup} from '../../../actions/claims/actionClaimsRecurrentProblem';
import {domains} from '../../../constants/Utils';
import {moduleRoles} from '../../../utils/roles';
import RecurrentProblemClaimsContainer from './RecurrentProblemClaimsContainer';
import checkRoles from '../../common/roleChecker/RoleChecker';
import SelectCountryGroup from '../../common/country/SelectCountryGroup';
import PageHeader from '../../common/PageHeader';
import recurrentProblemMessages from '../../../intl/claims/recurrentProblemMessages';

const domain = domains.CLAIMS;

const Label = styled.label`
    font-weight: bold;
`;

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

class RecurrentProblemClaimsPage extends Component {
    componentDidMount() {
        if (this.props.selectedCountryGroup === '') {
            this.props.storeClaimsSelectedCountryGroup(
                get(moduleRoles.getEditorGroups(domain, this.props.roles), '0.group', this.props.group)
            );
        }
    }

    handleCountryGroupSelection = countryGroup => this.props.storeClaimsSelectedCountryGroup(countryGroup.value);

    render() {
        const {roles, selectedCountryGroup} = this.props;
        let groups = [{group: this.props.group, value: true}];
        if (moduleRoles.isEditorClaims(this.props.roles)) {
            groups = moduleRoles.getEditorGroups(domain, roles);
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...recurrentProblemMessages.TITLE}/>}/>
                {moduleRoles.isEditorClaims(roles) &&
                <StyledForm className="form-group">
                    <div className="form-row mb-sm-3">
                        <div className="col-xl-2 col-lg-4 col-md-5 col-sm-6">
                            <Label>
                                <FormattedMessage {...recurrentProblemMessages.SELECT_COUNTRY}/>
                            </Label>
                            <SelectCountryGroup groups={groups}
                                                handleCountryGroupSelection={this.handleCountryGroupSelection}
                                                selectedCountryGroup={selectedCountryGroup}/>
                        </div>
                    </div>
                </StyledForm>
                }
                {groups.map((group, index) =>
                    (selectedCountryGroup === group.group &&
                        <div key={index}>
                            <RecurrentProblemClaimsContainer domain={domain} selectedGroup={selectedCountryGroup}/>
                        </div>
                    )
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
    group: state.profile.userDetail.group,
    selectedCountryGroup: state.claimsRecurrentProblem.selectedCountryGroup
});

export default checkRoles(connect(mapStateToProps, {
    storeClaimsSelectedCountryGroup
})(injectIntl(RecurrentProblemClaimsPage)), ['CL_DEALER', 'CL_EDITOR']);
