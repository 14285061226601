import React, {Component} from 'react';
import {change, formValueSelector, getFormInitialValues} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {
    BUTTON_LABEL_LOAD,
    FORM_BATTERY_SECTION,
    FORM_FIELD_BATTERY_REF_NUMBER,
    FORM_FIELD_BATTERY_TYPE,
    FORM_FIELD_BIN,
    FORM_FIELD_FIC_CUR_NUMBER,
    FORM_FIELD_SEND_HAND_BATTERY_TYPE,
    FORM_FIELD_VEHICLE_MODEL,
    FORM_FIELD_VIN,
    FORM_LABEL_BATTERY_REF_NUMBER,
    FORM_LABEL_BATTERY_TYPE,
    FORM_LABEL_BIN,
    FORM_LABEL_FIC_CUR_NUMBER,
    FORM_LABEL_VEHICLE_MODEL,
    FORM_LABEL_VIN,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import {
    positiveNumber,
    validBinLength,
    validVinLength
} from '../../../../../common/validationCommons';
import {connect} from 'react-redux';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import RadioFields from '../../../../../common/RadioFields';
import {
    batteryTypes,
    domains,
    NEW_BATTERY,
    SECOND_HAND_BATTERY
} from '../../../../../../constants/Utils';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {bindActionCreators} from 'redux';
import {fetchSecondHandBatteryTypes} from '../../../../../../actions/admin/actionAdminSecondHandBattery';
import {PrimaryButton} from '../../../../../common/Button';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import Loader from '../../../../../Loader';
import {
    bvmDataResetActionCreator,
    bvmDataSetActionCreator,
    fetchBvmInformationActionCreator
} from '../../../../../../actions/orders/bvmData';
import get from 'get-value';
import BorderRadius from '../../../../../common/styled/BorderRadius';

const domain = domains.SPARE_PARTS;

class BatteryDetailSection extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        const { readOnly } = this.props;
        if(!readOnly) {
            this.props.fetchSecondHandBatteryTypes(domain, this.props.initialValues.ticketSection.group);
        }
    }

    handleChange = (event, value) => {
        if(value === NEW_BATTERY) {
            this.props.change(`${FORM_BATTERY_SECTION}.${FORM_FIELD_SEND_HAND_BATTERY_TYPE}`, "");
        }
    };

    handleBvmLoad = (e) => {
        const {vin} = this.props;
        e.preventDefault();
        this.props.fetchBvmInformationActionCreator(vin);
    };

    fillVehicleFromBvm = () => {
        const {bvm} = this.props;
        this.props.change(`${FORM_BATTERY_SECTION}.${FORM_FIELD_VEHICLE_MODEL}`, get(bvm, 'bvmData.model', ''));
        this.props.bvmDataSetActionCreator();
    };

    componentWillUnmount() {
        this.props.bvmDataResetActionCreator();
    }

    render() {
        const { userRights, reqFields, readOnly, batteryAge, secondHandBatteryTypes, bvm } = this.props;
        if (bvm.bvmSet) {
            this.fillVehicleFromBvm();
        }

        return (
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.BATTERY_DETAIL_SECTION}/>
                </h3>
                <BorderRadius size={5} className="border border-secondary px-4 py-2 bg-white">
                    <div className="form-row">
                        <div className="col-xl-4 col-lg-6 col-sm-12 pb-2">
                                <RadioFields
                                    label={formMessages[FORM_LABEL_BATTERY_TYPE]}
                                    field={FORM_FIELD_BATTERY_TYPE}
                                    options={batteryTypes}
                                    isDisabled={!userRights.canEditBatteryDetails || readOnly}
                                    // isRequired={reqFields.includes(FORM_FIELD_BATTERY_TYPE) && !readOnly}
                                    handleChange={this.handleChange}
                                    intlMessages={formMessages}/>
                            <InputSelectFieldWithValidation field={FORM_FIELD_SEND_HAND_BATTERY_TYPE}
                                                            options={secondHandBatteryTypes.map(index => index.presetName)}
                                                            isDisabled={!userRights.canEditBatteryDetails || batteryAge!==SECOND_HAND_BATTERY || readOnly}
                                                            isRequired={batteryAge==SECOND_HAND_BATTERY && !readOnly}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-12 pb-2">
                            <div className="form-row align-items-end">
                                <div className="col-md-12 col-lg-8 col-xl-4">
                                    <InputFieldWithValidation label={formMessages[FORM_LABEL_VIN]}
                                                              field={FORM_FIELD_VIN}
                                                              maxLength={17}
                                                              isDisabled={!userRights.canEditBatteryDetails || readOnly}
                                                              isRequired={reqFields.includes(FORM_FIELD_VIN) && !readOnly}
                                                              validations={[validVinLength]}
                                                              isSmall/>
                                </div>
                                {userRights.canEditBvm && !readOnly &&
                                <>
                                    <div className="pl-2 pt-1">
                                        <PrimaryButton type="button" className="btn"
                                                       onClick={this.handleBvmLoad}
                                                       disabled={bvm.isBvmLoading}>
                                            <ValidFormattedMessage message={BUTTON_LABEL_LOAD}
                                                                   intlMessages={buttonMessages}/>
                                        </PrimaryButton>
                                    </div>
                                    <div className="pl-2 py-auto">
                                        {bvm.isBvmLoading &&
                                        <Loader isSmall/>
                                        }
                                    </div>
                                </>
                                }
                                <div className="col-md-12 col-lg-8 col-xl-4">
                                    <InputFieldWithValidation label={formMessages[FORM_LABEL_VEHICLE_MODEL]}
                                                              field={FORM_FIELD_VEHICLE_MODEL}
                                                              maxLength={50}
                                                              isDisabled={!userRights.canEditBatteryDetails || readOnly}
                                                              isRequired={reqFields.includes(FORM_FIELD_VEHICLE_MODEL) && !readOnly}
                                                              isSmall/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-xl-4 col-lg-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_FIC_CUR_NUMBER]}
                                                      field={FORM_FIELD_FIC_CUR_NUMBER}
                                                      validations={[positiveNumber]}
                                                      isDisabled={!userRights.canEditBatteryDetails || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_FIC_CUR_NUMBER) && !readOnly && batteryAge==NEW_BATTERY}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_BATTERY_REF_NUMBER]}
                                                      field={FORM_FIELD_BATTERY_REF_NUMBER}
                                                      validations={[positiveNumber]}
                                                      isDisabled={!userRights.canEditBatteryDetails || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_BATTERY_REF_NUMBER) && !readOnly}
                                                      isSmall/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-xl-4 col-lg-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_BIN]}
                                                      field={FORM_FIELD_BIN}
                                                      maxLength={17}
                                                      validations={[validBinLength]}
                                                      isDisabled={!userRights.canEditBatteryDetails || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_BIN) && !readOnly}
                                                      isSmall/>
                        </div>
                    </div>
                </BorderRadius>
            </>
        )
    }
}

const mapStateToProps = (state, initialProps) => ({
    vin: formValueSelector(initialProps.form)(state, `${FORM_BATTERY_SECTION}.${FORM_FIELD_VIN}`),
    initialValues: getFormInitialValues(initialProps.form)(state),
    batteryAge: formValueSelector(initialProps.form)(state, `${FORM_BATTERY_SECTION}.${FORM_FIELD_BATTERY_TYPE}`),
    secondHandBatteryTypes: state.adminSecondHandBatteryType.secondHandBatteryTypes,
    bvm: state.bvmData,
    bvmLoaded: state.bvmData.bvmLoaded,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            fetchSecondHandBatteryTypes,
            fetchBvmInformationActionCreator,
            bvmDataSetActionCreator,
            bvmDataResetActionCreator
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatteryDetailSection);
