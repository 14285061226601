import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import {FormattedMessage} from 'react-intl';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';

const TabLink = styled.li`
  :visited,
  :link {
    color: #000000;
  }
  
  :active,
  :hover {
    background-color: #ffcc33;
  }
  
  &.${'active'} {
    background-color: #ffcc33;
  }
`;

const BankHolidays = ({country, days}) => {
    const options = days.map(day => Object.assign({}, {value: day.date, label: day.date, isFixed: true}));
    return <div>
        <ul className="nav nav-tabs" id="bankHolidaysCountryTab" role="tablist">
            <TabLink key={"0"}>
                <a className={`nav-link active`}
                   id={`country-bank-hol-tab`}
                   data-toggle="tab"
                   href={`#country`}
                   role="tab"
                   aria-controls={``}
                   aria-selected="true"> <FormattedMessage {...countryGroupMessages[country]} />
                </a>
            </TabLink>
        </ul>

        <div style={{
            padding: "10px",
            border: "1px",
            borderStyle: "solid",
            borderColor: "#e9e9e9",
            borderTopStyle: "none",
        }}>
            <Select isMulti
                    isDisabled={true}
                    isLoading={false}
                    isClearable={false}
                    value={options}/>
        </div>
    </div>
};

export default BankHolidays;
