import React from 'react';
import {Field, reducer as form, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import RouteLeavingGuard from '../../common/modal/RouteLeavingGuard';
import {FieldWithValidation} from '../../common/FormField';
import flowMessages from '../../../intl/admin/flowMessages';
import {required} from '../../common/validationCommons';
import {BoldPrimaryButton, PrimaryButton, SecondaryButton} from '../../common/Button';
import {ADMINISTRATION_GENERIC_FLOW_PATH} from '../../../routes/paths';
import DragAndDropSelector from '../../common/dragdrop/DragAndDropSelector';
import FlowTreeNode from './FlowTreeNode';
import buttonMessages from "../../../intl/common/buttonMessages";
import {flowReducer} from '../../../reducers/flowReducer';

const Label = styled.label`
    font-weight: bold;
`;

function solutionGroupSelected(flowDetailFormState) {
    return flowDetailFormState && flowDetailFormState.values && flowDetailFormState.values.items.length > 0;
}

const FlowDetailForm = ({
                            initialValues, handleSubmit, handleReset, dirty, invalid, change, history,
                            flowDetailFormState = {}, intl: { formatMessage }
                        }) => (
    <>
        <RouteLeavingGuard when={dirty}/>
        <form className="form-group" onSubmit={handleSubmit}>
            <div className="form-row mb-sm-3">
                <div className="col">
                    <PrimaryButton type="button" className="btn" onClick={() => {
                        history.push(ADMINISTRATION_GENERIC_FLOW_PATH)
                    }}>
                        <FormattedMessage {...buttonMessages.BACK}/>
                    </PrimaryButton>
                </div>
            </div>
            <div className="form-row mb-sm-3">
                <Label className="col-form-label">
                    <FormattedMessage {...flowMessages.NAME}/>
                </Label>
                <div className="col-sm-4">
                    <Field name='name' component={FieldWithValidation} type="text"
                           placeholder={formatMessage(flowMessages.TABLE_NAME)}
                           validate={[required]}/>
                </div>
            </div>
            <div className="form-row mb-sm-3">
                <Label className="col-sm-12">
                    <FormattedMessage {...flowMessages.SOLUTION_GROUPS}/>
                </Label>
                <div className="col-sm-12">
                    <DragAndDropSelector availableItems={initialValues.availableSolutionGroups}
                                         selectedItems={initialValues.items}
                                         formChange={change}
                                         formValuesMapping={{
                                             availableItems: 'availableSolutionGroups',
                                             selectedItems: 'items'
                                         }} required/>
                </div>
            </div>
            {
                solutionGroupSelected(flowDetailFormState) &&
                <div className="form-row mb-sm-3">
                    <Label className="col-sm-12">
                        <FormattedMessage {...flowMessages.SOLUTION_PREVIEW}/>
                    </Label>
                    <div className="col-sm-12">
                        <FlowTreeNode/>
                    </div>
                </div>
            }
            <div className="form-row">
                <div className="col-sm-6">
                    <SecondaryButton type="button" className="btn float-right" onClick={handleReset} disabled={!dirty}>
                        <FormattedMessage {...buttonMessages.RESET}/>
                    </SecondaryButton>
                </div>
                <div className="col-sm-6">
                    <BoldPrimaryButton type="submit" className="btn"
                                       disabled={!dirty || invalid || !solutionGroupSelected(flowDetailFormState)}>
                        <FormattedMessage {...buttonMessages.SAVE}/>
                    </BoldPrimaryButton>
                </div>
            </div>
        </form>
    </>
);

FlowDetailForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    flowDetailFormState: PropTypes.object
};

const mapStateToProps = state => ({
    initialValues: state.flowReducer.detailData,
    flowDetailFormState: state.form.flowDetailForm
});

export default withRouter(connect(mapStateToProps, {})(reduxForm({
    form: 'flowDetailForm'
})(injectIntl(FlowDetailForm))));
