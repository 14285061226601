import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.modelAnswers.title',
        defaultMessage: 'Model answers settings'
    },
    MODAL_TITLE_CREATE: {
        id: 'admin.modelAnswers.modal.title.create',
        defaultMessage: 'Select Country & Form/Request Type for New Model Answer'
    },
    MODAL_TITLE_UPDATE: {
        id: 'admin.modelAnswers.modal.title.update',
        defaultMessage: 'Update Model Answer'
    },
    MODAL_SUBJECT: {
        id: 'admin.modelAnswers.modal.subject',
        defaultMessage: 'Model Answer Title'
    },
    MODAL_TEXT: {
        id: 'admin.modelAnswers.modal.text',
        defaultMessage: 'Model Answer Text'
    },
    MODAL_SELECT_GROUPS: {
        id: 'admin.modelAnswers.modal.selectCountries',
        defaultMessage: 'Select Countries'
    },
    MODAL_DISPLAY_AREA: {
        id: 'admin.modelAnswers.modal.displayArea',
        defaultMessage: 'Select Model Answer Display Area'
    },
    MODAL_FORM_TYPE: {
        id: 'admin.modelAnswers.modal.formType',
        defaultMessage: 'Select Dealer/Internal Request Type'
    },
    SELECT_SOLUTION_GROUP: {
        id: 'admin.modelAnswers.selectSolutionGroup',
        defaultMessage: 'Select solution group'
    },
    MODEL_ANSWERS: {
        id: 'admin.modelAnswers.subTitle',
        defaultMessage: 'Model Answers'
    },
    TABLE_SOLUTION_GROUP: {
        id: 'admin.modelAnswers.table.solutionGroupName',
        defaultMessage: 'Solution Group'
    },
    TABLE_GROUP: {
        id: 'admin.modelAnswers.table.group',
        defaultMessage: 'Country'
    },
    TABLE_FORM_TYPE: {
        id: 'admin.modelAnswers.table.formType',
        defaultMessage: 'Dealer/Internal Request Type'
    },
    TABLE_MODEL_ANSWER_TITLE: {
        id: 'admin.modelAnswers.table.modelAnswerTitle',
        defaultMessage: 'Model Answer Title'
    },
    TABLE_MODEL_ANSWER_DISPLAY_AREA: {
        id: 'admin.modelAnswers.table.modelAnswerDisplayArea',
        defaultMessage: 'Display Area'
    },
    TABLE_LAST_UPDATE: {
        id: 'admin.modelAnswers.table.lastUpdate',
        defaultMessage: 'Last Update'
    },
    TABLE_ACTION: {
        id: 'admin.modelAnswers.table.action',
        defaultMessage: 'Action'
    },
    TABLE_MODAL_REMOVE: {
        id: 'admin.modelAnswers.modal.remove',
        defaultMessage: 'remove the model answer'
    },
    DEALER_NOTES: {
        id: 'admin.modelAnswers.displayArea.option.dealerNotes',
        defaultMessage: 'Dealer Notes'
    },
    INTERNAL_NOTES: {
        id: 'admin.modelAnswers.displayArea.option.internalNotes',
        defaultMessage: 'Internal Notes'
    },
    MODAL_PLACEHOLDER_TEXT: {
        id: 'admin.modelAnswers.modal.text.placeholder',
        defaultMessage: 'Model answer text...'
    }
});

export default messages;
