import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.recurrentProblem.title',
        defaultMessage: 'Recurrent Problem setting'
    },
    HEADER: {
        id: 'admin.recurrentProblem.header',
        defaultMessage: 'Recurrent Problems'
    },
    SELECT_COUNTRY: {
        id: 'admin.recurrentProblem.selectCountry',
        defaultMessage: 'Select a country'
    },
    EMPTY: {
        id: 'admin.recurrentProblem.empty',
        defaultMessage: 'No Recurrent Problem messages'
    },
    TABLE_GROUP: {
        id: 'admin.recurrentProblem.table.group',
        defaultMessage: 'Country/importers'
    },
    TABLE_RECURRENT_PROBLEM_TEXT: {
        id: 'admin.recurrentProblem.table.recurrentProblemText',
        defaultMessage: 'Recurrent Problem text'
    },
    TABLE_ATTACHMENT: {
        id: 'admin.recurrentProblem.table.attachment',
        defaultMessage: 'Attachment'
    },
    TABLE_LAST_UPDATE: {
        id: 'admin.recurrentProblem.table.lastUpdate',
        defaultMessage: 'Last update'
    },
    TABLE_ACTION: {
        id: 'admin.recurrentProblem.table.action',
        defaultMessage: 'Action'
    },
    MODAL_TITLE: {
        id: 'admin.recurrentProblem.modal.title',
        defaultMessage: 'Select countries for new Recurrent Problem'
    },
    MODAL_SELECT_GROUPS: {
        id: 'admin.recurrentProblem.modal.selectCountries',
        defaultMessage: 'Select countries'
    },
    MODAL_TEXT: {
        id: 'admin.recurrentProblem.modal.text',
        defaultMessage: 'Text'
    },
    MODAL_ATTACHMENT: {
        id: 'admin.recurrentProblem.modal.attachment',
        defaultMessage: 'Attachment'
    },
    MODAL_PLACEHOLDER_TEXT: {
        id: 'admin.recurrentProblem.modal.text.placeholder',
        defaultMessage: 'Text'
    }
});

export default messages;
