import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const SPARE_PART_DEALERS_FETCH_ERROR = 'SPARE_PART_DEALERS_FETCH_ERROR';

export const fetchSparePartDealers = () => (dispatch) => {
    dispatch({
        type: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DEALERS_GET,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DEALERS_GET,
        payload: {}
    });
};
