import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    RENAULT_TRUCKS: {
        id: 'spareParts.requestForPartnerList.option.renaultTruck',
        defaultMessage: 'RENAULT TRUCKS'
    },
    NISSAN: {
        id: 'spareParts.requestForPartnerList.option.nissan',
        defaultMessage: 'NISSAN'
    },
    MITSUBISHI: {
        id: 'spareParts.requestForPartnerList.option.mitsubishi',
        defaultMessage: 'MITSUBISHI'
    },
    OPEL_VAUXHALL: {
        id: 'spareParts.requestForPartnerList.option.opelVauxhall',
        defaultMessage: 'OPEL-VAUXHALL'
    },
    FIAT: {
        id: 'spareParts.requestForPartnerList.option.fiat',
        defaultMessage: 'FIAT'
    },
    DAIMLER: {
        id: 'spareParts.requestForPartnerList.option.daimler',
        defaultMessage: 'DAIMLER'
    },
    LACOUR: {
        id: 'spareParts.requestForPartnerList.option.lacour',
        defaultMessage: 'LACOUR'
    },
});

export default messages;
