import React, {Component} from 'react';
import {
    FORM_FIELD_USER_STATUS, FORM_FIELD_CLIENT_SOLUTION_PILOT, FORM_FIELD_TRANSPORT_PROVIDER, FORM_FIELD_TRANSPORT_NUMBER, FORM_FIELD_CLAIM_TYPE, FORM_ORDERS_SECTION,
} from '../../../../../../constants/formConstants';
import {change, formValueSelector} from 'redux-form';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import get from 'get-value';
import {connect} from 'react-redux';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import formMessages from '../../../../../../intl/claims/formMessages';
import {claimType, transportProviderType, userStatusType} from '../../../../../../constants/Utils';
import { IPN_MAX_LENGTH } from '../../../../../../constants/validationConstants';
import { PROVIDER } from '../../../../../../constants/Utils';

class OrdersTroubleshootUpperTableBody extends Component {
    constructor(props) {
        super(props)
        this.customRefs = {
            [FORM_FIELD_USER_STATUS]: React.createRef(),
            [FORM_FIELD_CLIENT_SOLUTION_PILOT]: React.createRef(),
            [FORM_FIELD_TRANSPORT_PROVIDER]: React.createRef(),
            [FORM_FIELD_TRANSPORT_NUMBER]: React.createRef(),
            [FORM_FIELD_CLAIM_TYPE]: React.createRef(),
        };
    }

    changeFocus = (value, maxLength, fieldName) => {
        if (get(this, `customRefs.${fieldName}.current`) && maxLength && value.length === maxLength) {
            this.customRefs[fieldName].current.focus();
        }
    };

    render() {
        const {userRights, readOnly, reqFields, userStatus} = this.props;
        return (
            <>
                <tr className="border-bottom">
                    <td id="order1Print" style={{maxWidth: '120px', minWidth: '90px'}}>
                        <InputSelectFieldWithValidation field={FORM_FIELD_USER_STATUS}
                                                        options={userStatusType}
                                                        isDisabled={!userRights.canEditOrder || !userRights.canEditUserStatus || readOnly}
                                                        intlMessages={formMessages}
                                                        isRequired={userRights.canEditOrder && userRights.canEditUserStatus && !readOnly && reqFields.includes(FORM_FIELD_USER_STATUS)}
                        />
                    </td>
                    <td id="order2Print" style={{maxWidth: '130px', minWidth: '110px'}}>
                        <InputFieldWithValidation field={FORM_FIELD_CLIENT_SOLUTION_PILOT}
                                                  maxLength={IPN_MAX_LENGTH}
                                                  isDisabled={!userRights.canEditOrder || readOnly}
                                                  isRequired={userRights.canEditOrder && reqFields.includes(FORM_FIELD_CLIENT_SOLUTION_PILOT) && !readOnly}
                                                  isSmall
                                                  setRef={this.customRefs[FORM_FIELD_CLIENT_SOLUTION_PILOT]}
                        />
                    </td>
                    <td id="order1Print" style={{maxWidth: '120px', minWidth: '90px'}}>
                        <InputSelectFieldWithValidation field={FORM_FIELD_TRANSPORT_PROVIDER}
                                                        options={transportProviderType}
                                                        isDisabled={!userRights.canEditOrder || readOnly}
                                                        intlMessages={formMessages}
                                                        isRequired={userRights.canEditOrder && reqFields.includes(FORM_FIELD_TRANSPORT_PROVIDER) && !readOnly && userStatus === PROVIDER}
                        />
                    </td>
                    <td id="order2Print" style={{maxWidth: '130px', minWidth: '110px'}}>
                        <InputFieldWithValidation field={FORM_FIELD_TRANSPORT_NUMBER}
                                                  isDisabled={!userRights.canEditOrder || readOnly}
                                                  isRequired={userRights.canEditOrder && reqFields.includes(FORM_FIELD_TRANSPORT_NUMBER) && !readOnly && userStatus === PROVIDER}
                                                  isSmall
                                                  setRef={this.customRefs[FORM_FIELD_TRANSPORT_NUMBER]}
                        />
                    </td>
                    <td id="order1Print" style={{maxWidth: '200px', minWidth: '175px'}}>
                        <InputSelectFieldWithValidation field={FORM_FIELD_CLAIM_TYPE}
                                                        options={claimType}
                                                        isDisabled={!userRights.canEditOrder || !userRights.canEditClaimType || readOnly}
                                                        intlMessages={formMessages}
                                                        sort={false}
                                                        isRequired={userRights.canEditOrder && userRights.canEditClaimType && reqFields.includes(FORM_FIELD_CLAIM_TYPE) && !readOnly}
                        />
                    </td>
                </tr>
            </>
        )
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

const mapStateToProps = (state, props) => ({
    userStatus: formValueSelector(props.form)(state, `${FORM_ORDERS_SECTION}.${FORM_FIELD_USER_STATUS}`),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTroubleshootUpperTableBody);
