import React, { useState } from 'react';
import Marquee from 'react-fast-marquee';
import styled from 'styled-components';
import get from 'get-value';

const NotificationBar = styled.div`
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffcc33;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  font-size: 15px;
  cursor: pointer;
`;

const HeaderNotification = () => {
    const loadStateFromLocalStorage = () => {
        let value = {};
        if (localStorage.hasOwnProperty('dog.config')) {
            value = get(JSON.parse(localStorage.getItem('dog.config')), 'notification', false);
            return value;
        }
        return false;
    }

    const [notificationMessage, setNotificationMessage] = useState(loadStateFromLocalStorage());
    if (!notificationMessage) return null;

    return (
        <NotificationBar isHidden={!notificationMessage}>
            <Marquee>
                {notificationMessage}
            </Marquee>
            <CloseButton onClick={() => setNotificationMessage(false)}>×</CloseButton>
        </NotificationBar>
    );
}

export default HeaderNotification;
