import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const DIC_CLAIMS_INFO_SETTED = 'DIC_CLAIMS_INFO_SETTED';
export const DIC_CLAIMS_CLOSE_MODAL = 'DIC_CLAIMS_CLOSE_MODAL';

export const closeClaimsDicModal = () => dispatch => {
    return dispatch({
        type: DIC_CLAIMS_CLOSE_MODAL,
    });
};

export const setDicClaimsInfoActionCreator = () => {
    return {
        type: DIC_CLAIMS_INFO_SETTED,
    }
};

export const fetchDicClaimsInfoActionCreator = (partNo) => {
    return {
        type: claimsWebsocketCommands.CLAIMS_DIC_PART_INFO_GET,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_DIC_PART_INFO_GET,
        payload: {
            partNo
        },
    }
};
