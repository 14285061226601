import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const CLAIMS_LIST_FETCH_PENDING = 'CLAIMS_LIST_FETCH_PENDING';

export const fetchClaimsListPageData = (pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    dispatch({
        type: CLAIMS_LIST_FETCH_PENDING,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_LIST_GET,
        payload: {
            pageSize: pageSize,
            sorted: sorted,
            cursor,
            isNextFromPivot,
            filtered: filtered
        }
    });
};
