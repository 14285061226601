import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'get-value';
import {createClaimsExport, downloadClaimsExport, fetchClaimsExport} from '../../../actions/claims/actionClaimsExport';
import {fetchClaimsDealers} from '../../../actions/claims/actionClaimsDealers';
import PageHeader from '../../common/PageHeader';
import Loader from '../../Loader';
import ExportClaimsForm from './ExportClaimsForm';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {CLAIMS_EXPORT_FORM_FIELD_CHARSET, CLAIMS_EXPORT_FORM_FIELD_TYPE, XLS} from '../../../constants/exportConstant';
import {FORM_FIELD_PFX, FORM_FIELD_SFX} from '../../../constants/formConstants';

class ExportClaimsPage extends Component {
    componentDidMount() {
        this.props.fetchClaimsDealers();
        this.props.fetchClaimsExport();
    }

    handleSubmit = (values) => {
        const pfx = get(values, 'pfxNumber', '').length;
        const sfx = get(values, 'sfxNumber', '').length;

        if (pfx > 0 && sfx === 0) {
            throw new SubmissionError({
                [FORM_FIELD_SFX]: 'Fill complete ticket number'
            })
        }

        if (pfx === 0 && sfx > 0) {
            throw new SubmissionError({
                [FORM_FIELD_PFX]: 'Fill complete ticket number'
            })
        }
        const temp = JSON.parse(JSON.stringify((({assigned, dealer = {}, status}) => {
            const {dealerNumber, dealerName} = dealer;
            return ({
                lastEditor: assigned ? assigned.trim() : undefined,
                dealerNumber: dealerNumber ? dealerNumber.trim() : undefined,
                dealerName: dealerName ? dealerName.trim() : undefined,
                status
            });
        })(values)));

        if (pfx || sfx) {
            temp.ticketNumber = `${values.pfxNumber}-${values.sfxNumber}`;
        }

        const exportFilter = {};
        exportFilter.filter = [];
        for (let key in temp) {
            if (temp.hasOwnProperty(key)) {
                if (key === 'lastEditor') {
                    temp[key].split(' ').forEach(element => exportFilter.filter.push({id: key, value: element}));

                } else {
                    exportFilter.filter.push({id: key, value: temp[key]})
                }
            }
        }

        if (values.from) {
            exportFilter.from = moment(values.from).add(12, 'hours').toISOString().substr(0, 10);
        }
        if (values.to) {
            exportFilter.to = moment(values.to).add(12, 'hours').toISOString().substr(0, 10);
        }
        exportFilter.type = values.type;
        if (!exportFilter.type) {
            exportFilter.type = XLS;
        }
        exportFilter.charset = values.charset;
        this.props.createClaimsExport(exportFilter);
    };

    downloadExport = () => {
        this.props.downloadClaimsExport(this.props.exportRecord);
    };

    render() {
        const {isLoadingDealer, isLoadingExport, charSets} = this.props;
        if (isLoadingDealer || isLoadingExport) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage id="claims.export.header" defaultMessage="Export"/>}/>
                <ExportClaimsForm onSubmit={this.handleSubmit}
                                  form={'claimsExportForm'}
                                  downloadExport={this.downloadExport}
                                  initialValues={{
                                      [CLAIMS_EXPORT_FORM_FIELD_TYPE]: XLS,
                                      [CLAIMS_EXPORT_FORM_FIELD_CHARSET]: charSets[0]
                                  }}
                />
            </div>
        );
    }
}

ExportClaimsPage.propTypes = {
    downloadExport: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoadingDealer: state.claimsDealers.isLoadingDealer,
    isLoadingExport: state.claimsExportTickets.isLoadingExport,
    exportRecord: state.claimsExportTickets.exportRecord,
    charSets: state.profile.userDetail.charSets,
});

export default checkRoles(connect(mapStateToProps, {
    fetchClaimsDealers,
    fetchClaimsExport,
    createClaimsExport,
    downloadClaimsExport,
})(injectIntl(ExportClaimsPage)), ['CL_EDITOR']);
