import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    PART_AND_VEHICLE_NON_COMPLIANT: {
        id: 'techDoc.problemCategory.option.partAndVehicleNonCompliant',
        defaultMessage: 'Part & Veh non compliant' // ok
    },
    WAY_OF_SELLING: {
        id: 'techDoc.problemCategory.option.wayOfSelling',
        defaultMessage: 'Way of selling' // ok
    },
    // REQUEST_JUSTIFIED_MTC_UPDATE: {
    //     id: 'techDoc.problemCategory.option.requestJustifiedMTCUpdate',
    //     defaultMessage: 'Request Justified MTC update'
    // },
    HISTO_FILE_ERROR_UPDATE: {
        id: 'techDoc.problemCategory.option.histoFileErrorUpdate',
        defaultMessage: 'Histo File update needed' // ok
    },
    ILLUSTRATION_UPDATE: {
        id: 'techDoc.problemCategory.option.illustrationUpdate',
        defaultMessage: 'Illustration update' // ok
    },
    // REQUEST_JUSTIFIED_SIGNEPA_UPDATE: {
    //     id: 'techDoc.problemCategory.option.requestJustifiedSignePAUpdate',
    //     defaultMessage: 'Request Justified SignePA update'
    // },
    // ASE_TEAM_TRANSFER: {
    //     id: 'techDoc.problemCategory.option.aseTeamTransfer',
    //     defaultMessage: 'ASe team transfer'
    // },
    // WAREHOUSE_STOCK_CHECK: {
    //     id: 'techDoc.problemCategory.option.warehouseStockCheck',
    //     defaultMessage: 'Warehouse stock check'
    // },
    REPAIR_ENGINEERING_IS_NOT_CONCERNED: {
        id: 'techDoc.problemCategory.option.repairEngineeringIsNotConcerned',
        defaultMessage: 'Repair engineering not concerned' // ok
    },
    PART_NUMBER_AVAILABLE_IN_NDIALOGYS: {
        id: 'techDoc.problemCategory.option.partNumberAvailableInNdialogys',
        defaultMessage: 'Part number available in documentation' // ok
    },
    PART_NUMBER_DEPLETED_WITHOUT_REPLACEMENT: {
        id: 'techDoc.problemCategory.option.partNumberDepletedWithoutReplacement',
        defaultMessage: 'Part number depleted without replacement' // ok
    },
    SERIAL_LIFE_SPECIFICATION_ERROR: {
        id: 'techDoc.problemCategory.option.serialLifeSpecificationError',
        defaultMessage: 'Serial life specification error' // ok
    },
    VALIDATED_REPLACEMENT: {
        id: 'techDoc.problemCategory.option.validatedReplacement',
        defaultMessage: 'Replacement validated' // pl
    },
    REFUSED_REPLACEMENT: {
        id: 'techDoc.problemCategory.option.refusedReplacement',
        defaultMessage: 'Replacement refused' // ok
    },
    ALTERNATIVE_OFFER: {
        id: 'techDoc.problemCategory.option.alternativeOffer',
        defaultMessage: 'Alternative offer for PVI or VOR' // ok
    },
    // CURRENT_REPLACEMENT: {
    //     id: 'techDoc.problemCategory.option.currentReplacement',
    //     defaultMessage: 'Current replacement'
    // },
    ASBOM_UPDATE_NEEDED: {
        id: 'techDoc.problemCategory.option.asbomUpdateNeeded',
        defaultMessage: 'ASBOM update needed' // ok
    },
    ASBOM_OK_STOCK_CHECK_NEEDED: {
        id: 'techDoc.problemCategory.option.asbomOkStockCheckNeeded',
        defaultMessage: 'ASBOM OK Stock check needed' // ok
    },
    IT_SOFTWARE_ISSUE: {
        id: 'techDoc.problemCategory.option.ITSoftwareIssue',
        defaultMessage: 'IT/Software Issue' // ok
    },
    MISSING_INFORMATION: {
        id: 'techDoc.problemCategory.option.missingInformation',
        defaultMessage: 'Missing information'
    }
});

export default messages;
