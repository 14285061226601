import {domains} from '../../constants/Utils';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const ADMIN_ESCALATION_LEVEL_FETCH = 'ADMIN_ESCALATION_LEVEL_FETCH';
export const ADMIN_ESCALATION_LEVEL_ADD = 'ADMIN_ESCALATION_LEVEL_ADD';
export const ADMIN_ESCALATION_LEVEL_EDIT = 'ADMIN_ESCALATION_LEVEL_EDIT';
export const ADMIN_ESCALATION_LEVEL_UPDATE = 'ADMIN_ESCALATION_LEVEL_UPDATE';
export const ADMIN_ESCALATION_LEVEL_REMOVE = 'ADMIN_ESCALATION_LEVEL_REMOVE';

export const fetchEscalationLevels = (domain) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_ESCALATION_LEVEL_FETCH,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_ESCALATION_LEVELS_GET
            });
        }
    }
};

export const addEscalationLevel = (domain, escalationLevel) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_ESCALATION_LEVEL_ADD,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_ESCALATION_LEVEL_CREATE,
                payload: {escalationLevel}
            });
        }
    }
};

export const editEscalationLevel = index => dispatch => {
    dispatch({
        type: ADMIN_ESCALATION_LEVEL_EDIT,
        payload: {index}
    });
};

export const updateEscalationLevel = (domain, escalationLevel) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_ESCALATION_LEVEL_UPDATE,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_ESCALATION_LEVEL_UPDATE,
                payload: {escalationLevel}
            });
        }
    }
};

export const removeEscalationLevel = (domain, escalationLevel) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_ESCALATION_LEVEL_REMOVE,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_ESCALATION_LEVEL_DELETE,
                payload: {escalationLevel}
            });
        }

    }
};


