import {
    CODED_PARTS_KEYS_FORM,
    FORM_FIELD_ABS,
    FORM_FIELD_AIR_CONDITIONING,
    FORM_FIELD_CLIENT_TYPE,
    FORM_FIELD_ENGINE_SERIAL_NUMBER,
    FORM_FIELD_ENGINE_SERIAL_NUMBER_VDS,
    FORM_FIELD_ENGINE_TYPE,
    FORM_FIELD_ENGINE_TYPE_SUFFIX,
    FORM_FIELD_GEARBOX_SERIAL_NUMBER,
    FORM_FIELD_GEARBOX_SERIAL_NUMBER_VDS,
    FORM_FIELD_GEARBOX_TYPE,
    FORM_FIELD_GEARBOX_TYPE_SUFFIX,
    FORM_FIELD_MORE_DETAILS,
    FORM_FIELD_POWER_STEERING,
    FORM_FIELD_RELATED_DEALERS_TICKETS,
    FORM_FIELD_SRC_CLAIM_N,
    FORM_FIELD_SRC_REQUEST,
    FORM_FIELD_STEERING_WHEEL,
    FORM_FIELD_STEERING_WHEEL_ON,
    FORM_FIELD_SUPPLEMENTARY_INFORMATION,
    FORM_FIELD_VIP, FORM_PROBLEM_DESCRIPTION_SECTION,
    FORM_SUPPLEMENTARY_INFORMATION_SECTION,
    FORM_VEHICLE_DETAIL_SECTION,
    OVAL_PLATE_ORDER_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM,
    UNKNOWN_PART_OPENING_REQUEST_FORM,
} from './formConstants';

// section
const sectionSupplementaryInformation = FORM_SUPPLEMENTARY_INFORMATION_SECTION;
const sectionVehicleDetail = FORM_VEHICLE_DETAIL_SECTION;
const sectionProblemDescription = FORM_PROBLEM_DESCRIPTION_SECTION;

// forms
const formPartsKeys = CODED_PARTS_KEYS_FORM
const formOval = OVAL_PLATE_ORDER_FORM;
const formNotConformReference = SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM;
const formNotFoundReference = SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM;
const formUnknownPartOpening = UNKNOWN_PART_OPENING_REQUEST_FORM;
const formRequestForSupport = SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM;

const values = {
    [FORM_FIELD_SUPPLEMENTARY_INFORMATION]: {
        [sectionSupplementaryInformation]: {
            [formNotFoundReference]: false,
            [formNotConformReference]: false,
            [formPartsKeys]: false,
            [formUnknownPartOpening]: false,
        }
    },
    [FORM_FIELD_ENGINE_TYPE]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: false,
            [formUnknownPartOpening]: false,
        }
    },
    [FORM_FIELD_ENGINE_SERIAL_NUMBER]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: false,
            [formUnknownPartOpening]: false,
        }
    },
    [FORM_FIELD_GEARBOX_TYPE]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: false,
            [formUnknownPartOpening]: false,
        }
    },
    [FORM_FIELD_GEARBOX_SERIAL_NUMBER]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: false,
            [formUnknownPartOpening]: false,
        }
    },
    [FORM_FIELD_ABS]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: false,
            [formUnknownPartOpening]: false,
        }
    },
    [FORM_FIELD_POWER_STEERING]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: false,
            [formUnknownPartOpening]: false,
        }
    },
    [FORM_FIELD_AIR_CONDITIONING]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: false,
            [formUnknownPartOpening]: false,
        }
    },
    [FORM_FIELD_STEERING_WHEEL]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: false,
            [formUnknownPartOpening]: false,
        }
    },
    [FORM_FIELD_VIP]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: true,
            [formUnknownPartOpening]: true,
        }
    },
    [FORM_FIELD_CLIENT_TYPE]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: true,
            [formUnknownPartOpening]: true,
        }
    },
    [FORM_FIELD_SRC_REQUEST]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: true,
            [formUnknownPartOpening]: true,
        }
    },
    [FORM_FIELD_SRC_CLAIM_N]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: true,
            [formUnknownPartOpening]: true,
        }
    },
    [FORM_FIELD_MORE_DETAILS]: {
        [sectionSupplementaryInformation]: {
            [formPartsKeys]: true,
            [formUnknownPartOpening]: true,
        }
    },
    [FORM_FIELD_RELATED_DEALERS_TICKETS]: {
        [sectionProblemDescription]: {
            [formRequestForSupport]: false,
            [formNotConformReference]: true,
            [formNotFoundReference]: true,
        }
    },
    // TODO wait to decide if those field shift to vehicle detail section and should be displayed
    [FORM_FIELD_ENGINE_TYPE_SUFFIX]: {
        [sectionVehicleDetail]: {
            [formPartsKeys]: false,
            [formOval]: false,
            [formNotFoundReference]: false,
            [formNotConformReference]: false,
            [formUnknownPartOpening]: false,
            [formRequestForSupport]: false,
        }
    },
    [FORM_FIELD_ENGINE_SERIAL_NUMBER_VDS]: {
        [sectionVehicleDetail]: {
            [formPartsKeys]: false,
            [formOval]: false,
            [formNotFoundReference]: false,
            [formNotConformReference]: false,
            [formUnknownPartOpening]: false,
            [formRequestForSupport]: false,
        }
    }, [FORM_FIELD_GEARBOX_TYPE_SUFFIX]: {
        [sectionVehicleDetail]: {
            [formPartsKeys]: false,
            [formOval]: false,
            [formNotFoundReference]: false,
            [formNotConformReference]: false,
            [formUnknownPartOpening]: false,
            [formRequestForSupport]: false,
        }
    }, [FORM_FIELD_GEARBOX_SERIAL_NUMBER_VDS]: {
        [sectionVehicleDetail]: {
            [formPartsKeys]: false,
            [formOval]: false,
            [formNotFoundReference]: false,
            [formNotConformReference]: false,
            [formUnknownPartOpening]: false,
            [formRequestForSupport]: false,
        }
    }, [FORM_FIELD_STEERING_WHEEL_ON]: {
        [sectionVehicleDetail]: {
            [formPartsKeys]: false,
            [formOval]: false,
            [formNotFoundReference]: false,
            [formNotConformReference]: false,
            [formUnknownPartOpening]: false,
            [formRequestForSupport]: false,
        }
    },
}

const displayField = (field, section, form) => {
   if (!values[field] || !values[field][section] || values[field][section][form] === undefined) {
       console.error(`field not declared! Form: ${field}, section: ${section}, form: ${form}`)
       return true;
   }
    return values[field][section][form];
}

export default displayField;
