import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const SPARE_PARTS_LIST_FETCH_PENDING = 'SPARE_PARTS_LIST_FETCH_PENDING';

export const fetchSparePartsOrdersListPageData = (pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    dispatch({
        type: SPARE_PARTS_LIST_FETCH_PENDING,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_LIST_GET,
        payload: { pageSize: pageSize, sorted: sorted, cursor, isNextFromPivot, filtered: filtered },
    });
};
