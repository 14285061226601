import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {change, formValueSelector} from 'redux-form';
import formMessages from '../../../../../../../intl/admin/escalationSchemaMessages';
import {
    FORM_ESCALATION_SCHEMA_ESCALATION_SECTION,
    FORM_ESCALATION_SCHEMA_INFO_SECTION,
    FORM_FIELD_ES_ESCALATION_GROUPS,
    FORM_FIELD_ES_SG_NAME,
    FORM_LABEL_ES_ESCALATION_GROUPS,
} from '../../../../../../../constants/formConstants';
import {FormattedMessage, injectIntl} from 'react-intl';
import Select from 'react-select';
import {Label, RequiredSpan} from '../../../../../../common/StyledComponents';
import get from 'get-value';

const StyledDiv = styled.div`
  border-radius: 5px;
`;



const EscalationSection = (props) => {
    const {
        readOnly, reqFields,  intl: {formatMessage}, domain, escalationSchemaOptions, selectedSG, selectedEscalationSG
    } = props;

    const formattedEscalationSG = (escalationSchemaOptions || {}).filter(sg => sg.value !== get(selectedSG, "value"))

    const handleEscalationSolutionGroupsSelectionChange = newOptions => {
        const { change } = props;

        const newOptionsToSave = newOptions.map(element => {
            return {
                value: element.value,
                label: element.label
            };
        });

        const uniqueOptionsToSave = Array.from(new Set(newOptionsToSave.map(x => x.value)))
            .map(value => {
                return {
                    value: value,
                    label: newOptionsToSave.find(y => y.value === value).label
                }
            })

        change(`${FORM_ESCALATION_SCHEMA_ESCALATION_SECTION}.${FORM_FIELD_ES_ESCALATION_GROUPS}`, uniqueOptionsToSave);
    };

    return (
        <>
            <hr />
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-12 pb-2">
                    </div>
                </div>
                <div className="row mb-sm-2">
                    <Label>
                        <FormattedMessage {...formMessages[FORM_LABEL_ES_ESCALATION_GROUPS]} />
                        {!readOnly ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
                    </Label>
                </div>
                <div className="row mb-sm-3">
                    <div className="col">
                        <Select
                            isDisabled={readOnly}
                            field={FORM_FIELD_ES_ESCALATION_GROUPS}
                            options={formattedEscalationSG}
                            onChange={handleEscalationSolutionGroupsSelectionChange}
                            value={selectedEscalationSG}
                            isRequired={reqFields.includes(FORM_FIELD_ES_ESCALATION_GROUPS) && !readOnly}
                            isMulti
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )

};

const escalationGroupSorted = state => {
    if (state.adminEscalationSchema && state.adminEscalationSchema.EscalationSchemaOptions) {
        return [...state.adminEscalationSchema.EscalationSchemaOptions]
            .sort((a, b) => a.label.localeCompare(b.label));
    }
    return [];
}

const mapStateToProps = (state, props) => ({
    escalationSchemaOptions: escalationGroupSorted(state),
    selectedSG: formValueSelector(props.form)(state, `${FORM_ESCALATION_SCHEMA_INFO_SECTION}.${FORM_FIELD_ES_SG_NAME}`),
    selectedEscalationSG: formValueSelector(props.form)(state, `${FORM_ESCALATION_SCHEMA_ESCALATION_SECTION}.${FORM_FIELD_ES_ESCALATION_GROUPS}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EscalationSection));
