import React from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {handleFetchSearchClientIdsError} from '../../../../actions/admin/actionAdminClientIds';
import {injectIntl} from 'react-intl';
import internalFulltextSearchMessages from '../../../../intl/sparePartsOrders/internalFulltextSearchMessages';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';
import {
    FORM_FIELD_CREATED_AT_INTERNAL_TICKET_FROM,
    FORM_FIELD_CREATED_AT_INTERNAL_TICKET_TO,
    FORM_FIELD_CREATOR_IPN, FORM_FIELD_DEALER_FORM_TYPE, FORM_FIELD_DEALER_TICKET_NUMBER,
    FORM_FIELD_INTERNAL_TICKET_NUMBER,
    FORM_FIELD_PARTNER_NAME,
    FORM_FIELD_PRIORITY_TYPE,
    FORM_FIELD_REQUEST_FOR_PARTNER,
    FORM_FIELD_REQUEST_STATUS,
    FORM_FIELD_SG_REQUESTER,
    FORM_FIELD_TYPE_OF_REQUEST,
    FORM_LABEL_CREATED_AT_INTERNAL_TICKET_FROM,
    FORM_LABEL_CREATED_AT_INTERNAL_TICKET_TO, FORM_LABEL_DEALER_FORM_TYPE, FORM_LABEL_DEALER_TICKET_NUMBER,
    FORM_LABEL_INTERNAL_TICKET_NUMBER,
    FORM_LABEL_PARTNER_NAME,
    FORM_LABEL_PRIORITY_TYPE,
    FORM_LABEL_REQUEST_FOR_PARTNER,
    FORM_LABEL_REQUEST_STATUS,
    FORM_LABEL_REQUESTOR_IPN,
    FORM_LABEL_SG_REQUESTER,
    FORM_LABEL_TYPE_OF_REQUEST
} from '../../../../constants/formConstants';
import formMessages from '../../../../intl/sparePartsOrders/formMessages';
import {
    getCurrentFormTypes,
    getInternalTicketFormStates,
    internalTicketRequestType,
    priorityType, requestForPartner
} from '../../../../constants/Utils';
import internalTicketRequestTypeMessages
    from '../../../../intl/sparePartsOrders/internalTicketRequestTypeMessages';
import InputSelectFieldWithValidation from '../../../common/InputSelectFieldWithValidation';
import formStatesMessages from '../../../../intl/common/formStatesMessages';
import priorityTypeMessages from '../../../../intl/sparePartsOrders/priorityTypeMessages';
import {Field} from 'redux-form';
import {AsyncSelectField} from '../../../common/FormField';
import commonMessages from '../../../../intl/common/commonMessages';
import BorderRadius from '../../../common/styled/BorderRadius';
import {handleAdminSearch} from '../../../../utils/userUtils';
import {handleFetchUsersError} from '../../../../actions/actionRole';
import requestForPartnerMessages from '../../../../intl/sparePartsOrders/requestForPartnerMessages';
import DatePickerComponent from '../../../common/datePicker/DatePickerComponent';
import moment from 'moment';
import formTypeMessages from '../../../../intl/common/formTypeMessages';

const RequestDetailInternalSearchSection = ({domain, intl}, ) => {

    const loadRequester = (input, callBack) => {
        handleAdminSearch(input, callBack, handleFetchUsersError, domain);
    }

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...internalFulltextSearchMessages.REQUEST_DETAIL_SECTION}/>
            </h3>
            <BorderRadius size={5} className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_INTERNAL_TICKET_NUMBER]}
                                                  field={FORM_FIELD_INTERNAL_TICKET_NUMBER}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_TYPE_OF_REQUEST]}
                                                        field={FORM_FIELD_TYPE_OF_REQUEST}
                                                        options={internalTicketRequestType}
                                                        intlMessages={internalTicketRequestTypeMessages}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_REQUEST_STATUS]}
                                                        field={FORM_FIELD_REQUEST_STATUS}
                                                        options={getInternalTicketFormStates(domain)}
                                                        intlMessages={formStatesMessages}
                        />
                    </div>
                    {/*<div className="col-xl-3 col-md-6 col-sm-12 pb-2">*/}
                    {/*    <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_PRIORITY_TREATMENT]}*/}
                    {/*                                    field={FORM_FIELD_PRIORITY_TREATMENT}*/}
                    {/*                                    options={priorityTreatment}*/}
                    {/*                                    intlMessages={priorityTreatmentMessages}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_PRIORITY_TYPE]}
                                                        field={FORM_FIELD_PRIORITY_TYPE}
                                                        options={priorityType}
                                                        intlMessages={priorityTypeMessages}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <label className="col-form-label pl-0 font-weight-bold">
                            <FormattedMessage {...internalFulltextSearchMessages[FORM_LABEL_REQUESTOR_IPN]}/>
                        </label>
                        <Field component={AsyncSelectField}
                               name={FORM_FIELD_CREATOR_IPN}
                               isClearable
                               useCustomStyles
                               noOptionsMessage={() => intl.formatMessage(commonMessages.NONE)}
                               loadingMessage={() => intl.formatMessage(commonMessages.LOADING)}
                               loadOptions={loadRequester}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_SG_REQUESTER]}
                                                  field={FORM_FIELD_SG_REQUESTER}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_PARTNER_NAME]}
                                                  field={FORM_FIELD_PARTNER_NAME}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_REQUEST_FOR_PARTNER]}
                                                        field={FORM_FIELD_REQUEST_FOR_PARTNER}
                                                        options={requestForPartner}
                                                        intlMessages={requestForPartnerMessages}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <Field component={DatePickerComponent}
                               selectsEnd
                               isClearable
                               label={internalFulltextSearchMessages[FORM_LABEL_CREATED_AT_INTERNAL_TICKET_FROM]}
                               name={FORM_FIELD_CREATED_AT_INTERNAL_TICKET_FROM}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <Field component={DatePickerComponent}
                               selectsEnd
                               isClearable
                               label={internalFulltextSearchMessages[FORM_LABEL_CREATED_AT_INTERNAL_TICKET_TO]}
                               name={FORM_FIELD_CREATED_AT_INTERNAL_TICKET_TO}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_DEALER_TICKET_NUMBER]}
                                                  field={FORM_FIELD_DEALER_TICKET_NUMBER}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_DEALER_FORM_TYPE]}
                                                        field={FORM_FIELD_DEALER_FORM_TYPE}
                                                        options={getCurrentFormTypes(domain)}
                                                        intlMessages={formTypeMessages}
                        />
                    </div>
                </div>
            </BorderRadius>
        </>
    )
};

const mapStateToProps = (state) => ({
    roles: state.profile.userDetail.roles,
    lastEditors: state.sparePartsInternalTicketSearch.lastEditors,
});

const mapDispatchToProps = (dispatch, props) => ({
    handleFetchUsersError,
    handleFetchSearchClientIdsError

});


export default  connect(mapStateToProps, mapDispatchToProps)(injectIntl(RequestDetailInternalSearchSection));
