import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {getFormInitialValues} from 'redux-form';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import {FormattedMessage} from 'react-intl';
import get from 'get-value';
import {Link} from 'react-router-dom';
import {formatInternalTicketNumber} from '../../../../../../utils/utils';
import {ORDERS_INTERNAL_TICKET} from '../../../../../../routes/paths';
import internalTicketRequestTypeMessages
    from '../../../../../../intl/sparePartsOrders/internalTicketRequestTypeMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const InternalTicketReferences = (props) => {
    const {userRights, initialValues} = props;

    const references = get(initialValues, 'internalTicketReferences') || [];
    const hasReferences = references && references.length > 0;
    return (
        <>
            {userRights.canViewInternalTicketReferences && hasReferences && <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.INTERNAL_TICKET_REFERENCES}/>
                </h3>
                    <StyledDiv className="border border-secondary bg-white overflow-hidden">
                        <div>
                            {references.map((ref, index) => {
                                    const ticketNumber = formatInternalTicketNumber(ref.pfxNumber,ref.sfxNumber);
                                    const link = `${ORDERS_INTERNAL_TICKET}/view/${ref.uuid}`;
                                    const requestTypeMessage = internalTicketRequestTypeMessages[ref.typeOfRequest];
                                    return <div key={index} className="row m-0 align-items-stretch">
                                        <div style={{backgroundColor: '#f8f9fa'}}
                                             className="col-sm-12 col-lg-3 flex-column border-bottom">
                                            <Link to={link}>{ticketNumber}</Link>
                                        </div>
                                        <div className="col border-bottom" style={{whiteSpace: 'pre-wrap'}}>
                                            {requestTypeMessage && <FormattedMessage {...requestTypeMessage}/> }
                                        </div>
                                    </div>;
                                }
                            )}
                        </div>
                    </StyledDiv>
            </>}
        </>
    )
}

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
});

export default connect(mapStateToProps)(InternalTicketReferences);
