import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import {fetchClaimsTicket, resetClaimsTicket, updateClaimsTicket} from '../../../actions/claims/actionClaimsTicket';
import {transformUpdateClaimsTicketForSave} from '../Utils';
import {
    lockClaimsTicket,
    resetRedirectAfterClaimsLockTicket,
    unlockClaimsTicket
} from '../../../actions/claims/actionClaimsLockTicket';
import Loader from '../../Loader';
import UploadingClaimsAttachments from './UploadingClaimsAttachments';
import checkRoles from '../../common/roleChecker/RoleChecker';
import EditLoadTicketDataPage from './EditLoadTicketDataPage';
import {CLAIMS_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY, CLAIMS_TICKET_STATUS_NEW, CLAIMS_TICKET_STATUS_READ_BY_SUBSIDIARY, FORM_BUTTON_MARK_AS_READ, FORM_BUTTON_TAKE_BACK} from '../../../constants/formConstants';
import {moduleRoles} from '../../../utils/roles';

class EditClaimsTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false, fetchedNewTicketData: false, skippedReadBySubsidiaryStatus: false};
    }

    componentWillUnmount() {
        const {uuid, redirect} = this.props.lockTicket;
        if (!redirect) {
            this.props.unlockClaimsTicket(uuid);
        }
        this.props.resetRedirectAfterClaimsLockTicket();
    }

    componentDidMount() {
        this.props.resetClaimsTicket();
        this.props.lockClaimsTicket(this.props.match.params.ticketUuid);

        if (!this.props.location.redirectedAfterUpdate) {
            this.setState({...this.state, fetchedNewTicketData: true});
            this.props.fetchClaimsTicket(this.props.match.params.ticketUuid);
        }
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const dataForSend = transformUpdateClaimsTicketForSave(values);
        this.props.updateClaimsTicket(dataForSend);
        if (!([FORM_BUTTON_MARK_AS_READ, FORM_BUTTON_TAKE_BACK].includes(submitBy))) {
            this.setState({ticketSubmittedAndShouldRedirect: true});
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.ticketSubmittedAndShouldRedirect && !this.state.ticketSubmittedAndShouldRedirect
            && prevProps.ticketData.isTicketCreating && !this.props.ticketData.isTicketCreating
            && this.props.ticketData.isTicketLoading) {
            this.props.lockClaimsTicket(this.props.match.params.ticketUuid);
        }
    }

    render() {
        const {ticketData, attachmentsData, lockTicket, loggedUser} = this.props;

        if (lockTicket.redirect) {
            return <Redirect to={`/claims/view/${lockTicket.uuid}`}/>;
        }

        let formType = false;
        if (get(ticketData, 'ticket.type', {default: false})) {
            formType = ticketData.ticket.type;
        }

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingClaimsAttachments attachments={attachmentsData.attachmentsForUpload}
                                               attachmentsUrl={ticketData.newAttachments}
                                               correlationId={ticketData.correlationId}
                                               isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                               formType={formType}/>
        }

        if (ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || lockTicket.isTicketLocking
            || !formType
        ) {
            return <Loader/>;
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading &&
            get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/claims/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (get(ticketData, 'ticket.uuid', {default: false})
            && [CLAIMS_TICKET_STATUS_NEW, CLAIMS_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY].includes(get(ticketData, 'ticket.status', {default: false}))
            && (moduleRoles.isEditorClaims(loggedUser) || moduleRoles.isSGEditorClaims(loggedUser))
            && !this.state.skippedReadBySubsidiaryStatus
            && this.state.fetchedNewTicketData
        ) {
            this.setState({...this.state, skippedReadBySubsidiaryStatus: true});
            this.props.updateClaimsTicket({
                ticket: {
                    pfxNumber: ticketData.ticket.pfxNumber,
                    sfxNumber: ticketData.ticket.sfxNumber,
                    status: CLAIMS_TICKET_STATUS_READ_BY_SUBSIDIARY,
                    uuid: ticketData.ticket.uuid,
                }
            });
            return <Loader/>;
        }

        return (
            <EditLoadTicketDataPage formType={formType} handleSubmit={this.handleSubmit}/>
        );
    }
}

EditClaimsTicketPage.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    ticketData: state.claimsTicket,
    lockTicket: state.claimsLockTicketCheck,
    attachmentsData: state.claimsAttachment,
    loggedUser: state.profile.userDetail.roles,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchClaimsTicket,
    resetClaimsTicket,
    lockClaimsTicket,
    unlockClaimsTicket,
    resetRedirectAfterClaimsLockTicket,
    updateClaimsTicket,
})(EditClaimsTicketPage)), ['CL_DEALER', 'CL_EDITOR']);

