import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const initiateOrderManagementGroupBatch = (spareParts) => (dispatch) => {
    dispatch({
        type: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MANAGEMENT_GROUP_CREATE,
        sparePartsWebsocket: true,
        payload: {
            spareParts
        },
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MANAGEMENT_GROUP_CREATE,
    });
};

export const checkOrderManagementGroupBatch = () => (dispatch) => {
    dispatch({
        type: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MANAGEMENT_GROUP_GET,
        sparePartsWebsocket: true,
        payload: {},
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MANAGEMENT_GROUP_GET,
    });
};
