import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const createTechDocExportInternal = (exportFilter) => (dispatch) => {
    dispatch({
        type: techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_CREATE,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_CREATE,
        payload: {
            exportFilter
        },
    });
};

export const fetchTechDocExportInternal = () => (dispatch) => {
    dispatch({
        type: techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_GET,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_GET,
        payload: {},
    });
};

export const downloadExportInternal = (exportRecord) => (dispatch) => {
    dispatch({
        type: techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_DOWNLOAD_URL_GET,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_DOWNLOAD_URL_GET,
        payload: {
            exportRecord
        },
    });
};
