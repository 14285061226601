import React from 'react';
import {
    FORM_FIELD_GAR_1, FORM_FIELD_GAR_2,
    FORM_FIELD_GAR_3, FORM_LABEL_GAR_1,
    FORM_LABEL_GAR_2, FORM_LABEL_GAR_3,
} from "../../../../../../constants/formConstants";
import {InputFieldWithValidation} from "../../../../../common/InputFieldWithValidation";
import formMessages from "../../../../../../intl/technicalDocumentations/formMessages";
import PropTypes from 'prop-types';

const GarComponent = (props) => {
    const { readOnly, userRights, reqFields } = props;

    return (
        <div className="form-row pb-2">
            <div className="col-xl-3 col-lg-6 col-md-12 pb-1">
                <InputFieldWithValidation label={formMessages[FORM_LABEL_GAR_1]}
                                          field={FORM_FIELD_GAR_1}
                                          maxLength={20}
                                          isDisabled={!userRights.canEditGarFields || readOnly}
                                          isRequired={reqFields.includes(FORM_FIELD_GAR_1)}
                                          isSmall/>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-12 pb-1">
                <InputFieldWithValidation label={formMessages[FORM_LABEL_GAR_2]}
                                          field={FORM_FIELD_GAR_2}
                                          maxLength={20}
                                          isDisabled={!userRights.canEditGarFields || readOnly}
                                          isRequired={reqFields.includes(FORM_FIELD_GAR_2)}
                                          isSmall/>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-12 pb-1">
                <InputFieldWithValidation label={formMessages[FORM_LABEL_GAR_3]}
                                          field={FORM_FIELD_GAR_3}
                                          maxLength={20}
                                          isDisabled={!userRights.canEditGarFields || readOnly}
                                          isRequired={reqFields.includes(FORM_FIELD_GAR_3)}
                                          isSmall/>
            </div>
        </div>
    )
}

GarComponent.propTypes = {
    readOnly: PropTypes.bool,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
};

export default GarComponent;
