import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    STATUS: {
        id: 'common.form.statusHistory.table.status',
        defaultMessage: 'Status'
    },
    BY: {
        id: 'common.form.statusHistory.table.by',
        defaultMessage: 'By'
    },
    FROM: {
        id: 'common.form.statusHistory.table.from',
        defaultMessage: 'From'
    },
    IPN: {
        id: 'common.form.statusHistory.table.ipn',
        defaultMessage: 'Ipn'
    },
    NAME: {
        id: 'common.form.statusHistory.table.name',
        defaultMessage: 'Name'
    },
    TO: {
        id: 'common.form.statusHistory.table.to',
        defaultMessage: 'To'
    },
    DATE_TIME: {
        id: 'common.form.statusHistory.table.dateTime',
        defaultMessage: 'Date time'
    },
});

export default messages;
