import React from 'react';
import styled from 'styled-components';
import {FormSection, formValueSelector, reduxForm} from 'redux-form'
import SubmitButtons from './sections/SubmitButtons';
import { scrollContentWrapperToTop } from '../../../../../utils/utils';
import formMessages from '../../../../../intl/admin/solutionGroupMessages';
import InfoSection from './sections/info/InfoSection';
import CountrySection from './sections/country/CountrySection';
import {
    FORM_FIELD_SG_DEFAULT,
    FORM_FIELD_SG_NAME,
    FORM_SOLUTION_GROUP_ADMIN_SECTION,
    FORM_SOLUTION_GROUP_COUNTRY_SECTION,
    FORM_SOLUTION_GROUP_INFO_SECTION, FORM_SOLUTION_GROUP_USER_SECTION,
    SOLUTION_GROUP_FORM
} from '../../../../../constants/formConstants';
import RenderSyncErrors from '../../../../common/RenderSyncErrors'
import {intlSolutionGroupFieldMapping} from '../../../../../constants/solutionGroupGieldLabelMapping';
import AdminSection from './sections/admin/AdminSection';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import EditorsSection from './sections/user/UserSection';
import {moduleRoles} from '../../../../../utils/roles';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const SolutionGroupForm = (props) => {
    const { form, reqFields, readOnly, handleSubmit, domain, isDefault, creating, roles } = props;

    const isSgAdmin = moduleRoles.isSGAdmin(roles, domain);
    const isPowerUser = moduleRoles.isPowerUser(roles, domain);

    const sgAdminOnly = !isPowerUser && isSgAdmin;
    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form}
                              intlMessages={formMessages}
                              mapping={intlSolutionGroupFieldMapping}/>
            <FormSection name={FORM_SOLUTION_GROUP_INFO_SECTION}>
                <InfoSection form={form}
                             domain={domain}
                             reqFields={reqFields}
                             creating={creating}
                             readOnly={readOnly || sgAdminOnly}/>
            </FormSection>
            {isDefault &&
                <FormSection name={FORM_SOLUTION_GROUP_COUNTRY_SECTION}>
                    <CountrySection form={form}
                                    reqFields={reqFields}
                                    readOnly={readOnly || sgAdminOnly}/>
                </FormSection>
            }
            <FormSection name={FORM_SOLUTION_GROUP_ADMIN_SECTION}>
                <AdminSection form={form}
                                reqFields={reqFields}
                                readOnly={readOnly || sgAdminOnly}
                                domain={domain}
                />
            </FormSection>
            <FormSection name={FORM_SOLUTION_GROUP_USER_SECTION}>
                <EditorsSection form={form}
                                reqFields={reqFields}
                                readOnly={readOnly}
                                domain={domain}
                />
            </FormSection>
            <SubmitButtons form={form}
                           reqFields={reqFields}
                           readOnly={readOnly}
                           onSubmit={handleSubmit}
                           onSubmitFail={scrollContentWrapperToTop}/>
        </StyledDiv>
    )
};

const mapStateToProps = (state, props) => ({
    isDefault: formValueSelector(props.form)(state, `${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_DEFAULT}`),
    roles: state.profile.userDetail.roles,
});

const mapDispatchToProps = (dispatch, props) => ({

});

export default reduxForm({
    form: SOLUTION_GROUP_FORM,
    asyncBlurFields: [`${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_NAME}`]
})(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SolutionGroupForm)));

