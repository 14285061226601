import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import formMessages from '../../../../../../../intl/admin/solutionGroupMessages';
import PropTypes from 'prop-types';
import {change, formValueSelector, getFormInitialValues, getFormValues} from 'redux-form';
import { withRouter } from 'react-router-dom';
import {
    FORM_FIELD_SG_MANUAL_USERS_LIST,
    FORM_FIELD_SG_USERS,
    FORM_LABEL_SG_USERS,
    FORM_SOLUTION_GROUP_USER_SECTION,
} from '../../../../../../../constants/formConstants';
import {handleFetchUsersError} from '../../../../../../../actions/actionRole';
import {handleAdminSearch, handleUserSearch} from '../../../../../../../utils/userUtils';
import {debounce} from 'throttle-debounce';
import {DEFAULT_DEBOUNCE_TIMEOUT} from '../../../../../../../constants/validationConstants';
import AsyncSelect from 'react-select/lib/Async';
import BorderRadius from '../../../../../../common/styled/BorderRadius';
import Label from '../../../../../../common/styled/Label';

class EditorsSection extends Component {

    loadOptions = (input, callBack) => {
        handleAdminSearch(input, callBack, this.props.handleFetchUsersError, this.props.domain);
    }

    filterDuplicity = newOptions => {
        const optionsWithFilteredFields = newOptions.map(item => ({value: item.value, label: item.label, mail: item.mail}));
        const optionsUnique = [...new Map(optionsWithFilteredFields.map(item => [item['value'], item])).values()];

        this.props.change(`${FORM_SOLUTION_GROUP_USER_SECTION}.${FORM_FIELD_SG_USERS}`, optionsUnique);
    };

    render() {
        const { readOnly, userList = ''} = this.props;

        return (
            <>
                <hr />
                <BorderRadius size={5} className="border border-secondary px-4 py-2 bg-white">
                    <div className="row mb-sm-2">
                        <Label bold>
                            <FormattedMessage {...formMessages[FORM_LABEL_SG_USERS]} />
                        </Label>
                    </div>
                    <div className="row mb-sm-3">
                        <div className="col">
                            <AsyncSelect
                                field={FORM_FIELD_SG_MANUAL_USERS_LIST}
                                value={userList}
                                loadOptions={debounce(DEFAULT_DEBOUNCE_TIMEOUT, this.loadOptions)}
                                onChange={this.filterDuplicity}
                                isDisabled={readOnly}
                                isMulti
                            />
                        </div>
                    </div>
                </BorderRadius>
            </>
        )
    }
}

EditorsSection.propTypes = {
    intl: PropTypes.object.isRequired,
};

const mapStateToProps = (state, initialProps) => ({
    formValues: getFormValues(initialProps.form)(state),
    initialValues: getFormInitialValues(initialProps.form)(state, `userSection`),
    userDetail: state.profile.userDetail,
    userList: formValueSelector(initialProps.form)(state, `${FORM_SOLUTION_GROUP_USER_SECTION}.${FORM_FIELD_SG_USERS}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
    handleFetchUsersError,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(EditorsSection)));
