import React from 'react';
import {Droppable} from 'react-beautiful-dnd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DragAndDropItem from './DragAndDropItem';
import {PRIMARY_DARK_GREY, PRIMARY_LIGHT_GREY} from '../../../theme/colors';

const DroppableList = styled.div`
    border-radius: 0 0 0.3em 0.3em;
    background-color: ${props => props.isDraggingOver ? PRIMARY_DARK_GREY : PRIMARY_LIGHT_GREY};
`;

const DragAndDropList = ({droppableId, draggableItems = []}) => (
    <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
            <DroppableList ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}
                           className="pt-sm-2 pl-sm-2 pr-sm-2 pb-sm-1">
                {draggableItems.map((item, index) => (
                    item && index !== undefined &&
                    <DragAndDropItem key={item.id} item={item} index={index}/>
                ))}
                {provided.placeholder}
            </DroppableList>
        )}
    </Droppable>
);

DragAndDropList.propTypes = {
    droppableId: PropTypes.string.isRequired,
    draggableItems: PropTypes.array
};

export default DragAndDropList;
