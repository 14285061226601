import {DIC_CLAIMS_CLOSE_MODAL, DIC_CLAIMS_INFO_SETTED} from '../../actions/claims/actionClaimsDicData';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const claimsDic = (state = {
    isModalActive: false,
    partNo: {},
    partDescription: {},
    vorStatus: {},
    dicVinStatusLoaded: false,
}, action) => {
    switch (action.type) {
        case claimsWebsocketCommands.CLAIMS_DIC_PART_INFO_GET: {
            return Object.assign({}, state, {dicPartInfoLoaded: false, isModalActive: true});
        }
        case claimsWebsocketCommands.CLAIMS_DIC_PART_INFO_SEND: {
            const {partNo, partDescription, vorStatus, correlationId, managementGroup} = action.payload;
            if (correlationId < state.partInfoCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                partInfoCorrelationId: correlationId,
                partNo,
                partDescription,
                vorStatus,
                managementGroup,
                dicPartInfoLoaded: true,
                isModalActive: false
            });
        }
        case claimsWebsocketCommands.CLAIMS_DIC_PART_INFO_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.partInfoCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {
                partNo: {},
                partDescription: {},
                vorStatus: {},
                isModalActive: false,
                dicPartInfoLoaded: false,
                partInfoCorrelationId: correlationId,
            });
        }
        case DIC_CLAIMS_INFO_SETTED: {
            return Object.assign({}, state, {
                partNo: {},
                partDescription: {},
                vorStatus: {},
                isModalActive: false,
                dicPartInfoLoaded: false
            });
        }
        case DIC_CLAIMS_CLOSE_MODAL: {
            return Object.assign({}, state, {
                isModalActive: false,
            });
        }
        default:
            return state;
    }
};
