import {defineMessages} from 'react-intl';

const messages = defineMessages({
    NEW: {
        id: 'common.export.status.new',
        defaultMessage: 'New'
    },
    PROCESSING: {
        id: 'common.export.status.processing',
        defaultMessage: 'Processing'
    },
    ERROR: {
        id: 'common.export.status.error',
        defaultMessage: 'Error'
    },
    TO_MANY: {
        id: 'common.export.status.toMany',
        defaultMessage: 'Error - To many records'
    },
    NO_GROUP: {
        id: 'common.export.status.noGroup',
        defaultMessage: 'Error - User is missing admin or editor group for permissions'
    },
    FINISH: {
        id: 'common.export.status.finish',
        defaultMessage: 'Finish'
    },
    MISSING_WORKING_HOURS: {
        id: 'common.export.status.missingWorkingHours',
        defaultMessage: 'Error - Missing working hours'
    },
});

export default messages;
