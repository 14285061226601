import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    ORDERING_CONFIRMATION: {
        id: 'spareParts.batteryProblemType.option.orderingConfirmation',
        defaultMessage: 'Request on the ordering confirmation of a traction battery'
    },
    FOLLOW_UP: {
        id: 'spareParts.batteryProblemType.option.followUp',
        defaultMessage: 'Request on the follow-up of the ordered traction battery'
    },
    OTHER: {
        id: 'spareParts.batteryProblemType.option.other',
        defaultMessage: 'Others requests'
    },
});

export default messages;
