import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {change, formValueSelector, getFormInitialValues} from 'redux-form';
import {
    BUTTON_LABEL_LOAD, FORM_BUTTON_ESCALATE, FORM_BUTTON_FORWARD_TO_DEALER, FORM_BUTTON_TAKE_BACK,
    FORM_CHECKBOX_PVI_RELATED, FORM_CHECKBOX_RELATED_DEALERS_TICKETS,
    FORM_FIELD_DIALOGYS_VERSION,
    FORM_FIELD_ITEM_NUMBER, FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_PAGE_POST,
    FORM_FIELD_PAGE_PREF,
    FORM_FIELD_PART_DESCRIPTION, FORM_FIELD_PART_NO,
    FORM_FIELD_PART_NUMBER,
    FORM_FIELD_PR,
    FORM_FIELD_PROBLEM_DESCRIPTION, FORM_FIELD_PVI_RELATED,
    FORM_FIELD_QUANTITY, FORM_FIELD_RELATED_DEALERS_TICKETS,
    FORM_FIELD_SUPPLIER,
    FORM_LABEL_ITEM_NUMBER, FORM_LABEL_MANAGEMENT_GROUP,
    FORM_LABEL_PAGE,
    FORM_LABEL_PART_DESCRIPTION,
    FORM_LABEL_PART_NUMBER,
    FORM_LABEL_PR,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_PVI_RELATED,
    FORM_LABEL_QUANTITY, FORM_LABEL_RELATED_DEALERS_TICKETS,
    FORM_LABEL_SUPPLIER, FORM_PROBLEM_DESCRIPTION_SECTION,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from "../../../../../common/InputFieldWithValidation";
import {TextareaFieldWithValidation} from "../../../../../common/TextareaFieldWithValidation";
import InputSelectFieldWithValidation from "../../../../../common/InputSelectFieldWithValidation";
import {InputFieldPage} from "../../../../../common/InputFieldPage";
import {InputGroupCheckbox} from "../../../../../common/InputGroupCheckbox";
import {positiveNumber, positiveNumberWithZero, validAlphanumericInput} from '../../../../../common/validationCommons';
import formMessages from "../../../../../../intl/technicalDocumentations/formMessages";
import {FormattedMessage, injectIntl} from 'react-intl';
import {countryGroups, suppliers} from '../../../../../../constants/Utils';
import get from 'get-value';
import supplierMessages from "../../../../../../intl/technicalDocumentations/supplierMessages";
import InternalTicketReferences from './InternalTicketReferences';
import GarComponent from './GarComponent';
import warnMessages from '../../../../../../intl/common/warnMessages';
import {bindActionCreators} from 'redux';
import {
    fetchDicPartInfoActionCreator,
    setDicTechDocInfoActionCreator
} from '../../../../../../actions/techDoc/actionTechDocDicData';
import {moduleRoles} from '../../../../../../utils/roles';
import {isEmpty, size} from 'lodash';
import {PrimaryButton} from '../../../../../common/Button';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import buttonMessages from '../../../../../../intl/common/buttonMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;


class ProblemDescriptionUnknownSection extends Component {
    handlePartLoad = () => {
        const {fetchDicPartInfoActionCreator, partNo} = this.props;
        fetchDicPartInfoActionCreator(partNo);
    };

    fillDicPartInfo = () => {
        const {dicInfo, change, setDicTechDocInfoActionCreator, warnAlert} = this.props;
        const part = dicInfo.partNo;
        setDicTechDocInfoActionCreator();
        if (part) {
            if (!part[FORM_FIELD_MANAGEMENT_GROUP]) {
                change(`${FORM_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_MANAGEMENT_GROUP}`, dicInfo.managementGroup);
            } else {
                warnAlert(warnMessages.TD_DIC_WARN004, null, {partNumber: dicInfo.partNo})
            }
        } else {
            warnAlert(warnMessages.TD_DIC_WARN003, null, {partNumber: dicInfo.partNo});
        }
    };


    render(){
    const {readOnly, userRights, reqFields, initialValues, intl: {formatMessage}, group, form, dicInfo, partNo, user: {roles}, solutionGroupInfo, submittedBy} = this.props;

    if (dicInfo.dicPartInfoLoaded) {
        this.fillDicPartInfo();
    }

    console.log("TEST");
    console.log(reqFields)

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.PROBLEM_DESCRIPTION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-xl-2 col-lg-3 col-md-4 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_PR]}
                                                  field={FORM_FIELD_PR}
                                                  maxLength={5}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_PR)}
                                                  validations={[validAlphanumericInput]}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-8 pb-1">
                        <InputFieldPage label={formMessages[FORM_LABEL_PAGE]}
                                        fields={[FORM_FIELD_PAGE_PREF, FORM_FIELD_PAGE_POST]}
                                        isDisabled={!userRights.canEditProblemDesc || readOnly}
                                        isRequired={reqFields.includes(FORM_FIELD_PAGE_PREF) || reqFields.includes(FORM_FIELD_PAGE_POST)}
                                        validations={[positiveNumberWithZero]}
                        />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-8 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_ITEM_NUMBER]}
                                                  field={FORM_FIELD_ITEM_NUMBER}
                                                  maxLength={2}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_ITEM_NUMBER)}
                                                  validations={[positiveNumber]}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-4 col-lg-6 col-md-12 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_PART_NUMBER]}
                                                  field={FORM_FIELD_PART_NUMBER}
                                                  maxLength={10}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_PART_NUMBER)}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-1 col-lg-2 col-md-3 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_QUANTITY]}
                                                  field={FORM_FIELD_QUANTITY}
                                                  maxLength={3}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_QUANTITY)}
                                                  validations={[positiveNumber]}
                                                  isSmall/>
                    </div>
                    {(group !== countryGroups.GB && group !== countryGroups.IE) &&
                    <div className="col-xl-3 col-lg-4 col-md-9 pb-1">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_SUPPLIER]}
                                                        field={FORM_FIELD_SUPPLIER}
                                                        options={get(suppliers, `${get(initialValues, 'ticketSection.group', {default: 'X'})}`, {default: []})}
                                                        intlMessages={supplierMessages}
                                                        isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                        isRequired={reqFields.includes(FORM_FIELD_SUPPLIER)}
                        />
                    </div>
                    }
                </div>
                <div className="form-row">
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_PART_DESCRIPTION]}
                                                  field={FORM_FIELD_PART_DESCRIPTION}
                                                  maxLength={30}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_PART_DESCRIPTION)}
                                                  isSmall/>
                    </div>
                    {moduleRoles.isSGEditorDocumentation(roles) &&
                        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 pb-2">
                            <div className="form-row align-items-end">
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                    <InputFieldWithValidation label={formMessages[FORM_LABEL_MANAGEMENT_GROUP]}
                                                              field={FORM_FIELD_MANAGEMENT_GROUP}
                                                              maxLength={2}
                                                              isDisabled={!userRights.canEditManagementGroup || readOnly}
                                                              isRequired={(isEmpty(solutionGroupInfo) ? (reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP)) : (reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP) && !get(solutionGroupInfo, "groups", []).length == 0)) && submittedBy === FORM_BUTTON_ESCALATE && !readOnly}
                                                              validations={[validAlphanumericInput]}
                                                              isSmall/>
                                </div>
                                {userRights.canEditManagementGroup && !readOnly &&
                                    <>
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 pl-1 pt-1">
                                            <PrimaryButton type="button" className="btn"
                                                           disabled={size(partNo) < 10}
                                                           onClick={() => this.handlePartLoad()}
                                            >
                                                <ValidFormattedMessage message={BUTTON_LABEL_LOAD}
                                                                       intlMessages={buttonMessages}/>
                                            </PrimaryButton>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className="form-row">
                    <div className="col-12 pr-0 pb-1">
                        <TextareaFieldWithValidation label={formMessages[FORM_LABEL_PROBLEM_DESCRIPTION]}
                                                     field={FORM_FIELD_PROBLEM_DESCRIPTION}
                                                     isDisabled={!userRights.canEditProblemDescText || readOnly}
                                                     isRequired={reqFields.includes(FORM_FIELD_PROBLEM_DESCRIPTION)}
                                                     rows={6}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-3 col-lg-6">
                        <InputGroupCheckbox label={formMessages[FORM_LABEL_PVI_RELATED]}
                                            fields={[FORM_CHECKBOX_PVI_RELATED, FORM_FIELD_PVI_RELATED]}
                                            maxLength={8}
                                            isDisabled={!userRights.canEditProblemDesc || readOnly}
                                            isRequired={reqFields.includes(FORM_CHECKBOX_PVI_RELATED) || reqFields.includes(FORM_FIELD_PVI_RELATED)}
                                            placeholder={formatMessage(formMessages.PVI_RELATED_NO)}/>
                    </div>
                </div>
                <div className="form-row">
                    {userRights.canViewRelatedDealersTickets && <div className="col-sm-12">
                        <InputGroupCheckbox label={formMessages[FORM_LABEL_RELATED_DEALERS_TICKETS]}
                                            fields={[FORM_FIELD_RELATED_DEALERS_TICKETS, FORM_CHECKBOX_RELATED_DEALERS_TICKETS]}
                                            isDisabled={!userRights.canEditProblemDesc || readOnly}
                                            isRequired={reqFields.includes(FORM_FIELD_RELATED_DEALERS_TICKETS) || reqFields.includes(FORM_CHECKBOX_RELATED_DEALERS_TICKETS)}
                                            maxLength={100}
                                            placeholder={formatMessage(formMessages.RELATED_TICKETS_NO)}/>
                    </div>}
                </div>
                {/*{userRights.canViewGarFields && <GarComponent form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>}*/}
                {userRights.canViewInternalTicketReferences && <InternalTicketReferences form={form} />}
            </StyledDiv>
        </>
    )
    }
}

const mapStateToProps = (state, initialProps) => ({
    initialValues: getFormInitialValues(initialProps.form)(state),
    group: state.profile.userDetail.group,
    partNo: formValueSelector(initialProps.form)(state, `${FORM_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_PART_NO}`),
    dicInfo: state.techDocDic,
    user: state.profile.userDetail,
    solutionGroupInfo: state.techDocTicket.solutionGroupInfo,
    submittedBy: state.techDocTicket.submittedBy,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            setDicTechDocInfoActionCreator,
            fetchDicPartInfoActionCreator
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProblemDescriptionUnknownSection));
