import {defineMessages} from 'react-intl';

const messages = defineMessages({
    FILE_SELECT: {
        id: 'common.file.select.file.select',
        defaultMessage: 'Choose a file or drag it here'
    },
    FILE_DROP: {
        id: 'common.file.select.file.drop',
        defaultMessage: 'Drop the file'
    },
    NEW_ATTACHMENTS: {
        id: 'common.attachments.new',
        defaultMessage: 'New attachments'
    }
});

export default messages;
