import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import get from 'get-value';
import {
    confirmClaimsPopUpModal,
    fetchClaimsPopUp,
    openClaimsPopUpModal
} from '../../actions/claims/actionClaimsPopUpModal';
import {domains} from '../../constants/Utils';
import Loader from '../Loader';
import NewClaimsTicketPage from './ticket/NewClaimsTicketPage';
import ViewClaimsTicketPage from './ticket/ViewClaimsTicketPage';
import EditClaimsTicketPage from './ticket/EditClaimsTicketPage';
import ListClaimsPage from '../claims/list/ListClaimsPage';
import HistoryClaimsPage from '../claims/history/HistoryClaimsPage';
import FullTextSearchClaimsPage from './fullTextSearch/FullTextSearchClaimsPage';
import ExportClaimsPage from './export/ExportClaimsPage';
import RecurrentProblemClaimsPage from '../claims/recurrentProblem/RecurrentProblemClaimsPage';
import NotFoundPage from '../NotFoundPage';
import ClaimsPopUpModal from './ClaimsPopUpModal';
import {
    CLAIMS_PATH,
    CLAIMS_LIST_PATH,
    CLAIMS_HISTORY_PATH,
    CLAIMS_RECURRENT_PROBLEM_PATH,
    CLAIMS_NEW_CONTEST_CLAIM_STATUS,
    CLAIMS_NEW_PROBLEM_FOR_PLACING_CLAIMS,
    CLAIMS_NEW_RETURN_CLAIM,
    CLAIMS_NEW_STOCK_DEAD_CLAIM,
    CLAIMS_NEW_OLD_MATERIAL_CLAIM,
    CLAIMS_NEW_DIRECT_FLOW_CLAIM, CLAIMS_FULL_TEXT_SEARCH_PATH, CLAIMS_EXPORT_PATH, CLAIMS_NEW_TROUBLESHOOT_CLAIM
} from '../../routes/paths';
import {
    CONTEST_CLAIM_STATUS_FORM,
    PROBLEM_FOR_PLACING_CLAIMS_FORM,
    RETURN_CLAIM_FORM,
    STOCK_DEAD_CLAIM_FORM,
    OLD_MATERIAL_CLAIM_FORM,
    DIRECT_FLOW_CLAIM_FORM,
    TROUBLESHOOT_CLAIM_FORM
} from '../../constants/formConstants';

const domain = domains.CLAIMS;

class Claims extends Component {
    componentDidMount() {
        if (!this.props.isConfirm && this.props.userDetail.dealerNumber && this.props.userDetail.dealerNumber !== '' &&  get(this.props.userDetail, `roles.${domain}.dealer`)) {
            this.props.fetchClaimsPopUp(this.props.group);
            this.props.openClaimsPopUpModal();
        }
    }

    render() {
        const {userDetail, isOpen, confirmClaimsPopUpModal, isConfirm, isLoadingPopUp} = this.props;
        const dealer = get(userDetail, `roles.${domain}.dealer`);
        if (dealer && !isConfirm) {
            return isLoadingPopUp ? <Loader/> : <ClaimsPopUpModal show={isOpen}
                                                                  handleClose={confirmClaimsPopUpModal}/>;
        }
        return (
            <Switch>
                <Route exact path={CLAIMS_PATH}
                       render={() => dealer ? <Redirect to={CLAIMS_RECURRENT_PROBLEM_PATH}/> :
                           <Redirect to={CLAIMS_LIST_PATH}/>}/>
                <Route exact path={CLAIMS_NEW_CONTEST_CLAIM_STATUS}
                       render={() => <NewClaimsTicketPage formType={CONTEST_CLAIM_STATUS_FORM}/>}/>
                <Route exact path={CLAIMS_NEW_PROBLEM_FOR_PLACING_CLAIMS}
                       render={() => <NewClaimsTicketPage formType={PROBLEM_FOR_PLACING_CLAIMS_FORM}/>}/>
                <Route exact path={CLAIMS_NEW_RETURN_CLAIM}
                       render={() => <NewClaimsTicketPage formType={RETURN_CLAIM_FORM}/>}/>
                <Route exact path={CLAIMS_NEW_STOCK_DEAD_CLAIM}
                       render={() => <NewClaimsTicketPage formType={STOCK_DEAD_CLAIM_FORM}/>}/>
                <Route exact path={CLAIMS_NEW_OLD_MATERIAL_CLAIM}
                       render={() => <NewClaimsTicketPage formType={OLD_MATERIAL_CLAIM_FORM}/>}/>
                <Route exact path={CLAIMS_NEW_DIRECT_FLOW_CLAIM}
                       render={() => <NewClaimsTicketPage formType={DIRECT_FLOW_CLAIM_FORM}/>}/>
                <Route exact path={CLAIMS_NEW_TROUBLESHOOT_CLAIM}
                       render={() => <NewClaimsTicketPage formType={TROUBLESHOOT_CLAIM_FORM}/>}/>
                <Route exact path={CLAIMS_PATH + '/:view(view)/:ticketUuid(.*)'}
                       component={ViewClaimsTicketPage}/>
                <Route exact path={CLAIMS_PATH + '/:edit(edit)/:ticketUuid(.*)'}
                       component={EditClaimsTicketPage}/>
                <Route exact path={CLAIMS_LIST_PATH}
                       component={ListClaimsPage}/>
                <Route exact path={CLAIMS_HISTORY_PATH}
                       component={HistoryClaimsPage}/>
                <Route exact path={CLAIMS_FULL_TEXT_SEARCH_PATH}
                       component={FullTextSearchClaimsPage}/>
                <Route exact path={CLAIMS_EXPORT_PATH}
                       component={ExportClaimsPage}/>
                <Route exact path={CLAIMS_RECURRENT_PROBLEM_PATH}
                       component={RecurrentProblemClaimsPage}/>
                <Route component={NotFoundPage}/>
            </Switch>
        );
    }
}

const mapStateToProps = state => ({
    isOpen: state.claimsPopUpModal.isOpen,
    isConfirm: state.claimsPopUpModal.isConfirm,
    userDetail: state.profile.userDetail,
    isLoadingPopUp: state.claimsPopUpModal.isLoading,
    group: state.profile.userDetail.group,
});

export default withRouter(connect(mapStateToProps, {
    openClaimsPopUpModal,
    confirmClaimsPopUpModal,
    fetchClaimsPopUp
})(Claims));
