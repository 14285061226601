import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import set from 'set-value';
import {clearAlerts} from '../../../actions/alertsActions';
import {initNewClaimsTicket, transformNewTicketForSave} from '../Utils';
import {resetClaimsTicket, saveClaimsTicket} from '../../../actions/claims/actionClaimsTicket';
import {fetchAdminClientIds} from '../../../actions/admin/actionAdminClientIds';
import {createRights} from '../../../constants/claimsTicketRights';
import {createReqFields} from '../../../constants/claimsRequiredFields';
import checkRoles from '../../common/roleChecker/RoleChecker';
import ContestClaimStatusMasterForm from './forms/ContestClaimStatusMasterForm';
import ProblemForPlacingClaimsMasterForm from './forms/ProblemForPlacingClaimsMasterForm';
import ReturnClaimMasterForm from './forms/ReturnClaimMasterForm';
import StockDeadClaimMasterForm from './forms/StockDeadClaimMasterForm';
import OldMaterialClaimMasterForm from './forms/OldMaterialClaimMasterForm';
import DirectFlowClaimMasterForm from './forms/DirectFlowClaimMasterForm';
import UploadingClaimsAttachments from './UploadingClaimsAttachments';
import Loader from '../../Loader';
import NoBirComponent from '../../common/NoBirComponent';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import PageHeader from '../../common/PageHeader';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import {
    CONTEST_CLAIM_STATUS_FORM,
    PROBLEM_FOR_PLACING_CLAIMS_FORM,
    RETURN_CLAIM_FORM,
    STOCK_DEAD_CLAIM_FORM,
    OLD_MATERIAL_CLAIM_FORM,
    DIRECT_FLOW_CLAIM_FORM,
    TROUBLESHOOT_CLAIM_FORM,
} from '../../../constants/formConstants';
import TroubleshootMasterForm from './forms/TroubleshootMasterForm';
import formMessages from '../../../intl/claims/formMessages';
import { closeClaimsDicModal } from '../../../actions/claims/actionClaimsDicData';
import OverlayLoader from '../../OverlayLoader';

class NewClaimsTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false};
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetClaimsTicket();
        this.props.fetchAdminClientIds();
    }

    handleSubmit = async (values) => {
        const dataForSend = await transformNewTicketForSave(values.newValues);
        this.props.saveClaimsTicket(dataForSend);
        this.setState({ticketSubmittedAndShouldRedirect: true});
    };

    render() {
        const {formType, isLoadingClientIds, ticketData, attachmentsData, clientIds, dicInfo, closeClaimsDicModal} = this.props;

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingClaimsAttachments attachments={attachmentsData.attachmentsForUpload}
                                               attachmentsUrl={ticketData.newAttachments}
                                               correlationId={ticketData.correlationId}
                                               isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                               formType={formType}/>
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading &&
            get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/claims/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (!formType
            || this.state.ticketSubmittedAndShouldRedirect
            || isLoadingClientIds
            || clientIds.isLoading) {
            return <Loader/>;
        }

        const initializationValue = initNewClaimsTicket(this.props.userDetail);
        if (!initializationValue) {
            return <NoBirComponent formType={formType}/>;
        }
        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={<ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>}/>
                <div>
                    {formType === CONTEST_CLAIM_STATUS_FORM &&
                    <ContestClaimStatusMasterForm form={formType}
                                                  initialValues={set(initializationValue, 'ticketSection.type', CONTEST_CLAIM_STATUS_FORM)}
                                                  userRights={createRights}
                                                  reqFields={createReqFields[formType]}
                                                  enableReinitialize={true}
                                                  handleSubmit={this.handleSubmit}/>
                    }
                    {formType === PROBLEM_FOR_PLACING_CLAIMS_FORM &&
                    <ProblemForPlacingClaimsMasterForm form={formType}
                                                       initialValues={set(initializationValue, 'ticketSection.type', PROBLEM_FOR_PLACING_CLAIMS_FORM)}
                                                       userRights={createRights}
                                                       reqFields={createReqFields[formType]}
                                                       enableReinitialize={true}
                                                       handleSubmit={this.handleSubmit}/>
                    }
                    {formType === RETURN_CLAIM_FORM &&
                    <ReturnClaimMasterForm form={formType}
                                           initialValues={set(initializationValue, 'ticketSection.type', RETURN_CLAIM_FORM)}
                                           userRights={createRights}
                                           reqFields={createReqFields[formType]}
                                           enableReinitialize={true}
                                           handleSubmit={this.handleSubmit}/>
                    }
                    {formType === STOCK_DEAD_CLAIM_FORM &&
                    <StockDeadClaimMasterForm form={formType}
                                              initialValues={set(initializationValue, 'ticketSection.type', STOCK_DEAD_CLAIM_FORM)}
                                              userRights={createRights}
                                              reqFields={createReqFields[formType]}
                                              enableReinitialize={true}
                                              handleSubmit={this.handleSubmit}/>
                    }
                    {formType === OLD_MATERIAL_CLAIM_FORM &&
                    <OldMaterialClaimMasterForm form={formType}
                                                initialValues={set(initializationValue, 'ticketSection.type', OLD_MATERIAL_CLAIM_FORM)}
                                                userRights={createRights}
                                                reqFields={createReqFields[formType]}
                                                enableReinitialize={true}
                                                handleSubmit={this.handleSubmit}/>
                    }
                    {formType === DIRECT_FLOW_CLAIM_FORM &&
                    <DirectFlowClaimMasterForm form={formType}
                                               initialValues={set(initializationValue, 'ticketSection.type', DIRECT_FLOW_CLAIM_FORM)}
                                               userRights={createRights}
                                               reqFields={createReqFields[formType]}
                                               enableReinitialize={true}
                                               handleSubmit={this.handleSubmit}/>
                    }
                    {formType === TROUBLESHOOT_CLAIM_FORM &&
                    <TroubleshootMasterForm form={formType}
                                               initialValues={set(initializationValue, 'ticketSection.type', TROUBLESHOOT_CLAIM_FORM)}
                                               userRights={createRights}
                                               reqFields={createReqFields[formType]}
                                               enableReinitialize={true}
                                               handleSubmit={this.handleSubmit}/>
                    }
                </div>
                <OverlayLoader active={dicInfo.isModalActive}
                               text={formMessages.WAIT_DIC}
                               handleClose={closeClaimsDicModal}/>
            </div>
        );
    }
}

NewClaimsTicketPage.propTypes = {
    formType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    userDetail: state.profile.userDetail,
    isLoadingClientIds: state.adminClientIds.isLoading,
    ticketData: state.claimsTicket,
    attachmentsData: state.claimsAttachment,
    clientIds: state.adminClientIds,
    dicInfo: state.claimsDic,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    saveClaimsTicket,
    fetchAdminClientIds,
    resetClaimsTicket,
    closeClaimsDicModal
})(NewClaimsTicketPage)), ['CL_DEALER']);

