import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    margin-left: 5px;
`;

export default function TableHeader({id, message, filterable = true, sorted = []}) {
    const sortedItem = sorted.filter(sortedItem => sortedItem.id === id)[0];
    return <div>
        <FormattedMessage {...message}/>
        {filterable &&
        <StyledFontAwesomeIcon icon={sortedItem ? (sortedItem.desc ? 'sort-down' : 'sort-up') : 'sort'}/>
        }
    </div>
}

TableHeader.propTypes = {
    id: PropTypes.string.isRequired,
    message: PropTypes.object.isRequired,
    filterable: PropTypes.bool,
    sorted: PropTypes.array.isRequired
};
