import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Select from 'react-select';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import {PRIMARY_GREY} from '../../../theme/colors'

const styles = {
    multiValueLabel: (base, state) => {
        return state.data.isFixed ? {...base, color: PRIMARY_GREY, paddingRight: '6px'} : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? {...base, display: 'none'} : base;
    }
};

function getPreselectedCountryGroup(selectedCountryGroup, groups, formatMessage) {
    return groups.map(group => ({
        value: group.group,
        label: formatMessage(countryGroupMessages[group.group]),
        isFixed: true
    })).find(group => group.value === selectedCountryGroup);
}

function addPreselectedCountry(input, preselectedCountryGroup = {}) {
    let result = [];
    if (Array.isArray(input.value)) {
        result = result.concat(input.value);
    }
    if (!result.some(group => group.value === preselectedCountryGroup.value)) {
        result = [preselectedCountryGroup].concat(result);
    }
    return result;
}

const SelectGroupsField = (
    {
        input,
        label,
        groups,
        selectedCountryGroup = '',
        intl: {formatMessage},
        meta: {touched, error, warning}
    }
) => {
    const inputValues = addPreselectedCountry(
        input,
        getPreselectedCountryGroup(selectedCountryGroup, groups, formatMessage)
    );
    return (
        <div className="row">
            <div className="col-2">{label}</div>
            <div className="col-10">
                <Select
                    value={inputValues}
                    onChange={input.onChange}
                    onBlur={() => input.onBlur(input.value)}
                    options={groups.map(group => ({
                        value: group.group,
                        label: formatMessage(countryGroupMessages[group.group]),
                        isFixed: false
                    }))}
                    placeholder={formatMessage(countryGroupMessages.SELECT_COUNTRIES)}
                    isMulti
                    isSearchable
                    isClearable={inputValues.some(group => !group.isFixed)}
                    styles={styles}/>
            </div>
            <div className="col-12">
                {touched &&
                ((error && <span>{error}</span>) ||
                    (warning && <span>{warning}</span>))}
            </div>
        </div>
    );
};

SelectGroupsField.propTypes = {
    input: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    groups: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
    label: PropTypes.object.isRequired,
    selectedCountryGroup: PropTypes.string
};

export default injectIntl(SelectGroupsField);
