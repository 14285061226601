import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.ticketSetting.title',
        defaultMessage: 'Ticket setting'
    },
    SELECT_COUNTRY: {
        id: 'admin.ticketSetting.selectCountry',
        defaultMessage: 'Select a country'
    },
    TICKET_BLOCKING_DURATION: {
        id: 'admin.ticketSetting.blockingDuration',
        defaultMessage: 'Blocking Time duration (min)'
    },
    DAYS_TO_HISTORY: {
        id: 'admin.ticketSetting.daysToHistory',
        defaultMessage: 'Days to move in History'
    },
    DAYS_TO_CLOSE: {
        id: 'admin.ticketSetting.daysToClose',
        defaultMessage: 'Automatic ticket closure (days)'
    },
});

export default messages;
