import {domains} from '../../constants/Utils';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const ADMIN_WORKING_HOURS_FETCH_PENDING = 'ADMIN_WORKING_HOURS_FETCH_PENDING';
export const ADMIN_WORKING_HOURS_SAVE_PENDING = 'ADMIN_WORKING_HOURS_SAVE_PENDING';
export const ADMIN_WORKING_HOURS_RESET = 'ADMIN_WORKING_HOURS_RESET';
export const ADMIN_WORKING_HOURS_UPDATE = 'ADMIN_WORKING_HOURS_UPDATE';
export const ADMIN_WORKING_HOURS_SELECT_GROUP = 'ADMIN_WORKING_HOURS_SELECT_GROUP';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_WORKING_HOURS_SELECT_GROUP,
        payload: countryGroup
    });

export function updateWorkingHours(day, values) {
    return {
        type: ADMIN_WORKING_HOURS_UPDATE,
        payload: {day, values},
    };
}

export function resetWorkingHours() {
    return {
        type: ADMIN_WORKING_HOURS_RESET,
    };
}

export const fetchWorkingHoursData = ({domain, group}) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_WORKING_HOURS_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_WORKING_HOURS_GET,
                payload: {group}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_WORKING_HOURS_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_WORKING_HOURS_GET,
                payload: {group}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_WORKING_HOURS_FETCH_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_WORKING_HOURS_GET,
                payload: {group}
            });
        }
    }
};

export const storeWorkingHours = ({domain, workingHours}) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_WORKING_HOURS_SAVE_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_WORKING_HOURS_SET,
                payload: {workingHours}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_WORKING_HOURS_SAVE_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_WORKING_HOURS_SET,
                payload: {workingHours}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_WORKING_HOURS_SAVE_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_WORKING_HOURS_SET,
                payload: {workingHours}
            });
        }
    }
}
