import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import get from 'get-value';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {
    FORM_FIELD_CREATION_DATE,
    FORM_FIELD_NETWORK_TICKET_NUMBER,
    FORM_FIELD_PARTNER_NAME,
    FORM_FIELD_PRIORITY_TREATMENT,
    FORM_FIELD_PRIORITY_TYPE,
    FORM_FIELD_REQUEST_FOR_PARTNER,
    FORM_FIELD_REQUESTOR_IPN,
    FORM_FIELD_REQUESTOR_NAME,
    FORM_FIELD_SOLUTION_GROUP, FORM_FIELD_SRC_NUMBER,
    FORM_FIELD_TYPE_OF_REQUEST,
    FORM_LABEL_NETWORK_TICKET_NUMBER,
    FORM_LABEL_PARTNER_NAME,
    FORM_LABEL_PRIORITY_TREATMENT,
    FORM_LABEL_PRIORITY_TYPE,
    FORM_LABEL_REQUEST_FOR_PARTNER,
    FORM_LABEL_REQUESTOR_IPN,
    FORM_LABEL_REQUESTOR_NAME,
    FORM_LABEL_SOLUTION_GROUP, FORM_LABEL_SRC_NUMBER,
    FORM_LABEL_TYPE_OF_REQUEST,
    FORM_TICKET_SECTION
} from '../../../../../../constants/formConstants';
import {change, formValueSelector, getFormInitialValues} from 'redux-form';
import formMessages from '../../../../../../intl/technicalDocumentations/formMessages';
import {
    internalTicketRequestType, PRIORITY_SRC, PRIORITY_SRC_VOR, PRIORITY_VOR,
    priorityTreatment, priorityType,
    requestForPartner, requestForPartnerList, YES
} from '../../../../../../constants/Utils';
import priorityTreatmentMessages from '../../../../../../intl/technicalDocumentations/priorityTreatmentMessages';
import requestForPartnerMessages from '../../../../../../intl/technicalDocumentations/requestForPartnerMessages';
import internalTicketRequestTypeMessages from '../../../../../../intl/technicalDocumentations/internalTicketRequestTypeMessages';
import requestForPartnerListMessages
    from '../../../../../../intl/technicalDocumentations/requestForPartnerListMessages';
import priorityTypeMessages from '../../../../../../intl/technicalDocumentations/priorityTypeMessages';
import BorderRadius from '../../../../../common/styled/BorderRadius';
import {fetchTechDocInternalTicketSgInfo} from '../../../../../../actions/techDoc/actionTechDocInternalTicket';
import {Link} from 'react-router-dom';
import {ConditionalWrapper} from '../../../../../common/ConditionalWrapper';

const RequestDetailSection = (props) => {
    const {solutionGroupInfo, ticketReferenceId, userRights, readOnly, reqFields, roles, requestForPartnerValue, networkTicketNumberValue, priorityTreatmentValue, priorityTypeValue, initialValues, fetchTechDocInternalTicketSgInfo} = props;

    const priorityChanged = (event, value) => {
        if (value === 'YES') {
            props.change(`${FORM_TICKET_SECTION}.${FORM_FIELD_PRIORITY_TYPE}`, priorityTypeValue || PRIORITY_SRC);
        } else {
            props.change(`${FORM_TICKET_SECTION}.${FORM_FIELD_PRIORITY_TYPE}`, '');
            props.change(`${FORM_TICKET_SECTION}.${FORM_FIELD_SRC_NUMBER}`, '');
        }
    }

    const priorityTypeChanged = (event, value) => {
        if (value === PRIORITY_VOR) {
            props.change(`${FORM_TICKET_SECTION}.${FORM_FIELD_SRC_NUMBER}`, '');
        }
    }

    const handleChange = (event, value) => {
        fetchTechDocInternalTicketSgInfo(value);
        props.change(`${FORM_TICKET_SECTION}.${FORM_FIELD_TYPE_OF_REQUEST}`, '');
    };

    const getSolutionGroupsOptions = () => {
        // requester solution group can be change only during creation of the ticket
        const sgGroups = (!userRights.canEditInternalTicketRequestDetail || readOnly) ?
            [get(initialValues, 'requestorSolutionGroup')] : // read => get current solution group
             get(roles, 'TECHNICAL_DOCUMENTATION.sgEditor.sgPermission'); // edit => offer users solution groups

        return (sgGroups || []).map(sg => ({
            uuid: sg.id,
            presetName: sg.name
        }));
    };

    const getRequestTypesOptions = () => {
        // type of request can be change only during creation of the ticket
        const types = (!userRights.canEditInternalTicketRequestDetail || readOnly) ?
            [get(initialValues, FORM_FIELD_TYPE_OF_REQUEST)] : // read => get current type of request
            internalTicketRequestType.filter(type => get(solutionGroupInfo, `escalationSchemas.${type}`));

        return types;
    };

    return (
        <>
            <h3 className="text-left ml-4">
                <FormattedMessage {...formMessages.REQUEST_DETAIL_SECTION}/>
            </h3>
            <BorderRadius size={5} className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_REQUESTOR_NAME]}
                                                  field={FORM_FIELD_REQUESTOR_NAME}
                                                  isSmall
                                                  isRequired={reqFields.includes(FORM_FIELD_REQUESTOR_NAME)}
                                                  isDisabled
                        />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_REQUESTOR_IPN]}
                                                  field={FORM_FIELD_REQUESTOR_IPN}
                                                  isSmall
                                                  isRequired={reqFields.includes(FORM_FIELD_REQUESTOR_IPN)}
                                                  isDisabled
                        />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_SOLUTION_GROUP]}
                                                        field={FORM_FIELD_SOLUTION_GROUP}
                                                        customOptionsMsg={getSolutionGroupsOptions()}
                                                        isRequired={reqFields.includes(FORM_FIELD_SOLUTION_GROUP)}
                                                        isDisabled={!userRights.canEditInternalTicketRequestDetail || readOnly}
                                                        handleChange={handleChange}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_REQUEST_FOR_PARTNER]}
                                                        field={FORM_FIELD_REQUEST_FOR_PARTNER}
                                                        options={requestForPartner}
                                                        intlMessages={requestForPartnerMessages}
                                                        isRequired={reqFields.includes(FORM_FIELD_REQUEST_FOR_PARTNER)}
                                                        isDisabled={!userRights.canEditInternalTicketRequestDetail || readOnly}
                                                        noEmpty
                        />
                    </div>
                    {(requestForPartnerValue === YES) &&
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_PARTNER_NAME]}
                                                            field={FORM_FIELD_PARTNER_NAME}
                                                            options={requestForPartnerList}
                                                            intlMessages={requestForPartnerListMessages}
                                                            isRequired={reqFields.includes(FORM_FIELD_PARTNER_NAME)}
                                                            isDisabled={!userRights.canEditInternalTicketRequestDetail || readOnly}
                            />
                        </div>
                    }
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_TYPE_OF_REQUEST]}
                                                        field={FORM_FIELD_TYPE_OF_REQUEST}
                                                        options={getRequestTypesOptions()}
                                                        intlMessages={internalTicketRequestTypeMessages}
                                                        isRequired={reqFields.includes(FORM_FIELD_TYPE_OF_REQUEST)}
                                                        isDisabled={!userRights.canEditInternalTicketRequestDetail || readOnly}
                        />
                    </div>
                    {(networkTicketNumberValue) &&
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <ConditionalWrapper condition={!!ticketReferenceId}
                                                wrapper={children => <Link to={{pathname: `/documentation/view/${ticketReferenceId}`, redirectedFromInternal: true}}>
                                                    {children}
                                                </Link>}
                            >
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_NETWORK_TICKET_NUMBER]}
                                                      field={FORM_FIELD_NETWORK_TICKET_NUMBER}
                                                      isSmall
                                                      isRequired={reqFields.includes(FORM_FIELD_NETWORK_TICKET_NUMBER)}
                                                      isDisabled
                            />
                            </ConditionalWrapper>
                        </div>
                    }
                </div>
                <div className="form-row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_PRIORITY_TREATMENT]}
                                                        field={FORM_FIELD_PRIORITY_TREATMENT}
                                                        options={priorityTreatment}
                                                        intlMessages={priorityTreatmentMessages}
                                                        isRequired={reqFields.includes(FORM_FIELD_PRIORITY_TREATMENT)}
                                                        isDisabled={!userRights.canEditInternalTicketRequestDetail || readOnly}
                                                        noEmpty
                                                        handleChange={priorityChanged}
                        />
                    </div>
                    {(priorityTreatmentValue === YES) &&
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_PRIORITY_TYPE]}
                                                            field={FORM_FIELD_PRIORITY_TYPE}
                                                            options={priorityType}
                                                            intlMessages={priorityTypeMessages}
                                                            isRequired={priorityTreatmentValue === YES}
                                                            isDisabled={!userRights.canEditInternalTicketRequestDetail || readOnly}
                                                            handleChange={priorityTypeChanged}
                                                            noEmpty
                            />
                        </div>
                    }
                    {(priorityTypeValue === PRIORITY_SRC || priorityTypeValue === PRIORITY_SRC_VOR) &&
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_SRC_NUMBER]}
                                                      field={FORM_FIELD_SRC_NUMBER}
                                                      maxLength={20}
                                                      isDisabled={!userRights.canEditInternalTicketPartDetail || readOnly}
                                                      isRequired={priorityTypeValue === PRIORITY_SRC || priorityTypeValue === PRIORITY_SRC_VOR}
                                                      isSmall/>
                        </div>
                    }
                </div>
            </BorderRadius>
        </>
    )
};

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, props) => ({
    ticketReferenceId: get(state, 'techDocInternalTicket.ticketReference.uuid', false),
    initialValues: getFormInitialValues(props.form)(state),
    creationDate: selector(props.form, state, `${FORM_TICKET_SECTION}.${FORM_FIELD_CREATION_DATE}`),
    requestForPartnerValue: selector(props.form, state, `${FORM_TICKET_SECTION}.${FORM_FIELD_REQUEST_FOR_PARTNER}`),
    networkTicketNumberValue: selector(props.form, state, `${FORM_TICKET_SECTION}.${FORM_FIELD_NETWORK_TICKET_NUMBER}`),
    priorityTreatmentValue: selector(props.form, state, `${FORM_TICKET_SECTION}.${FORM_FIELD_PRIORITY_TREATMENT}`),
    priorityTypeValue: selector(props.form, state, `${FORM_TICKET_SECTION}.${FORM_FIELD_PRIORITY_TYPE}`),
    roles: state.profile.userDetail.roles,
    solutionGroupInfo: get(state, 'techDocInternalTicket.solutionGroupInfo', {}),
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            fetchTechDocInternalTicketSgInfo
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value))
});
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RequestDetailSection));
