import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {cloneDeep} from 'lodash';
import {
    clearEditorRightsManagementPageData,
    fetchEditorRightsManagementPageData,
    clearDealerRightsManagementPageData,
    fetchDealerRightsManagementPageData,
    saveDealerRightsManagementPageData,
    setDealerFormTypesChanged,
    setInitialDealerFormTypeOptions,
    setManagedUser,
    setSelectedDealerFormTypeOptions
} from '../../../actions/admin/actionAdminRightsManagement';
import SelectUser from './SelectUser';
import {clearAlerts} from '../../../actions/alertsActions';
import SelectDealerFormTypes from './SelectDealerFormTypes';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import Loader from '../../Loader';
import {handleUserSearch} from '../../../utils/userUtils';
import PageHeader from '../../common/PageHeader';
import errorMessages from "../../../intl/common/errorMessages";
import {handleFetchUsersError} from '../../../actions/actionRole';

const Panel = styled.h1`
  font-size: 1.5em;
  text-align: center;
`;

class DealerRightsManagementPage extends Component {
    componentDidMount() {
        if (this.props.managedUser && this.props.managedUser.ipn) {
            this.props.setDealerFormTypesChanged(false);
            this.loadData(this.props.managedUser);
        }
    }

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = user => {
        this.props.setManagedUser(user);
        this.loadData(user);
    };

    handleFormTypeSelectionChange = newOptions => {
        this.setSelectedOptionsChanged(newOptions);
        this.props.setSelectedDealerFormTypeOptions(newOptions);
    };

    handleAllFormTypesClick = () => {
        const selectedFormTypes = {};

        Object.keys(this.props.dealerData || {}).forEach(
            formType => selectedFormTypes[formType] = true
        );

        const selectedOptions = this.setDealerDataAsFormTypeOptions(selectedFormTypes);
        this.setSelectedOptionsChanged(selectedOptions);
    };

    handleSaveClick = () => {
        const selectedFormTypes = {};

        Object.keys(this.props.dealerData || {}).forEach(
            formType => selectedFormTypes[formType] = false
        );
        if (this.props.selectedOptions) {
            this.props.selectedOptions.forEach(
                ({value}) => {
                    selectedFormTypes[value] = true;
                }
            )
        }

        this.props.saveDealerRightsManagementPageData(this.props.managedUser, this.props.domain, selectedFormTypes);
    };

    handleCancelClick = () => {
        this.props.setSelectedDealerFormTypeOptions(cloneDeep(this.props.initialOptions));
        this.props.setDealerFormTypesChanged(false);
    };

    loadData = user => {
        this.props.clearAlerts();
        this.props.clearDealerRightsManagementPageData();
        this.props.clearEditorRightsManagementPageData();
        this.props.fetchEditorRightsManagementPageData(user, this.props.domain);
        this.props.fetchDealerRightsManagementPageData(user, this.props.domain);
    };

    setDealerDataAsFormTypeOptions = (dealerData) => {
        const result = Object.keys(dealerData || {}).map(countryIso => ({value: countryIso}));
        this.props.setSelectedDealerFormTypeOptions(cloneDeep(result));

        return result;
    };

    setSelectedOptionsChanged = newOptions => this.props.setDealerFormTypesChanged(
        JSON.stringify(
            cloneDeep(this.props.initialOptions)
                .sort((option1, option2) => option1.value.localeCompare(option2.value))
        ) !== JSON.stringify(
        newOptions
            .sort((option1, option2) => option1.value.localeCompare(option2.value))
        )
    );

    render() {
        const {isLoading, managedUser, dealerData, domain, isEditor} = this.props;
        if (isLoading) {
            return <Loader/>;
        }
        return (
            <div>
                <PageHeader title={
                    <FormattedMessage id="admin.title.auth.mng.dealer"
                                      defaultMessage="Authorization Management - {domain} - Dealer"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <div className="mb-sm-4">
                    <SelectUser handleUserSelection={this.handleUserSelection}
                                handleUserSearch={this.handleUserSearch} />
                </div>
                {isEditor &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.EDITOR_ROLE_SET} />
                </Panel>
                }
                {managedUser && managedUser.ipn && !managedUser.dealerNumber &&
                <Panel className="alert alert-warning">
                    <FormattedMessage {...errorMessages.DEALER_ROLE_NO_BIR} />
                </Panel>
                }
                {dealerData && Object.keys(dealerData).length > 0 && managedUser && managedUser.ipn && managedUser.dealerNumber && !isEditor &&
                <SelectDealerFormTypes handleFormTypeSelectionChange={this.handleFormTypeSelectionChange}
                                       handleAllFormTypesClick={this.handleAllFormTypesClick}
                                       handleSaveClick={this.handleSaveClick}
                                       handleCancelClick={this.handleCancelClick} />
                }
            </div>
        );
    }
}

DealerRightsManagementPage.propTypes = {
    managedUser: PropTypes.object.isRequired,
    setManagedUser: PropTypes.func.isRequired,
    setSelectedDealerFormTypeOptions: PropTypes.func.isRequired,
    dealerData: PropTypes.object.isRequired,
    selectedOptions: PropTypes.array,
    saveDealerRightsManagementPageData: PropTypes.func.isRequired,
    initialOptions: PropTypes.array.isRequired,
    setDealerFormTypesChanged: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    clearDealerRightsManagementPageData: PropTypes.func.isRequired,
    fetchDealerRightsManagementPageData: PropTypes.func.isRequired,
    clearEditorRightsManagementPageData: PropTypes.func.isRequired,
    fetchEditorRightsManagementPageData: PropTypes.func.isRequired,
    setInitialDealerFormTypeOptions: PropTypes.func.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEditor: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired
};


const mapStateToProps = state => ({
    isLoading: state.adminRightsManagement.isLoading,
    isEditor: Object.values(state.adminRightsManagement.editorData || {}).some(value => value),
    managedUser: state.adminRightsManagement.managedUser,
    dealerData: state.adminRightsManagement.dealerData,
    initialOptions: state.adminRightsManagement.initialDealerOptions,
    selectedOptions: state.adminRightsManagement.selectedDealerOptions,
});

export default connect(mapStateToProps, {
    setManagedUser,
    clearAlerts,
    clearEditorRightsManagementPageData,
    fetchEditorRightsManagementPageData,
    clearDealerRightsManagementPageData,
    fetchDealerRightsManagementPageData,
    setInitialDealerFormTypeOptions,
    setSelectedDealerFormTypeOptions,
    setDealerFormTypesChanged,
    saveDealerRightsManagementPageData,
    handleFetchUsersError
})(DealerRightsManagementPage);
