import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    // TOP MENU
    ADMINISTRATION: {
        id: 'admin.topMenu.administration',
        defaultMessage: 'Administration'
    },
    SPARE_PARTS: {
        id: 'admin.topMenu.spareParts',
        defaultMessage: 'Spare Parts Orders'
    },
    TECH_DOC: {
        id: 'admin.topMenu.techDoc',
        defaultMessage: 'Technical Documentation'
    },
    CLAIMS: {
        id: 'admin.topMenu.claims',
        defaultMessage: 'Claims'
    },
    GENERIC_TICKET: {
        id: 'admin.topMenu.genericTicket',
        defaultMessage: 'Generic Ticket'
    },

    // ADMIN SIDE MENU
    ADMIN_COMMON_SETTING: {
        id: 'admin.sideMenu.commonSetting',
        defaultMessage: 'Common setting'
    },
    ADMIN_COMMON_SETTING_CLIENT_ID: {
        id: 'admin.sideMenu.commonSetting.clientIdSetting',
        defaultMessage: 'Client ID setting'
    },
    ADMIN_SPARE_PARTS: {
        id: 'admin.sideMenu.spareParts',
        defaultMessage: 'Spare Parts Orders',
    },
    ADMIN_SPARE_PARTS_NOTIFICATION: {
        id: 'admin.sideMenu.spareParts.dealerNotification',
        defaultMessage: 'Dealer notification setting'
    },
    ADMIN_SPARE_PARTS_MAIN_PAGE_ALERTS: {
        id: 'admin.sideMenu.spareParts.mainPageAlerts',
        defaultMessage: 'Main page alerts'
    },
    ADMIN_SPARE_PARTS_RECURRENT_PROBLEMS: {
        id: 'admin.sideMenu.spareParts.recurrentProblems',
        defaultMessage: 'Recurrent Problems setting'
    },
    ADMIN_SPARE_PARTS_WORKING_HOURS: {
        id: 'admin.sideMenu.spareParts.workingHours',
        defaultMessage: 'Working hours'
    },
    ADMIN_SPARE_PARTS_TICKET_SETTING: {
        id: 'admin.sideMenu.spareParts.ticketSetting',
        defaultMessage: 'Ticket setting'
    },
    ADMIN_SPARE_PARTS_DEFAULT_COUNTRY_ROLE: {
        id: 'admin.sideMenu.spareParts.defaultCountryRole',
        defaultMessage: 'Default country role'
    },
    ADMIN_SPARE_PARTS_AUTH_MANAGEMENT: {
        id: 'admin.sideMenu.spareParts.authorizationManagement',
        defaultMessage: 'Authorization management'
    },
    ADMIN_SPARE_PARTS_AUTH_MANAGEMENT_ADMIN: {
        id: 'admin.sideMenu.spareParts.authorizationManagement.admin',
        defaultMessage: 'Admin'
    },
    ADMIN_SPARE_PARTS_AUTH_MANAGEMENT_DEALER: {
        id: 'admin.sideMenu.spareParts.authorizationManagement.dealer',
        defaultMessage: 'Dealer'
    },
    ADMIN_SPARE_PARTS_AUTH_MANAGEMENT_EDITOR: {
        id: 'admin.sideMenu.spareParts.authorizationManagement.editor',
        defaultMessage: 'Editor'
    },
    ADMIN_SPARE_PARTS_SECOND_HAND_BATTERY_TYPE: {
        id: 'admin.sideMenu.spareParts.secondHandBatteryType',
        defaultMessage: 'Second-Hand Battery Type'
    },
    ADMIN_TECH_DOC: {
        id: 'admin.sideMenu.techDoc',
        defaultMessage: 'Technical Documentation'
    },
    ADMIN_TECH_DOC_NOTIFICATION: {
        id: 'admin.sideMenu.techDoc.dealerNotification',
        defaultMessage: 'Dealer notification setting'
    },
    ADMIN_TECH_DOC_MAIN_PAGE_ALERTS: {
        id: 'admin.sideMenu.techDoc.mainPageAlerts',
        defaultMessage: 'Main page alerts'
    },
    ADMIN_TECH_DOC_RECURRENT_PROBLEMS: {
        id: 'admin.sideMenu.techDoc.recurrentProblems',
        defaultMessage: 'Recurrent Problems setting'
    },
    ADMIN_TECH_DOC_WORKING_HOURS: {
        id: 'admin.sideMenu.techDoc.workingHours',
        defaultMessage: 'Working hours'
    },
    ADMIN_TECH_DOC_TICKET_SETTING: {
        id: 'admin.sideMenu.techDoc.ticketSetting',
        defaultMessage: 'Ticket setting'
    },
    ADMIN_TECH_DOC_DEFAULT_COUNTRY_ROLE: {
        id: 'admin.sideMenu.techDoc.defaultCountryRole',
        defaultMessage: 'Default country role'
    },
    ADMIN_TECH_DOC_AUTH_MANAGEMENT: {
        id: 'admin.sideMenu.techDoc.authorizationManagement',
        defaultMessage: 'Authorization management'
    },
    ADMIN_TECH_DOC_AUTH_MANAGEMENT_ADMIN: {
        id: 'admin.sideMenu.techDoc.authorizationManagement.admin',
        defaultMessage: 'Admin'
    },
    ADMIN_TECH_DOC_AUTH_MANAGEMENT_DEALER: {
        id: 'admin.sideMenu.techDoc.authorizationManagement.dealer',
        defaultMessage: 'Dealer'
    },
    ADMIN_TECH_DOC_AUTH_MANAGEMENT_EDITOR: {
        id: 'admin.sideMenu.techDoc.authorizationManagement.editor',
        defaultMessage: 'Editor'
    },
    ADMIN_TECH_DOC_MULTI_LEVEL_ESCALATION_SCHEMA: {
        id: 'admin.sideMenu.techDoc.multiLevelEscalationSchema',
        defaultMessage: 'Multi-level Escalation Schema'
    },
    ADMIN_TECH_DOC_SOLUTION_GROUP_MANAGEMENT_ESCALATION_LEVELS: {
        id: 'admin.sideMenu.techDoc.solutionGroupManagement.escalationLevels',
        defaultMessage: 'Escalation levels'
    },
    ADMIN_TECH_DOC_SOLUTION_GROUP_NEW: {
        id: 'admin.sideMenu.techDoc.solutionGroupManagement.solutionGroupNew',
        defaultMessage: 'New solution group'
    },
    ADMIN_TECH_DOC_SOLUTION_GROUP_LIST: {
        id: 'admin.sideMenu.techDoc.solutionGroupManagement.solutionGroupList',
        defaultMessage: 'Solution group list'
    },
    ADMIN_TECH_DOC_SOLUTION_GROUP_USER_MANAGEMENT: {
        id: 'admin.sideMenu.techDoc.solutionGroupMangement.userManagement',
        defaultMessage: 'User management'
    },
    ADMIN_TECH_DOC_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS: {
        id: 'admin.sideMenu.techDoc.solutionGroupManagement.modelAnswersSettings',
        defaultMessage: 'Model answers settings'
    },
    ADMIN_TECH_DOC_ESCALATION_SCHEMA_NEW: {
        id: 'admin.sideMenu.techDoc.escalationSchemaManagement.escalationSchemaNew',
        defaultMessage: 'New escalation schema'
    },
    ADMIN_TECH_DOC_ESCALATION_SCHEMA_LIST: {
        id: 'admin.sideMenu.techDoc.escalationSchemaManagement.escalationSchemaList',
        defaultMessage: 'Escalation schema list'
    },
    ADMIN_TECH_DOC_ANSWERING_SCHEMA_NEW: {
        id: 'admin.sideMenu.techDoc.answeringSchemaManagement.answeringSchemaNew',
        defaultMessage: 'New answering schema'
    },
    ADMIN_TECH_DOC_ANSWERING_SCHEMA_LIST: {
        id: 'admin.sideMenu.techDoc.answeringSchemaManagement.answeringSchemaList',
        defaultMessage: 'Answering schema list'
    },
    ADMIN_CLAIMS: {
        id: 'admin.sideMenu.claims',
        defaultMessage: 'Claims'
    },
    ADMIN_CLAIMS_NOTIFICATION: {
        id: 'admin.sideMenu.claims.dealerNotification',
        defaultMessage: 'Dealer notification setting'
    },
    ADMIN_CLAIMS_MAIN_PAGE_ALERTS: {
        id: 'admin.sideMenu.claims.mainPageAlerts',
        defaultMessage: 'Main page alerts'
    },
    ADMIN_CLAIMS_RECURRENT_PROBLEMS: {
        id: 'admin.sideMenu.claims.recurrentProblems',
        defaultMessage: 'Recurrent Problems setting'
    },
    ADMIN_CLAIMS_WORKING_HOURS: {
        id: 'admin.sideMenu.claims.workingHours',
        defaultMessage: 'Working hours'
    },
    ADMIN_CLAIMS_TICKET_SETTING: {
        id: 'admin.sideMenu.claims.ticketSetting',
        defaultMessage: 'Ticket setting'
    },
    ADMIN_CLAIMS_AUTH_MANAGEMENT: {
        id: 'admin.sideMenu.claims.authorizationManagement',
        defaultMessage: 'Authorization management'
    },
    ADMIN_CLAIMS_AUTH_MANAGEMENT_ADMIN: {
        id: 'admin.sideMenu.claims.authorizationManagement.admin',
        defaultMessage: 'Admin'
    },
    ADMIN_CLAIMS_AUTH_MANAGEMENT_DEALER: {
        id: 'admin.sideMenu.claims.authorizationManagement.dealer',
        defaultMessage: 'Dealer'
    },
    ADMIN_CLAIMS_AUTH_MANAGEMENT_EDITOR: {
        id: 'admin.sideMenu.claims.authorizationManagement.editor',
        defaultMessage: 'Editor'
    },
    ADMIN_CLAIMS_PRESET_RESPONSE: {
        id: 'admin.sideMenu.claims.presetResponse',
        defaultMessage: 'Automatic Responses'
    },
    ADMIN_CLAIMS_DEFAULT_COUNTRY_ROLE: {
        id: 'admin.sideMenu.claims.defaultCountryRole',
        defaultMessage: 'Default country role'
    },
    ADMIN_GENERIC_TICKET: {
        id: 'admin.sideMenu.genericTicket',
        defaultMessage: 'Generic Tickets'
    },
    ADMIN_GENERIC_TICKET_SOLUTION_GROUP: {
        id: 'admin.sideMenu.genericTicket.solutionGroup',
        defaultMessage: 'Solution Group'
    },
    ADMIN_GENERIC_TICKET_FLOW: {
        id: 'admin.sideMenu.genericTicket.flow',
        defaultMessage: 'Flow'
    },


    // SP SIDE MENU
    SPARE_PARTS_MANAGEMENT_GROUP: {
        id: 'spareParts.sideMenu.managementGroup',
        defaultMessage: 'Management Group'
    },
    SPARE_PARTS_LIST: {
        id: 'spareParts.sideMenu.list',
        defaultMessage: 'List'
    },
    SPARE_PARTS_HISTORY: {
        id: 'spareParts.sideMenu.history',
        defaultMessage: 'History'
    },
    SPARE_PARTS_SEARCH_ENGINE: {
        id: 'spareParts.sideMenu.searchEngine',
        defaultMessage: 'Search Engine'
    },
    SPARE_PARTS_EXPORT: {
        id: 'spareParts.sideMenu.export',
        defaultMessage: 'Export'
    },
    SPARE_PARTS_INTERNAL_SEARCH: {
        id: 'spareParts.sideMenu.internal.search',
        defaultMessage: 'Search Engine'
    },
    SPARE_PARTS_RECURRENT_PROBLEM: {
        id: 'spareParts.sideMenu.recurrentProblem',
        defaultMessage: 'Recurrent Problem'
    },
    SPARE_PARTS_NEW: {
        id: 'spareParts.sideMenu.new',
        defaultMessage: 'New'
    },

    // TD SIDE MENU
    TECH_DOC_MANAGEMENT_GROUP: {
        id: 'techDoc.sideMenu.managementGroup',
        defaultMessage: 'Management Group'
    },
    TECH_DOC_LIST: {
        id: 'techDoc.sideMenu.list',
        defaultMessage: 'List'
    },
    TECH_DOC_HISTORY: {
        id: 'techDoc.sideMenu.history',
        defaultMessage: 'History'
    },
    TECH_DOC_INTERNAL_SEARCH: {
        id: 'techDoc.sideMenu.internal.search',
        defaultMessage: 'Search Engine'
    },
    TECH_DOC_INTERNAL_EXPORT: {
        id: 'techDoc.sideMenu.internal.export',
        defaultMessage: 'Export'
    },
    TECH_DOC_FULLTEXT_SEARCH: {
        id: 'techDoc.sideMenu.fullTextSearch',
        defaultMessage: 'Fulltext Search'
    },
    TECH_DOC_EXPORT: {
        id: 'techDoc.sideMenu.export',
        defaultMessage: 'Export'
    },
    TECH_DOC_RECURRENT_PROBLEM: {
        id: 'techDoc.sideMenu.recurrentProblem',
        defaultMessage: 'Recurrent Problem'
    },
    TECH_DOC_NEW: {
        id: 'techDoc.sideMenu.new',
        defaultMessage: 'New'
    },

    // CL SIDE MENU
    CLAIMS_LIST: {
        id: 'claims.sideMenu.list',
        defaultMessage: 'List'
    },
    CLAIMS_HISTORY: {
        id: 'claims.sideMenu.history',
        defaultMessage: 'History'
    },
    CLAIMS_FULLTEXT_SEARCH: {
        id: 'claims.sideMenu.fullTextSearch',
        defaultMessage: 'Fulltext Search'
    },
    CLAIMS_EXPORT: {
        id: 'claims.sideMenu.export',
        defaultMessage: 'Export'
    },
    CLAIMS_RECURRENT_PROBLEM: {
        id: 'claims.sideMenu.recurrentProblem',
        defaultMessage: 'Recurrent Problem'
    },
    TROUBLESHOOT_CLAIM: {
        id: 'claims.sideMenu.troubleshoot',
        defaultMessage: 'Troubleshoot'
    },
    CLAIMS_NEW: {
        id: 'claims.sideMenu.new',
        defaultMessage: 'New'
    },
    ADMIN_SPARE_PARTS_SOLUTION_GROUP_NEW: {
        id: 'admin.sideMenu.spareParts.solutionGroupManagement.solutionGroupNew',
        defaultMessage: 'New solution group'
    },
    ADMIN_SPARE_PARTS_SOLUTION_GROUP_LIST: {
        id: 'admin.sideMenu.spareParts.solutionGroupManagement.solutionGroupList',
        defaultMessage: 'Solution group list'
    },
    ADMIN_SPARE_PARTS_MULTI_LEVEL_ESCALATION_SCHEMA: {
        id: 'admin.sideMenu.spareParts.multiLevelEscalationSchema',
        defaultMessage: 'Multi-level Escalation Schema'
    },
    ADMIN_SPARE_PARTS_SOLUTION_GROUP_USER_MANAGEMENT: {
        id: 'admin.sideMenu.spareParts.solutionGroupManagement.userManagement',
        defaultMessage: 'User management'
    },
    ADMIN_SPARE_PARTS_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS: {
        id: 'admin.sideMenu.spareParts.solutionGroupManagement.modelAnswersSettings',
        defaultMessage: 'Model answers settings'
    },
    ADMIN_SPARE_PARTS_ESCALATION_SCHEMA_NEW: {
        id: 'admin.sideMenu.spareParts.escalationSchemaManagement.escalationSchemaNew',
        defaultMessage: 'New escalation schema'
    },
    ADMIN_SPARE_PARTS_ESCALATION_SCHEMA_LIST: {
        id: 'admin.sideMenu.spareParts.escalationSchemaManagement.escalationSchemaList',
        defaultMessage: 'Escalation schema list'
    },
    ADMIN_SPARE_PARTS_ANSWERING_SCHEMA_NEW: {
        id: 'admin.sideMenu.spareParts.answeringSchemaManagement.answeringSchemaNew',
        defaultMessage: 'New answering schema'
    },
    ADMIN_SPARE_PARTS_ANSWERING_SCHEMA_LIST: {
        id: 'admin.sideMenu.spareParts.answeringSchemaManagement.answeringSchemaList',
        defaultMessage: 'Answering schema list'
    },
});

export default messages;
