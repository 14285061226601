import React, {Component} from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {SecondaryButton} from '../../../../../common/Button';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import DropZoneForEditorsNote from './DropZoneForEditorsNote';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import fileSelectMessages from '../../../../../../intl/common/fileSelectMessages';
import validationMessages from '../../../../../../intl/common/validationMessages';

const ErrorDiv = styled.label`
    display: inherit;
`;

const StyledSpan = styled.span`
  &&{background-color: #ffcc33;};
  overflow: hidden;
  text-overflow: ellipsis;
`;

class RenderDropZoneForEditorsNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {newFiles: []}
    }

    onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            acceptedFiles.forEach(file => {
                this.props.fields.push(
                    {
                        name: file.name,
                        size: file.size,
                        contentType: file.type,
                        localUrl: window.URL.createObjectURL(file),
                    }
                );
            });
            this.setState({newFiles: this.state.newFiles.concat(acceptedFiles)});
        }
    };

    removeHandler = (e) => {
        e.preventDefault();
        this.props.fields.removeAll();
        this.setState({newFiles: []});
    };

    render() {
        const {name, meta: {error, dirty}} = this.props;
        const {newFiles} = this.state;
        return (
            <>
                <div className="col-xl-3 col-lg-4 col-sm-12 px-2 py-0">
                    {(newFiles.length > 0) &&
                    <>
                        <span className="font-weight-bold row m-0">
                            <FormattedMessage {...fileSelectMessages.NEW_ATTACHMENTS}/>
                        </span>
                        {newFiles.map((att, index) =>
                            <StyledSpan key={index}
                                        className="row border rounded m-0">
                                {att.name}
                            </StyledSpan>)}
                    </>
                    }
                </div>

                <div className="form-row justify-content-center align-items-center">
                    <div className="col-xl-8 col-lg-6 m-0 p-0">
                        <DropZoneForEditorsNote name={name}
                                                multiple={true}
                                                onDrop={this.onDrop}/>
                        {dirty && error &&
                        <ErrorDiv className="invalid-feedback text-center">
                            <ValidFormattedMessage message={error} intlMessages={validationMessages}/>
                        </ErrorDiv>
                        }
                    </div>
                    <div className="col-xl-4 col-lg-6 mb-lg-0 mb-2 mx-lg-0 mx-2">
                        <SecondaryButton disabled={newFiles.length === 0}
                                         type="button"
                                         className="btn"
                                         onClick={this.removeHandler}>
                            <FormattedMessage {...buttonMessages.CLEAR_ATTACHMENTS}/>
                        </SecondaryButton>
                    </div>
                </div>
            </>
        );
    }
}

export default RenderDropZoneForEditorsNotes;
