import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, FormattedTime, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import {withRouter} from 'react-router-dom';
import {clearAlerts} from '../../../actions/alertsActions';
import SearchEngineDocTable from './FullTextSearchTechDocTable';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import SearchEngineDocForm from './FullTextSearchTechDocForm';
import {fetchTechDocLastEditor} from '../../../actions/techDoc/actionTechDocFullTextSearchPage';
import {lockTechDocTicket, resetRedirectAfterTechDocLockTicket} from '../../../actions/techDoc/actionTechDocLockTicket';
import fulltextSearchMessages from '../../../intl/technicalDocumentations/fulltextSearchMessages';
import {updateTechDocUserFulltextSearchPreference} from '../../../actions/techDoc/actionTechDocPreferences';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import {StyledPicky} from '../../common/StyledComponents';
import internalStatusMessages from '../../../intl/technicalDocumentations/internalStatusMessages';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {fillNumberFiveZero, pruneEmpty} from '../../../utils/utils';
import {SubmissionError} from 'redux-form';
import {
    FORM_CHECKBOX_CLAIM_RELATED,
    FORM_CHECKBOX_NEW_OVAL_PLATE,
    FORM_CHECKBOX_OLD_OVAL_PLATE, FORM_CHECKBOX_PVI_RELATED, FORM_FIELD_FROM,
    FORM_FIELD_PFX,
    FORM_FIELD_SFX, FORM_FIELD_TO, FORM_PROBLEM_DESCRIPTION_SECTION,
    FORM_TICKET_SECTION,
    FORM_VEHICLE_DETAIL_SECTION
} from '../../../constants/formConstants';
import commonMessages from '../../../intl/common/commonMessages';
import immobilizedVehicleMessages from '../../../intl/technicalDocumentations/immobilizedVehicleMessages';
import {moduleRoles} from '../../../utils/roles';

class FullTextSearchTechDocPage extends Component {
    constructor(props) {
        super(props);
        const {intl, roles} = props;

        const isSgEditor = moduleRoles.isSGEditorDocumentation(roles);
        const isEditor = !isSgEditor && moduleRoles.isEditorDocumentation(roles);
        const isDealer = !isSgEditor && !isEditor && moduleRoles.isDealerDocumentation(roles);

        const storageData = this.loadStateFromSessionStorage();
        this.state = {
            ratchet: true,
            columnLibrary: [
                {
                    id: 'creatorIpn',
                    accessor: 'ticketBase.creatorIpn',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_CREATED_BY,
                },
                {
                    id: 'immobilizedVehicle',
                    accessor: 'problemDescription.immobilizedVehicle',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_IMMOBILIZED_VEHICLE,
                    Cell: e =>
                        <div>{immobilizedVehicleMessages[e.value] ? intl.formatMessage(immobilizedVehicleMessages[e.value]) : e.value}</div>
                },
                {
                    id: 'created',
                    accessor: 'ticketBase.created',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_ORDER_CREATION_TIME,
                    Cell: e => <div><FormattedDate value={new Date(e.value)}/> <FormattedTime
                        value={new Date(e.value)}/></div>,
                },
                {
                    id: 'clientId',
                    accessor: 'ticketBase.clientId',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_CLIENT_ID,
                    Cell: e => e.value && String(e.value).padStart(6, "0")
                },
                {
                    id: 'region',
                    accessor: 'ticketBase.region',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_REG_DIR,
                },
                {
                    id: 'countryCode3',
                    accessor: 'ticketBase.countryCode3',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_COUNTRY_CODE_3,
                },
                {
                    id: 'dealerNumber',
                    accessor: 'ticketBase.dealerNumber',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_DEALER_NUMBER,
                    Cell: e => e.value && String(e.value).padStart(8, "0")
                },
                {
                    id: 'dealerName',
                    accessor: 'ticketBase.dealerName',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_DEALER_NAME,
                },
                {
                    id: 'pr',
                    accessor: 'problemDescription.pr',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_PR,
                },
                {
                    id: 'pagePost',
                    accessor: 'problemDescription.pagePost',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_PAGE1,
                },
                {
                    id: 'pagePref',
                    accessor: 'problemDescription.pagePref',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_PAGE2,
                },
                {
                    id: 'partDesc',
                    accessor: 'problemDescription.partDesc',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_PART_DESCRIPTION,
                },
                {
                    id: 'managementGroup',
                    accessor: 'problemDescription.managementGroup',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_MANAGEMENT_GROUP,
                },
                {
                    id: 'internalStatus',
                    accessor: 'vehicleDetail.internalStatus',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_INTERNAL_STATUS,
                    Cell: e =>
                        <div>{internalStatusMessages[e.value] ? intl.formatMessage(internalStatusMessages[e.value]) : e.value}</div>
                },
                {
                    id: 'docType',
                    accessor: 'ticketBase.docType',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_FORM_TYPE,
                    Cell: e =>
                        <div>{formTypeMessages[e.value] ? intl.formatMessage(formTypeMessages[e.value]) : e.value}</div>
                },
                {
                    id: 'solutionGroup',
                    accessor: 'ticketBase.solutionGroup.name',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_SOLUTION_GROUP,
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'status',
                    accessor: 'ticketBase.status',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_STATUS,
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
            ],
            listDefaultFilters: get(storageData, 'filters', []),
            formInitValues: get(storageData, 'formValues', {}),
            searchOption: {},
        };


        if(isDealer) {
            this.state.columnLibrary = this.state.columnLibrary.filter(column => column.id !== 'managementGroup' && column.id !== 'countryCode3');
        }
    }

    componentWillUnmount() {
        this.props.resetRedirectAfterTechDocLockTicket();
    }

    resetForm = () => {
        this.setState({listDefaultFilters: [], formInitValues: {}});
        this.saveStateToSessionStorage({}, [[], {}]);
    }

    saveStateToSessionStorage(formValues = {}, filters = []) {
        sessionStorage.setItem('fullTextTD', JSON.stringify({formValues, filters}));
    }

    loadStateFromSessionStorage() {
        let value = {};
        if (sessionStorage.hasOwnProperty('fullTextTD')) {
            value = JSON.parse(sessionStorage.getItem('fullTextTD'));
            return value || {};
        }
        return {};
    }

    componentDidMount() {
        this.props.clearAlerts();
        if (moduleRoles.isEditorDocumentation(this.props.roles) || moduleRoles.isDealerDocumentation(this.props.roles)) {
            this.props.fetchTechDocLastEditor();
        }
    }

    handleEditClick = ({id}) => {
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockTechDocTicket(id);
            this.setState({ratchet: false});
        }
    };

    selectMultipleOption = (value) => {
        this.props.updateTechDocUserFulltextSearchPreference(this.props.loggedUser.ipn,
            this.state.columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    handleSubmit = (values) => {
        const polishedValues = pruneEmpty(values);
        const filter = [];
        const pfx = get(polishedValues, 'ticketSection.pfxNumber', '').length;
        const sfx = get(polishedValues, 'ticketSection.sfxNumber', '').length;

        if (pfx > 0 && sfx === 0) {
            throw new SubmissionError({
                [FORM_TICKET_SECTION]: {[FORM_FIELD_SFX]: 'Fill complete ticket number'}
            })
        }

        if (pfx === 0 && sfx > 0) {
            throw new SubmissionError({
                [FORM_TICKET_SECTION]: {[FORM_FIELD_PFX]: 'Fill complete ticket number'}
            })
        }
        const temp = JSON.parse(JSON.stringify({
            ...polishedValues
        }));

        if (pfx || sfx) {
            temp.ticketSection.ticketNumber = `${polishedValues.ticketSection.pfxNumber}-${polishedValues.ticketSection.sfxNumber}`;
            delete temp.ticketSection.pfxNumber;
            delete temp.ticketSection.sfxNumber;
        }
        if (!get(temp, `${FORM_VEHICLE_DETAIL_SECTION}.ovalPlate.${FORM_CHECKBOX_NEW_OVAL_PLATE}`, {default: true})) {
            delete temp[FORM_VEHICLE_DETAIL_SECTION].ovalPlate[FORM_CHECKBOX_NEW_OVAL_PLATE];
        }
        if (!get(temp, `${FORM_VEHICLE_DETAIL_SECTION}.ovalPlate.${FORM_CHECKBOX_OLD_OVAL_PLATE}`, {default: true})) {
            delete temp[FORM_VEHICLE_DETAIL_SECTION].ovalPlate[FORM_CHECKBOX_OLD_OVAL_PLATE];
        }
        if (!get(temp, `${FORM_PROBLEM_DESCRIPTION_SECTION}.${FORM_CHECKBOX_CLAIM_RELATED}`, {default: true})) {
            delete temp[FORM_PROBLEM_DESCRIPTION_SECTION][FORM_CHECKBOX_CLAIM_RELATED];
        }
        if (!get(temp, `${FORM_PROBLEM_DESCRIPTION_SECTION}.${FORM_CHECKBOX_PVI_RELATED}`, {default: true})) {
            delete temp[FORM_PROBLEM_DESCRIPTION_SECTION][FORM_CHECKBOX_PVI_RELATED];
        }
        if (get(temp, `${FORM_TICKET_SECTION}.${FORM_FIELD_FROM}`) === null) {
            delete temp[FORM_TICKET_SECTION][FORM_FIELD_FROM];
        }
        if (get(temp, `${FORM_TICKET_SECTION}.${FORM_FIELD_TO}`) === null) {
            delete temp[FORM_TICKET_SECTION][FORM_FIELD_TO];
        }
        Object.keys(temp).filter(key => key !== 'searchOption').forEach(section => Object.keys(temp[section]).forEach(key => {

                if (key === 'ovalPlate') {
                    Object.keys(temp[section][key]).forEach(ovalKey => filter.push(
                        {
                            id: ovalKey,
                            value: temp[section][key][ovalKey]
                        }
                    ))
                } else {
                    filter.push(
                        {
                            id: key,
                            value: temp[section][key]
                        }
                    )
                }
            }
        ));
        this.setState({listDefaultFilters: [filter, polishedValues.searchOption || {}]});
        this.saveStateToSessionStorage(polishedValues, [filter, polishedValues.searchOption || {}]);
    };


    render() {
        const {intl, techDocPreferences, lockTicket, isLoadingLastEditors} = this.props;
        if (lockTicket.redirect && !this.state.ratchet) {
            this.props.history.push(`/documentation/edit/${lockTicket.uuid}`);
        }
        if (techDocPreferences.isLoading) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...fulltextSearchMessages.TITLE}/>}/>
                {(!isLoadingLastEditors) ? <SearchEngineDocForm
                    form={'docSearchEngineForm'}
                    initialValues={this.state.formInitValues}
                    resetForm={this.resetForm}
                    enableReinitialize={true}
                    onSubmit={this.handleSubmit}
                /> : <Loader/>}
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span className="font-weight-bold">
                    <FormattedMessage {...fulltextSearchMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={this.state.columnLibrary.filter(item => !get(techDocPreferences, 'menuFullTextSearch', {default: []}).includes(item.id)).map(col => {
                                console.log("MESSAGE",col.id)
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={this.state.columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            selectAllText={<FormattedMessage {...commonMessages.SELECT_ALL}/>}
                            manySelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            allSelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <SearchEngineDocTable
                    columns={this.state.columnLibrary.filter(item => !get(techDocPreferences, 'menuFullTextSearch', {default: []}).includes(item.id))}
                    listDefaultFilters={this.state.listDefaultFilters}
                    handleEditClick={this.handleEditClick}/>
            </div>
        );
    }
}

FullTextSearchTechDocPage.propTypes = {
    lockTicket: PropTypes.object.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    updateTechDocUserFulltextSearchPreference: PropTypes.func.isRequired,
    fetchTechDocLastEditor: PropTypes.func.isRequired,
    lockTechDocTicket: PropTypes.func.isRequired,
    resetRedirectAfterTechDocLockTicket: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
    isLoadingLastEditors: PropTypes.bool.isRequired,
};


const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    techDocPreferences: state.techDocPreferences,
    lockTicket: state.techDocListOrderLockTicketCheck,
    isLoadingLastEditors: state.docFullTextSearchPage.isLoadingLastEditors,
    roles: state.profile.userDetail.roles,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateTechDocUserFulltextSearchPreference,
    lockTechDocTicket,
    resetRedirectAfterTechDocLockTicket,
    fetchTechDocLastEditor,
})(injectIntl(FullTextSearchTechDocPage))), ['TD_DEALER', 'TD_EDITOR', 'TD_SG_EDITOR']);

