import td_help_en from './config/technicalDocumentation/td_help_en';
import td_help_fr from './config/technicalDocumentation/td_help_fr';
import sp_help_fr from './config/spareParts/sp_help_fr';
import sp_help_en from './config/spareParts/sp_help_en';
import {domains} from '../../../constants/Utils';


export const getHelpLinks = (lang, module) => {
    if(module === domains.TECHNICAL_DOCUMENTATION) {
        if (lang === 'fr') {
            return td_help_fr;
        }
        return td_help_en;
    } else if(module === domains.SPARE_PARTS) {
        if (lang === 'fr') {
            return sp_help_fr;
        }
        return sp_help_en;
    } else {
        return []
    }
}
