import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    KEY_PLIP_STOLEN: {
        id: 'techDoc.problemType.option.keyPlipStolen',
        defaultMessage: 'Keys Theft, solicitude of new codes'
    },
    KEY_CARD_LOST_STOLEN: {
        id: 'techDoc.problemType.option.keyCardStolen',
        defaultMessage: 'Key / card lost / stolen - request for a new key code'
    },
    KEY_PLIP_SPARE_PART: {
        id: 'techDoc.problemType.option.keyPlipSparePart',
        defaultMessage: 'Request for key spare part'
    },
    KEY_DOESNT_WORK: {
        id: 'techDoc.problemType.option.keyDoesntWork',
        defaultMessage: 'Received key doesn\'t work'
    },
    KEY_PLIP_DOESNT_EXIST: {
        id: 'techDoc.problemType.option.keyPlipDoesntExist',
        defaultMessage: 'The key code / Plipa does not exist in the SPR Database'
    },
    NEW_KEY_CODE_IN_DB: {
        id: 'techDoc.problemType.option.newKeyCodeInDb',
        defaultMessage: 'Application for entering a new key code in the SPR Database'
    },
    DEMAND_CODES: {
        id: 'techDoc.problemType.option.demandCodes',
        defaultMessage: 'Codes Request'
    },
    CODED_PART_DOESNT_FIT: {
        id: 'techDoc.problemType.option.codedPartDoesntFit',
        defaultMessage: 'The coded part you ordered does not fit with the car'
    },
    CODED_PARTS_ORDERING_PROBLEM: {
        id: 'techDoc.problemType.option.codedPartsOrderingProblem',
        defaultMessage: 'Problem with ordering coded parts'
    },
    CODED_PARTS_OTHER_PROBLEMS: {
        id: 'techDoc.problemType.option.codedPartsOtherProblems',
        defaultMessage: 'Other problems with coded parts'
    },
    DIALOGYS_ERROR: {
        id: 'techDoc.problemType.option.dialogysError',
        defaultMessage: 'Dialogys error'
    },
    DELIVERED_COLLECTION_INCOMPLETE: {
        id: 'techDoc.problemType.option.deliveredCollectionIncomplete',
        defaultMessage: 'Incomplete collection Received'
    },
    DATE_REQUEST_FOR_UNRECEIVED_KEY: {
        id: 'techDoc.problemType.option.dateRequestForUndeliveredKey',
        defaultMessage: 'Request for delivery date, key not received'
    },
    IMPOSSIBILITY_TO_PLACE_AN_ORDER: {
        id: 'techDoc.problemType.option.impossibilityToPlaceAnOrder',
        defaultMessage: 'Impossibility to place an order'
    },
    ORDER_CANCELLATION: {
        id: 'techDoc.problemType.option.orderCancellation',
        defaultMessage: 'Order cancellation'
    },
    NONCOMPLIANCE: {
        id: 'techDoc.problemType.option.nonCompliance',
        defaultMessage: 'Non-compliance of the received part (functioning/cutting)'
    },
    MODIFICATION_OF_BVM: {
        id: 'techDoc.problemType.option.modificationOfBVM',
        defaultMessage: 'Modification of BVM following loss or theft'
    },
    IMPOSSIBILITY_TO_PROGRAM: {
        id: 'techDoc.problemType.option.impossibilityToProgram',
        defaultMessage: 'Impossibilty to program'
    },
    FOLLOW_UP_OF_CLAIMS: {
        id: 'techDoc.problemType.option.followUpOfClaims',
        defaultMessage: 'Follow-up of claims'
    },
    SPECIAL_REQUEST: {
        id: 'techDoc.problemType.option.specialRequest',
        defaultMessage: 'Special request'
    },
    REQUEST_FOR_PART_NUMBER_INFORMATION: {
        id: 'techDoc.problemType.option.requestForPartNumberInformation',
        defaultMessage: 'Request for part number information'
    },
});

export default messages;
