import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const createOrderExport = (exportFilter) => (dispatch) => {
    dispatch({
        type: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_EXPORT_CREATE,
        sparePartsWebsocket: true,
        payload: {
            exportFilter
        },
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_EXPORT_CREATE,
    });
};

export const fetchOrderExport = () => (dispatch) => {
    dispatch({
        type: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_EXPORT_GET,
        sparePartsWebsocket: true,
        payload: {},
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_EXPORT_GET,
    });
};

export const downloadExport = (exportRecord) => (dispatch) => {
    dispatch({
        type: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_EXPORT_DOWNLOAD_URL_GET,
        sparePartsWebsocket: true,
        payload: {
            exportRecord
        },
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_EXPORT_DOWNLOAD_URL_GET,
    });
};
