import v4 from 'uuid/v4';
import {cloneDeep} from 'lodash';
import {ALERTS_ADD, ALERTS_CLEAR, ALERTS_REMOVE} from '../actions/alertsActions';

export const alerts = (state = {
    list: []
}, action) => {
    switch (action.type) {
        case ALERTS_ADD : {
            const currentAlerts = cloneDeep(state.list);
            currentAlerts.forEach(alert => delete alert.id);

            const newAlert = cloneDeep(action.payload);
            if (newAlert.id) {
                delete newAlert.id;
            }

            if (currentAlerts.map(alert => JSON.stringify(alert)).includes(JSON.stringify(newAlert))) {
                return state;
            } else {
                if (!action.payload.id) {
                    action.payload.id = v4();
                }
                return Object.assign({}, state, {list: [...cloneDeep(state.list), action.payload]});
            }
        }
        case ALERTS_REMOVE : {
            let list = state.list;
            list = list.filter((item) => item.id !== action.payload);
            return Object.assign({}, state, {list});
        }
        case ALERTS_CLEAR: {
            return Object.assign({}, state, {list: []});
        }
        default:
            return state;
    }
};
