import {
    TECH_DOC_GENERATED_PRESIGNED_URLS,
    TECH_DOC_ATTACHMENTS_SAVED_ERROR, TECH_DOC_TICKET_ATTACHMENTS_SAVED,
} from '../../constants/websocketCommands';
import {
    TECH_DOC_ATTACHMENTS_UPLOADING,
    TECH_DOC_ATTACHMENTS_UPLOADING_ERROR
} from '../../actions/techDoc/actionTechDocAttachments';
import {TECH_DOC_TICKET_RESET} from '../../actions/techDoc/actionTechDocTicket';

export const techDocAttachment = (state = {
    correlationId: null,
    isTicketAttachmentUploading: false,
    attachmentsForUpload: [],
}, action) => {
    switch (action.type) {
        case TECH_DOC_TICKET_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
            });
        }
        case TECH_DOC_GENERATED_PRESIGNED_URLS: {
            const {attachments} = action.payload;
            return Object.assign({}, state, {
                attachmentsForUpload: Array.isArray(attachments) ? attachments : [],
            });
        }
        case TECH_DOC_ATTACHMENTS_UPLOADING: {
            return Object.assign({}, state, {isTicketAttachmentUploading: true});
        }
        //att done + error
        case TECH_DOC_ATTACHMENTS_SAVED_ERROR:
        case TECH_DOC_ATTACHMENTS_UPLOADING_ERROR: {
            return Object.assign({}, state, {
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
            });
        }
        case TECH_DOC_TICKET_ATTACHMENTS_SAVED: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
            });
        }
        default:
            return state;
    }
};
