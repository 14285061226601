import {put, takeEvery} from 'redux-saga/effects';
import {DIC_SPARE_PART_RESET} from '../../actions/orders/actionSparePartsDicData';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';


export function* handleDicError() {
    yield put({type: DIC_SPARE_PART_RESET});
};

function* sparePartsDicSaga() {
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_MESSAGE_SEND_ERROR, handleDicError);
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DIC_MESSAGE_ERROR, handleDicError);
}

export default sparePartsDicSaga;
