import React, {Component} from 'react';
import styled from 'styled-components';
import {change, Field, formValueSelector} from 'redux-form'
import {
    BUTTON_LABEL_BVM_LOAD,
    CHECKBOX_LABEL_BVM_CONNECTION,
    FORM_CHECKBOX_BVM_CONNECTION,
    FORM_FIELD_CUSTOMER_NAME,
    FORM_FIELD_DELIVERY_DATE,
    FORM_FIELD_REGISTRATION_NO,
    FORM_FIELD_REPAIR_ORDER_NO,
    FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
    FORM_FIELD_VEHICLE_MODEL,
    FORM_FIELD_VEHICLE_TYPE,
    FORM_FIELD_VIN,
    FORM_LABEL_CUSTOMER_NAME,
    FORM_LABEL_DELIVERY_DATE,
    FORM_LABEL_REGISTRATION_NO,
    FORM_LABEL_REPAIR_ORDER_NO,
    FORM_LABEL_VEHICLE_DETAIL_TEL_NO,
    FORM_LABEL_VEHICLE_MODEL,
    FORM_LABEL_VEHICLE_TYPE,
    FORM_LABEL_VIN,
    FORM_VEHICLE_DETAIL_SECTION
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import {required, validVehicleTypeLength, validVinLength} from '../../../../../common/validationCommons';
import {PrimaryButton} from '../../../../../common/Button';
import {connect} from 'react-redux';
import Loader from '../../../../../Loader';
import DatePickerComponent from '../../../../../common/datePicker/DatePickerComponent';
import {
    bvmDataResetActionCreator,
    bvmDataSetActionCreator,
    fetchBvmInformationActionCreator
} from '../../../../../../actions/orders/bvmData';
import {bindActionCreators} from 'redux';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import ConfirmationDialog from '../../../../../common/modal/ConfirmationDialog';
import * as moment from 'moment';
import get from 'get-value';
import modalMessages from '../../../../../../intl/common/modalMessages';

class VehicleDetailAllocationReqSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenManualRewriteDialog: false,
            isOpenBvmRewriteDialog: false
        }
    }

    componentWillUnmount() {
        this.props.bvmDataResetActionCreator();
    }

    handleBvmLoad = (e) => {
        const {vin} = this.props;
        e.preventDefault();
        this.props.fetchBvmInformationActionCreator(vin);
    };

    closeManualRewriteModal = e => {
        e.preventDefault();
        this.setState({isOpenManualRewriteDialog: false,});
        this.props.change(`${FORM_VEHICLE_DETAIL_SECTION}.${FORM_CHECKBOX_BVM_CONNECTION}`, true);
    };

    closeBvmRewriteModal = e => {
        e.preventDefault();
        this.setState({isOpenBvmRewriteDialog: false,});
        this.props.change(`${FORM_VEHICLE_DETAIL_SECTION}.${FORM_CHECKBOX_BVM_CONNECTION}`, false);
    };

    saveManualRewriteModal = e => {
        e.preventDefault();
        this.setState({isOpenManualRewriteDialog: false,});
        this.props.bvmDataResetActionCreator();
    };

    saveBvmRewriteModal = e => {
        e.preventDefault();
        this.setState({isOpenBvmRewriteDialog: false,});
        this.props.change(`${FORM_VEHICLE_DETAIL_SECTION}.${FORM_FIELD_DELIVERY_DATE}`, '');
        this.props.change(`${FORM_VEHICLE_DETAIL_SECTION}.${FORM_FIELD_VEHICLE_TYPE}`, '');
        this.props.change(`${FORM_VEHICLE_DETAIL_SECTION}.${FORM_FIELD_VEHICLE_MODEL}`, '');
        this.props.bvmDataResetActionCreator();
    };

    clearBvmFields = (e) => {
        const {bvmLoaded} = this.props;
        if (e.target.checked) {
            this.setState({isOpenBvmRewriteDialog: true});
        } else if (!e.target.checked && bvmLoaded) {
            this.setState({isOpenManualRewriteDialog: true});
        }
    };

    fillVehicleFromBvm = () => {
        const {bvm} = this.props;
        this.props.change(`${FORM_VEHICLE_DETAIL_SECTION}.${FORM_FIELD_DELIVERY_DATE}`, get(bvm, 'bvmData.deliveryDate') ? moment(bvm.bvmData.deliveryDate).format('YYYY-MM-DD') : '');
        this.props.change(`${FORM_VEHICLE_DETAIL_SECTION}.${FORM_FIELD_VEHICLE_TYPE}`, get(bvm, 'bvmData.vehicleType', ''));
        this.props.change(`${FORM_VEHICLE_DETAIL_SECTION}.${FORM_FIELD_VEHICLE_MODEL}`, get(bvm, 'bvmData.model', ''));
        this.props.bvmDataSetActionCreator();
    };

    render() {
        const {bvm, bvmLoaded, bvmConnection, userRights, reqFields, readOnly, vehicleDetailsRawData} = this.props;
        if (bvm.bvmSet) {
            this.fillVehicleFromBvm();
        }
        return (
            <>
                <div className="form-row">
                    <div className="col-12 pb-2">
                        <div className="form-row align-items-end">
                            <div className="col-md-12 col-lg-8 col-xl-4">
                                <InputFieldWithValidation label={formMessages[FORM_LABEL_VIN]}
                                                          field={FORM_FIELD_VIN}
                                                          maxLength={17}
                                                          isDisabled={!userRights.canEditVehDetails || readOnly || bvmLoaded || get(vehicleDetailsRawData, 'bvmLoaded', {default: false})}
                                                          isRequired={reqFields.includes(FORM_FIELD_VIN) && !readOnly}
                                                          validations={[validVinLength]}
                                                          isSmall/>
                            </div>
                            <label className="pl-2 pt-1">
                                <Field name={FORM_CHECKBOX_BVM_CONNECTION}
                                       component="input"
                                       type="checkbox"
                                       disabled={!userRights.canEditBvm || readOnly}
                                       onChange={this.clearBvmFields}
                                />{' '}
                                <ValidFormattedMessage message={CHECKBOX_LABEL_BVM_CONNECTION}
                                                       intlMessages={formMessages}/>
                            </label>
                            {userRights.canEditBvm && !readOnly &&
                            <>
                                <div className="pl-2 pt-1">
                                    <PrimaryButton type="button" className="btn"
                                                   onClick={this.handleBvmLoad}
                                                   disabled={!bvmConnection || bvmLoaded}>
                                        <ValidFormattedMessage message={BUTTON_LABEL_BVM_LOAD}
                                                               intlMessages={buttonMessages}/>
                                    </PrimaryButton>
                                </div>
                                <div className="pl-2 py-auto">
                                    {bvm.isBvmLoading &&
                                    <Loader isSmall/>
                                    }
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-4 col-lg-6 col-sm-12 pb-2">
                        <Field name={FORM_FIELD_DELIVERY_DATE} component={DatePickerComponent}
                               label={formMessages[FORM_LABEL_DELIVERY_DATE]}
                               validate={reqFields.includes(FORM_FIELD_DELIVERY_DATE) ? required : undefined}
                               isRequired={reqFields.includes(FORM_FIELD_DELIVERY_DATE) && !readOnly}
                               isClearable
                               isDisabled={!!bvmConnection || !userRights.canEditVehDetails || readOnly}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_REGISTRATION_NO]}
                                                  field={FORM_FIELD_REGISTRATION_NO}
                                                  maxLength={10}
                                                  isDisabled={!userRights.canEditVehDetails || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_REGISTRATION_NO) && !readOnly}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-3 col-md-5 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_VEHICLE_TYPE]}
                                                  field={FORM_FIELD_VEHICLE_TYPE}
                                                  maxLength={4}
                                                  validations={[validVehicleTypeLength]}
                                                  isDisabled={!!bvmConnection || !userRights.canEditVehDetails || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_VEHICLE_TYPE) && !readOnly}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-5 col-md-7 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_VEHICLE_MODEL]}
                                                  field={FORM_FIELD_VEHICLE_MODEL}
                                                  maxLength={50}
                                                  isDisabled={!!bvmConnection || !userRights.canEditVehDetails || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_VEHICLE_MODEL) && !readOnly}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-4 col-lg-6 col-md-7 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_CUSTOMER_NAME]}
                                                  field={FORM_FIELD_CUSTOMER_NAME}
                                                  maxLength={50}
                                                  isDisabled={!userRights.canEditVehDetails || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_CUSTOMER_NAME) && !readOnly}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-5 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_VEHICLE_DETAIL_TEL_NO]}
                                                  field={FORM_FIELD_VEHICLE_DETAIL_TEL_NO}
                                                  maxLength={15}
                                                  isDisabled={!userRights.canEditVehDetails || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_VEHICLE_DETAIL_TEL_NO) && !readOnly}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_REPAIR_ORDER_NO]}
                                                  field={FORM_FIELD_REPAIR_ORDER_NO}
                                                  maxLength={10}
                                                  isDisabled={!userRights.canEditRepairOrderNumber || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_REPAIR_ORDER_NO) && !readOnly}
                                                  isSmall/>
                    </div>
                </div>
                <ConfirmationDialog show={this.state.isOpenManualRewriteDialog}
                                    closeModal={this.closeManualRewriteModal}
                                    saveModal={this.saveManualRewriteModal}
                                    actionIdentifier={modalMessages.CONFIRMATION_DIALOG_ACTION_MANUALLY_REWRITE_BVM}/>
                <ConfirmationDialog show={this.state.isOpenBvmRewriteDialog}
                                    closeModal={this.closeBvmRewriteModal}
                                    saveModal={this.saveBvmRewriteModal}
                                    actionIdentifier={modalMessages.CONFIRMATION_DIALOG_ACTION_BVM_REWRITE}/>
            </>
        )
    }
}

const mapStateToProps = (state, initialProps) => ({
    vin: formValueSelector(initialProps.form)(state, `${FORM_VEHICLE_DETAIL_SECTION}.${FORM_FIELD_VIN}`),
    bvm: state.bvmData,
    bvmLoaded: state.bvmData.bvmLoaded,
    bvmConnection: formValueSelector(initialProps.form)(state, `${FORM_VEHICLE_DETAIL_SECTION}.${FORM_CHECKBOX_BVM_CONNECTION}`),
    vehicleDetailsRawData: state.sparePartsOrderTicket.vehicleDetail,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            bvmDataSetActionCreator,
            fetchBvmInformationActionCreator,
            bvmDataResetActionCreator
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetailAllocationReqSection);
