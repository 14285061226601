import React from 'react';
import Loader from './Loader';
import PropTypes from 'prop-types';
import {ModalFooter, ModalWindow} from './common/modal/Modal';
import {FormattedMessage} from 'react-intl';
import {SecondaryButton} from './common/Button';
import buttonMessages from '../intl/common/buttonMessages';

const customLoaderStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        minWidth: '600px',
        maxHeight: '70%',
        overflowY: 'auto',
        backgroundColor: 'white',
        borderStyle: 'solid',
        textAlign: 'center',
        fontSize: '1.4rem',
    },
    overlay: {
        zIndex: 1000,
    },
};

const OverlayLoader = ({active, text, handleClose}) => (
    <ModalWindow isOpen={active}
                 style={customLoaderStyles}
                 shouldCloseOnOverlayClick={false}
                 onRequestClose={handleClose}>
        <Loader/>
        {text instanceof Object ? <FormattedMessage {...text} /> : text}
        <ModalFooter>
            <div className="row">
                <div className="col">
                    <SecondaryButton type="button" className="btn" onClick={handleClose}>
                        <FormattedMessage {...buttonMessages.CLOSE}/>
                    </SecondaryButton>
                </div>
            </div>
        </ModalFooter>
    </ModalWindow>
);

OverlayLoader.propTypes = {
    active: PropTypes.bool,
    text: PropTypes.any.isRequired,
};

export default OverlayLoader;
