import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    SPARE_PARTS: {
        id: 'form.type.domain.spareParts',
        defaultMessage: 'Spare Parts Orders'
    },
    TECHNICAL_DOCUMENTATION: {
        id: 'form.type.domain.technicalDocumentation',
        defaultMessage: 'Technical Documentation'
    },
    CLAIMS: {
        id: 'form.type.domain.claims',
        defaultMessage: 'Claims'
    },
    DELIVERY_DATE_REMINDER: {
        id: 'form.type.spareParts.deliveryDateReminder',
        defaultMessage: 'Delivery Date Reminder'
    },
    ORDER_CANCELLATION: {
        id: 'form.type.spareParts.orderCancellation',
        defaultMessage: 'Order Cancellation'
    },
    VOR_PVI_ORDER: {
        id: 'form.type.spareParts.vorPviOrder',
        defaultMessage: 'VOR/PVI Order'
    },
    EV_BATTERY: {
        id: 'form.type.spareParts.evBattery',
        defaultMessage: 'EV Battery'
    },
    ORDER_FOR_PARTS_NO_LONGER_AVAILABLE: {
        id: 'form.type.spareParts.orderForPartsNoLongerAvailable',
        defaultMessage: 'Order for Part No Longer Available'
    },
    ALLOCATION_REQUEST: {
        id: 'form.type.spareParts.allocationRequest',
        defaultMessage: 'Allocation Request'
    },
    SPARE_PARTS_INTERNAL_TICKET: {
        id: 'form.type.spareParts.internalTicket',
        defaultMessage: 'Internal Ticket'
    },
    UNKNOWN_PART_OPENING_REQUEST: {
        id: 'form.type.technicalDocumentation.unknownPartOpeningRequest',
        defaultMessage: 'Unknown Part Opening Request'
    },
    OVAL_PLATE_ORDER: {
        id: 'form.type.technicalDocumentation.ovalPlateOrder',
        defaultMessage: 'Oval Plate Order'
    },
    CODED_PARTS_KEYS: {
        id: 'form.type.technicalDocumentation.codedPartsKeys',
        defaultMessage: 'Coded Parts/Keys'
    },
    INTERNAL_TICKET: {
        id: 'form.type.technicalDocumentation.internalTicket',
        defaultMessage: 'Internal Ticket'
    },
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE: {
        id: 'form.type.technicalDocumentation.sparePartsRequestForNotFoundReference',
        defaultMessage: 'Request For Not Found Reference'
    },
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE: {
        id: 'form.type.technicalDocumentation.sparePartsRequestForNotConformReference',
        defaultMessage: 'Request For Not Conform Reference'
    },
    SPARE_PARTS_REQUEST_FOR_SUPPORT: {
        id: 'form.type.technicalDocumentation.sparePartsRequestSupport',
        defaultMessage: 'Request For Support'
    },
    CONTEST_CLAIM_STATUS: {
        id: 'form.type.claims.contestClaimStatus',
        defaultMessage: 'Contest Claim Status'
    },
    PROBLEM_FOR_PLACING_CLAIMS: {
        id: 'form.type.claims.problemForPlacingClaims',
        defaultMessage: 'Problem for Placing Claims'
    },
    RETURN_CLAIM: {
        id: 'form.type.claims.returnClaim',
        defaultMessage: 'Return Claim'
    },
    STOCK_DEAD_CLAIM: {
        id: 'form.type.claims.stockDeadClaim',
        defaultMessage: 'Stock Dead Claim'
    },
    OLD_MATERIAL_CLAIM: {
        id: 'form.type.claims.oldMaterialClaim',
        defaultMessage: 'Old Material Claim'
    },
    DIRECT_FLOW_CLAIM: {
        id: 'form.type.claims.directFlowClaim',
        defaultMessage: 'Direct Flow Claim'
    },
    TROUBLESHOOT_CLAIM: {
        id: 'form.type.claims.troubleshootClaim',
        defaultMessage: 'Troubleshoot Claim'
    },
});

export default messages;
