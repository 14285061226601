import React, {forwardRef} from 'react';
import {
    FORM_FIELD_LIC_IAN,
    FORM_FIELD_LIC_LIG,
    FORM_FIELD_LIC_OAN,
    FORM_FIELD_LIC_ORD,
    FORM_FIELD_LIC_PROD,
    FORM_FIELDS_ORDER_ITEMS,
    FORM_LABEL_MPR88,
    FORM_ORDERS_SECTION,
    FORM_TICKET_SECTION,
} from '../../../../../../constants/formConstants';
import {
    LIC_IAN_FIELD_MAX_LENGTH,
    LIC_LIG_FIELD_MAX_LENGTH,
    LIC_OAN_FIELD_MAX_LENGTH,
    LIC_ORD_FIELD_MAX_LENGTH,
    LIC_PROD_FIELD_MAX_LENGTH,
} from '../../../../../../constants/validationConstants';
import {RequiredSpan, StyledTr,} from '../../../../../common/StyledComponents';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import {
    validLicIAnLength,
    validLicLigLength,
    validLicOAnLength,
    validLicOrdLength,
    validLicProdLength
} from '../../../../../common/validationCommons';
import {onlyDigits} from '../../../../../common/normalizationCommons';
import {formValueSelector, getFormInitialValues} from 'redux-form';
import {connect} from 'react-redux';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import PropTypes from 'prop-types';
import warnMessages from '../../../../../../intl/common/warnMessages';
import {FormattedMessage} from 'react-intl';
import get from 'get-value';
import { balticCountryGroups } from '../../../../../../constants/Utils';

const OrdersMprRow = (props) => {
    const {userRights, readOnly, orders, index, order, reqFields, initialValues, multiSourcingGroups, changeFocus} = props;

    const ticketGroup = initialValues[FORM_TICKET_SECTION].group

    return (
        <StyledTr className="border-bottom-secondary">
            <td className="font-weight-bold text-right vertical border-0">
                <ValidFormattedMessage message={FORM_LABEL_MPR88} intlMessages={formMessages}/>
                {userRights.canEditMpr && !orders[index].licEnded && !readOnly && !multiSourcingGroups.includes(ticketGroup) && !balticCountryGroups.includes(ticketGroup) &&
                <RequiredSpan>{'\u00A0*'}</RequiredSpan>}
            </td>
            <td className="border-0" colSpan="8">
                <div className="row m-0">
                    <InputFieldWithValidation field={`${order}.${FORM_FIELD_LIC_PROD}`}
                                              maxLength={LIC_PROD_FIELD_MAX_LENGTH}
                                              validations={[validLicProdLength]}
                                              normalize={onlyDigits}
                                              isDisabled={!userRights.canEditMpr || orders[index].licEnded || readOnly}
                                              isRequired={reqFields.includes(FORM_FIELD_LIC_PROD) && !orders[index].licEnded && !multiSourcingGroups.includes(ticketGroup) && !balticCountryGroups.includes(ticketGroup)}
                                              isSmall
                                              style={{
                                                  maxWidth: '45px',
                                                  marginRight: '5px'
                                              }}
                                              setRef={get(props, `myForwardedRef.${FORM_FIELD_LIC_PROD}`, undefined)}
                                              handleKeyUp={(value) => changeFocus(value, LIC_PROD_FIELD_MAX_LENGTH, FORM_FIELD_LIC_ORD)}
                    />
                    <InputFieldWithValidation field={`${order}.${FORM_FIELD_LIC_ORD}`}
                                              maxLength={LIC_ORD_FIELD_MAX_LENGTH}
                                              validations={[validLicOrdLength]}
                                              normalize={onlyDigits}
                                              isDisabled={!userRights.canEditMpr || orders[index].licEnded || readOnly}
                                              isRequired={reqFields.includes(FORM_FIELD_LIC_ORD) && !orders[index].licEnded && !multiSourcingGroups.includes(ticketGroup) && !balticCountryGroups.includes(ticketGroup)}
                                              isSmall
                                              setRef={get(props, `myForwardedRef.${FORM_FIELD_LIC_ORD}`, undefined)}
                                              style={{
                                                  maxWidth: '45px',
                                                  marginRight: '5px'
                                              }}
                                              handleKeyUp={(value) => changeFocus(value, LIC_ORD_FIELD_MAX_LENGTH, FORM_FIELD_LIC_OAN)}
                    />
                    <InputFieldWithValidation field={`${order}.${FORM_FIELD_LIC_OAN}`}
                                              maxLength={LIC_OAN_FIELD_MAX_LENGTH}
                                              validations={[validLicOAnLength]}
                                              normalize={onlyDigits}
                                              isDisabled={!userRights.canEditMpr || orders[index].licEnded || readOnly}
                                              isRequired={reqFields.includes(FORM_FIELD_LIC_OAN) && !orders[index].licEnded && !multiSourcingGroups.includes(ticketGroup) && !balticCountryGroups.includes(ticketGroup)}
                                              isSmall
                                              setRef={get(props, `myForwardedRef.${FORM_FIELD_LIC_OAN}`, undefined)}
                                              style={{
                                                  maxWidth: '35px',
                                                  marginRight: '5px'
                                              }}
                                              handleKeyUp={(value) => changeFocus(value, LIC_OAN_FIELD_MAX_LENGTH, FORM_FIELD_LIC_LIG)}
                    />
                    <InputFieldWithValidation field={`${order}.${FORM_FIELD_LIC_LIG}`}
                                              maxLength={LIC_LIG_FIELD_MAX_LENGTH}
                                              validations={[validLicLigLength]}
                                              normalize={onlyDigits}
                                              isDisabled={!userRights.canEditMpr || orders[index].licEnded || readOnly}
                                              isRequired={reqFields.includes(FORM_FIELD_LIC_LIG) && !orders[index].licEnded && !multiSourcingGroups.includes(ticketGroup) && !balticCountryGroups.includes(ticketGroup)}
                                              isSmall
                                              setRef={get(props, `myForwardedRef.${FORM_FIELD_LIC_LIG}`, undefined)}
                                              style={{
                                                  maxWidth: '55px',
                                                  marginRight: '5px'
                                              }}
                                              handleKeyUp={(value) => changeFocus(value, LIC_LIG_FIELD_MAX_LENGTH, FORM_FIELD_LIC_IAN)}
                    />
                    <InputFieldWithValidation field={`${order}.${FORM_FIELD_LIC_IAN}`}
                                              maxLength={LIC_IAN_FIELD_MAX_LENGTH}
                                              validations={[validLicIAnLength]}
                                              normalize={onlyDigits}
                                              isDisabled={!userRights.canEditMpr || orders[index].licEnded || readOnly}
                                              isRequired={reqFields.includes(FORM_FIELD_LIC_IAN) && !orders[index].licEnded && !multiSourcingGroups.includes(ticketGroup) && !balticCountryGroups.includes(ticketGroup)}
                                              isSmall
                                              setRef={get(props, `myForwardedRef.${FORM_FIELD_LIC_IAN}`, undefined)}
                                              style={{maxWidth: '35px'}}
                    />
                    {get(initialValues, `${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}.${index}.changedMPRDicResponse`, false) &&
                    <span
                        className="pl-2 text-danger"><FormattedMessage {...warnMessages.SP_ORDERS_WARN001}/>
                        </span>}
                </div>
            </td>
        </StyledTr>
    )
}

const mapStateToProps = (state, props) => ({
    orders: formValueSelector(props.form)(state, `${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}`),
    initialValues: getFormInitialValues(props.form)(state),
    multiSourcingGroups: state.sparePartsOrderMultiSourcingGroups.multiSourcingGroups,
});

OrdersMprRow.propTypes = {
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
    readOnly: PropTypes.bool,
    orders: PropTypes.array,
    index: PropTypes.any.isRequired,
    order: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
    multiSourcingGroups: PropTypes.array.isRequired,
};

const ConnectedOrdersMprRow = connect(
    mapStateToProps
)(OrdersMprRow);

export default forwardRef((props, ref) =>
    <ConnectedOrdersMprRow {...props} myForwardedRef={ref}/>
);
