import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'spareParts.popUp.title',
        defaultMessage: 'PopUp message'
    },
    NO_MESSAGE: {
        id: 'spareParts.popUp.noMessage',
        defaultMessage: 'No message'
    },
    CONFIRMATION_MESSAGE: {
        id: 'spareParts.popUp.confirmationMessage',
        defaultMessage: 'I RECOGNIZED TO HAVE KNOWN THE MESSAGE ABOVE'
    },
});

export default messages;
