import React, {useState} from 'react';
import {formValueSelector, change} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {
    FORM_CHECKBOX_BVM_CONNECTION,
    FORM_CHECKBOX_OLD_OVAL_PLATE,
    FORM_FIELD_ABS,
    FORM_FIELD_AIR_CONDITIONING,
    FORM_FIELD_CLIENT_TYPE,
    FORM_FIELD_ENGINE_SERIAL_NUMBER,
    FORM_FIELD_ENGINE_TYPE,
    FORM_FIELD_GEARBOX_SERIAL_NUMBER,
    FORM_FIELD_GEARBOX_TYPE,
    FORM_FIELD_MORE_DETAILS,
    FORM_FIELD_POWER_STEERING,
    FORM_FIELD_SRC_CLAIM_N,
    FORM_FIELD_SRC_REQUEST,
    FORM_FIELD_STEERING_WHEEL,
    FORM_FIELD_SUPPLEMENTARY_INFORMATION,
    FORM_LABEL_ABS,
    FORM_LABEL_AIR_CONDITIONING,
    FORM_LABEL_CLIENT_TYPE,
    FORM_LABEL_ENGINE_SERIAL_NUMBER,
    FORM_LABEL_ENGINE_TYPE,
    FORM_LABEL_GEARBOX_SERIAL_NUMBER,
    FORM_LABEL_GEARBOX_TYPE,
    FORM_LABEL_MORE_DETAILS,
    FORM_LABEL_POWER_STEERING,
    FORM_LABEL_SRC_CLAIM_N,
    FORM_LABEL_SRC_REQUEST,
    FORM_LABEL_STEERING_WHEEL,
    FORM_LABEL_SUPPLEMENTARY_INFORMATION,
    FORM_SUPPLEMENTARY_INFORMATION_SECTION,
    FORM_VEHICLE_DETAIL_SECTION,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import formMessages from '../../../../../../intl/technicalDocumentations/formMessages';
import absMessages from '../../../../../../intl/technicalDocumentations/absMessages';
import powerSteeringMessages from '../../../../../../intl/technicalDocumentations/powerSteeringMessages';
import airConditioningMessages from '../../../../../../intl/technicalDocumentations/airConditioningMessages';
import steeringWheelMessages from '../../../../../../intl/technicalDocumentations/steeringWheelMessages';
import clientTypeMessages from '../../../../../../intl/technicalDocumentations/clientTypeMessages';
import * as formConstants from '../../../../../../constants/formConstants';
import commonMessages from '../../../../../../intl/common/commonMessages';
import BorderRadius from '../../../../../common/styled/BorderRadius';
import displayField from '../../../../../../constants/displayField';
import Div from '../../../../../common/styled/HideAbleDiv';
import {srcRequestType} from '../../../../../../constants/Utils';

const SupplementaryInformationSection = (props) => {
    const { bvmConnection, readOnly, userRights, oldOvalSelected, reqFields, change, srcRequest, form } = props;
    const [srcNumberDisabled, setSrcNumberDisabled] = useState(!srcRequest || srcRequest === 'NO');

    const section = formConstants.FORM_SUPPLEMENTARY_INFORMATION_SECTION;

    const handleSrcRequestChecker = (event, value) => {
        if (value && (value === 'YES' || value === 'PRESIDENT')) {
            setSrcNumberDisabled(false);
        } else {
            change(`${section}.${formConstants.FORM_FIELD_SRC_CLAIM_N}`, '');
            setSrcNumberDisabled(true);
        }
    };

    const display = (field) => displayField(field, section, form);

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.SUPPLEMENTARY_INFORMATION_SECTION}/>
            </h3>
            <BorderRadius size={5} className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <Div className="col-xl-3 col-md-6 pb-1" display={display(FORM_FIELD_ENGINE_TYPE)}>
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_ENGINE_TYPE]}
                                                  field={FORM_FIELD_ENGINE_TYPE}
                                                  maxLength={6}
                                                  isDisabled={!userRights.canEditSuppInformations || readOnly || bvmConnection}
                                                  isRequired={reqFields.includes(FORM_FIELD_ENGINE_TYPE)}
                                                  isSmall/>
                    </Div>
                    <Div display={display(FORM_FIELD_ENGINE_SERIAL_NUMBER)} className="col-xl-3 col-md-6 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_ENGINE_SERIAL_NUMBER]}
                                                  field={FORM_FIELD_ENGINE_SERIAL_NUMBER}
                                                  maxLength={7}
                                                  isDisabled={!userRights.canEditSuppInformations || readOnly || bvmConnection}
                                                  isRequired={reqFields.includes(FORM_FIELD_ENGINE_SERIAL_NUMBER)}
                                                  isSmall/>
                    </Div>
                    <Div display={display(FORM_FIELD_GEARBOX_TYPE)} className="col-xl-3 col-md-6 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_GEARBOX_TYPE]}
                                                  field={FORM_FIELD_GEARBOX_TYPE}
                                                  maxLength={6}
                                                  isDisabled={!userRights.canEditSuppInformations || readOnly || bvmConnection}
                                                  isRequired={reqFields.includes(FORM_FIELD_GEARBOX_TYPE)}
                                                  isSmall/>
                    </Div>
                    <Div display={display(FORM_FIELD_GEARBOX_SERIAL_NUMBER)} className="col-xl-3 col-md-6 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_GEARBOX_SERIAL_NUMBER]}
                                                  field={FORM_FIELD_GEARBOX_SERIAL_NUMBER}
                                                  maxLength={7}
                                                  isDisabled={!userRights.canEditSuppInformations || readOnly || bvmConnection}
                                                  isRequired={reqFields.includes(FORM_FIELD_GEARBOX_SERIAL_NUMBER)}
                                                  isSmall/>
                    </Div>
                </div>
                <div className="form-row">
                    <Div display={display(FORM_FIELD_SUPPLEMENTARY_INFORMATION)} className="col-sm-12 pr-0">
                        <TextareaFieldWithValidation label={formMessages[FORM_LABEL_SUPPLEMENTARY_INFORMATION]}
                                                     field={FORM_FIELD_SUPPLEMENTARY_INFORMATION}
                                                     isDisabled={!userRights.canEditSuppInformationsText || readOnly}
                                                     isRequired={reqFields.includes(FORM_FIELD_SUPPLEMENTARY_INFORMATION)}
                                                     rows={6}/>
                    </Div>
                </div>
                <div className="form-row">
                    {oldOvalSelected && <>
                        <Div display={display(FORM_FIELD_ABS)} className="col-xl-3 col-md-6 pb-1">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_ABS]}
                                                            field={FORM_FIELD_ABS}
                                                            options={['NO_ABS', 'ABS', 'ABS_WITH_ESP_ESC']}
                                                            intlMessages={absMessages}
                                                            isDisabled={!userRights.canEditSuppInformations || readOnly || bvmConnection}
                                                            isRequired={reqFields.includes(FORM_FIELD_ABS)}
                            />
                        </Div>
                        <Div display={display(FORM_FIELD_POWER_STEERING)} className="col-xl-3 col-md-6 pb-1">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_POWER_STEERING]}
                                                            field={FORM_FIELD_POWER_STEERING}
                                                            options={['YES', 'NO', 'VARIABLE']}
                                                            intlMessages={powerSteeringMessages}
                                                            isDisabled={!userRights.canEditSuppInformations || readOnly || bvmConnection}
                                                            isRequired={reqFields.includes(FORM_FIELD_POWER_STEERING)}
                            />
                        </Div>
                        <Div display={display(FORM_FIELD_AIR_CONDITIONING)} className="col-xl-3 col-md-6 pb-1">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_AIR_CONDITIONING]}
                                                            field={FORM_FIELD_AIR_CONDITIONING}
                                                            options={['NORMAL_HEATING', 'NORMAL_AIRCON']}
                                                            intlMessages={airConditioningMessages}
                                                            isDisabled={!userRights.canEditSuppInformations || readOnly || bvmConnection}
                                                            isRequired={reqFields.includes(FORM_FIELD_AIR_CONDITIONING)}
                            />
                        </Div>
                    </>
                    }
                </div>
                <div className="form-row">
                    <Div className="col-xl-3 col-lg-4 col-md-5" display={display(FORM_FIELD_STEERING_WHEEL)} >
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_STEERING_WHEEL]}
                                                        field={FORM_FIELD_STEERING_WHEEL}
                                                        options={['LEFT', 'RIGHT', 'OTHER']}
                                                        intlMessages={steeringWheelMessages}
                                                        isDisabled={!userRights.canEditSuppInformations || readOnly}
                                                        isRequired={reqFields.includes(FORM_FIELD_STEERING_WHEEL)}
                        />
                    </Div>
                    <Div display={display(FORM_FIELD_CLIENT_TYPE)} className="col-xl-2 col-lg-4 col-md-4">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_CLIENT_TYPE]}
                                                        field={FORM_FIELD_CLIENT_TYPE}
                                                        options={Object.keys(clientTypeMessages)}
                                                        intlMessages={clientTypeMessages}
                                                        isDisabled={!userRights.canEditSuppInformations || readOnly}
                        />
                    </Div>
                    <Div display={display(FORM_FIELD_SRC_REQUEST)} className="col-xl-2 col-lg-4 col-md-6">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_SRC_REQUEST]}
                                                        field={FORM_FIELD_SRC_REQUEST}
                                                        options={srcRequestType}
                                                        sort={false}
                                                        intlMessages={commonMessages}
                                                        isRequired={reqFields.includes(FORM_FIELD_SRC_REQUEST)}
                                                        isDisabled={!userRights.canEditSuppInformations || readOnly || readOnly}
                                                        noEmpty
                                                        handleChange={handleSrcRequestChecker}
                        />
                    </Div>
                    <Div display={display(FORM_FIELD_SRC_CLAIM_N)} className="col-xl-3 col-lg-4 col-md-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_SRC_CLAIM_N]}
                                                  field={FORM_FIELD_SRC_CLAIM_N}
                                                  maxLength={256}
                                                  isDisabled={!userRights.canEditSuppInformations || readOnly || srcNumberDisabled}
                                                  isRequired={reqFields.includes(FORM_FIELD_SRC_CLAIM_N)}
                                                  isSmall
                        />
                    </Div>
                </div>
                <div className="form-row">
                    <Div display={display(FORM_FIELD_MORE_DETAILS)} className="col-sm-12 pr-0">
                        <TextareaFieldWithValidation label={formMessages[FORM_LABEL_MORE_DETAILS]}
                                                     field={FORM_FIELD_MORE_DETAILS}
                                                     isDisabled={!userRights.canEditSuppInformationsText || readOnly}
                                                     isRequired={reqFields.includes(FORM_FIELD_MORE_DETAILS)}
                                                     rows={6}
                        />
                    </Div>
                </div>
            </BorderRadius>
        </>
    )

}

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, initialProps) => ({
    bvmConnection: selector(initialProps.form, state, `${FORM_VEHICLE_DETAIL_SECTION}.${FORM_CHECKBOX_BVM_CONNECTION}`),
    oldOvalSelected: selector(initialProps.form, state, `${FORM_VEHICLE_DETAIL_SECTION}.ovalPlate.${FORM_CHECKBOX_OLD_OVAL_PLATE}`),
    srcRequest: selector(initialProps.form, state, `${FORM_SUPPLEMENTARY_INFORMATION_SECTION}.${FORM_FIELD_SRC_REQUEST}`),
    bvm: state.bvm,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(SupplementaryInformationSection);
