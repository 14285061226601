import React from 'react';
import {injectIntl} from "react-intl";
import {Field} from "redux-form";
import {FieldWithValidation} from "./FormField";
import {FieldGroupWithValidation} from "./FieldGroupWithValidation";
import {required, validInput} from "./validationCommons";
import PropTypes from 'prop-types';

const InputFieldGroupWithValidation = (props) => {
    const { field, isRequired, isButtonDisabled, isDisabled, loadOrderData, maxLength, validations, handleKeyUp,
        isUpperCase, setRef = () => {}, setRefButton = () => {}, reverse = false} = props;
    let newValidations = validations;
    if (!newValidations) {
        newValidations = [validInput];
    } else {
        newValidations.push(validInput);
    }
    if (isRequired) {
        newValidations.push(required);
    }
    return (
        <Field
            name={field}
            component={isDisabled ? FieldWithValidation : FieldGroupWithValidation}
            type="text"
            maxLength={maxLength}
            validate={newValidations}
            isDisabled={isDisabled}
            isButtonDisabled={isButtonDisabled || isDisabled}
            isSmall
            handleClick={loadOrderData ? loadOrderData : undefined}
            handleKeyUp={handleKeyUp ? handleKeyUp : undefined}
            setRef={setRef}
            setRefButton={setRefButton}
            isUpperCase={isUpperCase}
            reverse={reverse}
        />
    )
};

InputFieldGroupWithValidation.propTypes = {
    field: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    isButtonDisabled: PropTypes.bool,
    isDisabled: PropTypes.bool,
    loadOrderData: PropTypes.func,
    maxLength: PropTypes.number,
    validations: PropTypes.array,
};

export default (injectIntl(InputFieldGroupWithValidation));



