import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {
    fetchWorkingHoursData,
    resetWorkingHours,
    storeWorkingHours,
    updateWorkingHours,
} from '../../../actions/admin/actionAdminWorkingHours';
import {WorkingHoursPerWeek} from './WorkingHours';
import BankHolidays from './BankHolidays';
import get from 'get-value';
import {PrimaryButton, SecondaryButton} from '../../common/Button';
import Loader from "../../Loader";
import {FormattedMessage} from "react-intl";
import workingHoursMessages from "../../../intl/admin/workingHoursMessages";
import buttonMessages from "../../../intl/common/buttonMessages";


const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;
const ButtonDiv = styled.div`
  height: 20px;
  margin: 20px;
`;

const Button = styled(SecondaryButton)`
  margin-right: 10px;
`;

class WorkingHoursContainer extends Component {
    constructor(props) {
        super(props);
        this.onSliderChange = this.onSliderChange.bind(this);
    }

    componentDidMount() {
        this.props.fetchWorkingHoursData({
            domain: this.props.domain,
            group: this.props.group,
        });
    }

    onSave = () => {
        const {domain, data, group} = this.props;
        const workingHours = {group, ...data.workingHours};
        this.props.storeWorkingHours({domain, workingHours});
    };

    onCancel = () => {
        this.props.resetWorkingHours();
    };

    onSliderChange(day, value) {
        this.props.updateWorkingHours(day, value);
    }

    render() {
        const {isLoading, isDirty, data, group} = this.props;

        if (isLoading || !data) {
            return <Loader/>;
        }
        return (
            <Wrapper classname="container-fluid">
                <div className="tab-content" id="myTabContent">
                    <WorkingHoursBox disabled={!isDirty}
                                     valuesForSelectedCountry={data.workingHours.days}
                                     onSliderChange={this.onSliderChange}
                                     onSave={this.onSave}
                                     onCancel={this.onCancel}/>
                    <BankHolidaysBox days={get(data, 'bankHolidays.days', {default: []})}
                                     selectedCountry={group}/>
                </div>
            </Wrapper>
        );
    }
}

const WorkingHoursBox = ({disabled, valuesForSelectedCountry, onSliderChange, onSave, onCancel}) =>
    <div>
        <h6><FormattedMessage {...workingHoursMessages.SUB_TITLE}/></h6>
        <hr/>
        <WorkingHoursPerWeek onChange={onSliderChange} values={valuesForSelectedCountry}/>
        <ButtonDiv>
            <div style={{float: "right"}}>
                <Button className="btn" onClick={onCancel}
                        disabled={disabled}><FormattedMessage {...buttonMessages.CANCEL}/></Button>
                <PrimaryButton className="btn" onClick={onSave}
                               disabled={disabled}><FormattedMessage {...buttonMessages.SAVE}/></PrimaryButton>
            </div>
        </ButtonDiv>
    </div>;

WorkingHoursBox.propTypes = {
    disabled: PropTypes.bool.isRequired,
    valuesForSelectedCountry: PropTypes.object.isRequired,
    onSliderChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

const BankHolidaysBox = ({selectedCountry, days}) =>
    <div>
        <h6><FormattedMessage {...workingHoursMessages.BANK_HOLIDAYS_TITLE}/></h6>
        <hr/>
        <BankHolidays country={selectedCountry} days={days}/>
    </div>;

BankHolidaysBox.propTypes = {
    selectedCountry: PropTypes.string.isRequired,
    days: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminWorkingHours.isLoading,
    isDirty: state.adminWorkingHours.isDirty,
    data: state.adminWorkingHours.data,
    loggedUser: state.profile.userDetail.roles,
});

WorkingHoursContainer.propTypes = {
    domain: PropTypes.string.isRequired,
    loggedUser: PropTypes.object.isRequired,
    group: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    isDirty: PropTypes.bool,
    fetchWorkingHoursData: PropTypes.func.isRequired,
    resetWorkingHours: PropTypes.func.isRequired,
    storeWorkingHours: PropTypes.func.isRequired,
    updateWorkingHours: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
    fetchWorkingHoursData,
    updateWorkingHours,
    storeWorkingHours,
    resetWorkingHours
})(WorkingHoursContainer);
