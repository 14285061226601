import React, {Component}  from 'react';
import {injectIntl, FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import {connect} from 'react-redux';
import {change, getFormInitialValues, formValueSelector} from 'redux-form';
import styled from 'styled-components';
import get from 'get-value';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import formMessages from '../../../../../../intl/claims/formMessages';
import commonMessages from '../../../../../../intl/common/commonMessages';
import {
    FORM_FIELD_MESSAGE,
    FORM_LABEL_MESSAGE,
    FORM_LABEL_MESSAGES_HISTORY, FORM_MESSAGES_SECTION, FORM_STATUS_HISTORY_SECTION, TROUBLESHOOT_CLAIM_FORM
} from '../../../../../../constants/formConstants';
import {bindActionCreators} from 'redux';
import {fetchPresetResponse} from '../../../../../../actions/admin/actionAdminPresetResponse';
import {domains} from '../../../../../../constants/Utils';
import { RequiredSpan } from '../../../../../common/StyledComponents';
import {parseTextToJSX} from '../../../../../../utils/utils';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const domain = domains.CLAIMS;

class MessagesSection extends Component {

    componentDidMount() {
        const {readOnly} = this.props;
        if(!readOnly) {
            this.props.fetchPresetResponse(domain, this.props.initialValues.ticketSection.group);
        }
    }

    render() {
        const {userRights, readOnly, initialValues, reqFields, change, intl, presetResponses, form, ticketHistory = []} = this.props;

        const handleChange = (e) => {
            if (e.target.value !== '' && e.target.value !== undefined && e.target.value !== null) {
                change(`${FORM_MESSAGES_SECTION}.${FORM_FIELD_MESSAGE}`, get(presetResponses.find(i => i.uuid === e.target.value), 'presetMessage', ''));
            }
        };

        const messages = get(initialValues, 'messages');

        return (
            <>
                {(((userRights.canAddNotes || (form === TROUBLESHOOT_CLAIM_FORM && userRights.canAddNotesTroubleshoot)) && !readOnly) || (messages && (messages.length > 0))) &&
                <>
                    <h3 className="text-left mt-4 ml-4">
                        <FormattedMessage {...formMessages.MESSAGES_SECTION} />
                        {ticketHistory.length === 0 && <RequiredSpan>{'\u00A0*'}</RequiredSpan>}
                    </h3>
                    <StyledDiv className="border border-secondary bg-white overflow-hidden">
                        {(messages && (messages.length > 0)) &&
                        <div>
                            <div className="border-bottom border-bottom-secondary"
                                 style={{backgroundColor: '#e9ecef', borderRadius: '5px 5px 0 0'}}>
                                <label className="col-form-label pl-0 font-weight-bold ml-3">
                                    <ValidFormattedMessage message={FORM_LABEL_MESSAGES_HISTORY}
                                                           intlMessages={formMessages}/>
                                </label>
                            </div>
                            {messages.map((message, index) =>
                                <div key={index} className="row m-0 align-items-stretch">
                                    <div style={{backgroundColor: '#f8f9fa'}}
                                         className="col-sm-12 col-lg-4 flex-column border-bottom">
                                        <div>
                                            <FormattedDate value={new Date(message.created)}/> <FormattedTime
                                            value={new Date(message.created)}/>
                                            <span className="font-weight-bold">{' '}
                                                <ValidFormattedMessage message={message.isDealer ? 'DEALER' : 'EDITOR'}
                                                                       intlMessages={commonMessages}/>
                                        </span>
                                        </div>
                                        <div>
                                            {message.creatorName + ' (' + message.creatorIpn + ')'}
                                        </div>
                                    </div>
                                    <div className="col border-bottom" style={{whiteSpace: 'pre-wrap'}}>
                                        {parseTextToJSX(message.text)}
                                    </div>
                                </div>
                            )}
                        </div>
                        }
                        {(userRights.canAddNotes || (form === TROUBLESHOOT_CLAIM_FORM && userRights.canAddNotesTroubleshoot)) && !readOnly &&
                        <div className="col-sm-12 px-4 pb-3 pt-1">
                            {userRights.canShowAutomaticResponse && form !== TROUBLESHOOT_CLAIM_FORM &&
                            <div className="col-xl-4 col-lg-6 col-md-12 px-0 pb-0">
                                <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_MESSAGE]}
                                                                field={'test'}
                                                                customOptionsMsg={presetResponses}
                                                                boldTooltip
                                                                handleChange={handleChange}/>
                            </div>
                            }
                            <div className="col-sm-12 pt-3 px-0">
                                <TextareaFieldWithValidation field={FORM_FIELD_MESSAGE}
                                                             isRequired={ticketHistory.length === 0 && reqFields.includes(FORM_FIELD_MESSAGE)}
                                                             rows={4}/>
                            </div>
                        </div>
                        }
                    </StyledDiv>
                </>}
            </>
        )
    }
};

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
    messages: formValueSelector(props.form)(state, 'messages'),
    ticketHistory: formValueSelector(props.form)(state, FORM_STATUS_HISTORY_SECTION),
    presetResponses: state.adminPresetResponse.presetResponses,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            fetchPresetResponse
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MessagesSection));
