import {
    CLAIMS_TICKET_STATUS_NEW,
    CLAIMS_TICKET_STATUS_IN_PROGRESS_DEALER,
    CLAIMS_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    CLAIMS_TICKET_STATUS_READ_BY_SUBSIDIARY,
    CLAIMS_TICKET_STATUS_REOPENED
} from './formConstants';

export const editRights = {
    DEALER: {
        NEW: {
            // ticket section
            canEditHeader: true,
            canEditClientId: true,
            // order section
            canEditOrder: true,
            canEditSPReferenceNumber: true,
            canEditUserStatus: true,
            canEditClaimType: true,
            // problem description section
            canEditProblemDesc: true,
            canEditProblemDescText: true,
            // message section
            canShowAutomaticResponse: false,
            canAddNotes: false,
            canAddNotesTroubleshoot: true,
            // editor's notes section
            canShowEditorsNotes: false,
            canAddEditorsNotes: false,
            canAddEditorsNoteAttach: false,
            // attachment section
            canAddAttach: true,
            canAddMandatoryAttach: false,
            //buttons
            canReopen: false,
            canSave: true,
            canClose: true,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canForwardBackToSubs: false,
            canChangeToReadBySubs: false,
        },
        CLOSED: {
            // ticket section
            canEditHeader: false,
            canEditClientId: false,
            // order section
            canEditOrder: false,
            canEditSPReferenceNumber: false,
            canEditUserStatus: false,
            canEditClaimType: false,
            // problem description section
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            // message section
            canShowAutomaticResponse: false,
            canAddNotes: false,
            // editor's notes section
            canShowEditorsNotes: false,
            canAddEditorsNotes: false,
            canAddEditorsNoteAttach: false,
            // attachment section
            canAddAttach: false,
            canAddMandatoryAttach: false,
            //buttons
            canReopen: true,
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canForwardBackToSubs: false,
            canChangeToReadBySubs: false,
        },
        READ_BY_SUBSIDIARY: {
            // ticket section
            canEditHeader: false,
            canEditClientId: false,
            // order section
            canEditOrder: false,
            canEditSPReferenceNumber: false,
            canEditUserStatus: false,
            canEditClaimType: false,
            // problem description section
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            // message section
            canShowAutomaticResponse: false,
            canAddNotes: false,
            // editor's notes section
            canShowEditorsNotes: false,
            canAddEditorsNotes: false,
            canAddEditorsNoteAttach: false,
            // attachment section
            canAddAttach: false,
            canAddMandatoryAttach: false,
            //buttons
            canReopen: false,
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canForwardBackToSubs: false,
            canChangeToReadBySubs: false,
        },
        WAIT_SUBSIDIARY: {
            // ticket section
            canEditHeader: false,
            canEditClientId: false,
            // order section
            canEditOrder: false,
            canEditSPReferenceNumber: false,
            canEditUserStatus: false,
            canEditClaimType: false,
            // problem description section
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            // message section
            canShowAutomaticResponse: false,
            canAddNotes: false,
            // editor's notes section
            canShowEditorsNotes: false,
            canAddEditorsNotes: false,
            canAddEditorsNoteAttach: false,
            // attachment section
            canAddAttach: true,
            canAddMandatoryAttach: false,
            //buttons
            canReopen: false,
            canSave: true,
            canClose: true,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canForwardBackToSubs: false,
            canChangeToReadBySubs: false,
        },
        WAIT_DEALER: {
            // ticket section
            canEditHeader: false,
            canEditClientId: false,
            // order section
            canEditOrder: false,
            canEditSPReferenceNumber: false,
            canEditUserStatus: false,
            canEditClaimType: false,
            // problem description section
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            // message section
            canShowAutomaticResponse: false,
            canAddNotes: true,
            // editor's notes section
            canShowEditorsNotes: false,
            canAddEditorsNotes: false,
            canAddEditorsNoteAttach: false,
            // attachment section
            canAddAttach: true,
            canAddMandatoryAttach: false,
            //buttons
            canReopen: false,
            canSave: true,
            canClose: true,
            canForwardToDealer: false,
            canForwardToSubs: true,
            canForwardBackToSubs: false,
            canChangeToReadBySubs: false,
        },
        REOPEN: {
            // ticket section
            canEditHeader: false,
            canEditClientId: false,
            // order section
            canEditSPReferenceNumber: false,
            canEditUserStatus: false,
            canEditClaimType: false,
            // problem description section
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            // message section
            canShowAutomaticResponse: false,
            canAddNotes: true,
            // editor's notes section
            canShowEditorsNotes: false,
            canAddEditorsNotes: false,
            canAddEditorsNoteAttach: false,
            // attachment section
            canAddAttach: true,
            canAddMandatoryAttach: false,
            //buttons
            canReopen: false,
            canSave: true,
            canClose: true,
            canForwardToDealer: false,
            canForwardToSubs: true,
            canForwardBackToSubs: false,
            canChangeToReadBySubs: false,
        }
    },
    EDITOR: {
        NEW: {
            // ticket section
            canEditHeader: false,
            canEditClientId: false,
            // order section
            canEditOrder: true,
            canEditSPReferenceNumber: true,
            canEditUserStatus: false,
            canEditClaimType: false,
            canEditSubjectToVor: true,
            // problem description section
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            // message section
            canShowAutomaticResponse: false,
            canAddNotes: false,
            // editor's notes section
            canShowEditorsNotes: true,
            canAddEditorsNotes: false,
            canAddEditorsNoteAttach: false,
            // attachment section
            canAddAttach: false,
            canAddMandatoryAttach: false,
            //buttons
            canReopen: false,
            canSave: false,
            canClose: false,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canForwardBackToSubs: false,
            canChangeToReadBySubs: true,
            canViewMpr: true,
        },
        CLOSED: {
            // ticket section
            canEditHeader: false,
            canEditClientId: false,
            // order section
            canEditOrder: false,
            canEditSPReferenceNumber: false,
            canEditUserStatus: false,
            canEditClaimType: false,
            canEditSubjectToVor: false,
            // problem description section
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            // message section
            canShowAutomaticResponse: false,
            canAddNotes: false,
            // editor's notes section
            canShowEditorsNotes: true,
            canAddEditorsNotes: false,
            canAddEditorsNoteAttach: false,
            // attachment section
            canAddAttach: false,
            canAddMandatoryAttach: false,
            //buttons
            canReopen: true,
            canSave: false,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canForwardBackToSubs: false,
            canChangeToReadBySubs: false,
            canViewMpr: true,
        },
        READ_BY_SUBSIDIARY: {
            // ticket section
            canEditHeader: false,
            canEditClientId: false,
            // order section
            canEditOrder: true,
            canEditSPReferenceNumber: true,
            canEditUserStatus: false,
            canEditClaimType: false,
            canEditSubjectToVor: true,
            // problem description section
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            // message section
            canShowAutomaticResponse: true,
            canAddNotes: true,
            // editor's notes section
            canShowEditorsNotes: true,
            canAddEditorsNotes: true,
            canAddEditorsNoteAttach: true,
            // attachment section
            canAddAttach: true,
            canAddMandatoryAttach: false,
            //buttons
            canReopen: false,
            canSave: true,
            canClose: true,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canForwardBackToSubs: false,
            canChangeToReadBySubs: false,
            canViewMpr: true,
        },
        WAIT_SUBSIDIARY: {
            // ticket section
            canEditHeader: false,
            canEditClientId: false,
            // order section
            canEditOrder: true,
            canEditSPReferenceNumber: true,
            canEditUserStatus: false,
            canEditClaimType: false,
            canEditSubjectToVor: true,
            // problem description section
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            // message section
            canShowAutomaticResponse: false,
            canAddNotes: false,
            // editor's notes section
            canShowEditorsNotes: true,
            canAddEditorsNotes: false,
            canAddEditorsNoteAttach: false,
            // attachment section
            canAddAttach: true,
            canAddMandatoryAttach: false,
            //buttons
            canReopen: false,
            canSave: true,
            canClose: false,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canForwardBackToSubs: false,
            canChangeToReadBySubs: true,
            canViewMpr: true,
        },
        WAIT_DEALER: {
            // ticket section
            canEditHeader: false,
            canEditClientId: false,
            // order section
            canEditOrder: false,
            canEditSPReferenceNumber: false,
            canEditUserStatus: false,
            canEditClaimType: false,
            canEditSubjectToVor: false,
            // problem description section
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            // message section
            canShowAutomaticResponse: true,
            canAddNotes: true,
            // editor's notes section
            canShowEditorsNotes: true,
            canAddEditorsNotes: true,
            canAddEditorsNoteAttach: true,
            // attachment section
            canAddAttach: true,
            canAddMandatoryAttach: false,
            //buttons
            canReopen: false,
            canSave: true,
            canClose: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canForwardBackToSubs: true,
            canChangeToReadBySubs: false,
            canViewMpr: true,
        },
        REOPEN: {
            // ticket section
            canEditHeader: false,
            canEditClientId: false,
            // order section
            canEditOrder: true,
            canEditSPReferenceNumber: true,
            canEditUserStatus: false,
            canEditClaimType: false,
            canEditSubjectToVor: true,
            // problem description section
            canEditProblemDesc: false,
            canEditProblemDescText: false,
            // message section
            canShowAutomaticResponse: true,
            canAddNotes: true,
            // editor's notes section
            canShowEditorsNotes: true,
            canAddEditorsNotes: true,
            canAddEditorsNoteAttach: true,
            // attachment section
            canAddAttach: true,
            canAddMandatoryAttach: false,
            //buttons
            canReopen: false,
            canSave: true,
            canClose: false,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canForwardBackToSubs: false,
            canChangeToReadBySubs: false,
            canViewMpr: true,
        }
    }
};

export const createRights = {
    // ticket section
    canEditHeader: true,
    canEditOrder: true,
    canEditSPReferenceNumber: true,
    canEditUserStatus: true,
    canEditClaimType: true,
    canEditSubjectToVor: true,
    canEditClientId: true,
    // problem description section
    canEditProblemDesc: true,
    canEditProblemDescText: true,
    // message section
    canShowAutomaticResponse: false,
    canAddNotes: false,
    canAddNotesTroubleshoot: true,
    // editor's notes section
    canShowEditorsNotes: false,
    canAddEditorsNotes: false,
    canAddEditorsNoteAttach: false,
    // attachment section
    canAddAttach: true,
    canAddMandatoryAttach: true,
    //buttons
    canReopen: false,
    canSave: true,
    canClose: false,
    canForwardToDealer: false,
    canForwardToSubs: false,
    canForwardBackToSubs: false,
    canChangeToReadBySubs: false,
};


export const editClaimsListButtonVisibility = {
    DEALER: [
        CLAIMS_TICKET_STATUS_NEW,
        CLAIMS_TICKET_STATUS_IN_PROGRESS_DEALER,
        CLAIMS_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
        CLAIMS_TICKET_STATUS_REOPENED
    ],
    EDITOR: [
        CLAIMS_TICKET_STATUS_NEW,
        CLAIMS_TICKET_STATUS_IN_PROGRESS_DEALER,
        CLAIMS_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
        CLAIMS_TICKET_STATUS_READ_BY_SUBSIDIARY,
        CLAIMS_TICKET_STATUS_REOPENED
    ]
};
