import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {errorAlert} from '../alertsActions';
import alertMessages from '../../intl/common/alertMessages';
import {validateSolutionGroupCreateEvent, validateSolutionGroupUpdateEvent} from '../../api/validation/solutionGroup';
import validationMessage from '../../intl/common/validationMessages';
import {domains} from '../../constants/Utils';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';


export const updateSolutionGroup = (domain, data) => dispatch => {
    const {error} = validateSolutionGroupUpdateEvent(data);
    if (error) {
        switch (domain) {
            case domains.TECHNICAL_DOCUMENTATION: {
                dispatch(errorAlert(alertMessages.SOLUTION_GROUP_UPDATE_SEND_ERROR, [error.toString()]));
                dispatch({type: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUP_SAVE_UPDATE_ERROR});
                break;
            }
            case domains.SPARE_PARTS: {
                dispatch(errorAlert(alertMessages.SOLUTION_GROUP_UPDATE_SEND_ERROR, [error.toString()]));
                dispatch({type: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUP_SAVE_UPDATE_ERROR});
                break;
            }
        }
    } else {
        switch (domain) {
            case domains.TECHNICAL_DOCUMENTATION: {
                return dispatch({
                    type: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUPS_UPDATE_PENDING,
                    techDocWebsocket: true,
                    command: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUPS_UPDATE,
                    payload: {
                        payload: {
                            ...data
                        }
                    }
                });
            }
            case domains.SPARE_PARTS: {
                return dispatch({
                    type: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUPS_UPDATE_PENDING,
                    sparePartsWebsocket: true,
                    command: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUPS_UPDATE,
                    payload: {
                        payload: {
                            ...data
                        }
                    }
                });
            }


        }
    }
};

export const saveSolutionGroup = (domain, data) => dispatch => {
    const {error} = validateSolutionGroupCreateEvent(data);
    if (error) {
        switch (domain) {
            case domains.TECHNICAL_DOCUMENTATION: {
                dispatch(errorAlert(alertMessages.SOLUTION_GROUP_NEW_SEND_ERROR, [error.toString()]));
                dispatch({type: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUP_SAVE_NEW_ERROR});
                break;
            }
            case domains.SPARE_PARTS : {
                dispatch(errorAlert(alertMessages.SOLUTION_GROUP_NEW_SEND_ERROR, [error.toString()]));
                dispatch({type: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUP_SAVE_NEW_ERROR});
                break;
            }
        }
    } else {
        switch (domain) {
            case domains.TECHNICAL_DOCUMENTATION: {
                return dispatch({
                    type: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUPS_CREATE_PENDING,
                    techDocWebsocket: true,
                    command: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUPS_CREATE,
                    payload: {
                        payload: {
                            ...data,
                        }
                    }
                });
            }
            case domains.SPARE_PARTS: {
                return dispatch({
                    type: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUPS_CREATE_PENDING,
                    sparePartsWebsocket: true,
                    command: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUPS_CREATE,
                    payload: {
                        payload: {
                            ...data,
                        }
                    }
                });
            }
        }
    }
};

export const fetchSolutionGroup = (domain, id, ipn) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUPS_DETAIL_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUPS_DETAIL,
                payload: {
                    solutionGroup: {
                        id,
                    },
                }
            });
        }
        case domains.SPARE_PARTS: {
            return dispatch({
                type: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUPS_DETAIL_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUPS_DETAIL,
                payload: {
                    solutionGroup: {
                        id,
                    },
                }
            });
        }

    }
};

export const resetSolutionGroup = (domain) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUP_RESET,
            });
        }
        case domains.SPARE_PARTS: {
            return dispatch({
                type: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUP_RESET,
            });
        }
    }
};

export const redirectReset = (domain) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUP_REDIRECT_RESET,
            });
        }
        case domains.SPARE_PARTS: {
            return dispatch({
                type: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUP_REDIRECT_RESET,
            });
        }
    }
};

export const fetchSolutionGroupsPromiseError = () => dispatch => {
    dispatch(errorAlert(validationMessage.INVALID_UNIQUE_NAME));
};
