import { put, take, takeEvery, select, call } from 'redux-saga/effects';
import { fetchSettingClientIdData } from '../actions/actionClientId';
import {
    CLIENT_IDS_UPDATE_PENDING,
    ADMIN_CLIENT_IDS_ACCEPTED,
    CLIENT_IDS_UPDATE_RECORD,
    CLIENT_IDS_DELETE_PENDING,
    CLIENT_IDS_DELETE_RECORD,
    CLIENT_IDS_CREATE_PENDING,
    ADMIN_CLIENT_IDS_ERROR,
} from '../actions/actionClientId';
import get from 'get-value';
import { adminWebsocketCommands } from '../constants/adminWebsocketCommands';

export function* handleUpdate(action) {
    const response = yield take(adminWebsocketCommands.ADMIN_CLIENT_ID_UPDATE_COMMAND);
    if (get(response, 'payload.error')) {
        yield put({
            type: ADMIN_CLIENT_IDS_ERROR,
        });
    } else {
        yield take(ADMIN_CLIENT_IDS_ACCEPTED);
        yield put({
            type: CLIENT_IDS_UPDATE_RECORD,
            payload: {
                ...action.payload,
            },
        });
    }
}

export function* handleDelete(action) {
    const response = yield take(adminWebsocketCommands.ADMIN_CLIENT_ID_DELETE_COMMAND);
    if (get(response, 'payload.error')) {
        yield put({
            type: ADMIN_CLIENT_IDS_ERROR,
        });
    } else {
        yield take(ADMIN_CLIENT_IDS_ACCEPTED);
        yield put({
            type: CLIENT_IDS_DELETE_RECORD,
            payload: {
                ...action.payload,
            },
        });
    }
}

export function* handleCreate(action) {
    const stateAdminClientIds = yield select(state => state.adminClientId);
    const response = yield take(adminWebsocketCommands.ADMIN_CLIENT_ID_COPY_COMMAND);
    if (get(response, 'payload.error')) {
        yield put({
            type: ADMIN_CLIENT_IDS_ERROR,
        });
    } else {
        yield take(adminWebsocketCommands.ADMIN_CLIENT_IDS_SEND);
        const thunk = yield call(fetchSettingClientIdData, stateAdminClientIds.selectedCountryGroup, stateAdminClientIds.page, stateAdminClientIds.linesPerPage);
        yield put(thunk);
    }
}

function* clientIdsSaga() {
    yield takeEvery(CLIENT_IDS_UPDATE_PENDING, handleUpdate);
    yield takeEvery(CLIENT_IDS_DELETE_PENDING, handleDelete);
    yield takeEvery(CLIENT_IDS_CREATE_PENDING, handleCreate);
}

export default clientIdsSaga;
