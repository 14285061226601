import {defineMessages} from 'react-intl';

const messages = defineMessages({
    bs: {
        id: 'common.locale.bs',
        defaultMessage: 'Bosnian'
    },
    cs: {
        id: 'common.locale.cs',
        defaultMessage: 'Czech'
    },
    da: {
        id: 'common.locale.da',
        defaultMessage: 'Danish'
    },
    de: {
        id: 'common.locale.de',
        defaultMessage: 'German'
    },
    en: {
        id: 'common.locale.en',
        defaultMessage: 'English'
    },
    es: {
        id: 'common.locale.es',
        defaultMessage: 'Spanish'
    },
    fr: {
        id: 'common.locale.fr',
        defaultMessage: 'French'
    },
    hr: {
        id: 'common.locale.hr',
        defaultMessage: 'Croatian'
    },
    hu: {
        id: 'common.locale.hu',
        defaultMessage: 'Hungarian'
    },
    it: {
        id: 'common.locale.it',
        defaultMessage: 'Italian'
    },
    nl: {
        id: 'common.locale.nl',
        defaultMessage: 'Dutch'
    },
    pl: {
        id: 'common.locale.pl',
        defaultMessage: 'Polish'
    },
    pt: {
        id: 'common.locale.pt',
        defaultMessage: 'Portuguese'
    },
    ro: {
        id: 'common.locale.ro',
        defaultMessage: 'Romanian'
    },
    ru: {
        id: 'common.locale.ru',
        defaultMessage: 'Russian'
    },
    ru_UA: {
        id: 'common.locale.ruUA',
        defaultMessage: 'Ukrainian'
    },
    sk: {
        id: 'common.locale.sk',
        defaultMessage: 'Slovak'
    },
    sl: {
        id: 'common.locale.sl',
        defaultMessage: 'Slovenian'
    },
    sr: {
        id: 'common.locale.sr',
        defaultMessage: 'Serbian'
    },
    sv: {
        id: 'common.locale.sv',
        defaultMessage: 'Swedish'
    },
    gsw: {
        id: 'common.locale.gsw',
        defaultMessage: 'Swiss German'
    },
    de_AT: {
        id: 'common.locale.deAT',
        defaultMessage: 'Austrian German'
    },
    es_AR: {
        id: 'common.locale.esAR',
        defaultMessage: 'Spanish Argentina'
    },
    es_MX: {
        id: 'common.locale.esMX',
        defaultMessage: 'Spanish Mexican'
    },
    sq_XK: {
        id: 'common.locale.sq',
        defaultMessage: 'Albanian'
    },
    tr: {
        id: 'common.locale.tr',
        defaultMessage: 'Turkish'
    },
});

export default messages;
