import {bvmWebsocketCommands} from "../../constants/bvmWebsocketCommands";

export const BVM_DATA_SETTED = 'BVM_DATA_SETTED';
export const BVM_DATA_RESET = 'BVM_DATA_RESET';

export const bvmDataSetActionCreator = () => {
    return {
        type: BVM_DATA_SETTED,
    }
};
export const bvmDataResetActionCreator = () => {
    return {
        type: BVM_DATA_RESET,
    }
};

export const fetchBvmInformationActionCreator = (vin) => {
    return {
        type: bvmWebsocketCommands.BVM_DATA_GET,
        bvmWebsocket: true,
        command: bvmWebsocketCommands.BVM_DATA_GET,
        payload: {
            vin
        },
    }
};


