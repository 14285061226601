import 'react-picky/dist/picky.css';
import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, FormattedTime, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import {clearAlerts} from '../../../actions/alertsActions';
import {fetchClaimsLastEditor} from '../../../actions/claims/actionClaimsFullTextSearchPage';
import {lockClaimsTicket, resetRedirectAfterClaimsLockTicket} from '../../../actions/claims/actionClaimsLockTicket';
import {updateClaimsUserFulltextSearchPreference} from '../../../actions/claims/actionClaimsPreferences';
import {fillNumberFiveZero, pruneEmpty} from '../../../utils/utils';
import {StyledPicky} from '../../common/StyledComponents';
import {SubmissionError} from 'redux-form';
import PageHeader from '../../common/PageHeader';
import Loader from '../../Loader';
import FullTextSearchClaimsTable from './FullTextSearchClaimsTable';
import FullTextSearchClaimsForm from './FullTextSearchClaimsForm';
import checkRoles from '../../common/roleChecker/RoleChecker';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import fulltextSearchMessages from '../../../intl/claims/fulltextSearchMessages';
import {
    FORM_FIELD_FROM,
    FORM_FIELD_PFX,
    FORM_FIELD_SFX, FORM_FIELD_TO,
    FORM_TICKET_SECTION,
} from '../../../constants/formConstants';
import commonMessages from '../../../intl/common/commonMessages';

class FullTextSearchClaimsPage extends Component {
    constructor(props) {
        super(props);
        const {intl} = props;
        const storageData = this.loadStateFromSessionStorage();
        this.state = {
            ratchet: true,
            columnLibrary: [
                {
                    id: 'creatorIpn',
                    accessor: 'ticket.creatorIpn',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_CREATED_BY,
                },
                {
                    id: 'created',
                    accessor: 'ticket.created',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_ORDER_CREATION_TIME,
                    Cell: e => <div><FormattedDate value={new Date(e.value)}/> <FormattedTime
                        value={new Date(e.value)}/></div>,
                },
                {
                    id: 'clientId',
                    accessor: 'ticket.clientId',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_CLIENT_ID,
                    Cell: e => e.value && String(e.value).padStart(6, "0")
                },
                {
                    id: 'region',
                    accessor: 'ticket.region',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_REG_DIR,
                },
                {
                    id: 'dealerNumber',
                    accessor: 'ticket.dealerNumber',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_DEALER_NUMBER,
                    Cell: e => e.value && String(e.value).padStart(8, "0")
                },
                {
                    id: 'dealerName',
                    accessor: 'ticket.dealerName',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_DEALER_NAME,
                },
                {
                    id: 'type',
                    accessor: 'ticket.type',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_FORM_TYPE,
                    Cell: e =>
                        <div>{formTypeMessages[e.value] ? intl.formatMessage(formTypeMessages[e.value]) : e.value}</div>
                },
                {
                    id: 'status',
                    accessor: 'ticket.status',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_STATUS,
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'lastUpdate',
                    accessor: 'ticket.lastUpdate',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_LAST_STATUS_DATE,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'lastEditor',
                    accessor: 'ticket.lastEditor',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_LAST_EDITOR,
                },
                {
                    id: 'claimRelatedNo',
                    accessor: 'problemDescription.claimRelatedNo',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_CLAIM_RELATED
                },
                {
                    id: 'invoiceNo',
                    accessor: 'problemDescription.invoiceNo',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_INVOICE
                },
                {
                    id: 'missingPackageNo',
                    accessor: 'problemDescription.missingPackageNo',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_MISSING_PACKAGE
                },
                {
                    id: 'wrongDestinationNo',
                    accessor: 'problemDescription.wrongDestinationNo',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_WRONG_DESTINATION
                },
                {
                    id: 'partNo',
                    accessor: 'problemDescription.partNo',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_PART_NO
                },
                {
                    id: 'packageNo',
                    accessor: 'problemDescription.packageNo',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_PACKAGE_NO
                },
                {
                    id: 'takingBackNo',
                    accessor: 'problemDescription.takingBackNo',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_TAKING_BACK
                },
                {
                    id: 'containerNo',
                    accessor: 'problemDescription.containerNo',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_CONTAINER
                },
                {
                    id: 'clientSolutionPilot',
                    accessor: 'orderItem.clientSolutionPilot',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_CLIENT_SOLUTION_PILOT
                },
            ],
            listDefaultFilters: get(storageData, 'filters', []),
            formInitValues: get(storageData, 'formValues', {}),
            searchOption: {},
        };
    }

    componentWillUnmount() {
        this.props.resetRedirectAfterClaimsLockTicket();
    }

    resetForm = () => {
        this.setState({listDefaultFilters: [], formInitValues: {}});
        this.saveStateToSessionStorage({}, [[], {}]);
    }

    saveStateToSessionStorage(formValues = {}, filters = []) {
        sessionStorage.setItem('fullTextCL', JSON.stringify({formValues, filters}));
    }

    loadStateFromSessionStorage() {
        let value = {};
        if (sessionStorage.hasOwnProperty('fullTextCL')) {
            value = JSON.parse(sessionStorage.getItem('fullTextCL'));
            return value || {};
        }
        return {};
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.fetchClaimsLastEditor();
    }

    handleEditClick = ({id}) => {
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockClaimsTicket(id);
            this.setState({ratchet: false});
        }
    };

    selectMultipleOption = (value) => {
        this.props.updateClaimsUserFulltextSearchPreference(this.props.loggedUser.ipn,
            this.state.columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    handleSubmit = (values) => {
        const polishedValues = pruneEmpty(values);
        const filter = [];
        const pfx = get(polishedValues, 'ticketSection.pfxNumber', '').length;
        const sfx = get(polishedValues, 'ticketSection.sfxNumber', '').length;

        if (pfx > 0 && sfx === 0) {
            throw new SubmissionError({
                [FORM_TICKET_SECTION]: {[FORM_FIELD_SFX]: 'Fill complete ticket number'}
            })
        }

        if (pfx === 0 && sfx > 0) {
            throw new SubmissionError({
                [FORM_TICKET_SECTION]: {[FORM_FIELD_PFX]: 'Fill complete ticket number'}
            })
        }
        const temp = JSON.parse(JSON.stringify({
            ...polishedValues
        }));

        if (pfx || sfx) {
            temp.ticketSection.ticketNumber = `${polishedValues.ticketSection.pfxNumber}-${polishedValues.ticketSection.sfxNumber}`;
            delete temp.ticketSection.pfxNumber;
            delete temp.ticketSection.sfxNumber;
        }
        if (get(temp, `${FORM_TICKET_SECTION}.${FORM_FIELD_FROM}`) === null) {
            delete temp[FORM_TICKET_SECTION][FORM_FIELD_FROM];
        }
        if (get(temp, `${FORM_TICKET_SECTION}.${FORM_FIELD_TO}`) === null) {
            delete temp[FORM_TICKET_SECTION][FORM_FIELD_TO];
        }
        Object.keys(temp).filter(key => key !== 'searchOption').forEach(section => Object.keys(temp[section]).forEach(key => {

                if (key === 'ovalPlate') {
                    Object.keys(temp[section][key]).forEach(ovalKey => filter.push(
                        {
                            id: ovalKey,
                            value: temp[section][key][ovalKey]
                        }
                    ))
                } else {
                    filter.push(
                        {
                            id: key,
                            value: temp[section][key]
                        }
                    )
                }
            }
        ));
        this.setState({listDefaultFilters: [filter, polishedValues.searchOption || {}]});
        this.saveStateToSessionStorage(polishedValues, [filter, polishedValues.searchOption || {}]);
    };

    render() {
        const {intl, claimsPreferences, lockTicket, isLoadingLastEditors, history} = this.props;
        if (lockTicket.redirect && !this.state.ratchet) {
            history.push(`/claims/edit/${lockTicket.uuid}`);
        }
        if (claimsPreferences.isLoading) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...fulltextSearchMessages.TITLE}/>}/>
                {(!isLoadingLastEditors) ?
                    <FullTextSearchClaimsForm
                        form={'claimsFullTextSearchForm'}
                        initialValues={this.state.formInitValues}
                        resetForm={this.resetForm}
                        enableReinitialize={true}
                        onSubmit={this.handleSubmit}/> :
                    <Loader/>}
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12 p-0">
                        <span className="font-weight-bold">
                            <FormattedMessage {...fulltextSearchMessages.VISIBLE_COLUMNS}/>{':'}
                        </span>
                        <StyledPicky
                            value={this.state.columnLibrary.filter(item => !get(claimsPreferences, 'menuFullTextSearch', {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={this.state.columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            selectAllText={<FormattedMessage {...commonMessages.SELECT_ALL}/>}
                            manySelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            allSelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <FullTextSearchClaimsTable
                    columns={this.state.columnLibrary.filter(item => !get(claimsPreferences, 'menuFullTextSearch', {default: []}).includes(item.id))}
                    listDefaultFilters={this.state.listDefaultFilters}
                    handleEditClick={this.handleEditClick}/>
            </div>
        );
    }
}


FullTextSearchClaimsPage.propTypes = {
    intl: PropTypes.any.isRequired,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    claimsPreferences: state.claimsPreferences,
    lockTicket: state.claimsListOrderLockTicketCheck,
    isLoadingLastEditors: state.claimsFullTextSearchPage.isLoadingLastEditors,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateClaimsUserFulltextSearchPreference,
    lockClaimsTicket,
    resetRedirectAfterClaimsLockTicket,
    fetchClaimsLastEditor,
})(injectIntl(FullTextSearchClaimsPage))), ['CL_DEALER', 'CL_EDITOR']);

