import {
    FORM_FIELD_ES_SG_NAME,
    FORM_FIELD_ES_FORMTYPE,
    FORM_FIELD_ES_ESCALATION_GROUPS,
    FORM_LABEL_ES_ESCALATION_GROUPS,
    FORM_LABEL_ES_FORMTYPE,
    FORM_LABEL_ES_SG_NAME
} from './formConstants';

export const intlEscalationSchemaFieldMapping = {
    [FORM_FIELD_ES_SG_NAME]: FORM_LABEL_ES_SG_NAME,
    [FORM_FIELD_ES_FORMTYPE]: FORM_LABEL_ES_FORMTYPE,
    [FORM_FIELD_ES_ESCALATION_GROUPS]: FORM_LABEL_ES_ESCALATION_GROUPS,
};
