import {put, takeEvery, select} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import get from 'get-value';
import {claimsWebsocketCommands} from '../constants/claimsWebsocketCommands';
import {CLAIMS_TICKET_RESET} from '../actions/claims/actionClaimsTicket';

export function* handleTicketError(action) {
    const stateCorrelationId = get(yield select(state => state.claimsTicket), `correlationId`, {default: undefined});
    const {correlationId} = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(push('/claims'));
    yield put({type: CLAIMS_TICKET_RESET});
}

function* claimsTicketSaga() {
    yield takeEvery(claimsWebsocketCommands.CLAIMS_TICKET_UPDATE_ERROR, handleTicketError);
    yield takeEvery(claimsWebsocketCommands.CLAIMS_TICKET_CREATE_ERROR, handleTicketError);
    yield takeEvery(claimsWebsocketCommands.CLAIMS_TICKET_SEND_ERROR, handleTicketError);
}

export default claimsTicketSaga;
