import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    VIN_INVALID_LENGTH: {
        id: 'validations.dic.vinInvalidLength',
        defaultMessage: 'Invalid VIN length (17)'
    },
    VIN_REQUIRED: {
        id: 'validations.dic.vinRequired',
        defaultMessage: 'VIN is required for DIC creation'
    },
    DESTINATION_CODE_REQUIRED: {
        id: 'validations.dic.destinationCodeRequired',
        defaultMessage: 'Destination Code is required for DIC creation'
    },
    GROUP_REQUIRED: {
        id: 'validations.dic.groupRequired',
        defaultMessage: 'Dealer Country is required for DIC creation'
    },
    DEALER_NAME_REQUIRED: {
        id: 'validations.dic.dealerNameRequired',
        defaultMessage: 'Dealer Name is required for DIC creation'
    },
    DEALER_NUMBER_REQUIRED: {
        id: 'validations.dic.dealerNumberRequired',
        defaultMessage: 'Dealer Number is required for DIC creation'
    },
    REPAIR_ORDER_NUMBER_REQUIRED: {
        id: 'validations.dic.repairOrderNumberRequired',
        defaultMessage: 'Repair Order Number is required for DIC creation'
    },
    CAR_LOCATION_REQUIRED: {
        id: 'validations.dic.carLocationRequired',
        defaultMessage: 'Car Location is required for DIC creation'
    },
    DIC_NUMBER_REQUIRED: {
        id: 'validations.dic.dicNumberRequired',
        defaultMessage: 'DIC number is required for DIC update'
    },
    REGISTRATION_NUMBER_REQUIRED: {
        id: 'validations.dic.registrationNumberRequired',
        defaultMessage: 'Registration Number is required for DIC creation'
    },
    ORDERS_REQUIRED: {
        id: 'validations.dic.orderItemsRequired',
        defaultMessage: 'Order Items are required for DIC creation'
    },
    INVALID_MPR: {
        id: 'validations.dic.invalidMprLine',
        defaultMessage: 'Invalid MPR line in Order Items'
    },
    EMPTY_MPR: {
        id: 'validations.dic.emptyMprLine',
        defaultMessage: 'Empty MPR line in Order Items'
    },
});

export default messages;
