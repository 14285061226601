import {
    SPARE_PARTS_ORDERS_CREATE_TICKET,
    SPARE_PARTS_ORDERS_GET_TICKET,
    SPARE_PARTS_ORDERS_UPDATE_TICKET,
} from '../../constants/websocketCommands';

export const SPARE_PARTS_RESET = 'SPARE_PARTS_RESET';
export const SPARE_PARTS_ORDERS_RESET_BUT_CORRELATION_ID = 'SPARE_PARTS_ORDERS_RESET_BUT_CORRELATION_ID';
export const SPARE_PARTS_ORDERS_TICKET_CREATE = 'SPARE_PARTS_ORDERS_TICKET_CREATE';
export const SPARE_PARTS_ORDERS_TICKET_UPDATE = 'SPARE_PARTS_ORDERS_TICKET_UPDATE';
export const SPARE_PARTS_ORDERS_FETCH_TICKET = 'getTicket';
export const SPARE_PARTS_ORDERS_TICKET_SUBMIT = 'SPARE_PARTS_ORDERS_TICKET_SUBMIT';

export const updateTicketOrder = (data) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_ORDERS_TICKET_UPDATE,
        sparePartsWebsocket: true,
        command: SPARE_PARTS_ORDERS_UPDATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const saveTicketOrder = (data) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_ORDERS_TICKET_CREATE,
        sparePartsWebsocket: true,
        command: SPARE_PARTS_ORDERS_CREATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const fetchSparePartsOrdersTicket = (uuid) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_ORDERS_FETCH_TICKET,
        sparePartsWebsocket: true,
        command: SPARE_PARTS_ORDERS_GET_TICKET,
        payload: {
            ticket: {
                uuid: uuid,
            }
        }
    });
};

export const resetTicketOrder = () => dispatch => {
    return dispatch({
        type: SPARE_PARTS_RESET,
    });
};

export const resetButCorrelationIdTicketOrder = () => dispatch => {
    return dispatch({
        type: SPARE_PARTS_ORDERS_RESET_BUT_CORRELATION_ID,
    });
};

export const submitBy = (data) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_ORDERS_TICKET_SUBMIT,
        payload: {
            ...data
        }
    })
}
