import {cloneDeep} from 'lodash';

export const ALERTS_ADD = 'ALERTS_ADD';
export const ALERTS_REMOVE = 'ALERTS_REMOVE';
export const ALERTS_CLEAR = 'ALERTS_CLEAR';
export const ALERT_ERROR = 'alert';
export const ALERT_WARN = 'warn';
export const ALERT_INFO = 'info';

const addAlert = (text, type, details = {}, values = {}, id) => ({
    type: ALERTS_ADD,
    payload: {id, type, text, details: protectAgainstStateMutation(details), values},
});

export const removeAlert = id => ({
    type: ALERTS_REMOVE,
    payload: id,
});

export const clearAlerts = () => ({
    type: ALERTS_CLEAR,
});

const protectAgainstStateMutation = details => cloneDeep(details);

export const errorAlert = (text, details = {}, values) => addAlert(text, ALERT_ERROR, details, values);

export const warnAlert = (text, details = {}, values) => addAlert(text, ALERT_WARN, details, values);

export const infoAlert = (text, details = {}, values, id) => addAlert(text, ALERT_INFO, details, values, id);
