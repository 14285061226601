import React, {useEffect} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {change, formValueSelector, getFormInitialValues} from 'redux-form';
import styled from 'styled-components';
import get from 'get-value';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import formMessages from '../../../../../../intl/claims/formMessages';
import {
    FORM_FIELD_CLIENT_ID, FORM_FIELD_CREATION_DATE, FORM_FIELD_DEALER_NAME, FORM_FIELD_DEALER_NO, FORM_FIELD_EMAIL,
    FORM_FIELD_REGION, FORM_FIELD_TELEPHONE_NO, FORM_LABEL_CLIENT_ID, FORM_LABEL_CREATION_DATE, FORM_LABEL_DEALER_NAME,
    FORM_LABEL_DEALER_NO, FORM_LABEL_EMAIL, FORM_LABEL_REGION, FORM_LABEL_TELEPHONE_NO, FORM_TICKET_SECTION
} from '../../../../../../constants/formConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const TicketSection = (props) => {
    const {change, clientIds, userRights, readOnly, creationDate, reqFields, initialValues, dealerNumber} = props;
    const {ticketSection: {clientId}} = initialValues;
    const filteredClientIds = clientIds.filter(id => id.bir8 === dealerNumber);
    const clientIdOptions = filteredClientIds.map((id) => id.clientId);

    if (userRights.canEditClientId && !readOnly && filteredClientIds.length === 1 && !clientId) {
        change(`${FORM_TICKET_SECTION}.${FORM_FIELD_CLIENT_ID}`, get(filteredClientIds, '0.clientId', {default: ''}));
        change(`${FORM_TICKET_SECTION}.${FORM_FIELD_REGION}`, get(filteredClientIds, '0.region', {default: ''}));
    }

    const handleChange = (event, value) => {
        change(`${FORM_TICKET_SECTION}.${FORM_FIELD_REGION}`, get(filteredClientIds.find(e => e.clientId === (value)), 'region', {default: ''}));
    };

    useEffect(() => {
        if (!clientId) {
            change(`${FORM_TICKET_SECTION}.${FORM_FIELD_CLIENT_ID}`, get(filteredClientIds, '0.clientId', {default: ''}));
            change(`${FORM_TICKET_SECTION}.${FORM_FIELD_REGION}`, get(filteredClientIds, '0.region', {default: ''}));
        }
    }, []);

    return (
        <>
            <h3 className="text-left ml-4">
                <FormattedMessage {...formMessages.TICKET_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-xl-6 col-lg-8 col-md-12 col-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_DEALER_NAME]}
                                                  field={FORM_FIELD_DEALER_NAME}
                                                  isDisabled
                                                  isSmall
                                                  isRequired={reqFields.includes(FORM_FIELD_DEALER_NAME)}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-3 col-lg-4 col-md-12 col-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_DEALER_NO]}
                                                  field={FORM_FIELD_DEALER_NO}
                                                  isDisabled
                                                  isSmall
                                                  isRequired={reqFields.includes(FORM_FIELD_DEALER_NO)}/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_CLIENT_ID]}
                                                        field={FORM_FIELD_CLIENT_ID}
                                                        options={clientIdOptions.filter((item, index) => clientIdOptions.indexOf(item) === index)}
                                                        isRequired={reqFields.includes(FORM_FIELD_CLIENT_ID)}
                                                        isDisabled={!userRights.canEditClientId || clientIdOptions.length <= 1 || readOnly || get(filteredClientIds, '0.type', '') === 'NOT_USED'}
                                                        handleChange={handleChange}
                                                        noEmpty/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_REGION]}
                                                  field={FORM_FIELD_REGION}
                                                  isRequired={reqFields.includes(FORM_FIELD_REGION)}
                                                  placeholder="---"
                                                  isDisabled
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-5 col-lg-6 col-md-6 col-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_EMAIL]}
                                                  field={FORM_FIELD_EMAIL}
                                                  isDisabled
                                                  isRequired={reqFields.includes(FORM_FIELD_EMAIL)}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_TELEPHONE_NO]}
                                                  field={FORM_FIELD_TELEPHONE_NO}
                                                  maxLength={15}
                                                  isDisabled={!userRights.canEditHeader || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_TELEPHONE_NO)}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-12 pb-2">
                        {creationDate && <InputFieldWithValidation label={formMessages[FORM_LABEL_CREATION_DATE]}
                                                                   field={FORM_FIELD_CREATION_DATE}
                                                                   isDisabled
                                                                   format={(e) => props.intl.formatDate(new Date(e)) + '  ' + props.intl.formatTime(new Date(e))}
                                                                   isSmall/>}
                    </div>
                </div>
            </StyledDiv>
        </>
    )
};

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, props) => ({
    clientIds: state.adminClientIds.clientIds,
    initialValues: getFormInitialValues(props.form)(state),
    creationDate: selector(props.form, state, `${FORM_TICKET_SECTION}.${FORM_FIELD_CREATION_DATE}`),
    dealerNumber: selector(props.form, state, `${FORM_TICKET_SECTION}.${FORM_FIELD_DEALER_NO}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TicketSection));
