import {
    FORM_FIELD_AS_SG_NAME,
    FORM_FIELD_AS_FORMTYPE,
    FORM_FIELD_AS_ANSWERING_GROUPS,
    FORM_LABEL_AS_SG_NAME,
    FORM_LABEL_AS_FORMTYPE,
    FORM_LABEL_AS_ANSWERING_GROUPS
} from './formConstants';

export const intlAnsweringSchemaFieldMapping = {
    [FORM_FIELD_AS_SG_NAME]: FORM_LABEL_AS_SG_NAME,
    [FORM_FIELD_AS_FORMTYPE]: FORM_LABEL_AS_FORMTYPE,
    [FORM_FIELD_AS_ANSWERING_GROUPS]: FORM_LABEL_AS_ANSWERING_GROUPS,
};
