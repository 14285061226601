import get from 'get-value';
import v4 from 'uuid/v4';
import messages from '../intl/common/countryGroupMessages';
import {domains} from '../constants/Utils';

export const initLoadedSolutionGroup = (solutionGroupData, formatMessage) => {
    const { ...rest } = solutionGroupData.solutionGroup;
    const { country } = solutionGroupData;

    let countriesWithLabels = [];
    if(solutionGroupData.solutionGroup.groups){
        countriesWithLabels = solutionGroupData.solutionGroup.groups.map(country => {return {label: messages[country] ? formatMessage(messages[country]) : country, value: country}})
    }

    return {
        solutionGroupSection: {
            id: solutionGroupData.solutionGroup.id,
            name: solutionGroupData.solutionGroup.name,
            isActive: solutionGroupData.solutionGroup.isActive,
            isDefault: solutionGroupData.solutionGroup.isDefault,
            canForwardToDealer: solutionGroupData.solutionGroup.canForwardToDealer,
            notifications: solutionGroupData.solutionGroup.notifications || false,
            internalNotifications: solutionGroupData.solutionGroup.internalNotifications || false,
        },
        countrySection: {
            countries: countriesWithLabels,
        },
        adminSection: {
            admins: solutionGroupData.solutionGroup.admins
        },
        userSection: {
            users: solutionGroupData.solutionGroup.editors
        }
    };
};

export const initNewSolutionGroup = () => {
    return {
        solutionGroupSection: {
            name: '',
        },
        countrySection: {

        },
        adminSection: {
            admins: [],
        },
    };
};

export const initNewSolutionGroupAfterFail = (data, formatMessage) => {
    let countriesWithLabels = [];
    if(data.groups){
        countriesWithLabels = data.groups.map(country => {return {label: messages[country] ? formatMessage(messages[country]) : country, value: country}})
    }

    return {
        solutionGroupSection: {
            name: data.name,
            isDefault: data.isDefault,
            canForwardToDealer: data.canForwardToDealer,
        },
        countrySection: {
            countries: countriesWithLabels,
        },
        adminSection: {
            admins: data.admins,
        },
    };
};

export const transformNewSolutionGroupForSave = (values, domain) => {

    const isDefault = values.solutionGroupSection.isDefault;
    const notifications = isDefault ? false : values.solutionGroupSection.notifications;

    const data = {
        id: v4(),
        name: values.solutionGroupSection.name,
        isDefault: !!values.solutionGroupSection.isDefault,
        canForwardToDealer: !!values.solutionGroupSection.canForwardToDealer,
    };

    // TODO notifications are not deployed on claims stg env
    if (domain !== domains.CLAIMS) {
        data.notifications = notifications;
    }

    if (domain === domains.TECHNICAL_DOCUMENTATION) {
        data.internalNotifications = !isDefault ? false : values.solutionGroupSection.internalNotifications;
    }

    data.admins = [
        ...get(values, "adminSection.admins", {default: []})
    ];

    data.editors = [
        ...get(values, "userSection.users", {default: []})
    ];

    if(values.solutionGroupSection.isDefault === true) {
        data.groups = values.countrySection.countries.map(country => {return country.value});
    } else {
        data.groups = []
    }

    return data;
};

export const transformUpdateSolutionGroupForSave = (values, domain) => {
    const { oldValues, newValues } = values;

    let countriesWithoutLabels = [];

    const isDefault = newValues.solutionGroupSection.isDefault;

    if (isDefault){
        countriesWithoutLabels = newValues.countrySection.countries.map(country => {return country.value});
    } else {
        countriesWithoutLabels = [];
    }
    const notifications = isDefault ? false : newValues.solutionGroupSection.notifications;

    const data = {
        id: oldValues.solutionGroupSection.id,
        name: newValues.solutionGroupSection.name,
        isActive: newValues.solutionGroupSection.isActive,
        isDefault,
        canForwardToDealer: newValues.solutionGroupSection.canForwardToDealer,
        notifications,
    };

    // TODO notifications are not deployed on spare parts stg env
    if (domain === domains.TECHNICAL_DOCUMENTATION) {
        data.notifications = notifications;
        data.internalNotifications = !isDefault ? false : newValues.solutionGroupSection.internalNotifications;
    }

    data.groups = countriesWithoutLabels;

    data.admins = [
        ...get(newValues, "adminSection.admins", {default: []})
    ];

    data.editors = [
        ...get(newValues, "userSection.users", {default: []})
    ];

    return data;
};
