import {TECH_DOC_INTERNAL_SET_LOCK, TECH_DOC_INTERNAL_SET_UNLOCK,} from '../../constants/websocketCommands';

export const TECH_DOC_INTERNAL_RESET_REDIRECT = 'TECH_DOC_INTERNAL_RESET_REDIRECT';
export const TECH_DOC_INTERNAL_SET_LOCK_TICKET = 'TECH_DOC_INTERNAL_SET_LOCK_TICKET';

export const lockTechDocInternalTicket = uuid => dispatch =>
    dispatch({
        type: TECH_DOC_INTERNAL_SET_LOCK_TICKET,
        techDocWebsocket: true,
        command: TECH_DOC_INTERNAL_SET_LOCK,
        payload: {uuid}
    });

export const unlockTechDocInternalTicket = uuid => dispatch =>
    dispatch({
        type: TECH_DOC_INTERNAL_SET_UNLOCK,
        techDocWebsocket: true,
        command: TECH_DOC_INTERNAL_SET_UNLOCK,
        payload: {uuid}
    });

export const resetRedirectAfterTechDocInternalLockTicket = () => dispatch => dispatch({type: TECH_DOC_INTERNAL_RESET_REDIRECT});
