import React from 'react';
import {required, validInput} from './validationCommons';
import {Field} from 'redux-form';
import {FormattedMessage} from 'react-intl';


export const InputCheckBox = (props) => {
    const {
        field, isRequired, validations, label, isDisabled, disabledStyle, bold=true
    } = props;
    let newValidations = validations;

    if (!newValidations) {
        newValidations = [validInput];
    } else {
        newValidations.push(validInput);
    }
    if (isRequired) {
        newValidations.push(required);
    }

    const Checkbox = props => {
        const { input, isDisabled, type } = props;
        return (
            <input {...input} type={type} disabled={isDisabled}/>
        );
    };

    return (
        <div>
            <Field name={field}
                   component={Checkbox}
                   type="checkbox"
                   validate={newValidations}
                   isDisabled={isDisabled}
                   onChange={props.onChange}/>
            {!!label && label instanceof Object &&
                <label className={`col-form-label pl-2 ${bold ? 'font-weight-bold' : ''}`} style={(isDisabled && disabledStyle) ? disabledStyle : {}}>
                    <FormattedMessage {...label}/>
                </label>
            }
        </div>
    )
};



