import {
    TECH_DOC_ADMIN_SOLUTION_GROUPS_FETCH_PENDING,
    TECH_DOC_ADMIN_SOLUTION_GROUPS_SEND,
} from '../../actions/admin/actionSolutionGroupUserRightsManagement';

export const adminSolutionGroupOptions = (state = {
    solutionGroupOptions: [],
    isLoadingOptions: false,
}, action) => {
    switch (action.type) {
        case TECH_DOC_ADMIN_SOLUTION_GROUPS_FETCH_PENDING: {
            return Object.assign({}, state, {isLoadingOptions: true});
        }

        case TECH_DOC_ADMIN_SOLUTION_GROUPS_SEND:{
            const { solutionGroups } = action.payload;
            return Object.assign({}, state, { solutionGroupOptions: solutionGroups, isLoadingOptions: false });
        }

        default: {
            return state;
        }
    }
};
