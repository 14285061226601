import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.secondHandBattery.title',
        defaultMessage: 'Second-Hand Battery Type'
    },
    SECOND_HAND_BATTERY_TYPE: {
        id: 'admin.secondHandBattery',
        defaultMessage: 'Second-Hand Battery Type'
    },
    EMPTY: {
        id: 'admin.secondHandBattery.empty',
        defaultMessage: 'No Second-Hand Battery Types'
    },
    MODAL_TITLE: {
        id: 'admin.secondHandBattery.modal.title',
        defaultMessage: 'Add New Second-Hand Battery Type'
    },
    SECOND_HAND_BATTERY_TYPE_NAME: {
        id: 'admin.secondHandBattery.secondHandBatteryTypeName',
        defaultMessage: 'Name'
    }
});

export default messages;
