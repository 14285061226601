import 'react-table/react-table.css';
import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {FormSection, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {PrimaryButton, SecondaryButton} from '../../common/Button';
import {claimsFieldLabelMapping} from '../../../constants/claimsFieldLabelMapping';
import RenderSyncErrors from '../../common/RenderSyncErrors';
import TicketSection from '../../claims/fullTextSearch/section/TicketSection';
import SearchOption from '../../claims/fullTextSearch/section/SearchOption';
import ProblemDescriptionSectionClaimsSearch from './section/ProblemDescriptionSectionClaimsSearch';
import buttonMessages from '../../../intl/common/buttonMessages';
import fulltextSearchMessages from '../../../intl/claims/fulltextSearchMessages';
import {
    FORM_PROBLEM_DESCRIPTION_SECTION,
    FORM_SEARCH_OPTION_SECTION,
    FORM_TICKET_SECTION
} from '../../../constants/formConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

class FullTextSearchClaimsForm extends Component {
    reset = () => {
        this.props.resetForm();
        this.props.reset();
    }

    render() {
        const {handleSubmit, form} = this.props;
        return (
            <StyledDiv className="border border-secondary px-4 pb-4 pt-2 mb-4 bg-white">
                <RenderSyncErrors form={form} intlMessages={fulltextSearchMessages} mapping={claimsFieldLabelMapping}/>
                <div className="row justify-content-center">
                    <div className="col-12">
                        <form className="form-group">
                            <div className="col-12 pb-2">
                                <FormSection name={FORM_TICKET_SECTION}>
                                    <TicketSection form={form}/>
                                </FormSection>
                                <FormSection name={FORM_PROBLEM_DESCRIPTION_SECTION}>
                                    <ProblemDescriptionSectionClaimsSearch form={form}/>
                                </FormSection>
                                <FormSection name={FORM_SEARCH_OPTION_SECTION}>
                                    <SearchOption form={form}/>
                                </FormSection>
                            </div>
                            <div className="col-12 mt-4">
                                <PrimaryButton type="button"
                                               className="btn mr-2 float-right"
                                               onClick={handleSubmit}>
                                    <FormattedMessage {...buttonMessages.SEARCH}/>
                                </PrimaryButton>
                                <SecondaryButton type="button"
                                                 className="btn float-right mr-2 bg-white"
                                                 onClick={this.reset}>
                                    <FormattedMessage {...buttonMessages.CANCEL}/>
                                </SecondaryButton>
                            </div>
                        </form>
                    </div>
                </div>
            </StyledDiv>
        )
    }
}

FullTextSearchClaimsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
};

export default reduxForm({})(FullTextSearchClaimsForm);
