import React from 'react';
import styled from 'styled-components';
import {reduxForm, FormSection, formValueSelector} from 'redux-form'
import {
    DOCUMENTATION_STORE_CHECK_RESULTS_SECTION,
    DOCUMENTATION_UPDATE_SECTION,
    FORM_ATTACHMENTS_SECTION,
    FORM_FIELD_TYPE_OF_REQUEST,
    FORM_MESSAGES_SECTION,
    FORM_PART_DETAIL_SECTION,
    FORM_PROBLEM_DESCRIPTION_SECTION,
    FORM_TICKET_SECTION,
    FORM_VEHICLE_DETAIL_SECTION,
} from '../../../../constants/formConstants';
import RenderSyncErrors from '../../../common/RenderSyncErrors';
import formMessages from '../../../../intl/sparePartsOrders/formMessages';
import {sparePartsIntlFieldMapping} from '../../../../constants/sparePartsFieldLabelMapping';
import PropTypes from 'prop-types';
import VehicleDetailSectionInternal from './sections/vehicleDetail/VehicleDetailSectionInternal';
import PartDetailSection from './sections/partDetail/PartDetailSection';
import ProblemDescriptionSectionInternal from './sections/problemDescription/ProblemDescriptionSectionInternal';
import RequestDetailSection from './sections/requestDetail/RequestDetailSection';
import SubmitButtons from './sections/SubmitButtons';
import {scrollContentWrapperToTop} from '../../../../utils/utils';
import {
    PART_DOC_REQUEST,
    REF_REPLACEMENT_REQUEST,
    REQUEST_TO_OPEN_FOR_SALE,
    VISUAL_STORE_CHECK
} from '../../../../constants/Utils';
import {connect} from 'react-redux';
import AttachmentSection from './sections/attachments/AttachmentSection';
import TicketStatusHistory from '../../../common/TicketStatusHistory';
import MessagesSectionInternal from './sections/messagesSection/MessagesSectionInternal';
import DocumentationUpdateSection from './sections/documentationUpdate/DocumentationUpdateSection';
import StoreCheckResultsSection from './sections/storeCheckResults/StoreCheckResultsSection';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const InternalTicketMasterForm = (props) => {
    const {form, userRights, readOnly, handleSubmit, reqFields, selectedRequestType} = props;

    return (
        <StyledDiv className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form} intlMessages={formMessages} mapping={sparePartsIntlFieldMapping}/>
            <FormSection name={FORM_TICKET_SECTION}>
                <RequestDetailSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            {(selectedRequestType === VISUAL_STORE_CHECK || selectedRequestType === REQUEST_TO_OPEN_FOR_SALE || selectedRequestType === PART_DOC_REQUEST || selectedRequestType === REF_REPLACEMENT_REQUEST ) &&
                <FormSection name={FORM_VEHICLE_DETAIL_SECTION}>
                    <VehicleDetailSectionInternal form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
                </FormSection>
            }
            {selectedRequestType &&
            <FormSection name={FORM_PART_DETAIL_SECTION}>
                <PartDetailSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}
                                   selectedRequestType={selectedRequestType}/>
            </FormSection>
            }
            {selectedRequestType &&
            <FormSection name={FORM_PROBLEM_DESCRIPTION_SECTION}>
                <ProblemDescriptionSectionInternal form={form} userRights={userRights} reqFields={reqFields}
                                           readOnly={readOnly}/>
            </FormSection>
            }
            <FormSection name={FORM_MESSAGES_SECTION}>
                <MessagesSectionInternal form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            {userRights.canShowDocumentationUpdate && ( selectedRequestType === PART_DOC_REQUEST || selectedRequestType === REF_REPLACEMENT_REQUEST ) &&
                <FormSection name={DOCUMENTATION_UPDATE_SECTION}>
                    <DocumentationUpdateSection form={form} userRights={userRights} reqFields={reqFields}
                                                readOnly={readOnly}/>
                </FormSection>
            }
            {selectedRequestType === VISUAL_STORE_CHECK && userRights.canShowStoreCheckResult &&
            <FormSection name={DOCUMENTATION_STORE_CHECK_RESULTS_SECTION}>
                <StoreCheckResultsSection form={form} userRights={userRights} reqFields={reqFields}
                                            readOnly={readOnly}/>
            </FormSection>
            }
            <FormSection name={FORM_ATTACHMENTS_SECTION}>
                <AttachmentSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <TicketStatusHistory form={form} isInternal/>
            <SubmitButtons form={form} onSubmit={handleSubmit} onSubmitFail={scrollContentWrapperToTop}
                           userRights={userRights} readOnly={readOnly}/>
        </StyledDiv>
    )
}

InternalTicketMasterForm.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
};

const mapStateToProps = (state, props) => ({
    selectedRequestType: formValueSelector(props.form)(state, `${FORM_TICKET_SECTION}.${FORM_FIELD_TYPE_OF_REQUEST}`),
});

const mapDispatchToProps = (dispatch, props) => ({

});

export default reduxForm({})(connect(mapStateToProps, mapDispatchToProps)(InternalTicketMasterForm));

