import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {PrimaryButton} from '../../common/Button';
import {Table, Wrapper} from '../../common/StyledComponents';
import {moduleRoles} from '../../../utils/roles';
import Loader from '../../Loader';
import buttonMessages from '../../../intl/common/buttonMessages';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import adminMessages from '../../../intl/admin/adminMessages';
import escalationLevelMessages from '../../../intl/admin/escalationLevelMessages';
import EscalationLevelRow from './EscalationLevelRow';
import EscalationLevelDialog from './EscalationLevelDialog';
import {addEscalationLevel, fetchEscalationLevels} from '../../../actions/admin/actionAdminEscalationLevel';

const Button = styled(PrimaryButton)`
  margin-right: 5px;
`;

class EscalationLevelContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {isOpenAddEscalationLevelDialog: false};
    }

    componentDidMount() {
        this.props.fetchEscalationLevels(this.props.domain);
    }

    showAddModal = () =>
        this.setState({
            isOpenAddEscalationLevelDialog: true,
        });

    closeModal = () =>
        this.setState({
            isOpenAddEscalationLevelDialog: false
        });

    saveModal = ({presetName = ''}, responseId) => {
        this.setState({
            isOpenAddEscalationLevelDialog: false
        });
        const item = {
            name: presetName,
            uuid: responseId,
        };
        this.props.addEscalationLevel(this.props.domain, item);
    };

    render() {
        const {isLoading, escalationLevels, domain, intl: {formatMessage}, selectedGroup, roles} = this.props;
        return (
            <Wrapper>
                <h5><FormattedMessage {...escalationLevelMessages.ESCALATION_LEVEL}/></h5>
                {isLoading && <Loader/>}
                {!isLoading && <>
                    <Button
                        disabled={escalationLevels.length > 9}
                        className="btn btn-sm mb-sm-3"
                        onClick={this.showAddModal}>
                        <FormattedMessage {...buttonMessages.ADD}/>
                    </Button>
                    {escalationLevels.length === 0 &&
                    <div>
                        <FormattedMessage {...escalationLevelMessages.EMPTY}/>
                    </div>
                    }
                    {escalationLevels.length !== 0 &&
                        <div className="card rounded">
                            <Table className="table table-hover">
                                <thead className="thead-light">
                                <tr className="d-flex">
                                    <th className="col-10">
                                        <FormattedMessage {...escalationLevelMessages.ESCALATION_LEVEL}/>
                                    </th>
                                    <th className="col-2">
                                        <FormattedMessage {...adminMessages.ACTION}/>
                                    </th>
                                </tr>
                                </thead>
                                {
                                    escalationLevels.map((escalationLevel, index) =>
                                        <EscalationLevelRow index={index}
                                                            data={escalationLevel}
                                                            domain={this.props.domain}/>
                                    )
                                }
                            </Table>
                        </div>
                    }
                    <EscalationLevelDialog domain={domain}
                                          show={this.state.isOpenAddEscalationLevelDialog}
                                          closeModal={this.closeModal}
                                          saveModal={this.saveModal}
                                          initialValues={{
                                              groups: [moduleRoles.getAdminGroups(domain, roles)
                                                  .map(group => ({
                                                      value: group.group,
                                                      label: formatMessage(countryGroupMessages[group.group]),
                                                      isFixed: true
                                                  })).find(group => group.value === selectedGroup)]
                                          }}/>
                </>}
            </Wrapper>
        );
    }
}

EscalationLevelContainer.propTypes = {
    selectedGroup: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminEscalationLevel.isLoading,
    escalationLevels: state.adminEscalationLevel.escalationLevels,
    roles: state.profile.userDetail.roles,
    profile: state.profile.userDetail,
    intl: state.intl
});
export default connect(mapStateToProps, {
    addEscalationLevel,
    fetchEscalationLevels,
})(injectIntl(EscalationLevelContainer));
