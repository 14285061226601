import { techDocWebsocketCommands } from '../../constants/techDocWebsocketCommands';
import { errorAlert } from '../alertsActions';
import alertMessages from '../../intl/common/alertMessages';
import {domains} from '../../constants/Utils';
import {
    validateAnsweringSchemaCreateEvent,
    validateAnsweringSchemaUpdateEvent
} from '../../api/validation/answeringSchema';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const updateAnsweringSchema = (domain, data) => dispatch => {
    const { error } = validateAnsweringSchemaUpdateEvent(data);
    if (error) {
        switch (domain) {
            case domains.TECHNICAL_DOCUMENTATION: {
                dispatch(errorAlert(alertMessages.ANSWERING_SCHEMA_UPDATE_SEND_ERROR, [error.toString()]));
                dispatch({type: techDocWebsocketCommands.TECH_DOC_ANSWERING_SCHEMA_SAVE_UPDATE_ERROR});
                break;
            }
            case domains.SPARE_PARTS: {
                dispatch(errorAlert(alertMessages.ANSWERING_SCHEMA_UPDATE_SEND_ERROR, [error.toString()]));
                dispatch({type: sparePartsWebsocketCommands.SPARE_PARTS_ANSWERING_SCHEMA_SAVE_UPDATE_ERROR});
                break;
            }
        }
    } else {
        switch (domain) {
            case domains.TECHNICAL_DOCUMENTATION: {
                return dispatch({
                    type: techDocWebsocketCommands.TECH_DOC_ANSWERING_SCHEMA_UPDATE_PENDING,
                    techDocWebsocket: true,
                    command: techDocWebsocketCommands.TECH_DOC_ANSWERING_SCHEMA_UPDATE,
                    payload: {
                        payload: {
                            ...data
                        }
                    }
                });
            }
            case domains.SPARE_PARTS: {
                return dispatch({
                    type: sparePartsWebsocketCommands.SPARE_PARTS_ANSWERING_SCHEMA_UPDATE_PENDING,
                    sparePartsWebsocket: true,
                    command: sparePartsWebsocketCommands.SPARE_PARTS_ANSWERING_SCHEMA_UPDATE,
                    payload: {
                        payload: {
                            ...data
                        }
                    }
                });
            }
        }
    }
};

export const saveAnsweringSchema = (domain, data) => dispatch => {
    let shouldContinue = true;
    data.forEach(item => {
        const {error} = validateAnsweringSchemaCreateEvent(item);
        if (error && domains.TECHNICAL_DOCUMENTATION) {
            dispatch(errorAlert(alertMessages.ANSWERING_SCHEMA_NEW_SEND_ERROR, [error.toString()]));
            dispatch({type: techDocWebsocketCommands.TECH_DOC_ANSWERING_SCHEMA_SAVE_NEW_ERROR});
            shouldContinue = false;
        }
        if (error && domains.SPARE_PARTS) {
            dispatch(errorAlert(alertMessages.ANSWERING_SCHEMA_NEW_SEND_ERROR, [error.toString()]));
            dispatch({type: sparePartsWebsocketCommands.SPARE_PARTS_ANSWERING_SCHEMA_SAVE_NEW_ERROR});
            shouldContinue = false;
        }
    });
    if (shouldContinue && domain === domains.TECHNICAL_DOCUMENTATION) {
        data.forEach(item => {
            dispatch({
                type: techDocWebsocketCommands.TECH_DOC_ANSWERING_SCHEMA_CREATE_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_ANSWERING_SCHEMA_CREATE,
                payload: {
                    payload: {
                        ...item,
                    }
                }
            })
        });
    }
    if (shouldContinue && domain === domains.SPARE_PARTS) {
        data.forEach(item => {
            dispatch({
                type: sparePartsWebsocketCommands.SPARE_PARTS_ANSWERING_SCHEMA_CREATE_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ANSWERING_SCHEMA_CREATE,
                payload: {
                    payload: {
                        ...item,
                    }
                }
            })
        });
    }

};

export const fetchAnsweringSchema = (domain, id, ipn) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: techDocWebsocketCommands.TECH_DOC_ANSWERING_SCHEMA_DETAIL_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_ANSWERING_SCHEMA_DETAIL,
                payload: {
                    answeringSchema: {
                        id
                    },
                }
            });
        }
        case domains.SPARE_PARTS: {
            return dispatch({
                type: sparePartsWebsocketCommands.SPARE_PARTS_ANSWERING_SCHEMA_DETAIL_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ANSWERING_SCHEMA_DETAIL,
                payload: {
                    answeringSchema: {
                        id
                    },
                }
            });
        }
    }
};

export const resetAnsweringSchema = (domain) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: techDocWebsocketCommands.TECH_DOC_ANSWERING_SCHEMA_RESET,
            });
        }
        case domains.SPARE_PARTS: {
            return dispatch({
                type: sparePartsWebsocketCommands.SPARE_PARTS_ANSWERING_SCHEMA_RESET,
            });
        }
    }
};


export const redirectReset = (domain) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: techDocWebsocketCommands.TECH_DOC_ANSWERING_SCHEMA_REDIRECT_RESET,
            });
        }
        case domains.SPARE_PARTS: {
            return dispatch({
                type: sparePartsWebsocketCommands.SPARE_PARTS_ANSWERING_SCHEMA_REDIRECT_RESET,
            });
        }
    }
};
