import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    WIN1250: {
        id: 'common.charset.windows_1250',
        defaultMessage: 'Windows 1250'
    },
    WIN1251: {
        id: 'common.charset.windows_1251',
        defaultMessage: 'Windows 1251'
    },
    WIN1252: {
        id: 'common.charset.windows_1252',
        defaultMessage: 'Windows 1252'
    },
    ISO88592: {
        id: 'common.charset.iso8859_2',
        defaultMessage: 'ISO 8859-2'
    },
    UTF8: {
        id: 'common.charset.utf_8',
        defaultMessage: 'UTF-8 (w/o BOM)'
    },
    UTF8BOM: {
        id: 'common.charset.utf_8_bom',
        defaultMessage: 'UTF-8 (with BOM)'
    }
});

export default messages;
