import React from 'react';
import {
    FORM_FIELD_DESCRIPTION,
    FORM_FIELD_LINE,
    FORM_FIELD_ORDER, FORM_FIELD_PART_NO,
    FORM_FIELD_PROD, FORM_FIELD_QUANTITY, FORM_FIELD_REMARKS, FORM_FIELD_SUBJECT_TO_VOR,
    FORM_LABEL_DESCRIPTION,
    FORM_LABEL_DIC_TRANSFER,
    FORM_LABEL_LINE,
    FORM_LABEL_ORDER,
    FORM_LABEL_PART_NO,
    FORM_LABEL_PROD,
    FORM_LABEL_QUANTITY,
    FORM_LABEL_REMARKS,
    FORM_LABEL_SUBJECT_TO_VOR,
} from "../../../../../../constants/formConstants";
import {
    StyledSpan,
    StyledTh,
    StyledThead,
    StyledToolsTh,
} from "../../../../../common/StyledComponents";
import ValidFormattedMessage from "../../../../../common/ValidFormattedMessage";
import formMessages from "../../../../../../intl/sparePartsOrders/formMessages";
import PropTypes from 'prop-types';

const OrdersVorPviTableHeader = (props) => {
    const { userRights, readOnly, reqFields } = props;
    return (
        <StyledThead>
            <tr>
                {userRights.canViewMpr &&
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_DIC_TRANSFER} intlMessages={formMessages}/>
                </StyledTh>}
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_PROD} intlMessages={formMessages}/>
                    {reqFields.includes(FORM_FIELD_PROD) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_ORDER} intlMessages={formMessages}/>
                    {reqFields.includes(FORM_FIELD_ORDER) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_LINE} intlMessages={formMessages}/>
                    {reqFields.includes(FORM_FIELD_LINE) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_PART_NO} intlMessages={formMessages}/>
                    {reqFields.includes(FORM_FIELD_PART_NO) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_SUBJECT_TO_VOR} intlMessages={formMessages}/>
                    {reqFields.includes(FORM_FIELD_SUBJECT_TO_VOR) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_DESCRIPTION} intlMessages={formMessages}/>
                    {reqFields.includes(FORM_FIELD_DESCRIPTION) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_QUANTITY} intlMessages={formMessages}/>
                    {reqFields.includes(FORM_FIELD_QUANTITY) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                <StyledTh>
                    <ValidFormattedMessage message={FORM_LABEL_REMARKS} intlMessages={formMessages}/>
                    {reqFields.includes(FORM_FIELD_REMARKS) && !readOnly && <StyledSpan>{'\u00A0*'}</StyledSpan>}
                </StyledTh>
                {userRights.canEditItems && !readOnly && <StyledToolsTh/>}
            </tr>
        </StyledThead>
    )
}

OrdersVorPviTableHeader.propTypes = {
    readOnly: PropTypes.bool,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
};

export default OrdersVorPviTableHeader;
