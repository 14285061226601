import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {confirmOrderPopUpModal, fetchOrderPopUp, openOrderPopUpModal} from '../../actions/orders/actionOrderPopUpModal';
import PropTypes from 'prop-types';
import RecurrentProblemOrderPage from './recurrentProblem/RecurrentProblemOrderPage';
import OrderPopUpModal from './OrderPopUpModal';
import {
    ORDERS_EXPORT_PATH,
    ORDERS_HISTORY_PATH,
    ORDERS_INTERNAL_TICKET,
    ORDERS_INTERNAL_TICKET_HISTORY_PATH,
    ORDERS_INTERNAL_TICKET_LIST_PATH,
    ORDERS_INTERNAL_TICKET_SEARCH_PATH,
    ORDERS_LIST_PATH,
    ORDERS_MANAGEMENT_GROUP,
    ORDERS_NEW_ALLOCATION_REQUEST_FORM_PATH,
    ORDERS_NEW_CANCELLATION_FORM_PATH,
    ORDERS_NEW_DELIVERY_DATE_REMINDER_FORM_PATH,
    ORDERS_NEW_EV_BATTERY_FORM_PATH, ORDERS_NEW_INTERNAL_TICKET,
    ORDERS_NEW_PARTS_NON_AVAILABLE_FORM_PATH,
    ORDERS_NEW_VOR_PVI_FORM_PATH,
    ORDERS_PATH,
    ORDERS_RECURRENT_PROBLEM_PATH,
    ORDERS_SEARCH_ENGINE_PATH
} from '../../routes/paths';
import ListOrderPage from './list/ListOrderPage';
import HistoryOrderPage from './history/HistoryOrderPage';
import ViewSparePartTicketPage from './ticket/ViewSparePartTicketPage';
import NewSparePartTicketPage from './ticket/NewSparePartTicketPage';
import EditSparePartTicketPage from './ticket/EditSparePartTicketPage';
import SearchEngineOrderPage from './searchEngine/SearchEngineOrderPage';
import ExportOrderPage from './export/ExportOrderPage';
import {
    ALLOCATION_REQUEST_FORM,
    DELIVERY_DATE_REMINDER_FORM, EV_BATTERY_FORM,
    ORDER_CANCELLATION_FORM,
    ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
    SPARE_PARTS_INTERNAL_TICKET_FORM,
    VOR_PVI_ORDER_FORM
} from '../../constants/formConstants';
import get from 'get-value';
import Loader from '../Loader';
import { domains } from '../../constants/Utils';
import NotFoundPage from '../NotFoundPage';
import NewSparePartsInternalTicketPage from './internalTicket/NewSparePartsInternalTicketPage';
import ViewSparePartsInternalTicketPage from './internalTicket/ViewSparePartsInternalTicketPage';
import EditSparePartsInternalTicketPage from './internalTicket/EditSparePartsInternalTicketPage';
import ListSparePartsInternalTicketPage from './internalTicketList/ListSparePartsInternalTicketPage';
import HistorySparePartsInternalTicketPage from './internalTicketHistory/HistorySparePartsInternalTicketPage';
import InternalTicketSearchSparePartsPage from './internalTicketSearch/InternalTicketSearchSparePartsPage';
import ManagementGroupOrderPage from "./managementGroup/ManagementGroupOrderPage";

const domain = domains.SPARE_PARTS;

class SparePart extends Component {
    componentDidMount() {
        if (!this.props.isConfirm && this.props.userDetail.dealerNumber && this.props.userDetail.dealerNumber !== '' &&  get(this.props.userDetail, `roles.${domain}.dealer`)) {
            this.props.fetchOrderPopUp(this.props.group);
            this.props.openOrderPopUpModal();
        }
    }

    render() {
        const {userDetail, isOpen, confirmOrderPopUpModal, isConfirm, isLoadingPopUp} = this.props;
        const dealer = get(userDetail, `roles.${domain}.dealer`);
        if (dealer && !isConfirm) {
            return isLoadingPopUp ? <Loader /> : <OrderPopUpModal show={isOpen}
                                                                  handleClose={confirmOrderPopUpModal} />;
        }
        return (
            <Switch>
                <Route
                    exact path={ORDERS_PATH}
                    render={() => dealer ? <Redirect to={ORDERS_RECURRENT_PROBLEM_PATH}/> :
                        <Redirect to={ORDERS_LIST_PATH}/>}/>
                <Route exact path={ORDERS_LIST_PATH}
                       component={ListOrderPage}/>
                <Route exact path={ORDERS_HISTORY_PATH}
                       component={HistoryOrderPage}/>
                <Route exact path={ORDERS_SEARCH_ENGINE_PATH}
                       component={SearchEngineOrderPage}/>
                <Route exact path={ORDERS_EXPORT_PATH}
                       component={ExportOrderPage}/>
                <Route exact path={ORDERS_PATH + '/:view(view)/:ticketUuid(.*)'}
                       component={ViewSparePartTicketPage}/>
                <Route exact path={ORDERS_PATH + '/:edit(edit)/:ticketUuid(.*)'}
                       component={EditSparePartTicketPage}/>
                <Route exact path={ORDERS_NEW_ALLOCATION_REQUEST_FORM_PATH}
                       render={() => <NewSparePartTicketPage formType={ALLOCATION_REQUEST_FORM}/>}/>
                <Route exact path={ORDERS_NEW_CANCELLATION_FORM_PATH}
                       render={() => <NewSparePartTicketPage formType={ORDER_CANCELLATION_FORM}/>}/>
                <Route exact path={ORDERS_NEW_DELIVERY_DATE_REMINDER_FORM_PATH}
                       render={() => <NewSparePartTicketPage formType={DELIVERY_DATE_REMINDER_FORM}/>}/>
                <Route exact path={ORDERS_NEW_PARTS_NON_AVAILABLE_FORM_PATH}
                       render={() => <NewSparePartTicketPage formType={ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM}/>}/>
                <Route exact path={ORDERS_NEW_VOR_PVI_FORM_PATH}
                       render={() => <NewSparePartTicketPage formType={VOR_PVI_ORDER_FORM}/>}/>
                <Route exact path={ORDERS_NEW_EV_BATTERY_FORM_PATH}
                       render={() => <NewSparePartTicketPage formType={EV_BATTERY_FORM}/>}/>
                <Route exact path={ORDERS_NEW_INTERNAL_TICKET}
                       render={() => <NewSparePartsInternalTicketPage formType={SPARE_PARTS_INTERNAL_TICKET_FORM}/>}/>
                <Route exact path={ORDERS_INTERNAL_TICKET + '/:view(view)/:ticketUuid(.*)'}
                       component={ViewSparePartsInternalTicketPage}/>
                <Route exact path={ORDERS_INTERNAL_TICKET + '/:edit(edit)/:ticketUuid(.*)'}
                       component={EditSparePartsInternalTicketPage}/>
                <Route exact path={ORDERS_INTERNAL_TICKET_LIST_PATH}
                       component={ListSparePartsInternalTicketPage}/>
                <Route exact path={ORDERS_INTERNAL_TICKET_HISTORY_PATH}
                       component={HistorySparePartsInternalTicketPage}/>
                <Route exact path={ORDERS_INTERNAL_TICKET_SEARCH_PATH}
                       component={InternalTicketSearchSparePartsPage}/>
                <Route exact path={ORDERS_RECURRENT_PROBLEM_PATH}
                       component={RecurrentProblemOrderPage}/>
                <Route exact path={ORDERS_MANAGEMENT_GROUP}
                       component={ManagementGroupOrderPage}/>
                <Route component={NotFoundPage}/>
            </Switch>
        );
    }
}

SparePart.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isConfirm: PropTypes.bool.isRequired,
    openOrderPopUpModal: PropTypes.func.isRequired,
    confirmOrderPopUpModal: PropTypes.func.isRequired,
    userDetail: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    isOpen: state.orderPopUpModal.isOpen,
    isConfirm: state.orderPopUpModal.isConfirm,
    userDetail: state.profile.userDetail,
    isLoadingPopUp: state.orderPopUpModal.isLoading,
    group: state.profile.userDetail.group,
});

export default withRouter(connect(mapStateToProps, {
    openOrderPopUpModal,
    confirmOrderPopUpModal,
    fetchOrderPopUp
})(SparePart));
