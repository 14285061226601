import React from 'react';
import {FormattedMessage} from "react-intl";
import {required, validInput} from "./validationCommons";
import {Field, Fields} from "redux-form";
import {RequiredSpan} from "./StyledComponents";
import {FieldPage} from "./FieldPage";
import styled from "styled-components";

const InvisibleField = styled(Field)`
  display: none;
`;

export const InputFieldPage = (props) => {
    const {
        fields, isDisabled, isRequired, validations, label, normalize
    } = props;
    let newValidations = validations;

    if (!newValidations) {
        newValidations = [validInput];
    } else {
        newValidations.push(validInput);
    }
    if (isRequired) {
        newValidations.push(required);
    }

    return (
        <div>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label}/>
                {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
            </label>}
            <InvisibleField name={fields[0]} component="input" type="text" normalize={normalize}
                            validate={newValidations}/>
            <InvisibleField name={fields[1]} component="input" type="text" normalize={normalize}
                            validate={newValidations}/>
            <Fields names={fields}
                    component={FieldPage}
                    isDisabled={isDisabled}
                    isSmall
            />
        </div>
    )
};



