import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedDate} from 'react-intl';
import PropTypes from 'prop-types';
import get from 'get-value';
import modelAnswersMessages from '../../../intl/admin/modelAnswersMessages';
import moment from 'moment';
import {
    fetchModelAnswers,
} from '../../../actions/admin/actionAdminModelAnswers';
import Table from '../../common/table/TicketTable';
import commonMessages from '../../../intl/common/commonMessages';
import {
    getCurrentFormTypes,
    getFormTypes,
    getInternalFormTypes,
    modelAnswersDisplayAreaOptions
} from '../../../constants/Utils';
import internalTicketRequestTypeMessages from '../../../intl/technicalDocumentations/internalTicketRequestTypeMessages';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import formCountryMessages from '../../../intl/common/countryGroupMessages';
import DatePickerTableComponent from '../../common/datePicker/DatePickerTableComponent';
import {isInternal as formTypeIsInternal} from '../../../utils/escalationSchemaUtils';
import {debounce} from 'throttle-debounce';
import {IconDelete, IconEdit, PrimaryIcon} from "../../common/Button";
import styled from "styled-components";
import {isEqual, uniqWith} from "lodash";

const StyledSpan = styled.span`
    white-space: pre-line;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

const ModelAnswersTable = props => {
    const {
        domain,
        solutionGroup,
        countryGroups,
        intl: {formatMessage},
        modelAnswers,
        isLoading,
        pages,
        shouldRefetch,
        handleEditButton,
        handleDeleteButton,
        defaultSettings,
        handleTableSettings,
        isNextPage,
        isNextFromPivot,
    } = props;
    let filtering = false;

    const sgFormTypes = [... new Set([...Object.keys(get(solutionGroup, 'escalationSchemas', {})), ...Object.keys(get(solutionGroup, 'answeringSchemas', {}))])];
    const sgGroups = get(solutionGroup, 'groups', []);
    const internalFormTypesOptions = (getInternalFormTypes(domain) || {}).map(formType => ({
        value: formType,
        label: (internalTicketRequestTypeMessages[formType] ? formatMessage(internalTicketRequestTypeMessages[formType]) : (formTypeMessages[formType] ? formatMessage(formTypeMessages[formType]) : formType)) + ' (Internal)'
    }));
    const formTypeOptions = (getCurrentFormTypes(domain) || {}).map(formType => ({
        value: formType,
        label: formTypeMessages[formType] ? formatMessage(formTypeMessages[formType]) : formType
    }));
    const displayAreaOptions = modelAnswersDisplayAreaOptions.map(option => ({
        value: option,
        label: modelAnswersMessages[option] ? formatMessage(modelAnswersMessages[option]) : option
    }));

    const formatCountryLabel = (value) => {
        let result = '';
        if (value) {
            value.map(item => result += formatMessage(formCountryMessages[item]) + ', ')
        }
        return result.replace(/,\s$/, '');
    };

    const getColumnLib = () => {
        const columnLib = [
            {
                id: 'uuid',
                accessor: 'uuid',
                message: modelAnswersMessages.TABLE_SOLUTION_GROUP,
                filterable: false,
                sortable: false,
                Cell: () => <div>{get(solutionGroup, 'name')}</div>
            },
            {
                id: 'groups',
                accessor: 'groups',
                message: modelAnswersMessages.TABLE_GROUP,
                filterable: false,
                Cell: e => <StyledSpan>{(get(solutionGroup, 'isDefault', false) && get(e, 'original.displayArea') === 'INTERNAL_NOTES') ? formatMessage(formCountryMessages.ALL) : formatCountryLabel(e.value || [])}</StyledSpan>
            },
            {
                id: 'formTypes',
                accessor: 'formTypes',
                message: modelAnswersMessages.TABLE_FORM_TYPE,
                Filter: ({filter, onChange}) => (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{width: '100%', border: '1px solid #999999'}}
                        value={filter ? filter.value : ''}>
                        <option value=''>{formatMessage(commonMessages.ALL)}</option>
                        {[...internalFormTypesOptions, ...formTypeOptions].map((item, index) =>
                            <option key={index} value={item.value}>
                                {item.label}
                            </option>)
                        }
                    </select>
                ),
                Cell: e => <StyledSpan>{formatFormTypeLabel(e.value || [])}</StyledSpan>
            },
            {
                id: 'subject',
                accessor: 'subject',
                message: modelAnswersMessages.TABLE_MODEL_ANSWER_TITLE,
            },
            {
                id: 'displayArea',
                accessor: 'displayArea',
                message: modelAnswersMessages.TABLE_MODEL_ANSWER_DISPLAY_AREA,
                Filter: ({filter, onChange}) => (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{width: '100%', border: '1px solid #999999'}}
                        value={filter ? filter.value : ''}>
                        <option value=''>{formatMessage(commonMessages.ALL)}</option>
                        {displayAreaOptions.map((item, index) =>
                            <option key={index} value={item.value}>
                                {item.label}
                            </option>)
                        }
                    </select>
                ),
                Cell: e =>
                    <div>{modelAnswersMessages[e.value] ? formatMessage(modelAnswersMessages[e.value]) : e.value}</div>
            },
            {
                id: 'lastUpdate',
                accessor: 'lastUpdate',
                message: modelAnswersMessages.TABLE_LAST_UPDATE,
                Filter: ({filter, onChange}) => {
                    let value = filter && filter.value;
                    if (typeof value === 'string') {
                        value = moment(value).toDate();
                    }
                    return <DatePickerTableComponent
                        value={value || undefined}
                        onChange={onChange}
                        filter={filter}/>;
                },
                Cell: e => <FormattedDate value={new Date(e.value)}/>,
            }
        ];

        return get(solutionGroup, 'isDefault', false) ? columnLib : columnLib.filter(col => !['displayArea', 'groups'].includes(col.id));
    }

    const normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date) {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    const isValid = (item) => {
        const itemFormTypes = get(item, 'formTypes', []);
        const itemGroups = get(item, 'groups', []);
        const itemIsDefault = get(solutionGroup, 'isDefault', false);
        const itemDisplayArea = get(item, 'displayArea');


        const defaultCountryRolesOfItem = get(solutionGroup, 'defaultCountryRoles',
            []);
        const sgDealerFormTypes = sgFormTypes.filter(fType => getFormTypes(domain).includes(fType));

        const dealerNotesFTypesByDefaultCountryRoleAndSgSchemas = uniqWith(
            [...defaultCountryRolesOfItem
            .filter(role => itemGroups.includes(role.group))
            .flatMap(role => Object.entries(
                get(role, 'dealer.formsPermission', {default: {}}))
            .map(([formName, value]) => value ? formName : undefined)
            .filter(formType => formType)),
            ...sgDealerFormTypes],
            isEqual);



        if ((itemDisplayArea === 'INTERNAL_NOTES') && itemFormTypes.find(formType => !sgFormTypes.includes(formType))) {
            return false;
        }
        if (itemGroups.find(group => !sgGroups.includes(group))) {
            return false;
        }
        if (itemIsDefault && (itemGroups.length === 0 || (itemDisplayArea === 'INTERNAL_NOTES' && itemGroups.length !== sgGroups.length))) {
            return false;
        }
        if (!itemIsDefault && itemGroups.length > 0) {
            return false;
        }
        if ((itemDisplayArea === 'DEALER_NOTES') && itemFormTypes.find(formType => !getCurrentFormTypes(domain).includes(formType))) {
            return false;
        }

        if (itemDisplayArea === 'DEALER_NOTES' && itemFormTypes.find(formType => !dealerNotesFTypesByDefaultCountryRoleAndSgSchemas.includes(formType))) {
            return false;
        }
        return true;
    };

    const handleFetchData = state => {
        props.fetchModelAnswers(domain, solutionGroup, countryGroups, state.pageSize, state.sorted, state.cursor, state.isNextFromPivot, normalizeFiltered(state));
        filtering = false;
        handleTableSettings({
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: normalizeFiltered(state),
        });
    };

    useEffect(() => {
        if (shouldRefetch) {
            props.fetchModelAnswers(domain, solutionGroup, countryGroups, defaultSettings.pageSize, defaultSettings.sorted, undefined, undefined, normalizeFiltered(defaultSettings));
        }
    }, [shouldRefetch]);

    const handleFetchDataDebounced = debounce(1000, handleFetchData);
    const fetchStrategy = (state) => {
        filtering ? handleFetchDataDebounced(state) : handleFetchData(state);
    };

    const formatFormTypeLabel = (values = []) => {
        let result = '';
        if (values) {
            values.forEach(formTypeItem => {
                if (formTypeIsInternal(domain, formTypeItem)) {
                    result += (internalTicketRequestTypeMessages[formTypeItem] ? formatMessage(internalTicketRequestTypeMessages[formTypeItem]) : formTypeItem) + ' (Internal), '
                } else {
                    result += (formTypeMessages[formTypeItem] ? formatMessage(formTypeMessages[formTypeItem]) : formTypeItem) + ', ';
                }
            })
        }
        return result.replace(/,\s$/, '');
    }

    const checkedData = modelAnswers.map(item => (isValid(item) ? item : {
        ...item,
        style: {"fontWeight": "bold", "color": "red"}
    }));
    return (
        <div className="text-center">
            <Table isLoading={isLoading}
                   withPagination={true}
                   pages={pages}
                   handleFetchData={(e) => fetchStrategy(e)}
                   columns={getColumnLib()}
                   handleOnFilteredChange={() => {
                       filtering = true;
                   }}
                   data={checkedData}
                   defaultSorting={get(defaultSettings, 'sorted', [{id: 'uuid'}])}
                   defaultFiltered={get(defaultSettings, 'filtered', {default: []})}
                   defaultPageSize={get(defaultSettings, 'pageSize', {default: 10})}
                   isNextPage={isNextPage}
                   isNextFromPivot={isNextFromPivot}
                   toolButtons={[
                       {
                           handleClick: (row) => handleEditButton({
                               isOpen: true,
                               initValues: {
                                   uuid: row.uuid,
                                   groups: row.groups,
                                   displayArea: row.displayArea,
                                   formTypes: row.formTypes,
                                   subject: row.subject,
                                   text: row.text
                               }
                           }),
                           component: <PrimaryIcon className="btn btn-sm mr-sm-1" title="Edit" type="button"
                                                   key="editButton">
                               <IconEdit/>
                           </PrimaryIcon>
                       },
                       {
                           handleClick: (row) => handleDeleteButton({isOpen: true, modelAnswerToDelete: row.id}),
                           component: <PrimaryIcon className="btn btn-sm mr-sm-1" title="Delete" type="button"
                                                   key="deleteButton">
                               <IconDelete/>
                           </PrimaryIcon>
                       },
                   ]}
            />
        </div>
    );
}

ModelAnswersTable.propTypes = {
    domain: PropTypes.string.isRequired,
    solutionGroup: PropTypes.any.isRequired,
    intl: PropTypes.any.isRequired,
    countryGroups: PropTypes.array.isRequired,
    modelAnswers: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    shouldRefetch: PropTypes.bool.isRequired,
    fetchModelAnswers: PropTypes.func.isRequired,
    handleEditButton: PropTypes.func.isRequired,
    handleDeleteButton: PropTypes.func.isRequired,
    pages: PropTypes.number.isRequired,
    defaultSettings: PropTypes.any.isRequired,
    handleTableSettings: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    solutionGroup: state.adminModelAnswers.fullSolutionGroupInfo,
    countryGroups: state.adminModelAnswers.countryGroups,
    modelAnswers: state.adminModelAnswers.modelAnswers,
    isLoading: state.adminModelAnswers.isLoading,
    shouldRefetch: state.adminModelAnswers.shouldRefetch,
    pages: state.adminModelAnswers.pages,
    isNextPage: state.adminModelAnswers.isNextPage,
    isNextFromPivot: state.adminModelAnswers.isNextFromPivot,
});

export default connect(mapStateToProps, {
    fetchModelAnswers,
})(injectIntl(ModelAnswersTable));
