import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Field} from 'redux-form';
import moment from 'moment';
import styled from 'styled-components';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {required, validFiveCharLength, validInput, validSixCharLength} from '../../../../../common/validationCommons';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import DatePickerComponent from '../../../../../common/datePicker/DatePickerComponent';
import formMessages from '../../../../../../intl/claims/formMessages';
import {
    FORM_FIELD_ACCOUNTING_CREDIT_NOTE,
    FORM_FIELD_ACCOUNTING_CREDIT_NOTE_DATE,
    FORM_FIELD_BILL_OF_DELIVERY,
    FORM_FIELD_CLAIM_RELATED,
    FORM_FIELD_CREDIT_NOTE,
    FORM_FIELD_CREDIT_NOTE_DATE,
    FORM_FIELD_DATE,
    FORM_FIELD_INVOICE,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_SUPPLIER,
    FORM_FIELD_SUPPLIER_PART_NO,
    FORM_LABEL_ACCOUNTING_CREDIT_NOTE,
    FORM_LABEL_ACCOUNTING_CREDIT_NOTE_DATE,
    FORM_LABEL_BILL_OF_DELIVERY,
    FORM_LABEL_CLAIM_RELATED,
    FORM_LABEL_CREDIT_NOTE,
    FORM_LABEL_CREDIT_NOTE_DATE,
    FORM_LABEL_DATE,
    FORM_LABEL_INVOICE,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_SUPPLIER,
    FORM_LABEL_SUPPLIER_PART_NO,
} from '../../../../../../constants/formConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const DirectFlowClaimSection = (props) => {
    const {readOnly, userRights, reqFields} = props;

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.PROBLEM_DESCRIPTION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row pb-2">
                    <div className="col-xl-3 col-lg-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_INVOICE]}
                                                  field={FORM_FIELD_INVOICE}
                                                  maxLength={6}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_INVOICE)}
                                                  validations={[validSixCharLength]}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <Field component={DatePickerComponent}
                               selectsEnd
                               isClearable
                               label={formMessages[FORM_LABEL_DATE]}
                               name={FORM_FIELD_DATE}
                               isDisabled={!userRights.canEditProblemDesc || readOnly}
                               validate={reqFields.includes(FORM_FIELD_DATE) ? [validInput, required] : undefined}
                               isRequired={reqFields.includes(FORM_FIELD_DATE)}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_CLAIM_RELATED]}
                                                  field={FORM_FIELD_CLAIM_RELATED}
                                                  maxLength={5}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  validations={[validFiveCharLength]}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row pb-2">
                    <div className="col-xl-5 col-lg-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_SUPPLIER]}
                                                  field={FORM_FIELD_SUPPLIER}
                                                  maxLength={20}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_SUPPLIER)}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_CREDIT_NOTE]}
                                                  field={FORM_FIELD_CREDIT_NOTE}
                                                  maxLength={6}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  validations={[validSixCharLength]}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <Field component={DatePickerComponent}
                               selectsEnd
                               isClearable
                               label={formMessages[FORM_LABEL_CREDIT_NOTE_DATE]}
                               name={FORM_FIELD_CREDIT_NOTE_DATE}
                               isDisabled={!userRights.canEditProblemDesc || readOnly}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                    </div>
                </div>
                <div className="form-row pb-2">
                    <div className="col-xl-4 col-lg-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_SUPPLIER_PART_NO]}
                                                  field={FORM_FIELD_SUPPLIER_PART_NO}
                                                  maxLength={15}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_SUPPLIER_PART_NO)}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_BILL_OF_DELIVERY]}
                                                  field={FORM_FIELD_BILL_OF_DELIVERY}
                                                  maxLength={15}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row pb-2">
                    <div className="col-xl-4 col-lg-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_ACCOUNTING_CREDIT_NOTE]}
                                                  field={FORM_FIELD_ACCOUNTING_CREDIT_NOTE}
                                                  maxLength={15}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <Field component={DatePickerComponent}
                               selectsEnd
                               isClearable
                               label={formMessages[FORM_LABEL_ACCOUNTING_CREDIT_NOTE_DATE]}
                               name={FORM_FIELD_ACCOUNTING_CREDIT_NOTE_DATE}
                               isDisabled={!userRights.canEditProblemDesc || readOnly}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                    </div>
                </div>
                <div className="form-row pb-2">
                    <div className="col-12">
                        <TextareaFieldWithValidation label={formMessages[FORM_LABEL_PROBLEM_DESCRIPTION]}
                                                     field={FORM_FIELD_PROBLEM_DESCRIPTION}
                                                     isDisabled={!userRights.canEditProblemDescText || readOnly}
                                                     rows={6}
                                                     isRequired={reqFields.includes(FORM_FIELD_PROBLEM_DESCRIPTION)}/>
                    </div>
                </div>
            </StyledDiv>
        </>
    )
}

export default (DirectFlowClaimSection);
