import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {clearAlerts} from '../../../actions/alertsActions';
import get from 'get-value';
import Loader from '../../Loader';
import {
    ALLOCATION_REQUEST_FORM,
    DELIVERY_DATE_REMINDER_FORM, EV_BATTERY_FORM,
    FORM_BUTTON_CLOSE, FORM_BUTTON_EDIT,
    FORM_BUTTON_REOPEN, FORM_BUTTON_SG_TAKE_BACK,
    FORM_BUTTON_SWITCH_TO_PVI,
    FORM_BUTTON_TAKE_BACK,
    ORDER_CANCELLATION_FORM,
    ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
    ORDER_TICKET_STATUS_CLOSED,
    VOR_PVI_ORDER_FORM
} from '../../../constants/formConstants';
import DeliveryDateReminderMasterForm from './forms/DeliveryDateReminderMasterForm';
import AllocationRequestMasterForm from './forms/AllocationRequestMasterForm';
import VorPviOrderMasterForm from './forms/VorPviOrderMasterForm';
import PartNoLongerAvailableMasterForm from './forms/PartNoLongerAvailableMasterForm';
import OrderCancellationMasterForm from './forms/OrderCancellationMasterForm';
import EVBatteryMasterForm from './forms/EVBatteryMasterForm';
import {Redirect, withRouter} from 'react-router-dom';
import {
    fetchSparePartsOrdersTicket,
    resetTicketOrder,
    updateTicketOrder
} from '../../../actions/orders/actionTicket';
import PropTypes from 'prop-types';
import {initAllocationReqToVorPviTicket, initLoadedTicket, transformUpdateTicketForSave} from '../Utils';
import {moduleRoles} from '../../../utils/roles';
import {ORDERS_NEW_VOR_PVI_FORM_PATH} from '../../../routes/paths';
import {editRights} from '../../../constants/sparePartsTicketRights';
import {formatTicketNumber} from '../../../utils/utils';
import formMessages from '../../../intl/sparePartsOrders/formMessages';
import {lockSparePartsOrdersTicket, resetRedirectAfterLockTicket} from '../../../actions/orders/actionLockTicket';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import PageHeader from '../../common/PageHeader';
import checkRoles from '../../common/roleChecker/RoleChecker';

class ViewSparePartTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToNew: false,
            redirectToEdit: false,
            ticketSubmitted: false,
            hasToBeUpdated: false,
            storedTicketData: {},
            newInitData: {},
            fetchedNewTicketData: false,
            redirectToEditImmediately: false
        };
    }

    componentDidMount() {
        if (!this.props.location.redirectedAfterUpdate) {
            this.setState({...this.state, fetchedNewTicketData: true});
            this.props.fetchSparePartsOrdersTicket(this.props.match.params.ticketUuid);
        }
    }

    componentWillUnmount() {
        this.props.resetTicketOrder();
        this.props.resetRedirectAfterLockTicket();
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const {userDetail, ticketData} = this.props;

        switch (submitBy) {
            case FORM_BUTTON_SWITCH_TO_PVI:
                let newData = JSON.parse(JSON.stringify(values));
                const selectedOrders = newData.newValues.ordersSection.orderItems.filter(order => order.transferToVor).map(order => {
                    const {transferToVor, ...rest} = order;
                    return {...rest}
                });
                if (selectedOrders.length > 0) {
                    newData.newValues.ordersSection.orderItems = selectedOrders;
                    if (values.oldValues.ticketSection.status !== ORDER_TICKET_STATUS_CLOSED) {
                        this.props.lockSparePartsOrdersTicket(this.props.match.params.ticketUuid);
                        this.setState({hasToBeUpdated: true, storedTicketData: transformUpdateTicketForSave(newData)});
                    }
                    this.setState({
                        ticketSubmitted: true,
                        redirectToNew: true,
                        newInitData: initAllocationReqToVorPviTicket(values, userDetail),
                    });
                } else {
                    alert('Please select atleast one order to proceed!');
                }
                break;
            case FORM_BUTTON_REOPEN:
                this.props.lockSparePartsOrdersTicket(this.props.match.params.ticketUuid);
                this.setState({
                    redirectToEdit: true,
                    ticketSubmitted: true,
                    storedTicketData: transformUpdateTicketForSave(values)
                });
                break;
            case FORM_BUTTON_TAKE_BACK:
            case FORM_BUTTON_SG_TAKE_BACK:
                this.props.lockSparePartsOrdersTicket(this.props.match.params.ticketUuid);
                this.setState({
                    redirectToEdit: true,
                    ticketSubmitted: true,
                    storedTicketData: transformUpdateTicketForSave(values)
                });
                break;
            case FORM_BUTTON_CLOSE:
                this.props.lockSparePartsOrdersTicket(this.props.match.params.ticketUuid);
                this.setState({ticketSubmitted: true, storedTicketData: transformUpdateTicketForSave(values)});
                break;
            case FORM_BUTTON_EDIT:
                this.setState({redirectToEditImmediately: true, redirectToTicket: ticketData.ticket.uuid});
                this.props.resetTicketOrder();
                break;
        }
    };

    render() {
        const {
            ticketData,
            loggedUser,
        } = this.props;

        let formType = false;
        if (get(ticketData, 'ticket.orderType', {default: false})) {
            formType = ticketData.ticket.orderType;
        }

        if (!this.props.lockTicket.isTicketLocking && !this.props.lockTicket.redirect && this.state.redirectToNew && this.state.ticketSubmitted) {
            if (this.state.hasToBeUpdated) {
                this.props.updateTicketOrder(this.state.storedTicketData);
            }
            return <Redirect to={{
                pathname: ORDERS_NEW_VOR_PVI_FORM_PATH,
                redirectAfterAllocationReqSwitchToVor: true,
                initData: this.state.newInitData,
            }}/>;
        } else if (!this.props.lockTicket.isTicketLocking && !this.props.lockTicket.redirect && this.state.ticketSubmitted && this.state.redirectToEdit) {
            this.props.updateTicketOrder(this.state.storedTicketData);
            return <Redirect to={{
                pathname: `/order/edit/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        } else if (this.state.redirectToEditImmediately) {
            return <Redirect to={{
                pathname: `/order/edit/${this.state.redirectToTicket}`,
            }}/>;
        } else if (!this.props.lockTicket.isTicketLocking && !this.props.lockTicket.redirect && this.state.ticketSubmitted) {
            this.props.updateTicketOrder(this.state.storedTicketData);
            this.setState({ticketSubmitted: false, storedTicketData: {}, hasToBeUpdated: false});
        }

        const initializationValue = initLoadedTicket(ticketData);
        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !formType
        ) {
            return <Loader/>;
        }

        if ( this.props.location.redirectedFromInternal && !ticketData.isTicketLoading && this.state.fetchedNewTicketData
            && get(ticketData, 'solutionGroupInfo.canForwardToDealer', false) && ticketData.status !== ORDER_TICKET_STATUS_CLOSED) {
            return <Redirect to={`/order/edit/${ticketData.ticket.uuid}`}/>;
        }

        const isEditor = moduleRoles.isEditorOrder(loggedUser) || moduleRoles.isSGEditorOrder(loggedUser);
        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>
                    </>}/>
                <div>
                    {formType === DELIVERY_DATE_REMINDER_FORM &&
                    <DeliveryDateReminderMasterForm form={formType}
                                                    initialValues={initializationValue}
                                                    userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                    enableReinitialize={true}
                                                    reqFields={[]}
                                                    readOnly
                                                    handleSubmit={this.handleSubmit}/>
                    }
                    {formType === ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM &&
                    <PartNoLongerAvailableMasterForm form={formType}
                                                     initialValues={initializationValue}
                                                     userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                     enableReinitialize={true}
                                                     reqFields={[]}
                                                     readOnly
                                                     handleSubmit={this.handleSubmit}/>
                    }
                    {formType === ORDER_CANCELLATION_FORM &&
                    <OrderCancellationMasterForm form={formType}
                                                 initialValues={initializationValue}
                                                 userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                 enableReinitialize={true}
                                                 reqFields={[]}
                                                 readOnly
                                                 handleSubmit={this.handleSubmit}/>
                    }
                    {formType === VOR_PVI_ORDER_FORM &&
                    <VorPviOrderMasterForm form={formType}
                                           initialValues={initializationValue}
                                           userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                           enableReinitialize={true}
                                           reqFields={[]}
                                           readOnly
                                           handleSubmit={this.handleSubmit}/>
                    }
                    {formType === ALLOCATION_REQUEST_FORM &&
                    <AllocationRequestMasterForm form={formType}
                                                 initialValues={initializationValue}
                                                 userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                 enableReinitialize={true}
                                                 reqFields={[]}
                                                 readOnly
                                                 handleSubmit={this.handleSubmit}/>
                    }
                    {formType === EV_BATTERY_FORM &&
                    <EVBatteryMasterForm form={formType}
                                         initialValues={initializationValue}
                                         userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                         enableReinitialize={true}
                                         reqFields={[]}
                                         readOnly
                                         handleSubmit={this.handleSubmit}/>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.sparePartsOrderTicket,
    lockTicket: state.sparePartsOrderLockTicketCheck,
    userDetail: state.profile.userDetail,
});

ViewSparePartTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
    fetchSparePartsOrdersTicket: PropTypes.func.isRequired,
    resetTicketOrder: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    updateTicketOrder: PropTypes.func.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchSparePartsOrdersTicket,
    clearAlerts,
    lockSparePartsOrdersTicket,
    resetRedirectAfterLockTicket,
    resetTicketOrder,
    updateTicketOrder,
})(injectIntl(ViewSparePartTicketPage))),['SP_DEALER', 'SP_EDITOR', 'SP_SG_EDITOR']);

