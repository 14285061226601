import axios from 'axios';
import {axiosFileProgressInstance} from './rest';

export async function loadFile(url) {
    return axiosFileProgressInstance.get(url);
}

export const uploadRecurrentProblemFile = (url, fileUrl, contentType) => {
    const config = {
        headers: {'Content-Type': contentType}
    };
    //First download blob from the local url and then pass it to the axios
    return fetch(fileUrl).then(r => r.blob()).then(response => {
        return axios.create().put(url, response, config);
    });
};
