import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.genericTicket.flow.title',
        defaultMessage: 'Generic Tickets - Flow'
    },
    TABLE_NAME: {
        id: 'admin.genericTicket.flow.table.name',
        defaultMessage: 'Name'
    },
    TABLE_SOLUTION_GROUPS: {
        id: 'admin.genericTicket.flow.table.solutionGroups',
        defaultMessage: 'Solution Groups'
    },
    NAME: {
        id: 'admin.genericTicket.flow.name',
        defaultMessage: 'Name'
    },
    SOLUTION_GROUPS: {
        id: 'admin.genericTicket.flow.solutionGroups',
        defaultMessage: 'Solution Groups'
    },
    SOLUTION_PREVIEW: {
        id: 'admin.genericTicket.flow.preview',
        defaultMessage: 'Preview'
    },
    DETAIL_TITLE: {
        id: 'admin.genericTicket.flow.detail.title',
        defaultMessage: 'Generic Tickets - Flow - Detail'
    },
});

export default messages;
