import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import 'react-table/react-table.css';
import flowMessages from '../../../intl/admin/flowMessages';
import Table from '../../common/table/Table';
import {IconDelete, IconEdit, PrimaryIcon} from '../../common/Button';
import {DETAIL_PATH} from '../../../routes/paths';
import {flowReducer} from '../../../reducers/flowReducer';

const FlowTable = ({data, handleDeleteClick}) => {
    return (
        <Table
            columns={[
                {
                    id: 'name',
                    accessor: 'name',
                    message: flowMessages.TABLE_NAME
                },
                {
                    id: 'solutionGroups',
                    accessor: row => row.items
                        .map(solutionGroup => solutionGroup.name)
                        .toString()
                        .replace(/,/g, ', '),
                    message: flowMessages.TABLE_SOLUTION_GROUPS
                }
            ]}
            toolButtons={[
                {
                    path: {base: DETAIL_PATH, rowField: 'id'},
                    component: <PrimaryIcon className="btn btn-sm mr-sm-1" title="Edit" type="button" key="editButton">
                        <IconEdit/>
                    </PrimaryIcon>
                },
                {
                    handleClick: handleDeleteClick,
                    component: <PrimaryIcon className="btn btn-sm" title="Delete" type="button" key="deleteButton">
                        <IconDelete/>
                    </PrimaryIcon>
                }
            ]}
            data={data.sort((flow1, flow2) => flow1.name.localeCompare(flow2.name))}
            defaultSorting={[{id: 'name'}]}
        />
    );
};

FlowTable.propTypes = {
    data: PropTypes.array.isRequired,
    handleDeleteClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    data: state.flowReducer.listData
});

export default connect(mapStateToProps, {})(FlowTable);
