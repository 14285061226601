import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {clearAlerts} from '../../../actions/alertsActions';
import get from 'get-value';
import Loader from '../../Loader';
import {
    CODED_PARTS_KEYS_FORM,
    DOCUMENTATION_TICKET_STATUS_CLOSED,
    FORM_BUTTON_EDIT,
    FORM_BUTTON_REOPEN, FORM_BUTTON_SG_TAKE_BACK,
    OVAL_PLATE_ORDER_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM,
    UNKNOWN_PART_OPENING_REQUEST_FORM,
} from '../../../constants/formConstants';
import {Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {moduleRoles} from '../../../utils/roles';
import {formatTicketNumber} from '../../../utils/utils';
import {editRights} from '../../../constants/techDocTicketRights';
import UnknownPartOpeningMasterForm from './forms/UnknownPartOpeningMasterForm';
import OvalPlateOrderMasterForm from './forms/OvalPlateOrderMasterForm';
import CodedPartsMasterForm from './forms/CodedPartsMasterForm';
import NotFoundRefRequestMasterForm from './forms/NotFoundRefRequestMasterForm';
import NotConformRefRequestMasterForm from './forms/NotConformRefRequestMasterForm';
import {
    fetchTechDocTicket,
    resetTechDocTicket,
    updateTechDocTicket
} from '../../../actions/techDoc/actionTechDocTicket';
import {initLoadedTechDocTicket, transformUpdateTechDocTicketForSave} from '../Utils';
import formMessages from '../../../intl/technicalDocumentations/formMessages';
import RequestForSupportMasterForm from './forms/RequestForSupportMasterForm';
import {lockTechDocTicket, resetRedirectAfterTechDocLockTicket} from '../../../actions/techDoc/actionTechDocLockTicket';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import checkRoles from '../../common/roleChecker/RoleChecker';

class ViewTechDocTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToEdit: false,
            ticketSubmitted: false,
            storedTicketData: {},
            fetchedNewTicketData: false,
            redirectToEditImmediately: false
        };
    }

    componentDidMount() {
        if (!this.props.location.redirectedAfterUpdate) {
            this.setState({...this.state, fetchedNewTicketData: true});
            this.props.fetchTechDocTicket(this.props.match.params.ticketUuid);
        }
    }

    componentWillUnmount() {
        this.props.resetTechDocTicket();
        this.props.resetRedirectAfterTechDocLockTicket();
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const {ticketData} = this.props;

        if (submitBy === FORM_BUTTON_REOPEN) {
            this.props.lockTechDocTicket(this.props.match.params.ticketUuid);
            this.setState({redirectToEdit: true, ticketSubmitted: true, storedTicketData: transformUpdateTechDocTicketForSave(values)});
        }
        if (submitBy === FORM_BUTTON_EDIT) {
            this.setState({redirectToEditImmediately: true, redirectToTicket: ticketData.ticket.uuid});
            this.props.resetTechDocTicket();
        }
        if (submitBy === FORM_BUTTON_SG_TAKE_BACK) {
            this.props.lockTechDocTicket(this.props.match.params.ticketUuid);
            this.setState({redirectToEdit: true, ticketSubmitted: true, storedTicketData: transformUpdateTechDocTicketForSave(values)});
        }

    };

    render() {
        const {
            ticketData,
            loggedUser,
        } = this.props;

        let formType = false;
        if (get(ticketData, 'ticket.docType', {default: false})) {
            formType = ticketData.ticket.docType;
        }

        if (!this.props.lockTicket.isTicketLocking && !this.props.lockTicket.redirect && this.state.ticketSubmitted && this.state.redirectToEdit) {
            this.props.updateTechDocTicket(this.state.storedTicketData);
            return <Redirect to={{
                pathname: `/documentation/edit/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        } else if (this.state.redirectToEditImmediately) {
            return <Redirect to={{
                pathname: `/documentation/edit/${this.state.redirectToTicket}`,
            }}/>;
        } else if (!this.props.lockTicket.isTicketLocking && !this.props.lockTicket.redirect && this.state.ticketSubmitted) {
            this.props.updateTechDocTicket(this.state.storedTicketData);
            this.setState({ticketSubmitted: false, storedTicketData: {}});
        }

        const initializationValue = initLoadedTechDocTicket(ticketData);
        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !formType
        ) {
            return <Loader/>;
        }

        if ( this.props.location.redirectedFromInternal && !ticketData.isTicketLoading && this.state.fetchedNewTicketData
            && get(ticketData, 'solutionGroupInfo.canForwardToDealer', false) && ticketData.status !== DOCUMENTATION_TICKET_STATUS_CLOSED) {
            return <Redirect to={`/documentation/edit/${ticketData.ticket.uuid}`}/>;
        }



        const isEditor = moduleRoles.isEditorDocumentation(loggedUser) || moduleRoles.isSGEditorDocumentation(loggedUser);
        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {formType === UNKNOWN_PART_OPENING_REQUEST_FORM &&
                    <UnknownPartOpeningMasterForm form={formType}
                                                  initialValues={initializationValue}
                                                  userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                  userRoles={this.props.loggedUser}
                                                  enableReinitialize={true}
                                                  handleSubmit={this.handleSubmit}
                                                  reqFields={[]}
                                                  readOnly/>
                    }
                    {formType === OVAL_PLATE_ORDER_FORM &&
                    <OvalPlateOrderMasterForm form={formType}
                                              initialValues={initializationValue}
                                              userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                              userRoles={this.props.loggedUser}
                                              enableReinitialize={true}
                                              handleSubmit={this.handleSubmit}
                                              reqFields={[]}
                                              readOnly/>
                    }
                    {formType === CODED_PARTS_KEYS_FORM &&
                    <CodedPartsMasterForm form={formType}
                                          initialValues={initializationValue}
                                          userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                          userRoles={this.props.loggedUser}
                                          enableReinitialize={true}
                                          handleSubmit={this.handleSubmit}
                                          reqFields={[]}
                                          readOnly/>
                    }
                    {formType === SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM &&
                    <NotFoundRefRequestMasterForm form={formType}
                                                  initialValues={initializationValue}
                                                  userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                  userRoles={this.props.loggedUser}
                                                  enableReinitialize={true}
                                                  handleSubmit={this.handleSubmit}
                                                  reqFields={[]}
                                                  readOnly/>
                    }
                    {formType === SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM &&
                    <NotConformRefRequestMasterForm form={formType}
                                                    initialValues={initializationValue}
                                                    userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                    userRoles={this.props.loggedUser}
                                                    enableReinitialize={true}
                                                    handleSubmit={this.handleSubmit}
                                                    reqFields={[]}
                                                    readOnly/>
                    }
                    {formType === SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM &&
                    <RequestForSupportMasterForm form={formType}
                                                 initialValues={initializationValue}
                                                 userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                 userRoles={this.props.loggedUser}
                                                 enableReinitialize={true}
                                                 handleSubmit={this.handleSubmit}
                                                 reqFields={[]}
                                                 readOnly/>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.techDocTicket,
    lockTicket: state.techDocLockTicketCheck,
});

ViewTechDocTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
    fetchTechDocTicket: PropTypes.func.isRequired,
    resetTechDocTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchTechDocTicket,
    resetTechDocTicket,
    lockTechDocTicket,
    updateTechDocTicket,
    resetRedirectAfterTechDocLockTicket,
    clearAlerts,
})(injectIntl(ViewTechDocTicketPage))), ['TD_DEALER', 'TD_EDITOR', 'TD_SG_EDITOR']);

