import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import Select from 'react-select';
import {BoldPrimaryButton, PrimaryButton, SecondaryButton} from '../../common/Button';
import PropTypes from 'prop-types';
import authManagementMessages from "../../../intl/admin/authManagementMessages";
import buttonMessages from "../../../intl/common/buttonMessages";

const Label = styled.label`
    font-weight: bold;
`;

const SelectDefaultDealerRole = (props) => {
    const {
        defaultDealerRole, intl: {formatMessage}, selectedOptions, rolesChanged, handleFormTypeSelectionChange,
        handleAllFormTypesClick, handleSaveClick, handleCancelClick
    } = props;

    const options = Object.keys(defaultDealerRole || {})
        .map(formType => ({
            value: formType,
            label: formTypeMessages[formType] ? formatMessage(formTypeMessages[formType]) : formType
        }));

    const formattedSelectedOptions = (selectedOptions || []).map(formType => ({
        value: formType.value,
        label: formTypeMessages[formType.value] ? formatMessage(formTypeMessages[formType.value]) : formType.value
    })).sort((option1, option2) => option1.value.localeCompare(option2.value));

    return (
        <div className="form-group col-xl-12 col-lg-12 col-md-12 pl-0 pr-0">
            <div className="row mb-sm-2">
                <div className="col-sm-12">
                    <Label>
                        <FormattedMessage {...authManagementMessages.DEALER_SELECT_FORM_TYPES}/>
                    </Label>
                    <PrimaryButton type="button" className="btn ml-sm-3" onClick={handleAllFormTypesClick}>
                        <FormattedMessage {...buttonMessages.ALL}/>
                    </PrimaryButton>
                </div>
            </div>
            <div className="row mb-sm-3">
                <div className="col">
                    <Select isMulti isSearchable value={formattedSelectedOptions} options={options}
                            placeholder={formatMessage(authManagementMessages.DEALER_PLACEHOLDER_SELECT_FORM_TYPES)}
                            onChange={handleFormTypeSelectionChange}/>
                </div>
            </div>
            <div className="row mb-sm-3">
                <div className="col-sm-6">
                    <SecondaryButton type="button" className="btn float-right"
                                     onClick={handleCancelClick}
                                     disabled={!rolesChanged}>
                        <FormattedMessage {...buttonMessages.CANCEL}/>
                    </SecondaryButton>
                </div>
                <div className="col-sm-6">
                    <BoldPrimaryButton type="button" className="btn float-left"
                                       disabled={!rolesChanged}
                                       onClick={handleSaveClick}>
                        <FormattedMessage {...buttonMessages.SAVE}/>
                    </BoldPrimaryButton>
                </div>
            </div>
        </div>
    );
};

SelectDefaultDealerRole.propTypes = {
    defaultDealerRole: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    selectedOptions: PropTypes.array,
    handleFormTypeSelectionChange: PropTypes.func.isRequired,
    handleAllFormTypesClick: PropTypes.func.isRequired,
    handleSaveClick: PropTypes.func.isRequired,
    handleCancelClick: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    defaultDealerRole: state.adminDefaultDealerRole.defaultDealerRole,
    selectedOptions: state.adminDefaultDealerRole.selectedDefaultDealerRoles,
    rolesChanged: state.adminDefaultDealerRole.defaultDealerRolesChanged,
});

export default connect(mapStateToProps, {})(injectIntl(SelectDefaultDealerRole))
