import React, {Component} from 'react';
import {connect} from 'react-redux';
import Loader from '../../Loader';
import NotificationForm from './NotificationForm';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {fetchNotificationData, updateNotificationData} from "../../../actions/admin/actionAdminNotification";

const Wrapper = styled.div`
  margin-top: 30px;
`;

class NotificationContainer extends Component {
    componentDidMount() {
        this.props.fetchNotificationData(this.props.domain, this.props.group);
    }

    handleSubmit = ({subject = '', body = ''}) => {
        const {domain, group} = this.props;
        this.props.updateNotificationData(domain, group, {
            subject, body
        });
    };

    render() {
        const {isLoading, group, data, domain} = this.props;

        if (isLoading) {
            return <Loader/>;
        }

        return (
            <Wrapper classname="container-fluid">
                <NotificationForm onSubmit={this.handleSubmit} initialValues={data}
                                  enableReinitialize={true}
                                  form={`notificationForm${domain}_${group}`}/>
            </Wrapper>
        );
    }
}

NotificationContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired,
    updateNotificationData: PropTypes.func.isRequired,
    fetchNotificationData: PropTypes.func.isRequired,
    group: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminNotification.isLoading,
    data: state.adminNotification.emailData,
});

export default connect(mapStateToProps, {
        fetchNotificationData,
        updateNotificationData,
    }
)(NotificationContainer);
