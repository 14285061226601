import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    PACKAGING_ERROR: {
        id: 'spareParts.storeCheckResultsRootCause.option.packagingError',
        defaultMessage: 'Packaging error'
    },
    CONFORM_PART: {
        id: 'spareParts.storeCheckResultsRootCause.option.conformPart',
        defaultMessage: 'Conform part'
    },
    DLU_OVERDUE: {
        id: 'spareParts.storeCheckResultsRootCause.option.dluOverdue',
        defaultMessage: 'DLU overdue'
    },
    ORDER_ERROR: {
        id: 'spareParts.storeCheckResultsRootCause.option.orderError',
        defaultMessage: 'Order error'
    },
    WRONG_PART_SERVED: {
        id: 'spareParts.storeCheckResultsRootCause.option.wrongPartServed',
        defaultMessage: 'Wrong part served'
    },
    MELTED_REFERENCES: {
        id: 'spareParts.storeCheckResultsRootCause.option.meltedReferences',
        defaultMessage: 'Melted references'
    },
    WRONG_LABELLING: {
        id: 'spareParts.storeCheckResultsRootCause.option.wrongLabelling',
        defaultMessage: 'Wrong labelling'
    },
    DAMAGED_PART: {
        id: 'spareParts.storeCheckResultsRootCause.option.damagedPart',
        defaultMessage: 'Damaged part'
    },
    NOT_CONFORM_PART: {
        id: 'spareParts.storeCheckResultsRootCause.option.notConformPart',
        defaultMessage: 'Not conform part'
    }
});

export default messages;
