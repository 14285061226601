import {axiosInstance} from '../rest';

export const getDicNumber = async (ticketNumber) => {
    try {
        return await axiosInstance.get(`${process.env.REACT_APP_BE_HTTP_URL}/spare-parts/orders/dic?ticket_number=${ticketNumber}`)
    } catch (error) {
        console.log(error)
        throw { data: { dicNumber: "" }}
    }
};
