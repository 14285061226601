import React from 'react';
import {required, validInput} from "./validationCommons";
import {Field} from "redux-form";
import {
    DEFAULT_NOTE_FIELD_MAX_LENGTH,
} from "../../constants/validationConstants";
import {TextareaWithValidation} from "./TextareaWithValidation";
import {RequiredSpan} from "./StyledComponents";
import {FormattedMessage} from 'react-intl';
import ImgTooltip from './ImgTooltip';

export const TextareaFieldWithValidation = (props) => {
    const {label, field, rows, isRequired, maxLength, isDisabled, placeholder, boldTooltip} = props;
    let validations = [validInput];
    if (isRequired) {
        validations.push(required);
    }
    return (
        <div>
            {!!label && label instanceof Object &&
            <div className="row m-0">
                <label className="col-form-label pl-0 font-weight-bold">
                    <FormattedMessage {...label}/>
                    {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
                </label>
                {!!boldTooltip && <div className="p-1">
                    <ImgTooltip/>
                </div>}
            </div>}
            <Field
                name={field} component={TextareaWithValidation} type="text"
                placeholder={placeholder}
                maxLength={maxLength ? maxLength : DEFAULT_NOTE_FIELD_MAX_LENGTH}
                validate={validations}
                rows={rows}
                isDisabled={isDisabled}
            />
        </div>
    )
};



