import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const DOC_POPUP_FETCH_COMMAND = 'DOC_POPUP_FETCH_COMMAND';
export const DOC_POPUP_MODAL_OPEN = 'DOC_POPUP_MODAL_OPEN';
export const DOC_POPUP_MODAL_CONFIRM = 'DOC_POPUP_MODAL_CONFIRM';

export const openDocPopUpModal = () => (dispatch) => {
    dispatch({
        type: DOC_POPUP_MODAL_OPEN,
    });
};

export const confirmDocPopUpModal = () => (dispatch) => {
    dispatch({
        type: DOC_POPUP_MODAL_CONFIRM
    });
};

export const fetchDocPopUp = (group) => dispatch => {
    return dispatch({
        type: DOC_POPUP_FETCH_COMMAND,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_POPUP_GET,
        payload: {group}
    });
};
