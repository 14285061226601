import {
    CHECKBOX_LABEL_BVM_CONNECTION,
    FORM_CHECKBOX_BVM_CONNECTION,
    FORM_CHECKBOX_CLAIM_RELATED,
    FORM_CHECKBOX_DIC,
    FORM_CHECKBOX_PVI_RELATED,
    FORM_CHECKBOX_TO_VOR,
    FORM_CLEAR_ATTACHMENTS,
    FORM_FIELD_AGREEMENT,
    FORM_FIELD_ATTACHMENTS,
    FORM_FIELD_AUTHORITY_GIVEN,
    FORM_FIELD_AUTHORITY_NUMBER, FORM_FIELD_BATTERY_PROBLEM_TYPE, FORM_FIELD_BIN,
    FORM_FIELD_AVAILABILITY_DATE,
    FORM_FIELD_CAR_LOCATION,
    FORM_FIELD_CAR_MODEL,
    FORM_FIELD_CLIENT_ID,
    FORM_FIELD_CODE,
    FORM_FIELD_COURTESY_CAR_TYPE,
    FORM_FIELD_COURTESY_REPLY_REMARKS,
    FORM_FIELD_CUSTOMER_NAME,
    FORM_FIELD_DAILY_RATE, FORM_FIELD_DEALER_ADDRESS,
    FORM_FIELD_DEALER_NAME,
    FORM_FIELD_DEALER_NO, FORM_FIELD_DELIVERY_CONTACT_NAME,
    FORM_FIELD_DELIVERY_DATE,
    FORM_FIELD_DESCRIPTION,
    FORM_FIELD_DESTINATION_CODE,
    FORM_FIELD_DIC_NUMBER,
    FORM_FIELD_EDITOR_ORDER,
    FORM_FIELD_EMAIL, FORM_FIELD_FIC_CUR_NUMBER,
    FORM_FIELD_FROM, FORM_FIELD_INVOICE,
    FORM_FIELD_ENGINE_SERIAL_NUMBER,
    FORM_FIELD_ENGINE_TYPE,
    FORM_FIELD_GEARBOX_SERIAL_NUMBER,
    FORM_FIELD_GEARBOX_TYPE,
    FORM_FIELD_LIC_IAN,
    FORM_FIELD_LIC_LIG,
    FORM_FIELD_LIC_OAN,
    FORM_FIELD_LIC_ORD,
    FORM_FIELD_LIC_PROD,
    FORM_FIELD_LINE,
    FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_MANUFACTURE_DATE, FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
    FORM_FIELD_NAME,
    FORM_FIELD_NAME_TD_SEARCH,
    FORM_FIELD_NEW_ATTACHMENTS,
    FORM_FIELD_NOTE,
    FORM_FIELD_ORDER,
    FORM_FIELD_PART_NO, FORM_FIELD_PARTNER_NAME,
    FORM_FIELD_PFX, FORM_FIELD_PRIORITY_TREATMENT,
    FORM_FIELD_PROD,
    FORM_FIELD_QUANTITY,
    FORM_FIELD_REGION,
    FORM_FIELD_REGISTRATION_NO,
    FORM_FIELD_REIMBURSEMENT,
    FORM_FIELD_REMARKS,
    FORM_FIELD_REPAIR_ORDER_NO,
    FORM_FIELD_REPLY, FORM_FIELD_REQUEST_FOR_PARTNER, FORM_FIELD_SEND_HAND_BATTERY_TYPE,
    FORM_FIELD_SFX, FORM_FIELD_SOLUTION_GROUP,
    FORM_FIELD_STEERING_WHEEL,
    FORM_FIELD_SUBJECT_TO_VOR,
    FORM_FIELD_SUPPLEMENTARY_INFORMATION,
    FORM_FIELD_TELEPHONE_NO,
    FORM_FIELD_TICKET_NUMBER, FORM_FIELD_TYPE_OF_REQUEST,
    FORM_FIELD_UNTIL,
    FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
    FORM_FIELD_VEHICLE_MODEL,
    FORM_FIELD_VEHICLE_TYPE,
    FORM_FIELD_VIN,
    FORM_FIELDS_ORDER_ITEMS,
    FORM_LABEL_AGREEMENT,
    FORM_LABEL_ATTACHMENTS,
    FORM_LABEL_AUTHORITY_GIVEN,
    FORM_LABEL_AUTHORITY_NUMBER, FORM_LABEL_BATTERY_TYPE, FORM_LABEL_BIN,
    FORM_LABEL_AVAILABILITY_DATE,
    FORM_LABEL_CAR_LOCATION, FORM_LABEL_CLAIM_PROBLEM_TYPE,
    FORM_LABEL_CLAIM_RELATED,
    FORM_LABEL_CLIENT_ID,
    FORM_LABEL_CODE,
    FORM_LABEL_COURTESY_CAR_TYPE,
    FORM_LABEL_COURTESY_REPLY_REMARKS,
    FORM_LABEL_CUSTOMER_NAME,
    FORM_LABEL_DAILY_RATE, FORM_LABEL_DEALER_ADDRESS,
    FORM_LABEL_DEALER_NAME,
    FORM_LABEL_DEALER_NO, FORM_LABEL_DELIVERY_CONTACT_NAME,
    FORM_LABEL_DELIVERY_DATE,
    FORM_LABEL_DESCRIPTION,
    FORM_LABEL_DESTINATION_CODE,
    FORM_LABEL_DIC_NUMBER,
    FORM_LABEL_DIC_TRANSFER,
    FORM_LABEL_EDITOR_ORDER,
    FORM_LABEL_EMAIL, FORM_LABEL_FIC_CUR_NUMBER,
    FORM_LABEL_FROM, FORM_LABEL_INVOICE_NUMBER,
    FORM_LABEL_ENGINE_SERIAL_NUMBER,
    FORM_LABEL_ENGINE_TYPE,
    FORM_LABEL_GEARBOX_SERIAL_NUMBER,
    FORM_LABEL_GEARBOX_TYPE,
    FORM_LABEL_LIC_IAN,
    FORM_LABEL_LIC_LIG,
    FORM_LABEL_LIC_OAN,
    FORM_LABEL_LIC_ORD,
    FORM_LABEL_LIC_PROD,
    FORM_LABEL_LINE,
    FORM_LABEL_MANAGEMENT_GROUP,
    FORM_LABEL_MANUFACTURE_DATE, FORM_LABEL_N_REFERENCE_CAUSING_PROBLEM,
    FORM_LABEL_NAME,
    FORM_LABEL_NEW_ATTACHMENTS,
    FORM_LABEL_NOTE,
    FORM_LABEL_ORDER,
    FORM_LABEL_ORDER_ITEMS,
    FORM_LABEL_PART_NO, FORM_LABEL_PARTNER_NAME, FORM_LABEL_PRIORITY_TREATMENT, FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_PROD,
    FORM_LABEL_PVI_RELATED,
    FORM_LABEL_QUANTITY,
    FORM_LABEL_REGION,
    FORM_LABEL_REGISTRATION_NO,
    FORM_LABEL_REIMBURSEMENT,
    FORM_LABEL_REMARKS,
    FORM_LABEL_REPAIR_ORDER_NO,
    FORM_LABEL_REPLY, FORM_LABEL_REQUEST_FOR_PARTNER, FORM_LABEL_SOLUTION_GROUP,
    FORM_LABEL_STEERING_WHEEL,
    FORM_LABEL_SUBJECT_TO_VOR,
    FORM_LABEL_SUPPLEMENTARY_INFORMATION,
    FORM_LABEL_TELEPHONE_NO,
    FORM_LABEL_TICKET_NUMBER, FORM_LABEL_TYPE_OF_REQUEST,
    FORM_LABEL_UNTIL,
    FORM_LABEL_VEHICLE_DETAIL_TEL_NO,
    FORM_LABEL_VEHICLE_MODEL,
    FORM_LABEL_VEHICLE_TYPE,
    FORM_LABEL_VIN,
    FORM_LABEL_VOR_TRANSFER,
} from './formConstants';
import {ALL_COUNTRIES_VALUE} from './Utils';

export const sparePartsIntlFieldMapping = {
    [FORM_FIELD_LIC_PROD]: FORM_LABEL_LIC_PROD,
    [FORM_FIELD_LIC_ORD]: FORM_LABEL_LIC_ORD,
    [FORM_FIELD_LIC_OAN]: FORM_LABEL_LIC_OAN,
    [FORM_FIELD_LIC_LIG]: FORM_LABEL_LIC_LIG,
    [FORM_FIELD_LIC_IAN]: FORM_LABEL_LIC_IAN,
    [FORM_FIELDS_ORDER_ITEMS]: FORM_LABEL_ORDER_ITEMS,
    [FORM_FIELD_CLIENT_ID]: FORM_LABEL_CLIENT_ID,
    [FORM_FIELD_DEALER_NAME]: FORM_LABEL_DEALER_NAME,
    [FORM_FIELD_DEALER_NO]: FORM_LABEL_DEALER_NO,
    [FORM_FIELD_DESTINATION_CODE]: FORM_LABEL_DESTINATION_CODE,
    [FORM_FIELD_REGION]: FORM_LABEL_REGION,
    [FORM_FIELD_NAME]: FORM_LABEL_NAME,
    [FORM_FIELD_NAME_TD_SEARCH]: FORM_LABEL_NAME,
    [FORM_FIELD_EMAIL]: FORM_LABEL_EMAIL,
    [FORM_FIELD_TELEPHONE_NO]: FORM_LABEL_TELEPHONE_NO,
    [FORM_FIELD_TICKET_NUMBER]: FORM_LABEL_TICKET_NUMBER,
    [FORM_FIELD_NOTE]: FORM_LABEL_NOTE,
    [FORM_FIELD_DIC_NUMBER]: FORM_LABEL_DIC_NUMBER,
    [FORM_CHECKBOX_DIC]: FORM_LABEL_DIC_TRANSFER,
    [FORM_CHECKBOX_TO_VOR]: FORM_LABEL_VOR_TRANSFER,
    [FORM_FIELD_PROD]: FORM_LABEL_PROD,
    [FORM_FIELD_ORDER]: FORM_LABEL_ORDER,
    [FORM_FIELD_LINE]: FORM_LABEL_LINE,
    [FORM_FIELD_PART_NO]: FORM_LABEL_PART_NO,
    [FORM_FIELD_DESCRIPTION]: FORM_LABEL_DESCRIPTION,
    [FORM_FIELD_QUANTITY]: FORM_LABEL_QUANTITY,
    [FORM_FIELD_REMARKS]: FORM_LABEL_REMARKS,
    [FORM_FIELD_CODE]: FORM_LABEL_CODE,
    [FORM_FIELD_REPLY]: FORM_LABEL_REPLY,
    [FORM_FIELD_SUBJECT_TO_VOR]: FORM_LABEL_SUBJECT_TO_VOR,
    [FORM_FIELD_EDITOR_ORDER]: FORM_LABEL_EDITOR_ORDER,                                                   //sdílený
    [FORM_CHECKBOX_PVI_RELATED]: FORM_LABEL_PVI_RELATED,                                                  //sdílený
    [FORM_CHECKBOX_CLAIM_RELATED]: FORM_LABEL_CLAIM_RELATED,                                              //sdílený
    [FORM_FIELD_ATTACHMENTS]: FORM_LABEL_ATTACHMENTS,
    [FORM_FIELD_NEW_ATTACHMENTS]: FORM_LABEL_NEW_ATTACHMENTS,
    [FORM_CLEAR_ATTACHMENTS]: FORM_CLEAR_ATTACHMENTS,
    [FORM_FIELD_VIN]: FORM_LABEL_VIN,
    [FORM_FIELD_DELIVERY_DATE]: FORM_LABEL_DELIVERY_DATE,
    [FORM_FIELD_MANUFACTURE_DATE]: FORM_LABEL_MANUFACTURE_DATE,
    [FORM_FIELD_REGISTRATION_NO]: FORM_LABEL_REGISTRATION_NO,
    [FORM_FIELD_VEHICLE_TYPE]: FORM_LABEL_VEHICLE_TYPE,
    [FORM_FIELD_VEHICLE_MODEL]: FORM_LABEL_VEHICLE_MODEL,
    [FORM_FIELD_CAR_MODEL]: FORM_LABEL_VEHICLE_MODEL,
    [FORM_FIELD_CUSTOMER_NAME]: FORM_LABEL_CUSTOMER_NAME,
    [FORM_FIELD_VEHICLE_DETAIL_TEL_NO]: FORM_LABEL_VEHICLE_DETAIL_TEL_NO,
    [FORM_FIELD_DEALER_ADDRESS]: FORM_LABEL_DEALER_ADDRESS,
    [FORM_FIELD_DELIVERY_CONTACT_NAME]: FORM_LABEL_DELIVERY_CONTACT_NAME,
    [FORM_FIELD_FIC_CUR_NUMBER]: FORM_LABEL_FIC_CUR_NUMBER,
    [FORM_FIELD_BATTERY_PROBLEM_TYPE]: FORM_LABEL_PROBLEM_DESCRIPTION,
    [FORM_FIELD_REPAIR_ORDER_NO]: FORM_LABEL_REPAIR_ORDER_NO,
    [FORM_FIELD_CAR_LOCATION]: FORM_LABEL_CAR_LOCATION,
    [FORM_FIELD_COURTESY_CAR_TYPE]: FORM_LABEL_COURTESY_CAR_TYPE,
    [FORM_FIELD_SUPPLEMENTARY_INFORMATION]: FORM_LABEL_SUPPLEMENTARY_INFORMATION,
    [FORM_FIELD_REIMBURSEMENT]: FORM_LABEL_REIMBURSEMENT,
    [FORM_FIELD_AUTHORITY_GIVEN]: FORM_LABEL_AUTHORITY_GIVEN,
    [FORM_FIELD_AUTHORITY_NUMBER]: FORM_LABEL_AUTHORITY_NUMBER,
    [FORM_FIELD_AGREEMENT]: FORM_LABEL_AGREEMENT,
    [FORM_FIELD_DAILY_RATE]: FORM_LABEL_DAILY_RATE,
    [FORM_FIELD_FROM]: FORM_LABEL_FROM,
    [FORM_FIELD_UNTIL]: FORM_LABEL_UNTIL,
    [FORM_FIELD_COURTESY_REPLY_REMARKS]: FORM_LABEL_COURTESY_REPLY_REMARKS,
    [FORM_CHECKBOX_BVM_CONNECTION]: CHECKBOX_LABEL_BVM_CONNECTION,
    [ALL_COUNTRIES_VALUE]: ALL_COUNTRIES_VALUE,
    [FORM_FIELD_SEND_HAND_BATTERY_TYPE]: FORM_LABEL_BATTERY_TYPE,
    [FORM_FIELD_MANAGEMENT_GROUP]: FORM_LABEL_MANAGEMENT_GROUP,
    [FORM_FIELD_ENGINE_TYPE]: FORM_LABEL_ENGINE_TYPE,
    [FORM_FIELD_ENGINE_SERIAL_NUMBER]: FORM_LABEL_ENGINE_SERIAL_NUMBER,
    [FORM_FIELD_GEARBOX_TYPE]: FORM_LABEL_GEARBOX_TYPE,
    [FORM_FIELD_GEARBOX_SERIAL_NUMBER]: FORM_LABEL_GEARBOX_SERIAL_NUMBER,
    [FORM_FIELD_STEERING_WHEEL]: FORM_LABEL_STEERING_WHEEL,
    [FORM_FIELD_AVAILABILITY_DATE]: FORM_LABEL_AVAILABILITY_DATE,

    //search engine
    [FORM_FIELD_PFX]: FORM_LABEL_TICKET_NUMBER,
    [FORM_FIELD_SFX]: FORM_LABEL_TICKET_NUMBER,
    [FORM_FIELD_BATTERY_PROBLEM_TYPE]: FORM_LABEL_CLAIM_PROBLEM_TYPE,
    [FORM_FIELD_BIN]: FORM_LABEL_BIN,
    [FORM_FIELD_INVOICE]: FORM_LABEL_INVOICE_NUMBER,


    //TODO DBS
    [FORM_FIELD_SOLUTION_GROUP]: FORM_LABEL_SOLUTION_GROUP,
    [FORM_FIELD_REQUEST_FOR_PARTNER]: FORM_LABEL_REQUEST_FOR_PARTNER,
    [FORM_FIELD_TYPE_OF_REQUEST]: FORM_LABEL_TYPE_OF_REQUEST,
    [FORM_FIELD_PRIORITY_TREATMENT]: FORM_LABEL_PRIORITY_TREATMENT,
    [FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM]: FORM_LABEL_N_REFERENCE_CAUSING_PROBLEM,
    [FORM_FIELD_PARTNER_NAME]: FORM_LABEL_PARTNER_NAME,

};
