import {
    ADMIN_DEFAULT_DEALER_ROLE_SELECT_GROUP,
    SELECTED_DEFAULT_DEALER_ROLES,
    INITIAL_DEFAULT_DEALER_ROLES,
    DEFAULT_DEALER_ROLES_CHANGED,
    DEFAULT_DEALER_ROLES_SAVE_PENDING,
    DEFAULT_DEALER_ROLES_FETCH_SUCCESS,
    DEFAULT_ROLES_ERROR
} from '../../actions/admin/actionAdminDefaultCountryRole';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import get from 'get-value';


export const adminDefaultDealerRole = (state = {
    isLoading: false,
    defaultDealerRole: [],
    initialDefaultDealerRoles: [],
    selectedDefaultDealerRoles: null,
    selectedCountryGroup: '',
    defaultDealerRolesChanged: false,
}, action) => {
    switch (action.type) {
        case ADMIN_DEFAULT_DEALER_ROLE_SELECT_GROUP:
            return Object.assign({}, state, {isLoading: true, selectedCountryGroup: action.payload});


        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DEFAULT_DEALER_ROLES_SEND:
        case techDocWebsocketCommands.TECH_DOC_DEFAULT_DEALER_ROLES_SEND:
        case claimsWebsocketCommands.CLAIMS_DEFAULT_DEALER_ROLES_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                defaultDealerRole: get(action, 'payload.dealer.formsPermission', {default: []}),
                correlationId
            });
        }

        case SELECTED_DEFAULT_DEALER_ROLES: {
            return Object.assign({}, state, {selectedDefaultDealerRoles: action.payload})
        }

        case INITIAL_DEFAULT_DEALER_ROLES: {
            return Object.assign({}, state, {initialDefaultDealerRoles: action.payload})
        }

        case DEFAULT_DEALER_ROLES_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                defaultDealerRole: action.payload.finalResult,
                correlationId: action.payload.correlationId
            });
        }

        case DEFAULT_DEALER_ROLES_CHANGED: {
            return Object.assign({}, state, {defaultDealerRolesChanged: action.payload})
        }

        case DEFAULT_DEALER_ROLES_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }

        case DEFAULT_ROLES_ERROR: {
            const {correlationId} = action.payload;
            return Object.assign({}, state, {isLoading: false, correlationId});
        }

        default:
            return state;

    }
};

