import React from 'react';
import {reduxForm} from 'redux-form'
import {BoldPrimaryButton, SecondaryButton} from '../../common/Button';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import notificationMessages from "../../../intl/admin/notificationMessages";
import buttonMessages from "../../../intl/common/buttonMessages";
import {TextareaFieldWithValidation} from "../../common/TextareaFieldWithValidation";
import {InputFieldWithValidation} from "../../common/InputFieldWithValidation";

let NotificationForm = (props) => {
    const {handleSubmit, pristine, reset, submitting} = props;

    return (
        <form className="form-group" onSubmit={handleSubmit}>
            <InputFieldWithValidation label={notificationMessages.SUBJECT}
                                      field="subject"/>
            <TextareaFieldWithValidation label={notificationMessages.MESSAGE}
                                         field="body"
                                         rows="6"/>
            <div className="form-row">
                <div className="col-sm mt-2">
                    <BoldPrimaryButton type="submit"
                                       disabled={pristine || submitting}
                                       className="btn float-right">
                        <FormattedMessage {...buttonMessages.SAVE}/>
                    </BoldPrimaryButton>
                    <SecondaryButton type="button"
                                     disabled={pristine || submitting}
                                     className="btn float-right mr-2"
                                     onClick={reset || submitting}>
                        <FormattedMessage {...buttonMessages.CANCEL}/>
                    </SecondaryButton>
                </div>
            </div>
        </form>
    );
};

NotificationForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired
};

export default reduxForm({})(NotificationForm);
