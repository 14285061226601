import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const CLAIMS_UPDATE_USER_LIST_PREFERENCE = 'CLAIMS_UPDATE_USER_LIST_PREFERENCE';
export const CLAIMS_UPDATE_USER_HISTORY_PREFERENCE = 'CLAIMS_UPDATE_USER_HISTORY_PREFERENCE';
export const CLAIMS_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE = 'CLAIMS_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE';
export const CLAIMS_FETCH_PREFERENCES_PENDING = 'CLAIMS_FETCH_PREFERENCES_PENDING';
export const CLAIMS_UPDATE_USER_STATISTICS_PREFERENCE = 'CLAIMS_UPDATE_USER_STATISTICS_PREFERENCE';

export const getClaimsPreferences = () => dispatch => {
    return dispatch({
        type: CLAIMS_FETCH_PREFERENCES_PENDING,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_PREFERENCE_GET,
    });
};

export const updateClaimsUserListPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: CLAIMS_UPDATE_USER_LIST_PREFERENCE,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_USER_LIST_PREFERENCE_SET,
        payload: {
            preferences: {
                ipn,
                hiddenColumns: preferences
            }
        }
    });
};

export const updateClaimsUserHistoryPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: CLAIMS_UPDATE_USER_HISTORY_PREFERENCE,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_USER_HISTORY_PREFERENCE_SET,
        payload: {
            preferences: {
                ipn,
                hiddenColumns: preferences
            }
        }
    });
};

export const updateClaimsUserFulltextSearchPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: CLAIMS_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_USER_FULL_TEXT_SEARCH_PREFERENCE_SET,
        payload: {
            preferences: {
                ipn,
                hiddenColumns: preferences
            }
        }
    });
};

export const updateClaimsUserStatisticsPreference = (preferences) => dispatch => {
    return dispatch({
        type: CLAIMS_UPDATE_USER_STATISTICS_PREFERENCE,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_USER_STATISTICS_PREFERENCE_SET,
        payload: {preferences: {countries: preferences}}
    });
};
