import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const SPARE_PARTS_ORDERS_MULTI_SOURCING_GROUPS_FETCH_PENDING = 'SPARE_PARTS_ORDERS_MULTI_SOURCING_GROUPS_FETCH_PENDING';

export const fetchSparePartsMultiSourcingGroups = (group) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_ORDERS_MULTI_SOURCING_GROUPS_FETCH_PENDING,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MULTI_SOURCING_GROUPS_GET,
        payload: {
            group
        }
    });
};
