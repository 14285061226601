import {extractErrorText} from './actionUtils';
import {errorAlert} from './alertsActions';
import {
    createSolutionGroup as restPost,
    deleteSolutionGroup as restDelete,
    loadSolutionGroups as restGet,
    saveSolutionGroup as restPut
} from '../api/restSolutionGroup';
import {
    validateSolutionGroupCreateEvent,
    validateSolutionGroups,
    validateSolutionGroupUpdateEvent
} from '../api/validation/solutionGroup';
import alertMessages from '../intl/common/alertMessages';

export const GROUP_LIST_FETCH_PENDING = 'GROUP_LIST_FETCH_PENDING';
export const GROUP_LIST_FETCH_SUCCESS = 'GROUP_LIST_FETCH_SUCCESS';
export const GROUP_LIST_FETCH_ERROR = 'GROUP_LIST_FETCH_ERROR';
export const GROUP_STORING_PENDING = 'GROUP_STORING_PENDING';
export const GROUP_STORING_FINISHED = 'GROUP_STORING_FINISHED';
export const GROUP_DELETE_PENDING = 'GROUP_DELETE_PENDING';
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS';
export const GROUP_DELETE_ERROR = 'GROUP_DELETE_ERROR';
export const GROUP_CREATE_PENDING = 'GROUP_CREATE_PENDING';
export const GROUP_CREATE_SUCCESS = 'GROUP_CREATE_SUCCESS';
export const GROUP_CREATE_ERROR = 'GROUP_CREATE_ERROR';
export const GROUP_SAVE_PENDING = 'GROUP_SAVE_PENDING';
export const GROUP_SAVE_SUCCESS = 'GROUP_SAVE_SUCCESS';
export const GROUP_SAVE_ERROR = 'GROUP_SAVE_ERROR';
export const GROUP_REMOVE = 'GROUP_REMOVE';
export const GROUP_ADD = 'GROUP_ADD';
export const GROUP_EDIT_NAME = 'GROUP_EDIT_NAME';
export const GROUP_EDIT_USERS = 'GROUP_EDIT_USERS';
export const GROUP_RESET = 'GROUP_RESET';
export const GROUP_SAVE_STATE = 'GROUP_SAVE_STATE';

export const fetchSolutionGroupListPageData = () => dispatch => {
    dispatch({type: GROUP_LIST_FETCH_PENDING});

    return restGet().then(
        ({data}) => {
            const {error} = validateSolutionGroups(data);
            if (error) {
                dispatch({type: GROUP_LIST_FETCH_ERROR});
                dispatch(errorAlert(alertMessages.SOLUTION_GROUPS_LIST_RETRIEVED_ERROR, [error.toString()]));
            } else {
                const resultTransformed = {};
                data.forEach(solutionGroup => resultTransformed[solutionGroup.id] = solutionGroup);
                dispatch({type: GROUP_LIST_FETCH_SUCCESS, payload: resultTransformed});
            }

        }, error => {
            dispatch({type: GROUP_LIST_FETCH_ERROR});
            dispatch(errorAlert(...extractErrorText(error, alertMessages.SOLUTION_GROUPS_LIST_FETCH_ERROR)));
        }
    );
};

export const storingChangesStarted = () => dispatch => dispatch({type: GROUP_STORING_PENDING});

export const storingChangesFinished = () => dispatch => dispatch({type: GROUP_STORING_FINISHED});

export const deleteSolutionGroup = solutionGroupId => dispatch => {
    dispatch({type: GROUP_DELETE_PENDING});

    return restDelete(solutionGroupId).then(
        () => {
            dispatch({type: GROUP_DELETE_SUCCESS});
        }, error => {
            dispatch({type: GROUP_DELETE_ERROR});
            dispatch(errorAlert(...extractErrorText(error, alertMessages.SOLUTION_GROUPS_DELETE_ERROR)));
        }
    );
};

export const saveNewSolutionGroup = solutionGroupCreateEvent => dispatch => {
    dispatch({type: GROUP_CREATE_PENDING});

    const {error} = validateSolutionGroupCreateEvent(solutionGroupCreateEvent);
    if (error) {
        dispatch({type: GROUP_CREATE_ERROR});
        dispatch(errorAlert(alertMessages.SOLUTION_GROUPS_NEW_SEND_ERROR, [error.toString()]));
    } else {
        return restPost(solutionGroupCreateEvent).then(
            () => {
                dispatch({type: GROUP_CREATE_SUCCESS});
            }, error => {
                dispatch({type: GROUP_CREATE_ERROR});
                dispatch(errorAlert(...extractErrorText(error, alertMessages.SOLUTION_GROUPS_NEW_SAVE_ERROR)));
            }
        );
    }
};

export const saveExistingSolutionGroup = (solutionGroupId, solutionGroupUpdateEvent) => dispatch => {
    dispatch({type: GROUP_SAVE_PENDING});

    const {error} = validateSolutionGroupUpdateEvent(solutionGroupUpdateEvent);
    if (error) {
        dispatch({type: GROUP_SAVE_ERROR});
        dispatch(errorAlert(alertMessages.SOLUTION_GROUPS_SEND_ERROR, [error.toString()]));
    } else {
        return restPut(solutionGroupId, solutionGroupUpdateEvent).then(
            () => {
                dispatch({type: GROUP_SAVE_SUCCESS});
            }, error => {
                dispatch({type: GROUP_SAVE_ERROR});
                dispatch(errorAlert(...extractErrorText(error, alertMessages.SOLUTION_GROUPS_SAVE_ERROR)));
            }
        );
    }

};

export const removeSolutionGroup = solutionGroupId => dispatch => dispatch({
    type: GROUP_REMOVE,
    payload: solutionGroupId
});

export const addSolutionGroup = solutionGroup => dispatch => dispatch({
    type: GROUP_ADD,
    payload: solutionGroup
});

export const editSolutionGroupName = (solutionGroupId, solutionGroupName) => dispatch => dispatch({
    type: GROUP_EDIT_NAME,
    payload: {id: solutionGroupId, name: solutionGroupName, changedName: solutionGroupName}
});

export const editSolutionGroupUsers = (solutionGroupId, users, addedUser, removedUser, multipleRemovedUsers) => dispatch => dispatch({
    type: GROUP_EDIT_USERS,
    payload: {id: solutionGroupId, users, addedUser, removedUser, multipleRemovedUsers}
});

export const resetSolutionGroupChanges = () => dispatch => dispatch({type: GROUP_RESET});

export const saveListData = listData => dispatch => dispatch({type: GROUP_SAVE_STATE, payload: listData});
