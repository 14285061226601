import {
    ADMIN_SECOND_HAND_BATTERY_TYPE_SELECT_GROUP,
    ADMIN_SECOND_HAND_BATTERY_TYPE_FETCH,
    ADMIN_SECOND_HAND_BATTERY_TYPE_ADD,
    ADMIN_SECOND_HAND_BATTERY_TYPE_EDIT,
    ADMIN_SECOND_HAND_BATTERY_TYPE_UPDATE,
    ADMIN_SECOND_HAND_BATTERY_TYPE_SWITCH,
    ADMIN_SECOND_HAND_BATTERY_TYPE_REMOVE,
} from '../../actions/admin/actionAdminSecondHandBattery';
// import {distributionWebsocketCommands} from '../../constants/distributionWebsocketCommands';
// import {commercialWebsocketCommands} from '../../constants/commercialWebsocketCommands';
// import {marketingWebsocketCommands} from '../../constants/marketingWebsocketCommands';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';
import {move} from '../../utils/utils';
import get from 'get-value';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const adminSecondHandBatteryType = (state = {
    isLoading: false,
    secondHandBatteryTypes: [],
    edit: -1,
    selectedCountryGroup: '',
}, action) => {
    switch (action.type) {
        case ADMIN_SECOND_HAND_BATTERY_TYPE_SELECT_GROUP:
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        case ADMIN_SECOND_HAND_BATTERY_TYPE_FETCH:
            return Object.assign({}, state, {isLoading: true, secondHandBatteryTypes: []});
        case ADMIN_SECOND_HAND_BATTERY_TYPE_ADD:
            return Object.assign({}, state, {isLoading: true});
        case ADMIN_SECOND_HAND_BATTERY_TYPE_EDIT:
            return Object.assign({}, state, {edit: action.payload.index});
        case ADMIN_SECOND_HAND_BATTERY_TYPE_UPDATE:
            return Object.assign({}, state, {isLoading: true, edit: -1});
        case ADMIN_SECOND_HAND_BATTERY_TYPE_SWITCH: {
            let newArray = state.secondHandBatteryTypes.slice();
            move(newArray, action.payload.oldIndex, action.payload.newIndex);
            return Object.assign({}, state, {secondHandBatteryTypes: newArray});
        }
        case ADMIN_SECOND_HAND_BATTERY_TYPE_REMOVE:
            return Object.assign({}, state, {isLoading: true});


        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPES_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                secondHandBatteryTypes: get(action, 'payload.secondHandBatteryTypes', {default: []}),
                correlationId
            });
        }

        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPES_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, secondHandBatteryTypes: [], correlationId});
        }

        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPES_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId});
        }
        default:
            return state;
    }
};
