import React from 'react';
import styled from 'styled-components';
import Tree, {withStyles} from 'react-vertical-tree';
import PropTypes from 'prop-types';
import {PRIMARY_BLACK, PRIMARY_LIGHT_GREY} from '../../../theme/colors';

const SpanArrowUp = styled.span`
    border: solid ${PRIMARY_BLACK};
    border-width: 2px 0 0 2px;
    display: inline-block;
    padding: 3px;
    left: calc( 50% - 4px );
    margin-top: 0;
    position: absolute;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
`;

const DivTreeNode = styled.div`
    border: 0.05em solid ${PRIMARY_BLACK};
    border-radius: 0.3em;
    padding: 1em 1em 1em 1em;
    background-color: ${PRIMARY_LIGHT_GREY};
    margin: 0.2em auto 0.1em auto;
    width: fit-content;
`;

const StyledTree = withStyles(
    {
        lines: {
            color: `${PRIMARY_BLACK}`,
            height: '3em',
        }
    }
)(Tree);

const VerticalTree = ({data = []}) => {
    return (
        <StyledTree data={data} direction
                    render={item =>
                        <React.Fragment>
                            <DivTreeNode>{item.name}</DivTreeNode>
                            {
                                item.children.length > 0 &&
                                <SpanArrowUp/>
                            }
                        </React.Fragment>
                    }/>
    );
};

VerticalTree.propTypes = {
    data: PropTypes.array
};

export default VerticalTree;
