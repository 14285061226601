import {
    ALLOCATION_REQUEST_FORM,
    DELIVERY_DATE_REMINDER_FORM,
    EV_BATTERY_FORM,
    FORM_FIELD_BATTERY_TYPE,
    FORM_FIELD_BATTERY_PROBLEM_TYPE,
    FORM_FIELD_BATTERY_REF_NUMBER,
    FORM_FIELD_BIN,
    FORM_FIELD_CAR_LOCATION,
    FORM_FIELD_CLIENT_ID,
    FORM_FIELD_CODE,
    FORM_FIELD_COUNTRY,
    FORM_FIELD_CUSTOMER_NAME,
    FORM_FIELD_DEALER_ADDRESS,
    FORM_FIELD_DEALER_NAME,
    FORM_FIELD_DELIVERY_CONTACT_NAME,
    FORM_FIELD_DELIVERY_DATE,
    FORM_FIELD_DESCRIPTION,
    FORM_FIELD_DESTINATION_CODE,
    FORM_FIELD_EMAIL,
    FORM_FIELD_EORI_NO,
    FORM_FIELD_FIC_CUR_NUMBER,
    FORM_FIELD_LIC_IAN,
    FORM_FIELD_LIC_LIG,
    FORM_FIELD_LIC_OAN,
    FORM_FIELD_LIC_ORD,
    FORM_FIELD_LIC_PROD,
    FORM_FIELD_LINE,
    FORM_FIELD_ORDER,
    FORM_FIELD_PART_NO,
    FORM_FIELD_QUANTITY,
    FORM_FIELD_REGISTRATION_NO,
    FORM_FIELD_REPAIR_ORDER_NO,
    FORM_FIELD_REPLY,
    FORM_FIELD_TELEPHONE_NO,
    FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
    FORM_FIELD_VEHICLE_TYPE,
    FORM_FIELD_VIN,
    FORM_LABEL_DEALER_NO,
    ORDER_CANCELLATION_FORM,
    ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
    VOR_PVI_ORDER_FORM,
    FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_ENGINE_TYPE,
    FORM_FIELD_ENGINE_SERIAL_NUMBER,
    FORM_FIELD_GEARBOX_TYPE,
    FORM_FIELD_GEARBOX_SERIAL_NUMBER,
    FORM_FIELD_STEERING_WHEEL,
    FORM_FIELD_AVAILABILITY_DATE,
} from './formConstants';

export const editReqFields = {
    'FR': {
        'DEALER': {
            [ALLOCATION_REQUEST_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [ORDER_CANCELLATION_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [VOR_PVI_ORDER_FORM]: [
                FORM_FIELD_PART_NO,
                FORM_FIELD_VIN,
                FORM_FIELD_REPAIR_ORDER_NO,
                FORM_FIELD_CAR_LOCATION,
                FORM_FIELD_QUANTITY,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_DELIVERY_DATE,
                FORM_FIELD_VEHICLE_TYPE,
                FORM_FIELD_CUSTOMER_NAME,
                FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
            ],
            [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [DELIVERY_DATE_REMINDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [EV_BATTERY_FORM]: [
                FORM_FIELD_DEALER_NAME,
                FORM_FIELD_COUNTRY,
                FORM_LABEL_DEALER_NO,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_EMAIL,
            ],
        },
        'EDITOR': {
            [ALLOCATION_REQUEST_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [ORDER_CANCELLATION_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [VOR_PVI_ORDER_FORM]: [
                FORM_FIELD_DESTINATION_CODE,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REPAIR_ORDER_NO,
                FORM_FIELD_LIC_PROD,
                FORM_FIELD_LIC_ORD,
                FORM_FIELD_LIC_OAN,
                FORM_FIELD_LIC_LIG,
                FORM_FIELD_LIC_IAN,
                FORM_FIELD_MANAGEMENT_GROUP,
                FORM_FIELD_QUANTITY,
            ],
            [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REPLY,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [DELIVERY_DATE_REMINDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REPLY,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [EV_BATTERY_FORM]: [
                FORM_FIELD_DEALER_NAME,
                FORM_FIELD_COUNTRY,
                FORM_LABEL_DEALER_NO,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_EMAIL,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
        }
    },
    'GB': {
        'DEALER': {
            [ALLOCATION_REQUEST_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [ORDER_CANCELLATION_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [VOR_PVI_ORDER_FORM]: [
                FORM_FIELD_PART_NO,
                FORM_FIELD_VIN,
                FORM_FIELD_REGISTRATION_NO,
                FORM_FIELD_CAR_LOCATION,
                FORM_FIELD_QUANTITY,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_DELIVERY_DATE,
                FORM_FIELD_VEHICLE_TYPE,
                FORM_FIELD_CUSTOMER_NAME,
                FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
            ],
            [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [DELIVERY_DATE_REMINDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [EV_BATTERY_FORM]: [
                FORM_FIELD_DEALER_NAME,
                FORM_FIELD_COUNTRY,
                FORM_LABEL_DEALER_NO,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_EMAIL,
                FORM_FIELD_EORI_NO,
            ],
        },
        'EDITOR': {
            [ALLOCATION_REQUEST_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [ORDER_CANCELLATION_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [VOR_PVI_ORDER_FORM]: [
                FORM_FIELD_DESTINATION_CODE,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REGISTRATION_NO,
                FORM_FIELD_LIC_PROD,
                FORM_FIELD_LIC_ORD,
                FORM_FIELD_LIC_OAN,
                FORM_FIELD_LIC_LIG,
                FORM_FIELD_LIC_IAN,
                FORM_FIELD_MANAGEMENT_GROUP,
                FORM_FIELD_QUANTITY,
            ],
            [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REPLY,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [DELIVERY_DATE_REMINDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REPLY,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [EV_BATTERY_FORM]: [
                FORM_FIELD_DEALER_NAME,
                FORM_FIELD_COUNTRY,
                FORM_LABEL_DEALER_NO,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_EMAIL,
                FORM_FIELD_EORI_NO,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
        }
    },
    'IE': {
        'DEALER': {
            [ALLOCATION_REQUEST_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [ORDER_CANCELLATION_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [VOR_PVI_ORDER_FORM]: [
                FORM_FIELD_PART_NO,
                FORM_FIELD_VIN,
                FORM_FIELD_REGISTRATION_NO,
                FORM_FIELD_CAR_LOCATION,
                FORM_FIELD_QUANTITY,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_DELIVERY_DATE,
                FORM_FIELD_VEHICLE_TYPE,
                FORM_FIELD_CUSTOMER_NAME,
                FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
            ],
            [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [DELIVERY_DATE_REMINDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [EV_BATTERY_FORM]: [
                FORM_FIELD_DEALER_NAME,
                FORM_FIELD_COUNTRY,
                FORM_LABEL_DEALER_NO,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_EMAIL,
                FORM_FIELD_EORI_NO,
            ],
        },
        'EDITOR': {
            [ALLOCATION_REQUEST_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [ORDER_CANCELLATION_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [VOR_PVI_ORDER_FORM]: [
                FORM_FIELD_DESTINATION_CODE,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REGISTRATION_NO,
                FORM_FIELD_LIC_PROD,
                FORM_FIELD_LIC_ORD,
                FORM_FIELD_LIC_OAN,
                FORM_FIELD_LIC_LIG,
                FORM_FIELD_LIC_IAN,
                FORM_FIELD_MANAGEMENT_GROUP,
                FORM_FIELD_QUANTITY,
            ],
            [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REPLY,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [DELIVERY_DATE_REMINDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REPLY,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [EV_BATTERY_FORM]: [
                FORM_FIELD_DEALER_NAME,
                FORM_FIELD_COUNTRY,
                FORM_LABEL_DEALER_NO,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_EMAIL,
                FORM_FIELD_EORI_NO,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
        }
    },
    'AR': {
        'DEALER': {
            [ALLOCATION_REQUEST_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [ORDER_CANCELLATION_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [VOR_PVI_ORDER_FORM]: [
                FORM_FIELD_PART_NO,
                FORM_FIELD_VIN,
                FORM_FIELD_REGISTRATION_NO,
                FORM_FIELD_CAR_LOCATION,
                FORM_FIELD_QUANTITY,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_DELIVERY_DATE,
                FORM_FIELD_VEHICLE_TYPE,
                FORM_FIELD_CUSTOMER_NAME,
                FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
            ],
            [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [DELIVERY_DATE_REMINDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [EV_BATTERY_FORM]: [
                FORM_FIELD_DEALER_NAME,
                FORM_FIELD_COUNTRY,
                FORM_LABEL_DEALER_NO,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_EMAIL,
            ],
        },
        'EDITOR': {
            [ALLOCATION_REQUEST_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [ORDER_CANCELLATION_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [VOR_PVI_ORDER_FORM]: [
                FORM_FIELD_DESTINATION_CODE,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REGISTRATION_NO,
                FORM_FIELD_LIC_PROD,
                FORM_FIELD_LIC_ORD,
                FORM_FIELD_LIC_OAN,
                FORM_FIELD_LIC_LIG,
                FORM_FIELD_LIC_IAN,
                FORM_FIELD_MANAGEMENT_GROUP,
                FORM_FIELD_QUANTITY,
            ],
            [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REPLY,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [DELIVERY_DATE_REMINDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REPLY,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [EV_BATTERY_FORM]: [
                FORM_FIELD_DEALER_NAME,
                FORM_FIELD_COUNTRY,
                FORM_LABEL_DEALER_NO,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_EMAIL,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
        }
    },
    'DEFAULT': {
        'DEALER': {
            [ALLOCATION_REQUEST_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [ORDER_CANCELLATION_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [VOR_PVI_ORDER_FORM]: [
                FORM_FIELD_PART_NO,
                FORM_FIELD_VIN,
                FORM_FIELD_REGISTRATION_NO,
                FORM_FIELD_CAR_LOCATION,
                FORM_FIELD_QUANTITY,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_DELIVERY_DATE,
                FORM_FIELD_VEHICLE_TYPE,
                FORM_FIELD_CUSTOMER_NAME,
                FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
            ],
            [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [DELIVERY_DATE_REMINDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_ORDER,
                FORM_FIELD_LINE,
                FORM_FIELD_PART_NO,
                FORM_FIELD_DESCRIPTION,
                FORM_FIELD_QUANTITY,
            ],
            [EV_BATTERY_FORM]: [
                FORM_FIELD_DEALER_NAME,
                FORM_FIELD_COUNTRY,
                FORM_LABEL_DEALER_NO,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_EMAIL,
            ],
        },
        'EDITOR': {
            [ALLOCATION_REQUEST_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [ORDER_CANCELLATION_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [VOR_PVI_ORDER_FORM]: [
                FORM_FIELD_DESTINATION_CODE,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REGISTRATION_NO,
                FORM_FIELD_LIC_PROD,
                FORM_FIELD_LIC_ORD,
                FORM_FIELD_LIC_OAN,
                FORM_FIELD_LIC_LIG,
                FORM_FIELD_LIC_IAN,
                FORM_FIELD_MANAGEMENT_GROUP,
                FORM_FIELD_QUANTITY,
            ],
            [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REPLY,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [DELIVERY_DATE_REMINDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_REPLY,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
            [EV_BATTERY_FORM]: [
                FORM_FIELD_DEALER_NAME,
                FORM_FIELD_COUNTRY,
                FORM_LABEL_DEALER_NO,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_EMAIL,
                FORM_FIELD_MANAGEMENT_GROUP,
            ],
        }
    },
};


export const createReqFields = {
    'FR': {
        [ALLOCATION_REQUEST_FORM]: [
            FORM_FIELD_VIN,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [ORDER_CANCELLATION_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [VOR_PVI_ORDER_FORM]: [
            FORM_FIELD_PART_NO,
            FORM_FIELD_VIN,
            FORM_FIELD_REPAIR_ORDER_NO,
            FORM_FIELD_CAR_LOCATION,
            FORM_FIELD_QUANTITY,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_DELIVERY_DATE,
            FORM_FIELD_VEHICLE_TYPE,
            FORM_FIELD_CUSTOMER_NAME,
            FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
            FORM_FIELD_ENGINE_TYPE,
            FORM_FIELD_ENGINE_SERIAL_NUMBER,
            FORM_FIELD_GEARBOX_TYPE,
            FORM_FIELD_GEARBOX_SERIAL_NUMBER,
            FORM_FIELD_STEERING_WHEEL,
            FORM_FIELD_AVAILABILITY_DATE,
        ],
        [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [DELIVERY_DATE_REMINDER_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [EV_BATTERY_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_COUNTRY,
            FORM_LABEL_DEALER_NO,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_EMAIL,
            FORM_FIELD_DEALER_ADDRESS,
            FORM_FIELD_DELIVERY_CONTACT_NAME,
            FORM_FIELD_TELEPHONE_NO,
            // FORM_FIELD_EORI_NO,
            FORM_FIELD_VIN,
            FORM_FIELD_FIC_CUR_NUMBER,
            FORM_FIELD_CODE,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
            FORM_FIELD_BATTERY_PROBLEM_TYPE,
            FORM_FIELD_BATTERY_TYPE,
        ],
    },
    'GB': {
        [ALLOCATION_REQUEST_FORM]: [
            FORM_FIELD_VIN,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [ORDER_CANCELLATION_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [VOR_PVI_ORDER_FORM]: [
            FORM_FIELD_PART_NO,
            FORM_FIELD_VIN,
            FORM_FIELD_REPAIR_ORDER_NO,
            FORM_FIELD_REGISTRATION_NO,
            FORM_FIELD_CAR_LOCATION,
            FORM_FIELD_QUANTITY,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_DELIVERY_DATE,
            FORM_FIELD_VEHICLE_TYPE,
            FORM_FIELD_CUSTOMER_NAME,
            FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
            FORM_FIELD_ENGINE_TYPE,
            FORM_FIELD_ENGINE_SERIAL_NUMBER,
            FORM_FIELD_GEARBOX_TYPE,
            FORM_FIELD_GEARBOX_SERIAL_NUMBER,
            FORM_FIELD_STEERING_WHEEL,
            FORM_FIELD_AVAILABILITY_DATE,
        ],
        [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [DELIVERY_DATE_REMINDER_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [EV_BATTERY_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_COUNTRY,
            FORM_LABEL_DEALER_NO,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_EMAIL,
            FORM_FIELD_DEALER_ADDRESS,
            FORM_FIELD_DELIVERY_CONTACT_NAME,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_EORI_NO,
            FORM_FIELD_VIN,
            FORM_FIELD_FIC_CUR_NUMBER,
            FORM_FIELD_BATTERY_REF_NUMBER,
            FORM_FIELD_CODE,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
            FORM_FIELD_BATTERY_PROBLEM_TYPE,
            FORM_FIELD_BATTERY_TYPE,
        ],
    },
    'IE': {
        [ALLOCATION_REQUEST_FORM]: [
            FORM_FIELD_VIN,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [ORDER_CANCELLATION_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [VOR_PVI_ORDER_FORM]: [
            FORM_FIELD_PART_NO,
            FORM_FIELD_VIN,
            FORM_FIELD_REPAIR_ORDER_NO,
            FORM_FIELD_REGISTRATION_NO,
            FORM_FIELD_CAR_LOCATION,
            FORM_FIELD_QUANTITY,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_DELIVERY_DATE,
            FORM_FIELD_VEHICLE_TYPE,
            FORM_FIELD_CUSTOMER_NAME,
            FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
            FORM_FIELD_ENGINE_TYPE,
            FORM_FIELD_ENGINE_SERIAL_NUMBER,
            FORM_FIELD_GEARBOX_TYPE,
            FORM_FIELD_GEARBOX_SERIAL_NUMBER,
            FORM_FIELD_STEERING_WHEEL,
            FORM_FIELD_AVAILABILITY_DATE,
        ],
        [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [DELIVERY_DATE_REMINDER_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [EV_BATTERY_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_COUNTRY,
            FORM_LABEL_DEALER_NO,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_EMAIL,
            FORM_FIELD_DEALER_ADDRESS,
            FORM_FIELD_DELIVERY_CONTACT_NAME,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_EORI_NO,
            FORM_FIELD_VIN,
            FORM_FIELD_FIC_CUR_NUMBER,
            FORM_FIELD_BATTERY_REF_NUMBER,
            FORM_FIELD_CODE,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
            FORM_FIELD_BATTERY_PROBLEM_TYPE,
            FORM_FIELD_BATTERY_TYPE,
        ],
    },
    'AR': {
        [ALLOCATION_REQUEST_FORM]: [
            FORM_FIELD_VIN,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [ORDER_CANCELLATION_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [VOR_PVI_ORDER_FORM]: [
            FORM_FIELD_PART_NO,
            FORM_FIELD_VIN,
            FORM_FIELD_REPAIR_ORDER_NO,
            FORM_FIELD_REGISTRATION_NO,
            FORM_FIELD_CAR_LOCATION,
            FORM_FIELD_QUANTITY,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_DELIVERY_DATE,
            FORM_FIELD_VEHICLE_TYPE,
            FORM_FIELD_CUSTOMER_NAME,
            FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
            FORM_FIELD_ENGINE_TYPE,
            FORM_FIELD_ENGINE_SERIAL_NUMBER,
            FORM_FIELD_GEARBOX_TYPE,
            FORM_FIELD_GEARBOX_SERIAL_NUMBER,
            FORM_FIELD_STEERING_WHEEL,
            FORM_FIELD_AVAILABILITY_DATE,
        ],
        [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [DELIVERY_DATE_REMINDER_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [EV_BATTERY_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_COUNTRY,
            FORM_LABEL_DEALER_NO,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_EMAIL,
            FORM_FIELD_DEALER_ADDRESS,
            FORM_FIELD_DELIVERY_CONTACT_NAME,
            // FORM_FIELD_EORI_NO,
            FORM_FIELD_VIN,
            FORM_FIELD_FIC_CUR_NUMBER,
            FORM_FIELD_BATTERY_REF_NUMBER,
            FORM_FIELD_CODE,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
            FORM_FIELD_BATTERY_PROBLEM_TYPE,
            FORM_FIELD_BATTERY_TYPE,
        ],
    },
    'DEFAULT': {
        [ALLOCATION_REQUEST_FORM]: [
            FORM_FIELD_VIN,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [ORDER_CANCELLATION_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [VOR_PVI_ORDER_FORM]: [
            FORM_FIELD_PART_NO,
            FORM_FIELD_VIN,
            FORM_FIELD_REPAIR_ORDER_NO,
            FORM_FIELD_REGISTRATION_NO,
            FORM_FIELD_CAR_LOCATION,
            FORM_FIELD_QUANTITY,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_DELIVERY_DATE,
            FORM_FIELD_VEHICLE_TYPE,
            FORM_FIELD_CUSTOMER_NAME,
            FORM_FIELD_VEHICLE_DETAIL_TEL_NO,
            FORM_FIELD_ENGINE_TYPE,
            FORM_FIELD_ENGINE_SERIAL_NUMBER,
            FORM_FIELD_GEARBOX_TYPE,
            FORM_FIELD_GEARBOX_SERIAL_NUMBER,
            FORM_FIELD_STEERING_WHEEL,
            FORM_FIELD_AVAILABILITY_DATE,
        ],
        [ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [DELIVERY_DATE_REMINDER_FORM]: [
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
        ],
        [EV_BATTERY_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_COUNTRY,
            FORM_LABEL_DEALER_NO,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_EMAIL,
            FORM_FIELD_DEALER_ADDRESS,
            FORM_FIELD_DELIVERY_CONTACT_NAME,
            FORM_FIELD_TELEPHONE_NO,
            // FORM_FIELD_EORI_NO,
            FORM_FIELD_VIN,
            FORM_FIELD_FIC_CUR_NUMBER,
            FORM_FIELD_BATTERY_REF_NUMBER,
            FORM_FIELD_CODE,
            FORM_FIELD_ORDER,
            FORM_FIELD_LINE,
            FORM_FIELD_PART_NO,
            FORM_FIELD_DESCRIPTION,
            FORM_FIELD_QUANTITY,
            FORM_FIELD_BATTERY_PROBLEM_TYPE,
            FORM_FIELD_BATTERY_TYPE,
        ],
    }
};

