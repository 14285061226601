import {
    CONTEST_CLAIM_STATUS_FORM,
    PROBLEM_FOR_PLACING_CLAIMS_FORM,
    FORM_FIELD_CLAIM_RELATED,
    FORM_FIELD_INVOICE,
    FORM_FIELD_MISSING_PACKAGE,
    FORM_FIELD_WRONG_DESTINATION,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_CODE_PROD,
    FORM_FIELD_PART_NO,
    FORM_FIELD_QUANTITY,
    FORM_FIELD_DATE,
    FORM_FIELD_PACKAGE_NO,
    FORM_FIELD_DEFECTIVE,
    FORM_FIELD_DAMAGED,
    FORM_FIELD_RETURN,
    FORM_FIELD_OBSERVATION,
    FORM_FIELD_RETURN_PART_NO,
    FORM_FIELD_DESCRIPTION,
    FORM_FIELD_INVOICED_ORDER1,
    FORM_FIELD_INVOICED_ORDER2,
    FORM_FIELD_INVOICED_LINE1,
    FORM_FIELD_INVOICED_LINE2,
    FORM_FIELD_CLIENT_ID,
    FORM_FIELD_REGION,
    FORM_FIELD_QUANTITY_TOTAL,
    RETURN_CLAIM_FORM,
    FORM_FIELD_TAKING_BACK,
    FORM_FIELD_CONTAINER,
    STOCK_DEAD_CLAIM_FORM,
    OLD_MATERIAL_CLAIM_FORM,
    DIRECT_FLOW_CLAIM_FORM,
    FORM_FIELD_SUPPLIER,
    FORM_FIELD_SUPPLIER_PART_NO,
    FORM_FIELD_NEW_ATTACHMENTS,
    TROUBLESHOOT_CLAIM_FORM,
    FORM_FIELD_SP_TICKET_NUMBER_REFERENCE,
    FORM_FIELD_CLAIM_TYPE,
    FORM_FIELD_TRANSPORT_NUMBER,
    FORM_FIELD_TRANSPORT_PROVIDER,
    FORM_FIELD_USER_STATUS,
    FORM_FIELD_TELEPHONE_NO,
    FORM_FIELD_CLIENT_SOLUTION_PILOT,
    FORM_FIELD_MESSAGE,
} from './formConstants';

export const createReqFields = {
    [CONTEST_CLAIM_STATUS_FORM]: [
        FORM_FIELD_CLIENT_ID,
        FORM_FIELD_REGION,
        FORM_FIELD_CLAIM_RELATED,
        FORM_FIELD_INVOICE,
        FORM_FIELD_MISSING_PACKAGE,
        FORM_FIELD_WRONG_DESTINATION,
        FORM_FIELD_PROBLEM_DESCRIPTION
    ],
    [PROBLEM_FOR_PLACING_CLAIMS_FORM]: [
        FORM_FIELD_CLIENT_ID,
        FORM_FIELD_REGION,
        FORM_FIELD_INVOICED_ORDER1,
        FORM_FIELD_INVOICED_ORDER2,
        FORM_FIELD_INVOICED_LINE1,
        FORM_FIELD_INVOICED_LINE2,
        FORM_FIELD_CODE_PROD,
        FORM_FIELD_PART_NO,
        FORM_FIELD_QUANTITY,
        FORM_FIELD_INVOICE,
        FORM_FIELD_DATE,
        FORM_FIELD_PACKAGE_NO,
        FORM_FIELD_RETURN_PART_NO,
        FORM_FIELD_DESCRIPTION,
        FORM_FIELD_QUANTITY_TOTAL,
        FORM_FIELD_DEFECTIVE,
        FORM_FIELD_DAMAGED,
        FORM_FIELD_RETURN,
        FORM_FIELD_OBSERVATION,
        FORM_FIELD_PROBLEM_DESCRIPTION
    ],
    [RETURN_CLAIM_FORM]: [
        FORM_FIELD_CLIENT_ID,
        FORM_FIELD_REGION,
        FORM_FIELD_CLAIM_RELATED,
        FORM_FIELD_INVOICE,
        FORM_FIELD_TAKING_BACK,
        FORM_FIELD_CONTAINER,
        FORM_FIELD_PROBLEM_DESCRIPTION
    ],
    [STOCK_DEAD_CLAIM_FORM]: [
        FORM_FIELD_CLIENT_ID,
        FORM_FIELD_REGION,
        FORM_FIELD_TAKING_BACK,
        FORM_FIELD_PROBLEM_DESCRIPTION
    ],
    [OLD_MATERIAL_CLAIM_FORM]: [
        FORM_FIELD_CLIENT_ID,
        FORM_FIELD_REGION,
        FORM_FIELD_TAKING_BACK,
        FORM_FIELD_CONTAINER,
        FORM_FIELD_INVOICE,
        FORM_FIELD_PROBLEM_DESCRIPTION
    ],
    [DIRECT_FLOW_CLAIM_FORM]: [
        FORM_FIELD_CLIENT_ID,
        FORM_FIELD_REGION,
        FORM_FIELD_INVOICE,
        FORM_FIELD_DATE,
        FORM_FIELD_SUPPLIER,
        FORM_FIELD_SUPPLIER_PART_NO,
        FORM_FIELD_PROBLEM_DESCRIPTION,
        FORM_FIELD_NEW_ATTACHMENTS
    ],
    [TROUBLESHOOT_CLAIM_FORM]: [
        FORM_FIELD_TELEPHONE_NO,
        FORM_FIELD_SP_TICKET_NUMBER_REFERENCE,
        FORM_FIELD_USER_STATUS,
        FORM_FIELD_CLIENT_SOLUTION_PILOT,
        FORM_FIELD_TRANSPORT_PROVIDER,
        FORM_FIELD_TRANSPORT_NUMBER,
        FORM_FIELD_CLAIM_TYPE,
        FORM_FIELD_MESSAGE
    ],
};

export const editReqFields = {
    'DEALER': {
        [CONTEST_CLAIM_STATUS_FORM]: [],
        [PROBLEM_FOR_PLACING_CLAIMS_FORM]: [],
        [RETURN_CLAIM_FORM]: [],
        [STOCK_DEAD_CLAIM_FORM]: [],
        [OLD_MATERIAL_CLAIM_FORM]: [],
        [DIRECT_FLOW_CLAIM_FORM]: [],
        [TROUBLESHOOT_CLAIM_FORM]: [
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_SP_TICKET_NUMBER_REFERENCE,
            FORM_FIELD_USER_STATUS,
            FORM_FIELD_CLIENT_SOLUTION_PILOT,
            FORM_FIELD_TRANSPORT_PROVIDER,
            FORM_FIELD_TRANSPORT_NUMBER,
            FORM_FIELD_CLAIM_TYPE,
            FORM_FIELD_MESSAGE
        ],
    },
    'EDITOR': {
        [CONTEST_CLAIM_STATUS_FORM]: [],
        [PROBLEM_FOR_PLACING_CLAIMS_FORM]: [],
        [RETURN_CLAIM_FORM]: [],
        [STOCK_DEAD_CLAIM_FORM]: [],
        [OLD_MATERIAL_CLAIM_FORM]: [],
        [DIRECT_FLOW_CLAIM_FORM]: [],
        [TROUBLESHOOT_CLAIM_FORM]: [
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_SP_TICKET_NUMBER_REFERENCE,
            FORM_FIELD_USER_STATUS,
            FORM_FIELD_CLIENT_SOLUTION_PILOT,
            FORM_FIELD_TRANSPORT_PROVIDER,
            FORM_FIELD_TRANSPORT_NUMBER,
            FORM_FIELD_CLAIM_TYPE,
            FORM_FIELD_MESSAGE
        ],
    }
};
