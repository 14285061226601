import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {cloneDeep} from 'lodash';
import {
    deleteSolutionGroup,
    fetchSolutionGroupListPageData,
    saveExistingSolutionGroup,
    saveListData,
    saveNewSolutionGroup,
    storingChangesFinished,
    storingChangesStarted
} from '../../../actions/actionSolutionGroup';
import Loader from '../../Loader';
import solutionGroupMessages from '../../../intl/admin/solutionGroupMessages';
import SolutionGroupTableForm from './SolutionGroupTableForm';
import PageHeader from '../../common/PageHeader';

class SolutionGroupListPage extends Component {
    componentDidMount() {
        this.props.fetchSolutionGroupListPageData();
    }

    handleSaveClick = values => {
        this.props.storingChangesStarted();

        this.saveCurrentDataToState(values);

        const newSolutionGroupIds = cloneDeep(this.props.newSolutionGroupIds);
        const editedSolutionGroups = cloneDeep(this.props.editedSolutionGroups);
        const deletedSolutionGroupIds = cloneDeep(this.props.deletedSolutionGroupIds);

        this.resolveDeleted(newSolutionGroupIds, editedSolutionGroups, deletedSolutionGroupIds);
        this.resolveNew(newSolutionGroupIds, editedSolutionGroups);
        this.resolveEdited(editedSolutionGroups);

        this.props.storingChangesFinished();
    };

    saveCurrentDataToState = values => {
        const currentValues = cloneDeep(values);
        Object.keys(currentValues).forEach(solutionGroupId => {
            if (this.props.deletedSolutionGroupIds.has(solutionGroupId)) {
                delete currentValues[solutionGroupId];
            } else {
                if (!currentValues[solutionGroupId].id) {
                    currentValues[solutionGroupId].id = solutionGroupId;
                }
                currentValues[solutionGroupId].users = currentValues[solutionGroupId].users.map(user => ({
                    ipn: user.ipn,
                    name: user.name
                }));
            }
        });
        this.props.saveListData(currentValues);
    };


    resolveDeleted = (newSolutionGroupIds, editedSolutionGroups, deletedSolutionGroupIds) => {
        deletedSolutionGroupIds.forEach(solutionGroupId => {
            this.props.deleteSolutionGroup(solutionGroupId);
            newSolutionGroupIds.delete(solutionGroupId);
            editedSolutionGroups.delete(solutionGroupId);
        });
    };

    resolveNew = (newSolutionGroupIds, editedSolutionGroups) => {
        newSolutionGroupIds.forEach(solutionGroupId => {
            const editedSolutionGroup = editedSolutionGroups.get(solutionGroupId);
            const event = {
                name: editedSolutionGroup.name,
                users: editedSolutionGroup.users ? [...editedSolutionGroup.users.values()] : []
            };
            this.props.saveNewSolutionGroup(event);
            editedSolutionGroups.delete(solutionGroupId);
        });
    };

    resolveEdited = editedSolutionGroups => {
        editedSolutionGroups.forEach((solutionGroup, solutionGroupId) => {
            const event = {
                changedName: solutionGroup.name ? solutionGroup.name : undefined,
                addedUsers: [],
                removedUsers: []
            };
            if (solutionGroup.users) {
                solutionGroup.users.forEach((user, ipn) => {
                    if (solutionGroup.addedUsers && solutionGroup.addedUsers.has(ipn)) {
                        event.addedUsers.push(user);
                    }
                });
            }
            if (solutionGroup.removedUsers) {
                solutionGroup.removedUsers.forEach((user, ipn) => {
                    if (!solutionGroup.users || !solutionGroup.users.has(ipn)) {
                        event.removedUsers.push(user);
                    }
                });
            }
            this.props.saveExistingSolutionGroup(solutionGroupId, event);
        });
    };

    render() {
        const {isLoading, isStoringFinished} = this.props;
        if (!isStoringFinished || isLoading) {
            return <Loader/>;
        }

        return (
            <div>
                <PageHeader title={<FormattedMessage {...solutionGroupMessages.TITLE}/>}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <SolutionGroupTableForm onSubmit={this.handleSaveClick}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SolutionGroupListPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isStoringFinished: PropTypes.bool.isRequired,
    fetchSolutionGroupListPageData: PropTypes.func.isRequired,
    storingChangesStarted: PropTypes.func.isRequired,
    deleteSolutionGroup: PropTypes.func.isRequired,
    saveNewSolutionGroup: PropTypes.func.isRequired,
    saveExistingSolutionGroup: PropTypes.func.isRequired,
    saveListData: PropTypes.func.isRequired,
    storingChangesFinished: PropTypes.func.isRequired,
    newSolutionGroupIds: PropTypes.instanceOf(Set).isRequired,
    editedSolutionGroups: PropTypes.instanceOf(Map).isRequired,
    deletedSolutionGroupIds: PropTypes.instanceOf(Set).isRequired
};

const mapStateToProps = state => ({
    isLoading: state.solutionGroupReducer.isLoading,
    isStoringFinished: state.solutionGroupReducer.isStoringFinished,
    newSolutionGroupIds: state.solutionGroupReducer.newSolutionGroupIds,
    editedSolutionGroups: state.solutionGroupReducer.editedSolutionGroups,
    deletedSolutionGroupIds: state.solutionGroupReducer.deletedSolutionGroupIds
});

export default connect(mapStateToProps, {
    fetchSolutionGroupListPageData,
    storingChangesStarted,
    deleteSolutionGroup,
    saveNewSolutionGroup,
    saveExistingSolutionGroup,
    saveListData,
    storingChangesFinished
})(SolutionGroupListPage);
