import React from 'react';
import {ModalBody, ModalHeader, ModalWindow} from '../../common/modal/Modal';
import YouTube from 'react-youtube';
import {YOUTUBE_VIDEO} from './HelpConst';
import ReactPlayer from 'react-player';

const HelpModal = ({isOpen, switchModal, video}) => {

    const type = video && video.type;
    const label = video && video.label;
    const url = video && video.href;

    const getYoutubeVideoId = () => {
        if (video.href){
            const url = new URL(video.href);

            // resolve format https://www.youtube.com/watch?v=oQ43TSKZ5l8
            if (url.searchParams.get('v')) {
                return url.searchParams.get('v');
            }

            // resolve format https://youtu.be/bjPOWyPGTuw
            return url.pathname && url.pathname.replace('/', '');
        }
    }

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '720px',
            width: '100%',
            minWidth: '600px',
            maxHeight: '70%',
            overflowY: 'auto',
        },
        overlay: {
            zIndex: 1000
        }}

    return <ModalWindow isOpen={isOpen} onRequestClose={switchModal} customStyles={modalStyles}>
        <ModalHeader>
            {label}
        </ModalHeader>
        <ModalBody >
            { type === YOUTUBE_VIDEO
                ? <YouTube videoId={getYoutubeVideoId()} />
                : <ReactPlayer url={url} />
            }
        </ModalBody>
    </ModalWindow>
}

export default HelpModal;
