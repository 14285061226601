import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TABLE_TICKET_NUMBER: {
        id: 'spareParts.history.table.ticketNo',
        defaultMessage: 'Ticket No.'
    },
    TABLE_CREATED_BY: {
        id: 'spareParts.history.table.createdBy',
        defaultMessage: 'Created by (IPN)'
    },
    TABLE_ORDER_CREATION_TIME: {
        id: 'spareParts.history.table.orderCreationTime',
        defaultMessage: 'Order Creation Time'
    },
    TABLE_CLIENT_ID: {
        id: 'spareParts.history.table.clientId',
        defaultMessage: 'Client ID'
    },
    TABLE_REGION: {
        id: 'spareParts.history.table.region',
        defaultMessage: 'Region'
    },
    TABLE_COUNTRY_CODE_3: {
        id: 'spareParts.history.table.countryCode3',
        defaultMessage: 'Country Code'
    },
    TABLE_DEALER_NUMBER: {
        id: 'spareParts.history.table.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    TABLE_ORDER_TYPE: {
        id: 'spareParts.history.table.orderType',
        defaultMessage: 'Order type'
    },
    REQUEST_OF_COURTESY_CAR: {
        id: 'spareParts.history.table.requestOfCourtesyCar',
        defaultMessage: 'Courtesy car Request'
    },
    TABLE_STATUS: {
        id: 'spareParts.history.table.status',
        defaultMessage: 'Status'
    },
    TABLE_LAST_STATUS_DATE: {
        id: 'spareParts.history.table.lastStatusDate',
        defaultMessage: 'Last Status Date'
    },
    TITLE: {
        id: 'spareParts.history.title',
        defaultMessage: 'Orders history'
    },
    VISIBLE_COLUMNS: {
        id: 'spareParts.history.label.visibleColumns',
        defaultMessage: 'Visible columns'
    },
    TABLE_LAST_EDITOR: {
        id: 'spareParts.history.table.lastEditor',
        defaultMessage: 'Last Editor'
    },
    TABLE_BIN: {
        id: 'spareParts.history.table.bin',
        defaultMessage: 'BIN'
    },
    TABLE_INVOICE_NUMBER: {
        id: 'spareParts.history.table.invoiceNumber',
        defaultMessage: 'Invoice Number'
    },
    TABLE_PART_NO: {
        id: 'spareParts.history.table.partNo',
        defaultMessage: 'Part No'
    },
    TABLE_MANAGEMENT_GROUP: {
        id: 'spareParts.history.table.managementGroup',
        defaultMessage: 'Management Group'
    },
    TABLE_PROBLEM_TYPE: {
        id: 'spareParts.history.table.problemType',
        defaultMessage: 'Problem Type'
    },
    TABLE_TYPE_OF_CLIENT: {
        id: 'spareParts.history.table.typeOfClient',
        defaultMessage: 'Type of Client'
    },
    TABLE_SRC_TYPE: {
        id: 'spareParts.history.table.srcType',
        defaultMessage: 'SRC Type'
    },
    TABLE_SRC_N: {
        id: 'spareParts.history.table.srcN',
        defaultMessage: 'SRC N°'
    },


    INTERNAL_TICKET_TITLE: { //YY
        id: 'spareParts.history.titleInternalTicket',
        defaultMessage: 'History orders - Internal Ticket'
    },
    TABLE_SOLUTION_GROUP: {
        id: 'spareParts.history.table.solutionGroup',
        defaultMessage: 'Solution Gr.'
    },
    TABLE_INTERNAL_TICKET_NUMBER: { //YY
        id: 'spareParts.internalHistory.table.ticketNo',
        defaultMessage: 'Internal Ticket No.'
    },
    TABLE_REQUEST_TYPE: { //YY
        id: 'spareParts.internalHistory.table.requestType',
        defaultMessage: 'Request Type'
    },
    TABLE_REQUEST_STATUS: { //YY
        id: 'spareParts.internalHistory.table.requestStatus',
        defaultMessage: 'Request Status'
    },
    TABLE_PRIORITY: { //YY
        id: 'spareParts.internalHistory.table.priority',
        defaultMessage: 'Priority'
    },
    TABLE_PRIORITY_TYPE: { //YY
        id: 'spareParts.internalHistory.table.priorityType',
        defaultMessage: 'Priority Type'
    },
    TABLE_DEALER_TICKET_NUMBER: { //YY
        id: 'spareParts.internalHistory.table.dealerTicketNo',
        defaultMessage: 'Dealer Ticket No.'
    },
    TABLE_DEALER_FORM_TYPE: { //YY
        id: 'spareParts.internalHistory.table.dealerTicketFormType',
        defaultMessage: 'Dealer Form Type'
    },
    TABLE_IT_CREATION_TIME: { //YY
        id: 'spareParts.internalHistory.table.itCreationDate',
        defaultMessage: 'IT Creation Date'
    },
    TABLE_IT_CREATED_BY: { //YY
        id: 'spareParts.internalHistory.table.itCreatedBy',
        defaultMessage: 'IT Created by (IPN)'
    },
    TABLE_SG_REQUESTOR: { //YY
        id: 'spareParts.internalHistory.table.sgRequestor',
        defaultMessage: 'SG Requestor'
    },
    TABLE_ASSIGNED_SOLUTION_GROUP: { //YY
        id: 'spareParts.internalHistory.table.assignedSolutionGroup',
        defaultMessage: 'Assigned SG'
    },
    TABLE_DEALER_CLIENT_ID: { //YY
        id: 'spareParts.internalHistory.table.clientId',
        defaultMessage: 'Client ID'
    },
    TABLE_DEALER_REGION: { //YY
        id: 'spareParts.internalHistory.table.region',
        defaultMessage: 'Region'
    },
    TABLE_IT_DEALER_NUMBER: { //YY
        id: 'spareParts.internalHistory.table.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    TABLE_PART_NUMBER: { //YY
        id: 'spareParts.internalHistory.table.partNumber',
        defaultMessage: 'Part No.'
    },
    TABLE_MANAGEMENT_GRP: { //YY
        id: 'spareParts.internalHistory.table.managementGrp',
        defaultMessage: 'Management Group'
    },
    TABLE_PARTNER: { //YY
        id: 'spareParts.internalHistory.table.partner',
        defaultMessage: 'Partner'
    },
    TABLE_PARTNER_NAME: { //YY
        id: 'spareParts.internalHistory.table.partnerName',
        defaultMessage: 'Partner Name'
    },
    TABLE_IT_LAST_STATUS_DATE: { //YY
        id: 'spareParts.internalHistory.table.lastStatusDate',
        defaultMessage: 'Last Status Date'
    },
    TABLE_IT_LAST_EDITOR: { //YY
        id: 'spareParts.internalHistory.table.lastEditor',
        defaultMessage: 'Last Editor'
    }

});

export default messages;
