import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import help from '../../images/question.png';
import {Image} from './StyledComponents';

const ImgTooltip = (props) => {
    return (
        <div>
            <Tooltip placement="right"
                     overlay={<span>To bold part of text in the field (eg: <strong>this text</strong>), please use following script **this text**</span>}>
                <Image width={16} height={16} src={help}/>
            </Tooltip>
        </div>
    );
};

export default ImgTooltip;
