import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {RequiredSpan} from '../StyledComponents';
import validationMessages from '../../../intl/common/validationMessages';
import {injectIntl} from 'react-intl';
import DatePickerCommon from './DatePickerCommon';

const ErrorDiv = styled.label`
    display: inherit;
`;

const DatePickerComponent = props => {
    const {
        input, startDate, endDate, selectsStart, isDisabled, selectsEnd, isRequired, label,
        meta: {touched, error, invalid}, intl: {formatDate}
    } = props;
    return (
        <div>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label}/>
                {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
            </label>}
            <DatePickerCommon {...input}
                              selected={input.value.length > 0 ? new Date(input.value) : undefined}
                              value={input.value.length > 0 ? formatDate(new Date(input.value)) : undefined}
                              className={'form-control form-control-sm' + (touched && invalid ? ' is-invalid' : '')}
                              onChange={(date) => input.onChange(checkDate(date, startDate, endDate, selectsStart, selectsEnd))}
                              selectsEnd={selectsEnd}
                              selectsStart={selectsStart}
                              startDate={startDate ? new Date(startDate) : undefined}
                              endDate={endDate ? new Date(endDate) : undefined}
                              isClearable={!isDisabled}
                              disabled={isDisabled}
            />
            {touched && error && <ErrorDiv className="invalid-feedback">{validationMessages[error] ?
                <FormattedMessage {...validationMessages[error]}/> : error}</ErrorDiv>}
        </div>
    );
};
export default injectIntl(DatePickerComponent);


const checkDate = (date, startDate, endDate, selectsStart, selectsEnd) => {
    if (!date) {
        return undefined;
    }
    if (endDate && selectsStart) {
        return new Date(endDate).valueOf() < new Date(date).valueOf() ? new Date(endDate) : new Date(date);
    }
    if (startDate && selectsEnd) {
        return new Date(startDate).valueOf() < new Date(date).valueOf() ? new Date(date) : new Date(startDate);
    }
    return new Date(date);
};

DatePickerComponent.propTypes = {
    input: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool,
};
