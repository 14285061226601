import {defineMessages} from 'react-intl';

const messages = defineMessages({
    'cs-CZ': {
        id: 'common.location.cs-cz',
        defaultMessage: 'Czech Republic'
    },
    'fr-FR': {
        id: 'common.location.fr-fr',
        defaultMessage: 'France'
    },
    'es-ES': {
        id: 'common.location.es-es',
        defaultMessage: 'Spain'
    },
    'sv-SE': {
        id: 'common.location.sv-se',
        defaultMessage: 'Sweden'
    },
    'en-GB': {
        id: 'common.location.en-gb',
        defaultMessage: 'United Kingdom'
    },
    'pl-PL': {
        id: 'common.location.pl-pl',
        defaultMessage: 'Poland'
    },
    'sl-SI': {
        id: 'common.location.sl-si',
        defaultMessage: 'Slovenia'
    },
    'en-IE': {
        id: 'common.location.en-ie',
        defaultMessage: 'Ireland'
    },
    'pt-PT': {
        id: 'common.location.pt-pt',
        defaultMessage: 'Portugal'
    },
    'de-DE': {
        id: 'common.location.de-de',
        defaultMessage: 'Germany'
    },
    'it-IT': {
        id: 'common.location.it-it',
        defaultMessage: 'Italy'
    },
    'es-AR': {
        id: 'common.location.es-ar',
        defaultMessage: 'Argentina'
    },
    'es-MX': {
        id: 'common.location.es-mx',
        defaultMessage: 'Mexico'
    },
    'uk-UA': {
        id: 'common.location.uk-ua',
        defaultMessage: 'Ukraine'
    },
    'sq-XK': {
        id: 'common.location.sq-XK',
        defaultMessage: 'Kosovo'
    },
    'tr-TR': {
        id: 'common.location.tr-TR',
        defaultMessage: 'Turkey'
    }
});

export default messages;
