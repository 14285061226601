import styled from 'styled-components';
import {PrimaryIcon} from './Button';
import Picky from 'react-picky';

export const RequiredSpan = styled.span`
  &&{color: red};
`;

export const StyledDiv = styled.div`
  border-radius: 5px;
`;

export const RedButton = styled.button`
  background-color: #D8190C;
  cursor: pointer;
`;

export const StyledTr = styled.tr`
    :hover&&{
        background-color: white;
    };
`;

export const ReplyTd = styled.td`
    &&{
        padding: 0.2rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    };
`;

export const StyledThead = styled.thead`
  &&{background-color: #e9ecef;};
`;

export const StyledSpan = styled.span`
  &&{color: red};
`;

export const StyledTh = styled.th`
    &&{
        border-top: 0px;
        text-align: center
        white-space: nowrap;
        @media print {
          white-space: normal;
        }
    };
`;

export const StyledProblemDescTh = styled.th`
    &&{
        border-top: 0px;
        padding: 0px;
        text-align: center;
        white-space: nowrap;
    };
`;

export const StyledToolsTh = styled(StyledTh)`
  width: 55px;
`;


export const BorderlessPrimaryIcon = styled(PrimaryIcon)`
  border-radius: 0 0 0 0;
`;

export const StyledPicky = styled(Picky)`
    button:first-child{
        border-radius: 0.3em;
    };
`;

export const Label = styled.label`
    font-weight: bold;
`;

export const MessageCell = styled.td`
    white-space: pre-wrap;
`;

export const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

export const StyledSmall = styled.input`
    color: black;
    font-size: 0.875rem;
`;

export const StyledTextArea = styled.textarea`
    color: black;
    font-size: 0.875rem;
`;

export const Table = styled.table`
  width: 100%;
`;

export const Wrapper = styled.div`
  padding-bottom: 30px;
`;

export const Margin = styled.div`
    ${({left}) => left && `margin-left: ${left}px`};
    ${({right}) => right && `margin-right: ${right}px`};
    ${({top}) => top && `margin-top: ${top}px`};
    ${({bottom}) => bottom && `margin-bottom: ${bottom}px`};
`;

export const Padding = styled.div`
    ${({left}) => left && `padding-left: ${left}px`};
    ${({right}) => right && `padding-right: ${right}px`};
    ${({top}) => top && `padding-top: ${top}px`};
    ${({bottom}) => bottom && `padding-bottom: ${bottom}px`};
`;

export const Image = styled.img`
    padding: 0;
    margin: 0;
    cursor: pointer;
    ${({width}) => width && `width: ${width}px`};
    ${({height}) => height && `height: ${height}px`};
`;

export const Flex = styled.div`
    display: flex;
    ${({justifyContent}) => justifyContent && `justify-content: ${justifyContent}`};
`;

Image.displayName = 'Image';

