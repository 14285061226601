import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const DIC_TECH_DOC_INFO_SETTED = 'DIC_TECH_DOC_INFO_SETTED';
export const DIC_TECH_DOC_RESET = 'DIC_TECH_DOC_RESET';
export const DIC_TECH_DOC_CLOSE_MODAL = 'DIC_TECH_DOC_CLOSE_MODAL';

export const resetTechDocDic = () => dispatch => {
    return dispatch({
        type: DIC_TECH_DOC_RESET,
    });
};

export const closeTechDocDicModal = () => dispatch => {
    return dispatch({
        type: DIC_TECH_DOC_CLOSE_MODAL,
    });
};

export const setDicTechDocInfoActionCreator = () => {
    return {
        type: DIC_TECH_DOC_INFO_SETTED,
    }
};

export const fetchDicPartInfoActionCreator = (partNo) => {
    return {
        type: techDocWebsocketCommands.TECH_DOC_DIC_PART_INFO_GET,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_DIC_PART_INFO_GET,
        payload: {
            partNo
        },
    }
};
