import {
    FORM_FIELD_ATTACHMENTS,
    FORM_FIELD_CLAIM_RELATED,
    FORM_FIELD_CLIENT_ID,
    FORM_FIELD_CREATION_DATE,
    FORM_FIELD_DEALER_NAME,
    FORM_FIELD_DEALER_NO,
    FORM_FIELD_DESCRIPTION,
    FORM_FIELD_EMAIL,
    FORM_FIELD_MESSAGE,
    FORM_FIELD_NEW_ATTACHMENTS,
    FORM_FIELD_PART_NO,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_QUANTITY,
    FORM_FIELD_REGION,
    FORM_FIELD_TELEPHONE_NO,
    FORM_LABEL_ATTACHMENTS,
    FORM_LABEL_CLAIM_RELATED,
    FORM_LABEL_CLIENT_ID,
    FORM_LABEL_CREATION_DATE,
    FORM_LABEL_DEALER_NAME,
    FORM_LABEL_DEALER_NO,
    FORM_LABEL_DESCRIPTION,
    FORM_LABEL_EMAIL,
    FORM_LABEL_LINE,
    FORM_LABEL_MESSAGE,
    FORM_LABEL_NEW_ATTACHMENTS,
    FORM_LABEL_ORDER,
    FORM_LABEL_PART_NO,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_QUANTITY,
    FORM_LABEL_REGION,
    FORM_LABEL_TELEPHONE_NO,
    FORM_LABEL_INVOICE,
    FORM_LABEL_MISSING_PACKAGE,
    FORM_LABEL_WRONG_DESTINATION,
    FORM_FIELD_INVOICE,
    FORM_FIELD_MISSING_PACKAGE,
    FORM_FIELD_WRONG_DESTINATION,
    FORM_FIELD_RETURN_PART_NO,
    FORM_FIELD_RETURN,
    FORM_LABEL_RETURN,
    FORM_FIELD_PACKAGE_NO,
    FORM_LABEL_PACKAGE_NO,
    FORM_LABEL_OBSERVATION,
    FORM_FIELD_OBSERVATION,
    FORM_FIELD_DEFECTIVE,
    FORM_FIELD_DATE,
    FORM_FIELD_DAMAGED,
    FORM_FIELD_CODE_PROD,
    FORM_LABEL_CODE_PROD,
    FORM_LABEL_DAMAGED,
    FORM_LABEL_DATE,
    FORM_LABEL_DEFECTIVE,
    FORM_FIELD_INVOICED_ORDER1,
    FORM_FIELD_INVOICED_ORDER2,
    FORM_FIELD_INVOICED_LINE1,
    FORM_FIELD_INVOICED_LINE2,
    FORM_FIELD_QUANTITY_TOTAL,
    FORM_LABEL_QUANTITY_TOTAL,
    FORM_FIELD_TAKING_BACK,
    FORM_FIELD_CONTAINER,
    FORM_LABEL_TAKING_BACK,
    FORM_LABEL_CONTAINER,
    FORM_FIELD_DEALER_REMARKS,
    FORM_FIELD_SUPPLIER,
    FORM_FIELD_CREDIT_NOTE,
    FORM_FIELD_CREDIT_NOTE_DATE,
    FORM_FIELD_SUPPLIER_PART_NO,
    FORM_FIELD_BILL_OF_DELIVERY,
    FORM_FIELD_ACCOUNTING_CREDIT_NOTE_DATE,
    FORM_FIELD_ACCOUNTING_CREDIT_NOTE,
    FORM_LABEL_SUPPLIER,
    FORM_LABEL_CREDIT_NOTE,
    FORM_LABEL_CREDIT_NOTE_DATE,
    FORM_LABEL_SUPPLIER_PART_NO,
    FORM_LABEL_BILL_OF_DELIVERY,
    FORM_LABEL_ACCOUNTING_CREDIT_NOTE,
    FORM_LABEL_ACCOUNTING_CREDIT_NOTE_DATE,
    FORM_FIELD_NAME_TD_SEARCH,
    FORM_FIELD_FROM,
    FORM_FIELD_TO,
    FORM_FIELD_LAST_EDITOR,
    FORM_FIELD_FORM_TYPE,
    FORM_FIELD_STATUS,
    FORM_FIELD_PFX,
    FORM_FIELD_SFX,
    FORM_LABEL_TICKET_NUMBER,
    FORM_LABEL_STATUS,
    FORM_LABEL_FORM_TYPE,
    FORM_LABEL_LAST_EDITOR,
    FORM_LABEL_TO,
    FORM_LABEL_FROM,
    FORM_LABEL_NAME,
    FORM_LABEL_DEALER_REMARKS,
    FORM_FIELD_EDITORS_NOTES,
    FORM_LABEL_EDITORS_NOTES,
    FORM_LABEL_EDITORS_NOTE_ATTACHMENTS,
    FORM_FIELD_EDITORS_NOTE_ATTACHMENTS,
    FORM_FIELD_USER_STATUS,
    FORM_LABEL_USER_STATUS,
    FORM_FIELD_CLIENT_SOLUTION_PILOT,
    FORM_LABEL_CLIENT_SOLUTION_PILOT,
    FORM_LABEL_TRANSPORT_PROVIDER,
    FORM_FIELD_TRANSPORT_PROVIDER,
    FORM_FIELD_TRANSPORT_NUMBER,
    FORM_LABEL_TRANSPORT_NUMBER,
    FORM_FIELD_CLAIM_TYPE,
    FORM_LABEL_CLAIM_TYPE,
    FORM_FIELD_PROD,
    FORM_LABEL_PROD,
    FORM_FIELD_ORDER,
    FORM_FIELD_LINE,
    FORM_FIELD_SUBJECT_TO_VOR,
    FORM_LABEL_SUBJECT_TO_VOR,
    FORM_FIELD_DIC_NUMBER,
    FORM_LABEL_DIC_NUMBER,
    FORM_FIELD_SP_TICKET_NUMBER_REFERENCE,
    FORM_LABEL_SP_TICKET_NUMBER_REFERENCE
} from './formConstants';

export const claimsFieldLabelMapping = {
    // FORMS
    // ticket section
    [FORM_FIELD_DEALER_NAME]: FORM_LABEL_DEALER_NAME,
    [FORM_FIELD_DEALER_NO]: FORM_LABEL_DEALER_NO,
    [FORM_FIELD_CLIENT_ID]: FORM_LABEL_CLIENT_ID,
    [FORM_FIELD_REGION]: FORM_LABEL_REGION,
    [FORM_FIELD_EMAIL]: FORM_LABEL_EMAIL,
    [FORM_FIELD_TELEPHONE_NO]: FORM_LABEL_TELEPHONE_NO,
    [FORM_FIELD_CREATION_DATE]: FORM_LABEL_CREATION_DATE,
    // problem description section
    [FORM_FIELD_CLAIM_RELATED]: FORM_LABEL_CLAIM_RELATED,
    [FORM_FIELD_INVOICE]: FORM_LABEL_INVOICE,
    [FORM_FIELD_PART_NO]: FORM_LABEL_PART_NO,
    [FORM_FIELD_MISSING_PACKAGE]: FORM_LABEL_MISSING_PACKAGE,
    [FORM_FIELD_WRONG_DESTINATION]: FORM_LABEL_WRONG_DESTINATION,
    [FORM_FIELD_PROBLEM_DESCRIPTION]: FORM_LABEL_PROBLEM_DESCRIPTION,
    [FORM_FIELD_INVOICED_ORDER1]: FORM_LABEL_ORDER,
    [FORM_FIELD_INVOICED_ORDER2]: FORM_LABEL_ORDER,
    [FORM_FIELD_INVOICED_LINE1]: FORM_LABEL_LINE,
    [FORM_FIELD_INVOICED_LINE2]: FORM_LABEL_LINE,
    [FORM_FIELD_CODE_PROD]: FORM_LABEL_CODE_PROD,
    [FORM_FIELD_QUANTITY]: FORM_LABEL_QUANTITY,
    [FORM_FIELD_DATE]: FORM_LABEL_DATE,
    [FORM_FIELD_PACKAGE_NO]: FORM_LABEL_PACKAGE_NO,
    [FORM_FIELD_RETURN_PART_NO]: FORM_LABEL_PART_NO,
    [FORM_FIELD_DESCRIPTION]: FORM_LABEL_DESCRIPTION,
    [FORM_FIELD_QUANTITY_TOTAL]: FORM_LABEL_QUANTITY_TOTAL,
    [FORM_FIELD_DEFECTIVE]: FORM_LABEL_DEFECTIVE,
    [FORM_FIELD_DAMAGED]: FORM_LABEL_DAMAGED,
    [FORM_FIELD_RETURN]: FORM_LABEL_RETURN,
    [FORM_FIELD_OBSERVATION]: FORM_LABEL_OBSERVATION,
    [FORM_FIELD_TAKING_BACK]: FORM_LABEL_TAKING_BACK,
    [FORM_FIELD_CONTAINER]: FORM_LABEL_CONTAINER,
    [FORM_FIELD_DEALER_REMARKS]: FORM_LABEL_DEALER_REMARKS,
    [FORM_FIELD_SUPPLIER]: FORM_LABEL_SUPPLIER,
    [FORM_FIELD_CREDIT_NOTE]: FORM_LABEL_CREDIT_NOTE,
    [FORM_FIELD_CREDIT_NOTE_DATE]: FORM_LABEL_CREDIT_NOTE_DATE,
    [FORM_FIELD_SUPPLIER_PART_NO]: FORM_LABEL_SUPPLIER_PART_NO,
    [FORM_FIELD_BILL_OF_DELIVERY]: FORM_LABEL_BILL_OF_DELIVERY,
    [FORM_FIELD_ACCOUNTING_CREDIT_NOTE]: FORM_LABEL_ACCOUNTING_CREDIT_NOTE,
    [FORM_FIELD_ACCOUNTING_CREDIT_NOTE_DATE]: FORM_LABEL_ACCOUNTING_CREDIT_NOTE_DATE,
    // message section
    [FORM_FIELD_MESSAGE]: FORM_LABEL_MESSAGE,
    // editor's notes section
    [FORM_FIELD_EDITORS_NOTES]: FORM_LABEL_EDITORS_NOTES,
    [FORM_FIELD_EDITORS_NOTE_ATTACHMENTS]: FORM_LABEL_EDITORS_NOTE_ATTACHMENTS,
    // attachment section
    [FORM_FIELD_ATTACHMENTS]: FORM_LABEL_ATTACHMENTS,
    [FORM_FIELD_NEW_ATTACHMENTS]: FORM_LABEL_NEW_ATTACHMENTS,
    // FULL TEXT SEARCH
    // ticket section
    [FORM_FIELD_NAME_TD_SEARCH]: FORM_LABEL_NAME,
    [FORM_FIELD_FROM]: FORM_LABEL_FROM,
    [FORM_FIELD_TO]: FORM_LABEL_TO,
    [FORM_FIELD_LAST_EDITOR]: FORM_LABEL_LAST_EDITOR,
    [FORM_FIELD_FORM_TYPE]: FORM_LABEL_FORM_TYPE,
    [FORM_FIELD_STATUS]: FORM_LABEL_STATUS,
    [FORM_FIELD_PFX]: FORM_LABEL_TICKET_NUMBER,
    [FORM_FIELD_SFX]: FORM_LABEL_TICKET_NUMBER,
    //  order section
    [FORM_FIELD_SP_TICKET_NUMBER_REFERENCE]: FORM_LABEL_SP_TICKET_NUMBER_REFERENCE,
    [FORM_FIELD_DIC_NUMBER]: FORM_LABEL_DIC_NUMBER,
    [FORM_FIELD_USER_STATUS]: FORM_LABEL_USER_STATUS,
    [FORM_FIELD_CLIENT_SOLUTION_PILOT]: FORM_LABEL_CLIENT_SOLUTION_PILOT,
    [FORM_FIELD_TRANSPORT_PROVIDER]: FORM_LABEL_TRANSPORT_PROVIDER,
    [FORM_FIELD_TRANSPORT_NUMBER]: FORM_LABEL_TRANSPORT_NUMBER,
    [FORM_FIELD_CLAIM_TYPE]: FORM_LABEL_CLAIM_TYPE,
    [FORM_FIELD_PROD]: FORM_LABEL_PROD,
    [FORM_FIELD_ORDER]: FORM_LABEL_ORDER,
    [FORM_FIELD_LINE]: FORM_LABEL_LINE,
    [FORM_FIELD_SUBJECT_TO_VOR]: FORM_LABEL_SUBJECT_TO_VOR,
};
