import {FILE, YOUTUBE_VIDEO} from '../../HelpConst';

const values = [
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment utiliser le menu Liste ?',
        href: 'https://youtu.be/2Ds43lkTB28'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment utiliser l\'historique ?',
        href: ''
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment trouver un ticket spécifique à partir du moteur de recherche pour Expertise Local ?',
        href: 'https://youtu.be/YYW9RgL_OOg'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment trouver un ticket spécifique à partir du moteur de recherche ?',
        href: 'https://youtu.be/Z_jIy9WCljc'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment exporter des tickets en fichier excel ?',
        href: 'https://youtu.be/P_AF8F2ihdU'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment traiter un ticket - Expertise Local ?',
        href: 'https://youtu.be/NKFkfspMW_g'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment traiter un ticket - Expertise niveau 2 avec option "Réponse au concessionnaire" ?',
        href: 'https://youtu.be/hsuem8fPk80'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment traiter un ticket - Expertise niveau 2',
        href: 'https://youtu.be/63bpubUTF0w'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment traiter un ticket  si vous êtes "niveau d\'expertise 3" ?',
        href: 'https://youtu.be/IqkwXe33Xvg'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment créer un ticket interne depuis un ticket concessionnaire ?',
        href: 'https://youtu.be/SIz23puYWP4'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment créer un ticket interne ?',
        href: 'https://youtu.be/iqXZjbpo8QU'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment traiter un ticket interne que vous avez crée ?',
        href: 'https://youtu.be/NkLra31ZHYI'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment traiter un ticket interne - Expertise niveau 2 et 3 ?',
        href: 'https://youtu.be/Rk5U2YHaDZ8'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - Comment retrouver un ticket spécifique à partir du moteur de recherche pour tickets internes ?',
        href: 'https://youtu.be/azZd6ozPZU0'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'Comment ajouter ou supprimer un utilisateur',
        href: 'https://youtu.be/c94naFb5cBU\n'
    }
];

export default values;
