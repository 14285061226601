import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import {withRouter} from 'react-router-dom';
import {clearAlerts} from '../../../actions/alertsActions';
import SearchEngineOrderTable from './SearchEngineOrderTable';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import {lockSparePartsOrdersTicket, resetRedirectAfterLockTicket} from '../../../actions/orders/actionLockTicket';
import SearchEngineOrderForm from './SearchEngineOrderForm';
import {SubmissionError} from 'redux-form';
import {
    updateSparePartsUserSearchEnginePreference
} from '../../../actions/orders/actionSparePartsPreferences';
import {FORM_FIELD_PFX, FORM_FIELD_SFX} from '../../../constants/formConstants';
import searchEngineMessages from '../../../intl/sparePartsOrders/searchEngineMessages';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import {FormattedDate} from 'react-intl';
import {StyledPicky} from '../../common/StyledComponents';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {fillNumberFiveZero, pruneEmpty} from '../../../utils/utils';
import commonMessages from '../../../intl/common/commonMessages';
import {batteryProblemTypes, countryGroups, domains} from '../../../constants/Utils';
import batteryProblemTypeMessages from '../../../intl/sparePartsOrders/batteryProblemTypeMessages';
import fulltextSearchMessages from '../../../intl/technicalDocumentations/fulltextSearchMessages';
import {moduleRoles} from '../../../utils/roles';

const domain = domains.SPARE_PARTS;
class SearchEngineOrderPage extends Component {
    constructor(props) {
        super(props);
        const {intl, loggedUser: {roles}} = props;

        const isSgEditor = moduleRoles.isSGEditorOrder(roles);
        const isEditor = !isSgEditor && moduleRoles.isEditorOrder(roles);
        const isDealer = !isSgEditor && !isEditor && moduleRoles.isDealerOrder(roles);

        const urlParams = new URLSearchParams(props.location.search);
        const managedGroups = get(props, `loggedUser.roles.${domain}.dealer`) ? [get(props, 'loggedUser.group', 'XX')] : moduleRoles.getEditorGroups(domain, get(props, 'loggedUser.roles', {})).map(({group}) => group);
        const storageData = this.loadStateFromSessionStorage();
        this.state = {
            ratchet: false,
            columnLibrary: [
                {
                    id: 'creatorIpn',
                    accessor: 'ticket.creatorIpn',
                    filterable: false,
                    message: searchEngineMessages.TABLE_CREATED_BY,
                },
                {
                    id: 'created',
                    accessor: 'ticket.created',
                    filterable: false,
                    message: searchEngineMessages.TABLE_ORDER_CREATION_TIME,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'clientId',
                    accessor: 'ticket.clientId',
                    filterable: false,
                    message: searchEngineMessages.TABLE_CLIENT_ID,
                    Cell: e => e.value && String(e.value).padStart(6, "0")
                },
                {
                    id: 'region',
                    accessor: 'ticket.region',
                    filterable: false,
                    message: searchEngineMessages.TABLE_REGION,
                },
                {
                    id: 'countryCode3',
                    accessor: 'ticket.countryCode3',
                    filterable: false,
                    message: searchEngineMessages.TABLE_COUNTRY_CODE_3,
                },
                {
                    id: 'dealerNumber',
                    accessor: 'ticket.dealerNumber',
                    filterable: false,
                    message: searchEngineMessages.TABLE_DEALER_NUMBER,
                    Cell: e => e.value && String(e.value).padStart(8, "0")
                },
                {
                    id: 'bin',
                    accessor: 'batteryDetail.bin',
                    filterable: false,
                    message: searchEngineMessages.TABLE_BIN,
                },
                {
                    id: 'invoiceNumber',
                    accessor: 'batteryProblemDescription.invoiceNumber',
                    filterable: false,
                    message: searchEngineMessages.TABLE_INVOICE_NUMBER,
                },
                {
                    id: 'batteryPartNo',
                    accessor: 'batteryProblemDescription.partNo',
                    filterable: false,
                    message: searchEngineMessages.PART_NO,
                },
                {
                    id: 'managementGroup',
                    accessor: 'managementGroup',
                    filterable: false,
                    message: searchEngineMessages.MANAGEMENT_GROUP,
                },
                {
                    id: 'batteryProblemType',
                    accessor: 'batteryProblemDescription.batteryProblemType',
                    filterable: false,
                    message: searchEngineMessages.PROBLEM_TYPE,
                    Cell: e =>
                        <div>{batteryProblemTypeMessages[e.value] ? intl.formatMessage(batteryProblemTypeMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'orderType',
                    accessor: 'ticket.orderType',
                    filterable: false,
                    message: searchEngineMessages.TABLE_ORDER_TYPE,
                    Cell: e =>
                        <div>{formTypeMessages[e.value] ? intl.formatMessage(formTypeMessages[e.value]) : e.value}</div>
                },
                {
                    id: 'requestOfCourtesyCar',
                    accessor: 'carRequests.requestOfCourtesyCar',
                    filterable: false,
                    message: searchEngineMessages.REQUEST_OF_COURTESY_CAR,
                    Cell: e =>
                        <div>{commonMessages[e.value] ? intl.formatMessage(commonMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'solutionGroup',
                    accessor: 'ticket.solutionGroup.name',
                    filterable: false,
                    message: fulltextSearchMessages.TABLE_SOLUTION_GROUP,
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'status',
                    accessor: 'ticket.status',
                    filterable: false,
                    message: searchEngineMessages.TABLE_STATUS,
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'lastUpdate',
                    accessor: 'ticket.lastUpdate',
                    filterable: false,
                    message: searchEngineMessages.TABLE_LAST_STATUS_DATE,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                }
            ],
            listDefaultFilters: get(storageData, 'filters', []),
            formInitValues: get(storageData, 'formValues', {}),
            searchOption: {},
        };

        if(isDealer) {
            this.state.columnLibrary = this.state.columnLibrary.filter(column => column.id !== 'managementGroup' && column.id !== 'countryCode3');
        }

        if(!managedGroups.includes(countryGroups.IT)) {
            this.state.columnLibrary = this.state.columnLibrary.filter(column => column.id !== 'requestOfCourtesyCar');
        }

        let urlParamsFilter = [];
        let urlParamsForm = {};
        if(urlParams.has('vin')){
            const vin = urlParams.get('vin') ? urlParams.get('vin').slice(0,17) : '';
            urlParamsFilter.push(
                {
                    id: 'vin',
                    value: vin,
                }
            )
            urlParamsForm.vin = vin;
        }
        if (urlParamsFilter.length > 0) {
            this.state.listDefaultFilters = [urlParamsFilter, {}];
            this.state.formInitValues = urlParamsForm;
        }
    }

    componentWillUnmount() {
        this.props.resetRedirectAfterLockTicket();
    }

    resetForm = () => {
        this.setState({listDefaultFilters: [], formInitValues: {}});
        this.saveStateToSessionStorage({}, [[], {}]);
    }

    saveStateToSessionStorage(formValues = {}, filters = []) {
        sessionStorage.setItem('searchEngineSP', JSON.stringify({formValues, filters}));
    }

    loadStateFromSessionStorage() {
        let value = {};
        if (sessionStorage.hasOwnProperty('searchEngineSP')) {
            value = JSON.parse(sessionStorage.getItem('searchEngineSP'));
            return value || {};
        }
        return {};
    }

    componentDidMount() {
        this.props.clearAlerts();
    }

    handleEditClick = ({id}) => {
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockSparePartsOrdersTicket(id);
            this.setState({ratchet: true});
        }
    };

    selectMultipleOption = (value) => {
        this.props.updateSparePartsUserSearchEnginePreference(this.props.loggedUser.ipn,
            this.state.columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    handleSubmit = (values) => {
        const polishedValues = pruneEmpty(values);
        const filter = [];
        const pfx = get(polishedValues, 'pfxNumber', '').length;
        const sfx = get(polishedValues, 'sfxNumber', '').length;

        if (pfx > 0 && sfx === 0) {
            throw new SubmissionError({
                [FORM_FIELD_SFX]: 'Fill complete ticket number'
            })
        }

        if (pfx === 0 && sfx > 0) {
            throw new SubmissionError({
                [FORM_FIELD_PFX]: 'Fill complete ticket number'
            })
        }
        const temp = JSON.parse(JSON.stringify({
            ...polishedValues
        }));
        if (pfx || sfx) {
            temp.ticketNumber = `${polishedValues.pfxNumber}-${polishedValues.sfxNumber}`;
            delete temp.pfxNumber;
            delete temp.sfxNumber;
        }

        Object.keys(temp).filter(key => key !== 'searchOption').forEach(key => filter.push({
            id: key,
            value: temp[key]
        }));

        this.setState({listDefaultFilters: [filter, polishedValues.searchOption || {}]});
        this.saveStateToSessionStorage(polishedValues, [filter, polishedValues.searchOption || {}]);
    };

    render() {
        const {intl, sparePartsPreferences, lockTicket, loggedUser} = this.props;
        if (lockTicket.redirect && this.state.ratchet) {
            this.props.history.push(`/order/edit/${lockTicket.uuid}`);
        }
        if (sparePartsPreferences.isLoading) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...searchEngineMessages.TITLE}/>}/>
                <SearchEngineOrderForm
                    form={'searchEngineForm'}
                    initialValues={this.state.formInitValues}
                    resetForm={this.resetForm}
                    enableReinitialize={true}
                    user={loggedUser}
                    onSubmit={this.handleSubmit}
                />
                <div className="d-flex justify-content-end p-4 mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span className="font-weight-bold"><FormattedMessage {...searchEngineMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={this.state.columnLibrary.filter(item => !get(sparePartsPreferences, 'menuSearchEngine', {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={this.state.columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            selectAllText={<FormattedMessage {...commonMessages.SELECT_ALL}/>}
                            manySelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            allSelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <SearchEngineOrderTable
                    columns={this.state.columnLibrary.filter(item => !get(sparePartsPreferences, 'menuSearchEngine', {default: []}).includes(item.id))}
                    listDefaultFilters={this.state.listDefaultFilters}
                    handleEditClick={this.handleEditClick}/>
            </div>
        );
    }
}

SearchEngineOrderPage.propTypes = {
    lockTicket: PropTypes.object.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    updateSparePartsUserSearchEnginePreference: PropTypes.func.isRequired,
    lockSparePartsOrdersTicket: PropTypes.func.isRequired,
    resetRedirectAfterLockTicket: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    sparePartsPreferences: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    sparePartsPreferences: state.sparePartsPreferences,
    lockTicket: state.sparePartsListLockTicketCheck,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateSparePartsUserSearchEnginePreference,
    lockSparePartsOrdersTicket,
    resetRedirectAfterLockTicket,
})(injectIntl(SearchEngineOrderPage))), ['SP_EDITOR', 'SP_DEALER', 'SP_SG_EDITOR']);

