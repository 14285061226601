import {domains} from '../../constants/Utils';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {errorAlert} from '../alertsActions';
import {
    validateSolutionGroupEditorData
} from '../../api/validation/role';
import alertMessages from '../../intl/common/alertMessages';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';


export const MANAGED_SOLUTION_GROUPS_EDITOR = 'MANAGED_SOLUTION_GROUPS_EDITOR';
export const INITIAL_SOLUTION_GROUPS_EDITOR_OPTIONS = 'INITIAL_SOLUTION_GROUPS_EDITOR_OPTIONS';
export const SELECTED_SOLUTION_GROUPS_EDITOR_OPTIONS = 'SELECTED_SOLUTION_GROUPS_EDITOR_OPTIONS';
export const FIXED_SOLUTION_GROUPS_EDITOR_OPTIONS = 'FIXED_SOLUTION_GROUPS_EDITOR_OPTIONS';
export const SOLUTION_GROUPS_EDITOR_OPTIONS_CHANGED = 'SOLUTION_GROUPS_EDITOR_OPTIONS_CHANGED';
export const SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_PENDING = 'SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_PENDING';
export const SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_SUCCESS = 'SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_SUCCESS';
export const SOLUTION_GROUPS_EDITOR_RIGHTS_SAVE_PENDING = 'SOLUTION_GROUPS_EDITOR_RIGHTS_SAVE_PENDING';
export const SOLUTION_GROUPS_EDITOR_RIGHTS_CLEAR = 'SOLUTION_GROUPS_EDITOR_RIGHTS_CLEAR';
export const SOLUTION_GROUPS_EDITOR_RIGHTS_ERROR = 'SOLUTION_GROUPS_EDITOR_RIGHTS_ERROR';
export const TECH_DOC_ADMIN_SOLUTION_GROUPS_FETCH_PENDING = 'TECH_DOC_ADMIN_SOLUTION_GROUPS_FETCH_PENDING';
export const TECH_DOC_ADMIN_SOLUTION_GROUPS_SEND = 'TECH_DOC_ADMIN_SOLUTION_GROUPS_SEND';

export const TECH_DOC_SOLUTION_GROUPS_EDITOR_ROLE_SEND = 'TECH_DOC_SOLUTION_GROUPS_EDITOR_ROLE_SEND';

export const setManagedUser = managedUser => ({
    type: MANAGED_SOLUTION_GROUPS_EDITOR,
    payload: managedUser
});

export const setInitialSGEditorOptions = countryOptions => ({
    type: INITIAL_SOLUTION_GROUPS_EDITOR_OPTIONS,
    payload: countryOptions
});

export const setSelectedSGEditorOptions = sgOptions => ({
    type: SELECTED_SOLUTION_GROUPS_EDITOR_OPTIONS,
    payload: sgOptions
});

export const setSGEditorOptionsChanged = countriesChanged => ({
    type: SOLUTION_GROUPS_EDITOR_OPTIONS_CHANGED,
    payload: countriesChanged
});

export const fetchSGEditorRightsManagementPageData = (user, domain) => (dispatch) => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUPS_USER_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.SPARE_PARTS: {
            return dispatch({
                type: SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUPS_USER_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
    }
};

export const saveSGEditorRightsManagementPageData = (user, domain, userData) => dispatch => {
    const userDataToSend = userData.filter(x => x !== null).map(sg => ({
        id: sg.value ? sg.value : sg.id,
        name: sg.label ? sg.label : sg.name,
        groups: sg.groups,
        isDefault: sg.isDefault,
        isActive: sg.isActive,
        canForwardToDealer: sg.canForwardToDealer
    }))

    const {error} = validateSolutionGroupEditorData(userDataToSend);

    if (error) {
        dispatch({type: SOLUTION_GROUPS_EDITOR_RIGHTS_ERROR});
        dispatch(errorAlert(alertMessages.RIGHTS_EDITOR_SEND_ERROR, [error.toString()]));
    } else {
        switch (domain) {
            case domains.TECHNICAL_DOCUMENTATION: {
                return dispatch({
                    type: SOLUTION_GROUPS_EDITOR_RIGHTS_SAVE_PENDING,
                    techDocWebsocket: true,
                    command: techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUPS_USER_ROLE_SET,
                    payload: {role: {sgEditor: {sgPermission: userDataToSend}, id: user.ipn, mail: user.mail}}
                });
            }
            case domains.SPARE_PARTS: {
                return dispatch({
                    type: SOLUTION_GROUPS_EDITOR_RIGHTS_SAVE_PENDING,
                    sparePartsWebsocket: true,
                    command: sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUPS_USER_ROLE_SET,
                    payload: {role: {sgEditor: {sgPermission: userDataToSend}, id: user.ipn, mail: user.mail}}
                });
            }
        }
    }
};

export const clearSGEditorRightsManagementPageData = () => ({
    type: SOLUTION_GROUPS_EDITOR_RIGHTS_CLEAR
});

