import {
    sparePartsWebsocketCommands
} from '../../constants/sparePartsWebsocketCommands';

export const fetchOrderTypeFilter = () => (dispatch) => {
    dispatch({
        type: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_ORDER_TYPE_FILTER,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_ORDER_TYPE_FILTER,
        payload: {}
    });
};
