import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const CLAIMS_RECURRENT_PROBLEM_PENDING = 'CLAIMS_RECURRENT_PROBLEM_PENDING';
export const CLAIMS_RECURRENT_PROBLEM_SELECT_GROUP = 'CLAIMS_RECURRENT_PROBLEM_SELECT_GROUP';

export const fetchClaimsRecurrentProblems = (domain, group) => dispatch => {
    return dispatch({
        type: CLAIMS_RECURRENT_PROBLEM_PENDING,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_GET,
        payload: {group}
    });
};

export const storeClaimsSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: CLAIMS_RECURRENT_PROBLEM_SELECT_GROUP,
        payload: countryGroup
    });
