import {domains} from '../../constants/Utils';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const ADMIN_TICKET_SETTINGS_FETCH_PENDING = 'ADMIN_TICKET_SETTINGS_FETCH_PENDING';
export const ADMIN_TICKET_SETTINGS_SELECT_GROUP = 'ADMIN_TICKET_SETTINGS_SELECT_GROUP';
export const ADMIN_TICKET_SETTINGS_UPDATE_PENDING = 'ADMIN_TICKET_SETTINGS_UPDATE_PENDING';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_TICKET_SETTINGS_SELECT_GROUP,
        payload: countryGroup
    });

export const fetchTicketSettingsData = (domain, group) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_TICKET_SETTINGS_GET,
                payload: {group}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_TICKET_SETTINGS_GET,
                payload: {group}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_FETCH_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_TICKET_SETTINGS_GET,
                payload: {group}
            });
        }
    }
};

export const updateTicketSettingsData = (domain, settings) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_UPDATE_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_TICKET_SETTINGS_SET,
                payload: {settings}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_UPDATE_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_TICKET_SETTINGS_SET,
                payload: {settings}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_TICKET_SETTINGS_UPDATE_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_TICKET_SETTINGS_SET,
                payload: {settings}
            });
        }
    }
}
