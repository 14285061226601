import React, {Component} from 'react';
import {connect} from 'react-redux';
import {clearAlerts} from '../../../actions/alertsActions';
import set from 'set-value';
import get from 'get-value';
import {Redirect, withRouter} from 'react-router-dom';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import {fetchAdminClientIds} from '../../../actions/admin/actionAdminClientIds';
import {
    fetchSparePartsOrdersTicket,
    resetButCorrelationIdTicketOrder,
    resetTicketOrder,
    saveTicketOrder
} from '../../../actions/orders/actionTicket';
import Loader from '../../Loader';
import {
    ALLOCATION_REQUEST_FORM,
    DELIVERY_DATE_REMINDER_FORM, EV_BATTERY_FORM,
    ORDER_CANCELLATION_FORM,
    ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
    VOR_PVI_ORDER_FORM
} from '../../../constants/formConstants';
import DeliveryDateReminderMasterForm from './forms/DeliveryDateReminderMasterForm';
import AllocationRequestMasterForm from './forms/AllocationRequestMasterForm';
import VorPviOrderMasterForm from './forms/VorPviOrderMasterForm';
import PartNoLongerAvailableMasterForm from './forms/PartNoLongerAvailableMasterForm';
import OrderCancellationMasterForm from './forms/OrderCancellationMasterForm';
import {lockSparePartsOrdersTicket} from '../../../actions/orders/actionLockTicket';
import UploadingAttachments from './UploadingAttachments';
import {initNewBatteryTicket, initNewTicket, transformNewTicketForSave} from '../Utils';
import {createRights} from '../../../constants/sparePartsTicketRights';
import NoBirComponent from '../../common/NoBirComponent';
import {createReqFields} from '../../../constants/sparePartsRequiredFields';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import PageHeader from '../../common/PageHeader';
import PropTypes from 'prop-types';
import checkRoles from '../../common/roleChecker/RoleChecker';
import OverlayLoader from '../../OverlayLoader';
import formMessages from '../../../intl/sparePartsOrders/formMessages';
import {closeSparePartDicModal} from '../../../actions/orders/actionSparePartsDicData';
import EVBatteryMasterForm from './forms/EVBatteryMasterForm';

class NewSparePartTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false};
    }

    componentDidMount() {
        this.props.resetButCorrelationIdTicketOrder();
        this.props.clearAlerts();
        this.props.fetchAdminClientIds();
    }

    handleSubmit = (values) => {
        const dataForSend = transformNewTicketForSave(values.newValues);
        this.props.saveTicketOrder(dataForSend);
        this.setState({ticketSubmittedAndShouldRedirect: true});
    };

    render() {
        const {
            isLoadingClientIds,
            ticketData,
            attachmentsData,
            userDetail,
            formType,
            clientIds,
            closeSparePartDicModal,
            dicInfo: {isModalActive}
        } = this.props;

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingAttachments attachments={attachmentsData.attachmentsForUpload}
                                         attachmentsUrl={ticketData.newAttachments}
                                         correlationId={ticketData.correlationId}
                                         isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                         formType={formType}/>
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/order/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (isLoadingClientIds
            || ticketData.isTicketCreating
            || (ticketData.isTicketLoading && !this.props.location.redirectAfterAllocationReqSwitchToVor)
            || !formType
            || this.state.ticketSubmittedAndShouldRedirect
            || clientIds.isLoading
        ) {
            return <Loader/>;
        }

        let initializationValue = {};
        if (this.props.location.redirectAfterAllocationReqSwitchToVor) {
            initializationValue = this.props.location.initData;
        } else if(formType === EV_BATTERY_FORM){
            initializationValue = initNewBatteryTicket(userDetail);
            if (!initializationValue) {
                return <NoBirComponent formType={formType}/>;
            }
        } else {
            initializationValue = initNewTicket(userDetail);
            if (!initializationValue) {
                return <NoBirComponent formType={formType}/>;
            }
        }
        const requiredFields = createReqFields[createReqFields[userDetail.group] ? userDetail.group : 'DEFAULT'][formType];

        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={<ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>}/>
                <div>
                    {formType === DELIVERY_DATE_REMINDER_FORM &&
                    <DeliveryDateReminderMasterForm form={formType}
                                                    initialValues={set(initializationValue, 'ticketSection.orderType', DELIVERY_DATE_REMINDER_FORM)}
                                                    reqFields={requiredFields}
                                                    userRights={createRights}
                                                    enableReinitialize={true}
                                                    handleSubmit={this.handleSubmit}/>
                    }
                    {formType === ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM &&
                    <PartNoLongerAvailableMasterForm form={formType}
                                                     initialValues={set(initializationValue, 'ticketSection.orderType', ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM)}
                                                     reqFields={requiredFields}
                                                     userRights={createRights}
                                                     enableReinitialize={true}
                                                     handleSubmit={this.handleSubmit}/>
                    }
                    {formType === ORDER_CANCELLATION_FORM &&
                    <OrderCancellationMasterForm form={formType}
                                                 initialValues={set(initializationValue, 'ticketSection.orderType', ORDER_CANCELLATION_FORM)}
                                                 reqFields={requiredFields}
                                                 userRights={createRights}
                                                 enableReinitialize={true}
                                                 handleSubmit={this.handleSubmit}/>
                    }
                    {formType === VOR_PVI_ORDER_FORM &&
                    <VorPviOrderMasterForm form={formType}
                                           initialValues={set(initializationValue, 'ticketSection.orderType', VOR_PVI_ORDER_FORM)}
                                           reqFields={requiredFields}
                                           userRights={createRights}
                                           enableReinitialize={true}
                                           handleSubmit={this.handleSubmit}/>
                    }
                    {formType === ALLOCATION_REQUEST_FORM &&
                    <AllocationRequestMasterForm form={formType}
                                                 initialValues={set(initializationValue, 'ticketSection.orderType', ALLOCATION_REQUEST_FORM)}
                                                 reqFields={requiredFields}
                                                 userRights={createRights}
                                                 enableReinitialize={true}
                                                 handleSubmit={this.handleSubmit}/>
                    }
                    {formType === EV_BATTERY_FORM &&
                    <EVBatteryMasterForm form={formType}
                                           initialValues={set(initializationValue, 'ticketSection.orderType', EV_BATTERY_FORM)}
                                           reqFields={requiredFields}
                                           userRights={createRights}
                                           enableReinitialize={true}
                                           handleSubmit={this.handleSubmit}/>
                    }
                </div>
                <OverlayLoader active={isModalActive}
                               text={formMessages.WAIT_DIC}
                               handleClose={closeSparePartDicModal}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    clearAlerts: PropTypes.func.isRequired,
    isLoadingClientIds: state.adminClientIds.isLoading,
    ticketData: state.sparePartsOrderTicket,
    attachmentsData: state.sparePartsOrderAttachment,
    userDetail: state.profile.userDetail,
    clientIds: state.adminClientIds,
    dicInfo: state.sparePartsDic,
});

NewSparePartTicketPage.propTypes = {
    resetTicketOrder: PropTypes.func.isRequired,
    resetButCorrelationIdTicketOrder: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    fetchAdminClientIds: PropTypes.func.isRequired,
    clientIds: PropTypes.object.isRequired,
    saveTicketOrder: PropTypes.func.isRequired,
    isLoadingClientIds: PropTypes.bool.isRequired,
    ticketData: PropTypes.object.isRequired,
    attachmentsData: PropTypes.object.isRequired,
    userDetail: PropTypes.object.isRequired,
    formType: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    dicInfo: PropTypes.object.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchSparePartsOrdersTicket,
    fetchAdminClientIds,
    saveTicketOrder,
    resetTicketOrder,
    resetButCorrelationIdTicketOrder,
    lockSparePartsOrdersTicket,
    closeSparePartDicModal
})(NewSparePartTicketPage)), ['SP_DEALER']);

