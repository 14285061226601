import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    MATRA: {
        id: 'techDoc.supplier.option.matra',
        defaultMessage: 'Matra'
    },
    RENAULT_DACIA: {
        id: 'techDoc.supplier.option.renaultDacia',
        defaultMessage: 'Renault / Dacia'
    },
    ALPINE: {
        id: 'techDoc.supplier.option.alpine',
        defaultMessage: 'Alpine (Renault Sport Technologies)'
    },
});

export default messages;
