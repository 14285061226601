export function transformSolutionGroupsToFlowTree(solutionGroups) {
    const result = [];
    solutionGroups.forEach(solutionGroup => attachSolutionGroupAsNode(result, solutionGroup));
    return result;
}

const attachSolutionGroupAsNode = (tree, solutionGroup) => {
    if (tree.length === 0) {
        tree.push({id: solutionGroup.id, name: solutionGroup.name, parent: null, children: []});
    } else {
        const firstTree = tree[0];
        if (firstTree.children.length === 0) {
            firstTree.children.push({
                id: solutionGroup.id,
                name: solutionGroup.name,
                parent: {id: firstTree.id},
                children: []
            });
        } else {
            attachSolutionGroupAsNode(firstTree.children, solutionGroup);
        }
    }
};
