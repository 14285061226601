import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.workingHours.title',
        defaultMessage: 'Working hours'
    },
    SELECT_COUNTRY: {
        id: 'admin.workingHours.selectCountry',
        defaultMessage: 'Select a country'
    },
    SUB_TITLE: {
        id: 'admin.workingHours.subTitle',
        defaultMessage: 'Working hours'
    },
    BANK_HOLIDAYS_TITLE: {
        id: 'admin.workingHours.bankHoliday.title',
        defaultMessage: 'Bank holidays'
    }
});

export default messages;
