import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../../Loader';
import {
    initNewEscalationSchema,
    initNewEscalationSchemaAfterFail,
    transformNewEscalationSchema,
} from '../../../../utils/escalationSchemaUtils';
import { createReqFields } from '../../../../constants/escalationSchemaRequiredFields';
import PageHeader from '../../../common/PageHeader';
import get from 'get-value';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {
    fetchEscalationSchema,
    redirectReset,
    resetEscalationSchema,
    saveEscalationSchema
} from '../../../../actions/admin/actionEscalationSchema';
import {ESCALATION_SCHEMA_FORM} from '../../../../constants/formConstants';
import {FormattedMessage, injectIntl} from 'react-intl';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import EscalationSchemaForm from './forms/EscalationSchemaForm';
import {
    fetchEscalationSchemaSGOptions,
} from '../../../../actions/admin/actionEscalationSchemaOptions';
import {getAdminEscalationSchemaPath} from '../../../../routes/pathResolver';

class NewEscalationSchemaPage extends Component {

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetEscalationSchema(this.props.domain);
        this.props.fetchEscalationSchemaSGOptions(this.props.domain);
    }
    componentWillUnmount() {
        this.props.redirectReset(this.props.domain);
    }

    handleSubmit = ({newValues}) => {
        const dataForSend = transformNewEscalationSchema(this.props.domain, newValues);

        this.setState(dataForSend)
        this.props.saveEscalationSchema(this.props.domain, dataForSend);
    };

    render() {
        const {
            escalationSchemaData,
            domain,
            intl: {formatMessage},
        } = this.props;

        const {
            escalationSchemaSubmittedAndShouldRedirect: shouldRedirect,
            isEscalationSchemaCreating: creating,
            isEscalationSchemaLoading: loading,
            escalationSchema: schema,
            initWithOldData
        } = escalationSchemaData;

        const sgGroup = get(escalationSchemaData, 'escalationSchema.solutionGroupId');

        if (shouldRedirect && !creating && !loading && sgGroup) {
            return <Redirect to={{
                pathname: `${getAdminEscalationSchemaPath(this.props.domain)}/view/${schema.formType}-${schema.solutionGroupId}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (creating || loading || shouldRedirect) {
            return <Loader/>;
        }

        let initializationValue;
        if (!creating && !loading && !shouldRedirect && initWithOldData && this.state){
            initializationValue = initNewEscalationSchemaAfterFail(this.state, formatMessage);
        } else {
            initializationValue = initNewEscalationSchema();
        }

        const requiredFields = createReqFields['DEFAULT'][ESCALATION_SCHEMA_FORM];
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.escalationSchema.title"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - Escalation Schema"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <div className="container-fluid">
                    <div className="col-12">
                        <EscalationSchemaForm
                            form={ESCALATION_SCHEMA_FORM}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            domain={domain}
                            creating={true}
                            handleSubmit={this.handleSubmit}
                            type={'new'}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

NewEscalationSchemaPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    loggedUser: PropTypes.object.isRequired,
    escalationSchemaData: PropTypes.object.isRequired,
    fetchEscalationSchema: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any,
    resetEscalationSchema: PropTypes.func.isRequired,
    saveEscalationSchema: PropTypes.func.isRequired,
    isLoadingProfile: PropTypes.bool.isRequired,
    userDetail: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    clearAlerts: PropTypes.func.isRequired,
    escalationSchemaData: state.adminEscalationSchema,
    userDetail: state.profile.userDetail,
    isLoadingProfile: state.profile.isLoading,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchEscalationSchema,
    saveEscalationSchema,
    resetEscalationSchema,
    redirectReset,
    fetchEscalationSchemaSGOptions,
})(injectIntl(NewEscalationSchemaPage))), ['TD_POWER_USER', 'SP_POWER_USER']);

