import {defineMessages} from 'react-intl';

const messages = defineMessages({
    // FORM
    TICKET: {
        id: 'claims.ticket.ticket',
        defaultMessage: 'Ticket'
    },
    // ticket section
    TICKET_SECTION: {
        id: 'claims.ticket.header.dealerInformation',
        defaultMessage: 'Dealer information'
    },
    DEALER_NAME: {
        id: 'claims.ticket.label.dealerName',
        defaultMessage: 'Dealer Name'
    },
    DEALER_NUMBER: {
        id: 'claims.ticket.label.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    CLIENT_ID: {
        id: 'claims.ticket.label.clientId',
        defaultMessage: 'Client ID'
    },
    REGION: {
        id: 'claims.ticket.label.region',
        defaultMessage: 'Region'
    },
    EMAIL: {
        id: 'claims.ticket.label.email',
        defaultMessage: 'E-mail'
    },
    TELEPHONE_NUMBER: {
        id: 'claims.ticket.label.telephoneNumber',
        defaultMessage: 'Telephone number'
    },
    CREATION_DATE: {
        id: 'claims.ticket.label.creationDate',
        defaultMessage: 'Creation Date'
    },
    // orders section
    ORDERS_SECTION: {
        id: 'claims.ticket.header.orders',
        defaultMessage: 'Orders'
    },
    SUBJECT_TO_VOR: {
        id: 'claims.ticket.table.subjectToVor',
        defaultMessage: 'Subject to VOR'
    },
    PROVIDER: {
        id: 'claims.ticket.table.provider',
        defaultMessage: 'Provider'
    },
    RECEIVER: {
        id: 'claims.ticket.table.receiver',
        defaultMessage: 'Receiver'
    },
    DHL: {
        id: 'claims.ticket.table.dhl',
        defaultMessage: 'DHL'
    },
    FLASH: {
        id: 'claims.ticket.table.flash',
        defaultMessage: 'FLASH'
    },
    OTHERS: {
        id: 'claims.ticket.table.others',
        defaultMessage: 'Others'
    },
    DELAY_CREDIT: {
        id: 'claims.ticket.table.delayCredit',
        defaultMessage: 'Delay credit'
    },
    DELAY_INVOICE: {
        id: 'claims.ticket.table.delayInvoice',
        defaultMessage: 'Delay invoice'
    },
    TROUBLESHOOTING_NOT_RECEIVED: {
        id: 'claims.ticket.table.troubleshootingNotReceived',
        defaultMessage: 'Troubleshooting not received'
    },
    MPR88: {
        id: 'claims.ticket.label.mpr88',
        defaultMessage: 'Order MPR88'
    },
    WAIT_DIC: {
        id: 'claims.ticket.dic.loadingMessage',
        defaultMessage: 'Resolving DIC response, please wait...'
    },
     // problem description section
    PROBLEM_DESCRIPTION_SECTION: {
        id: 'claims.ticket.header.problemDescriptionSection',
        defaultMessage: 'Problem Description'
    },
    CLAIM_RELATED: {
        id: 'claims.ticket.label.claimRelated',
        defaultMessage: 'Claim Related No.'
    },
    INVOICE: {
        id: 'claims.ticket.label.invoice',
        defaultMessage: 'Invoice No.'
    },
    MISSING_PACKAGE: {
        id: 'claims.ticket.label.missingPackage',
        defaultMessage: 'Missing Package No.'
    },
    WRONG_DESTINATION: {
        id: 'claims.ticket.label.wrongDestination',
        defaultMessage: 'Package Destination Error No.'
    },
    PROBLEM_DESCRIPTION: {
        id: 'claims.ticket.label.problemDescription',
        defaultMessage: 'Problem Description'
    },
    ORDER_INVOICED: {
        id: 'claims.ticket.table.orderInvoiced',
        defaultMessage: 'Order Invoiced'
    },
    ORDER: {
        id: 'claims.ticket.table.order',
        defaultMessage: 'Order'
    },
    LINE: {
        id: 'claims.ticket.table.line',
        defaultMessage: 'Line'
    },
    CODE_PROD: {
        id: 'claims.ticket.table.codeProd',
        defaultMessage: 'Code Prod'
    },
    PART_NUMBER: {
        id: 'claims.ticket.table.partNo',
        defaultMessage: 'Part No.'
    },
    SP_TICKET_NUMBER_REFERENCE: {
        id: 'claims.ticket.table.spTicketNumberReference',
        defaultMessage: 'Related New HTL Ticket Number VOR/PVI'
    },
    DIC_NUMBER: {
        id: 'claims.ticket.table.dicNumber',
        defaultMessage: 'N° DIC'
    },
    USER_STATUS: {
        id: 'claims.ticket.table.userStatus',
        defaultMessage: 'User Status'
    },
    CLIENT_SOLUTION_PILOT: {
        id: 'claims.ticket.table.clientSolutionPilot',
        defaultMessage: 'Client Solution Pilot'
    },
    TRANSPORT_PROVIDER: {
        id: 'claims.ticket.table.transportProvider',
        defaultMessage: 'Transport Provider'
    },
    TRANSPORT_NUMBER: {
        id: 'claims.ticket.table.transportNumber',
        defaultMessage: 'Transport Number'
    },
    CLAIM_TYPE: {
        id: 'claims.ticket.table.claimType',
        defaultMessage: 'Claim Type'
    },
    QUANTITY: {
        id: 'claims.ticket.table.quantity',
        defaultMessage: 'QTY'
    },
    DATE: {
        id: 'claims.ticket.table.date',
        defaultMessage: 'Date'
    },
    PACKAGE_NO: {
        id: 'claims.ticket.table.packageNo',
        defaultMessage: 'Package No.'
    },
    ORDER_RECEIVED: {
        id: 'claims.ticket.table.orderReceived',
        defaultMessage: 'Order Received'
    },
    DESCRIPTION: {
        id: 'claims.ticket.table.description',
        defaultMessage: 'Description'
    },
    QUANTITY_TOTAL: {
        id: 'claims.ticket.table.quantityTotal',
        defaultMessage: 'QTY Total'
    },
    DEFECTIVE: {
        id: 'claims.ticket.table.defective',
        defaultMessage: 'Defective'
    },
    DAMAGED: {
        id: 'claims.ticket.table.damaged',
        defaultMessage: 'Damaged'
    },
    RETURN: {
        id: 'claims.ticket.table.return',
        defaultMessage: 'Return'
    },
    OBSERVATION: {
        id: 'claims.ticket.table.observation',
        defaultMessage: 'Observation'
    },
    TAKING_BACK: {
        id: 'claims.ticket.table.takingBack',
        defaultMessage: 'Taking Back No.'
    },
    CONTAINER: {
        id: 'claims.ticket.table.container',
        defaultMessage: 'Container No.'
    },
    DEALER_REMARKS: {
        id: 'claims.ticket.table.dealerRemarks',
        defaultMessage: 'Dealer Remarks'
    },
    SUPPLIER: {
        id: 'claims.ticket.table.supplier',
        defaultMessage: 'Supplier'
    },
    CREDIT_NOTE: {
        id: 'claims.ticket.table.creditNote',
        defaultMessage: 'Supplier Credit Note No.'
    },
    CREDIT_NOTE_DATE: {
        id: 'claims.ticket.table.creditNoteDate',
        defaultMessage: 'Credit Note Date'
    },
    SUPPLIER_PART_NO: {
        id: 'claims.ticket.table.supplierPartNo',
        defaultMessage: 'Supplier Part No.'
    },
    BILL_OF_DELIVERY: {
        id: 'claims.ticket.table.billOfDelivery',
        defaultMessage: 'Bill Of Delivery No.'
    },
    ACCOUNTING_CREDIT_NOTE: {
        id: 'claims.ticket.table.accountingCreditNote',
        defaultMessage: 'DLPA Accounting Credit Note No.'
    },
    ACCOUNTING_CREDIT_NOTE_DATE: {
        id: 'claims.ticket.table.accountingCreditNoteDate',
        defaultMessage: 'Accounting Credit Note Date'
    },
    // message section
    MESSAGES_SECTION: {
        id: 'claims.ticket.header.messages',
        defaultMessage: 'Messages'
    },
    MESSAGES_HISTORY: {
        id: 'claims.ticket.label.messagesHistory',
        defaultMessage: 'History of Messages'
    },
    MESSAGE: {
        id: 'claims.ticket.label.dialogBox',
        defaultMessage: 'DialogBox'
    },
    // editor's motes section
    EDITORS_NOTES_SECTION: {
        id: 'claims.ticket.header.editorsNotes',
        defaultMessage: 'Internal Notes'
    },
    EDITORS_NOTES_HISTORY: {
        id: 'claims.ticket.label.editorsNotesHistory',
        defaultMessage: 'History of Internal Notes'
    },
    EDITORS_NOTES: {
        id: 'claims.ticket.label.editorsNotes',
        defaultMessage: 'Note'
    },
    EDITORS_NOTE_ATTACHMENTS: {
        id: 'claims.ticket.label.editorsNoteAttachments',
        defaultMessage: 'Attachment'
    },
    // notes section
    NOTE_SECTION: {
        id: 'claims.ticket.header.notes',
        defaultMessage: 'Dealer Notes (Dedicated zone for communication with Dealers)'
    },
    // attachment section
    ATTACHMENTS_SECTION: {
        id: 'claims.ticket.header.attachments',
        defaultMessage: 'Attachments'
    },
    DEALER_ATTACHMENTS: {
        id: 'claims.ticket.label.dealerAttachments',
        defaultMessage: 'Dealer Attachments'
    },
    EDITOR_ATTACHMENTS: {
        id: 'claims.ticket.label.editorAttachments',
        defaultMessage: 'Editor Attachments'
    },
    NEW_ATTACHMENTS: {
        id: 'claims.ticket.attachments.new',
        defaultMessage: 'New attachments'
    },
    UPLOADING_ATTACHMENTS: {
        id: 'claims.ticket.attachments.uploadingAttachments',
        defaultMessage: 'Ticket was saved, uploading attachments...'
    },
    MANDATORY_ATTACHMENT_MSG1: {
        id: 'claims.ticket.attachments.mandatoryAttachmentMsg1',
        defaultMessage: 'Please attach your collection documents.'
    },
    MANDATORY_ATTACHMENT_MSG2: {
        id: 'claims.ticket.attachments.mandatoryAttachmentMsg2',
        defaultMessage: 'Please attach your BL and Renault invoice file.'
    },
});

export default messages;
