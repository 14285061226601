import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const fetchTechDocDealers = () => (dispatch) => {
    dispatch({
        type: techDocWebsocketCommands.TECH_DOC_DEALERS_GET,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_DEALERS_GET,
        payload: {}
    });
};
