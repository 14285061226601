import {CLAIMS_CREATE_TICKET, CLAIMS_GET_TICKET, CLAIMS_UPDATE_TICKET} from '../../constants/websocketCommands';

export const CLAIMS_TICKET_RESET = 'CLAIMS_TICKET_RESET';
export const CLAIMS_TICKET_CREATE = 'CLAIMS_TICKET_CREATE';
export const CLAIMS_TICKET_UPDATE = 'CLAIMS_TICKET_UPDATE';

export const updateClaimsTicket = (data) => dispatch => {
    return dispatch({
        type: CLAIMS_TICKET_UPDATE,
        claimsWebsocket: true,
        command: CLAIMS_UPDATE_TICKET,
        payload: {...data}
    });
};

export const saveClaimsTicket = (data) => dispatch => {
    return dispatch({
        type: CLAIMS_TICKET_CREATE,
        claimsWebsocket: true,
        command: CLAIMS_CREATE_TICKET,
        payload: {...data}
    });
};

export const fetchClaimsTicket = (uuid) => dispatch => {
    return dispatch({
        type: CLAIMS_GET_TICKET,
        claimsWebsocket: true,
        command: CLAIMS_GET_TICKET,
        payload: {
            ticket: {uuid: uuid}
        }
    });
};

export const resetClaimsTicket = () => dispatch => {
    return dispatch({
        type: CLAIMS_TICKET_RESET,
    });
};
