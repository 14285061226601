import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const initiateTechDocManagementGroupBatch = (spareParts) => (dispatch) => {
    dispatch({
        type: techDocWebsocketCommands.TECH_DOC_MANAGEMENT_GROUP_CREATE,
        techDocWebsocket: true,
        payload: {
            spareParts
        },
        command: techDocWebsocketCommands.TECH_DOC_MANAGEMENT_GROUP_CREATE,
    });
};

export const checkTechDocManagementGroupBatch = () => (dispatch) => {
    dispatch({
        type: techDocWebsocketCommands.TECH_DOC_MANAGEMENT_GROUP_GET,
        techDocWebsocket: true,
        payload: {},
        command: techDocWebsocketCommands.TECH_DOC_MANAGEMENT_GROUP_GET,
    });
};
