import React, {Component} from 'react';
import styled from 'styled-components';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import {FormattedMessage, injectIntl, FormattedDate, FormattedTime} from 'react-intl';
import {connect} from 'react-redux';
import {PrimaryButton} from '../../common/Button';
import {InputFieldWithValidation} from '../../common/InputFieldWithValidation';
import RadioFields from '../../common/RadioFields';
import {
    domains,
    getInternalTicketFormStates,
    internalTicketRequestType,
    requestForPartnerList,
} from '../../../constants/Utils';
import InputSelectFieldWithValidation from '../../common/InputSelectFieldWithValidation';
import buttonMessages from '../../../intl/common/buttonMessages';
import statusMessages from '../../../intl/common/formStatesMessages';
import exportConstantMessages from '../../../intl/common/exportMessages';
import DatePickerComponent from '../../common/datePicker/DatePickerComponent';
import moment from 'moment';
import PropTypes from 'prop-types';
import charsetMessages from '../../../intl/charsetMessages';
import {
    BUTTON_LABEL_DOWNLOAD,
    BUTTON_LABEL_EXPORT,
} from '../../../constants/formConstants';
import {
    CSV,
    DOC_EXPORT_INTERNAL_FORM_FIELD_CHARSET,
    DOC_EXPORT_INTERNAL_FORM_FIELD_FROM,
    DOC_EXPORT_INTERNAL_FORM_FIELD_HAS_BEEN_ASSIGNED_TO,
    DOC_EXPORT_INTERNAL_FORM_FIELD_IS_ASSIGNED_TO,
    DOC_EXPORT_INTERNAL_FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
    DOC_EXPORT_INTERNAL_FORM_FIELD_PARTNER,
    DOC_EXPORT_INTERNAL_FORM_FIELD_REQUEST_TYPE,
    DOC_EXPORT_INTERNAL_FORM_FIELD_STATUS,
    DOC_EXPORT_INTERNAL_FORM_FIELD_TO, DOC_EXPORT_INTERNAL_FORM_FIELD_TYPE,
    DOC_EXPORT_INTERNAL_FORM_FIELD_VEHICLE_FAMILY, DOC_EXPORT_INTERNAL_FORM_LABEL_CHARSET,
    DOC_EXPORT_INTERNAL_FORM_LABEL_FROM,
    DOC_EXPORT_INTERNAL_FORM_LABEL_HAS_BEEN_ASSIGNED_TO,
    DOC_EXPORT_INTERNAL_FORM_LABEL_IS_ASSIGNED_TO,
    DOC_EXPORT_INTERNAL_FORM_LABEL_N_REFERENCE_CAUSING_PROBLEM,
    DOC_EXPORT_INTERNAL_FORM_LABEL_PARTNER,
    DOC_EXPORT_INTERNAL_FORM_LABEL_REQUEST_TYPE,
    DOC_EXPORT_INTERNAL_FORM_LABEL_STATUS,
    DOC_EXPORT_INTERNAL_FORM_LABEL_TO, DOC_EXPORT_INTERNAL_FORM_LABEL_TYPE,
    DOC_EXPORT_INTERNAL_FORM_LABEL_VEHICLE_FAMILY,
    ticketExportTypes
} from '../../../constants/exportConstant';
import {moduleRoles} from '../../../utils/roles';
import { uniqBy } from 'lodash';
import get from 'get-value';
import internalTicketExportMessages from '../../../intl/technicalDocumentations/internalTicketExportMessages';
import internalTicketRequestTypeMessages from '../../../intl/technicalDocumentations/internalTicketRequestTypeMessages';
import requestForPartnerListMessages from '../../../intl/sparePartsOrders/requestForPartnerListMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const status = (() => {
    const arr = getInternalTicketFormStates(domains.TECHNICAL_DOCUMENTATION);
    arr.splice(2, 1);
    return arr;
})();

class InternalTicketExportTechDocForm extends Component {

    render() {
        const {handleSubmit, exportRecord, downloadExport, exportType, startDate, endDate, charSets, roles} = this.props;
        let sgOptions = [];

        if(moduleRoles.isSGEditor(roles, domains.TECHNICAL_DOCUMENTATION) && (get(roles, 'TECHNICAL_DOCUMENTATION.sgEditor.sgPermission.length') > 0)){
            const sgEditorOptions = (get(roles, 'TECHNICAL_DOCUMENTATION.sgEditor.sgPermission') || []).map(sg => ({
                uuid: sg.id,
                presetName: sg.name
            }))
            sgOptions = sgOptions.concat(sgEditorOptions);
        }
        if(moduleRoles.isSGAdmin(roles, domains.TECHNICAL_DOCUMENTATION) && (get(roles, 'TECHNICAL_DOCUMENTATION.sgAdmin.sgPermission.length') > 0)){
            const sgAdminOptions = (get(roles, 'TECHNICAL_DOCUMENTATION.sgAdmin.sgPermission') || []).map(sg => ({
                uuid: sg.id,
                presetName: sg.name
            }))
            sgOptions = sgOptions.concat(sgAdminOptions);
        }


        return (
            <StyledDiv className="border border-secondary p-4 bg-white">
                <div className="row justify-content-center">
                    <div className="col-xl-8">
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <Field component={DatePickerComponent}
                                       selectsStart
                                       label={internalTicketExportMessages[DOC_EXPORT_INTERNAL_FORM_LABEL_FROM]}
                                       name={DOC_EXPORT_INTERNAL_FORM_FIELD_FROM}
                                       startDate={startDate}
                                       endDate={endDate}
                                       parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <Field component={DatePickerComponent}
                                       selectsEnd
                                       label={internalTicketExportMessages[DOC_EXPORT_INTERNAL_FORM_LABEL_TO]}
                                       name={DOC_EXPORT_INTERNAL_FORM_FIELD_TO}
                                       startDate={startDate}
                                       endDate={endDate}
                                       parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={internalTicketExportMessages[DOC_EXPORT_INTERNAL_FORM_LABEL_STATUS]}
                                                                field={DOC_EXPORT_INTERNAL_FORM_FIELD_STATUS}
                                                                options={status}
                                                                intlMessages={statusMessages}
                                                                isSmall/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={internalTicketExportMessages[DOC_EXPORT_INTERNAL_FORM_LABEL_IS_ASSIGNED_TO]}
                                                                field={DOC_EXPORT_INTERNAL_FORM_FIELD_IS_ASSIGNED_TO}
                                                                customOptionsMsg={uniqBy(sgOptions, (e => (e.uuid)))}
                                                                isSmall/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={internalTicketExportMessages[DOC_EXPORT_INTERNAL_FORM_LABEL_HAS_BEEN_ASSIGNED_TO]}
                                                                field={DOC_EXPORT_INTERNAL_FORM_FIELD_HAS_BEEN_ASSIGNED_TO}
                                                                customOptionsMsg={uniqBy(sgOptions, (e => (e.uuid)))}
                                                                isSmall/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={internalTicketExportMessages[DOC_EXPORT_INTERNAL_FORM_LABEL_REQUEST_TYPE]}
                                                                field={DOC_EXPORT_INTERNAL_FORM_FIELD_REQUEST_TYPE}
                                                                options={internalTicketRequestType}
                                                                intlMessages={internalTicketRequestTypeMessages}
                                />
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={internalTicketExportMessages[DOC_EXPORT_INTERNAL_FORM_LABEL_PARTNER]}
                                                                field={DOC_EXPORT_INTERNAL_FORM_FIELD_PARTNER}
                                                                options={requestForPartnerList}
                                                                intlMessages={requestForPartnerListMessages}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldWithValidation label={internalTicketExportMessages[DOC_EXPORT_INTERNAL_FORM_LABEL_VEHICLE_FAMILY]}
                                                          field={DOC_EXPORT_INTERNAL_FORM_FIELD_VEHICLE_FAMILY}
                                                          maxLength={4}
                                                          isSmall
                                />
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldWithValidation label={internalTicketExportMessages[DOC_EXPORT_INTERNAL_FORM_LABEL_N_REFERENCE_CAUSING_PROBLEM]}
                                                          field={DOC_EXPORT_INTERNAL_FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM}
                                                          maxLength={10}
                                                          isSmall
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <RadioFields
                                    label={internalTicketExportMessages[DOC_EXPORT_INTERNAL_FORM_LABEL_TYPE]}
                                    field={DOC_EXPORT_INTERNAL_FORM_FIELD_TYPE}
                                    options={ticketExportTypes}
                                    intlMessages={internalTicketExportMessages}/>
                                <InputSelectFieldWithValidation label={internalTicketExportMessages[DOC_EXPORT_INTERNAL_FORM_LABEL_CHARSET]}
                                                                field={DOC_EXPORT_INTERNAL_FORM_FIELD_CHARSET}
                                                                options={charSets}
                                                                intlMessages={charsetMessages}
                                                                isDisabled={!exportType}
                                                                noEmpty
                                                                isSmall/>
                            </div>
                        </div>
                        <form className="form-group">
                            {(Object.keys(exportRecord).length > 0) &&
                            <div className="">
                                <div>
                                    <span className="font-weight-bold">
                                        <FormattedMessage {...internalTicketExportMessages.LAST_EXPORT}/>{' '}
                                    </span>
                                    <FormattedDate value={new Date(exportRecord.created)}/> <FormattedTime
                                    value={new Date(exportRecord.created)}/>
                                </div>
                                <div>
                                    <span className="font-weight-bold">
                                        <FormattedMessage {...internalTicketExportMessages.EXPORT_STATUS}/>{' '}
                                    </span>
                                    <FormattedMessage {...exportConstantMessages[exportRecord.status]}/>
                                </div>
                                {exportRecord.status === 'FINISH' &&
                                <PrimaryButton type="button"
                                               className="btn mr-2 float-left"
                                               onClick={downloadExport}>
                                    <FormattedMessage {...buttonMessages[BUTTON_LABEL_DOWNLOAD]}/>
                                </PrimaryButton>
                                }
                            </div>
                            }
                            <PrimaryButton type="button"
                                           className="btn mr-2 float-right"
                                           onClick={handleSubmit}>
                                <FormattedMessage {...buttonMessages[BUTTON_LABEL_EXPORT]}/>
                            </PrimaryButton>
                        </form>
                    </div>
                </div>
            </StyledDiv>
        );
    }
}

InternalTicketExportTechDocForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    exportType: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    charSets: PropTypes.array.isRequired,
};

const selector = (form, ...other) => (formValueSelector(form))(...other);

const mapStateToProps = (state, initialProps) => ({
    startDate: selector(initialProps.form, state, DOC_EXPORT_INTERNAL_FORM_FIELD_FROM),
    endDate: selector(initialProps.form, state, DOC_EXPORT_INTERNAL_FORM_FIELD_TO),
    exportType: (selector(initialProps.form, state, DOC_EXPORT_INTERNAL_FORM_FIELD_TYPE) === CSV),
    exportRecord: state.docExportInternalTickets.exportRecord,
    charSets: state.profile.userDetail.charSets,
    roles: state.profile.userDetail.roles,
});

export default reduxForm({
    enableReinitialize: true,
})(injectIntl(connect(mapStateToProps, {})(InternalTicketExportTechDocForm)));
