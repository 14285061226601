import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearAlerts } from '../../../../actions/alertsActions';
import get from 'get-value';
import {Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    fetchEscalationSchema,
    redirectReset,
    resetEscalationSchema,
    updateEscalationSchema
} from '../../../../actions/admin/actionEscalationSchema';
import Loader from '../../../Loader';
import {ESCALATION_SCHEMA_FORM} from '../../../../constants/formConstants';
import EscalationSchemaForm from './forms/EscalationSchemaForm';
import { editReqFields } from '../../../../constants/escalationSchemaRequiredFields';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import {FormattedMessage, injectIntl} from 'react-intl';
import formTypeMessages from '../../../../intl/common/formTypeMessages';

import {
    initLoadedEscalationSchema,
    transformUpdateEscalationSchema
} from '../../../../utils/escalationSchemaUtils';
import {
    fetchEscalationSchemaSGOptions,
} from '../../../../actions/admin/actionEscalationSchemaOptions';
import {getAdminEscalationSchemaPath} from '../../../../routes/pathResolver';


class EditEscalationSchemaPage extends Component {

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetEscalationSchema(this.props.domain);
        this.props.fetchEscalationSchemaSGOptions(this.props.domain);
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchEscalationSchema(this.props.domain, this.props.match.params.escalationSchemaUuid);
        }
    }

    handleSubmit = (values) => {
        const dataForSend = transformUpdateEscalationSchema(this.props.domain, values);
        this.props.updateEscalationSchema(this.props.domain, dataForSend);
    };

    componentWillUnmount() {
        this.props.redirectReset(this.props.domain);
    }

    render() {
        const {
            escalationSchemaData,
            domain,
            intl: {formatMessage},
        } = this.props;

        const {
            escalationSchemaSubmittedAndShouldRedirect: redirecting,
            isEscalationSchemaCreating: creating,
            isEscalationSchemaLoading: loading,
            initWithOldData,
        } = escalationSchemaData;

        let formType = false;
        if (get(escalationSchemaData, 'escalationSchema.name', { default: false })) {
            formType = ESCALATION_SCHEMA_FORM;
        }

        if (redirecting && !creating && !loading && get(escalationSchemaData, 'escalationSchema.solutionGroupId', { default: false })) {
            return <Redirect to={{
                pathname: `${getAdminEscalationSchemaPath(domain)}/view/${escalationSchemaData.escalationSchema.formType + "-" + escalationSchemaData.escalationSchema.solutionGroupId}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (loading || creating || !formType) {
            return <Loader/>;
        }

        let initializationValue
        if(!creating && !loading && !redirecting && initWithOldData){
            initializationValue = initLoadedEscalationSchema(domain, escalationSchemaData, formatMessage);
        } else {
            initializationValue = initLoadedEscalationSchema(domain, escalationSchemaData, formatMessage);
        }

        const requiredFields = editReqFields['DEFAULT']['EDITOR'][formType];
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.escalationSchema.title"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - Escalation schema"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <div className="container-fluid">
                    <div className="col-12">
                        <EscalationSchemaForm
                            form={formType}
                            initialValues={initializationValue}
                            reqFields={requiredFields}
                            enableReinitialize={true}
                            domain={domain}
                            edit={true}
                            handleSubmit={this.handleSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

EditEscalationSchemaPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    resetEscalationSchema: PropTypes.func.isRequired,
    fetchEscalationSchema: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateEscalationSchema: PropTypes.func.isRequired,
    intl: PropTypes.object,
    escalationSchemaData: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    location: PropTypes.object,
    userDetail: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    escalationSchemaData: state.adminEscalationSchema,
    userDetail: state.profile.userDetail,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    fetchEscalationSchema,
    updateEscalationSchema,
    resetEscalationSchema,
    redirectReset,
    fetchEscalationSchemaSGOptions,
})(injectIntl(EditEscalationSchemaPage))), ['TD_POWER_USER','SP_POWER_USER']);
