import React from 'react';
import classNames from 'classnames';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import PropTypes from "prop-types";
import {SORT_ASC, SORT_DESC, SORT_NONE} from '../../constants/Utils';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    margin-left: 5px;
    cursor: pointer;
`;

const Sorter = (props) => {
    const {column, sort, sorting = {}} = props;
    const order = sorting.order || SORT_NONE;
    const sortClass = classNames({
        'sort': !order || column !== sorting.column,
        'sort-up': column === sorting.column && order === SORT_ASC,
        'sort-down': column === sorting.column && order === SORT_DESC,
    });


    return (
        <StyledFontAwesomeIcon onClick={() => sort(column)} icon={sortClass}></StyledFontAwesomeIcon>
    );
};

Sorter.propTypes = {
    column: PropTypes.string.isRequired,
    sort: PropTypes.func,
    sorting: PropTypes.object,
};

export default Sorter;
