import React from 'react';
import {FormattedMessage} from 'react-intl';
import {
    DOCUMENTATIONS_EXPORT_PATH,
    DOCUMENTATIONS_HISTORY_PATH,
    DOCUMENTATIONS_LIST_PATH,
    DOCUMENTATIONS_NEW_CODED_PARTS_KEYS,
    DOCUMENTATIONS_NEW_OVAL_PLATE_ORDER,
    DOCUMENTATIONS_NEW_REQUEST_FOR_NOT_CONFORM_REFERENCE,
    DOCUMENTATIONS_NEW_REQUEST_FOR_NOT_FOUND_REFERENCE,
    DOCUMENTATIONS_NEW_UNKNOWN_PART_OPENING_REQUEST,
    DOCUMENTATIONS_RECURRENT_PROBLEM_PATH,
    DOCUMENTATIONS_FULL_TEXT_SEARCH_PATH,
    DOCUMENTATIONS_NEW_INTERNAL_TICKET,
    DOCUMENTATIONS_INTERNAL_TICKET_LIST_PATH,
    DOCUMENTATIONS_INTERNAL_TICKET_HISTORY_PATH,
    DOCUMENTATIONS_INTERNAL_TICKET_SEARCH_PATH,
    DOCUMENTATIONS_INTERNAL_TICKET_EXPORT_PATH,
    DOCUMENTATIONS_MANAGEMENT_GROUP_PATH,
} from '../../../../routes/paths';
import {moduleRoles} from '../../../../utils/roles';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import {
    CODED_PARTS_KEYS_FORM, INTERNAL_TICKET_FORM,
    OVAL_PLATE_ORDER_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
    UNKNOWN_PART_OPENING_REQUEST_FORM
} from '../../../../constants/formConstants';
import menuMessages from '../../../../intl/layout/menuMessages';
import {domains} from '../../../../constants/Utils';



export const getTechDocEditorMenu = roles => {
    const techDocNewEditorOrderSubmenu = buildTechDocNewEditorOrderSubmenu(roles);

    let result = [];

    if(moduleRoles.isSGEditorLVL1onlyForGroupsDocumentation(roles, ['IN', 'KR', 'CO', 'BR', 'UA', 'TR'])) {
        result = [];
    } else {
        result = [
            {
                label: <FormattedMessage {...menuMessages.TECH_DOC_LIST}/>,
                to: DOCUMENTATIONS_LIST_PATH,
                id: DOCUMENTATIONS_LIST_PATH
            },
            {
                label: <FormattedMessage {...menuMessages.TECH_DOC_HISTORY}/>,
                to: DOCUMENTATIONS_HISTORY_PATH,
                id: DOCUMENTATIONS_HISTORY_PATH
            },
            {
                label: <FormattedMessage {...menuMessages.TECH_DOC_FULLTEXT_SEARCH}/>,
                to: DOCUMENTATIONS_FULL_TEXT_SEARCH_PATH,
                id: DOCUMENTATIONS_FULL_TEXT_SEARCH_PATH
            },
            {
                label: <FormattedMessage {...menuMessages.TECH_DOC_RECURRENT_PROBLEM}/>,
                to: DOCUMENTATIONS_RECURRENT_PROBLEM_PATH,
                id: DOCUMENTATIONS_RECURRENT_PROBLEM_PATH
            },
        ];
    }

    if (techDocNewEditorOrderSubmenu.length > 0) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[INTERNAL_TICKET_FORM]}/>,
                content: techDocNewEditorOrderSubmenu
            }
        );
    }


    if ((moduleRoles.isAdminDocumentation(roles) || moduleRoles.isSGAdminDocumentation(roles)) && !moduleRoles.isSGEditorLVL1onlyForGroupsDocumentation(roles, ['IN', 'KR', 'CO', 'BR', 'UA', 'TR'])) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.TECH_DOC_EXPORT}/>,
                to: DOCUMENTATIONS_EXPORT_PATH,
                id: DOCUMENTATIONS_EXPORT_PATH
            }
        );
    }
    if (moduleRoles.isSGAdminDocumentation(roles, 'MANAGEMENT_GROUP')) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.TECH_DOC_MANAGEMENT_GROUP}/>,
                to: DOCUMENTATIONS_MANAGEMENT_GROUP_PATH,
                id: DOCUMENTATIONS_MANAGEMENT_GROUP_PATH
            }
        );
    }

    return result;
};

export const getTechDocDealerMenu = roles => {
    const techDocNewOrderSubmenu = buildTechDocNewOrderSubmenu(roles);

    const result = [
        {
            label: <FormattedMessage {...menuMessages.TECH_DOC_LIST}/>,
            to: DOCUMENTATIONS_LIST_PATH,
            id: DOCUMENTATIONS_LIST_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.TECH_DOC_HISTORY}/>,
            to: DOCUMENTATIONS_HISTORY_PATH,
            id: DOCUMENTATIONS_HISTORY_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.TECH_DOC_FULLTEXT_SEARCH}/>,
            to: DOCUMENTATIONS_FULL_TEXT_SEARCH_PATH,
            id: DOCUMENTATIONS_FULL_TEXT_SEARCH_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.TECH_DOC_RECURRENT_PROBLEM}/>,
            to: DOCUMENTATIONS_RECURRENT_PROBLEM_PATH,
            id: DOCUMENTATIONS_RECURRENT_PROBLEM_PATH
        }
    ];


    if (buildTechDocNewOrderSubmenu.length > 0) {
        result.unshift(
            {
                label: <FormattedMessage {...menuMessages.TECH_DOC_NEW}/>,
                content: techDocNewOrderSubmenu
            }
        );
    }

    return result;
};


const buildTechDocNewOrderSubmenu = roles => {
    const result = [];

    const formTypes = moduleRoles.getDealerForms(domains.TECHNICAL_DOCUMENTATION, roles).map(formType => formType.group);

    if (formTypes.includes(CODED_PARTS_KEYS_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[CODED_PARTS_KEYS_FORM]}/>,
                to: DOCUMENTATIONS_NEW_CODED_PARTS_KEYS,
                id: DOCUMENTATIONS_NEW_CODED_PARTS_KEYS
            }
        );
    }
    if (formTypes.includes(OVAL_PLATE_ORDER_FORM)) {
        result.push({
                label: <FormattedMessage {...formTypeMessages[OVAL_PLATE_ORDER_FORM]}/>,
                to: DOCUMENTATIONS_NEW_OVAL_PLATE_ORDER,
                id: DOCUMENTATIONS_NEW_OVAL_PLATE_ORDER
            }
        );
    }
    if (formTypes.includes(SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM)) {
        result.push({
                label: <FormattedMessage {...formTypeMessages[SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM]}/>,
                to: DOCUMENTATIONS_NEW_REQUEST_FOR_NOT_CONFORM_REFERENCE,
                id: DOCUMENTATIONS_NEW_REQUEST_FOR_NOT_CONFORM_REFERENCE
            }
        );
    }
    if (formTypes.includes(SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM]}/>,
                to: DOCUMENTATIONS_NEW_REQUEST_FOR_NOT_FOUND_REFERENCE,
                id: DOCUMENTATIONS_NEW_REQUEST_FOR_NOT_FOUND_REFERENCE
            }
        );
    }
    if (formTypes.includes(UNKNOWN_PART_OPENING_REQUEST_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[UNKNOWN_PART_OPENING_REQUEST_FORM]}/>,
                to: DOCUMENTATIONS_NEW_UNKNOWN_PART_OPENING_REQUEST,
                id: DOCUMENTATIONS_NEW_UNKNOWN_PART_OPENING_REQUEST
            }
        );
    }

    return result;
};


const buildTechDocNewEditorOrderSubmenu = roles => {
    const result = [];

    if(moduleRoles.isSGEditor(roles, domains.TECHNICAL_DOCUMENTATION)){
        result.push(
            {
                label: <FormattedMessage {...menuMessages.TECH_DOC_NEW}/>,
                to: DOCUMENTATIONS_NEW_INTERNAL_TICKET,
                id: DOCUMENTATIONS_NEW_INTERNAL_TICKET
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.TECH_DOC_LIST}/>,
                to: DOCUMENTATIONS_INTERNAL_TICKET_LIST_PATH,
                id: DOCUMENTATIONS_INTERNAL_TICKET_LIST_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.TECH_DOC_HISTORY}/>,
                to: DOCUMENTATIONS_INTERNAL_TICKET_HISTORY_PATH,
                id: DOCUMENTATIONS_INTERNAL_TICKET_HISTORY_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.TECH_DOC_INTERNAL_SEARCH}/>,
                to: DOCUMENTATIONS_INTERNAL_TICKET_SEARCH_PATH,
                id: DOCUMENTATIONS_INTERNAL_TICKET_SEARCH_PATH,
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.TECH_DOC_INTERNAL_EXPORT}/>,
                to: DOCUMENTATIONS_INTERNAL_TICKET_EXPORT_PATH,
                id: DOCUMENTATIONS_INTERNAL_TICKET_EXPORT_PATH
            }
        );
    }

    return result;
};
