import React, {Component} from 'react';
import {connect} from 'react-redux';
import get from 'get-value';
import Loader from '../../Loader';
import {
    CODED_PARTS_KEYS_FORM,
    OVAL_PLATE_ORDER_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM,
    UNKNOWN_PART_OPENING_REQUEST_FORM,
} from '../../../constants/formConstants';
import PropTypes from 'prop-types';
import {moduleRoles} from '../../../utils/roles';
import {formatTicketNumber} from '../../../utils/utils';
import {editRights} from '../../../constants/techDocTicketRights';
import UnknownPartOpeningMasterForm from './forms/UnknownPartOpeningMasterForm';
import OvalPlateOrderMasterForm from './forms/OvalPlateOrderMasterForm';
import CodedPartsMasterForm from './forms/CodedPartsMasterForm';
import NotFoundRefRequestMasterForm from './forms/NotFoundRefRequestMasterForm';
import NotConformRefRequestMasterForm from './forms/NotConformRefRequestMasterForm';
import {FormattedMessage} from 'react-intl';
import {initLoadedTechDocTicket} from '../Utils';
import {editReqFields} from '../../../constants/techDocRequiredFields';
import {fetchAdminClientIds} from '../../../actions/admin/actionAdminClientIds';
import formMessages from '../../../intl/technicalDocumentations/formMessages';
import RequestForSupportMasterForm from './forms/RequestForSupportMasterForm';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import checkRoles from '../../common/roleChecker/RoleChecker';
import OverlayLoader from '../../OverlayLoader';
import {resetTechDocTicket} from '../../../actions/techDoc/actionTechDocTicket';
import {closeTechDocDicModal} from '../../../actions/techDoc/actionTechDocDicData';

class EditLoadTicketDataPage extends Component {
    componentDidMount() {
        const {ticketData} = this.props;
        this.props.fetchAdminClientIds(get(ticketData, 'ticket.group'), get(ticketData, 'ticket.dealerNumber'));
    }

    render() {
        const {
            ticketData,
            loggedUser,
            clientIds,
            handleSubmit,
            formType,
            dicInfo: {isModalActive},
            closeTechDocDicModal
        } = this.props;

        if (clientIds.isLoading) {
            return <Loader/>;
        }

        const isEditor = moduleRoles.isEditorDocumentation(loggedUser) || moduleRoles.isSGEditorDocumentation(loggedUser);
        const requiredFields = editReqFields[editReqFields[ticketData.ticket.group] ? ticketData.ticket.group : 'DEFAULT'][isEditor ? 'EDITOR' : 'DEALER'][formType];
        const initializationValue = initLoadedTechDocTicket(ticketData);

        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {formType === UNKNOWN_PART_OPENING_REQUEST_FORM &&
                    <UnknownPartOpeningMasterForm form={formType}
                                                  initialValues={initializationValue}
                                                  userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                  userRoles={this.props.loggedUser}
                                                  enableReinitialize={true}
                                                  handleSubmit={handleSubmit}
                                                  reqFields={requiredFields}
                                                  editMode
                    />
                    }
                    {formType === OVAL_PLATE_ORDER_FORM &&
                    <OvalPlateOrderMasterForm form={formType}
                                              initialValues={initializationValue}
                                              userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                              userRoles={this.props.loggedUser}
                                              enableReinitialize={true}
                                              handleSubmit={handleSubmit}
                                              reqFields={requiredFields}
                                              editMode
                    />
                    }
                    {formType === CODED_PARTS_KEYS_FORM &&
                    <CodedPartsMasterForm form={formType}
                                          initialValues={initializationValue}
                                          userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                          userRoles={this.props.loggedUser}
                                          enableReinitialize={true}
                                          handleSubmit={handleSubmit}
                                          reqFields={requiredFields}
                                          editMode
                    />
                    }
                    {formType === SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM &&
                    <NotFoundRefRequestMasterForm form={formType}
                                                  initialValues={initializationValue}
                                                  userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                  userRoles={this.props.loggedUser}
                                                  enableReinitialize={true}
                                                  handleSubmit={handleSubmit}
                                                  reqFields={requiredFields}
                                                  editMode
                    />
                    }
                    {formType === SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM &&
                    <NotConformRefRequestMasterForm form={formType}
                                                    initialValues={initializationValue}
                                                    userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                    userRoles={this.props.loggedUser}
                                                    enableReinitialize={true}
                                                    handleSubmit={handleSubmit}
                                                    reqFields={requiredFields}
                                                    editMode
                    />
                    }
                    {formType === SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM &&
                    <RequestForSupportMasterForm form={formType}
                                                 initialValues={initializationValue}
                                                 userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                 userRoles={this.props.loggedUser}
                                                 enableReinitialize={true}
                                                 handleSubmit={handleSubmit}
                                                 reqFields={requiredFields}
                                                 editMode
                    />
                    }
                </div>
                <OverlayLoader active={isModalActive}
                               text={formMessages.WAIT_DIC}
                               handleClose={closeTechDocDicModal}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.techDocTicket,
    clientIds: state.adminClientIds,
    dicInfo: state.techDocDic,
});

EditLoadTicketDataPage.propTypes = {
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
    fetchAdminClientIds: PropTypes.func.isRequired,
};

export default checkRoles(connect(mapStateToProps, {
    fetchAdminClientIds,
    resetTechDocTicket,
    closeTechDocDicModal
})(EditLoadTicketDataPage), ['TD_DEALER', 'TD_EDITOR', 'TD_SG_EDITOR']);

