import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    TICKET_NUMBER: {
        id: 'spareParts.export.ticketNumber',
        defaultMessage: 'Ticket No.'
    },
    DATE_FROM: {
        id: 'spareParts.export.dateFrom',
        defaultMessage: 'Date From'
    },
    DATE_TO: {
        id: 'spareParts.export.dateTo',
        defaultMessage: 'Date To'
    },
    ASSIGNED: {
        id: 'spareParts.export.assigned',
        defaultMessage: 'Assigned to'
    },
    STATUS: {
        id: 'spareParts.export.status',
        defaultMessage: 'Status'
    },
    SOLUTION_GROUP: {
        id: 'spareParts.export.form.solutionGroup',
        defaultMessage: 'Solution Group'
    },
    DEALER_NUMBER: {
        id: 'spareParts.export.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    DEALER_NAME: {
        id: 'spareParts.export.dealerName',
        defaultMessage: 'Dealer Name'
    },
    TYPE: {
        id: 'spareParts.export.type',
        defaultMessage: 'Type'
    },
    XLS: {
        id: 'spareParts.export.xls',
        defaultMessage: 'XLS'
    },
    CSV: {
        id: 'spareParts.export.csv',
        defaultMessage: 'CSV'
    },
    EXPORT_STATUS: {
        id: 'spareParts.export.exportStatus',
        defaultMessage: 'Export status:'
    },
    LAST_EXPORT: {
        id: 'spareParts.export.lastExport',
        defaultMessage: 'Last export:'
    }
});

export default messages;
