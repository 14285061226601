import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {clearAlerts} from '../../../actions/alertsActions';
import get from 'get-value';
import Loader from '../../Loader';
import {
    FORM_BUTTON_EDIT,
    FORM_BUTTON_REOPEN, FORM_BUTTON_TAKE_BACK,
    INTERNAL_TICKET_FORM,
} from '../../../constants/formConstants';
import {Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {formatInternalTicketNumber} from '../../../utils/utils';
import {editRights} from '../../../constants/techDocInternalTicketRights';
import {
    initLoadedTechDocInternalTicket,
    transformUpdateTechDocInternalTicketForSave
} from '../Utils';
import formMessages from '../../../intl/technicalDocumentations/formMessages';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import checkRoles from '../../common/roleChecker/RoleChecker';
import InternalTicketMasterForm from './forms/InternalTicketMasterForm';
import set from 'set-value';
import {
    fetchTechDocInternalTicket,
    resetTechDocInternalTicket,
    updateTechDocInternalTicket
} from '../../../actions/techDoc/actionTechDocInternalTicket';
import {
    lockTechDocInternalTicket,
    resetRedirectAfterTechDocInternalLockTicket
} from '../../../actions/techDoc/actionTechDocInternalLockTicket';

class ViewTechDocInternalTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToEdit: false,
            ticketSubmitted: false,
            storedTicketData: {},
            redirectToEditImmediately: false,
            checkStatusSkipAfterUpdateRedirect: false
        };
    }

    componentDidMount() {
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchTechDocInternalTicket(this.props.match.params.ticketUuid);
        }
    }

    componentWillUnmount() {
        this.props.resetTechDocInternalTicket();
        this.props.resetRedirectAfterTechDocInternalLockTicket();
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const {ticketData} = this.props;

        switch (submitBy) {
            case FORM_BUTTON_REOPEN:
                this.props.lockTechDocInternalTicket(this.props.match.params.ticketUuid);
                this.setState({redirectToEdit: true, ticketSubmitted: true, storedTicketData: transformUpdateTechDocInternalTicketForSave(values)});
                break;
            case FORM_BUTTON_TAKE_BACK:
                this.props.lockTechDocInternalTicket(this.props.match.params.ticketUuid);
                this.setState({checkStatusSkipAfterUpdateRedirect: true, redirectToEdit: true, ticketSubmitted: true, storedTicketData: transformUpdateTechDocInternalTicketForSave(values)});
                break;
            case FORM_BUTTON_EDIT:
                this.setState({redirectToEditImmediately: true, redirectToTicket: ticketData.ticket.uuid});
                this.props.resetTechDocInternalTicket();
                break;
        }
    };

    render() {
        const {
            ticketData,
            loggedUser,
        } = this.props;

        let formType = false;
        if (get(ticketData, 'ticket.docType', {default: false})) {
            formType = ticketData.ticket.docType;
        }

        if (!this.props.lockTicket.isTicketLocking && !this.props.lockTicket.redirect && this.state.ticketSubmitted && this.state.redirectToEdit) {
            this.props.updateTechDocInternalTicket(this.state.storedTicketData);
            return <Redirect to={{
                pathname: `/documentation/internalTicket/edit/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true,
                checkStatusSkipAfterUpdateRedirect: !!this.state.checkStatusSkipAfterUpdateRedirect,
            }}/>;
        } else if (this.state.redirectToEditImmediately) {
            return <Redirect to={{
                pathname: `/documentation/internalTicket/edit/${this.state.redirectToTicket}`,
            }}/>;
        } else if (!this.props.lockTicket.isTicketLocking && !this.props.lockTicket.redirect && this.state.ticketSubmitted) {
            this.props.updateTechDocInternalTicket(this.state.storedTicketData);
            this.setState({ticketSubmitted: false, storedTicketData: {}});
        }

        const initializationValue = initLoadedTechDocInternalTicket(ticketData);
        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !formType
        ) {
            return <Loader/>;
        }

        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatInternalTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {formType === INTERNAL_TICKET_FORM &&
                    <InternalTicketMasterForm form={formType}
                                                 initialValues={initializationValue}
                                                 userRights={editRights['TD_SG_EDITOR'][ticketData.ticket.status]}
                                                 enableReinitialize={true}
                                                 handleSubmit={this.handleSubmit}
                                                 reqFields={[]}
                                                 readOnly/>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.techDocInternalTicket,
    lockTicket: state.techDocLockInternalTicketCheck,
});

ViewTechDocInternalTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
    fetchTechDocInternalTicket: PropTypes.func.isRequired,
    resetTechDocInternalTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchTechDocInternalTicket,
    resetTechDocInternalTicket,
    lockTechDocInternalTicket,
    updateTechDocInternalTicket,
    resetRedirectAfterTechDocInternalLockTicket,
    clearAlerts,
})(injectIntl(ViewTechDocInternalTicketPage))), ['TD_SG_EDITOR']);

