import {SPARE_PARTS_ORDERS_MULTI_SOURCING_GROUPS_FETCH_PENDING} from "../../actions/orders/actionMultiSoursingGroups";
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const sparePartsOrderMultiSourcingGroups = (state = {
    multiSourcingGroups: [],
    correlationId: null
}, action) => {
    switch (action.type) {
        case SPARE_PARTS_ORDERS_MULTI_SOURCING_GROUPS_FETCH_PENDING: {
            return Object.assign({}, state, { isLoadingMultiSourcingGroups: true, multiSourcingGroups: [] });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MULTI_SOURCING_GROUPS_GET: {
            const { multiSourcingGroups, error, correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, {
                    isLoadingMultiSourcingGroups: false,
                    multiSourcingGroups: [],
                    correlationId
                });
            }
            return Object.assign({}, state, {
                isLoadingMultiSourcingGroups: false,
                multiSourcingGroups,
                correlationId
            });
        }

        default:
            return state;
    }
};
