import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import 'react-table/react-table.css';
import {debounce} from 'throttle-debounce';
import {Link} from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import Table from '../../common/table/TicketTable';
import {IconEdit, PrimaryIcon} from '../../common/Button';
import listMessages from '../../../intl/sparePartsOrders/listMessages';
import {editSparePartsInternalTicketListButtonVisibility} from '../../../constants/sparePartsInternalTicketRights';
import {fetchSparePartsInternalTicketListPageData} from '../../../actions/orders/actionSparePartsInternalTicketListPage';
import {formatInternalTicketNumber} from '../../../utils/utils';
import get from 'get-value';
import {domains} from '../../../constants/Utils';

class ListSparePartsInternalTicketTable extends React.Component {
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(props.location.search);
        const loadedState = this.loadStateFromSessionStorage({
            listInternalSPDefaultPageSize: 10,
            listInternalSPDefaultSort: [{id: 'TICKET_NUMBER', desc: true}],
            listInternalSPDefaultFilters: [],
        });

        if (urlParams.has('status')) {
            this.state = {
                ...loadedState,
                listInternalSPDefaultFilters: [
                    {
                        id: 'STATUS',
                        value: urlParams.get('status') ? urlParams.get('status') : '',
                    }
                ]
            }
        } else {
            this.state = {
                ...loadedState,
                listInternalSPDefaultFilters: loadedState.listInternalSPDefaultFilters.filter(item => item.id !== 'GROUP')
            }
        }

        this.filtering = false;
        this.handleFetchDataDebounced = debounce(1000, this.handleFetchData);
    }

    componentDidMount() {
        window.addEventListener(
            'beforeunload',
            this.saveStateToSessionStorage
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            'beforeunload',
            this.saveStateToSessionStorage
        );
        this.saveStateToSessionStorage();
    }

    loadStateFromSessionStorage(defaults) {
        return Object.keys(defaults)
            .reduce((acc, key) => {
                if (sessionStorage.hasOwnProperty(key) && key.match(/^listInternalSP*/)) {
                    let value = sessionStorage.getItem(key);
                    value = JSON.parse(value);
                    return {
                        ...acc,
                        [key]: value,
                    };
                }
                return acc;
            }, defaults);
    }

    saveStateToSessionStorage = () => {
        for (let key in this.state) {
            if (this.state.hasOwnProperty(key) && key.match(/^listInternalSP*/)) {
                sessionStorage.setItem(key, JSON.stringify(this.state[key]));
            }
        }
    }

    handleFetchData = state => {
        this.props.fetchSparePartsInternalTicketListPageData(state.pageSize, state.sorted, state.cursor, state.isNextFromPivot, this.normalizeFiltered(state));
        this.filtering = false;
        this.setState({
            listInternalSPDefaultPageSize: state.pageSize,
            listInternalSPDefaultSort: state.sorted,
            listInternalSPDefaultFilters: state.filtered
        });
    };

    fetchStrategy = state => {
        this.filtering ? this.handleFetchDataDebounced(state) : this.handleFetchData(state);
    };

    normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date || filterItem.id === 'IT_CREATION_TIME' || filterItem.id === 'LAST_UPDATE') {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const {columns, data, pages, isLoading, handleEditClick, isNextPage, isNextFromPivot, userRoles} = this.props;

        return (
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'TICKET_NUMBER',
                                    accessor: 'ticketNumber',
                                    message: listMessages.TABLE_INTERNAL_TICKET_NUMBER,
                                    Cell: row => <Link
                                        to={`view/${row.original.id}`}>{get(row, 'original.pfxNumber') ? formatInternalTicketNumber(get(row, 'original.pfxNumber'), get(row, 'original.sfxNumber')) : ''}</Link>
                                },
                                ...columns]}
                            toolButtons={[
                                {
                                    handleClick: handleEditClick,
                                    component: <PrimaryIcon className="btn btn-sm"
                                                            title="Edit"
                                                            type="button"
                                                            key="editButton">
                                        <IconEdit/>
                                    </PrimaryIcon>
                                }
                            ]}
                            isLoading={isLoading}
                            data={data}
                            pages={pages}
                            handleFetchData={this.fetchStrategy}
                            handleOnFilteredChange={() => {
                                this.filtering = true;
                            }}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={this.state.listInternalSPDefaultFilters}
                            defaultSorting={this.state.listInternalSPDefaultSort}
                            defaultPageSize={this.state.listInternalSPDefaultPageSize}
                            buttonVisibility={editSparePartsInternalTicketListButtonVisibility['SP_SG_EDITOR']}
                            sgPermissions={get(userRoles, `${domains.SPARE_PARTS}.sgEditor.sgPermission`)}
                            toolSortable={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

ListSparePartsInternalTicketTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    pages: PropTypes.number.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    fetchSparePartsInternalTicketListPageData: PropTypes.func.isRequired,
    columns: PropTypes.array,
    userRoles: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.sparePartsInternalTicketListPage.isTableLoading,
    data: state.sparePartsInternalTicketListPage.listData,
    isNextPage: state.sparePartsInternalTicketListPage.isNextPage,
    isNextFromPivot: state.sparePartsInternalTicketListPage.isNextFromPivot,
    pages: state.sparePartsInternalTicketListPage.pages,
    userRoles: state.profile.userDetail.roles,
});

export default connect(mapStateToProps, {
    fetchSparePartsInternalTicketListPageData
})(ListSparePartsInternalTicketTable);
