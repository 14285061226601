import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {PRIMARY_YELLOW} from '../../theme/colors';

const HeaderLine = styled.hr`
  background-color: ${PRIMARY_YELLOW};
  margin: 0 0 20px;
`;

function PageHeader({title}) {
    return <><h2>{title}</h2><HeaderLine/></>;
}

PageHeader.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]).isRequired,
};

export default PageHeader;
