import React, {useState} from 'react';
import {formValueSelector, change} from 'redux-form'
import {connect} from 'react-redux';
import {
    FORM_FIELD_SRC_CLAIM_N,
    FORM_FIELD_SRC_REQUEST,
    FORM_LABEL_SRC_CLAIM_N,
    FORM_LABEL_SRC_REQUEST,
    FORM_SUPPLEMENTARY_INFORMATION_SECTION,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import * as formConstants from '../../../../../../constants/formConstants';
import commonMessages from '../../../../../../intl/common/commonMessages';
import Div from '../../../../../common/styled/HideAbleDiv';
import {srcRequestType} from '../../../../../../constants/Utils';

const SupplementaryInformationAllocationSection = (props) => {
    const {readOnly, userRights, reqFields, change, srcRequest, form} = props;
    const [srcNumberDisabled, setSrcNumberDisabled] = useState(!srcRequest || srcRequest === 'NO');

    const section = formConstants.FORM_SUPPLEMENTARY_INFORMATION_SECTION;

    const handleSrcRequestChecker = (event, value) => {
        if (value && (value === 'YES' || value === 'PRESIDENT')) {
            setSrcNumberDisabled(false);
        } else {
            change(`${section}.${formConstants.FORM_FIELD_SRC_CLAIM_N}`, '');
            setSrcNumberDisabled(true);
        }
    };

    return (
        <>
            <div className="form-row">
                <Div className="col-xl-2 col-lg-4 col-md-6 pb-2">
                    <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_SRC_REQUEST]}
                                                    field={FORM_FIELD_SRC_REQUEST}
                                                    options={srcRequestType}
                                                    sort={false}
                                                    intlMessages={commonMessages}
                                                    isRequired={reqFields.includes(FORM_FIELD_SRC_REQUEST)}
                                                    isDisabled={!userRights.canEditSuppInformations || readOnly || readOnly}
                                                    noEmpty
                                                    handleChange={handleSrcRequestChecker}
                    />
                </Div>
                <Div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                    <InputFieldWithValidation label={formMessages[FORM_LABEL_SRC_CLAIM_N]}
                                              field={FORM_FIELD_SRC_CLAIM_N}
                                              maxLength={256}
                                              isDisabled={!userRights.canEditSuppInformations || readOnly || srcNumberDisabled}
                                              isRequired={reqFields.includes(FORM_FIELD_SRC_CLAIM_N)}
                                              isSmall
                    />
                </Div>
            </div>
        </>
    )

}

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, initialProps) => ({
    srcRequest: selector(initialProps.form, state, `${FORM_SUPPLEMENTARY_INFORMATION_SECTION}.${FORM_FIELD_SRC_REQUEST}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(SupplementaryInformationAllocationSection);
