import React, {Component} from 'react';
import {change, FieldArray, formValueSelector} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import {
    FORM_FIELD_DESCRIPTION, FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_PART_NO, FORM_FIELD_SUBJECT_TO_VOR,
    FORM_FIELDS_ORDER_ITEMS,
    FORM_ORDERS_SECTION,
} from '../../../../../../constants/formConstants';
import {BorderlessPrimaryIcon, StyledDiv} from '../../../../../common/StyledComponents';
import OrdersTableHeader from './OrdersTableHeader';
import OrdersTableBody from './OrdersTableBody';
import {MAX_SPARE_PARTS_ORDER_COUNT} from '../../../../../../constants/validationConstants';
import {
    fetchDicSparePartInfoActionCreator,
    setDicSparePartInfoActionCreator
} from '../../../../../../actions/orders/actionSparePartsDicData';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import warnMessages from '../../../../../../intl/common/warnMessages';
import {warnAlert} from '../../../../../../actions/alertsActions';

class OrderItemsSection extends Component {
    handleOrderPartLoad = (index) => {
        const {orders, fetchDicSparePartInfoActionCreator} = this.props;
        fetchDicSparePartInfoActionCreator(orders[index][FORM_FIELD_PART_NO]);
    };

    fillDicSparePartInfo = () => {
        const {dicInfo, orders, change, setDicSparePartInfoActionCreator, warnAlert, userRights} = this.props;
        const order = orders.find(e => e[FORM_FIELD_PART_NO] === dicInfo.partNo);
        setDicSparePartInfoActionCreator();
        if (order) {
            if(userRights.canEditItems) {
                if (!order[FORM_FIELD_DESCRIPTION]) {
                    change(`${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}[${order.index}].${FORM_FIELD_DESCRIPTION}`, dicInfo.partDescription.trim());
                } else {
                    warnAlert(warnMessages.SP_DIC_WARN002, null, {partNumber: dicInfo.partNo})
                }
            }
            if (!order[FORM_FIELD_MANAGEMENT_GROUP]) {
                change(`${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}[${order.index}].${FORM_FIELD_MANAGEMENT_GROUP}`, dicInfo.managementGroup);
            } else {
                warnAlert(warnMessages.SP_DIC_WARN004, null, {partNumber: dicInfo.partNo})
            }
        } else {
            warnAlert(warnMessages.SP_DIC_WARN003, null, {partNumber: dicInfo.partNo});
        }
    };

    renderOrders = ({fields}) => {
        const {userRights, readOnly, form, reqFields} = this.props;
        return (
            <>
                <div className="table-responsive">
                    <table className="table table-hover mb-0">
                        <OrdersTableHeader userRights={userRights} readOnly={readOnly} form={form}
                                           reqFields={reqFields}/>
                        <tbody>
                        {fields.map((order, index) => <OrdersTableBody key={index} form={form} index={index}
                                                                       order={order}
                                                                       userRights={userRights} readOnly={readOnly}
                                                                       handleRemoveOrder={() => {
                                                                           fields.remove(index);
                                                                           this.forceUpdate();
                                                                       }}
                                                                       reqFields={reqFields}
                                                                       handleOrderPartLoad={this.handleOrderPartLoad}/>)}
                        </tbody>
                    </table>
                </div>
            </>
        )
    };

    render() {
        const {dicInfo} = this.props;

        if (dicInfo.dicPartInfoLoaded) {
            this.fillDicSparePartInfo();
        }

        return (
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.ORDERS_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    <div className="d-flex justify-content-md-center">
                        <FieldArray name={FORM_FIELDS_ORDER_ITEMS} component={this.renderOrders}/>
                    </div>
                </StyledDiv>
            </>
        )
    }
}

const mapStateToProps = (state, props) => ({
    orders: formValueSelector(props.form)(state, `${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}`),
    dicInfo: state.sparePartsDic,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            setDicSparePartInfoActionCreator,
            fetchDicSparePartInfoActionCreator,
            warnAlert
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderItemsSection);
