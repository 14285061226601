import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TABLE_TICKET_NUMBER: {
        id: 'spareParts.searchEngine.table.ticketNo',
        defaultMessage: 'Ticket No.'
    },
    TABLE_CREATED_BY: {
        id: 'spareParts.searchEngine.table.createdBy',
        defaultMessage: 'Created by (IPN)'
    },
    TABLE_ORDER_CREATION_TIME: {
        id: 'spareParts.searchEngine.table.orderCreationTime',
        defaultMessage: 'Order Creation Time'
    },
    TABLE_CLIENT_ID: {
        id: 'spareParts.searchEngine.table.clientId',
        defaultMessage: 'Client ID'
    },
    TABLE_REGION: {
        id: 'spareParts.searchEngine.table.region',
        defaultMessage: 'Region'
    },
    TABLE_COUNTRY_CODE_3: {
        id: 'spareParts.searchEngine.table.countryCode3',
        defaultMessage: 'Country Code'
    },
    TABLE_DEALER_NUMBER: {
        id: 'spareParts.searchEngine.table.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    TABLE_ORDER_TYPE: {
        id: 'spareParts.searchEngine.table.orderType',
        defaultMessage: 'Order type'
    },
    REQUEST_OF_COURTESY_CAR: {
        id: 'spareParts.searchEngine.table.requestOfCourtesyCar',
        defaultMessage: 'Courtesy car Request'
    },
    TABLE_STATUS: {
        id: 'spareParts.searchEngine.table.status',
        defaultMessage: 'Status'
    },
    TABLE_LAST_STATUS_DATE: {
        id: 'spareParts.searchEngine.table.lastStatusDate',
        defaultMessage: 'Last Status Date'
    },
    TITLE: {
        id: 'spareParts.searchEngine.title',
        defaultMessage: 'Search Engine'
    },
    DEALER_NUMBER: {
        id: 'spareParts.searchEngine.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    CLIENT_ID: {
        id: 'spareParts.searchEngine.clientId',
        defaultMessage: 'Client ID'
    },
    REGISTRATION_NUMBER: {
        id: 'spareParts.searchEngine.registrationNumber',
        defaultMessage: 'Registration number'
    },
    PART_NUMBER: {
        id: 'spareParts.searchEngine.partNumber',
        defaultMessage: 'Part No'
    },
    MANAGEMENT_GROUP: {
        id: 'spareParts.searchEngine.managementGroup',
        defaultMessage: 'Management Group'
    },
    REGION: {
        id: 'spareParts.searchEngine.region',
        defaultMessage: 'Region'
    },
    COUNTRY_CODE_3: {
        id: 'spareParts.searchEngine.countryCode3',
        defaultMessage: 'Country Code'
    },
    DIC_NUMBER: {
        id: 'spareParts.searchEngine.dicNumber',
        defaultMessage: 'N° DIC'
    },
    VIN: {
        id: 'spareParts.searchEngine.vinNumber',
        defaultMessage: 'VIN'
    },
    TICKET_NUMBER: {
        id: 'spareParts.searchEngine.ticketNumber',
        defaultMessage: 'Ticket number'
    },
    SEARCH_OPTION_SECTION: {
        id: 'spareParts.searchEngine.searchOption',
        defaultMessage: 'Searching option'
    },
    FULL_DATABASE: {
        id: 'spareParts.searchEngine.fullDatabase',
        defaultMessage: 'Full database'
    },
    INTERNATIONAL: {
        id: 'spareParts.searchEngine.international',
        defaultMessage: 'International Search'
    },
    VISIBLE_COLUMNS: {
        id: 'spareParts.searchEngine.label.visibleColumns',
        defaultMessage: 'Visible columns'
    },
    TABLE_BIN: {
        id: 'spareParts.searchEngine.table.label.bin',
        defaultMessage: 'BIN'
    },
    TABLE_INVOICE_NUMBER: {
        id: 'spareParts.searchEngine.table.invoiceNumber',
        defaultMessage: 'Invoice Number'
    },
    BIN: {
        id: 'spareParts.searchEngine.bin',
        defaultMessage: 'BIN'
    },
    INVOICE_NUMBER: {
        id: 'spareParts.searchEngine.invoiceNumber',
        defaultMessage: 'Invoice Number'
    },
    PART_NO: {
        id: 'spareParts.searchEngine.partNo',
        defaultMessage: 'Part No'
    },
    PROBLEM_TYPE: {
        id: 'spareParts.searchEngine.problemType',
        defaultMessage: 'Problem Type'
    },
});

export default messages;
