import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import { Redirect, withRouter } from 'react-router-dom';
import { clearAlerts } from '../../../../actions/alertsActions';
import PageHeader from '../../../common/PageHeader';
import checkRoles from '../../../common/roleChecker/RoleChecker';
import solutionGroupMessages from '../../../../intl/admin/solutionGroupMessages';
import ListSolutionGroupTable from './ListSolutionGroupTable';
import commonMessages from '../../../../intl/common/commonMessages';
import {countryGroups} from '../../../../constants/Utils';
import formCountryMessages from '../../../../intl/common/countryGroupMessages';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import {getAdminSGPath} from '../../../../routes/pathResolver';

class ListSolutionGroupPage extends Component {
    constructor(props) {
        super(props);
        const { intl } = props;
        this.state = {
            ratchet: false,
            solutionGroup: '',
            columnLibrary: [
                {
                    id: 'group',
                    accessor: row => this.formatCountryLabel(row.groups, intl),
                    message: solutionGroupMessages.TABLE_COUNTRIES,
                    Filter: ({ filter, onChange }) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%', border: '1px solid #999999' }}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {Object.keys(countryGroups).map((key, index) =>
                                <option key={index} value={key}>
                                    {formCountryMessages[key] ? intl.formatMessage(formCountryMessages[key]) : key}
                                </option>)}
                        </select>
                    ),
                },
                {
                    id: 'isActive',
                    accessor: row => row.isActive ? 'Yes' : 'No',
                    message: solutionGroupMessages.TABLE_ACTIVE,
                    Filter: ({ filter, onChange }) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%', border: '1px solid #999999' }}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            <option value="false">{intl.formatMessage(commonMessages.NO)}</option>
                            <option value="true">{intl.formatMessage(commonMessages.YES)}</option>
                        </select>
                    ),
                },
                {
                    id: 'canForwardToDealer',
                    accessor: row => row.canForwardToDealer ? 'Yes' : 'No',
                    message: solutionGroupMessages.TABLE_CAN_FORWARD_TO_DEALER,
                    Filter: ({ filter, onChange }) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%', border: '1px solid #999999' }}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            <option value="false">{intl.formatMessage(commonMessages.NO)}</option>
                            <option value="true">{intl.formatMessage(commonMessages.YES)}</option>
                        </select>
                    ),
                },
                {
                    id: 'isDefault',
                    accessor: row => row.isDefault ? 'Yes' : 'No',
                    message: solutionGroupMessages.TABLE_DEFAULT,
                    Filter: ({ filter, onChange }) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%', border: '1px solid #999999' }}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            <option value="false">{intl.formatMessage(commonMessages.NO)}</option>
                            <option value="true">{intl.formatMessage(commonMessages.YES)}</option>
                        </select>
                    ),
                },
            ],
        };
    }

    formatCountryLabel = (value, intl) => {
        let result = '';
        if (value) {
            value.map(country => result += intl.formatMessage(formCountryMessages[country]) + ', ')
        }
        return result.replace(/,\s$/, '');
    };

    handleEditClick = ({ id }) => {
        this.setState({ ratchet: true, solutionGroup: id });
    };


    render() {
        const { filter, domain } = this.props;
        const { columnLibrary, solutionGroup } = this.state;


        if (this.state.ratchet) {
            return <Redirect to={`${getAdminSGPath(this.props.domain)}/edit/${solutionGroup}`}/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={
                    <FormattedMessage id="admin.escalationLevel.listTitle"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - Solution Group List"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <ListSolutionGroupTable
                    columns={columnLibrary}
                    handleEditClick={this.handleEditClick} filter={filter} domain={domain}/>
            </div>
        );
    }
}

ListSolutionGroupPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
};

const mapStateToPropos = state => ({});

export default checkRoles(withRouter(connect(mapStateToPropos, {
    clearAlerts,
})(injectIntl(ListSolutionGroupPage))), ['TD_POWER_USER', 'TD_SG_ADMIN', 'SP_POWER_USER', 'SP_SG_ADMIN']);
