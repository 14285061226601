import React from 'react';
import styled from 'styled-components';
import {withRouter } from 'react-router-dom';
import {PRIMARY_DARK_GREY} from '../theme/colors';
import renaultCar from '../images/car.png';
import {HOME_PATH} from '../routes/paths';
import Header from "./Header";
import { PrimaryButton } from './common/Button';
import { providers } from '../constants/Utils';

const TopNavigation = styled.nav`
    font-family: sans-serif;
    font-weight: bold;
    padding: 1px 10px;
    background-color: ${PRIMARY_DARK_GREY};
    min-height: 55px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh
`;

const Main = styled.div`
    display: flex;
    flex: 1;
`;

const Img = styled.img`
    padding-top: 16px;
    height: 35%;
    width: 35%;
`;

const Login = ({history}) => {
    return <Wrapper>
        <Header/>
        <TopNavigation className="navbar navbar-expand-md nav-pills" />
        <Main>
            <div className="d-flex flex-column flex-grow-1 justify-content-between">
                <div className="text-center m-4">
                    <p className="mb-4">In case of any issue after OKTA login, please, report incident in SNOW and login with IDP button in the right bottom corner.</p>
                    <PrimaryButton className="btn btn-lg mb-4" onClick={() => {
                        localStorage.setItem("provider", providers.OKTA)
                        localStorage.setItem("providerExpireAt", new Date().valueOf() + 1800000) // 30 min expiration
                        history.push(HOME_PATH)
                    }}>OKTA login</PrimaryButton>
                    <div className="text-center">
                        <Img className="mr-l" src={renaultCar}/>
                    </div>
                </div>
                <div className="text-right m-4">
                    <PrimaryButton className="btn" onClick={() => {
                        localStorage.setItem("provider", providers.IDP)
                        localStorage.setItem("providerExpireAt", new Date().valueOf() + 1800000) // 30 min expiration
                        history.push(HOME_PATH)
                    }}>Keycloak login</PrimaryButton>
                </div>
            </div>
        </Main>
    </Wrapper>
}

export default withRouter(Login);
