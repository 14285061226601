import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    PRO: {
        id: 'techDoc.clientType.option.Pro',
        defaultMessage: 'Pro'
    },
    PART: {
        id: 'techDoc.clientType.option.part',
        defaultMessage: 'Part'
    },
    OTHERS: {
        id: 'techDoc.clientType.option.others',
        defaultMessage: 'Others'
    },
    COMMERCIAL_VEHICLE: {
        id: 'techDoc.clientType.option.commercialVehicle',
        defaultMessage: 'Commercial Vehicle'
    },
    FLEET: {
        id: 'techDoc.clientType.option.fleet',
        defaultMessage: 'Fleet'
    },
});

export default messages;
