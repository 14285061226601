import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Select from 'react-select';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import {ALL_COUNTRIES_VALUE} from '../../../constants/Utils';

function convertToSelection(group, formatMessage) {
    return {value: group, label: (group && group.length > 0) ? countryGroupMessages[group] ? formatMessage(countryGroupMessages[group]) : group : ''};
}

function SelectCountryGroup({groups, handleCountryGroupSelection, selectedCountryGroup, intl: {formatMessage}, includeSelectionForAll = false, selectionForAllValue = ALL_COUNTRIES_VALUE}) {
    const options = groups.map(group => {
        if (group instanceof Object) {
            return convertToSelection(group.group, formatMessage);
        } else {
            return convertToSelection(group, formatMessage);
        }
    }).sort((option1, option2) => option1.label.localeCompare(option2.label));
    if (includeSelectionForAll) {
        options.unshift({value: selectionForAllValue, label: formatMessage(countryGroupMessages.ALL)});
    }
    return <Select isSearchable
                   options={options}
                   value={convertToSelection(selectedCountryGroup, formatMessage)}
                   placeholder={formatMessage(countryGroupMessages.SELECT_COUNTRY)}
                   onChange={handleCountryGroupSelection}/>;
}

SelectCountryGroup.propTypes = {
    groups: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
    handleCountryGroupSelection: PropTypes.func.isRequired,
    selectedCountryGroup: PropTypes.string.isRequired,
    includeSelectionForAll: PropTypes.bool,
    selectionForAllValue: PropTypes.any
};

export default injectIntl(SelectCountryGroup);
