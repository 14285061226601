import React from 'react';
import {injectIntl} from "react-intl";
import {required} from "./validationCommons";
import {Field} from "redux-form";
import {RequiredSpan} from "./StyledComponents";
import {FieldWithValidation} from "./FormField";
import SelectFieldWithValidation from "./FormField";
import {FormattedMessage} from 'react-intl';
import get from 'get-value';
import ImgTooltip from './ImgTooltip';

const InputSelectFieldWithValidation = (props) => {
    const { style, label, field, options, isDisabled, isRequired, intlMessages, intl, handleChange, noEmpty, sort, customOptionsMsg, boldTooltip } = props;
    let validations = [];
    if (isRequired) {
        validations.push(required);
    }
    const handleFormat = (value) => {
        let format;
        if (customOptionsMsg) {
            format = get(customOptionsMsg
                .map((option) => ({
                    id: option.uuid,
                    translation: option.presetName
                })));
            return get(format.find(option => option.id === value), 'translation', value)
        } else if (intlMessages) {
            return intlMessages[value] ? intl.formatMessage(intlMessages[value]) : value
        } else {
            return value
        }
    };
    return (
        <div>
            {!!label && label instanceof Object &&
            <div className="row m-0">
                <label className="col-form-label pl-0 font-weight-bold">
                    <FormattedMessage {...label}/>
                    {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
                </label>
                {!!boldTooltip && <div className="p-1">
                    <ImgTooltip/>
                </div>}
            </div>}
            <Field name={field}
                   component={isDisabled ? FieldWithValidation : SelectFieldWithValidation}
                   type="text"
                   validate={validations}
                   isDisabled={isDisabled}
                   style={style}
                   intlMessages={intlMessages}
                   options={options}
                   format={isDisabled ? ((value) => value ? handleFormat(value) : undefined) : undefined}
                   onChange={handleChange ? handleChange : undefined}
                   isRequired
                   label={label}
                   isSmall
                   placeholder="---"
                   noEmpty={noEmpty}
                   sort={sort}
                   customOptionsMsg={customOptionsMsg}
            />
        </div>
    )
};
export default (injectIntl(InputSelectFieldWithValidation));
