import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components';
import GenericMainPage from './GenericMainPage';
import NotFoundPage from '../NotFoundPage';
import PropTypes from 'prop-types';

const FlexWrapper = styled.div`
    padding-left: 0;
    display:flex;
`;

class Generic extends Component {
    render() {
        return (
            <FlexWrapper>
                <Switch>
                    <Route exact path={`${this.props.match.url}/`} component={GenericMainPage}/>
                    <Route component={NotFoundPage}/>
                </Switch>
            </FlexWrapper>
        );
    }
}

Generic.propTypes = {
    match: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    isToggled: state.menu.isToggled,
});

export default connect(mapStateToProps, null)(Generic);
