import {defineMessages} from 'react-intl';

const messages = defineMessages({
    UPLOADING_ATTACHMENTS: {
        id: 'spareParts.ticket.attachments.uploadingAttachments',
        defaultMessage: 'Ticket was saved, uploading attachments...'
    },
    TICKET: {
        id: 'spareParts.ticket.ticket',
        defaultMessage: 'Ticket'
    },
    WAIT_DIC: {
        id: 'spareParts.ticket.dic.loadingMessage',
        defaultMessage: 'Resolving DIC response, please wait...'
    },
    TICKET_SECTION: {
        id: 'spareParts.ticket.header.dealerInformation',
        defaultMessage: 'Dealer information'
    },
    MESSAGE: {
        id: 'spareParts.ticket.label.dialogBox',
        defaultMessage: 'DialogBox'
    },
    CREATION_DATE: {
        id: 'spareParts.ticket.label.creationDate',
        defaultMessage: 'Creation Date'
    },
    FORM_LABEL_ORDER_ITEMS: {
        id: 'spareParts.ticket.orders.orderItem',
        defaultMessage: 'Order Item'
    },
    ORDERS_SECTION: {
        id: 'spareParts.ticket.header.orders',
        defaultMessage: 'Orders'
    },
    NOTE_SECTION: {
        id: 'spareParts.ticket.header.notes',
        defaultMessage: 'Notes'
    },
    SUPPLEMENTARY_INFORMATION_SECTION: {
        id: 'spareParts.ticket.header.supplementaryInformationSection',
        defaultMessage: 'Supplementary Information'
    },
    COURTESY_REPLY_HEADER: {
        id: 'spareParts.ticket.header.carCourtesyReply',
        defaultMessage: 'VOR/PVI - Courtesy Car NPDC Reply'
    },
    VEHICLE_DETAIL_VOR_PVI_SECTION: {
        id: 'spareParts.ticket.header.vehicleDetailVorPvi',
        defaultMessage: 'VOR/PVI - Customer / Vehicle Details'
    },
    BATTERY_DETAIL_SECTION: {
        id: 'spareParts.ticket.header.batteryDetail',
        defaultMessage: 'Battery Detail'
    },
    BATTERY_PROBLEM_DESCRIPTION_SECTION: {
        id: 'spareParts.ticket.header.batteryProblemDescriptio',
        defaultMessage: 'Problem Description'
    },
    PROBLEM_DESCRIPTION: { //YY
        id: 'spareParts.ticket.header.problemDescription',
        defaultMessage: 'Problem Description'
    },
    INVOICE_NUMBER: {
        id: 'spareParts.ticket.header.invoiceNumber',
        defaultMessage: 'Invoice Number (RCL)'
    },
    MANAGEMENT_GROUP: {
        id: 'spareParts.ticket.header.managementGroup',
        defaultMessage: 'Management Group'
    },
    AVAILABILITY_SECOND_HAND_STOCK: {
        id: 'spareParts.ticket.header.availabilitySecondHandStock',
        defaultMessage: 'Battery in (RCL)'
    },
    BATTERY_PROBLEM_TYPE: {
        id: 'spareParts.ticket.header.batteryProblemType',
        defaultMessage: 'Problem Type'
    },
    CLAIM_PROBLEM_TYPE: {
        id: 'spareParts.ticket.header.claimProblemType',
        defaultMessage: 'Claim Problem Type'
    },
    PRODUCT_CODE: {
        id: 'spareParts.ticket.header.productCode',
        defaultMessage: 'Product Code'
    },
    WAREHOUSE_SECTION: {
        id: 'spareParts.ticket.header.carCourtesy',
        defaultMessage: 'VOR/PVI - Courtesy Car Request'
    },
    ATTACHMENTS_SECTION: {
        id: 'spareParts.ticket.header.attachments',
        defaultMessage: 'Attachments'
    },
    DEALER_ATTACHMENTS: {
        id: 'spareParts.ticket.label.dealerAttachments',
        defaultMessage: 'Dealer Attachments'
    },
    EDITOR_ATTACHMENTS: {
        id: 'spareParts.ticket.label.editorAttachments',
        defaultMessage: 'Editor Attachments'
    },
    DEALER_NAME: {
        id: 'spareParts.ticket.label.dealerName',
        defaultMessage: 'Dealer name'
    },
    DEALER_ADDRESS: {
        id: 'spareParts.ticket.label.dealerAddress',
        defaultMessage: 'Dealer Address'
    },
    COUNTRY: {
        id: 'spareParts.ticket.label.country',
        defaultMessage: 'Country'
    },
    DELIVERY_CONTACT_NAME: {
        id: 'spareParts.ticket.label.deliveryContactName',
        defaultMessage: 'Delivery Contact Name'
    },
    EORI_NUMBER: {
        id: 'spareParts.ticket.label.eoriNumber',
        defaultMessage: 'EORI Number'
    },
    FIC_CUR_NUMBER: {
        id: 'spareParts.ticket.label.ficCurNumber',
        defaultMessage: 'CIR Number'
    },
    BATTERY_REF_NUMBER: {
        id: 'spareParts.ticket.label.batteryRefNumber',
        defaultMessage: 'Battery Reference Number'
    },
    BIN: {
        id: 'spareParts.ticket.label.bin',
        defaultMessage: 'BIN'
    },
    DEALER_ORDER: {
        id: 'spareParts.ticket.label.dealerOrder',
        defaultMessage: 'Dealer Order'
    },
    DEALER_NUMBER: {
        id: 'spareParts.ticket.label.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    CLIENT_ID: {
        id: 'spareParts.ticket.label.clientId',
        defaultMessage: 'Client ID'
    },
    DESTINATION_CODE: {
        id: 'spareParts.ticket.label.destinationCode',
        defaultMessage: 'Destination code'
    },
    REGION: {
        id: 'spareParts.ticket.label.region',
        defaultMessage: 'Region'
    },
    NAME: {
        id: 'spareParts.ticket.label.name',
        defaultMessage: 'Name'
    },
    EMAIL: {
        id: 'spareParts.ticket.label.email',
        defaultMessage: 'E-mail'
    },
    TELEPHONE_NUMBER: {
        id: 'spareParts.ticket.label.telephoneNumber',
        defaultMessage: 'Telephone number'
    },
    DIC_NUMBER: {
        id: 'spareParts.ticket.label.dicNumber',
        defaultMessage: 'N° DIC'
    },
    MPR88: {
        id: 'spareParts.ticket.label.mpr88',
        defaultMessage: 'Order MPR88'
    },
    ORDER_REPLY_HISTORY: {
        id: 'spareParts.ticket.label.replyHistory',
        defaultMessage: 'Reply history'
    },
    ORDER_REPLY: {
        id: 'spareParts.ticket.label.reply',
        defaultMessage: 'Reply'
    },
    VOR_TRANSFER: {
        id: 'spareParts.ticket.table.tipOverToVor',
        defaultMessage: 'To VOR'
    },
    PROD: {
        id: 'spareParts.ticket.table.prod',
        defaultMessage: 'PROD'
    },
    ORDER: {
        id: 'spareParts.ticket.table.order',
        defaultMessage: 'Order'
    },
    LINE: {
        id: 'spareParts.ticket.table.line',
        defaultMessage: 'Line'
    },
    PART_NUMBER: {
        id: 'spareParts.ticket.table.partNumber',
        defaultMessage: 'Part No'
    },
    DESCRIPTION: {
        id: 'spareParts.ticket.table.description',
        defaultMessage: 'Description'
    },
    QUANTITY: {
        id: 'spareParts.ticket.table.quantity',
        defaultMessage: 'QTY'
    },
    REMARKS: {
        id: 'spareParts.ticket.table.remarks',
        defaultMessage: 'Remarks'
    },
    SUBJECT_TO_VOR: {
        id: 'spareParts.ticket.table.subjectToVor',
        defaultMessage: 'SubjectToVOR'
    },
    DIC_TRANSFER: {
        id: 'spareParts.ticket.table.dic',
        defaultMessage: 'DIC'
    },
    NOTES_HISTORY: {
        id: 'spareParts.ticket.label.notesHistory',
        defaultMessage: 'Notes history'
    },
    NOTE: {
        id: 'spareParts.ticket.label.note',
        defaultMessage: 'Note'
    },
    VIN: {
        id: 'spareParts.ticket.label.vinNumber',
        defaultMessage: 'VIN'
    },
    BVM_CONNECTION: {
        id: 'spareParts.ticket.label.bvmConnection',
        defaultMessage: 'BVM connection'
    },
    DELIVERY_DATE: {
        id: 'spareParts.ticket.label.deliveryDate',
        defaultMessage: 'Date of delivery to customer'
    },
    REGISTRATION_NUMBER: {
        id: 'spareParts.ticket.label.registrationNumber',
        defaultMessage: 'Registration number'
    },
    VEHICLE_TYPE: {
        id: 'spareParts.ticket.label.vehicleType',
        defaultMessage: 'Vehicle Type'
    },
    VEHICLE_MODEL: {
        id: 'spareParts.ticket.label.vehicleModel',
        defaultMessage: 'Car Model'
    },
    CUSTOMER_NAME: {
        id: 'spareParts.ticket.label.customerName',
        defaultMessage: 'Customer Name'
    },
    VEHICLE_DETAIL_TELEPHONE_NUMBER: {
        id: 'spareParts.ticket.vehicleDetail.label.telephoneNumber',
        defaultMessage: 'Telephone No'
    },
    REPAIR_ORDER_NUMBER: {
        id: 'spareParts.ticket.label.repairOrderNo',
        defaultMessage: 'Repair Order No'
    },
    CAR_LOCATION: {
        id: 'spareParts.ticket.label.repairedCarLocation',
        defaultMessage: 'Repaired car location'
    },
    AGREEMENT: {
        id: 'spareParts.ticket.label.courtesyCarReplyAgreement',
        defaultMessage: 'Agreement out of procedure\n'
    },
    TOTAL_DAYS: {
        id: 'spareParts.ticket.label.totalDays',
        defaultMessage: 'Total number of days'
    },
    TOTAL_AMOUNT: {
        id: 'spareParts.ticket.label.totalAmount',
        defaultMessage: 'Total amount'
    },
    AUTHORITY_GIVEN: {
        id: 'spareParts.ticket.label.authorityGiven',
        defaultMessage: 'Authority Given'
    },
    AUTHORITY_NUMBER: {
        id: 'spareParts.ticket.label.authorityNumber',
        defaultMessage: 'Authority No'
    },
    FROM: {
        id: 'spareParts.ticket.label.from',
        defaultMessage: 'From'
    },
    UNTIL: {
        id: 'spareParts.ticket.label.until',
        defaultMessage: 'Until'
    },
    DAILY_RATE: {
        id: 'spareParts.ticket.label.dailyRate',
        defaultMessage: 'Daily Rate'
    },
    COURTESY_REPLY_REMARKS: {
        id: 'spareParts.ticket.label.courtesyReplyRemarks',
        defaultMessage: 'Remarks'
    },
    REIMBURSEMENT: {
        id: 'spareParts.ticket.label.requestReimbursement',
        defaultMessage: 'Request of Courtesy Car Cost Reimbursement'
    },
    COURTESY_CAR_TYPE: {
        id: 'spareParts.ticket.label.courtesyCarType',
        defaultMessage: 'Courtesy Car Type'
    },
    SUPPLEMENTARY_INFORMATION: {
        id: 'spareParts.ticket.label.supplementaryInformation',
        defaultMessage: 'Supplementary information'
    },
    COURTESY_NOTES_HISTORY: {
        id: 'spareParts.ticket.label.courtesyNotesHistory',
        defaultMessage: 'Notes history'
    },
    HISTORY_CAR_TYPE: {
        id: 'spareParts.ticket.courtesyCarHistory.label.carType',
        defaultMessage: 'Car Type'
    },
    HISTORY_INFORMATION: {
        id: 'spareParts.ticket.courtesyCarHistory.label.information',
        defaultMessage: 'Information'
    },
    HISTORY_REQUEST: {
        id: 'spareParts.ticket.courtesyCarHistory.label.requestReimbursement',
        defaultMessage: 'Request'
    },
    LIC_PROD: {
        id: 'spareParts.ticket.orders.table.mpr88.licProd',
        defaultMessage: 'MPR88 - Lic PROD'
    },
    LIC_ORD: {
        id: 'spareParts.ticket.orders.table.mpr88.licOrd',
        defaultMessage: 'MPR88 - Lic ORD'
    },
    LIC_OAN: {
        id: 'spareParts.ticket.orders.table.mpr88.licOan',
        defaultMessage: 'MPR88 - Lic OAN'
    },
    LIC_LIG: {
        id: 'spareParts.ticket.orders.table.mpr88.licLig',
        defaultMessage: 'MPR88 - Lic LIG'
    },
    LIC_IAN: {
        id: 'spareParts.ticket.orders.table.mpr88.licIan',
        defaultMessage: 'MPR88 - Lic IAN'
    },
    NEW_BATTERY: {
        id: 'spareParts.ticket.label.newBattery',
        defaultMessage: 'New battery'
    },
    SECOND_HAND_BATTERY: {
        id: 'spareParts.ticket.label.secondHandBattery',
        defaultMessage: 'Second-Hand battery'
    },
    BATTERY_TYPE: {
        id: 'spareParts.ticket.label.batteryType',
        defaultMessage: 'Battery Type'
    },


    MESSAGES_HISTORY: { //YY
        id: 'spareParts.ticket.label.messagesHistory',
        defaultMessage: 'History of Messages'
    },
    PROBLEM_DESCRIPTION_SECTION: { //YY
        id: 'spareParts.ticket.header.problemDescriptionSection',
        defaultMessage: 'Problem Description'
    },
    PART_MARKING: { //YY
        id: 'spareParts.ticket.label.partMarking',
        defaultMessage: 'Part marking'
    },
    REQUESTOR_IPN: { //YY
        id: 'spareParts.ticket.label.requestorIpn',
        defaultMessage: 'Requestor Ipn'
    },
    REQUESTOR_NAME: { //YY
        id: 'spareParts.ticket.label.requestorName',
        defaultMessage: 'Requestor Name'
    },
    MANUFACTURE_DATE: { //YY
        id: 'spareParts.ticket.label.manufactureDate',
        defaultMessage: 'Manufacturing Date'
    },
    VEHICLE_DETAIL_SECTION: { //YY
        id: 'spareParts.ticket.header.vehicleDetail',
        defaultMessage: 'Vehicle Details'
    },
    TAPV: { //YY
        id: 'spareParts.ticket.label.tapv',
        defaultMessage: 'TAPV'
    },
    VEHICLE_FAMILY: { //YY
        id: 'spareParts.ticket.label.vehicleFamily',
        defaultMessage: 'Vehicle Family'
    },
    VEHICLE_BRAND: { //YY
        id: 'spareParts.ticket.label.vehicleBrand',
        defaultMessage: 'Vehicle Brand'
    },
    PART_DETAIL_SECTION: { //YY
        id: 'spareParts.ticket.header.partDetailSection',
        defaultMessage: 'Part Detail'
    },
    N_REFERENCE_CAUSING_PROBLEM: { //YY
        id: 'spareParts.ticket.label.nReferenceCausingProblem',
        defaultMessage: 'N° Reference causing problem'
    },
    N_REFERENCE_FOR_REPLACEMENT: { //YY
        id: 'spareParts.ticket.label.nReferenceForReplacement',
        defaultMessage: 'N° Reference for Replacement'
    },
    CATALOG_NUMBER: { //YY
        id: 'spareParts.ticket.label.catalogNumber',
        defaultMessage: 'Catalog Number'
    },
    PLATE_NUMBER: { //YY
        id: 'spareParts.ticket.label.plateNumber',
        defaultMessage: 'Plate Number'
    },
    SOLUTION_GROUP: { //YY
        id: 'spareParts.ticket.label.solutionGroup',
        defaultMessage: 'Solution Group'
    },
    PARTNER_NAME: { //YY
        id: 'spareParts.ticket.label.partnerName',
        defaultMessage: 'Partner Name'
    },
    TYPE_OF_REQUEST: { //YY
        id: 'spareParts.ticket.label.typeOfRequest',
        defaultMessage: 'Type Of Request'
    },
    PRIORITY_TREATMENT: { //YY
        id: 'spareParts.ticket.label.priorityTreatment',
        defaultMessage: 'Priority Treatment Needed?'
    },
    PRIORITY_TYPE: { //YY
        id: 'spareParts.ticket.label.priorityType',
        defaultMessage: 'Priority Type'
    },
    SRC_NUMBER: {
        id: 'spareParts.ticket.label.srcNumber',
        defaultMessage: 'SRC Number'
    },
    NETWORK_TICKET_NUMBER: { //YY
        id: 'spareParts.ticket.label.networkTicketNumber',
        defaultMessage: 'Original Associated Network Ticket Number'
    },
    ROOT_CAUSE: { //YY
        id: 'spareParts.ticket.label.rootCause',
        defaultMessage: 'Root Cause'
    },
    REQUEST_FOR_PARTNER: { //YY
        id: 'spareParts.ticket.label.requestForPartner',
        defaultMessage: 'Request For Partner'
    },
    INTERNAL_TICKET_REFERENCES: { //YY
        id: 'spareParts.ticket.header.internalTicketReferences',
        defaultMessage: 'Internal Ticket References'
    },
    DOCUMENTATION_UPDATE_SECTION: { //YY
        id: 'spareParts.ticket.header.documentationUpdate',
        defaultMessage: 'Documentation Update'
    },
    STORE_CHECK_RESULTS_SECTION: { //YY
        id: 'spareParts.ticket.header.storeCheckResults',
        defaultMessage: 'Store Check Results'
    },
    DOC_UPDATE_NEEDED: { //YY
        id: 'spareParts.ticket.label.docUpdateNeeded',
        defaultMessage: 'Documentation update needed?'
    },
    UPDATE_FILE_NUMBER: { //YY
        id: 'spareParts.ticket.label.updateFileNumber',
        defaultMessage: 'Update File Number'
    },
    ESTIMATED_DATE_OF_MODIFICATION: { //YY
        id: 'spareParts.ticket.label.estimatedDateOfModification',
        defaultMessage: 'Estimated Date Of Modification'
    },
    // editor's notes section
    EDITORS_NOTES_SECTION_SP: { //YY
        id: 'spareParts.ticket.header.editorsNotes',
        defaultMessage: 'Internal Notes'
    },
    EDITORS_NOTES_HISTORY_SP: { //YY
        id: 'spareParts.ticket.header.editorsNotesHistory',
        defaultMessage: 'History of Internal Notes'
    },
    EDITORS_NOTES_SP: { //YY
        id: 'spareParts.ticket.label.editorsNotes',
        defaultMessage: 'Note'
    },
    DELETE_ATTACHMENT: { //YY
        id: 'spareParts.internalTicket.label.deleteAttachments',
        defaultMessage: 'Delete'
    },
    REQUEST_DETAIL_SECTION: {
        id: 'spareParts.ticket.header.requestDetailSection',
        defaultMessage: 'Request Detail'
    },
    CLIENT_TYPE: {
        id: 'spareParts.ticket.label.clientType',
        defaultMessage: 'Type of Client'
    },
    SRC_REQUEST: {
        id: 'spareParts.ticket.label.srcRequest',
        defaultMessage: 'SRC Request°'
    },
    SRC_CLAIM_N: {
        id: 'spareParts.ticket.label.srcClaimN',
        defaultMessage: 'SRC Claim N°'
    },
    MORE_DETAILS: {
        id: 'spareParts.ticket.label.moreDetails',
        defaultMessage: 'More details'
    },
    ENGINE_TYPE: {
        id: 'spareParts.ticket.label.engineTypeAndSuffix',
        defaultMessage: 'Engine Type & Suffix'
    },
    ENGINE_SERIAL_NUMBER: {
        id: 'spareParts.ticket.label.engineSerialNumber',
        defaultMessage: 'Engine serial number'
    },
    GEARBOX_TYPE: {
        id: 'spareParts.ticket.label.gearboxType',
        defaultMessage: 'Gearbox Type & Suffix'
    },
    GEARBOX_SERIAL_NUMBER: {
        id: 'spareParts.ticket.label.gearboxSerialNumber',
        defaultMessage: 'Gearbox serial number'
    },
    STEERING_WHEEL: {
        id: 'spareParts.ticket.label.steeringWheel',
        defaultMessage: 'Steering Wheel on'
    },
    AVAILABILITY_DATE: {
        id: 'spareParts.ticket.label.availabilityDateToCustomer',
        defaultMessage: 'ADTC'
    },
    YES: {
        id: 'spareParts.ticket.label.yes',
        defaultMessage: 'Yes'
    },
    NO: {
        id: 'spareParts.ticket.label.no',
        defaultMessage: 'No'
    },
});

export default messages;
