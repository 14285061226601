import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import Table from '../../../common/table/TicketTable';
import {IconDelete, IconEdit, PrimaryIcon} from '../../../common/Button';
import { debounce } from 'throttle-debounce';
import moment from 'moment';
import {
    deleteAnsweringSchemaListRow,
    fetchAnsweringSchemaListPageData
} from '../../../../actions/admin/actionAnsweringSchemaListPage';
import {Link} from 'react-router-dom';
import ConfirmationDialog from '../../../common/modal/ConfirmationDialog';
import answeringSchemaMessages from '../../../../intl/admin/answeringSchemaMessages';

class ListAnsweringSchemaTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.loadStateFromSessionStorage({
            listASDefaultPageSize: 10,
            listASDefaultSort: [],
            listASDefaultFilters: [],
        });
        this.filtering = false;

        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleFetchDataDebounced = debounce(1000, this.handleFetchData);
        this.newData = [];
        this.deletedRow = false;
    }

    componentDidMount() {
        window.addEventListener(
            'beforeunload',
            this.saveStateToSessionStorage.bind(this)
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            'beforeunload',
            this.saveStateToSessionStorage.bind(this)
        );
        this.saveStateToSessionStorage();
    }

    loadStateFromSessionStorage(defaults) {
        return Object.keys(defaults)
            .reduce((acc, key) => {
                if (sessionStorage.hasOwnProperty(key) && key.match(/^listAnsweringSchema*/)) {
                    let value = sessionStorage.getItem(key);
                    value = JSON.parse(value);
                    return {
                        ...acc,
                        [key]: value,
                    };
                }
                return acc;
            }, defaults);
    }

    saveStateToSessionStorage() {
        for (let key in this.state) {
            if (key.match(/^listAnsweringSchema*/)) {
                sessionStorage.setItem(key, JSON.stringify(this.state[key]));
            }
        }
    }

    handleFetchData = state => {
        this.props.fetchAnsweringSchemaListPageData(this.props.domain, state.pageSize, state.sorted, state.cursor, state.isNextFromPivot, this.normalizeFiltered(state));
        this.filtering = false;
        this.setState({
            listASDefaultPageSize: state.pageSize,
            listASDefaultSort: state.sorted,
            listASDefaultFilters: state.filtered
        });
    };

    fetchStrategy = (state) => {
        this.filtering ? this.handleFetchDataDebounced(state) : this.handleFetchData(state);
    };

    normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date) {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    showRemoveModal = (e) => {
        this.deleteThisRow = false;
        this.setState({
            isOpenRemoveDialog: true,
            solutionGroupId: e.solutionGroupId,
            formType: e.formType,
            id: e.id
        });
    };

    closeModal = e => {
        e.preventDefault();
        this.deleteThisRow = false;
        this.setState({
            isOpenRemoveDialog: false,
            solutionGroupId: null,
            formType: null,
            id: null
        });
    };

    saveModal = e => {
        e.preventDefault();
        this.deleteThisRow = true;
        this.setState({
            isOpenRemoveDialog: false,
        });
        this.onClickDelete();
    };

    onClickDelete = () => {
        this.props.deleteAnsweringSchemaListRow(this.props.domain, this.state.solutionGroupId, this.state.formType)
    };

    render() {
        const { columns, data, pages, isLoading, handleEditClick } = this.props;
        if(this.deleteThisRow){
            if(this.deletedRow) {
                this.newData = this.newData.filter(v => v.id !== this.state.id);
            } else {
                this.deletedRow = true;
                this.newData = data.filter(v => v.id !== this.state.id);
            }
        }
        return (
            <>
                <ConfirmationDialog show={this.state.isOpenRemoveDialog} closeModal={this.closeModal}
                                    saveModal={this.saveModal} actionIdentifier={answeringSchemaMessages.TABLE_AS_REMOVE}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Table
                                columns={[
                                    {
                                        id: 'name',
                                        accessor: 'name',
                                        message: answeringSchemaMessages.TABLE_SOLUTION_GROUP,
                                        Cell: row => <Link to={`view/${row.original.id}`}>{row.value}</Link>
                                    },
                                    ...columns]}
                                toolButtons={[
                                    {
                                        handleClick: handleEditClick,
                                        component: <PrimaryIcon className="btn btn-sm mr-sm-1" title="Edit" type="button"
                                                                key="editButton">
                                            <IconEdit/>
                                        </PrimaryIcon>
                                    },
                                    {
                                        handleClick: this.showRemoveModal,
                                        component: <PrimaryIcon className="btn btn-sm mr-sm-1" title="Delete" type="button"
                                                                key="deleteButton">
                                            <IconDelete/>
                                        </PrimaryIcon>
                                    },
                                ]}
                                isLoading={isLoading}
                                data={this.deletedRow ? this.newData : data}
                                pages={pages}
                                handleFetchData={this.fetchStrategy}
                                handleOnFilteredChange={() => {
                                    this.filtering = true;
                                }}
                                defaultFiltered={this.state.listASDefaultFilters}
                                defaultSorting={this.state.listASDefaultSort}
                                defaultPageSize={this.state.listASDefaultPageSize}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

ListAnsweringSchemaTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number.isRequired,
    fetchAnsweringSchemaListPageData: PropTypes.func.isRequired,
    columns: PropTypes.array,
    userRoles: PropTypes.object.isRequired,
    handleEditClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminAnsweringSchemaList.isTableLoading,
    data: state.adminAnsweringSchemaList.listData,
    pages: state.adminAnsweringSchemaList.pages,
});

export default connect(mapStateToProps, {
    fetchAnsweringSchemaListPageData,
    deleteAnsweringSchemaListRow
})(ListAnsweringSchemaTable);
