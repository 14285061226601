import React from 'react';
import {clearFields, Field, formValueSelector} from 'redux-form'
import {connect} from 'react-redux';
import {
    FORM_CHECKBOX_NEW_OVAL_PLATE,
    FORM_CHECKBOX_OLD_OVAL_PLATE,
    FORM_FIELD_NEW_PLATE1,
    FORM_FIELD_NEW_PLATE10,
    FORM_FIELD_NEW_PLATE11,
    FORM_FIELD_NEW_PLATE12,
    FORM_FIELD_NEW_PLATE13,
    FORM_FIELD_NEW_PLATE14,
    FORM_FIELD_NEW_PLATE15,
    FORM_FIELD_NEW_PLATE16,
    FORM_FIELD_NEW_PLATE17,
    FORM_FIELD_NEW_PLATE2,
    FORM_FIELD_NEW_PLATE3,
    FORM_FIELD_NEW_PLATE4,
    FORM_FIELD_NEW_PLATE5,
    FORM_FIELD_NEW_PLATE6,
    FORM_FIELD_NEW_PLATE7,
    FORM_FIELD_NEW_PLATE8,
    FORM_FIELD_NEW_PLATE9,
    FORM_FIELD_OLD_PLATE1,
    FORM_FIELD_OLD_PLATE2,
    FORM_FIELD_OLD_PLATE3,
    FORM_FIELD_OLD_PLATE4,
    FORM_FIELD_OLD_PLATE5,
    FORM_FIELD_OLD_PLATE6,
    FORM_FIELD_OLD_PLATE7,
    FORM_FIELD_OLD_PLATE8,
    FORM_VEHICLE_DETAIL_SECTION
} from '../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';
import fulltextSearchMessages from '../../../../intl/technicalDocumentations/fulltextSearchMessages';
import {FormattedMessage} from 'react-intl';


const NewOvalPlate = (props) => {
    const { clearFields, newOvalSelected } = props;

    const clearPlateFields = (e) => {
        if (e.target.value === 'true') {
            clearFields(
                FORM_CHECKBOX_NEW_OVAL_PLATE,
                FORM_FIELD_NEW_PLATE1,
                FORM_FIELD_NEW_PLATE10,
                FORM_FIELD_NEW_PLATE11,
                FORM_FIELD_NEW_PLATE12,
                FORM_FIELD_NEW_PLATE13,
                FORM_FIELD_NEW_PLATE14,
                FORM_FIELD_NEW_PLATE15,
                FORM_FIELD_NEW_PLATE16,
                FORM_FIELD_NEW_PLATE17,
                FORM_FIELD_NEW_PLATE2,
                FORM_FIELD_NEW_PLATE3,
                FORM_FIELD_NEW_PLATE4,
                FORM_FIELD_NEW_PLATE5,
                FORM_FIELD_NEW_PLATE6,
                FORM_FIELD_NEW_PLATE7,
                FORM_FIELD_NEW_PLATE8,
                FORM_FIELD_NEW_PLATE9);
        }
        clearFields(
            FORM_CHECKBOX_OLD_OVAL_PLATE, FORM_FIELD_OLD_PLATE1,
            FORM_FIELD_OLD_PLATE2, FORM_FIELD_OLD_PLATE3, FORM_FIELD_OLD_PLATE4,
            FORM_FIELD_OLD_PLATE5, FORM_FIELD_OLD_PLATE6, FORM_FIELD_OLD_PLATE7,
            FORM_FIELD_OLD_PLATE8);
    };

    return (
        <>
            <div className="col-xl-6 col-lg-6 pb-2">
                <div className="form-row justify-content-center">
                    <div className="form-row">
                        <label className="pl-2 pt-1">
                            <Field
                                name={FORM_CHECKBOX_NEW_OVAL_PLATE}
                                component="input"
                                type="checkbox"
                                onChange={(e) => clearPlateFields(e)}
                            />{' '}
                            <FormattedMessage {...fulltextSearchMessages.NEW_OVAL_PLATE}/>
                        </label>
                    </div>
                </div>
                <div className="form-row">
                    <div className="offset-1 col-3 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE1}
                                                  maxLength={4}
                                                  isSmall
                                                  isDisabled={!newOvalSelected}/>
                    </div>
                    <div className="offset-4 col-3 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE2}
                                                  maxLength={7}
                                                  isSmall
                                                  isDisabled={!newOvalSelected}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="offset-1 col-3 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE3}
                                                  maxLength={8}
                                                  isSmall
                                                  isDisabled={!newOvalSelected}/>
                    </div>
                    <div className="col-4 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE4}
                                                  maxLength={8}
                                                  isSmall
                                                  isDisabled={!newOvalSelected}/>
                    </div>
                    <div className="col-3 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE5}
                                                  maxLength={8}
                                                  isSmall
                                                  isDisabled={!newOvalSelected}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="offset-1 col-3 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE6}
                                                  maxLength={6}
                                                  isSmall
                                                  isDisabled={!newOvalSelected}/>
                    </div>
                    <div className="col-4 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE7}
                                                  maxLength={8}
                                                  isSmall
                                                  isDisabled={!newOvalSelected}/>
                    </div>
                    <div className="col-3 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE8}
                                                  maxLength={8}
                                                  isSmall
                                                  isDisabled={!newOvalSelected}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="offset-1 col-3 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE9}
                                                  maxLength={8}
                                                  isSmall
                                                  isDisabled={!newOvalSelected}/>
                    </div>
                    <div className="col-4 pb-1">
                        <div className="form-row">
                            <div className="col-3 pb-1 pr-0">
                                <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE10}
                                                          maxLength={1}
                                                          isSmall
                                                          isDisabled={!newOvalSelected}/>
                            </div>
                            <div className="col-2 pb-1 px-0">
                                <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE11}
                                                          maxLength={1}
                                                          isSmall
                                                          isDisabled={!newOvalSelected}/>
                            </div>
                            <div className="col-2 pb-1 px-0">
                                <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE12}
                                                          maxLength={1}
                                                          isSmall
                                                          isDisabled={!newOvalSelected}/>
                            </div>
                            <div className="col-2 pb-1 px-0">
                                <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE13}
                                                          maxLength={1}
                                                          isSmall
                                                          isDisabled={!newOvalSelected}/>
                            </div>
                            <div className="col-3 pb-1 pl-0">
                                <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE14}
                                                          maxLength={1}
                                                          isSmall
                                                          isDisabled={!newOvalSelected}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 pb-1">
                        <div className="form-row">
                            <div className="col-4 pr-0">
                                <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE15}
                                                          maxLength={1}
                                                          isSmall
                                                          isDisabled={!newOvalSelected}/>
                            </div>
                            <div className="col-4 px-0">
                                <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE16}
                                                          maxLength={1}
                                                          isSmall
                                                          isDisabled={!newOvalSelected}/>
                            </div>
                            <div className="col-4 pl-0">
                                <InputFieldWithValidation field={FORM_FIELD_NEW_PLATE17}
                                                          maxLength={1}
                                                          isSmall
                                                          isDisabled={!newOvalSelected}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, initialProps) => ({
    newOvalSelected: selector(initialProps.form, state, `${FORM_VEHICLE_DETAIL_SECTION}.ovalPlate.${FORM_CHECKBOX_NEW_OVAL_PLATE}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    clearFields: (...fields) => dispatch(clearFields(props.form, false, false, ...fields.map(e => FORM_VEHICLE_DETAIL_SECTION + '.ovalPlate.' + e))),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOvalPlate);
