import get from 'get-value';
import {SPARE_PARTS_HISTORY_FETCH_PENDING} from "../../actions/orders/actionSparePartsHistoryPage";
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const sparePartsHistoryPage = (state = {
    correlationId: null,
    isLoading: false,
    isTableLoading: false,
    listData: [],
    pages: 0,
    isNextFromPivot: true,
    isNextPage: false,
}, action) => {
    switch (action.type) {
        case SPARE_PARTS_HISTORY_FETCH_PENDING: {
            return Object.assign({}, state, { isTableLoading: true });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_HISTORY_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isTableLoading: false,
                    listData: get(action, 'payload.result.data', { default: [] }),
                    pages: get(action, 'payload.result.pages', { default: 0 }),
                    isNextFromPivot: get(action, 'payload.result.isNextFromPivot', false),
                    isNextPage: get(action, 'payload.result.isNextPage', false),
                    correlationId
                });
            }
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_HISTORY_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {isTableLoading: false, correlationId});
            }
        }
        default:
            return state;
    }
};
