import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'admin.genericTicket.solutionGroup.title',
        defaultMessage: 'Generic Tickets - Solution Group'
    },
    TABLE_NAME: {
        id: 'admin.genericTicket.solutionGroup.table.name',
        defaultMessage: 'Solution Group'
    },
    TABLE_ACTIVE: {
        id: 'admin.genericTicket.solutionGroup.table.active',
        defaultMessage: 'Active'
    },
    TABLE_COUNTRIES: {
        id: 'admin.genericTicket.solutionGroup.table.countries',
        defaultMessage: 'Countries'
    },
    TABLE_DEFAULT: {
        id: 'admin.genericTicket.solutionGroup.table.default',
        defaultMessage: 'Level 1 Group'
    },
    TABLE_USERS: {
        id: 'admin.genericTicket.solutionGroup.table.users',
        defaultMessage: 'Members'
    },
    TABLE_SELECT_USERS: {
        id: 'admin.genericTicket.solutionGroup.table.selectUsers',
        defaultMessage: 'Select users'
    },
    TABLE_SELECT_USERS_SEARCH: {
        id: 'admin.genericTicket.solutionGroup.table.selectUsers.search',
        defaultMessage: 'Start searching by name or IPN...'
    },
    TABLE_CAN_FORWARD_TO_DEALER: {
        id: 'admin.genericTicket.solutionGroup.table.canForwardToDealer',
        defaultMessage: 'Answer To Dealer'
    },
    SG_NAME: {
        id: 'admin.genericTicket.solutionGroup.form.sgName',
        defaultMessage: 'Solution group name'
    },
    SG_ACTIVE: {
        id: 'admin.genericTicket.solutionGroup.form.sgActive',
        defaultMessage: 'Solution group active'
    },
    SG_DEFAULT: {
        id: 'admin.genericTicket.solutionGroup.form.sgDefault',
        defaultMessage: 'Level 1 Group'
    },
    SG_ANSWER_TO_DEALER:{
        id: 'admin.genericTicket.solutionGroup.form.sgAnswerToDealer',
        defaultMessage: 'Answer to dealer'
    },
    SG_NOTIFICATIONS:{
        id: 'admin.genericTicket.solutionGroup.form.sgNotifications',
        defaultMessage: 'Notifications'
    },
    SG_INTERNAL_NOTIFICATIONS: {
        id: 'admin.genericTicket.solutionGroup.form.sgInternalNotifications',
        defaultMessage: 'Internal Ticket Notifications'
    },
    SG_LEVEL: {
        id: 'admin.genericTicket.solutionGroup.form.sgLevel',
        defaultMessage: 'Solution group level'
    },
    SG_ADMINS: {
        id: 'admin.genericTicket.solutionGroup.form.sgAdmins',
        defaultMessage: 'Solution group administrators'
    },
    SG_USERS: {
        id: 'admin.genericTicket.solutionGroup.form.sgUsers',
        defaultMessage: 'Solution group editors'
    },
    SG_COUNTRIES: {
        id: 'admin.genericTicket.solutionGroup.form.sgCountries',
        defaultMessage: 'Level 1 countries'
    },
});

export default messages;
