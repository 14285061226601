import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import styled from 'styled-components';
import {PrimaryButton, SecondaryButton} from '../Button';
import {ModalBody, ModalFooter, ModalHeader, ModalWindow} from './Modal';
import modalMessages from '../../../intl/common/modalMessages';
import buttonMessages from '../../../intl/common/buttonMessages';

const Button = styled(SecondaryButton)`
  margin-right: 10px;
`;

class ConfirmationDialog extends Component {
    render() {
        const {show, saveModal, closeModal, actionIdentifier = 'proceed', intl: {formatMessage}} = this.props;
        return (
            <ModalWindow isOpen={show} onRequestClose={closeModal} shouldCloseOnOverlayClick={false}>
                <form onSubmit={saveModal} className="form">
                    <ModalHeader>
                        <FormattedMessage {...modalMessages.CONFIRMATION_DIALOG_TITLE}/>
                    </ModalHeader>
                    <ModalBody>
                        <FormattedMessage {...modalMessages.CONFIRMATION_DIALOG_BODY}
                                          values={{action: actionIdentifier instanceof Object ? formatMessage(actionIdentifier) : actionIdentifier}}/>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row align-items-end">
                            <div className="col">
                                <Button type="button" className="btn" onClick={closeModal}>
                                    <FormattedMessage {...buttonMessages.NO}/>
                                </Button>
                                <PrimaryButton type="submit" className="btn">
                                    <FormattedMessage {...buttonMessages.YES}/>
                                </PrimaryButton>
                            </div>
                        </div>
                    </ModalFooter>
                </form>
            </ModalWindow>
        )
    }
}

ConfirmationDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveModal: PropTypes.func.isRequired,
    actionIdentifier: PropTypes.any
};

export default injectIntl(ConfirmationDialog);
