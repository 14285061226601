export const techDocWebsocketCommands = {
    //route (commands)
    TECH_DOC_HISTORY_GET: 'getHistory',
    TECH_DOC_LIST_GET: 'getList',
    TECH_DOC_INTERNAL_TICKET_HISTORY_GET: 'getInternalHistory',
    TECH_DOC_INTERNAL_TICKET_LIST_GET: 'getInternalList',
    TECH_DOC_INTERNAL_TICKET_SEARCH_GET: 'getFullTextSearchInternal',
    TECH_DOC_NOTIFICATIONS_GET: 'getEmailNotification',
    TECH_DOC_NOTIFICATIONS_SET: 'setEmailNotification',
    TECH_DOC_MAIN_MESSAGE_GET: 'getMainMessage',
    TECH_DOC_MAIN_MESSAGES_GET: 'getGroupsMainMessages',
    TECH_DOC_MAIN_MESSAGE_SET: 'setMainMessage',
    TECH_DOC_POPUP_GET: 'getPopUp',
    TECH_DOC_POPUP_SET: 'setPopUp',
    TECH_DOC_TICKET_SETTINGS_SET: 'updateTicketSettings',
    TECH_DOC_TICKET_SETTINGS_GET: 'getTicketSettings',
    TECH_DOC_DEFAULT_DEALER_ROLE_GET: 'getDefaultDealerRole',
    TECH_DOC_DEFAULT_DEALER_ROLE_SET: 'setDefaultDealerRole',
    TECH_DOC_WORKING_HOURS_GET: 'getWorkingHours',
    TECH_DOC_WORKING_HOURS_SET: 'setWorkingHours',
    TECH_DOC_RECURRENT_PROBLEMS_GET: 'getRecurrentProblem',
    TECH_DOC_RECURRENT_PROBLEMS_CREATE: 'createRecurrentProblem',
    TECH_DOC_RECURRENT_PROBLEMS_MOVE: 'moveRecurrentProblem',
    TECH_DOC_RECURRENT_PROBLEMS_DELETE: 'deleteRecurrentProblem',
    TECH_DOC_RECURRENT_PROBLEMS_UPDATE: 'updateRecurrentProblem',
    TECH_DOC_RECURRENT_PROBLEMS_FILE_DOWNLOAD: 'recurrentProblemAttachmentDownload',
    TECH_DOC_RECURRENT_PROBLEMS_FILE_UPLOAD: 'recurrentProblemAttachmentUpload',
    TECH_DOC_ADMIN_ROLE_GET: 'getAdminRole',
    TECH_DOC_ADMIN_ROLE_SET: 'setAdminRole',
    TECH_DOC_DEALER_ROLE_GET: 'getDealerRole',
    TECH_DOC_DEALER_ROLE_SET: 'setDealerRole',
    TECH_DOC_EDITOR_ROLE_GET: 'getEditorRole',
    TECH_DOC_EDITOR_ROLE_SET: 'setEditorRole',
    TECH_DOC_SOLUTION_GROUPS_USER_ROLE_GET: 'getSolutionGroupEditorRole',
    TECH_DOC_SOLUTION_GROUPS_USER_ROLE_SET: 'setSolutionGroupEditorRole',
    TECH_DOC_USER_LIST_PREFERENCE_SET: 'setUserListPreference',
    TECH_DOC_USER_HISTORY_PREFERENCE_SET: 'setUserHistoryPreference',
    TECH_DOC_USER_INTERNAL_TICKET_LIST_PREFERENCE_SET: 'setUserInternalListPreference',
    TECH_DOC_USER_INTERNAL_TICKET_HISTORY_PREFERENCE_SET: 'setUserInternalHistoryPreference',
    TECH_DOC_USER_FULL_TEXT_SEARCH_PREFERENCE_SET: 'setUserFullTextSearchPreference',
    TECH_DOC_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE_SET: 'setUserFullTextSearchInternalPreference',
    TECH_DOC_USER_STATISTICS_PREFERENCE_SET: 'setUserStatisticsPreference',
    TECH_DOC_USER_STATISTICS_SG_PREFERENCE_SET: 'setUserStatisticsSGPreference',
    TECH_DOC_PREFERENCE_GET: 'getPreferences',
    TECH_DOC_DEALERS_GET: 'getDealers',
    TECH_DOC_EXPORT_GET: 'getExport',
    TECH_DOC_EXPORT_CREATE: 'createTicketExport',
    TECH_DOC_EXPORT_DOWNLOAD_URL_GET: 'exportDownloadPreSignedUrl',
    TECH_DOC_EXPORT_INTERNAL_GET: 'getInternalExport',
    TECH_DOC_EXPORT_INTERNAL_CREATE: 'createInternalTicketExport',
    TECH_DOC_EXPORT_INTERNAL_DOWNLOAD_URL_GET: 'internalExportDownloadPreSignedUrl',
    TECH_DOC_FULL_TEXT_SEARCH_GET: 'getFullTextSearch',
    TECH_DOC_FULL_TEXT_SEARCH_LAST_EDITORS_GET: 'getLastEditors',
    TECH_DOC_DIC_PART_INFO_GET: 'getPartInfo',
    TECH_DOC_MANAGEMENT_GROUP_GET: 'getManagementGroupCheck',
    TECH_DOC_MANAGEMENT_GROUP_CREATE: 'initiateManagementGroupBatch',
    TECH_DOC_MODEL_ANSWERS_GET: 'getModelAnswers',
    TECH_DOC_MODEL_ANSWERS_CREATE: 'createModelAnswer',
    TECH_DOC_MODEL_ANSWERS_UPDATE: 'updateModelAnswer',
    TECH_DOC_MODEL_ANSWERS_DELETE: 'deleteModelAnswer',
    TECH_DOC_FULL_SOLUTION_GROUP_INFO_GET: 'getFullSolutionGroupInfo',

    //receive
    TECH_DOC_TICKET_SEND: 'TECH_DOC_TICKET_SEND',
    TECH_DOC_TICKET_SEND_ERROR: 'TECH_DOC_TICKET_SEND_ERROR',
    TECH_DOC_TICKET_LIST_SEND: 'TECH_DOC_TICKET_LIST_SEND',
    TECH_DOC_TICKET_LIST_SEND_ERROR: 'TECH_DOC_TICKET_LIST_SEND_ERROR',
    TECH_DOC_TICKET_HISTORY_SEND: 'TECH_DOC_TICKET_HISTORY_SEND',
    TECH_DOC_TICKET_HISTORY_SEND_ERROR: 'TECH_DOC_TICKET_HISTORY_SEND_ERROR',
    TECH_DOC_GENERATED_PRESIGNED_URLS: 'TECH_DOC_GENERATED_PRESIGNED_URLS',
    TECH_DOC_TICKET_CREATED: 'TECH_DOC_TICKET_CREATED',
    TECH_DOC_TICKET_CREATE_ERROR: 'TECH_DOC_TICKET_CREATE_ERROR',
    TECH_DOC_TICKET_LOCKED: 'TECH_DOC_TICKET_LOCKED',
    TECH_DOC_TICKET_LOCKED_ERROR: 'TECH_DOC_TICKET_LOCKED_ERROR',
    TECH_DOC_TICKET_UNLOCKED: 'TECH_DOC_TICKET_UNLOCKED',
    TECH_DOC_TICKET_UNLOCKED_ERROR: 'TECH_DOC_TICKET_UNLOCKED_ERROR',
    TECH_DOC_TICKET_ATTACHMENTS_SAVED: 'TECH_DOC_TICKET_ATTACHMENTS_SAVED',
    TECH_DOC_TICKET_ATTACHMENTS_SAVED_ERROR: 'TECH_DOC_TICKET_ATTACHMENTS_SAVED_ERROR',
    TECH_DOC_TICKET_ATTACHMENTS_DOWNLOAD: 'TECH_DOC_TICKET_ATTACHMENTS_DOWNLOAD',
    TECH_DOC_TICKET_ATTACHMENTS_DOWNLOAD_ERROR: 'TECH_DOC_TICKET_ATTACHMENTS_DOWNLOAD_ERROR',
    TECH_DOC_TICKET_UPDATED: 'TECH_DOC_TICKET_UPDATED',
    TECH_DOC_TICKET_UPDATE_ERROR: 'TECH_DOC_TICKET_UPDATE_ERROR',
    TECH_DOC_ROLES_ACCEPTED: 'TECH_DOC_ROLES_ACCEPTED',
    TECH_DOC_ROLES_ERROR: 'TECH_DOC_ROLES_ERROR',
    TECH_DOC_ADMIN_ROLE_SEND: 'TECH_DOC_ADMIN_ROLE_SEND',
    TECH_DOC_ADMIN_ROLE_SEND_ERROR: 'TECH_DOC_ADMIN_ROLE_SEND_ERROR',
    TECH_DOC_DEALER_ROLE_SEND: 'TECH_DOC_DEALER_ROLE_SEND',
    TECH_DOC_DEALER_ROLE_SEND_ERROR: 'TECH_DOC_DEALER_ROLE_SEND_ERROR',
    TECH_DOC_EDITOR_ROLE_SEND: 'TECH_DOC_EDITOR_ROLE_SEND',
    TECH_DOC_EDITOR_ROLE_SEND_ERROR: 'TECH_DOC_EDITOR_ROLE_SEND_ERROR',
    TECH_DOC_SOLUTION_GROUPS_EDITOR_ROLE_SEND: 'TECH_DOC_SOLUTION_GROUPS_EDITOR_ROLE_SEND',
    TECH_DOC_SOLUTION_GROUPS_EDITOR_ROLE_SEND_ERROR: 'TECH_DOC_SOLUTION_GROUPS_EDITOR_ROLE_SEND_ERROR',
    TECH_DOC_NOTIFICATIONS_ACCEPTED: 'TECH_DOC_NOTIFICATIONS_ACCEPTED',
    TECH_DOC_NOTIFICATIONS_ERROR: 'TECH_DOC_NOTIFICATIONS_ERROR',
    TECH_DOC_NOTIFICATIONS_SEND: 'TECH_DOC_NOTIFICATIONS_SEND',
    TECH_DOC_NOTIFICATIONS_SEND_ERROR: 'TECH_DOC_NOTIFICATIONS_SEND_ERROR',
    TECH_DOC_WORKING_HOURS_ACCEPTED: 'TECH_DOC_WORKING_HOURS_ACCEPTED',
    TECH_DOC_WORKING_HOURS_ERROR: 'TECH_DOC_WORKING_HOURS_ERROR',
    TECH_DOC_WORKING_HOURS_SEND: 'TECH_DOC_WORKING_HOURS_SEND',
    TECH_DOC_WORKING_HOURS_SEND_ERROR: 'TECH_DOC_WORKING_HOURS_SEND_ERROR',
    TECH_DOC_RECURRENT_PROBLEMS_ACCEPTED: 'TECH_DOC_RECURRENT_PROBLEMS_ACCEPTED',
    TECH_DOC_RECURRENT_PROBLEMS_ERROR: 'TECH_DOC_RECURRENT_PROBLEMS_ERROR',
    TECH_DOC_RECURRENT_PROBLEMS_SEND: 'TECH_DOC_RECURRENT_PROBLEMS_SEND',
    TECH_DOC_RECURRENT_PROBLEMS_SEND_ERROR: 'TECH_DOC_RECURRENT_PROBLEMS_SEND_ERROR',
    TECH_DOC_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD: 'TECH_DOC_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD',
    TECH_DOC_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD_ERROR: 'TECH_DOC_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD_ERROR',
    TECH_DOC_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD: 'TECH_DOC_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD',
    TECH_DOC_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD_ERROR: 'TECH_DOC_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD_ERROR',
    TECH_DOC_MAIN_MESSAGES_ACCEPTED: 'TECH_DOC_MAIN_MESSAGES_ACCEPTED',
    TECH_DOC_MAIN_MESSAGES_ERROR: 'TECH_DOC_MAIN_MESSAGES_ERROR',
    TECH_DOC_MAIN_MESSAGES_SEND: 'TECH_DOC_MAIN_MESSAGES_SEND',
    TECH_DOC_MAIN_MESSAGES_SEND_ERROR: 'TECH_DOC_MAIN_MESSAGES_SEND_ERROR',
    TECH_DOC_GROUPS_MAIN_MESSAGES_SEND: 'TECH_DOC_GROUPS_MAIN_MESSAGES_SEND',
    TECH_DOC_GROUPS_MAIN_MESSAGES_SEND_ERROR: 'TECH_DOC_GROUPS_MAIN_MESSAGES_SEND_ERROR',
    TECH_DOC_POPUP_ACCEPTED: 'TECH_DOC_POPUP_ACCEPTED',
    TECH_DOC_POPUP_ERROR: 'TECH_DOC_POPUP_ERROR',
    TECH_DOC_POPUP_SEND: 'TECH_DOC_POPUP_SEND',
    TECH_DOC_POPUP_SEND_ERROR: 'TECH_DOC_POPUP_SEND_ERROR',
    TECH_DOC_TICKET_SETTINGS_ACCEPTED: 'TECH_DOC_TICKET_SETTINGS_ACCEPTED',
    TECH_DOC_TICKET_SETTINGS_ERROR: 'TECH_DOC_TICKET_SETTINGS_ERROR',
    TECH_DOC_TICKET_SETTINGS_SEND: 'TECH_DOC_TICKET_SETTINGS_SEND',
    TECH_DOC_TICKET_SETTINGS_SEND_ERROR: 'TECH_DOC_TICKET_SETTINGS_SEND_ERROR',
    TECH_DOC_DEFAULT_DEALER_ROLES_SEND: 'TECH_DOC_DEFAULT_DEALER_ROLES_SEND',
    TECH_DOC_DEFAULT_DEALER_ROLES_ERROR: 'TECH_DOC_DEFAULT_DEALER_ROLES_SEND_ERROR',
    TECH_DOC_PREFERENCES_ACCEPTED: 'TECH_DOC_PREFERENCES_ACCEPTED',
    TECH_DOC_PREFERENCES_ERROR: 'TECH_DOC_PREFERENCES_ERROR',
    TECH_DOC_PREFERENCES_SEND: 'TECH_DOC_PREFERENCES_SEND',
    TECH_DOC_PREFERENCES_SEND_ERROR: 'TECH_DOC_PREFERENCES_SEND_ERROR',
    TECH_DOC_DEALERS_SEND: 'TECH_DOC_DEALERS_FETCH_SUCCESS',
    TECH_DOC_DEALERS_SEND_ERROR: 'TECH_DOC_DEALERS_FETCH_ERROR',
    TECH_DOC_EXPORT_SEND: 'TECH_DOC_EXPORT_FETCH_SUCCESS',
    TECH_DOC_EXPORT_SEND_ERROR: 'TECH_DOC_EXPORT_FETCH_ERROR',
    TECH_DOC_EXPORT_STATUS: 'TECH_DOC_EXPORT_STATUS',
    TECH_DOC_EXPORT_ERROR: 'TECH_DOC_EXPORT_ERROR',
    TECH_DOC_EXPORT_DOWNLOAD_URL_SEND: 'TECH_DOCS_EXPORT_DOWNLOAD',
    TECH_DOC_EXPORT_DOWNLOAD_URL_SEND_ERROR: 'TECH_DOCS_EXPORT_DOWNLOAD_ERROR',
    TECH_DOC_FULL_TEXT_SEARCH_SEND: 'TECH_DOC_FULL_TEXT_SEARCH_FETCH_SUCCESS',
    TECH_DOC_FULL_TEXT_SEARCH_SEND_ERROR: 'TECH_DOC_FULL_TEXT_SEARCH_FETCH_ERROR',
    TECH_DOC_FULL_TEXT_SEARCH_LAST_EDITORS_SEND: 'TECH_DOC_FULL_TEXT_SEARCH_LAST_EDITORS_FETCH_SUCCESS',
    TECH_DOC_FULL_TEXT_SEARCH_LAST_EDITORS_SEND_ERROR: 'TECH_DOC_FULL_TEXT_SEARCH_LAST_EDITORS_FETCH_ERROR',
    TECH_DOC_INTERNAL_TICKET_SEND: 'TECH_DOC_INTERNAL_TICKET_SEND',
    TECH_DOC_INTERNAL_TICKET_SEND_ERROR: 'TECH_DOC_INTERNAL_TICKET_SEND_ERROR',
    TECH_DOC_INTERNAL_TICKET_LIST_SEND: 'TECH_DOC_INTERNAL_TICKET_LIST_SEND',
    TECH_DOC_INTERNAL_TICKET_LIST_SEND_ERROR: 'TECH_DOC_INTERNAL_TICKET_LIST_SEND_ERROR',
    TECH_DOC_INTERNAL_TICKET_HISTORY_SEND: 'TECH_DOC_INTERNAL_TICKET_HISTORY_SEND',
    TECH_DOC_INTERNAL_TICKET_HISTORY_SEND_ERROR: 'TECH_DOC_INTERNAL_TICKET_HISTORY_SEND_ERROR',
    TECH_DOC_INTERNAL_TICKET_SEARCH_SEND: 'TECH_DOC_INTERNAL_TICKET_SEARCH_SEND',
    TECH_DOC_INTERNAL_TICKET_SEARCH_SEND_ERROR: 'TECH_DOC_INTERNAL_TICKET_SEARCH_SEND_ERROR',
    TECH_DOC_INTERNAL_TICKET_UPDATE_ERROR: 'TECH_DOC_INTERNAL_TICKET_UPDATE_ERROR',
    TECH_DOC_INTERNAL_TICKET_CREATE_ERROR: 'TECH_DOC_INTERNAL_TICKET_CREATE_ERROR',
    TECH_DOC_DIC_PART_INFO_SEND: 'TECH_DOC_DIC_PART_INFO_SEND',
    TECH_DOC_DIC_PART_INFO_SEND_ERROR: 'TECH_DOC_DIC_PART_INFO_ERROR',
    TECH_DOC_EXPORT_INTERNAL_SEND: 'TECH_DOC_EXPORT_INTERNAL_FETCH_SUCCESS',
    TECH_DOC_EXPORT_INTERNAL_SEND_ERROR: 'TECH_DOC_EXPORT_INTERNAL_FETCH_ERROR',
    TECH_DOC_EXPORT_INTERNAL_STATUS: 'TECH_DOC_EXPORT_INTERNAL_STATUS',
    TECH_DOC_EXPORT_INTERNAL_ERROR: 'TECH_DOC_EXPORT_INTERNAL_ERROR',
    TECH_DOC_EXPORT_INTERNAL_DOWNLOAD_URL_SEND: 'TECH_DOC_EXPORT_INTERNAL_DOWNLOAD',
    TECH_DOC_EXPORT_INTERNAL_DOWNLOAD_URL_SEND_ERROR: 'TECH_DOC_EXPORT_INTERNAL_DOWNLOAD_ERROR',

    // SECOND-HAND BATTERY TYPE
    TECH_DOC_ESCALATION_LEVELS_GET: 'getEscalationLevels',
    TECH_DOC_ESCALATION_LEVEL_CREATE: 'createEscalationLevel',
    TECH_DOC_ESCALATION_LEVEL_DELETE: 'deleteEscalationLevel',
    TECH_DOC_ESCALATION_LEVEL_UPDATE: 'updateEscalationLevel',
    // SECOND-HAND BATTERY TYPE RESPONSE
    TECH_DOC_ESCALATION_LEVEL_ERROR: 'TECH_DOC_ESCALATION_LEVEL_ERROR',
    TECH_DOC_ESCALATION_LEVEL_SEND: 'TECH_DOC_ESCALATION_LEVEL_SEND',
    TECH_DOC_ESCALATION_LEVEL_SEND_ERROR: 'TECH_DOC_ESCALATION_LEVEL_SEND_ERROR',

    //SOLUTION GROUPS
    //Get List Solution Group
    TECH_DOC_SOLUTION_GROUPS_LIST: 'getSolutionGroups',
    TECH_DOC_SOLUTION_GROUPS_LIST_FETCH_PENDING: 'TECH_DOC_SOLUTION_GROUPS_LIST_FETCH_PENDING',
    TECH_DOC_SOLUTION_GROUPS_LIST_SEND: 'TECH_DOC_SOLUTION_GROUPS_LIST_SEND',
    TECH_DOC_SOLUTION_GROUPS_LIST_SEND_ERROR: 'TECH_DOC_SOLUTION_GROUPS_LIST_SEND_ERROR',
    //Get Detail Solution Group
    TECH_DOC_SOLUTION_GROUPS_DETAIL: 'getSolutionGroup',
    TECH_DOC_SOLUTION_GROUPS_DETAIL_FETCH_PENDING: 'TECH_DOC_SOLUTION_GROUP_DETAIL_FETCH_PENDING',
    //Create Solution Group
    TECH_DOC_SOLUTION_GROUPS_CREATE: 'createSolutionGroup',
    TECH_DOC_SOLUTION_GROUPS_CREATE_PENDING: 'TECH_DOC_SOLUTION_GROUPS_CREATE_PENDING',
    TECH_DOC_SOLUTION_GROUPS_ACCEPTED: 'TECH_DOC_SOLUTION_GROUPS_ACCEPTED',
    TECH_DOC_SOLUTION_GROUPS_ERROR: 'TECH_DOC_SOLUTION_GROUPS_ERROR',
    //Update Solution Group
    TECH_DOC_SOLUTION_GROUPS_UPDATE: 'updateSolutionGroup',
    TECH_DOC_SOLUTION_GROUPS_UPDATE_PENDING: 'TECH_DOC_SOLUTION_GROUPS_UPDATE_PENDING',
    //Update Solution Group - NAME, USERS and RESET
    TECH_DOC_SOLUTION_GROUP_RESET: 'TECH_DOC_SOLUTION_GROUP_RESET',
    TECH_DOC_SOLUTION_GROUP_REDIRECT_RESET: 'TECH_DOC_SOLUTION_GROUP_REDIRECT_RESET',
    TECH_DOC_SOLUTION_GROUPS_SEND: 'TECH_DOC_SOLUTION_GROUPS_SEND',
    TECH_DOC_SOLUTION_GROUPS_SEND_ERROR: 'TECH_DOC_SOLUTION_GROUPS_SEND_ERROR',
    TECH_DOC_SOLUTION_GROUP_SAVE_NEW_ERROR: 'TECH_DOC_SOLUTION_GROUP_SAVE_NEW_ERROR',
    TECH_DOC_SOLUTION_GROUP_SAVE_UPDATE_ERROR: 'TECH_DOC_SOLUTION_GROUP_SAVE_UPDATE_ERROR',

    //Get Full Solution Group
    TECH_DOC_FULL_SOLUTION_GROUP_INFO_SEND: 'TECH_DOC_FULL_SOLUTION_GROUP_INFO_SEND',
    TECH_DOC_FULL_SOLUTION_GROUP_INFO_SEND_ERROR: 'TECH_DOC_FULL_SOLUTION_GROUP_INFO_SEND_ERROR',

    //ESCALATION SCHEMA
    //Get List Solution Group
    TECH_DOC_ESCALATION_SCHEMA_LIST: 'getEscalationSchemas',
    TECH_DOC_ESCALATION_SCHEMA_LIST_FETCH_PENDING: 'TECH_DOC_ESCALATION_SCHEMA_LIST_FETCH_PENDING',
    TECH_DOC_ESCALATION_SCHEMA_LIST_SEND: 'TECH_DOC_ESCALATION_SCHEMA_LIST_SEND',
    TECH_DOC_ESCALATION_SCHEMA_LIST_SEND_ERROR: 'TECH_DOC_ESCALATION_SCHEMA_LIST_SEND_ERROR',
    //Get Detail Solution Group
    TECH_DOC_ESCALATION_SCHEMA_DETAIL: 'getEscalationSchema',
    TECH_DOC_ESCALATION_SCHEMA_DETAIL_FETCH_PENDING: 'TECH_DOC_ESCALATION_SCHEMA_DETAIL_FETCH_PENDING',
    //Create Solution Group
    TECH_DOC_ESCALATION_SCHEMA_CREATE: 'createEscalationSchema',
    TECH_DOC_ESCALATION_SCHEMA_CREATE_PENDING: 'TECH_DOC_ESCALATION_SCHEMA_CREATE_PENDING',
    TECH_DOC_ESCALATION_SCHEMA_ACCEPTED: 'TECH_DOC_ESCALATION_SCHEMA_ACCEPTED',
    TECH_DOC_ESCALATION_SCHEMA_ERROR: 'TECH_DOC_ESCALATION_SCHEMA_ERROR',
    //Update Solution Group
    TECH_DOC_ESCALATION_SCHEMA_UPDATE: 'updateEscalationSchema',
    TECH_DOC_ESCALATION_SCHEMA_UPDATE_PENDING: 'TECH_DOC_ESCALATION_SCHEMA_UPDATE_PENDING',
    //Delete Solution Group
    TECH_DOC_ESCALATION_SCHEMA_DELETE: 'deleteEscalationSchema',
    TECH_DOC_ESCALATION_SCHEMA_DELETE_PENDING: 'TECH_DOC_ESCALATION_SCHEMA_DELETE_PENDING',
    //Update Solution Group - NAME, USERS and RESET
    TECH_DOC_ESCALATION_SCHEMA_RESET: 'TECH_DOC_ESCALATION_SCHEMA_RESET',
    TECH_DOC_ESCALATION_SCHEMA_REDIRECT_RESET: 'TECH_DOC_ESCALATION_SCHEMA_REDIRECT_RESET',
    TECH_DOC_ESCALATION_SCHEMA_SEND: 'TECH_DOC_ESCALATION_SCHEMA_SEND',
    TECH_DOC_ESCALATION_SCHEMA_SEND_ERROR: 'TECH_DOC_ESCALATION_SCHEMA_SEND_ERROR',
    TECH_DOC_ESCALATION_SCHEMA_SAVE_NEW_ERROR: 'TECH_DOC_ESCALATION_SCHEMA_SAVE_NEW_ERROR',
    TECH_DOC_ESCALATION_SCHEMA_SAVE_UPDATE_ERROR: 'TECH_DOC_ESCALATION_SCHEMA_SAVE_UPDATE_ERROR',


    //ANSWERING SCHEMA
    //Get List Solution Group
    TECH_DOC_ANSWERING_SCHEMA_LIST: 'getAnsweringSchemas',
    TECH_DOC_ANSWERING_SCHEMA_LIST_FETCH_PENDING: 'TECH_DOC_ANSWERING_SCHEMA_LIST_FETCH_PENDING',
    TECH_DOC_ANSWERING_SCHEMA_LIST_SEND: 'TECH_DOC_ANSWERING_SCHEMA_LIST_SEND',
    TECH_DOC_ANSWERING_SCHEMA_LIST_SEND_ERROR: 'TECH_DOC_ANSWERING_SCHEMA_LIST_SEND_ERROR',
    //Get Detail Solution Group
    TECH_DOC_ANSWERING_SCHEMA_DETAIL: 'getAnsweringSchema',
    TECH_DOC_ANSWERING_SCHEMA_DETAIL_FETCH_PENDING: 'TECH_DOC_ANSWERING_SCHEMA_DETAIL_FETCH_PENDING',
    //Create Solution Group
    TECH_DOC_ANSWERING_SCHEMA_CREATE: 'createAnsweringSchema',
    TECH_DOC_ANSWERING_SCHEMA_CREATE_PENDING: 'TECH_DOC_ANSWERING_SCHEMA_CREATE_PENDING',
    TECH_DOC_ANSWERING_SCHEMA_ACCEPTED: 'TECH_DOC_ANSWERING_SCHEMA_ACCEPTED',
    TECH_DOC_ANSWERING_SCHEMA_ERROR: 'TECH_DOC_ANSWERING_SCHEMA_ERROR',
    //Update Solution Group
    TECH_DOC_ANSWERING_SCHEMA_UPDATE: 'updateAnsweringSchema',
    TECH_DOC_ANSWERING_SCHEMA_UPDATE_PENDING: 'TECH_DOC_ANSWERING_SCHEMA_UPDATE_PENDING',
    //Delete Solution Group
    TECH_DOC_ANSWERING_SCHEMA_DELETE: 'deleteAnsweringSchema',
    TECH_DOC_ANSWERING_SCHEMA_DELETE_PENDING: 'TECH_DOC_ANSWERING_SCHEMA_DELETE_PENDING',
    //Update Solution Group - NAME, USERS and RESET
    TECH_DOC_ANSWERING_SCHEMA_RESET: 'TECH_DOC_ANSWERING_SCHEMA_RESET',
    TECH_DOC_ANSWERING_SCHEMA_REDIRECT_RESET: 'TECH_DOC_ANSWERING_SCHEMA_REDIRECT_RESET',
    TECH_DOC_ANSWERING_SCHEMA_SEND: 'TECH_DOC_ANSWERING_SCHEMA_SEND',
    TECH_DOC_ANSWERING_SCHEMA_SEND_ERROR: 'TECH_DOC_ANSWERING_SCHEMA_SEND_ERROR',
    TECH_DOC_ANSWERING_SCHEMA_SAVE_NEW_ERROR: 'TECH_DOC_ANSWERING_SCHEMA_SAVE_NEW_ERROR',
    TECH_DOC_ANSWERING_SCHEMA_SAVE_UPDATE_ERROR: 'TECH_DOC_ANSWERING_SCHEMA_SAVE_UPDATE_ERROR',

    //fetch SG options
    TECH_DOC_ADMIN_SOLUTION_GROUPS_OPTIONS: 'getAdminSolutionGroups',
    TECH_DOC_ADMIN_SOLUTION_GROUPS_FETCH_PENDING: 'TECH_DOC_ADMIN_SOLUTION_GROUPS_FETCH_PENDING',
    TECH_DOC_ADMIN_SOLUTION_GROUPS_SEND: 'TECH_DOC_ADMIN_SOLUTION_GROUPS_SEND',

    TECH_DOC_MANAGEMENT_GROUP_STATUS: 'TECH_DOC_MANAGEMENT_GROUP_BATCH_STATUS',
    TECH_DOC_MANAGEMENT_GROUP_ERROR: 'TECH_DOC_MANAGEMENT_GROUP_BATCH_ERROR',
    TECH_DOC_MANAGEMENT_GROUP_SEND: 'TECH_DOC_MANAGEMENT_GROUP_CHECK_SUCCESS',
    TECH_DOC_MANAGEMENT_GROUP_SEND_ERROR: 'TECH_DOC_MANAGEMENT_GROUP_CHECK_ERROR',

    TECH_DOC_MODEL_ANSWERS_SEND: 'TECH_DOC_MODEL_ANSWERS_SEND',
    TECH_DOC_MODEL_ANSWERS_SEND_ERROR: 'TECH_DOC_MODEL_ANSWERS_SEND_ERROR',
    TECH_DOC_MODEL_ANSWERS_SAVE: 'TECH_DOC_MODEL_ANSWERS_SAVE',
    TECH_DOC_MODEL_ANSWERS_SAVE_ERROR: 'TECH_DOC_MODEL_ANSWERS_SAVE_ERROR',
};
