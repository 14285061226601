import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import 'react-picky/dist/picky.css';
import {Link, withRouter} from 'react-router-dom';
import {clearAlerts} from '../../../actions/alertsActions';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {lockSparePartsInternalTicket} from '../../../actions/orders/actionSparePartsInternalLockTicket';
import PageHeader from '../../common/PageHeader';
import internalFulltextSearchMessages from '../../../intl/sparePartsOrders/internalFulltextSearchMessages';
import InternalTicketSearchSparePartsForm from './InternalTicketSearchSparePartsForm';
import get from 'get-value';
import {fillNumberFiveZero, formatTicketNumber, mapFiltersForFulltext} from '../../../utils/utils';
import InternalTicketSearchSparePartsTable from './InternalTicketSearchSparePartsTable';
import commonMessages from '../../../intl/common/commonMessages';
import {
    domains,
} from '../../../constants/Utils';
import internalTicketRequestTypeMessages from '../../../intl/sparePartsOrders/internalTicketRequestTypeMessages';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import priorityTypeMessages from '../../../intl/sparePartsOrders/priorityTypeMessages';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import requestForPartnerListMessages from '../../../intl/sparePartsOrders/requestForPartnerListMessages';
import {
    FORM_FIELD_CREATED_AT_INTERNAL_TICKET,
    FORM_FIELD_CREATOR_IPN, FORM_FIELD_DEALER_FORM_TYPE, FORM_FIELD_DEALER_TICKET_NUMBER, FORM_FIELD_PARTNER_NAME,
    FORM_FIELD_PRIORITY_TREATMENT, FORM_FIELD_PRIORITY_TYPE, FORM_FIELD_REQUEST_FOR_PARTNER,
    FORM_FIELD_REQUEST_STATUS, FORM_FIELD_SG_REQUESTER,
    FORM_FIELD_TYPE_OF_REQUEST
} from '../../../constants/formConstants';
import {StyledPicky} from '../../common/StyledComponents';
import {
    updateSparePartsUserFulltextSearchInternalPreference,
} from '../../../actions/orders/actionSparePartsPreferences';
import Loader from '../../Loader';

const SESSION_STORAGE_KEY = 'fullTextInternalTicketSP';
const domain = domains.SPARE_PARTS;

class InternalTicketSearchSparePartsPage extends Component {
    constructor(props) {
        super(props);
        const {intl} = props;
        const storageData = this.loadStateFromSessionStorage();
        this.state = {
            leavingPage: false,
            columnLibrary: [
                {
                    id: FORM_FIELD_TYPE_OF_REQUEST,
                    accessor: FORM_FIELD_TYPE_OF_REQUEST,
                    message: internalFulltextSearchMessages.TABLE_REQUEST_TYPE,
                    filterable: false,
                    Cell: e =>
                        <div>{internalTicketRequestTypeMessages[e.value] ? intl.formatMessage(internalTicketRequestTypeMessages[e.value]) : e.value}</div>
                },
                {
                    id: FORM_FIELD_REQUEST_STATUS,
                    accessor: FORM_FIELD_REQUEST_STATUS,
                    message: internalFulltextSearchMessages.TABLE_REQUEST_STATUS,
                    filterable: false,
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: FORM_FIELD_PRIORITY_TREATMENT,
                    accessor: FORM_FIELD_PRIORITY_TREATMENT,
                    message: internalFulltextSearchMessages.TABLE_PRIORITY,
                    filterable: false,
                    Cell: e => <div>{intl.formatMessage(commonMessages[e.value === 'YES' ? e.value : 'NO'])}</div>
                },
                {
                    id: FORM_FIELD_PRIORITY_TYPE,
                    accessor: FORM_FIELD_PRIORITY_TYPE,
                    message: internalFulltextSearchMessages.TABLE_PRIORITY_TYPE,
                    filterable: false,
                    Cell: e =>
                        <div>{priorityTypeMessages[e.value] ? intl.formatMessage(priorityTypeMessages[e.value]) : e.value}</div>,
                },
                {
                    id: FORM_FIELD_DEALER_TICKET_NUMBER,
                    accessor: 'ticketReference',
                    message: internalFulltextSearchMessages.TABLE_DEALER_TICKET_NUMBER,
                    filterable: false,
                    Cell: row => <Link
                        to={`/order/view/${get(row, 'value.uuid')}`}>{get(row, 'value.pfxNumber') ? formatTicketNumber(get(row, 'value.pfxNumber'), get(row, 'value.sfxNumber')) : ''}</Link>
                },
                {
                    id: FORM_FIELD_DEALER_FORM_TYPE,
                    accessor: 'ticketReference.formType',
                    message: internalFulltextSearchMessages.TABLE_DEALER_FORM_TYPE,
                    filterable: false,
                    Cell: e =>
                        <div>{formTypeMessages[e.value] ? intl.formatMessage(formTypeMessages[e.value]) : e.value}</div>
                },
                {
                    id: FORM_FIELD_CREATED_AT_INTERNAL_TICKET,
                    accessor: FORM_FIELD_CREATED_AT_INTERNAL_TICKET,
                    message: internalFulltextSearchMessages.TABLE_IT_CREATION_TIME,
                    filterable: false,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: FORM_FIELD_CREATOR_IPN,
                    accessor: FORM_FIELD_CREATOR_IPN,
                    message: internalFulltextSearchMessages.TABLE_IT_CREATED_BY,
                    filterable: false,
                },
                {
                    id: FORM_FIELD_SG_REQUESTER,
                    accessor: 'solutionGroup',
                    message: internalFulltextSearchMessages.TABLE_SG_REQUESTOR,
                    filterable: false,
                    Cell: e =>
                        <div>{get(e, 'value.name', '')}</div>
                },
                {
                    id: 'assignedSg',
                    accessor: 'assignedSolutionGroup',
                    message: internalFulltextSearchMessages.TABLE_ASSIGNED_SOLUTION_GROUP,
                    filterable: false,
                    Cell: e =>
                        <div>{get(e, 'value.name', '')}</div>
                },
                {
                    id: 'clientId',
                    accessor: 'ticketReference.clientId',
                    message: internalFulltextSearchMessages.TABLE_DEALER_CLIENT_ID,
                    filterable: false,
                },
                {
                    id: 'regDir',
                    accessor: 'ticketReference.region',
                    message: internalFulltextSearchMessages.TABLE_DEALER_REGION,
                    filterable: false,
                },
                {
                    id: 'dealerNumber',
                    accessor: 'ticketReference.dealerNumber',
                    message: internalFulltextSearchMessages.TABLE_IT_DEALER_NUMBER,
                    filterable: false,
                    Cell: e => fillNumberFiveZero(e.value)
                },
                {
                    id: 'partNumber',
                    accessor: 'nReferenceCausingProblem',
                    message: internalFulltextSearchMessages.TABLE_PART_NUMBER,
                    filterable: false,
                },
                {
                    id: 'managementGroup',
                    accessor: 'managementGroup',
                    message: internalFulltextSearchMessages.TABLE_MANAGEMENT_GROUP,
                    filterable: false,
                },
                {
                    id: FORM_FIELD_REQUEST_FOR_PARTNER,
                    accessor: FORM_FIELD_REQUEST_FOR_PARTNER,
                    message: internalFulltextSearchMessages.TABLE_PARTNER,
                    filterable: false,
                    Cell: e => <div>{intl.formatMessage(commonMessages[e.value === 'YES' ? e.value : 'NO'])}</div>
                },
                {
                    id: FORM_FIELD_PARTNER_NAME,
                    accessor: FORM_FIELD_PARTNER_NAME,
                    message: internalFulltextSearchMessages.TABLE_PARTNER_NAME,
                    filterable: false,
                    Cell: e =>
                        <div>{requestForPartnerListMessages[e.value] ? intl.formatMessage(requestForPartnerListMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'lastUpdate',
                    accessor: 'lastUpdate',
                    message: internalFulltextSearchMessages.TABLE_IT_LAST_STATUS_DATE,
                    filterable: false,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'lastEditor',
                    accessor: 'lastEditor',
                    message: internalFulltextSearchMessages.TABLE_IT_LAST_EDITOR,
                    filterable: false,
                }
            ],
            listDefaultFilters: get(storageData, 'filters', []),
            formInitValues: get(storageData, 'formValues', {}),
            searchOption: {},
        };
    }

    resetForm = () => {
        this.setState({listDefaultFilters: [], formInitValues: {}});
        this.saveStateToSessionStorage({}, [[], {}]);
    }

    saveStateToSessionStorage(formValues = {}, filters = []) {
        sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify({formValues, filters}));
    }

    loadStateFromSessionStorage() {
        if (sessionStorage.hasOwnProperty(SESSION_STORAGE_KEY)) {
            return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY));
        }
        return {};
    }

    handleEditClick = ({id}) => {
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockSparePartsInternalTicket(id);
            this.setState({leavingPage: true});
        }
    };

    selectMultipleOption = (value) => {
        const ipn = this.props.loggedUser.ipn;
        const preferences = this.state.columnLibrary
            .filter(item => !value.some(value => value.id === item.id))
            .map(column => column.id);

        this.props.updateSparePartsUserFulltextSearchInternalPreference(ipn, preferences);
    };

    handleSubmit = (values) => {
        const filter = mapFiltersForFulltext(values);
        this.setState({listDefaultFilters: [filter, values.searchOption || {}]});
        this.saveStateToSessionStorage(values, [filter, values.searchOption || {}]);
    };


    render() {
        const {intl, sparePartsPreferences, lockTicket} = this.props;
        if (lockTicket.redirect && this.state.leavingPage) {
            this.props.history.push(`/order/internalTicket/edit/${lockTicket.uuid}`)
        }

        if (sparePartsPreferences.isLoading) {
            return <Loader/>;
        }

        const hiddenColumns = get(sparePartsPreferences, 'menuFullTextSearchInternal', {default: []});
        const columns = this.state.columnLibrary.filter(item => !hiddenColumns.includes(item.id));
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...internalFulltextSearchMessages.TITLE}/>}/>
                <InternalTicketSearchSparePartsForm
                    initialValues={this.state.formInitValues}
                    onSubmit={this.handleSubmit}
                    resetForm={this.resetForm}
                    enableReinitialize={true}
                    form={'internalTicketSearchForm'}
                />
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span className="font-weight-bold">
                    <FormattedMessage {...internalFulltextSearchMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={columns.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={this.state.columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            selectAllText={<FormattedMessage {...commonMessages.SELECT_ALL}/>}
                            manySelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            allSelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <InternalTicketSearchSparePartsTable
                    columns={columns}
                    listDefaultFilters={this.state.listDefaultFilters}
                    handleEditClick={this.handleEditClick}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    sparePartsPreferences: state.sparePartsPreferences,
    lockTicket: state.sparePartsInternalTicketListOrderLockTicketCheck,
    roles: state.profile.userDetail.roles,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateSparePartsUserFulltextSearchInternalPreference,
    lockSparePartsInternalTicket,
    // resetRedirectAfterSparePartsLockTicket,
})(injectIntl(InternalTicketSearchSparePartsPage))), ['SP_SG_EDITOR']);

