import {defineMessages} from 'react-intl';

export const messages = defineMessages({ // here
    YES: {
        id: 'common.yes',
        defaultMessage: 'Yes'
    },
    NO: {
        id: 'common.no',
        defaultMessage: 'No'
    },
    PRESIDENT: {
        id: 'common.president',
        defaultMessage: 'President'
    },
    ALL: {
        id: 'common.all',
        defaultMessage: 'All'
    },
    NONE: {
        id: 'common.none',
        defaultMessage: 'Empty'
    },
    DEALER: {
        id: 'common.role.dealer',
        defaultMessage: 'Dealer'
    },
    LOADING: {
        id: 'common.loading',
        defaultMessage: 'Loading...'
    },
    EDITOR: {
        id: 'common.role.editor',
        defaultMessage: 'Editor'
    },
    SG_EDITOR: {
        id: 'common.role.sgEditor',
        defaultMessage: 'SG Editor'
    },
    ADMIN: {
        id: 'common.role.admin',
        defaultMessage: 'Admin'
    },
    DROP_ZONE_OVER_FILE: {
        id: 'common.dropZone.overFile',
        defaultMessage: 'Drop files here'
    },
    DROP_ZONE: {
        id: 'common.dropZone',
        defaultMessage: 'Click or drag and drop a file here.'
    },
    DND_AVAILABLE: {
        id: 'common.dragAndDrop.list.header.available',
        defaultMessage: 'Available'
    },
    DND_SELECTED: {
        id: 'common.dragAndDrop.list.header.selected',
        defaultMessage: 'Selected'
    },
    PAGE_DOES_NOT_EXIST: {
        id: 'common.invalid.page',
        defaultMessage: 'This page does not exist. If it should exist please contact support.'
    },
    UNAUTHORIZED: {
        id: 'common.unauthorized',
        defaultMessage: 'You don\'t have a permission to access this page.'
    },
    CONTACT_ADMIN: {
        id: 'common.contactAdministrator',
        defaultMessage: 'Please contact your administrator to assign you appropriate rights.'
    },
    IS_MANDATORY: {
        id: 'common.isMandatory',
        defaultMessage: 'is mandatory'
    },
    SELECT_ALL: {
        id: 'common.selectAll',
        defaultMessage: 'Select all'
    },
    SELECTED: {
        id: 'common.selected',
        defaultMessage: 'selected'
    },
    HELP: {
        id: 'common.help',
        defaultMessage: 'Help'
    },
});

export default messages;
