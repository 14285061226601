import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TABLE_SOLUTION_GROUP: {
        id: 'admin.escalationSchema.table.solutionGroup',
        defaultMessage: 'Solution group'
    },
    TABLE_FORMTYPE: {
        id: 'admin.escalationSchema.table.formtype',
        defaultMessage: 'Form/Request Type'
    },
    TICKET_TYPE: {
        id: 'admin.escalationSchema.table.ticketType',
        defaultMessage: 'Ticket Type'
    },
    TABLE_ESCALATION_SOLUTION_GROUPS: {
        id: 'admin.escalationSchema.table.escalationSolutionGroups',
        defaultMessage: 'Escalation solution groups'
    },
    TABLE_COUNTRIES: {
        id: 'admin.escalationSchema.table.countries',
        defaultMessage: 'Countries'
    },
    TABLE_ES_REMOVE: {
        id: 'admin.escalationSchema.form.esEscalationGroups.remove',
        defaultMessage: 'remove the escalation schema'
    },
    ES_SG_NAME: {
        id: 'admin.escalationSchema.form.essgName',
        defaultMessage: 'Solution group name'
    },
    ES_FORMTYPE: {
        id: 'admin.escalationSchema.form.esFormtype',
        defaultMessage: 'Form/Request Type'
    },
    ES_ESCALATION_GROUPS: {
        id: 'admin.escalationSchema.form.esEscalationGroups',
        defaultMessage: 'Escalation groups'
    },
});

export default messages;
