import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    TITLE: {
        id: 'admin.mainPageAlert.title',
        defaultMessage: 'Main page alerts'
    },
    SELECT_COUNTRY: {
        id: 'admin.mainPageAlert.selectCountry',
        defaultMessage: 'Select a country'
    },
    LAST_UPDATE: {
        id: 'admin.mainPageAlert.lastUpdate',
        defaultMessage: 'Last update'
    },
    MAIN_PAGE_MESSAGE: {
        id: 'admin.mainPageAlert.mainPageMessage',
        defaultMessage: 'Main page message'
    },
    PLACEHOLDER_MAIN_PAGE_MESSAGE: {
        id: 'admin.mainPageAlert.mainPageMessage.placeholder',
        defaultMessage: 'Main message'
    },
    POP_UP_MESSAGE: {
        id: 'admin.mainPageAlert.popUpMessage',
        defaultMessage: 'PopUp message'
    },
    PLACEHOLDER_POP_UP_MESSAGE: {
        id: 'admin.mainPageAlert.popUpMessage.placeholder',
        defaultMessage: 'PopUp message'
    }
});

export default messages;
