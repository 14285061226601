import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const SPARE_PARTS_VIN_DEALER_CHECK_SET = 'SPARE_PARTS_VIN_DEALER_CHECK_SET';
export const SPARE_PARTS_VIN_DEALER_CHECK_RESET = 'SPARE_PARTS_VIN_DEALER_CHECK_RESET';

export const vinCheckDealerSetActionCreator = () => {
    return {
        type: SPARE_PARTS_VIN_DEALER_CHECK_SET,
    }
};
export const vinCheckDealerResetActionCreator = () => {
    return {
        type: SPARE_PARTS_VIN_DEALER_CHECK_RESET,
    }
};

export const fetchVinCheckDealerActionCreator = (vin, dealerNumber) => {
    return {
        type: sparePartsWebsocketCommands.SPARE_PARTS_VIN_DEALER_CHECK,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_VIN_DEALER_CHECK,
        payload: {
            vin,
            dealerNumber
        },
    }
};


