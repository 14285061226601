import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {domains} from '../../constants/Utils';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const fetchEscalationSchemaListPageData = (domain, pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: techDocWebsocketCommands.TECH_DOC_ESCALATION_SCHEMA_LIST_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_ESCALATION_SCHEMA_LIST,
                payload: {pageSize: pageSize, sorted: sorted, cursor: cursor, isNextFromPivot: isNextFromPivot, filtered: filtered}
            });
        }
        case domains.SPARE_PARTS: {
            return dispatch({
                type: sparePartsWebsocketCommands.SPARE_PARTS_ESCALATION_SCHEMA_LIST_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ESCALATION_SCHEMA_LIST,
                payload: {pageSize: pageSize, sorted: sorted, cursor: cursor, isNextFromPivot: isNextFromPivot, filtered: filtered}
            });
        }
    }
};

export const deleteEscalationSchemaListRow = (domain, id, formType) => dispatch => {
    switch (domain) {
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: techDocWebsocketCommands.TECH_DOC_ESCALATION_SCHEMA_DELETE_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_ESCALATION_SCHEMA_DELETE,
                payload: {
                    payload:{
                        id: id,
                        escalationSchema: {
                            formType: formType
                        }
                    }
                }
            });
        }
        case domains.SPARE_PARTS: {
            return dispatch({
                type: sparePartsWebsocketCommands.SPARE_PARTS_ESCALATION_SCHEMA_DELETE_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ESCALATION_SCHEMA_DELETE,
                payload: {
                    payload:{
                        id: id,
                        escalationSchema: {
                            formType: formType
                        }
                    }
                }
            });
        }
    }
};
