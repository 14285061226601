import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'get-value';
import {fetchClaimsTicket, resetClaimsTicket, updateClaimsTicket} from '../../../actions/claims/actionClaimsTicket';
import {lockClaimsTicket, resetRedirectAfterClaimsLockTicket} from '../../../actions/claims/actionClaimsLockTicket';
import {clearAlerts} from '../../../actions/alertsActions';
import {formatTicketNumber} from '../../../utils/utils';
import {initLoadedClaimsTicket, transformUpdateClaimsTicketForSave} from '../Utils';
import {moduleRoles} from '../../../utils/roles';
import {editRights} from '../../../constants/claimsTicketRights';
import checkRoles from '../../common/roleChecker/RoleChecker';
import ContestClaimStatusMasterForm from './forms/ContestClaimStatusMasterForm';
import ProblemForPlacingClaimsMasterForm from './forms/ProblemForPlacingClaimsMasterForm';
import ReturnClaimMasterForm from './forms/ReturnClaimMasterForm';
import StockDeadClaimMasterForm from './forms/StockDeadClaimMasterForm';
import OldMaterialClaimMasterForm from './forms/OldMaterialClaimMasterForm';
import DirectFlowClaimMasterForm from './forms/DirectFlowClaimMasterForm';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formMessages from '../../../intl/claims/formMessages';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import {
    CONTEST_CLAIM_STATUS_FORM,
    PROBLEM_FOR_PLACING_CLAIMS_FORM,
    RETURN_CLAIM_FORM,
    STOCK_DEAD_CLAIM_FORM,
    OLD_MATERIAL_CLAIM_FORM,
    DIRECT_FLOW_CLAIM_FORM,
    FORM_BUTTON_REOPEN,
    TROUBLESHOOT_CLAIM_FORM, FORM_BUTTON_CLOSE, FORM_BUTTON_EDIT,
} from '../../../constants/formConstants';
import TroubleshootMasterForm from './forms/TroubleshootMasterForm';

class ViewClaimsTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToEdit: false,
            ticketSubmitted: false,
            storedTicketData: {},
            redirectToEditImmediately: false
        };
    }

    componentDidMount() {
        if (!this.props.location.redirectedAfterUpdate) {
            this.props.fetchClaimsTicket(this.props.match.params.ticketUuid);
        }
    }

    componentWillUnmount() {
        this.props.resetClaimsTicket();
        this.props.resetRedirectAfterClaimsLockTicket();
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const {ticketData} = this.props;
        switch (submitBy) {
            case FORM_BUTTON_REOPEN:
                this.props.lockClaimsTicket(this.props.match.params.ticketUuid);
                this.setState({redirectToEdit: true, ticketSubmitted: true, storedTicketData: transformUpdateClaimsTicketForSave(values)});
                break;
            case FORM_BUTTON_CLOSE:
                this.props.lockClaimsTicket(this.props.match.params.ticketUuid);
                this.setState({ticketSubmitted: true, storedTicketData: transformUpdateClaimsTicketForSave(values)});
                break;
            case FORM_BUTTON_EDIT:
                this.setState({redirectToEditImmediately: true, redirectToTicket: ticketData.ticket.uuid})
                this.props.resetClaimsTicket();
                break;
        }
    };

    render() {
        const {ticketData, loggedUser} = this.props;
        let formType = false;
        if (get(ticketData, 'ticket.type', {default: false})) {
            formType = ticketData.ticket.type;
        }

        if (!this.props.lockTicket.isTicketLocking && !this.props.lockTicket.redirect && this.state.ticketSubmitted && this.state.redirectToEdit) {
            this.props.updateClaimsTicket(this.state.storedTicketData);
            return <Redirect to={{
                pathname: `/claims/edit/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        } else if (!this.props.lockTicket.isTicketLocking && !this.props.lockTicket.redirect && this.state.ticketSubmitted) {
            this.props.updateClaimsTicket(this.state.storedTicketData);
            this.setState({ticketSubmitted: false, storedTicketData: {}});
        } else if (this.state.redirectToEditImmediately) {
            return <Redirect to={{
                pathname: `/claims/edit/${this.state.redirectToTicket}`,
            }}/>;
        }

        const initializationValue = initLoadedClaimsTicket(ticketData);
        if (
            ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || !formType
        ) {
            return <Loader/>;
        }
        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {formType === CONTEST_CLAIM_STATUS_FORM &&
                    <ContestClaimStatusMasterForm form={formType}
                                                  initialValues={initializationValue}
                                                  userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                  enableReinitialize={true}
                                                  handleSubmit={this.handleSubmit}
                                                  reqFields={[]}
                                                  readOnly/>
                    }
                    {formType === PROBLEM_FOR_PLACING_CLAIMS_FORM &&
                    <ProblemForPlacingClaimsMasterForm form={formType}
                                                       initialValues={initializationValue}
                                                       userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                       enableReinitialize={true}
                                                       handleSubmit={this.handleSubmit}
                                                       reqFields={[]}
                                                       readOnly/>
                    }
                    {formType === RETURN_CLAIM_FORM &&
                    <ReturnClaimMasterForm form={formType}
                                           initialValues={initializationValue}
                                           userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                           enableReinitialize={true}
                                           handleSubmit={this.handleSubmit}
                                           reqFields={[]}
                                           readOnly/>
                    }
                    {formType === STOCK_DEAD_CLAIM_FORM &&
                    <StockDeadClaimMasterForm form={formType}
                                              initialValues={initializationValue}
                                              userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                              enableReinitialize={true}
                                              handleSubmit={this.handleSubmit}
                                              reqFields={[]}
                                              readOnly/>
                    }
                    {formType === OLD_MATERIAL_CLAIM_FORM &&
                    <OldMaterialClaimMasterForm form={formType}
                                                initialValues={initializationValue}
                                                userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                enableReinitialize={true}
                                                handleSubmit={this.handleSubmit}
                                                reqFields={[]}
                                                readOnly/>
                    }
                    {formType === DIRECT_FLOW_CLAIM_FORM &&
                    <DirectFlowClaimMasterForm form={formType}
                                               initialValues={initializationValue}
                                               userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                               enableReinitialize={true}
                                               handleSubmit={this.handleSubmit}
                                               reqFields={[]}
                                               readOnly/>
                    }
                    {formType === TROUBLESHOOT_CLAIM_FORM &&
                    <TroubleshootMasterForm form={formType}
                                               initialValues={initializationValue}
                                               userRights={editRights[moduleRoles.isEditorClaims(loggedUser) ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                               enableReinitialize={true}
                                               handleSubmit={this.handleSubmit}
                                               reqFields={[]}
                                               readOnly/>
                    }
                </div>
            </div>
        );
    }
}

ViewClaimsTicketPage.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    resetClaimsTicket: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.claimsTicket,
    lockTicket: state.claimsLockTicketCheck,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchClaimsTicket,
    resetClaimsTicket,
    lockClaimsTicket,
    updateClaimsTicket,
    resetRedirectAfterClaimsLockTicket,
    clearAlerts,
})(injectIntl(ViewClaimsTicketPage))), ['CL_DEALER', 'CL_EDITOR']);

