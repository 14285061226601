import {CLAIMS_STATISTICS_SEND, CLAIMS_STATISTICS_SEND_ERROR} from '../../constants/websocketCommands';
import {CLAIMS_STATISTICS_PENDING} from '../../actions/claims/actionClaimsStatistics';

export const claimsStatistics = (state = {
    correlationId: '',
    isLoading: false,
    data: [],
}, action) => {
    switch (action.type) {
        // load data
        case CLAIMS_STATISTICS_PENDING: {
            const {correlationId} = action.payload;
            return Object.assign({}, state, {
                isLoading: true, correlationId
            });
        }

        // command accepted
        case CLAIMS_STATISTICS_SEND: {
            const {correlationId, result: {data}} = action.payload;
            if (correlationId !== state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isLoading: false, correlationId, data
                });
            }
        }

        // error
        case CLAIMS_STATISTICS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId !== state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isLoading: false, correlationId
                });
            }
        }

        default: {
            return state;
        }
    }
};
