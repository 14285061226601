import {
    ADMIN_COUNTRIES_CHANGED,
    ADMIN_RIGHTS_CLEAR,
    ADMIN_RIGHTS_FETCH_PENDING,
    ADMIN_RIGHTS_SAVE_PENDING,
    ADMIN_RIGHTS_FETCH_SUCCESS,
    DEALER_FORM_TYPES_CHANGED,
    DEALER_RIGHTS_CLEAR,
    DEALER_RIGHTS_FETCH_PENDING,
    DEALER_RIGHTS_SAVE_PENDING,
    DEALER_RIGHTS_FETCH_SUCCESS,
    EDITOR_COUNTRIES_CHANGED,
    EDITOR_RIGHTS_CLEAR,
    EDITOR_RIGHTS_FETCH_PENDING,
    EDITOR_RIGHTS_SAVE_PENDING,
    EDITOR_RIGHTS_FETCH_SUCCESS,
    INITIAL_ADMIN_COUNTRY_OPTIONS,
    INITIAL_DEALER_FORM_TYPE_OPTIONS,
    INITIAL_EDITOR_COUNTRY_OPTIONS,
    MANAGED_USER, RIGHTS_ERROR,
    SELECTED_ADMIN_COUNTRY_OPTIONS,
    SELECTED_DEALER_FORM_TYPE_OPTIONS,
    SELECTED_EDITOR_COUNTRY_OPTIONS,
} from '../../actions/admin/actionAdminRightsManagement';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';
import {
    FIXED_SOLUTION_GROUPS_EDITOR_OPTIONS,
    INITIAL_SOLUTION_GROUPS_EDITOR_OPTIONS,
    MANAGED_SOLUTION_GROUPS_EDITOR,
    SELECTED_SOLUTION_GROUPS_EDITOR_OPTIONS,
    SOLUTION_GROUPS_EDITOR_OPTIONS_CHANGED,
    SOLUTION_GROUPS_EDITOR_RIGHTS_CLEAR,
    SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_PENDING,
    SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_SUCCESS,
    SOLUTION_GROUPS_EDITOR_RIGHTS_SAVE_PENDING,
} from '../../actions/admin/actionSolutionGroupUserRightsManagement';

export const adminRightsManagement = (state = {
    isLoading: false,
    managedUser: {},
    adminData: {},
    initialAdminOptions: [],
    selectedAdminOptions: null,
    adminCountriesChanged: false,
    dealerData: {},
    initialDealerOptions: [],
    selectedDealerOptions: null,
    dealerFormTypesChanged: false,
    editorData: {},
    initialEditorOptions: [],
    selectedEditorOptions: null,
    editorCountriesChanged: false,

    solutionGroupEditorData: [],
    initialSolutionGroupUserOptions: [],
    solutionGroupPUOptions: [],
    isLoadingPUOptions: false,
    fixedSolutionGroupEditorOptions: null,
    selectedSolutionGroupEditorOptions: null,
    solutionGroupEditorRightsChanged: false,
}, action) => {
    switch (action.type) {
        case MANAGED_USER: {
            return Object.assign({}, state, {
                adminCountriesChanged: false,
                dealerFormTypesChanged: false,
                editorCountriesChanged: false,
                managedUser: action.payload
            });
        }
        case ADMIN_RIGHTS_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case ADMIN_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                adminData: action.payload.finalResult,
                correlationId: action.payload.correlationId
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_ADMIN_ROLE_SEND_ERROR:
        case techDocWebsocketCommands.TECH_DOC_ADMIN_ROLE_SEND_ERROR:
        case claimsWebsocketCommands.CLAIMS_ADMIN_ROLE_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId});
        }
        case INITIAL_ADMIN_COUNTRY_OPTIONS: {
            return Object.assign({}, state, {initialAdminOptions: action.payload,})
        }
        case SELECTED_ADMIN_COUNTRY_OPTIONS: {
            return Object.assign({}, state, {selectedAdminOptions: action.payload})
        }
        case ADMIN_COUNTRIES_CHANGED: {
            return Object.assign({}, state, {adminCountriesChanged: action.payload})
        }
        case ADMIN_RIGHTS_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case ADMIN_RIGHTS_CLEAR: {
            return Object.assign({}, state, {adminData: {}});
        }
        case DEALER_RIGHTS_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case DEALER_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                dealerData: action.payload.finalResult,
                dealerCorrelationId: action.payload.correlationId
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DEALER_ROLE_SEND_ERROR:
        case techDocWebsocketCommands.TECH_DOC_DEALER_ROLE_SEND_ERROR:
        case claimsWebsocketCommands.CLAIMS_DEALER_ROLE_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.dealerCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, dealerCorrelationId: correlationId});
        }
        case DEALER_RIGHTS_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case DEALER_RIGHTS_CLEAR: {
            return Object.assign({}, state, {dealerData: {}});
        }
        case INITIAL_DEALER_FORM_TYPE_OPTIONS: {
            return Object.assign({}, state, {initialDealerOptions: action.payload})
        }
        case SELECTED_DEALER_FORM_TYPE_OPTIONS: {
            return Object.assign({}, state, {selectedDealerOptions: action.payload})
        }
        case DEALER_FORM_TYPES_CHANGED: {
            return Object.assign({}, state, {dealerFormTypesChanged: action.payload})
        }
        case INITIAL_EDITOR_COUNTRY_OPTIONS: {
            return Object.assign({}, state, {initialEditorOptions: action.payload})
        }
        case SELECTED_EDITOR_COUNTRY_OPTIONS: {
            return Object.assign({}, state, {selectedEditorOptions: action.payload})
        }
        case EDITOR_RIGHTS_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case EDITOR_RIGHTS_FETCH_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                editorData: action.payload.finalResult,
                editorCorrelationId: action.payload.correlationId
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_EDITOR_ROLE_SEND_ERROR:
        case techDocWebsocketCommands.TECH_DOC_EDITOR_ROLE_SEND_ERROR:
        case claimsWebsocketCommands.CLAIMS_EDITOR_ROLE_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.editorCorrelationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, editorCorrelationId: correlationId});
        }
        case EDITOR_RIGHTS_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case EDITOR_RIGHTS_CLEAR: {
            return Object.assign({}, state, {editorData: {}});
        }
        case EDITOR_COUNTRIES_CHANGED: {
            return Object.assign({}, state, {editorCountriesChanged: action.payload})
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_ROLES_ERROR:
        case techDocWebsocketCommands.TECH_DOC_ROLES_ERROR:
        case claimsWebsocketCommands.CLAIMS_ROLES_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId});
        }
        case RIGHTS_ERROR: {
            const {correlationId} = action.payload;
            return Object.assign({}, state, {isLoading: false, correlationId});
        }




        case MANAGED_SOLUTION_GROUPS_EDITOR: {
            return Object.assign({}, state, {
                solutionGroupEditorRightsChanged: false,
                managedUser: action.payload
            });
        }
        case SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }

        case SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_SUCCESS: {
            console.log('reducerAction', {action});
            return Object.assign({}, state, {
                isLoading: false,
                solutionGroupEditorData: action.payload.finalResult,
                correlationId: action.payload.correlationId
            });
        }
        case INITIAL_SOLUTION_GROUPS_EDITOR_OPTIONS: {
            return Object.assign({}, state, {initialSolutionGroupUserOptions: action.payload,})
        }
        case SELECTED_SOLUTION_GROUPS_EDITOR_OPTIONS: {
            return Object.assign({}, state, {selectedSolutionGroupEditorOptions: action.payload})
        }
        case FIXED_SOLUTION_GROUPS_EDITOR_OPTIONS: {
            return Object.assign({}, state, {fixedSolutionGroupEditorOptions: action.payload})
        }
        case SOLUTION_GROUPS_EDITOR_OPTIONS_CHANGED: {
            return Object.assign({}, state, {solutionGroupEditorRightsChanged: action.payload})
        }
        case SOLUTION_GROUPS_EDITOR_RIGHTS_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case SOLUTION_GROUPS_EDITOR_RIGHTS_CLEAR: {
            return Object.assign({}, state, {
                solutionGroupEditorData: [],
                initialSolutionGroupUserOptions: [],
                solutionGroupPUOptions: [],
                fixedSolutionGroupEditorOptions: null,
                selectedSolutionGroupEditorOptions: null,
                solutionGroupEditorRightsChanged: false,
            });
        }

        default: {
            return state;
        }
    }
};
