import React from 'react';
import {FormattedMessage} from 'react-intl';
import {
    ORDERS_NEW_EV_BATTERY_FORM_PATH,
    ORDERS_EXPORT_PATH,
    ORDERS_HISTORY_PATH,
    ORDERS_LIST_PATH,
    ORDERS_NEW_ALLOCATION_REQUEST_FORM_PATH,
    ORDERS_NEW_CANCELLATION_FORM_PATH,
    ORDERS_NEW_DELIVERY_DATE_REMINDER_FORM_PATH,
    ORDERS_NEW_PARTS_NON_AVAILABLE_FORM_PATH,
    ORDERS_NEW_VOR_PVI_FORM_PATH,
    ORDERS_RECURRENT_PROBLEM_PATH,
    ORDERS_SEARCH_ENGINE_PATH,
    ORDERS_NEW_INTERNAL_TICKET,
    ORDERS_INTERNAL_TICKET_LIST_PATH,
    ORDERS_INTERNAL_TICKET_HISTORY_PATH,
    ORDERS_INTERNAL_TICKET_SEARCH_PATH,
    ORDERS_MANAGEMENT_GROUP,
} from '../../../../routes/paths';
import {moduleRoles} from '../../../../utils/roles';
import {
    ALLOCATION_REQUEST_FORM,
    DELIVERY_DATE_REMINDER_FORM, EV_BATTERY_FORM,
    ORDER_CANCELLATION_FORM,
    ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
    VOR_PVI_ORDER_FORM
} from '../../../../constants/formConstants';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import menuMessages from '../../../../intl/layout/menuMessages';
import {domains} from '../../../../constants/Utils';


export const getSparePartsEditorMenu = roles => {
    const sparePartsNewEditorOrderSubmenu = buildSparePartsNewEditorOrderSubmenu(roles);

    const result = [
        {
            label: <FormattedMessage {...menuMessages.SPARE_PARTS_LIST}/>,
            to: ORDERS_LIST_PATH,
            id: ORDERS_LIST_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.SPARE_PARTS_HISTORY}/>,
            to: ORDERS_HISTORY_PATH,
            id: ORDERS_HISTORY_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.SPARE_PARTS_SEARCH_ENGINE}/>,
            to: ORDERS_SEARCH_ENGINE_PATH,
            id: ORDERS_SEARCH_ENGINE_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.SPARE_PARTS_RECURRENT_PROBLEM}/>,
            to: ORDERS_RECURRENT_PROBLEM_PATH,
            id: ORDERS_RECURRENT_PROBLEM_PATH
        },
    ];

    // if (sparePartsNewEditorOrderSubmenu.length > 0) {
    //     result.push(
    //         {
    //             label: <FormattedMessage {...formTypeMessages.SPARE_PARTS_INTERNAL_TICKET}/>,
    //             content: sparePartsNewEditorOrderSubmenu
    //         }
    //     );
    // }


    if (moduleRoles.isAdminOrder(roles) || moduleRoles.isSGAdminOrder(roles)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.SPARE_PARTS_EXPORT}/>,
                to: ORDERS_EXPORT_PATH,
                id: ORDERS_EXPORT_PATH
            }
        );
    }
    if (moduleRoles.isSGAdminOrder(roles, 'MANAGEMENT_GROUP')) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.SPARE_PARTS_MANAGEMENT_GROUP}/>,
                to: ORDERS_MANAGEMENT_GROUP,
                id: ORDERS_MANAGEMENT_GROUP
            }
        );
    }

    return result;
};

export const getSparePartsDealerMenu = roles => {
    const sparePartsNewOrderSubmenu = buildSparePartsNewOrderSubmenu(roles);

    const result = [
        {
            label: <FormattedMessage {...menuMessages.SPARE_PARTS_LIST}/>,
            to: ORDERS_LIST_PATH,
            id: ORDERS_LIST_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.SPARE_PARTS_HISTORY}/>,
            to: ORDERS_HISTORY_PATH,
            id: ORDERS_HISTORY_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.SPARE_PARTS_SEARCH_ENGINE}/>,
            to: ORDERS_SEARCH_ENGINE_PATH,
            id: ORDERS_SEARCH_ENGINE_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.SPARE_PARTS_RECURRENT_PROBLEM}/>,
            to: ORDERS_RECURRENT_PROBLEM_PATH,
            id: ORDERS_RECURRENT_PROBLEM_PATH
        }
    ];

    if (sparePartsNewOrderSubmenu.length > 0) {
        result.unshift(
            {
                label: <FormattedMessage {...menuMessages.SPARE_PARTS_NEW}/>,
                content: sparePartsNewOrderSubmenu
            }
        );
    }

    return result;
};

const buildSparePartsNewOrderSubmenu = roles => {
    const result = [];

    const formTypes = moduleRoles.getDealerForms(domains.SPARE_PARTS, roles).map(formType => formType.group);

    if (formTypes.includes(ALLOCATION_REQUEST_FORM)) {
        result.push({
                label: <FormattedMessage {...formTypeMessages[ALLOCATION_REQUEST_FORM]}/>,
                to: ORDERS_NEW_ALLOCATION_REQUEST_FORM_PATH,
                id: ORDERS_NEW_ALLOCATION_REQUEST_FORM_PATH
            }
        );
    }
    if (formTypes.includes(DELIVERY_DATE_REMINDER_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[DELIVERY_DATE_REMINDER_FORM]}/>,
                to: ORDERS_NEW_DELIVERY_DATE_REMINDER_FORM_PATH,
                id: ORDERS_NEW_DELIVERY_DATE_REMINDER_FORM_PATH
            }
        );
    }
    if (formTypes.includes(ORDER_CANCELLATION_FORM)) {
        result.push({
                label: <FormattedMessage {...formTypeMessages[ORDER_CANCELLATION_FORM]}/>,
                to: ORDERS_NEW_CANCELLATION_FORM_PATH,
                id: ORDERS_NEW_CANCELLATION_FORM_PATH
            }
        );
    }
    if (formTypes.includes(ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM]}/>,
                to: ORDERS_NEW_PARTS_NON_AVAILABLE_FORM_PATH,
                id: ORDERS_NEW_PARTS_NON_AVAILABLE_FORM_PATH
            }
        );
    }
    if (formTypes.includes(VOR_PVI_ORDER_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[VOR_PVI_ORDER_FORM]}/>,
                to: ORDERS_NEW_VOR_PVI_FORM_PATH,
                id: ORDERS_NEW_VOR_PVI_FORM_PATH
            }
        );
    }
    if (formTypes.includes(EV_BATTERY_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[EV_BATTERY_FORM]}/>,
                to: ORDERS_NEW_EV_BATTERY_FORM_PATH,
                id: ORDERS_NEW_EV_BATTERY_FORM_PATH
            }
        );
    }

    return result;
};


const buildSparePartsNewEditorOrderSubmenu = roles => {
    const result = [];

    if(moduleRoles.isSGEditor(roles, domains.SPARE_PARTS)){
        result.push(
            {
                label: <FormattedMessage {...menuMessages.SPARE_PARTS_NEW}/>,
                to: ORDERS_NEW_INTERNAL_TICKET,
                id: ORDERS_NEW_INTERNAL_TICKET
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.SPARE_PARTS_LIST}/>,
                to: ORDERS_INTERNAL_TICKET_LIST_PATH,
                id: ORDERS_INTERNAL_TICKET_LIST_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.SPARE_PARTS_HISTORY}/>,
                to: ORDERS_INTERNAL_TICKET_HISTORY_PATH,
                id: ORDERS_INTERNAL_TICKET_HISTORY_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.SPARE_PARTS_INTERNAL_SEARCH}/>,
                to: ORDERS_INTERNAL_TICKET_SEARCH_PATH,
                id: ORDERS_INTERNAL_TICKET_SEARCH_PATH
            }
        );
    }

    return result;
};
