import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {change, formValueSelector} from 'redux-form';
import formMessages from '../../../../../../../intl/admin/answeringSchemaMessages';
import {
    FORM_ANSWERING_SCHEMA_ANSWERING_SECTION,
    FORM_ANSWERING_SCHEMA_INFO_SECTION,
    FORM_FIELD_AS_ANSWERING_GROUPS,
    FORM_FIELD_AS_SG_NAME,
    FORM_LABEL_AS_ANSWERING_GROUPS,
} from '../../../../../../../constants/formConstants';
import {FormattedMessage, injectIntl} from 'react-intl';
import Select from 'react-select';
import {Label, RequiredSpan} from '../../../../../../common/StyledComponents';
import get from 'get-value';

const StyledDiv = styled.div`
  border-radius: 5px;
`;



const AnsweringSection = (props) => {
    const {
        readOnly, reqFields,  intl: {formatMessage}, answeringSchemaOptions, selectedSG, selectedAnsweringSG
    } = props;

    const formattedAnsweringSG = (answeringSchemaOptions || {}).filter(sg => sg.value !== get(selectedSG, "value"));

    const handleAnsweringSolutionGroupsSelectionChange = newOptions => {
        const { change } = props;

        const newOptionsToSave = newOptions.map(element => {
            return {
                value: element.value,
                label: element.label
            };
        });

        const uniqueOptionsToSave = Array.from(new Set(newOptionsToSave.map(x => x.value)))
            .map(value => {
                return {
                    value: value,
                    label: newOptionsToSave.find(y => y.value === value).label
                }
            })

        change(`${FORM_ANSWERING_SCHEMA_ANSWERING_SECTION}.${FORM_FIELD_AS_ANSWERING_GROUPS}`, uniqueOptionsToSave);
    };

    return (
        <>
            <hr />
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-12 pb-2">
                    </div>
                </div>
                <div className="row mb-sm-2">
                    <Label>
                        <FormattedMessage {...formMessages[FORM_LABEL_AS_ANSWERING_GROUPS]} />
                        {!readOnly ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
                    </Label>
                </div>
                <div className="row mb-sm-3">
                    <div className="col">
                        <Select
                            isDisabled={readOnly}
                            field={FORM_FIELD_AS_ANSWERING_GROUPS}
                            options={formattedAnsweringSG}
                            onChange={handleAnsweringSolutionGroupsSelectionChange}
                            value={selectedAnsweringSG}
                            isMulti
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )

};

const answerGroupSorted = state => {
    if (state.adminAnsweringSchema && state.adminAnsweringSchema.AnsweringSchemaOptions) {
        return [...state.adminAnsweringSchema.AnsweringSchemaOptions]
            .sort((a, b) => a.label.localeCompare(b.label));
    }
    return [];
}

const mapStateToProps = (state, props) => ({
    answeringSchemaOptions: answerGroupSorted(state),
    selectedSG: formValueSelector(props.form)(state, `${FORM_ANSWERING_SCHEMA_INFO_SECTION}.${FORM_FIELD_AS_SG_NAME}`),
    selectedAnsweringSG: formValueSelector(props.form)(state, `${FORM_ANSWERING_SCHEMA_ANSWERING_SECTION}.${FORM_FIELD_AS_ANSWERING_GROUPS}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AnsweringSection));
