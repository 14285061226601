import {
    TECH_DOC_INTERNAL_CREATE_TICKET, TECH_DOC_INTERNAL_GET_SG_INFO,
    TECH_DOC_INTERNAL_GET_TICKET,
    TECH_DOC_INTERNAL_UPDATE_TICKET
} from '../../constants/websocketCommands';

export const TECH_DOC_INTERNAL_TICKET_RESET = 'TECH_DOC_INTERNAL_TICKET_RESET';
export const TECH_DOC_INTERNAL_TICKET_CREATE = 'TECH_DOC_INTERNAL_TICKET_CREATE';
export const TECH_DOC_INTERNAL_TICKET_UPDATE = 'TECH_DOC_INTERNAL_TICKET_UPDATE';


export const updateTechDocInternalTicket = (data) => dispatch => {
    return dispatch({
        type: TECH_DOC_INTERNAL_TICKET_UPDATE,
        techDocWebsocket: true,
        command: TECH_DOC_INTERNAL_UPDATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const saveTechDocInternalTicket = (data) => dispatch => {
    return dispatch({
        type: TECH_DOC_INTERNAL_TICKET_CREATE,
        techDocWebsocket: true,
        command: TECH_DOC_INTERNAL_CREATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const fetchTechDocInternalTicket = (uuid) => dispatch => {
    return dispatch({
        type: TECH_DOC_INTERNAL_GET_TICKET,
        techDocWebsocket: true,
        command: TECH_DOC_INTERNAL_GET_TICKET,
        payload: {
            ticket: {
                uuid: uuid,
            }
        }
    });
};

export const fetchTechDocInternalTicketSgInfo = (sgId) => dispatch => {
    return dispatch({
        type: TECH_DOC_INTERNAL_GET_SG_INFO,
        techDocWebsocket: true,
        command: TECH_DOC_INTERNAL_GET_SG_INFO,
        payload: {
            solutionGroupId: sgId
        }
    });
};

export const resetTechDocInternalTicket = () => dispatch => {
    return dispatch({
        type: TECH_DOC_INTERNAL_TICKET_RESET,
    });
};
