import React from 'react';
import {change, clearFields, Field, formValueSelector} from 'redux-form'
import {FormattedMessage} from "react-intl";
import {connect} from 'react-redux';
import {
    FORM_CHECKBOX_BVM_CONNECTION,
    FORM_CHECKBOX_NEW_OVAL_PLATE,
    FORM_CHECKBOX_OLD_OVAL_PLATE,
    FORM_FIELD_NEW_PLATE1,
    FORM_FIELD_NEW_PLATE10,
    FORM_FIELD_NEW_PLATE11,
    FORM_FIELD_NEW_PLATE12,
    FORM_FIELD_NEW_PLATE13,
    FORM_FIELD_NEW_PLATE14,
    FORM_FIELD_NEW_PLATE15,
    FORM_FIELD_NEW_PLATE16,
    FORM_FIELD_NEW_PLATE17,
    FORM_FIELD_NEW_PLATE2,
    FORM_FIELD_NEW_PLATE3,
    FORM_FIELD_NEW_PLATE4,
    FORM_FIELD_NEW_PLATE5,
    FORM_FIELD_NEW_PLATE6,
    FORM_FIELD_NEW_PLATE7,
    FORM_FIELD_NEW_PLATE8,
    FORM_FIELD_NEW_PLATE9,
    FORM_FIELD_OLD_PLATE1,
    FORM_FIELD_OLD_PLATE2,
    FORM_FIELD_OLD_PLATE3,
    FORM_FIELD_OLD_PLATE4,
    FORM_FIELD_OLD_PLATE5,
    FORM_FIELD_OLD_PLATE6,
    FORM_FIELD_OLD_PLATE7,
    FORM_FIELD_OLD_PLATE8,
    FORM_FIELD_VIN,
    FORM_VEHICLE_DETAIL_SECTION
} from "../../../../../../constants/formConstants";
import {InputFieldWithValidation} from "../../../../../common/InputFieldWithValidation";
import {required} from "../../../../../common/validationCommons";
import formMessages from "../../../../../../intl/technicalDocumentations/formMessages";


const OldOvalPlate = (props) => {
    const { userRights, readOnly, oldOvalSelected, bvmConnection, change, clearFields, newOvalSelected, checkboxDisabled } = props;

    const clearPlateFields = () => {
        if (newOvalSelected) {
            change(`${FORM_VEHICLE_DETAIL_SECTION}.ovalPlate.${FORM_CHECKBOX_NEW_OVAL_PLATE}`, false);
            clearFields(FORM_CHECKBOX_NEW_OVAL_PLATE, FORM_FIELD_NEW_PLATE1, FORM_FIELD_NEW_PLATE2, FORM_FIELD_NEW_PLATE3, FORM_FIELD_NEW_PLATE4, FORM_FIELD_NEW_PLATE5,
                FORM_FIELD_NEW_PLATE6, FORM_FIELD_NEW_PLATE7, FORM_FIELD_NEW_PLATE8, FORM_FIELD_NEW_PLATE9, FORM_FIELD_NEW_PLATE10, FORM_FIELD_NEW_PLATE11,
                FORM_FIELD_NEW_PLATE12, FORM_FIELD_NEW_PLATE13, FORM_FIELD_NEW_PLATE14, FORM_FIELD_NEW_PLATE15, FORM_FIELD_NEW_PLATE16, FORM_FIELD_NEW_PLATE17);
        }
        clearFields(FORM_FIELD_OLD_PLATE1,
            FORM_FIELD_OLD_PLATE2, FORM_FIELD_OLD_PLATE3, FORM_FIELD_OLD_PLATE4,
            FORM_FIELD_OLD_PLATE5, FORM_FIELD_OLD_PLATE6, FORM_FIELD_OLD_PLATE7,
            FORM_FIELD_OLD_PLATE8);
    };

    return (
        <>
            <div className="col-xl-6 pb-2">
                <div className="form-row justify-content-center">
                    <label className="pl-2 pt-1">
                        <Field
                            name={FORM_CHECKBOX_OLD_OVAL_PLATE} component="input" type="checkbox"
                            disabled={!userRights.canEditBvm || readOnly || bvmConnection || checkboxDisabled}
                            onChange={clearPlateFields}
                            validate={(!oldOvalSelected && !newOvalSelected) ? [required] : null}
                        />{' '}
                        <FormattedMessage {...formMessages.OLD_OVAL_PLATE}/>
                    </label>
                </div>
                <div className="form-row">
                    <div className="offset-2 offset-lg-3 col-lg-6 col-8 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE1} maxLength={4} isSmall
                                                  isRequired={!!oldOvalSelected}
                                                  isDisabled={!userRights.canEditVehDetails || !oldOvalSelected || readOnly || bvmConnection}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="offset-lg-1 col-lg-3 col-4 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE2} maxLength={2} isSmall
                                                  isRequired={!!oldOvalSelected}
                                                  isDisabled={!userRights.canEditVehDetails || !oldOvalSelected || readOnly || bvmConnection}/>
                    </div>
                    <div className="col-lg-4 col-4 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE3} maxLength={8} isSmall
                                                  isRequired={!!oldOvalSelected}
                                                  isDisabled={!userRights.canEditVehDetails || !oldOvalSelected || readOnly || bvmConnection}/>
                    </div>
                    <div className="col-lg-3 col-4 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE4} maxLength={8} isSmall
                                                  isRequired={!!oldOvalSelected}
                                                  isDisabled={!userRights.canEditVehDetails || !oldOvalSelected || readOnly || bvmConnection}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="offset-lg-1 col-lg-3 col-4 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE5} maxLength={8} isSmall
                                                  isRequired={!!oldOvalSelected}
                                                  isDisabled={!userRights.canEditVehDetails || !oldOvalSelected || readOnly || bvmConnection}/>
                    </div>
                    <div className="col-lg-4 col-4 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE6} maxLength={8} isSmall
                                                  isRequired={!!oldOvalSelected}
                                                  isDisabled={!userRights.canEditVehDetails || !oldOvalSelected || readOnly || bvmConnection}/>
                    </div>
                    <div className="col-lg-3 col-4 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE7} maxLength={8} isSmall
                                                  isRequired={!!oldOvalSelected}
                                                  isDisabled={!userRights.canEditVehDetails || !oldOvalSelected || readOnly || bvmConnection}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="offset-2 offset-lg-3 col-lg-6 col-8 pb-1">
                        <InputFieldWithValidation field={FORM_FIELD_OLD_PLATE8} maxLength={7} isSmall
                                                  isRequired={!!oldOvalSelected}
                                                  isDisabled={!userRights.canEditVehDetails || !oldOvalSelected || readOnly || bvmConnection}/>
                    </div>
                </div>
            </div>
        </>
    )
}

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, initialProps) => ({
    vin: selector(initialProps.form, state, `${FORM_VEHICLE_DETAIL_SECTION}.${FORM_FIELD_VIN}`),
    oldOvalSelected: selector(initialProps.form, state, `${FORM_VEHICLE_DETAIL_SECTION}.ovalPlate.${FORM_CHECKBOX_OLD_OVAL_PLATE}`),
    newOvalSelected: selector(initialProps.form, state, `${FORM_VEHICLE_DETAIL_SECTION}.ovalPlate.${FORM_CHECKBOX_NEW_OVAL_PLATE}`),
    bvmConnection: selector(initialProps.form, state, `${FORM_VEHICLE_DETAIL_SECTION}.${FORM_CHECKBOX_BVM_CONNECTION}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
    clearFields: (...fields) => dispatch(clearFields(props.form, false, false, ...fields.map(e => FORM_VEHICLE_DETAIL_SECTION + ".ovalPlate." + e))),
});

export default connect(mapStateToProps, mapDispatchToProps)(OldOvalPlate);
