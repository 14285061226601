import React, {Component} from 'react';
import {
    FORM_BUTTON_LOAD,
    FORM_FIELD_DESCRIPTION,
    FORM_FIELD_LINE,
    FORM_FIELD_ORDER,
    FORM_FIELD_PART_NO,
    FORM_FIELD_PROD,
    FORM_FIELD_QUANTITY,
    FORM_FIELD_REMARKS,
    FORM_FIELD_SUBJECT_TO_VOR,
    FORM_ORDERS_SECTION
} from '../../../../../../constants/formConstants';
import {
    DESC_FIELD_MAX_LENGTH,
    LINE_FIELD_MAX_LENGTH,
    ORDER_FIELD_MAX_LENGTH, ORDER_FIELD_MAX_LENGTH_AR, ORDER_FIELD_MAX_LENGTH_RU,
    PART_NO_FIELD_MAX_LENGTH,
    QUANTITY_SHORT_FIELD_MAX_LENGTH,
} from '../../../../../../constants/validationConstants';
import {change, formValueSelector, getFormInitialValues, getFormValues} from 'redux-form';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import {
    positiveNumber,
    positiveNumberWithZero,
    validAlphanumericInput,
    validPartNoLength
} from '../../../../../common/validationCommons';
import get from 'get-value';
import {connect} from 'react-redux';
import InputFieldGroupWithValidation from '../../../../../common/InputFieldGroupWithValidation';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import commonMessages from '../../../../../../intl/common/commonMessages';
import {prodValues, PROVIDER, RECEIVER} from '../../../../../../constants/Utils';

class OrdersTroubleshootLowerTableBody extends Component {
    constructor(props) {
        super(props)
        this.customRefs = {
            [FORM_FIELD_PROD]: React.createRef(),
            [FORM_FIELD_ORDER]: React.createRef(),
            [FORM_FIELD_LINE]: React.createRef(),
            [FORM_FIELD_QUANTITY]: React.createRef(),
            [FORM_FIELD_PART_NO]: React.createRef(),
            [FORM_BUTTON_LOAD]: React.createRef(),
            [FORM_FIELD_SUBJECT_TO_VOR]: React.createRef(),
            [FORM_FIELD_QUANTITY]: React.createRef(),
        };
    }

    changeFocus = (value, maxLength, fieldName) => {
        if (get(this, `customRefs.${fieldName}.current`) && maxLength && value.length === maxLength) {
            this.customRefs[fieldName].current.focus();
        }
    };

    render() {
        const {userRights, readOnly, order, initialValues, reqFields, handleOrderPartLoad, user, formValues} = this.props;
        const userStatus = formValues.ordersSection.userStatus

        return (
            <>
                <tr className="border-bottom">
                    <td id="order1Print" style={{maxWidth: '120px', minWidth: '90px'}}>
                        <InputSelectFieldWithValidation field={FORM_FIELD_PROD}
                                                        options={get(prodValues, `${get(initialValues, 'ticketSection.group', {default: 'X'})}`, {default: []})}
                                                        isDisabled={!userRights.canEditOrder || readOnly}
                                                        isRequired={userRights.canEditOrder && !readOnly && userStatus === RECEIVER}
                        />
                    </td>
                    <td id="order2Print" style={{maxWidth: '130px', minWidth: '110px'}}>
                        <InputFieldWithValidation field={FORM_FIELD_ORDER}
                                                  maxLength={user.group === "AR" ? ORDER_FIELD_MAX_LENGTH_AR : (user.group === "RU" ? ORDER_FIELD_MAX_LENGTH_RU : ORDER_FIELD_MAX_LENGTH)}
                                                  validations={[validAlphanumericInput]}
                                                  isDisabled={!userRights.canEditOrder || readOnly}
                                                  isRequired={userRights.canEditOrder && !readOnly && userStatus === RECEIVER}
                                                  isSmall
                                                  setRef={this.customRefs[FORM_FIELD_ORDER]}
                                                  handleKeyUp={(value) => this.changeFocus(value, (user.group === "AR" ? ORDER_FIELD_MAX_LENGTH_AR : (user.group === "RU" ? ORDER_FIELD_MAX_LENGTH_RU : ORDER_FIELD_MAX_LENGTH)), FORM_FIELD_LINE)}
                        />
                    </td>
                    <td id="order2Print" style={{maxWidth: '130px', minWidth: '100px'}}>
                        <InputFieldWithValidation field={FORM_FIELD_LINE}
                                                  maxLength={LINE_FIELD_MAX_LENGTH}
                                                  validations={[positiveNumberWithZero]}
                                                  isDisabled={!userRights.canEditOrder || readOnly}
                                                  isRequired={userRights.canEditOrder && !readOnly && userStatus === RECEIVER}
                                                  isSmall
                                                  setRef={this.customRefs[FORM_FIELD_LINE]}
                                                  handleKeyUp={(value) => this.changeFocus(value, LINE_FIELD_MAX_LENGTH, FORM_FIELD_PART_NO)}
                        />
                    </td>
                    <td id="order2Print" style={{maxWidth: '200px', minWidth: '175px'}}>
                        <InputFieldGroupWithValidation field={FORM_FIELD_PART_NO}
                                                       isRequired={userRights.canEditOrder && !readOnly && (userStatus === RECEIVER || userStatus === PROVIDER)}
                                                       isButtonDisabled={!order[FORM_FIELD_PART_NO] || order[FORM_FIELD_PART_NO].length < 10 || order[FORM_FIELD_DESCRIPTION] || order[FORM_FIELD_SUBJECT_TO_VOR]}
                                                       isDisabled={!userRights.canEditOrder || readOnly}
                                                       loadOrderData={() => {
                                                           handleOrderPartLoad();
                                                           this.customRefs[FORM_FIELD_QUANTITY].current.focus()
                                                       }}
                                                       maxLength={PART_NO_FIELD_MAX_LENGTH}
                                                       validations={[validPartNoLength]}
                                                       setRef={this.customRefs[FORM_FIELD_PART_NO]}
                                                       setRefButton={this.customRefs[FORM_BUTTON_LOAD]}
                                                       handleKeyUp={(value) => this.changeFocus(value, PART_NO_FIELD_MAX_LENGTH, FORM_BUTTON_LOAD)}
                                                       isUpperCase
                        />
                    </td>
                    <td id="order1Print" style={{maxWidth: '80px', minWidth: '50px'}}>
                        <InputSelectFieldWithValidation
                            field={FORM_FIELD_SUBJECT_TO_VOR}
                            options={['YES', 'NO']}
                            intlMessages={commonMessages}
                            isDisabled={(!userRights.canEditOrder && !userRights.canEditSubjectToVor) || readOnly}
                            isRequired={userRights.canEditOrder && reqFields.includes(FORM_FIELD_SUBJECT_TO_VOR) && !readOnly}
                        />
                    </td>
                    <td id="order3Print" style={{minWidth: '100px'}}>
                        <InputFieldWithValidation field={FORM_FIELD_DESCRIPTION}
                                                  maxLength={DESC_FIELD_MAX_LENGTH}
                                                  isDisabled={!userRights.canEditOrder || readOnly}
                                                  isRequired={userRights.canEditOrder && !readOnly && (userStatus === RECEIVER || userStatus === PROVIDER)}
                                                  isSmall
                                                  handleKeyUp={(value) => this.changeFocus(value, DESC_FIELD_MAX_LENGTH, FORM_FIELD_QUANTITY)}/>
                    </td>
                    <td id="order1Print" style={{maxWidth: '100px', minWidth: '60px'}}>
                        <InputFieldWithValidation field={FORM_FIELD_QUANTITY}
                                                  maxLength={QUANTITY_SHORT_FIELD_MAX_LENGTH}
                                                  validations={[positiveNumber]}
                                                  isDisabled={!userRights.canEditOrder || readOnly}
                                                  isRequired={userRights.canEditOrder && !readOnly && (userStatus === RECEIVER || userStatus === PROVIDER)}
                                                  isSmall
                                                  setRef={this.customRefs[FORM_FIELD_QUANTITY]} />
                    </td>
                </tr>
            </>
        )
    };
};

const mapStateToProps = (state, props) => ({
    formValues: getFormValues(props.form)(state),
    initialValues: getFormInitialValues(props.form)(state),
    order: formValueSelector(props.form)(state, FORM_ORDERS_SECTION),
    user: state.profile.userDetail,
})

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTroubleshootLowerTableBody);
