import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'rc-slider/assets/index.css';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import get from 'get-value';
import {FormattedMessage} from 'react-intl';
import daysMessages from '../../../intl/common/daysMessages';
import {PRIMARY_YELLOW} from '../../../theme/colors';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = (props) => {
    const {value, dragging, index, ...restProps} = props;
    return (
        <Tooltip
            prefixCls="workingHours-slider-tooltip"
            overlay={value}
            visible={dragging}
            placement="top"
            key={index}
        >
            <Handle value={value} {...restProps} />
        </Tooltip>
    );
};
export const formatMinutesToTime = (minutes) => {
    return minutes === 1440 ? '24:00' : `${Math.floor(minutes / 60)}`.padStart(2, '0') + ':' + `${Math.floor(minutes % 60)}`.padStart(2, '0');
};

const RangeWrapper = styled.span`
  margin: 50px;
`;
const wrapperStyle = {margin: 20};

const RangeComponent = ({day, onChange, values}) => {
    const marks = {360: '6:00', 720: '12:00', 1080: '18:00'};
    return <div>
        <FormattedMessage {...daysMessages[day]} />
        <RangeWrapper>
            <Range id={day}
                   min={0}
                   max={1440}
                   step={1}
                   value={values}
                   handle={handle}
                   marks={marks}
                   tipFormatter={value => `${formatMinutesToTime(value)}`}
                   onChange={value => onChange(day, value)}
                   pushable={0}
                   trackStyle={[{backgroundColor: PRIMARY_YELLOW}]}
                   handleStyle={{borderColor: PRIMARY_YELLOW}}
                   activeDotStyle={{borderColor: 'gray'}}
            />
        </RangeWrapper>
    </div>
};

export const WorkingHoursPerWeek = ({onChange, values}) => {
    const DAYS_IN_WEEK = [1, 2, 3, 4, 5, 6, 7];
    return <div style={wrapperStyle}>
        {DAYS_IN_WEEK.map((day) => {
            const dayValue = values[day];
            return <RangeComponent day={day} key={day} onChange={onChange}
                                   values={[get(dayValue, 'from', {default: 0}), get(dayValue, 'to', {default: 0})]}/>
        })}
    </div>
};

WorkingHoursPerWeek.propTypes = {
    onChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
};
