import React from 'react';
import {FormattedMessage} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getFormInitialValues, reduxForm} from 'redux-form'
import {throttle} from 'throttle-debounce';
import * as PropTypes from 'prop-types';
import get from 'get-value';
import ValidFormattedMessage from '../../../../common/ValidFormattedMessage';
import {PrimaryButton} from '../../../../common/Button';
import {RequiredSpan} from '../../../../common/StyledComponents';
import commonMessages from '../../../../../intl/common/commonMessages';
import buttonMessages from '../../../../../intl/common/buttonMessages';
import {
    BUTTON_LABEL_ADD_REPLY_COMMENTS,
    BUTTON_LABEL_CLOSE,
    BUTTON_LABEL_FORWARD_TO_DEALER,
    BUTTON_LABEL_FORWARD_TO_NPDC,
    BUTTON_LABEL_REOPEN,
    BUTTON_LABEL_TAKE_BACK,
    FORM_BUTTON_CLOSE,
    FORM_BUTTON_FORWARD_TO_DEALER,
    FORM_BUTTON_FORWARD_TO_NPDC,
    FORM_BUTTON_MARK_AS_READ,
    FORM_BUTTON_REOPEN,
    FORM_BUTTON_SAVE,
    FORM_BUTTON_TAKE_BACK,
    CLAIMS_TICKET_STATUS_IN_PROGRESS_DEALER,
    CLAIMS_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    CLAIMS_TICKET_STATUS_READ_BY_SUBSIDIARY,
    CLAIMS_TICKET_STATUS_REOPENED,
    CLAIMS_TICKET_STATUS_CLOSED,
    BUTTON_LABEL_SAVE_AND_SEND, FORM_BUTTON_EDIT, BUTTON_LABEL_SAVE,
} from '../../../../../constants/formConstants';
import {
    editClaimsListButtonVisibility
} from "../../../../../constants/claimsTicketRights";
import {moduleRoles} from "../../../../../utils/roles";
import {domains} from "../../../../../constants/Utils";

const SubmitButtons = (props) => {
    const {change, handleSubmit, submitting, initialValues, readOnly, userRights, dirty, editMode, intlLanguage, roles, dealerNumber, ticket} = props;
    const ticketInNonEditableState = () => {
        const buttonVisibility = editClaimsListButtonVisibility[moduleRoles.isDealerClaims(roles) ? 'DEALER' : 'EDITOR']
        return !buttonVisibility.includes(initialValues.ticketSection.status);
    }

    const renderEditButton = () => {
        return (
            <PrimaryButton onClick={
                handleSubmit(values =>
                    props.onSubmit({
                        submitBy: FORM_BUTTON_EDIT,
                    }))
            } className="btn mr-2 float-right">
                <FormattedMessage {...buttonMessages.EDIT}/>
            </PrimaryButton>
        )
    };

    const renderBackButton = () => {
        return (
            <PrimaryButton onClick={(e) => {
                e.preventDefault();
                props.history.goBack();
            }} className="btn mr-2 float-right">
                <FormattedMessage {...buttonMessages.BACK}/>
            </PrimaryButton>
        )
    };

    const renderButton = (button, message, changeTo) => {
        return (
            <PrimaryButton onClick={throttle(1000, async (e) => {
                e.preventDefault();
                //Unfortunately haven't found any more elegant way to omit validation on MARK_AS_READ button click, this value is checked in REQUIRED validation
                if (button === FORM_BUTTON_MARK_AS_READ) {
                    change('omitValidation', true);
                    await (interval => new Promise(resolve => setTimeout(resolve, interval)))(75);
                }
                handleSubmit(values =>
                    props.onSubmit({
                        newValues: {...values, ticketSection: {...values.ticketSection, status: changeTo}},
                        oldValues: initialValues,
                        submitBy: button,
                    }))()
            })
            } className="btn mr-2 float-right" disabled={submitting || (!dirty && button === FORM_BUTTON_SAVE)}>
                <ValidFormattedMessage message={message} intlMessages={buttonMessages}/>
            </PrimaryButton>
        )
    };

    if (readOnly) {
        const ticketBelongsToDealer = moduleRoles.isDealerClaims(roles) && dealerNumber === initialValues.ticketSection.dealerNumber;
        const editorGroups = moduleRoles.isEditorClaims(roles) ? moduleRoles.getEditorGroups(domains.CLAIMS, roles).map(e => e.group) : false;
        const statusClosed = initialValues.ticketSection.status === CLAIMS_TICKET_STATUS_CLOSED;
        let canSwitchToEdit = !statusClosed;

        if (ticketInNonEditableState()) {
            canSwitchToEdit = false;
        } else if (editorGroups && !(editorGroups || []).includes(ticket['group'])) {
            canSwitchToEdit = false;
        } else if (moduleRoles.isDealerClaims(roles) && !(ticketBelongsToDealer)) {
            canSwitchToEdit = false;
        }

        return (
            <div className="d-flex justify-content-end mt-3">
                <form className="form-group">
                    {renderBackButton()}
                    {canSwitchToEdit && renderEditButton()}
                    {userRights.canReopen && initialValues.ticketSection.status === CLAIMS_TICKET_STATUS_CLOSED &&
                    renderButton(FORM_BUTTON_REOPEN, BUTTON_LABEL_REOPEN, CLAIMS_TICKET_STATUS_REOPENED)}
                </form>
            </div>
        );
    }
    return (
        <div className="d-flex justify-content-between mt-3">
            <div><RequiredSpan>{'*\u00A0'}</RequiredSpan><FormattedMessage {...commonMessages.IS_MANDATORY}/></div>
            <form className="form-group">
                {get(initialValues, 'ticketSection.uuid') && renderBackButton()}
                {userRights.canReopen && get(initialValues, 'ticketSection.status') === CLAIMS_TICKET_STATUS_CLOSED &&
                renderButton(FORM_BUTTON_REOPEN, BUTTON_LABEL_REOPEN, CLAIMS_TICKET_STATUS_REOPENED)}
                {userRights.canForwardBackToSubs &&
                renderButton(FORM_BUTTON_TAKE_BACK, BUTTON_LABEL_TAKE_BACK, CLAIMS_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY)}
                {userRights.canClose &&
                renderButton(FORM_BUTTON_CLOSE, BUTTON_LABEL_CLOSE, CLAIMS_TICKET_STATUS_CLOSED)}
                {userRights.canSave &&
                renderButton(FORM_BUTTON_SAVE, !editMode && intlLanguage === 'it' ? BUTTON_LABEL_SAVE_AND_SEND : BUTTON_LABEL_SAVE, get(initialValues, 'ticketSection.status'))}
                {userRights.canForwardToSubs &&
                renderButton(FORM_BUTTON_FORWARD_TO_NPDC, BUTTON_LABEL_FORWARD_TO_NPDC, CLAIMS_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY)}
                {userRights.canForwardToDealer &&
                renderButton(FORM_BUTTON_FORWARD_TO_DEALER, BUTTON_LABEL_FORWARD_TO_DEALER, CLAIMS_TICKET_STATUS_IN_PROGRESS_DEALER)}
                {userRights.canChangeToReadBySubs &&
                renderButton(FORM_BUTTON_MARK_AS_READ, BUTTON_LABEL_ADD_REPLY_COMMENTS, CLAIMS_TICKET_STATUS_READ_BY_SUBSIDIARY)}
            </form>
        </div>
    )
}

SubmitButtons.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    readOnly: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    userRights: PropTypes.object.isRequired,
};

const mapStateToProps = (state, initialProps) => ({
    initialValues: getFormInitialValues(initialProps.form)(state),
    roles: state.profile.userDetail.roles,
    intlLanguage: get(state, 'intl.language'),
    dealerNumber: state.profile.userDetail.dealerNumber,
    ticket: get(state, 'claimsTicket.ticket'),
});

export default reduxForm({})(withRouter(connect(mapStateToProps, {})(SubmitButtons)));
