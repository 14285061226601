import React, {useState} from 'react';
import styled from 'styled-components';
import {formValueSelector, change, Field} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {
    FORM_FIELD_ROOT_CAUSE,
    FORM_LABEL_ROOT_CAUSE,
} from '../../../../../../constants/formConstants';
import formMessages from '../../../../../../intl/technicalDocumentations/formMessages';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {storeCheckResultsRootCauseList} from '../../../../../../constants/Utils';
import storeCheckResultsRootCauseMessages
    from '../../../../../../intl/technicalDocumentations/storeCheckResultsRootCauseMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const StoreCheckResultsSection = (props) => {
    const { readOnly, reqFields, userRights, updateFileNumber, change, selectedRequestType } = props;

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.STORE_CHECK_RESULTS_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-md-6">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_ROOT_CAUSE]}
                                                        field={FORM_FIELD_ROOT_CAUSE}
                                                        options={storeCheckResultsRootCauseList}
                                                        intlMessages={storeCheckResultsRootCauseMessages}
                                                        isRequired={reqFields.includes(FORM_FIELD_ROOT_CAUSE)}
                                                        isDisabled={readOnly}
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )

}

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, initialProps) => ({
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(StoreCheckResultsSection);
