import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {withRouter} from 'react-router-dom';
import FlowDetailForm from './FlowDetailForm';
import {fetchFlowDetailPageData, saveExistingFlow, saveNewFlow} from '../../../actions/actionFlow'
import Loader from '../../Loader';
import flowMessages from '../../../intl/admin/flowMessages';
import {ADMINISTRATION_GENERIC_FLOW_PATH} from '../../../routes/paths';
import PageHeader from '../../common/PageHeader';
import {flowReducer} from '../../../reducers/flowReducer';

class FlowDetailPage extends Component {
    componentDidMount() {
        this.reloadData();
    }

    handleSaveClick = (flow, dispatch, {initialValues: originalFlow = {}}) => {
        const {match: {params: {id}}, saveExistingFlow, saveNewFlow, history} = this.props;
        if (id) {
            saveExistingFlow(flow, originalFlow).then(() => history.push(ADMINISTRATION_GENERIC_FLOW_PATH));
        } else {
            saveNewFlow(flow).then(() => history.push(ADMINISTRATION_GENERIC_FLOW_PATH));
        }
    };

    reloadData = () => this.props.fetchFlowDetailPageData(this.props.match.params.id);

    render() {
        if (this.props.isLoading) {
            return <Loader/>;
        }

        return (
            <div>
                <PageHeader title={<FormattedMessage {...flowMessages.DETAIL_TITLE}/>}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <FlowDetailForm onSubmit={this.handleSaveClick} handleReset={() => this.reloadData()}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

FlowDetailPage.propTypes = {
    match: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchFlowDetailPageData: PropTypes.func.isRequired,
    saveNewFlow: PropTypes.func.isRequired,
    saveExistingFlow: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.flowReducer.isLoading
});

export default withRouter(connect(mapStateToProps, {
    fetchFlowDetailPageData,
    saveNewFlow,
    saveExistingFlow
})(FlowDetailPage));
