import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {withAlert} from 'react-alert';
import PropTypes from 'prop-types';
import Header from './Header';
import TopBar from './menu/topMenu/TopBar';
import SideBar from './menu/sideMenu/SideBar';
import HomePage from './home/HomePage';
import Administration from './administration/Administration';
import TechnicalDocumentation from './technicalDocumentations/TechnicalDocumentation';
import Claims from './claims/Claims';
import Generic from './generic/Generic';
import {
    ADMINISTRATION_PATH, CLAIMS_PATH, DOCUMENTATIONS_PATH, GENERICS_PATH, HELP_PATH, HOME_PATH, ORDERS_PATH
} from '../routes/paths';
import NotFoundPage from './NotFoundPage';
import SparePart from './sparePartsOrders/SparePart';
import {moduleRoles} from '../utils/roles';
import {connect} from 'react-redux';
import get from 'get-value';
import {getSparePartsPreferences} from '../actions/orders/actionSparePartsPreferences';
import {getTechDocPreferences} from '../actions/techDoc/actionTechDocPreferences';
import {getClaimsPreferences} from '../actions/claims/actionClaimsPreferences';
import HelpPage from './administration/help/HelpPage';
import HeaderNotification from './HeaderNotification';

const Main = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
`;

const ContentWrapper = styled.div`
    flex: 1;
    margin-top: 6px;
    margin-bottom: 6px;
    padding: 0 20px 0 20px;
    transition: all 0.5s ease;
    overflow-y: auto;
    overflow-x: hidden;
`;

const AppWrapper = styled.div`
    height: ${({isHomePage = false}) => isHomePage ? '' : '100vh'};
    display: flex;
    flex-direction: column;
`;

class AppContent extends Component {
    componentDidMount() {
        this.props.alert.show();
        const user = get(this, 'props.userDetail.ipn');
        const userRoles = get(this, 'props.userDetail.roles', {default: {}});
        if (user && (moduleRoles.isEditorOrder(userRoles) || moduleRoles.isSGEditorOrder(userRoles) || moduleRoles.isDealerOrder(userRoles))) {
            this.props.getSparePartsPreferences();
        }
        if (user && (moduleRoles.isEditorDocumentation(userRoles) || moduleRoles.isSGEditorDocumentation(userRoles) || moduleRoles.isDealerDocumentation(userRoles))) {
            this.props.getTechDocPreferences();
        }
        if (user && (moduleRoles.isEditorClaims(userRoles) || moduleRoles.isDealerClaims(userRoles))) {
            this.props.getClaimsPreferences();
        }
    }

    render() {
        const {store, location: {pathname}} = this.props;
        return (
            <AppWrapper isHomePage={pathname === HOME_PATH}>
                <HeaderNotification/>
                <Header/>
                <TopBar/>
                <Main id="mainContentPrint">
                    <SideBar store={store}/>
                    <ContentWrapper id="contentWrapper">
                        <Switch>
                            <Route exact path={HOME_PATH} component={HomePage}/>
                            <Route path={ADMINISTRATION_PATH} component={Administration}/>
                            <Route path={ORDERS_PATH} component={SparePart}/>
                            <Route path={DOCUMENTATIONS_PATH} component={TechnicalDocumentation}/>
                            <Route path={CLAIMS_PATH} component={Claims}/>
                            <Route path={GENERICS_PATH} component={Generic}/>
                            <Route path={HELP_PATH} component={HelpPage}/>
                            <Route component={NotFoundPage}/>
                        </Switch>
                    </ContentWrapper>
                </Main>
            </AppWrapper>
        );
    }
}

AppContent.propTypes = {
    store: PropTypes.any.isRequired,
    alert: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    userDetail: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    userDetail: state.profile.userDetail,
});

export default withRouter(withAlert(connect(mapStateToProps, {
    getSparePartsPreferences,
    getTechDocPreferences,
    getClaimsPreferences,
})(AppContent)));
