import React, {Component} from 'react'
import {Prompt, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import ConfirmationDialog from './ConfirmationDialog'

export class RouteLeavingGuard extends Component {
    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleBlockedNavigation = this.handleBlockedNavigation.bind(this);
        this.handleConfirmNavigationClick = this.handleConfirmNavigationClick.bind(this);
        this.state = {
            modalVisible: false,
            lastLocation: null,
            confirmedNavigation: false,
        };
    }

    showModal(location) {
        this.setState({
            modalVisible: true,
            lastLocation: location,
        });
    }

    closeModal(callback) {
        if (callback && callback instanceof Function) {
            this.setState({modalVisible: false}, callback);
        } else {
            this.setState({modalVisible: false});
        }
    }

    handleBlockedNavigation(nextLocation) {
        const {confirmedNavigation} = this.state;
        const {shouldBlockNavigation = () => true} = this.props;
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            this.showModal(nextLocation);
            return false;
        }

        return true;
    }

    handleConfirmNavigationClick() {
        this.closeModal(() => {
            const {history} = this.props;
            const {lastLocation} = this.state;
            if (lastLocation) {
                this.setState({
                    confirmedNavigation: true
                }, () => {
                    history.push(lastLocation.pathname)
                })
            }
        });
    }

    render() {
        const {when} = this.props;
        const {modalVisible} = this.state;
        return (
            <React.Fragment>
                <Prompt when={when} message={this.handleBlockedNavigation}/>
                <ConfirmationDialog show={modalVisible} closeModal={this.closeModal}
                                    saveModal={this.handleConfirmNavigationClick}
                                    actionIdentifier="leave (with changes unsaved)"/>
            </React.Fragment>
        )
    }
}

RouteLeavingGuard.propTypes = {
    shouldBlockNavigation: PropTypes.func,
    navigate: PropTypes.func,
    when: PropTypes.bool,
    history: PropTypes.object.isRequired
};

export default withRouter(RouteLeavingGuard);
