import {put, takeEvery, select} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import get from 'get-value';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {TECH_DOC_TICKET_RESET} from '../../actions/techDoc/actionTechDocTicket';

export function* handleTicketError(action) {
    const stateCorrelationId = get(yield select(state => state.techDocTicket), `correlationId`, {default: undefined});
    const {correlationId} = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(push('/documentation'));
    yield put({type: TECH_DOC_TICKET_RESET});
}

function* techDocTicketSaga() {
    yield takeEvery(techDocWebsocketCommands.TECH_DOC_TICKET_UPDATE_ERROR, handleTicketError);
    yield takeEvery(techDocWebsocketCommands.TECH_DOC_TICKET_CREATE_ERROR, handleTicketError);
    yield takeEvery(techDocWebsocketCommands.TECH_DOC_TICKET_SEND_ERROR, handleTicketError);
}

export default techDocTicketSaga;
