import {
    SUPPORT_MODAL_CLOSE,
    SUPPORT_MODAL_SHOW,
    USER_SETTINGS_SAVE_PENDING,
    ADMIN_USER_SETTINGS_SEND,
    ADMIN_USER_SETTINGS_SEND_ERROR,
    USER_PROFILE_SAVE_PENDING,
    ADMIN_USER_PROFILE_SEND_ERROR,
    ADMIN_USER_PROFILE_SEND,
    SUPPORT_MODAL_START_COUNTDOWN,
} from '../actions/actionSupport';

export const supportModal = (state = {
                                 isModalOpen: false,
                                 initiateCountdown: false,
                             },
                             action) => {
        switch (action.type) {
            case SUPPORT_MODAL_SHOW:
                return Object.assign({}, state, {isModalOpen: true});
            case SUPPORT_MODAL_CLOSE: {
                return Object.assign({}, state, {isModalOpen: false});
            }
            case SUPPORT_MODAL_START_COUNTDOWN: {
                const count = action.payload.count;
                return Object.assign({}, state, {initiateCountdown: true, count: count});
            }
            case USER_PROFILE_SAVE_PENDING:
            case USER_SETTINGS_SAVE_PENDING: {
                return Object.assign({}, state, {isLoading: true});
            }
            case ADMIN_USER_PROFILE_SEND_ERROR:
            case ADMIN_USER_PROFILE_SEND:
            case ADMIN_USER_SETTINGS_SEND_ERROR:
            case ADMIN_USER_SETTINGS_SEND: {
                return Object.assign({}, state, {isLoading: false});
            }
            default:
                return state;
        }
    }
;
