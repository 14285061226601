import React, {Component} from 'react';
import {connect} from 'react-redux';
import Loader from '../../Loader';
import {
    FORM_FIELD_TYPE_OF_REQUEST,
    FORM_TICKET_SECTION,
    INTERNAL_TICKET_FORM,
} from '../../../constants/formConstants';
import PropTypes from 'prop-types';
import {formatInternalTicketNumber} from '../../../utils/utils';
import {editRights} from '../../../constants/techDocInternalTicketRights';
import {FormattedMessage} from 'react-intl';
import {initLoadedTechDocInternalTicket} from '../Utils';
import {createReqFields, editReqFields} from '../../../constants/techDocInternalTicketRequiredFields';
import formMessages from '../../../intl/technicalDocumentations/formMessages';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import checkRoles from '../../common/roleChecker/RoleChecker';
import InternalTicketMasterForm from './forms/InternalTicketMasterForm';
import {formValueSelector} from 'redux-form';

class EditLoadInternalTicketDataPage extends Component {
    componentDidMount() {
        const {ticketData} = this.props;
    }

    render() {
        const {
            ticketData,
            clientIds,
            handleSubmit,
            formType,
            selectedRequestType
        } = this.props;

        if (clientIds.isLoading) {
            return <Loader/>;
        }

        const requiredFields = editReqFields[editReqFields[ticketData.ticket.group] ? ticketData.ticket.group : 'DEFAULT']['TD_SG_EDITOR'][selectedRequestType ? selectedRequestType : formType];
        const initializationValue = initLoadedTechDocInternalTicket(ticketData);
        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatInternalTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {formType === INTERNAL_TICKET_FORM &&
                    <InternalTicketMasterForm form={formType}
                                                 initialValues={initializationValue}
                                                 userRights={editRights['TD_SG_EDITOR'][ticketData.ticket.status]}
                                                 enableReinitialize={true}
                                                 handleSubmit={handleSubmit}
                                                 reqFields={requiredFields}
                    />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ticketData: state.techDocInternalTicket,
    clientIds: state.adminClientIds,
    selectedRequestType: formValueSelector(props.formType)(state, `${FORM_TICKET_SECTION}.${FORM_FIELD_TYPE_OF_REQUEST}`),
});

EditLoadInternalTicketDataPage.propTypes = {
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
};

export default checkRoles(connect(mapStateToProps, {

})(EditLoadInternalTicketDataPage), ['TD_SG_EDITOR']);

