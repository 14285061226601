import React from 'react';
import styled from 'styled-components';
import {Field, reduxForm} from 'redux-form'
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {TextArea} from '../../../common/TextArea';
import {BoldPrimaryButton, SecondaryButton} from '../../../common/Button';
import mainPageAlertMessages from "../../../../intl/admin/mainPageAlertMessages";
import buttonMessages from "../../../../intl/common/buttonMessages";

const Button = styled(SecondaryButton)`
  margin-right: 10px;
`;

const MessageForm = ({ handleSubmit, pristine, reset, submitting, intl: { formatMessage } }) => (
    <div>
        <form onSubmit={handleSubmit}>
            <div>
                <Field name="textMainMessage"
                       component={TextArea}
                       placeholder={formatMessage(mainPageAlertMessages.PLACEHOLDER_MAIN_PAGE_MESSAGE)}
                       className="form-control"/>
            </div>
            <div className="row">
                <div className="col">
                    <BoldPrimaryButton type="submit"
                                       disabled={pristine || submitting}
                                       className="btn float-right">
                        <FormattedMessage {...buttonMessages.SAVE}/>
                    </BoldPrimaryButton>
                    <Button type="button"
                            disabled={pristine || submitting}
                            className="btn float-right"
                            onClick={reset}>
                        <FormattedMessage {...buttonMessages.CANCEL}/>
                    </Button>
                </div>
            </div>
        </form>
    </div>
);

MessageForm.propTypes = {
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
};

export default reduxForm({
    enableReinitialize: true,
})(injectIntl(MessageForm));
