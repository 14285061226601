import {BVM_DATA_RESET, BVM_DATA_SETTED} from '../actions/orders/bvmData';
import {bvmWebsocketCommands} from '../constants/bvmWebsocketCommands';

export const bvmData = (state = {
    isBvmLoading: false,
    bvmData: {},
    bvmLoaded: false,
    bvmSet: false,
}, action) => {
    switch (action.type) {
        case bvmWebsocketCommands.BVM_DATA_GET: {
            return Object.assign({}, state, {isBvmLoading: true, bvmLoaded: false, bvmSet: false});
        }
        case bvmWebsocketCommands.BVM_DATA_SEND: {
            const {bvmData} = action.payload;
            return Object.assign({}, state, {bvmData, isBvmLoading: false, bvmLoaded: true, bvmSet: true});
        }
        case BVM_DATA_SETTED: {
            return Object.assign({}, state, {bvmData: {}, bvmSet: false});
        }
        case BVM_DATA_RESET:
        case bvmWebsocketCommands.BVM_DATA_SEND_WARN:
        case bvmWebsocketCommands.BVM_DATA_SEND_ERROR: {
            return Object.assign({}, state, {bvmData: {}, isBvmLoading: false, bvmLoaded: false, bvmSet: false});
        }
        default:
            return state;
    }
};
