import {defineMessages} from 'react-intl';

const messages = defineMessages({
    PLACEHOLDER_SELECT_USER: {
        id: 'admin.authorizationManagement.selectUser.placeholder',
        defaultMessage: 'Select a user'
    },
    SELECT_USER: {
        id: 'admin.authorizationManagement.selectUser',
        defaultMessage: 'Select User'
    },
    SELECT_USER_SEARCH: {
        id: 'admin.authorizationManagement.selectUser.search',
        defaultMessage: 'Start searching by name or IPN...'
    },
    ADMIN_PLACEHOLDER_SELECT_COUNTRIES: {
        id: 'admin.authorizationManagement.admin.selectCountries.placeholder',
        defaultMessage: 'Select countries'
    },
    ADMIN_PLACEHOLDER_SELECT_SOLUTION_GROUPS: {
        id: 'admin.authorizationManagement.admin.selectSolutionGroups.placeholder',
        defaultMessage: 'Select solution groups'
    },
    ADMIN_SELECT_COUNTRIES: {
        id: 'admin.authorizationManagement.admin.selectCountries',
        defaultMessage: 'Select countries'
    },
    DEALER_SELECT_FORM_TYPES: {
        id: 'admin.authorizationManagement.dealer.selectFormTypes',
        defaultMessage: 'Select Form Types'
    },
    EDITOR_SELECT_COUNTRIES: {
        id: 'admin.authorizationManagement.editor.selectCountries',
        defaultMessage: 'Select countries'
    },
    EDITOR_PLACEHOLDER_SELECT_COUNTRIES: {
        id: 'admin.authorizationManagement.editor.selectCountries.placeholder',
        defaultMessage: 'Select countries'
    },
    DEALER_PLACEHOLDER_SELECT_FORM_TYPES: {
        id: 'admin.authorizationManagement.dealer.placeholder.selectFormTypes',
        defaultMessage: 'Select Form Types'
    },
    EDITOR_SG_SELECT_SG: {
        id: 'admin.authorizationManagement.SGeditor.selectSG',
        defaultMessage: 'Select solution groups'
    },
    EDITOR_SG_PLACEHOLDER_SELECT_SG: {
        id: 'admin.authorizationManagement.SGeditor.selectSG.placeholder',
        defaultMessage: 'Select solution groups'
    },
});

export default messages;
