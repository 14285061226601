import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl, FormattedDate, FormattedTime} from 'react-intl';
import styled from 'styled-components';
import get from 'get-value';
import {Field, reduxForm, change, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {BoldPrimaryButton, SecondaryButton} from '../../common/Button';
import FileSelectionField from '../../administration/common/FileSelectionField';
import buttonMessages from "../../../intl/common/buttonMessages";
import managementGroupMessages from "../../../intl/sparePartsOrders/managementGroupMessages";
import * as XLSX from 'xlsx';

import TextareaAutosize from 'react-textarea-autosize';

const Header = styled.div`
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 700
`;

const Body = styled.div`
    position: relative;
    padding: 20px;
    padding-bottom: 0px;
    border-top: ${props => props.topBorder ? '1px solid #e5e5e5' : ''};
`;

const Footer = styled.div`
    margin-top: 15px;
    padding: 10px 20px 20px;
    text-align: center;
    border-top: 1px solid #e5e5e5;
`;

class ManagementGroupOrderForm extends Component {
    save = attachment => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            const dataParse = XLSX.utils.sheet_to_json(ws, {header: 1});
            this.props.change('spareParts', (dataParse || [])
                .filter(e => Array.isArray(e) && typeof (e[0] + '') === 'string' && (e[0] + '').length === 10 && typeof (e[1] + '') === 'string' && (e[1] + '').length === 2)
                .map(e => ({sparePartNumber: e[0] + '', managementGroup: e[1] + ''})));
        };
        reader.readAsBinaryString(attachment);
    };

    handleAttachmentSelection = async (attachment) => {
        this.save(attachment);
    };

    render() {
        const {
            handleSubmit,
            batchRecord,
            spareParts,
            pristine, reset, submitting,
        } = this.props;

        return (
            <form>
                <Header>
                    <FormattedMessage {...managementGroupMessages.FORM_TITLE}/>
                </Header>
                <Body>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-2">
                                <label>
                                    <FormattedMessage {...managementGroupMessages.ATTACHMENT_FIELD}/>
                                </label>
                            </div>
                            <div className="col-10">
                                <Field name="attachment" component={FileSelectionField}
                                       handleAttachmentSelection={this.handleAttachmentSelection} showIcon
                                       accept={['.xls', '.xlsx']}
                                       showName needsFixedHeight/>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-2">
                                <label>
                                    <FormattedMessage {...managementGroupMessages.TOTAL_PARTS_LABEL}/>
                                </label>
                            </div>
                            <div className="col-10">
                                {spareParts && spareParts.length}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <label>
                                    <FormattedMessage {...managementGroupMessages.LOADED_PARTS_LABEL}/>
                                </label>
                            </div>
                            <div className="col-10">
                                <TextareaAutosize
                                    style={{
                                        width: '200px'
                                    }}
                                    maxRows={4}
                                    value={(spareParts || []).map(e => e.managementGroup + '=>' + e.sparePartNumber + '\n').join('')}
                                />
                            </div>
                        </div>
                    </div>
                </Body>
                <Body topBorder>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-2">
                                <label>
                                    <FormattedMessage {...managementGroupMessages.LAST_BATCH_LABEL}/>
                                </label>
                            </div>
                            <div className="col-10">
                                {batchRecord.created && <>
                                    <FormattedDate value={new Date(batchRecord.created)}/> <FormattedTime
                                    value={new Date(batchRecord.created)}/>
                                </>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <label>
                                    <FormattedMessage {...managementGroupMessages.STATUS_LABEL}/>
                                </label>
                            </div>
                            <div className="col-10">
                                {get(batchRecord, 'status', '')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <label>
                                    <FormattedMessage {...managementGroupMessages.UPDATED_TICKETS_LABEL}/>
                                </label>
                            </div>
                            <div className="col-10">
                                {get(batchRecord, 'updatedTickets', '0')}
                            </div>
                        </div>
                    </div>
                </Body>
                <Footer>
                    <div className="form-row">
                        <div className="col-sm">
                            <BoldPrimaryButton type="submit" className="btn float-right"
                                               disabled={pristine || submitting}
                                               onClick={handleSubmit}>
                                <FormattedMessage {...buttonMessages.SAVE}/>
                            </BoldPrimaryButton>
                            <SecondaryButton type="button" className="btn float-right mr-2"
                                             onClick={reset || submitting}
                                             disabled={pristine || submitting}>
                                <FormattedMessage {...buttonMessages.CANCEL}/>
                            </SecondaryButton>
                        </div>
                    </div>
                </Footer>
            </form>
        )
    }
}

ManagementGroupOrderForm.propTypes = {
    intl: PropTypes.any.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
    batchRecord: state.sparePartsManagementGroupBatch.batchRecord,
    spareParts: formValueSelector(props.form)(state, 'spareParts'),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value))
});
export default reduxForm({})(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ManagementGroupOrderForm)));
