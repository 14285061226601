import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Field} from 'redux-form';
import moment from 'moment';
import styled from 'styled-components';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import {
    positiveNumberWithZero,
    required,
    validFourCharLength,
    validInput,
    validPartNoLength,
    validSixCharLength,
    validSixToTenCharLength,
    validThreeCharLength,
    validTwoCharLength
} from '../../../../../common/validationCommons';
import {RequiredSpan} from '../../../../../common/StyledComponents';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import DatePickerComponent from '../../../../../common/datePicker/DatePickerComponent';
import formMessages from '../../../../../../intl/claims/formMessages';
import {
    FORM_FIELD_CODE_PROD,
    FORM_FIELD_DAMAGED,
    FORM_FIELD_DATE,
    FORM_FIELD_DEFECTIVE,
    FORM_FIELD_DESCRIPTION,
    FORM_FIELD_INVOICE,
    FORM_FIELD_INVOICED_LINE1,
    FORM_FIELD_INVOICED_LINE2,
    FORM_FIELD_INVOICED_ORDER1,
    FORM_FIELD_INVOICED_ORDER2,
    FORM_FIELD_OBSERVATION,
    FORM_FIELD_PACKAGE_NO,
    FORM_FIELD_PART_NO,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_QUANTITY,
    FORM_FIELD_QUANTITY_TOTAL,
    FORM_FIELD_RETURN,
    FORM_FIELD_RETURN_PART_NO,
    FORM_LABEL_CODE_PROD,
    FORM_LABEL_DAMAGED,
    FORM_LABEL_DATE,
    FORM_LABEL_DEFECTIVE,
    FORM_LABEL_DESCRIPTION,
    FORM_LABEL_INVOICE,
    FORM_LABEL_LINE,
    FORM_LABEL_OBSERVATION,
    FORM_LABEL_ORDER,
    FORM_LABEL_ORDER_INVOICED,
    FORM_LABEL_ORDER_RECEIVED,
    FORM_LABEL_PACKAGE_NO,
    FORM_LABEL_PART_NO,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_QUANTITY,
    FORM_LABEL_QUANTITY_TOTAL,
    FORM_LABEL_RETURN
} from '../../../../../../constants/formConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;
const StyledLabelDiv = styled.div`
  padding-top: 7px;
  padding-bottom: 7px;
`;
const StyledBorderDiv = styled.div`
  border-color: #ced4da;
   :hover {
    background-color: #e9ecef;
  }
`;
const StyledHead = styled.div`
  background-color: #e9ecef;
`;

const ProblemForPlacingClaimsSection = (props) => {
    const {readOnly, userRights, reqFields} = props;

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.PROBLEM_DESCRIPTION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary p-4 bg-white">
                <StyledBorderDiv className="border rounded-top">
                    <div className="form-row m-0">
                        <StyledHead className="col-12 font-weight-bold">
                            <ValidFormattedMessage message={FORM_LABEL_ORDER_INVOICED}
                                                   intlMessages={formMessages}/>
                        </StyledHead>
                    </div>
                    <div className="form-row mx-3">
                        <div className="col-xl-2 col-lg-6">
                            <StyledLabelDiv className="row m-0 font-weight-bold">
                                <ValidFormattedMessage message={FORM_LABEL_ORDER}
                                                       intlMessages={formMessages}/>
                                {userRights.canEditProblemDesc && !readOnly && <RequiredSpan>{'\u00A0*'}</RequiredSpan>}
                            </StyledLabelDiv>
                            <div className="row m-0">
                                <div className="col-7 p-0 m-0">
                                    <InputFieldWithValidation field={FORM_FIELD_INVOICED_ORDER1}
                                                              maxLength={3}
                                                              isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                              isRequired={reqFields.includes(FORM_FIELD_INVOICED_ORDER1)}
                                                              validations={[validThreeCharLength]}
                                                              isSmall/>
                                </div>
                                <div className="col-5 pl-1 pr-0 m-0">
                                    <InputFieldWithValidation field={FORM_FIELD_INVOICED_ORDER2}
                                                              maxLength={2}
                                                              isRequired={reqFields.includes(FORM_FIELD_INVOICED_ORDER2)}
                                                              isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                              validations={[validTwoCharLength]}
                                                              isSmall/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-6">
                            <StyledLabelDiv className="row m-0 font-weight-bold">
                                <ValidFormattedMessage message={FORM_LABEL_LINE}
                                                       intlMessages={formMessages}/>
                                {userRights.canEditProblemDesc && !readOnly && <RequiredSpan>{'\u00A0*'}</RequiredSpan>}
                            </StyledLabelDiv>
                            <div className="row m-0">
                                <div className="col-7 p-0 m-0">
                                    <InputFieldWithValidation field={FORM_FIELD_INVOICED_LINE1}
                                                              maxLength={4}
                                                              isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                              isRequired={reqFields.includes(FORM_FIELD_INVOICED_LINE1)}
                                                              validations={[validFourCharLength]}
                                                              isSmall/>
                                </div>
                                <div className="col-5 pl-1 pr-0 m-0">
                                    <InputFieldWithValidation field={FORM_FIELD_INVOICED_LINE2}
                                                              maxLength={2}
                                                              isRequired={reqFields.includes(FORM_FIELD_INVOICED_LINE2)}
                                                              isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                              validations={[validTwoCharLength]}
                                                              isSmall/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_CODE_PROD]}
                                                      field={FORM_FIELD_CODE_PROD}
                                                      maxLength={3}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_CODE_PROD)}
                                                      validations={[validThreeCharLength]}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_PART_NO]}
                                                      field={FORM_FIELD_PART_NO}
                                                      maxLength={10}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_PART_NO)}
                                                      validations={[validPartNoLength]}
                                                      isSmall/>
                        </div>
                    </div>
                    <div className="form-row pb-2 mb-2 mx-3">
                        <div className="col-xl-2 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_QUANTITY]}
                                                      field={FORM_FIELD_QUANTITY}
                                                      maxLength={6}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_QUANTITY)}
                                                      validations={[positiveNumberWithZero]}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-2 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_INVOICE]}
                                                      field={FORM_FIELD_INVOICE}
                                                      maxLength={6}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_INVOICE)}
                                                      validations={[validSixCharLength]}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-2 col-lg-6">
                            <Field component={DatePickerComponent}
                                   selectsEnd
                                   isClearable
                                   label={formMessages[FORM_LABEL_DATE]}
                                   name={FORM_FIELD_DATE}
                                   isDisabled={!userRights.canEditProblemDesc || readOnly}
                                   isRequired={reqFields.includes(FORM_FIELD_DATE)}
                                   validate={reqFields.includes(FORM_FIELD_DATE) ? [validInput, required] : undefined}
                                   parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_PACKAGE_NO]}
                                                      field={FORM_FIELD_PACKAGE_NO}
                                                      maxLength={10}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_PACKAGE_NO)}
                                                      validations={[validSixToTenCharLength]}
                                                      isSmall/>
                        </div>
                    </div>
                </StyledBorderDiv>

                <StyledBorderDiv className="border rounded-top mt-4">
                    <div className="form-row m-0">
                        <StyledHead className="col-12 font-weight-bold">
                            <ValidFormattedMessage message={FORM_LABEL_ORDER_RECEIVED}
                                                   intlMessages={formMessages}/>
                        </StyledHead>
                    </div>
                    <div className="form-row mx-3">
                        <div className="col-xl-3 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_PART_NO]}
                                                      field={FORM_FIELD_RETURN_PART_NO}
                                                      maxLength={10}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_RETURN_PART_NO)}
                                                      validations={[validPartNoLength]}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-5 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_DESCRIPTION]}
                                                      field={FORM_FIELD_DESCRIPTION}
                                                      maxLength={20}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_DESCRIPTION)}
                                                      isSmall/>
                        </div>
                    </div>
                    <div className="form-row pb-2 mb-2 mx-3">
                        <div className="col-xl-2 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_QUANTITY_TOTAL]}
                                                      field={FORM_FIELD_QUANTITY_TOTAL}
                                                      maxLength={6}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_QUANTITY_TOTAL)}
                                                      validations={[positiveNumberWithZero]}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-2 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_DEFECTIVE]}
                                                      field={FORM_FIELD_DEFECTIVE}
                                                      maxLength={6}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_DEFECTIVE)}
                                                      validations={[positiveNumberWithZero]}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-2 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_DAMAGED]}
                                                      field={FORM_FIELD_DAMAGED}
                                                      maxLength={6}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_DAMAGED)}
                                                      validations={[positiveNumberWithZero]}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-2 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_RETURN]}
                                                      field={FORM_FIELD_RETURN}
                                                      maxLength={6}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_RETURN)}
                                                      validations={[positiveNumberWithZero]}
                                                      isSmall/>
                        </div>
                    </div>
                    <div className="form-row pb-2 mb-2 mx-3">
                        <div className="col">
                            <TextareaFieldWithValidation label={formMessages[FORM_LABEL_OBSERVATION]}
                                                         field={FORM_FIELD_OBSERVATION}
                                                         isDisabled={!userRights.canEditProblemDescText || readOnly}
                                                         rows={3}
                                                         isRequired={reqFields.includes(FORM_FIELD_OBSERVATION)}/>
                        </div>
                    </div>
                </StyledBorderDiv>

                <div className="form-row py-2">
                    <div className="col">
                        <TextareaFieldWithValidation label={formMessages[FORM_LABEL_PROBLEM_DESCRIPTION]}
                                                     field={FORM_FIELD_PROBLEM_DESCRIPTION}
                                                     isDisabled={!userRights.canEditProblemDescText || readOnly}
                                                     rows={6}
                                                     isRequired={reqFields.includes(FORM_FIELD_PROBLEM_DESCRIPTION)}/>
                    </div>
                </div>
            </StyledDiv>
        </>
    )
}

export default (ProblemForPlacingClaimsSection);
