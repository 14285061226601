import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const fetchClaimsFullTextSearchPageData = (pageSize, sorted, cursor, isNextFromPivot, searchOption, filtered) => (dispatch) => {
    dispatch({
        type: claimsWebsocketCommands.CLAIMS_FULL_TEXT_SEARCH_GET,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_FULL_TEXT_SEARCH_GET,
        payload: {
            pageSize: pageSize,
            sorted: sorted,
            cursor,
            isNextFromPivot,
            searchOption,
            filtered: filtered
        },
    });
};

export const fetchClaimsLastEditor = () => (dispatch) => {
    dispatch({
        type: claimsWebsocketCommands.CLAIMS_FULL_TEXT_SEARCH_LAST_EDITORS_GET,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_FULL_TEXT_SEARCH_LAST_EDITORS_GET,
        payload: {},
    });
};
