import React, {useEffect} from 'react';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import get from 'get-value';
import {
    FORM_TICKET_SECTION,
    FORM_FIELD_CLIENT_ID,
    FORM_FIELD_DEALER_NAME,
    FORM_FIELD_DEALER_NO,
    FORM_FIELD_DESTINATION_CODE, FORM_FIELD_EMAIL, FORM_FIELD_NAME,
    FORM_FIELD_REGION, FORM_FIELD_TELEPHONE_NO,
    FORM_LABEL_CLIENT_ID,
    FORM_LABEL_DEALER_NAME,
    FORM_LABEL_DEALER_NO,
    FORM_LABEL_DESTINATION_CODE, FORM_LABEL_EMAIL, FORM_LABEL_NAME,
    FORM_LABEL_REGION, FORM_LABEL_TELEPHONE_NO, FORM_FIELD_CREATION_DATE, FORM_LABEL_CREATION_DATE
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {change, getFormInitialValues, formValueSelector} from 'redux-form';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const TicketSection = (props) => {
    const {change, clientIds, initialValues, userRights, readOnly, reqFields, createdInDic, processingDic, creationDate, selectedClientId} = props;
    const {ticketSection: {clientId}} = initialValues;

    if (userRights.canEditClientId && !readOnly && clientIds.length === 1 && !clientId && !createdInDic) {
        change(`${FORM_TICKET_SECTION}.${FORM_FIELD_CLIENT_ID}`, get(clientIds, '0.clientId', {default: ''}));
        change(`${FORM_TICKET_SECTION}.${FORM_FIELD_DESTINATION_CODE}`, get(clientIds, '0.destinationCode', {default: ''}));
        change(`${FORM_TICKET_SECTION}.${FORM_FIELD_REGION}`, get(clientIds, '0.region', {default: ''}));
    }

    const handleChange = (event, value) => {
        change(`${FORM_TICKET_SECTION}.${FORM_FIELD_DESTINATION_CODE}`, get(clientIds.find(e => e.clientId === (value)), 'destinationCode', {default: ''}));
        change(`${FORM_TICKET_SECTION}.${FORM_FIELD_REGION}`, get(clientIds.find(e => e.clientId === (value)), 'region', {default: ''}));
    };

    const clientIdOptions = clientIds.map((id) => id.clientId);
    const destinationCodeOptions = clientIds.filter((id) => id.clientId === selectedClientId).map((id) => id.destinationCode);

    useEffect(() => {
        if (!clientId) {
            change(`${FORM_TICKET_SECTION}.${FORM_FIELD_CLIENT_ID}`, get(clientIds, '0.clientId', { default: '' }));
            change(`${FORM_TICKET_SECTION}.${FORM_FIELD_DESTINATION_CODE}`, get(clientIds, '0.destinationCode', { default: '' }));
            change(`${FORM_TICKET_SECTION}.${FORM_FIELD_REGION}`, get(clientIds, '0.region', { default: '' }));
        }
    }, []);

    return (
        <>
            <h3 className="text-left ml-4">
                <FormattedMessage {...formMessages.TICKET_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-xl-6 col-lg-8 col-md-12 col-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_DEALER_NAME]}
                                                  field={FORM_FIELD_DEALER_NAME}
                                                  isDisabled isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_DEALER_NO]}
                                                  field={FORM_FIELD_DEALER_NO} isDisabled
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-3 col-lg-4 col-md-12 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_CLIENT_ID]}
                                                        field={FORM_FIELD_CLIENT_ID}
                                                        options={clientIdOptions.filter((item, index) => clientIdOptions.indexOf(item) === index)}
                                                        isRequired={reqFields.includes(FORM_FIELD_CLIENT_ID) && !readOnly && get(clientIds, '0.type', '') !== 'NOT_USED'}
                                                        isDisabled={!userRights.canEditClientId || clientIdOptions.length <= 1 || readOnly || get(clientIds, '0.type', '') === 'NOT_USED' || createdInDic || processingDic}
                                                        handleChange={handleChange}
                                                        noEmpty
                        />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_DESTINATION_CODE]}
                                                        field={FORM_FIELD_DESTINATION_CODE}
                                                        options={destinationCodeOptions}
                                                        isRequired={reqFields.includes(FORM_FIELD_DESTINATION_CODE) && !readOnly && get(clientIds, '0.type', '') !== 'NOT_USED'}
                                                        isDisabled={!userRights.canEditClientId || destinationCodeOptions.length <= 1 || readOnly || createdInDic || processingDic || get(clientIds, '0.type', '') === 'NOT_USED'}
                                                        noEmpty
                        />
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_REGION]}
                                                  field={FORM_FIELD_REGION}
                                                  placeholder="---"
                                                  isDisabled
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-4 col-lg-6 col-md-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_NAME]}
                                                  field={FORM_FIELD_NAME}
                                                  isDisabled
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_EMAIL]}
                                                  field={FORM_FIELD_EMAIL}
                                                  isDisabled
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_TELEPHONE_NO]}
                                                  field={FORM_FIELD_TELEPHONE_NO}
                                                  maxLength={15}
                                                  isDisabled={!userRights.canEditItems || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_TELEPHONE_NO) && !readOnly}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 pb-2">
                        {creationDate && <InputFieldWithValidation label={formMessages[FORM_LABEL_CREATION_DATE]}
                                                                   field={FORM_FIELD_CREATION_DATE}
                                                                   isDisabled
                                                                   format={(e) => props.intl.formatDate(new Date(e)) + '  ' + props.intl.formatTime(new Date(e))}
                                                                   isSmall/>}
                    </div>
                </div>
            </StyledDiv>
        </>
    )
};

const mapStateToProps = (state, props) => ({
    clientIds: state.adminClientIds.clientIds,
    initialValues: getFormInitialValues(props.form)(state),
    createdInDic: state.sparePartsOrderTicket.ticket.createdInDic,
    processingDic: state.sparePartsDic.isCreateOrUpdateDicLoading,
    creationDate: formValueSelector(props.form)(state, `${FORM_TICKET_SECTION}.${FORM_FIELD_CREATION_DATE}`),
    selectedClientId: formValueSelector(props.form)(state, `${FORM_TICKET_SECTION}.${FORM_FIELD_CLIENT_ID}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TicketSection));
