import React from 'react';
import {FormattedMessage} from 'react-intl';
import {
    FORM_FIELD_FULLTEXT_SEARCH,
    FORM_LABEL_FULLTEXT_SEARCH,
} from '../../../../constants/formConstants';
import {InputCheckBox} from '../../../common/InputCheckbox';
import {connect} from 'react-redux';
import internalFulltextSearchMessages from '../../../../intl/sparePartsOrders/internalFulltextSearchMessages';
import BorderRadius from '../../../common/styled/BorderRadius';

const SearchOptionInternalSearchSection = () => {
    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...internalFulltextSearchMessages.SEARCH_OPTION_SECTION}/>
            </h3>
            <BorderRadius size={5} className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-4 col-lg-5 col-md-7 pb-2">
                        <InputCheckBox label={internalFulltextSearchMessages[FORM_LABEL_FULLTEXT_SEARCH]}
                                       field={FORM_FIELD_FULLTEXT_SEARCH}/>
                    </div>
                </div>
            </BorderRadius>
        </>
    )
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
});

export default connect(mapStateToProps, {})(SearchOptionInternalSearchSection);
