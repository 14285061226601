import {
    CLAIMS_FETCH_PREFERENCES_PENDING,
    CLAIMS_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE,
    CLAIMS_UPDATE_USER_HISTORY_PREFERENCE,
    CLAIMS_UPDATE_USER_LIST_PREFERENCE, CLAIMS_UPDATE_USER_STATISTICS_PREFERENCE
} from '../../actions/claims/actionClaimsPreferences';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';
import get from 'get-value';

export const claimsPreferences = (state = {
    isLoading: true,
    menuList: [],
    menuHistory: [],
    menuFullTextSearch: [],
    menuStatistics: [],
}, action) => {
    switch (action.type) {
        case CLAIMS_UPDATE_USER_LIST_PREFERENCE: {
            return Object.assign({}, state, {menuList: action.payload.preferences.hiddenColumns});
        }
        case CLAIMS_UPDATE_USER_HISTORY_PREFERENCE: {
            return Object.assign({}, state, {menuHistory: action.payload.preferences.hiddenColumns});
        }
        case CLAIMS_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE: {
            return Object.assign({}, state, {menuFullTextSearch: action.payload.preferences.hiddenColumns});
        }
        case CLAIMS_UPDATE_USER_STATISTICS_PREFERENCE: {
            return Object.assign({}, state, {menuStatistics: action.payload.preferences.countries});
        }
        case claimsWebsocketCommands.CLAIMS_PREFERENCES_SEND: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                menuList: get(action,'payload.preferences.menuList', []),
                menuHistory: get(action,'payload.preferences.menuHistory', []),
                menuFullTextSearch: get(action,'payload.preferences.menuFullTextSearch', []),
                menuStatistics: get(action,'payload.preferences.menuStatistics', []),
                isLoading: false,
                correlationId: action.payload.correlationId
            });
        }
        case claimsWebsocketCommands.CLAIMS_PREFERENCES_SEND_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId: action.payload.correlationId});
        }
        case claimsWebsocketCommands.CLAIMS_PREFERENCES_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId: action.payload.correlationId});
        }
        case CLAIMS_FETCH_PREFERENCES_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        default:
            return state;
    }
};
