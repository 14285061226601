import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const SPARE_PARTS_RECURRENT_PROBLEM_PENDING = 'SPARE_PARTS_RECURRENT_PROBLEM_PENDING';
export const SPARE_PARTS_RECURRENT_PROBLEM_SELECT_GROUP = 'SPARE_PARTS_RECURRENT_PROBLEM_SELECT_GROUP';

export const fetchSparePartsRecurrentProblems = (domain, group) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_RECURRENT_PROBLEM_PENDING,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_GET,
        payload: { group }
    });
};

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: SPARE_PARTS_RECURRENT_PROBLEM_SELECT_GROUP,
        payload: countryGroup
    });
