import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    BUTTON_LABEL_LOAD,
    FORM_BUTTON_ESCALATE,
    FORM_BUTTON_FORWARD_TO_DEALER,
    FORM_BUTTON_TAKE_BACK,
    FORM_CHECKBOX_CLAIM_RELATED,
    FORM_CHECKBOX_PVI_RELATED,
    FORM_CHECKBOX_RELATED_DEALERS_TICKETS,
    FORM_FIELD_CLAIM_RELATED,
    FORM_FIELD_ITEM_NUMBER,
    FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_PAGE_POST,
    FORM_FIELD_PAGE_PREF,
    FORM_FIELD_PART_DESCRIPTION,
    FORM_FIELD_PART_MARKING,
    FORM_FIELD_PART_NO,
    FORM_FIELD_PART_NUMBER,
    FORM_FIELD_PR,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_PVI_RELATED,
    FORM_FIELD_RELATED_DEALERS_TICKETS,
    FORM_LABEL_CLAIM_RELATED,
    FORM_LABEL_ITEM_NUMBER,
    FORM_LABEL_MANAGEMENT_GROUP,
    FORM_LABEL_PAGE,
    FORM_LABEL_PART_DESCRIPTION,
    FORM_LABEL_PART_MARKING,
    FORM_LABEL_PART_NUMBER,
    FORM_LABEL_PR,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_PVI_RELATED,
    FORM_LABEL_RELATED_DEALERS_TICKETS,
    FORM_PROBLEM_DESCRIPTION_SECTION,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {InputFieldPage} from '../../../../../common/InputFieldPage';
import {InputGroupCheckbox} from '../../../../../common/InputGroupCheckbox';
import {positiveNumber, positiveNumberWithZero, validAlphanumericInput} from '../../../../../common/validationCommons';
import formMessages from '../../../../../../intl/technicalDocumentations/formMessages';
import InternalTicketReferences from './InternalTicketReferences';
import displayField from '../../../../../../constants/displayField';
import Div from '../../../../../common/styled/HideAbleDiv';
import GarComponent from './GarComponent';
import warnMessages from '../../../../../../intl/common/warnMessages';
import {change, formValueSelector} from 'redux-form';
import {
    fetchDicPartInfoActionCreator,
    setDicTechDocInfoActionCreator
} from '../../../../../../actions/techDoc/actionTechDocDicData';
import {bindActionCreators} from 'redux';
import {PrimaryButton} from '../../../../../common/Button';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import {isEmpty, size} from 'lodash'
import {moduleRoles} from '../../../../../../utils/roles';
import get from 'get-value';
import {warnAlert} from '../../../../../../actions/alertsActions';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

class ProblemDescriptionSectionTechDoc extends Component {
    handlePartLoad = () => {
        const {fetchDicPartInfoActionCreator, partNo, warnAlert} = this.props;
        if(partNo.length == 10) {
            fetchDicPartInfoActionCreator(partNo);
        } else {
            warnAlert(warnMessages.TD_DIC_WARN005)
        }
    };

    fillDicPartInfo = () => {
        const {dicInfo, change, setDicTechDocInfoActionCreator, warnAlert, userRights} = this.props;
        const part = dicInfo.partNo;
        setDicTechDocInfoActionCreator();
        if (part) {
            if (!part[FORM_FIELD_MANAGEMENT_GROUP]) {
                change(`${FORM_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_MANAGEMENT_GROUP}`, dicInfo.managementGroup);
            } else {
                warnAlert(warnMessages.TD_DIC_WARN004, null, {partNumber: dicInfo.partNo})
            }
        } else {
            warnAlert(warnMessages.TD_DIC_WARN003, null, {partNumber: dicInfo.partNo});
        }
    };

    render() {
        const {readOnly, userRights, form, reqFields, intl: {formatMessage}, dicInfo, partNo, user: {roles}, solutionGroupInfo, submittedBy} = this.props;
        const display = (field) => displayField(field, FORM_PROBLEM_DESCRIPTION_SECTION, form);
        const isConformOrNotFoundReference = form
            === SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM
            || form
            === SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM;

        if (dicInfo.dicPartInfoLoaded) {
            this.fillDicPartInfo();
        }

        return (
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.PROBLEM_DESCRIPTION_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                    <div className="form-row">
                        <div className="col-xl-2 col-lg-3 col-md-4 pb-1">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_PR]}
                                                      field={FORM_FIELD_PR}
                                                      maxLength={5}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_PR)}
                                                      validations={[validAlphanumericInput]}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-8 pb-1">
                            <InputFieldPage label={formMessages[FORM_LABEL_PAGE]}
                                            fields={[FORM_FIELD_PAGE_PREF, FORM_FIELD_PAGE_POST]}
                                            validations={[positiveNumberWithZero]}
                                            isDisabled={!userRights.canEditProblemDesc || readOnly}
                                            isRequired={reqFields.includes(FORM_FIELD_PAGE_PREF) || reqFields.includes(FORM_FIELD_PAGE_POST)}
                            />
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-8 pb-1">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_ITEM_NUMBER]}
                                                      field={FORM_FIELD_ITEM_NUMBER}
                                                      maxLength={2}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_ITEM_NUMBER)}
                                                      validations={[positiveNumber]}
                                                      isSmall/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-xl-4 col-lg-6 col-md-12 pb-1">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_PART_NUMBER]}
                                                      field={FORM_FIELD_PART_NUMBER}
                                                      maxLength={10}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_PART_NUMBER)}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12 pb-1">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_PART_MARKING]}
                                                      field={FORM_FIELD_PART_MARKING}
                                                      maxLength={20}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_PART_MARKING)}
                                                      isSmall/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 pb-1">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_PART_DESCRIPTION]}
                                                      field={FORM_FIELD_PART_DESCRIPTION}
                                                      maxLength={30}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_PART_DESCRIPTION)}
                                                      isSmall/>
                        </div>
                        {moduleRoles.isSGEditorDocumentation(roles) &&
                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 pb-2">
                                <div className="form-row align-items-end">
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                        <InputFieldWithValidation label={formMessages[FORM_LABEL_MANAGEMENT_GROUP]}
                                                                  field={FORM_FIELD_MANAGEMENT_GROUP}
                                                                  maxLength={2}
                                                                  isDisabled={!userRights.canEditManagementGroup || readOnly}
                                                                  isRequired={(isEmpty(solutionGroupInfo) ? (reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP)) : (reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP) && !get(solutionGroupInfo, "groups", []).length == 0)) && submittedBy === FORM_BUTTON_ESCALATE && !readOnly}
                                                                  validations={[validAlphanumericInput]}
                                                                  isSmall/>
                                    </div>
                                    {userRights.canEditManagementGroup && !readOnly &&
                                        <>
                                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 pl-1 pt-1">
                                                <PrimaryButton type="button" className="btn"
                                                               disabled={size(partNo) < 10}
                                                               onClick={() => this.handlePartLoad()}
                                                >
                                                    <ValidFormattedMessage message={BUTTON_LABEL_LOAD}
                                                                           intlMessages={buttonMessages}/>
                                                </PrimaryButton>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className="form-row">
                        <div className="col-12 pr-0 pb-1">
                            <TextareaFieldWithValidation label={formMessages[FORM_LABEL_PROBLEM_DESCRIPTION]}
                                                         field={FORM_FIELD_PROBLEM_DESCRIPTION}
                                                         isDisabled={!userRights.canEditProblemDescText || readOnly}
                                                         rows={6}
                                                         isRequired={reqFields.includes(FORM_FIELD_PROBLEM_DESCRIPTION)}/>
                        </div>
                    </div>
                    <div className="form-row pb-2">
                        <div className="col-xl-3 col-lg-6">
                            <InputGroupCheckbox label={formMessages[FORM_LABEL_CLAIM_RELATED]}
                                                fields={[FORM_CHECKBOX_CLAIM_RELATED, FORM_FIELD_CLAIM_RELATED]}
                                                isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                isRequired={reqFields.includes(FORM_CHECKBOX_CLAIM_RELATED) || reqFields.includes(FORM_FIELD_CLAIM_RELATED)}
                                                maxLength={8}
                                                placeholder={formatMessage(formMessages.CLAIM_RELATED_NO)}/>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <InputGroupCheckbox label={formMessages[FORM_LABEL_PVI_RELATED]}
                                                fields={[FORM_CHECKBOX_PVI_RELATED, FORM_FIELD_PVI_RELATED]}
                                                isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                isRequired={reqFields.includes(FORM_CHECKBOX_PVI_RELATED) || reqFields.includes(FORM_FIELD_PVI_RELATED)}
                                                maxLength={8}
                                                placeholder={formatMessage(formMessages.PVI_RELATED_NO)}/>
                        </div>
                    </div>
                    <div className="form-row pb-2">
                        <Div className="col-sm-12" display={display(FORM_FIELD_RELATED_DEALERS_TICKETS) && userRights.canViewRelatedDealersTickets}>
                            <InputGroupCheckbox label={formMessages[FORM_LABEL_RELATED_DEALERS_TICKETS]}
                                                fields={[FORM_FIELD_RELATED_DEALERS_TICKETS, FORM_CHECKBOX_RELATED_DEALERS_TICKETS]}
                                                isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                isRequired={reqFields.includes(FORM_FIELD_RELATED_DEALERS_TICKETS) || reqFields.includes(FORM_CHECKBOX_RELATED_DEALERS_TICKETS)}
                                                maxLength={100}
                                                placeholder={formatMessage(formMessages.RELATED_TICKETS_NO)}/>
                        </Div>
                    </div>
                    {userRights.canViewGarFields && !isConformOrNotFoundReference && <GarComponent form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>}
                    {userRights.canViewInternalTicketReferences && <InternalTicketReferences form={form} />}
                </StyledDiv>
            </>
        )
    }
}

const mapStateToProps = (state, initialProps) => ({
    partNo: formValueSelector(initialProps.form)(state, `${FORM_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_PART_NO}`),
    dicInfo: state.techDocDic,
    user: state.profile.userDetail,
    solutionGroupInfo: state.techDocTicket.solutionGroupInfo,
    submittedBy: state.techDocTicket.submittedBy,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            setDicTechDocInfoActionCreator,
            fetchDicPartInfoActionCreator,
            warnAlert
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProblemDescriptionSectionTechDoc));
