import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TABLE_SOLUTION_GROUP: {
        id: 'admin.answeringSchema.table.solutionGroup',
        defaultMessage: 'Solution group'
    },
    TABLE_FORMTYPE: {
        id: 'admin.answeringSchema.table.formtype',
        defaultMessage: 'Form/Request Type'
    },
    TICKET_TYPE: {
        id: 'admin.answeringSchema.table.ticketType',
        defaultMessage: 'Ticket Type'
    },
    TABLE_ESCALATION_SOLUTION_GROUPS: {
        id: 'admin.answeringSchema.table.escalationSolutionGroups',
        defaultMessage: 'Escalation solution groups'
    },
    TABLE_ANSWERING_SOLUTION_GROUPS: {
        id: 'admin.answeringSchema.table.answeringSolutionGroups',
        defaultMessage: 'Answering solution groups'
    },
    TABLE_AS_REMOVE: {
        id: 'admin.answeringSchema.form.esEscalationGroups.remove',
        defaultMessage: 'remove the answering schema'
    },
    AS_SG_NAME: {
        id: 'admin.answeringSchema.form.assgName',
        defaultMessage: 'Solution group name'
    },
    AS_FORMTYPE: {
        id: 'admin.answeringSchema.form.asFormtype',
        defaultMessage: 'Form/Request Type'
    },
    AS_ANSWERING_GROUPS: {
        id: 'admin.answeringSchema.form.asAnsweringGroups',
        defaultMessage: 'Answering groups'
    },
});

export default messages;
