import React, {Component} from 'react';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {change, formValueSelector, getFormInitialValues} from 'redux-form';
import {
    BUTTON_LABEL_LOAD, FORM_BATTERY_PROBLEM_DESCRIPTION_SECTION,
    FORM_BUTTON_ESCALATE, FORM_BUTTON_FORWARD_TO_DEALER, FORM_BUTTON_TAKE_BACK,
    FORM_CHECKBOX_CLAIM_RELATED,
    FORM_CHECKBOX_PVI_RELATED,
    FORM_FIELD_CLAIM_RELATED, FORM_FIELD_CODE,
    FORM_FIELD_DESCRIPTION,
    FORM_FIELD_DIALOGYS_VERSION,
    FORM_FIELD_EDITOR_CODE_1,
    FORM_FIELD_EDITOR_CODE_2, FORM_FIELD_EDITOR_DESCRIPTION,
    FORM_FIELD_EDITOR_LINE,
    FORM_FIELD_EDITOR_ORDER, FORM_FIELD_EDITOR_PART_NO, FORM_FIELD_EDITOR_QUANTITY, FORM_FIELD_IMMOBILIZED_VEHICLE,
    FORM_FIELD_ITEM_NUMBER,
    FORM_FIELD_LINE, FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_ORDER,
    FORM_FIELD_PAGE_POST,
    FORM_FIELD_PAGE_PREF,
    FORM_FIELD_PART_NO,
    FORM_FIELD_PR,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_PROBLEM_TYPE,
    FORM_FIELD_PVI_RELATED,
    FORM_FIELD_QUANTITY,
    FORM_LABEL_CLAIM_RELATED,
    FORM_LABEL_CODE,
    FORM_LABEL_DEALER_ORDER,
    FORM_LABEL_DESCRIPTION,
    FORM_LABEL_EDITOR_CODE_1,
    FORM_LABEL_EDITOR_CODE_2, FORM_LABEL_EDITOR_DESCRIPTION, FORM_LABEL_EDITOR_LINE, FORM_LABEL_EDITOR_ORDER,
    FORM_LABEL_EDITOR_ORDER_REPLY, FORM_LABEL_EDITOR_PART_NO, FORM_LABEL_EDITOR_QUANTITY,
    FORM_LABEL_ITEM_NUMBER,
    FORM_LABEL_LINE, FORM_LABEL_MANAGEMENT_GROUP, FORM_LABEL_MMOBILIZED_VEHICLE,
    FORM_LABEL_ORDER,
    FORM_LABEL_PAGE,
    FORM_LABEL_PART_NO,
    FORM_LABEL_PR,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_PROBLEM_TYPE,
    FORM_LABEL_PVI_RELATED,
    FORM_LABEL_QUANTITY, FORM_PROBLEM_DESCRIPTION_SECTION,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {InputFieldPage} from '../../../../../common/InputFieldPage';
import {InputGroupCheckbox} from '../../../../../common/InputGroupCheckbox';
import {
    StyledProblemDescTh,
} from '../../../../../common/StyledComponents';
import {
    positiveNumber,
    positiveNumberWithZero,
    validAlphanumericInput,
    validPartNoLength
} from '../../../../../common/validationCommons';
import {moduleRoles} from '../../../../../../utils/roles';
import formMessages from '../../../../../../intl/technicalDocumentations/formMessages';
import immobilizedVehicleMessages from '../../../../../../intl/technicalDocumentations/immobilizedVehicleMessages';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import get from 'get-value';
import {immobilizedVehicle, problemTypes} from '../../../../../../constants/Utils';
import problemTypeMessages from '../../../../../../intl/technicalDocumentations/problemTypeMessages';
import PropTypes from 'prop-types';
import InternalTicketReferences from './InternalTicketReferences';
import {PrimaryButton} from '../../../../../common/Button';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import {bindActionCreators} from 'redux';
import {
    fetchDicPartInfoActionCreator,
    setDicTechDocInfoActionCreator
} from '../../../../../../actions/techDoc/actionTechDocDicData';
import warnMessages from '../../../../../../intl/common/warnMessages';
import {setDicSparePartInfoActionCreator} from '../../../../../../actions/orders/actionSparePartsDicData';
import {isEmpty, size} from 'lodash';
import {warnAlert} from '../../../../../../actions/alertsActions';

const StyledDiv = styled.div`
  border-radius: 5px;
`;
const StyledBorderDiv = styled.div`
  border-color: #ced4da;
`;
export const StyledHeaderThead = styled.thead`
  &&{background-color: #e9ecef;};
`;
export const StyledColumnHeaderThead = styled.thead`
  &&{background-color: #f8f9fa;};
`;

class ProblemDescriptionCodedPartsSection extends Component {
    handlePartLoad = () => {
        const {fetchDicPartInfoActionCreator, partNo, warnAlert } = this.props;
        if(partNo.length == 10) {
            fetchDicPartInfoActionCreator(partNo);
        } else {
            warnAlert(warnMessages.TD_DIC_WARN005)
        }
    };

    fillDicPartInfo = () => {
        const {dicInfo, change, setDicTechDocInfoActionCreator, warnAlert, userRights} = this.props;
        const part = dicInfo.partNo;
        setDicTechDocInfoActionCreator();
        if (part) {
            if (!part[FORM_FIELD_MANAGEMENT_GROUP]) {
                change(`${FORM_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_MANAGEMENT_GROUP}`, dicInfo.managementGroup);
            } else {
                warnAlert(warnMessages.TD_DIC_WARN004, null, {partNumber: dicInfo.partNo})
            }
        } else {
            warnAlert(warnMessages.TD_DIC_WARN003, null, {partNumber: dicInfo.partNo});
        }
    };

render() {
    const {readOnly, userRights, roles, reqFields, initialValues, intl: {formatMessage}, user, ticketData, form, dicInfo, solutionGroupInfo, submittedBy, partNo} = this.props;

    const isEditor = moduleRoles.isEditorDocumentation(roles);
    const isSgEditor = moduleRoles.isSGEditorDocumentation(roles);

    if (dicInfo.dicPartInfoLoaded) {
        this.fillDicPartInfo();
    }

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.PROBLEM_DESCRIPTION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-lg-5 col-md-8 pb-3">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_PROBLEM_TYPE]}
                                                        field={FORM_FIELD_PROBLEM_TYPE}
                                                        options={get(problemTypes, `${get(initialValues, 'ticketSection.group', {default: 'X'})}`, {default: []})}
                                                        intlMessages={problemTypeMessages}
                                                        sort={null}
                                                        isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                        isRequired={reqFields.includes(FORM_FIELD_PROBLEM_TYPE)}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <StyledBorderDiv className="table-responsive border rounded-top">
                            <table className="table table-hover mb-0">
                                <StyledHeaderThead>
                                    <tr>
                                        <StyledProblemDescTh colSpan="6" className="font-weight-bold py-0">
                                            <ValidFormattedMessage message={FORM_LABEL_DEALER_ORDER}
                                                                   intlMessages={formMessages}/>
                                        </StyledProblemDescTh>
                                    </tr>
                                </StyledHeaderThead>
                                <StyledColumnHeaderThead>
                                    <tr>
                                        <StyledProblemDescTh>
                                            <ValidFormattedMessage message={FORM_LABEL_ORDER}
                                                                   intlMessages={formMessages}/>
                                        </StyledProblemDescTh>
                                        <StyledProblemDescTh>
                                            <ValidFormattedMessage message={FORM_LABEL_LINE}
                                                                   intlMessages={formMessages}/>
                                        </StyledProblemDescTh>
                                        <StyledProblemDescTh>
                                            <ValidFormattedMessage message={FORM_LABEL_PART_NO}
                                                                   intlMessages={formMessages}/>
                                        </StyledProblemDescTh>
                                        <StyledProblemDescTh>
                                            <ValidFormattedMessage message={FORM_LABEL_DESCRIPTION}
                                                                   intlMessages={formMessages}/>
                                        </StyledProblemDescTh>
                                        <StyledProblemDescTh>
                                            <ValidFormattedMessage message={FORM_LABEL_QUANTITY}
                                                                   intlMessages={formMessages}/>
                                        </StyledProblemDescTh>
                                        <StyledProblemDescTh>
                                            <ValidFormattedMessage message={FORM_LABEL_CODE}
                                                                   intlMessages={formMessages}/>
                                        </StyledProblemDescTh>
                                    </tr>
                                </StyledColumnHeaderThead>
                                <tbody>
                                <tr>
                                    <td style={{maxWidth: '130px', minWidth: '100px'}}>
                                        <InputFieldWithValidation field={FORM_FIELD_ORDER}
                                                                  maxLength={(user.group === "AR" ? 10 : 7)}
                                                                  validations={[positiveNumber]}
                                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                                  isRequired={reqFields.includes(FORM_FIELD_ORDER)}
                                                                  isSmall/>
                                    </td>
                                    <td style={{maxWidth: '130px', minWidth: '100px'}}>
                                        <InputFieldWithValidation field={FORM_FIELD_LINE}
                                                                  maxLength={6}
                                                                  validations={[positiveNumberWithZero]}
                                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                                  isRequired={reqFields.includes(FORM_FIELD_LINE)}
                                                                  isSmall/>
                                    </td>
                                    <td style={{maxWidth: '200px', minWidth: '130px'}}>
                                        <InputFieldWithValidation field={FORM_FIELD_PART_NO}
                                                                  maxLength={10}
                                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                                  isRequired={reqFields.includes(FORM_FIELD_PART_NO)}
                                                                  isSmall/>
                                    </td>
                                    <td style={{minWidth: '130px'}}>
                                        <InputFieldWithValidation field={FORM_FIELD_DESCRIPTION}
                                                                  maxLength={17}
                                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                                  isRequired={reqFields.includes(FORM_FIELD_DESCRIPTION)}
                                                                  isSmall/>
                                    </td>
                                    <td style={{maxWidth: '100px', minWidth: '100px'}}>
                                        <InputFieldWithValidation field={FORM_FIELD_QUANTITY}
                                                                  maxLength={5}
                                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                                  isRequired={reqFields.includes(FORM_FIELD_QUANTITY)}
                                                                  validations={[positiveNumber]}
                                                                  isSmall/>
                                    </td>
                                    <td style={{maxWidth: '120px', minWidth: '150px'}}>
                                        <InputFieldWithValidation field={FORM_FIELD_CODE}
                                                                  maxLength={20}
                                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                                  isRequired={reqFields.includes(FORM_FIELD_CODE)}
                                                                  isSmall/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </StyledBorderDiv>
                    </div>
                </div>
                { moduleRoles.isSGEditorDocumentation(roles) &&
                <div className="form-row">
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 pb-2">
                        <div className="form-row align-items-end">
                                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                                    <InputFieldWithValidation label={formMessages[FORM_LABEL_MANAGEMENT_GROUP]}
                                                              field={FORM_FIELD_MANAGEMENT_GROUP}
                                                              maxLength={2}
                                                              isDisabled={!userRights.canEditManagementGroup || readOnly}
                                                              isRequired={(isEmpty(solutionGroupInfo) ? (reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP) && size(partNo) == 10) : (reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP)  && size(partNo) == 10 && !get(solutionGroupInfo, "groups", []).length == 0)) && submittedBy === FORM_BUTTON_ESCALATE && !readOnly}
                                                              validations={[validAlphanumericInput]}
                                                              isSmall/>
                                </div>
                            {userRights.canEditManagementGroup && !readOnly &&
                            <>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 pt-1">
                                    <PrimaryButton type="button" className="btn"
                                                   disabled={size(partNo) < 10}
                                                   onClick={this.handlePartLoad}
                                    >
                                        <ValidFormattedMessage message={BUTTON_LABEL_LOAD}
                                                               intlMessages={buttonMessages}/>
                                    </PrimaryButton>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
                }
                {userRights.canViewEditorReply && <>
                    <div className="form-row">
                        <div className="col">
                            <StyledBorderDiv className="table-responsive border">
                                <table className="table table-hover mb-0">
                                    <StyledHeaderThead>
                                        <tr>
                                            <StyledProblemDescTh colSpan="6" className="font-weight-bold">
                                                <ValidFormattedMessage message={FORM_LABEL_EDITOR_ORDER_REPLY}
                                                                       intlMessages={formMessages}/>
                                            </StyledProblemDescTh>
                                        </tr>
                                    </StyledHeaderThead>
                                    <StyledColumnHeaderThead>
                                        <tr>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_EDITOR_ORDER}
                                                                       intlMessages={formMessages}/>
                                            </StyledProblemDescTh>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_EDITOR_LINE}
                                                                       intlMessages={formMessages}/>
                                            </StyledProblemDescTh>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_EDITOR_PART_NO}
                                                                       intlMessages={formMessages}/>
                                            </StyledProblemDescTh>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_EDITOR_DESCRIPTION}
                                                                       intlMessages={formMessages}/>
                                            </StyledProblemDescTh>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_EDITOR_QUANTITY}
                                                                       intlMessages={formMessages}/>
                                            </StyledProblemDescTh>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_EDITOR_CODE_1}
                                                                       intlMessages={formMessages}/>
                                            </StyledProblemDescTh>
                                        </tr>
                                    </StyledColumnHeaderThead>
                                    <tbody>
                                    <tr>
                                        <td style={{maxWidth: '130px', minWidth: '100px'}}>
                                            <InputFieldWithValidation field={FORM_FIELD_EDITOR_ORDER}
                                                                      maxLength={(ticketData.ticket.group === "AR" ? 10 : 7)}
                                                                      isDisabled={!userRights.canEditProblemDesc || (!isEditor && !isSgEditor) || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_EDITOR_ORDER)}
                                                                      validations={[positiveNumber]}
                                                                      isSmall/>
                                        </td>
                                        <td style={{maxWidth: '130px', minWidth: '100px'}}>
                                            <InputFieldWithValidation field={FORM_FIELD_EDITOR_LINE}
                                                                      maxLength={6}
                                                                      isDisabled={!userRights.canEditProblemDesc || (!isEditor && !isSgEditor) || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_EDITOR_LINE)}
                                                                      validations={[positiveNumber]}
                                                                      isSmall/>
                                        </td>
                                        <td style={{maxWidth: '200px', minWidth: '130px'}}>
                                            <InputFieldWithValidation field={FORM_FIELD_EDITOR_PART_NO}
                                                                      maxLength={10}
                                                                      validations={[validPartNoLength]}
                                                                      isDisabled={!userRights.canEditProblemDesc || (!isEditor && !isSgEditor) || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_EDITOR_PART_NO)}
                                                                      isSmall/>
                                        </td>
                                        <td style={{minWidth: '130px'}}>
                                            <InputFieldWithValidation field={FORM_FIELD_EDITOR_DESCRIPTION}
                                                                      maxLength={17}
                                                                      isDisabled={!userRights.canEditProblemDesc || (!isEditor && !isSgEditor) || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_EDITOR_DESCRIPTION)}
                                                                      isSmall/>
                                        </td>
                                        <td style={{maxWidth: '100px', minWidth: '100px'}}>
                                            <InputFieldWithValidation field={FORM_FIELD_EDITOR_QUANTITY}
                                                                      maxLength={5}
                                                                      isDisabled={!userRights.canEditProblemDesc || (!isEditor && !isSgEditor) || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_EDITOR_QUANTITY)}
                                                                      validations={[positiveNumber]}
                                                                      isSmall/>
                                        </td>
                                        <td style={{maxWidth: '120px', minWidth: '150px'}}>
                                            <InputFieldWithValidation field={FORM_FIELD_EDITOR_CODE_1}
                                                                      maxLength={20}
                                                                      isDisabled={!userRights.canEditProblemDesc || (!isEditor && !isSgEditor) || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_EDITOR_CODE_1)}
                                                                      isSmall/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </StyledBorderDiv>
                        </div>
                    </div>
                    <div className="form-row mb-3">
                        <div className="col">
                            <StyledBorderDiv className="table-responsive border rounded-bottom">
                                <table className="table table-hover mb-0">
                                    <StyledColumnHeaderThead>
                                        <tr>
                                            <StyledProblemDescTh colSpan="5"/>
                                            <StyledProblemDescTh>
                                                <ValidFormattedMessage message={FORM_LABEL_EDITOR_CODE_2}
                                                                       intlMessages={formMessages}/>
                                            </StyledProblemDescTh>
                                        </tr>
                                    </StyledColumnHeaderThead>
                                    <tbody>
                                    <tr>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td style={{maxWidth: '120px', minWidth: '150px'}}>
                                            <InputFieldWithValidation field={FORM_FIELD_EDITOR_CODE_2}
                                                                      maxLength={20}
                                                                      isDisabled={!userRights.canEditProblemDesc || (!isEditor && !isSgEditor) || readOnly}
                                                                      isRequired={reqFields.includes(FORM_FIELD_EDITOR_CODE_2)}
                                                                      isSmall/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </StyledBorderDiv>
                        </div>
                    </div>
                </>
                }
                <div className="form-row">
                    <div className="col-xl-2 col-lg-3 col-md-4 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_PR]}
                                                  field={FORM_FIELD_PR}
                                                  maxLength={5}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_PR)}
                                                  validations={[validAlphanumericInput]}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-8 pb-1">
                        <InputFieldPage label={formMessages[FORM_LABEL_PAGE]}
                                        fields={[FORM_FIELD_PAGE_PREF, FORM_FIELD_PAGE_POST]}
                                        isDisabled={!userRights.canEditProblemDesc || readOnly}
                                        isRequired={reqFields.includes(FORM_FIELD_PAGE_PREF) || reqFields.includes(FORM_FIELD_PAGE_POST)}
                                        validations={[positiveNumberWithZero]}
                        />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-8 pb-1">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_ITEM_NUMBER]}
                                                  field={FORM_FIELD_ITEM_NUMBER}
                                                  maxLength={2}
                                                  isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_ITEM_NUMBER)}
                                                  validations={[positiveNumber]}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-12 pr-0 pb-1">
                        <TextareaFieldWithValidation label={formMessages[FORM_LABEL_PROBLEM_DESCRIPTION]}
                                                     field={FORM_FIELD_PROBLEM_DESCRIPTION}
                                                     isDisabled={!userRights.canEditProblemDescText || readOnly}
                                                     isRequired={reqFields.includes(FORM_FIELD_PROBLEM_DESCRIPTION)}
                                                     rows={6}/>
                    </div>
                </div>
                <div className="form-row pb-2">
                    <div className="col-xl-3 col-lg-6">
                        <InputGroupCheckbox label={formMessages[FORM_LABEL_CLAIM_RELATED]}
                                            fields={[FORM_CHECKBOX_CLAIM_RELATED, FORM_FIELD_CLAIM_RELATED]}
                                            maxLength={8}
                                            isDisabled={!userRights.canEditProblemDesc || readOnly}
                                            isRequired={reqFields.includes(FORM_CHECKBOX_CLAIM_RELATED) || reqFields.includes(FORM_FIELD_CLAIM_RELATED)}
                                            placeholder={formatMessage(formMessages.CLAIM_RELATED_NO)}/>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <InputGroupCheckbox label={formMessages[FORM_LABEL_PVI_RELATED]}
                                            fields={[FORM_CHECKBOX_PVI_RELATED, FORM_FIELD_PVI_RELATED]}
                                            maxLength={8}
                                            isDisabled={!userRights.canEditProblemDesc || readOnly}
                                            isRequired={reqFields.includes(FORM_CHECKBOX_PVI_RELATED) || reqFields.includes(FORM_FIELD_PVI_RELATED)}
                                            placeholder={formatMessage(formMessages.PVI_RELATED_NO)}/>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_MMOBILIZED_VEHICLE]}
                                                        field={FORM_FIELD_IMMOBILIZED_VEHICLE}
                                                        options={immobilizedVehicle}
                                                        intlMessages={immobilizedVehicleMessages}
                                                        isDisabled={!userRights.canEditImmobilizedVehicle || readOnly}
                                                        isRequired={reqFields.includes(FORM_FIELD_PROBLEM_TYPE)}
                                                        noEmpty
                        />
                    </div>
                </div>
                {userRights.canViewInternalTicketReferences && <InternalTicketReferences form={form} />}
            </StyledDiv>
        </>
    )
    }
}

const mapStateToProps = (state, initialProps) => ({
    roles: state.profile.userDetail.roles,
    initialValues: getFormInitialValues(initialProps.form)(state),
    user: state.profile.userDetail,
    ticketData: state.techDocTicket,
    partNo: formValueSelector(initialProps.form)(state, `${FORM_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_PART_NO}`),
    dicInfo: state.techDocDic,
    solutionGroupInfo: state.techDocTicket.solutionGroupInfo,
    submittedBy: state.techDocTicket.submittedBy,
});


const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            setDicTechDocInfoActionCreator,
            fetchDicPartInfoActionCreator,
            warnAlert
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProblemDescriptionCodedPartsSection));
