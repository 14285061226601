import {
    CONNECT_ADMIN_WEBSOCKET_ERROR,
    CONNECT_ADMIN_WEBSOCKET_PENDING,
    CONNECT_ADMIN_WEBSOCKET_SUCCESS,
    CONNECT_BVM_WEBSOCKET_ERROR,
    CONNECT_BVM_WEBSOCKET_PENDING,
    CONNECT_BVM_WEBSOCKET_SUCCESS,
    CONNECT_SPARE_PARTS_WEBSOCKET_ERROR,
    CONNECT_SPARE_PARTS_WEBSOCKET_PENDING,
    CONNECT_SPARE_PARTS_WEBSOCKET_SUCCESS,
    CONNECT_TECH_DOC_WEBSOCKET_ERROR,
    CONNECT_TECH_DOC_WEBSOCKET_PENDING,
    CONNECT_TECH_DOC_WEBSOCKET_SUCCESS,
    CONNECT_CLAIMS_WEBSOCKET_ERROR,
    CONNECT_CLAIMS_WEBSOCKET_PENDING,
    CONNECT_CLAIMS_WEBSOCKET_SUCCESS,
} from '../actions/actionWebsocket';
import {cloneDeep} from 'lodash';
import {modules} from '../constants/Utils';

export const websocket = (state = {
    isConnected: {},
}, action) => {
    switch (action.type) {
        case CONNECT_SPARE_PARTS_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.SPARE_PARTS] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_SPARE_PARTS_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.SPARE_PARTS] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_SPARE_PARTS_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.SPARE_PARTS] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_TECH_DOC_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.TECHNICAL_DOCUMENTATION] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_TECH_DOC_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.TECHNICAL_DOCUMENTATION] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_TECH_DOC_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.TECHNICAL_DOCUMENTATION] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_CLAIMS_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.CLAIMS] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_CLAIMS_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.CLAIMS] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_CLAIMS_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.CLAIMS] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_ADMIN_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.ADMINISTRATION] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_ADMIN_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.ADMINISTRATION] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_ADMIN_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.ADMINISTRATION] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_BVM_WEBSOCKET_PENDING: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.BVM] = false;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_BVM_WEBSOCKET_SUCCESS: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.BVM] = true;
            return Object.assign({}, state, {isConnected});
        }
        case CONNECT_BVM_WEBSOCKET_ERROR: {
            const isConnected = cloneDeep(state.isConnected);
            isConnected[modules.BVM] = false;
            return Object.assign({}, state, {isConnected});
        }
        default:
            return state;
    }
};
