import React from 'react';
import {reduxForm, FormSection} from 'redux-form'
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {scrollContentWrapperToTop} from '../../../../utils/utils';
import {claimsFieldLabelMapping} from '../../../../constants/claimsFieldLabelMapping';
import StockDeadClaimSection from './sections/problemDescription/StockDeadClaimSection';
import TicketStatusHistory from '../../../common/TicketStatusHistory';
import TicketSection from './sections/ticket/TicketSection';
import MessagesSection from './sections/messages/MessagesSection';
import EditorsNotesSection from './sections/editorsNotes/EditorsNotesSection';
import RenderSyncErrors from '../../../common/RenderSyncErrors';
import AttachmentSection from './sections/attachments/AttachmentSection';
import SubmitButtons from './sections/SubmitButtons';
import formMessages from '../../../../intl/claims/formMessages';
import {
    FORM_ATTACHMENTS_SECTION, FORM_EDITORS_NOTES_SECTION,
    FORM_MESSAGES_SECTION,
    FORM_PROBLEM_DESCRIPTION_SECTION,
    FORM_TICKET_SECTION
} from '../../../../constants/formConstants';

const StyledDiv = styled.div`
  border-radius: 10px;
`;

const StockDeadClaimMasterForm = (props) => {
    const {form, userRights, readOnly, handleSubmit, reqFields, editMode} = props;

    return (
        <StyledDiv className="border border-dark py-2 px-4 bg-light">
            <RenderSyncErrors form={form} intlMessages={formMessages} mapping={claimsFieldLabelMapping}/>
            <FormSection name={FORM_TICKET_SECTION}>
                <TicketSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_PROBLEM_DESCRIPTION_SECTION}>
                <StockDeadClaimSection form={form} userRights={userRights} reqFields={reqFields}
                                       readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_MESSAGES_SECTION}>
                <MessagesSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_EDITORS_NOTES_SECTION}>
                <EditorsNotesSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_ATTACHMENTS_SECTION}>
                <AttachmentSection form={form} userRights={userRights} readOnly={readOnly}/>
            </FormSection>
            <TicketStatusHistory form={form}/>
            <SubmitButtons form={form} onSubmit={handleSubmit} onSubmitFail={scrollContentWrapperToTop}
                           userRights={userRights} readOnly={readOnly} editMode={editMode} />
        </StyledDiv>
    )
}

StockDeadClaimMasterForm.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
};

export default reduxForm({})(StockDeadClaimMasterForm);
