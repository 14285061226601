import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    LEFT: {
        id: 'spareParts.steeringWheel.option.left',
        defaultMessage: 'Left'
    },
    RIGHT: {
        id: 'spareParts.steeringWheel.option.right',
        defaultMessage: 'Right'
    },
    OTHER: {
        id: 'spareParts.steeringWheel.option.other',
        defaultMessage: 'Other'
    },
});

export default messages;
