import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import 'react-table/react-table.css';
import {FormattedMessage, injectIntl} from 'react-intl';
import v4 from 'uuid/v4';
import {cloneDeep} from 'lodash';
import {debounce} from 'throttle-debounce';
import solutionGroupMessages from '../../../intl/admin/solutionGroupMessages';
import Table from '../../common/table/Table';
import {BoldPrimaryButton, IconDelete, PrimaryButton, PrimaryIcon, SecondaryButton} from '../../common/Button';
import {
    addSolutionGroup,
    editSolutionGroupName,
    editSolutionGroupUsers,
    fetchSolutionGroupListPageData,
    removeSolutionGroup,
    resetSolutionGroupChanges
} from '../../../actions/actionSolutionGroup';
import {handleFetchUsersError} from '../../../actions/actionRole';
import {FieldWithValidation, AsyncSelectField, SwitchableField} from '../../common/FormField';
import {required} from '../../common/validationCommons';
import RouteLeavingGuard from '../../common/modal/RouteLeavingGuard';
import ConfirmationDialog from '../../common/modal/ConfirmationDialog';
import {DEFAULT_DEBOUNCE_TIMEOUT} from '../../../constants/validationConstants'
import {extractUserLabelForSelectionOption, handleUserSearch, mapUserToSelectionOption} from '../../../utils/userUtils';
import buttonMessages from "../../../intl/common/buttonMessages";

class SolutionGroupTableForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataAsArray: [],
            activeSolutionGroupId: { forNameChange: null, forUsersChange: null },
            isOpenDeleteDialog: false,
            solutionGroupIdToBeDeleted: null
        };
    }

    componentDidMount() {
        this.setState({
            dataAsArray: Object.values(cloneDeep(this.props.initialValues))
                .map(solutionGroup => {
                    solutionGroup.users = solutionGroup.users.map(mapUserToSelectionOption);
                    return solutionGroup;
                })
                .sort((solutionGroup1, solutionGroup2) =>
                    solutionGroup1.name.localeCompare(solutionGroup2.name))
        });
    }

    componentWillUnmount() {
        this.resetChanges();
    }

    handleAddClick = () => {
        const id = v4();
        const newSolutionGroup = { id: id, name: '', users: [] };
        this.props.addSolutionGroup(cloneDeep(newSolutionGroup));
        this.state.dataAsArray.unshift(newSolutionGroup);
        this.setState({ activeSolutionGroupId: { forNameChange: id } });
        this.props.change(`${id}.users`, []);
    };

    handleCancelClick = () => {
        this.setState({ activeSolutionGroupId: { forNameChange: null, forUsersChange: null } });
        this.resetChanges();
        this.props.reset();
        this.props.fetchSolutionGroupListPageData();
    };

    handleDeleteClick = solutionGroup => {
        this.setState({
            isOpenDeleteDialog: true,
            solutionGroupIdToBeDeleted: solutionGroup.id
        });
    };

    handleNameChange = (newName, solutionGroupId) => {
        this.state.dataAsArray.find(solutionGroup => solutionGroup.id === solutionGroupId).name = newName;
        this.props.editSolutionGroupName(solutionGroupId, newName);
    };

    handleUserSearch = (inputValue, callback) => {
        handleUserSearch(inputValue, callback, this.props.handleFetchUsersError);
    };

    handleUserSelection = (solutionGroupId, options, modification) => {
        const previousUsers = cloneDeep(this.props.formState.values[solutionGroupId].users);
        this.state.dataAsArray.find(solutionGroup => solutionGroup.id === solutionGroupId).users = options;
        const users = new Map();
        options.forEach(({ ipn, name }) => users.set(ipn, { ipn, name }));

        this.props.editSolutionGroupUsers(
            solutionGroupId,
            users,
            modification.action === 'select-option' ? {
                ipn: modification.option.ipn,
                name: modification.option.name
            } : null,
            modification.action === 'remove-value' ? {
                ipn: modification.removedValue.ipn,
                name: modification.removedValue.name
            } : null,
            modification.action === 'clear' ? previousUsers : null
        );
    };

    closeDeleteModal = e => {
        e.preventDefault();
        this.setState({
            isOpenDeleteDialog: false,
            solutionGroupIdToBeDeleted: null
        });
    };

    saveDeleteModal = e => {
        e.preventDefault();
        this.props.removeSolutionGroup(this.state.solutionGroupIdToBeDeleted);
        this.setState({
            dataAsArray: this.state.dataAsArray
                .filter(solutionGroup => solutionGroup.id !== this.state.solutionGroupIdToBeDeleted),
            isOpenDeleteDialog: false,
            solutionGroupIdToBeDeleted: null
        });
    };

    resetChanges = () => {
        this.props.resetSolutionGroupChanges();
    };

    handleSwitchableFieldClick = itemToBeActivated => {
        this.setState({
            activeSolutionGroupId: Object.assign({}, this.state.activeSolutionGroupId, itemToBeActivated)
        });
    };

    formatUsersLabel = value => {
        let result = '';
        value.forEach(user => result += `${extractUserLabelForSelectionOption(user)}, `);
        return result.replace(/,\s$/, '');
    };

    renderName = ({ original: { id } }) => {
        const { intl: { formatMessage } } = this.props;
        if (this.state.activeSolutionGroupId.forNameChange === id) {
            return <Field name={`${id}.name`} component={FieldWithValidation} id={id} type="text"
                          placeholder={formatMessage(solutionGroupMessages.TABLE_NAME)}
                          validate={[required]} autoFocus handleNameChange={this.handleNameChange}/>;
        } else {
            return <Field name={`${id}.name`} component={SwitchableField} type="text" validate={[required]}
                          handleFieldClick={() => this.handleSwitchableFieldClick({ forNameChange: id })}/>;
        }
    };

    renderUsers = ({ original: { id, users } }) => {
        const { intl: { formatMessage } } = this.props;
        if (this.state.activeSolutionGroupId.forUsersChange === id) {
            return <Field name={`${id}.users`} component={AsyncSelectField}
                          placeholder={formatMessage(solutionGroupMessages.TABLE_SELECT_USERS)} isMulti
                          noOptionsMessage={() => formatMessage(solutionGroupMessages.TABLE_SELECT_USERS_SEARCH)}
                          menuPosition="fixed" defaultValue={users}
                          loadOptions={debounce(DEFAULT_DEBOUNCE_TIMEOUT, this.handleUserSearch)}
                          onSelectChange={(options, modification) => this.handleUserSelection(id, options, modification)}/>;
        } else {
            return <Field name={`${id}.users`} component={SwitchableField} type="text"
                          handleFieldClick={() => this.handleSwitchableFieldClick({ forUsersChange: id })}
                          format={this.formatUsersLabel}/>;
        }
    };

    render() {
        const { handleSubmit, invalid, dirty } = this.props;

        return (
            <React.Fragment>
                <RouteLeavingGuard when={dirty}/>
                <form className="form-group" onSubmit={handleSubmit}>
                    <div className="form-row mb-3">
                        <div className="col">
                            <PrimaryButton type="button" className="btn" disabled={invalid}
                                           onClick={this.handleAddClick}>
                                <FormattedMessage {...buttonMessages.ADD}/>
                            </PrimaryButton>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col">
                            <Table
                                columns={[
                                    {
                                        id: 'name',
                                        message: solutionGroupMessages.TABLE_NAME,
                                        Cell: this.renderName
                                    },
                                    {
                                        id: 'users',
                                        message: solutionGroupMessages.TABLE_USERS,
                                        Cell: this.renderUsers
                                    }
                                ]}
                                toolButtons={[
                                    {
                                        handleClick: this.handleDeleteClick,
                                        component: <PrimaryIcon className="btn btn-sm mr-sm-1" title="Delete"
                                                                type="button" key="deleteButton">
                                            <IconDelete/>
                                        </PrimaryIcon>
                                    }
                                ]}
                                data={this.state.dataAsArray}
                                defaultSorting={[{ id: 'name' }]}
                            />
                        </div>
                    </div>
                    {
                        dirty &&
                        <div className="form-row mt-sm-3">
                            <div className="col-sm-6">
                                <SecondaryButton type="button" className="btn float-right"
                                                 onClick={this.handleCancelClick}>
                                    <FormattedMessage {...buttonMessages.CANCEL}/>
                                </SecondaryButton>
                            </div>
                            <div className="col-sm-6">
                                <BoldPrimaryButton type="submit" className="btn" disabled={invalid}>
                                    <FormattedMessage {...buttonMessages.SAVE}/>
                                </BoldPrimaryButton>
                            </div>
                        </div>
                    }
                </form>
                <ConfirmationDialog show={this.state.isOpenDeleteDialog} closeModal={this.closeDeleteModal}
                                    saveModal={this.saveDeleteModal} actionIdentifier="delete the Solution Group"/>
            </React.Fragment>
        );
    }
}

SolutionGroupTableForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    formState: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    addSolutionGroup: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    fetchSolutionGroupListPageData: PropTypes.func.isRequired,
    removeSolutionGroup: PropTypes.func.isRequired,
    resetSolutionGroupChanges: PropTypes.func.isRequired,
    editSolutionGroupName: PropTypes.func.isRequired,
    editSolutionGroupUsers: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    handleFetchUsersError: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    initialValues: state.solutionGroupReducer.listData,
    formState: state.form.solutionGroupTableForm
});

export default connect(mapStateToProps, {
    addSolutionGroup,
    fetchSolutionGroupListPageData,
    removeSolutionGroup,
    resetSolutionGroupChanges,
    editSolutionGroupName,
    editSolutionGroupUsers,
    handleFetchUsersError
})(reduxForm({
    form: 'solutionGroupTableForm'
})(injectIntl(SolutionGroupTableForm)));
