import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';
import styled from 'styled-components';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {
    validBetweenFiveAndEightCharLength,
    validFiveCharLength,
    validPartNoLength,
    validSixCharLength,
    validSixToTenCharLength
} from '../../../../../common/validationCommons';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import formMessages from '../../../../../../intl/claims/formMessages';
import {
    FORM_FIELD_CLAIM_RELATED,
    FORM_FIELD_INVOICE,
    FORM_FIELD_MISSING_PACKAGE,
    FORM_FIELD_PART_NO,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_WRONG_DESTINATION,
    FORM_LABEL_CLAIM_RELATED,
    FORM_LABEL_INVOICE,
    FORM_LABEL_MISSING_PACKAGE,
    FORM_LABEL_PART_NO,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_WRONG_DESTINATION,
    FORM_PROBLEM_DESCRIPTION_SECTION,
} from '../../../../../../constants/formConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

class ContestClaimStatusSection extends Component {

    render() {
        const {readOnly, userRights, reqFields, claimsRelated, missingPackage, wrongDestination} = this.props;

        let newReqFields = ['claimRelatedNo', 'missingPackageNo', 'wrongDestinationNo']
        if (claimsRelated || missingPackage || wrongDestination) {
            if (claimsRelated) {
                newReqFields = ['claimRelatedNo']
            } else if (missingPackage) {
                newReqFields = ['missingPackageNo']
            } else if (wrongDestination) {
                newReqFields = ['wrongDestinationNo']
            }
        }

        return (
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.PROBLEM_DESCRIPTION_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                    <div className="form-row pb-2">
                        <div className="col-xl-3 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_CLAIM_RELATED]}
                                                      field={FORM_FIELD_CLAIM_RELATED}
                                                      maxLength={8}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={newReqFields.includes(FORM_FIELD_CLAIM_RELATED)}
                                                      validations={[validBetweenFiveAndEightCharLength]}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_INVOICE]}
                                                      field={FORM_FIELD_INVOICE}
                                                      maxLength={6}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_INVOICE)}
                                                      validations={[validSixCharLength]}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_PART_NO]}
                                                      field={FORM_FIELD_PART_NO}
                                                      maxLength={10}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      validations={[validPartNoLength]}
                                                      isSmall/>
                        </div>
                    </div>
                    <div className="form-row pb-2">
                        <div className="col-xl-4 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_MISSING_PACKAGE]}
                                                      field={FORM_FIELD_MISSING_PACKAGE}
                                                      maxLength={10}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={newReqFields.includes(FORM_FIELD_MISSING_PACKAGE)}
                                                      validations={[validSixToTenCharLength]}
                                                      isSmall/>
                        </div>
                        <div className="col-xl-4 col-lg-6">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_WRONG_DESTINATION]}
                                                      field={FORM_FIELD_WRONG_DESTINATION}
                                                      maxLength={10}
                                                      isDisabled={!userRights.canEditProblemDesc || readOnly}
                                                      isRequired={newReqFields.includes(FORM_FIELD_WRONG_DESTINATION)}
                                                      validations={[validSixToTenCharLength]}
                                                      isSmall/>
                        </div>
                    </div>
                    <div className="form-row pb-2">
                        <div className="col-12">
                            <TextareaFieldWithValidation label={formMessages[FORM_LABEL_PROBLEM_DESCRIPTION]}
                                                         field={FORM_FIELD_PROBLEM_DESCRIPTION}
                                                         isDisabled={!userRights.canEditProblemDescText || readOnly}
                                                         rows={6}
                                                         isRequired={reqFields.includes(FORM_FIELD_PROBLEM_DESCRIPTION)}/>
                        </div>
                    </div>
                </StyledDiv>
            </>
        )
    }
}

const mapStateToProps = (state, props) => ({
    claimsRelated: formValueSelector(props.form)(state, `${FORM_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_CLAIM_RELATED}`),
    missingPackage: formValueSelector(props.form)(state, `${FORM_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_MISSING_PACKAGE}`),
    wrongDestination: formValueSelector(props.form)(state, `${FORM_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_WRONG_DESTINATION}`),
});

export default connect(mapStateToProps, null)(ContestClaimStatusSection);
