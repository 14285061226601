const winston = require('winston');
const bowser = require('bowser');
const get = require('get-value');
const uuid = require('uuid/v4');

const bowserInfo = bowser.getParser(window.navigator.userAgent);

const logger = winston.createLogger({
    transports: [
        new winston.transports.Console({
            level: 'error'
        })
    ]
});

const allowHttpsLogging = () => {
    let sessionId, browserId;
    if (sessionStorage.hasOwnProperty('dog.session')) {
        sessionId = JSON.parse(sessionStorage.getItem('dog.session')) || '';
    } else {
        sessionId = uuid();
        sessionStorage.setItem('dog.session', JSON.stringify(sessionId));
    }
    if (localStorage.hasOwnProperty('dog.browser')) {
        browserId = JSON.parse(localStorage.getItem('dog.browser')) || '';
    } else {
        browserId = uuid();
        localStorage.setItem('dog.browser', JSON.stringify(browserId));
    }

    const httpsTransport = new winston.transports.Http({
        format: winston.format.combine(
            winston.format.timestamp({format: 'YYYY/MM/DD HH:mm:ss'}),
            winston.format.label({label: {info: get(bowserInfo, 'parsedResult') || {}, correlationId: sessionId, browserId: browserId}})
        ),
        level: 'info',
        ssl: true,
        host: (process.env.REACT_APP_BE_HTTP_URL || '').replace('https://', ''),
        path: '/logging/log',
        headers: {'X-Api-Key': process.env.REACT_APP_BE_API_KEY}
    });

    logger.add(httpsTransport);
};

module.exports = {logger, allowHttpsLogging};
