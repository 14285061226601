import {put, takeEvery, select} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import get from 'get-value';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {SPARE_PARTS_INTERNAL_TICKET_RESET} from '../../actions/orders/actionSparePartsInternalTicket';

export function* handleTicketError(action) {
    const stateCorrelationId = get(yield select(state => state.sparePartsInternalTicket), `correlationId`, {default: undefined});
    const {correlationId} = action.payload;
    if (correlationId < stateCorrelationId) {
        return;
    }
    yield put(push('/order/internalTicket/list'));
    yield put({type: SPARE_PARTS_INTERNAL_TICKET_RESET});
}

function* sparePartsInternalTicketSaga() {
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_INTERNAL_TICKET_UPDATE_ERROR, handleTicketError);
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_INTERNAL_TICKET_CREATE_ERROR, handleTicketError);
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_INTERNAL_TICKET_SEND_ERROR, handleTicketError);
}

export default sparePartsInternalTicketSaga;
