import {DIGIT_STRING_VALIDATION_REGEX} from "../../constants/validationConstants";

export const onlyDigits = value => DIGIT_STRING_VALIDATION_REGEX.test(value) || value === null ? value : undefined;

export const normalizeTicketNumber = value => {
    if (!value) {
        return value;
    }
    const digitsOnly = value.replace(/[^\d]/g, '');
    if (digitsOnly.length <= 2) {
        return digitsOnly;
    }
    if (digitsOnly.length <= 9) {
        return `${digitsOnly.slice(0, 2)}-${digitsOnly.slice(2)}`;
    }
};
