export function extractErrorText(error = {}, message = '') {
    const {config = {}, response = {}} = error;
    const {method = null, path = null, url = null} = config;
    const {status = null, statusText = null, data = null} = response;
    const errorDetails = {};
    if (Object.keys(config).length > 0 && (method || path || url)) {
        Object.assign(errorDetails, {
            method: transformLongText(method),
            path: transformLongText(path),
            url: transformLongText(url)
        });
    }
    if (Object.keys(response).length > 0 && (status || statusText || data)) {
        Object.assign(errorDetails, {
            status: transformLongText(status),
            statusText: transformLongText(statusText),
            data: transformLongText(data)
        });
    }
    return [message, errorDetails];
}

function transformLongText(text) {
    const lineLength = 100;

    if (text && text.length > lineLength) {
        return `${text.substring(0, lineLength)}...`;
    } else {
        return text;
    }
}
