import React from 'react';
import {Draggable} from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {PRIMARY_WHITE, PRIMARY_GREY} from '../../../theme/colors';

const DraggableItem = styled.div`
    user-select: none;
    background-color: ${PRIMARY_WHITE};
    border-radius: 0.3em;
    border: 1px solid ${PRIMARY_GREY};
`;

const DragAndDropItem = ({item, index}) => (
    <Draggable draggableId={item.id} index={index}>
        {provided => (
            <DraggableItem ref={provided.innerRef} className="p-sm-3 mb-sm-2"
                           {...provided.draggableProps} {...provided.dragHandleProps}>
                {item.name}
            </DraggableItem>
        )}
    </Draggable>
);

DragAndDropItem.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
};

export default DragAndDropItem;
