import {domains} from '../../constants/Utils';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';
import {errorAlert} from '../alertsActions';
import {validateAdminRoles, validateEditorData, validateDealerRoles} from '../../api/validation/role';
import alertMessages from '../../intl/common/alertMessages';

export const MANAGED_USER = 'MANAGED_USER';
export const INITIAL_ADMIN_COUNTRY_OPTIONS = 'INITIAL_ADMIN_COUNTRY_OPTIONS';
export const SELECTED_ADMIN_COUNTRY_OPTIONS = 'SELECTED_ADMIN_COUNTRY_OPTIONS';
export const ADMIN_COUNTRIES_CHANGED = 'ADMIN_COUNTRIES_CHANGED';
export const ADMIN_RIGHTS_FETCH_PENDING = 'ADMIN_RIGHTS_FETCH_PENDING';
export const ADMIN_RIGHTS_SAVE_PENDING = 'ADMIN_RIGHTS_SAVE_PENDING';
export const ADMIN_RIGHTS_CLEAR = 'ADMIN_RIGHTS_CLEAR';
export const ADMIN_RIGHTS_FETCH_SUCCESS = 'ADMIN_RIGHTS_FETCH_SUCCESS';
export const INITIAL_DEALER_FORM_TYPE_OPTIONS = 'INITIAL_DEALER_FORM_TYPE_OPTIONS';
export const SELECTED_DEALER_FORM_TYPE_OPTIONS = 'SELECTED_DEALER_FORM_TYPE_OPTIONS';
export const DEALER_FORM_TYPES_CHANGED = 'DEALER_FORM_TYPES_CHANGED';
export const DEALER_RIGHTS_FETCH_PENDING = 'DEALER_RIGHTS_FETCH_PENDING';
export const DEALER_RIGHTS_SAVE_PENDING = 'DEALER_RIGHTS_SAVE_PENDING';
export const DEALER_RIGHTS_CLEAR = 'DEALER_RIGHTS_CLEAR';
export const DEALER_RIGHTS_FETCH_SUCCESS = 'DEALER_RIGHTS_FETCH_SUCCESS';
export const INITIAL_EDITOR_COUNTRY_OPTIONS = 'INITIAL_EDITOR_COUNTRY_OPTIONS';
export const SELECTED_EDITOR_COUNTRY_OPTIONS = 'SELECTED_EDITOR_COUNTRY_OPTIONS';
export const EDITOR_COUNTRIES_CHANGED = 'EDITOR_COUNTRIES_CHANGED';
export const EDITOR_RIGHTS_FETCH_PENDING = 'EDITOR_RIGHTS_FETCH_PENDING';
export const EDITOR_RIGHTS_SAVE_PENDING = 'EDITOR_RIGHTS_SAVE_PENDING';
export const EDITOR_RIGHTS_CLEAR = 'EDITOR_RIGHTS_CLEAR';
export const EDITOR_RIGHTS_FETCH_SUCCESS = 'EDITOR_RIGHTS_FETCH_SUCCESS';
export const RIGHTS_ERROR = 'RIGHTS_ERROR';

export const setManagedUser = managedUser => ({
    type: MANAGED_USER,
    payload: managedUser
});
export const setInitialAdminCountryOptions = countryOptions => ({
    type: INITIAL_ADMIN_COUNTRY_OPTIONS,
    payload: countryOptions
});

export const setSelectedAdminCountryOptions = countryOptions => ({
    type: SELECTED_ADMIN_COUNTRY_OPTIONS,
    payload: countryOptions
});

export const setAdminCountriesChanged = adminCountriesChanged => ({
    type: ADMIN_COUNTRIES_CHANGED,
    payload: adminCountriesChanged
});

export const fetchAdminRightsManagementPageData = (user, domain) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_RIGHTS_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_ADMIN_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_RIGHTS_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_ADMIN_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_RIGHTS_FETCH_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_ADMIN_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
    }
};

export const saveAdminRightsManagementPageData = (user, domain, adminData) => dispatch => {
    const {error} = validateAdminRoles(adminData);

    if (error) {
        dispatch({type: RIGHTS_ERROR});
        dispatch(errorAlert(alertMessages.RIGHTS_ADMIN_SEND_ERROR, [error.toString()]));
    } else {
        switch (domain) {
            case domains.SPARE_PARTS: {
                return dispatch({
                    type: ADMIN_RIGHTS_SAVE_PENDING,
                    sparePartsWebsocket: true,
                    command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_ADMIN_ROLE_SET,
                    payload: {role: {admin: {groupPermission: adminData}, id: user.ipn}}
                });
            }
            case domains.TECHNICAL_DOCUMENTATION: {
                return dispatch({
                    type: ADMIN_RIGHTS_SAVE_PENDING,
                    techDocWebsocket: true,
                    command: techDocWebsocketCommands.TECH_DOC_ADMIN_ROLE_SET,
                    payload: {role: {admin: {groupPermission: adminData}, id: user.ipn}}
                });
            }
            case domains.CLAIMS: {
                return dispatch({
                    type: ADMIN_RIGHTS_SAVE_PENDING,
                    claimsWebsocket: true,
                    command: claimsWebsocketCommands.CLAIMS_ADMIN_ROLE_SET,
                    payload: {role: {admin: {groupPermission: adminData}, id: user.ipn}}
                });
            }

        }
    }
};

export const clearAdminRightsManagementPageData = () => ({
    type: ADMIN_RIGHTS_CLEAR
});

export const setInitialDealerFormTypeOptions = formTypeOptions => ({
    type: INITIAL_DEALER_FORM_TYPE_OPTIONS,
    payload: formTypeOptions
});

export const setSelectedDealerFormTypeOptions = formTypeOptions => ({
    type: SELECTED_DEALER_FORM_TYPE_OPTIONS,
    payload: formTypeOptions
});

export const setDealerFormTypesChanged = formTypesChanged => ({
    type: DEALER_FORM_TYPES_CHANGED,
    payload: formTypesChanged
});

export const fetchDealerRightsManagementPageData = (user, domain) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: DEALER_RIGHTS_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DEALER_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: DEALER_RIGHTS_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_DEALER_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: DEALER_RIGHTS_FETCH_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_DEALER_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
    }
};

export const saveDealerRightsManagementPageData = (user, domain, dealerData) => dispatch => {
    const {error} = validateDealerRoles(domain, dealerData);
    if (error) {
        dispatch({type: RIGHTS_ERROR});
        dispatch(errorAlert(alertMessages.RIGHTS_DEALER_SEND_ERROR, [error.toString()]));
    } else {
        switch (domain) {
            case domains.SPARE_PARTS: {
                return dispatch({
                    type: DEALER_RIGHTS_SAVE_PENDING,
                    sparePartsWebsocket: true,
                    command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DEALER_ROLE_SET,
                    payload: {role: {dealer: {formsPermission: dealerData}, id: user.ipn}}
                });
            }
            case domains.TECHNICAL_DOCUMENTATION: {
                return dispatch({
                    type: DEALER_RIGHTS_SAVE_PENDING,
                    techDocWebsocket: true,
                    command: techDocWebsocketCommands.TECH_DOC_DEALER_ROLE_SET,
                    payload: {role: {dealer: {formsPermission: dealerData}, id: user.ipn}}
                });
            }
            case domains.CLAIMS: {
                return dispatch({
                    type: DEALER_RIGHTS_SAVE_PENDING,
                    claimsWebsocket: true,
                    command: claimsWebsocketCommands.CLAIMS_DEALER_ROLE_SET,
                    payload: {role: {dealer: {formsPermission: dealerData}, id: user.ipn}}
                });
            }
        }
    }
};

export const clearDealerRightsManagementPageData = () => ({
    type: DEALER_RIGHTS_CLEAR
});

export const setInitialEditorCountryOptions = countryOptions => ({
    type: INITIAL_EDITOR_COUNTRY_OPTIONS,
    payload: countryOptions
});

export const setSelectedEditorCountryOptions = countryOptions => ({
    type: SELECTED_EDITOR_COUNTRY_OPTIONS,
    payload: countryOptions
});

export const setEditorCountriesChanged = countriesChanged => ({
    type: EDITOR_COUNTRIES_CHANGED,
    payload: countriesChanged
});

export const fetchEditorRightsManagementPageData = (user, domain) => (dispatch) => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: EDITOR_RIGHTS_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_EDITOR_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: EDITOR_RIGHTS_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_EDITOR_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: EDITOR_RIGHTS_FETCH_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_EDITOR_ROLE_GET,
                payload: {role: {ipn: user.ipn}}
            });
        }
    }
};

export const saveEditorRightsManagementPageData = (user, domain, editorData) => dispatch => {
    const {error} = validateEditorData(editorData);

    if (error) {
        dispatch({type: RIGHTS_ERROR});
        dispatch(errorAlert(alertMessages.RIGHTS_EDITOR_SEND_ERROR, [error.toString()]));
    } else {
        switch (domain) {
            case domains.SPARE_PARTS: {
                return dispatch({
                    type: EDITOR_RIGHTS_SAVE_PENDING,
                    sparePartsWebsocket: true,
                    command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_EDITOR_ROLE_SET,
                    payload: {role: {editor: {groupPermission: editorData}, id: user.ipn}}
                });
            }
            case domains.TECHNICAL_DOCUMENTATION: {
                return dispatch({
                    type: EDITOR_RIGHTS_SAVE_PENDING,
                    techDocWebsocket: true,
                    command: techDocWebsocketCommands.TECH_DOC_EDITOR_ROLE_SET,
                    payload: {role: {editor: {groupPermission: editorData}, id: user.ipn}}
                });
            }
            case domains.CLAIMS: {
                return dispatch({
                    type: EDITOR_RIGHTS_SAVE_PENDING,
                    claimsWebsocket: true,
                    command: claimsWebsocketCommands.CLAIMS_EDITOR_ROLE_SET,
                    payload: {role: {editor: {groupPermission: editorData}, id: user.ipn}}
                });
            }
        }
    }
};

export const clearEditorRightsManagementPageData = () => ({
    type: EDITOR_RIGHTS_CLEAR
});
