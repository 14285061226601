import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import {clearAlerts} from '../../../actions/alertsActions';
import ListOrderTable from './ListOrderTable';
import {
    batteryProblemTypes,
    countryGroups,
    domains,
    getDealerFormTypes,
    getEditorFormTypes,
    getFormStates, getSgEditorFormTypesOptions, requestOfCourtesyCarType, srcRequestType
} from '../../../constants/Utils';
import {lockSparePartsOrdersTicket, resetRedirectAfterLockTicket} from '../../../actions/orders/actionLockTicket';
import listMessages from '../../../intl/sparePartsOrders/listMessages';
import commonMessages from '../../../intl/common/commonMessages';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import {
    updateSparePartsUserListPreference
} from '../../../actions/orders/actionSparePartsPreferences';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import {StyledPicky} from '../../common/StyledComponents';
import DatePickerTableComponent from '../../common/datePicker/DatePickerTableComponent';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {fillNumberFiveZero} from '../../../utils/utils';
import {moduleRoles} from '../../../utils/roles';
import batteryProblemTypeMessages from '../../../intl/sparePartsOrders/batteryProblemTypeMessages';
import clientTypeMessages from '../../../intl/technicalDocumentations/clientTypeMessages';

import {
    fetchOrderTypeFilter
} from '../../../actions/orders/actionSparePartsOrderTypeFilter';

const domain = domains.SPARE_PARTS;

class ListOrderPage extends Component {
    constructor(props) {
        super(props);
        const {intl, loggedUser, loggedUser: {roles}} = props;

        const isSgEditor = moduleRoles.isSGEditorOrder(roles);
        const isEditor = !isSgEditor && moduleRoles.isEditorOrder(roles);
        const isDealer = !isSgEditor && !isEditor && moduleRoles.isDealerOrder(roles);

        const managedGroups = get(props, `loggedUser.roles.${domain}.dealer`) ? [get(props, 'loggedUser.group', 'XX')] : moduleRoles.getEditorGroups(domain, get(props, 'loggedUser.roles', {})).map(({group}) => group);
        this.state = {
            ratchet: false,
            columnLibrary: [
                {
                    id: 'created',
                    accessor: 'created',
                    message: listMessages.TABLE_ORDER_CREATION_TIME,
                    Filter: ({filter, onChange}) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'creatorIpn',
                    accessor: 'creatorIpn',
                    message: listMessages.TABLE_CREATED_BY,
                },
                {
                    id: 'clientId',
                    accessor: 'clientId',
                    message: listMessages.TABLE_CLIENT_ID,
                    Cell: e => e.value && String(e.value).padStart(6, "0")
                },
                {
                    id: 'region',
                    accessor: 'region',
                    message: listMessages.TABLE_REGION,
                },
                {
                    id: 'countryCode3',
                    accessor: 'countryCode3',
                    message: listMessages.TABLE_COUNTRY_CODE_3,
                },
                {
                    id: 'dealerNumber',
                    accessor: 'dealerNumber',
                    message: listMessages.TABLE_DEALER_NUMBER,
                    Cell: e => e.value && String(e.value).padStart(8, "0")
                },
                {
                    id: 'orderType',
                    accessor: 'orderType',
                    message: listMessages.TABLE_ORDER_TYPE,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {this.props.isLoadingOrderTypeFilter && <Loader />}
                            {this.props.orderTypeFilter &&
                            this.props.orderTypeFilter.map((orderType, index) => (
                                <option key={index} value={orderType}>
                                    {formTypeMessages[orderType] ? intl.formatMessage(formTypeMessages[orderType]) : orderType}
                                </option>
                            ))
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{formTypeMessages[e.value] ? intl.formatMessage(formTypeMessages[e.value]) : e.value}</div>
                },
                {
                    id: 'requestOfCourtesyCar',
                    accessor: 'requestOfCourtesyCar',
                    message: listMessages.REQUEST_OF_COURTESY_CAR,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {requestOfCourtesyCarType.map((key, index) =>
                                <option key={index} value={key}>
                                    {commonMessages[key] ? intl.formatMessage(commonMessages[key]) : key}
                                </option>)}
                        </select>
                    ),
                    Cell: e =>
                        <div>{commonMessages[e.value] ? intl.formatMessage(commonMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'bin',
                    accessor: 'bin',
                    message: listMessages.TABLE_BIN,
                },
                {
                    id: 'invoiceNumber',
                    accessor: 'invoiceNumber',
                    message: listMessages.TABLE_INVOICE_NUMBER,
                },
                {
                    id: 'partNo',
                    accessor: 'partNo',
                    message: listMessages.TABLE_PART_NO,
                },
                {
                    id: 'managementGroup',
                    accessor: 'managementGroup',
                    message: listMessages.TABLE_MANAGEMENT_GROUP,
                },
                {
                    id: 'batteryProblemType',
                    accessor: 'batteryProblemType',
                    message: listMessages.TABLE_PROBLEM_TYPE,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {[...new Set(managedGroups.reduce((acc, cur) => batteryProblemTypes[cur] ? acc.concat(batteryProblemTypes[cur]) : acc, []))].map((type, index) =>
                                <option key={index} value={type}>
                                    {batteryProblemTypeMessages[type] ? intl.formatMessage(batteryProblemTypeMessages[type]) : type}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{batteryProblemTypeMessages[e.value] ? intl.formatMessage(batteryProblemTypeMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'solutionGroup',
                    accessor: 'solutionGroup',
                    message: listMessages.TABLE_SOLUTION_GROUP,
                    Cell: e =>
                        <div> {e.value && !e.value.canForwardToDealer && isDealer ? '' : get(e, 'value.name', '')}</div>
                },
                {
                    id: 'status',
                    accessor: 'status',
                    message: listMessages.TABLE_STATUS,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {getFormStates(domain).map((key, index) =>
                                <option key={index} value={key}>
                                    {formStatesMessages[key] ? intl.formatMessage(formStatesMessages[key]) : key}
                                </option>)}
                        </select>
                    ),
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'lastUpdate',
                    accessor: 'lastUpdate',
                    message: listMessages.TABLE_LAST_STATUS_DATE,
                    Filter: ({filter, onChange}) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'lastEditor',
                    accessor: 'lastEditor',
                    message: listMessages.TABLE_LAST_EDITOR,
                },
                {
                    id: 'clientType',
                    accessor: 'clientType',
                    message: listMessages.TABLE_TYPE_OF_CLIENT,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {
                                Object.keys(clientTypeMessages).map((key, index) =>
                                    <option key={index} value={key}>
                                        {clientTypeMessages[key] ? intl.formatMessage(clientTypeMessages[key]) : key}
                                    </option>
                                )
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{clientTypeMessages[e.value] ? intl.formatMessage(clientTypeMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'srcRequest',
                    accessor: 'srcRequest',
                    message: listMessages.TABLE_SRC_TYPE,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {srcRequestType.map((key, index) =>
                                <option key={index} value={key}>
                                    {commonMessages[key] ? intl.formatMessage(commonMessages[key]) : key}
                                </option>)}
                        </select>
                    ),
                    Cell: e =>
                        <div>{commonMessages[e.value] ? intl.formatMessage(commonMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'srcClaimN',
                    accessor: 'srcClaimN',
                    message: listMessages.TABLE_SRC_N,
                }
            ],
        };

        if(isDealer) {
            this.state.columnLibrary = this.state.columnLibrary.filter(column => column.id !== 'managementGroup' && column.id !== 'countryCode3');
        }

        if(!managedGroups.includes(countryGroups.IT)) {
            this.state.columnLibrary = this.state.columnLibrary.filter(column => column.id !== 'requestOfCourtesyCar');
        }
    }

    componentDidMount() {
        this.props.fetchOrderTypeFilter();
    }

    componentWillUnmount() {
        this.props.resetRedirectAfterLockTicket();
    }

    handleEditClick = ({uuid}) => {
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockSparePartsOrdersTicket(uuid);
            this.setState({ratchet: true});
        }
    };

    selectMultipleOption = (value) => {
        this.props.updateSparePartsUserListPreference(this.props.loggedUser.ipn,
            this.state.columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    render() {
        const {intl, sparePartsPreferences, lockTicket, location, isLoadingOrderTypeFilter, orderTypeFilter} = this.props;
        const {columnLibrary} = this.state;
        if (lockTicket.redirect && this.state.ratchet) {
            this.props.history.push(`/order/edit/${lockTicket.uuid}`);
        }
        if (sparePartsPreferences.isLoading) {
            return <Loader/>;
        }

        var test = <FormattedMessage {...commonMessages.SELECTED}/>

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...listMessages.TITLE}/>}/>
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span
                            className="font-weight-bold"><FormattedMessage {...listMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={columnLibrary.filter(item => !get(sparePartsPreferences, 'menuList', {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            selectAllText={<FormattedMessage {...commonMessages.SELECT_ALL}/>}
                            manySelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            allSelectedPlaceholder={"%s " + intl.formatMessage(commonMessages.SELECTED)}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <ListOrderTable
                    columns={columnLibrary.filter(item => !get(sparePartsPreferences, 'menuList', {default: []}).includes(item.id))}
                    handleEditClick={this.handleEditClick}
                    location={location}
                />
            </div>
        );
    }
}

ListOrderPage.propTypes = {
    lockTicket: PropTypes.object.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    updateSparePartsUserListPreference: PropTypes.func.isRequired,
    lockSparePartsOrdersTicket: PropTypes.func.isRequired,
    resetRedirectAfterLockTicket: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    sparePartsPreferences: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isLoadingOrderTypeFilter: PropTypes.bool.isRequired,
    orderTypeFilter: PropTypes.array.isRequired,
    fetchOrderTypeFilter: PropTypes.func.isRequired
};


const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    lockTicket: state.sparePartsListLockTicketCheck,
    sparePartsPreferences: state.sparePartsPreferences,
    isLoadingOrderTypeFilter: state.sparePartsOrderTypeFilter.isLoadingOrderTypeFilter,
    orderTypeFilter: state.sparePartsOrderTypeFilter.orderTypeFilter
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateSparePartsUserListPreference,
    lockSparePartsOrdersTicket,
    resetRedirectAfterLockTicket,
    fetchOrderTypeFilter
})(injectIntl(ListOrderPage))), ['SP_EDITOR', 'SP_DEALER', 'SP_SG_EDITOR']);

