import get from 'get-value';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const techDocManagementGroupBatch = (state = {
    isLoadingBatchInfo: false,
    batchRecord: {},
    correlationId: null,
}, action) => {
    switch (action.type) {
        case techDocWebsocketCommands.TECH_DOC_MANAGEMENT_GROUP_GET: {
            return Object.assign({}, state, { isLoadingBatchInfo: true });
        }
        case techDocWebsocketCommands.TECH_DOC_MANAGEMENT_GROUP_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            const batchRecord = get(action, 'payload.batchRecord', { default: {} });
            return Object.assign({}, state, {
                isLoadingBatchInfo: false,
                batchRecord,
                correlationId,
            });
        }
        case techDocWebsocketCommands.TECH_DOC_MANAGEMENT_GROUP_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isLoadingBatchInfo: false });
        }
        case techDocWebsocketCommands.TECH_DOC_MANAGEMENT_GROUP_CREATE: {
            const batchRecord = {
                created: new Date(),
                status: 'NEW'
            };
            return Object.assign({}, state, { batchRecord });
        }
        case techDocWebsocketCommands.TECH_DOC_MANAGEMENT_GROUP_STATUS:
        case techDocWebsocketCommands.TECH_DOC_MANAGEMENT_GROUP_ERROR: {
            const {correlationId, batchRecord} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { batchRecord, correlationId });
        }
        default:
            return state;
    }
};
