import {
    ORDER_POPUP_FETCH_PENDING,
    ORDER_POPUP_MODAL_CONFIRM,
    ORDER_POPUP_MODAL_OPEN
} from '../../actions/orders/actionOrderPopUpModal';
import {sparePartsWebsocketCommands} from "../../constants/sparePartsWebsocketCommands";
import get from 'get-value';

export const orderPopUpModal = (state = {
    isOpen: false,
    isConfirm: false,
    isLoading: false,
    popUp: {},
}, action) => {
    switch (action.type) {
        case ORDER_POPUP_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_POPUP_SEND: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                popUp: get(action, 'payload.popup', {default: {}}),
                correlationId
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_POPUP_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, popUp: {}, correlationId});
        }
        case ORDER_POPUP_MODAL_OPEN: {
            return Object.assign({}, state, {isOpen: true});
        }
        case ORDER_POPUP_MODAL_CONFIRM: {
            return Object.assign({}, state, {isOpen: false, isConfirm: true});
        }
        default:
            return state;
    }
};
