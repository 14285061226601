import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import {
    FORM_FIELD_AGREEMENT,
    FORM_FIELD_AUTHORITY_GIVEN,
    FORM_FIELD_AUTHORITY_NUMBER,
    FORM_FIELD_COURTESY_REPLY_REMARKS,
    FORM_FIELD_DAILY_RATE,
    FORM_FIELD_FROM,
    FORM_FIELD_UNTIL,
    FORM_LABEL_AGREEMENT,
    FORM_LABEL_AUTHORITY_GIVEN,
    FORM_LABEL_AUTHORITY_NUMBER,
    FORM_LABEL_COURTESY_REPLY_REMARKS,
    FORM_LABEL_DAILY_RATE,
    FORM_LABEL_FROM,
    FORM_LABEL_TOTAL_AMOUNT,
    FORM_LABEL_TOTAL_DAYS,
    FORM_LABEL_UNTIL, FORM_TICKET_SECTION,
    FORM_WAREHOUSE_SECTION,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import {Field, formValueSelector} from 'redux-form';
import DatePickerComponent from '../../../../../common/datePicker/DatePickerComponent';
import moment from 'moment';
import {positiveNumber} from '../../../../../common/validationCommons';
import {connect} from 'react-redux';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import commonMessages from '../../../../../../intl/common/commonMessages';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import {countryCurrency} from '../../../../../../constants/Utils';
import get from 'get-value';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const WarehouseSection = (props) => {
    const {readOnly, userRights, authorityGiven, dailyRate, startDate, endDate, reqFields, group} = props;
    const numberOfDays = (startDate && endDate) ? moment(endDate).diff(moment(startDate), 'days') + 1 : 0;

    return (
        <>
            {userRights.canViewAuthReply &&
            <div className="pt-4">
                <h3 className="text-left ml-4">
                    <FormattedMessage {...formMessages.COURTESY_REPLY_HEADER}/>
                </h3>
                <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                    <div className="form-row">
                        <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                            <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_AUTHORITY_GIVEN]}
                                                            field={FORM_FIELD_AUTHORITY_GIVEN}
                                                            options={['YES', 'NO']}
                                                            intlMessages={commonMessages}
                                                            isDisabled={!userRights.canEditAuthReply || readOnly}
                                                            isRequired={reqFields.includes(FORM_FIELD_AUTHORITY_GIVEN) && !readOnly}/>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_AUTHORITY_NUMBER]}
                                                      field={FORM_FIELD_AUTHORITY_NUMBER}
                                                      isSmall
                                                      maxLength={10}
                                                      isDisabled={!userRights.canEditAuthReply || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_AUTHORITY_NUMBER) && !readOnly}/>
                        </div>
                        {authorityGiven === 'YES' ?
                            <div className="col align-self-end">
                                <label className="pl-2 pt-1">
                                    <Field
                                        name={FORM_FIELD_AGREEMENT}
                                        component="input"
                                        type="checkbox"
                                        disabled={!userRights.canEditAuthReply || readOnly}
                                    />{' '}
                                    <ValidFormattedMessage message={FORM_LABEL_AGREEMENT}
                                                           intlMessages={formMessages}/>
                                </label>
                            </div> : null}
                    </div>
                    <div className="form-row ">
                        <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                            <Field name={FORM_FIELD_FROM} component={DatePickerComponent} selectsStart
                                   label={formMessages[FORM_LABEL_FROM]}
                                   startDate={startDate} endDate={endDate}
                                   isDisabled={!userRights.canEditAuthReply || readOnly}
                                   isRequired={reqFields.includes(FORM_FIELD_FROM) && !readOnly}
                                   parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}
                            />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                            <Field name={FORM_FIELD_UNTIL} component={DatePickerComponent} selectsEnd
                                   label={formMessages[FORM_LABEL_UNTIL]}
                                   startDate={startDate} endDate={endDate}
                                   isDisabled={!userRights.canEditAuthReply || readOnly}
                                   isRequired={reqFields.includes(FORM_FIELD_UNTIL) && !readOnly}
                                   parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}
                            />
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_DAILY_RATE]}
                                                      field={FORM_FIELD_DAILY_RATE}
                                                      isSmall
                                                      isDisabled={!userRights.canEditAuthReply || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_DAILY_RATE) && !readOnly}
                                                      validations={[positiveNumber]}
                                                      maxLength={6}/>
                        </div>
                    </div>
                    <div className="form-row ">
                        <div className="col-xl-3 col-lg-6 col-md-12 mb-2 align-self-end pb-1 pt-3">
                            <ValidFormattedMessage message={FORM_LABEL_TOTAL_DAYS}
                                                   intlMessages={formMessages}/>{':'}
                            <span className="bg-light ml-1 pl-1 pr-1 border border-dark rounded">
                                    {numberOfDays ? numberOfDays : 0}
                                </span>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-12 mb-2 align-self-end pb-1 pt-3">
                            <ValidFormattedMessage message={FORM_LABEL_TOTAL_AMOUNT}
                                                   intlMessages={formMessages}/>{':'}
                            <span className="bg-light ml-1 pl-1 pr-1 border border-dark rounded">
                                    {dailyRate && numberOfDays ? Math.round(((dailyRate * numberOfDays) + Number.EPSILON) * 100) / 100 : 0}{'\u00A0' + get(countryCurrency, group, 'EUR')}
                                </span>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-12 pb-2">
                            <TextareaFieldWithValidation label={formMessages[FORM_LABEL_COURTESY_REPLY_REMARKS]}
                                                         field={FORM_FIELD_COURTESY_REPLY_REMARKS}
                                                         rows={3}
                                                         isDisabled={!userRights.canEditAuthReply || readOnly}/>
                        </div>
                    </div>
                </StyledDiv>
            </div>
            }
        </>
    )
}

const mapStateToProps = (state, initialProps) => ({
    dailyRate: formValueSelector(initialProps.form)(state, `${FORM_WAREHOUSE_SECTION}.${FORM_FIELD_DAILY_RATE}`),
    authorityGiven: formValueSelector(initialProps.form)(state, `${FORM_WAREHOUSE_SECTION}.${FORM_FIELD_AUTHORITY_GIVEN}`),
    startDate: formValueSelector(initialProps.form)(state, `${FORM_WAREHOUSE_SECTION}.${FORM_FIELD_FROM}`),
    endDate: formValueSelector(initialProps.form)(state, `${FORM_WAREHOUSE_SECTION}.${FORM_FIELD_UNTIL}`),
    group: formValueSelector(initialProps.form)(state, `${FORM_TICKET_SECTION}.group`),
});

export default connect(mapStateToProps, null)(WarehouseSection);
