import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const TECH_DOC_UPDATE_USER_LIST_PREFERENCE = 'TECH_DOC_UPDATE_USER_LIST_PREFERENCE';
export const TECH_DOC_UPDATE_USER_HISTORY_PREFERENCE = 'TECH_DOC_UPDATE_USER_HISTORY_PREFERENCE';
export const TECH_DOC_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE = 'TECH_DOC_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE';
export const TECH_DOC_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE = 'TECH_DOC_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE';
export const TECH_DOC_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE = 'TECH_DOC_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE';
export const TECH_DOC_UPDATE_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE = 'TECH_DOC_UPDATE_USER_FULL_TEXT_SEARCH_INTENRAL_PREFERENCE';
export const TECH_DOC_UPDATE_USER_STATISTICS_PREFERENCE = 'TECH_DOC_UPDATE_USER_STATISTICS_PREFERENCE';
export const TECH_DOC_UPDATE_USER_STATISTICS_SG_PREFERENCE = 'TECH_DOC_UPDATE_USER_STATISTICS_SG_PREFERENCE';
export const TECH_DOC_FETCH_PREFERENCES_PENDING = 'TECH_DOC_FETCH_PREFERENCES_PENDING';

export const getTechDocPreferences = () => dispatch => {
    return dispatch({
        type: TECH_DOC_FETCH_PREFERENCES_PENDING,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_PREFERENCE_GET
    });
};

export const updateTechDocUserListPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: TECH_DOC_UPDATE_USER_LIST_PREFERENCE,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_USER_LIST_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateTechDocUserHistoryPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: TECH_DOC_UPDATE_USER_HISTORY_PREFERENCE,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_USER_HISTORY_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateTechDocUserFulltextSearchPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: TECH_DOC_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_USER_FULL_TEXT_SEARCH_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateTechDocUserFulltextSearchInternalPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: TECH_DOC_UPDATE_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateTechDocUserStatisticsPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: TECH_DOC_UPDATE_USER_STATISTICS_PREFERENCE,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_USER_STATISTICS_PREFERENCE_SET,
        payload: {preferences: {ipn, countries: preferences}}
    });
};

export const updateTechDocUserStatisticsSGPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: TECH_DOC_UPDATE_USER_STATISTICS_SG_PREFERENCE,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_USER_STATISTICS_SG_PREFERENCE_SET,
        payload: {preferences: {ipn, solutionGroups: preferences}}
    });
};

export const updateTechDocUserInternalTicketListPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: TECH_DOC_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_USER_INTERNAL_TICKET_LIST_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateTechDocUserInternalTicketHistoryPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: TECH_DOC_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE,
        techDocWebsocket: true,
        command: techDocWebsocketCommands.TECH_DOC_USER_INTERNAL_TICKET_HISTORY_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};
