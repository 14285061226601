import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'techDoc.fulltextSearch.title',
        defaultMessage: 'Fulltext Search'
    },
    VISIBLE_COLUMNS: {
        id: 'techDoc.fulltextSearch.label.visibleColumns',
        defaultMessage: 'Visible columns'
    },
    TABLE_TICKET_NUMBER: {
        id: 'techDoc.fulltextSearch.table.ticketNo',
        defaultMessage: 'Ticket No.'
    },
    TABLE_ORDER_CREATION_TIME: {
        id: 'techDoc.fulltextSearch.table.creationDate',
        defaultMessage: 'Creation Date'
    },
    TABLE_CLIENT_ID: {
        id: 'techDoc.fulltextSearch.table.clientId',
        defaultMessage: 'Client ID'
    },
    TABLE_DEALER_NUMBER: {
        id: 'techDoc.fulltextSearch.table.dealerNumber',
        defaultMessage: 'Dealer Number'
    },
    TABLE_DEALER_NAME: {
        id: 'techDoc.fulltextSearch.table.dealerName',
        defaultMessage: 'Dealer Name'
    },
    TABLE_PR: {
        id: 'techDoc.fulltextSearch.table.pr',
        defaultMessage: 'PR'
    },
    TABLE_REG_DIR: {
        id: 'techDoc.fulltextSearch.table.regDir',
        defaultMessage: 'Reg. Dir.'
    },
    TABLE_COUNTRY_CODE_3: {
        id: 'techDoc.fulltextSearch.table.countryCode3',
        defaultMessage: 'Country Code'
    },
    TABLE_PART_DESCRIPTION: {
        id: 'techDoc.fulltextSearch.table.partDescription',
        defaultMessage: 'Part Description'
    },
    TABLE_MANAGEMENT_GROUP: {
        id: 'techDoc.fulltextSearch.table.managementGroup',
        defaultMessage: 'Management Group'
    },
    TABLE_INTERNAL_STATUS: {
        id: 'techDoc.fulltextSearch.table.internalStatus',
        defaultMessage: 'Internal status'
    },
    TABLE_FORM_TYPE: {
        id: 'techDoc.fulltextSearch.table.formType',
        defaultMessage: 'Type of Form'
    },
    TABLE_STATUS: {
        id: 'techDoc.fulltextSearch.table.status',
        defaultMessage: 'Ticket Status'
    },
    TABLE_SOLUTION_GROUP: {
        id: 'techDoc.fulltextSearch.table.solutionGroup',
        defaultMessage: 'Solution Gr.'
    },
    TABLE_LAST_EDITOR: {
        id: 'techDoc.fulltextSearch.table.lastEditor',
        defaultMessage: 'Last Editor'
    },
    TABLE_CREATED_BY: {
        id: 'techDoc.fulltextSearch.table.createdBy',
        defaultMessage: 'Created by (IPN)'
    },
    TABLE_PAGE1: {
        id: 'techDoc.fulltextSearch.table.page1',
        defaultMessage: 'Page 1'
    },
    TABLE_PAGE2: {
        id: 'techDoc.fulltextSearch.table.page2',
        defaultMessage: 'Page 2'
    },
    TABLE_PROBLEM_TYPE: {
        id: 'techDoc.fulltextSearch.table.problemType',
        defaultMessage: 'Type of Problem'
    },
    TABLE_VOR_RELATED: {
        id: 'techDoc.fulltextSearch.table.vorRelated',
        defaultMessage: 'VOR/PVI related'
    },
    TABLE_IMMOBILIZED_VEHICLE: {
        id: 'techDoc.fulltextSearch.table.immobilizedVehicle',
        defaultMessage: 'Immobilized Vehicle'
    },
    TICKET_SECTION: {
        id: 'techDoc.fulltextSearch.header.ticketInformation',
        defaultMessage: 'Ticket information'
    },
    DEALER_SECTION: {
        id: 'techDoc.fulltextSearch.header.dealerInformation',
        defaultMessage: 'Dealer Information'
    },
    DEALER_NAME: {
        id: 'techDoc.fulltextSearch.dealerName',
        defaultMessage: 'Dealer Name'
    },
    DEALER_NUMBER: {
        id: 'techDoc.fulltextSearch.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    NAME: {
        id: 'techDoc.fulltextSearch.name',
        defaultMessage: 'Creator Name'
    },
    CLIENT_ID: {
        id: 'techDoc.fulltextSearch.clientId',
        defaultMessage: 'Client ID'
    },
    REGION: {
        id: 'techDoc.fulltextSearch.region',
        defaultMessage: 'Region'
    },
    COUNTRY_CODE_3: {
        id: 'techDoc.fulltextSearch.countryCode3',
        defaultMessage: 'Country Code'
    },
    TICKET_NUMBER: {
        id: 'techDoc.fulltextSearch.ticketNumber',
        defaultMessage: 'Ticket number'
    },
    DOC_TYPE: {
        id: 'techDoc.fulltextSearch.formType',
        defaultMessage: 'Type of Form'
    },
    SOLUTION_GROUP: {
        id: 'techDoc.fulltextSearch.solutionGroup',
        defaultMessage: 'Solution Gr.'
    },
    STATUS: {
        id: 'techDoc.fulltextSearch.status',
        defaultMessage: 'Ticket Status'
    },
    FROM: {
        id: 'techDoc.fulltextSearch.from',
        defaultMessage: 'Date From'
    },
    TO: {
        id: 'techDoc.fulltextSearch.to',
        defaultMessage: 'Date To'
    },
    EDITOR_IPN: {
        id: 'techDoc.fulltextSearch.lastEditor',
        defaultMessage: 'Last Editor'
    },
    VEHICLE_DETAIL_SECTION: {
        id: 'techDoc.fulltextSearch.header.vehicleDetail',
        defaultMessage: 'Vehicle Details'
    },
    VEHICLE_MODEL: {
        id: 'techDoc.fulltextSearch.vehicleModel',
        defaultMessage: 'Car Model'
    },
    VIN: {
        id: 'techDoc.fulltextSearch.vin',
        defaultMessage: 'VIN'
    },
    REGISTRATION_NUMBER: {
        id: 'techDoc.fulltextSearch.registrationNumber',
        defaultMessage: 'Registration number'
    },
    SUPPLEMENTARY_INFORMATION_SECTION: {
        id: 'techDoc.fulltextSearch.header.supplementaryInformationSection',
        defaultMessage: 'Supplementary Information'
    },
    ENGINE_TYPE: {
        id: 'techDoc.fulltextSearch.engineTypeAndSuffix',
        defaultMessage: 'Engine Type & Suffix'
    },
    ENGINE_SERIAL_NUMBER: {
        id: 'techDoc.fulltextSearch.engineSerialNumber',
        defaultMessage: 'Engine serial number'
    },
    GEARBOX_TYPE: {
        id: 'techDoc.fulltextSearch.gearboxType',
        defaultMessage: 'Gearbox Type & Suffix'
    },
    GEARBOX_SERIAL_NUMBER: {
        id: 'techDoc.fulltextSearch.gearboxSerialNumber',
        defaultMessage: 'Gearbox serial number'
    },
    SEARCH_OPTION_SECTION: {
        id: 'techDoc.fulltextSearch.header.searchOption',
        defaultMessage: 'Searching option'
    },
    FULLTEXT_SEARCH: {
        id: 'techDoc.fulltextSearch.fullText',
        defaultMessage: 'Fulltext'
    },
    FULL_DATABASE: {
        id: 'techDoc.fulltextSearch.fullDatabase',
        defaultMessage: 'Full database'
    },
    INTERNATIONAL: {
        id: 'techDoc.fulltextSearch.international',
        defaultMessage: 'International Search'
    },
    ATTACHMENTS_ONLY: {
        id: 'techDoc.fulltextSearch.onlyTicketsWithAttachments',
        defaultMessage: 'Only tickets with Attachments'
    },
    PROBLEM_DESCRIPTION_SECTION: {
        id: 'techDoc.fulltextSearch.header.problemDescriptionSection',
        defaultMessage: 'Problem Description'
    },
    PR: {
        id: 'techDoc.fulltextSearch.pr',
        defaultMessage: 'PR'
    },
    PAGE: {
        id: 'techDoc.fulltextSearch.page',
        defaultMessage: 'Page'
    },
    PART_NUMBER: {
        id: 'techDoc.fulltextSearch.partNumber',
        defaultMessage: 'Part No'
    },
    PART_DESCRIPTION: {
        id: 'techDoc.fulltextSearch.partDescription',
        defaultMessage: 'Part Description'
    },
    MANAGEMENT_GROUP: {
        id: 'techDoc.fulltextSearch.managementGroup',
        defaultMessage: 'Management Group'
    },
    PART_MARKING: {
        id: 'techDoc.fulltextSearch.partMarking',
        defaultMessage: 'Part marking'
    },
    CLAIM_RELATED: {
        id: 'techDoc.fulltextSearch.claimRelated',
        defaultMessage: 'Claim related'
    },
    PVI_RELATED: {
        id: 'techDoc.fulltextSearch.pviRelated',
        defaultMessage: 'VOR/PVI Order related'
    },
    GAR: {
        id: 'techDoc.fulltextSearch.garRelated',
        defaultMessage: 'GAR related (1-3)'
    },
    IMMOBILIZED_VEHICLE: {
        id: 'techDoc.fulltextSearch.immobilizedVehicle',
        defaultMessage: 'Immobilized Vehicle'
    },
    PROBLEM_DESCRIPTION: {
        id: 'techDoc.fulltextSearch.problemDescription',
        defaultMessage: 'Problem Description'
    },
    OLD_OVAL_PLATE: {
        id: 'techDoc.fulltextSearch.oldOvalPlate',
        defaultMessage: 'Old Oval Plate'
    },
    OLD_PLATE1: {
        id: 'techDoc.fulltextSearch.oldOvalPlate.vehicleType',
        defaultMessage: 'Old plate 1'
    },
    OLD_PLATE2: {
        id: 'techDoc.fulltextSearch.oldOvalPlate.factoryOptions',
        defaultMessage: 'Old plate 2'
    },
    OLD_PLATE3: {
        id: 'techDoc.fulltextSearch.oldOvalPlate.technicalEquipmentCode',
        defaultMessage: 'Old plate 3'
    },
    OLD_PLATE4: {
        id: 'techDoc.fulltextSearch.oldOvalPlate.additionalOptionalEquipment',
        defaultMessage: 'Old plate 4'
    },
    OLD_PLATE5: {
        id: 'techDoc.fulltextSearch.oldOvalPlate.seatTrimCode',
        defaultMessage: 'Old plate 5'
    },
    OLD_PLATE6: {
        id: 'techDoc.fulltextSearch.oldOvalPlate.paintCode',
        defaultMessage: 'Old plate 6'
    },
    OLD_PLATE7: {
        id: 'techDoc.fulltextSearch.oldOvalPlate.equipmentLevel',
        defaultMessage: 'Old plate 7'
    },
    OLD_PLATE8: {
        id: 'techDoc.fulltextSearch.oldOvalPlate.fabricationNumber',
        defaultMessage: 'Old plate 8'
    },
    NEW_OVAL_PLATE: {
        id: 'techDoc.fulltextSearch.newOvalPlate',
        defaultMessage: 'New Oval Plate'
    },
    NEW_PLATE1: {
        id: 'techDoc.fulltextSearch.newOvalPlate.vehicleType',
        defaultMessage: 'New plate 1'
    },
    NEW_PLATE2: {
        id: 'techDoc.fulltextSearch.newOvalPlate.fabricationNumber',
        defaultMessage: 'New plate 2'
    },
    NEW_PLATE3: {
        id: 'techDoc.fulltextSearch.newOvalPlate.equipmentLevel',
        defaultMessage: 'New plate 3'
    },
    NEW_PLATE4: {
        id: 'techDoc.fulltextSearch.newOvalPlate.additionalOptionalEquipment1',
        defaultMessage: 'New plate 4'
    },
    NEW_PLATE5: {
        id: 'techDoc.fulltextSearch.newOvalPlate.additionalOptionalEquipment2',
        defaultMessage: 'New plate 5'
    },
    NEW_PLATE6: {
        id: 'techDoc.fulltextSearch.newOvalPlate.paintCode',
        defaultMessage: 'New plate 6'
    },
    NEW_PLATE7: {
        id: 'techDoc.fulltextSearch.newOvalPlate.seatTrimCode',
        defaultMessage: 'New plate 7'
    },
    NEW_PLATE8: {
        id: 'techDoc.fulltextSearch.newOvalPlate.interiorTrimCode',
        defaultMessage: 'New plate 8'
    },
    NEW_PLATE9: {
        id: 'techDoc.fulltextSearch.newOvalPlate.technicalCountryCode',
        defaultMessage: 'New plate 9'
    },
    NEW_PLATE10: {
        id: 'techDoc.fulltextSearch.newOvalPlate.suspensionType',
        defaultMessage: 'New plate 10'
    },
    NEW_PLATE11: {
        id: 'techDoc.fulltextSearch.newOvalPlate.countryClimateType',
        defaultMessage: 'New plate 11'
    },
    NEW_PLATE12: {
        id: 'techDoc.fulltextSearch.newOvalPlate.airConditioningType',
        defaultMessage: 'New plate 12'
    },
    NEW_PLATE13: {
        id: 'techDoc.fulltextSearch.newOvalPlate.engineFiltrationType',
        defaultMessage: 'New plate 13'
    },
    NEW_PLATE14: {
        id: 'techDoc.fulltextSearch.newOvalPlate.steeringType',
        defaultMessage: 'New plate 14'
    },
    NEW_PLATE15: {
        id: 'techDoc.fulltextSearch.newOvalPlate.dampersType',
        defaultMessage: 'New plate 15'
    },
    NEW_PLATE16: {
        id: 'techDoc.fulltextSearch.newOvalPlate.trimSystem',
        defaultMessage: 'New plate 16'
    },
    NEW_PLATE17: {
        id: 'techDoc.fulltextSearch.newOvalPlate.braking',
        defaultMessage: 'New plate 17'
    },
});

export default messages;
