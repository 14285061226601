import v4 from 'uuid/v4';
import {
    FORM_EDITORS_NOTES_SECTION_TD,
    FORM_FIELD_EDITORS_NOTES_TD,
    ORDER_TICKET_STATUS_NEW,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM
} from '../../constants/formConstants';
import get from 'get-value';
import {diff} from 'deep-object-diff';
import {NO} from '../../constants/Utils';
import {formatTicketNumber} from '../../utils/utils';

export const initLoadedTechDocTicket = (ticketData) => {
    let ovalPlateMapping = {};
    const {lockIpn, lockTime, ...ticketSection} = get(ticketData, 'ticket', {default: {}});
    if (get(ticketData, 'vehicleDetail.ovalPlate.newOvalPlate', {default: false})) {
        Object.keys(ticketData.vehicleDetail.ovalPlate).forEach(param => ovalPlateMapping[param !== 'newOvalPlate' ?
            'new' + param.charAt(0).toUpperCase() + param.slice(1) : param] = ticketData.vehicleDetail.ovalPlate[param]);
    }
    if (get(ticketData, 'vehicleDetail.ovalPlate.oldOvalPlate', {default: false})) {
        Object.keys(ticketData.vehicleDetail.ovalPlate).forEach(param => ovalPlateMapping[param !== 'oldOvalPlate' ?
            'old' + param.charAt(0).toUpperCase() + param.slice(1) : param] = ticketData.vehicleDetail.ovalPlate[param]);
    }
    const isConformOrNotFoundReference = ticketSection.docType
        === SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM
        || ticketSection.docType
        === SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM;
    return {
        ticketSection: {...ticketSection},
        vehicleDetail: {...ticketData.vehicleDetail, ovalPlate: ovalPlateMapping},
        suppInfoSection: ticketData.supplementaryInformation,
        problemDescription: ticketData.problemDescription,
        messages: ticketData.messages,
        statusHistory: ticketData.logs,
        attachmentsSection: {attachments: ticketData.attachments},
        documentationUpdate: ticketData.documentationUpdate || isConformOrNotFoundReference ? {} : { docUpdateNeeded: 'NO'},
        editorsNotes: ticketData.editorsNotes,
        internalTicketReferences: ticketData.internalTicketReferences,
    };
};

export const initNewTechDocTicket = (userDetail) => {
    if (!userDetail.dealerNumber || userDetail === '') {
        return undefined;
    }
    if(userDetail.group === 'AR') {
        return {
            ticketSection: {
                dealerNumber: userDetail.dealerNumber,
                dealerName: userDetail.dealerName,
                email: userDetail.mail,
                group: userDetail.group,
                status: ORDER_TICKET_STATUS_NEW,
            },
            vehicleDetail: {
                bvmLoaded: true,
                ovalPlate: {newOvalPlate: true}
            },
            suppInfoSection: {
                srcRequest: 'NO'
            },
        };
    } else {
        return {
            ticketSection: {
                dealerNumber: userDetail.dealerNumber,
                dealerName: userDetail.dealerName,
                email: userDetail.mail,
                group: userDetail.group,
                telNo: userDetail.telNo,
                status: ORDER_TICKET_STATUS_NEW,
            },
            vehicleDetail: {
                bvmLoaded: true,
                ovalPlate: {newOvalPlate: true}
            },
            suppInfoSection: {
                srcRequest: 'NO'
            },
        };
    }
};

export const transformNewTicketForSave = (values) => {
    const data = {};
    const {ovalPlate, ...vehicleDetail} = values.vehicleDetail;


    data.ticket = {
        ...values.ticketSection,
        uuid: v4(),
    };

    data.vehicleDetail = vehicleDetail;
    if (ovalPlate) {
        data.vehicleDetail['ovalPlate'] = {};
        Object.keys(ovalPlate).forEach(param => data.vehicleDetail.ovalPlate[(param.startsWith('old') || param.startsWith('new')) && param !== 'oldOvalPlate' && param !== 'newOvalPlate' ?
            param.slice(3).charAt(0).toLowerCase() + param.slice(4) : param] = ovalPlate[param]);
    }

    if (values.suppInfoSection) {
        data.supplementaryInformation = values.suppInfoSection;
    }
    if (values.problemDescription) {
        data.problemDescription = values.problemDescription;
    }

    if (get(values, 'attachmentsSection.newAttachments', {default: false}) && values.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        values.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                ...attachment,
                uuid: v4(),
            })
        })
    }
    return data;
};

export const createInternalTicketTechDocInitData = (newValues) => {
    return {
        vin: get(newValues, 'vehicleDetail.vin'),
        problemDesc: get(newValues, 'problemDescription.problemDesc'),
        partNo: get(newValues, 'problemDescription.partNo'),
        partMarking: get(newValues, 'problemDescription.partMarking'),
        managementGroup: get(newValues, 'problemDescription.managementGroup'),
        catalogNumber: get(newValues, 'problemDescription.pr'),
        plateNumber: get(newValues, 'problemDescription.pagePref', '') + get(newValues, 'problemDescription.pagePost', ''),
        ticketReference: {
            uuid: get(newValues, 'ticketSection.uuid'),
            pfxNumber: get(newValues, 'ticketSection.pfxNumber'),
            sfxNumber: get(newValues, 'ticketSection.sfxNumber'),
            networkTicketNumber: formatTicketNumber(get(newValues, 'ticketSection.pfxNumber'), get(newValues, 'ticketSection.sfxNumber')),
            docType: get(newValues, 'ticketSection.docType'),
            region: get(newValues, 'ticketSection.region'),
            group: get(newValues, 'ticketSection.group'),
            clientId: get(newValues, 'ticketSection.clientId'),
            dealerNumber: get(newValues, 'ticketSection.dealerNumber'),
        },
        attachments: (get(newValues, 'attachmentsSection.attachments') || []).map(item => ({
            ...item,
            isDealerTicketAttachment: true,
            dealerTicketId: get(newValues, 'ticketSection.uuid'),
        }))
    };
}

export const transformUpdateTechDocTicketForSave = (values) => {
    const {oldValues, newValues, submitBy} = values;
    const difference = diff(oldValues, newValues);
    const data = {};
    data.submitBy = submitBy
    data.ticket = {
        ...get(difference, 'ticketSection'),
        uuid: oldValues.ticketSection.uuid,
        pfxNumber: oldValues.ticketSection.pfxNumber,
        sfxNumber: oldValues.ticketSection.sfxNumber,
        status: newValues.ticketSection.status,
    };

    const newVehicleDetail = get(difference, 'vehicleDetail');
    if (newVehicleDetail) {
        const {ovalPlate, ...rest} = newVehicleDetail;
        data.vehicleDetail = rest;
        if (get(newVehicleDetail, 'ovalPlate', {default: false})) {
            data.vehicleDetail['ovalPlate'] = {};
            Object.keys(newVehicleDetail.ovalPlate).forEach(param => data.vehicleDetail.ovalPlate[(param.startsWith('old') || param.startsWith('new')) && param !== 'oldOvalPlate' && param !== 'newOvalPlate' ?
                param.slice(3).charAt(0).toLowerCase() + param.slice(4) : param] = newVehicleDetail.ovalPlate[param]);
        }
    }

    const newSuppInfo = get(difference, 'suppInfoSection');
    if (newSuppInfo) {
        data.supplementaryInformation = newSuppInfo;
    }

    const newProblemDescription = get(difference, 'problemDescription');
    if (newProblemDescription) {
        data.problemDescription = newProblemDescription;
    }

    const newDocumentUpdate = get(difference, 'documentationUpdate');
    if (newDocumentUpdate) {
        data.documentationUpdate = newDocumentUpdate;
    }

    const newMessage = get(difference, 'messagesSection.newMessage');
    if (newMessage) {
        data.message = {
            uuid: v4(),
            newMessage,
        };
    }

    const newEditorsNote = get(difference, `${FORM_EDITORS_NOTES_SECTION_TD}.${FORM_FIELD_EDITORS_NOTES_TD}`);
    if (newEditorsNote) {
        data.editorsNote = {
            uuid: v4(),
            newEditorsNote,
        };
    }

    if (get(difference, 'attachmentsSection.newAttachments', {default: false}) && difference.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        difference.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                uuid: v4(),
                ...attachment,
            })
        })
    }

    return data;
};

export const initLoadedTechDocInternalTicket = (ticketData) => {
    const {lockIpn, lockTime, ...ticketSection} = get(ticketData, 'ticket', {default: {}});

    const originalSolutionGroup = get(ticketSection, "solutionGroup", {default: {}});
    ticketSection.solutionGroup = get(ticketSection, "solutionGroup.id", {default: {}});

    return {
        ticketSection: {...ticketSection},
        vehicleDetail: ticketData.vehicleDetail,
        problemDescription: ticketData.problemDescription,
        partDetail: ticketData.partDetail,
        messages: ticketData.messages,
        documentationUpdate: ticketData.documentationUpdate,
        storeCheckResults: ticketData.storeCheckResults,
        statusHistory: ticketData.logs,
        attachmentsSection: {attachments: ticketData.attachments},
        requestorSolutionGroup: originalSolutionGroup,
    };
};

export const initNewTechDocInternalTicket = (userDetail, initData) => {
    if (!userDetail.ipn || !userDetail.name || userDetail === '') {
        return undefined;
    }

    const data = {};

    const networkTicketNumber = get(initData, 'ticketReference.networkTicketNumber');

    data.ticketSection = {
        requestorName: userDetail.name,
        requestorIpn: userDetail.ipn,
        requestForPartner: "NO",
        priorityTreatment: "NO",

        ...(networkTicketNumber && {networkTicketNumber: networkTicketNumber}),
    };

    if (get(userDetail, 'roles.TECHNICAL_DOCUMENTATION.sgEditor.sgPermission').length === 1) {
        const array = get(userDetail, 'roles.TECHNICAL_DOCUMENTATION.sgEditor.sgPermission')
        data.ticketSection.solutionGroup = array[0].id
    }

    data.vehicleDetail = {
        bvmLoaded: true,
        ...(get(initData, 'vin') && {vin: get(initData, 'vin')}),
    };

    data.problemDescription = {
        ...(get(initData, 'problemDesc') && {problemDesc: get(initData, 'problemDesc')}),
    };

    data.partDetail = {
        ...(get(initData, 'partNo') && {nReferenceCausingProblem: get(initData, 'partNo')}),
        ...(get(initData, 'managementGroup') && {managementGroup: get(initData, 'managementGroup')}),
        ...(get(initData, 'partMarking') && {partMarking: get(initData, 'partMarking')}),
        ...(get(initData, 'catalogNumber') && {catalogNumber: get(initData, 'catalogNumber')}),
        ...(get(initData, 'plateNumber') && {plateNumber: get(initData, 'plateNumber')}),
    }

    data.attachmentsSection = {
        ...(get(initData, 'attachments') && {attachments: get(initData, 'attachments')}),
    }

    if (initData && initData.ticketReference) {
        data.ticketReference = initData.ticketReference;
    }

    // data.documentationUpdate = {
    //     docUpdateNeeded: "NO",
    // }

    return data;
};

export const transformNewInternalTicketForSave = (values, editorSolutionGroups) => {
    const data = {};

    data.ticket = {
        ...values.ticketSection,
        status: undefined,
        uuid: v4(),
    };

    const editorSolutionGroup = editorSolutionGroups.find(x => x.id === data.ticket.solutionGroup);
    data.ticket.solutionGroup = {id: data.ticket.solutionGroup, name: editorSolutionGroup.name};

    if(data.ticket.requestForPartner === NO) {
        delete data.ticket.partnerName
    }

    if(values.vehicleDetail) {
        data.vehicleDetail = values.vehicleDetail
    }

    if(values.partDetail) {
        data.partDetail = values.partDetail
    }

    if(values.problemDescription) {
        data.problemDescription = values.problemDescription
    }

    if(values.documentationUpdate) {
        data.documentationUpdate = values.documentationUpdate
    }

    if(values.storeCheckResults) {
        data.storeCheckResults = values.storeCheckResults
    }

    if (values.messagesSection && values.messagesSection.newMessage) {
        data.message = {
            uuid: v4(),
            newMessage: values.messagesSection.newMessage,
        };
    }

    const internalAttachments = get(values, 'attachmentsSection.newAttachments');
    const dealerAttachments = get(values, 'attachmentsSection.attachments');

    if ((internalAttachments && internalAttachments.length) || (dealerAttachments && dealerAttachments.length)) {
        data.newAttachments = [];

        internalAttachments && internalAttachments.forEach(attachment => {
            data.newAttachments.push({
                ...attachment,
                uuid: v4(),
            })
        });
        dealerAttachments && dealerAttachments.forEach(attachment => {
            data.newAttachments.push(attachment);
        });
    }

    if (values.ticketReference) {
        data.ticketReference = values.ticketReference;
    }

    return data;
};

export const transformUpdateTechDocInternalTicketForSave = (values) => {
    const {oldValues, newValues} = values;
    const difference = diff(oldValues, newValues);
    const data = {};

    data.ticket = {
        ...get(difference, 'ticketSection'),
        uuid: oldValues.ticketSection.uuid,
        pfxNumber: oldValues.ticketSection.pfxNumber,
        sfxNumber: oldValues.ticketSection.sfxNumber,
        status: newValues.ticketSection.status,
    };


    const newVehicleDetail = get(difference, 'vehicleDetail');
    if(newVehicleDetail) {
        data.vehicleDetail = newVehicleDetail
    }

    const newPartDetail = get(difference, 'partDetail');
    if(newPartDetail) {
        data.partDetail = newPartDetail
    }

    const newProblemDescription = get(difference, 'problemDescription');
    if(newProblemDescription) {
        data.problemDescription = newProblemDescription
    }

    const newDocumentationUpdate = get(difference, 'documentationUpdate');
    if(newDocumentationUpdate) {
        data.documentationUpdate = newDocumentationUpdate
    }

    const newStoreCheckResults = get(difference, 'storeCheckResults');
    if(newStoreCheckResults) {
        data.storeCheckResults = newStoreCheckResults
    }

    const newMessage = get(difference, 'messagesSection.newMessage');
    if (newMessage) {
        data.message = {
            uuid: v4(),
            newMessage,
        };
    }

    if (get(difference, 'attachmentsSection.newAttachments', {default: false}) && difference.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        difference.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                uuid: v4(),
                ...attachment,
            })
        })
    }

    return data;
};
