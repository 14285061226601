import React from 'react';
import {FormSection, reduxForm} from 'redux-form'
import SubmitButtons from './sections/SubmitButtons';
import {
    FORM_ATTACHMENTS_SECTION,
    FORM_TICKET_SECTION,
    FORM_NOTES_SECTION,
    FORM_ORDERS_SECTION, FORM_EDITORS_NOTES_SECTION_SP
} from '../../../../constants/formConstants';
import TicketStatusHistory from "../../../common/TicketStatusHistory";
import RenderSyncErrors from "../../../common/RenderSyncErrors";
import TicketSection from "./sections/ticket/TicketSection";
import {scrollContentWrapperToTop} from "../../../../utils/utils";
import OrderItemsSection from "./sections/orders/OrderItemsSection";
import NotesSection from "./sections/notes/NotesSection";
import AttachmentSection from "./sections/attachments/AttachmentSection";
import formMessages from "../../../../intl/sparePartsOrders/formMessages";
import {sparePartsIntlFieldMapping} from "../../../../constants/sparePartsFieldLabelMapping";
import PropTypes from 'prop-types';
import BorderRadius from '../../../common/styled/BorderRadius';
import EditorsNotesSectionSP from './sections/editorsNotesSp/EditorsNotesSectionSP';
import InternalTicketReferences from './sections/ticketReferences/InternalTicketReferences';


const OrderCancellationMasterForm = (props) => {
    const {form, userRights, reqFields, readOnly, handleSubmit, editMode} = props;
    return (
        <BorderRadius size={10} className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form} intlMessages={formMessages} mapping={sparePartsIntlFieldMapping}/>
            <FormSection name={FORM_TICKET_SECTION}>
                <TicketSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_ORDERS_SECTION}>
                <OrderItemsSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_NOTES_SECTION}>
                <NotesSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            {userRights.canReadEditorsNotes && <FormSection name={FORM_EDITORS_NOTES_SECTION_SP}>
                <EditorsNotesSectionSP form={form} userRights={userRights} readOnly={readOnly}/>
            </FormSection>}
            <InternalTicketReferences form={form} userRights={userRights}/>
            <FormSection name={FORM_ATTACHMENTS_SECTION}>
                <AttachmentSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <TicketStatusHistory form={form}/>
            <SubmitButtons form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}
                           onSubmit={handleSubmit} onSubmitFail={scrollContentWrapperToTop} editMode={editMode}/>
        </BorderRadius>
    )
}


OrderCancellationMasterForm.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    editMode: PropTypes.any,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
};

export default reduxForm({})(OrderCancellationMasterForm);
