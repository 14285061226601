import {domains} from '../../constants/Utils';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const ADMIN_RECURRENT_PROBLEM_FETCH_PENDING = 'ADMIN_RECURRENT_PROBLEM_FETCH_PENDING';
export const ADMIN_RECURRENT_PROBLEM_SWITCH = 'ADMIN_RECURRENT_PROBLEM_SWITCH';
export const ADMIN_RECURRENT_PROBLEM_ADD = 'ADMIN_RECURRENT_PROBLEM_ADD';
export const ADMIN_RECURRENT_PROBLEM_REMOVE = 'ADMIN_RECURRENT_PROBLEM_REMOVE';
export const ADMIN_RECURRENT_PROBLEM_UPDATE = 'ADMIN_RECURRENT_PROBLEM_UPDATE';
export const ADMIN_RECURRENT_PROBLEM_EDIT = 'ADMIN_RECURRENT_PROBLEM_EDIT';
export const ADMIN_RECURRENT_PROBLEM_SELECT_GROUP = 'ADMIN_RECURRENT_PROBLEM_SELECT_GROUP';

export const fetchRecurrentProblems = (domain, group) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_GET,
                payload: {group}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_GET,
                payload: {group}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_FETCH_PENDING,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_GET,
                payload: {group}
            });
        }
    }
};

export const addItem = (domain, recurrentProblem) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_ADD,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_CREATE,
                payload: {recurrentProblem}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_ADD,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_CREATE,
                payload: {recurrentProblem}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_ADD,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_CREATE,
                payload: {recurrentProblem}
            });
        }
    }
};

export const changePosition = (domain, recurrentProblem, oldIndex) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_SWITCH,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_MOVE,
                payload: {oldIndex, newIndex: recurrentProblem.position, recurrentProblem}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_SWITCH,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_MOVE,
                payload: {oldIndex, newIndex: recurrentProblem.position, recurrentProblem}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_SWITCH,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_MOVE,
                payload: {oldIndex, newIndex: recurrentProblem.position, recurrentProblem}
            });
        }
    }
};

export const removeItem = (domain, recurrentProblem) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_REMOVE,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_DELETE,
                payload: {recurrentProblem}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_REMOVE,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_DELETE,
                payload: {recurrentProblem}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_REMOVE,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_DELETE,
                payload: {recurrentProblem}
            });
        }
    }
};

export const updateItem = (domain, recurrentProblem, oldItem, index) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_UPDATE,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_UPDATE,
                payload: {recurrentProblem, index, oldItem}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_UPDATE,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_UPDATE,
                payload: {recurrentProblem, index, oldItem}
            });
        }
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_RECURRENT_PROBLEM_UPDATE,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_UPDATE,
                payload: {recurrentProblem, index, oldItem}
            });
        }
    }
};

export const editItem = index => dispatch => {
    dispatch({type: ADMIN_RECURRENT_PROBLEM_EDIT, payload: {index}});
};

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_RECURRENT_PROBLEM_SELECT_GROUP,
        payload: countryGroup
    });
