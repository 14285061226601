import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'claims.recurrentProblem.title',
        defaultMessage: 'Recurrent Problems'
    },
    EMPTY: {
        id: 'claims.recurrentProblem.empty',
        defaultMessage: 'There is no recurrent problem defined'
    },
    TABLE_PROBLEM_NUMBER: {
        id: 'claims.recurrentProblem.table.problemNumber',
        defaultMessage: 'Problem number'
    },
    TABLE_PROBLEM_TITLE: {
        id: 'claims.recurrentProblem.table.problemTitle',
        defaultMessage: 'Problem title'
    },
    TABLE_ATTACHMENT: {
        id: 'claims.recurrentProblem.table.attachment',
        defaultMessage: 'Attachment'
    },
    TABLE_LAST_UPDATE: {
        id: 'claims.recurrentProblem.table.lastUpdate',
        defaultMessage: 'Last update'
    },
    SELECT_COUNTRY: {
        id: 'claims.recurrentProblem.selectCountry',
        defaultMessage: 'Select a country'
    },
});

export default messages;
