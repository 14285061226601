export const PRIMARY_YELLOW = '#ffcc33';
export const PRIMARY_YELLOW_CLEAR_SHADE = '#ffdf4a';
export const PRIMARY_YELLOW_DARK_SHADE = '#eab91a';
export const PRIMARY_BLACK = '#000000';
export const PRIMARY_WHITE = '#ffffff';
export const PRIMARY_VERY_DARK_GREY = '#333333';
export const PRIMARY_DARK_GREY = '#666666';
export const PRIMARY_GREY = '#999999';
export const PRIMARY_LIGHT_GREY = '#cccccc';
export const SECONDARY_RED = '#c80e0e';
export const SECONDARY_GREEN = '#9bc814';
export const SECONDARY_INDIGO = '#5a78be';
export const SECONDARY_BLUE = '#37bed7';
export const SECONDARY_ORANGE = '#ff7d14';
export const SECONDARY_YELLOW = '#dcdc00';
export const RENAULT = PRIMARY_YELLOW;
export const DACIA = '#003292';
export const SAMSUNG = '#1428a0';
