export function transformFlowToCreateEvent({name = '', items = []}) {
    const result = {name, items: []};
    items.forEach(({id, name}) => {
        const itemNode = {};
        itemNode.item = {id, name};
        const parentNode = result.items[result.items.length - 1];
        if (parentNode) {
            parentNode.child = itemNode.item;
        }
        result.items.push(itemNode);
    });
    return result;
}

export function transformCreateEventToFlow({id = '', name = '', items = []}) {
    return {id, name, items: items.map(({item: {id, name}}) => ({id, name}))};
}

export function transformFlowToUpdateEvent({name = '', items = []}, {name: initialName = '', items: initialItems = []}) {
    const result = {changedName: name, changedItems: []};
    if (name === initialName) {
        delete result.changedName;
    }
    if (JSON.stringify(items) !== JSON.stringify(initialItems)) {
        items.forEach(({id, name}) => {
            const itemNode = {};
            itemNode.item = {id, name};
            const parentNode = result.changedItems[result.changedItems.length - 1];
            if (parentNode) {
                parentNode.child = itemNode.item;
            }
            result.changedItems.push(itemNode);
        });
    }
    return result;
}
