import {sparePartsWebsocketCommands} from "../../constants/sparePartsWebsocketCommands";

export const ORDER_POPUP_FETCH_PENDING = 'ORDER_POPUP_FETCH_PENDING';
export const ORDER_POPUP_MODAL_OPEN = 'ORDER_POPUP_PROBLEM_MODAL_OPEN';
export const ORDER_POPUP_MODAL_CONFIRM = 'ORDER_POPUP_MODAL_CONFIRM';

export const openOrderPopUpModal = () => (dispatch) => {
    dispatch({
        type: ORDER_POPUP_MODAL_OPEN,
    });
};

export const confirmOrderPopUpModal = () => (dispatch) => {
    dispatch({
        type: ORDER_POPUP_MODAL_CONFIRM
    });
};

export const fetchOrderPopUp = (group) => dispatch => {
    return dispatch({
        type: ORDER_POPUP_FETCH_PENDING,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_POPUP_GET,
        payload: {group}
    });
};
