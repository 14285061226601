import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const createClaimsExport = (exportFilter) => (dispatch) => {
    dispatch({
        type: claimsWebsocketCommands.CLAIMS_EXPORT_CREATE,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_EXPORT_CREATE,
        payload: {exportFilter}
    });
};

export const fetchClaimsExport = () => (dispatch) => {
    dispatch({
        type: claimsWebsocketCommands.CLAIMS_EXPORT_GET,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_EXPORT_GET,
        payload: {}
    });
};

export const downloadClaimsExport = (exportRecord) => (dispatch) => {
    dispatch({
        type: claimsWebsocketCommands.CLAIMS_EXPORT_DOWNLOAD_URL_GET,
        claimsWebsocket: true,
        command: claimsWebsocketCommands.CLAIMS_EXPORT_DOWNLOAD_URL_GET,
        payload: {exportRecord}
    });
};
