import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    YES: {
        id: 'spareParts.requestForPartner.option.yes',
        defaultMessage: 'Yes'
    },
    NO: {
        id: 'spareParts.requestForPartner.option.no',
        defaultMessage: 'No'
    },
});

export default messages;
