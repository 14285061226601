import {TECH_DOC_STATISTICS_PENDING} from '../../actions/techDoc/actionTechDocStatistics';
import {TECH_DOC_STATISTICS_SEND, TECH_DOC_STATISTICS_SEND_ERROR} from '../../constants/websocketCommands';

export const techDocStatistics = (state = {
    correlationId: '',
    isLoading: false,
    data: [],
}, action) => {
    switch (action.type) {
        case TECH_DOC_STATISTICS_PENDING: {
            const {correlationId} = action.payload;
            return Object.assign({}, state, {
                isLoading: true, correlationId
            });
        }
        case TECH_DOC_STATISTICS_SEND: {
            const {correlationId, result: {data}} = action.payload;
            if (correlationId !== state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isLoading: false, correlationId, data
                });
            }
        }
        case TECH_DOC_STATISTICS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId !== state.correlationId) {
                return state;
            } else {
                return Object.assign({}, state, {
                    isLoading: false, correlationId
                });
            }
        }
        default: {
            return state;
        }
    }
};
