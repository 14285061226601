import React from 'react';
import {reduxForm} from 'redux-form';
import {InputCheckBox} from '../../../common/InputCheckbox';

const CheckboxForm = (props) => {
    const handleChange = (value) => props.change('textPopUp', value.currentTarget.value === 'true' ? ' ' : '');
    return (
        <div className="pl-2">
            <InputCheckBox field="disabled"
                           onChange={handleChange}/>
        </div>
    );
};

export default reduxForm({})(CheckboxForm);
