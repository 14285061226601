import get from 'get-value';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const sparePartDealer = (state = {
    isLoadingDealer: false,
    dealers: [],
    correlationId: null,
}, action) => {
    switch (action.type) {
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DEALERS_GET: {
            return Object.assign({}, state, { isLoadingDealer: true });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DEALERS_SEND: {
            const dealers = get(action, 'payload.dealers', { default: [] });
            return Object.assign({}, state, {
                isLoadingDealer: false,
                dealers,
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DEALERS_SEND_ERROR: {
            return Object.assign({}, state, { isLoadingDealer: false });
        }
        default:
            return state;
    }
};
