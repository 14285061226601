import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import {FormattedMessage, injectIntl} from 'react-intl';
import '../../css/formControl.css';
import validationMessages from '../../intl/common/validationMessages';
import ValidFormattedMessage from './ValidFormattedMessage';
import {DEFAULT_DEBOUNCE_TIMEOUT} from '../../constants/validationConstants';
import {debounce} from 'throttle-debounce';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

const ErrorDiv = styled.label`
    display: inherit;
`;

const MaybeClickableDiv = styled.div`
    cursor: ${props => props.onClick ? 'pointer' : 'inherit'};
`;

const ClickableInput = styled.input`
    cursor: pointer;
`;

const StyledInput = styled.input`
    color: black;
`;

const StyledSelect = styled.select`
    color: black;
`;

const customSelectFormStyles = {
    container: (provided, state) => ({
        ...provided,
        fontSize: '0.875rem',
        height: 'calc(1.5em + .5rem + 2px)',
    }),
    control: ({borderWidth, borderRadius, borderStyle, transition, borderColor, backgroundColor, ...provided}, state) => ({
        ...provided,
        height: 'calc(1.5em + .5rem + 2px)',
        padding: '0',
        minHeight: '31px',
        border: '1px solid #ced4da',
        borderRadius: '.20rem',
        transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
        fontSize: '0.875rem',
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : '',
        borderColor: state.isFocused ? '#80bdff' : '',
        outline: state.isFocused ? '0' : ''
    }),
    valueContainer: (provided, state) => ({
        paddingTop: '0',
        paddingRight: '.4rem',
        paddingBottom: '0',
        paddingLeft: '.4rem',
        height: 'calc(1.5em + .5rem + 1px)',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        height: 'calc(1.5em + .5rem + 1px)',
        padding: '5px'
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        height: 'calc(1.5em + .5rem + 1px)',
        padding: '5px'
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
    }),
};

export class FieldWithValidation extends Component {
    render() {
        const {
            input, id, isDisabled, isSmall, type = 'text', style, placeholder, maxLength, handleNameChange, autoFocus,
            setRef = () => {
            }, meta: {touched, error, invalid}
        } = this.props;
        return (
            <div>
                <StyledInput {...input} type={type} placeholder={placeholder} maxLength={maxLength}
                             disabled={isDisabled}
                             className={'form-control ' + (isSmall ? ' form-control-sm' : '') + (touched && invalid ? ' is-invalid' : '')}
                             onKeyUp={(e) => handleNameChange && handleNameChange(e.target.value, id)}
                             autoFocus={autoFocus}
                             style={style}
                             ref={setRef}
                />
                {touched && error && <ErrorDiv className="invalid-feedback">
                    <ValidFormattedMessage message={error} intlMessages={validationMessages}/></ErrorDiv>
                }
            </div>
        );
    }
}

export const RenderSelectInput = ({ input, options, name, id, isMulti, isSearchable, isDisabled, isClearable, closeMenuOnSelect = true, meta: {touched, error, invalid}}) => {
    return (
        <>
            <Select
                {...input}
                id={id}
                name={name}
                options={options}
                onChange={value => input.onChange(value)}
                onBlur={() => input.onBlur(input.value)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                isSearchable={isSearchable}
                isClearable={isClearable}
                closeMenuOnSelect={closeMenuOnSelect}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: touched && invalid ? '#dc3545' : baseStyles.borderColor,
                    }),
                }}
            />
            {touched && error && <ErrorDiv className="invalid-feedback">
                <ValidFormattedMessage message={error} intlMessages={validationMessages}/></ErrorDiv>
            }
        </>
    )
};

export const RenderMultipleInput = ({ input, inputValue, placeholder, name, id,
                                        isMulti, isDisabled, isClearable, handleKeyDown, handleInputChange,
                                        handleChange, meta: { touched, error, invalid } }) => {

    const components = {
        DropdownIndicator: null,
    };

    return (
        <>
            <CreatableSelect
                {...input}
                id={id}
                name={name}
                components={components}
                isClearable={isClearable}
                isMulti={isMulti}
                isDisabled={isDisabled}
                menuIsOpen={false}
                onChange={handleChange}
                onBlur={() => {
                }}
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                inputValue={inputValue}
            />
            {touched && error && <ErrorDiv className="invalid-feedback">{validationMessages[error] ?
                <FormattedMessage {...validationMessages[error]} /> : error}</ErrorDiv>}
        </>
    );
};

FieldWithValidation.propTypes = {
    input: PropTypes.object.isRequired,
    id: PropTypes.any,
    isDisabled: PropTypes.bool,
    isSmall: PropTypes.bool,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    handleNameChange: PropTypes.func,
    autoFocus: PropTypes.bool
};

const SelectFieldWithValidation = props => {
    const {
        input, isDisabled, isSmall, options, intlMessages, intl, noEmpty, sort = true, customOptionsMsg,
        meta: {touched, error, invalid}
    } = props;

    let sortedOptions;
    if (customOptionsMsg) {
        sortedOptions = customOptionsMsg
            .filter(option => option && (typeof option === 'object' || option instanceof Object))
            .map((option) => ({
                id: option.uuid,
                translation: option.presetName
            }));
    } else if (options) {
        if(sort){
            sortedOptions = options.filter(option => option && (typeof option === 'string' || option instanceof String)).map((option) => ({
                id: option,
                translation: intlMessages ? (intlMessages[option] ? intl.formatMessage(intlMessages[option]) : option) : option
            })).sort((option1, option2) => option1.translation.localeCompare(option2.translation));
        } else {
            sortedOptions = options.filter(option => option && (typeof option === 'string' || option instanceof String)).map((option) => ({
                id: option,
                translation: intlMessages ? (intlMessages[option] ? intl.formatMessage(intlMessages[option]) : option) : option
            }));
        }
    } else {
        sortedOptions = [];
    }

    return (
        <div>
            <StyledSelect {...input}
                          disabled={isDisabled}
                          className={'form-control ' + (isSmall ? ' form-control-sm' : '') + (touched && invalid ? ' is-invalid' : '')}>
                {!noEmpty && <option></option>}
                {sortedOptions.length && sortedOptions.map((option, index) => <option key={index}
                                                                                      value={option.id}>{option.translation}</option>)}
                {!sortedOptions.some(option => option.id === input.value) && input.value !== '' && input.value !== undefined && input.value !== null &&
                <option disabled key={sortedOptions.length} value={input.value}>{`${input.value}(deprecated)`}</option>}
            </StyledSelect>
            {touched && error && <ErrorDiv className="invalid-feedback">
                <ValidFormattedMessage message={error} intlMessages={validationMessages}/></ErrorDiv>}
        </div>
    );
};
export default injectIntl(SelectFieldWithValidation);

SelectFieldWithValidation.propTypes = {
    input: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool,
    isSmall: PropTypes.bool,
    options: PropTypes.array,
    intlMessages: PropTypes.any,
    intl: PropTypes.any,
};

export const ReadOnlyField = props => {
    const {input: {value}, handleFieldClick} = props;
    return (
        <MaybeClickableDiv onClick={handleFieldClick}>
            <span>{Array.isArray(value) ? value.length : value}</span>
        </MaybeClickableDiv>
    );
};

ReadOnlyField.propTypes = {
    input: PropTypes.object.isRequired,
    handleFieldClick: PropTypes.func
};

export const SwitchableField = props => {
    const {input, handleFieldClick, type, meta: {touched, error, invalid}} = props;
    return (
        <div onClick={handleFieldClick}>
            <ClickableInput {...input} type={type}
                            className={'form-control ' + (touched && invalid ? ' is-invalid' : '')}/>
            {touched && error && <ErrorDiv className="invalid-feedback">{validationMessages[error] ?
                <FormattedMessage {...validationMessages[error]}/> : error}</ErrorDiv>}
        </div>
    );
};

SwitchableField.propTypes = {
    input: PropTypes.object.isRequired,
    handleFieldClick: PropTypes.func,
    type: PropTypes.string.isRequired
};

export const AsyncSelectField = props => {
    const {
        input, placeholder = '', isMulti, noOptionsMessage, menuPosition = 'absolute', loadOptions, defaultValue,
        isClearable, loadingMessage, useCustomStyles = () => {}
    } = props;

    return (
        <AsyncSelect {...input}
                     loadOptions={debounce(DEFAULT_DEBOUNCE_TIMEOUT, loadOptions)}
                     onBlur={() => input.onBlur(input.value)}
                     value={input.value ? {value: input.value, label: input.value} : ''}
                     defaultValue={defaultValue}
                     placeholder={placeholder}
                     noOptionsMessage={noOptionsMessage}
                     loadingMessage={loadingMessage}
                     onChange={event => event ? input.onChange(event.value) : input.onChange(null)}
                     isClearable={isClearable}
                     styles={useCustomStyles ? customSelectFormStyles : undefined}
                     isMulti={isMulti}
                     menuPosition={menuPosition}
        />
    );
};

AsyncSelectField.propTypes = {
    input: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    isMulti: PropTypes.bool,
    noOptionsMessage: PropTypes.func.isRequired,
    menuPosition: PropTypes.string,
    loadOptions: PropTypes.func.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    isClearable: PropTypes.bool,
    useCustomStyles: PropTypes.bool,
    loadingMessage: PropTypes.string
};
