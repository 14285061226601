import v4 from 'uuid/v4';
import {extractErrorText} from './actionUtils';
import {errorAlert} from './alertsActions';
import {
    createFlow as restPost,
    deleteFlow as restDelete,
    loadFlow as restGetDetail,
    loadFlows as restGet,
    saveFlow as restPut
} from '../api/restFlow';
import {loadSolutionGroups as restGetSolutionGroups} from '../api/restSolutionGroup';
import {
    transformCreateEventToFlow,
    transformFlowToCreateEvent,
    transformFlowToUpdateEvent
} from '../utils/transformer/flowEventTransformer';
import {validateFlow, validateFlowCreateEvent, validateFlows, validateFlowUpdateEvent} from '../api/validation/flow';
import {validateSolutionGroups} from '../api/validation/solutionGroup';
import alertMessages from '../intl/common/alertMessages';

export const FLOW_LIST_FETCH_PENDING = 'FLOW_LIST_FETCH_PENDING';
export const FLOW_LIST_FETCH_SUCCESS = 'FLOW_LIST_FETCH_SUCCESS';
export const FLOW_LIST_FETCH_ERROR = 'FLOW_LIST_FETCH_ERROR';
export const FLOW_DELETE_PENDING = 'FLOW_DELETE_PENDING';
export const FLOW_DELETE_SUCCESS = 'FLOW_DELETE_SUCCESS';
export const FLOW_DELETE_ERROR = 'FLOW_DELETE_ERROR';
export const FLOW_DETAIL_FETCH_PENDING = 'FLOW_DETAIL_FETCH_PENDING';
export const FLOW_DETAIL_FETCH_SUCCESS = 'FLOW_DETAIL_FETCH_SUCCESS';
export const FLOW_DETAIL_FETCH_ERROR = 'FLOW_DETAIL_FETCH_ERROR';
export const FLOW_SAVE_NEW_PENDING = 'FLOW_SAVE_NEW_PENDING';
export const FLOW_SAVE_NEW_SUCCESS = 'FLOW_SAVE_NEW_SUCCESS';
export const FLOW_SAVE_NEW_ERROR = 'FLOW_SAVE_NEW_ERROR';
export const FLOW_SAVE_EXISTING_PENDING = 'FLOW_SAVE_EXISTING_PENDING';
export const FLOW_SAVE_EXISTING_SUCCESS = 'FLOW_SAVE_EXISTING_SUCCESS';
export const FLOW_SAVE_EXISTING_ERROR = 'FLOW_SAVE_EXISTING_ERROR';
export const FLOW_STORE_SAVED = 'FLOW_STORE_SAVED';
export const FLOW_ADD_SAVED_TO_LIST = 'FLOW_ADD_SAVED_TO_LIST';

export const fetchFlowListPageData = () => dispatch => {
    dispatch({type: FLOW_LIST_FETCH_PENDING});

    return restGet().then(
        ({data}) => {
            const {error} = validateFlows(data);
            if (error) {
                dispatch({type: FLOW_LIST_FETCH_ERROR});
                dispatch(errorAlert(alertMessages.FLOW_LIST_RETRIEVED_ERROR, [error.toString()]));
            } else {
                dispatch({
                    type: FLOW_LIST_FETCH_SUCCESS,
                    payload: data.map(flow => transformCreateEventToFlow(flow))
                });
            }
        },
        error => {
            dispatch({type: FLOW_LIST_FETCH_ERROR});
            dispatch(errorAlert(...extractErrorText(error, alertMessages.FLOW_LIST_FETCH_ERROR)));
        }
    );
};

export const deleteFlow = flowId => dispatch => {
    dispatch({type: FLOW_DELETE_PENDING});

    restDelete(flowId).then(
        () => dispatch({type: FLOW_DELETE_SUCCESS, payload: flowId}),
        error => {
            dispatch({type: FLOW_DELETE_ERROR});
            dispatch(errorAlert(...extractErrorText(error, alertMessages.FLOW_DELETE_ERROR)));
        }
    );
};

export const fetchFlowDetailPageData = flowId => dispatch => {
    dispatch({type: FLOW_DETAIL_FETCH_PENDING});

    restGetSolutionGroups().then(
        ({data}) => {
            const {error} = validateSolutionGroups(data);
            if (error) {
                dispatch({type: FLOW_DETAIL_FETCH_ERROR});
                dispatch(errorAlert(alertMessages.SOLUTION_GROUPS_RETRIEVED_ERROR, [error.toString()]));
            } else {
                const availableSolutionGroups = data.map(({id, name}) => ({id, name}));

                if (flowId) {
                    restGetDetail(flowId).then(
                        ({data}) => {
                            const {error} = validateFlow(data);
                            if (error) {
                                dispatch({type: FLOW_DETAIL_FETCH_ERROR});
                                dispatch(errorAlert(alertMessages.FLOW_RETRIEVED_ERROR, [error.toString()]));
                            } else {
                                const retrievedFlow = transformCreateEventToFlow(data);
                                dispatch({
                                    type: FLOW_DETAIL_FETCH_SUCCESS,
                                    payload: Object.assign(
                                        retrievedFlow,
                                        {
                                            availableSolutionGroups: availableSolutionGroups.filter(
                                                solutionGroup => !retrievedFlow.items.find(item => item.id === solutionGroup.id)
                                            )
                                        }
                                    )
                                });
                            }
                        },
                        error => {
                            dispatch({type: FLOW_DETAIL_FETCH_ERROR});
                            dispatch(errorAlert(...extractErrorText(error, alertMessages.FLOW_FETCH_ERROR)));
                        }
                    );
                } else {
                    dispatch({
                        type: FLOW_DETAIL_FETCH_SUCCESS,
                        payload: {id: v4(), name: '', items: [], availableSolutionGroups}
                    });
                }
            }
        },
        error => {
            dispatch({type: FLOW_DETAIL_FETCH_ERROR});
            dispatch(errorAlert(...extractErrorText(error, alertMessages.SOLUTION_GROUPS_FETCH_ERROR)));
        }
    );
};

export const saveNewFlow = flow => dispatch => {
    dispatch({type: FLOW_SAVE_NEW_PENDING});

    const flowCreateEvent = transformFlowToCreateEvent(flow);
    const {error} = validateFlowCreateEvent(flowCreateEvent);
    if (error) {
        dispatch({type: FLOW_SAVE_NEW_ERROR});
        dispatch(errorAlert(alertMessages.FLOW_NEW_SEND_ERROR, [error.toString()]));
    } else {
        return restPost(flowCreateEvent).then(
            () => {
                dispatch({type: FLOW_STORE_SAVED, payload: flow});
                dispatch({type: FLOW_SAVE_NEW_SUCCESS});
            },
            error => {
                dispatch({type: FLOW_SAVE_NEW_ERROR});
                dispatch(errorAlert(...extractErrorText(error, alertMessages.FLOW_NEW_SAVE_ERROR)));
            }
        );
    }
};

export const saveExistingFlow = (flow, originalFlow) => dispatch => {
    dispatch({type: FLOW_SAVE_EXISTING_PENDING});

    const flowUpdateEvent = transformFlowToUpdateEvent(flow, originalFlow);
    const {error} = validateFlowUpdateEvent(flowUpdateEvent);
    if (error) {
        dispatch({type: FLOW_SAVE_EXISTING_ERROR});
        dispatch(errorAlert(alertMessages.FLOW_SEND_ERROR, [error.toString()]));
    } else {
        return restPut(flow.id, flowUpdateEvent).then(
            () => {
                dispatch({type: FLOW_STORE_SAVED, payload: flow});
                dispatch({type: FLOW_SAVE_EXISTING_SUCCESS});
            },
            error => {
                dispatch({type: FLOW_SAVE_EXISTING_ERROR});
                dispatch(errorAlert(...extractErrorText(error, alertMessages.FLOW_SAVE_ERROR)));
            }
        );
    }
};

export const addSavedFlowToFlowList = () => dispatch => {
    dispatch({type: FLOW_ADD_SAVED_TO_LIST});
    dispatch({type: FLOW_STORE_SAVED, payload: {}});
};
