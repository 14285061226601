import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Route} from 'react-router-dom';
import tableMessages from '../../../intl/common/tableMessages';
import {StyledReactTable, StyledReactTableWithoutId} from '../../../theme/styledTable'
import TableHeader from './TableHeader';
import {moduleRoles} from "../../../utils/roles";
import buttonMessages from "../../../intl/common/buttonMessages";
import Pagination from './Pagination';
import get from 'get-value';
import {
    ALLOCATION_REQUEST_FORM,
    CODED_PARTS_KEYS_FORM,
    DELIVERY_DATE_REMINDER_FORM, EV_BATTERY_FORM,
    ORDER_CANCELLATION_FORM,
    ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
    OVAL_PLATE_ORDER_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
    UNKNOWN_PART_OPENING_REQUEST_FORM, VOR_PVI_ORDER_FORM
} from '../../../constants/formConstants';
import {
    PART_DOC_REQUEST, PRICE_REQUEST,
    REF_REPLACEMENT_REQUEST,
    REQUEST_TO_OPEN_FOR_SALE,
    VISUAL_STORE_CHECK
} from '../../../constants/Utils';

const ButtonDiv = styled.div`
  display: table-cell;
`;

class TicketTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            role: moduleRoles.isDealerOrder(this.props.userRoles),
            activePage: 1,
        };
        this.setActivePage = this.setActivePage.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        const {defaultSorting = []} = this.props;
        this.setState({
            sorted: defaultSorting
        });
    }

    setActivePage(page) {
        this.setState({activePage: page})
    };

    hasData = () => !this.props.isLoading && this.props.data && this.props.data.length !== 0;

    fetchData = (state) => {
        this.props.handleFetchData(state);
        const page = state.page === 0 ? 1 : state.page;
        this.setState({activePage: page});
    };

    isRowDisabled = row => {
        const { dealerNumber, editorGroups,sgPermissions, checkInternationalTicketsForSGEditors } = this.props
        const ticketGroup = get(row, 'original.ticketBase.group') || get(row, 'original.ticket.group') || get(row, 'original.group');

        if (this.ticketInNonEditableState(row.original)) {
            return true
        } else if (editorGroups && !sgPermissions && !(editorGroups || []).includes(ticketGroup)){
            return true
        } else if (dealerNumber && dealerNumber !== 'EDITOR'){
            if(row.original.ticketBase) {
                if(row.original.ticketBase.dealerNumber != dealerNumber){
                    return true
                }
            } else if (row.original.ticket) {
                if(row.original.ticket.dealerNumber != dealerNumber){
                    return true
                }
            }
        }
        const sgTicketBase = get(row, 'original.ticketBase.solutionGroup.id');
        const sgTicket = get(row, 'original.ticket.solutionGroup.id');
        const sgTicketInternal = get(row, 'original.assignedSolutionGroup.id');
        const sgTicketList = get(row, 'original.solutionGroup.id');
        const ticketSgGroupId = sgTicketBase || sgTicketInternal || sgTicket || sgTicketList;
        if (sgPermissions) {
            const userGroupIds = sgPermissions.map(perm => perm.id);
            if (ticketSgGroupId && !userGroupIds.includes(ticketSgGroupId)) {
                // ticket is assigned on group which user is not member of
                return true
            }
            if(checkInternationalTicketsForSGEditors && !ticketSgGroupId && !(sgPermissions.find(sg => (get(sg, 'groups', {default: []}) || []).includes(ticketGroup)) || (editorGroups && (editorGroups || []).includes(ticketGroup)))){
                // check international ticket that is not assigned but user has to be SGDefaultEditor/Editor of that tickets country to edit
                return true
            }
        } else if(ticketSgGroupId) {
            // ticket has solution group but user is not sgEditor
            return true
        }
        return false
    }

    shouldBeBold = (item, disabledRowIds) => {
        const formType = item.typeOfRequest || item.formType || item.orderType || item.docType;

        if(disabledRowIds.includes(item.id)) {
            return false;
        }
        switch (formType) {
            case SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM:
            case SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM:
            case UNKNOWN_PART_OPENING_REQUEST_FORM: {
                return ['YES', 'PRESIDENT'].includes(get(item, 'srcRequest')) || item.pviRelated;
            }
            case CODED_PARTS_KEYS_FORM: {
                return ['YES', 'PRESIDENT'].includes(get(item, 'srcRequest')) || ['YES'].includes(get(item, 'immobilizedVehicle')) || item.pviRelated;
            }
            case OVAL_PLATE_ORDER_FORM:
                return false;
            case VISUAL_STORE_CHECK:
            case REQUEST_TO_OPEN_FOR_SALE:
            case PART_DOC_REQUEST:
            case REF_REPLACEMENT_REQUEST:
            case PRICE_REQUEST:
                return ['YES'].includes(get(item, 'priorityTreatment'));
            case VOR_PVI_ORDER_FORM:
            case ALLOCATION_REQUEST_FORM:
                return ['YES', 'PRESIDENT'].includes(get(item, 'srcRequest'));
            case DELIVERY_DATE_REMINDER_FORM:
            case ORDER_CANCELLATION_FORM:
            case ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM:
            case EV_BATTERY_FORM:
                return false;
            default:
                return false;
        }
    };

    getTableParams = () => {
        const {
            intl, isLoading = false, withPagination = true, columns, data, toolButtons = [], isNextPage, isNextFromPivot,
            handleOnFilteredChange, pages, handlerOnSortChange, allowTooltipsInHeader = false, defaultPageSize = 10,
            toolSortable = false
        } = this.props;

        const decoratedColumns = columns.map(
            column =>
                Object.assign({}, column,
                    {
                        Header: () => <TableHeader id={column.id} message={column.message}
                                                   filterable={column.filterable}
                                                   sorted={this.state.sorted ? this.state.sorted : []}/>,

                        filterMethod: (filter, row) => {
                            const id = filter.pivotId || filter.id;
                            return row[id] !== undefined ?
                                String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
                                : true;
                        }
                    })
        );

        const disabledRowIds = []
        data.forEach(d => {
            if(this.isRowDisabled({ original: d }))
                disabledRowIds.push(d.id)
            }
        )

        if (toolButtons.length > 0) {
            decoratedColumns.unshift({
                id: 'TOOLS',
                accessor: 'tools',
                filterable: toolSortable,
                sortable: toolSortable,
                message: toolSortable ? tableMessages.TABLE_TOOLS : undefined,
                maxWidth: toolButtons.length * 41,
                Header: () => toolSortable ? (<TableHeader id={'TOOLS'} message={tableMessages.TABLE_TOOLS}
                                                         filterable={toolSortable}
                                                         sorted={this.state.sorted ? this.state.sorted : []}/>) : undefined,
                Cell: row => {
                    return <React.Fragment>
                        {
                            toolButtons.map((toolButton, index) => {
                                let toolButtonObject = toolButton;

                                if (toolButtonObject instanceof Function) {
                                    toolButtonObject = toolButtonObject(row);
                                }

                                if(this.isRowDisabled(row)) {
                                    disabledRowIds.push(get(row, "original.id"))
                                    return <div key={index}/>;
                                }

                                if (toolButton.path) {
                                    return <Route key={`${row.original.id}route${index}`}
                                                  render={({history, history: {location: {pathname}}}) => (
                                                      <ButtonDiv key={`${row.original.id}button${index}`}
                                                                 onClick={() => history.push(
                                                                     `${pathname}/${toolButton.path.base}/${row.original[toolButton.path.rowField]}`
                                                                 )}>
                                                          {toolButtonObject.component}
                                                      </ButtonDiv>
                                                  )}/>;
                                } else {
                                    return <ButtonDiv key={`${row.original.id}button${index}`}
                                                      onClick={() => toolButtonObject.handleClick(row.original, toolButtonObject.onClickCallback)}>
                                        {toolButtonObject.component}
                                    </ButtonDiv>;
                                }
                            })
                        }
                    </React.Fragment>;
                }
            });
        }

        const modifiedData = data.map(item => (this.shouldBeBold(item, disabledRowIds) ? {...item, style: {"fontWeight": "bold"} } : item));

        return {
            columns: decoratedColumns,
            manual: true,
            loading: isLoading,
            data: modifiedData,
            pages: pages,
            filterable: true,
            showPagination: (withPagination && this.hasData()),
            minRows: (this.hasData() ? 1 : 4),
            firstText: <FormattedMessage {...buttonMessages.FIRST}/>,
            previousText: <FormattedMessage {...buttonMessages.PREVIOUS}/>,
            nextText: <FormattedMessage {...buttonMessages.NEXT}/>,
            lastText: <FormattedMessage {...buttonMessages.LAST}/>,
            noDataText: <FormattedMessage {...tableMessages.TABLE_NO_DATA}/>,
            pageText: <FormattedMessage {...tableMessages.TABLE_PAGE}/>,
            ofText: <FormattedMessage {...tableMessages.TABLE_OF}/>,
            rowsText: intl.formatMessage(tableMessages.TABLE_ROWS),
            className: "-highlight",
            onFetchData: ((state, instance) => {this.fetchData(state, instance);}),
            onSortedChange: (handlerOnSortChange === undefined ? (sorted => this.setState({sorted})) : handlerOnSortChange),
            onFilteredChange: (handleOnFilteredChange === undefined ? () => null : handleOnFilteredChange),
            defaultFiltered: (this.props.defaultFiltered === undefined ? [] : this.props.defaultFiltered),
            defaultSorted: (this.props.defaultSorting === undefined ? [] : this.props.defaultSorting),
            defaultPageSize: defaultPageSize,
            getTheadFilterThProps: (allowTooltipsInHeader ? () => ({style: {overflow: "inherit"}}) : () => ({})),
            PaginationComponent: Pagination,
            getPaginationProps: ((state, rowInfo, column, instance) => {
                return {
                    isNextPage,
                    isNextFromPivot,
                    activePage: this.state.activePage > pages ? pages : this.state.activePage,
                }
            }),
            getTrProps: ((state, row) => {
                if(get(row, 'original.style')) {
                    return { style: get(row, 'original.style') }
                } else {
                    return {}
                }
            })
        }
    }

    render() {
        const {
            toolSortable = false,
        } = this.props;

        return (
            toolSortable ?
                    <StyledReactTableWithoutId {...this.getTableParams()} />
                :
                <StyledReactTable {...this.getTableParams()} />

        );
    }

    ticketInNonEditableState(original) {
        const status = get(original, 'ticketBase.status') || get(original, 'ticket.status') || get(original, 'status');
        return this.props.buttonVisibility && !this.props.buttonVisibility.includes(status);
    }
}

TicketTable.propTypes = {
    intl: PropTypes.any.isRequired,
    isLoading: PropTypes.bool,
    withPagination: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    handleFetchData: PropTypes.func,
    toolButtons: PropTypes.array,
    editorGroups: PropTypes.any,
    defaultSorting: PropTypes.array,
    allowTooltipsInHeader: PropTypes.bool,
    checkInternationalTicketsForSGEditors: PropTypes.any
};

export default injectIntl(TicketTable);
