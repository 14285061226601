import {
    ORDER_TICKET_STATUS_NEW,
    ORDER_TICKET_STATUS_IN_PROGRESS_DEALER,
    ORDER_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    ORDER_TICKET_STATUS_READ_BY_SUBSIDIARY,
    ORDER_TICKET_STATUS_REOPENED, ORDER_TICKET_STATUS_TRANSFERRED
} from './formConstants';

export const editRights = {
    DEALER: {
        NEW: {
            canAddAttach: true,
            canAddNotes: true,
            canChangeToReadBySubs: false,
            canClose: true,
            canContactDic: false,
            canEditAuthReply: false,
            canEditClientId: true,
            canEditCourCarReq: true,
            canEditCourCarType: false,
            canEditDic: false,
            canEditItems: true,
            canEditItemsQuantity: true,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: false,
            canEditRemarks: false,
            canEditRepairOrderNumber: true,
            canEditVehDetails: true,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: false,
            canEditBatteryInvoiceNumber: false,
            canEditVehDetailsRepairCarLocation: true,
            canEditSuppInformations: true,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canTipOverToPvi: true,
            canViewAuthReply: false,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canAnswer: false,
            canEscalate: false,
            canViewInternalTicketReferences: false,
        },
        CLOSED: {
            canAddAttach: false,
            canAddNotes: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canContactDic: false,
            canEditAuthReply: false,
            canEditClientId: false,
            canEditCourCarReq: false,
            canEditCourCarType: false,
            canEditDic: false,
            canEditItems: false,
            canEditItemsQuantity: false,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: false,
            canEditRemarks: false,
            canEditRepairOrderNumber: false,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: false,
            canEditBatteryInvoiceNumber: false,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: true,
            canSave: false,
            canTipOverToPvi: true,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canAnswer: false,
            canEscalate: false,
            canViewInternalTicketReferences: false,
        },
        READ_BY_SUBSIDIARY: {
            canAddAttach: false,
            canAddNotes: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canContactDic: false,
            canEditAuthReply: false,
            canEditClientId: false,
            canEditCourCarReq: false,
            canEditCourCarType: false,
            canEditDic: false,
            canEditItems: false,
            canEditItemsQuantity: false,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: false,
            canEditRemarks: false,
            canEditRepairOrderNumber: false,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: false,
            canEditBatteryInvoiceNumber: false,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canTipOverToPvi: true,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canAnswer: false,
            canEscalate: false,
            canViewInternalTicketReferences: false,
        },
        WAIT_SUBSIDIARY: {
            canAddAttach: false,
            canAddNotes: false,
            canChangeToReadBySubs: false,
            canClose: true,
            canContactDic: false,
            canEditAuthReply: false,
            canEditClientId: false,
            canEditCourCarReq: false,
            canEditCourCarType: false,
            canEditDic: false,
            canEditItems: false,
            canEditItemsQuantity: false,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: false,
            canEditRemarks: false,
            canEditRepairOrderNumber: false,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: false,
            canEditBatteryInvoiceNumber: false,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canTipOverToPvi: true,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canAnswer: false,
            canEscalate: false,
            canViewInternalTicketReferences: false,
        },
        WAIT_DEALER: {
            canAddAttach: true,
            canAddNotes: true,
            canChangeToReadBySubs: false,
            canClose: true,
            canContactDic: false,
            canEditAuthReply: false,
            canEditClientId: false,
            canEditCourCarReq: false,
            canEditCourCarType: false,
            canEditDic: false,
            canEditItems: true,
            canEditItemsQuantity: true,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: false,
            canEditRemarks: false,
            canEditRepairOrderNumber: false,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: false,
            canEditBatteryInvoiceNumber: false,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: false,
            canForwardToSubs: true,
            canReopen: false,
            canSave: true,
            canTipOverToPvi: true,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canAnswer: false,
            canEscalate: false,
            canViewInternalTicketReferences: false,
        },
        REOPEN: {
            canAddAttach: true,
            canAddNotes: true,
            canChangeToReadBySubs: false,
            canClose: true,
            canContactDic: false,
            canEditAuthReply: false,
            canEditClientId: false,
            canEditCourCarReq: false,
            canEditCourCarType: false,
            canEditDic: false,
            canEditItems: false,
            canEditItemsQuantity: false,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: false,
            canEditRemarks: false,
            canEditRepairOrderNumber: false,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: false,
            canEditBatteryInvoiceNumber: false,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: false,
            canForwardToSubs: true,
            canReopen: false,
            canSave: true,
            canTipOverToPvi: true,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canAnswer: false,
            canEscalate: false,
            canViewInternalTicketReferences: false,
        },
        TRANSFERRED: {
            canAddAttach: false,
            canAddNotes: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canContactDic: false,
            canEditAuthReply: false,
            canEditClientId: false,
            canEditCourCarReq: false,
            canEditCourCarType: false,
            canEditDic: false,
            canEditItems: false,
            canEditItemsQuantity: false,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: false,
            canEditRemarks: false,
            canEditRepairOrderNumber: false,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: false,
            canEditBatteryInvoiceNumber: false,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canTipOverToPvi: false,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: false,
            canReadEditorsNotes: false,
            canAnswer: false,
            canEscalate: false,
            canViewInternalTicketReferences: false,
        }
    },
    EDITOR: {
        NEW: {
            canAddAttach: true,
            canAddNotes: true,
            canChangeToReadBySubs: true,
            canClose: false,
            canContactDic: true,
            canEditAuthReply: true,
            canEditClientId: true,
            canEditCourCarReq: false,
            canEditCourCarType: true,
            canEditDic: true,
            canEditItems: false,
            canEditItemsQuantity: true,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: true,
            canEditRemarks: true,
            canEditRepairOrderNumber: true,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: true,
            canEditBatteryInvoiceNumber: true,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canTipOverToPvi: false,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canAnswer: false,
            canEscalate: false,
            canViewInternalTicketReferences: true,
        },
        CLOSED: {
            canAddAttach: false,
            canAddNotes: false,
            canChangeToReadBySubs: false,
            canClose: false,
            canContactDic: false,
            canEditAuthReply: false,
            canEditClientId: false,
            canEditCourCarReq: false,
            canEditCourCarType: false,
            canEditDic: false,
            canEditItems: false,
            canEditItemsQuantity: true,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: false,
            canEditRemarks: true,
            canEditRepairOrderNumber: true,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: false,
            canEditBatteryInvoiceNumber: false,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: true,
            canSave: false,
            canTipOverToPvi: false,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: false,
            canReadEditorsNotes: true,
            canAnswer: false,
            canEscalate: false,
            canViewInternalTicketReferences: true,
        },
        READ_BY_SUBSIDIARY: {
            canAddAttach: true,
            canAddNotes: true,
            canChangeToReadBySubs: false,
            canClose: true,
            canContactDic: true,
            canEditAuthReply: true,
            canEditClientId: true,
            canEditCourCarReq: false,
            canEditCourCarType: true,
            canEditDic: true,
            canEditItems: false,
            canEditItemsQuantity: true,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: true,
            canEditRemarks: true,
            canEditRepairOrderNumber: true,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: true,
            canEditBatteryInvoiceNumber: true,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: true,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canTipOverToPvi: false,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canAnswer: true,
            canEscalate: true,
            canViewInternalTicketReferences: true,
        },
        WAIT_SUBSIDIARY: {
            canAddAttach: true,
            canAddNotes: true,
            canChangeToReadBySubs: true,
            canClose: true,
            canContactDic: true,
            canEditAuthReply: true,
            canEditClientId: false,
            canEditCourCarReq: false,
            canEditCourCarType: true,
            canEditDic: true,
            canEditItems: false,
            canEditItemsQuantity: true,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: true,
            canEditRemarks: true,
            canEditRepairOrderNumber: true,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: true,
            canEditBatteryInvoiceNumber: true,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: false,
            canTipOverToPvi: false,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canAnswer: false,
            canEscalate: false,
            canViewInternalTicketReferences: true,
        },
        WAIT_DEALER: {
            canAddAttach: true,
            canAddNotes: true,
            canChangeToReadBySubs: false,
            canClose: false,
            canContactDic: true,
            canEditAuthReply: true,
            canEditClientId: false,
            canEditCourCarReq: false,
            canEditCourCarType: true,
            canEditDic: true,
            canEditItems: false,
            canEditItemsQuantity: true,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: true,
            canEditRemarks: true,
            canEditRepairOrderNumber: true,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: false,
            canEditBatteryInvoiceNumber: false,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: false,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: true,
            canForwardToDealer: false,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canTipOverToPvi: false,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canAnswer: false,
            canEscalate: false,
            canViewInternalTicketReferences: true,
        },
        REOPEN: {
            canAddAttach: true,
            canAddNotes: true,
            canChangeToReadBySubs: false,
            canClose: false,
            canContactDic: true,
            canEditAuthReply: true,
            canEditClientId: false,
            canEditCourCarReq: false,
            canEditCourCarType: true,
            canEditDic: true,
            canEditItems: false,
            canEditItemsQuantity: true,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: true,
            canEditRemarks: true,
            canEditRepairOrderNumber: true,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: false,
            canEditBatteryInvoiceNumber: false,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: true,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canTipOverToPvi: false,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canAnswer: false,
            canEscalate: true,
            canViewInternalTicketReferences: true,
        },
        TRANSFERRED: {
            canAddAttach: true,
            canAddNotes: true,
            canChangeToReadBySubs: false,
            canClose: true,
            canContactDic: true,
            canEditAuthReply: true,
            canEditClientId: false,
            canEditCourCarReq: false,
            canEditCourCarType: true,
            canEditDic: true,
            canEditItems: false,
            canEditItemsQuantity: true,
            canEditDealerAddress: false,
            canEditDeliveryContactName: false,
            canEditTelNo: false,
            canEditMpr: true,
            canEditRemarks: true,
            canEditRepairOrderNumber: true,
            canEditVehDetails: false,
            canEditBatteryDetails: false,
            canEditBatteryProblemDesc: false,
            canEditManagementGroup: true,
            canEditBatteryInvoiceNumber: true,
            canEditVehDetailsRepairCarLocation: false,
            canEditSuppInformations: true,
            canEditSuppInformationsText: false,
            canForwardBackToSubs: false,
            canForwardToDealer: true,
            canForwardToSubs: false,
            canReopen: false,
            canSave: true,
            canTipOverToPvi: false,
            canViewAuthReply: true,
            canViewMpr: true,
            canEditBvm: false,

            canAddEditorsNotes: true,
            canReadEditorsNotes: true,
            canAnswer: true,
            canEscalate: true,
            canViewInternalTicketReferences: true,
        }
    }
};

export const createRights = {
    canAddAttach: true,
    canAddNotes: true,
    canChangeToReadBySubs: false,
    canClose: false,
    canContactDic: false,
    canEditAuthReply: false,
    canEditClientId: true,
    canEditCourCarReq: true,
    canEditCourCarType: true,
    canEditDic: false,
    canEditItems: true,
    canEditItemsQuantity: true,
    canEditDealerAddress: true,
    canEditDeliveryContactName: true,
    canEditTelNo: true,
    canEditMpr: false,
    canEditRemarks: true,
    canEditRepairOrderNumber: true,
    canEditVehDetails: true,
    canEditBatteryDetails: true,
    canEditBatteryProblemDesc: true,
    canEditManagementGroup: false,
    canEditBatteryInvoiceNumber: false,
    canEditVehDetailsRepairCarLocation: true,
    canEditSuppInformations: true,
    canEditSuppInformationsText: true,
    canForwardBackToSubs: false,
    canForwardToDealer: false,
    canForwardToSubs: false,
    canReopen: false,
    canSave: true,
    canTipOverToPvi: false,
    canViewAuthReply: false,
    canViewMpr: false,
    canEditBvm: true,
    canAddEditorsNotes: false,
    canReadEditorsNotes: false,
    canAnswer: false,
    canEscalate: false,
    canViewInternalTicketReferences: false,
};

export const editOrderListButtonVisibility = {
    DEALER: [
        ORDER_TICKET_STATUS_NEW,
        ORDER_TICKET_STATUS_IN_PROGRESS_DEALER,
        ORDER_TICKET_STATUS_REOPENED
    ],
    EDITOR: [
        ORDER_TICKET_STATUS_NEW,
        ORDER_TICKET_STATUS_IN_PROGRESS_DEALER,
        ORDER_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
        ORDER_TICKET_STATUS_READ_BY_SUBSIDIARY,
        ORDER_TICKET_STATUS_TRANSFERRED,
        ORDER_TICKET_STATUS_REOPENED
    ]
};


