import {put, takeEvery} from 'redux-saga/effects';
import {sparePartsWebsocketCommands} from '../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../constants/claimsWebsocketCommands';
import {confirmOrderPopUpModal} from '../actions/orders/actionOrderPopUpModal';
import {confirmDocPopUpModal} from '../actions/techDoc/actionDocPopUpModal';
import {confirmClaimsPopUpModal} from '../actions/claims/actionClaimsPopUpModal';
import get from 'get-value';

export function* handleOrderPopUpSend(action) {
    if (!get(action, 'payload.popup.disabled', false)) {
        yield put(confirmOrderPopUpModal());
    }
}

export function* handleDocPopUpSend(action) {
    if (!get(action, 'payload.popup.disabled', false)) {
        yield put(confirmDocPopUpModal());
    }
}

export function* handleClaimsPopUpSend(action) {
    if (!get(action, 'payload.popup.disabled', false)) {
        yield put(confirmClaimsPopUpModal());
    }
}

function* dealerPopUpSaga() {
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_POPUP_SEND, handleOrderPopUpSend);
    yield takeEvery(techDocWebsocketCommands.TECH_DOC_POPUP_SEND, handleDocPopUpSend);
    yield takeEvery(claimsWebsocketCommands.CLAIMS_POPUP_SEND, handleClaimsPopUpSend);
}

export default dealerPopUpSaga;
