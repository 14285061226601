import {FILE, YOUTUBE_VIDEO} from '../../HelpConst';

const values = [
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to use the List Menu ?',
        href: 'https://youtu.be/bjPOWyPGTuw'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to use the History Menu ?',
        href: 'https://youtu.be/CYjzEg0AD4A'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to find a specific ticket with the search engine for Local Expertise ?',
        href: 'https://youtu.be/sgV5UqymXEg'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to find a specific ticket with the search engine ?',
        href: 'https://youtu.be/EjeQc1TkieY'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to export tickets in excel file ?',
        href: 'https://youtu.be/u-4286Q7NoU'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to process a dealer ticket - Local Expertise ?',
        href: 'https://youtu.be/n8JPnqxzw68'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to process a dealer ticket - Expertise level 2 with Answer to dealer option',
        href: 'https://youtu.be/O_CpoSEYMeI'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to process a dealer ticket - Expertise level 2',
        href: 'https://youtu.be/eowbQcMG9Xw'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to process a dealer ticket when you are " expertise level 3" ?',
        href: 'https://youtu.be/_SUh7lc9530'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to create a new internal ticket from a dealer ticket ?',
        href: 'https://youtu.be/gTfXQmOykbg'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to create an internal ticket',
        href: 'https://youtu.be/TUARtyt7uQQ'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to process an internal ticket that you created ?',
        href: 'https://youtu.be/EjSeojuyJlg'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to process an internal ticket - Expertise level 2 and 3 ?',
        href: 'https://youtu.be/ETrk6aNG2F4'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'New HTL - How to find a specific ticket with the search engine for internal tickets',
        href: 'https://youtu.be/vAFtJSanuwc'
    },
    {
        type: YOUTUBE_VIDEO,
        label: 'How to add and delete a User',
        href: 'https://youtu.be/phNXKSWZlKM'
    },
];

export default values;
