import React from 'react';
import get from 'get-value';
import styled from 'styled-components';
import renaultLogo from '../images/RENAULT_LOGO_english.png';
import {Flex, Image, Margin} from './common/StyledComponents';
import {NavLink, withRouter} from 'react-router-dom';
import {PRIMARY_DARK_GREY, PRIMARY_YELLOW} from '../theme/colors';
import renaultCar from '../images/car.png';
import {HOME_PATH} from '../routes/paths';

const Title = styled.h1`
    margin: 0;
    margin-top: 5px;
`;
Title.displayName = 'Title';

const TopNavigation = styled.nav`
    font-family: sans-serif;
    font-weight: bold;
    padding: 1px 10px;
    background-color: ${PRIMARY_DARK_GREY};
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: white;
    padding: 8px;

    @media (max-height: 450px) {
        display: none;
    };
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const MenuLink = styled(NavLink)`
    :visited,
    :link {
        color: rgba(255, 255, 255, 0.85);
    }
    :active,
    :hover {
        background-color: ${PRIMARY_YELLOW};
        color: black;
    }
    transition: all 0.5s ease;

    && {
        padding: 0.6em 1em;
        margin: 5px;
    }

    &.${'nav-item-active'} {
        background-color: ${PRIMARY_YELLOW};
        color: black;
    }
`;

const Main = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
`;

const Img = styled.img`
    padding-top: 16px;
    height: 35%;
    width: 35%;
`;
const Header = (props) => (
    <Wrapper>
        <HeaderWrapper>
            <Flex>
                <Margin left={15}>
                    <Image height={60} src={renaultLogo}/>
                </Margin>
                <Margin left={15}>
                    <Title>Dialog: New Hotline</Title>
                </Margin>
            </Flex>
        </HeaderWrapper>
        <TopNavigation className="navbar navbar-expand-md nav-pills">
            <MenuLink exact className="nav-link ml-auto" to={HOME_PATH} activeClassName="nav-item-active">
                Log In
            </MenuLink>
        </TopNavigation>
        <Main>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h1 className="text-center">{get(props, 'location.state.err.error_description') || get(props, 'location.state.err.error') || "User successfully logged out."}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="text-center">
                            <Img src={renaultCar}/>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    </Wrapper>
);

export default withRouter(Header);
