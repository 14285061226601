import React from 'react';
import {reduxForm, FormSection} from 'redux-form'
import {
    DOCUMENTATION_UPDATE_SECTION,
    FORM_ATTACHMENTS_SECTION,
    FORM_EDITORS_NOTES_SECTION_TD,
    FORM_MESSAGES_SECTION,
    FORM_PROBLEM_DESCRIPTION_SECTION, FORM_SUPPLEMENTARY_INFORMATION_SECTION,
    FORM_TICKET_SECTION,
    FORM_VEHICLE_DETAIL_SECTION,
} from '../../../../constants/formConstants';
import TicketStatusHistory from '../../../common/TicketStatusHistory';
import TicketSection from './sections/ticket/TicketSection';
import ProblemDescriptionCodedPartsSection from './sections/problemDescription/ProblemDescriptionCodedPartsSection';
import VehicleDetailSection from './sections/vehicleDetail/VehicleDetailSection';
import MessagesSection from './sections/messagesSection/MessagesSection';
import RenderSyncErrors from '../../../common/RenderSyncErrors';
import {scrollContentWrapperToTop} from '../../../../utils/utils';
import AttachmentSection from './sections/attachments/AttachmentSection';
import SubmitButtons from './sections/SubmitButtons';
import formMessages from '../../../../intl/technicalDocumentations/formMessages';
import {techDocFieldLabelMapping} from '../../../../constants/techDocFieldLabelMapping';
import PropTypes from 'prop-types';
import BorderRadius from '../../../common/styled/BorderRadius';
import EditorsNotesSectionTD from './sections/editorsNotesTd/EditorsNotesSectionTD';
import SupplementaryInformationSection from './sections/supplementaryInfo/SupplementaryInformationSection';
import TicketDocumentationUpdateSection from './sections/documentation/DocumentationUpdateSectionTD';
import {moduleRoles} from '../../../../utils/roles';

const CodedPartsMasterForm = (props) => {
    const {form, userRights, readOnly, handleSubmit, reqFields, editMode, userRoles} = props;

    const canReadDocUpdateSection = userRights.canViewDocumentationUpdateSection && moduleRoles.isSGEditorLVL23Documentation(userRoles);

    return (
        <BorderRadius size={10} className="border border-dark p-4 bg-light">
            <RenderSyncErrors form={form} intlMessages={formMessages} mapping={techDocFieldLabelMapping}/>
            <FormSection name={FORM_TICKET_SECTION}>
                <TicketSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_VEHICLE_DETAIL_SECTION}>
                <VehicleDetailSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_SUPPLEMENTARY_INFORMATION_SECTION}>
                <SupplementaryInformationSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_PROBLEM_DESCRIPTION_SECTION}>
                <ProblemDescriptionCodedPartsSection form={form} userRights={userRights} reqFields={reqFields}
                                                     readOnly={readOnly}/>
            </FormSection>
            <FormSection name={FORM_MESSAGES_SECTION}>
                <MessagesSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>
            {userRights.canReadEditorsNotes && <FormSection name={FORM_EDITORS_NOTES_SECTION_TD}>
                <EditorsNotesSectionTD form={form} userRights={userRights} readOnly={readOnly}/>
            </FormSection>}
            {canReadDocUpdateSection && <FormSection name={DOCUMENTATION_UPDATE_SECTION}>
                <TicketDocumentationUpdateSection form={form} userRights={userRights} reqFields={reqFields}
                                                  readOnly={readOnly}/>
            </FormSection>}
            <FormSection name={FORM_ATTACHMENTS_SECTION}>
                <AttachmentSection form={form} userRights={userRights} reqFields={reqFields} readOnly={readOnly}/>
            </FormSection>

            <TicketStatusHistory form={form}/>
            <SubmitButtons form={form} onSubmit={handleSubmit} onSubmitFail={scrollContentWrapperToTop}
                           userRights={userRights} readOnly={readOnly} editMode={editMode}/>
        </BorderRadius>
    )
}

CodedPartsMasterForm.propTypes = {
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    userRights: PropTypes.object.isRequired,
    reqFields: PropTypes.array.isRequired,
};

export default reduxForm({})(CodedPartsMasterForm);
