import React from 'react';
import {required, validInput} from "./validationCommons";
import {Field} from "redux-form";
import {FieldWithValidation} from "./FormField";
import {DEFAULT_FIELD_MAX_LENGTH} from "../../constants/validationConstants";
import {RequiredSpan} from "./StyledComponents";
import {FormattedMessage} from 'react-intl';

export const InputFieldWithValidation = (props) => {
    const {
        label, field, placeholder, isDisabled, isRequired, isSmall, validations, format, maxLength, style, normalize, parse, handleKeyUp, setRef = () => {
        }
    } = props;
    let newValidations = validations;

    if (!newValidations) {
        newValidations = [validInput];
    } else {
        newValidations.push(validInput);
    }
    if (isRequired) {
        newValidations.push(required);
    }

    return (
        <div>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label}/>
                {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
            </label>}
            <Field
                name={field} component={FieldWithValidation} type="text"
                placeholder={placeholder}
                maxLength={maxLength ? maxLength : DEFAULT_FIELD_MAX_LENGTH}
                isSmall={isSmall}
                validate={newValidations}
                normalize={normalize}
                format={format}
                isDisabled={isDisabled}
                style={style}
                setRef={setRef}
                handleNameChange={(value) => handleKeyUp && handleKeyUp(value)}
                parse={parse}
            />
        </div>
    )
};
