import {
    SPARE_PARTS_INTERNAL_TICKET_ATTACHMENTS_SAVED_ERROR,
    SPARE_PARTS_INTERNAL_GET_TICKET,
    SPARE_PARTS_INTERNAL_TICKET_ATTACHMENTS_SAVED,
    SPARE_PARTS_INTERNAL_TICKET_CREATED,
    SPARE_PARTS_INTERNAL_TICKET_LOCKED,
    SPARE_PARTS_INTERNAL_TICKET_LOCKED_ERROR,
    SPARE_PARTS_INTERNAL_TICKET_LOCKED_FATAL,
    SPARE_PARTS_INTERNAL_TICKET_SEND,
    SPARE_PARTS_INTERNAL_TICKET_UPDATED,
    SPARE_PARTS_INTERNAL_TICKET_SG_INFO_SEND,
    SPARE_PARTS_INTERNAL_TICKET_SG_INFO_ERROR,
    SPARE_PARTS_INTERNAL_GET_SG_INFO,
} from '../../constants/websocketCommands';
import {
    SPARE_PARTS_INTERNAL_TICKET_CREATE,
    SPARE_PARTS_INTERNAL_TICKET_RESET,
    SPARE_PARTS_INTERNAL_TICKET_UPDATE
} from '../../actions/orders/actionSparePartsInternalTicket';
// import {SPARE_PARTS_INTERNAL_ATTACHMENTS_UPLOADING_ERROR} from '../../actions/orders/actionSparePartsInternalAttachments';
import {SPARE_PARTS_INTERNAL_RESET_REDIRECT, SPARE_PARTS_INTERNAL_SET_LOCK_TICKET} from '../../actions/orders/actionSparePartsInternalLockTicket';

export const sparePartsInternalTicket = (state = {
    correlationId: null,
    correlationIdSgInfo: null,
    isTicketLoading: false,
    isTicketCreating: false,
    created: false,
    ticket: {},
    vehicleDetail: {},
    partDetail: {},
    problemDescription: {},
    logs: [],
    messages: [],
    attachments: [],
    newAttachments: [],
    ticketReference: {},
    canTakeBack: false,
}, action) => {
    switch (action.type) {
        case SPARE_PARTS_INTERNAL_TICKET_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                correlationIdSgInfo: null,
                isTicketLoading: false,
                isTicketCreating: false,
                created: false,
                ticket: {},
                vehicleDetail: {},
                partDetail: {},
                problemDescription: {},
                logs: [],
                attachments: [],
                newAttachments: [],
                ticketReference: {},
                canTakeBack: false,
            });
        }
        case SPARE_PARTS_INTERNAL_TICKET_UPDATE:
        case SPARE_PARTS_INTERNAL_TICKET_CREATE: {
            const {newAttachments} = action.payload;
            return Object.assign({}, state, {
                newAttachments,
                isTicketCreating: true,
                created: false,
                isTicketLoading: true,
                vehicleDetail: {},
                partDetail: {},
                problemDescription: {},
                messages: [],
                logs: [],
                attachments: [],
                ticketReference: {},
            });
        }
        //command accepted
        case SPARE_PARTS_INTERNAL_TICKET_CREATED:
        case SPARE_PARTS_INTERNAL_TICKET_UPDATED:
        case SPARE_PARTS_INTERNAL_GET_TICKET: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketCreating: false,
                created: true,
                isTicketLoading: true,
                correlationId
            });
        }

        case SPARE_PARTS_INTERNAL_TICKET_SEND: {
            const {ticket, correlationId, vehicleDetail, partDetail, problemDescription, logs, attachments, error, messages, solutionGroupInfo, canTakeBack, documentationUpdate, storeCheckResults, ticketReference} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, {isTicketLoading: false, correlationId});
            }
            return Object.assign({}, state, {
                isTicketLoading: false,
                correlationId,
                ticket,
                vehicleDetail,
                partDetail,
                problemDescription,
                logs,
                attachments,
                messages,
                solutionGroupInfo,
                canTakeBack,
                documentationUpdate,
                storeCheckResults,
                ticketReference,
            });
        }
        case SPARE_PARTS_INTERNAL_GET_SG_INFO: {
            return Object.assign({}, state, {
                solutionGroupInfo: {}
            });
        }
        case SPARE_PARTS_INTERNAL_TICKET_SG_INFO_SEND: {
            const {correlationId, solutionGroupInfo} = action.payload;

            if (correlationId < state.correlationIdSgInfo) {
                return state;
            }
            return Object.assign({}, state, {
                solutionGroupInfo,
                correlationIdSgInfo: correlationId
            });
        }
        case SPARE_PARTS_INTERNAL_TICKET_SG_INFO_ERROR: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationIdSgInfo) {
                return state;
            }
            return Object.assign({}, state, {
                solutionGroupInfo: {},
                correlationIdSgInfo: correlationId
            });
        }
        //att done + error
        // case SPARE_PARTS_INTERNAL_ATTACHMENTS_UPLOADING_ERROR:
        case SPARE_PARTS_INTERNAL_TICKET_ATTACHMENTS_SAVED_ERROR: {
            return Object.assign({}, state, {
                newAttachments: [],
            });
        }
        case SPARE_PARTS_INTERNAL_TICKET_ATTACHMENTS_SAVED: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketLoading: true,
                correlationId,
                newAttachments: [],
            });
        }
        default:
            return state;
    }
};

export const sparePartsLockInternalTicketCheck = (state = {
    correlationId: null,
    isTicketLocking: false,
    redirect: false,
}, action) => {
    switch (action.type) {
        case SPARE_PARTS_INTERNAL_TICKET_LOCKED_ERROR:
        case SPARE_PARTS_INTERNAL_TICKET_LOCKED_FATAL: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isTicketLocking: false,
                redirect: true,
            });
        }
        case SPARE_PARTS_INTERNAL_RESET_REDIRECT: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case SPARE_PARTS_INTERNAL_TICKET_LOCKED: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case SPARE_PARTS_INTERNAL_SET_LOCK_TICKET: {
            const {uuid} = action.payload;
            return Object.assign({}, state, {
                isTicketLocking: true,
                redirect: false,
                uuid,
            });
        }
        default:
            return state;
    }
};
