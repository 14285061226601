import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from "react-intl";
import {PrimaryButton} from "./Button";
import buttonMessages from "../../intl/common/buttonMessages";
import '../../css/formControl.css';
import validationMessages from "../../intl/common/validationMessages";

const ErrorDiv = styled.label`
    display: inherit;
`;

export const FieldGroupWithValidation = (props) => {
    const {input, handleClick, isDisabled, isButtonDisabled, isSmall, isUpperCase, type, placeholder,
        maxLength, handleKeyUp, meta: {touched, error, invalid}, setRef = () => {}, setRefButton = () => {}, reverse = false} = props;

    return (
        <div>
            <div style={reverse ? { display: "flex", flexDirection: "row-reverse" } : {}} className="input-group input-group-sm">
                <input {...input} type={type} placeholder={placeholder}
                       className={"form-control " + (isSmall ? " form-control-sm" : "") + (touched && invalid ? " is-invalid" : "")}
                       maxLength={maxLength}
                       onKeyUp={(e) => handleKeyUp && handleKeyUp(e.target.value)}
                       disabled={isDisabled}
                       ref={setRef}
                       onInput={isUpperCase && ((e) => e.target.value = e.target.value.toUpperCase())}
                />
                <div className="input-group-append">
                    <PrimaryButton disabled={isButtonDisabled} type="button" className="btn"
                                   ref={setRefButton}
                                   onClick={() => handleClick()}>
                        <FormattedMessage {...buttonMessages.LOAD}/>
                    </PrimaryButton>
                </div>
            </div>
            {touched && error && <ErrorDiv className="invalid-feedback">{validationMessages[error] ?
                <FormattedMessage {...validationMessages[error]}/> : error}</ErrorDiv>}
        </div>
    );
};




