import {
    CODED_PARTS_KEYS_FORM,
    FORM_CHECKBOX_NEW_OVAL_PLATE,
    FORM_CHECKBOX_OLD_OVAL_PLATE,
    FORM_FIELD_CLIENT_ID,
    FORM_FIELD_DEALER_NAME,
    FORM_FIELD_DEALER_NO,
    FORM_FIELD_EMAIL,
    FORM_FIELD_ENGINE_SERIAL_NUMBER,
    FORM_FIELD_ENGINE_TYPE,
    FORM_FIELD_GEARBOX_SERIAL_NUMBER,
    FORM_FIELD_GEARBOX_TYPE,
    FORM_FIELD_ITEM_NUMBER,
    FORM_FIELD_MANUFACTURE_DATE,
    FORM_FIELD_NEW_PLATE1,
    FORM_FIELD_NEW_PLATE10,
    FORM_FIELD_NEW_PLATE11,
    FORM_FIELD_NEW_PLATE12,
    FORM_FIELD_NEW_PLATE13,
    FORM_FIELD_NEW_PLATE14,
    FORM_FIELD_NEW_PLATE15,
    FORM_FIELD_NEW_PLATE16,
    FORM_FIELD_NEW_PLATE17,
    FORM_FIELD_NEW_PLATE2,
    FORM_FIELD_NEW_PLATE3,
    FORM_FIELD_NEW_PLATE4,
    FORM_FIELD_NEW_PLATE5,
    FORM_FIELD_NEW_PLATE6,
    FORM_FIELD_NEW_PLATE7,
    FORM_FIELD_NEW_PLATE8,
    FORM_FIELD_NEW_PLATE9,
    FORM_FIELD_PAGE_POST,
    FORM_FIELD_PAGE_PREF,
    FORM_FIELD_PART_DESCRIPTION,
    FORM_FIELD_PART_MARKING,
    FORM_FIELD_PART_NUMBER,
    FORM_FIELD_PR,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_STEERING_WHEEL,
    FORM_FIELD_TELEPHONE_NO,
    FORM_FIELD_CAR_MODEL,
    FORM_FIELD_VIN,
    OVAL_PLATE_ORDER_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
    UNKNOWN_PART_OPENING_REQUEST_FORM,
    SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM,
    CODED_PARTS_KEYS_FORM_GBIE, FORM_FIELD_MANAGEMENT_GROUP,
} from './formConstants';
import {countryGroups} from './Utils';

export const editReqFields = {
    'AR': {
        'DEALER': {
            [CODED_PARTS_KEYS_FORM]: [],
            [OVAL_PLATE_ORDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANUFACTURE_DATE,
                FORM_FIELD_CAR_MODEL,
                FORM_FIELD_VIN,
            ],
            [SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM]: [
                FORM_FIELD_ENGINE_SERIAL_NUMBER,
                FORM_FIELD_ENGINE_TYPE,
                FORM_FIELD_GEARBOX_SERIAL_NUMBER,
                FORM_FIELD_GEARBOX_TYPE,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_ITEM_NUMBER,
                FORM_FIELD_PR,
                FORM_FIELD_STEERING_WHEEL,
                FORM_FIELD_PAGE_PREF,
                FORM_FIELD_PAGE_POST,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANUFACTURE_DATE,
                FORM_FIELD_CAR_MODEL,
                FORM_FIELD_PART_MARKING,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_VIN,
            ],
            [SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM]: [
                FORM_FIELD_ENGINE_SERIAL_NUMBER,
                FORM_FIELD_ENGINE_TYPE,
                FORM_FIELD_GEARBOX_SERIAL_NUMBER,
                FORM_FIELD_GEARBOX_TYPE,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_STEERING_WHEEL,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANUFACTURE_DATE,
                FORM_FIELD_CAR_MODEL,
                FORM_FIELD_PART_MARKING,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_VIN,
            ],
            [UNKNOWN_PART_OPENING_REQUEST_FORM]: [
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_ITEM_NUMBER,
                FORM_FIELD_PR,
                FORM_FIELD_PAGE_PREF,
                FORM_FIELD_PAGE_POST,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANUFACTURE_DATE,
                FORM_FIELD_CAR_MODEL,
                FORM_FIELD_VIN,
            ],
            [SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM]: [
                FORM_FIELD_ENGINE_SERIAL_NUMBER,
                FORM_FIELD_ENGINE_TYPE,
                FORM_FIELD_GEARBOX_SERIAL_NUMBER,
                FORM_FIELD_GEARBOX_TYPE,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_ITEM_NUMBER,
                FORM_FIELD_PR,
                FORM_FIELD_STEERING_WHEEL,
                FORM_FIELD_PAGE_PREF,
                FORM_FIELD_PAGE_POST,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANUFACTURE_DATE,
                FORM_FIELD_CAR_MODEL,
                FORM_FIELD_PART_MARKING,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_VIN,
            ],
        },
        'EDITOR': {
            [CODED_PARTS_KEYS_FORM]: [
                FORM_FIELD_MANAGEMENT_GROUP
            ],
            [OVAL_PLATE_ORDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
            ],
            [SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_ENGINE_SERIAL_NUMBER,
                FORM_FIELD_ENGINE_TYPE,
                FORM_FIELD_GEARBOX_SERIAL_NUMBER,
                FORM_FIELD_GEARBOX_TYPE,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_ITEM_NUMBER,
                FORM_FIELD_PR,
                FORM_FIELD_STEERING_WHEEL,
                FORM_FIELD_PAGE_PREF,
                FORM_FIELD_PAGE_POST,
                FORM_FIELD_MANAGEMENT_GROUP
            ],
            [SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_ENGINE_SERIAL_NUMBER,
                FORM_FIELD_ENGINE_TYPE,
                FORM_FIELD_GEARBOX_SERIAL_NUMBER,
                FORM_FIELD_GEARBOX_TYPE,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_STEERING_WHEEL,
                FORM_FIELD_MANAGEMENT_GROUP
            ],
            [UNKNOWN_PART_OPENING_REQUEST_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_ITEM_NUMBER,
                FORM_FIELD_PR,
                FORM_FIELD_PAGE_PREF,
                FORM_FIELD_PAGE_POST,
                FORM_FIELD_MANAGEMENT_GROUP
            ],
            [SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_ENGINE_SERIAL_NUMBER,
                FORM_FIELD_ENGINE_TYPE,
                FORM_FIELD_GEARBOX_SERIAL_NUMBER,
                FORM_FIELD_GEARBOX_TYPE,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_ITEM_NUMBER,
                FORM_FIELD_PR,
                FORM_FIELD_STEERING_WHEEL,
                FORM_FIELD_PAGE_PREF,
                FORM_FIELD_PAGE_POST,
            ],
        }
    },
    'DEFAULT': {
        'DEALER': {
            [CODED_PARTS_KEYS_FORM]: [],
            [OVAL_PLATE_ORDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANUFACTURE_DATE,
                FORM_FIELD_CAR_MODEL,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_VIN,
            ],
            [SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM]: [
                FORM_FIELD_ENGINE_SERIAL_NUMBER,
                FORM_FIELD_ENGINE_TYPE,
                FORM_FIELD_GEARBOX_SERIAL_NUMBER,
                FORM_FIELD_GEARBOX_TYPE,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_STEERING_WHEEL,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANUFACTURE_DATE,
                FORM_FIELD_CAR_MODEL,
                FORM_FIELD_PART_MARKING,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_VIN,
            ],
            [SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM]: [
                FORM_FIELD_ENGINE_SERIAL_NUMBER,
                FORM_FIELD_ENGINE_TYPE,
                FORM_FIELD_GEARBOX_SERIAL_NUMBER,
                FORM_FIELD_GEARBOX_TYPE,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_STEERING_WHEEL,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANUFACTURE_DATE,
                FORM_FIELD_CAR_MODEL,
                FORM_FIELD_PART_MARKING,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_VIN,
            ],
            [UNKNOWN_PART_OPENING_REQUEST_FORM]: [
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANUFACTURE_DATE,
                FORM_FIELD_CAR_MODEL,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_VIN,
            ],
            [SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM]: [
                FORM_FIELD_ENGINE_SERIAL_NUMBER,
                FORM_FIELD_ENGINE_TYPE,
                FORM_FIELD_GEARBOX_SERIAL_NUMBER,
                FORM_FIELD_GEARBOX_TYPE,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_ITEM_NUMBER,
                FORM_FIELD_PR,
                FORM_FIELD_STEERING_WHEEL,
                FORM_FIELD_PAGE_PREF,
                FORM_FIELD_PAGE_POST,
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_MANUFACTURE_DATE,
                FORM_FIELD_CAR_MODEL,
                FORM_FIELD_PART_MARKING,
                FORM_FIELD_PROBLEM_DESCRIPTION,
                FORM_FIELD_TELEPHONE_NO,
                FORM_FIELD_VIN,
            ],
        },
        'EDITOR': {
            [CODED_PARTS_KEYS_FORM]: [
                FORM_FIELD_MANAGEMENT_GROUP
            ],
            [OVAL_PLATE_ORDER_FORM]: [
                FORM_FIELD_CLIENT_ID,
            ],
            [SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_ENGINE_SERIAL_NUMBER,
                FORM_FIELD_ENGINE_TYPE,
                FORM_FIELD_GEARBOX_SERIAL_NUMBER,
                FORM_FIELD_GEARBOX_TYPE,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_STEERING_WHEEL,
                FORM_FIELD_MANAGEMENT_GROUP
            ],
            [SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_ENGINE_SERIAL_NUMBER,
                FORM_FIELD_ENGINE_TYPE,
                FORM_FIELD_GEARBOX_SERIAL_NUMBER,
                FORM_FIELD_GEARBOX_TYPE,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_STEERING_WHEEL,
                FORM_FIELD_MANAGEMENT_GROUP
            ],
            [UNKNOWN_PART_OPENING_REQUEST_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_MANAGEMENT_GROUP
            ],
            [SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM]: [
                FORM_FIELD_CLIENT_ID,
                FORM_FIELD_ENGINE_SERIAL_NUMBER,
                FORM_FIELD_ENGINE_TYPE,
                FORM_FIELD_GEARBOX_SERIAL_NUMBER,
                FORM_FIELD_GEARBOX_TYPE,
                FORM_FIELD_PART_DESCRIPTION,
                FORM_FIELD_PART_NUMBER,
                FORM_FIELD_ITEM_NUMBER,
                FORM_FIELD_PR,
                FORM_FIELD_STEERING_WHEEL,
                FORM_FIELD_PAGE_PREF,
                FORM_FIELD_PAGE_POST,
            ],
        }
    }
};

export const createReqFields = {
    'AR': {
        [CODED_PARTS_KEYS_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_CHECKBOX_NEW_OVAL_PLATE,
            FORM_FIELD_NEW_PLATE1,
            FORM_FIELD_NEW_PLATE2,
            FORM_FIELD_NEW_PLATE3,
            FORM_FIELD_NEW_PLATE4,
            FORM_FIELD_NEW_PLATE5,
            FORM_FIELD_NEW_PLATE6,
            FORM_FIELD_NEW_PLATE7,
            FORM_FIELD_NEW_PLATE8,
            FORM_FIELD_NEW_PLATE9,
            FORM_FIELD_NEW_PLATE10,
            FORM_FIELD_NEW_PLATE11,
            FORM_FIELD_NEW_PLATE12,
            FORM_FIELD_NEW_PLATE13,
            FORM_FIELD_NEW_PLATE14,
            FORM_FIELD_NEW_PLATE15,
            FORM_FIELD_NEW_PLATE16,
            FORM_FIELD_NEW_PLATE17
        ],
        [CODED_PARTS_KEYS_FORM_GBIE]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_CHECKBOX_NEW_OVAL_PLATE,
            FORM_FIELD_NEW_PLATE1,
            FORM_FIELD_NEW_PLATE2,
            FORM_FIELD_NEW_PLATE3,
            FORM_FIELD_NEW_PLATE4,
            FORM_FIELD_NEW_PLATE5,
            FORM_FIELD_NEW_PLATE6,
            FORM_FIELD_NEW_PLATE7,
            FORM_FIELD_NEW_PLATE8,
            FORM_FIELD_NEW_PLATE9,
            FORM_FIELD_NEW_PLATE10,
            FORM_FIELD_NEW_PLATE11,
            FORM_FIELD_NEW_PLATE12,
            FORM_FIELD_NEW_PLATE13,
            FORM_FIELD_NEW_PLATE14,
            FORM_FIELD_NEW_PLATE15,
            FORM_FIELD_NEW_PLATE16,
            FORM_FIELD_NEW_PLATE17,
            FORM_FIELD_PR,
            FORM_FIELD_PAGE_PREF,
            FORM_FIELD_PAGE_POST,
            FORM_FIELD_ITEM_NUMBER
        ],
        [OVAL_PLATE_ORDER_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_VIN,
            FORM_FIELD_CAR_MODEL,
            FORM_FIELD_MANUFACTURE_DATE,
            FORM_CHECKBOX_NEW_OVAL_PLATE
        ],
        [SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_VIN,
            FORM_FIELD_CAR_MODEL,
            FORM_FIELD_MANUFACTURE_DATE,
            FORM_CHECKBOX_NEW_OVAL_PLATE,
            FORM_CHECKBOX_OLD_OVAL_PLATE,
            FORM_FIELD_ENGINE_TYPE,
            FORM_FIELD_ENGINE_SERIAL_NUMBER,
            FORM_FIELD_GEARBOX_TYPE,
            FORM_FIELD_GEARBOX_SERIAL_NUMBER,
            FORM_FIELD_STEERING_WHEEL,
            FORM_FIELD_PR,
            FORM_FIELD_PAGE_PREF,
            FORM_FIELD_PAGE_POST,
            FORM_FIELD_ITEM_NUMBER,
            FORM_FIELD_PART_NUMBER,
            FORM_FIELD_PART_DESCRIPTION,
            FORM_FIELD_PROBLEM_DESCRIPTION,
            FORM_FIELD_PART_MARKING
        ],
        [SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_VIN,
            FORM_FIELD_CAR_MODEL,
            FORM_FIELD_MANUFACTURE_DATE,
            FORM_CHECKBOX_NEW_OVAL_PLATE,
            FORM_CHECKBOX_OLD_OVAL_PLATE,
            FORM_FIELD_ENGINE_TYPE,
            FORM_FIELD_ENGINE_SERIAL_NUMBER,
            FORM_FIELD_GEARBOX_TYPE,
            FORM_FIELD_GEARBOX_SERIAL_NUMBER,
            FORM_FIELD_STEERING_WHEEL,
            FORM_FIELD_PR,
            FORM_FIELD_PAGE_PREF,
            FORM_FIELD_PAGE_POST,
            FORM_FIELD_ITEM_NUMBER,
            FORM_FIELD_PART_NUMBER,
            FORM_FIELD_PART_DESCRIPTION,
            FORM_FIELD_PROBLEM_DESCRIPTION,
            FORM_FIELD_PART_MARKING
        ],
        [UNKNOWN_PART_OPENING_REQUEST_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_VIN,
            FORM_FIELD_CAR_MODEL,
            FORM_FIELD_MANUFACTURE_DATE,
            FORM_CHECKBOX_NEW_OVAL_PLATE,
            FORM_CHECKBOX_OLD_OVAL_PLATE,
            FORM_FIELD_PR,
            FORM_FIELD_PAGE_PREF,
            FORM_FIELD_PAGE_POST,
            FORM_FIELD_ITEM_NUMBER,
            FORM_FIELD_PART_NUMBER,
            FORM_FIELD_PART_DESCRIPTION,
        ],
    },
    'DEFAULT': {
        [CODED_PARTS_KEYS_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_FIELD_TELEPHONE_NO,
            FORM_CHECKBOX_NEW_OVAL_PLATE,
            FORM_FIELD_NEW_PLATE1,
            FORM_FIELD_NEW_PLATE2,
            FORM_FIELD_NEW_PLATE3,
            FORM_FIELD_NEW_PLATE4,
            FORM_FIELD_NEW_PLATE5,
            FORM_FIELD_NEW_PLATE6,
            FORM_FIELD_NEW_PLATE7,
            FORM_FIELD_NEW_PLATE8,
            FORM_FIELD_NEW_PLATE9,
            FORM_FIELD_NEW_PLATE10,
            FORM_FIELD_NEW_PLATE11,
            FORM_FIELD_NEW_PLATE12,
            FORM_FIELD_NEW_PLATE13,
            FORM_FIELD_NEW_PLATE14,
            FORM_FIELD_NEW_PLATE15,
            FORM_FIELD_NEW_PLATE16,
            FORM_FIELD_NEW_PLATE17
        ],
        [CODED_PARTS_KEYS_FORM_GBIE]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_FIELD_TELEPHONE_NO,
            FORM_CHECKBOX_NEW_OVAL_PLATE,
            FORM_FIELD_NEW_PLATE1,
            FORM_FIELD_NEW_PLATE2,
            FORM_FIELD_NEW_PLATE3,
            FORM_FIELD_NEW_PLATE4,
            FORM_FIELD_NEW_PLATE5,
            FORM_FIELD_NEW_PLATE6,
            FORM_FIELD_NEW_PLATE7,
            FORM_FIELD_NEW_PLATE8,
            FORM_FIELD_NEW_PLATE9,
            FORM_FIELD_NEW_PLATE10,
            FORM_FIELD_NEW_PLATE11,
            FORM_FIELD_NEW_PLATE12,
            FORM_FIELD_NEW_PLATE13,
            FORM_FIELD_NEW_PLATE14,
            FORM_FIELD_NEW_PLATE15,
            FORM_FIELD_NEW_PLATE16,
            FORM_FIELD_NEW_PLATE17,
            FORM_FIELD_PR,
            FORM_FIELD_PAGE_PREF,
            FORM_FIELD_PAGE_POST,
            FORM_FIELD_ITEM_NUMBER
        ],
        [OVAL_PLATE_ORDER_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_VIN,
            FORM_FIELD_CAR_MODEL,
            FORM_FIELD_MANUFACTURE_DATE,
            FORM_CHECKBOX_NEW_OVAL_PLATE
        ],
        [SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_VIN,
            FORM_FIELD_CAR_MODEL,
            FORM_FIELD_MANUFACTURE_DATE,
            FORM_CHECKBOX_NEW_OVAL_PLATE,
            FORM_CHECKBOX_OLD_OVAL_PLATE,
            FORM_FIELD_ENGINE_TYPE,
            FORM_FIELD_ENGINE_SERIAL_NUMBER,
            FORM_FIELD_GEARBOX_TYPE,
            FORM_FIELD_GEARBOX_SERIAL_NUMBER,
            FORM_FIELD_STEERING_WHEEL,
            FORM_FIELD_PART_NUMBER,
            FORM_FIELD_PART_DESCRIPTION,
            FORM_FIELD_PROBLEM_DESCRIPTION,
            FORM_FIELD_PART_MARKING
        ],
        [SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_VIN,
            FORM_FIELD_CAR_MODEL,
            FORM_FIELD_MANUFACTURE_DATE,
            FORM_CHECKBOX_NEW_OVAL_PLATE,
            FORM_CHECKBOX_OLD_OVAL_PLATE,
            FORM_FIELD_ENGINE_TYPE,
            FORM_FIELD_ENGINE_SERIAL_NUMBER,
            FORM_FIELD_GEARBOX_TYPE,
            FORM_FIELD_GEARBOX_SERIAL_NUMBER,
            FORM_FIELD_STEERING_WHEEL,
            FORM_FIELD_PART_NUMBER,
            FORM_FIELD_PART_DESCRIPTION,
            FORM_FIELD_PROBLEM_DESCRIPTION,
            FORM_FIELD_PART_MARKING
        ],
        [UNKNOWN_PART_OPENING_REQUEST_FORM]: [
            FORM_FIELD_DEALER_NAME,
            FORM_FIELD_DEALER_NO,
            FORM_FIELD_EMAIL,
            FORM_FIELD_TELEPHONE_NO,
            FORM_FIELD_CLIENT_ID,
            FORM_FIELD_VIN,
            FORM_FIELD_CAR_MODEL,
            FORM_FIELD_MANUFACTURE_DATE,
            FORM_CHECKBOX_NEW_OVAL_PLATE,
            FORM_CHECKBOX_OLD_OVAL_PLATE,
            FORM_FIELD_PART_NUMBER,
            FORM_FIELD_PART_DESCRIPTION,
        ],
    }
};

