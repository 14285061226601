import {SPARE_PARTS_VIN_DEALER_CHECK_SET, SPARE_PARTS_VIN_DEALER_CHECK_RESET} from '../../actions/orders/actionSparePartsVinDealerCheck';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';

export const sparePartsVinDealerCheck = (state = {
    isLoading: false,
    result: {},
    isLoaded: false,
    isSet: false,
    vin: ''
}, action) => {
    switch (action.type) {
        case sparePartsWebsocketCommands.SPARE_PARTS_VIN_DEALER_CHECK: {
            const {vin} = action.payload;
            return Object.assign({}, state, {vin, isLoading: true, isLoaded: false, isSet: false});
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_VIN_DEALER_CHECK_SEND: {
            const {result} = action.payload;
            return Object.assign({}, state, {result: result, isLoading: false, isLoaded: true, isSet: true});
        }
        case SPARE_PARTS_VIN_DEALER_CHECK_SET: {
            return Object.assign({}, state, {vin: '', result: {}, isSet: false});
        }
        case SPARE_PARTS_VIN_DEALER_CHECK_RESET:
        case sparePartsWebsocketCommands.SPARE_PARTS_VIN_DEALER_CHECK_SEND_ERROR: {
            return Object.assign({}, state, {vin: '', result: {}, isLoading: false, isLoaded: false, isSet: false});
        }
        default:
            return state;
    }
};
