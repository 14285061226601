import {
    FORM_LABEL_SG_ACTIVE,
    FORM_LABEL_SG_COUNTRIES,
    FORM_LABEL_SG_NAME,
    FORM_FIELD_SG_NAME,
    FORM_FIELD_SG_ACTIVE,
    FORM_FIELD_SG_COUNTRIES
} from './formConstants';

export const intlSolutionGroupFieldMapping = {
    [FORM_FIELD_SG_NAME]: FORM_LABEL_SG_NAME,
    [FORM_FIELD_SG_ACTIVE]: FORM_LABEL_SG_ACTIVE,
    [FORM_FIELD_SG_COUNTRIES]: FORM_LABEL_SG_COUNTRIES,
};
