import React from 'react';
import {withRouter} from 'react-router-dom';
import {domains} from '../../constants/Utils';
import {
    ADMINISTRATION_PATH,
    CLAIMS_PATH,
    DOCUMENTATIONS_PATH,
    GENERICS_PATH,
    ORDERS_PATH
} from '../../routes/paths';

export const Domain = withRouter(({component: Component, ...props}) => {
    let domain;
    if (props.location.pathname.startsWith(ORDERS_PATH) || props.location.pathname.startsWith(`${ADMINISTRATION_PATH}${ORDERS_PATH}`)) {
        domain = domains.SPARE_PARTS;
    }
    if (props.location.pathname.startsWith(DOCUMENTATIONS_PATH) || props.location.pathname.startsWith(`${ADMINISTRATION_PATH}${DOCUMENTATIONS_PATH}`)) {
        domain = domains.TECHNICAL_DOCUMENTATION;
    }
    if (props.location.pathname.startsWith(CLAIMS_PATH) || props.location.pathname.startsWith(`${ADMINISTRATION_PATH}${CLAIMS_PATH}`)) {
        domain = domains.CLAIMS;
    }
    if (props.location.pathname.startsWith(GENERICS_PATH)) {
        domain = domains.GENERIC_TICKET;
    }
    return <Component domain={domain} {...props}/>;
});
