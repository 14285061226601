import {TOGGLE} from '../actions/actionMenu';

export const menu = (state = {isToggled: false}, action) => {
    switch (action.type) {
        case TOGGLE: {
            return Object.assign({}, state, {isToggled: action.payload});
        }
        default:
            return state;
    }
};
