import {axiosInstance} from './rest';

export async function loadFlows() {
    return axiosInstance.get(`administration/GENERIC_TICKET/flow`);
}

export async function createFlow(flowCreateEvent) {
    return axiosInstance.post(`administration/GENERIC_TICKET/flow`, flowCreateEvent);
}

export async function deleteFlow(flowId) {
    return axiosInstance.delete(`administration/GENERIC_TICKET/flow/${flowId}`);
}

export async function saveFlow(flowId, flowUpdateEvent) {
    return axiosInstance.put(`administration/GENERIC_TICKET/flow/${flowId}`, flowUpdateEvent);
}

export async function loadFlow(flowId) {
    return axiosInstance.get(`administration/GENERIC_TICKET/flow/${flowId}`);
}
