import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {formValueSelector, change, Field} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {
    DOCUMENTATION_UPDATE_SECTION,
    FORM_FIELD_DOC_UPDATE_NEEDED,
    FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION,
    FORM_FIELD_ROOT_CAUSE, FORM_FIELD_SOLUTION_GROUP,
    FORM_FIELD_TYPE_OF_REQUEST,
    FORM_FIELD_UPDATE_FILE_NUMBER,
    FORM_LABEL_DOC_UPDATE_NEEDED,
    FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION,
    FORM_LABEL_ROOT_CAUSE,
    FORM_LABEL_UPDATE_FILE_NUMBER,
    FORM_TICKET_SECTION,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import formMessages from '../../../../../../intl/technicalDocumentations/formMessages';
import DatePickerComponent from '../../../../../common/datePicker/DatePickerComponent';
import moment from 'moment';
import commonMessages from '../../../../../../intl/common/commonMessages';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {
    PART_DOC_REQUEST,
    priorityType,
    problemCategoryList,
    REQUEST_TO_OPEN_FOR_SALE
} from '../../../../../../constants/Utils';
import priorityTypeMessages from '../../../../../../intl/technicalDocumentations/priorityTypeMessages';
import problemCategoryMessages from '../../../../../../intl/technicalDocumentations/problemCategoryMessages';
import {moduleRoles} from "../../../../../../utils/roles";
import get from "get-value";
import {first, isEmpty} from "lodash";

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const DocumentationUpdateSectionTD = (props) => {
    const { readOnly, reqFields, userRights, updateFileNumber, updateFileNumberNeeded, change, selectedRequestType, userRoles, solutionGroupId, initialValues, roles, assignedSolutionGroup } = props;

    const isDisabled = selectedRequestType === PART_DOC_REQUEST ?
        !userRights.canEditPartDocDocumentationUpdate || readOnly || !moduleRoles.isSGEditorLVL23Documentation(userRoles) :
        !userRights.canEditDocumentationUpdate || readOnly;

    const selectedSolutionGroup = useMemo(() => {
        const sgGroups = (!userRights.canEditInternalTicketRequestDetail
            || readOnly) ?
            [get(initialValues, 'requestorSolutionGroup')] : // read => get current solution group
            get(roles, 'TECHNICAL_DOCUMENTATION.sgEditor.sgPermission'); // edit => offer users solution groups
        return !isEmpty(sgGroups) ? first(sgGroups.filter(group => get(group, 'id', '') === solutionGroupId)) : {};
    }, [initialValues, userRights, roles, solutionGroupId]);

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.DOCUMENTATION_UPDATE_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-md-6">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_DOC_UPDATE_NEEDED]}
                                                        field={FORM_FIELD_DOC_UPDATE_NEEDED}
                                                        options={['YES', 'NO']}
                                                        intlMessages={commonMessages}
                                                        isRequired={(!get(selectedSolutionGroup, 'isDefault', false) && !isDisabled) || reqFields.includes(FORM_FIELD_DOC_UPDATE_NEEDED)}
                                                        isDisabled={isDisabled}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_UPDATE_FILE_NUMBER]}
                                                  field={FORM_FIELD_UPDATE_FILE_NUMBER}
                                                  maxLength={25}
                                                  isDisabled={isDisabled || (updateFileNumberNeeded === 'NO')}
                                                  isRequired={reqFields.includes(FORM_FIELD_UPDATE_FILE_NUMBER)}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <Field component={DatePickerComponent}
                               selectsStart
                               label={formMessages[FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION]}
                               name={FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}
                               isRequired={
                                   (selectedSolutionGroup ? get(selectedSolutionGroup, 'name', '').toLowerCase().includes('l3')  && updateFileNumberNeeded === 'YES': false)
                                   ||
                                   (assignedSolutionGroup ? get(assignedSolutionGroup, 'name', '').toLowerCase().includes('l3')  && updateFileNumberNeeded === 'YES' : false)
                               }
                               isDisabled={get(selectedSolutionGroup, 'name', '').toLowerCase().includes('l2') || get(assignedSolutionGroup, 'name', '').toLowerCase().includes('l2') || isDisabled}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_ROOT_CAUSE]}
                                                        field={FORM_FIELD_ROOT_CAUSE}
                                                        options={problemCategoryList}
                                                        intlMessages={problemCategoryMessages}
                                                        isRequired={reqFields.includes(FORM_FIELD_ROOT_CAUSE)}
                                                        isDisabled={isDisabled}
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )

}

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, initialProps) => ({
    userRoles: state.profile.userDetail.roles,
    updateFileNumber: selector(initialProps.form, state, `${DOCUMENTATION_UPDATE_SECTION}.${FORM_FIELD_UPDATE_FILE_NUMBER}`),
    updateFileNumberNeeded: selector(initialProps.form, state, `${DOCUMENTATION_UPDATE_SECTION}.${FORM_FIELD_DOC_UPDATE_NEEDED}`),
    selectedRequestType: selector(initialProps.form, state, `${FORM_TICKET_SECTION}.${FORM_FIELD_TYPE_OF_REQUEST}`),
    solutionGroupId: selector(initialProps.form, state, `${FORM_TICKET_SECTION}.${FORM_FIELD_SOLUTION_GROUP}`),
    initialValues: initialProps,
    roles: state.profile.userDetail.roles,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(DocumentationUpdateSectionTD);
