import React, {Component} from 'react';
import styled from 'styled-components';
import PopUpForm from './PopUpForm';
import {connect} from 'react-redux';
import {fetchPopUp, savePopUp} from '../../../../actions/admin/actionAdminPopUp';
import Loader from '../../../Loader';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import get from 'get-value';
import mainPageAlertMessages from "../../../../intl/admin/mainPageAlertMessages";
import PopUpCheckboxForm from './PopUpCheckboxForm';

const Wrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
`;

const Header = styled.div`
  margin-top: 10px;
  font-style: italic;
`;

class MessageContainer extends Component {
    componentDidMount() {
        this.props.fetchPopUp(this.props.domain, this.props.selectedGroup);
    }

    save = (values) => {
        const date = new Date();
        const data = {
            text: values.textPopUp,
            disabled: values.disabled,
            userName: this.props.profile.name,
            updated: date.getTime(),
            group: this.props.selectedGroup,
        };
        this.props.savePopUp(this.props.domain, data);
    };

    render() {
        const {isLoading, popUp, selectedGroup} = this.props;
        return (
            <Wrapper>
                <h5 className="row mx-0"><FormattedMessage {...mainPageAlertMessages.POP_UP_MESSAGE}/>{!isLoading && <PopUpCheckboxForm form={`popUpForm${selectedGroup}`}/>}</h5>
                {isLoading && <Loader/>}
                {!isLoading &&
                <div>
                    {
                        get(popUp, 'updated', false) &&
                        <Header>
                            <span className="pr-2">
                                <FormattedMessage {...mainPageAlertMessages.LAST_UPDATE}/>{':'}
                            </span>
                            {popUp.userName} - <FormattedDate value={new Date(popUp.updated)}/>
                        </Header>
                    }
                    <PopUpForm onSubmit={this.save} initialValues={{textPopUp: popUp.text, disabled: get(popUp, 'disabled', false)}}
                                 form={`popUpForm${selectedGroup}`}/>
                </div>}
            </Wrapper>
        );
    }
}

MessageContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    popUp: PropTypes.object.isRequired,
    fetchPopUp: PropTypes.func.isRequired,
    savePopUp: PropTypes.func.isRequired,
    domain: PropTypes.string.isRequired,
    selectedGroup: PropTypes.string.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminPopUp.isLoading,
    popUp: state.adminPopUp.popUp,
    profile: state.profile.userDetail,
});

export default connect(mapStateToProps, {
    fetchPopUp,
    savePopUp,
})(injectIntl(MessageContainer));
