import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'mainPage.title.welcome',
        defaultMessage: 'Welcome'
    },
    MAIN_MESSAGES: {
        id: 'mainPage.header.mainMessages',
        defaultMessage: 'Main messages'
    },
    MESSAGES_EMPTY: {
        id: 'mainPage.messages.empty',
        defaultMessage: 'No message set'
    },
    LAST_UPDATED: {
        id: 'mainPage.messages.lastUpdated',
        defaultMessage: 'Last update'
    },
    STATISTICS: {
        id: 'mainPage.header.statistics',
        defaultMessage: 'Statistics'
    },
    SELECT_COUNTRIES: {
        id: 'mainPage.header.selectCountries',
        defaultMessage: 'Select Countries'
    },
    SELECT_SOLUTION_GROUPS: {
        id: 'mainPage.header.selectSolutionGroups',
        defaultMessage: 'Select Solution Groups'
    },
});

export default messages;
