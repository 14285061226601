import {adminWebsocketCommands} from '../constants/adminWebsocketCommands';
import {REQUEST_PARAM_DELIM} from '../constants/Utils';

export const CLIENT_IDS_UPDATE_PENDING = 'CLIENT_IDS_UPDATE_PENDING';
export const CLIENT_IDS_CREATE_PENDING = 'CLIENT_IDS_CREATE_PENDING';
export const CLIENT_IDS_DELETE_PENDING = 'CLIENT_IDS_DELETE_PENDING';
export const CLIENT_IDS_UPDATE_RECORD = 'CLIENT_IDS_UPDATE_RECORD';
export const CLIENT_IDS_EDIT_RECORD = 'CLIENT_IDS_EDIT_RECORD';
export const CLIENT_IDS_DELETE_RECORD = 'CLIENT_IDS_DELETE_RECORD';
export const CLIENT_IDS_FETCH_PENDING = 'CLIENT_IDS_FETCH_PENDING';
export const CLIENT_IDS_FETCH_SUCCESS = 'ADMIN_CLIENT_ID_LIST_FETCH_SUCCESS';
export const ADMIN_CLIENT_IDS_ERROR = 'ADMIN_CLIENT_IDS_ERROR';
export const CLIENT_IDS_SET_TEXT_SEARCH = 'CLIENT_IDS_SET_TEXT_SEARCH';
export const CLIENT_IDS_SET_SORTING = 'CLIENT_IDS_SET_SORTING';
export const CLIENT_IDS_SET_LINES_PER_PAGE = 'CLIENT_IDS_SET_LINES_PER_PAGE';
export const CLIENT_IDS_SELECT_GROUP = 'CLIENT_IDS_SELECT_GROUP';
export const ADMIN_CLIENT_IDS_ACCEPTED = 'ADMIN_CLIENT_IDS_ACCEPTED';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: CLIENT_IDS_SELECT_GROUP,
        payload: countryGroup,
    });

export function fetchSettingClientIdData(group, page, perPage) {
    return (dispatch, getState) => {

        const {adminClientId} = getState();
        const {column, order} = adminClientId.sorting;
        const {fulltext} = adminClientId;
        const orderParam = column && order ? `${column}${REQUEST_PARAM_DELIM}${order}` : undefined;

        return dispatch({
            type: CLIENT_IDS_FETCH_PENDING,
            adminWebsocket: true,
            command: adminWebsocketCommands.ADMIN_CLIENT_ID_LIST_FETCH_COMMAND,
            payload: {
                group,
                page,
                perPage,
                fulltext: fulltext ? fulltext : undefined,
                order: orderParam,
            },
        });
    }
}

export function createClientIdRecord({selectedGroup, bir8, clientId, destinationCode, region, dealerName}) {
    return (dispatch) => {
        return dispatch({
            type: CLIENT_IDS_CREATE_PENDING,
            adminWebsocket: true,
            command: adminWebsocketCommands.ADMIN_CLIENT_ID_COPY_COMMAND,
            payload: {
                group: selectedGroup,
                bir8,
                clientId,
                destinationCode,
                dealerName,
                region,
            },
        });
    }
}

export function setSorting(column) {
    return (dispatch) => {
        dispatch({type: CLIENT_IDS_SET_SORTING, payload: column});
    }
}

export function setTextSearch(fulltext) {
    return (dispatch) => {
        dispatch({type: CLIENT_IDS_SET_TEXT_SEARCH, payload: fulltext});
    }
}

export function setLinesPerPage(linesPerPage) {
    return (dispatch) => {
        dispatch({type: CLIENT_IDS_SET_LINES_PER_PAGE, payload: linesPerPage});
    }
}

export function editClientIdRecordsState(index) {
    return (dispatch) => {
        dispatch({type: CLIENT_IDS_EDIT_RECORD, payload: {index}});
    }
}

export function updateClientIdRecord({index, clientId, destinationCode, region, group, bir8, uuid}) {
    return (dispatch) => {
        return dispatch({
            type: CLIENT_IDS_UPDATE_PENDING,
            adminWebsocket: true,
            command: adminWebsocketCommands.ADMIN_CLIENT_ID_UPDATE_COMMAND,
            payload: {
                index,
                group,
                bir8,
                clientIdUuid: uuid,
                clientId,
                destinationCode,
                region,
            },
        });
    }
}

export function deleteClientIdRecord(group, bir8, uuid, index) {
    return (dispatch) => {
        return dispatch({
            type: CLIENT_IDS_DELETE_PENDING,
            adminWebsocket: true,
            command: adminWebsocketCommands.ADMIN_CLIENT_ID_DELETE_COMMAND,
            payload: {
                index,
                group,
                bir8,
                clientIdUuid: uuid,
            },
        });
    }
}


