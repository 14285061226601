import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import get from 'get-value';
import MessageContainer from './mainMessage/MessageContainer';
import {moduleRoles} from '../../../utils/roles';
import SelectCountryGroup from '../../common/country/SelectCountryGroup';
import mainPageAlertMessages from '../../../intl/admin/mainPageAlertMessages';
import {storeSelectedCountryGroup} from '../../../actions/admin/actionAdminMainMessage';
import PageHeader from '../../common/PageHeader';
import ImportersTable from '../../common/ImportersTable';

const Label = styled.label`
    font-weight: bold;
`;

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

class MainPageAlertPage extends Component {
    componentDidMount() {
        const {domain, roles, selectedCountryGroup} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);
        if (selectedCountryGroup === '' || !groups.map(group => group.group).includes(selectedCountryGroup)) {
            this.props.storeSelectedCountryGroup(get(groups, '0.group', ''));
        }
    }

    handleCountryGroupSelection = countryGroup => this.props.storeSelectedCountryGroup(countryGroup.value);

    render() {
        const {roles, domain, selectedCountryGroup} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);
        return (
            <div>
                <PageHeader title={<FormattedMessage {...mainPageAlertMessages.TITLE}/>}/>
                <StyledForm className="form-group">
                    <div className="form-row mb-sm-3">
                        <div className="col-xl-2 col-lg-4 col-md-5 col-sm-6">
                            <Label>
                                <FormattedMessage {...mainPageAlertMessages.SELECT_COUNTRY}/>
                            </Label>
                            <SelectCountryGroup groups={groups}
                                                handleCountryGroupSelection={this.handleCountryGroupSelection}
                                                selectedCountryGroup={selectedCountryGroup}/>
                        </div>
                    </div>
                </StyledForm>
                <div>
                    {groups.map((group, index) =>
                        (selectedCountryGroup === group.group &&
                            <div key={index}>
                                <MessageContainer domain={domain} selectedGroup={group.group}/>
                            </div>
                        )
                    )}
                </div>
                {selectedCountryGroup && selectedCountryGroup === 'IMP' && <ImportersTable/>}
            </div>
        );
    }
}

MainPageAlertPage.propTypes = {
    roles: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    storeSelectedCountryGroup: PropTypes.func.isRequired,
    selectedCountryGroup: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
    selectedCountryGroup: state.adminMainMessage.selectedCountryGroup
});

export default connect(mapStateToProps, {
    storeSelectedCountryGroup
})(MainPageAlertPage);
