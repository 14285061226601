import React, {useEffect} from 'react';
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {
    FORM_FIELD_COURTESY_CAR_TYPE,
    FORM_CAR_REQUEST,
    FORM_CAR_REQUEST_SECTION, FORM_WAREHOUSE_SECTION, FORM_FIELD_DAILY_RATE, FORM_TICKET_SECTION
} from '../../../../../../constants/formConstants';
import {change, formValueSelector, getFormInitialValues} from 'redux-form';
import {connect} from 'react-redux';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import courtesyCarsMessages from '../../../../../../intl/sparePartsOrders/courtesyCarsMessages';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import commonMessages from '../../../../../../intl/common/commonMessages';
import {courtesyCars, dailyRates} from '../../../../../../constants/Utils';
import get from 'get-value';

const CarCourtesyTableRow = (props) => {
    const {carRequests, initialValues, index, userRights, readOnly, group, change} = props;
    const carType = carRequests ? get(carRequests, `${carRequests.length - 1}.carType`, '') : '';

    useEffect(() => {
        if (!get(initialValues, `${FORM_WAREHOUSE_SECTION}.${FORM_FIELD_DAILY_RATE}`) && carType) {
            change(`${FORM_WAREHOUSE_SECTION}.${FORM_FIELD_DAILY_RATE}`, get(dailyRates, `${group}.${carType}`, ''));
        }
    }, []);

    const handleChange = (e) => {
        if (e.target.value !== '' && e.target.value !== undefined && e.target.value !== null) {
            change(`${FORM_WAREHOUSE_SECTION}.${FORM_FIELD_DAILY_RATE}`, get(dailyRates, `${group}.${e.target.value}`, ''));
        }
    };

    return (
        <div key={index} className="row m-0 align-items-stretch">
            <div style={{backgroundColor: '#f8f9fa'}} className="col-sm-12 col-lg-4 flex-column border-bottom">
                <div>
                    <FormattedDate value={new Date(carRequests[index].created)}/> <FormattedTime
                    value={new Date(carRequests[index].created)}/>
                    <span className="font-weight-bold">{' '}
                        <ValidFormattedMessage message={carRequests[index].isDealer ? 'DEALER' : 'EDITOR'}
                                               intlMessages={commonMessages}/>
                    </span>
                </div>
                <div>
                    {carRequests[index].userName + ' (' + carRequests[index].creatorIpn + ')'}
                </div>
            </div>
            <div className="col border-bottom">
                <div className="row m-0">
                    <div className="col font-weight-bold">
                        <FormattedMessage {...formMessages.HISTORY_REQUEST}/>:
                    </div>
                    <div className="col">
                        {carRequests[index].request ?
                            <ValidFormattedMessage message={carRequests[index].request}
                                                   intlMessages={commonMessages}/> : '---'}
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col font-weight-bold">
                        <FormattedMessage {...formMessages.HISTORY_CAR_TYPE}/>:
                    </div>
                    <div className="col" style={{minWidth: '150px'}}>
                        {!readOnly && userRights.canEditCourCarType && index === carRequests.length - 1 ?
                            <InputSelectFieldWithValidation
                                field={`${FORM_CAR_REQUEST}[${index}].${FORM_FIELD_COURTESY_CAR_TYPE}`}
                                options={get(courtesyCars, `${get(initialValues, 'ticketSection.group', {default: 'X'})}`, {default: []})}
                                intlMessages={courtesyCarsMessages}
                                sort={false}
                                handleChange={handleChange}/>
                            : <>
                                {carRequests[index].carType ?
                                    <ValidFormattedMessage message={carRequests[index].carType}
                                                           intlMessages={courtesyCarsMessages}/> : '---'}
                            </>
                        }
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col font-weight-bold">
                        <FormattedMessage {...formMessages.HISTORY_INFORMATION}/>:
                    </div>
                    <div className="col">
                        {carRequests[index].information}
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state, initialProps) => ({
    carRequests: formValueSelector(initialProps.form)(state, `${FORM_CAR_REQUEST_SECTION}.${FORM_CAR_REQUEST}`),
    initialValues: getFormInitialValues(initialProps.form)(state),
    group: formValueSelector(initialProps.form)(state, `${FORM_TICKET_SECTION}.group`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(CarCourtesyTableRow);
