import React from 'react';
import {injectIntl, FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import {connect} from 'react-redux';
import {FieldArray, formValueSelector, getFormInitialValues} from 'redux-form';
import styled from 'styled-components';
import get from 'get-value';
import moment from 'moment';
import {TextareaFieldWithValidation} from '../../../../../common/TextareaFieldWithValidation';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import RenderDropZoneForEditorsNotes from './RenderDropZoneForEditorsNotes';
import UploadedAttachments from '../attachments/UploadedAttachments';
import formMessages from '../../../../../../intl/claims/formMessages';
import {
    FORM_ATTACHMENTS_SECTION,
    FORM_FIELD_ATTACHMENTS,
    FORM_FIELD_EDITORS_NOTES,
    FORM_FIELD_NEW_EDITORS_NOTE_ATTACHMENTS,
    FORM_LABEL_EDITORS_NOTES,
    FORM_LABEL_EDITORS_NOTES_HISTORY,
} from '../../../../../../constants/formConstants';
import {parseTextToJSX} from '../../../../../../utils/utils';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const compare = (a, b) => {
    return moment(a.created).isAfter(b.created) ? 1 : -1;
};

const EditorsNotesSection = props => {
    const {attachments = {}, userRights, readOnly, form, initialValues} = props;

    const editorsNotes = get(initialValues, 'editorsNotesSection.editorsNotes', []);
    const editorNoteAttachments = Object.keys(attachments).map(item => attachments[item]).filter(item => item.isEditor)
        .filter(item => item.section === 'editorsNotesSection').sort(compare);

    return (
        <>
            {((userRights.canAddEditorsNotes && !readOnly) ||
                (userRights.canShowEditorsNotes && editorsNotes && (editorsNotes.length > 0)) ||
                (userRights.canShowEditorsNotes && editorNoteAttachments && editorNoteAttachments.length > 0)) &&
            <>
                <h3 className="text-left mt-4 ml-4">
                    <FormattedMessage {...formMessages.EDITORS_NOTES_SECTION}/>
                </h3>
                <StyledDiv className="border border-secondary bg-white overflow-hidden">
                    {(editorsNotes && (editorsNotes.length > 0)) &&
                    <div>
                        <div className="border-bottom border-bottom-secondary"
                             style={{backgroundColor: '#e9ecef', borderRadius: '5px 5px 0 0'}}>
                            <label className="col-form-label pl-0 font-weight-bold ml-3">
                                <ValidFormattedMessage message={FORM_LABEL_EDITORS_NOTES_HISTORY}
                                                       intlMessages={formMessages}/>
                            </label>
                        </div>
                        {editorsNotes.map((editorsNote, index) =>
                            <div key={index} className="row m-0 align-items-stretch">
                                <div style={{backgroundColor: '#f8f9fa'}}
                                     className="col-sm-12 col-lg-4 flex-column border-bottom">
                                    <div>
                                        <FormattedDate value={new Date(editorsNote.created)}/>
                                        <FormattedTime value={new Date(editorsNote.created)}/>
                                        {' '}
                                        <span className="font-weight-bold">{editorsNote.creatorName}</span>
                                        {' '}({editorsNote.creatorIpn})
                                    </div>
                                </div>
                                <div className="col border-bottom" style={{whiteSpace: 'pre-wrap'}}>
                                    {parseTextToJSX(editorsNote.text)}
                                </div>
                            </div>
                        )}
                    </div>
                    }
                    {(editorNoteAttachments && editorNoteAttachments.length > 0) &&
                    <UploadedAttachments
                        attachments={editorNoteAttachments}
                        label={formMessages.EDITORS_NOTE_ATTACHMENTS}
                        form={form}
                        section={'EditorsNotesSection'}/>
                    }
                    {userRights.canAddEditorsNotes && !readOnly &&
                    <div className="col-sm-12 px-4 pb-3 pt-1">
                        <div className="col-sm-12 px-0">
                            <TextareaFieldWithValidation label={formMessages[FORM_LABEL_EDITORS_NOTES]}
                                                         field={FORM_FIELD_EDITORS_NOTES}
                                                         boldTooltip
                                                         rows={3}/>
                        </div>
                    </div>
                    }
                    {userRights.canAddEditorsNoteAttach && !readOnly &&
                    <div className="col-sm-12 px-4 pb-0 pt-1 mb-0">
                        <div className="col-2 px-0 pb-0 mb-0">
                            <label className="font-weight-bold">
                                <FormattedMessage {...formMessages.EDITORS_NOTE_ATTACHMENTS}/>
                            </label>
                        </div>
                        <FieldArray
                            name={FORM_FIELD_NEW_EDITORS_NOTE_ATTACHMENTS}
                            component={RenderDropZoneForEditorsNotes}/>
                    </div>
                    }
                </StyledDiv>
            </>}
        </>
    )
};

const mapStateToProps = (state, props) => ({
    initialValues: getFormInitialValues(props.form)(state),
    attachments: formValueSelector(props.form)(state, `${FORM_ATTACHMENTS_SECTION}.${FORM_FIELD_ATTACHMENTS}`),
});

export default injectIntl(connect(mapStateToProps, null)(EditorsNotesSection));
