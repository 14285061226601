export const supportProperties = {
    DEFAULT: {
        DEALER: {
            responsible: 'REYNOLDS & REYNOLDS',
            phone: '04 76 84 30 30',
            email: 'support-dcsnet_fr@reyrey.com'
        },
        EDITOR: {
            responsible: 'REYNOLDS & REYNOLDS',
            phone: '04 76 84 30 30',
            email: 'support-dcsnet_fr@reyrey.com'
        }
    },
    HR: {
        DEALER: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        },
        EDITOR: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        }
    },
    XK: {
        DEALER: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        },
        EDITOR: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        }
    },
    SI: {
        DEALER: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        },
        EDITOR: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        }
    },
    RS: {
        DEALER: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        },
        EDITOR: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        }
    },
    BA: {
        DEALER: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        },
        EDITOR: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        }
    },
    ME: {
        DEALER: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        },
        EDITOR: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        }
    },
    MK: {
        DEALER: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        },
        EDITOR: {
            responsible: 'Service Desk',
            phone: '+386 1 582 82 82',
            email: 'sos@telekom.si'
        }
    },
    AR: {
        DEALER: {
            responsible: 'SERVICE DESK RENAULT ARGENTINA',
            phone: '------------------',
            email: '------------------'
        },
        EDITOR: {
            responsible: 'SERVICE DESK RENAULT ARGENTINA',
            phone: '------------------',
            email: '------------------'
        }
    },
    AT: {
        DEALER: {
            responsible: 'Dealer HelpDesk',
            phone: '+43 / 1 / 68010-443',
            email: 'Helpdesk-edv@renault.at'
        },
        EDITOR: {
            responsible: 'Subsidiary HelpDesk',
            phone: '+43 / 1 / 68010-443',
            email: 'Helpdesk-edv@renault.at'
        }
    },
    BE: {
        DEALER: {
            responsible: 'RBL Helpdesk/NL Helpdesk',
            phone: 'e-mail only',
            email: 'DSI.ServiceDesk@renault.be'
        },
        EDITOR: {
            responsible: 'RBL Helpdesk',
            phone: 'e-mail only',
            email: 'DSI.ServiceDesk@renault.be'
        }
    },
    LU: {
        DEALER: {
            responsible: 'RBL Helpdesk',
            phone: 'e-mail only',
            email: 'DSI.ServiceDesk@renault.be'
        },
        EDITOR: {
            responsible: 'RBL Helpdesk',
            phone: 'e-mail only',
            email: 'DSI.ServiceDesk@renault.be'
        }
    },
    NL: {
        DEALER: {
            responsible: 'NL Helpdesk',
            phone: 'e-mail only',
            email: 'ict.servicedesk@renault.nl'
        },
        EDITOR: {
            responsible: 'NL Helpdesk',
            phone: 'e-mail only',
            email: 'ict.servicedesk@renault.nl'
        }
    },
    DE: {
        DEALER: {
            responsible: 'H\u00e4ndler Service Center IT',
            phone: '02232-739162',
            email: 'haendlerhelpdesk.deutschland@renault.de'
        },
        EDITOR: {
            responsible: 'H\u00e4ndler Service Center IT',
            phone: '02232-739162',
            email: 'haendlerhelpdesk.deutschland@renault.de'
        }
    },
    IT: {
        DEALER: {
            responsible: 'SERVICE DESK RENAULT ITALIA',
            phone: '800905469',
            email: 'service.desk@renault.it'
        },
        EDITOR: {
            responsible: 'SERVICE DESK RENAULT ITALIA',
            phone: '800905469',
            email: 'service.desk@renault.it'
        }
    },
    MA: {
        DEALER: {
            responsible: 'Service Desk',
            phone: '+212 522 349773',
            email: 'farah.idbougnoun@renault.com'
        },
        EDITOR: {
            responsible: 'Service Desk',
            phone: '+212 522 349773',
            email: 'farah.idbougnoun@renault.com'
        }
    },
    MX: {
        DEALER: {
            responsible: 'SERVICE DESK RENAULT MEXICO',
            phone: '------------------',
            email: '------------------'
        },
        EDITOR: {
            responsible: 'SERVICE DESK RENAULT MEXICO',
            phone: '------------------',
            email: '------------------'
        }
    },
    CZ: {
        DEALER: {
            responsible: 'Dealer HelpDesk',
            phone: '+420 222 339 400',
            email: 'helpdesk.cz@renault.cz'
        },
        EDITOR: {
            responsible: 'Subsidiary HelpDesk',
            phone: '+420 222 339 400',
            email: 'helpdesk.cz@renault.cz'
        }
    },
    SK: {
        DEALER: {
            responsible: 'Dealer HelpDesk',
            phone: '+421 220 839 977',
            email: 'helpdesk.sk@renault.sk'
        },
        EDITOR: {
            responsible: 'Subsidiary HelpDesk',
            phone: '+421 220 839 977',
            email: 'helpdesk.sk@renault.sk'
        }
    },
    HU: {
        DEALER: {
            responsible: 'Dealer HelpDesk',
            phone: '+36 1 288 2000',
            email: 'dealer.support@renault.hu'
        },
        EDITOR: {
            responsible: 'Subsidiary HelpDesk',
            phone: '+36 1 288 2000',
            email: 'dealer.support@renault.hu'
        }
    },
    DK: {
        DEALER: {
            responsible: '',
            phone: '',
            email: ''
        },
        EDITOR: {
            responsible: '',
            phone: '',
            email: ''
        }
    },
    DZ: {
        DEALER: {
            responsible: 'Renault Nordic IT',
            phone: '0858575200',
            email: 'Dealers.Nordic-IT-Helpdesk@renault.com'
        },
        EDITOR: {
            responsible: 'Renault Nordic IT',
            phone: '0858575200',
            email: 'Dealers.Nordic-IT-Helpdesk@renault.com'
        }
    },
    SE: {
        DEALER: {
            responsible: 'Renault Nordic IT',
            phone: '0858575200',
            email: 'Dealers.Nordic-IT-Helpdesk@renault.com'
        },
        EDITOR: {
            responsible: 'Renault Nordic IT',
            phone: '0858575200',
            email: 'Dealers.Nordic-IT-Helpdesk@renault.com'
        }
    },
    FI: {
        DEALER: {
            responsible: '',
            phone: '',
            email: ''
        },
        EDITOR: {
            responsible: '',
            phone: '',
            email: ''
        }
    },
    NO: {
        DEALER: {
            responsible: '',
            phone: '',
            email: ''
        },
        EDITOR: {
            responsible: '',
            phone: '',
            email: ''
        }
    },
    PL: {
        DEALER: {
            responsible: 'SERWIS COMARCH',
            phone: '00048 12 646 1 646',
            email: 'serwis.renault@comarch.pl'
        },
        EDITOR: {
            responsible: 'SERWIS COMARCH',
            phone: '00048 12 646 1 646',
            email: 'serwis.renault@comarch.pl'
        }
    },
    LV: {
        DEALER: {
            responsible: 'SERWIS COMARCH',
            phone: '00048 12 646 1 646',
            email: 'serwis.renault@comarch.pl'
        },
        EDITOR: {
            responsible: 'SERWIS COMARCH',
            phone: '00048 12 646 1 646',
            email: 'serwis.renault@comarch.pl'
        }
    },
    LT: {
        DEALER: {
            responsible: 'SERWIS COMARCH',
            phone: '00048 12 646 1 646',
            email: 'serwis.renault@comarch.pl'
        },
        EDITOR: {
            responsible: 'SERWIS COMARCH',
            phone: '00048 12 646 1 646',
            email: 'serwis.renault@comarch.pl'
        }
    },
    EE: {
        DEALER: {
            responsible: 'SERWIS COMARCH',
            phone: '00048 12 646 1 646',
            email: 'serwis.renault@comarch.pl'
        },
        EDITOR: {
            responsible: 'SERWIS COMARCH',
            phone: '00048 12 646 1 646',
            email: 'serwis.renault@comarch.pl'
        }
    },
    PT: {
        DEALER: {
            responsible: 'Helpdesk Portugal',
            phone: '+351 218 361 030',
            email: 'Helpdesk.Portugal@renault.pt'
        },
        EDITOR: {
            responsible: 'Helpdesk Portugal',
            phone: '+351 218 361 200',
            email: 'Helpdesk.Portugal@renault.pt'
        }
    },
    RO: {
        DEALER: {
            responsible: 'Helpdesk Dacia',
            phone: '0248500000',
            email: 'helpdesk.dacia@daciagroup.co'
        },
        EDITOR: {
            responsible: 'Helpdesk Dacia',
            phone: '0248500000',
            email: 'helpdesk.dacia@daciagroup.co'
        }
    },
    ES: {
        DEALER: {
            responsible: 'Helpdesk',
            phone: '901116298',
            email: '--------'
        },
        EDITOR: {
            responsible: 'Helpdesk',
            phone: '901116298',
            email: '--------'
        }
    },
    CH: {
        DEALER: {
            responsible: 'Service Desk',
            phone: '(GER) +41 44 777 03 77 (FRA) +41 44 777 04 37',
            email: 'SD-DEALER-CH@renault.ch'
        },
        EDITOR: {
            responsible: 'Service Desk',
            phone: '(GER) +41 44 777 03 77 (FRA) +41 44 777 04 37',
            email: 'SD-DEALER-CH@renault.ch'
        }
    },
    GB: {
        DEALER: {
            responsible: 'ISGO SERVICE DESK U.K',
            phone: '0844 561 7177',
            email: 'isgo.servicedesk@renault.co.uk'
        },
        EDITOR: {
            responsible: 'ISGO SERVICE DESK U.K',
            phone: '0844 561 7177',
            email: 'isgo.servicedesk@renault.co.uk'
        }
    },
    IE: {
        DEALER: {
            responsible: 'ISGO SERVICE DESK U.K',
            phone: '0844 561 7177',
            email: 'isgo.servicedesk@renault.co.uk'
        },
        EDITOR: {
            responsible: 'ISGO SERVICE DESK U.K',
            phone: '0844 561 7177',
            email: 'isgo.servicedesk@renault.co.uk'
        }
    },
    FR: {
        DEALER: {
            responsible: 'REYNOLDS & REYNOLDS',
            phone: '04 76 84 30 30',
            email: 'support-dcsnet_fr@reyrey.com'
        },
        EDITOR: {
            responsible: 'REYNOLDS & REYNOLDS',
            phone: '04 76 84 30 30',
            email: 'support-dcsnet_fr@reyrey.com'
        }
    },
    UA: {
        DEALER: {
            responsible: 'SERVICE DESK RENAULT UKRAINE',
            phone: '------------------',
            email: '------------------'
        },
        EDITOR: {
            responsible: 'SERVICE DESK RENAULT UKRAINE',
            phone: '------------------',
            email: '------------------'
        }
    },
    IN: {
        DEALER: {
            responsible: '',
            phone: '',
            email: ''
        },
        EDITOR: {
            responsible: '',
            phone: '',
            email: ''
        }
    },
    KR: {
        DEALER: {
            responsible: '',
            phone: '',
            email: ''
        },
        EDITOR: {
            responsible: '',
            phone: '',
            email: ''
        }
    },
    CO: {
        DEALER: {
            responsible: '',
            phone: '',
            email: ''
        },
        EDITOR: {
            responsible: '',
            phone: '',
            email: ''
        }
    },
    BR: {
        DEALER: {
            responsible: '',
            phone: '',
            email: ''
        },
        EDITOR: {
            responsible: '',
            phone: '',
            email: ''
        }
    },
    TR: {
        DEALER: {
            responsible: '',
            phone: '',
            email: ''
        },
        EDITOR: {
            responsible: '',
            phone: '',
            email: ''
        }
    },
};

export const localeToCodeMapping = {
    bs: 'BS',
    cs: 'CZ',
    da: 'DK',
    de: 'DE',
    en: 'DEFAULT',
    es: 'ES',
    fr: 'FR',
    hr: 'HR',
    hu: 'HU',
    it: 'IT',
    mx: 'MX',
    nl: 'NL',
    pl: 'PL',
    pt: 'PT',
    ro: 'RO',
    ru: 'RU',
    sk: 'SK',
    sl: 'SI',
    sr: 'RS',
    sv: 'SE',
    ua: 'UA',
    gsw: 'GSW',
    sq: 'XK',
};

