import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';
import get from 'get-value';
import {clearAlerts} from '../../../actions/alertsActions';
import Loader from '../../Loader';
import {
    CODED_PARTS_KEYS_FORM,
    OVAL_PLATE_ORDER_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
    UNKNOWN_PART_OPENING_REQUEST_FORM
} from '../../../constants/formConstants';
import set from 'set-value';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import UnknownPartOpeningMasterForm from './forms/UnknownPartOpeningMasterForm';
import OvalPlateOrderMasterForm from './forms/OvalPlateOrderMasterForm';
import NotFoundRefRequestMasterForm from './forms/NotFoundRefRequestMasterForm';
import NotConformRefRequestMasterForm from './forms/NotConformRefRequestMasterForm';
import CodedPartsMasterForm from './forms/CodedPartsMasterForm';
import {initNewTechDocTicket, transformNewTicketForSave} from '../Utils';
import {resetTechDocTicket, saveTechDocTicket} from '../../../actions/techDoc/actionTechDocTicket';
import {createRights} from '../../../constants/techDocTicketRights';
import NoBirComponent from '../../common/NoBirComponent';
import {createReqFields, createReqFieldsCodedPartsKeysForm} from '../../../constants/techDocRequiredFields';
import UploadingTechDocAttachments from './UploadingTechDocAttachments';
import {fetchAdminClientIds} from '../../../actions/admin/actionAdminClientIds';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import PageHeader from '../../common/PageHeader';
import PropTypes from 'prop-types';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {countryGroups} from '../../../constants/Utils';
import OverlayLoader from '../../OverlayLoader';
import formMessages from '../../../intl/sparePartsOrders/formMessages';
import {closeSparePartDicModal} from '../../../actions/orders/actionSparePartsDicData';
import {closeTechDocDicModal} from '../../../actions/techDoc/actionTechDocDicData';

class NewTechDocTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false};
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetTechDocTicket();
        this.props.fetchAdminClientIds();
    }


    handleSubmit = (values) => {
        const dataForSend = transformNewTicketForSave(values.newValues);
        this.props.saveTechDocTicket(dataForSend);
        this.setState({ticketSubmittedAndShouldRedirect: true});

    };

    render() {
        const {
            formType,
            isLoadingClientIds,
            ticketData,
            attachmentsData,
            clientIds,
            group,
            dicInfo: {isModalActive},
            closeTechDocDicModal
        } = this.props;

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingTechDocAttachments attachments={attachmentsData.attachmentsForUpload}
                                                attachmentsUrl={ticketData.newAttachments}
                                                correlationId={ticketData.correlationId}
                                                isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                                formType={formType}/>
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/documentation/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (!formType
            || this.state.ticketSubmittedAndShouldRedirect
            || isLoadingClientIds
            || clientIds.isLoading) {
            return <Loader/>;
        }

        const initializationValue = initNewTechDocTicket(this.props.userDetail);
        if (!initializationValue) {
            return <NoBirComponent formType={formType}/>;
        }

        const requiredFields = createReqFields[createReqFields[group] ? group : 'DEFAULT'][formType];

        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={<ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>}/>
                <div>
                    {formType === UNKNOWN_PART_OPENING_REQUEST_FORM &&
                    <UnknownPartOpeningMasterForm form={formType}
                                                  initialValues={set(initializationValue, 'ticketSection.docType', UNKNOWN_PART_OPENING_REQUEST_FORM)}
                                                  userRights={createRights}
                                                  reqFields={requiredFields}
                                                  enableReinitialize={true}
                                                  handleSubmit={this.handleSubmit}/>
                    }
                    {formType === OVAL_PLATE_ORDER_FORM &&
                    <OvalPlateOrderMasterForm form={formType}
                                              initialValues={set({
                                                  ...initializationValue,
                                                  vehicleDetail: {bvmLoaded: true, ovalPlate: {newOvalPlate: true}}
                                              }, 'ticketSection.docType', OVAL_PLATE_ORDER_FORM)}
                                              userRights={createRights}
                                              reqFields={requiredFields}
                                              enableReinitialize={true}
                                              handleSubmit={this.handleSubmit}/>
                    }
                    {formType === CODED_PARTS_KEYS_FORM &&
                    <CodedPartsMasterForm form={formType}
                                          initialValues={set({
                                              ...initializationValue,
                                              vehicleDetail: {bvmLoaded: true, ovalPlate: {newOvalPlate: true}},
                                              problemDescription: {immobilizedVehicle: 'NO'},
                                          }, 'ticketSection.docType', CODED_PARTS_KEYS_FORM)}
                                          userRights={createRights}
                                          reqFields={((group === countryGroups.GB || group === countryGroups.IE) ? createReqFields[createReqFields[group] ? group : 'DEFAULT'][formType + '_GBIE'] : requiredFields)}
                                          enableReinitialize={true}
                                          handleSubmit={this.handleSubmit}/>
                    }
                    {formType === SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM &&
                    <NotFoundRefRequestMasterForm form={formType}
                                                  initialValues={set(initializationValue, 'ticketSection.docType', SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM)}
                                                  userRights={createRights}
                                                  reqFields={requiredFields}
                                                  enableReinitialize={true}
                                                  handleSubmit={this.handleSubmit}/>
                    }
                    {formType === SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM &&
                    <NotConformRefRequestMasterForm form={formType}
                                                    initialValues={set(initializationValue, 'ticketSection.docType', SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM)}
                                                    userRights={createRights}
                                                    reqFields={requiredFields}
                                                    enableReinitialize={true}
                                                    handleSubmit={this.handleSubmit}/>
                    }
                </div>
                <OverlayLoader active={isModalActive}
                               text={formMessages.WAIT_DIC}
                               handleClose={closeTechDocDicModal}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userDetail: state.profile.userDetail,
    group: state.profile.userDetail.group,
    isLoadingClientIds: state.adminClientIds.isLoading,
    ticketData: state.techDocTicket,
    attachmentsData: state.techDocAttachment,
    clientIds: state.adminClientIds,
    dicInfo: state.techDocDic,
});

NewTechDocTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    fetchAdminClientIds: PropTypes.func.isRequired,
    userDetail: PropTypes.object.isRequired,
    formType: PropTypes.string.isRequired,
    isLoadingClientIds: PropTypes.bool.isRequired,
    saveTechDocTicket: PropTypes.func.isRequired,
    ticketData: PropTypes.object.isRequired,
    attachmentsData: PropTypes.object.isRequired,
    clientIds: PropTypes.object.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    saveTechDocTicket,
    fetchAdminClientIds,
    resetTechDocTicket,
    closeTechDocDicModal
})(NewTechDocTicketPage)), ['TD_DEALER']);

