import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import get from 'get-value';
import * as PropTypes from 'prop-types';
import NotificationContainer from './NotificationContainer';
import {FormattedMessage} from 'react-intl';
import {moduleRoles} from '../../../utils/roles';
import SelectCountryGroup from '../../common/country/SelectCountryGroup';
import {storeSelectedCountryGroup} from '../../../actions/admin/actionAdminNotification';
import notificationMessages from '../../../intl/admin/notificationMessages';
import PageHeader from '../../common/PageHeader';
import ImportersTable from '../../common/ImportersTable';

const Label = styled.label`
    font-weight: bold;
`;

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

class NotificationPage extends Component {
    componentDidMount() {
        const {domain, roles, selectedCountryGroup} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);
        if (selectedCountryGroup === '' || !groups.map(group => group.group).includes(selectedCountryGroup)) {
            this.props.storeSelectedCountryGroup(get(groups, '0.group', ''));
        }
    }

    handleCountryGroupSelection = countryGroup => this.props.storeSelectedCountryGroup(countryGroup.value);

    render() {
        const {domain, roles, selectedCountryGroup} = this.props;
        const groups = moduleRoles.getAdminGroups(domain, roles);
        return (
            <div>
                <PageHeader title={<FormattedMessage {...notificationMessages.TITLE}/>}/>
                <StyledForm className="form-group">
                    <div className="form-row mb-sm-3">
                        <div className="col-xl-2 col-lg-4 col-md-5 col-sm-6">
                            <Label>
                                <FormattedMessage {...notificationMessages.SELECT_COUNTRY}/>
                            </Label>
                            <SelectCountryGroup groups={groups}
                                                handleCountryGroupSelection={this.handleCountryGroupSelection}
                                                selectedCountryGroup={selectedCountryGroup}/>
                        </div>
                    </div>
                </StyledForm>
                {groups.map((group, index) =>
                    (selectedCountryGroup === group.group &&
                        <div key={index}>
                            <NotificationContainer domain={domain} group={group.group}/>
                        </div>
                    )
                )}
                {selectedCountryGroup && selectedCountryGroup === 'IMP' && <ImportersTable/>}
            </div>
        );
    }
}

NotificationPage.propTypes = {
    domain: PropTypes.string.isRequired,
    roles: PropTypes.object.isRequired,
    storeSelectedCountryGroup: PropTypes.func.isRequired,
    selectedCountryGroup: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
    selectedCountryGroup: state.adminNotification.selectedCountryGroup
});

export default connect(mapStateToProps, {
    storeSelectedCountryGroup
})(NotificationPage);
