import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {editClientIdRecordsState, updateClientIdRecord} from '../../../actions/actionClientId';
import RecordButtonForm from './forms/RecordButtonForm';
import {DestructiveIcon, IconAdd, IconCancel, IconDelete, IconEdit, PrimaryIcon} from '../../common/Button'
import PropTypes from 'prop-types';
import EditClientIdForm from './forms/EditClientIdForm';
import {CLIENT_ID_FIELD_MAX_LENGTH} from '../../../constants/validationConstants';

const Row = styled.tr`
    background-color: ${props => (props.isEmpty ? '#f8d7da' : '#fff')};
`;

const Cell = styled.td`
  box-sizing: border-box;
  white-space: pre;
  &&{padding: 10px;};
`;

const ToolCell = styled(Cell)`
  width: 125px;
`;

export const InputCell = styled(Cell)`
  width: 20%;
`;

const ButtonGroupDiv = styled.div`
  display: flex;
`;

class ClientIdRecord extends Component {
    handleClickPencil = (e) => {
        const {editClientIdRecordsState, index} = this.props;
        e.preventDefault();
        editClientIdRecordsState(index);
    };

    handleCancel = (e) => {
        const {editClientIdRecordsState} = this.props;
        e.preventDefault();
        editClientIdRecordsState(-1);
    };

    handleSubmit = (values) => {
        const {index, updateClientIdRecord, selectedGroup, data: {bir8, uuid}} = this.props;
        updateClientIdRecord({
            index,
            clientId: values.clientId.padStart(CLIENT_ID_FIELD_MAX_LENGTH, '0'),
            destinationCode: values.destinationCode.padStart(CLIENT_ID_FIELD_MAX_LENGTH, '0'),
            region: values.region,
            group: selectedGroup,
            bir8,
            uuid
        });
    };

    renderTools() {
        const {editRecord, index, data, handleDeleteClick, handleAddClick} = this.props;
        return (
            <ButtonGroupDiv>
                {(editRecord !== index) ?
                    <React.Fragment>
                        {data.type !== 'NOT_USED' &&
                        <PrimaryIcon className="btn btn-sm"
                                     onMouseDown={this.handleClickPencil}
                                     title="Edit"
                                     type="button"
                                     disabled={editRecord === -1 ? false : true}>
                            <IconEdit/>
                        </PrimaryIcon>}
                        {!data.original && <PrimaryIcon className="btn btn-sm ml-1"
                                                        onMouseDown={() => handleDeleteClick(data, index)}
                                                        title="Delete"
                                                        type="button"
                                                        key="deleteButton"
                                                        disabled={editRecord === -1 ? false : true}>
                            <IconDelete/>
                        </PrimaryIcon>}
                        {data.type !== 'NOT_USED' && !!data.destinationCode && !!data.region &&
                        <PrimaryIcon className="btn btn-sm ml-1"
                                     onMouseDown={() => handleAddClick(data)}
                                     title="Duplicate"
                                     type="button"
                                     key="duplicateButton"
                                     disabled={editRecord === -1 ? false : true}>
                            <IconAdd/>
                        </PrimaryIcon>}

                    </React.Fragment>
                    :
                    <React.Fragment>
                        <DestructiveIcon className="btn btn-sm"
                                         onClick={this.handleCancel}
                                         type="button">
                            <IconCancel/>
                        </DestructiveIcon>
                        <RecordButtonForm onSubmit={this.handleSubmit} form={`ClientIdRecordForm${index}`}/>
                    </React.Fragment>}
            </ButtonGroupDiv>
        );
    }

    renderFormCells() {
        const {editRecord, index, data} = this.props;
        if (editRecord === index) {
            return (
                <EditClientIdForm initialValues={{
                    clientId: data.clientId,
                    destinationCode: data.destinationCode,
                    region: data.region
                }} enableReinitialize={true} form={`ClientIdRecordForm${index}`}/>
            );
        } else {
            return (
                <React.Fragment>
                    <InputCell>{data.type === 'NOT_USED' ? 'NOT_USED' : data.clientId}</InputCell>
                    <InputCell>{data.destinationCode}</InputCell>
                    <InputCell>{data.region}</InputCell>
                </React.Fragment>);
        }
    }

    render() {
        const {data} = this.props;
        return (
            <Row isEmpty={(!data.clientId || !data.destinationCode || !data.region) && data.type !== 'NOT_USED'}>
                <Cell>{data.bir8}</Cell>
                <Cell>{data.dealerName}</Cell>
                {this.renderFormCells()}
                <ToolCell>{this.renderTools()}</ToolCell>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    editRecord: state.adminClientId.edit,
});

ClientIdRecord.propTypes = {
    data: PropTypes.object.isRequired,
    index: PropTypes.any.isRequired,
    updateClientIdRecord: PropTypes.func.isRequired,
    editClientIdRecordsState: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired,
    handleAddClick: PropTypes.func.isRequired,
    editRecord: PropTypes.any.isRequired,
    selectedGroup: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {
    editClientIdRecordsState,
    updateClientIdRecord,
})(ClientIdRecord);
