import {
    SPARE_PARTS_INTERNAL_CREATE_TICKET, SPARE_PARTS_INTERNAL_GET_SG_INFO,
    SPARE_PARTS_INTERNAL_GET_TICKET,
    SPARE_PARTS_INTERNAL_UPDATE_TICKET
} from '../../constants/websocketCommands';

export const SPARE_PARTS_INTERNAL_TICKET_RESET = 'SPARE_PARTS_INTERNAL_TICKET_RESET';
export const SPARE_PARTS_INTERNAL_TICKET_CREATE = 'SPARE_PARTS_INTERNAL_TICKET_CREATE';
export const SPARE_PARTS_INTERNAL_TICKET_UPDATE = 'SPARE_PARTS_INTERNAL_TICKET_UPDATE';


export const updateSparePartsInternalTicket = (data) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_INTERNAL_TICKET_UPDATE,
        sparePartsWebsocket: true,
        command: SPARE_PARTS_INTERNAL_UPDATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const saveSparePartsInternalTicket = (data) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_INTERNAL_TICKET_CREATE,
        sparePartsWebsocket: true,
        command: SPARE_PARTS_INTERNAL_CREATE_TICKET,
        payload: {
            ...data,
        }
    });
};

export const fetchSparePartsInternalTicket = (uuid) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_INTERNAL_GET_TICKET,
        sparePartsWebsocket: true,
        command: SPARE_PARTS_INTERNAL_GET_TICKET,
        payload: {
            ticket: {
                uuid: uuid,
            }
        }
    });
};

export const fetchSparePartsInternalTicketSgInfo = (sgId) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_INTERNAL_GET_SG_INFO,
        sparePartsWebsocket: true,
        command: SPARE_PARTS_INTERNAL_GET_SG_INFO,
        payload: {
            solutionGroupId: sgId
        }
    });
};

export const resetSparePartsInternalTicket = () => dispatch => {
    return dispatch({
        type: SPARE_PARTS_INTERNAL_TICKET_RESET,
    });
};
