import React, {Component} from 'react';
import commonMessages from '../intl/common/commonMessages';
import {connect} from 'react-redux';
import {warnAlert} from '../actions/alertsActions';
import PropTypes from 'prop-types';

class NotFoundPage extends Component {
    componentDidMount() {
        this.props.warnAlert(commonMessages.PAGE_DOES_NOT_EXIST);
    }

    render = () => (
        <div>
        </div>
    );
}

NotFoundPage.propTypes = {
    warnAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
    warnAlert
})(NotFoundPage);
