import Joi from 'joi';

export function validateFlows(flows) {
    return Joi.validate(
        flows,
        Joi.array().required().items(Joi.object().keys(getFlowSchema()))
    );
}

export function validateFlow(flow) {
    return Joi.validate(
        flow,
        Joi.object().required().keys(getFlowSchema())
    );
}

export function validateFlowCreateEvent(flowCreateEvent) {
    return Joi.validate(
        flowCreateEvent,
        Joi.object().required().keys({
            name: Joi.string().required(),
            items: Joi.array().required().min(1).items(
                Joi.object().keys(getSolutionGroupSchema())
            )
        })
    );
}

export function validateFlowUpdateEvent(flowUpdateEvent) {
    return Joi.validate(
        flowUpdateEvent,
        Joi.object().required().keys({
            changedName: Joi.string().min(1),
            changedItems: Joi.array().required().items(
                Joi.object().keys(getSolutionGroupSchema())
            )
        })
    );
}

function getFlowSchema() {
    return {
        id: Joi.string().required().guid({version: ['uuidv4']}),
        name: Joi.string().required(),
        items: Joi.array().required().min(1).items(
            Joi.object().keys(getSolutionGroupSchema())
        )
    };
}

function getSolutionGroupSchema() {
    return {
        item: Joi.object().required().keys({
            id: Joi.string().required().guid({version: ['uuidv4']}),
            name: Joi.string().required()
        }),
        child: Joi.object().keys({
            id: Joi.string().required().guid({version: ['uuidv4']}),
            name: Joi.string().required()
        })
    };
}
