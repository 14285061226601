import React from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {handleFetchSearchClientIdsError} from '../../../../actions/admin/actionAdminClientIds';
import {injectIntl} from 'react-intl';
import internalFulltextSearchMessages from '../../../../intl/sparePartsOrders/internalFulltextSearchMessages';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';
import {
    FORM_FIELD_MANUFACTURE_DATE,
    FORM_FIELD_TAPV,
    FORM_FIELD_VEHICLE_BRAND, FORM_FIELD_VEHICLE_FAMILY, FORM_FIELD_VIN,
    FORM_LABEL_MANUFACTURE_DATE,
    FORM_LABEL_TAPV,
    FORM_LABEL_VEHICLE_BRAND, FORM_LABEL_VEHICLE_FAMILY, FORM_LABEL_VIN
} from '../../../../constants/formConstants';
import {Field} from 'redux-form';
import BorderRadius from '../../../common/styled/BorderRadius';
import {handleFetchUsersError} from '../../../../actions/actionRole';
import DatePickerComponent from '../../../common/datePicker/DatePickerComponent';
import moment from 'moment';

const VehicleDetailInternalSearchSection = ({domain, intl}, ) => {

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...internalFulltextSearchMessages.VEHICLE_DETAIL_SECTION}/>
            </h3>
            <BorderRadius size={5} className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_VIN]}
                                                  field={FORM_FIELD_VIN}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <Field component={DatePickerComponent}
                               selectsEnd
                               isClearable
                               label={internalFulltextSearchMessages[FORM_LABEL_MANUFACTURE_DATE]}
                               name={FORM_FIELD_MANUFACTURE_DATE}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_TAPV]}
                                                  field={FORM_FIELD_TAPV}
                                                  maxLength={4}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_VEHICLE_FAMILY]}
                                                  field={FORM_FIELD_VEHICLE_FAMILY}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={internalFulltextSearchMessages[FORM_LABEL_VEHICLE_BRAND]}
                                                  field={FORM_FIELD_VEHICLE_BRAND}
                                                  isSmall
                        />
                    </div>
                </div>
            </BorderRadius>
        </>
    )
};

const mapStateToProps = (state) => ({
    roles: state.profile.userDetail.roles,
    lastEditors: state.sparePartsInternalTicketSearch.lastEditors,
});

const mapDispatchToProps = (dispatch, props) => ({
    handleFetchUsersError,
    handleFetchSearchClientIdsError

});


export default  connect(mapStateToProps, mapDispatchToProps)(injectIntl(VehicleDetailInternalSearchSection));
