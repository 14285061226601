import {
    ADMIN_RECURRENT_PROBLEM_ADD,
    ADMIN_RECURRENT_PROBLEM_EDIT,
    ADMIN_RECURRENT_PROBLEM_FETCH_PENDING,
    ADMIN_RECURRENT_PROBLEM_REMOVE,
    ADMIN_RECURRENT_PROBLEM_SELECT_GROUP,
    ADMIN_RECURRENT_PROBLEM_SWITCH,
    ADMIN_RECURRENT_PROBLEM_UPDATE
} from '../../actions/admin/actionAdminRecurrentProbem';
import {move} from '../../utils/utils';
import get from 'get-value';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const adminRecurrentProblem = (state = {
    isLoading: false,
    recurrentProblems: [],
    edit: -1,
    selectedCountryGroup: '',
}, action) => {
    switch (action.type) {
        case ADMIN_RECURRENT_PROBLEM_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true, recurrentProblems: []});
        }
        case ADMIN_RECURRENT_PROBLEM_EDIT: {
            return Object.assign({}, state, {edit: action.payload.index});
        }
        case ADMIN_RECURRENT_PROBLEM_SELECT_GROUP: {
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_SEND:
        case techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_SEND:
        case claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                recurrentProblems: get(action, 'payload.recurrentProblems', {default: []}),
                correlationId
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_SEND_ERROR:
        case techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_SEND_ERROR:
        case claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, recurrentProblems: [], correlationId});
        }
        case ADMIN_RECURRENT_PROBLEM_SWITCH: {
            let newArray = state.recurrentProblems.slice();
            move(newArray, action.payload.oldIndex, action.payload.newIndex);
            return Object.assign({}, state, {recurrentProblems: newArray});
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ERROR:
        case techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_ERROR:
        case claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId});
        }
        case ADMIN_RECURRENT_PROBLEM_ADD:
            return Object.assign({}, state, {isLoading: true});
        case ADMIN_RECURRENT_PROBLEM_REMOVE:
            return Object.assign({}, state, {isLoading: true});
        case ADMIN_RECURRENT_PROBLEM_UPDATE: {
            let newArray = state.recurrentProblems.slice();
            newArray[action.payload.index] = action.payload.recurrentProblem;
            return Object.assign({}, state, {edit: -1, recurrentProblems: newArray});
        }
        default:
            return state;
    }
};
