import React from 'react';
import styled from 'styled-components';
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';
import {formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import {
    downloadAttachment,
} from '../../../../../../actions/orders/actionAttachments';
import {
    FORM_TICKET_SECTION
} from '../../../../../../constants/formConstants';

const StyledA = styled.a`
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  :hover&&{
        color: #ffcc33;
    };
`;

const StyledHeaderDiv = styled.div`
    &&{background-color: #e9ecef;};
`;

const StyledInfoDiv = styled.div`
    &&{background-color: #f8f9fa;};
`;

const UploadedAttachments = (props) => {
    const {attachments, label, downloadAttachment, ticketId} = props;

    const downloadFile = (uuid, name) => (e) => {
        e.preventDefault();
        downloadAttachment(uuid, ticketId, name);
    };

    return (
        <div>
            <StyledHeaderDiv className="border-bottom border-bottom-secondary">
                <label className="col-form-label pl-0 font-weight-bold ml-3">
                    {!!label && label instanceof Object && <FormattedMessage {...label}/>}
                </label>
                <div className="pl-2 py-auto"/>
            </StyledHeaderDiv>
            {attachments.map((att, index) =>
                <div key={index} className="row m-0 align-items-stretch">
                    <StyledInfoDiv className="col-sm-12 col-lg-4 flex-column border-bottom">
                        <div>
                            <FormattedDate value={new Date(att.created)}/> <FormattedTime value={new Date(att.created)}/>{' '}
                            <span className="font-weight-bold">{att.creatorName}</span>
                            {' '}({att.creatorIpn})
                        </div>
                    </StyledInfoDiv>
                    <div className="col border-bottom">
                        <StyledA onClick={downloadFile(att.uuid, att.name)}>{`${att.name}`}</StyledA>
                    </div>
                </div>
            )}
        </div>
    )
};

const mapStateToProps = (state, initialProps) => ({
    ticketId: formValueSelector(initialProps.form)(state, `${FORM_TICKET_SECTION}.uuid`),
});

export default connect(mapStateToProps, {
    downloadAttachment,
})(UploadedAttachments);
