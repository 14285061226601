import get from 'get-value';
import {
    sparePartsWebsocketCommands
} from '../../constants/sparePartsWebsocketCommands';

export const sparePartsOrderTypeFilter = (state = {
    isLoadingOrderTypeFilter: false,
    orderTypeFilter: [],
    correlationId: null,
}, action) => {
    switch (action.type) {
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_ORDER_TYPE_FILTER: {
            return Object.assign({}, state, {isLoadingOrderTypeFilter: true});
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDER_TYPE_FILTER_FETCH_SUCCESS: {
            const orderTypeFilter = get(action, 'payload.orderTypeFilter', {default: []});
            return Object.assign({}, state, {
                isLoadingOrderTypeFilter: false,
                orderTypeFilter: orderTypeFilter,
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDER_TYPE_FILTER_FETCH_ERROR: {
            return Object.assign({}, state, {isLoadingOrderTypeFilter: false});
        }
        default:
            return state;
    }
};
