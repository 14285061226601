import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import styled from 'styled-components';
import get from 'get-value';
import {PrimaryButton} from '../Button';

const Button = styled(PrimaryButton)`
    display: inline;
    width: 50%;
    background-clip: padding-box;
    padding-left: 2px;
    padding-first: 2px;
`;

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.first = this.first.bind(this);
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.last = this.last.bind(this);
    }

    first() {
        this.props.onFetchData({ ...this.props, isNextFromPivot: true, page: 1 });
    };

    next() {
        const page = this.props.activePage + 1;
        const pages = this.props.pages;
        const pivot = get(this, `props.data.${this.props.data.length - 1}`, { default: {} });
        this.props.onFetchData({
            ...this.props,
            cursor: pivot.cursor,
            isNextFromPivot: true,
            page: page > pages ? pages : page
        });
    };

    prev() {
        const page = this.props.activePage - 1;
        const pivot = get(this, 'props.data.0', { default: {} });
        this.props.onFetchData({
            ...this.props,
            cursor: pivot.cursor,
            isNextFromPivot: false,
            page: page < 1 ? 1 : page
        });
    };

    last() {
        this.props.onFetchData({ ...this.props, isNextFromPivot: false, page: this.props.pages });
    };

    getPage = () => {
        const { activePage, isNextPage, isNextFromPivot, pages } = this.props;
        if (!isNextFromPivot && activePage === 1 && isNextPage) {
            return 2;
        } else if (isNextFromPivot && activePage === pages && isNextPage) {
            return pages - 1;
        } else {
            return activePage;
        }
    };

    render() {
        const { pages = 1, pageSize, onPageSizeChange } = this.props;

        return (
            <div className="-pagination">
                <div className="-previous">
                        <Button className="btn"
                                onClick={() => {
                                    this.first();
                                }}
                                disabled={this.getPage() <= 1}
                        >{this.props.firstText}</Button>
                        <Button className="btn"
                                onClick={() => {
                                    this.prev();
                                }}
                                disabled={this.getPage() <= 1}
                        >{this.props.previousText}</Button>
                </div>
                <div className="-center">
                    <span className="-pageInfo">
                      <span className="-currentPage">{this.props.pageText}{' '}{this.getPage()}</span>/<span className="-totalPages">{pages}</span>
                    </span>
                    <span className="select-wrap -pageSizeOptions">
                        <select
                            value={pageSize}
                            onChange={e => {
                                onPageSizeChange(Number(e.target.value))
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {`${pageSize} ${this.props.rowsText}`}
                                </option>
                            ))}
                        </select>
                        </span>
                </div>
                <div className="-next">
                    <Button className="btn"
                            onClick={() => {
                                this.next();
                            }}
                            disabled={this.getPage() >= pages}
                    >{this.props.nextText}</Button>
                    <Button className="btn"
                            onClick={() => {
                                this.last();
                            }}
                            disabled={this.getPage() >= pages}
                    >{this.props.lastText}</Button>
                </div>
            </div>
        );
    }
}

Pagination.propTypes = {};

export default injectIntl(Pagination);
