import React from 'react';
import {required, validInput} from "./validationCommons";
import {Field, Fields} from "redux-form";
import {RequiredSpan} from "./StyledComponents";
import styled from "styled-components";
import {CheckboxGroupField} from "./CheckboxGroupField";
import {FormattedMessage} from 'react-intl';

const InvisibleField = styled(Field)`
  display: none;
`;

export const InputGroupCheckbox = (props) => {
    const {
        fields, isDisabled, isRequired, validations, label, maxLength, placeholder
    } = props;
    let newValidations = validations;

    if (!newValidations) {
        newValidations = [validInput];
    } else {
        newValidations.push(validInput);
    }
    if (isRequired) {
        newValidations.push(required);
    }

    return (
        <div>
            {!!label && label instanceof Object &&
            <label className="col-form-label pl-0 font-weight-bold">
                <FormattedMessage {...label}/>
                {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
            </label>}
            <InvisibleField name={fields[1]} component="input" type="text" validate={newValidations}/>
            <Fields names={fields}
                    component={CheckboxGroupField}
                    isDisabled={isDisabled}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    isSmall
            />
        </div>
    )
};
