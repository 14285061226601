import {
    ADMIN_MODEL_ANSWERS_SET_COUNTRY_GROUPS,
    ADMIN_MODEL_ANSWERS_FETCH_PENDING,
    ADMIN_MODEL_ANSWERS_SAVE_PENDING,
    ADMIN_MODEL_ANSWERS_DELETE_PENDING,
    ADMIN_MODEL_ANSWERS_RESET,
    ADMIN_MODEL_ANSWERS_SOLUTION_GROUP_FETCH_PENDING
} from '../../actions/admin/actionAdminModelAnswers';
import get from 'get-value';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const adminModelAnswers = (state = {
    isLoading: false,
    isLoadingSolutionGroup: false,
    shouldRefetch: false,
    modelAnswers: [],
    fullSolutionGroupInfo: {},
    countryGroups: [],
    pages: 0,
    isNextFromPivot: true,
    isNextPage: false,
}, action) => {
    switch (action.type) {
        case ADMIN_MODEL_ANSWERS_RESET: {
            return Object.assign({}, state, {
                isLoading: false,
                isLoadingSolutionGroup: false,
                shouldRefetch: false,
                modelAnswers: [],
                fullSolutionGroupInfo: {},
                countryGroups: [],
                pages: 0,
                isNextFromPivot: true,
                isNextPage: false,
            });
        }
        case ADMIN_MODEL_ANSWERS_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true, modelAnswers: []});
        }
        case ADMIN_MODEL_ANSWERS_SOLUTION_GROUP_FETCH_PENDING: {
            return Object.assign({}, state, {
                isLoadingSolutionGroup: true,
                fullSolutionGroupInfo: {},
                countryGroups: []
            });
        }
        case ADMIN_MODEL_ANSWERS_SET_COUNTRY_GROUPS: {
            return Object.assign({}, state, {countryGroups: action.payload});
        }
        case ADMIN_MODEL_ANSWERS_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true, modelAnswers: []});
        }
        case ADMIN_MODEL_ANSWERS_DELETE_PENDING: {
            return Object.assign({}, state, {isLoading: true, modelAnswers: []});
        }
        case techDocWebsocketCommands.TECH_DOC_MODEL_ANSWERS_SEND:
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MODEL_ANSWERS_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                shouldRefetch: false,
                modelAnswers: get(action, 'payload.result.data', {default: []}),
                pages: get(action, 'payload.result.pages', {default: 0}),
                isNextFromPivot: get(action, 'payload.result.isNextFromPivot', false),
                isNextPage: get(action, 'payload.result.isNextPage', false),
                correlationId
            });
        }
        case techDocWebsocketCommands.TECH_DOC_FULL_SOLUTION_GROUP_INFO_SEND:
        case sparePartsWebsocketCommands.SPARE_PARTS_FULL_SOLUTION_GROUP_INFO_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoadingSolutionGroup: false,
                fullSolutionGroupInfo: {
                    id: get(action, 'payload.solutionGroupInfo.SK', {default: ''}),
                    ...get(action, 'payload.solutionGroupInfo', {default: {}})
                },
                countryGroups: get(action, 'payload.solutionGroupInfo.groups', {default: []}),
                correlationId
            });
        }
        case techDocWebsocketCommands.TECH_DOC_FULL_SOLUTION_GROUP_INFO_SEND_ERROR:
        case sparePartsWebsocketCommands.SPARE_PARTS_FULL_SOLUTION_GROUP_INFO_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoadingSolutionGroup: false, fullSolutionGroupInfo: {}, countryGroups:[], correlationId});
        }
        case techDocWebsocketCommands.TECH_DOC_MODEL_ANSWERS_SEND_ERROR:
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MODEL_ANSWERS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, shouldRefetch: false, modelAnswers: [], correlationId});
        }
        case techDocWebsocketCommands.TECH_DOC_MODEL_ANSWERS_SAVE_ERROR:
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MODEL_ANSWERS_SAVE_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, shouldRefetch: false, correlationId});
        }
        case techDocWebsocketCommands.TECH_DOC_MODEL_ANSWERS_SAVE:
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MODEL_ANSWERS_SAVE: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {shouldRefetch: true, correlationId});
        }
        default:
            return state;
    }
};
