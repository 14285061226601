import React from 'react';
import {reduxForm} from 'redux-form'
import {BoldPrimaryButton, SecondaryButton} from '../../common/Button';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {positiveNumber} from "../../common/validationCommons";
import {onlyDigits} from "../../common/normalizationCommons";
import ticketSettingsMessages from "../../../intl/admin/ticketSettingsMessages";
import buttonMessages from "../../../intl/common/buttonMessages";
import {InputFieldWithValidation} from "../../common/InputFieldWithValidation";

let TicketSettingsForm = (props) => {
    const {handleSubmit, pristine, reset, submitting} = props;
    return (
        <form className="form-group col-xl-4 col-lg-5 col-md-12 pl-0 pr-0" onSubmit={handleSubmit}>
            <InputFieldWithValidation label={ticketSettingsMessages.TICKET_BLOCKING_DURATION}
                                      field="blockingTime"
                                      validations={[positiveNumber]}
                                      normalize={onlyDigits}
                                      maxLength={5}
                                      isRequired/>
            <InputFieldWithValidation label={ticketSettingsMessages.DAYS_TO_HISTORY}
                                      field="daysToHistory"
                                      validations={[positiveNumber]}
                                      normalize={onlyDigits}
                                      maxLength={5}
                                      isRequired/>
            <InputFieldWithValidation label={ticketSettingsMessages.DAYS_TO_CLOSE}
                                      field="daysToClose"
                                      validations={[positiveNumber]}
                                      normalize={onlyDigits}
                                      maxLength={5}
                                      isRequired/>
            <div className="form-row">
                <div className="col-sm mt-2">
                    <BoldPrimaryButton type="submit" className="btn float-right"
                                       disabled={pristine || submitting}>
                        <FormattedMessage {...buttonMessages.SAVE}/>
                    </BoldPrimaryButton>
                    <SecondaryButton type="button" className="btn float-right mr-2"
                                     onClick={reset || submitting}
                                     disabled={pristine || submitting}>
                        <FormattedMessage {...buttonMessages.CANCEL}/>
                    </SecondaryButton>
                </div>
            </div>
        </form>
    );
};

TicketSettingsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired
};

export default reduxForm({})(TicketSettingsForm);
