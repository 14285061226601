import {cloneDeep} from 'lodash';
import {
    GROUP_ADD,
    GROUP_CREATE_ERROR,
    GROUP_CREATE_PENDING,
    GROUP_CREATE_SUCCESS,
    GROUP_DELETE_ERROR,
    GROUP_DELETE_PENDING,
    GROUP_DELETE_SUCCESS,
    GROUP_EDIT_NAME,
    GROUP_EDIT_USERS,
    GROUP_LIST_FETCH_ERROR,
    GROUP_LIST_FETCH_PENDING,
    GROUP_LIST_FETCH_SUCCESS,
    GROUP_REMOVE,
    GROUP_RESET,
    GROUP_SAVE_ERROR,
    GROUP_SAVE_PENDING,
    GROUP_SAVE_STATE,
    GROUP_SAVE_SUCCESS,
    GROUP_STORING_FINISHED,
    GROUP_STORING_PENDING
} from '../actions/actionSolutionGroup';

export const solutionGroupReducer = (state = {
    isLoading: false,
    isStoringFinished: true,
    listData: {},
    newSolutionGroupIds: new Set(),
    editedSolutionGroups: new Map(),
    deletedSolutionGroupIds: new Set()
}, action) => {
    switch (action.type) {
        case GROUP_LIST_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case GROUP_LIST_FETCH_SUCCESS: {
            return Object.assign({}, state, {isLoading: false, listData: action.payload});
        }
        case GROUP_LIST_FETCH_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case GROUP_CREATE_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case GROUP_CREATE_SUCCESS: {
            return Object.assign({}, state, {isLoading: false});
        }
        case GROUP_CREATE_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case GROUP_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case GROUP_SAVE_SUCCESS: {
            return Object.assign({}, state, {isLoading: false});
        }
        case GROUP_SAVE_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case GROUP_STORING_PENDING: {
            return Object.assign({}, state, {isStoringFinished: false});
        }
        case GROUP_STORING_FINISHED: {
            return Object.assign({}, state, {isStoringFinished: true});
        }
        case GROUP_DELETE_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        case GROUP_DELETE_SUCCESS: {
            return Object.assign({}, state, {isLoading: false});
        }
        case GROUP_DELETE_ERROR: {
            return Object.assign({}, state, {isLoading: false});
        }
        case GROUP_REMOVE: {
            const deletedSolutionGroupIds = cloneDeep(state.deletedSolutionGroupIds);
            deletedSolutionGroupIds.add(action.payload);
            const listData = cloneDeep(state.listData);
            delete listData[action.payload];
            return Object.assign({}, state, {listData, deletedSolutionGroupIds});
        }
        case GROUP_ADD: {
            const newSolutionGroupIds = cloneDeep(state.newSolutionGroupIds);
            newSolutionGroupIds.add(action.payload.id);
            const listData = cloneDeep(state.listData);
            listData[action.payload.id] = action.payload;
            return Object.assign({}, state, {listData, newSolutionGroupIds});
        }
        case GROUP_RESET: {
            return Object.assign({}, state, {
                newSolutionGroupIds: new Set(),
                editedSolutionGroups: new Map(),
                deletedSolutionGroupIds: new Set()
            });
        }
        case GROUP_EDIT_NAME: {
            const {id, name} = action.payload;
            const editedSolutionGroups = cloneDeep(state.editedSolutionGroups);
            const solutionGroup = editedSolutionGroups.get(id);
            editedSolutionGroups.set(
                id,
                solutionGroup ? Object.assign({}, solutionGroup, {name}) : {id, name}
            );
            return Object.assign({}, state, {editedSolutionGroups});
        }
        case GROUP_EDIT_USERS: {
            const {id, users, addedUser, removedUser, multipleRemovedUsers} = action.payload;
            const editedSolutionGroups = cloneDeep(state.editedSolutionGroups);
            const solutionGroup = editedSolutionGroups.get(id);
            const addedUsers = (solutionGroup && solutionGroup.addedUsers) ? solutionGroup.addedUsers : new Map();
            if (addedUser) {
                addedUsers.set(addedUser.ipn, addedUser);
            }
            const removedUsers = (solutionGroup && solutionGroup.removedUsers) ? solutionGroup.removedUsers : new Map();
            if (removedUser) {
                removedUsers.set(removedUser.ipn, removedUser);
            }
            if (multipleRemovedUsers) {
                multipleRemovedUsers.forEach(user => removedUsers.set(user.ipn, user));
            }
            editedSolutionGroups.set(
                id,
                solutionGroup ?
                    Object.assign({}, solutionGroup, {users, addedUsers, removedUsers}) :
                    {id, users, addedUsers, removedUsers}
            );
            return Object.assign({}, state, {editedSolutionGroups});
        }
        case GROUP_SAVE_STATE: {
            return Object.assign({}, state, {listData: action.payload});
        }
        default: {
            return state;
        }
    }
};
