import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'techDoc.history.title',
        defaultMessage: 'History documentations'
    },
    INTERNAL_TICKET_TITLE: {
        id: 'techDoc.history.titleInternalTicket',
        defaultMessage: 'History documentations - Internal Ticket'
    },
    VISIBLE_COLUMNS: {
        id: 'techDoc.history.label.visibleColumns',
        defaultMessage: 'Visible columns'
    },
    TABLE_TICKET_NUMBER: {
        id: 'techDoc.history.table.ticketNo',
        defaultMessage: 'Ticket No.'
    },
    TABLE_SOLUTION_GROUP: {
        id: 'techDoc.history.table.solutionGroup',
        defaultMessage: 'Solution Gr.'
    },
    TABLE_ORDER_CREATION_TIME: {
        id: 'techDoc.history.table.creationDate',
        defaultMessage: 'Creation Date'
    },
    TABLE_CLIENT_ID: {
        id: 'techDoc.history.table.clientId',
        defaultMessage: 'Client ID'
    },
    TABLE_DEALER_NUMBER: {
        id: 'techDoc.history.table.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    TABLE_DEALER_NAME: {
        id: 'techDoc.history.table.dealerName',
        defaultMessage: 'Dealer Name'
    },
    TABLE_PR: {
        id: 'techDoc.history.table.pr',
        defaultMessage: 'PR'
    },
    TABLE_PART_DESCRIPTION: {
        id: 'techDoc.history.table.partDescription',
        defaultMessage: 'Part Description'
    },
    TABLE_MANAGEMENT_GROUP: {
        id: 'techDoc.history.table.managementGroup',
        defaultMessage: 'Management Group'
    },
    TABLE_INTERNAL_STATUS: {
        id: 'techDoc.history.table.internalStatus',
        defaultMessage: 'Internal status'
    },
    TABLE_FORM_TYPE: {
        id: 'techDoc.history.table.formType',
        defaultMessage: 'Type of Form'
    },
    TABLE_STATUS: {
        id: 'techDoc.history.table.status',
        defaultMessage: 'Status'
    },
    TABLE_LAST_EDITOR: {
        id: 'techDoc.history.table.lastEditor',
        defaultMessage: 'Last Editor'
    },
    TABLE_CREATED_BY: {
        id: 'techDoc.history.table.createdBy',
        defaultMessage: 'Created by (IPN)'
    },
    TABLE_PAGE1: {
        id: 'techDoc.history.table.page1',
        defaultMessage: 'Page 1'
    },
    TABLE_PAGE2: {
        id: 'techDoc.history.table.page2',
        defaultMessage: 'Page 2'
    },
    TABLE_PROBLEM_TYPE: {
        id: 'techDoc.history.table.problemType',
        defaultMessage: 'Type of Problem'
    },
    TABLE_VOR_RELATED: {
        id: 'techDoc.history.table.vorRelated',
        defaultMessage: 'VOR/PVI related'
    },
    TABLE_REG_DIR: {
        id: 'techDoc.history.table.regDir',
        defaultMessage: 'Reg. Dir.'
    },
    TABLE_COUNTRY_CODE_3: {
        id: 'techDoc.history.table.countryCode3',
        defaultMessage: 'Country Code'
    },
    TABLE_IMMOBILIZED_VEHICLE: {
        id: 'techDoc.history.table.immobilizedVehicle',
        defaultMessage: 'Immobilized Vehicle'
    },
    TABLE_TYPE_OF_CLIENT: {
        id: 'techDoc.history.table.typeOfClient',
        defaultMessage: 'Type of Client'
    },
    TABLE_SRC_TYPE: {
        id: 'techDoc.history.table.srcType',
        defaultMessage: 'SRC Type'
    },
    TABLE_SRC_N: {
        id: 'techDoc.history.table.srcN',
        defaultMessage: 'SRC N°'
    },


    TABLE_INTERNAL_TICKET_NUMBER: {
        id: 'techDoc.internalHistory.table.ticketNo',
        defaultMessage: 'Internal Ticket No.'
    },
    TABLE_REQUEST_TYPE: {
        id: 'techDoc.internalHistory.table.requestType',
        defaultMessage: 'Request Type'
    },
    TABLE_REQUEST_STATUS: {
        id: 'techDoc.internalHistory.table.requestStatus',
        defaultMessage: 'Request Status'
    },
    TABLE_PRIORITY: {
        id: 'techDoc.internalHistory.table.priority',
        defaultMessage: 'Priority'
    },
    TABLE_PRIORITY_TYPE: {
        id: 'techDoc.internalHistory.table.priorityType',
        defaultMessage: 'Priority Type'
    },
    TABLE_DEALER_TICKET_NUMBER: {
        id: 'techDoc.internalHistory.table.dealerTicketNo',
        defaultMessage: 'Dealer Ticket No.'
    },
    TABLE_DEALER_FORM_TYPE: {
        id: 'techDoc.internalHistory.table.dealerTicketFormType',
        defaultMessage: 'Dealer Form Type'
    },
    TABLE_IT_CREATION_TIME: {
        id: 'techDoc.internalHistory.table.itCreationDate',
        defaultMessage: 'IT Creation Date'
    },
    TABLE_IT_CREATED_BY: {
        id: 'techDoc.internalHistory.table.itCreatedBy',
        defaultMessage: 'IT Created by (IPN)'
    },
    TABLE_SG_REQUESTOR: {
        id: 'techDoc.internalHistory.table.sgRequestor',
        defaultMessage: 'SG Requestor'
    },
    TABLE_ASSIGNED_SOLUTION_GROUP: {
        id: 'techDoc.internalHistory.table.assignedSolutionGroup',
        defaultMessage: 'Assigned SG'
    },
    TABLE_DEALER_CLIENT_ID: {
        id: 'techDoc.internalHistory.table.clientId',
        defaultMessage: 'Client ID'
    },
    TABLE_DEALER_REGION: {
        id: 'techDoc.internalHistory.table.region',
        defaultMessage: 'Region'
    },
    TABLE_DEALER_COUNTRY_CODE_3: {
        id: 'techDoc.internalHistory.table.countryCode3',
        defaultMessage: 'Country Code'
    },
    TABLE_IT_DEALER_NUMBER: {
        id: 'techDoc.internalHistory.table.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    TABLE_PART_NUMBER: {
        id: 'techDoc.internalHistory.table.partNumber',
        defaultMessage: 'Part No.'
    },
    TABLE_MANAGEMENT_GRP: {
        id: 'techDoc.internalHistory.table.managementGrp',
        defaultMessage: 'Management Group'
    },
    TABLE_PARTNER: {
        id: 'techDoc.internalHistory.table.partner',
        defaultMessage: 'Partner'
    },
    TABLE_PARTNER_NAME: {
        id: 'techDoc.internalHistory.table.partnerName',
        defaultMessage: 'Partner Name'
    },
    TABLE_IT_LAST_STATUS_DATE: {
        id: 'techDoc.internalHistory.table.lastStatusDate',
        defaultMessage: 'Last Status Date'
    },
    TABLE_IT_LAST_EDITOR: {
        id: 'techDoc.internalHistory.table.lastEditor',
        defaultMessage: 'Last Editor'
    },
});

export default messages;
