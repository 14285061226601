import React, {Component} from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import get from 'get-value';
import PropTypes from 'prop-types';
import 'react-picky/dist/picky.css';
import {withRouter} from 'react-router-dom';
import {clearAlerts} from '../../../actions/alertsActions';
import ListDocInternalTicketTable from './ListTechDocInternalTicketTable';
import {
    domains,
    getCurrentFormTypes,
    getInternalTicketFormStates,
    internalTicketRequestType,
    priorityType,
    requestForPartnerList
} from '../../../constants/Utils';
import commonMessages from '../../../intl/common/commonMessages';
import listMessages from '../../../intl/technicalDocumentations/listMessages';
import internalTicketRequestTypeMessages from '../../../intl/technicalDocumentations/internalTicketRequestTypeMessages';
import Loader from '../../Loader';
import PageHeader from '../../common/PageHeader';
import {StyledPicky} from '../../common/StyledComponents';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {updateTechDocUserInternalTicketListPreference} from '../../../actions/techDoc/actionTechDocPreferences';
import {
    lockTechDocInternalTicket,
    resetRedirectAfterTechDocInternalLockTicket
} from '../../../actions/techDoc/actionTechDocInternalLockTicket';
import {fillNumberFiveZero, formatTicketNumber} from '../../../utils/utils';
import DatePickerTableComponent from '../../common/datePicker/DatePickerTableComponent';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import formStatesMessages from '../../../intl/common/formStatesMessages';
import moment from 'moment';
import {Link} from 'react-router-dom';
import requestForPartnerListMessages from '../../../intl/technicalDocumentations/requestForPartnerListMessages';
import priorityTypeMessages from '../../../intl/technicalDocumentations/priorityTypeMessages';

const domain = domains.TECHNICAL_DOCUMENTATION;

class ListTechDocInternalTicketPage extends Component {
    constructor(props) {
        super(props);
        const {intl, loggedUser, loggedUser: {roles}} = props;

        this.state = {
            ratchet: false,
            columnLibrary: [
                {
                    id: 'REQUEST_TYPE',
                    accessor: 'typeOfRequest',
                    message: listMessages.TABLE_REQUEST_TYPE,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {internalTicketRequestType.map((key, index) =>
                                <option key={index} value={key}>
                                    {internalTicketRequestTypeMessages[key] ? intl.formatMessage(internalTicketRequestTypeMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{internalTicketRequestTypeMessages[e.value] ? intl.formatMessage(internalTicketRequestTypeMessages[e.value]) : e.value}</div>
                },
                {
                    id: 'STATUS',
                    accessor: 'status',
                    message: listMessages.TABLE_REQUEST_STATUS,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {getInternalTicketFormStates(domain).map((key, index) =>
                                <option key={index} value={key}>
                                    {formStatesMessages[key] ? intl.formatMessage(formStatesMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{formStatesMessages[e.value] ? intl.formatMessage(formStatesMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'PRIORITY',
                    accessor: 'priorityTreatment',
                    message: listMessages.TABLE_PRIORITY,
                    Filter: ({filter, onChange}) => (
                        <select onChange={event => onChange(event.target.value)}
                                style={{width: '100%', border: '1px solid #999999'}}
                                value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            <option value="YES">{intl.formatMessage(commonMessages.YES)}</option>
                            <option value="NO">{intl.formatMessage(commonMessages.NO)}</option>
                        </select>
                    ),
                    Cell: e => <div>{intl.formatMessage(commonMessages[e.value === 'YES' ? e.value : 'NO'])}</div>
                },
                {
                    id: 'PRIORITY_TYPE',
                    accessor: 'priorityType',
                    message: listMessages.TABLE_PRIORITY_TYPE,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {priorityType.map((key, index) =>
                                <option key={index} value={key}>
                                    {priorityTypeMessages[key] ? intl.formatMessage(priorityTypeMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{priorityTypeMessages[e.value] ? intl.formatMessage(priorityTypeMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'DEALER_TICKET_NUMBER',
                    accessor: 'ticketReference',
                    message: listMessages.TABLE_DEALER_TICKET_NUMBER,
                    Cell: row => <Link to={{
                        pathname: `/documentation/view/${get(row, 'value.uuid')}`,
                        redirectedFromInternal: true
                    }}>{get(row, 'value.pfxNumber') ? formatTicketNumber(get(row, 'value.pfxNumber'), get(row, 'value.sfxNumber')) : ''}</Link>
                },
                {
                    id: 'DEALER_FORM_TYPE',
                    accessor: 'ticketReference.docType',
                    message: listMessages.TABLE_DEALER_FORM_TYPE,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {getCurrentFormTypes(domain).map((key, index) =>
                                <option key={index} value={key}>
                                    {formTypeMessages[key] ? intl.formatMessage(formTypeMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{formTypeMessages[e.value] ? intl.formatMessage(formTypeMessages[e.value]) : e.value}</div>
                },
                {
                    id: 'IT_CREATION_TIME',
                    accessor: 'created',
                    message: listMessages.TABLE_IT_CREATION_TIME,
                    Filter: ({filter, onChange}) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'CREATED_BY',
                    accessor: 'creatorIpn',
                    message: listMessages.TABLE_IT_CREATED_BY,
                },
                {
                    id: 'SG_REQUESTOR',
                    accessor: 'solutionGroup',
                    message: listMessages.TABLE_SG_REQUESTOR,
                    Cell: e =>
                        <div>{get(e, 'value.name', '')}</div>
                },
                {
                    id: 'ASSIGNED_SG',
                    accessor: 'assignedSolutionGroup',
                    message: listMessages.TABLE_ASSIGNED_SOLUTION_GROUP,
                    Cell: e =>
                        <div>{get(e, 'value.name', '')}</div>
                },
                {
                    id: 'CLIENT_ID',
                    accessor: 'ticketReference.clientId',
                    message: listMessages.TABLE_DEALER_CLIENT_ID,
                },
                {
                    id: 'REG_DIR',
                    accessor: 'ticketReference.region',
                    message: listMessages.TABLE_DEALER_REGION,
                },
                {
                    id: 'COUNTRY_CODE_3',
                    accessor: 'ticketReference.countryCode3',
                    message: listMessages.TABLE_DEALER_COUNTRY_CODE_3,
                },
                {
                    id: 'DEALER_NUMBER',
                    accessor: 'ticketReference.dealerNumber',
                    message: listMessages.TABLE_IT_DEALER_NUMBER,
                    Cell: e => fillNumberFiveZero(e.value)
                },
                {
                    id: 'PART_NUMBER',
                    accessor: 'nReferenceCausingProblem',
                    message: listMessages.TABLE_PART_NUMBER,
                },
                {
                    id: 'MANAGEMENT_GROUP',
                    accessor: 'managementGroup',
                    message: listMessages.TABLE_MANAGEMENT_GRP,
                },
                {
                    id: 'PARTNER',
                    accessor: 'requestForPartner',
                    message: listMessages.TABLE_PARTNER,
                    Filter: ({filter, onChange}) => (
                        <select onChange={event => onChange(event.target.value)}
                                style={{width: '100%', border: '1px solid #999999'}}
                                value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            <option value="YES">{intl.formatMessage(commonMessages.YES)}</option>
                            <option value="NO">{intl.formatMessage(commonMessages.NO)}</option>
                        </select>
                    ),
                    Cell: e => <div>{intl.formatMessage(commonMessages[e.value === 'YES' ? e.value : 'NO'])}</div>
                },
                {
                    id: 'PARTNER_NAME',
                    accessor: 'partnerName',
                    message: listMessages.TABLE_PARTNER_NAME,
                    Filter: ({filter, onChange}) => (
                        <select
                            onChange={event => onChange(event.target.value)}
                            style={{width: '100%', border: '1px solid #999999'}}
                            value={filter ? filter.value : ''}>
                            <option value="">{intl.formatMessage(commonMessages.ALL)}</option>
                            {requestForPartnerList.map((key, index) =>
                                <option key={index} value={key}>
                                    {requestForPartnerListMessages[key] ? intl.formatMessage(requestForPartnerListMessages[key]) : key}
                                </option>)
                            }
                        </select>
                    ),
                    Cell: e =>
                        <div>{requestForPartnerListMessages[e.value] ? intl.formatMessage(requestForPartnerListMessages[e.value]) : e.value}</div>,
                },
                {
                    id: 'LAST_UPDATE',
                    accessor: 'lastUpdate',
                    Filter: ({filter, onChange}) => {
                        let value = filter && filter.value;
                        if (typeof value === 'string') {
                            value = moment(value).toDate();
                        }
                        return <DatePickerTableComponent
                            value={value || undefined}
                            onChange={onChange}
                            filter={filter}/>;
                    },
                    message: listMessages.TABLE_IT_LAST_STATUS_DATE,
                    Cell: e => <FormattedDate value={new Date(e.value)}/>,
                },
                {
                    id: 'LAST_EDITOR',
                    accessor: 'lastEditor',
                    message: listMessages.TABLE_IT_LAST_EDITOR,
                }
            ]
        };
    }

    componentWillUnmount() {
        this.props.resetRedirectAfterTechDocInternalLockTicket();
    }

    handleEditClick = ({id}) => {
        //mozna nacitat info do listu a delat lokalni kontrolu jestli je ticket zamceny
        if (!this.props.lockTicket.isTicketLocking) {
            this.props.lockTechDocInternalTicket(id);
            this.setState({ratchet: true});
        }
    };

    selectMultipleOption = (value) => {
        this.props.updateTechDocUserInternalTicketListPreference(this.props.loggedUser.ipn, this.state.columnLibrary.filter(item => !value.some(value => value.id === item.id)).map(column => column.id))
    };

    render() {
        const {intl, techDocPreferences, lockTicket, location} = this.props;
        const {columnLibrary} = this.state;
        if (lockTicket.redirect && this.state.ratchet) {
            this.props.history.push(`/documentation/internalTicket/edit/${lockTicket.uuid}`);
        }
        if (techDocPreferences.isLoading) {
            return <Loader/>;
        }
        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage {...listMessages.INTERNAL_TICKET_TITLE}/>}/>
                <div className="d-flex justify-content-end mb-3">
                    <div className="col-xl-4 col-lg-7 col-md-9 col-sm-12">
                        <span
                            className="font-weight-bold"><FormattedMessage {...listMessages.VISIBLE_COLUMNS}/>{':'}</span>
                        <StyledPicky
                            value={columnLibrary.filter(item => !get(techDocPreferences, 'menuInternalList', {default: []}).includes(item.id)).map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            options={columnLibrary.map(col => {
                                return {id: col.id, message: intl.formatMessage(col.message)}
                            })}
                            onChange={this.selectMultipleOption}
                            open={false}
                            valueKey="id"
                            labelKey="message"
                            multiple={true}
                            includeSelectAll={true}
                            selectAllText={<FormattedMessage {...commonMessages.SELECT_ALL}/>}
                            manySelectedPlaceholder={'%s ' + intl.formatMessage(commonMessages.SELECTED)}
                            allSelectedPlaceholder={'%s ' + intl.formatMessage(commonMessages.SELECTED)}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                </div>
                <ListDocInternalTicketTable
                    columns={columnLibrary.filter(item => !get(techDocPreferences, 'menuInternalList', {default: []}).includes(item.id))}
                    handleEditClick={this.handleEditClick}
                    location={location}
                />
            </div>
        );
    }
}

ListTechDocInternalTicketPage.propTypes = {
    lockTicket: PropTypes.object.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    updateTechDocUserInternalTicketListPreference: PropTypes.func.isRequired,
    lockTechDocInternalTicket: PropTypes.func.isRequired,
    resetRedirectAfterTechDocInternalLockTicket: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
    intl: PropTypes.any.isRequired,
    techDocPreferences: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    loggedUser: state.profile.userDetail,
    techDocPreferences: state.techDocPreferences,
    lockTicket: state.techDocInternalTicketListOrderLockTicketCheck
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    updateTechDocUserInternalTicketListPreference,
    lockTechDocInternalTicket,
    resetRedirectAfterTechDocInternalLockTicket,
})(injectIntl(ListTechDocInternalTicketPage))), ['TD_SG_EDITOR']);
