import React, { useState, useEffect } from 'react';

const Countdown = ({ initiateCountdown }) => {
    const [count, setCount] = useState(initiateCountdown);

    useEffect(() => {
        if (count > 0) {
            const timer = setInterval(() => {
                setCount(prevCount => prevCount - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [count]);

    return (
        <div>
            <h1>{count}</h1>
        </div>
    );
};

export default Countdown;
