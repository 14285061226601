import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {connect} from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    addSecondHandBatteryType,
    fetchSecondHandBatteryTypes,
    moveSecondHandBatteryType
} from '../../../actions/admin/actionAdminSecondHandBattery';
import {PrimaryButton} from '../../common/Button';
import {Table, Wrapper} from '../../common/StyledComponents';
import {moduleRoles} from '../../../utils/roles';
import SecondHandBatteryDialog from './SecondHandBatteryDialog';
import SecondHandBatteryRow from './SecondHandBatteryRow';
import Loader from '../../Loader';
import buttonMessages from '../../../intl/common/buttonMessages';
import presetResponseMessages from '../../../intl/admin/presetResponseMessages';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import adminMessages from '../../../intl/admin/adminMessages';
import secondHandBatteryMessages from '../../../intl/admin/secondHandBatteryMessages';

const Button = styled(PrimaryButton)`
  margin-right: 5px;
`;

class SecondHandBatteryContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {isOpenAddSecondHandBatteryTypeDialog: false};
    }

    componentDidMount() {
        this.props.fetchSecondHandBatteryTypes(this.props.domain, this.props.selectedGroup);
    }

    showAddModal = () =>
        this.setState({
            isOpenAddSecondHandBatteryTypeDialog: true,
        });

    closeModal = () =>
        this.setState({
            isOpenAddSecondHandBatteryTypeDialog: false
        });

    saveModal = ({presetName = ''}, responseId) => {
        this.setState({
            isOpenAddSecondHandBatteryTypeDialog: false
        });
        const item = {
            presetName: presetName,
            uuid: responseId,
            group: this.props.selectedGroup,
        };
        this.props.addSecondHandBatteryType(this.props.domain, item);
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }
        // no movement
        if (result.destination.index === result.source.index) {
            return;
        }
        //swap
        const item = {
            group: this.props.selectedGroup,
            position: result.destination.index,
            uuid: this.props.secondHandBatteryTypes[result.source.index].uuid,
        };
        this.props.moveSecondHandBatteryType(this.props.domain, item, result.source.index);
    };

    render() {
        const {isLoading, secondHandBatteryTypes, domain, intl: {formatMessage}, selectedGroup, roles} = this.props;
        return (
            <Wrapper>
                <h5><FormattedMessage {...secondHandBatteryMessages.SECOND_HAND_BATTERY_TYPE}/></h5>
                {isLoading && <Loader/>}
                {!isLoading && <>
                    <Button
                        disabled={secondHandBatteryTypes.length > 9}
                        className="btn btn-sm mb-sm-3"
                        onClick={this.showAddModal}>
                        <FormattedMessage {...buttonMessages.ADD}/>
                    </Button>
                    {secondHandBatteryTypes.length === 0 &&
                    <div>
                        <FormattedMessage {...secondHandBatteryMessages.EMPTY}/>
                    </div>
                    }
                    {secondHandBatteryTypes.length !== 0 &&
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className="card rounded">
                            <Table className="table table-hover">
                                <thead className="thead-light">
                                <tr className="d-flex">
                                    <th className="col-1"/>
                                    <th className="col-9">
                                        <FormattedMessage {...secondHandBatteryMessages.SECOND_HAND_BATTERY_TYPE_NAME}/>
                                    </th>
                                    <th className="col-2">
                                        <FormattedMessage {...adminMessages.ACTION}/>
                                    </th>
                                </tr>
                                </thead>
                                <Droppable droppableId="table">
                                    {provided => {
                                        return (
                                            <tbody ref={provided.innerRef}
                                                   {...provided.droppableProps}>
                                            {secondHandBatteryTypes.map((secondHandBatteryType, index) =>
                                                <Draggable key={index} draggableId={`id-${index}`} index={index}>
                                                    {provided => {
                                                        return (
                                                            <SecondHandBatteryRow provided={provided}
                                                                               index={index}
                                                                               data={secondHandBatteryType}
                                                                               domain={this.props.domain}/>
                                                        )
                                                    }}
                                                </Draggable>
                                            )}
                                            </tbody>
                                        )
                                    }}
                                </Droppable>
                            </Table>
                        </div>
                    </DragDropContext>
                    }
                    <SecondHandBatteryDialog domain={domain}
                                          show={this.state.isOpenAddSecondHandBatteryTypeDialog}
                                          closeModal={this.closeModal}
                                          saveModal={this.saveModal}
                                          group={formatMessage(countryGroupMessages[selectedGroup])}
                                          initialValues={{
                                              groups: [moduleRoles.getAdminGroups(domain, roles)
                                                  .map(group => ({
                                                      value: group.group,
                                                      label: formatMessage(countryGroupMessages[group.group]),
                                                      isFixed: true
                                                  })).find(group => group.value === selectedGroup)]
                                          }}/>
                </>}
            </Wrapper>
        );
    }
}

SecondHandBatteryContainer.propTypes = {
    selectedGroup: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    isLoading: state.adminSecondHandBatteryType.isLoading,
    secondHandBatteryTypes: state.adminSecondHandBatteryType.secondHandBatteryTypes,
    roles: state.profile.userDetail.roles,
    profile: state.profile.userDetail,
    intl: state.intl
});
export default connect(mapStateToProps, {
    addSecondHandBatteryType,
    fetchSecondHandBatteryTypes,
    moveSecondHandBatteryType
})(injectIntl(SecondHandBatteryContainer));
