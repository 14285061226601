import React, {Component} from 'react';
import {getHelpLinks} from './HelpSetting';
import PageHeader from '../../common/PageHeader';
import HelpLang from './HelpLang';
import {FILE, VIDEO, YOUTUBE_VIDEO} from './HelpConst';
import HelpModal from './HelpModal';
import {connect} from 'react-redux';
import checkRoles from '../../common/roleChecker/RoleChecker';
import play from '../../../images/play.svg';
import file from '../../../images/file.svg';
import {Flex, Image, Margin} from '../../common/StyledComponents';
import {domains} from '../../../constants/Utils';
import FileIconWithName from '../common/FileIconWithName';
import { downloadFile } from "../../../actions/actionFile";

class HelpPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: this.props.language === 'fr' ? 'fr' : 'en',
            modalIsOpened: false,
            selectedVideo: null,
        }
    }

    changeLanguage = (newLang) => {
        if (newLang !== this.state.lang) {
            this.setState({lang: newLang});
        }
    }

    openLink = (e, link) => {
        if (link.type === VIDEO || link.type === YOUTUBE_VIDEO) {
            e.preventDefault();
            this.switchModal();
            this.selectVideo(link);
        }
    }

    selectVideo = (link) => this.setState({selectedVideo: link})

    switchModal = () => this.setState((prevState) => ({modalIsOpened: !prevState.modalIsOpened}))

    render() {
        const tdLinks = getHelpLinks(this.state.lang, domains.TECHNICAL_DOCUMENTATION);
        const spLinks = getHelpLinks(this.state.lang, domains.SPARE_PARTS);
        const titleHeader = this.state.lang === 'fr'
            ? 'Bienvenue sur l’aide en ligne de Dialog: New Hotline'
            : 'Welcome to Dialog: New Hotline on-line help';
        const SPtitle = this.state.lang === 'fr'
            ? 'Commandes'
            : 'Spare Parts Orders';
        const TDtitle = this.state.lang === 'fr'
            ? 'Documentation technique'
            : 'Technical Documentation'
        const GlossaryTitle = 'Solution groups glossary'

        return (
            <>
                <div className="container-fluid">
                    <HelpLang lang={this.state.lang} changeLanguage={this.changeLanguage}/>
                    <PageHeader title={titleHeader}/>
                    <h3>{SPtitle}</h3>
                    <ul style={{listStyleType: 'none'}}>
                        {spLinks.map((item, index) => {
                            const href = item.type === FILE ? item.href : '#';
                            const target = item.type === FILE ? '_blank' : '#';
                            const icon = item.type === FILE ? file : play;
                            return <li key={index}>
                                <Margin top={5}>
                                    <Flex>
                                        <Image width={25} height={25} src={icon} color={'gray'}/>
                                        <Margin left={5}>
                                            <a onClick={(e) => this.openLink(e, item)} href={href}
                                               target={target}>{item.label}</a>
                                        </Margin>
                                    </Flex>
                                </Margin>
                            </li>
                        })}
                    </ul>
                    <h3>{TDtitle}</h3>
                    <ul style={{listStyleType: 'none'}}>
                        {tdLinks.map((item, index) => {
                            const href = item.type === FILE ? item.href : '#';
                            const target = item.type === FILE ? '_blank' : '#';
                            const icon = item.type === FILE ? file : play;
                            return <li key={index}>
                                <Margin top={5}>
                                    <Flex>
                                        <Image width={25} height={25} src={icon} color={'gray'}/>
                                        <Margin left={5}>
                                            <a onClick={(e) => this.openLink(e, item)} href={href}
                                               target={target}>{item.label}</a>
                                        </Margin>
                                    </Flex>
                                </Margin>
                            </li>
                        })}
                    </ul>
                    <h3>{GlossaryTitle}</h3>
                    <ul style={{listStyleType: 'none'}}>
                        <li>
                            <FileIconWithName
                                file={{name: "SG-glossary-03-23.pdf", type: "application/pdf"}}
                                showIcon
                                showName
                                onClick={() => this.props.downloadFile(domains.ADMINISTRATION, {uuid: "sg-glossary-03-23.pdf", name: "sg-glossary-03-23"})}
                            />
                        </li>
                    </ul>
                </div>
                <HelpModal isOpen={this.state.modalIsOpened}
                           switchModal={this.switchModal}
                           video={this.state.selectedVideo}
                />
            </>
        );
    }
}

const mapStateToProps = state => ({
    language: state.intl.language,
});

export default checkRoles(connect(mapStateToProps, { downloadFile })(HelpPage), ['TD_POWER_USER', 'TD_SG_ADMIN', 'TD_EDITOR', 'TD_SG_EDITOR', 'SP_POWER_USER', 'SP_SG_ADMIN', 'SP_EDITOR', 'SP_SG_EDITOR', 'CL_POWER_USER', 'CL_SG_ADMIN', 'CL_EDITOR', 'CL_SG_EDITOR']);
