import React from 'react'
import {Field, reduxForm} from 'redux-form'
import {required, validDigitString} from '../../../common/validationCommons';
import {FieldWithValidation} from '../../../common/FormField';
import {CLIENT_ID_FIELD_MAX_LENGTH} from '../../../../constants/validationConstants';
import {InputCell} from '../ClientIdRecord';

const EditClientIdForm = () =>
    <>
        <InputCell>
            <Field name="clientId"
                   component={FieldWithValidation}
                   type="text"
                   validate={[required, validDigitString]}
                   maxLength={CLIENT_ID_FIELD_MAX_LENGTH}
                   isSmall/>
        </InputCell>
        <InputCell>
            <Field name="destinationCode"
                   component={FieldWithValidation}
                   type="text"
                   validate={[required, validDigitString]}
                   maxLength={CLIENT_ID_FIELD_MAX_LENGTH}
                   isSmall/>
        </InputCell>
        <InputCell>
            <Field name="region"
                   component={FieldWithValidation}
                   type="text"
                   validate={required}
                   maxLength={CLIENT_ID_FIELD_MAX_LENGTH}
                   isSmall/>
        </InputCell>
    </>;

export default reduxForm({})(EditClientIdForm)
