import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {fetchAdminClientIds} from '../../../actions/admin/actionAdminClientIds';
import {fetchSparePartsMultiSourcingGroups} from '../../../actions/orders/actionMultiSoursingGroups';
import Loader from '../../Loader';
import {
    ALLOCATION_REQUEST_FORM,
    DELIVERY_DATE_REMINDER_FORM, EV_BATTERY_FORM,
    ORDER_CANCELLATION_FORM,
    ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM,
    VOR_PVI_ORDER_FORM
} from '../../../constants/formConstants';
import DeliveryDateReminderMasterForm from './forms/DeliveryDateReminderMasterForm';
import AllocationRequestMasterForm from './forms/AllocationRequestMasterForm';
import VorPviOrderMasterForm from './forms/VorPviOrderMasterForm';
import PartNoLongerAvailableMasterForm from './forms/PartNoLongerAvailableMasterForm';
import OrderCancellationMasterForm from './forms/OrderCancellationMasterForm';
import EVBatteryMasterForm from './forms/EVBatteryMasterForm';
import {initLoadedTicket} from '../Utils';
import {moduleRoles} from '../../../utils/roles';
import {editRights} from '../../../constants/sparePartsTicketRights';
import {editReqFields} from '../../../constants/sparePartsRequiredFields';
import {closeSparePartDicModal} from '../../../actions/orders/actionSparePartsDicData';
import formMessages from '../../../intl/sparePartsOrders/formMessages';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import {formatTicketNumber} from '../../../utils/utils';
import checkRoles from '../../common/roleChecker/RoleChecker';
import OverlayLoader from '../../OverlayLoader';
import get from 'get-value';

class EditLoadTicketDataPage extends Component {
    componentDidMount() {
        const {ticketData} = this.props;
        this.props.fetchAdminClientIds(get(ticketData, 'ticket.group'), get(ticketData, 'ticket.dealerNumber'));
        this.props.fetchSparePartsMultiSourcingGroups();
    }

    render() {
        const {
            isLoadingClientIds,
            isLoadingMultiSourcingGroups,
            ticketData,
            loggedUser,
            closeSparePartDicModal,
            handleSubmit,
            dicInfo: {isModalActive},
            formType
        } = this.props;

        if (isLoadingClientIds || isLoadingMultiSourcingGroups) {
            return <Loader/>;
        }
        const isEditor = moduleRoles.isEditorOrder(loggedUser) || moduleRoles.isSGEditorOrder(loggedUser);
        const requiredFields = editReqFields[editReqFields[ticketData.ticket.group] ? ticketData.ticket.group : 'DEFAULT'][isEditor ? 'EDITOR' : 'DEALER'][formType];
        const initializationValue = initLoadedTicket(ticketData);

        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {formType === DELIVERY_DATE_REMINDER_FORM &&
                    <DeliveryDateReminderMasterForm form={formType}
                                                    initialValues={initializationValue}
                                                    userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                    enableReinitialize={true}
                                                    keepDirtyOnReinitialize={true}
                                                    reqFields={requiredFields}
                                                    handleSubmit={handleSubmit}
                                                    editMode/>
                    }
                    {formType === ORDER_FOR_PARTS_NO_LONGER_AVAILABLE_FORM &&
                    <PartNoLongerAvailableMasterForm form={formType}
                                                     initialValues={initializationValue}
                                                     userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                     enableReinitialize={true}
                                                     keepDirtyOnReinitialize={true}
                                                     reqFields={requiredFields}
                                                     handleSubmit={handleSubmit}
                                                     editMode/>
                    }
                    {formType === ORDER_CANCELLATION_FORM &&
                    <OrderCancellationMasterForm form={formType}
                                                 initialValues={initializationValue}
                                                 userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                 enableReinitialize={true}
                                                 keepDirtyOnReinitialize={true}
                                                 reqFields={requiredFields}
                                                 handleSubmit={handleSubmit}
                                                 editMode/>
                    }
                    {formType === VOR_PVI_ORDER_FORM &&
                    <VorPviOrderMasterForm form={formType}
                                           initialValues={initializationValue}
                                           userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                           enableReinitialize={true}
                                           keepDirtyOnReinitialize={true}
                                           reqFields={requiredFields}
                                           handleSubmit={handleSubmit}
                                           editMode/>
                    }
                    {formType === ALLOCATION_REQUEST_FORM &&
                    <AllocationRequestMasterForm form={formType}
                                                 initialValues={initializationValue}
                                                 userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                 enableReinitialize={true}
                                                 keepDirtyOnReinitialize={true}
                                                 reqFields={requiredFields}
                                                 handleSubmit={handleSubmit}
                                                 editMode/>
                    }
                    {formType === EV_BATTERY_FORM &&
                    <EVBatteryMasterForm form={formType}
                                         initialValues={initializationValue}
                                         userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                         enableReinitialize={true}
                                         keepDirtyOnReinitialize={true}
                                         reqFields={requiredFields}
                                         handleSubmit={handleSubmit}
                                         editMode/>
                    }
                </div>
                <OverlayLoader active={isModalActive}
                               text={formMessages.WAIT_DIC}
                               handleClose={closeSparePartDicModal}/>
            </div>
        );
    }
}

EditLoadTicketDataPage.propTypes = {
    fetchAdminClientIds: PropTypes.func.isRequired,
    fetchSparePartsMultiSourcingGroups: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isLoadingClientIds: PropTypes.bool.isRequired,
    isLoadingMultiSourcingGroups: PropTypes.bool.isRequired,
    ticketData: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    closeSparePartDicModal: PropTypes.func.isRequired,
    dicInfo: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    isLoadingMultiSourcingGroups: state.sparePartsOrderMultiSourcingGroups.isLoadingMultiSourcingGroups,
    isLoadingClientIds: state.adminClientIds.isLoading,
    ticketData: state.sparePartsOrderTicket,
    clientIds: state.adminClientIds,
    dicInfo: state.sparePartsDic,
});

export default checkRoles(connect(mapStateToProps, {
    fetchAdminClientIds,
    fetchSparePartsMultiSourcingGroups,
    closeSparePartDicModal
})(EditLoadTicketDataPage),['SP_DEALER', 'SP_EDITOR', 'SP_SG_EDITOR']);
