import {ADMIN_SECOND_HAND_BATTERY_FETCH} from '../actions/admin/actionAdminSecondHandBattery';

export const sparePartsWebsocketCommands = {
    //route (commands)
    SPARE_PARTS_ORDERS_NOTIFICATIONS_GET: 'getEmailNotification',
    SPARE_PARTS_ORDERS_NOTIFICATIONS_SET: 'setEmailNotification',
    SPARE_PARTS_ORDERS_MAIN_MESSAGE_GET: 'getMainMessage',
    SPARE_PARTS_ORDERS_MAIN_MESSAGE_SET: 'setMainMessage',
    SPARE_PARTS_ORDERS_MAIN_MESSAGES_GET: 'getGroupsMainMessages',
    SPARE_PARTS_ORDERS_POPUP_GET: 'getPopUp',
    SPARE_PARTS_ORDERS_POPUP_SET: 'setPopUp',
    SPARE_PARTS_ORDERS_TICKET_SETTINGS_SET: 'updateTicketSettings',
    SPARE_PARTS_ORDERS_TICKET_SETTINGS_GET: 'getTicketSettings',
    SPARE_PARTS_ORDERS_DEFAULT_DEALER_ROLE_GET: 'getDefaultDealerRole',
    SPARE_PARTS_ORDERS_DEFAULT_DEALER_ROLE_SET: 'setDefaultDealerRole',
    SPARE_PARTS_ORDERS_WORKING_HOURS_GET: 'getWorkingHours',
    SPARE_PARTS_ORDERS_WORKING_HOURS_SET: 'setWorkingHours',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_GET: 'getRecurrentProblem',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_CREATE: 'createRecurrentProblem',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_MOVE: 'moveRecurrentProblem',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_DELETE: 'deleteRecurrentProblem',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_UPDATE: 'updateRecurrentProblem',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_FILE_DOWNLOAD: 'recurrentProblemAttachmentDownload',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_FILE_UPLOAD: 'recurrentProblemAttachmentUpload',
    SPARE_PARTS_ORDERS_ADMIN_ROLE_GET: 'getAdminRole',
    SPARE_PARTS_ORDERS_ADMIN_ROLE_SET: 'setAdminRole',
    SPARE_PARTS_ORDERS_DEALER_ROLE_GET: 'getDealerRole',
    SPARE_PARTS_ORDERS_DEALER_ROLE_SET: 'setDealerRole',
    SPARE_PARTS_ORDERS_EDITOR_ROLE_GET: 'getEditorRole',
    SPARE_PARTS_ORDERS_EDITOR_ROLE_SET: 'setEditorRole',
    SPARE_PARTS_SOLUTION_GROUPS_USER_ROLE_GET: 'getSolutionGroupEditorRole',
    SPARE_PARTS_SOLUTION_GROUPS_USER_ROLE_SET: 'setSolutionGroupEditorRole',
    SPARE_PARTS_ORDERS_USER_LIST_PREFERENCE_SET: 'setUserListPreference',
    SPARE_PARTS_ORDERS_USER_HISTORY_PREFERENCE_SET: 'setUserHistoryPreference',
    SPARE_PARTS_ORDERS_USER_SEARCH_ENGINE_PREFERENCE_SET: 'setUserSearchEnginePreference',
    SPARE_PARTS_ORDERS_USER_STATISTICS_PREFERENCE_SET: 'setUserStatisticsPreference',
    SPARE_PARTS_ORDERS_PREFERENCE_GET: 'getPreferences',
    SPARE_PARTS_ORDERS_MULTI_SOURCING_GROUPS_GET: 'getMultiSourcingGroups',
    SPARE_PARTS_ORDERS_EXPORT_GET: 'getExport',
    SPARE_PARTS_ORDERS_EXPORT_CREATE: 'createTicketExport',
    SPARE_PARTS_ORDERS_EXPORT_DOWNLOAD_URL_GET: 'exportDownloadPreSignedUrl',
    SPARE_PARTS_ORDERS_DEALERS_GET: 'getDealers',
    SPARE_PARTS_ORDERS_HISTORY_GET: 'getHistory',
    SPARE_PARTS_ORDERS_LIST_GET: 'getList',
    SPARE_PARTS_ORDERS_SEARCH_ENGINE_GET: 'getSearchEngine',
    SPARE_PARTS_ORDERS_DIC_CREATE: 'createDic',
    SPARE_PARTS_ORDERS_DIC_UPDATE: 'updateDic',
    SPARE_PARTS_ORDERS_DIC_PART_INFO_GET: 'getSparePartInfo',
    SPARE_PARTS_ORDERS_DIC_VIN_STATUS_GET: 'getVinDicStatus',

    SPARE_PARTS_INTERNAL_TICKET_HISTORY_GET: 'getInternalHistory',
    SPARE_PARTS_INTERNAL_TICKET_LIST_GET: 'getInternalList',
    SPARE_PARTS_INTERNAL_TICKET_SEARCH_GET: 'getFullTextSearchInternal',
    SPARE_PARTS_ORDERS_USER_INTERNAL_TICKET_LIST_PREFERENCE_SET: 'setUserInternalListPreference',
    SPARE_PARTS_ORDERS_USER_INTERNAL_TICKET_HISTORY_PREFERENCE_SET: 'setUserInternalHistoryPreference',
    SPARE_PARTS_ORDERS_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE_SET: 'setUserFullTextSearchInternalPreference',
    SPARE_PARTS_ORDERS_USER_STATISTICS_SG_PREFERENCE_SET: 'setUserStatisticsSGPreference',

    SPARE_PARTS_VIN_DEALER_CHECK: 'checkVinDealer',

    SPARE_PARTS_ORDERS_MANAGEMENT_GROUP_GET: 'getManagementGroupCheck',
    SPARE_PARTS_ORDERS_MANAGEMENT_GROUP_CREATE: 'initiateManagementGroupBatch',
    SPARE_PARTS_ORDERS_MODEL_ANSWERS_GET: 'getModelAnswers',
    SPARE_PARTS_ORDERS_MODEL_ANSWERS_CREATE: 'createModelAnswer',
    SPARE_PARTS_ORDERS_MODEL_ANSWERS_UPDATE: 'updateModelAnswer',
    SPARE_PARTS_ORDERS_MODEL_ANSWERS_DELETE: 'deleteModelAnswer',
    SPARE_PARTS_ORDERS_FULL_SOLUTION_GROUP_INFO_GET: 'getFullSolutionGroupInfo',

    //receive
    SPARE_PARTS_ORDERS_TICKET_SEND_ERROR: 'SPARE_PARTS_ORDERS_TICKET_SEND_ERROR',
    SPARE_PARTS_ORDERS_NOTIFICATIONS_ACCEPTED: 'SPARE_PARTS_ORDERS_NOTIFICATIONS_ACCEPTED',
    SPARE_PARTS_ORDERS_NOTIFICATIONS_ERROR: 'SPARE_PARTS_ORDERS_NOTIFICATIONS_ERROR',
    SPARE_PARTS_ORDERS_NOTIFICATIONS_SEND: 'SPARE_PARTS_ORDERS_NOTIFICATIONS_SEND',
    SPARE_PARTS_ORDERS_NOTIFICATIONS_SEND_ERROR: 'SPARE_PARTS_ORDERS_NOTIFICATIONS_SEND_ERROR',
    SPARE_PARTS_ORDERS_WORKING_HOURS_ACCEPTED: 'SPARE_PARTS_ORDERS_WORKING_HOURS_ACCEPTED',
    SPARE_PARTS_ORDERS_WORKING_HOURS_ERROR: 'SPARE_PARTS_ORDERS_WORKING_HOURS_ERROR',
    SPARE_PARTS_ORDERS_WORKING_HOURS_SEND: 'SPARE_PARTS_ORDERS_WORKING_HOURS_SEND',
    SPARE_PARTS_ORDERS_WORKING_HOURS_SEND_ERROR: 'SPARE_PARTS_ORDERS_WORKING_HOURS_SEND_ERROR',
    SPARE_PARTS_ORDERS_TICKET_SETTINGS_ACCEPTED: 'SPARE_PARTS_ORDERS_TICKET_SETTINGS_ACCEPTED',
    SPARE_PARTS_ORDERS_TICKET_SETTINGS_ERROR: 'SPARE_PARTS_ORDERS_TICKET_SETTINGS_ERROR',
    SPARE_PARTS_ORDERS_TICKET_SETTINGS_SEND: 'SPARE_PARTS_ORDERS_TICKET_SETTINGS_SEND',
    SPARE_PARTS_ORDERS_TICKET_SETTINGS_SEND_ERROR: 'SPARE_PARTS_ORDERS_TICKET_SETTINGS_SEND_ERROR',
    SPARE_PARTS_ORDERS_DEFAULT_DEALER_ROLES_SEND: 'SPARE_PARTS_ORDERS_DEFAULT_DEALER_ROLES_SEND',
    SPARE_PARTS_ORDERS_DEFAULT_DEALER_ROLES_ERROR: 'SPARE_PARTS_ORDERS_DEFAULT_DEALER_ROLES_ERROR',
    SPARE_PARTS_ORDERS_ROLES_ACCEPTED: 'SPARE_PARTS_ORDERS_ROLES_ACCEPTED',
    SPARE_PARTS_ORDERS_ROLES_ERROR: 'SPARE_PARTS_ORDERS_ROLES_ERROR',
    SPARE_PARTS_ORDERS_ADMIN_ROLE_SEND: 'SPARE_PARTS_ORDERS_ADMIN_ROLE_SEND',
    SPARE_PARTS_ORDERS_ADMIN_ROLE_SEND_ERROR: 'SPARE_PARTS_ORDERS_ADMIN_ROLE_SEND_ERROR',
    SPARE_PARTS_ORDERS_DEALER_ROLE_SEND: 'SPARE_PARTS_ORDERS_DEALER_ROLE_SEND',
    SPARE_PARTS_ORDERS_DEALER_ROLE_SEND_ERROR: 'SPARE_PARTS_ORDERS_DEALER_ROLE_SEND_ERROR',
    SPARE_PARTS_ORDERS_EDITOR_ROLE_SEND: 'SPARE_PARTS_ORDERS_EDITOR_ROLE_SEND',
    SPARE_PARTS_ORDERS_EDITOR_ROLE_SEND_ERROR: 'SPARE_PARTS_ORDERS_EDITOR_ROLE_SEND_ERROR',
    SPARE_PARTS_SOLUTION_GROUPS_EDITOR_ROLE_SEND: 'SPARE_PARTS_SOLUTION_GROUPS_EDITOR_ROLE_SEND',
    SPARE_PARTS_SOLUTION_GROUPS_EDITOR_ROLE_SEND_ERROR: 'SPARE_PARTS_SOLUTION_GROUPS_EDITOR_ROLE_SEND_ERROR',
    SPARE_PARTS_ORDERS_MAIN_MESSAGES_ACCEPTED: 'SPARE_PARTS_ORDERS_MAIN_MESSAGES_ACCEPTED',
    SPARE_PARTS_ORDERS_MAIN_MESSAGES_ERROR: 'SPARE_PARTS_ORDERS_MAIN_MESSAGES_ERROR',
    SPARE_PARTS_ORDERS_MAIN_MESSAGES_SEND: 'SPARE_PARTS_ORDERS_MAIN_MESSAGES_SEND',
    SPARE_PARTS_ORDERS_MAIN_MESSAGES_SEND_ERROR: 'SPARE_PARTS_ORDERS_MAIN_MESSAGES_SEND_ERROR',
    SPARE_PARTS_ORDERS_GROUPS_MAIN_MESSAGES_SEND: 'SPARE_PARTS_ORDERS_GROUPS_MAIN_MESSAGES_SEND',
    SPARE_PARTS_ORDERS_GROUPS_MAIN_MESSAGES_SEND_ERROR: 'SPARE_PARTS_ORDERS_GROUPS_MAIN_MESSAGES_SEND_ERROR',
    SPARE_PARTS_ORDERS_POPUP_ACCEPTED: 'SPARE_PARTS_ORDERS_POPUP_ACCEPTED',
    SPARE_PARTS_ORDERS_POPUP_ERROR: 'SPARE_PARTS_ORDERS_POPUP_ERROR',
    SPARE_PARTS_ORDERS_POPUP_SEND: 'SPARE_PARTS_ORDERS_POPUP_SEND',
    SPARE_PARTS_ORDERS_POPUP_SEND_ERROR: 'SPARE_PARTS_ORDERS_POPUP_SEND_ERROR',
    SPARE_PARTS_ORDERS_PREFERENCES_ACCEPTED: 'SPARE_PARTS_ORDERS_PREFERENCES_ACCEPTED',
    SPARE_PARTS_ORDERS_PREFERENCES_ERROR: 'SPARE_PARTS_ORDERS_PREFERENCES_ERROR',
    SPARE_PARTS_ORDERS_PREFERENCES_SEND: 'SPARE_PARTS_ORDERS_PREFERENCES_SEND',
    SPARE_PARTS_ORDERS_PREFERENCES_SEND_ERROR: 'SPARE_PARTS_ORDERS_PREFERENCES_SEND_ERROR',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ACCEPTED: 'SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ACCEPTED',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ERROR: 'SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ERROR',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_SEND: 'SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_SEND',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_SEND_ERROR: 'SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_SEND_ERROR',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD: 'SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD_ERROR: 'SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ATTACHMENTS_DOWNLOAD_ERROR',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD: 'SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD',
    SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD_ERROR: 'SPARE_PARTS_ORDERS_RECURRENT_PROBLEMS_ATTACHMENTS_UPLOAD_ERROR',
    SPARE_PARTS_ORDERS_EXPORT_SEND: 'SPARE_PART_EXPORT_FETCH_SUCCESS',
    SPARE_PARTS_ORDERS_EXPORT_SEND_ERROR: 'SPARE_PART_EXPORT_FETCH_ERROR',
    SPARE_PARTS_ORDERS_EXPORT_STATUS: 'ORDER_EXPORT_STATUS',
    SPARE_PARTS_ORDERS_EXPORT_ERROR: 'ORDER_EXPORT_ERROR',
    SPARE_PARTS_ORDERS_EXPORT_DOWNLOAD_URL_SEND: 'SPARE_PARTS_EXPORT_DOWNLOAD',
    SPARE_PARTS_ORDERS_EXPORT_DOWNLOAD_URL_SEND_ERROR: 'SPARE_PARTS_EXPORT_DOWNLOAD_ERROR',
    SPARE_PARTS_ORDERS_DEALERS_SEND: 'SPARE_PART_DEALERS_FETCH_SUCCESS',
    SPARE_PARTS_ORDERS_DEALERS_SEND_ERROR: 'SPARE_PART_DEALERS_FETCH_ERROR',
    SPARE_PARTS_ORDERS_HISTORY_SEND: 'getHistory',
    SPARE_PARTS_ORDERS_HISTORY_SEND_ERROR: 'SPARE_PARTS_HISTORY_FETCH_ERROR',
    SPARE_PARTS_ORDERS_LIST_SEND: 'getList',
    SPARE_PARTS_ORDERS_LIST_SEND_ERROR: 'SPARE_PARTS_LIST_FETCH_ERROR',
    SPARE_PARTS_ORDERS_SEARCH_ENGINE_SEND: 'getSearchEngine',
    SPARE_PARTS_ORDERS_SEARCH_ENGINE_SEND_ERROR: 'SPARE_PARTS_SEARCH_ENGINE_FETCH_ERROR',
    SPARE_PARTS_ORDERS_DIC_PART_INFO_SEND: 'SPARE_PARTS_DIC_PART_INFO_SEND',
    SPARE_PARTS_ORDERS_DIC_PART_INFO_SEND_ERROR: 'SPARE_PARTS_DIC_PART_INFO_ERROR',
    SPARE_PARTS_ORDERS_DIC_VIN_STATUS_INFO_SEND: 'SPARE_PARTS_DIC_VIN_STATUS_INFO_SEND',
    SPARE_PARTS_ORDERS_DIC_VIN_STATUS_INFO_SEND_ERROR: 'SPARE_PARTS_DIC_VIN_STATUS_INFO_ERROR',
    SPARE_PARTS_ORDERS_DIC_UPDATE_ERROR: 'SPARE_PARTS_DIC_UPDATE_ERROR',
    SPARE_PARTS_ORDERS_DIC_CREATE_ERROR: 'SPARE_PARTS_DIC_CREATE_ERROR',
    SPARE_PARTS_ORDERS_DIC_CREATE_SEND: 'SPARE_PARTS_DIC_CREATE_SEND',
    SPARE_PARTS_ORDERS_DIC_UPDATE_SEND: 'SPARE_PARTS_DIC_UPDATE_SEND',
    SPARE_PARTS_ORDERS_DIC_CREATED: 'SPARE_PARTS_TICKET_DIC_CREATED',
    SPARE_PARTS_ORDERS_DIC_UPDATED: 'SPARE_PARTS_TICKET_DIC_UPDATED',
    SPARE_PARTS_ORDERS_DIC_MESSAGE_SEND_ERROR: 'SPARE_PARTS_SEND_DIC_MESSAGE_ERROR',
    SPARE_PARTS_ORDERS_DIC_MESSAGE_ERROR: 'SPARE_PARTS_DIC_MESSAGE_ERROR',
    SPARE_PARTS_ORDERS_TICKET_UPDATE_FATAL: 'SPARE_PARTS_ORDERS_TICKET_UPDATE_FATAL',
    SPARE_PARTS_ORDERS_TICKET_CREATE_FATAL: 'SPARE_PARTS_ORDERS_TICKET_CREATE_FATAL',

    SPARE_PARTS_INTERNAL_TICKET_SEND: 'SPARE_PARTS_INTERNAL_TICKET_SEND',
    SPARE_PARTS_INTERNAL_TICKET_SEND_ERROR: 'SPARE_PARTS_INTERNAL_TICKET_SEND_ERROR',
    SPARE_PARTS_INTERNAL_TICKET_LIST_SEND: 'SPARE_PARTS_INTERNAL_TICKET_LIST_SEND',
    SPARE_PARTS_INTERNAL_TICKET_LIST_SEND_ERROR: 'SPARE_PARTS_INTERNAL_TICKET_LIST_SEND_ERROR',
    SPARE_PARTS_INTERNAL_TICKET_HISTORY_SEND: 'SPARE_PARTS_INTERNAL_TICKET_HISTORY_SEND',
    SPARE_PARTS_INTERNAL_TICKET_HISTORY_SEND_ERROR: 'SPARE_PARTS_INTERNAL_TICKET_HISTORY_SEND_ERROR',
    SPARE_PARTS_INTERNAL_TICKET_SEARCH_SEND: 'SPARE_PARTS_INTERNAL_TICKET_SEARCH_SEND',
    SPARE_PARTS_INTERNAL_TICKET_SEARCH_SEND_ERROR: 'SPARE_PARTS_INTERNAL_TICKET_SEARCH_SEND_ERROR',
    SPARE_PARTS_INTERNAL_TICKET_UPDATE_ERROR: 'SPARE_PARTS_INTERNAL_TICKET_UPDATE_ERROR',
    SPARE_PARTS_INTERNAL_TICKET_CREATE_ERROR: 'SPARE_PARTS_INTERNAL_TICKET_CREATE_ERROR',

    // SECOND-HAND BATTERY TYPE
    SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPE_GET: 'getSecondHandBatteryTypes',
    SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPE_CREATE: 'createSecondHandBatteryType',
    SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPE_MOVE: 'moveSecondHandBatteryType',
    SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPE_DELETE: 'deleteSecondHandBatteryType',
    SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPE_UPDATE: 'updateSecondHandBatteryType',
    // SECOND-HAND BATTERY TYPE RESPONSE
    SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPES_ERROR: 'SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPES_ERROR',
    SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPES_SEND: 'SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPES_SEND',
    SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPES_SEND_ERROR: 'SPARE_PARTS_ORDERS_SECOND_HAND_BATTERY_TYPES_SEND_ERROR',

    //SOLUTION GROUPS
    //Get List Solution Group
    SPARE_PARTS_SOLUTION_GROUPS_LIST: 'getSolutionGroups',
    SPARE_PARTS_SOLUTION_GROUPS_LIST_FETCH_PENDING: 'SPARE_PARTS_SOLUTION_GROUPS_LIST_FETCH_PENDING',
    SPARE_PARTS_SOLUTION_GROUPS_LIST_SEND: 'SPARE_PARTS_SOLUTION_GROUPS_LIST_SEND',
    SPARE_PARTS_SOLUTION_GROUPS_LIST_SEND_ERROR: 'SPARE_PARTS_SOLUTION_GROUPS_LIST_SEND_ERROR',
    //Get Detail Solution Group
    SPARE_PARTS_SOLUTION_GROUPS_DETAIL: 'getSolutionGroup',
    SPARE_PARTS_SOLUTION_GROUPS_DETAIL_FETCH_PENDING: 'SPARE_PARTS_SOLUTION_GROUP_DETAIL_FETCH_PENDING',
    //Create Solution Group
    SPARE_PARTS_SOLUTION_GROUPS_CREATE: 'createSolutionGroup',
    SPARE_PARTS_SOLUTION_GROUPS_CREATE_PENDING: 'SPARE_PARTS_SOLUTION_GROUPS_CREATE_PENDING',
    SPARE_PARTS_SOLUTION_GROUPS_ACCEPTED: 'SPARE_PARTS_SOLUTION_GROUPS_ACCEPTED',
    SPARE_PARTS_SOLUTION_GROUPS_ERROR: 'SPARE_PARTS_SOLUTION_GROUPS_ERROR',
    //Update Solution Group
    SPARE_PARTS_SOLUTION_GROUPS_UPDATE: 'updateSolutionGroup',
    SPARE_PARTS_SOLUTION_GROUPS_UPDATE_PENDING: 'SPARE_PARTS_SOLUTION_GROUPS_UPDATE_PENDING',
    //Update Solution Group - NAME, USERS and RESET
    SPARE_PARTS_SOLUTION_GROUP_RESET: 'SPARE_PARTS_SOLUTION_GROUP_RESET',
    SPARE_PARTS_SOLUTION_GROUP_REDIRECT_RESET: 'SPARE_PARTS_SOLUTION_GROUP_REDIRECT_RESET',
    SPARE_PARTS_SOLUTION_GROUPS_SEND: 'SPARE_PARTS_SOLUTION_GROUPS_SEND',
    SPARE_PARTS_SOLUTION_GROUPS_SEND_ERROR: 'SPARE_PARTS_SOLUTION_GROUPS_SEND_ERROR',
    SPARE_PARTS_SOLUTION_GROUP_SAVE_NEW_ERROR: 'SPARE_PARTS_SOLUTION_GROUP_SAVE_NEW_ERROR',
    SPARE_PARTS_SOLUTION_GROUP_SAVE_UPDATE_ERROR: 'SPARE_PARTS_SOLUTION_GROUP_SAVE_UPDATE_ERROR',

    //Get Full Solution Group
    SPARE_PARTS_FULL_SOLUTION_GROUP_INFO_SEND: 'SPARE_PARTS_FULL_SOLUTION_GROUP_INFO_SEND',
    SPARE_PARTS_FULL_SOLUTION_GROUP_INFO_SEND_ERROR: 'SPARE_PARTS_FULL_SOLUTION_GROUP_INFO_SEND_ERROR',

    //ESCALATION SCHEMA
    //Get List Solution Group
    SPARE_PARTS_ESCALATION_SCHEMA_LIST: 'getEscalationSchemas',
    SPARE_PARTS_ESCALATION_SCHEMA_LIST_FETCH_PENDING: 'SPARE_PARTS_ESCALATION_SCHEMA_LIST_FETCH_PENDING',
    SPARE_PARTS_ESCALATION_SCHEMA_LIST_SEND: 'SPARE_PARTS_ESCALATION_SCHEMA_LIST_SEND',
    SPARE_PARTS_ESCALATION_SCHEMA_LIST_SEND_ERROR: 'SPARE_PARTS_ESCALATION_SCHEMA_LIST_SEND_ERROR',
    //Get Detail Solution Group
    SPARE_PARTS_ESCALATION_SCHEMA_DETAIL: 'getEscalationSchema',
    SPARE_PARTS_ESCALATION_SCHEMA_DETAIL_FETCH_PENDING: 'SPARE_PARTS_ESCALATION_SCHEMA_DETAIL_FETCH_PENDING',
    //Create Solution Group
    SPARE_PARTS_ESCALATION_SCHEMA_CREATE: 'createEscalationSchema',
    SPARE_PARTS_ESCALATION_SCHEMA_CREATE_PENDING: 'SPARE_PARTS_ESCALATION_SCHEMA_CREATE_PENDING',
    SPARE_PARTS_ESCALATION_SCHEMA_ACCEPTED: 'SPARE_PARTS_ESCALATION_SCHEMA_ACCEPTED',
    SPARE_PARTS_ESCALATION_SCHEMA_ERROR: 'SPARE_PARTS_ESCALATION_SCHEMA_ERROR',
    //Update Solution Group
    SPARE_PARTS_ESCALATION_SCHEMA_UPDATE: 'updateEscalationSchema',
    SPARE_PARTS_ESCALATION_SCHEMA_UPDATE_PENDING: 'SPARE_PARTS_ESCALATION_SCHEMA_UPDATE_PENDING',
    //Delete Solution Group
    SPARE_PARTS_ESCALATION_SCHEMA_DELETE: 'deleteEscalationSchema',
    SPARE_PARTS_ESCALATION_SCHEMA_DELETE_PENDING: 'SPARE_PARTS_ESCALATION_SCHEMA_DELETE_PENDING',
    //Update Solution Group - NAME, USERS and RESET
    SPARE_PARTS_ESCALATION_SCHEMA_RESET: 'SPARE_PARTS_ESCALATION_SCHEMA_RESET',
    SPARE_PARTS_ESCALATION_SCHEMA_REDIRECT_RESET: 'SPARE_PARTS_ESCALATION_SCHEMA_REDIRECT_RESET',
    SPARE_PARTS_ESCALATION_SCHEMA_SEND: 'SPARE_PARTS_ESCALATION_SCHEMA_SEND',
    SPARE_PARTS_ESCALATION_SCHEMA_SEND_ERROR: 'SPARE_PARTS_ESCALATION_SCHEMA_SEND_ERROR',
    SPARE_PARTS_ESCALATION_SCHEMA_SAVE_NEW_ERROR: 'SPARE_PARTS_ESCALATION_SCHEMA_SAVE_NEW_ERROR',
    SPARE_PARTS_ESCALATION_SCHEMA_SAVE_UPDATE_ERROR: 'SPARE_PARTS_ESCALATION_SCHEMA_SAVE_UPDATE_ERROR',


    //ANSWERING SCHEMA
    //Get List Solution Group
    SPARE_PARTS_ANSWERING_SCHEMA_LIST: 'getAnsweringSchemas',
    SPARE_PARTS_ANSWERING_SCHEMA_LIST_FETCH_PENDING: 'SPARE_PARTS_ANSWERING_SCHEMA_LIST_FETCH_PENDING',
    SPARE_PARTS_ANSWERING_SCHEMA_LIST_SEND: 'SPARE_PARTS_ANSWERING_SCHEMA_LIST_SEND',
    SPARE_PARTS_ANSWERING_SCHEMA_LIST_SEND_ERROR: 'SPARE_PARTS_ANSWERING_SCHEMA_LIST_SEND_ERROR',
    //Get Detail Solution Group
    SPARE_PARTS_ANSWERING_SCHEMA_DETAIL: 'getAnsweringSchema',
    SPARE_PARTS_ANSWERING_SCHEMA_DETAIL_FETCH_PENDING: 'SPARE_PARTS_ANSWERING_SCHEMA_DETAIL_FETCH_PENDING',
    //Create Solution Group
    SPARE_PARTS_ANSWERING_SCHEMA_CREATE: 'createAnsweringSchema',
    SPARE_PARTS_ANSWERING_SCHEMA_CREATE_PENDING: 'SPARE_PARTS_ANSWERING_SCHEMA_CREATE_PENDING',
    SPARE_PARTS_ANSWERING_SCHEMA_ACCEPTED: 'SPARE_PARTS_ANSWERING_SCHEMA_ACCEPTED',
    SPARE_PARTS_ANSWERING_SCHEMA_ERROR: 'SPARE_PARTS_ANSWERING_SCHEMA_ERROR',
    //Update Solution Group
    SPARE_PARTS_ANSWERING_SCHEMA_UPDATE: 'updateAnsweringSchema',
    SPARE_PARTS_ANSWERING_SCHEMA_UPDATE_PENDING: 'SPARE_PARTS_ANSWERING_SCHEMA_UPDATE_PENDING',
    //Delete Solution Group
    SPARE_PARTS_ANSWERING_SCHEMA_DELETE: 'deleteAnsweringSchema',
    SPARE_PARTS_ANSWERING_SCHEMA_DELETE_PENDING: 'SPARE_PARTS_ANSWERING_SCHEMA_DELETE_PENDING',
    //Update Solution Group - NAME, USERS and RESET
    SPARE_PARTS_ANSWERING_SCHEMA_RESET: 'SPARE_PARTS_ANSWERING_SCHEMA_RESET',
    SPARE_PARTS_ANSWERING_SCHEMA_REDIRECT_RESET: 'SPARE_PARTS_ANSWERING_SCHEMA_REDIRECT_RESET',
    SPARE_PARTS_ANSWERING_SCHEMA_SEND: 'SPARE_PARTS_ANSWERING_SCHEMA_SEND',
    SPARE_PARTS_ANSWERING_SCHEMA_SEND_ERROR: 'SPARE_PARTS_ANSWERING_SCHEMA_SEND_ERROR',
    SPARE_PARTS_ANSWERING_SCHEMA_SAVE_NEW_ERROR: 'SPARE_PARTS_ANSWERING_SCHEMA_SAVE_NEW_ERROR',
    SPARE_PARTS_ANSWERING_SCHEMA_SAVE_UPDATE_ERROR: 'SPARE_PARTS_ANSWERING_SCHEMA_SAVE_UPDATE_ERROR',

    SPARE_PARTS_ADMIN_SOLUTION_GROUPS_OPTIONS: 'getAdminSolutionGroups',
    SPARE_PARTS_ADMIN_SOLUTION_GROUPS_FETCH_PENDING: 'SPARE_PARTS_ADMIN_SOLUTION_GROUPS_FETCH_PENDING',
    SPARE_PARTS_ADMIN_SOLUTION_GROUPS_SEND: 'SPARE_PARTS_ADMIN_SOLUTION_GROUPS_SEND',

    SPARE_PARTS_ORDERS_ORDER_TYPE_FILTER: 'getOrderTypeFilter',
    SPARE_PARTS_ORDER_TYPE_FILTER_FETCH_SUCCESS: 'SPARE_PARTS_ORDER_TYPE_FILTER_SUCCESS',
    SPARE_PARTS_ORDER_TYPE_FILTER_FETCH_ERROR: 'SPARE_PARTS_ORDER_TYPE_FILTER_FETCH_ERROR',

    SPARE_PARTS_VIN_DEALER_CHECK_SEND: 'SPARE_PARTS_VIN_DEALER_CHECK_SEND',
    SPARE_PARTS_VIN_DEALER_CHECK_SEND_ERROR: 'SPARE_PARTS_VIN_DEALER_CHECK_SEND_ERROR',

    SPARE_PARTS_ORDERS_MANAGEMENT_GROUP_STATUS: 'ORDER_MANAGEMENT_GROUP_BATCH_STATUS',
    SPARE_PARTS_ORDERS_MANAGEMENT_GROUP_ERROR: 'ORDER_MANAGEMENT_GROUP_BATCH_ERROR',
    SPARE_PARTS_ORDERS_MANAGEMENT_GROUP_SEND: 'SPARE_PART_MANAGEMENT_GROUP_CHECK_SUCCESS',
    SPARE_PARTS_ORDERS_MANAGEMENT_GROUP_SEND_ERROR: 'SPARE_PART_MANAGEMENT_GROUP_CHECK_ERROR',

    SPARE_PARTS_ORDERS_MODEL_ANSWERS_SEND: 'SPARE_PARTS_MODEL_ANSWERS_SEND',
    SPARE_PARTS_ORDERS_MODEL_ANSWERS_SEND_ERROR: 'SPARE_PARTS_MODEL_ANSWERS_SEND_ERROR',
    SPARE_PARTS_ORDERS_MODEL_ANSWERS_SAVE: 'SPARE_PARTS_MODEL_ANSWERS_SAVE',
    SPARE_PARTS_ORDERS_MODEL_ANSWERS_SAVE_ERROR: 'SPARE_PARTS_MODEL_ANSWERS_SAVE_ERROR',
};
