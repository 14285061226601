import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {startUploadAttachments, uploadAttachments} from '../../../actions/orders/actionAttachments';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import Loader from '../../Loader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formMessages from '../../../intl/sparePartsOrders/formMessages';
import PageHeader from '../../common/PageHeader';
import get from 'get-value';

const UploadingAttachments = (props) => {
    const {formType, isAttachmentUploading, correlationId, attachmentsUrl, attachments, sparePartsOrderTicket} = props;
    const solutionGroup = get(sparePartsOrderTicket, 'ticket.solutionGroup.id');

    const uploadAttachments = () => {
        const merge = [];
        attachmentsUrl.forEach(attachment => {
            const data = attachments.find(att => att.uuid === attachment.uuid);
            merge.push({
                ...attachment,
                ...data,
            })
        });
        props.uploadAttachments(merge, correlationId, solutionGroup);
    };

    if (!isAttachmentUploading) {
        props.startUploadAttachments();
        uploadAttachments();
    }

    return (
        <div className="container-fluid">
            <PageHeader title={<ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>}/>
            <div className="container-fluid">
                <div className="col-12">
                    <FormattedMessage {...formMessages.UPLOADING_ATTACHMENTS}/>
                </div>
                <Loader/>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, {
    startUploadAttachments,
    uploadAttachments,
})(UploadingAttachments);

