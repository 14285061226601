import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import checkRoles from '../../common/roleChecker/RoleChecker';
import PageHeader from '../../common/PageHeader';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import EscalationLevelContainer from './EscalationLevelContainer';

class EscalationLevelPage extends Component {
    render() {
        const {domain} = this.props;
        return (
            <div>
                <PageHeader title={
                    <FormattedMessage id="admin.escalationLevel.levelsTitle"
                                      defaultMessage="Multi-level Escalation Schema - {domain} - Escalation levels"
                                      values={{ domain: <FormattedMessage {...formTypeMessages[domain]} /> }} />
                } />
                <div>
                    <div>
                        <EscalationLevelContainer domain={domain}/>
                    </div>
                </div>
            </div>
        );
    }
}

EscalationLevelPage.propTypes = {
    domain: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
});

export default checkRoles(connect(mapStateToProps, {
})(EscalationLevelPage), ['TD_POWER_USER']);
