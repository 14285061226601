import {put, takeEvery, select} from 'redux-saga/effects';
import {errorAlert} from '../actions/alertsActions';
import {extractErrorText} from '../actions/actionUtils';
import {cloneDeep} from 'lodash';
import {
    ADMIN_COUNTRIES_CHANGED,
    ADMIN_RIGHTS_FETCH_SUCCESS,
    DEALER_FORM_TYPES_CHANGED,
    DEALER_RIGHTS_FETCH_SUCCESS,
    EDITOR_COUNTRIES_CHANGED,
    EDITOR_RIGHTS_FETCH_SUCCESS,
    INITIAL_ADMIN_COUNTRY_OPTIONS,
    INITIAL_DEALER_FORM_TYPE_OPTIONS,
    INITIAL_EDITOR_COUNTRY_OPTIONS,
    RIGHTS_ERROR,
    SELECTED_ADMIN_COUNTRY_OPTIONS,
    SELECTED_DEALER_FORM_TYPE_OPTIONS,
    SELECTED_EDITOR_COUNTRY_OPTIONS
} from '../actions/admin/actionAdminRightsManagement';
import {countryGroups, getFormTypes} from '../constants/Utils';
import {
    validateAdminRoles,
    validateEditorData,
    validateSolutionGroupEditorData,
    validateDealerRoles
} from '../api/validation/role';
import get from 'get-value';
import {SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM} from '../constants/formConstants';
import {sparePartsWebsocketCommands} from '../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../constants/claimsWebsocketCommands';

import alertMessages from '../intl/common/alertMessages';
import {
    DEFAULT_DEALER_ROLES_CHANGED, DEFAULT_DEALER_ROLES_FETCH_SUCCESS, DEFAULT_ROLES_ERROR,
    INITIAL_DEFAULT_DEALER_ROLES,
    SELECTED_DEFAULT_DEALER_ROLES
} from '../actions/admin/actionAdminDefaultCountryRole';
import {
    FIXED_SOLUTION_GROUPS_EDITOR_OPTIONS,
    INITIAL_SOLUTION_GROUPS_EDITOR_OPTIONS,
    SELECTED_SOLUTION_GROUPS_EDITOR_OPTIONS, SOLUTION_GROUPS_EDITOR_OPTIONS_CHANGED,
    SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_SUCCESS
} from '../actions/admin/actionSolutionGroupUserRightsManagement';
import {moduleRoles} from '../utils/roles';

function* handleFetchAdminData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `correlationId`, {default: undefined});
        const {correlationId} = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const emptyAdminData = {};
        Object.keys(countryGroups).forEach(groupKey => emptyAdminData[countryGroups[groupKey]] = false);
        const {error} = validateAdminRoles(cloneDeep(action.payload.groupPermission));
        if (error && action.payload.groupPermission) {
            yield put({type: RIGHTS_ERROR, payload: {correlationId}});
            yield put(errorAlert(alertMessages.RIGHTS_ADMIN_RETRIEVED_ERROR, [error.toString()]));
        } else {
            const finalResult = (action.payload.groupPermission ? Object.assign(emptyAdminData, action.payload.groupPermission) : emptyAdminData);
            yield put({
                type: ADMIN_RIGHTS_FETCH_SUCCESS,
                payload: {finalResult, correlationId},
            });

            const result = Object.keys(finalResult)
                .filter(countryIso => finalResult[countryIso] === true)
                .map(countryIso => ({value: countryIso}));
            yield put({
                type: SELECTED_ADMIN_COUNTRY_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: INITIAL_ADMIN_COUNTRY_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: ADMIN_COUNTRIES_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({type: RIGHTS_ERROR});
        yield put(errorAlert(...extractErrorText(e, alertMessages.RIGHTS_ADMIN_FETCH_ERROR)));
    }
}

function* handleFetchEditorData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `editorCorrelationId`, {default: undefined});
        const {correlationId} = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const loggedAdminCountries = get(yield select(state => state.profile), `userDetail.roles.${action.payload.domain}.admin.groupPermission`, {default: {}});
        const emptyEditorData = Object.keys(loggedAdminCountries)
            .filter(groupKey => loggedAdminCountries[groupKey] === true)
            .reduce((acc, groupKey) => ({
                ...acc,
                [groupKey]: false,
            }), {});
        const {error} = validateEditorData(cloneDeep(action.payload.groupPermission));
        if (error && action.payload.groupPermission) {
            yield put({type: RIGHTS_ERROR, payload: {correlationId}});
            yield put(errorAlert(alertMessages.RIGHTS_EDITOR_RETRIEVED_ERROR, [error.toString()]));
        } else {
            const filteredResult = Object.keys(action.payload.groupPermission || {})
                .filter(countryIso => emptyEditorData[countryIso] !== undefined)
                .reduce((acc, countryIso) => ({
                    ...acc,
                    [countryIso]: action.payload.groupPermission[countryIso],
                }), {});
            const finalResult = (action.payload.groupPermission ? Object.assign(emptyEditorData, filteredResult) : emptyEditorData);

            yield put({
                type: EDITOR_RIGHTS_FETCH_SUCCESS,
                payload: {finalResult, correlationId},
            });

            const result = Object.keys(finalResult)
                .filter(countryIso => finalResult[countryIso] === true)
                .map(countryIso => ({value: countryIso}));
            yield put({
                type: SELECTED_EDITOR_COUNTRY_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: INITIAL_EDITOR_COUNTRY_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: EDITOR_COUNTRIES_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({type: RIGHTS_ERROR});
        yield put(errorAlert(...extractErrorText(e, alertMessages.RIGHTS_EDITOR_FETCH_ERROR)));
    }
}

function* handleFetchDealerData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `dealerCorrelationId`, {default: undefined});
        const {correlationId} = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const emptyDealerData = getFormTypes(action.payload.domain)
            .filter(formType => formType !== SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM)
            .reduce((acc, formKey) => ({
                ...acc,
                [formKey]: false,
            }), {});

        const domain = get(action, 'payload.domain', {default: ''});
        const dealerData = cloneDeep(action.payload.formsPermission);
        const {error} = validateDealerRoles(domain, dealerData);

        if (error && action.payload.formsPermission) {
            yield put({type: RIGHTS_ERROR, payload: {correlationId}});
            yield put(errorAlert(alertMessages.RIGHTS_DEALER_RETRIEVED_ERROR, [error.toString()]));
        } else {
            const finalResult = (action.payload.formsPermission ? Object.assign(emptyDealerData, action.payload.formsPermission) : emptyDealerData);
            yield put({
                type: DEALER_RIGHTS_FETCH_SUCCESS,
                payload: {finalResult, correlationId},
            });

            const result = Object.keys(finalResult)
                .filter(formType => finalResult[formType] === true)
                .map(formType => ({value: formType}));
            yield put({
                type: SELECTED_DEALER_FORM_TYPE_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: INITIAL_DEALER_FORM_TYPE_OPTIONS,
                payload: cloneDeep(result)
            });
            yield put({
                type: DEALER_FORM_TYPES_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({type: RIGHTS_ERROR});
        yield put(errorAlert(...extractErrorText(e, alertMessages.RIGHTS_DEALER_FETCH_ERROR)));
    }
}

function* handleFetchDefaultDealerData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminDefaultDealerRole), `correlationId`, {default: undefined});
        const {correlationId} = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }
        const emptyDealerData = getFormTypes(action.payload.domain)
            .filter(formType => formType !== SPARE_PARTS_REQUEST_FOR_SUPPORT_FORM)
            .reduce((acc, formKey) => ({
                ...acc,
                [formKey]: false,
            }), {});

        const domain = get(action, 'payload.domain', {default: ''});
        const dealerData = cloneDeep(get(action, 'payload.dealer.formsPermission'));

        const {error} = validateDealerRoles(domain, dealerData);

        if (error && get(action, 'payload.dealer.formsPermission')) {
            yield put({type: DEFAULT_ROLES_ERROR, payload: {correlationId}});
            yield put(errorAlert(alertMessages.DEFAULT_DEALER_ROLES_RETRIEVED_ERROR, [error.toString()]));
        } else {
            const finalResult = (get(action, 'payload.dealer.formsPermission') ? Object.assign(emptyDealerData, action.payload.dealer.formsPermission) : emptyDealerData);

            yield put({
                type: DEFAULT_DEALER_ROLES_FETCH_SUCCESS,
                payload: {finalResult, correlationId},
            });

            const result = Object.keys(finalResult)
                .filter(formType => finalResult[formType] === true)
                .map(formType => ({value: formType}));

            yield put({
                type: SELECTED_DEFAULT_DEALER_ROLES,
                payload: cloneDeep(result)
            });
            yield put({
                type: INITIAL_DEFAULT_DEALER_ROLES,
                payload: cloneDeep(result)
            });
            yield put({
                type: DEFAULT_DEALER_ROLES_CHANGED,
                payload: false
            });
        }
    } catch (e) {
        yield put({type: DEFAULT_ROLES_ERROR});
        yield put(errorAlert(...extractErrorText(e, alertMessages.DEFAULT_DEALER_ROLES_FETCH_ERROR)));
    }
}

function* handleFetchSGEditorData(action) {
    try {
        const stateCorrelationId = get(yield select(state => state.adminRightsManagement), `correlationId`, {default: undefined});
        const {correlationId} = action.payload;
        if (correlationId < stateCorrelationId) {
            return;
        }

        console.log('in the handleFetchSGEditorData');
        console.log('action.payload.domain');
        console.log(action.payload);
        if(moduleRoles.isPowerUser(get(yield select(state => state.profile), `userDetail.roles`, {default: {}}), action.payload.domain)){
            const emptySGEditorData = get(yield select(state => state.adminEscalationSchema), `EscalationSchemaOptions`, {default: {}});
            const {error} = validateSolutionGroupEditorData(cloneDeep(action.payload.groupPermission));

            if (error && action.payload.sgPermission) {
                yield put({type: RIGHTS_ERROR, payload: {correlationId}});
                yield put(errorAlert(alertMessages.RIGHTS_SG_EDITOR_RETRIEVED_ERROR, [error.toString()]));
            } else {

                yield put({
                    type: SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_SUCCESS,
                    payload: {finalResult: emptySGEditorData, correlationId},
                });
                if(action.payload.sgPermission){
                    const fixedSG = []

                    const editableSG = (action.payload.sgPermission || {}).map(sg => (
                        emptySGEditorData.find(e => e.value === sg.id) ? {
                            value: sg.id,
                            label: sg.name,
                        } : undefined
                    )).filter(x => x !== undefined)

                    yield put({
                        type: SELECTED_SOLUTION_GROUPS_EDITOR_OPTIONS,
                        payload: cloneDeep(editableSG)
                    });

                    yield put({
                        type: FIXED_SOLUTION_GROUPS_EDITOR_OPTIONS,
                        payload: cloneDeep(fixedSG)
                    });

                    yield put({
                        type: INITIAL_SOLUTION_GROUPS_EDITOR_OPTIONS,
                        payload: cloneDeep(editableSG)
                    });
                }

                yield put({
                    type: SOLUTION_GROUPS_EDITOR_OPTIONS_CHANGED,
                    payload: false
                });
            }
        } else {
            const loggedAdminSG = get(yield select(state => state.profile), `userDetail.roles.${action.payload.domain}.sgAdmin.sgPermission`, {default: {}});
            const emptySGEditorData = loggedAdminSG
                .filter(sg => sg.isActive === true)

            const {error} = validateSolutionGroupEditorData(cloneDeep(action.payload.groupPermission));

            if (error && action.payload.sgPermission) {
                yield put({type: RIGHTS_ERROR, payload: {correlationId}});
                yield put(errorAlert(alertMessages.RIGHTS_SG_EDITOR_RETRIEVED_ERROR, [error.toString()]));
            } else {

                yield put({
                    type: SOLUTION_GROUPS_EDITOR_RIGHTS_FETCH_SUCCESS,
                    payload: {finalResult: emptySGEditorData, correlationId},
                });
                if(action.payload.sgPermission){
                    const fixedSG = (action.payload.sgPermission || {}).map(sg => (
                        !emptySGEditorData.find(e => e.id === sg.id) ? {
                            value: sg.id,
                            label: sg.name,
                            groups: sg.groups,
                            isDefault: sg.isDefault,
                            isActive: sg.isActive,
                            canForwardToDealer: sg.canForwardToDealer,
                            isFixed: true
                        } : undefined
                    )).filter(x => x !== undefined)

                    const editableSG = (action.payload.sgPermission || {}).map(sg => (
                        emptySGEditorData.find(e => e.id === sg.id) ? {
                            value: sg.id,
                            label: sg.name,
                            groups: sg.groups,
                            isDefault: sg.isDefault,
                            isActive: sg.isActive,
                            canForwardToDealer: sg.canForwardToDealer,
                        } : undefined
                    )).filter(x => x !== undefined)

                    yield put({
                        type: SELECTED_SOLUTION_GROUPS_EDITOR_OPTIONS,
                        payload: cloneDeep(editableSG)
                    });

                    yield put({
                        type: FIXED_SOLUTION_GROUPS_EDITOR_OPTIONS,
                        payload: cloneDeep(fixedSG)
                    });

                    yield put({
                        type: INITIAL_SOLUTION_GROUPS_EDITOR_OPTIONS,
                        payload: cloneDeep(editableSG)
                    });
                }

                yield put({
                    type: SOLUTION_GROUPS_EDITOR_OPTIONS_CHANGED,
                    payload: false
                });
            }
        }
    } catch (e) {
        yield put({type: RIGHTS_ERROR});
        yield put(errorAlert(...extractErrorText(e, alertMessages.RIGHTS_SG_EDITOR_FETCH_ERROR)));
    }
}

function* rightsManagementSaga() {
    yield takeEvery(techDocWebsocketCommands.TECH_DOC_ADMIN_ROLE_SEND, handleFetchAdminData);
    yield takeEvery(techDocWebsocketCommands.TECH_DOC_EDITOR_ROLE_SEND, handleFetchEditorData);
    yield takeEvery(techDocWebsocketCommands.TECH_DOC_DEALER_ROLE_SEND, handleFetchDealerData);
    yield takeEvery(techDocWebsocketCommands.TECH_DOC_DEFAULT_DEALER_ROLES_SEND, handleFetchDefaultDealerData);
    yield takeEvery(techDocWebsocketCommands.TECH_DOC_SOLUTION_GROUPS_EDITOR_ROLE_SEND, handleFetchSGEditorData);
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_ADMIN_ROLE_SEND, handleFetchAdminData);
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_EDITOR_ROLE_SEND, handleFetchEditorData);
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DEALER_ROLE_SEND, handleFetchDealerData);
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_DEFAULT_DEALER_ROLES_SEND, handleFetchDefaultDealerData);
    yield takeEvery(sparePartsWebsocketCommands.SPARE_PARTS_SOLUTION_GROUPS_EDITOR_ROLE_SEND, handleFetchSGEditorData);
    yield takeEvery(claimsWebsocketCommands.CLAIMS_ADMIN_ROLE_SEND, handleFetchAdminData);
    yield takeEvery(claimsWebsocketCommands.CLAIMS_EDITOR_ROLE_SEND, handleFetchEditorData);
    yield takeEvery(claimsWebsocketCommands.CLAIMS_DEALER_ROLE_SEND, handleFetchDealerData);
    yield takeEvery(claimsWebsocketCommands.CLAIMS_DEFAULT_DEALER_ROLES_SEND, handleFetchDefaultDealerData);
}

export default rightsManagementSaga;
