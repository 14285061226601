import React from 'react';
import { connect } from 'react-redux';
import {change, formValueSelector} from 'redux-form';
import { InputFieldWithValidation } from '../../../../../../common/InputFieldWithValidation';
import formMessages from '../../../../../../../intl/admin/solutionGroupMessages';
import { InputCheckBox } from '../../../../../../common/InputCheckbox';
import {
    FORM_FIELD_SG_ACTIVE,
    FORM_FIELD_SG_ANSWER_TO_DEALER,
    FORM_FIELD_SG_DEFAULT,
    FORM_FIELD_SG_NAME,
    FORM_FIELD_SG_NOTIFICATIONS,
    FORM_FIELD_SG_TECH_DOC_INTERNAL_TICKET_NOTIFICATION,
    FORM_LABEL_SG_ACTIVE,
    FORM_LABEL_SG_ANSWER_TO_DEALER,
    FORM_LABEL_SG_DEFAULT,
    FORM_LABEL_SG_NAME,
    FORM_LABEL_SG_NOTIFICATIONS, FORM_LABEL_SG_TECH_DOC_INTERNAL_NOTIFICATIONS,
    FORM_SOLUTION_GROUP_INFO_SECTION
} from '../../../../../../../constants/formConstants';
import BorderRadius from '../../../../../../common/styled/BorderRadius';
import {domains} from '../../../../../../../constants/Utils';

const InfoSection = (props) => {
    const {
        readOnly, reqFields,  creating, isDefault, isActive, canForwardToDealer, notifications, domain, internalNotifications
    } = props;

    const handleChangeIsDefault = () => {
        if(!isDefault) {
            props.change(`${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_ACTIVE}`, true);
            props.change(`${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_ANSWER_TO_DEALER}`, true);
        }
    }
    const handleChangeAnswerToDealer = () => {
        if(!canForwardToDealer){
            props.change(`${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_ACTIVE}`, true);
        } else {
            props.change(`${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_DEFAULT}`, false);
        }
    }
    const handleChangeIsActive = () => {
        if(isActive){
            props.change(`${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_DEFAULT}`, false);
            props.change(`${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_ANSWER_TO_DEALER}`, false);
        }
    }

    const handleChangeNotifications = () => {
        props.change(`${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_NOTIFICATIONS}`, !notifications);
    }

    const handleChangeInternalNotifications = () => {
        props.change(`${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_TECH_DOC_INTERNAL_TICKET_NOTIFICATION}`, !internalNotifications);
    }

    // TODO notifications are not deployed on spare parts stg env
    const notificationFeature = domain !== domains.CLAIMS;

    return (
        <BorderRadius size={5} className="border border-secondary px-4 py-2 bg-white">
            <div className="form-row">
                <div className="col pb-2">
                    <InputFieldWithValidation
                        label={formMessages[FORM_LABEL_SG_NAME]}
                        field={FORM_FIELD_SG_NAME}
                        isDisabled={readOnly}
                        isRequired={reqFields.includes(FORM_FIELD_SG_NAME) && !readOnly}
                        isSmall/>
                </div>
            </div>
            {!creating &&
                <div className="form-row">
                    <div className="col pb-2">
                        <InputCheckBox
                            label={formMessages[FORM_LABEL_SG_ACTIVE]}
                            field={FORM_FIELD_SG_ACTIVE}
                            isDisabled={readOnly}
                            onChange={handleChangeIsActive}
                            disabledStyle={{color: '#ccced0'}}
                            isSmall/>
                    </div>
                </div>
            }
            <div className="form-row">
                <div className="col pb-2">
                    <InputCheckBox
                        label={formMessages[FORM_LABEL_SG_DEFAULT]}
                        field={FORM_FIELD_SG_DEFAULT}
                        isDisabled={readOnly}
                        onChange={handleChangeIsDefault}
                        disabledStyle={{color: '#ccced0'}}
                        isSmall/>
                </div>
            </div>
            <div className="form-row">
                <div className="col pb-2">
                    <InputCheckBox
                        label={formMessages[FORM_LABEL_SG_ANSWER_TO_DEALER]}
                        field={FORM_FIELD_SG_ANSWER_TO_DEALER}
                        isDisabled={readOnly}
                        onChange={handleChangeAnswerToDealer}
                        disabledStyle={{color: '#ccced0'}}
                        isSmall/>
                </div>
            </div>
            {!isDefault && notificationFeature && <div className="form-row">
                <div className="col pb-2">
                    <InputCheckBox
                        label={formMessages[FORM_LABEL_SG_NOTIFICATIONS]}
                        field={FORM_FIELD_SG_NOTIFICATIONS}
                        isDisabled={readOnly}
                        onChange={handleChangeNotifications}
                        disabledStyle={{color: '#ccced0'}}
                        isSmall/>
                </div>
            </div>}
            {isDefault && domain === domains.TECHNICAL_DOCUMENTATION && <div className="form-row">
                <div className="col pb-2">
                    <InputCheckBox
                        label={formMessages[FORM_LABEL_SG_TECH_DOC_INTERNAL_NOTIFICATIONS]}
                        field={FORM_FIELD_SG_TECH_DOC_INTERNAL_TICKET_NOTIFICATION}
                        isDisabled={readOnly}
                        onChange={handleChangeInternalNotifications}
                        disabledStyle={{color: '#ccced0'}}
                        isSmall/>
                </div>
            </div>}
        </BorderRadius>
    )

};

const mapStateToProps = (state, props) => ({
    canForwardToDealer: formValueSelector(props.form)(state, `${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_ANSWER_TO_DEALER}`),
    isDefault: formValueSelector(props.form)(state, `${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_DEFAULT}`),
    isActive: formValueSelector(props.form)(state, `${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_ACTIVE}`),
    notifications: formValueSelector(props.form)(state, `${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_NOTIFICATIONS}`),
    internalNotifications: formValueSelector(props.form)(state, `${FORM_SOLUTION_GROUP_INFO_SECTION}.${FORM_FIELD_SG_TECH_DOC_INTERNAL_TICKET_NOTIFICATION}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoSection);
