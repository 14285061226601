import React from 'react';
import 'react-table/react-table.css';
import {PrimaryButton, SecondaryButton} from '../../common/Button';
import {FormSection, reduxForm} from 'redux-form';
import buttonMessages from '../../../intl/common/buttonMessages';
import {FormattedMessage} from 'react-intl';
import BorderRadius from '../../common/styled/BorderRadius';
import RequestDetailInternalSearchSection from './section/RequestDetailInternalSearchSection';
import {
    FORM_PART_DETAIL_SECTION,
    FORM_REQUEST_DETAILS_SECTION,
    FORM_SEARCH_OPTION_SECTION,
    FORM_VEHICLE_DETAIL_SECTION
} from '../../../constants/formConstants';
import {domains} from '../../../constants/Utils';
import SearchOptionInternalSearchSection from './section/SearchOptionInternalSearchSection';
import VehicleDetailInternalSearchSection from './section/VehicleDetailInternalSearchSection';
import PartDetailInternalSearchSection from './section/PartDetailInternalSearchSection';

class InternalTicketSearchSparePartsForm extends React.Component {
    reset = () => {
        this.props.resetForm();
        this.props.reset();
    }

    render() {
        return (
            <BorderRadius size={5} className="border border-secondary p-4 bg-white">
                {/*<RenderSyncErrors form={form} intlMessages={fulltextSearchMessages} mapping={sparePartsFieldLabelMapping}/>*/}
                <div className="row justify-content-center">
                    <div className="col-12">
                        <this.props.form className="form-group">
                            <div className="col-12 pb-2">
                                <FormSection name={FORM_REQUEST_DETAILS_SECTION}>
                                    <RequestDetailInternalSearchSection domain={domains.SPARE_PARTS} />
                                </FormSection>
                                <FormSection name={FORM_VEHICLE_DETAIL_SECTION}>
                                    <VehicleDetailInternalSearchSection domain={domains.SPARE_PARTS} />
                                </FormSection>
                                <FormSection name={FORM_PART_DETAIL_SECTION}>
                                    <PartDetailInternalSearchSection domain={domains.SPARE_PARTS} />
                                </FormSection>
                                <FormSection name={FORM_SEARCH_OPTION_SECTION}>
                                    <SearchOptionInternalSearchSection />
                                </FormSection>
                            </div>
                            <div className="col-12 pb-2">
                                <PrimaryButton type="button"
                                               className="btn mr-2 float-right"
                                               onClick={this.props.handleSubmit}>
                                    <FormattedMessage {...buttonMessages.SEARCH}/>
                                </PrimaryButton>
                                <SecondaryButton type="button"
                                                 className="btn float-right mr-2 bg-white"
                                                 onClick={this.reset}>
                                    <FormattedMessage {...buttonMessages.CANCEL}/>
                                </SecondaryButton>
                            </div>
                        </this.props.form>
                    </div>
                </div>
            </BorderRadius>
        )
    }
}

export default reduxForm({})(InternalTicketSearchSparePartsForm);
