import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {formValueSelector, change, Field} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {
    DOCUMENTATION_UPDATE_SECTION,
    FORM_FIELD_DOC_UPDATE_NEEDED,
    FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION,
    FORM_FIELD_ROOT_CAUSE,
    FORM_FIELD_UPDATE_FILE_NUMBER,
    FORM_LABEL_DOC_UPDATE_NEEDED,
    FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION,
    FORM_LABEL_ROOT_CAUSE,
    FORM_LABEL_UPDATE_FILE_NUMBER,
    SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM,
    SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM,
} from '../../../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import formMessages from '../../../../../../intl/technicalDocumentations/formMessages';
import DatePickerComponent from '../../../../../common/datePicker/DatePickerComponent';
import moment from 'moment';
import commonMessages from '../../../../../../intl/common/commonMessages';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {problemCategoryList} from '../../../../../../constants/Utils';
import problemCategoryMessages from '../../../../../../intl/technicalDocumentations/problemCategoryMessages';
import get from "get-value";

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const DocumentationUpdateSectionTD = (props) => {
    const { readOnly, reqFields, userRights, updateFileNumber, updateFileNumberNeeded, change, form, ticketData } = props;

    const ticketSolutionGroup = get(ticketData, 'solutionGroupInfo', {});
    const isConformOrNotFoundReference = form
        === SPARE_PARTS_REQUEST_FOR_NOT_CONFORM_REFERENCE_FORM
        || form
        === SPARE_PARTS_REQUEST_FOR_NOT_FOUND_REFERENCE_FORM;


    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.DOCUMENTATION_UPDATE_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-md-6">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_DOC_UPDATE_NEEDED]}
                                                        field={FORM_FIELD_DOC_UPDATE_NEEDED}
                                                        options={['YES', 'NO']}
                                                        intlMessages={commonMessages}
                                                        isRequired={(!readOnly && isConformOrNotFoundReference && !get(ticketSolutionGroup, 'isDefault', true)) || reqFields.includes(FORM_FIELD_DOC_UPDATE_NEEDED)}
                                                        isDisabled={readOnly || !userRights.canEditDocumentationUpdate}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_UPDATE_FILE_NUMBER]}
                                                  field={FORM_FIELD_UPDATE_FILE_NUMBER}
                                                  maxLength={25}
                                                  isDisabled={readOnly || (updateFileNumberNeeded === 'NO') || !userRights.canEditDocumentationUpdate}
                                                  isRequired={reqFields.includes(FORM_FIELD_UPDATE_FILE_NUMBER)}
                                                  isSmall
                        />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <Field component={DatePickerComponent}
                               selectsStart
                               label={formMessages[FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION]}
                               name={FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}
                               isRequired={ticketSolutionGroup ?  !readOnly && get(ticketSolutionGroup, 'name', '').toLowerCase().includes('l3') && updateFileNumberNeeded === 'YES'  : false}
                               isDisabled={readOnly || !userRights.canEditDocumentationUpdate || get(ticketSolutionGroup, 'name', '').toLowerCase().includes('l2')}
                        />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_ROOT_CAUSE]}
                                                        field={FORM_FIELD_ROOT_CAUSE}
                                                        options={problemCategoryList}
                                                        intlMessages={problemCategoryMessages}
                                                        isRequired={reqFields.includes(FORM_FIELD_ROOT_CAUSE)}
                                                        isDisabled={readOnly || !userRights.canEditDocumentationUpdate}
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )

}

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, initialProps) => ({
    userRoles: state.profile.userDetail.roles,
    updateFileNumber: selector(initialProps.form, state, `${DOCUMENTATION_UPDATE_SECTION}.${FORM_FIELD_UPDATE_FILE_NUMBER}`),
    updateFileNumberNeeded: selector(initialProps.form, state, `${DOCUMENTATION_UPDATE_SECTION}.${FORM_FIELD_DOC_UPDATE_NEEDED}`),
    ticketData: state.techDocTicket,
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(DocumentationUpdateSectionTD);
