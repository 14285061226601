import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Field} from 'redux-form';
import {RequiredSpan} from './StyledComponents';
import {RenderSelectInput} from './FormField';
import {required} from "./validationCommons";

const InputMultipleSelectField = (props) => {
    const {
        intlMessages,
        intl: {formatMessage},
        label,
        field,
        options,
        isDisabled,
        isRequired,
        placeholder,
        onHandle,
        isSearchable,
        isClearable,
        closeMenuOnSelect
    } = props;

    let validations = [];
    if (isRequired) {
        validations.push(required);
    }

    const formattedOptions = options.map(value => ({
        value: value,
        label: (intlMessages && intlMessages[value]) ? formatMessage(intlMessages[value]) : value
    }));

    const formatValue = (value) => {
        return value && value.length > 0 ? value.map(e => ({
            value: e,
            label: (intlMessages && intlMessages[e]) ? formatMessage(intlMessages[e]) : e
        })) : null;
    };

    const parseValue = (value) => {
        return value && value.length > 0 ? value.map(e => e.value) : null;
    };

    return (
        <div>
            {!!label && label instanceof Object &&
                <label className="col-form-label pl-0 font-weight-bold">
                    <FormattedMessage {...label}/>
                    {!isDisabled && isRequired ? <RequiredSpan>{'\u00A0*'}</RequiredSpan> : null}
                </label>
            }
            <Field name={field}
                   component={RenderSelectInput}
                   parse={parseValue}
                   format={formatValue}
                   options={formattedOptions}
                   onChange={onHandle}
                   isMulti
                   isSearchable={isSearchable}
                   isDisabled={isDisabled}
                   isClearable={isClearable}
                   validate={validations}
                   closeMenuOnSelect={closeMenuOnSelect}
                   placeholder={placeholder}/>
        </div>
    )
};

export default (injectIntl(InputMultipleSelectField));
