import {
    FILE_UPLOAD_STATUS,
    FILES_DOWNLOAD_PENDING,
    FILES_UPLOAD_PENDING
} from '../actions/actionFile';

export const mainFiles = (state = {
    uploading: new Map(),
    attachmentsToBeUploaded: new Map(),
    attachmentsToBeDownloaded: new Map(),
}, action) => {
    switch (action.type) {
        case FILES_UPLOAD_PENDING: {
            let attachmentsToBeUploaded = new Map(state.attachmentsToBeUploaded);
            attachmentsToBeUploaded.set(action.payload.attachment.uuid, {
                localFileUrl: action.payload.attachment.localFileUrl,
                contentType: action.payload.attachment.contentType
            });
            return Object.assign({}, state, {attachmentsToBeUploaded});
        }
        case FILES_DOWNLOAD_PENDING: {
            let attachmentsToBeDownloaded = new Map(state.attachmentsToBeDownloaded);
            attachmentsToBeDownloaded.set(action.payload.attachment.uuid, {
                name: action.payload.attachment.name,
                problemId: action.payload.attachment.problemId
            });
            return Object.assign({}, state, {attachmentsToBeDownloaded});
        }
        case FILE_UPLOAD_STATUS: {
            const {uuid, status} = action.payload;
            let uploading = new Map(state.uploading);
            let attachmentsToBeUploaded = new Map(state.attachmentsToBeUploaded);
            if (status === 'DONE') {
                uploading.delete(uuid);
                attachmentsToBeUploaded.delete(uuid);
            } else {
                uploading.set(uuid, 'IN PROGRESS');
            }
            return Object.assign({}, state, {uploading, attachmentsToBeUploaded});
        }
        default: {
            return state;
        }
    }
};
