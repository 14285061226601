import Joi from 'joi';

export function validateEscalationSchema(escalationSchema) {
    return Joi.validate(
        escalationSchema,
        Joi.array().required().items(
            Joi.object().keys({
                id: Joi.string().required().guid({version: ['uuidv4']}),
                name: Joi.string().required(),
                users: Joi.array().required().items(
                    Joi.object().keys(getUserSchema())
                )
            })
        )
    );
}

export function validateEscalationSchemaCreateEvent(escalationSchemaCreateEvent) {
    return Joi.validate(
        escalationSchemaCreateEvent,
        Joi.object().required().keys({
            id: Joi.string().required().guid({version: ['uuidv4']}),
            name: Joi.string().required(),
            escalationSchema: Joi.object().required(),
        })
    );
}

export function validateEscalationSchemaUpdateEvent(escalationSchemaUpdateEvent) {
    return Joi.validate(
        escalationSchemaUpdateEvent,
        Joi.object().required().keys({
            id: Joi.string().required().guid({version: ['uuidv4']}),
            name: Joi.string().required(),
            escalationSchema: Joi.object().required(),
        })
    );
}

function getUserSchema() {
    return {
        ipn: Joi.string().required(),
        name: Joi.string().required()
    };
}
