import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';

const StyledDiv = styled.div`
    border-radius: 5px;
`;

const SectionWrapper = ({children, label}) => (
    <>
        <h3 className="text-left mt-4 ml-4">
            <FormattedMessage {...label}/>
        </h3>
        <StyledDiv className="border border-secondary px-4 py-2 bg-white">
            {children}
        </StyledDiv>
    </>
);

export default SectionWrapper;
