import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import get from 'get-value';
import {
    TECH_DOC_RECURRENT_PROBLEM_PENDING,
    TECH_DOC_RECURRENT_PROBLEM_SELECT_GROUP
} from '../../actions/techDoc/actionTechDocRecurrentProblem';

export const techDocRecurrentProblem = (state = {
    isLoading: false,
    recurrentProblems: [],
    selectedCountryGroup: '',
}, action) => {
    switch (action.type) {
        case TECH_DOC_RECURRENT_PROBLEM_PENDING: {
            return Object.assign({}, state, {isLoading: true, recurrentProblems: []});
        }
        case techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                recurrentProblems: get(action, 'payload.recurrentProblems', {default: []}),
                correlationId
            });
        }
        case techDocWebsocketCommands.TECH_DOC_RECURRENT_PROBLEMS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, recurrentProblems: [], correlationId});
        }
        case TECH_DOC_RECURRENT_PROBLEM_SELECT_GROUP: {
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        }
        default:
            return state;
    }
};
