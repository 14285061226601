import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from "react-intl";
import {
    FORM_FIELD_SUPPLEMENTARY_INFORMATION,
    FORM_LABEL_SUPPLEMENTARY_INFORMATION,
} from "../../../../../../constants/formConstants";
import {TextareaFieldWithValidation} from "../../../../../common/TextareaFieldWithValidation";
import formMessages from "../../../../../../intl/technicalDocumentations/formMessages";

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const SupplementaryInformationOvalPlateSection = (props) => {
    const { readOnly, userRights, reqFields } = props;
    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.SUPPLEMENTARY_INFORMATION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-sm-12 pr-0 pb-2">
                        <TextareaFieldWithValidation label={formMessages[FORM_LABEL_SUPPLEMENTARY_INFORMATION]}
                                                     field={FORM_FIELD_SUPPLEMENTARY_INFORMATION} rows={6}
                                                     isDisabled={!userRights.canEditSuppInformationsText || readOnly}
                                                     isRequired={reqFields.includes(FORM_FIELD_SUPPLEMENTARY_INFORMATION)}
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )

}

export default SupplementaryInformationOvalPlateSection;
