import React from 'react';
import {formValueSelector, getFormInitialValues, getFormValues, reduxForm} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {
    ALLOCATION_REQUEST_FORM,
    BUTTON_LABEL_CLOSE,
    BUTTON_LABEL_CREATE_DIC,
    BUTTON_LABEL_FORWARD_TO_DEALER,
    BUTTON_LABEL_FORWARD_TO_NPDC,
    BUTTON_LABEL_MARK_AS_READ,
    BUTTON_LABEL_REOPEN,
    BUTTON_LABEL_SAVE,
    BUTTON_LABEL_SWITCH_TO_PVI,
    BUTTON_LABEL_TAKE_BACK,
    BUTTON_LABEL_UPDATE_DIC,
    FORM_BUTTON_CLOSE,
    FORM_BUTTON_CREATE_DIC,
    FORM_BUTTON_FORWARD_TO_DEALER,
    FORM_BUTTON_FORWARD_TO_NPDC,
    FORM_BUTTON_MARK_AS_READ,
    FORM_BUTTON_REOPEN,
    FORM_BUTTON_SAVE,
    FORM_BUTTON_SWITCH_TO_PVI,
    FORM_BUTTON_TAKE_BACK,
    FORM_BUTTON_UPDATE_DIC,
    FORM_FIELDS_ORDER_ITEMS,
    FORM_ORDERS_SECTION,
    FORM_TICKET_SECTION,
    ORDER_TICKET_STATUS_IN_PROGRESS_DEALER,
    ORDER_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    ORDER_TICKET_STATUS_READ_BY_SUBSIDIARY,
    ORDER_TICKET_STATUS_REOPENED,
    ORDER_TICKET_STATUS_CLOSED,
    FORM_BUTTON_ANSWER,
    FORM_BUTTON_ESCALATE,
    BUTTON_LABEL_ESCALATE,
    ORDER_TICKET_STATUS_TRANSFERRED,
    BUTTON_LABEL_ANSWER,
    FORM_BUTTON_CREATE_INTERNAL_TICKET,
    BUTTON_LABEL_CREATE_INTERNAL_TICKET,
    FORM_BUTTON_EDIT,
    FORM_BUTTON_SG_TAKE_BACK,
    FORM_STATUS_HISTORY_SECTION,
    BUTTON_LABEL_SAVE_AND_SEND,
    BUTTON_LABEL_CANCEL_REQUEST,
    ORDER_CANCELLATION_FORM,
    VOR_PVI_ORDER_FORM,
} from '../../../../../constants/formConstants';
import {PrimaryButton} from '../../../../common/Button';
import {moduleRoles} from '../../../../../utils/roles';
import buttonMessages from '../../../../../intl/common/buttonMessages';
import commonMessages from '../../../../../intl/common/commonMessages';
import {withRouter} from 'react-router-dom';
import {RequiredSpan} from '../../../../common/StyledComponents';
import get from 'get-value';
import {validateCreateDic, validateUpdateDic} from '../../../../common/validationCommons';
import {addDicValidationMessages, createDic, updateDic} from '../../../../../actions/orders/actionSparePartsDicData';
import {formatTicketNumber, scrollContentWrapperToTop} from '../../../../../utils/utils';
import ValidFormattedMessage from '../../../../common/ValidFormattedMessage';
import {hasSelectedAnyNewOrdersForTransferToDic, hasSelectedAnyOrdersForTransferToDic} from '../../../Utils';
import {throttle} from 'throttle-debounce';
import {countryGroups, domains} from '../../../../../constants/Utils';
import {editOrderListButtonVisibility} from '../../../../../constants/sparePartsTicketRights';
import {submitBy} from '../../../../../actions/orders/actionTicket';

const SubmitButtons = (props) => {
    const {change, dirty, handleSubmit, submitting, initialValues, readOnly, userRights, roles, orders, form, dicInfo,
        multiSourcingGroups, formValues, addDicValidationMessages, createDic, updateDic, createdInDic, processingDic,
        userIpn, dealerNumber, escalationSGs, answeringSGs, canForwardToDealerFromSG, ticketSG, editMode, ticket, ticketHistory = [], intlLanguage} = props;

    const sgPermissions = get(roles, `${domains.SPARE_PARTS}.sgEditor.sgPermission`, {default: []});

    const getTakeBackSgList = () => {
        return sgPermissions.filter(sg => sg.isActive).map(item => ({key: item.id, value: item.name})).filter(sg => ticketHistory.some(historySg => get(historySg, "toSolutionGroup.id") === sg.key))
    }

    const filteredAnsweringSGs = (answeringSGs || []).filter(sg => ticketHistory.some(historySg => get(historySg, "toSolutionGroup.id") === sg.id));
    const [selectedGroupForEscalate, setSelectedGroupForEscalate] = React.useState(escalationSGs && escalationSGs.length && escalationSGs[0].id);
    const [selectedGroupForAnswer, setSelectedGroupForAnswer] = React.useState(filteredAnsweringSGs && filteredAnsweringSGs.length && filteredAnsweringSGs[0].id);
    const [selectedTakeBackGroup, setSelectedTakeBackGroup] = React.useState(sgPermissions && ticketHistory && sgPermissions.length && ticketHistory.length && getTakeBackSgList().length && getTakeBackSgList()[0].key);

    const ticketStatus = get(initialValues, 'ticketSection.status');
    const statusClosed = ticketStatus === ORDER_TICKET_STATUS_CLOSED;
    const ticketGroup = get(ticket, 'group');
    const defaultSG = ticketGroup ? moduleRoles.isSGDefaultEditorForGroup(roles, domains.SPARE_PARTS, ticketGroup) : false;

    const hasSomeEscalationSG = Array.isArray(escalationSGs) && escalationSGs.length;

    const canForwardToDealer = typeof canForwardToDealerFromSG === 'boolean' ? canForwardToDealerFromSG : userRights.canForwardToDealer;
    const canClose = typeof canForwardToDealerFromSG === 'boolean' ? (canForwardToDealerFromSG && userRights.canClose) : userRights.canClose;
    const canTipOverToPvi = typeof canForwardToDealerFromSG === 'boolean' ? (canForwardToDealerFromSG && userRights.canTipOverToPvi) : userRights.canTipOverToPvi;
    const canForwardBackToSubs = userRights.canForwardBackToSubs
    const canReopen = moduleRoles.isSGEditorOrder(roles) ? (!!defaultSG && userRights.canReopen) : userRights.canReopen;
    const canEscalateToSG = userRights.canEscalate && hasSomeEscalationSG;
    const canAnswerToSG = userRights.canAnswer && filteredAnsweringSGs && !!filteredAnsweringSGs.length;
    const canForwardToSubs =  userRights.canForwardToSubs && !(moduleRoles.isDealerOrder(roles) && ticketSG);
    const canCreateInternalTicket = editMode && moduleRoles.isSGEditorOrder(roles) && ticketSG;
    const isNLevelFlow = Array.isArray(ticketHistory) && ticketHistory.some(h => h.solutionGroup || h.toSolutionGroup)

    const renderEditButton = () => {
        return (
            <PrimaryButton onClick={
                handleSubmit(values =>
                    props.onSubmit({
                        submitBy: FORM_BUTTON_EDIT,
                    }))
            } className="btn mr-2 float-right">
                <FormattedMessage {...buttonMessages.EDIT}/>
            </PrimaryButton>
        )
    };

    const renderBackButton = () => {
        return (
            <PrimaryButton onClick={(e) => {
                e.preventDefault();
                props.history.goBack();
            }} className="btn mr-2 float-right">
                <FormattedMessage {...buttonMessages.BACK}/>
            </PrimaryButton>
        )
    };

    const renderCreateDicButton = (button, message) => {
        return (
            <PrimaryButton onClick={
                (e) => {
                    e.preventDefault();
                    const validations = validateCreateDic(
                        formValues.vehicleDetail.vin,
                        orders.filter(order => order.transferToDic).map(({partNo, quantity, licProd, licOrd, licOAn, licLig, licIAn}) => ({
                            partNo,
                            quantity,
                            mprLine: `${licProd ? licProd : ''}${licOrd ? licOrd : ''}${licOAn ? licOAn : ''}${licLig ? licLig : ''}${licIAn ? licIAn : ''}`
                        })),
                        formValues.ticketSection.destinationCode,
                        formValues.ticketSection.dealerNumber,
                        formValues.ticketSection.dealerName,
                        formValues.ticketSection.group,
                        formValues.vehicleDetail.repairOrderNo,
                        formValues.vehicleDetail.repairedCarLocation,
                        formValues.vehicleDetail.registrationNumber,
                        multiSourcingGroups.includes(initialValues[FORM_TICKET_SECTION].group));
                    if (validations.length > 0) {
                        addDicValidationMessages(validations);
                        scrollContentWrapperToTop();
                    } else {
                        addDicValidationMessages(validations);
                        createDic({
                            uuid: formValues.ticketSection.uuid,
                            dicData: {
                                vin: formValues.vehicleDetail.vin, //1
                                htlNumber: formatTicketNumber(formValues.ticketSection.pfxNumber, formValues.ticketSection.sfxNumber), //1 + dicNumber + díly
                                customerNumber: formValues.ticketSection.destinationCode,
                                dealerNumber: formValues.ticketSection.dealerNumber,
                                dealerName: formValues.ticketSection.dealerName,
                                dealerCountry: formValues.ticketSection.group,
                                jobNo: formValues.vehicleDetail.repairOrderNo,
                                utility: '',
                                immobilizationLocation: formValues.vehicleDetail.repairedCarLocation,
                                comments: formatTicketNumber(formValues.ticketSection.pfxNumber, formValues.ticketSection.sfxNumber),
                                creatorIpn: userIpn,
                                orderParts: orders.filter(order => order.transferToDic).map(({uuid, partNo, quantity, licProd, licOrd, licOAn, licLig, licIAn}) => ({
                                    uuid,
                                    partNo,
                                    quantity,
                                    mprLine: `${licProd ? licProd : ''}${licOrd ? licOrd : ''}${licOAn ? licOAn : ''}${licLig ? licLig : ''}${licIAn ? licIAn : ''}`
                                })),
                                regNumber: formValues.vehicleDetail.registrationNumber
                            }
                        });
                    }
                }
            } className="btn mr-2 float-right" disabled={submitting || processingDic}>
                <ValidFormattedMessage message={message} intlMessages={buttonMessages}/>
            </PrimaryButton>
        )
    };

    const renderUpdateDicButton = (button, message) => {
        const newOrdersToDic = orders.filter((order, index) => order.transferToDic && order.transferToDic !== get(initialValues, `ordersSection.orderItems.${index}.transferToDic`, {default: false}))
        return (
            <PrimaryButton onClick={
                (e) => {
                    e.preventDefault();
                    const validations = validateUpdateDic(
                        formValues.vehicleDetail.vin,
                        newOrdersToDic.map(({partNo, quantity, licProd, licOrd, licOAn, licLig, licIAn}) => ({
                            partNo,
                            quantity,
                            mprLine: `${licProd ? licProd : ''}${licOrd ? licOrd : ''}${licOAn ? licOAn : ''}${licLig ? licLig : ''}${licIAn ? licIAn : ''}`
                        })),
                        formValues.ordersSection.dicNumber,
                        multiSourcingGroups.includes(initialValues[FORM_TICKET_SECTION].group));
                    if (validations.length > 0) {
                        addDicValidationMessages(validations);
                        scrollContentWrapperToTop();
                    } else {
                        addDicValidationMessages(validations);
                        updateDic({
                            uuid: formValues.ticketSection.uuid,
                            dicData: {
                                vin: formValues.vehicleDetail.vin,
                                htlNumber: formatTicketNumber(formValues.ticketSection.pfxNumber, formValues.ticketSection.sfxNumber),
                                dicNumber: formValues.ordersSection.dicNumber, //1
                                orderParts: newOrdersToDic.map(({uuid, partNo, quantity, licProd, licOrd, licOAn, licLig, licIAn}) => ({
                                    uuid,
                                    partNo,
                                    quantity,
                                    mprLine: `${licProd ? licProd : ''}${licOrd ? licOrd : ''}${licOAn ? licOAn : ''}${licLig ? licLig : ''}${licIAn ? licIAn : ''}`
                                })),
                            }
                        });
                    }
                }
            } className="btn mr-2 float-right" disabled={submitting || processingDic}>
                <ValidFormattedMessage message={message} intlMessages={buttonMessages}/>
            </PrimaryButton>
        )
    };

    const renderButton = (button, message, changeToStatus, optionalChangeToSolutionGroup) => {
        return (
            <PrimaryButton onClick={throttle(1000, async (e) => {
                e.preventDefault();
                //Unfortunately haven't found any more elegant way to omit validation on MARK_AS_READ button click, this value is checked in REQUIRED validation
                if (button === FORM_BUTTON_MARK_AS_READ) {
                    change('omitValidation', true);
                    await (interval => new Promise(resolve => setTimeout(resolve, interval)))(75);
                }

                await props.submitBy({submittedBy: button})

                handleSubmit(values =>
                    props.onSubmit({
                        // newValues: {...values, ticketSection: {...values.ticketSection, status: changeTo}},
                        newValues: {
                            ...values,
                            ticketSection: {
                                ...values.ticketSection,
                                status: changeToStatus,
                                ...(button === FORM_BUTTON_ANSWER) && {
                                    solutionGroup: {
                                        id: selectedGroupForAnswer,
                                        name: answeringSGs.find(item => selectedGroupForAnswer === item.id).name
                                    }
                                },
                                ...(button === FORM_BUTTON_ESCALATE) && {
                                    solutionGroup: {
                                        id: selectedGroupForEscalate,
                                        name: escalationSGs.find(item => selectedGroupForEscalate === item.id).name
                                    }
                                },
                                ...(button === FORM_BUTTON_SG_TAKE_BACK) && {
                                    solutionGroup: {
                                        id: optionalChangeToSolutionGroup.id,
                                        name: optionalChangeToSolutionGroup.name
                                    }
                                },
                            }
                        },
                        oldValues: initialValues,
                        submitBy: button,
                    }))()
            })
            } className="btn mr-2 float-right"
                           disabled={submitting || (!dirty && button === FORM_BUTTON_SAVE) || processingDic}>
                <ValidFormattedMessage message={message} intlMessages={buttonMessages}/>
            </PrimaryButton>
        )
    };

    const renderSelectButton = (button, message, changeToStatus, availableOptions, value, setValue) => {
        const moveLeft = {marginLeft: -10, marginRight: 10};
        const selectedSg = {id: value, name: get(availableOptions.find(sg => sg.key === value), "value")}
        return (
            <>
                <div className="input-group-prepend">
                    {renderButton(button, message, changeToStatus, selectedSg)}
                </div>
                <select className="custom-select" value={value} onChange={e => setValue(e.target.value)} style={moveLeft}>
                    {availableOptions.map((item) => <option key={item.key} value={item.key}>{item.value}</option>)}
                </select>
            </>
        )
    };

    const renderEscalateButton = () => {
        const value = selectedGroupForEscalate;
        const setValue = setSelectedGroupForEscalate;
        const availableOptions = escalationSGs.map(item => ({key: item.id, value: item.name}));
        return renderSelectButton(FORM_BUTTON_ESCALATE, BUTTON_LABEL_ESCALATE, ORDER_TICKET_STATUS_TRANSFERRED, availableOptions, value, setValue);
    };

    const renderAnswerButton = () => {
        const value = selectedGroupForAnswer;
        const setValue = setSelectedGroupForAnswer;
        const availableOptions = filteredAnsweringSGs.map(item => ({key: item.id, value: item.name}));
        return renderSelectButton(FORM_BUTTON_ANSWER, BUTTON_LABEL_ANSWER, ORDER_TICKET_STATUS_TRANSFERRED, availableOptions, value, setValue);
    };

    const renderTakeBackButton = (button, message, changeToStatus) => {
        const value = selectedTakeBackGroup;
        const setValue = setSelectedTakeBackGroup;
        const availableOptions = getTakeBackSgList()
        return renderSelectButton(button, message, changeToStatus, availableOptions, value, setValue);
    };

    const renderReopenButton = () => {
        return renderButton(FORM_BUTTON_REOPEN, BUTTON_LABEL_REOPEN, ORDER_TICKET_STATUS_REOPENED);
    };

    const renderCreateInternalTicketButton = (status) => {
        return renderButton(FORM_BUTTON_CREATE_INTERNAL_TICKET, BUTTON_LABEL_CREATE_INTERNAL_TICKET, status);
    };

    const ticketInNonEditableState = () => {
        const buttonVisibility = editOrderListButtonVisibility[moduleRoles.isDealerOrder(roles) ? 'DEALER' : 'EDITOR'];
        return !buttonVisibility.includes(ticketStatus);
    };

    const canSaveTicket = () => {
        switch (ticketStatus) {
            case ORDER_TICKET_STATUS_READ_BY_SUBSIDIARY:
            case ORDER_TICKET_STATUS_REOPENED:
                return userRights.canSave && moduleRoles.isSGDefaultEditorForGroup(roles, domains.SPARE_PARTS, get(ticket, 'group'));
            case ORDER_TICKET_STATUS_TRANSFERRED:
                return userRights.canSave && moduleRoles.isSGEditorOrder(roles);
            default:
                return userRights.canSave;
        }
    };

    if (readOnly) {
        const ticketBelongsToDealer = moduleRoles.isDealerOrder(roles) && dealerNumber === initialValues.ticketSection.dealerNumber;
        const isEditor = moduleRoles.isEditor(roles) || moduleRoles.isSGEditorOrder(roles);
        const editorGroups = moduleRoles.isEditorOrder(roles) ? moduleRoles.getEditorGroups(domains.SPARE_PARTS, roles).map(e => e.group) : false;
        let canSwitchToEdit = !statusClosed;
        const canSgTakeBack = !statusClosed && ticketSG && get(ticketSG, 'id') !== get(defaultSG, 'id') && !moduleRoles.isSGEditorOrderInSG(roles, get(ticketSG, 'id')) && sgPermissions.length > 0;
        const canDealerTakeBack = !statusClosed && ticketStatus !== ORDER_TICKET_STATUS_IN_PROGRESS_DEALER
            && ticketBelongsToDealer && form === VOR_PVI_ORDER_FORM && get(props, 'location.state.canTakeBack', false);

        if (ticketInNonEditableState()) {
            canSwitchToEdit = false;
        } else if (editorGroups && !sgPermissions && !(editorGroups || []).includes(ticketGroup)){
            canSwitchToEdit = false;
        } else if (moduleRoles.isDealerOrder(roles) && (!ticketBelongsToDealer || ticketSG)){
            canSwitchToEdit = false;
        } else if (moduleRoles.isSGEditorOrder(roles)) {
            if (ticketSG && !moduleRoles.isSGEditorOrderInSG(roles, get(ticketSG, 'id', {default:''}))) {
                // ticket is assigned on group which user is not member of
                canSwitchToEdit = false;
            } else if (!ticketSG && !defaultSG) {
                // ticket can be switched to edit
                canSwitchToEdit = false;
            }
        }

        return (
            <div className="d-flex justify-content-end mt-3">
                <form>
                    <div className={canSgTakeBack ? "input-group" : "form-group"}>
                    {renderBackButton()}
                    {canSwitchToEdit && renderEditButton()}
                    {canSgTakeBack && (isNLevelFlow ?
                            renderTakeBackButton(FORM_BUTTON_SG_TAKE_BACK, BUTTON_LABEL_TAKE_BACK, ORDER_TICKET_STATUS_TRANSFERRED) :
                            renderButton(FORM_BUTTON_TAKE_BACK, BUTTON_LABEL_TAKE_BACK, ORDER_TICKET_STATUS_TRANSFERRED))}
                    {(ticketBelongsToDealer || (isEditor && orders && !orders.find(order => order.replies.length === 0))) && canClose && !statusClosed &&
                        renderButton(FORM_BUTTON_CLOSE, form === ORDER_CANCELLATION_FORM && ticketGroup === (countryGroups.GB || countryGroups.IE) ? BUTTON_LABEL_CANCEL_REQUEST : BUTTON_LABEL_CLOSE, ORDER_TICKET_STATUS_CLOSED)}
                    {(ticketBelongsToDealer || isEditor) && canReopen && statusClosed && renderReopenButton()}
                    {(ticketBelongsToDealer || isEditor) && canTipOverToPvi && form === ALLOCATION_REQUEST_FORM && renderButton(FORM_BUTTON_SWITCH_TO_PVI, BUTTON_LABEL_SWITCH_TO_PVI, ORDER_TICKET_STATUS_CLOSED)}
                    {canDealerTakeBack && renderButton(FORM_BUTTON_TAKE_BACK, BUTTON_LABEL_TAKE_BACK, ORDER_TICKET_STATUS_IN_PROGRESS_DEALER)}
                    </div>
                </form>
            </div>
        )
    }
    return (
        <div className="d-flex justify-content-between mt-3">
            <div><RequiredSpan>{'*\u00A0'}</RequiredSpan><FormattedMessage {...commonMessages.IS_MANDATORY}/></div>
            <form>
                <div className="input-group">
                    {get(initialValues, 'ticketSection.uuid') && renderBackButton()}
                    {canEscalateToSG && renderEscalateButton()}
                    {canAnswerToSG && renderAnswerButton()}
                    {canReopen && statusClosed && renderReopenButton()}
                    {canForwardBackToSubs && (isNLevelFlow ?
                        renderTakeBackButton(FORM_BUTTON_SG_TAKE_BACK, BUTTON_LABEL_TAKE_BACK, ORDER_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY) :
                        renderButton(FORM_BUTTON_TAKE_BACK, BUTTON_LABEL_TAKE_BACK, ORDER_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY))}
                    {canClose && (moduleRoles.isDealerOrder(roles) || (orders && !orders.find(order => order.replies.length === 0))) && !statusClosed && renderButton(FORM_BUTTON_CLOSE, BUTTON_LABEL_CLOSE, ORDER_TICKET_STATUS_CLOSED)}
                    {canSaveTicket() && renderButton(FORM_BUTTON_SAVE, !editMode && intlLanguage === 'it' ? BUTTON_LABEL_SAVE_AND_SEND : BUTTON_LABEL_SAVE, ticketStatus)}
                    {canForwardToSubs && renderButton(FORM_BUTTON_FORWARD_TO_NPDC, BUTTON_LABEL_FORWARD_TO_NPDC, ORDER_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY)}
                    {canForwardToDealer && renderButton(FORM_BUTTON_FORWARD_TO_DEALER, BUTTON_LABEL_FORWARD_TO_DEALER, ORDER_TICKET_STATUS_IN_PROGRESS_DEALER)}
                    {userRights.canChangeToReadBySubs && renderButton(FORM_BUTTON_MARK_AS_READ, BUTTON_LABEL_MARK_AS_READ, ORDER_TICKET_STATUS_READ_BY_SUBSIDIARY)}
                    {/*{canCreateInternalTicket && renderCreateInternalTicketButton(ticketStatus)}*/}
                    {userRights.canContactDic && dicInfo.dicVinStatusLoaded && dicInfo.canCreateDic && !createdInDic && hasSelectedAnyOrdersForTransferToDic(orders) && renderCreateDicButton(FORM_BUTTON_CREATE_DIC, BUTTON_LABEL_CREATE_DIC)}
                    {userRights.canContactDic && dicInfo.dicVinStatusLoaded && dicInfo.canCreateDic && createdInDic && hasSelectedAnyNewOrdersForTransferToDic(get(initialValues, 'ordersSection.orderItems', {default: []}), orders) && renderUpdateDicButton(FORM_BUTTON_UPDATE_DIC, BUTTON_LABEL_UPDATE_DIC)}
                </div>
            </form>
        </div>
    )
};

SubmitButtons.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    addDicValidationMessages: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    dicInfo: PropTypes.object.isRequired,
    multiSourcingGroups: PropTypes.array.isRequired,
    formValues: PropTypes.object.isRequired,
    pristine: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    userRights: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
    userIpn: PropTypes.string.isRequired,
    escalationSGs: PropTypes.any,
    answeringSGs: PropTypes.any,
    canForwardToDealerFromSG: PropTypes.any,
    ticketSG: PropTypes.any,
    ticket: PropTypes.any,
};

function getSolutionGroups(state, schemaType) {
    if (!state.solutionGroupInfo || !state.solutionGroupInfo[schemaType]) {
        return undefined;
    }
    const formType = get(state, 'ticket.orderType');
    return state.solutionGroupInfo[schemaType][formType]
}

const mapStateToProps = (state, initialProps) => ({
    ticketHistory: formValueSelector(initialProps.form)(state, `${FORM_STATUS_HISTORY_SECTION}`),
    orders: formValueSelector(initialProps.form)(state, `${FORM_ORDERS_SECTION}.${FORM_FIELDS_ORDER_ITEMS}`),
    formValues: getFormValues(initialProps.form)(state),
    roles: state.profile.userDetail.roles,
    userIpn: state.profile.userDetail.ipn,
    dicInfo: state.sparePartsDic,
    initialValues: getFormInitialValues(initialProps.form)(state),
    multiSourcingGroups: state.sparePartsOrderMultiSourcingGroups.multiSourcingGroups,
    createdInDic: state.sparePartsOrderTicket.ticket.createdInDic,
    processingDic: state.sparePartsDic.isCreateOrUpdateDicLoading,
    dealerNumber: state.profile.userDetail.dealerNumber,
    escalationSGs: getSolutionGroups(state.sparePartsOrderTicket, 'escalationSchemas'),
    answeringSGs: getSolutionGroups(state.sparePartsOrderTicket, 'answeringSchemas'),
    canForwardToDealerFromSG: get(state, 'sparePartsOrderTicket.solutionGroupInfo.canForwardToDealer'),
    ticketSG: get(state, 'sparePartsOrderTicket.ticket.solutionGroup'),
    ticket: get(state, 'sparePartsOrderTicket.ticket'),
    intlLanguage: get(state, 'intl.language')
});


export default reduxForm({})(withRouter(connect(mapStateToProps, {
    addDicValidationMessages,
    createDic,
    updateDic,
    submitBy
})(SubmitButtons)));
