import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import VerticalTree from '../../common/tree/VerticalTree';
import {transformSolutionGroupsToFlowTree} from '../../../utils/transformer/flowTreeNodeTransformer';

const FlowTreeNode = ({flowDetailForm: {values: {items: selectedSolutionGroups = []}}}) => (
    <VerticalTree data={transformSolutionGroupsToFlowTree(selectedSolutionGroups)}/>
);

FlowTreeNode.propTypes = {
    flowDetailForm: PropTypes.object
};

const mapStateToProps = state => ({
    flowDetailForm: state.form.flowDetailForm
});

export default connect(mapStateToProps, {})(FlowTreeNode);
