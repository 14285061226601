import {
    SPARE_PARTS_ORDERS_ATTACHMENTS_SAVED,
    SPARE_PARTS_ORDERS_ATTACHMENTS_SAVED_ERROR,
    SPARE_PARTS_ORDERS_GENERATED_PRESIGNED_URLS,
} from '../../constants/websocketCommands';
import {
    SPARE_PARTS_ORDERS_ATTACHMENTS_UPLOADING,
    SPARE_PARTS_ORDERS_ATTACHMENTS_UPLOADING_ERROR
} from "../../actions/orders/actionAttachments";
import {SPARE_PARTS_RESET} from "../../actions/orders/actionTicket";

export const sparePartsOrderAttachment = (state = {
    correlationId: null,
    isTicketAttachmentUploading: false,
    attachmentsForUpload: [],
}, action) => {
    switch (action.type) {
        case SPARE_PARTS_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
            });
        }
        case SPARE_PARTS_ORDERS_GENERATED_PRESIGNED_URLS: {
            const { attachments } = action.payload;
            return Object.assign({}, state, {
                attachmentsForUpload: Array.isArray(attachments) ? attachments : [],
            });
        }
        case SPARE_PARTS_ORDERS_ATTACHMENTS_UPLOADING: {
            return Object.assign({}, state, { isTicketAttachmentUploading: true });
        }
        //att done + error
        case SPARE_PARTS_ORDERS_ATTACHMENTS_SAVED_ERROR:
        case SPARE_PARTS_ORDERS_ATTACHMENTS_UPLOADING_ERROR: {
            return Object.assign({}, state, {
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
            });
        }
        case SPARE_PARTS_ORDERS_ATTACHMENTS_SAVED: {
            const { correlationId } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isTicketAttachmentUploading: false,
                attachmentsForUpload: [],
            });
        }
        default:
            return state;
    }
};
