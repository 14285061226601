// ------------- spare parts orders -------------
// route (commands)
export const SPARE_PARTS_ORDERS_CREATE_ATTACHMENTS = 'createAttachments';
export const SPARE_PARTS_ORDERS_UPDATE_TICKET = 'updateSparePartTicket';
export const SPARE_PARTS_ORDERS_CREATE_TICKET = 'createSparePartTicket';
export const SPARE_PARTS_ORDERS_DOWNLOAD_PRESIGNED_URL = 'attachmentDownloadPreSignedUrl';
export const SPARE_PARTS_ORDERS_SET_LOCK = 'setLockTicket';
export const SPARE_PARTS_ORDERS_SET_UNLOCK = 'setUnlockTicket';
export const SPARE_PARTS_ORDERS_GET_TICKET = 'getTicket';
export const SPARE_PARTS_ORDERS_GET_STATISTICS = 'getStatistics';
// websocket receive (commands)
export const SPARE_PARTS_ORDERS_TICKET_SEND = 'SPARE_PARTS_ORDERS_TICKET_SEND';
export const SPARE_PARTS_ORDERS_TICKET_CREATED = 'SPARE_PARTS_ORDERS_TICKET_CREATED';
export const SPARE_PARTS_ORDERS_TICKET_UPDATED = 'SPARE_PARTS_ORDERS_TICKET_UPDATED';
export const SPARE_PARTS_ORDERS_TICKET_LOCKED = 'SPARE_PARTS_ORDERS_TICKET_LOCKED';
export const SPARE_PARTS_ORDERS_TICKET_LOCKED_ERROR = 'SPARE_PARTS_ORDERS_TICKET_LOCKED_ERROR';
export const SPARE_PARTS_ORDERS_TICKET_LOCKED_FATAL = 'SPARE_PARTS_ORDERS_TICKET_LOCKED_FATAL';
export const SPARE_PARTS_ORDERS_GENERATED_PRESIGNED_URLS = 'SPARE_PARTS_ORDERS_GENERATED_PRESIGNED_URLS';
export const SPARE_PARTS_ORDERS_ATTACHMENTS_SAVED = 'SPARE_PARTS_ORDERS_ATTACHMENTS_SAVED';
export const SPARE_PARTS_ORDERS_ATTACHMENTS_SAVED_ERROR = 'SPARE_PARTS_ORDERS_ATTACHMENTS_SAVED_ERROR';
export const SPARE_PARTS_ORDERS_ATTACHMENTS_DOWNLOAD = 'SPARE_PARTS_ORDERS_ATTACHMENTS_DOWNLOAD';
export const SPARE_PARTS_ORDERS_STATISTICS_SEND = 'SPARE_PARTS_ORDERS_STATISTICS_SEND';
export const SPARE_PARTS_ORDERS_STATISTICS_SEND_ERROR = 'SPARE_PARTS_ORDERS_STATISTICS_SEND_ERROR';

// route (commands)
export const SPARE_PARTS_INTERNAL_CREATE_ATTACHMENTS = 'createInternalAttachments';
export const SPARE_PARTS_INTERNAL_UPDATE_TICKET = 'updateInternalTicket';
export const SPARE_PARTS_INTERNAL_CREATE_TICKET = 'createInternalTicket';
export const SPARE_PARTS_INTERNAL_DOWNLOAD_PRESIGNED_URL = 'internalAttachmentDownloadPreSignedUrl';
export const SPARE_PARTS_INTERNAL_SET_LOCK = 'setLockInternalTicket';
export const SPARE_PARTS_INTERNAL_SET_UNLOCK = 'setUnlockInternalTicket';
export const SPARE_PARTS_INTERNAL_GET_TICKET = 'getInternalTicket';
export const SPARE_PARTS_INTERNAL_GET_SG_INFO = 'getSolutionGroupInfo';

// websocket receive (commands)
export const SPARE_PARTS_INTERNAL_TICKET_SEND = 'SPARE_PARTS_INTERNAL_TICKET_SEND';
export const SPARE_PARTS_INTERNAL_TICKET_SG_INFO_SEND = 'SPARE_PARTS_INTERNAL_TICKET_SG_INFO_SEND';
export const SPARE_PARTS_INTERNAL_TICKET_SG_INFO_ERROR = 'SPARE_PARTS_INTERNAL_TICKET_SG_INFO_ERROR';
export const SPARE_PARTS_INTERNAL_TICKET_CREATED = 'SPARE_PARTS_INTERNAL_TICKET_CREATED';
export const SPARE_PARTS_INTERNAL_TICKET_UPDATED = 'SPARE_PARTS_INTERNAL_TICKET_UPDATED';
export const SPARE_PARTS_INTERNAL_TICKET_LOCKED = 'SPARE_PARTS_INTERNAL_TICKET_LOCKED';
export const SPARE_PARTS_INTERNAL_TICKET_LOCKED_ERROR = 'SPARE_PARTS_INTERNAL_TICKET_LOCKED_ERROR';
export const SPARE_PARTS_INTERNAL_TICKET_LOCKED_FATAL = 'SPARE_PARTS_INTERNAL_TICKET_LOCKED_FATAL';
export const SPARE_PARTS_INTERNAL_GENERATED_PRESIGNED_URLS = 'SPARE_PARTS_INTERNAL_GENERATED_PRESIGNED_URLS';
export const SPARE_PARTS_INTERNAL_TICKET_ATTACHMENTS_SAVED = 'SPARE_PARTS_INTERNAL_TICKET_ATTACHMENTS_SAVED';
export const SPARE_PARTS_INTERNAL_TICKET_ATTACHMENTS_SAVED_ERROR = 'SPARE_PARTS_INTERNAL_TICKET_ATTACHMENTS_SAVED_ERROR';
export const SPARE_PARTS_INTERNAL_TICKET_ATTACHMENTS_DOWNLOAD = 'SPARE_PARTS_INTERNAL_TICKET_ATTACHMENTS_DOWNLOAD';


// ------------- tech doc -------------
// route (commands)
export const TECH_DOC_CREATE_ATTACHMENTS = 'createAttachments';
export const TECH_DOC_UPDATE_TICKET = 'updateTechDocTicket';
export const TECH_DOC_CREATE_TICKET = 'createTechDocTicket';
export const TECH_DOC_DOWNLOAD_PRESIGNED_URL = 'attachmentDownloadPreSignedUrl';
export const TECH_DOC_SET_LOCK = 'setLockTicket';
export const TECH_DOC_SET_UNLOCK = 'setUnlockTicket';
export const TECH_DOC_GET_TICKET = 'getTicket';
export const TECH_DOC_GET_STATISTICS = 'getStatistics';
// websocket receive (commands)
export const TECH_DOC_TICKET_SEND = 'TECH_DOC_TICKET_SEND';
export const TECH_DOC_TICKET_CREATED = 'TECH_DOC_TICKET_CREATED';
export const TECH_DOC_TICKET_UPDATED = 'TECH_DOC_TICKET_UPDATED';
export const TECH_DOC_TICKET_LOCKED = 'TECH_DOC_TICKET_LOCKED';
export const TECH_DOC_TICKET_LOCKED_ERROR = 'TECH_DOC_TICKET_LOCKED_ERROR';
export const TECH_DOC_TICKET_LOCKED_FATAL = 'TECH_DOC_TICKET_LOCKED_FATAL';
export const TECH_DOC_GENERATED_PRESIGNED_URLS = 'TECH_DOC_GENERATED_PRESIGNED_URLS';
export const TECH_DOC_TICKET_ATTACHMENTS_SAVED = 'TECH_DOC_TICKET_ATTACHMENTS_SAVED';
export const TECH_DOC_ATTACHMENTS_SAVED_ERROR = 'TECH_DOC_ATTACHMENTS_SAVED_ERROR';
export const TECH_DOC_ATTACHMENTS_DOWNLOAD = 'TECH_DOC_TICKET_ATTACHMENTS_DOWNLOAD';
export const TECH_DOC_STATISTICS_SEND = 'TECH_DOC_STATISTICS_SEND';
export const TECH_DOC_STATISTICS_SEND_ERROR = 'TECH_DOC_STATISTICS_SEND_ERROR';

// route (commands)
export const TECH_DOC_INTERNAL_CREATE_ATTACHMENTS = 'createInternalAttachments';
export const TECH_DOC_INTERNAL_UPDATE_TICKET = 'updateInternalTicket';
export const TECH_DOC_INTERNAL_CREATE_TICKET = 'createInternalTicket';
export const TECH_DOC_INTERNAL_DOWNLOAD_PRESIGNED_URL = 'internalAttachmentDownloadPreSignedUrl';
export const TECH_DOC_INTERNAL_SET_LOCK = 'setLockInternalTicket';
export const TECH_DOC_INTERNAL_SET_UNLOCK = 'setUnlockInternalTicket';
export const TECH_DOC_INTERNAL_GET_TICKET = 'getInternalTicket';
export const TECH_DOC_INTERNAL_GET_SG_INFO = 'getSolutionGroupInfo';

// websocket receive (commands)
export const TECH_DOC_INTERNAL_TICKET_SEND = 'TECH_DOC_INTERNAL_TICKET_SEND';
export const TECH_DOC_INTERNAL_TICKET_SG_INFO_SEND = 'TECH_DOC_INTERNAL_TICKET_SG_INFO_SEND';
export const TECH_DOC_INTERNAL_TICKET_SG_INFO_ERROR = 'TECH_DOC_INTERNAL_TICKET_SG_INFO_ERROR';
export const TECH_DOC_INTERNAL_TICKET_CREATED = 'TECH_DOC_INTERNAL_TICKET_CREATED';
export const TECH_DOC_INTERNAL_TICKET_UPDATED = 'TECH_DOC_INTERNAL_TICKET_UPDATED';
export const TECH_DOC_INTERNAL_TICKET_LOCKED = 'TECH_DOC_INTERNAL_TICKET_LOCKED';
export const TECH_DOC_INTERNAL_TICKET_LOCKED_ERROR = 'TECH_DOC_INTERNAL_TICKET_LOCKED_ERROR';
export const TECH_DOC_INTERNAL_TICKET_LOCKED_FATAL = 'TECH_DOC_INTERNAL_TICKET_LOCKED_FATAL';
export const TECH_DOC_INTERNAL_GENERATED_PRESIGNED_URLS = 'TECH_DOC_INTERNAL_GENERATED_PRESIGNED_URLS';
export const TECH_DOC_INTERNAL_TICKET_ATTACHMENTS_SAVED = 'TECH_DOC_INTERNAL_TICKET_ATTACHMENTS_SAVED';
export const TECH_DOC_INTERNAL_TICKET_ATTACHMENTS_SAVED_ERROR = 'TECH_DOC_INTERNAL_TICKET_ATTACHMENTS_SAVED_ERROR';
export const TECH_DOC_INTERNAL_TICKET_ATTACHMENTS_DOWNLOAD = 'TECH_DOC_INTERNAL_TICKET_ATTACHMENTS_DOWNLOAD';



// ------------- claims -------------
// route (commands)
export const CLAIMS_CREATE_ATTACHMENTS = 'createAttachments';
export const CLAIMS_UPDATE_TICKET = 'updateClaimsTicket';
export const CLAIMS_CREATE_TICKET = 'createClaimsTicket';
export const CLAIMS_DOWNLOAD_PRESIGNED_URL = 'attachmentDownloadPreSignedUrl';
export const CLAIMS_SET_LOCK = 'setLockTicket';
export const CLAIMS_SET_UNLOCK = 'setUnlockTicket';
export const CLAIMS_GET_TICKET = 'getTicket';
export const CLAIMS_GET_STATISTICS = 'getStatistics';

// websocket receive (commands)
export const CLAIMS_TICKET_SEND = 'CLAIMS_TICKET_SEND';
export const CLAIMS_TICKET_CREATED = 'CLAIMS_TICKET_CREATED';
export const CLAIMS_TICKET_UPDATED = 'CLAIMS_TICKET_UPDATED';
export const CLAIMS_TICKET_LOCKED = 'CLAIMS_TICKET_LOCKED';
export const CLAIMS_TICKET_LOCKED_ERROR = 'CLAIMS_TICKET_LOCKED_ERROR';
export const CLAIMS_TICKET_LOCKED_FATAL = 'CLAIMS_TICKET_LOCKED_FATAL';
export const CLAIMS_GENERATED_PRESIGNED_URLS = 'CLAIMS_GENERATED_PRESIGNED_URLS';
export const CLAIMS_TICKET_ATTACHMENTS_SAVED = 'CLAIMS_TICKET_ATTACHMENTS_SAVED';
export const CLAIMS_ATTACHMENTS_SAVED_ERROR = 'CLAIMS_ATTACHMENTS_SAVED_ERROR';
export const CLAIMS_ATTACHMENTS_DOWNLOAD = 'CLAIMS_TICKET_ATTACHMENTS_DOWNLOAD';
export const CLAIMS_STATISTICS_SEND = 'CLAIMS_STATISTICS_SEND';
export const CLAIMS_STATISTICS_SEND_ERROR = 'CLAIMS_STATISTICS_SEND_ERROR';

