import React, {Component} from 'react';
import styled from 'styled-components';
import MessageForm from './MessageForm';
import {connect} from 'react-redux';
import Loader from '../../../Loader';
import {FormattedDate, FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import get from 'get-value';
import mainPageAlertMessages from "../../../../intl/admin/mainPageAlertMessages";
import {fetchMainMessage, saveMainMessage} from "../../../../actions/admin/actionAdminMainMessage";

const Wrapper = styled.div`
  padding-top: 30px;
`;

const Header = styled.div`
  margin-top: 10px;
  font-style: italic;
`;

class MessageContainer extends Component {
    componentDidMount() {
        this.props.fetchMainMessage(this.props.domain, this.props.selectedGroup);
    }

    save = (values) => {
        const date = new Date();
        const data = {
            text: values.textMainMessage,
            userName: this.props.profile.name,
            updated: date.getTime(),
            group: this.props.selectedGroup,
        };
        this.props.saveMainMessage(this.props.domain, data);
    };

    render() {
        const {isLoading, mainMessage, selectedGroup} = this.props;
        return (
            <Wrapper>
                <h5><FormattedMessage {...mainPageAlertMessages.MAIN_PAGE_MESSAGE}/></h5>
                {isLoading && <Loader/>}
                {!isLoading &&
                <div>
                    {
                        get(mainMessage, 'updated', false) &&
                        <Header>
                            <span className="pr-2">
                                <FormattedMessage {...mainPageAlertMessages.LAST_UPDATE}/>{':'}
                            </span>
                            {mainMessage.userName} - <FormattedDate value={new Date(mainMessage.updated)}/>
                        </Header>
                    }
                    <MessageForm onSubmit={this.save} initialValues={{textMainMessage: mainMessage.text}}
                                 form={`messageForm${selectedGroup}`}/>
                </div>}
            </Wrapper>
        );
    }
}

MessageContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    mainMessage: PropTypes.object.isRequired,
    fetchMainMessage: PropTypes.func.isRequired,
    saveMainMessage: PropTypes.func.isRequired,
    domain: PropTypes.string.isRequired,
    selectedGroup: PropTypes.string.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminMainMessage.isLoading,
    mainMessage: state.adminMainMessage.mainMessage,
    profile: state.profile.userDetail,
});

export default connect(mapStateToProps, {
    fetchMainMessage,
    saveMainMessage,
})(MessageContainer);
