import {
    SPARE_PARTS_ORDERS_ATTACHMENTS_SAVED,
    SPARE_PARTS_ORDERS_ATTACHMENTS_SAVED_ERROR,
    SPARE_PARTS_ORDERS_GET_TICKET,
    SPARE_PARTS_ORDERS_TICKET_CREATED,
    SPARE_PARTS_ORDERS_TICKET_LOCKED,
    SPARE_PARTS_ORDERS_TICKET_LOCKED_ERROR,
    SPARE_PARTS_ORDERS_TICKET_LOCKED_FATAL,
    SPARE_PARTS_ORDERS_TICKET_SEND,
    SPARE_PARTS_ORDERS_TICKET_UPDATED,
} from '../../constants/websocketCommands';
import {
    SPARE_PARTS_RESET,
    SPARE_PARTS_ORDERS_TICKET_CREATE,
    SPARE_PARTS_ORDERS_TICKET_UPDATE, SPARE_PARTS_ORDERS_RESET_BUT_CORRELATION_ID, SPARE_PARTS_ORDERS_TICKET_SUBMIT
} from '../../actions/orders/actionTicket';
import {
    SPARE_PARTS_ORDERS_RESET_REDIRECT,
    SPARE_PARTS_ORDERS_SET_LOCK_TICKET
} from '../../actions/orders/actionLockTicket';
import {SPARE_PARTS_ORDERS_ATTACHMENTS_UPLOADING_ERROR} from '../../actions/orders/actionAttachments';

export const sparePartsOrderTicket = (state = {
    correlationId: null,
    isTicketLoading: false,
    isTicketCreating: false,
    created: false,
    ticket: {},
    orderItems: [],
    notes: [],
    vehicleDetail: {},
    supplementaryInformation: {},
    warehouse: {},
    carRequests: [],
    batteryDetail: {},
    batteryProblemDescription: {},
    logs: [],
    attachments: [],
    newAttachments: [],
    submittedBy: null,
}, action) => {
    switch (action.type) {
        case SPARE_PARTS_RESET: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLoading: false,
                isTicketCreating: false,
                created: false,
                ticket: {},
                orderItems: [],
                notes: [],
                vehicleDetail: {},
                supplementaryInformation: {},
                warehouse: {},
                carRequests: [],
                batteryDetail: {},
                batteryProblemDescription: {},
                logs: [],
                attachments: [],
                newAttachments: [],
                submittedBy: null,
            });
        }
        case SPARE_PARTS_ORDERS_TICKET_SUBMIT: {
            const { submittedBy } = action.payload;
            return Object.assign({}, state, {
                submittedBy
            });
        }
        case SPARE_PARTS_ORDERS_RESET_BUT_CORRELATION_ID: {
            return Object.assign({}, state, {
                correlationId: JSON.parse(JSON.stringify(new Date())),
                isTicketLoading: false,
                isTicketCreating: false,
                created: false,
                ticket: {},
                orderItems: [],
                notes: [],
                vehicleDetail: {},
                supplementaryInformation: {},
                warehouse: {},
                carRequests: [],
                batteryDetail: {},
                batteryProblemDescription: {},
                logs: [],
                attachments: [],
                newAttachments: [],
            });
        }
        case SPARE_PARTS_ORDERS_TICKET_UPDATE:
        case SPARE_PARTS_ORDERS_TICKET_CREATE: {
            const {newAttachments} = action.payload;
            return Object.assign({}, state, {
                newAttachments,
                isTicketCreating: true,
                created: false,
                isTicketLoading: true,
                orderItems: [],
                notes: [],
                vehicleDetail: {},
                supplementaryInformation: {},
                warehouse: {},
                carRequests: [],
                batteryDetail: {},
                batteryProblemDescription: {},
                logs: [],
                attachments: [],
            });
        }
        //command accepted
        case SPARE_PARTS_ORDERS_TICKET_CREATED:
        case SPARE_PARTS_ORDERS_TICKET_UPDATED:
        case SPARE_PARTS_ORDERS_GET_TICKET: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }

            return Object.assign({}, state, {
                isTicketCreating: false,
                created: true,
                isTicketLoading: true,
                correlationId
            });
        }
        case SPARE_PARTS_ORDERS_TICKET_SEND: {
            const {ticket, orderItems, notes, vehicleDetail, supplementaryInformation, warehouse, carRequests, logs, error, correlationId, attachments, batteryDetail, batteryProblemDescription, solutionGroupInfo, editorsNotes, internalTicketReferences } = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            if (error) {
                return Object.assign({}, state, {isTicketLoading: false, correlationId});
            }
            return Object.assign({}, state, {
                isTicketLoading: false,
                correlationId,
                ticket,
                orderItems,
                notes,
                vehicleDetail,
                supplementaryInformation,
                warehouse,
                carRequests,
                batteryDetail,
                batteryProblemDescription,
                logs,
                attachments,
                solutionGroupInfo,
                editorsNotes,
                internalTicketReferences,
            });
        }
        //att done + error
        case SPARE_PARTS_ORDERS_ATTACHMENTS_UPLOADING_ERROR:
        case SPARE_PARTS_ORDERS_ATTACHMENTS_SAVED_ERROR: {
            return Object.assign({}, state, {
                newAttachments: [],
            });
        }
        case SPARE_PARTS_ORDERS_ATTACHMENTS_SAVED: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTicketLoading: true,
                correlationId,
                newAttachments: [],
            });
        }
        default:
            return state;
    }
};

export const sparePartsOrderLockTicketCheck = (state = {
    correlationId: null,
    isTicketLocking: false,
    redirect: false,
}, action) => {
    switch (action.type) {
        case SPARE_PARTS_ORDERS_TICKET_LOCKED_ERROR:
        case SPARE_PARTS_ORDERS_TICKET_LOCKED_FATAL: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId,
                isTicketLocking: false,
                redirect: true,
            });
        }
        case SPARE_PARTS_ORDERS_RESET_REDIRECT: {
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case SPARE_PARTS_ORDERS_TICKET_LOCKED: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                correlationId: null,
                isTicketLocking: false,
                redirect: false,
            });
        }
        case SPARE_PARTS_ORDERS_SET_LOCK_TICKET: {
            const {uuid} = action.payload;
            return Object.assign({}, state, {
                isTicketLocking: true,
                redirect: false,
                uuid,
            });
        }
        default:
            return state;
    }
};
