import React from 'react';
import Dropzone from 'react-dropzone';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import styled from 'styled-components';
import commonMessages from '../../../../../../intl/common/commonMessages';

const Wrapper = styled.div`
  -ms-flex: 0 0 300px;
  flex: 0 0 300px;
  margin: 10px;
`;

const DropZoneField = styled.div`
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 50px;
  display: flex;
  margin: auto;
  border: dashed #ffcc33;
  border-radius: 0.3em;
  :hover {
    background-color: #ffffcc;
  }
`;

class DropZoneForEditorsNote extends React.Component {
    onCancel = () => {
    };

    handleOnDrop = (files) => {
        this.props.onDrop(files);
    };

    render() {
        return (
            <Wrapper>
                <Dropzone onDrop={this.handleOnDrop}
                          onFileDialogCancel={this.onCancel}
                          multiple={!!this.props.multiple}>
                    {({getRootProps, getInputProps, isDragActive}) => {
                        return (
                            <DropZoneField {...getRootProps()}
                                           className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}>
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <p className="p-0 m-0">
                                            <FormattedMessage {...commonMessages.DROP_ZONE_OVER_FILE}/>
                                        </p> :
                                        <p className="p-0 m-0"><FormattedMessage {...commonMessages.DROP_ZONE}/></p>
                                }
                            </DropZoneField>
                        )
                    }}
                </Dropzone>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps, {})(DropZoneForEditorsNote);
