import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import get from 'get-value';
import {SubmissionError} from 'redux-form';
import moment from 'moment';
import ExportDocForm from './ExportTechDocForm';
import {createTechDocExport, downloadExport, fetchTechDocExport} from '../../../actions/techDoc/actionDocExport';
import {fetchTechDocDealers} from '../../../actions/techDoc/actionTechDocDealers';
import Loader from '../../Loader';
import {DOC_EXPORT_FORM_FIELD_CHARSET, DOC_EXPORT_FORM_FIELD_TYPE, XLS} from '../../../constants/exportConstant';
import {FORM_FIELD_PFX, FORM_FIELD_SFX} from '../../../constants/formConstants';
import PageHeader from '../../common/PageHeader';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {moduleRoles} from '../../../utils/roles';

class ExportTechDocPage extends Component {
    componentDidMount() {
        if (moduleRoles.isEditorDocumentation(this.props.roles)) {
            this.props.fetchTechDocDealers();
        }
        this.props.fetchTechDocExport();
    }

    handleSubmit = (values) => {
        const pfx = get(values, 'pfxNumber', '').length;
        const sfx = get(values, 'sfxNumber', '').length;

        if (pfx > 0 && sfx === 0) {
            throw new SubmissionError({
                [FORM_FIELD_SFX]: 'Fill complete ticket number'
            })
        }

        if (pfx === 0 && sfx > 0) {
            throw new SubmissionError({
                [FORM_FIELD_PFX]: 'Fill complete ticket number'
            })
        }
        const temp = JSON.parse(JSON.stringify((({assigned, dealer = {}, status, solutionGroup}) => {
            const {dealerNumber, dealerName} = dealer;
            return ({
                lastEditor: assigned ? assigned.trim() : undefined,
                dealerNumber: dealerNumber ? dealerNumber.trim() : undefined,
                dealerName: dealerName ? dealerName.trim() : undefined,
                status,
                solutionGroup
            });
        })(values)));

        if (pfx || sfx) {
            temp.ticketNumber = `${values.pfxNumber}-${values.sfxNumber}`;
        }

        const exportFilter = {};
        exportFilter.filter = [];
        for (let key in temp) {
            if (temp.hasOwnProperty(key)) {
                if (key === 'lastEditor') {
                    temp[key].split(' ').forEach(element => exportFilter.filter.push({id: key, value: element}));

                } else {
                    exportFilter.filter.push({id: key, value: temp[key]})
                }
            }
        }

        if (values.from) {
            exportFilter.from = moment(values.from).add(12, 'hours').toISOString().substr(0, 10);
        }
        if (values.to) {
            exportFilter.to = moment(values.to).add(12, 'hours').toISOString().substr(0, 10);
        }
        exportFilter.type = values.type;
        if (!exportFilter.type) {
            exportFilter.type = XLS;
        }
        exportFilter.charset = values.charset;
        this.props.createTechDocExport(exportFilter);
    };

    downloadExport = () => {
        this.props.downloadExport(this.props.exportRecord);
    };

    render() {
        const {isLoadingDealer, isLoadingExport, charSets} = this.props;

        if (isLoadingDealer || isLoadingExport) {
            return <Loader/>;
        }

        return (
            <div className="container-fluid">
                <PageHeader title={<FormattedMessage id="techDoc.export.header" defaultMessage="Export"/>}/>
                <ExportDocForm onSubmit={this.handleSubmit}
                               form={'docExportForm'}
                               downloadExport={this.downloadExport}
                               initialValues={{
                                   [DOC_EXPORT_FORM_FIELD_TYPE]: XLS,
                                   [DOC_EXPORT_FORM_FIELD_CHARSET]: charSets[0]
                               }}
                />
            </div>
        );
    }
}

ExportTechDocPage.propTypes = {
    isLoadingDealer: PropTypes.bool.isRequired,
    isLoadingExport: PropTypes.bool.isRequired,
    exportRecord: PropTypes.object.isRequired,
    fetchTechDocDealers: PropTypes.func.isRequired,
    fetchTechDocExport: PropTypes.func.isRequired,
    createTechDocExport: PropTypes.func.isRequired,
    downloadExport: PropTypes.func.isRequired,
    charSets: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    isLoadingDealer: state.docExportDealers.isLoadingDealer,
    isLoadingExport: state.docExportTickets.isLoadingExport,
    exportRecord: state.docExportTickets.exportRecord,
    charSets: state.profile.userDetail.charSets,
    roles: state.profile.userDetail.roles,
});

export default checkRoles(connect(mapStateToProps, {
    fetchTechDocDealers,
    fetchTechDocExport,
    createTechDocExport,
    downloadExport,
})(injectIntl(ExportTechDocPage)), ['TD_EDITOR', 'TD_SG_EDITOR']);
