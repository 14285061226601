import {sparePartsWebsocketCommands} from "../../constants/sparePartsWebsocketCommands";

export const SPARE_PARTS_UPDATE_USER_LIST_PREFERENCE = 'SPARE_PARTS_UPDATE_USER_LIST_PREFERENCE';
export const SPARE_PARTS_UPDATE_USER_HISTORY_PREFERENCE = 'SPARE_PARTS_UPDATE_USER_HISTORY_PREFERENCE';
export const SPARE_PARTS_UPDATE_USER_SEARCH_ENGINE_PREFERENCE = 'SPARE_PARTS_UPDATE_USER_SEARCH_ENGINE_PREFERENCE';
export const SPARE_PARTS_UPDATE_USER_STATISTICS_PREFERENCE = 'SPARE_PARTS_UPDATE_USER_STATISTICS_PREFERENCE';
export const SPARE_PARTS_FETCH_PREFERENCES_PENDING = 'SPARE_PARTS_FETCH_PREFERENCES_PENDING';
export const SPARE_PARTS_UPDATE_USER_STATISTICS_SG_PREFERENCE = 'SPARE_PARTS_UPDATE_USER_STATISTICS_SG_PREFERENCE';
export const SPARE_PARTS_UPDATE_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE = 'SPARE_PARTS_UPDATE_USER_FULL_TEXT_SEARCH_INTENRAL_PREFERENCE';
export const SPARE_PARTS_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE = 'SPARE_PARTS_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE';
export const SPARE_PARTS_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE = 'SPARE_PARTS_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE';

export const getSparePartsPreferences = () => dispatch => {
    return dispatch({
        type: SPARE_PARTS_FETCH_PREFERENCES_PENDING,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_PREFERENCE_GET
    });
};

export const updateSparePartsUserListPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_UPDATE_USER_LIST_PREFERENCE,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_USER_LIST_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateSparePartsUserHistoryPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_UPDATE_USER_HISTORY_PREFERENCE,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_USER_HISTORY_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateSparePartsUserSearchEnginePreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_UPDATE_USER_SEARCH_ENGINE_PREFERENCE,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_USER_SEARCH_ENGINE_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateSparePartsUserStatisticsPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_UPDATE_USER_STATISTICS_PREFERENCE,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_USER_STATISTICS_PREFERENCE_SET,
        payload: {preferences: {ipn, countries: preferences}}
    });
};

export const updateSparePartsUserInternalTicketHistoryPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_USER_INTERNAL_TICKET_HISTORY_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateSparePartsUserInternalTicketListPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_USER_INTERNAL_TICKET_LIST_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateSparePartsUserFulltextSearchInternalPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_UPDATE_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE_SET,
        payload: {preferences: {ipn, hiddenColumns: preferences}}
    });
};

export const updateSparePartsUserStatisticsSGPreference = (ipn, preferences) => dispatch => {
    return dispatch({
        type: SPARE_PARTS_UPDATE_USER_STATISTICS_SG_PREFERENCE,
        sparePartsWebsocket: true,
        command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_USER_STATISTICS_SG_PREFERENCE_SET,
        payload: {preferences: {ipn, solutionGroups: preferences}}
    });
};
