import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import styled from 'styled-components';
import moment from 'moment';
import {domains, getFormStates, getFormTypes} from '../../../../constants/Utils';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';
import InputFieldTicketNumber from '../../../common/InputFieldTicketNumber';
import InputSelectFieldWithValidation from '../../../common/InputSelectFieldWithValidation';
import DatePickerComponent from '../../../common/datePicker/DatePickerComponent';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import formStatesMessages from '../../../../intl/common/formStatesMessages';
import fulltextSearchMessages from '../../../../intl/claims/fulltextSearchMessages';
import {
    FORM_FIELD_CLIENT_ID,
    FORM_FIELD_DEALER_NAME,
    FORM_FIELD_DEALER_NO,
    FORM_FIELD_LAST_EDITOR,
    FORM_FIELD_FORM_TYPE,
    FORM_FIELD_FROM,
    FORM_FIELD_NAME_TD_SEARCH,
    FORM_FIELD_PFX,
    FORM_FIELD_REGION,
    FORM_FIELD_SFX,
    FORM_FIELD_STATUS,
    FORM_FIELD_TO,
    FORM_LABEL_CLIENT_ID,
    FORM_LABEL_DEALER_NAME,
    FORM_LABEL_DEALER_NO, FORM_LABEL_LAST_EDITOR, FORM_LABEL_FORM_TYPE, FORM_LABEL_FROM,
    FORM_LABEL_NAME,
    FORM_LABEL_REGION, FORM_LABEL_STATUS, FORM_LABEL_TICKET_NUMBER, FORM_LABEL_TO,
} from '../../../../constants/formConstants';
import {AsyncSelectField} from '../../../common/FormField';
import commonMessages from '../../../../intl/common/commonMessages';
import {handleClientIdSearch} from '../../../../utils/utils';
import {handleFetchSearchClientIdsError} from '../../../../actions/admin/actionAdminClientIds';
import {injectIntl} from 'react-intl';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const TicketSection = (props) => {
    const {lastEditors, handleFetchSearchClientIdsError, intl: {formatMessage}} = props;

    return (
        <>
            <h3 className="text-left ml-4">
                <FormattedMessage {...fulltextSearchMessages.TICKET_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 pb-0 pt-3 bg-white">
                <div className="form-row">
                    <div className="col-xl-4 col-md-6 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_DEALER_NAME]}
                                                  field={FORM_FIELD_DEALER_NAME}
                                                  maxLength={200}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-md-6 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_DEALER_NO]}
                                                  field={FORM_FIELD_DEALER_NO}
                                                  maxLength={8}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-4 col-md-6 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_NAME]}
                                                  field={FORM_FIELD_NAME_TD_SEARCH}
                                                  maxLength={200}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-4 col-md-6 pb-2">
                        <label className="col-form-label pl-0 font-weight-bold">
                            <FormattedMessage {...fulltextSearchMessages[FORM_LABEL_CLIENT_ID]}/>
                        </label>
                        <Field component={AsyncSelectField}
                               name={FORM_FIELD_CLIENT_ID}
                               isClearable
                               useCustomStyles
                               noOptionsMessage={() => formatMessage(commonMessages.NONE)}
                               loadingMessage={() => formatMessage(commonMessages.LOADING)}
                               loadOptions={(inputValue, callback) => {handleClientIdSearch(inputValue, callback, handleFetchSearchClientIdsError)}}
                        />
                    </div>
                    <div className="col-xl-4 col-md-6 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_REGION]}
                                                  field={FORM_FIELD_REGION}
                                                  maxLength={6}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <Field component={DatePickerComponent}
                               selectsEnd
                               isClearable
                               label={fulltextSearchMessages[FORM_LABEL_FROM]}
                               name={FORM_FIELD_FROM}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}
                               isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <Field component={DatePickerComponent}
                               selectsEnd
                               isClearable
                               label={fulltextSearchMessages[FORM_LABEL_TO]}
                               name={FORM_FIELD_TO}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}
                               isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 pb-2">
                        <InputSelectFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_LAST_EDITOR]}
                                                        field={FORM_FIELD_LAST_EDITOR}
                                                        options={lastEditors}
                                                        isSmall/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-xl-4 col-md-6 pb-2">
                        <InputSelectFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_FORM_TYPE]}
                                                        field={FORM_FIELD_FORM_TYPE}
                                                        intlMessages={formTypeMessages}
                                                        options={getFormTypes(domains.CLAIMS)
                                                            .map((type) => type)}
                                                        isSmall/>
                    </div>
                    <div className="col-xl-4 col-md-6 pb-2">
                        <InputSelectFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_STATUS]}
                                                        field={FORM_FIELD_STATUS}
                                                        options={getFormStates(domains.CLAIMS)
                                                            .map((status) => status)}
                                                        intlMessages={formStatesMessages}
                                                        isSmall/>
                    </div>
                    <div className="col-xl-4 col-md-6 pb-2">
                        <InputFieldTicketNumber label={fulltextSearchMessages[FORM_LABEL_TICKET_NUMBER]}
                                                fields={[FORM_FIELD_PFX, FORM_FIELD_SFX]}
                                                validation
                                                isSmall/>
                    </div>
                </div>
            </StyledDiv>
        </>
    )
};

const mapStateToProps = (state) => ({
    clientIds: state.adminClientIds.clientIds,
    lastEditors: state.claimsFullTextSearchPage.lastEditors,
});

export default connect(mapStateToProps, {handleFetchSearchClientIdsError})(injectIntl(TicketSection));
