import {defineMessages} from 'react-intl';

const messages = defineMessages({
    TITLE: {
        id: 'spareParts.internal.search.title',
        defaultMessage: 'Search Engine'
    },
    REQUEST_DETAIL_SECTION: {
        id: 'spareParts.internal.search.requestDetailSection',
        defaultMessage: 'Request details'
    },
    // search criteria
    INTERNAL_TICKET_NUMBER: {
        id: 'spareParts.internal.search.requestDetailSection.ticketNumber',
        defaultMessage: 'Internal Ticket number'
    },
    DEALER_TICKET_NUMBER: {
        id: 'spareParts.internal.search.requestDetailSection.dealerTicketNumber',
        defaultMessage: 'Dealer ticket'
    },
    DEALER_FORM_TYPE: {
        id: 'spareParts.internal.search.requestDetailSection.dealerFormType',
        defaultMessage: 'Dealer Form Type'
    },
    TYPE_OF_REQUEST: {
        id: 'spareParts.internal.search.requestDetailSection.typeOfRequest',
        defaultMessage: 'Request type'
    },
    REQUEST_STATUS: {
        id: 'spareParts.internal.search.requestDetailSection.requestStatus',
        defaultMessage: 'Request Status'
    },
    SG_REQUESTER: {
        id: 'spareParts.internal.search.requestDetailSection.sgRequester',
        defaultMessage: 'SG requestor'
    },
    PARTNER_NAME: {
        id: 'spareParts.internal.search.requestDetailSection.partnerName',
        defaultMessage: 'Partner Name'
    },
    REQUESTOR_IPN: {
        id: 'spareParts.internal.search.requestDetailSection.requestorIpn',
        defaultMessage: 'Requestor (IPN)'
    },
    REQUEST_FOR_PARTNER: {
        id: 'spareParts.internal.search.requestDetailSection.requestForPartner',
        defaultMessage: 'Partner ? (Yes/No)'
    },
    CREATED_AT_INTERNAL_TICKET_FROM: {
        id: 'spareParts.internal.search.requestDetailSection.createdAtInternalTicketFrom',
        defaultMessage: 'Creation Date of the Internal Ticket From'
    },

    CREATED_AT_INTERNAL_TICKET_TO: {
        id: 'spareParts.internal.search.requestDetailSection.createdAtInternalTicketTo',
        defaultMessage: 'Creation Date of the Internal Ticket To'
    },

     VEHICLE_DETAIL_SECTION: {
        id: 'spareParts.internal.search.vehicleDetailSection',
        defaultMessage: 'Vehicle details'
    },
    VIN: {
        id: 'spareParts.internal.search.vehicleDetailSection.vin',
        defaultMessage: 'VIN number'
    },
    MANUFACTURE_DATE: {
        id: 'spareParts.internal.search.vehicleDetailSection.manufactureDate',
        defaultMessage: 'Manufacturing date'
    },
    TAPV: {
        id: 'spareParts.internal.search.vehicleDetailSection.tapv',
        defaultMessage: 'TAPV'
    },
    VEHICLE_FAMILY: {
        id: 'spareParts.internal.search.vehicleDetailSection.vehicleFamily',
        defaultMessage: 'Vehicle Family'
    },
    VEHICLE_BRAND: {
        id: 'spareParts.internal.search.vehicleDetailSection.vehicleBrand',
        defaultMessage: 'Vehicle Brand'
    },

    PART_DETAIL_SECTION: {
        id: 'spareParts.internal.search.partDetailSection',
        defaultMessage: 'Part details'
    },
    N_REFERENCE_CAUSING_PROBLEM: {
        id: 'spareParts.internal.search.vehicleDetailSection.nReferenceCausingProblem',
        defaultMessage: 'N° reference causing problem'
    },
    MANAGEMENT_GROUP: {
        id: 'spareParts.internal.search.vehicleDetailSection.mangementGroup',
        defaultMessage: 'Management Group'
    },
    N_REFERENCE_FOR_REPLACEMENT: {
        id: 'spareParts.internal.search.vehicleDetailSection.nReferenceForReplacement',
        defaultMessage: 'N° reference for Replacement'
    },
    CATALOG_NUMBER: {
        id: 'spareParts.internal.search.vehicleDetailSection.catalogNumber',
        defaultMessage: 'Catalog Number'
    },
    PLATE_NUMBER: {
        id: 'spareParts.internal.search.vehicleDetailSection.plateNumber',
        defaultMessage: 'Plate Number'
    },
    PART_MARKING: {
        id: 'spareParts.internal.search.vehicleDetailSection.partMarking',
        defaultMessage: 'Part Marking'
    },

    // searching options
    SEARCH_OPTION_SECTION : {
        id: 'spareParts.internal.search.options',
        defaultMessage: 'Searching option'
    },
    FULLTEXT_SEARCH : {
        id: 'spareParts.internal.search.options.fulltextSearch',
        defaultMessage: 'Fulltext'
    },

    VISIBLE_COLUMNS: {
        id: 'spareParts.internal.search.label.visibleColumns',
        defaultMessage: 'Visible columns'
    },


    // table
    TABLE_TICKET_NUMBER: {
        id: 'spareParts.internal.search.table.ticketNumber',
        defaultMessage: 'Internal Ticket number'
    },
    TABLE_REQUEST_TYPE: {
        id: 'spareParts.internal.search.table.requestType',
        defaultMessage: 'Request type'
    },
    TABLE_REQUEST_STATUS: {
        id: 'spareParts.internal.search.table.status',
        defaultMessage: 'Request Status'
    },
    TABLE_PRIORITY: {
        id: 'spareParts.internal.search.table.priority',
        defaultMessage: 'Priority'
    },
    TABLE_PRIORITY_TYPE: {
        id: 'spareParts.internal.search.table.priorityType',
        defaultMessage: 'Priority Type'
    },
    TABLE_DEALER_TICKET_NUMBER: {
        id: 'spareParts.internal.search.table.dealerTicketNo',
        defaultMessage: 'Dealer Ticket No.'
    },
    TABLE_DEALER_FORM_TYPE: {
        id: 'spareParts.internal.search.table.dealerTicketFormType',
        defaultMessage: 'Dealer Form Type'
    },
    TABLE_IT_CREATION_TIME: {
        id: 'spareParts.internal.search.table.itCreationDate',
        defaultMessage: 'IT Creation Date'
    },
    TABLE_IT_CREATED_BY: {
        id: 'spareParts.internal.search.table.itCreatedBy',
        defaultMessage: 'IT Created by (IPN)'
    },
    TABLE_SG_REQUESTOR: {
        id: 'spareParts.internal.search.table.sgRequestor',
        defaultMessage: 'SG Requestor'
    },
    TABLE_ASSIGNED_SOLUTION_GROUP: {
        id: 'spareParts.internal.search.table.assignedSolutionGroup',
        defaultMessage: 'Assigned SG'
    },
    TABLE_DEALER_CLIENT_ID: {
        id: 'spareParts.internal.search.table.clientId',
        defaultMessage: 'Client ID'
    },
    TABLE_DEALER_REGION: {
        id: 'spareParts.internal.search.table.region',
        defaultMessage: 'Region'
    },
    TABLE_IT_DEALER_NUMBER: {
        id: 'spareParts.internal.search.table.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    TABLE_PART_NUMBER: {
        id: 'spareParts.internal.search.table.partNumber',
        defaultMessage: 'Part No.'
    },
    TABLE_MANAGEMENT_GROUP: {
        id: 'spareParts.internal.search.table.managementGroup',
        defaultMessage: 'Management Group'
    },
    TABLE_PARTNER: {
        id: 'spareParts.internal.search.table.partner',
        defaultMessage: 'Partner'
    },
    TABLE_PARTNER_NAME: {
        id: 'spareParts.internal.search.table.partnerName',
        defaultMessage: 'Partner Name'
    },
    TABLE_IT_LAST_STATUS_DATE: {
        id: 'spareParts.internal.search.table.lastStatusDate',
        defaultMessage: 'Last Status Date'
    },
    TABLE_IT_LAST_EDITOR: {
        id: 'spareParts.internal.search.table.lastEditor',
        defaultMessage: 'Last Editor'
    },
});

export default messages;
