import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styled from "styled-components";
import 'react-picky/dist/picky.css';
import {withRouter} from 'react-router-dom';
import PageHeader from '../../common/PageHeader';
import checkRoles from '../../common/roleChecker/RoleChecker';
import managementGroupMessages from "../../../intl/technicalDocumentations/managementGroupMessages";
import {useEffect} from 'react';
import ManagementGroupTechDocForm from './ManagementGroupTechDocForm';
import Loader from '../../Loader';
import {
    checkTechDocManagementGroupBatch,
    initiateTechDocManagementGroupBatch
} from '../../../actions/techDoc/actionTechDocManagementGroup';

const Wrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
`;

const ManagementGroupTechDocPage = (props) => {
    useEffect(() => {
        props.checkTechDocManagementGroupBatch();
    }, []);

    const handleSubmit = (values) => {
        props.initiateTechDocManagementGroupBatch(values.spareParts);
    };

    if (props.isLoadingBatchInfo) {
        return <Loader/>;
    }

    return (
        <div>
            <PageHeader title={<FormattedMessage {...managementGroupMessages.TITLE}/>}/>
            <Wrapper classname="container-fluid">
                <ManagementGroupTechDocForm
                    onSubmit={handleSubmit}
                    enableReinitialize={false}
                    form='techDocManagementGroupForm'/>
            </Wrapper>
        </div>
    )
};

ManagementGroupTechDocPage.propTypes = {
    intl: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    isLoadingBatchInfo: state.techDocManagementGroupBatch.isLoadingBatchInfo,
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    initiateTechDocManagementGroupBatch,
    checkTechDocManagementGroupBatch,
})(injectIntl(ManagementGroupTechDocPage))), ['TD_SG_ADMIN']);

