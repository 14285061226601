import React from 'react';
import {FormattedMessage} from 'react-intl';
import {
    ADMINISTRATION_CLIENT_ID_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ADMIN_PATH,
    ADMINISTRATION_DOCUMENTATIONS_DEALER_NOTIFICATION_PATH,
    ADMINISTRATION_DOCUMENTATIONS_DEALER_PATH,
    ADMINISTRATION_DOCUMENTATIONS_EDITOR_PATH,
    ADMINISTRATION_DOCUMENTATIONS_MAIN_PAGE_PATH,
    ADMINISTRATION_DOCUMENTATIONS_RECURRENT_PROBLEM_PATH,
    ADMINISTRATION_DOCUMENTATIONS_TICKET_SETTING_PATH,
    ADMINISTRATION_DOCUMENTATIONS_WORKING_HOUR_PATH,
    ADMINISTRATION_GENERIC_FLOW_PATH,
    ADMINISTRATION_GENERIC_SOLUTION_GROUP_PATH,
    ADMINISTRATION_ORDER_ADMIN_PATH,
    ADMINISTRATION_ORDER_DEALER_NOTIFICATION_PATH,
    ADMINISTRATION_ORDER_DEALER_PATH,
    ADMINISTRATION_ORDER_EDITOR_PATH,
    ADMINISTRATION_ORDER_MAIN_PAGE_PATH,
    ADMINISTRATION_ORDER_RECURRENT_PROBLEM_PATH,
    ADMINISTRATION_ORDER_TICKET_SETTING_PATH,
    ADMINISTRATION_ORDER_WORKING_HOUR_PATH,
    ADMINISTRATION_CLAIMS_ADMIN_PATH,
    ADMINISTRATION_CLAIMS_DEALER_NOTIFICATION_PATH,
    ADMINISTRATION_CLAIMS_DEALER_PATH,
    ADMINISTRATION_CLAIMS_EDITOR_PATH,
    ADMINISTRATION_CLAIMS_MAIN_PAGE_PATH,
    ADMINISTRATION_CLAIMS_RECURRENT_PROBLEM_PATH,
    ADMINISTRATION_CLAIMS_TICKET_SETTING_PATH,
    ADMINISTRATION_CLAIMS_WORKING_HOUR_PATH,
    ADMINISTRATION_ORDER_DEFAULT_COUNTRY_ROLE_PATH,
    ADMINISTRATION_DOCUMENTATIONS_DEFAULT_COUNTRY_ROLE_PATH,
    ADMINISTRATION_CLAIMS_PRESET_RESPONSE_PATH,
    ADMINISTRATION_CLAIMS_DEFAULT_COUNTRY_ROLE_PATH,
    ADMINISTRATION_ORDER_SECOND_HAND_BATTERY_TYPE_PATH,
    ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_NEW_PATH,
    ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_LIST_PATH,
    ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_USER_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_NEW_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_LIST_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_NEW_PATH,
    ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_LIST_PATH,
    ADMINISTRATION_ORDER_SOLUTION_GROUP_NEW_PATH,
    ADMINISTRATION_ORDER_SOLUTION_GROUP_LIST_PATH,
    ADMINISTRATION_ORDER_SOLUTION_GROUP_USER_PATH,
    ADMINISTRATION_ORDER_ESCALATION_SCHEMA_NEW_PATH,
    ADMINISTRATION_ORDER_ESCALATION_SCHEMA_LIST_PATH,
    ADMINISTRATION_ORDER_ANSWERING_SCHEMA_NEW_PATH,
    ADMINISTRATION_ORDER_ANSWERING_SCHEMA_LIST_PATH,
    ADMINISTRATION_ORDER_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS_PATH,
    ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS_PATH
} from '../../../../routes/paths';
import {moduleRoles} from '../../../../utils/roles';
import {domains} from '../../../../constants/Utils';
import menuMessages from '../../../../intl/layout/menuMessages';

export const getAdministrationMenuAdminHtl = roles => {
    const isAdminOf = (domain) => moduleRoles.isAdmin(roles, domain);
    const isPowerUserOf = (domain) => moduleRoles.isPowerUser(roles, domain);
    const isSGAdminOf = (domain) => moduleRoles.isSGAdmin(roles, domain);

    const SPARE_PARTS = domains.SPARE_PARTS;
    const TECH_DOC = domains.TECHNICAL_DOCUMENTATION;
    const CLAIMS = domains.CLAIMS;
    const GENERIC_TICKET = domains.GENERIC_TICKET;

    const result = [];
    if (isAdminOf(SPARE_PARTS) || isPowerUserOf(SPARE_PARTS) || isSGAdminOf(SPARE_PARTS)) {
        let content = [];
        if (isAdminOf(SPARE_PARTS)) {
            content = [
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_NOTIFICATION}/>,
                    to: ADMINISTRATION_ORDER_DEALER_NOTIFICATION_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_MAIN_PAGE_ALERTS}/>,
                    to: ADMINISTRATION_ORDER_MAIN_PAGE_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_RECURRENT_PROBLEMS}/>,
                    to: ADMINISTRATION_ORDER_RECURRENT_PROBLEM_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_WORKING_HOURS}/>,
                    to: ADMINISTRATION_ORDER_WORKING_HOUR_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_TICKET_SETTING}/>,
                    to: ADMINISTRATION_ORDER_TICKET_SETTING_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_SECOND_HAND_BATTERY_TYPE}/>,
                    to: ADMINISTRATION_ORDER_SECOND_HAND_BATTERY_TYPE_PATH
                }
            ];
        }

        const authorizationManagementOrderSubmenu = buildAuthorizationManagementOrderSubmenu(roles);
        if (authorizationManagementOrderSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_AUTH_MANAGEMENT}/>,
                    content: authorizationManagementOrderSubmenu
                }
            );
        }
        const solutionGroupManagementSubmenu = buildSolutionGroupManagementSparePartsSubmenu(roles);
        if (solutionGroupManagementSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_MULTI_LEVEL_ESCALATION_SCHEMA}/>,
                    content: solutionGroupManagementSubmenu
                }
            );
        }
        result.push({
            label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS}/>,
            content
        });
    }
    if (isAdminOf(TECH_DOC) || isPowerUserOf(TECH_DOC) || isSGAdminOf(TECH_DOC)) {
        let content = [];
        if (isAdminOf(TECH_DOC)) {
            content = [
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_NOTIFICATION}/>,
                    to: ADMINISTRATION_DOCUMENTATIONS_DEALER_NOTIFICATION_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_MAIN_PAGE_ALERTS}/>,
                    to: ADMINISTRATION_DOCUMENTATIONS_MAIN_PAGE_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_RECURRENT_PROBLEMS}/>,
                    to: ADMINISTRATION_DOCUMENTATIONS_RECURRENT_PROBLEM_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_WORKING_HOURS}/>,
                    to: ADMINISTRATION_DOCUMENTATIONS_WORKING_HOUR_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_TICKET_SETTING}/>,
                    to: ADMINISTRATION_DOCUMENTATIONS_TICKET_SETTING_PATH
                }
            ];
        }
        const authorizationManagementDocumentationSubmenu = buildAuthorizationManagementDocumentationSubmenu(roles);
        if (authorizationManagementDocumentationSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_AUTH_MANAGEMENT}/>,
                    content: authorizationManagementDocumentationSubmenu
                }
            );
        }
        const solutionGroupManagementDocumentationSubmenu = buildSolutionGroupManagementDocumentationSubmenu(roles);
        if (solutionGroupManagementDocumentationSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_MULTI_LEVEL_ESCALATION_SCHEMA}/>,
                    content: solutionGroupManagementDocumentationSubmenu
                }
            );
        }
        result.push({
            label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC}/>,
            content
        });
    }
    if (isAdminOf(CLAIMS) || isPowerUserOf(CLAIMS)) {
        let content = [];
        if (isAdminOf(CLAIMS)) {
            content = [
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_CLAIMS_NOTIFICATION}/>,
                    to: ADMINISTRATION_CLAIMS_DEALER_NOTIFICATION_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_CLAIMS_MAIN_PAGE_ALERTS}/>,
                    to: ADMINISTRATION_CLAIMS_MAIN_PAGE_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_CLAIMS_RECURRENT_PROBLEMS}/>,
                    to: ADMINISTRATION_CLAIMS_RECURRENT_PROBLEM_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_CLAIMS_WORKING_HOURS}/>,
                    to: ADMINISTRATION_CLAIMS_WORKING_HOUR_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_CLAIMS_TICKET_SETTING}/>,
                    to: ADMINISTRATION_CLAIMS_TICKET_SETTING_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_CLAIMS_PRESET_RESPONSE}/>,
                    to: ADMINISTRATION_CLAIMS_PRESET_RESPONSE_PATH
                }
            ];
        }
        const authorizationManagementCLaimsSubmenu = buildAuthorizationManagementClaimsSubmenu(roles);
        if (authorizationManagementCLaimsSubmenu.length > 0) {
            content.push(
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_CLAIMS_AUTH_MANAGEMENT}/>,
                    content: authorizationManagementCLaimsSubmenu
                }
            );
        }
        result.push({
            label: <FormattedMessage {...menuMessages.ADMIN_CLAIMS}/>,
            content
        });
    }

    if (isAdminOf(GENERIC_TICKET) || isPowerUserOf(GENERIC_TICKET)) {
        result.push({
            label: <FormattedMessage {...menuMessages.ADMIN_GENERIC_TICKET}/>,
            content: [
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_GENERIC_TICKET_SOLUTION_GROUP}/>,
                    to: ADMINISTRATION_GENERIC_SOLUTION_GROUP_PATH
                },
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_GENERIC_TICKET_FLOW}/>,
                    to: ADMINISTRATION_GENERIC_FLOW_PATH
                }
            ]
        });
    }
    if (isAdminOf(SPARE_PARTS) || isAdminOf(TECH_DOC) || isAdminOf(CLAIMS)) {
        result.push({
            label: <FormattedMessage {...menuMessages.ADMIN_COMMON_SETTING}/>,
            content: [
                {
                    label: <FormattedMessage {...menuMessages.ADMIN_COMMON_SETTING_CLIENT_ID}/>,
                    to: ADMINISTRATION_CLIENT_ID_PATH
                },
            ]
        });
    }

    return result;
};

const buildAuthorizationManagementOrderSubmenu = roles => {
    const result = [];

    if (moduleRoles.isPowerUser(roles, domains.SPARE_PARTS)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_AUTH_MANAGEMENT_ADMIN}/>,
                to: ADMINISTRATION_ORDER_ADMIN_PATH
            }
        );
    }
    if (moduleRoles.isAdmin(roles, domains.SPARE_PARTS)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_AUTH_MANAGEMENT_DEALER}/>,
                to: ADMINISTRATION_ORDER_DEALER_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_AUTH_MANAGEMENT_EDITOR}/>,
                to: ADMINISTRATION_ORDER_EDITOR_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_DEFAULT_COUNTRY_ROLE}/>,
                    to: ADMINISTRATION_ORDER_DEFAULT_COUNTRY_ROLE_PATH
            }
        );
    }

    return result;
};

const buildAuthorizationManagementDocumentationSubmenu = roles => {
    const result = [];

    if (moduleRoles.isPowerUser(roles, domains.TECHNICAL_DOCUMENTATION)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_AUTH_MANAGEMENT_ADMIN}/>,
                to: ADMINISTRATION_DOCUMENTATIONS_ADMIN_PATH
            }
        );
    }
    if (moduleRoles.isAdmin(roles, domains.TECHNICAL_DOCUMENTATION)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_AUTH_MANAGEMENT_DEALER}/>,
                to: ADMINISTRATION_DOCUMENTATIONS_DEALER_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_AUTH_MANAGEMENT_EDITOR}/>,
                to: ADMINISTRATION_DOCUMENTATIONS_EDITOR_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_DEFAULT_COUNTRY_ROLE}/>,
                to: ADMINISTRATION_DOCUMENTATIONS_DEFAULT_COUNTRY_ROLE_PATH
            }
        );
    }

    return result;
};

const buildAuthorizationManagementClaimsSubmenu = roles => {
    const result = [];

    if (moduleRoles.isPowerUser(roles, domains.CLAIMS)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_CLAIMS_AUTH_MANAGEMENT_ADMIN}/>,
                to: ADMINISTRATION_CLAIMS_ADMIN_PATH
            }
        );
    }
    if (moduleRoles.isAdmin(roles, domains.CLAIMS)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_CLAIMS_AUTH_MANAGEMENT_DEALER}/>,
                to: ADMINISTRATION_CLAIMS_DEALER_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_CLAIMS_AUTH_MANAGEMENT_EDITOR}/>,
                to: ADMINISTRATION_CLAIMS_EDITOR_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_CLAIMS_DEFAULT_COUNTRY_ROLE}/>,
                to: ADMINISTRATION_CLAIMS_DEFAULT_COUNTRY_ROLE_PATH
            }
        );
    }
    return result;
};


const buildSolutionGroupManagementDocumentationSubmenu = roles => {
    const result = [];

    const techDocDomain = domains.TECHNICAL_DOCUMENTATION;

    if (moduleRoles.isPowerUser(roles, techDocDomain)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_SOLUTION_GROUP_NEW}/>,
                to: ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_NEW_PATH
            }
        );
    }
    if (moduleRoles.isPowerUser(roles, techDocDomain) || moduleRoles.isSGAdmin(roles, techDocDomain)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_SOLUTION_GROUP_LIST}/>,
                to: ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_LIST_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_SOLUTION_GROUP_USER_MANAGEMENT}/>,
                to: ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_USER_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS}/>,
                to: ADMINISTRATION_DOCUMENTATIONS_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS_PATH
            }
        );
    }
    if (moduleRoles.isPowerUser(roles, techDocDomain)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_ESCALATION_SCHEMA_NEW}/>,
                to: ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_NEW_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_ESCALATION_SCHEMA_LIST}/>,
                to: ADMINISTRATION_DOCUMENTATIONS_ESCALATION_SCHEMA_LIST_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_ANSWERING_SCHEMA_NEW}/>,
                to: ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_NEW_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_ANSWERING_SCHEMA_LIST}/>,
                to: ADMINISTRATION_DOCUMENTATIONS_ANSWERING_SCHEMA_LIST_PATH
            }
        );

    }

    return result;
};

const buildSolutionGroupManagementSparePartsSubmenu = roles => {
    const result = [];

    const spareParts = domains.SPARE_PARTS;

    const newSolutionGroup = {
        label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_SOLUTION_GROUP_NEW}/>,
        to: ADMINISTRATION_ORDER_SOLUTION_GROUP_NEW_PATH
    };
    const solutionGroupList = {
        label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_SOLUTION_GROUP_LIST}/>,
        to: ADMINISTRATION_ORDER_SOLUTION_GROUP_LIST_PATH
    }

    const userManagement = {
        label: <FormattedMessage {...menuMessages.ADMIN_TECH_DOC_SOLUTION_GROUP_USER_MANAGEMENT}/>,
        to: ADMINISTRATION_ORDER_SOLUTION_GROUP_USER_PATH
    }

    const modelAnswersSettings = {
        label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS}/>,
        to: ADMINISTRATION_ORDER_SOLUTION_GROUP_MODEL_ANSWERS_SETTINGS_PATH
    }

    const newEscalation = {
        label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_ESCALATION_SCHEMA_NEW}/>,
        to: ADMINISTRATION_ORDER_ESCALATION_SCHEMA_NEW_PATH
    }

    const escalationList = {
        label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_ESCALATION_SCHEMA_LIST}/>,
        to: ADMINISTRATION_ORDER_ESCALATION_SCHEMA_LIST_PATH
    };

    const newAnswering = {
        label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_ANSWERING_SCHEMA_NEW}/>,
        to: ADMINISTRATION_ORDER_ANSWERING_SCHEMA_NEW_PATH
    };

    const answeringList = {
        label: <FormattedMessage {...menuMessages.ADMIN_SPARE_PARTS_ANSWERING_SCHEMA_LIST}/>,
        to: ADMINISTRATION_ORDER_ANSWERING_SCHEMA_LIST_PATH
    }

    if (moduleRoles.isPowerUser(roles, spareParts)) {
        result.push(newSolutionGroup);
    }
    if (moduleRoles.isPowerUser(roles, spareParts) || moduleRoles.isSGAdmin(roles, spareParts)) {
        result.push(solutionGroupList);
        result.push(userManagement);
        result.push(modelAnswersSettings);
    }
    if (moduleRoles.isPowerUser(roles, spareParts)) {
        result.push(newEscalation);
        result.push(escalationList);
        result.push(newAnswering);
        result.push(answeringList);
    }

    return result;
};


