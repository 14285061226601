import React from 'react';
import styled from 'styled-components';
import {FormSection,} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {
    FORM_FIELD_REGISTRATION_NO,
    FORM_FIELD_CAR_MODEL,
    FORM_FIELD_VIN,
    FORM_LABEL_REGISTRATION_NO,
    FORM_LABEL_VEHICLE_MODEL,
    FORM_LABEL_VIN
} from '../../../../constants/formConstants';
import {InputFieldWithValidation} from '../../../common/InputFieldWithValidation';
import NewOvalPlate from './NewOvalPlate';
import OldOvalPlate from './OldOvalPlate';
import fulltextSearchMessages from "../../../../intl/technicalDocumentations/fulltextSearchMessages";

const StyledDiv = styled.div`
  border-radius: 5px;
`;


const VehicleDetailSectionSearch = (props) => {
    const { form } = props;
    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...fulltextSearchMessages.VEHICLE_DETAIL_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-2 col-lg-4 col-md-4 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_VEHICLE_MODEL]}
                                                  field={FORM_FIELD_CAR_MODEL}
                                                  maxLength={50}
                                                  isSmall/>
                    </div>
                    <div className="col-md-7 pb-2">
                                <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_VIN]}
                                                          field={FORM_FIELD_VIN}
                                                          maxLength={17}
                                                          isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 pb-2">
                        <InputFieldWithValidation label={fulltextSearchMessages[FORM_LABEL_REGISTRATION_NO]}
                                                  field={FORM_FIELD_REGISTRATION_NO}
                                                  maxLength={10}
                                                  isSmall/>
                    </div>
                </div>
                <div className="form-row mt-4">
                    <FormSection name="ovalPlate" className="form-row">
                        <NewOvalPlate form={form}/>
                        <OldOvalPlate form={form}/>
                    </FormSection>
                </div>
            </StyledDiv>
        </>
    )
};

export default (VehicleDetailSectionSearch);
