import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {fetchClaimsRecurrentProblems} from '../../../actions/claims/actionClaimsRecurrentProblem';
import Loader from '../../Loader';
import RecurrentProblemClaimsRow from './RecurrentProblemClaimsRow';
import recurrentProblemMessages from '../../../intl/claims/recurrentProblemMessages';

class RecurrentProblemClaimsContainer extends Component {
    componentDidMount() {
        const {domain, fetchClaimsRecurrentProblems, selectedGroup} = this.props;
        fetchClaimsRecurrentProblems(domain, selectedGroup);
    }

    render() {
        const {isLoading, recurrentProblems} = this.props;
        if (isLoading) {
            return <Loader/>;
        }
        return (
            <>
                {recurrentProblems.length === 0 &&
                <div className="row">
                    <div className="col">
                        <FormattedMessage {...recurrentProblemMessages.EMPTY}/>
                    </div>
                </div>
                }
                {recurrentProblems.length > 0 &&
                <div className="row">
                    <div className="col">
                        <table className="table table-hover">
                            <thead className="thead-light">
                            <tr className="d-flex">
                                <th className="col-2">
                                    <FormattedMessage {...recurrentProblemMessages.TABLE_PROBLEM_NUMBER}/>
                                </th>
                                <th className="col-6">
                                    <FormattedMessage {...recurrentProblemMessages.TABLE_PROBLEM_TITLE}/>
                                </th>
                                <th className="col-2">
                                    <FormattedMessage {...recurrentProblemMessages.TABLE_ATTACHMENT}/>
                                </th>
                                <th className="col-2">
                                    <FormattedMessage {...recurrentProblemMessages.TABLE_LAST_UPDATE}/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {recurrentProblems.map((recurrentProblem, index) =>
                                <RecurrentProblemClaimsRow key={`${recurrentProblem}${index}`}
                                                           recurrentProblem={recurrentProblem}
                                                           domain={this.props.domain} index={index}/>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                }
            </>
        );
    }
}

RecurrentProblemClaimsContainer.propTypes = {
    intl: PropTypes.any.isRequired,
    domain: PropTypes.string.isRequired,
    selectedGroup: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.claimsRecurrentProblem.isLoading,
    recurrentProblems: state.claimsRecurrentProblem.recurrentProblems,
});

export default connect(mapStateToProps, {
    fetchClaimsRecurrentProblems,
})(injectIntl(RecurrentProblemClaimsContainer));
