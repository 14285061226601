import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import Table from '../../../common/table/TicketTable';
import { IconEdit, PrimaryIcon } from '../../../common/Button';
import { debounce } from 'throttle-debounce';
import moment from 'moment';
import solutionGroupMessages from '../../../../intl/admin/solutionGroupMessages';
import { fetchSolutionGroupListPageData } from '../../../../actions/admin/actionSolutionGroupListPage';
import {Link} from 'react-router-dom';

class ListSolutionGroupTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.loadStateFromSessionStorage({
            listSGDefaultPageSize: 10,
            listSGDefaultSort: [],
            listSGDefaultFilters: [],
        });
        this.filtering = false;

        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleFetchDataDebounced = debounce(1000, this.handleFetchData);
    }

    componentDidMount() {
        window.addEventListener(
            'beforeunload',
            this.saveStateToSessionStorage.bind(this)
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            'beforeunload',
            this.saveStateToSessionStorage.bind(this)
        );
        this.saveStateToSessionStorage();
    }

    loadStateFromSessionStorage(defaults) {
        return Object.keys(defaults)
            .reduce((acc, key) => {
                if (sessionStorage.hasOwnProperty(key) && key.match(/^listSG*/)) {
                    let value = sessionStorage.getItem(key);
                    value = JSON.parse(value);
                    return {
                        ...acc,
                        [key]: value,
                    };
                }
                return acc;
            }, defaults);
    }

    saveStateToSessionStorage() {
        for (let key in this.state) {
            if (key.match(/^listSG*/)) {
                sessionStorage.setItem(key, JSON.stringify(this.state[key]));
            }
        }
    }

    handleFetchData = state => {
        this.props.fetchSolutionGroupListPageData(this.props.domain, state.pageSize, state.sorted, state.cursor, state.isNextFromPivot, this.normalizeFiltered(state));
        this.filtering = false;
        this.setState({
            listSGDefaultPageSize: state.pageSize,
            listSGDefaultSort: state.sorted,
            listSGDefaultFilters: state.filtered
        });
    };

    fetchStrategy = (state) => {
        this.filtering ? this.handleFetchDataDebounced(state) : this.handleFetchData(state);
    };

    normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date) {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const { columns, data, pages, isLoading, handleEditClick, domain } = this.props;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'name',
                                    accessor: 'name',
                                    message: solutionGroupMessages.TABLE_NAME,
                                    Cell: row => <Link to={`view/${row.original.id}`}>{row.value}</Link>
                                },
                                ...columns]}
                            toolButtons={[
                                {
                                    handleClick: handleEditClick,
                                    component: <PrimaryIcon className="btn btn-sm mr-sm-1" title="Edit" type="button"
                                                            key="editButton">
                                        <IconEdit/>
                                    </PrimaryIcon>
                                },
                            ]}
                            isLoading={isLoading}
                            data={data}
                            pages={pages}
                            handleFetchData={this.fetchStrategy}
                            handleOnFilteredChange={() => {
                                this.filtering = true;
                            }}
                            defaultFiltered={this.state.listSGDefaultFilters}
                            defaultSorting={this.state.listSGDefaultSort}
                            defaultPageSize={this.state.listSGDefaultPageSize}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

ListSolutionGroupTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    pages: PropTypes.number.isRequired,
    fetchSolutionGroupListPageData: PropTypes.func.isRequired,
    columns: PropTypes.array,
    userRoles: PropTypes.object.isRequired,
    handleEditClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.adminSolutionGroupList.isTableLoading,
    data: state.adminSolutionGroupList.listData,
    pages: state.adminSolutionGroupList.pages,
});

export default connect(mapStateToProps, {
    fetchSolutionGroupListPageData
})(ListSolutionGroupTable);
