import {
    TECH_DOC_FETCH_PREFERENCES_PENDING, TECH_DOC_UPDATE_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE,
    TECH_DOC_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE,
    TECH_DOC_UPDATE_USER_HISTORY_PREFERENCE,
    TECH_DOC_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE,
    TECH_DOC_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE,
    TECH_DOC_UPDATE_USER_LIST_PREFERENCE,
    TECH_DOC_UPDATE_USER_STATISTICS_PREFERENCE,
    TECH_DOC_UPDATE_USER_STATISTICS_SG_PREFERENCE
} from '../../actions/techDoc/actionTechDocPreferences';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import get from 'get-value';

export const techDocPreferences = (state = {
    isLoading: true,
    menuList: [],
    menuHistory: [],
    menuFullTextSearch: [],
    menuFullTextSearchInternal: [],
    menuStatistics: [],
    menuInternalList: [],
    menuInternalHistory: [],
}, action) => {
    switch (action.type) {
        case TECH_DOC_UPDATE_USER_LIST_PREFERENCE: {
            return Object.assign({}, state, {menuList: action.payload.preferences.hiddenColumns});
        }
        case TECH_DOC_UPDATE_USER_HISTORY_PREFERENCE: {
            return Object.assign({}, state, {menuHistory: action.payload.preferences.hiddenColumns});
        }
        case TECH_DOC_UPDATE_USER_INTERNAL_TICKET_LIST_PREFERENCE: {
            return Object.assign({}, state, {menuInternalList: action.payload.preferences.hiddenColumns});
        }
        case TECH_DOC_UPDATE_USER_INTERNAL_TICKET_HISTORY_PREFERENCE: {
            return Object.assign({}, state, {menuInternalHistory: action.payload.preferences.hiddenColumns});
        }
        case TECH_DOC_UPDATE_USER_FULL_TEXT_SEARCH_PREFERENCE: {
            return Object.assign({}, state, {menuFullTextSearch: action.payload.preferences.hiddenColumns});
        }
        case TECH_DOC_UPDATE_USER_FULL_TEXT_SEARCH_INTERNAL_PREFERENCE: {
            return Object.assign({}, state, {menuFullTextSearchInternal: action.payload.preferences.hiddenColumns});
        }
        case TECH_DOC_UPDATE_USER_STATISTICS_PREFERENCE: {
            return Object.assign({}, state, {menuStatistics: action.payload.preferences.countries});
        }
        case TECH_DOC_UPDATE_USER_STATISTICS_SG_PREFERENCE: {
            return Object.assign({}, state, {menuStatisticsSG: action.payload.preferences.solutionGroups});
        }
        case techDocWebsocketCommands.TECH_DOC_PREFERENCES_SEND: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                menuList: get(action,'payload.preferences.menuList', []),
                menuHistory: get(action,'payload.preferences.menuHistory', []),
                menuInternalList: get(action,'payload.preferences.menuInternalList', []),
                menuInternalHistory: get(action,'payload.preferences.menuInternalHistory', []),
                menuFullTextSearch: get(action,'payload.preferences.menuFullTextSearch', []),
                menuFullTextSearchInternal: get(action,'payload.preferences.menuFullTextSearchInternal', []),
                menuStatistics: get(action,'payload.preferences.menuStatistics', []),
                menuStatisticsSG: get(action,'payload.preferences.menuStatisticsSG', []),
                isLoading: false,
                correlationId: action.payload.correlationId
            });
        }
        case techDocWebsocketCommands.TECH_DOC_PREFERENCES_SEND_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId: action.payload.correlationId});
        }
        case techDocWebsocketCommands.TECH_DOC_PREFERENCES_ERROR: {
            if (action.payload.correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, correlationId: action.payload.correlationId});
        }
        case TECH_DOC_FETCH_PREFERENCES_PENDING: {
            return Object.assign({}, state, {isLoading: true});
        }
        default:
            return state;
    }
};
