import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {moduleRoles} from '../../../../utils/roles';
import {InputCheckBox} from '../../../common/InputCheckbox';
import fulltextSearchMessages from '../../../../intl/claims/fulltextSearchMessages';
import {
    FORM_FIELD_FULL_DATABASE,
    FORM_FIELD_FULLTEXT_SEARCH,
    FORM_FIELD_INTERNATIONAL,
    FORM_LABEL_FULL_DATABASE,
    FORM_LABEL_FULLTEXT_SEARCH,
    FORM_LABEL_INTERNATIONAL,
} from '../../../../constants/formConstants';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const SearchOption = (props) => {
    const {roles} = props;
    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...fulltextSearchMessages.SEARCH_OPTION_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary p-4 bg-white">
                <div className="form-row">
                    <div className="col-xl-4 pb-2">
                        <InputCheckBox label={fulltextSearchMessages[FORM_LABEL_FULLTEXT_SEARCH]}
                                       field={FORM_FIELD_FULLTEXT_SEARCH}/>
                    </div>
                </div>
                {moduleRoles.isDealerClaims(roles) &&
                <div className="form-row">
                    <div className="col-xl-4 pb-2">
                        <InputCheckBox label={fulltextSearchMessages[FORM_LABEL_FULL_DATABASE]}
                                       field={FORM_FIELD_FULL_DATABASE}/>
                    </div>
                </div>
                }
                {moduleRoles.isEditorClaims(roles) &&
                <div className="form-row">
                    <div className="col-xl-4 pb-2">
                        <InputCheckBox label={fulltextSearchMessages[FORM_LABEL_INTERNATIONAL]}
                                       field={FORM_FIELD_INTERNATIONAL}/>
                    </div>
                </div>
                }
            </StyledDiv>
        </>
    )
};

const mapStateToProps = state => ({
    roles: state.profile.userDetail.roles,
});

export default connect(mapStateToProps, {})(SearchOption);
