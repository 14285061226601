import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    SRC: {
        id: 'techDoc.priorityType.option.src',
        defaultMessage: 'SRC'
    },
    VOR: {
        id: 'techDoc.priorityType.option.vor',
        defaultMessage: 'VOR'
    },
});

export default messages;
