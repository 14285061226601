import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {reduxForm, formValueSelector, getFormInitialValues} from 'redux-form';
import {PrimaryButton} from '../../common/Button';
import {connect} from 'react-redux';
import {ModalFooter, ModalWindow} from '../../common/modal/Modal';
import buttonMessages from '../../../intl/common/buttonMessages';
import supportMessages from '../../../intl/layout/supportMessages';
import InputSelectFieldWithValidation from '../../common/InputSelectFieldWithValidation';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import countryGroupMessages from '../../../intl/common/countryGroupMessages';
import localesMessages from '../../../intl/common/localesMessages';
import locationsMessages from '../../../intl/common/locationsMessages';
import {languages, locations} from '../../../constants/Utils';
import moment from 'moment-timezone';
import {localeToCodeMapping, supportProperties} from '../../../constants/support';
import Loader from '../../Loader';
import get from 'get-value';
import Countdown from "../../Countdown";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        minWidth: '600px',
        maxHeight: '90%',
        overflowY: 'auto',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
};

const customHeaderStyles = {
    fontWeight: '700',
    textAlign: 'center',
    border: 0,
    padding: '15px',
};

const StyledForm = styled.form`
    padding-top: 5px;
    position: relative;
    margin: 0 auto;
`;

const StyledLoader = styled.div`
 display: inline-block;
`;

class SupportModal extends React.Component {
    getUserSupport = () => {
        if (!!this.props.userCountry && supportProperties[this.props.userCountry]) {
            return supportProperties[this.props.userCountry].DEALER;
        } else if (!!localeToCodeMapping[this.props.language] && supportProperties[localeToCodeMapping[this.props.language]]) {
            return supportProperties[localeToCodeMapping[this.props.language]].EDITOR;
        } else {
            return supportProperties.DEFAULT.EDITOR;
        }
    };

    save = (data) => {
        this.props.saveModal(data);
    };

    close = () => {
        this.props.reset();
        this.props.closeModal();
    };

    render() {
        const {show, handleSubmit, pristine, submitting, isLoading, selectedProfile, initialValues, profiles = [], initiateCountdown, count} = this.props;
        const timezone = moment.tz(moment.tz.guess()).format('Z');
        const countrySupport = this.getUserSupport();

        return (
            <ModalWindow isOpen={show}
                         onRequestClose={this.close}
                         customStyles={customStyles}>
                {initiateCountdown ?
                    <div className="d-flex flex-column align-items-center mt-3">
                        <Countdown initiateCountdown={count}/>
                        <FormattedMessage {...supportMessages.PROFILE_CHANGE_INITIATED} />
                    </div> :
                    <StyledForm onSubmit={handleSubmit(this.save)}>
                        <div style={customHeaderStyles}>
                            <FormattedMessage {...supportMessages.INFORMATION} />
                        </div>
                        <table className='table table-striped table-sm'>
                            <tbody>
                            <tr>
                                <td className='font-weight-bold'>
                                    <FormattedMessage {...supportMessages.RESP_HELP_DESK} />{':'}
                                </td>
                                <td>{countrySupport.responsible}</td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold'><FormattedMessage {...supportMessages.PHONE} />{':'}
                                </td>
                                <td>{countrySupport.phone}</td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold'><FormattedMessage {...supportMessages.EMAIL} />{':'}
                                </td>
                                <td>{countrySupport.email}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div style={customHeaderStyles}>
                            <FormattedMessage {...supportMessages.USER_CUSTOMIZATION} />
                        </div>
                        <table className='table table-striped table-sm'>
                            <tbody>
                            <tr>
                                <td className='font-weight-bold'>
                                    <FormattedMessage {...supportMessages.PROFILE} />{':'}
                                </td>
                                <td>
                                    <InputSelectFieldWithValidation field='profileIpn'
                                                                    customOptionsMsg={profiles.map(e => ({
                                                                        uuid: e.ipn,
                                                                        presetName: e.ipn + (e.dealerNumber ? ` (${e.dealerNumber})` : '')
                                                                    }))}
                                                                    noEmpty/>
                                </td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold'>
                                    <FormattedMessage {...supportMessages.LANGUAGE} />{':'}
                                </td>
                                <td>
                                    <InputSelectFieldWithValidation field='language'
                                                                    options={languages}
                                                                    noEmpty
                                                                    intlMessages={localesMessages}/>
                                </td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold'>
                                    <FormattedMessage {...supportMessages.LOCATION} />{':'}
                                </td>
                                <td>
                                    <InputSelectFieldWithValidation field='locale'
                                                                    options={locations}
                                                                    noEmpty
                                                                    intlMessages={locationsMessages}/>
                                </td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold'>
                                    <FormattedMessage {...supportMessages.TIMEZONE} />{':'}
                                </td>
                                <td>{'UTC' + timezone}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div style={customHeaderStyles}>
                            <FormattedMessage {...supportMessages.MULTI_SOURCING_COUNTRIES} />
                        </div>
                        <div className="text-center">
                            {['PL', 'DE', 'HU', 'IT'].map((country, index) => <React.Fragment
                                key={index}>{index !== 0 ? ', ' : ''}<ValidFormattedMessage
                                message={country} intlMessages={countryGroupMessages}/></React.Fragment>)}
                        </div>
                        <ModalFooter>
                            <div className="row">
                                <div className="col">
                                    <PrimaryButton type="submit" className="btn" disabled={initiateCountdown || isLoading || pristine || submitting}>
                                        <FormattedMessage {...(selectedProfile && selectedProfile !== get(initialValues, 'profileIpn') ? supportMessages.SWITCH_PROFILE : buttonMessages.SAVE)}/>
                                    </PrimaryButton>
                                    {isLoading &&
                                    <StyledLoader className="pl-2 py-auto">
                                        <Loader isSmall/>
                                    </StyledLoader>
                                    }
                                </div>
                            </div>
                        </ModalFooter>
                    </StyledForm>
                }
            </ModalWindow>
        )
    }
}

SupportModal.propTypes = {
    show: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
    userIpn: state.profile.userDetail.ipn,
    userDealerNumber: state.profile.userDetail.dealerNumber,
    profiles: state.profile.userDetail.profiles,
    roles: state.profile.userDetail.roles,
    userCountry: state.profile.userDetail.group,
    language: state.intl.language,
    locale: state.intl.locale,
    isLoading: state.supportModal.isLoading,
    initiateCountdown: state.supportModal.initiateCountdown,
    count: state.supportModal.count,
    selectedProfile: formValueSelector(props.form)(state, 'profileIpn'),
    initialValues: getFormInitialValues(props.form)(state),
});

export default reduxForm({
    form: 'SupportModalForm',
    enableReinitialize: true,
})(connect(mapStateToProps, {})(SupportModal));
