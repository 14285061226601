import React from 'react';
import {connect} from "react-redux";
import ValidFormattedMessage from "../../../../../common/ValidFormattedMessage";
import dicMessages from "../../../../../../intl/common/dicMessages";
import PropTypes from 'prop-types';

const RenderDicValidations = (props) => {
    const {dicValidationMessages} = props;
    return (
        <div className="text-center mb-3">
            <strong>
                {dicValidationMessages.map((message, index) => {
                    return (<div key={index} className="alert alert-warning m-1 p-1" role="alert">
                        <ValidFormattedMessage message={message} intlMessages={dicMessages}/>
                    </div>)
                })}
            </strong>
        </div>
    )
}

const mapStateToProps = (state) => ({
    dicValidationMessages: state.sparePartsDic.dicValidationMessages,
});

RenderDicValidations.propTypes = {
    dicValidationMessages: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, null)(RenderDicValidations);
