import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    MODAL_TITLE: {
        id: 'admin.clientId.modal.title',
        defaultMessage: 'Add client ID record'
    },
    MODAL_DEALER_NUMBER: {
        id: 'admin.clientId.modal.dealerNumber',
        defaultMessage: 'Dealer number'
    },
    MODAL_NAME: {
        id: 'admin.clientId.modal.dealerName',
        defaultMessage: 'Dealer name'
    },
    MODAL_CLIENT_ID: {
        id: 'admin.clientId.modal.clientId',
        defaultMessage: 'Client ID'
    },
    MODAL_DESTINATION_CODE: {
        id: 'admin.clientId.modal.destinationCode',
        defaultMessage: 'Destination code'
    },
    MODAL_REGION: {
        id: 'admin.clientId.modal.dealerRegion',
        defaultMessage: 'Dealer region'
    },
    PAGE: {
        id: 'admin.clientId.page',
        defaultMessage: 'Page'
    },
    SHOW_FIRST: {
        id: 'admin.clientId.table.pagination.showFirst',
        defaultMessage: 'Show first'
    },
    SHOW_PREV: {
        id: 'admin.clientId.table.pagination.showPrev',
        defaultMessage: 'Show prev'
    },
    SHOW_NEXT: {
        id: 'admin.clientId.table.pagination.showNext',
        defaultMessage: 'Show next'
    },
    SHOW_LAST: {
        id: 'admin.clientId.table.pagination.showLast',
        defaultMessage: 'Show last'
    },
    TOTAL_RESULTS: {
        id: 'admin.clientId.totalResults',
        defaultMessage: 'Total results'
    },
    TABLE_DEALER_NUMBER: {
        id: 'admin.clientId.table.dealerNumber',
        defaultMessage: 'Dealer number'
    },
    TABLE_DEALER_NAME: {
        id: 'admin.clientId.table.dealerName',
        defaultMessage: 'Dealer name'
    },
    TABLE_CLIENT_ID: {
        id: 'admin.clientId.table.clientId',
        defaultMessage: 'Client ID'
    },
    TABLE_DESTINATION_CODE: {
        id: 'admin.clientId.table.destinationCode',
        defaultMessage: 'Destination code'
    },
    TABLE_DEALER_REGION: {
        id: 'admin.clientId.table.dealerRegion',
        defaultMessage: 'Dealer region'
    },
    TABLE_NO_RESULTS: {
        id: 'admin.clientId.table.noResults',
        defaultMessage: 'No results'
    },
    TITLE: {
        id: 'admin.clientId.title',
        defaultMessage: 'Setting Client ID'
    },
    SELECT_COUNTRY: {
        id: 'admin.clientId.selectCountry',
        defaultMessage: 'Select a country'
    },
    RECORDS_PER_PAGE: {
        id: 'admin.clientId.recordsPerPage',
        defaultMessage: 'Per page'
    }
});

export default messages;
