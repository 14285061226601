import get from 'get-value';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';
import {
    CLAIMS_RECURRENT_PROBLEM_PENDING,
    CLAIMS_RECURRENT_PROBLEM_SELECT_GROUP
} from '../../actions/claims/actionClaimsRecurrentProblem';

export const claimsRecurrentProblem = (state = {
    isLoading: false,
    recurrentProblems: [],
    selectedCountryGroup: '',
}, action) => {
    switch (action.type) {
        // load data
        case CLAIMS_RECURRENT_PROBLEM_PENDING: {
            return Object.assign({}, state, {isLoading: true, recurrentProblems: []});
        }
        case CLAIMS_RECURRENT_PROBLEM_SELECT_GROUP: {
            return Object.assign({}, state, {selectedCountryGroup: action.payload});
        }

        // command accepted
        case claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                recurrentProblems: get(action, 'payload.recurrentProblems', {default: []}),
                correlationId
            });
        }

        // error
        case claimsWebsocketCommands.CLAIMS_RECURRENT_PROBLEMS_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, recurrentProblems: [], correlationId});
        }

        default:
            return state;
    }
};
