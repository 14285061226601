import {
    DOC_POPUP_FETCH_COMMAND,
    DOC_POPUP_MODAL_CONFIRM,
    DOC_POPUP_MODAL_OPEN
} from '../../actions/techDoc/actionDocPopUpModal';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import get from 'get-value';

export const docPopUpModal = (state = {
    isOpen: false,
    isConfirm: false,
    isLoading: false,
    popUp: {},
}, action) => {
    switch (action.type) {
        case DOC_POPUP_FETCH_COMMAND: {
            return Object.assign({}, state, {isLoading: true});
        }
        case techDocWebsocketCommands.TECH_DOC_POPUP_SEND: {
            const {correlationId} = action.payload;

            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                popUp: get(action, 'payload.popup', {default: {}}),
                correlationId
            });
        }
        case techDocWebsocketCommands.TECH_DOC_POPUP_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, popUp: {}, correlationId});
        }
        case DOC_POPUP_MODAL_OPEN: {
            return Object.assign({}, state, {isOpen: true});
        }
        case DOC_POPUP_MODAL_CONFIRM: {
            return Object.assign({}, state, {isOpen: false, isConfirm: true});
        }
        default:
            return state;
    }
};

