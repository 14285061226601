import {CLAIMS_DOWNLOAD_PRESIGNED_URL, CLAIMS_CREATE_ATTACHMENTS} from '../../constants/websocketCommands';
import {extractErrorText} from '../actionUtils';
import {errorAlert} from '../alertsActions';
import {uploadClaimsAttachment} from '../../api/claims/restClaimsAttachments';
import alertMessages from '../../intl/common/alertMessages';

export const CLAIMS_ATTACHMENTS_UPLOADING_ERROR = 'CLAIMS_ATTACHMENTS_UPLOADING_ERROR';
export const CLAIMS_ATTACHMENTS_UPLOADING = 'CLAIMS_ATTACHMENTS_UPLOADING';

export const startUploadClaimsAttachments = () => dispatch => {
    return dispatch({type: CLAIMS_ATTACHMENTS_UPLOADING});
};

export const downloadClaimsAttachment = (uuid, ticketId, name) => dispatch => {
    return dispatch({
        type: CLAIMS_DOWNLOAD_PRESIGNED_URL,
        claimsWebsocket: true,
        command: CLAIMS_DOWNLOAD_PRESIGNED_URL,
        payload: {
            attachment: {
                uuid,
                ticketId,
                name
            }
        }
    });
};

export const uploadClaimsAttachments = (attachments, correlationId) => dispatch => {
    const promises = [];
    const savedAttachments = [];
    attachments.forEach(attachment => {
        promises.push(
            uploadClaimsAttachment(attachment.url, attachment.localUrl, attachment.name, attachment.contentType).then(
                () => {
                    window.URL.revokeObjectURL(attachment.localUrl);
                    savedAttachments.push(attachment);
                }, error => {
                    dispatch({type: CLAIMS_ATTACHMENTS_UPLOADING_ERROR});
                    dispatch(errorAlert(...extractErrorText(error, alertMessages.FILE_UPLOAD_ERROR)));
                }
            )
        )
    });
    return Promise.all(promises).then(() => {
            if (savedAttachments.length > 0) {
                dispatch({
                    type: CLAIMS_CREATE_ATTACHMENTS,
                    claimsWebsocket: true,
                    command: CLAIMS_CREATE_ATTACHMENTS,
                    payload: {
                        attachments: savedAttachments,
                        correlationId,
                        uuid: savedAttachments[0].ticketId,
                    }
                })
            } else {
                dispatch({
                    type: CLAIMS_ATTACHMENTS_UPLOADING_ERROR,
                })
            }
        }
    )
};
