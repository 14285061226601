import get from 'get-value';
import {SPARE_PARTS_SEARCH_ENGINE_FETCH_PENDING} from '../../actions/orders/actionSparePartsSearchEnginePage';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';


export const sparePartsSearchEnginePage = (state = {
    isLoading: false,
    isTableLoading: false,
    searchEngineData: [],
    pages: 0,
    isNextFromPivot: true,
    isNextPage: false,
}, action) => {
    switch (action.type) {
        case SPARE_PARTS_SEARCH_ENGINE_FETCH_PENDING: {
            return Object.assign({}, state, { isTableLoading: true, searchEngineData: [] });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_SEARCH_ENGINE_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isTableLoading: false,
                searchEngineData: get(action, 'payload.result.data', { default: [] }),
                pages: get(action, 'payload.result.pages', { default: 0 }),
                isNextFromPivot: get(action, 'payload.result.isNextFromPivot', false),
                isNextPage: get(action, 'payload.result.isNextPage', false),
                correlationId
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_SEARCH_ENGINE_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, { isTableLoading: false , searchEngineData: [], correlationId });
        }
        default:
            return state;
    }
};
