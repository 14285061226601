import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import 'react-table/react-table.css';
import {debounce} from 'throttle-debounce';
import {Link} from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import Table from '../../common/table/TicketTable';
import {fetchSparePartsInternalTicketHistoryPageData} from '../../../actions/orders/actionSparePartsInternalTicketHistoryPage';
import historyMessages from '../../../intl/sparePartsOrders/historyMessages';
import {formatInternalTicketNumber} from '../../../utils/utils';
import get from 'get-value';

class HistorySparePartsInternalTicketTable extends Component {
    constructor(props) {
        super(props);
        this.state = this.loadStateFromSessionStorage({
            historyInternalSPDefaultPageSize: 10,
            historyInternalSPDefaultSort: [{id: 'TICKET_NUMBER', desc: true}],
            historyInternalSPDefaultFilters: [],
        });
        this.filtering = false;
        this.handleFetchDataDebounced = debounce(1000, this.handleFetchData);
    }

    componentDidMount() {
        window.addEventListener(
            'beforeunload',
            this.saveStateToSessionStorage
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            'beforeunload',
            this.saveStateToSessionStorage
        );
        this.saveStateToSessionStorage();
    }

    loadStateFromSessionStorage(defaults) {
        return Object.keys(defaults)
            .reduce((acc, key) => {
                if (sessionStorage.hasOwnProperty(key) && key.match(/^historyInternalSP*/)) {
                    let value = sessionStorage.getItem(key);
                    value = JSON.parse(value);
                    return {
                        ...acc,
                        [key]: value,
                    };
                }
                return acc;
            }, defaults);
    }

    saveStateToSessionStorage = () => {
        for (let key in this.state) {
            if (this.state.hasOwnProperty(key) && key.match(/^historyInternalSP*/)) {
                sessionStorage.setItem(key, JSON.stringify(this.state[key]));
            }
        }
    }

    handleFetchData = state => {
        this.props.fetchSparePartsInternalTicketHistoryPageData(state.pageSize, state.sorted, state.cursor, state.isNextFromPivot, this.normalizeFiltered(state));
        this.filtering = false;
        this.setState({
            historyInternalSPDefaultPageSize: state.pageSize,
            historyInternalSPDefaultSort: state.sorted,
            historyInternalSPDefaultFilters: state.filtered,
        });
    };

    fetchStrategy = (state) => {
        this.filtering ? this.handleFetchDataDebounced(state) : this.handleFetchData(state);
    };

    normalizeFiltered = state => {
        return state.filtered
            .filter(filterItem => filterItem.value)
            .map(filterItem => {
                if (filterItem.value instanceof Date || filterItem.id === 'IT_CREATION_TIME' || filterItem.id === 'LAST_UPDATE') {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10),
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const {columns, data, pages, isLoading, isNextPage, isNextFromPivot} = this.props;
        return (
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'TICKET_NUMBER',
                                    accessor: 'ticketNumber',
                                    message: historyMessages.TABLE_INTERNAL_TICKET_NUMBER,
                                    Cell: row => <Link
                                        to={`view/${row.original.id}`}>{get(row, 'original.pfxNumber') ? formatInternalTicketNumber(get(row, 'original.pfxNumber'), get(row, 'original.sfxNumber')) : ''}</Link>,
                                },
                                ...columns]}
                            isLoading={isLoading}
                            data={data}
                            pages={pages}
                            handleFetchData={this.fetchStrategy}
                            handleOnFilteredChange={() => {
                                this.filtering = true;
                            }}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={this.state.historyInternalSPDefaultFilters}
                            defaultSorting={this.state.historyInternalSPDefaultSort}
                            defaultPageSize={this.state.historyInternalSPDefaultPageSize}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

HistorySparePartsInternalTicketTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    pages: PropTypes.number.isRequired,
    fetchSparePartsInternalTicketHistoryPageData: PropTypes.func.isRequired,
    columns: PropTypes.array,
};

const mapStateToProps = state => ({
    isLoading: state.sparePartsInternalTicketHistoryPage.isTableLoading,
    data: state.sparePartsInternalTicketHistoryPage.listData,
    isNextPage: state.sparePartsInternalTicketHistoryPage.isNextPage,
    isNextFromPivot: state.sparePartsInternalTicketHistoryPage.isNextFromPivot,
    pages: state.sparePartsInternalTicketHistoryPage.pages,
});

export default connect(mapStateToProps, {fetchSparePartsInternalTicketHistoryPageData})(HistorySparePartsInternalTicketTable);
