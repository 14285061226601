import Joi from 'joi';

export function validateAnsweringSchemaCreateEvent(escalationSchemaCreateEvent) {
    return Joi.validate(
        escalationSchemaCreateEvent,
        Joi.object().required().keys({
            id: Joi.string().required().guid({version: ['uuidv4']}),
            name: Joi.string().required(),
            answeringSchema: Joi.object().required(),
        })
    );
}

export function validateAnsweringSchemaUpdateEvent(escalationSchemaUpdateEvent) {
    return Joi.validate(
        escalationSchemaUpdateEvent,
        Joi.object().required().keys({
            id: Joi.string().required().guid({version: ['uuidv4']}),
            name: Joi.string().required(),
            answeringSchema: Joi.object().required(),
        })
    );
}
