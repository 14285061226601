import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';
import {domains} from '../../constants/Utils';

export const ADMIN_PRESET_RESPONSE_SELECT_GROUP = 'ADMIN_PRESET_RESPONSE_SELECT_GROUP';
export const ADMIN_PRESET_RESPONSE_FETCH = 'ADMIN_PRESET_RESPONSE_FETCH';
export const ADMIN_PRESET_RESPONSE_ADD = 'ADMIN_PRESET_RESPONSE_ADD';
export const ADMIN_PRESET_RESPONSE_EDIT = 'ADMIN_PRESET_RESPONSE_EDIT';
export const ADMIN_PRESET_RESPONSE_UPDATE = 'ADMIN_PRESET_RESPONSE_UPDATE';
export const ADMIN_PRESET_RESPONSE_MOVE = 'ADMIN_PRESET_RESPONSE_MOVE';
export const ADMIN_PRESET_RESPONSE_SWITCH = 'ADMIN_PRESET_RESPONSE_SWITCH';
export const ADMIN_PRESET_RESPONSE_REMOVE = 'ADMIN_PRESET_RESPONSE_REMOVE';

export const storeSelectedCountryGroup = countryGroup => dispatch =>
    dispatch({
        type: ADMIN_PRESET_RESPONSE_SELECT_GROUP,
        payload: countryGroup
    });

export const fetchPresetResponse = (domain, group) => dispatch => {
    switch (domain) {
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_PRESET_RESPONSE_FETCH,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_PRESET_RESPONSE_GET,
                payload: {group}
            });
        }
    }
};

export const addPresetResponse = (domain, presetResponse) => dispatch => {
    switch (domain) {
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_PRESET_RESPONSE_ADD,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_PRESET_RESPONSE_CREATE,
                payload: {presetResponse}
            });
        }
    }
};

export const editPresetResponse = index => dispatch => {
    dispatch({
        type: ADMIN_PRESET_RESPONSE_EDIT,
        payload: {index}
    });
};

export const updatePresetResponse = (domain, presetResponse) => dispatch => {
    switch (domain) {
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_PRESET_RESPONSE_UPDATE,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_PRESET_RESPONSE_UPDATE,
                payload: {presetResponse}
            });
        }
    }
};

export const movePresetResponse = (domain, presetResponse, oldIndex) => dispatch => {
    switch (domain) {
        case domains.CLAIMS: {
            dispatch({
                type: ADMIN_PRESET_RESPONSE_SWITCH,
                payload: {oldIndex, newIndex: presetResponse.position, presetResponse}
            });
            return dispatch({
                type: ADMIN_PRESET_RESPONSE_MOVE,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_PRESET_RESPONSE_MOVE,
                payload: {presetResponse}
            });
        }
    }
};

export const removePresetResponse = (domain, presetResponse) => dispatch => {
    switch (domain) {
        case domains.CLAIMS: {
            return dispatch({
                type: ADMIN_PRESET_RESPONSE_REMOVE,
                claimsWebsocket: true,
                command: claimsWebsocketCommands.CLAIMS_PRESET_RESPONSE_DELETE,
                payload: {presetResponse}
            });
        }

    }
};


