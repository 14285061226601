import React, {Component} from 'react';
import { connect } from 'react-redux';
import {change, formValueSelector, getFormInitialValues, getFormValues} from 'redux-form';
import messages from '../../../../../../../intl/common/countryGroupMessages';
import { countryGroups } from '../../../../../../../constants/Utils';
import Select from 'react-select';
import {
    FORM_FIELD_SG_COUNTRIES,
    FORM_LABEL_SG_COUNTRIES,
    FORM_SOLUTION_GROUP_COUNTRY_SECTION,
} from '../../../../../../../constants/formConstants';
import {FormattedMessage, injectIntl} from 'react-intl';
import formMessages from '../../../../../../../intl/admin/solutionGroupMessages';
import {Label} from '../../../../../../common/StyledComponents';
import BorderRadius from '../../../../../../common/styled/BorderRadius';


class CountrySection extends Component {

    handleCountrySelectionChange = newOptions => {
        const { change, intl: {formatMessage} } = this.props;

        const newOptionsToSave = newOptions.map(element => {
            return {
                value: element.value,
                label: element.label
            };
        });

        const uniqueOptionsToSave = Array.from(new Set(newOptionsToSave.map(x => x.value)))
            .map(value => {
                return {
                    value: value,
                    label: newOptionsToSave.find(y => y.value === value).label
                }
            });

        change(`${FORM_SOLUTION_GROUP_COUNTRY_SECTION}.${FORM_FIELD_SG_COUNTRIES}`, uniqueOptionsToSave);
    };

    render() {
        const { readOnly, groups, intl: {formatMessage} } = this.props;

        const countryOptions = Object.keys(countryGroups)
                .map(country => ({
                    label: messages[country] ? formatMessage(messages[country]) : country,
                    value: country
                }));


        return (
            <>
                <hr/>
                <BorderRadius size={5} className="border border-secondary px-4 py-2 bg-white">
                        <div className="row mb-sm-2">
                            <Label>
                                <FormattedMessage {...formMessages[FORM_LABEL_SG_COUNTRIES]} />
                            </Label>
                        </div>
                        <div className="row mb-sm-3">
                            <div className="col">
                                <Select
                                    isDisabled={readOnly}
                                    field={FORM_FIELD_SG_COUNTRIES}
                                    value={groups ? groups : ''}
                                    isMulti
                                    options={countryOptions}
                                    onChange={this.handleCountrySelectionChange}
                                />
                            </div>
                        </div>
                </BorderRadius>
            </>
        )
    }
}

const mapStateToProps = (state, initialProps) => ({
    solutionGroupData: state.adminSolutionGroup,
    formValues: getFormValues(initialProps.form)(state),
    initialValues: getFormInitialValues(initialProps.form)(state, `countrySection`),
    userDetail: state.profile.userDetail,
    groups: formValueSelector(initialProps.form)(state, `${FORM_SOLUTION_GROUP_COUNTRY_SECTION}.${FORM_FIELD_SG_COUNTRIES}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CountrySection));

