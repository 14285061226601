import React, {Component} from 'react';
import {connect} from 'react-redux';
import get from 'get-value';
import {Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {fetchSparePartsOrdersTicket, resetTicketOrder, updateTicketOrder} from '../../../actions/orders/actionTicket';
import Loader from '../../Loader';
import {
    FORM_BUTTON_ANSWER,
    FORM_BUTTON_CREATE_INTERNAL_TICKET, FORM_BUTTON_ESCALATE, FORM_BUTTON_FORWARD_TO_DEALER,
    FORM_BUTTON_MARK_AS_READ,
    FORM_BUTTON_TAKE_BACK,
    ORDER_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    ORDER_TICKET_STATUS_NEW,
    ORDER_TICKET_STATUS_READ_BY_SUBSIDIARY, OVAL_PLATE_ORDER_FORM,
} from '../../../constants/formConstants';
import {
    lockSparePartsOrdersTicket,
    resetRedirectAfterLockTicket,
    unlockSparePartsOrdersTicket
} from '../../../actions/orders/actionLockTicket';
import UploadingAttachments from './UploadingAttachments';
import {createInternalTicketSparePartsInitData, transformUpdateTicketForSave} from '../Utils';
import {resetSparePartDic} from '../../../actions/orders/actionSparePartsDicData';
import checkRoles from '../../common/roleChecker/RoleChecker';
import EditLoadTicketDataPage from './EditLoadTicketDataPage';
import {ORDERS_NEW_INTERNAL_TICKET} from '../../../routes/paths';
import {moduleRoles} from '../../../utils/roles';
import alertMessages from '../../../intl/common/alertMessages';
import {editRights} from '../../../constants/sparePartsTicketRights';
import {injectIntl} from 'react-intl';

class EditSparePartTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false, createInternalTicket: false, skippedReadBySubsidiaryStatus: false, fetchedNewTicketData: false};
    }

    componentWillUnmount() {
        const {uuid, redirect} = this.props.lockTicket;
        if (!redirect) {
            this.props.unlockSparePartsOrdersTicket(uuid);
        }
        this.props.resetRedirectAfterLockTicket();
        this.props.resetSparePartDic();
    }

    componentDidMount() {
        this.props.resetTicketOrder();
        this.props.resetSparePartDic();
        this.props.lockSparePartsOrdersTicket(this.props.match.params.ticketUuid);

        if (!this.props.location.redirectedAfterUpdate) {
            this.setState({...this.state, fetchedNewTicketData: true});
            this.props.fetchSparePartsOrdersTicket(this.props.match.params.ticketUuid);
        }
    }

    answeredToSuspiciousGroup = (submitBy, dataForSend) => {
        if (submitBy === FORM_BUTTON_ANSWER) {
            const assignedSolutionGroup = get(dataForSend, 'ticket.solutionGroup.id');
            return !this.props.previousSolutionGroups.includes(assignedSolutionGroup)
        }
    }

    missingEditorsNotes = (submitBy, dataForSend) => {
        if (submitBy === FORM_BUTTON_ANSWER || submitBy === FORM_BUTTON_ESCALATE) {
            const {loggedUser, ticketData} = this.props;

            const isEditor = moduleRoles.isEditorOrder(loggedUser) || moduleRoles.isSGEditorOrder(loggedUser);
            const rights = editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status];

            return rights.canAddEditorsNotes && !dataForSend.editorsNote;
        }
    }

    missingMessageToDealer = (submitBy, dataForSend) => {
        return submitBy === FORM_BUTTON_FORWARD_TO_DEALER && !dataForSend.note;
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const dataForSend = transformUpdateTicketForSave(values);

        if (this.answeredToSuspiciousGroup(submitBy, dataForSend)) {
            const groupName = get(dataForSend, 'ticket.solutionGroup.name');
            if (!window.confirm(this.props.intl.formatMessage(alertMessages.ESCALATION_SUSPICIOUS_GROUP_WARN, {groupName}))) {
                return;
            }
        }
        if (this.missingEditorsNotes(submitBy, dataForSend)) {
            if (!window.confirm(this.props.intl.formatMessage(alertMessages.ESCALATION_MISSING_EDITORS_NOTES_GROUP_WARN))) {
                return;
            }
        }
        // if (this.missingMessageToDealer(submitBy, dataForSend)) {
        //     if (!window.confirm(this.props.intl.formatMessage(alertMessages.ANSWER_TO_DEALER_MISSING_MESSAGE_WARN))) {
        //         return;
        //     }
        // }
        if (submitBy === FORM_BUTTON_CREATE_INTERNAL_TICKET) {
            const initData = createInternalTicketSparePartsInitData(values.newValues);
            this.setState({
                ...this.state,
                ticketSubmittedAndShouldRedirect: true,
                createInternalTicket: true,
                createInternalTicketData: initData
            })
        } else {
            this.props.updateTicketOrder(dataForSend);
            if (!([FORM_BUTTON_MARK_AS_READ, FORM_BUTTON_TAKE_BACK].includes(submitBy))) {
                this.setState({...this.state, ticketSubmittedAndShouldRedirect: true});
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.ticketSubmittedAndShouldRedirect && !this.state.ticketSubmittedAndShouldRedirect
            && prevProps.ticketData.isTicketCreating && !this.props.ticketData.isTicketCreating
            && this.props.ticketData.isTicketLoading) {
            this.props.lockSparePartsOrdersTicket(this.props.match.params.ticketUuid);
        }
    }

    render() {
        const {
            ticketData,
            attachmentsData,
            lockTicket,
            loggedUser
        } = this.props;

        if (lockTicket.redirect) {
            return <Redirect to={`/order/view/${lockTicket.uuid}`}/>;
        }
        if (this.state.createInternalTicket) {
            return <Redirect to={{
                pathname: ORDERS_NEW_INTERNAL_TICKET,
                initData: this.state.createInternalTicketData,
            }}/>
        }

        let formType = false;
        if (get(ticketData, 'ticket.orderType', {default: false})) {
            formType = ticketData.ticket.orderType;
        }

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingAttachments attachments={attachmentsData.attachmentsForUpload}
                                         attachmentsUrl={ticketData.newAttachments}
                                         correlationId={ticketData.correlationId}
                                         isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                         formType={formType}/>
        }

        if (ticketData.isTicketLoading
            || ticketData.isTicketCreating
            || lockTicket.isTicketLocking
            || !formType
        ) {
            return <Loader/>;
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/order/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (get(ticketData, 'ticket.uuid', {default: false})
            && [ORDER_TICKET_STATUS_NEW, ORDER_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY].includes(get(ticketData, 'ticket.status', {default: false}))
            && (moduleRoles.isEditorOrder(loggedUser) || moduleRoles.isSGEditorOrder(loggedUser))
            && !this.state.skippedReadBySubsidiaryStatus
            && this.state.fetchedNewTicketData
        ) {
            this.setState({...this.state, skippedReadBySubsidiaryStatus: true});
            this.props.updateTicketOrder({
                ticket: {
                    pfxNumber: ticketData.ticket.pfxNumber,
                    sfxNumber: ticketData.ticket.sfxNumber,
                    status: ORDER_TICKET_STATUS_READ_BY_SUBSIDIARY,
                    uuid: ticketData.ticket.uuid,
                }
            });
            return <Loader/>;
        }

        return (
            <EditLoadTicketDataPage formType={formType} handleSubmit={this.handleSubmit}/>
        );
    }
}

EditSparePartTicketPage.propTypes = {
    resetRedirectAfterLockTicket: PropTypes.func.isRequired,
    resetTicketOrder: PropTypes.func.isRequired,
    fetchSparePartsOrdersTicket: PropTypes.func.isRequired,
    lockSparePartsOrdersTicket: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    updateTicketOrder: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    ticketData: PropTypes.object.isRequired,
    attachmentsData: PropTypes.object.isRequired,
    lockTicket: PropTypes.object.isRequired,
    unlockSparePartsOrdersTicket: PropTypes.func.isRequired,
    resetSparePartDic: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired,
};

const getPreviousSolutionGroups = (state) => {
    const logs = get(state, 'sparePartsOrderTicket.logs') || [];

    const fromIds = logs.map(item => item.solutionGroup && item.solutionGroup.id);
    const toIds = logs.map(item => item.toSolutionGroup && item.toSolutionGroup.id);

    return [...new Set([...fromIds, ...toIds])];
}

const mapStateToProps = (state) => ({
    ticketData: state.sparePartsOrderTicket,
    attachmentsData: state.sparePartsOrderAttachment,
    lockTicket: state.sparePartsOrderLockTicketCheck,
    loggedUser: state.profile.userDetail.roles,
    previousSolutionGroups: getPreviousSolutionGroups(state)
});

export default checkRoles(withRouter(connect(mapStateToProps, {
    fetchSparePartsOrdersTicket,
    updateTicketOrder,
    resetTicketOrder,
    lockSparePartsOrdersTicket,
    resetRedirectAfterLockTicket,
    unlockSparePartsOrdersTicket,
    resetSparePartDic
})(injectIntl(EditSparePartTicketPage))), ['SP_DEALER', 'SP_EDITOR', 'SP_SG_EDITOR']);
