import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {InputFieldWithValidation} from '../../../../../common/InputFieldWithValidation';
import formMessages from '../../../../../../intl/sparePartsOrders/formMessages';
import {
    BUTTON_LABEL_LOAD, FORM_BATTERY_PROBLEM_DESCRIPTION_SECTION,
    FORM_FIELD_CATALOG_NUMBER, FORM_FIELD_DESCRIPTION, FORM_FIELD_MANAGEMENT_GROUP,
    FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM,
    FORM_FIELD_N_REFERENCE_FOR_REPLACEMENT,
    FORM_FIELD_PART_MARKING, FORM_FIELD_PART_NO,
    FORM_FIELD_PLATE_NUMBER, FORM_FIELD_TYPE_OF_REQUEST,
    FORM_LABEL_CATALOG_NUMBER, FORM_LABEL_MANAGEMENT_GROUP,
    FORM_LABEL_N_REFERENCE_CAUSING_PROBLEM,
    FORM_LABEL_N_REFERENCE_FOR_REPLACEMENT,
    FORM_LABEL_PART_MARKING,
    FORM_LABEL_PLATE_NUMBER, FORM_MESSAGES_SECTION, FORM_PART_DETAIL_SECTION, FORM_TICKET_SECTION
} from '../../../../../../constants/formConstants';
import {change, formValueSelector, getFormInitialValues} from 'redux-form';
import {
    PART_DOC_REQUEST,
    REF_REPLACEMENT_REQUEST,
    REQUEST_TO_OPEN_FOR_SALE,
    VISUAL_STORE_CHECK
} from '../../../../../../constants/Utils';
import {moduleRoles} from '../../../../../../utils/roles';
import {isEmpty} from 'lodash';
import get from 'get-value';
import {validAlphanumericInput} from '../../../../../common/validationCommons';
import {PrimaryButton} from '../../../../../common/Button';
import ValidFormattedMessage from '../../../../../common/ValidFormattedMessage';
import buttonMessages from '../../../../../../intl/common/buttonMessages';
import {bindActionCreators} from 'redux';
import {
    fetchDicSparePartInfoActionCreator,
    setDicSparePartInfoActionCreator
} from '../../../../../../actions/orders/actionSparePartsDicData';
import {warnAlert} from '../../../../../../actions/alertsActions';
import warnMessages from '../../../../../../intl/common/warnMessages';
import {size} from 'lodash'

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const PartDetailSection = (props) => {
    const {readOnly, userRights, form, reqFields, intl: {formatMessage}, selectedRequestType, user: {roles}, solutionGroupInfo, referenceCausingProblem} = props;

    const handlePartLoad = () => {
        const {fetchDicSparePartInfoActionCreator, referenceCausingProblem} = props;
        fetchDicSparePartInfoActionCreator(referenceCausingProblem);
    };

    const fillDicSparePartInfo = () => {
        const {dicInfo, change, setDicSparePartInfoActionCreator, warnAlert, userRights} = props;
        const order = dicInfo.partNo;
        setDicSparePartInfoActionCreator();
        if (order) {
            if (!order[FORM_FIELD_MANAGEMENT_GROUP]) {
                change(`${FORM_PART_DETAIL_SECTION}.${FORM_FIELD_MANAGEMENT_GROUP}`, dicInfo.managementGroup);
            } else {
                warnAlert(warnMessages.SP_DIC_WARN004, null, {partNumber: dicInfo.partNo})
            }
        } else {
            warnAlert(warnMessages.SP_DIC_WARN003, null, {partNumber: dicInfo.partNo});
        }
    };

    if (props.dicInfo.dicPartInfoLoaded) {
        fillDicSparePartInfo();
    }

    return (
        <>

            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.PART_DETAIL_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                        <InputFieldWithValidation label={formMessages[FORM_LABEL_N_REFERENCE_CAUSING_PROBLEM]}
                                                  field={FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM}
                                                  maxLength={10}
                                                  isDisabled={!userRights.canEditInternalTicketPartDetail || readOnly}
                                                  isRequired={reqFields.includes(FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM)}
                                                  isSmall/>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2 ml-1">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_MANAGEMENT_GROUP]}
                                                      field={FORM_FIELD_MANAGEMENT_GROUP}
                                                      maxLength={2}
                                                      isDisabled={!userRights.canEditInternalTicketManagementGroup || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_MANAGEMENT_GROUP)}
                                                      validations={[validAlphanumericInput]}
                                                      isSmall/>
                            {!readOnly && userRights.canEditInternalTicketManagementGroup &&
                                <>
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 pl-1 pt-1">
                                        <PrimaryButton type="button" className="btn"
                                                       disabled={size(referenceCausingProblem) < 10}
                                                       onClick={() => handlePartLoad()}
                                        >
                                            <ValidFormattedMessage message={BUTTON_LABEL_LOAD}
                                                                   intlMessages={buttonMessages}/>
                                        </PrimaryButton>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {selectedRequestType === REF_REPLACEMENT_REQUEST &&
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-2">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_N_REFERENCE_FOR_REPLACEMENT]}
                                                      field={FORM_FIELD_N_REFERENCE_FOR_REPLACEMENT}
                                                      maxLength={10}
                                                      isDisabled={!userRights.canEditInternalTicketPartDetail || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_N_REFERENCE_FOR_REPLACEMENT)}
                                                      isSmall/>
                        </div>
                    }
                </div>
                <div className="form-row">
                    {(selectedRequestType === VISUAL_STORE_CHECK || selectedRequestType === REQUEST_TO_OPEN_FOR_SALE || selectedRequestType === PART_DOC_REQUEST || selectedRequestType === REF_REPLACEMENT_REQUEST) &&
                        <div className="col-xl-3 col-lg-6 col-md-12 pb-1">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_CATALOG_NUMBER]}
                                                      field={FORM_FIELD_CATALOG_NUMBER}
                                                      isDisabled={!userRights.canEditInternalTicketPartDetail || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_CATALOG_NUMBER)}
                                                      isSmall/>
                        </div>
                    }
                    {(selectedRequestType === VISUAL_STORE_CHECK || selectedRequestType === REQUEST_TO_OPEN_FOR_SALE || selectedRequestType === PART_DOC_REQUEST || selectedRequestType === REF_REPLACEMENT_REQUEST) &&
                        <div className="col-xl-3 col-lg-6 col-md-12 pb-1">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_PLATE_NUMBER]}
                            field={FORM_FIELD_PLATE_NUMBER}
                            isDisabled={!userRights.canEditInternalTicketPartDetail || readOnly}
                            isRequired={reqFields.includes(FORM_FIELD_PLATE_NUMBER)}
                            isSmall/>
                        </div>
                    }
                    {(selectedRequestType === VISUAL_STORE_CHECK || selectedRequestType === PART_DOC_REQUEST || selectedRequestType === REF_REPLACEMENT_REQUEST) &&
                        <div className="col-xl-3 col-lg-6 col-md-12 pb-1">
                            <InputFieldWithValidation label={formMessages[FORM_LABEL_PART_MARKING]}
                                                      field={FORM_FIELD_PART_MARKING}
                                                      isDisabled={!userRights.canEditInternalTicketPartDetail || readOnly}
                                                      isRequired={reqFields.includes(FORM_FIELD_PART_MARKING)}
                                                      isSmall/>
                        </div>
                    }
                </div>
            </StyledDiv>
        </>
    )
}

const mapStateToProps = (state, initialProps) => ({
    user: state.profile.userDetail,
    solutionGroupInfo: state.sparePartsOrderTicket.solutionGroupInfo,
    referenceCausingProblem: formValueSelector(initialProps.form)(state, `${FORM_PART_DETAIL_SECTION}.${FORM_FIELD_N_REFERENCE_CAUSING_PROBLEM}`),
    dicInfo: state.sparePartsDic,
});

const mapDispatchToProps = (dispatch, props) => ({
    ...bindActionCreators(
        {
            setDicSparePartInfoActionCreator,
            fetchDicSparePartInfoActionCreator,
            warnAlert,
        },
        dispatch,
    ),
    change: (name, value) => dispatch(change(props.form, name, value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PartDetailSection));
