import React from 'react';
import {getFormSyncErrors, reduxForm} from 'redux-form'
import {connect} from 'react-redux';
import {flatten} from 'flat';
import {FORM_FIELD_NEW_ATTACHMENTS, FORM_FIELDS_ORDER_ITEMS} from '../../constants/formConstants';
import validationMessages from '../../intl/common/validationMessages';
import ValidFormattedMessage from './ValidFormattedMessage';
import PropTypes from 'prop-types';

const RenderSyncErrors = (props) => {
    const {submitFailed, formSyncErrors, intlMessages, mapping} = props;
    const errors = formSyncErrors ? flatten(formSyncErrors) : {};

    return (
        <div className="text-center mb-3">
            {submitFailed && <strong>
                {Object.keys(errors).filter(e => typeof errors[e] === 'string').map((e, index) => {
                    const splitFieldName = e ? e.split('.') : [];
                    if (splitFieldName.includes(FORM_FIELDS_ORDER_ITEMS)) {
                        return (<div key={index} className="alert alert-danger m-1 p-1"
                                     role="alert">
                            <ValidFormattedMessage message={mapping[splitFieldName[splitFieldName.length - 3]]}
                                                   intlMessages={intlMessages}/>{` ${parseInt(splitFieldName[splitFieldName.length - 2]) + 1} | `}
                            <ValidFormattedMessage message={mapping[splitFieldName[splitFieldName.length - 1]]}
                                                   intlMessages={intlMessages}/>{' : '}
                            <ValidFormattedMessage message={errors[e]} intlMessages={validationMessages}/>
                        </div>)
                    } else if (splitFieldName.includes(FORM_FIELD_NEW_ATTACHMENTS)) {
                        return (<div key={index} className="alert alert-danger m-1 p-1"
                                     role="alert">
                            <ValidFormattedMessage message={mapping[splitFieldName[splitFieldName.length - 2]]}
                                                   intlMessages={intlMessages}>{' : '}</ValidFormattedMessage>
                            <ValidFormattedMessage message={errors[e]} intlMessages={validationMessages}/>
                        </div>)
                    }
                    return (<div key={index} className="alert alert-danger m-1 p-1"
                                 role="alert">
                        <ValidFormattedMessage message={mapping[splitFieldName[splitFieldName.length - 1]]}
                                               intlMessages={intlMessages}>{' : '}</ValidFormattedMessage>
                        <ValidFormattedMessage message={errors[e]} intlMessages={validationMessages}/>
                    </div>)
                })}
            </strong>}
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    formSyncErrors: getFormSyncErrors(props.form)(state),
});

RenderSyncErrors.propTypes = {
    submitFailed: PropTypes.any,
    formSyncErrors: PropTypes.object,
    intlMessages: PropTypes.object,
    mapping: PropTypes.object,
};

export default reduxForm({})(connect(mapStateToProps, null)(RenderSyncErrors));
