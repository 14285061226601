import {
    ESCALATION_SCHEMA_FORM, FORM_FIELD_ES_ESCALATION_GROUPS, FORM_FIELD_ES_FORMTYPE, FORM_FIELD_ES_SG_NAME,
} from './formConstants';

export const editReqFields = {
    'DEFAULT': {
        'EDITOR': {
            [ESCALATION_SCHEMA_FORM]: [
                FORM_FIELD_ES_SG_NAME,
                FORM_FIELD_ES_FORMTYPE,
                FORM_FIELD_ES_ESCALATION_GROUPS,
            ],
        }
    },
};

export const createReqFields = {
    'DEFAULT': {
        [ESCALATION_SCHEMA_FORM]: [
            FORM_FIELD_ES_SG_NAME,
            FORM_FIELD_ES_FORMTYPE,
            FORM_FIELD_ES_ESCALATION_GROUPS,
        ],
    }
};

