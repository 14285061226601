import React, {Component} from 'react';
import {DragDropContext} from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import DragAndDropList from './DragAndDropList';
import {PRIMARY_LIGHT_GREY} from '../../../theme/colors';
import commonMessages from "../../../intl/common/commonMessages";

const DroppableListHeader = styled.div`
    border-radius: 0.3em 0.3em 0 0;
    background-color: ${PRIMARY_LIGHT_GREY};
`;

const RightSelectorErrorLabel = styled.label`
    display: ${props => props.selectionIsInvalid ? 'block' : 'none'};
`;

class DragAndDropSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {availableItems: [], selectedItems: [], selectorDirty: false};
    }

    componentDidMount() {
        const {availableItems = [], selectedItems = []} = this.props;
        this.setState({availableItems, selectedItems, selectorDirty: false});
    }

    getList = id => {
        const id2List = {
            leftList: 'availableItems',
            rightList: 'selectedItems'
        };
        return this.state[id2List[id]];
    };

    onDragEnd = result => {
        const {source, destination} = result;
        const {
            formChange = () => {
            },
            formValuesMapping = {}
        } = this.props;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const availableItems = this.reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = {availableItems};

            if (source.droppableId === 'rightList') {
                state = {selectedItems: availableItems};
            }

            this.setState(
                state,
                () => {
                    formChange(formValuesMapping.availableItems, state.availableItems);
                    formChange(formValuesMapping.selectedItems, state.selectedItems);
                }
            );
        } else {
            const result = this.move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState(
                {
                    availableItems: result.leftList,
                    selectedItems: result.rightList,
                    selectorDirty: true
                }, () => {
                    formChange(formValuesMapping.availableItems, result.leftList);
                    formChange(formValuesMapping.selectedItems, result.rightList);
                }
            );
        }
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    getAvailableItemsSorted = () =>
        this.state.availableItems.sort((item1, item2) => item1.name.localeCompare(item2.name));

    selectionIsInvalid = () => {
        const {required = false} = this.props;
        const {selectedItems, selectorDirty} = this.state;
        return required && selectedItems.length === 0 && selectorDirty;
    };

    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <div className="row">
                    <div className="col-sm-6 col-lg-3">
                        <DroppableListHeader className="pt-sm-1 pl-sm-2">
                            <FormattedMessage {...commonMessages.DND_AVAILABLE}/>
                        </DroppableListHeader>
                        <DragAndDropList droppableId="leftList" draggableItems={this.getAvailableItemsSorted()}/>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <DroppableListHeader className="pt-sm-1 pl-sm-2">
                            <FormattedMessage {...commonMessages.DND_SELECTED}/>
                        </DroppableListHeader>
                        <DragAndDropList droppableId="rightList" draggableItems={this.state.selectedItems}/>
                        <RightSelectorErrorLabel className="invalid-feedback"
                                                 selectionIsInvalid={this.selectionIsInvalid()}>
                            Required
                        </RightSelectorErrorLabel>
                    </div>
                </div>
            </DragDropContext>
        );
    }
}

DragAndDropSelector.propTypes = {
    availableItems: PropTypes.array,
    selectedItems: PropTypes.array,
    formChange: PropTypes.func,
    formValuesMapping: PropTypes.object,
    required: PropTypes.bool
};

export default DragAndDropSelector;
