import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    GAR_RAISED_TO_FRANCE: {
        id: 'techDoc.internalStatus.option.garRaisedToFrance',
        defaultMessage: 'GAR raised to France'
    },
    STOCK_CHECK_REQUESTED: {
        id: 'techDoc.internalStatus.option.stockCheckRequested',
        defaultMessage: 'Stock check requested'
    },
    REQUEST_OPENING_FOR_SALE: {
        id: 'techDoc.internalStatus.option.requestOpeningForTheSale',
        defaultMessage: 'Opening request for sale transmitted'
    },
    REQUEST_CODE: {
        id: 'techDoc.internalStatus.option.requestCode',
        defaultMessage: 'Request code'
    },
    DIVERS: {
        id: 'techDoc.internalStatus.option.divers',
        defaultMessage: 'Divers'
    },
    SOLUTION: {
        id: 'techDoc.internalStatus.option.solution',
        defaultMessage: 'Solution'
    },
    E_KEYS: {
        id: 'techDoc.internalStatus.option.eKeys',
        defaultMessage: 'E-Keys'
    },
    PENDING_ICS: {
        id: 'techDoc.internalStatus.option.pendingIcs',
        defaultMessage: 'Pending ICS'
    },
    CONSULTATION: {
        id: 'techDoc.internalStatus.option.consultation',
        defaultMessage: 'Consultation - external department'
    },
    REQUEST_CHECK_ON_SHELF: {
        id: 'techDoc.internalStatus.option.requestCheckOnShelf',
        defaultMessage: 'Request to check on the shelf'
    },
    REQUEST_FOR_OPENING: {
        id: 'techDoc.internalStatus.option.requestForOpening',
        defaultMessage: 'Request for opening for sale transferred'
    },
    CLOSED: {
        id: 'techDoc.internalStatus.option.closed',
        defaultMessage: 'Closed'
    },
    CONTROL_STOCK_CDE: {
        id: 'techDoc.internalStatus.option.controlStockCde',
        defaultMessage: 'Control Stock CDE'
    },
    CONTROL_STOCK_DLPA: {
        id: 'techDoc.internalStatus.option.controlStockDlpa',
        defaultMessage: 'Control Stock DLPA'
    },
    KISTA: {
        id: 'techDoc.internalStatus.option.kista',
        defaultMessage: 'KISTA'
    },
    PENDING_E_KEYS: {
        id: 'techDoc.internalStatus.option.pendingEKeys',
        defaultMessage: 'Pending E-Keys'
    },
    PART_ORDERED: {
        id: 'techDoc.internalStatus.option.partOrdered',
        defaultMessage: 'Part ordered to France'
    },
});

export default messages;
