import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';
import get from 'get-value';
import {clearAlerts} from '../../../actions/alertsActions';
import Loader from '../../Loader';
import {
    FORM_BUTTON_ESCALATE,
    FORM_FIELD_TYPE_OF_REQUEST,
    FORM_TICKET_SECTION,
    SPARE_PARTS_INTERNAL_TICKET_FORM,
} from '../../../constants/formConstants';
import set from 'set-value';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import {
    initNewSparePartsInternalTicket,
    transformNewInternalTicketForSave,
} from '../Utils';
import {createRights} from '../../../constants/sparePartsInternalTicketRights';
import {createReqFields} from '../../../constants/sparePartsInternalTicketRequiredFields';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import PageHeader from '../../common/PageHeader';
import PropTypes from 'prop-types';
import checkRoles from '../../common/roleChecker/RoleChecker';
import InternalTicketMasterForm from './forms/InternalTicketMasterForm';
import {
    fetchSparePartsInternalTicketSgInfo,
    resetSparePartsInternalTicket,
    saveSparePartsInternalTicket
} from '../../../actions/orders/actionSparePartsInternalTicket';
import UploadingSparePartsInternalTicketAttachments from './UploadingSparePartsInternalTicketAttachments';
import {scrollContentWrapperToTop} from '../../../utils/utils';
import {formValueSelector} from 'redux-form';
import OverlayLoader from '../../OverlayLoader';
import formMessages from '../../../intl/sparePartsOrders/formMessages';
import alertMessages from '../../../intl/common/alertMessages';
import {injectIntl} from 'react-intl';
import {closeSparePartDicModal} from '../../../actions/orders/actionSparePartsDicData';

class NewSparePartsInternalTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false};
    }

    componentDidMount() {
        const {userDetail} = this.props;
        this.props.clearAlerts();
        this.props.resetSparePartsInternalTicket();
        if (get(userDetail, 'roles.SPARE_PARTS.sgEditor.sgPermission', []).length === 1) {
            this.props.fetchSparePartsInternalTicketSgInfo(get(userDetail, 'roles.SPARE_PARTS.sgEditor.sgPermission.0.id', ''));
        }
        scrollContentWrapperToTop();
    }

    missingEditorsNotes = (submitBy, dataForSend) => {
        if (submitBy === FORM_BUTTON_ESCALATE) {
            return !dataForSend.message;
        }
    }

    handleSubmit = (values) => {
        const {submitBy} = values;
        const editorSolutionGroups = get(this.props.userDetail, 'roles.SPARE_PARTS.sgEditor.sgPermission');
        const dataForSend = transformNewInternalTicketForSave(values.newValues, editorSolutionGroups);
        if (this.missingEditorsNotes(submitBy, dataForSend)) {
            if (!window.confirm(this.props.intl.formatMessage(alertMessages.ESCALATION_MISSING_EDITORS_NOTES_GROUP_WARN))) {
                return;
            }
        }
        this.props.saveSparePartsInternalTicket(dataForSend);
        this.setState({ticketSubmittedAndShouldRedirect: true});
    };

    render() {
        const {
            formType,
            ticketData,
            attachmentsData,
            group,
            selectedRequestType,
            dicInfo: {isModalActive},
            closeSparePartDicModal
        } = this.props;

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingSparePartsInternalTicketAttachments attachments={attachmentsData.attachmentsForUpload}
                                                attachmentsUrl={ticketData.newAttachments}
                                                correlationId={ticketData.correlationId}
                                                isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                                formType={formType}/>
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/order/internalTicket/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (!formType
            || this.state.ticketSubmittedAndShouldRedirect
            || ticketData.correlationId
        ) {
            return <Loader/>;
        }

        const initializationValue = initNewSparePartsInternalTicket(this.props.userDetail, this.props.location.initData);
        const requiredFields = createReqFields[createReqFields[group] ? group : 'DEFAULT'][selectedRequestType ? selectedRequestType : formType];

        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={<ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>}/>
                <div>
                    {formType === SPARE_PARTS_INTERNAL_TICKET_FORM &&
                    <InternalTicketMasterForm form={formType}
                                                    initialValues={set(initializationValue, 'ticketSection.docType', SPARE_PARTS_INTERNAL_TICKET_FORM)}
                                                    userRights={createRights}
                                                    reqFields={requiredFields}
                                                    enableReinitialize={true}
                                                    handleSubmit={this.handleSubmit}/>
                    }
                </div>
                <OverlayLoader active={isModalActive}
                               text={formMessages.WAIT_DIC}
                               handleClose={closeSparePartDicModal}/>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    userDetail: state.profile.userDetail,
    group: state.profile.userDetail.group,
    ticketData: state.sparePartsInternalTicket,
    attachmentsData: state.sparePartsInternalTicketAttachment,
    selectedRequestType: formValueSelector(props.formType)(state, `${FORM_TICKET_SECTION}.${FORM_FIELD_TYPE_OF_REQUEST}`),
    dicInfo: state.sparePartsDic,
});

NewSparePartsInternalTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    userDetail: PropTypes.object.isRequired,
    formType: PropTypes.string.isRequired,
    saveSparePartsInternalTicket: PropTypes.func.isRequired,
    fetchSparePartsInternalTicketSgInfo: PropTypes.func.isRequired,
    ticketData: PropTypes.object.isRequired,
    attachmentsData: PropTypes.object.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    saveSparePartsInternalTicket,
    resetSparePartsInternalTicket,
    fetchSparePartsInternalTicketSgInfo,
    closeSparePartDicModal
})(injectIntl(NewSparePartsInternalTicketPage))), ["SP_SG_EDITOR"]);

