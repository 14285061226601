import v4 from 'uuid/v4';
import {
    ALLOCATION_REQUEST_FORM,
    EV_BATTERY_FORM,
    FORM_EDITORS_NOTES_SECTION_SP,
    FORM_FIELD_CAR_LOCATION,
    FORM_FIELD_EDITORS_NOTES_SP,
    ORDER_TICKET_STATUS_NEW,
    VOR_PVI_ORDER_FORM
} from '../../constants/formConstants';
import get from 'get-value';
import {diff} from 'deep-object-diff';
import {NO} from '../../constants/Utils';
import {formatTicketNumber} from '../../utils/utils';

export function isEmptyObject(obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export const initLoadedTicket = (ticketData) => {
    const {dicNumber, lockIpn, lockTime, ...rest} = get(ticketData, 'ticket', {default:{}});
    return {
        ticketSection: {
            ...rest,
        },
        ordersSection: {
            dicNumber,
            orderItems: ticketData.orderItems,
        },
        vehicleDetail: ticketData.vehicleDetail,
        warehouse: ticketData.warehouse,
        carRequestsSection: {
            carRequests: ticketData.carRequests
        },
        batteryDetail: ticketData.batteryDetail,
        batteryProblemDescription: ticketData.batteryProblemDescription,
        notes: ticketData.notes,
        statusHistory: ticketData.logs,
        attachmentsSection: {attachments: ticketData.attachments},
        editorsNotes: ticketData.editorsNotes,
        internalTicketReferences: ticketData.internalTicketReferences,
        suppInfoSection: ticketData.supplementaryInformation,
    };
};

export const initNewTicket = (userDetail) => {
    if (!userDetail.dealerNumber || userDetail === '') {
        return undefined;
    }
    if(userDetail.group === 'AR') {
        return {
            ticketSection: {
                dealerNumber: userDetail.dealerNumber,
                dealerName: userDetail.dealerName,
                email: userDetail.mail,
                group: userDetail.group,
                userName: userDetail.name,
                status: ORDER_TICKET_STATUS_NEW,
            },
            ordersSection: {
                orderItems: [{index: 0}],
            },
            vehicleDetail: {
                bvmLoaded: true,
            },
            suppInfoSection: {
                srcRequest: 'NO'
            },
        };
    } else {
        return {
            ticketSection: {
                dealerNumber: userDetail.dealerNumber,
                dealerName: userDetail.dealerName,
                email: userDetail.mail,
                group: userDetail.group,
                telNo: userDetail.telNo,
                userName: userDetail.name,
                status: ORDER_TICKET_STATUS_NEW,
            },
            ordersSection: {
                orderItems: [{index: 0}],
            },
            vehicleDetail: {
                bvmLoaded: true,
            },
            suppInfoSection: {
                srcRequest: 'NO'
            },
        };
    }
};

export const initNewBatteryTicket = (userDetail) => {
    if (!userDetail.dealerNumber || userDetail === '') {
        return undefined;
    }
    if(userDetail.group === 'AR') {
        return {
            ticketSection: {
                dealerNumber: userDetail.dealerNumber,
                dealerName: userDetail.dealerName,
                email: userDetail.mail,
                group: userDetail.group,
                userName: userDetail.name,
                status: ORDER_TICKET_STATUS_NEW,
            },
            ordersSection: {
                orderItems: [{index: 0}],
            },
            batteryDetail: {
                batteryType: "NEW_BATTERY",
            },
            batteryProblemDescription: {
                quantity: "1",
            },
        };
    } else {
        return {
            ticketSection: {
                dealerNumber: userDetail.dealerNumber,
                dealerName: userDetail.dealerName,
                email: userDetail.mail,
                group: userDetail.group,
                telNo: userDetail.telNo,
                userName: userDetail.name,
                status: ORDER_TICKET_STATUS_NEW,
            },
            ordersSection: {
                orderItems: [{index: 0}],
            },
            batteryDetail: {
                batteryType: "NEW_BATTERY",
            },
            batteryProblemDescription: {
                quantity: "1",
            },
        };
    }
};

export const initAllocationReqToVorPviTicket = (values, userDetail) => {
    const {ticketUuid, uuid, ...rest} = values.oldValues.vehicleDetail;
    const selectedOrders = values.newValues.ordersSection.orderItems.filter(order => order.transferToVor).map((order, index) => {
            return {
                line: order.line,
                order: order.order,
                partNo: order.partNo,
                quantity: order.quantity,
                prod: order.prod,
                remarks: order.remarks,
                description: order.description,
            }
        }
    );
    let data;
    if(userDetail.group === 'AR') {
        data = {
            ticketSection: {
                dealerNumber: userDetail.dealerNumber,
                dealerName: userDetail.dealerName,
                email: userDetail.mail,
                group: userDetail.group,
                userName: userDetail.name,
                status: ORDER_TICKET_STATUS_NEW,
            },
            ordersSection: {
                orderItems: selectedOrders,
            },
            vehicleDetail: rest,
        };
    } else {
        data = {
            ticketSection: {
                dealerNumber: userDetail.dealerNumber,
                dealerName: userDetail.dealerName,
                email: userDetail.mail,
                group: userDetail.group,
                telNo: userDetail.telNo,
                userName: userDetail.name,
                status: ORDER_TICKET_STATUS_NEW,
            },
            ordersSection: {
                orderItems: selectedOrders,
            },
            vehicleDetail: rest,
        };
    }

    return data;
};


export const transformNewTicketForSave = (values) => {
    const data = {};
    data.ticket = {
        ...values.ticketSection,
        uuid: v4(),
    };

    if(data.ticket.orderType !== EV_BATTERY_FORM) {
        data.orderItems = [];
        values.ordersSection.orderItems.forEach((item, index) => {
            data.orderItems.push({
                index,
                ...item,
                uuid: v4(),
            })
        });
    }

    if (values.suppInfoSection) {
        data.supplementaryInformation = values.suppInfoSection;
    }

    if (values.notesSection) {
        data.note = {
            ...values.notesSection,
            uuid: v4(),
        };
    }

    if ((data.ticket.orderType === EV_BATTERY_FORM) && values.batteryDetail) {
        data.batteryDetail = {
            ...values.batteryDetail,
            uuid: v4(),
        };
    }

    if ((data.ticket.orderType === EV_BATTERY_FORM) && values.batteryProblemDescription) {
        data.batteryProblemDescription = {
            ...values.batteryProblemDescription,
            uuid: v4(),
        };
    }

    if ((data.ticket.orderType === ALLOCATION_REQUEST_FORM || data.ticket.orderType === VOR_PVI_ORDER_FORM) && values.vehicleDetail) {
        data.vehicleDetail = {
            ...values.vehicleDetail,
            uuid: v4(),
        };
    }

    if (data.ticket.orderType === VOR_PVI_ORDER_FORM && (data.vehicleDetail[FORM_FIELD_CAR_LOCATION] === 'OWN_WORKSHOP' || data.vehicleDetail[FORM_FIELD_CAR_LOCATION] === 'SUBSIDIARY') && get(values, `carRequestsSection`)) {
        data.carRequest = {
            request: values.carRequestsSection.reimbursement,
            carType: values.carRequestsSection.carType,
            information: values.carRequestsSection.supplementaryInformation,
            uuid: v4(),
        };
    }

    if (get(values, 'attachmentsSection.newAttachments', {default: false}) && values.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        values.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                ...attachment,
                uuid: v4(),
            })
        })
    }
    return data;
};

export const createInternalTicketSparePartsInitData = (newValues) => {
    return {
        vin: get(newValues, 'vehicleDetail.vin') || get(newValues, 'batteryDetail.vin'),
            ticketReference: {
        uuid: get(newValues, 'ticketSection.uuid'),
            pfxNumber: get(newValues, 'ticketSection.pfxNumber'),
            sfxNumber: get(newValues, 'ticketSection.sfxNumber'),
            networkTicketNumber: formatTicketNumber(get(newValues, 'ticketSection.pfxNumber'), get(newValues, 'ticketSection.sfxNumber')),
            formType: get(newValues, 'ticketSection.orderType'),
            region: get(newValues, 'ticketSection.region'),
            group: get(newValues, 'ticketSection.group'),
            clientId: get(newValues, 'ticketSection.clientId'),
            dealerNumber: get(newValues, 'ticketSection.dealerNumber'),
    },
        batteryProblemDescription: {
            ...get(newValues, 'batteryProblemDescription.partNo') && {partNo: get(newValues, 'batteryProblemDescription.partNo')},
            ...get(newValues, 'batteryProblemDescription.managementGroup') && {managementGroup: get(newValues, 'batteryProblemDescription.managementGroup')}
        },
        orderItems: {
            ...get(newValues, 'ordersSection.orderItems.0.partNo') && {partNo: get(newValues, 'ordersSection.orderItems.0.partNo')},
            ...get(newValues, 'ordersSection.orderItems.0.managementGroup') && {managementGroup: get(newValues, 'ordersSection.orderItems.0.managementGroup')}
        },
        attachments: (get(newValues, 'attachmentsSection.attachments') || []).map(item => ({
            ...item,
            isDealerTicketAttachment: true,
            dealerTicketId: get(newValues, 'ticketSection.uuid'),
        }))
    }
}

export const transformUpdateTicketForSave = (values) => {
    const {oldValues, newValues, submitBy} = values;
    const difference = diff(oldValues, newValues);
    const dicNumber = get(difference, 'ordersSection.dicNumber');
    const data = {};
    data.submitBy = submitBy
    data.ticket = {
        ...get(difference, 'ticketSection'),
        dicNumber,
        uuid: oldValues.ticketSection.uuid,
        pfxNumber: oldValues.ticketSection.pfxNumber,
        sfxNumber: oldValues.ticketSection.sfxNumber,
        status: newValues.ticketSection.status,
    };

    const replies = [];
    const orderItems = [];
    const newOrderItems = get(difference, 'ordersSection.orderItems');
    if (newOrderItems) {
        Object.keys(newOrderItems).forEach(itemKey => {
            const orderItem = {...newOrderItems[itemKey]};
            if (orderItem.orderReply) {
                replies.push({
                    reply: orderItem.orderReply,
                    uuid: v4(),
                    itemUuid: get(oldValues, `ordersSection.orderItems.${itemKey}.uuid`),
                });
                delete orderItem.orderReply;
            }
            const {transferToDic, ...orderItemProps} = orderItem;
            if (Object.keys(orderItemProps).length > 0) {
                const uuid = get(oldValues, `ordersSection.orderItems.${itemKey}.uuid`, v4());
                orderItems.push({
                    ...orderItemProps,
                    uuid,
                });
            }
        });
    }
    if (replies.length > 0) {
        data.replies = replies;
    }
    if (orderItems.length > 0) {
        data.orderItems = orderItems;
    }

    const newSuppInfo = get(difference, 'suppInfoSection');
    if (newSuppInfo) {
        data.supplementaryInformation = newSuppInfo;
    }

    const note = get(difference, 'notesSection.note');
    if (note) {
        data.note = {
            uuid: v4(),
            note,
        };
    }

    const vehicleDetail = get(difference, 'vehicleDetail');
    if (vehicleDetail && (oldValues.ticketSection.orderType === ALLOCATION_REQUEST_FORM || oldValues.ticketSection.orderType === VOR_PVI_ORDER_FORM)) {
        const uuid = get(oldValues, `vehicleDetail.uuid`, v4());
        data.vehicleDetail = {
            ...vehicleDetail,
            uuid,
        };
    }

    const warehouse = get(difference, 'warehouse');
    if (warehouse && (oldValues.ticketSection.orderType === VOR_PVI_ORDER_FORM)) {
        const uuid = get(oldValues, `warehouse.uuid`, v4());
        data.warehouse = {
            ...warehouse,
            uuid,
        };
    }

    const carRequestsSection = get(difference, `carRequestsSection`);
    if (carRequestsSection) {
        const {carRequests, ...rest} = carRequestsSection;
        if (((oldValues.ticketSection.status === 'NEW' && newValues.ticketSection.status === 'NEW') || (oldValues.ticketSection.status === 'WAIT_DEALER' && oldValues.ticketSection.group === 'TR')) && rest) {
            data.carRequest = {
                uuid: v4(),
                request: get(rest, 'reimbursement'),
                carType: get(rest, 'carType'),
                information: get(rest, 'supplementaryInformation'),
            };
        } else if (carRequestsSection.carRequests) {
            const carType = carRequests[Object.keys(carRequests)[0]].carType;
            if (carType) {
                data.carRequest = {
                    uuid: v4(),
                    carType,
                };
            }
        }
    }

    const batteryDetail = get(difference, 'batteryDetail');
    if (batteryDetail && (oldValues.ticketSection.orderType === EV_BATTERY_FORM)) {
        const uuid = get(oldValues, `batteryDetail.uuid`, v4());
        data.batteryDetail = {
            ...batteryDetail,
            uuid,
        };
    }

    const batteryProblemDescription = get(difference, 'batteryProblemDescription');
    if (batteryProblemDescription && (oldValues.ticketSection.orderType === EV_BATTERY_FORM)) {
        const uuid = get(oldValues, `batteryProblemDescription.uuid`, v4());
        data.batteryProblemDescription = {
            ...batteryProblemDescription,
            uuid,
        };
    }

    const newEditorsNote = get(difference, `${FORM_EDITORS_NOTES_SECTION_SP}.${FORM_FIELD_EDITORS_NOTES_SP}`);
    if (newEditorsNote) {
        data.editorsNote = {
            uuid: v4(),
            newEditorsNote,
        };
    }

    if (get(difference, 'attachmentsSection.newAttachments', {default: false}) && difference.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        difference.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                uuid: v4(),
                ...attachment,
            })
        })
    }

    return data;
};

export const initLoadedSparePartsInternalTicket = (ticketData) => {
    const {lockIpn, lockTime, ...ticketSection} = get(ticketData, 'ticket', {default: {}});

    const originalSolutionGroup = get(ticketSection, "solutionGroup", {default: {}});
    ticketSection.solutionGroup = get(ticketSection, "solutionGroup.id", {default: {}});

    return {
        ticketSection: {...ticketSection},
        vehicleDetail: ticketData.vehicleDetail,
        problemDescription: ticketData.problemDescription,
        partDetail: ticketData.partDetail,
        messages: ticketData.messages,
        documentationUpdate: ticketData.documentationUpdate,
        storeCheckResults: ticketData.storeCheckResults,
        statusHistory: ticketData.logs,
        attachmentsSection: {attachments: ticketData.attachments},
        requestorSolutionGroup: originalSolutionGroup,
    };
};

export const initNewSparePartsInternalTicket = (userDetail, initData) => {
    if (!userDetail.ipn || !userDetail.name || userDetail === '') {
        return undefined;
    }

    console.log("initData")
    console.log(initData)

    const data = {};

    const networkTicketNumber = get(initData, 'ticketReference.networkTicketNumber');

    data.ticketSection = {
        requestorName: userDetail.name,
        requestorIpn: userDetail.ipn,
        requestForPartner: "NO",
        priorityTreatment: "NO",

        ...(networkTicketNumber && {networkTicketNumber: networkTicketNumber}),
    };

    if (get(userDetail, 'roles.SPARE_PARTS.sgEditor.sgPermission').length === 1) {
        const array = get(userDetail, 'roles.SPARE_PARTS.sgEditor.sgPermission')
        data.ticketSection.solutionGroup = array[0].id
    }

    data.vehicleDetail = {
        bvmLoaded: true,
        ...(get(initData, 'vin') && {vin: get(initData, 'vin')}),
    };

    data.problemDescription = {
        ...(get(initData, 'problemDesc') && {problemDesc: get(initData, 'problemDesc')}),
    };

    data.partDetail = {
        ...(get(initData, 'orderItems.partNo') && {nReferenceCausingProblem: get(initData, 'orderItems.partNo')}),
        ...(get(initData, 'orderItems.managementGroup') && {managementGroup: get(initData, 'orderItems.managementGroup')}),
        ...(get(initData, 'batteryProblemDescription.partNo') && {nReferenceCausingProblem: get(initData, 'batteryProblemDescription.partNo')}),
        ...(get(initData, 'batteryProblemDescription.managementGroup') && {managementGroup: get(initData, 'batteryProblemDescription.managementGroup')}),
        ...(get(initData, 'partMarking') && {partMarking: get(initData, 'partMarking')}),
    };

    data.attachmentsSection = {
        ...(get(initData, 'attachments') && {attachments: get(initData, 'attachments')}),
    };

    if (initData && initData.ticketReference) {
        data.ticketReference = initData.ticketReference;
    }

    data.documentationUpdate = {
        docUpdateNeeded: "NO",
    }

    return data;
};

export const transformNewInternalTicketForSave = (values, editorSolutionGroups) => {
    const data = {};

    data.ticket = {
        ...values.ticketSection,
        status: undefined,
        uuid: v4(),
    };

    const editorSolutionGroup = editorSolutionGroups.find(x => x.id === data.ticket.solutionGroup);
    data.ticket.solutionGroup = {id: data.ticket.solutionGroup, name: editorSolutionGroup.name};

    if(data.ticket.requestForPartner === NO) {
        delete data.ticket.partnerName
    }

    if(values.vehicleDetail) {
        data.vehicleDetail = values.vehicleDetail
    }

    if(values.partDetail) {
        data.partDetail = values.partDetail
    }

    if(values.problemDescription) {
        data.problemDescription = values.problemDescription
    }

    if(values.documentationUpdate) {
        data.documentationUpdate = values.documentationUpdate
    }

    if(values.storeCheckResults) {
        data.storeCheckResults = values.storeCheckResults
    }

    if (values.messagesSection && values.messagesSection.newMessage) {
        data.message = {
            uuid: v4(),
            newMessage: values.messagesSection.newMessage,
        };
    }

    const internalAttachments = get(values, 'attachmentsSection.newAttachments');
    const dealerAttachments = get(values, 'attachmentsSection.attachments');

    if ((internalAttachments && internalAttachments.length) || (dealerAttachments && dealerAttachments.length)) {
        data.newAttachments = [];

        internalAttachments && internalAttachments.forEach(attachment => {
            data.newAttachments.push({
                ...attachment,
                uuid: v4(),
            })
        });
        dealerAttachments && dealerAttachments.forEach(attachment => {
            data.newAttachments.push(attachment);
        });
    }

    if (values.ticketReference) {
        data.ticketReference = values.ticketReference;
    }

    return data;
};

export const transformUpdateSparePartsInternalTicketForSave = (values) => {
    const {oldValues, newValues} = values;
    const difference = diff(oldValues, newValues);
    const data = {};

    data.ticket = {
        ...get(difference, 'ticketSection'),
        uuid: oldValues.ticketSection.uuid,
        pfxNumber: oldValues.ticketSection.pfxNumber,
        sfxNumber: oldValues.ticketSection.sfxNumber,
        status: newValues.ticketSection.status,
    };


    const newVehicleDetail = get(difference, 'vehicleDetail');
    if(newVehicleDetail) {
        data.vehicleDetail = newVehicleDetail
    }

    const newPartDetail = get(difference, 'partDetail');
    if(newPartDetail) {
        data.partDetail = newPartDetail
    }

    const newProblemDescription = get(difference, 'problemDescription');
    if(newProblemDescription) {
        data.problemDescription = newProblemDescription
    }

    const newDocumentationUpdate = get(difference, 'documentationUpdate');
    if(newDocumentationUpdate) {
        data.documentationUpdate = newDocumentationUpdate
    }

    const newStoreCheckResults = get(difference, 'storeCheckResults');
    if(newStoreCheckResults) {
        data.storeCheckResults = newStoreCheckResults
    }

    const newMessage = get(difference, 'messagesSection.newMessage');
    if (newMessage) {
        data.message = {
            uuid: v4(),
            newMessage,
        };
    }

    if (get(difference, 'attachmentsSection.newAttachments', {default: false}) && difference.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        difference.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                uuid: v4(),
                ...attachment,
            })
        })
    }

    return data;
};

export const hasSelectedAnyOrdersForTransferToDic = (orders) => orders.find(order => order.transferToDic);
export const hasSelectedAnyNewOrdersForTransferToDic = (initialOrders, orders) => orders.find((order) => order.transferToDic && get(initialOrders.find(initOrder => initOrder.uuid === order.uuid), 'transferToDic', false) !== order.transferToDic);

export const checkDeliveryDateOlder10Years = value => {
    const date = new Date(value);
    const tempDate = new Date();
    tempDate.setFullYear(tempDate.getFullYear() - 10);

    return date < tempDate;
};
