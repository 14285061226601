import {defineMessages} from 'react-intl';

const messages = defineMessages({
    DATE_FROM: {
        id: 'techDoc.internal.export.form.dateFrom',
        defaultMessage: 'Date From'
    },
    DATE_TO: {
        id: 'techDoc.internal.export.form.dateTo',
        defaultMessage: 'Date To'
    },
    STATUS: {
        id: 'techDoc.internal.export.form.status',
        defaultMessage: 'Status'
    },
    IS_ASSIGNED_TO: {
        id: 'techDoc.internal.export.form.isAssignedTo',
        defaultMessage: 'Is Assigned To'
    },
    HAS_BEEN_ASSIGNED_TO: {
        id: 'techDoc.internal.export.form.hasBeenAssignedTo',
        defaultMessage: 'Has Been Assigned To'
    },
    REQUEST_TYPE: {
        id: 'techDoc.internal.export.form.requestType',
        defaultMessage: 'Type Of Request'
    },
    PARTNER: {
        id: 'techDoc.internal.export.form.partner',
        defaultMessage: 'Partner'
    },
    VEHICLE_FAMILY: {
        id: 'techDoc.internal.export.form.vehicleFamily',
        defaultMessage: 'Vehicle Family'
    },
    N_REFERENCE_CAUSING_PROBLEM: {
        id: 'techDoc.internal.export.form.nReferenceCausingProblem',
        defaultMessage: 'N° reference causing problem'
    },
    TYPE: {
        id: 'techDoc.internal.export.form.type',
        defaultMessage: 'Type'
    },
    CHARSET: {
        id: 'techDoc.internal.export.form.charset',
        defaultMessage: 'Charset'
    },
    XLS: {
        id: 'techDoc.internal.export.form.xls',
        defaultMessage: 'XLS'
    },
    CSV: {
        id: 'techDoc.internal.export.form.csv',
        defaultMessage: 'CSV'
    },
    EXPORT_STATUS: {
        id: 'techDoc.internal.export.exportStatus',
        defaultMessage: 'Export status:'
    },
    LAST_EXPORT: {
        id: 'techDoc.internal.export.lastExport',
        defaultMessage: 'Last export:'
    }
});

export default messages;
