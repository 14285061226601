import React, {Component} from 'react';
import styled from 'styled-components';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import {FormattedDate, FormattedMessage, FormattedTime, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {PrimaryButton} from '../../common/Button';
import {InputFieldWithValidation} from '../../common/InputFieldWithValidation';
import RadioFields from '../../common/RadioFields';
import {domains, getFormStates,} from '../../../constants/Utils';
import InputSelectFieldWithValidation from '../../common/InputSelectFieldWithValidation';
import buttonMessages from '../../../intl/common/buttonMessages';
import statusMessages from '../../../intl/common/formStatesMessages';
import exportConstantMessages from '../../../intl/common/exportMessages';
import exportMessages from '../../../intl/sparePartsOrders/exportMessages';
import DatePickerComponent from '../../common/datePicker/DatePickerComponent';
import moment from 'moment';
import PropTypes from 'prop-types';
import charsetMessages from '../../../intl/charsetMessages';
import {BUTTON_LABEL_DOWNLOAD, BUTTON_LABEL_EXPORT} from '../../../constants/formConstants';
import {
    CSV,
    ORDER_EXPORT_FORM_FIELD_ASSIGNED,
    ORDER_EXPORT_FORM_FIELD_CHARSET,
    ORDER_EXPORT_FORM_FIELD_DEALER_NAME,
    ORDER_EXPORT_FORM_FIELD_DEALER_NUMBER,
    ORDER_EXPORT_FORM_FIELD_FROM,
    ORDER_EXPORT_FORM_FIELD_PFX_NUMBER,
    ORDER_EXPORT_FORM_FIELD_SFX_NUMBER, ORDER_EXPORT_FORM_FIELD_SOLUTION_GROUP,
    ORDER_EXPORT_FORM_FIELD_STATUS,
    ORDER_EXPORT_FORM_FIELD_TO,
    ORDER_EXPORT_FORM_FIELD_TYPE,
    ORDER_EXPORT_FORM_LABEL_ASSIGNED,
    ORDER_EXPORT_FORM_LABEL_CHARSET,
    ORDER_EXPORT_FORM_LABEL_DEALER_NAME,
    ORDER_EXPORT_FORM_LABEL_DEALER_NUMBER,
    ORDER_EXPORT_FORM_LABEL_FROM, ORDER_EXPORT_FORM_LABEL_SOLUTION_GROUP,
    ORDER_EXPORT_FORM_LABEL_STATUS,
    ORDER_EXPORT_FORM_LABEL_TICKET_NUMBER,
    ORDER_EXPORT_FORM_LABEL_TO,
    ORDER_EXPORT_FORM_LABEL_TYPE,
    ticketExportTypes
} from '../../../constants/exportConstant';
import InputFieldTicketNumber from '../../common/InputFieldTicketNumber';
import {uniqBy} from 'lodash';
import {moduleRoles} from '../../../utils/roles';
import get from 'get-value';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const status = (() => {
    const arr = getFormStates(domains.SPARE_PARTS);
    arr.splice(2, 1);
    return arr;
})();

class ExportOrderForm extends Component {
    onChange = (event, newValue) => {
        event.preventDefault();
        const { change, dealers } = this.props;
        if (event.target.name === `dealer.${ORDER_EXPORT_FORM_FIELD_DEALER_NAME}`) {
            const find = dealers.filter(dealer => dealer.dealerName === newValue)[0];
            change('dealer', find ? find : { dealerNumber: '', dealerName: '' });
        }
        if (event.target.name === `dealer.${ORDER_EXPORT_FORM_FIELD_DEALER_NUMBER}`) {
            const find = dealers.filter(dealer => dealer.dealerNumber === newValue)[0];
            change('dealer', find ? find : { dealerNumber: '', dealerName: '' });
        }
    };

    render() {
        const { handleSubmit, dealers, exportType, exportRecord, downloadExport, startDate, endDate, charSets, roles } = this.props;
        let sgOptions = [];

        if(moduleRoles.isSGEditor(roles, domains.SPARE_PARTS) && (get(roles, 'SPARE_PARTS.sgEditor.sgPermission.length') > 0)){
            const sgEditorOptions = (get(roles, 'SPARE_PARTS.sgEditor.sgPermission') || []).map(sg => ({
                uuid: sg.id,
                presetName: sg.name
            }))
            sgOptions = sgOptions.concat(sgEditorOptions);
        }
        if(moduleRoles.isSGAdmin(roles, domains.SPARE_PARTS) && (get(roles, 'SPARE_PARTS.sgAdmin.sgPermission.length') > 0)){
            const sgAdminOptions = (get(roles, 'SPARE_PARTS.sgAdmin.sgPermission') || []).map(sg => ({
                uuid: sg.id,
                presetName: sg.name
            }))
            sgOptions = sgOptions.concat(sgAdminOptions);
        }


        return (
            <StyledDiv className="border border-secondary p-4 bg-white">
                <div className="row justify-content-center">
                    <div className="col-xl-8 col-md-12">
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldTicketNumber label={exportMessages[ORDER_EXPORT_FORM_LABEL_TICKET_NUMBER]}
                                                        fields={[ORDER_EXPORT_FORM_FIELD_PFX_NUMBER, ORDER_EXPORT_FORM_FIELD_SFX_NUMBER]}
                                                        validation/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputFieldWithValidation label={exportMessages[ORDER_EXPORT_FORM_LABEL_ASSIGNED]}
                                                          field={ORDER_EXPORT_FORM_FIELD_ASSIGNED}
                                                          isSmall/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <Field component={DatePickerComponent}
                                       selectsStart
                                       label={exportMessages[ORDER_EXPORT_FORM_LABEL_FROM]}
                                       name={ORDER_EXPORT_FORM_FIELD_FROM}
                                       startDate={startDate}
                                       endDate={endDate}
                                       parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <Field component={DatePickerComponent}
                                       selectsEnd
                                       label={exportMessages[ORDER_EXPORT_FORM_LABEL_TO]}
                                       name={ORDER_EXPORT_FORM_FIELD_TO}
                                       startDate={startDate}
                                       endDate={endDate}
                                       parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation
                                    label={exportMessages[ORDER_EXPORT_FORM_LABEL_DEALER_NAME]}
                                    field={`dealer.${ORDER_EXPORT_FORM_FIELD_DEALER_NAME}`}
                                    options={dealers.map(dealer => dealer.dealerName)}
                                    handleChange={this.onChange}
                                    isSmall/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation
                                    label={exportMessages[ORDER_EXPORT_FORM_LABEL_DEALER_NUMBER]}
                                    field={`dealer.${ORDER_EXPORT_FORM_FIELD_DEALER_NUMBER}`}
                                    options={dealers.map(dealer => dealer.dealerNumber)}
                                    handleChange={this.onChange}
                                    isSmall/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={exportMessages[ORDER_EXPORT_FORM_LABEL_STATUS]}
                                                                field={ORDER_EXPORT_FORM_FIELD_STATUS}
                                                                options={status}
                                                                intlMessages={statusMessages}
                                                                isSmall/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <InputSelectFieldWithValidation label={exportMessages[ORDER_EXPORT_FORM_LABEL_SOLUTION_GROUP]}
                                                                field={ORDER_EXPORT_FORM_FIELD_SOLUTION_GROUP}
                                                                customOptionsMsg={uniqBy(sgOptions, (e => (e.uuid)))}
                                                                isSmall/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-2">
                                <RadioFields
                                    label={exportMessages[ORDER_EXPORT_FORM_LABEL_TYPE]}
                                    field={ORDER_EXPORT_FORM_FIELD_TYPE}
                                    options={ticketExportTypes}
                                    intlMessages={exportMessages}/>
                                <InputSelectFieldWithValidation label={exportMessages[ORDER_EXPORT_FORM_LABEL_CHARSET]}
                                                                field={ORDER_EXPORT_FORM_FIELD_CHARSET}
                                                                options={charSets}
                                                                intlMessages={charsetMessages}
                                                                isDisabled={!exportType}
                                                                noEmpty
                                                                isSmall/>
                            </div>
                        </div>
                        <form className="form-group">
                            {(Object.keys(exportRecord).length > 0) &&
                            <div className="">
                                <div>
                                    <span className="font-weight-bold">
                                        <FormattedMessage {...exportMessages.LAST_EXPORT}/>{' '}
                                    </span>
                                    <FormattedDate value={new Date(exportRecord.created)}/> <FormattedTime
                                    value={new Date(exportRecord.created)}/>
                                </div>
                                <div>
                                    <span className="font-weight-bold">
                                        <FormattedMessage {...exportMessages.EXPORT_STATUS}/>{' '}
                                    </span>
                                    <FormattedMessage {...exportConstantMessages[exportRecord.status]}/>
                                </div>
                                {exportRecord.status === 'FINISH' &&
                                <PrimaryButton type="button"
                                               className="btn mr-2 float-left"
                                               onClick={downloadExport}>
                                    <FormattedMessage {...buttonMessages[BUTTON_LABEL_DOWNLOAD]}/>
                                </PrimaryButton>
                                }
                            </div>
                            }
                            <PrimaryButton type="button"
                                           className="btn mr-2 float-right"
                                           onClick={handleSubmit}>
                                <FormattedMessage {...buttonMessages[BUTTON_LABEL_EXPORT]}/>
                            </PrimaryButton>
                        </form>
                    </div>
                </div>
            </StyledDiv>
        );
    }
}

ExportOrderForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    exportType: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    dealers: PropTypes.array.isRequired,
    charSets: PropTypes.array.isRequired,
};

const selector = (form, ...other) => (formValueSelector(form))(...other);

const mapStateToProps = (state, initialProps) => ({
    startDate: selector(initialProps.form, state, ORDER_EXPORT_FORM_FIELD_FROM),
    endDate: selector(initialProps.form, state, ORDER_EXPORT_FORM_FIELD_TO),
    exportType: (selector(initialProps.form, state, ORDER_EXPORT_FORM_FIELD_TYPE) === CSV),
    dealers: state.sparePartDealer.dealers,
    exportRecord: state.orderExportTickets.exportRecord,
    charSets: state.profile.userDetail.charSets,
    roles: state.profile.userDetail.roles,
});

export default reduxForm({
    enableReinitialize: true,
})(injectIntl(connect(mapStateToProps, {})(ExportOrderForm)));
