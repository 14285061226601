import React from 'react';
import styled, {keyframes} from 'styled-components';
import PropTypes from 'prop-types';

const KeyframesSpin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;
KeyframesSpin.displayName = 'KeyframesSpin';

const LoaderDiv = styled.div`
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #ffcc33;
  width: ${props => props.isSmall ? '10px' : '80px'};
  height: ${props => props.isSmall ? '10px' : '80px'};
  animation: ${KeyframesSpin} 1s linear infinite;
  margin: auto;
`;
LoaderDiv.displayName = 'LoaderDiv';

const LoaderWrapper = styled.div`
  width: 100%;
  height: ${props => props.isSmall ? '10px' : '200px'};
  display: flex;
  align-items: center;
`;
LoaderWrapper.displayName = 'LoaderWrapper';

const Loader = ({isSmall}) => (
    <LoaderWrapper isSmall={isSmall}>
        <LoaderDiv isSmall={isSmall}/>
    </LoaderWrapper>
);

Loader.propTypes = {
    isSmall: PropTypes.bool,
};

export default Loader;
