import {domains} from '../../constants/Utils';
import get from 'get-value';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const ADMIN_MODEL_ANSWERS_FETCH_PENDING = 'ADMIN_MODEL_ANSWERS_FETCH_PENDING';
export const ADMIN_MODEL_ANSWERS_SAVE_PENDING = 'ADMIN_MODEL_ANSWERS_SAVE_PENDING';
export const ADMIN_MODEL_ANSWERS_DELETE_PENDING = 'ADMIN_MODEL_ANSWERS_DELETE_PENDING';
export const ADMIN_MODEL_ANSWERS_SOLUTION_GROUP_FETCH_PENDING = 'ADMIN_MODEL_ANSWERS_SOLUTION_GROUP_FETCH_PENDING';
export const ADMIN_MODEL_ANSWERS_RESET = 'ADMIN_MODEL_ANSWERS_RESET';
export const ADMIN_MODEL_ANSWERS_SET_COUNTRY_GROUPS = 'ADMIN_MODEL_ANSWERS_SET_COUNTRY_GROUPS';

export const fetchModelAnswers = (domain, solutionGroup, countryGroups, pageSize, sorted, cursor, isNextFromPivot, filtered) => dispatch => {
    const fullFiltered = get(solutionGroup, 'isDefault') ? [...filtered] : [...filtered].filter(item => item.id !== 'displayArea');
    if(get(solutionGroup, 'id')) {
        fullFiltered.push({id: 'sgId', value: solutionGroup.id});
    }
    if(get(solutionGroup, 'isDefault') && countryGroups) {
        fullFiltered.push({id: 'groups', value: countryGroups});
    }
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_MODEL_ANSWERS_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MODEL_ANSWERS_GET,
                payload: {pageSize: pageSize, sorted: sorted, cursor: cursor, isNextFromPivot: isNextFromPivot, filtered: fullFiltered}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_MODEL_ANSWERS_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_MODEL_ANSWERS_GET,
                payload: {pageSize: pageSize, sorted: sorted, cursor: cursor, isNextFromPivot: isNextFromPivot, filtered: fullFiltered}
            });
        }
    }
};

export const createModelAnswer = (domain, payload) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_MODEL_ANSWERS_SAVE_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MODEL_ANSWERS_CREATE,
                payload: {payload}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_MODEL_ANSWERS_SAVE_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_MODEL_ANSWERS_CREATE,
                payload: {payload}
            });
        }
    }
};

export const updateModelAnswer = (domain, payload) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_MODEL_ANSWERS_SAVE_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MODEL_ANSWERS_UPDATE,
                payload: {payload}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_MODEL_ANSWERS_SAVE_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_MODEL_ANSWERS_UPDATE,
                payload: {payload}
            });
        }
    }
};

export const deleteModelAnswer = (domain, payload) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_MODEL_ANSWERS_DELETE_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_MODEL_ANSWERS_DELETE,
                payload: {payload}
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_MODEL_ANSWERS_DELETE_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_MODEL_ANSWERS_DELETE,
                payload: {payload}
            });
        }
    }
};

export const fetchFullSolutionGroupInfo = (domain, sgId) => dispatch => {
    switch (domain) {
        case domains.SPARE_PARTS: {
            return dispatch({
                type: ADMIN_MODEL_ANSWERS_SOLUTION_GROUP_FETCH_PENDING,
                sparePartsWebsocket: true,
                command: sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_FULL_SOLUTION_GROUP_INFO_GET,
                payload: {
                    solutionGroupId: sgId
                }
            });
        }
        case domains.TECHNICAL_DOCUMENTATION: {
            return dispatch({
                type: ADMIN_MODEL_ANSWERS_SOLUTION_GROUP_FETCH_PENDING,
                techDocWebsocket: true,
                command: techDocWebsocketCommands.TECH_DOC_FULL_SOLUTION_GROUP_INFO_GET,
                payload: {
                    solutionGroupId: sgId
                }
            });
        }
    }
};

export const setCountryGroups = groups => dispatch => dispatch({
    type: ADMIN_MODEL_ANSWERS_SET_COUNTRY_GROUPS,
    payload: groups
});

export const resetModelAnswers = groups => dispatch => dispatch({
    type: ADMIN_MODEL_ANSWERS_RESET,
});
