import React from 'react';
import styled from 'styled-components';
import '../../css/formControl.css';
import validationMessages from "../../intl/common/validationMessages";
import {FormattedMessage} from "react-intl";

const ErrorDiv = styled.label`
    display: inherit;
`;

export const CheckboxGroupField = (fields) => {
    const { isSmall, isDisabled, names, maxLength, placeholder } = fields;

    const firstField = fields[names[0].split('.')[names[0].split('.').length - 1]];
    const secondField = fields[names[1].split('.')[names[1].split('.').length - 1]];

    return (
        <div>
            <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                    <div className="input-group-text">
                        <input {...firstField.input}
                               disabled={isDisabled}
                               type="checkbox"
                               checked={firstField.input.value}/>
                    </div>
                </div>
                <input {...secondField.input}
                       className={"form-control" + (isSmall ? " form-control-sm" : "") + (secondField.meta.touched && secondField.meta.invalid ? " is-invalid" : "")}
                       disabled={isDisabled}
                       maxLength={maxLength}
                       placeholder={placeholder}
                       type="text"/>
            </div>
            {(firstField.meta.touched && firstField.meta.error &&
                <ErrorDiv
                    className="invalid-feedback">{validationMessages[firstField.meta.error] ?
                    <FormattedMessage {...validationMessages[firstField.meta.error]}/> : firstField.meta.error}</ErrorDiv>) || (secondField.meta.touched && secondField.meta.error &&
                <ErrorDiv className="invalid-feedback">{validationMessages[secondField.meta.error] ?
                    <FormattedMessage {...validationMessages[secondField.meta.error]}/> : secondField.meta.error}</ErrorDiv>)}
        </div>
    );
};




