import {} from '../../actions/techDoc/actionDocExport';
import get from 'get-value';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';

export const docExportInternalTickets = (state = {
    isLoadingExport: false,
    exportRecord: {},
    correlationId: null,
}, action) => {
    switch (action.type) {
        case techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_GET: {
            return Object.assign({}, state, {isLoadingExport: true});
        }
        case techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            const exportRecord = get(action, 'payload.exportRecord', {default: {}});
            return Object.assign({}, state, {
                isLoadingExport: false,
                exportRecord,
                correlationId,
            });
        }
        case techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoadingExport: false});
        }
        case techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_CREATE: {
            const exportRecord = {
                created: new Date(),
                status: 'NEW'
            };
            return Object.assign({}, state, {exportRecord});
        }
        case techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_STATUS:
        case techDocWebsocketCommands.TECH_DOC_EXPORT_INTERNAL_ERROR: {
            const {correlationId, exportRecord} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {exportRecord, correlationId});
        }
        default:
            return state;
    }
};
