import {
    ADMIN_POPUP_FETCH_PENDING,
    ADMIN_POPUP_SAVE_PENDING,
} from '../../actions/admin/actionAdminPopUp';
import get from 'get-value';
import {sparePartsWebsocketCommands} from '../../constants/sparePartsWebsocketCommands';
import {techDocWebsocketCommands} from '../../constants/techDocWebsocketCommands';
import {claimsWebsocketCommands} from '../../constants/claimsWebsocketCommands';

export const adminPopUp = (state = {
    isLoading: false,
    popUp: {},
}, action) => {
    switch (action.type) {
        case ADMIN_POPUP_FETCH_PENDING: {
            return Object.assign({}, state, {isLoading: true, popUp: {}});
        }
        case ADMIN_POPUP_SAVE_PENDING: {
            return Object.assign({}, state, {isLoading: true, popUp: {}});
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_POPUP_SEND:
        case techDocWebsocketCommands.TECH_DOC_POPUP_SEND:
        case claimsWebsocketCommands.CLAIMS_POPUP_SEND: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {
                isLoading: false,
                popUp: get(action, 'payload.popup', {default: {}}),
                correlationId
            });
        }
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_POPUP_ERROR:
        case techDocWebsocketCommands.TECH_DOC_POPUP_ERROR:
        case claimsWebsocketCommands.CLAIMS_POPUP_ERROR:
        case sparePartsWebsocketCommands.SPARE_PARTS_ORDERS_POPUP_SEND_ERROR:
        case techDocWebsocketCommands.TECH_DOC_POPUP_SEND_ERROR:
        case claimsWebsocketCommands.CLAIMS_POPUP_SEND_ERROR: {
            const {correlationId} = action.payload;
            if (correlationId < state.correlationId) {
                return state;
            }
            return Object.assign({}, state, {isLoading: false, popUp: {}, correlationId});
        }
        default: {
            return state;
        }
    }
};
