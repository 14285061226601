import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import 'react-table/react-table.css';
import Table from '../../common/table/TicketTable';
import {IconEdit, PrimaryIcon} from '../../common/Button';
import {fetchSparePartsSearchEnginePageData} from '../../../actions/orders/actionSparePartsSearchEnginePage';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {editOrderListButtonVisibility} from '../../../constants/sparePartsTicketRights';
import {moduleRoles} from '../../../utils/roles';
import searchEngineMessages from "../../../intl/sparePartsOrders/searchEngineMessages";
import get from 'get-value';
import {domains} from '../../../constants/Utils';


class SearchEngineOrderTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listDefaultPageSize: 10,
            listDefaultSort: [{id: "ticketNumber", desc: true}],
        };
    }

    handleFetchData = (state) => {
        this.props.fetchSparePartsSearchEnginePageData(
            state.pageSize,
            state.sorted,
            state.cursor,
            state.isNextFromPivot,
            get(this, 'props.listDefaultFilters.1', { default: {} }),
            this.normalizeFiltered(get(this, 'props.listDefaultFilters.0', { default: [] }))
        );
        this.setState({
            listDefaultPageSize: state.pageSize,
            listDefaultSort: state.sorted,
        });
    };

    normalizeFiltered = state => {
        return state
            .map(filterItem => {
                if (filterItem.value instanceof Date) {
                    return {
                        id: filterItem.id,
                        value: moment(filterItem.value).add(12, 'hours').toISOString().substr(0, 10)
                    };
                } else {
                    return filterItem;
                }
            });
    };

    render() {
        const { columns, data, pages, isLoading, handleEditClick, listDefaultFilters, isNextPage, isNextFromPivot, dealerNumber } = this.props;
        return (
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col">
                        <Table
                            columns={[
                                {
                                    id: 'ticketNumber',
                                    accessor: 'ticket.ticketNumber',
                                    filterable: false,
                                    message: searchEngineMessages.TABLE_TICKET_NUMBER,
                                    Cell: (row) => <Link
                                        to={`view/${row.original.ticket.uuid}`}>{row.value}</Link>,
                                },
                                ...columns]}
                            toolButtons={[
                                {
                                    handleClick: handleEditClick,
                                    component: <PrimaryIcon className="btn btn-sm"
                                                            title="Edit"
                                                            type="button"
                                                            key="editButton">
                                        <IconEdit/>
                                    </PrimaryIcon>
                                }
                            ]}
                            isLoading={isLoading}
                            data={data}
                            pages={pages}
                            handleFetchData={this.handleFetchData}
                            isNextPage={isNextPage}
                            isNextFromPivot={isNextFromPivot}
                            defaultFiltered={listDefaultFilters}
                            defaultSorting={this.state.listDefaultSort}
                            defaultPageSize={this.state.listDefaultPageSize}
                            buttonVisibility={editOrderListButtonVisibility[moduleRoles.isDealerOrder(this.props.userRoles) ? 'DEALER' : 'EDITOR']}
                            dealerNumber={moduleRoles.isDealerOrder(this.props.userRoles) ? this.props.dealerNumber : 'EDITOR'}
                            editorGroups={moduleRoles.isEditorOrder(this.props.userRoles) ? moduleRoles.getEditorGroups(domains.SPARE_PARTS, this.props.userRoles).map(e => e.group) : false}
                            sgPermissions = {get(this.props.userRoles, `${domains.SPARE_PARTS}.sgEditor.sgPermission`)}
                            checkInternationalTicketsForSGEditors
                            toolSortable={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

SearchEngineOrderTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    isNextPage: PropTypes.bool.isRequired,
    isNextFromPivot: PropTypes.bool.isRequired,
    pages: PropTypes.number.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    fetchSparePartsSearchEnginePageData: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    userRoles: PropTypes.object.isRequired,
    listDefaultFilters: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.sparePartsSearchEnginePage.isTableLoading,
    data: state.sparePartsSearchEnginePage.searchEngineData,
    isNextPage: state.techDocHistoryPage.isNextPage,
    isNextFromPivot: state.techDocHistoryPage.isNextFromPivot,
    pages: state.sparePartsSearchEnginePage.pages,
    userRoles: state.profile.userDetail.roles,
    dealerNumber: state.profile.userDetail.dealerNumber,
});

export default connect(mapStateToProps, { fetchSparePartsSearchEnginePageData })(SearchEngineOrderTable);
