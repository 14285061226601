import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {domains} from '../../constants/Utils';
import Table from '../common/table/Table';
import statisticsMessages from '../../intl/home/statisticsMessages';
import {ORDERS_LIST_PATH, DOCUMENTATIONS_LIST_PATH, CLAIMS_LIST_PATH} from '../../routes/paths';

const DOMAIN_PATH = {
    [domains.SPARE_PARTS]: ORDERS_LIST_PATH,
    [domains.TECHNICAL_DOCUMENTATION]: DOCUMENTATIONS_LIST_PATH,
    [domains.CLAIMS]: CLAIMS_LIST_PATH,
};
function convertToTableData(data, domain, formatMessage) {
    const result = [];
    if (data) {
        data.forEach(row => result.push({
            status: formatMessage(statisticsMessages[`TABLE_ROW_${domain}_${row.status}`]),
            status_orig: row.status,
            total: row.total
        }));
    }
    return result;
}

function renderTotal(table) {
    return <span className="font-weight-bold">{
        table.data.length === 0 ? 0 : table.data.map(row => row.total).reduce((acc, cur) => acc + cur)
    }</span>;
}

function StatisticsTable({domain, data, group, solutionGroup, intl: {formatMessage}}) {
    return <Table
        withPagination={false}
        columns={[
            {
                id: 'status',
                accessor: 'status',
                message: statisticsMessages.TABLE_HEADER_STATUS,
                Cell: row => {
                    return <Link
                        to={`${DOMAIN_PATH[domain]}?status=${row.original.status_orig}${(group && group.length > 0) ? '&group=' + group : ''}${(solutionGroup && solutionGroup.length > 0) ? '&sgroup=' + solutionGroup : ''}`}>{row.value}</Link>
                },
                Footer: <span className="font-weight-bold">
                    <FormattedMessage {...statisticsMessages.TABLE_FOOTER_TOTAL}/>
                </span>
            },
            {
                id: 'total',
                accessor: 'total',
                message: statisticsMessages.TABLE_HEADER_TOTAL,
                Footer: renderTotal
            }
        ]}
        data={convertToTableData(data, domain, formatMessage)}
        defaultSorting={[{id: 'status'}]}
    />
}

StatisticsTable.propTypes = {
    domain: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
    group: PropTypes.array.isRequired,
    solutionGroup: PropTypes.array.isRequired,
};

export default connect(null, {})(injectIntl(StatisticsTable));
